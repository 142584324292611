import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

interface SearchEventsParams {
	q?: string
	page: number
	userId: number
	campusId?: number
	categoryId?: number[]
	startDate?: string
	endDate?: string
	schoolId: number
}

const searchEvents = async ({ q, page, userId, campusId, categoryId, startDate, endDate, schoolId }: SearchEventsParams) => {
	const url = `${schoolId}/events/search`

	const params = {
		q,
		page,
		userId,
		campuses: campusId,
		categoryId,
		perPage: settings.SEARCH_LIST_PER_PAGE,
		startDate,
		endDate,
	}

	return await http.get(url, { params })
}

export default searchEvents

import SignUpCard from '@/components/SignUpCard'
import { GridLayout } from '@/features/onboarding/components/onboardingEntities/layouts/GridLayout'
import OnboardingEntityList from '@/features/onboarding/components/onboardingEntities/list'
import { ButtonItem } from '@/features/onboarding/components/onboardingEntities/templates/ButtonItem'
import { selectUserChoicesByType } from '@/features/onboarding/slice'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppSelector } from '@/store'
import React from 'react'
import { useOnboardingStepConfig } from './hooks'
import IStepProps from './StudentSteps/types/IStepProps'

const AcademicCollegeStep: React.FC<IStepProps> = ({ onClickNext }) => {
	const academicCollegeConfig = useOnboardingStepConfig('academicColleges')
	const userSelections = useAppSelector((state) => selectUserChoicesByType(state, { entityType: EntityTypes.academicCollege }))

	const disableContinueButton = userSelections.length === 0

	const onClickContinue = () => {
		onClickNext()
	}

	return (
		<SignUpCard
			title={academicCollegeConfig?.title}
			subTitle={academicCollegeConfig?.subTitle}
			continueButtonDisabled={disableContinueButton}
			showSkipButtonWhenContinueDisabled={true}
			onClickContinue={onClickContinue}
			skipText="Skip"
		>
			<GridLayout>
				<OnboardingEntityList
					entityType={EntityTypes.academicCollege}
					renderItem={({ item, onClick }) => <ButtonItem {...item} onClick={onClick} />}
				/>
			</GridLayout>
		</SignUpCard>
	)
}

export { AcademicCollegeStep }

import { Theme, makeStyles } from '@material-ui/core/styles'
import { IEntityItemProps } from './IEntityItemProps'

const CONTAINER_BORDER_RADIUS = 3

const useStyles = makeStyles<Theme, Partial<Omit<IEntityItemProps, 'onClick'>>>((theme) => ({
	cardWrapper: {
		height: 160,
		flexGrow: 1,
		flexBasis: '48%',
	},
	root: ({ photo, checked }) => ({
		borderRadius: CONTAINER_BORDER_RADIUS,
		backgroundImage: `url(${photo})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		color: theme.palette.common.white,
		cursor: 'pointer',
		boxShadow: '0px 6px 6px rgba(0,0,0,0.2)',
		height: 160,
		width: '100%',
		position: 'relative',
		'&::before': {
			zIndex: 1,
			content: '""',
			display: 'block',
			position: 'absolute',
			width: '100%',
			height: '100%',
			borderRadius: CONTAINER_BORDER_RADIUS,
			background: checked ? theme.palette.primary.main : theme.palette.common.black,
			opacity: checked ? 0.82 : 0.58,
		},
	}),
	contentWrapper: {
		zIndex: 2,
		position: 'absolute',
		bottom: 0,
		left: 0,
		padding: '12px 13px',
	},
	title: {
		fontSize: 15,
		fontWeight: 700,
		lineHeight: 1.2,
		overflowWrap: 'anywhere',
	},
	subtitle: () => {
		const lineHeight = `1.25em`
		return {
			fontSize: 15,
			fontWeight: 400,
			margin: '5px 0 0 0',
			height: lineHeight,
			lineHeight,
			overflow: 'hidden',
			position: 'relative',
			marginRight: '-1em',
			paddingRight: '1em',
			maxWidth: '100%',
		}
	},
}))

export const GridItem = ({ checked, description, id, name, subtitle, photo, onClick, showDescription = true }: IEntityItemProps) => {
	const classes = useStyles({ checked, photo })

	return (
		<div className={classes.cardWrapper}>
			<div className={classes.root} onClick={onClick}>
				<div className={classes.contentWrapper}>
					<div className={classes.title}>{name}</div>
					{showDescription && <p className={classes.subtitle}>{subtitle}</p>}
				</div>
			</div>
		</div>
	)
}

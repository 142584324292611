import settings from '../../../constants/http'
import { orgTypes } from '../../../utils/organizationRoles'
import http from '../requestHttp'

interface SearchOrganizationMembershipsProps {
	userId: number
	q?: string
	page: number
	organizationId: string
	organizationMembershipTypeId?: orgTypes
	schoolId: number
}

const searchOrganizationMemberships = async ({
	userId,
	q,
	page,
	organizationId,
	organizationMembershipTypeId,
	schoolId,
}: SearchOrganizationMembershipsProps) => {
	const url = `${schoolId}/organizations/${userId}/search/memberships`

	const params = {
		q,
		page,
		organizationId,
		organizationMembershipTypeId,
		perPage: settings.USERS_PER_PAGE,
	}

	return http.get(url, { params })
}

export default searchOrganizationMemberships

import { Card, CardContent, CardHeader, CardProps, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		borderRadius: 20,
		boxShadow: '0px 3px 15px #00000019',
		padding: `${theme.spacing(1)}px`,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}`,
		},
		flex: 1,
	},
	headerContent: {
		paddingBottom: theme.spacing(1),
	},
	title: {
		fontWeight: 700,
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
}))

export interface ICommonCardProps extends CardProps {
	title?: string
	subHeader?: ReactNode
}

const CommonCard: React.FC<ICommonCardProps> = ({ children, title, subHeader, elevation = 1, classes: externalClasses = {}, ...props }) => {
	const classes = useStyles()

	return (
		<Card className={classNames(classes.root, externalClasses.root, classes.content)} {...props} elevation={elevation}>
			{(title || subHeader) && (
				<CardHeader classes={{ title: classes.title, root: classes.headerContent }} subheader={subHeader} title={title} />
			)}
			<CardContent className={classes.content}>{children}</CardContent>
		</Card>
	)
}

export { CommonCard }

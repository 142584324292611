import http from '@/api/http/requestHttp'
import { PostResponseStatuses } from '@/interfaces/common'
import { CommonUserConnectionParams } from './types'

interface PostUserResponseParams extends CommonUserConnectionParams {
	response: PostResponseStatuses
	reportMessage?: string
}

const postUserResponse = async ({ userId, targetUserId, response, reportMessage, schoolId }: PostUserResponseParams) => {
	const url = `${schoolId}/network/${userId}/response`

	return http.post(url, { targetUserId, response, reportMessage })
}

export default postUserResponse

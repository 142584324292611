import { makeStyles, Theme } from '@material-ui/core/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Message } from '@/store/messages/types'
import { Typography } from '@material-ui/core'
import { STYLES } from '@/constants'

interface StylesProps {
	listStart?: 'flex-start' | 'end'
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	rootContainer: ({ listStart = 'end' }) => ({
		display: 'flex',
		flexDirection: 'column-reverse',
		justifyContent: listStart,
	}),
	contentContainer: ({ listStart = 'end' }) => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		overflow: 'auto',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		'&>div.infinite-scroll-component__outerdiv': {
			width: STYLES.FILL_AVAILABLE_WIDTH,
			height: '100%',
			display: 'flex',
			flexDirection: 'column-reverse',
			justifyContent: listStart,
		},
	}),
	placeholder: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
}))

interface MessagesListProps extends StylesProps {
	placeholder?: React.ReactNode
	startPlaceholder?: React.ReactNode
	rowRenderer: (message: Message, index: number, array: Message[]) => React.ReactElement
	handleLoadMore: () => void
	targetId?: string | number
	isLoading: boolean
	canLoadMore: boolean
	messages: Message[]
}

const MessagesList = ({
	placeholder,
	startPlaceholder,
	rowRenderer,
	handleLoadMore,
	targetId,
	isLoading,
	canLoadMore,
	messages,
	listStart,
}: MessagesListProps) => {
	const classes = useStyles({ listStart })

	return (
		<AutoSizer>
			{({ height, width }) => (
				<div
					key={`${targetId}`}
					className={classes.root}
					style={{
						height,
						width,
					}}
				>
					<div className={classes.contentContainer}>
						<InfiniteScroll
							className={classes.rootContainer}
							dataLength={messages.length}
							next={handleLoadMore}
							hasMore={canLoadMore}
							loader={<Typography>Loading...</Typography>}
							height={height}
							inverse
						>
							{!messages.length && !isLoading && !!placeholder && <div className={classes.placeholder}>{placeholder}</div>}
							{messages.map((m, i) => rowRenderer(m, i, messages))}
							{!isLoading && !canLoadMore && startPlaceholder}
						</InfiniteScroll>
					</div>
				</div>
			)}
		</AutoSizer>
	)
}

export default MessagesList

import IconButton from '@/components/Buttons/IconButton'
import { CaretLeft } from '@phosphor-icons/react'
import React from 'react'
import { useHistory } from 'react-router-dom'

export type IBackButtonProps = {
	onClick?: () => void
}

const BackButton: React.FC<IBackButtonProps> = ({ onClick, children = 'Back' }) => {
	const history = useHistory()
	const defaultClickHandler = () => {
		history.goBack()
	}
	const onButtonClick = onClick || defaultClickHandler
	return (
		<IconButton onClick={onButtonClick} startIcon={<CaretLeft size={24} weight="bold" />}>
			{children}
		</IconButton>
	)
}

export default BackButton

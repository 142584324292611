import AddToCalendarModal from '@/components/AddToCalendarModal'

import ExternalUrlCard from '@/components/IndividualPage/ExternalUrlCard'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import PageContainer from '@/components/IndividualPage/PageContainer'
import { ReportSubject } from '@/components/ReportModal'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { ReportActions } from '@/interfaces/common'
import { setEventResponseRequest } from '@/store/events'

import { isPast } from '@/utils/dateTime2'

import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppDispatch } from '@/store'
import { useCallback, useMemo } from 'react'
import { useGetEventByIdQuery } from '../api'
import AboutEventCard from '../components/AboutEventCard'
import EventTitleCard from '../components/EventTitleCard'

const IndividualEvent = ({
	match: {
		params: { id },
	},
	location,
}: any) => {
	const dispatch = useAppDispatch()

	const { data, isLoading } = useGetEventByIdQuery({ id })

	const event = data || null

	const eventName = event?.name

	useWatchPageTitle({
		watchEntity: eventName,
	})

	const handleReportEvent = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setEventResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)
	const imageCardClasses = useMemo(
		() => ({
			itemId: event?.id,
			reportSubject: ReportSubject.Event,
			onSubmitReport: handleReportEvent,
		}),
		[event?.id, handleReportEvent],
	)
	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.event } }), [id])

	if (!data || isLoading) return <LoadingPlaceholder />

	const pastEvent = isPast(event.endsOn)

	return (
		<PageContainer>
			<AddToCalendarModal />

			<ImageCard dotButton={imageCardClasses} photoUrl={event.imageUrl} />
			<EventTitleCard
				userResponse={event.response}
				rsvpSettings={event.rsvpSettings}
				name={`${eventName}`}
				eventId={event.id}
				description={event.description}
				startDate={event.startsOn}
				endDate={event.endsOn}
				organizations={event.organizationIds}
				address={event.address}
				sharedData={sharedData}
				pastEvent={pastEvent}
			/>

			<AboutEventCard categories={event.categories} description={event.description} />
			<ExternalUrlCard url={event.externalUrl} />
		</PageContainer>
	)
}

export default IndividualEvent

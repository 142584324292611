import { useAppDispatch } from '@/store'
import { getNotifications, removeSnackbar } from '@/store/app'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const displayedSnacks = new Map()

const Notifier = () => {
	const dispatch = useAppDispatch()

	const notifications = useSelector(getNotifications)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const storeDisplayed = (id: any) => {
		displayedSnacks.set(id, true)
	}

	const removeDisplayed = (id: any) => {
		displayedSnacks.delete(id)
	}

	useEffect(() => {
		notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				setTimeout(() => closeSnackbar(key), 1000)
				return
			}

			// do nothing if snackbar is already displayed
			if (displayedSnacks.has(key)) return

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				autoHideDuration: 100,
				//...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey)
					}
				},
				onExit: (event, myKey) => {
					// remove this snackbar from redux store
					dispatch(removeSnackbar({ key }))
					removeDisplayed(key)
				},
			})

			// keep track of snack-bars that we've displayed
			storeDisplayed(key)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

	return null
}

export default Notifier

import { ICreatePageHeaderConfig } from '@/anthology/components/PageHeaderTabs'

export enum UserOrganizationTypes {
	followed = 'followed',
	member = 'member',
	admin = 'admin',
}

export type TUserOrganizationTypes = `${UserOrganizationTypes}`

export const USER_ORGANIZATIONS_TABS: ICreatePageHeaderConfig<TUserOrganizationTypes> = {
	followed: {
		label: 'Following',
		order: 0,
		placeholder: {
			title: 'No organizations at the moment!',
			description: ['Organizations you follow, belong to,', 'and are the admin or moderator of', 'will appear here.'],
		},
	},
	member: {
		label: 'Joined',
		order: 1,
		placeholder: {
			title: 'No organizations at the moment!',
			description: ['You are not a member any organizations.'],
		},
	},
	admin: {
		label: 'Admin',
		order: 2,
		placeholder: {
			description: ['Organizations that you are a', 'super admin or moderator of will', 'appear here.'],
		},
	},
}

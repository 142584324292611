import http from '@/api/http/requestHttp'

export interface InviteUsersToGroupBody {
	invites: number[]
}

const inviteUsersToGroup = async (id: string, data: InviteUsersToGroupBody, schoolId: number) => {
	const url = `${schoolId}/groups/${id}/members/invites`

	return http.post(url, data)
}

export default inviteUsersToGroup

const ROOT = 'volunteer'

const VOLUNTEERS = {
	ROOT,
	EVENTS: `${ROOT}/events`,
	ORGANIZATIONS: `${ROOT}/organizations`,
	MY: `${ROOT}/my`,
}

export default VOLUNTEERS

import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import * as http from '@/api/http'
import { CampusIcon, DotButton, IconButtonWithTooltip, IconWrapper, ImageViewModal } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CategoryTag from '@/components/CategoryTag'
import { PostComments } from '@/components/Feeds/CommentsList'
import LinkPreview from '@/components/Feeds/LinkPreview'
import ShareButtonWithTooltip from '@/components/Feeds/ShareButtonWithTooltip'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import OrganizationRow from '@/components/OrganizationRow'
import { ReportSubject } from '@/components/ReportModal'
import UserRow from '@/components/UserRow'
import { PATHS } from '@/constants'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import { useGetPostPermissionsQuery } from '@/features/permissions/api'
import usePostDelete from '@/features/posts/hooks/crud/usePostDelete'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useAuth from '@/hooks/useAuth'
import { ReportActions, SharedDataTypes } from '@/interfaces/common'
import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import { useAppDispatch } from '@/store'
import { getFeedsTagsOptions, setPostResponseRequest } from '@/store/feeds'
import { FeedPost } from '@/store/feeds/types'
import { setShareModal } from '@/store/messages'
import { copyToClipboard, createShareLink } from '@/utils/common'
import { getFromNow } from '@/utils/dateTime2'
import { createGmailMessageLink, createOutlookMessageLink } from '@/utils/services'
import { AdminBadge } from '../Badges/AdminBadge'
import useMentionClickHandler from '../Editor/hooks/useMentionClickHandler'
import { Middot } from '../Middot'
import { useStyles } from './PostCard/useStyles'
import { PostImpressionRecorder } from './PostImpressionRecorder'
import { usePostPermissions } from './hooks/usePostPermissions'

interface PostCardProps extends FeedPost {
	shouldSkipViewMonitor?: boolean
	showAsPreview?: boolean
	openComments?: boolean
	clickable?: boolean
	onClick?: () => void
}

const PostCard = ({
	showAsPreview,
	shouldSkipViewMonitor = false,
	openComments = false,
	clickable = false,
	onClick,
	...post
}: PostCardProps) => {
	const { pathname } = useLocation()
	const { createdAt, permissions } = post
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const deletePost = usePostDelete(post.id)

	const {
		id,
		ownerUser,
		ownerOrganization,
		tag,
		usersAudience,
		campusId,
		message,
		photoUrl,
		linkPreviewUrl,
		numberOfReposts,
		sharedEntityId,
		sharedEntityType,
		uiState,
	} = post

	const { schoolId, userId } = useAuth()

	const { liked: currentUserLikedPost, numberOfLikes: numLikes, numberOfComments: numComments } = post
	const numViews = +post.numberOfViews
	const appTheme = useTheme()
	const campusesOptions = useSelector(getActiveCampuses)
	const showCampuses = useSelector(selectShowCampuses)
	const tagsOptions = useSelector(getFeedsTagsOptions)

	const { isOwnerOrAdmin, canEditPost, canDeletePost, canViewStats } = usePostPermissions(post)
	const skipPermissionCheck = permissions || isOwnerOrAdmin || !post.ownerOrganization

	const { data: serverData, isLoading: permissionsRequestIsLoading } = useGetPostPermissionsQuery(
		{ postId: post.id, userId },
		{
			skip: skipPermissionCheck,
		},
	)

	const permissionsData = useMemo(() => {
		if (serverData) {
			return serverData
		}
		if (permissions) {
			return permissions
		}
		return { canDelete: canDeletePost, canEdit: canEditPost }
	}, [canDeletePost, canEditPost, permissions, serverData])

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const [showComments, setShowComments] = useState(openComments)

	const [fullImageView, setFullImageView] = useState('')

	const classes = useStyles({ showAsPreview, clickable, uiState })

	const handleOpenComments = (event: React.MouseEvent<EventTarget>) => {
		if (pathname === PATHS.APP.POST_INFO(id)) {
			event.stopPropagation()
			setShowComments((prev) => !prev)
		}
	}

	const tagLabel = tagsOptions.find((option) => option.value === +tag)?.label
	const campusLabel = campusesOptions.find((option) => option.value === +campusId)?.label

	const showContentBlock = message || (sharedEntityId && sharedEntityType)

	const numViewsDisplay = numViews > 1 ? numViews : 0

	const sharedData = useMemo(
		() => ({
			sharedEntity: { sharedEntityId: post.id, sharedEntityType: EntityTypes.post },
		}),
		[post.id],
	)
	const disableReposting = +usersAudience === 2

	const renderButtons = useMemo(() => {
		const buttons = []

		if (permissionsData.canEdit) {
			buttons.push({
				label: 'Edit Post',
				onClick: (e: any) => {
					e.stopPropagation()
					globalStates.createFeedsModal.setValue((data) => {
						data.content = { ...data.content, postToEdit: post }
						data.isEdit = true
						data.isSchedule = false
					})
				},
				icon: null,
			})
		}

		if (permissionsData.canDelete) {
			buttons.push({
				label: 'Delete Post',
				onClick: (e: any) => {
					e.stopPropagation()
					deletePost()
				},
				icon: null,
			})
		}
		return buttons
	}, [permissionsData.canEdit, permissionsData.canDelete, post, deletePost])

	const handleLikePost = useCallback(
		async (event: any) => {
			event.stopPropagation()

			await http.feeds.likePost({ userId, postId: id, schoolId })
		},
		[userId, id, schoolId],
	)

	const handleRepostFeed = useCallback(
		(event: React.MouseEvent<EventTarget>) => {
			event.stopPropagation()

			setCreateFeedsModal((data) => {
				// @ts-ignore
				data.content = { ...data.content, ...sharedData }
			})
		},

		[setCreateFeedsModal, sharedData],
	)

	const handleReportPost = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setPostResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const handleClick = useCallback(
		(e: any) => {
			e.stopPropagation()

			if (onClick) onClick()
			if (clickable) {
				push(PATHS.APP.POST_INFO(id))
			}
		},
		[onClick, clickable, id, push],
	)

	const handleHtmlClick = (e: any) => {
		if (e.target.tagName !== 'A') return
		e.stopPropagation()
	}

	const handleOpenImageView = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		setFullImageView(photoUrl ?? '')
	}

	const handleCloseImageView = () => setFullImageView('')

	const shareButtons = useMemo(
		() => [
			{
				label: 'Copy Link',
				onClick: (e: any) => {
					e.stopPropagation()

					const shareLink = createShareLink(PATHS.APP.POST_INFO(id))

					copyToClipboard(shareLink)
				},
				icon: null,
			},
			{
				label: 'Direct Message',
				onClick: (e: any) => {
					e.stopPropagation()
					//@ts-ignore
					const type = SharedDataTypes.sharedPost

					dispatch(setShareModal({ isOpen: true, data: sharedData, type }))
				},
				icon: null,
			},
			{
				label: 'Share via Outlook',
				onClick: (e: any) => {
					e.stopPropagation()
					const shareLink = createShareLink(PATHS.APP.POST_INFO(id))

					const link = createOutlookMessageLink('Post', shareLink)

					window.open(link, '_blank')
				},
				icon: null,
			},
			{
				label: 'Share via Gmail',
				onClick: (e: any) => {
					e.stopPropagation()
					const shareLink = createShareLink(PATHS.APP.POST_INFO(id))

					const link = createGmailMessageLink('Post', shareLink)

					window.open(link, '_blank')
				},
				icon: null,
			},
		],
		[dispatch, id, sharedData],
	)
	// @TODO: passing classes from parent to children is bad approach
	// come back to this after UIKit is complete switch to styled components
	const postOwnerClasses = useMemo(
		() => ({
			root: classes.userInfoRow,
			userName: classes.userName,
			info: classes.greyText,
			profileImage: classes.profileImage,
		}),

		[classes.greyText, classes.profileImage, classes.userInfoRow, classes.userName],
	)
	const contentRef = useMentionClickHandler(message)

	return (
		<PostImpressionRecorder as="div" post={post} className={classes.root} id={id} data-post-id={id}>
			<div ref={contentRef} className={classes.contentContainer} onClick={handleClick}>
				<div className={classes.header}>
					{!ownerOrganization ? (
						ownerUser && <UserRow classnames={postOwnerClasses} user={ownerUser!} />
					) : (
						<OrganizationRow classnames={postOwnerClasses} organization={ownerOrganization} />
					)}
					<div className={classes.headerButtons}>
						<span className={classes.metaLine}>
							<Typography variant={'body1'}>{getFromNow(createdAt)}</Typography>
							{canViewStats && numViewsDisplay ? (
								<>
									<Middot />
									<span className={classes.flexCenteredItemsY}>
										<IconWrapper iconKey="eye" color={appTheme.palette.text.primary} />
										<Typography variant={'body1'} className={classes.numViewsText}>
											{numViewsDisplay}
										</Typography>
									</span>
								</>
							) : null}
						</span>

						{!showAsPreview && (
							<DotButton
								isLoading={permissionsRequestIsLoading}
								variant="secondary"
								className={classes.moreButton}
								itemId={id}
								reportSubject={ReportSubject.Post}
								onSubmitReport={handleReportPost}
								renderButtons={renderButtons}
								showReportButton={!permissionsData.canEdit}
							/>
						)}
					</div>
				</div>
				<div className={classes.postInfo}>
					{tagLabel && tagLabel !== 'Other' && (
						<CategoryTag
							name={tagLabel}
							cardColor={appTheme.palette.common.white}
							textColor={appTheme.palette.primary.main}
							borderColor={appTheme.palette.primary.main}
						/>
					)}
					{showCampuses && (
						<CategoryTag
							icon={<CampusIcon />}
							cardColor={appTheme.palette.type === 'dark' ? appTheme.palette.background.default : appTheme.palette.grey[200]}
							textColor={appTheme.palette.text.secondary}
							borderColor={appTheme.palette.divider}
							name={campusLabel || 'All Campuses'}
						/>
					)}
					{permissionsData.canEdit && <AdminBadge rootClass={classes.adminBadge} size={42} />}
				</div>
				{showContentBlock && (
					<div className={classes.content}>
						{message && <DescriptionHtml className={classes.postText} onClick={handleHtmlClick} description={message} />}

						{sharedEntityId && sharedEntityType ? (
							<div className={classes.sharedObject}>
								<SharedEntity sharedEntityId={sharedEntityId} sharedEntityType={sharedEntityType} />
							</div>
						) : null}
					</div>
				)}
				{photoUrl && (
					<>
						<ImageViewModal imageSrc={fullImageView} onClose={handleCloseImageView} />
						<div className={classes.sharedPhotoContainer} onClick={handleOpenImageView}>
							<img className={classes.sharedPhoto} src={photoUrl} alt={''} />
						</div>
					</>
				)}
				{linkPreviewUrl && (
					<div className={classes.previewLinkContainer}>
						<LinkPreview userId={userId} url={linkPreviewUrl} />
					</div>
				)}
				{!showAsPreview && (
					<div className={classes.footer}>
						<div className={classes.footerLeftBlock}>
							<IconButtonWithTooltip
								tooltipTitle={currentUserLikedPost ? 'Unlike' : 'Like'}
								label={`${numLikes}`}
								color={ButtonColors.LIGHT_RED}
								onClick={handleLikePost}
							>
								<IconWrapper
									iconKey="heart"
									color={currentUserLikedPost ? appTheme.colors.red[500] : appTheme.palette.text.primary}
									weight={currentUserLikedPost ? 'fill' : 'regular'}
								/>
							</IconButtonWithTooltip>
							<IconButtonWithTooltip
								tooltipTitle="Comment"
								label={`${numComments}`}
								color={ButtonColors.LIGHT_GREEN}
								onClick={handleOpenComments}
							>
								<IconWrapper iconKey="comment" color={appTheme.palette.text.primary} />
							</IconButtonWithTooltip>
							<IconButtonWithTooltip
								tooltipTitle="Repost"
								onClick={disableReposting ? () => {} : handleRepostFeed}
								label={`${numberOfReposts}`}
								color={ButtonColors.LIGHT_BLUE}
								disabled={disableReposting}
							>
								<IconWrapper iconKey="repeat" color={appTheme.palette.text.primary} />
							</IconButtonWithTooltip>
						</div>

						<div className={classes.footerRightBlock}>
							<ShareButtonWithTooltip tooltipTitle="Share" renderButtons={shareButtons} disabled={disableReposting}>
								<IconWrapper iconKey="shareFat" color={appTheme.palette.text.primary} />
							</ShareButtonWithTooltip>
						</div>
					</div>
				)}
			</div>
			{showComments && <PostComments postId={id} />}
		</PostImpressionRecorder>
	)
}

export default PostCard

import http from '@/api/http/requestHttp'
import { CommonUserConnectionParams } from './types'

const patchUnblockUser = async ({ userId, targetUserId, schoolId }: CommonUserConnectionParams) => {
	const url = `${schoolId}/network/${userId}/unblock`

	return http.patch(url, { targetUserId })
}

export default patchUnblockUser

import { makeStyles, Theme } from '@material-ui/core/styles'
import MUIDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme: Theme) => ({
	calendar: {
		backgroundColor: `${theme.palette.specialColors.transparent} !important`,
		border: 'none !important',
		marginTop: 10,
		'&>.react-datepicker__month-container': {
			'&>.react-datepicker__header': {
				backgroundColor: `${theme.palette.specialColors.transparent} !important`,
				border: 'none !important',
				'&>.react-datepicker__current-month': {
					fontWeight: 350,
					fontSize: '1rem',
				},
				'&>.react-datepicker__day-names': {
					padding: '10px 0 0 0',
					'&>.react-datepicker__day-name': {
						color: theme.palette.text.secondary,
						fontSize: '0.75rem',
						margin: 5,
					},
				},
			},
			'&>.react-datepicker__month': {
				'&>.react-datepicker__week': {
					'&>.react-datepicker__day': {
						margin: 5,
						fontSize: '0.85rem',
						fontWeight: 350,
					},
					'&>.react-datepicker__day--selected': {
						backgroundColor: theme.palette.primary.light,
						color: theme.palette.primary.main,
						borderRadius: 5,
						boxShadow: '0px 7px 7px -1px rgba(181,181,181,0.73)',
						padding: 5,
						fontSize: '1rem',
						margin: 0,
					},
					'&>.react-datepicker__day--keyboard-selected': {
						backgroundColor: theme.palette.specialColors.transparent,
						color: theme.palette.text.primary,
					},
					'&>.react-datepicker__day--in-range': {
						backgroundColor: theme.palette.primary.light,
						color: theme.palette.primary.main,
						borderRadius: 5,
					},
				},
			},
		},
	},
	beforeDay: {
		color: theme.palette.text.secondary,
	},
}))

export const DatePicker = (props: ReactDatePickerProps<any, boolean>) => {
	const classes = useStyles()

	const dayClassName = (date: Date) => {
		return DateTime.fromJSDate(date).startOf('day') < DateTime.local().startOf('day') ? classes.beforeDay : null
	}

	return <MUIDatePicker calendarClassName={classes.calendar} dayClassName={dayClassName} inline {...props} />
}

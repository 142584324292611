import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import { PATHS } from '@/constants'
import { useAppDispatch } from '@/store'
import { getError } from '@/store/app'
import { signOutRequest } from '@/store/auth'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { FEEDBACK_URL, isEngage } from '../constants/configuration'

const useStyles = makeStyles((theme) => ({
	errorCard: {
		paddingBottom: '1vh',
		minHeight: '45vh',
	},
	h5: {
		fontWeight: 700,
		textAlign: 'center',
		margin: '1em 0',
	},
	contentContainer: {
		maxWidth: '40vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
			maxWidth: 'none',
		},
	},
	link: {
		textDecoration: 'underline',
		color: theme.palette.primary.main,
	},
	withMargin: {
		marginTop: '1em',
	},
	messageText: {
		textAlign: 'center',
		fontSize: '1.12em',
		lineHeight: '1.6em',
	},
}))

const AuthErrorPage = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const error = useSelector(getError)
	const [errorMessage, setErrorMessage] = useState(
		isEngage()
			? 'We were unable to authenticate you for the application.'
			: 'Your login information does not match our records as an active student, faculty, or staff member. At this time, the app is not	open to community members, alumni, prospective students, or new students who have not yet attended NSO.',
	)

	useEffect(() => {
		const id = setTimeout(() => dispatch(signOutRequest()), 10000)

		return () => {
			if (id) clearTimeout(id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch])

	useEffect(() => setErrorMessage((currMessage) => error?.message ?? currMessage), [error])

	if (!error) return <Redirect to={PATHS.APP.ROOT} />

	return (
		<CardLayout>
			<Card className={classes.errorCard}>
				<div className={classes.contentContainer}>
					<Typography className={classes.h5} variant="h3">
						Sorry, something went wrong
					</Typography>
					<div className={classes.messageText}>{errorMessage}</div>
					<div className={classNames(classes.messageText, classes.withMargin)}>
						Please contact
						<Link className={classes.link} href={FEEDBACK_URL} color="inherit">
							&nbsp;our support team
						</Link>
						&nbsp;with any questions or concerns.
					</div>
				</div>
			</Card>
		</CardLayout>
	)
}

export default AuthErrorPage

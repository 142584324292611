import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useState } from 'react'

import { IconWrapper, ModalWithSubmittedPopup, TextButton, Toggle } from '@/components'
import { TruncateText } from '@/components/Text/TruncateText'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { removeOrLeaveGroupRequest, toggleGroupNotificationsRequest } from '@/store/groups'
import { submitFeedback } from '@/utils/services'
import { ADMIN_ACTIONS, checkGroupPermission, checkGroupRole, getShortCounterLabel } from '@navengage/sen-shared-assets'
import { GroupChatRoles, IGroupChat } from '@navengage/sen-types'
import { EditTabs } from '.'
import GroupImage from '../GroupImage'
import SettingsButton from './SettingsButton'

const useStyles = makeStyles((theme) => ({
	text: {
		wordBreak: 'break-word',
	},
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
		height: '100%',
	},
	mainInfoContainer: {
		display: 'flex',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'baseline',
		justifyContent: 'space-between',
		padding: 20,
		borderBottom: `solid 1px ${theme.palette.border.bold}`,
	},
	mainInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	groupName: {
		fontWeight: 700,
	},
	description: {
		marginTop: 10,
	},
	editButton: {
		minWidth: '20%',
		fontWeight: 600,
		justifyContent: 'flex-end',
	},
	leaveButton: {
		fontWeight: 600,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	toggle: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		justifyContent: 'space-between',
		padding: 20,
		color: theme.palette.text.primary,
		margin: 0,
		'&:hover': {
			color: theme.palette.primary.main,
		},
		'&>span:last-child': {
			...theme.typography.button,
			textTransform: 'none',
			fontWeight: 600,
			marginLeft: 0,
			marginRight: 0,
			[theme.breakpoints.up('xl')]: {
				...theme.typography.body1,
			},
		},
	},
	requestsNotification: {
		position: 'relative',
		width: 30,
		height: 30,
	},
	// @TODO: copy pasted css
	notificationDot: {
		width: 7,
		height: 7,
		backgroundColor: theme.palette.icons.notificationDot,
		borderRadius: '50%',
		position: 'absolute',
		top: 0,
		left: 0,
	},
}))

interface GroupInformationProps {
	data?: IGroupChat
	handleOpenTab: (t: EditTabs) => () => void
}

const GroupInformation = ({ data, handleOpenTab }: GroupInformationProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const [isOpenLeaveConfirmation, setIsOpenLeaveConfirmation] = useState(false)

	const isOwner = checkGroupRole(GroupChatRoles.Owner, data?.userMemberType)
	const isAdmin = checkGroupRole(GroupChatRoles.Admin, data?.userMemberType)

	const canEditGroup = checkGroupPermission(ADMIN_ACTIONS.EDIT_GROUP, data, data?.userMemberType)
	const canApproveRequests = checkGroupPermission(ADMIN_ACTIONS.APPROVE_MEMBER_REQUESTS, data, data?.userMemberType)

	const handleToggleLeaveConfirmationPopup = () => setIsOpenLeaveConfirmation((prev) => !prev)

	const handleRemoveOrLeaveGroup = () => dispatch(removeOrLeaveGroupRequest())

	const handleToggleNotifications = () => dispatch(toggleGroupNotificationsRequest())

	return (
		<div className={classes.groupInformation}>
			<ModalWithSubmittedPopup
				isOpen={isOpenLeaveConfirmation}
				onSubmit={handleRemoveOrLeaveGroup}
				onClose={handleToggleLeaveConfirmationPopup}
				title={`Are you sure you want to ${isOwner ? 'end' : 'leave'} this group?`}
				content={[isOwner ? 'This action cannot be undone.' : '']}
				submitButtonColor={appTheme.colors.red[500]}
				submitButtonLabel={isOwner ? 'End group' : 'Leave group'}
				showSubmittedModal={false}
			/>
			<GroupImage
				groupName={data?.name}
				placeholderSize={4}
				url={data?.thumbnail}
				onEdit={handleOpenTab(EditTabs.EDIT)}
				canEdit={canEditGroup}
				openImageByClick
			/>
			<div className={classes.mainInfoContainer}>
				<div className={classes.mainInfo}>
					<TruncateText controlled lines={2}>
						<Typography component="span" className={classNames(classes.text, classes.groupName)}>
							{data?.name}
						</Typography>
					</TruncateText>

					{data?.description && (
						<TruncateText controlled lines={3}>
							<Typography component="span" variant="subtitle1" className={classNames(classes.text, classes.description)}>
								{data?.description}
							</Typography>
						</TruncateText>
					)}
				</div>
				{canEditGroup && (
					<TextButton className={classes.editButton} onClick={handleOpenTab(EditTabs.EDIT)}>
						Edit
					</TextButton>
				)}
			</div>
			<Toggle
				className={classes.toggle}
				value={data?.isMutedNotifications ? data?.isMutedNotifications : false}
				labelPosition="start"
				label="Mute messages"
				onChange={() => handleToggleNotifications()}
			/>
			<SettingsButton
				label={`Members - ${getShortCounterLabel(data?.memberCount || 0)}`}
				onClick={handleOpenTab(EditTabs.MEMBERS)}
				icon={isAdmin ? <IconWrapper iconKey="crown" color={appTheme.palette.text.primary} /> : null}
			/>
			{canApproveRequests && (
				<SettingsButton
					label={`Member requests - ${getShortCounterLabel(data?.memberRequestCount || 0)}`}
					onClick={handleOpenTab(EditTabs.MEMBER_REQUESTS)}
					icon={
						!!data?.memberRequestCount ? (
							<div className={classes.requestsNotification}>
								<div className={classes.notificationDot} />
							</div>
						) : null
					}
				/>
			)}
			<SettingsButton label="Gallery" onClick={handleOpenTab(EditTabs.GALLERY)} />
			{isOwner && <SettingsButton label="Settings" onClick={handleOpenTab(EditTabs.SETTINGS)} />}
			<SettingsButton label="Report a concern" onClick={submitFeedback} />
			<SettingsButton
				label={isOwner ? 'End group' : 'Leave group'}
				textColor={appTheme.colors.red[500]}
				textHoverColor={appTheme.colors.red[400]}
				onClick={handleToggleLeaveConfirmationPopup}
				showArrow={false}
			/>
		</div>
	)
}

export default GroupInformation

import { Modal, styled } from '@mui/material'

import { CircleIconButton, IconButtonVariant } from '@/components'
import { ModalKind } from '../types/dashboardTypes'

const StyledModal = styled(Modal)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	textAlign: 'center',
}))

const ModalContent = styled('div')<{ kind: ModalKind }>(({ theme, kind }) =>
	kind === ModalKind.ENTITY_SEARCH
		? {
				width: '50%',
				backgroundColor: 'white',
				margin: 'auto',
				marginTop: '100px',
				padding: '20px',
				borderRadius: '8px',
				position: 'relative',
		  }
		: {
				width: '72vw',
				position: 'relative',
				backgroundColor: 'white',
				borderRadius: '8px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'space-between',
				justifyContent: 'flex-start',
				overflowY: 'auto',
				padding: theme.spacing(3),
				maxHeight: '75vh',
				height: 'fit-content',
		  },
)

const CloseButton = styled(CircleIconButton)(({ theme }) => ({
	position: 'absolute',
	top: 12,
	right: 12,
}))

export type TAdminModalProps<T extends ModalKind> = {
	isOpen: boolean
	modalKind: T
	setModalState: (params: any) => void
	modalContent: JSX.Element
}

const AdminModal = <T extends ModalKind>({ isOpen, setModalState, modalKind, modalContent }: TAdminModalProps<T>) => {
	const handleClose = () => setModalState({ isOpen: false, modalKind })

	return (
		<StyledModal open={isOpen} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
			<ModalContent kind={modalKind}>
				<CloseButton onClick={handleClose} iconKey="x" variant={IconButtonVariant.NO_BG} />
				{modalContent}
			</ModalContent>
		</StyledModal>
	)
}

export default AdminModal

import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback } from 'react'

import { IconWrapper, InfoModalCard, Modal } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	submitText: {
		margin: '10px 0',
		textAlign: 'center',
	},
	text: {
		width: 300,
	},
}))

interface BlockUserPopupProps {
	isOpenModal: boolean
	handleCloseModal: () => void
}

const BlockUserPopup = ({ isOpenModal = false, handleCloseModal = () => {} }: BlockUserPopupProps) => {
	const classes = useStyles()
	const theme = useTheme()

	const onClose = useCallback(() => {
		handleCloseModal()
	}, [handleCloseModal])

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<InfoModalCard
				onClose={onClose}
				icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
			>
				<Typography className={classes.submitText}>Done.</Typography>
				<Typography className={classNames(classes.submitText, classes.text)}>
					You’ve blocked this user. They will no longer be able to see your profile or any of your app activity. They will not be notified.
					You can edit in settings.
				</Typography>
			</InfoModalCard>
		</Modal>
	)
}

export default BlockUserPopup

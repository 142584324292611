import { useCallback, useMemo, useState } from 'react'
import AboutCard from '@/components/UserProfile/AboutCard'
import { getUserRole, isStudent } from '@/utils/authHandlers'
import { useHistory } from 'react-router'
import UserInterestsCard from '@/components/UserProfile/UserInterestsCard'
import EditUserInterestsModal from '@/components/UserProfile/EditUserInterestsModal'
import { PATHS } from '@/constants'
import ProfileInfoCard from '@/components/UserProfile/ProfileInfoCard'
import { NetworkUserInfo, UserInfo } from '@/interfaces/user'
import { convertMemberships, ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import EditUserExperienceItemsModal from '@/components/UserProfile/UserExperienceItems/EditUserExperienceItemsModal'
import { isEngage } from '@/constants/configuration'

interface ProfileTabProps {
	userInfo?: UserInfo | NetworkUserInfo
	handleOpenEditModal?: () => void
	canEdit?: boolean
}

const ProfileTab = ({ handleOpenEditModal = () => {}, canEdit = false, userInfo }: ProfileTabProps) => {
	const { push } = useHistory()

	const info = useMemo(
		() => ({
			[ProfileInfoCardTypes.MEMBERSHIPS]: convertMemberships(userInfo?.memberships) || [],
			[ProfileInfoCardTypes.INTERNSHIPS]: userInfo?.userReportedInternship || [],
			[ProfileInfoCardTypes.EDUCATION_ABROAD]: userInfo?.userReportedStudyAbroad || [],
			[ProfileInfoCardTypes.RESEARCH]: userInfo?.userReportedResearch || [],
			[ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT]: userInfo?.userReportedAdditionalInvolvement || [],
			[ProfileInfoCardTypes.INTERESTS]: userInfo?.userReportedInterests || [],
		}),
		[userInfo],
	)

	const [isOpenEditExpModal, setIsOpenEditExpModal] = useState<ProfileInfoCardTypes | null>(null)
	const [isOpenEditInterestsModal, setIsOpenEditInterestsModal] = useState(false)

	const isStudentProfile = isStudent(getUserRole(userInfo?.appUserTypeId || 0))

	const handleOpenEditSection = useCallback(
		(type: ProfileInfoCardTypes) => () => {
			setIsOpenEditExpModal(type)
		},
		[],
	)

	const handleOpenEditInterestsSection = useCallback(() => setIsOpenEditInterestsModal(true), [])

	const handleCloseModal = () => {
		setIsOpenEditExpModal(null)
		setIsOpenEditInterestsModal(false)
	}

	const profileCards = useMemo(
		() =>
			isStudentProfile
				? [
						isEngage()
							? null
							: {
									type: ProfileInfoCardTypes.MEMBERSHIPS,
									handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.MEMBERSHIPS),
									items: info[ProfileInfoCardTypes.MEMBERSHIPS],
							  },

						{
							type: ProfileInfoCardTypes.INTERNSHIPS,
							handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.INTERNSHIPS),
							items: info[ProfileInfoCardTypes.INTERNSHIPS],
						},
						{
							type: ProfileInfoCardTypes.EDUCATION_ABROAD,
							handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.EDUCATION_ABROAD),
							items: info[ProfileInfoCardTypes.EDUCATION_ABROAD],
						},
						{
							type: ProfileInfoCardTypes.RESEARCH,
							handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.RESEARCH),
							items: info[ProfileInfoCardTypes.RESEARCH],
						},
						isEngage()
							? null
							: {
									type: ProfileInfoCardTypes.VOLUNTEER,
									handleOpenEditModal: () => push(PATHS.APP.VOLUNTEERS_EVENTS),
									items: userInfo?.shifts,
							  },
				  ]
				: [
						isEngage()
							? null
							: {
									type: ProfileInfoCardTypes.MEMBERSHIPS,
									handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.MEMBERSHIPS),
									items: info[ProfileInfoCardTypes.MEMBERSHIPS],
							  },
						{
							type: ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT,
							handleOpenEditModal: handleOpenEditSection(ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT),
							items: info[ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT],
						},
				  ],
		[handleOpenEditSection, info, isStudentProfile, push, userInfo?.shifts],
	)

	if (!userInfo) return null

	return (
		<>
			<EditUserExperienceItemsModal
				isOpen={!!isOpenEditExpModal}
				onClose={handleCloseModal}
				type={isOpenEditExpModal as ProfileInfoCardTypes}
				//@ts-ignore
				items={info[isOpenEditExpModal]}
			/>
			<EditUserInterestsModal isOpen={isOpenEditInterestsModal} onClose={handleCloseModal} items={info[ProfileInfoCardTypes.INTERESTS]} />
			<AboutCard canEdit={canEdit} userInfo={userInfo} handleOpenEditModal={handleOpenEditModal} />
			<UserInterestsCard
				canEdit={canEdit}
				items={info[ProfileInfoCardTypes.INTERESTS]}
				handleOpenEditModal={handleOpenEditInterestsSection}
			/>
			{profileCards.map((cardProps) => (cardProps ? <ProfileInfoCard key={cardProps.type} canEdit={canEdit} {...cardProps} /> : null))}
		</>
	)
}

export default ProfileTab

import React from 'react'
import { Link, LinkProps } from '@material-ui/core'

const DEFAULT_COUNTRY_CODE = '+1'

export interface IPhoneLinkProps extends Omit<LinkProps, 'href'> {
	plainNumber?: string
	countryCode?: string
	label: string
}

const PhoneLink: React.FC<IPhoneLinkProps> = ({ label, plainNumber, countryCode = DEFAULT_COUNTRY_CODE, ...linkProps }) => {
	const tel = `${countryCode}${plainNumber ? plainNumber : label.replace(/\D/g, '')}`
	return (
		<Link {...linkProps} href={`tel:${tel}`}>
			{label}
		</Link>
	)
}

export default PhoneLink

import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { createUploadErrorBreakpoint } from '@/utils/uploadErrorHandlers'
import { getLocalFileByUrl } from '@/utils/dragAndDrop'
import { AxiosResponse } from 'axios'
import * as http from '@/api/http'

export interface PhotoUpload {
	schoolId: number
	fileUrl: string
	imageName: string
	directory?: PhotoUploadDirectories
	isPrivateImage?: boolean
	options?: {
		compressImage?: boolean
		onError?: {
			uploadErrors: UploadErrors
			uploadErrorMessage: UploadErrorMessage
		}
	}
}

const uploadPhoto = async ({
	schoolId,
	fileUrl,
	imageName,
	directory = PhotoUploadDirectories.EVENT,
	isPrivateImage = false,
	options,
}: PhotoUpload): Promise<AxiosResponse<any, any>> => {
	const { onError, compressImage } = options ?? {}
	let clearBreakpoint = null

	if (onError) {
		clearBreakpoint = createUploadErrorBreakpoint({
			path: onError.uploadErrors,
			message: onError.uploadErrorMessage,
		})
	}

	const file = await getLocalFileByUrl(fileUrl, imageName, compressImage)
	const res = await http.uploadPhoto({ file, directory, isPrivateImage, schoolId })

	if (clearBreakpoint) clearBreakpoint()

	return res
}

export default uploadPhoto

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
	card: {
		minWidth: 370,
		backgroundColor: theme.palette.background.paper,
		borderRadius: 8,
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '30px 30px 30px 55px',

		[theme.breakpoints.down('sm')]: {
			minWidth: '250px',
		},
	},
	cardRoot: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	closeIcon: {
		color: theme.palette.text.secondary,
		cursor: 'pointer',
		alignSelf: 'flex-start',
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

interface InfoModalCardCardProps {
	icon: React.ReactNode
	onClose?: (e: any) => void
	children: React.ReactNode
	className?: string
}

export const InfoModalCard = ({ icon = null, children, onClose, className = '' }: InfoModalCardCardProps) => {
	const classes = useStyles()

	return (
		<div className={classes.card}>
			<div className={classNames(classes.cardRoot, className)}>
				<div className={classes.contentContainer}>
					{icon}
					{children}
				</div>
				<CloseIcon className={classes.closeIcon} onClick={onClose} />
			</div>
		</div>
	)
}

export default InfoModalCard

import { ValueOf } from 'type-fest'

export const AUTH_ERRORS = {
	FAILED_TO_EXCHANGE_CODE: {
		message: 'Auth failed: Code exchange request failed',
		code: 'failed_on_code_exchange',
	},
	FAILED_TO_RETRIEVE_USER_INFO: {
		message: 'Auth failed: Failed to retrieve userInfo from server',
		code: 'userinfo_failed',
	},
	MISSING_ACCOUNT: {
		message: 'Auth failed: Got 404 on usrInfo request',
		code: 'missing_account',
	},
	MISSING_EXTERNAL_ID: {
		message: 'Auth failed: ExternalId was missing in userInfo response',
		code: 'missing_external_id',
	},
	REFRESH_TOKEN_EXPIRED: {
		message: 'ReAuth failed: Refresh token has been expired',
		code: 'refresh_token_expired',
	},
} as const

export type TAuthError = ValueOf<typeof AUTH_ERRORS> & { details: any }

import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PATHS from '@/constants/paths'
import { getIsLoading, getUserInfo, getUserRole } from '@/store/auth'
import AppRoute from '@/interfaces/route'
import Processing from '@/pages/Auth/Processing'

const PrivateRoute = ({ component: Component, allowedRoles, isPrivate, isRestricted, analyticsRole, ...rest }: AppRoute) => {
	const isAuthenticating = useSelector(getIsLoading)
	const userRole = useSelector(getUserRole)
	const userInfo = useSelector(getUserInfo)

	return (
		<Route
			{...rest}
			render={(props) =>
				!isRestricted ? (
					!isPrivate || allowedRoles.includes(userRole) || (analyticsRole && !!userInfo.analyticsRole) ? (
						<Component {...props} />
					) : !isAuthenticating ? (
						<Redirect
							to={{
								pathname: PATHS.ROOT,
							}}
						/>
					) : (
						<Processing />
					)
				) : (
					<Redirect
						to={{
							pathname: PATHS.NOT_FOUND_ERROR,
						}}
					/>
				)
			}
		/>
	)
}

export default PrivateRoute

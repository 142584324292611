import { useCurrentUser } from '@/hooks/userHooks'
import { useAppDispatch } from '@/store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchPostsQuery } from '../api'
import { initFilters, selectFeedFilters } from '../feed/slice'
import useRealtimeFeed from './useRealtimeFeed'

const useFeed = () => {
	const dispatch = useAppDispatch()
	const currentUser = useCurrentUser()

	const filter = useSelector(selectFeedFilters)

	useSearchPostsQuery(
		{
			userId: currentUser.id,
			audienceScope: filter.audienceScope,
			campusAll: filter.includeAllCampuses,
			campusId: filter.campusId ?? undefined,
			tags: filter.tags?.length > 0 ? filter.tags : undefined,
			offset: filter.offset,
			usersAudience: filter.usersAudience,
		},
		{
			skip: !filter.isFiltersReady,
			refetchOnMountOrArgChange: true,
		},
	)
	useRealtimeFeed()

	useEffect(() => {
		dispatch(
			initFilters({
				campusId: currentUser.campusId,
			}),
		)
	}, [currentUser.campusId, dispatch])
}

export default useFeed

import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { TMentionEntity } from './types'

export type TMentionData = {
	name: string
	summary?: string
	entityType: TMentionEntity
	picture?: string | null
	id: string | number
	externalId?: string
}

export default class MentionTypeaheadOption extends MenuOption {
	constructor(public readonly data: TMentionData) {
		super(data.name)
	}
}

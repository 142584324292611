import last from 'lodash/last'
/**
 * Define base server url
 * @return Server base url
 */
const DEFAULT_BASE_URL = 'http://localhost:9000'

export const getBaseUrl = (basePath?: string): string => {
	const baseUrl = basePath || process.env.REACT_APP_BASE_API_URL || DEFAULT_BASE_URL
	return last(baseUrl) !== '/' ? `${baseUrl}/` : baseUrl
}

export const getAppUrl = (): string => {
	const baseUrl = window.location.origin || 'http://localhost:3000'

	const newUrl = last(baseUrl) === '/' ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl

	// Temporary fix for Cal Poly Now
	if (newUrl === 'https://cpnow.navengage.com') {
		return 'https://calpoly.navengage.com'
	} else {
		return newUrl
	}
}

export const getQueryParamByName = (name: string) => {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get(name) || null
}

export const includeUrl = (pathNames: string[]): boolean => {
	const { pathname } = window.location
	const splitPathName = pathname.split('/')

	return pathNames.some((path) => {
		if (pathname === path) {
			return true
		}

		const splitPath = path.split('/')

		if (splitPathName.length < splitPath.length) {
			return false
		}

		if (splitPath.includes(':id')) {
			const restoredPathName = splitPath.map((p, index) => (p === ':id' ? ':id' : splitPathName[index])).join('/')

			return restoredPathName === path
		}

		return false
	})
}

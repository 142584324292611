import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Chip, Typography } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import { TOption } from '@/store/types'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	card: ({ checked }: any) => ({
		width: '100%',
		backgroundColor: theme.palette.specialColors.transparent,
		borderRadius: 0,
		border: 'none',
		color: checked ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		fontSize: 16,
		'&>span': {
			display: 'flex',
			flexDirection: 'column',
		},
		'&:disabled': {
			opacity: 0.5,
		},
	}),
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textAlign: 'left',
		textTransform: 'none',
		alignItems: 'center',
		lineHeight: '20px',
		justifyContent: 'space-between',
		fontSize: '1em',
	},
	icon: {
		color: theme.palette.primary.main,
		paddingRight: 5,
	},
	description: {
		color: theme.palette.text.primary,
		paddingRight: 40,
	},
	subOptionsList: {
		width: '100%',
		display: 'flex',
		padding: '10px 0px',
		'& > .MuiChip-root': {
			marginRight: 15,
			textTransform: 'none',
			cursor: 'pointer',
		},
	},
}))

interface CardSelectOptionProps {
	label: string
	description?: string
	className?: string
	onClick: (v: any) => void
	subOptionClick?: (v: any) => void
	checked: boolean
	multiple?: boolean
	options?: Array<TOption>
	checkedOption?: number
	disableRipple?: boolean
	disabled?: boolean
}

const CardSelectOption = ({
	label,
	description = '',
	className = '',
	onClick,
	checked,
	multiple = false,
	options,
	checkedOption,
	subOptionClick,
	disableRipple,
	disabled = false,
}: CardSelectOptionProps) => {
	const classes = useStyles({ checked })
	const subOptionClickHandler = (v: number | string) => (e: any) => {
		e.preventDefault()
		subOptionClick(v)
	}
	return (
		<Button disabled={disabled} disableRipple={disableRipple} className={classNames(classes.card, className)} onClick={onClick}>
			<div className={classes.contentContainer}>
				<Typography>{label}</Typography>
				{checked && <IconWrapper iconKey="checkCircle" weight="fill" className={classes.icon} />}
				{!checked && multiple && <AddIcon className={classes.icon} />}
			</div>
			<div className={classes.contentContainer}>
				<Typography className={classes.description}>{description}</Typography>
			</div>
			{checked && options && (
				<div className={classes.subOptionsList}>
					{options.map((option) => (
						<Chip
							key={option.value}
							variant={option.value === checkedOption ? 'default' : 'outlined'}
							color={option.value === checkedOption ? 'primary' : 'default'}
							label={option.label}
							onClick={!disabled ? subOptionClickHandler(option.value) : null}
						/>
					))}
				</div>
			)}
		</Button>
	)
}

export default CardSelectOption

import { makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		backgroundColor: theme.colors.pink[500],
		padding: '5px 10px',
		borderRadius: 5,
		color: theme.palette.common.white,
	},
}))

interface BadgeProps {
	className?: string
	children: React.ReactNode
}

const Badge = ({ children, className }: BadgeProps) => {
	const classes = useStyles()

	return <Typography className={classNames(classes.root, className)}>{children}</Typography>
}

export default Badge

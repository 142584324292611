import { useAppDispatch, useAppSelector } from '@/store'
import { useCallback, useEffect, useState } from 'react'
import { useGetOnboardingEntitiesQuery } from '../api'
import { onboardingSlice, selectAll, selectCanLoadMore, selectIds, selectIsLoading, selectIsUninitialized } from '../slice'
import { GetOnboardingEntitiesParams, TOnboardingEntitiesParams } from '../types/queryParams/GetOnboardingEntitiesParams'

const useOnboardingEntities = (params: TOnboardingEntitiesParams, defaultTake: number = 15) => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(onboardingSlice.actions.clearAdapterState())
	}, [dispatch])
	const ids = useAppSelector(selectIds)
	const canLoadMore = useAppSelector(selectCanLoadMore)
	const [queryParams, setQueryParams] = useState<GetOnboardingEntitiesParams | null>({
		...params,
		skip: 0,
		take: defaultTake,
	})

	useEffect(() => {
		setQueryParams((prev) => ({
			...prev,
			skip: 0,
			take: defaultTake,
			search: params.search ?? undefined,
		}))
	}, [defaultTake, params.search])

	const entities = useAppSelector(selectAll)
	useGetOnboardingEntitiesQuery(queryParams, { refetchOnMountOrArgChange: true, skip: !queryParams })
	const isUninitialized = useAppSelector(selectIsUninitialized)
	const isLoading = useAppSelector(selectIsLoading)
	const loadMore = useCallback(() => {
		setQueryParams((prev) => ({
			...prev,
			skip: prev.skip + prev.take,
		}))
	}, [])
	return {
		ids,
		entities,
		isLoading,
		loadMore,
		canLoadMore,
		isUninitialized,
	}
}

export default useOnboardingEntities

import ColorButton from '@/components/Buttons/ColorButton'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

import Card from '@/components/IndividualPage/Card'
import ShiftCheckBox from '@/components/IndividualPage/ShiftCheckBox'
import { useAppDispatch } from '@/store'
import { updateEventVolunteerShifts } from '@/store/events'
import { isUserSubmittedToShift } from '@/utils/events'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	shiftsCheckBoxes: {
		margin: '2vh 0',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		margin: '2vh 0',
		borderTop: `solid 1px ${theme.palette.divider}`,
	},
	submitButton: {
		width: 200,
		height: 50,
	},
	infoText: {
		margin: '2vh 0',
	},
}))

interface EditShiftsCardProps {
	eventId: string
	shifts: any[]
	userId: number
	onSubmitShifts: () => void
}

const EditShiftsCard = ({ eventId, shifts = [], userId, onSubmitShifts }: EditShiftsCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const [checkBoxes, setCheckBoxes] = useState(shifts)

	const handleCheckBoxChange = (id: string) =>
		setCheckBoxes(checkBoxes.map((checkBox) => (checkBox.id === id ? { ...checkBox, checked: !checkBox.checked } : checkBox)))

	const disableSignUp = !checkBoxes.some((check) => check.checked)

	const handleClickSubmitShifts = () => {
		dispatch(
			updateEventVolunteerShifts({
				eventId,
				submittedIds: checkBoxes.filter((check) => check.checked).map((check) => check.id),
			}),
		)
		onSubmitShifts()
	}

	const selectedCount = checkBoxes.filter((check) => check.checked).length

	useEffect(() => {
		setCheckBoxes(shifts.map((shift) => (isUserSubmittedToShift(shift, userId) ? { ...shift, checked: true } : shift)))
	}, [shifts, userId])

	return (
		<Card title="Sign Up" id="EditShiftsSection">
			<Typography>You can sign up for multiple shifts.</Typography>
			<div className={classes.shiftsCheckBoxes}>
				{checkBoxes.map((checkBox, index) => (
					<ShiftCheckBox
						key={checkBox.id}
						shiftNumber={index}
						userId={userId}
						onClick={handleCheckBoxChange}
						shift={checkBox}
						checked={checkBox.checked}
					/>
				))}
			</div>
			<div className={classes.buttonsContainer}>
				<Typography className={classes.infoText}>{`${selectedCount} Shift${selectedCount !== 1 ? 's' : ''} Selected`}</Typography>
				<ColorButton className={classes.submitButton} onClick={handleClickSubmitShifts} disabled={disableSignUp}>
					Done
				</ColorButton>
			</div>
		</Card>
	)
}

export default EditShiftsCard

import { Theme, makeStyles } from '@material-ui/core'
import { STYLES } from '@/constants'

export interface StyleProps {
	primary: boolean
	skeleton?: boolean
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	card: {
		borderRadius: 10,
		cursor: 'pointer',
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(1.5% + 0px)',
		height: '100%',
		width: '97%',
	},
	cardHeader: ({ skeleton }) => ({
		position: 'relative',
		height: '68%',
		aspectRatio: !skeleton ? '16/9' : 'initial',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		padding: 0,
		background: !skeleton ? `linear-gradient(0deg, rgba(154,154,154,1) 0%, rgba(0,0,0,1) 94%)` : 'none',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	}),
	cardContent: {
		height: '23%',
		padding: '3%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		fontSize: '0.9375em',
	},
	cardContentTitle: {
		fontWeight: 700,
		fontSize: 22,
	},
	cardContentSubTitle: {
		fontSize: 15,
		color: theme.palette.text.secondary,
	},
	cardContentTitleContainer: ({ primary }) => ({
		width: primary ? STYLES.FILL_AVAILABLE_WIDTH : '57%',
		fontSize: 22,
		boxSize: 'border-box',
		paddingRight: '10px',
	}),
	cardContentInfo: ({ primary }) => ({
		height: '100%',
		width: primary ? '45%' : STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		'&>p': {
			fontSize: '15px !important',
		},
	}),
	cardContentFooter: {
		width: '55%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	image: {
		width: '100%',
		aspectRatio: '16/9',
		objectFit: 'cover',
	},
	gradientImage: {
		borderRadius: 10,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		position: 'absolute',
		background: `linear-gradient(0deg, rgba(154,154,154,0) 40%, rgba(0,0,0,0.6) 90%)`,
	},
	imageButtonsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'calc(100% - 6%)',
		padding: '3%',
		position: 'absolute',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	feedbackButton: {
		marginLeft: 'auto',
		height: 50,
		minWidth: 50,
	},
	description: ({ primary }) => ({
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: primary ? 'flex-end' : 'flex-start',
	}),
	rootContainer: {
		width: '100%',
		paddingTop: 'calc(80%)',
		position: 'relative',
	},
	fullWidthButton: {
		height: 50,
		width: 'calc(100% - 60px)',
		transform: 'initial',
	},
	shareButtonSkeleton: {
		width: 50,
		height: 50,
		transform: 'initial',
	},
}))

export enum EditableOnboardingSteps {
	OPPORTUNITIES = 'opportunities',
	JOIN_GROUPS = 'joinGroups',
	FOLLOW_ORGS = 'followOrgs',
	FOLLOW_ACADEMIC_ORGS = 'followAcademicOrgs',
	EXPERIENCE = 'experience',
	INTERESTS = 'interests',
	CONNECTIONS = 'connections',
	MAJORS = 'majors',
	ACADEMIC_COLLEGES = 'academicColleges',
	AUTO_CONNECT_USERS = 'autoConnectUsers',
	AUTO_FOLLOW_ORGANIZATIONS = 'autoFollowOrganizations',
}

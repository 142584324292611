import TypedStorageManager from '@/utils/storageManager/TypedStorageManager'
import IAuthStorageSchema from '../types/IAuthStorageSchema'

const storeKeys: Array<keyof IAuthStorageSchema> = [
	'accessToken',
	'accessTokenExpiration',
	'refreshToken',
	'currentUserId',
	'tokenType',
	'refreshTokenExpiration',
	'schoolId',
]

class AuthStorage extends TypedStorageManager<IAuthStorageSchema> {
	public clear(): void {
		super.clear(storeKeys)
	}
}

const authStorage = new AuthStorage(sessionStorage)

export default authStorage

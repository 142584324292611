import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import { STYLES } from '@/constants'
import NavigationButton, { NavigationButtonClassNames, SideBarNavigation } from '@/components/Buttons/NavigationButton'
import StickyContainer from './StickyContainer'

const useStyles = makeStyles((theme) => ({
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: 30,
		padding: 15,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.neutral?.light,
	},
	headerContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		padding: '0 0 15px 0',
		margin: '15px 15px',
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
	navButtonIcon: {
		width: 40,
		minWidth: 40,
		marginRight: 7,
	},
}))

export interface StaticSideBarProps {
	classnames?: {
		root?: string
		stickyContainer?: string
	}
	navButtonClassNames?: NavigationButtonClassNames
	navigation: SideBarNavigation[]
	onClickNavigation: (path: string) => void
	header?: React.ReactNode
}

const StaticSideBar = ({ navigation, onClickNavigation, header, navButtonClassNames, classnames }: StaticSideBarProps) => {
	const classes = useStyles()

	return (
		<StickyContainer className={classnames?.stickyContainer}>
			<div className={classNames(classnames?.root, classes.container)}>
				{header && <div className={classes.headerContainer}>{header}</div>}
				{navigation.map((nav) => (
					<NavigationButton
						classnames={{
							icon: classes.navButtonIcon,
							...navButtonClassNames,
						}}
						key={nav.path}
						onClick={onClickNavigation}
						navigation={nav}
					/>
				))}
			</div>
		</StickyContainer>
	)
}

export default StaticSideBar

import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import AcademicLogo from '@/components/icons/png/PathBuilder/EventsQ1/Academic.png'
import ArtLogo from '@/components/icons/png/PathBuilder/EventsQ1/Art.png'
import AthleticsLogo from '@/components/icons/png/PathBuilder/EventsQ1/Athletics.png'
import CivicLogo from '@/components/icons/png/PathBuilder/EventsQ1/Civic.png'
import CulturalLogo from '@/components/icons/png/PathBuilder/EventsQ1/Cultural.png'
import DanceLogo from '@/components/icons/png/PathBuilder/EventsQ1/Dance.png'
import EntrepreneurshipLogo from '@/components/icons/png/PathBuilder/EventsQ1/Entrepreneurship.png'
import FilmLogo from '@/components/icons/png/PathBuilder/EventsQ1/Film.png'
import FoodLogo from '@/components/icons/png/PathBuilder/EventsQ1/Food.png'
import FundraisingLogo from '@/components/icons/png/PathBuilder/EventsQ1/Fundraising.png'
import GovernmentLogo from '@/components/icons/png/PathBuilder/EventsQ1/Government.png'
import MusicLogo from '@/components/icons/png/PathBuilder/EventsQ1/Music.png'
import NetworkingLogo from '@/components/icons/png/PathBuilder/EventsQ1/Networking.png'
import PhilanthropyLogo from '@/components/icons/png/PathBuilder/EventsQ1/Philanthropy.png'
import PhysicalActivityLogo from '@/components/icons/png/PathBuilder/EventsQ1/PhysicalActivity.png'
import PoliticalLogo from '@/components/icons/png/PathBuilder/EventsQ1/Political.png'
import ProfessionalLogo from '@/components/icons/png/PathBuilder/EventsQ1/Professional.png'
import ReligionLogo from '@/components/icons/png/PathBuilder/EventsQ1/Religion.png'
import ScienceLogo from '@/components/icons/png/PathBuilder/EventsQ1/Science.png'
import ServiceLogo from '@/components/icons/png/PathBuilder/EventsQ1/Service.png'
import SocialLogo from '@/components/icons/png/PathBuilder/EventsQ1/Social.png'
import SustainabilityLogo from '@/components/icons/png/PathBuilder/EventsQ1/Sustainability.png'
import TechnologyLogo from '@/components/icons/png/PathBuilder/EventsQ1/Technology.png'
import TheatreLogo from '@/components/icons/png/PathBuilder/EventsQ1/Theatre.png'
import TravelLogo from '@/components/icons/png/PathBuilder/EventsQ1/Travel.png'
import VolunteerLogo from '@/components/icons/png/PathBuilder/EventsQ1/Volunteer.png'
import WellnessLogo from '@/components/icons/png/PathBuilder/EventsQ1/Wellness.png'
import { ImageCheckBox } from '@/components/widgets'
import { getArrayMinSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 40px)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100px)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		margin: '0 20px',
	},
}))

const options = [
	{
		label: 'Academic',
		value: 'Academic',
		icon: AcademicLogo,
	},
	{
		label: 'Art',
		value: 'Art',
		icon: ArtLogo,
	},
	{
		label: 'Athletics',
		value: 'Athletics',
		icon: AthleticsLogo,
	},
	{
		label: 'Civic',
		value: 'Civic',
		icon: CivicLogo,
	},
	{
		label: 'Cultural',
		value: 'Cultural',
		icon: CulturalLogo,
	},
	{
		label: 'Dance',
		value: 'Dance',
		icon: DanceLogo,
	},
	{
		label: 'Film',
		value: 'Film',
		icon: FilmLogo,
	},
	{
		label: 'Entrepreneurship',
		value: 'Entrepreneurship',
		icon: EntrepreneurshipLogo,
	},
	{
		label: 'Food',
		value: 'Food',
		icon: FoodLogo,
	},
	{
		label: 'Fundraising',
		value: 'Fundraising',
		icon: FundraisingLogo,
	},
	{
		label: 'Government',
		value: 'Government',
		icon: GovernmentLogo,
	},
	{
		label: 'Music',
		value: 'Music',
		icon: MusicLogo,
	},
	{
		label: 'Networking',
		value: 'Networking',
		icon: NetworkingLogo,
	},
	{
		label: 'Philanthropy',
		value: 'Philanthropy',
		icon: PhilanthropyLogo,
	},
	{
		label: 'Physical Activity',
		value: 'Physical Activity',
		icon: PhysicalActivityLogo,
	},
	{
		label: 'Political',
		value: 'Political',
		icon: PoliticalLogo,
	},
	{
		label: 'Professional',
		value: 'Professional',
		icon: ProfessionalLogo,
	},
	{
		label: 'Religion',
		value: 'Religion',
		icon: ReligionLogo,
	},
	{
		label: 'Science',
		value: 'Science',
		icon: ScienceLogo,
	},
	{
		label: 'Service',
		value: 'Service',
		icon: ServiceLogo,
	},
	{
		label: 'Social',
		value: 'Social',
		icon: SocialLogo,
	},
	{
		label: 'Sustainability',
		value: 'Sustainability',
		icon: SustainabilityLogo,
	},
	{
		label: 'Technology',
		value: 'Technology',
		icon: TechnologyLogo,
	},
	{
		label: 'Theatre',
		value: 'Theatre',
		icon: TheatreLogo,
	},
	{
		label: 'Travel',
		value: 'Travel',
		icon: TravelLogo,
	},
	{
		label: 'Volunteer',
		value: 'Volunteer',
		icon: VolunteerLogo,
	},
	{
		label: 'Wellness',
		value: 'Wellness',
		icon: WellnessLogo,
	},
]

const Question1 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 5, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					title="What types of events do you like?"
					subTitle="Choose at least 5"
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									options.map((item) => (
										<ImageCheckBox
											key={item.value}
											label={item.label}
											value={item.value}
											icon={item.icon}
											onChange={handleSelectItem}
											checked={isChecked(item.value)}
										/>
									))
								}
							/>
						)}
					/>
				</StepperCard>
			}
		/>
	)
}

export default Question1

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import Skeleton from '@material-ui/lab/Skeleton/Skeleton'

const useStyles = makeStyles((theme) => ({
	itemContainer: ({ cursorPointer }: any) => ({
		display: 'flex',
		margin: '0.5vh 0',
		alignItems: 'center',
		cursor: cursorPointer ? 'pointer' : 'default',
	}),
	iconContainer: {
		width: 50,
		height: 50,
		marginRight: 13,
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	blueText: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	label: {
		fontWeight: 600,
	},
	subLabel: {
		color: theme.palette.text.secondary,
		fontWeight: 600,
	},
}))

interface ItemAboutRowProps {
	loading?: boolean
	className?: string
	header?: string
	label: string
	subLabel?: string
	icon: React.ReactNode
	onClick?: (e: any) => void
}

const ItemAboutRow = ({ className = '', header, label, subLabel, icon, onClick, loading }: ItemAboutRowProps) => {
	const classes = useStyles({ cursorPointer: !!onClick })

	const handleClick = (e: any) => {
		if (onClick) onClick(e)
	}

	return (
		<div className={classNames(classes.itemContainer, className)} onClick={handleClick}>
			<div className={classes.iconContainer}>{icon}</div>
			<div>
				{loading ? (
					<Typography className={classes.label}>
						<Skeleton width={200} />
					</Typography>
				) : (
					<>
						<Typography className={classes.blueText}>{header}</Typography>
						<Typography className={classes.label}>{label}</Typography>
						<Typography className={classes.subLabel}>{subLabel}</Typography>
					</>
				)}
			</div>
		</div>
	)
}

export default ItemAboutRow

const STYLES = {
	MAIN_HEADER_HEIGHT: 8.5,
	LANDING_HEADER_HEIGHT: 15,
	LANDING_HEADER_HEIGHT_MOBILE: 12,
	LANDING_FOOTER_HEIGHT: 16,
	HEADER_HEIGHT: 8.5,
	FOOTER_HEIGHT: 16,
	FILL_AVAILABLE_WIDTH: '-webkit-fill-available; width: -moz-available',
	FILTER: {
		XL: 341,
		LG: 320,
		SM: 300,
	},
	CONNECTION_REQUESTS_BAR: {
		XL: 450,
		LG: 370,
		MD: 320,
		SM: 300,
	},
	SIDEBAR: {
		COLLAPSED: 90,
		OPENED: 290,
	},
	MESSAGES_BAR: {
		XL: 450,
		LG: 370,
		MD: 320,
		SM: 300,
	},
	GROUP_BAR: {
		XL: 450,
		LG: 370,
		MD: 320,
		SM: 300,
	},
	BOX_SHADOW: {
		'-webkit-box-shadow': '0px 0px 10px 0px rgba(34, 60, 80, 0.09)',
		'-moz-box-shadow': '0px 0px 10px 0px rgba(34, 60, 80, 0.09)',
		boxShadow: '0px 0px 10px 0px rgba(34, 60, 80, 0.09)',
	},
	BOX_SHADOW_DOWN: {
		'-webkit-box-shadow': '0px 4px 5px 0px rgba(0,0,0,0.15)',
		'-moz-box-shadow': '0px 4px 5px 0px rgba(0,0,0,0.15)',
		boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.15)',
	},
}

export default STYLES

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ColorButton, CommonModalCard, Modal } from '@/components'
import SuccessPopup from '@/components/SuccessPopup'
import { TruncateTextWithTooltip } from '@/components/Text/TruncateTextWithTooltip'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { getSearchedGroup, joinGroupRequest } from '@/store/groups'
import { getCountLabel } from '@/utils/common'
import { GroupChatTypes } from '@navengage/sen-types'
import Badge from './Badge'
import GroupImage from './GroupImage'

const useStyles = makeStyles((theme) => ({
	rootCard: {
		maxWidth: 500,
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		minHeight: '50vh',
		overflowY: 'auto',
		padding: 30,
		paddingBottom: 0,
	},
	flexContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '100%',
	},
	gridContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'grid',
		gridTemplateColumns: `100%`,
		justifyContent: 'space-evenly',
		gridGap: 0,
	},
	groupImageContainer: {
		width: '35%',
	},
	groupNameContainer: {
		textAlign: 'center',

		margin: '20px 0',
		maxWidth: '80%',
	},
	groupName: {
		color: theme.palette.text.primary,
		fontWeight: 700,
	},
	footer: {
		borderTop: 'none',
	},
	description: {
		maxWidth: '100%',
		textAlign: 'center',
	},
	descriptionContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	mainInfo: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	joinButton: {
		width: 200,
		height: 42,
	},
	requestedLabel: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
		borderRadius: 5,
		minWidth: 150,
		height: 38,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'not-allowed',
	},
}))

interface JoinGroupPopupProps {
	groupId: string
	onClose: () => void
}

const JoinGroupPopup = ({ groupId, onClose }: JoinGroupPopupProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const [showSuccessPopup, setShowSuccessPopup] = useState(false)

	const data = useSelector(getSearchedGroup(groupId))

	const handleToggleSuccessPopup = () => setShowSuccessPopup((prev) => !prev)

	const handleJoinGroup = () => {
		if (data?.type !== GroupChatTypes.Public) {
			handleToggleSuccessPopup()
		}
		dispatch(
			joinGroupRequest({
				id: data!.id,
				type: data!.type,
			}),
		)
	}

	const isOpen = !!groupId && !!data

	return (
		<>
			<SuccessPopup
				isOpen={showSuccessPopup}
				onClose={handleToggleSuccessPopup}
				title="Member request sent!"
				submittedMessage={['You`ll be notified once admin', 'approves your request to join.']}
			/>
			<Modal isOpen={isOpen} onClose={onClose}>
				<CommonModalCard
					classnames={{
						card: classes.rootCard,
						footer: classes.footer,
					}}
					title="Join Group Chat"
					onClose={onClose}
					footerContent={
						data?.isRequested ? (
							<div className={classes.requestedLabel}>Requested</div>
						) : (
							<ColorButton className={classes.joinButton} onClick={handleJoinGroup}>
								Join Group Chat
							</ColorButton>
						)
					}
					showFooter
				>
					<div className={classes.contentContainer}>
						<div className={classes.gridContainer}>
							<div className={classes.mainInfo}>
								<div className={classes.groupImageContainer}>
									<GroupImage url={data?.thumbnail} groupName={data?.name} borderRadius={7} placeholderSize={2.5} openImageByClick />
								</div>
								<div className={classes.groupNameContainer}>
									<TruncateTextWithTooltip lines={1}>
										<Typography className={classes.groupName} component="span">
											{data?.name}
										</Typography>
									</TruncateTextWithTooltip>
								</div>
								<Badge>{getCountLabel(data?.memberCount || 0, 'member')}</Badge>
							</div>
							<div className={classes.descriptionContainer}>
								<TruncateTextWithTooltip lines={4}>
									<Typography component="span" className={classes.description}>
										{data?.description}
									</Typography>
								</TruncateTextWithTooltip>
							</div>
						</div>
					</div>
				</CommonModalCard>
			</Modal>
		</>
	)
}

export default JoinGroupPopup

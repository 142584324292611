import CardLayout from '@/components/CardLayout'
import { PATHS } from '@/constants'
import { useAppDispatch } from '@/store'
import { getSignUpFormInfo, getUserInfo, getUserRole, setSignUpForm } from '@/store/auth'
import { isStudent as checkIsStudent, userHasSignUpData } from '@/utils/authHandlers'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { RequireExactlyOne } from 'type-fest'
import { ROLE } from '../../constants/'
import { getLogger } from '../../init/DIContainer'

// Student
import ExperiencesStep from '@/pages/SignUp/StudentSteps/ExperiencesStep'
import { FollowAcademicUnitsStep } from '@/pages/SignUp/StudentSteps/FollowAcademicUnitsStep'
import { FollowOrganizationsStep } from '@/pages/SignUp/StudentSteps/FollowOrganizationsStep'
import IntendedGraduationDateStep from '@/pages/SignUp/StudentSteps/IntendedGraduationDateStep'
import { InterestsStep } from '@/pages/SignUp/StudentSteps/interestsStep'
import { JoinGroupChatsStep } from '@/pages/SignUp/StudentSteps/JoinGroupChatsStep'
import OpportunitiesStep from '@/pages/SignUp/StudentSteps/OpportunitiesStep'

// Faculty / Staff
import CampusStep from '@/pages/SignUp/CampusStep'
import FacultyPositionStep from '@/pages/SignUp/FacultySteps/FacultyPositionStep'

// Both
import { selectShowCampuses } from '@/features/campus/slice'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import HowDidYouHearStep from '@/pages/SignUp/HowDidYouHearStep'
import UserNameStep from '@/pages/SignUp/UserNameStep'
import UserTypeStep from '@/pages/SignUp/UserTypeStep'
import { OnboardingSteps, TOnboarding } from '@navengage/sen-shared-assets'
import { ErrorCard } from '../../components/error/ErrorCard'
import { AcademicCollegeStep } from './AcademicCollegeStep'
import { SignupStepsContext } from './SignupStepsContext'
import { MajorsStep } from './StudentSteps/MajorsStep'
import { UserConnectionsStep } from './StudentSteps/UserConnectionsStep'
import UserPhotoStep from './UserPhotoStep'

export interface SignUpStepsProps {
	onClickNext: () => void
}

const SIGN_UP_STEPS_MAPPER: Record<Exclude<OnboardingSteps, OnboardingSteps.PLEDGE>, any> = {
	campus: CampusStep,
	experience: ExperiencesStep,
	followAcademicOrgs: FollowAcademicUnitsStep,
	followOrgs: FollowOrganizationsStep,
	graduation: IntendedGraduationDateStep,
	howDidYouHear: HowDidYouHearStep,
	interests: InterestsStep,
	joinGroups: JoinGroupChatsStep,
	opportunities: OpportunitiesStep,
	position: FacultyPositionStep,
	profilePic: UserPhotoStep,
	userName: UserNameStep,
	userType: UserTypeStep,
	connections: UserConnectionsStep,
	majors: MajorsStep,
	academicColleges: AcademicCollegeStep,
}

const DEFAULT_STUDENT_STEPS: Array<`${OnboardingSteps}`> = [
	'userType',
	'userName',
	'profilePic',
	'followAcademicOrgs',
	'graduation',
	'interests',
	'experience',
	'followAcademicOrgs',
	'connections',
	'joinGroups',
	'opportunities',
	'howDidYouHear',
]

const DEFAULT_FACULTY_STEPS: Array<`${OnboardingSteps}`> = ['userType', 'userName', 'profilePic', 'campus', 'position', 'howDidYouHear']

const getFilteredSteps = (
	steps: Array<`${OnboardingSteps}`>,
	defaultConfig: Array<`${OnboardingSteps}`>,
	onboardingConfig: TOnboarding,
	hasCampuses: boolean,
) => {
	const finalSteps = steps || defaultConfig
	return finalSteps
		.filter((step: `${OnboardingSteps}`) => {
			if (step === 'campus' && !hasCampuses) {
				return false
			}
			return !onboardingConfig[step].disabled
		})
		.map((step) => SIGN_UP_STEPS_MAPPER[step])
}

const sentry = getLogger()

const SignUpSteps = () => {
	const history = useHistory()
	const dispatch = useAppDispatch()

	const userRole = useSelector(getUserRole)
	const userInfo = useSelector(getUserInfo)
	const formData = useSelector(getSignUpFormInfo)
	const showCampuses = useSelector(selectShowCampuses)
	const [currentStep, setCurrentStep] = useState(0)
	const [navigationMiddleware, setNavigationMiddleware] = useState(null)

	const selectedUserRole = formData.patches.userTypeChoices?.find(({ checked }) => !!checked)?.name as ROLE // @TODO: Update role type in sen-shared-assets

	const finalUserRole = selectedUserRole || userRole
	const isStudent = checkIsStudent(finalUserRole)
	const {
		features: {
			onboarding: {
				config: { facultySteps: facultyConfigSteps, studentSteps: studentConfigSteps, ...onboardingConfig },
			},
		},
	} = useSchoolConfig()

	const cleanedSteps = useMemo(() => {
		const stepsConfig: [Array<`${OnboardingSteps}`>, Array<`${OnboardingSteps}`>] = isStudent
			? [studentConfigSteps, DEFAULT_STUDENT_STEPS]
			: [facultyConfigSteps, DEFAULT_FACULTY_STEPS]
		const steps = getFilteredSteps(...stepsConfig, onboardingConfig, showCampuses)
		const filteredSteps = !finalUserRole ? steps.filter((e) => e !== UserTypeStep) : steps

		return filteredSteps
	}, [facultyConfigSteps, finalUserRole, showCampuses, isStudent, onboardingConfig, studentConfigSteps])

	const CurrentStepComponent = cleanedSteps[currentStep]

	const runStepNavigationMiddleware = useCallback(
		({
			navigation,
			step,
			data,
		}: {
			navigation: typeof navigationMiddleware
			step: number
			data: RequireExactlyOne<{ isBack?: boolean; isForward?: boolean }, 'isBack' | 'isForward'>
		}) => {
			sentry.addBreadcrumb({
				type: 'navigation',
				category: 'signup',
				data: {
					from: CurrentStepComponent?.name,
					to: data.isBack
						? cleanedSteps[currentStep - 1]?.name ?? 'beginning'
						: cleanedSteps?.[currentStep + 1]?.name ?? 'terms and conditions',
				},
			})
			navigation?.[step]?.(data)
		},
		[CurrentStepComponent, cleanedSteps, currentStep],
	)

	const onClickBack = useCallback(() => {
		runStepNavigationMiddleware({ navigation: navigationMiddleware, step: currentStep, data: { isBack: true } })

		const newStep = currentStep > 0 ? currentStep - 1 : 0

		if (newStep < cleanedSteps.length) {
			setCurrentStep(newStep)
		} else {
			history.push(PATHS.SIGN_UP.TERMS)
		}
	}, [runStepNavigationMiddleware, navigationMiddleware, currentStep, cleanedSteps.length, history])

	const onClickNext = useCallback(() => {
		runStepNavigationMiddleware({
			navigation: navigationMiddleware,
			step: currentStep,
			data: { isForward: true },
		})

		const newStep = currentStep + 1
		if (newStep < cleanedSteps.length) {
			setCurrentStep(newStep)
		} else {
			history.push(PATHS.SIGN_UP.TERMS)
		}
	}, [runStepNavigationMiddleware, navigationMiddleware, currentStep, cleanedSteps.length, history])

	const patchFormData = useCallback(
		(body) => {
			dispatch(setSignUpForm(body))
		},
		[dispatch],
	)

	const handleSetNavigationMiddleware = useCallback(
		(middleware) => {
			setNavigationMiddleware({ [currentStep]: middleware })
		},
		[currentStep],
	)

	const progress = useMemo(() => {
		return cleanedSteps.reduce(
			(map, StepComponent) => {
				let stepIsCompleted = false
				try {
					stepIsCompleted = StepComponent.checkIsCompleted(formData)
				} catch (err) {
					// Fail silently
				}

				map.total += 1
				map.completed += stepIsCompleted ? 1 : 0
				return map
			},
			{ total: 0, completed: 0, currentStep },
		)
	}, [formData, cleanedSteps, currentStep])

	if (userHasSignUpData(userRole, userInfo)) {
		return <Redirect to={PATHS.SIGN_UP.TERMS} />
	}

	return (
		<SignupStepsContext.Provider
			value={{
				currentStep,
				formData,
				userInfo,
				selectedUserType: finalUserRole,
				isStudent,
				progress,
				setNavigationMiddleware: handleSetNavigationMiddleware,
				patchFormData,
				onClickBack,
				onClickNext,
			}}
		>
			<CardLayout>
				<sentry.ErrorBoundary fallback={<ErrorCard />}>
					<CurrentStepComponent key={isStudent ? 'student' : 'staff'} {...{ userInfo, onClickBack, onClickNext }} />
				</sentry.ErrorBoundary>
			</CardLayout>
		</SignupStepsContext.Provider>
	)
}

export default SignUpSteps

import { UserInfo } from '@/interfaces/user'
import { Grid } from '@material-ui/core'
import React from 'react'

import AppUsage from './AppUsage'
import SelfReportedProfileItems from './profileItems/UserReportedProfileItemsStats'
import UserInfoRow from './UserInfoRow'

export type IUserProfileStatsProps = {
	userId: UserInfo['id']
	appUserTypeId: number
}

const UserProfileStats: React.FC<IUserProfileStatsProps> = ({ userId, appUserTypeId }) => {
	return (
		<UserInfoRow>
			<Grid container spacing={2}>
				<Grid sm={6} item>
					<AppUsage userId={userId} />
				</Grid>
				<Grid sm={6} item>
					<SelfReportedProfileItems appUserTypeId={appUserTypeId} userId={userId} />
				</Grid>
			</Grid>
		</UserInfoRow>
	)
}

export default UserProfileStats

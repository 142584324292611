import React, { forwardRef } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import classNames from 'classnames'

interface StyleProps {
	value: boolean
	labelPosition?: 'bottom' | 'end' | 'start'
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	checkBox: ({ value }) => ({
		color: theme.palette.text.secondary,
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: theme.palette.primary.main,
		},
		'&.MuiCheckbox-colorPrimary.Mui-disabled': {
			color: theme.palette.grey[200],
			'&>span': {
				'&>div': {
					backgroundColor: value ? theme.palette.grey[200] : theme.palette.background.default,
					//@TODO: convert color and find matching existing color
					border: 'solid 5px #00c4ff05',
				},
			},
		},
		padding: 0,
		fontSize: 12,
	}),
	checkBoxLabel: ({ labelPosition = 'bottom' }) => ({
		position: 'relative',
		color: theme.palette.text.secondary,
		margin: 0,
		'&>span:last-child': {
			fontSize: 13,
			marginLeft: labelPosition === 'end' ? 10 : 0,
			marginRight: labelPosition === 'start' ? 10 : 0,
		},
	}),
	itemContainer: ({ value }) => ({
		backgroundColor: value ? theme.palette.primary.main : theme.palette.neutral?.main,
		width: 35,
		height: 15,
		borderRadius: 30,
		border: `solid 5px ${value ? theme.palette.primary.main : theme.palette.border?.bold}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: value ? 'right' : 'left',
	}),
	checkCircle: {
		backgroundColor: theme.palette.common.white,
		width: 15,
		height: 15,
		borderRadius: '50%',
	},
}))

interface ToggleCheckBoxProps extends StyleProps {
	className?: string
	disabled?: boolean
	showLabel?: boolean
	onChange?: (v: React.ChangeEvent<HTMLInputElement>) => void
	label?:
		| {
				checked: string
				unchecked: string
		  }
		| string
}

export const Toggle = forwardRef<any, ToggleCheckBoxProps>(
	({ className = '', value = false, disabled = false, showLabel = true, onChange, labelPosition = 'bottom', label }, ref) => {
		const classes = useStyles({ value, labelPosition })

		const getLabel = () => {
			if (!showLabel) return undefined
			if (typeof label === 'string') return label

			if (value) return label ? label.checked : 'On'
			else return label ? label.unchecked : 'Off'
		}

		const checkItem = (
			<div className={classes.itemContainer}>
				<div className={classes.checkCircle}></div>
			</div>
		)

		return (
			<FormControlLabel
				ref={ref}
				value={labelPosition}
				className={classNames(className, classes.checkBoxLabel)}
				control={
					<Checkbox
						color="primary"
						classes={{
							root: classes.checkBox,
						}}
						checked={value}
						onChange={onChange}
						disabled={disabled}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checkedIcon={checkItem}
						icon={checkItem}
					/>
				}
				label={getLabel()}
				labelPlacement={labelPosition}
			/>
		)
	},
)

import EntityScope from '@/features/onboarding/types/EntityScope'

enum EntityTypes {
	post = 1,
	event = 2,
	organization = 3,
	research = 4,
	studyAbroad = 5,
	job = 6,
	user = 7,
	group = 8,
	major = 9,
	academicCollege = 10,
	autoConnectUsers = 11,
	autoFollowOrganizations = 12,
	comment = 13,
	experience = 14,
	interest = 15,
	opportunities = 16,
}

// @TODO: Caps lock entityTypes;  => EntityType; => opportunity
const ENTITY_NAMES = {
	[EntityTypes.post]: 'Post',
	[EntityTypes.event]: 'Event',
	[EntityTypes.organization]: 'Organization',
	[EntityTypes.research]: 'Research',
	[EntityTypes.studyAbroad]: 'Study Abroad',
	[EntityTypes.job]: 'Job',
	[EntityTypes.user]: 'User',
	[EntityTypes.group]: 'Group',
	[EntityTypes.major]: 'Major',
	[EntityTypes.academicCollege]: 'Academic College',
	[EntityTypes.autoConnectUsers]: 'Auto-Connect User',
	[EntityTypes.autoFollowOrganizations]: 'Auto-Follow Organization',
	[EntityTypes.comment]: 'Comment',
	[EntityTypes.experience]: 'Experience',
	[EntityTypes.interest]: 'Interest',
	[EntityTypes.opportunities]: 'Opportunity',
} as const

export const mapEntityTypeToName = (entityType: EntityTypes, entityScope: EntityScope) => {
	if (entityScope === EntityScope.Academic) {
		return ENTITY_NAMES[EntityTypes.academicCollege]
	}

	const entityName = ENTITY_NAMES[entityType] ?? 'Unknown'
	return entityName
}

export const externalEntities = [EntityTypes.organization, EntityTypes.autoFollowOrganizations]
export const isSignupExtra = (entityType: EntityTypes) =>
	[EntityTypes.experience, EntityTypes.interest, EntityTypes.opportunities].includes(entityType)

export const isAddEntityToDBEnabled = (entityType: EntityTypes) =>
	[
		EntityTypes.experience,
		EntityTypes.interest,
		EntityTypes.opportunities,
		EntityTypes.major,
		EntityTypes.academicCollege,
		EntityTypes.group,
	].includes(entityType)

export const isSignupGroup = (entityType: EntityTypes) => [EntityTypes.group].includes(entityType)

export default EntityTypes

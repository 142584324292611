import React, { useCallback } from 'react'
import IOrganizationRequestModalProps from './IOrganizationRequestModalProps'
import ModalWithSubmittedPopup from '@/anthology/components/ModalWithSubmittedPopup'
import { useJoinRequestMutation } from '../../api'

export interface IOrganizationJoinRequestModalProps extends IOrganizationRequestModalProps {}

const OrganizationJoinRequestModal: React.FC<IOrganizationJoinRequestModalProps> = ({ orgId, handleClose, isOpen }) => {
	const [joinRequest, { isError, isLoading, isSuccess, reset }] = useJoinRequestMutation()
	const onClose = useCallback(() => {
		reset()
		handleClose()
	}, [handleClose, reset])
	const onSubmit = useCallback(() => {
		joinRequest({ id: orgId })
	}, [joinRequest, orgId])

	return (
		<ModalWithSubmittedPopup
			isError={isError}
			isSuccess={isSuccess}
			isLoading={isLoading}
			isOpen={isOpen}
			onSubmit={onSubmit}
			onClose={onClose}
			title="Request To Join"
			content={['If you want to join this organization, or are already a member and would like to claim membership, please continue.']}
			submittedMessage={['You’ve requested to join.']}
			submitButtonLabel="Request to Join"
		/>
	)
}

export default OrganizationJoinRequestModal

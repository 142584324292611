import { InputAdornment, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { CommonModalCard, Modal } from '@/components'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import ModalUserRow from '@/components/Messages/ModalUserRow'
import { PATHS, STYLES } from '@/constants'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import SharedPost from '@/features/shareEntity/components/SharedPost'
import { SHARED_ENTITY_NAMES } from '@/features/shareEntity/constants'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import {
	clearSearchUsers,
	clearShareModal,
	getCanLoadMoreSearchUserResults,
	getIsLoadingSearchUserResults,
	getSearchUserQuery,
	getSearchUserResults,
	getShareModalData,
	searchUserRequest,
	setNewMessageContent,
	setSearchUsersQuery,
} from '@/store/messages'

const useStyles = makeStyles((theme) => ({
	rootCard: {
		minWidth: 530,
		width: '75vw',
		[theme.breakpoints.up('lg')]: {
			width: '45vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	cardHeader: {},
	rootContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	contentContainer: {
		maxHeight: '80vh',
		overflow: 'auto',
		paddingBottom: 25,
	},
	content: {
		margin: '0 20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontSize: '1em',
		boxShadow: '0px 7px 15px -1px rgba(181,181,181,0.33)',
		margin: '2vh 0',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	searchInputContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '0 1.5vw',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	cardToShareContainer: {
		width: '50vw',
		margin: '2vh 0',
		[theme.breakpoints.up('lg')]: {
			width: '35vw',
		},
	},
	itemsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		overflow: 'auto',
		maxHeight: 320,
		margin: '20px 0',
	},
	searchPlaceholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		padding: '10vh',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 48%)`,
		justifyContent: 'space-evenly',
		gridGap: 0,
	},
}))

const ShareItemShareItemViaMessageModalModal = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const { isOpen, data } = useSelector(getShareModalData)
	const searchValue = useSelector(getSearchUserQuery)
	const canLoadMore = useSelector(getCanLoadMoreSearchUserResults)
	const isLoading = useSelector(getIsLoadingSearchUserResults)
	const users = useSelector(getSearchUserResults)

	const { sharedEntity } = data || {}
	const type =
		sharedEntity?.sharedEntityType && SHARED_ENTITY_NAMES[sharedEntity?.sharedEntityType]
			? SHARED_ENTITY_NAMES[sharedEntity.sharedEntityType]
			: ''
	const [isTouched, setIsTouched] = useState(false)

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setSearchUsersQuery(value))
			setIsTouched(true)
		},
		[dispatch],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(searchUserRequest({ fetchMore: true }))
	}, [dispatch])

	const handleSearch = useCallback(() => {
		dispatch(searchUserRequest({}))
	}, [dispatch])

	const onClose = useCallback(() => {
		dispatch(clearShareModal())
		dispatch(setSearchUsersQuery(''))
		dispatch(clearSearchUsers())
	}, [dispatch])

	const handleSelectUser = useCallback(
		(user: UserData) => () => {
			dispatch(setNewMessageContent(data || {}))
			push(PATHS.APP.MESSAGES_USER_DIALOGUE(user.id))
			onClose()
		},
		[data, dispatch, onClose, push],
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[],
	)

	const handleClearInput = useCallback(() => {
		dispatch(setSearchUsersQuery(''))
		delayedSearch()
	}, [delayedSearch, dispatch])

	useEffect(() => {
		if (isTouched) delayedSearch()

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, isTouched])

	useEffect(() => {
		if (isOpen) handleSearch()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])
	const skipTypes = useMemo(() => [EntityTypes.post], [])
	const showCardContainer = !!sharedEntity

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<CommonModalCard
				title={`Share ${type}`}
				onClose={onClose}
				classnames={{
					header: classes.cardHeader,
					card: classes.rootCard,
				}}
			>
				<div className={classes.rootContainer}>
					<div className={classes.contentContainer}>
						<div className={classes.content}>
							<div className={classes.searchInputContainer}>
								<TextField
									className={classes.input}
									onChange={handleChangeInput}
									value={searchValue}
									placeholder="Search your network "
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon className={classes.inputIcon} fontSize="medium" />
											</InputAdornment>
										),
										endAdornment: searchValue ? (
											<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
												<Close className={classes.inputIcon} fontSize="medium" />
											</InputAdornment>
										) : undefined,
									}}
									variant="outlined"
								/>
							</div>
							<div className={classes.itemsContainer}>
								{!!users?.length ? (
									<ItemsSection
										handleLoadMoreItems={handleLoadMore}
										items={users}
										classnames={{
											content: classes.cardContainer,
										}}
										canLoadMore={canLoadMore}
										isLoading={isLoading}
										renderItems={(items: any) =>
											items.map((user: any) => <ModalUserRow key={`${user.id}-user`} user={user} onClick={handleSelectUser(user)} />)
										}
									/>
								) : (
									<div className={classes.searchPlaceholder}>
										<Typography>No results found.</Typography>
									</div>
								)}
							</div>
							{showCardContainer && (
								<div className={classes.cardToShareContainer}>
									{sharedEntity && (
										<SharedEntity
											skipTypes={skipTypes}
											sharedEntityId={sharedEntity.sharedEntityId}
											sharedEntityType={sharedEntity.sharedEntityType}
										/>
									)}
								</div>
							)}
							{sharedEntity && sharedEntity.sharedEntityType === EntityTypes.post && <SharedPost {...sharedEntity} />}
						</div>
					</div>
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export default ShareItemShareItemViaMessageModalModal

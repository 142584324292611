import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	placeholder: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

interface PageContainerProps {
	children: React.ReactNode
}

const PageContainer = ({ children }: PageContainerProps) => {
	const classes = useStyles()

	return <div className={classes.root}>{children}</div>
}

export default PageContainer

import * as http from '@/api/http'
import { NewGoalsReqBody, NewGoalsResponse } from '@/api/http/pathBuilder/postNewGoals'
import { getTimePeriodValue, getWithCustomAnswerValue } from '@/constants/pathBuilder'
import { pathBuilder } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/pathBuilder/constants'
import { PathBuilderForm } from '@/store/pathBuilder/types'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { initGoalsRequest, selectAuth } from '../auth'

export const setForm = createAction<PathBuilderForm>(`${MODULE_NAME}/SET_FORM`)
export const updateForm = createAction<PathBuilderForm>(`${MODULE_NAME}/UPDATE_FORM`)
export const setIsEditPathBuilder = createAction<{
	builderData: any
	stepToOpenIndex: number
	isEdit: boolean
}>(`${MODULE_NAME}/SET_IS_EDIT_PATH_BUILDER`)
export const setPathBuilder = createAction<{
	key: string
	currentSubStep: number
	answer: any
	clearFollowingAnswers?: boolean
}>(`${MODULE_NAME}/SET_PATH_BUILDER_DATA`)

export const setSearchOrganizations = createAction<{
	page: number
	canLoadMore: boolean
	list: any[]
}>(`${MODULE_NAME}/SET_SEARCH_ORGANIZATIONS`)
export const clearSearchUsers = createAction(`${MODULE_NAME}/CLEAR_SEARCH_ORGANIZATIONS`)
export const setSearchOrganizationsQuery = createAction<string>(`${MODULE_NAME}/SET_SEARCH_ORGANIZATIONS_QUERY`)
export const setIsOpenPathBuilderCompletedPopup = createAction<boolean>(`${MODULE_NAME}/SET_IS_OPEN_POPUP`)
export const setStartBuilderPath = createAction<boolean>(`${MODULE_NAME}/SET_START_BUILD_PATH`)

export const setCountries = createAction<string[]>(`${MODULE_NAME}/SET_COUNTRIES`)

interface PathBuilderFormWithCreatedAt extends PathBuilderForm {
	createdAt: string
}

const convertFormToBuilder = (formData: PathBuilderForm) => {
	const getNewBranchIndex = (targetStepIndex: number, form: PathBuilderForm): number => {
		let newBranchIndex = 0

		if (targetStepIndex >= pathBuilder.length || targetStepIndex < 0) return newBranchIndex

		const calculatedStepInfo = pathBuilder[targetStepIndex]

		calculatedStepInfo.branches.forEach(({ condition }, index) => {
			// @ts-ignore
			if (condition && condition.answer === form[condition.questionKey]) {
				newBranchIndex = index
			}
		})

		return newBranchIndex
	}

	let builder = pathBuilder.reduce((acc, step, index) => {
		const stepQuestions = step.branches[getNewBranchIndex(index, formData)].questions
		const stepAnswers = stepQuestions.map((question) => {
			// @ts-ignore
			let answerData = formData[question.questionKey]

			const withTimePeriodValue = ['numberOfHoursWouldLikeToVolunteer']

			const withCustomStringAnswerValue = ['professionalGoalsForAfterGraduation']

			const withCustomAnswerValue = ['wouldLikeToParticipateIn', 'whileAbroadWouldLikeTo']

			if (withTimePeriodValue.includes(question.questionKey)) {
				answerData = getTimePeriodValue(question.questionKey, formData)
			}

			if (withCustomAnswerValue.includes(question.questionKey)) {
				answerData = getWithCustomAnswerValue(question.questionKey, formData, true)
			}

			if (withCustomStringAnswerValue.includes(question.questionKey)) {
				answerData = getWithCustomAnswerValue(question.questionKey, formData, false)
			}

			return { [question.questionKey]: answerData }
		})

		return {
			...acc,
			[step.label]: stepAnswers,
		}
	}, {} as any)

	return builder
}

export const convertGoalsResponseToPathBuilderForm = async (res: NewGoalsResponse, userId: number, schoolId: number) => {
	const {
		typesOfEvents,
		wouldLikeToJoinANewStudentOrganization,
		numberOfOrganizationsToJoin,
		sizeOfOrganizationWouldLikeToJoin,
		descriptionOfOrganizationWouldLikeToJoin,
		organizationsWouldLikeToJoin,
		wouldLikeToVolunteer,
		causesWouldLikeToVolunteerFor,
		numberOfHoursWouldLikeToVolunteer,
		whenWouldLikeToVolunteer,
		interestedInJoiningAStudentServiceOrganization,
		wouldLikeToStudyAbroad,
		wouldLikeToParticipateIn,
		countriesWhereWouldLikeToStudyAbroad,
		whileAbroadWouldLikeTo,
		whenWouldLikeToStudyAbroad,
		wouldLikeToCompleteUndergraduateResearch,
		areasOfResearchOrCreativeInquiryInterestedIn,
		whenWouldLikeToCompleteResearch,
		currentlyHaveAJob,
		whenLookingForNextJobOrInternship,
		typeOfJobOrInternshipWouldLikeToComplete,
		professionalGoalsForAfterGraduation,
		interestedInTheseServices,
		currentlyLookingForAJob,
		typeOfJobCurrentlyLookingFor,
		experiencesWouldLikeToHaveOutsideOfClassroom,
		createdAt,
		emailsAboutGrantProgramOffers,
	} = res

	const orgIds =
		organizationsWouldLikeToJoin
			?.split(';')
			.map((id) => id.trim())
			.filter((id) => !!id) ?? []

	const orgsResponse = await Promise.all(orgIds.map((id) => http.organizations.getOrganizationById(id, userId, schoolId)))

	const form: PathBuilderFormWithCreatedAt = {
		typesOfEvents: typesOfEvents?.split(';'),
		wouldLikeToJoinANewStudentOrganization,
		numberOfOrganizationsToJoin,
		sizeOfOrganizationWouldLikeToJoin,
		descriptionOfOrganizationWouldLikeToJoin,
		organizationsWouldLikeToJoin: orgsResponse.map(
			({
				data: {
					organizations: { name, id, categories, photoUrl },
				},
			}: any) => ({
				label: name,
				value: id,
				categories: categories.map(({ category }: any) => category),
				photoUrl: photoUrl,
			}),
		),
		wouldLikeToVolunteer,
		causesWouldLikeToVolunteerFor: causesWouldLikeToVolunteerFor?.split(';'),
		numberOfHoursWouldLikeToVolunteer,
		whenWouldLikeToVolunteer: whenWouldLikeToVolunteer?.split(';'),
		interestedInJoiningAStudentServiceOrganization,
		wouldLikeToStudyAbroad,
		wouldLikeToParticipateIn: wouldLikeToParticipateIn?.split(';'),
		countriesWhereWouldLikeToStudyAbroad: countriesWhereWouldLikeToStudyAbroad?.split(';'),
		whileAbroadWouldLikeTo: whileAbroadWouldLikeTo?.split(';'),
		whenWouldLikeToStudyAbroad: whenWouldLikeToStudyAbroad?.split(';'),
		wouldLikeToCompleteUndergraduateResearch,
		areasOfResearchOrCreativeInquiryInterestedIn: areasOfResearchOrCreativeInquiryInterestedIn?.split(';'),
		whenWouldLikeToCompleteResearch: whenWouldLikeToCompleteResearch?.split(';'),
		currentlyHaveAJob,
		whenLookingForNextJobOrInternship,
		typeOfJobOrInternshipWouldLikeToComplete,
		professionalGoalsForAfterGraduation,
		interestedInTheseServices: interestedInTheseServices?.split(';'),
		currentlyLookingForAJob,
		typeOfJobCurrentlyLookingFor,
		experiencesWouldLikeToHaveOutsideOfClassroom: experiencesWouldLikeToHaveOutsideOfClassroom?.split(';'),
		createdAt,
		emailsAboutGrantProgramOffers,
	}

	return form
}

export const createForm = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_FORM`, async (_undefined, { dispatch, getState }) => {
	try {
		const builder = getState().PATH_BUILDER.formBuilder.data

		const form = Object.keys(builder).reduce((acc, key) => {
			const allSteps = builder[key].reduce((acc: any, answer: any) => {
				if (!answer) return acc

				let data: any = {}

				const answerKeys = Object.keys(answer)
				const questionKey = answerKeys.length ? answerKeys[0] : null
				const answerData = questionKey ? answer[questionKey] : null

				if (!questionKey) return acc

				if (typeof answerData === 'string' || typeof answerData === 'boolean' || Array.isArray(answerData)) {
					data[questionKey] = answerData
				} else if (answerData?.answers) {
					const newData = Array.isArray(answerData?.answers)
						? answerData?.answers.map((curData: string) =>
								curData.toLowerCase() === 'other' ? `${curData}: ${answerData?.otherAnswer}` : curData,
						  )
						: answerData?.answers.toLowerCase() === 'other'
						? `${answerData?.answers}: ${answerData?.otherAnswer}`
						: answerData?.answers
					data[questionKey] = newData
				} else if (answerData?.hours) {
					data[questionKey] = `${answerData?.hours};${answerData?.timePeriod}`
				}

				return {
					...acc,
					...data,
				}
			}, {})

			return {
				...acc,
				...allSteps,
			}
		}, {} as PathBuilderForm)

		dispatch(setForm(form))
	} catch (e: any) {
		console.error(e, 'e')
	}
})

export const searchOrganizationsRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SEARCH_ORGANIZATIONS`, async ({ fetchMore }, { dispatch, getState }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const storedOrgs = rootState.PATH_BUILDER.searchOrganization.list
		const currentPage = rootState.PATH_BUILDER.searchOrganization.page

		const searchQuery = rootState.PATH_BUILDER.searchOrganization.q
		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.organizations.searchOrganizations({
			q: searchQuery,
			page,
			userId: userId,
			schoolId,
		})

		const orgsMap = data.map(({ id, name, categories, photoUrl }: any) => ({
			value: id,
			label: name,
			categories: categories.map(({ category }: any) => category),
			photoUrl: photoUrl,
		}))

		const list = fetchMore ? storedOrgs.concat(orgsMap) : orgsMap

		dispatch(setSearchOrganizations({ page, canLoadMore: !!data.length, list }))
	} catch (e: any) {
		console.error(e)
	}
})

export const saveGoalsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/SAVE_USER_GOALS_REQUEST`, async (_undefined, { dispatch, getState }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			form: {
				typesOfEvents,
				wouldLikeToJoinANewStudentOrganization,
				numberOfOrganizationsToJoin,
				sizeOfOrganizationWouldLikeToJoin,
				descriptionOfOrganizationWouldLikeToJoin,
				organizationsWouldLikeToJoin,
				wouldLikeToVolunteer,
				causesWouldLikeToVolunteerFor,
				numberOfHoursWouldLikeToVolunteer,
				whenWouldLikeToVolunteer,
				interestedInJoiningAStudentServiceOrganization,
				wouldLikeToStudyAbroad,
				wouldLikeToParticipateIn,
				countriesWhereWouldLikeToStudyAbroad,
				whileAbroadWouldLikeTo,
				whenWouldLikeToStudyAbroad,
				wouldLikeToCompleteUndergraduateResearch,
				areasOfResearchOrCreativeInquiryInterestedIn,
				whenWouldLikeToCompleteResearch,
				currentlyHaveAJob,
				whenLookingForNextJobOrInternship,
				typeOfJobOrInternshipWouldLikeToComplete,
				professionalGoalsForAfterGraduation,
				interestedInTheseServices,
				currentlyLookingForAJob,
				typeOfJobCurrentlyLookingFor,
				experiencesWouldLikeToHaveOutsideOfClassroom,
				emailsAboutGrantProgramOffers,
				resourceEmails,
			},
		} = rootState.PATH_BUILDER

		const body: NewGoalsReqBody = {
			typesOfEvents: typesOfEvents?.join(';'),
			wouldLikeToJoinANewStudentOrganization,
			numberOfOrganizationsToJoin,
			sizeOfOrganizationWouldLikeToJoin,
			descriptionOfOrganizationWouldLikeToJoin,
			organizationsWouldLikeToJoin: organizationsWouldLikeToJoin?.map(({ value }: any) => value).join(';'),
			wouldLikeToVolunteer,
			causesWouldLikeToVolunteerFor: causesWouldLikeToVolunteerFor?.join(';'),
			numberOfHoursWouldLikeToVolunteer: numberOfHoursWouldLikeToVolunteer
				? Object.values(numberOfHoursWouldLikeToVolunteer).join(';')
				: null,
			whenWouldLikeToVolunteer: whenWouldLikeToVolunteer?.join(';'),
			interestedInJoiningAStudentServiceOrganization,
			wouldLikeToStudyAbroad,
			wouldLikeToParticipateIn: wouldLikeToParticipateIn?.join(';'),
			countriesWhereWouldLikeToStudyAbroad: countriesWhereWouldLikeToStudyAbroad?.join(';'),
			whileAbroadWouldLikeTo: whileAbroadWouldLikeTo?.join(';'),
			whenWouldLikeToStudyAbroad: whenWouldLikeToStudyAbroad?.join(';'),
			wouldLikeToCompleteUndergraduateResearch,
			areasOfResearchOrCreativeInquiryInterestedIn: areasOfResearchOrCreativeInquiryInterestedIn?.join(';'),
			whenWouldLikeToCompleteResearch: whenWouldLikeToCompleteResearch?.join(';'),
			currentlyHaveAJob,
			whenLookingForNextJobOrInternship,
			typeOfJobOrInternshipWouldLikeToComplete,
			professionalGoalsForAfterGraduation,
			interestedInTheseServices: interestedInTheseServices?.join(';'),
			currentlyLookingForAJob,
			typeOfJobCurrentlyLookingFor,
			experiencesWouldLikeToHaveOutsideOfClassroom: experiencesWouldLikeToHaveOutsideOfClassroom?.join(';'),
			emailsAboutGrantProgramOffers,
			resourceEmails,
		}

		await http.pathBuilder.postNewGoals(userId, body, schoolId)
		dispatch(initGoalsRequest())
	} catch (e: any) {
		console.error(e)
	}
})

export const setIsEditForm = createAsyncThunk<
	any,
	{
		formData: PathBuilderForm
		stepToOpenIndex: number
		isEdit: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SET_IS_EDIT_FORM`, async ({ formData, stepToOpenIndex, isEdit }, { dispatch }) => {
	try {
		const builder = convertFormToBuilder(formData)

		dispatch(
			setIsEditPathBuilder({
				stepToOpenIndex,
				isEdit,
				builderData: builder,
			}),
		)
	} catch (e: any) {
		console.error(e)
	}
})

export const loadCountriesListRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_COUNTRIES`, async (_undefined, { dispatch, getState }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			data: { countries },
		} = await http.pathBuilder.getCountries(userId, schoolId)

		await dispatch(setCountries(countries))
	} catch (e: any) {
		console.error(e)
	}
})

import { SvgIcon } from '@material-ui/core'

const StepperCheckMarkIcon = (props: any) => {
	return (
		<SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 20 20" width={13.623} height={13.623} {...props}>
			<g fill="none" stroke="#3dd8ba" strokeLinecap="round" strokeWidth={2}>
				<path data-name="\u041B\u0438\u043D\u0438\u044F 269" d="M5.847 12.211L1.412 8.269" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 270" d="M5.847 12.212L12.253 1.37" />
			</g>
		</SvgIcon>
	)
}

export default StepperCheckMarkIcon

import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { Compass } from '@phosphor-icons/react'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { IconButton, VolunteerIcon } from '@/components'
import Filter from '@/components/Filter'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { getActiveCampuses } from '@/features/campus/slice'
import { Filters, SearchConfig } from '@/interfaces/common'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import {
	clearFilters as clearEventsFilters,
	getCategories as getEventsCategories,
	getSearchConfig as getEventsSearchConfig,
	loadEventsListWithFilterRequest,
	openCreateEventPage,
} from '@/store/events'
import {
	clearFilters as clearOrgFilters,
	getSearchConfig as getOrgSearchConfig,
	getCategories as getOrgsCategories,
	loadOrganizationsListWithFilterRequest,
} from '@/store/organizations'
import { getFutureShifts } from '@/store/volunteer'
import { requestToAddOrganization } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	createEventButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
	container: {
		height: '100%',
		width: '100%',
	},
}))

const VolunteerPage = () => {
	const classes = useStyles()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const [tab, setTab] = useState(!window.location.pathname.includes('organization') ? 0 : 1)

	const eventsSearchConfig = useSelector(getEventsSearchConfig)
	const orgSearchConfig = useSelector(getOrgSearchConfig)
	const eventsCategories = useSelector(getEventsCategories)
	const orgsCategories = useSelector(getOrgsCategories)
	const campuses = useSelector(getActiveCampuses)
	const futureShifts = useSelector(getFutureShifts)

	const searchEvent = !tab

	const categories = useMemo(() => (searchEvent ? eventsCategories : orgsCategories), [eventsCategories, orgsCategories, searchEvent])
	const volunteerCategory = categories.find((c) => c?.label?.toLowerCase().includes('volunteer'))?.value

	const handleClearEventFilter = () => {
		dispatch(clearEventsFilters())
	}

	const handleClearOrgFilter = () => {
		dispatch(clearOrgFilters())
	}

	const fetchSearchEndFilterEvents = useCallback(
		(config: SearchConfig) => {
			dispatch(loadEventsListWithFilterRequest(config))
			window.scrollTo({ top: 0 })
		},
		[dispatch],
	)

	const fetchSearchEndFilterOrgs = useCallback(
		(config: SearchConfig) => {
			dispatch(loadOrganizationsListWithFilterRequest(config))
			window.scrollTo({ top: 0 })
		},
		[dispatch],
	)

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Volunteer',
				icon: <Compass size={ICON_SIZES.md} />,
				path: searchEvent ? PATHS.APP.VOLUNTEERS_EVENTS : PATHS.APP.VOLUNTEERS_ORGANIZATIONS,
			},
			{
				label: 'My Volunteer Shifts',
				icon: <VolunteerIcon fontSize="medium" />,
				path: PATHS.APP.VOLUNTEERS_SHIFTS,
				info: [`${futureShifts.length} Upcoming `],
			},
		],
		[futureShifts.length, searchEvent],
	)

	const handleClickCreateEvent = useCallback(() => {
		dispatch(openCreateEventPage({}))
	}, [dispatch])

	const handleChangeTab = useCallback(
		(tabIndex: number) => {
			setTab(tabIndex)
			push(tabIndex ? PATHS.APP.VOLUNTEERS_ORGANIZATIONS : PATHS.APP.VOLUNTEERS_EVENTS)
		},
		[push],
	)

	const canUseFilter = pathname !== PATHS.APP.VOLUNTEERS_SHIFTS

	return (
		<div className={classes.root}>
			{searchEvent && (
				<Filter
					key="events"
					title="Volunteer"
					searchPlaceholder="Search volunteer"
					value={eventsSearchConfig}
					filters={[Filters.campuses, Filters.categories, Filters.date]}
					filterOptions={{
						campuses,
						categories,
					}}
					defaultFilterOptions={{
						categories: [volunteerCategory],
					}}
					canUseFilter={canUseFilter}
					onFilterChange={fetchSearchEndFilterEvents}
					clearFilters={handleClearEventFilter}
					filterNavigation={filterNavigation}
					additionalElementsRenderer={() => (
						<IconButton className={classes.createEventButton} startIcon={<Add />} onClick={handleClickCreateEvent}>
							Create Volunteer Event
						</IconButton>
					)}
				/>
			)}
			{!searchEvent && (
				<Filter
					key="organizations"
					title="Volunteer"
					searchPlaceholder="Search volunteer"
					value={orgSearchConfig}
					filters={[Filters.campuses, Filters.categories]}
					filterOptions={{
						campuses,
						categories,
					}}
					defaultFilterOptions={{
						categories: [volunteerCategory],
					}}
					canUseFilter={canUseFilter}
					onFilterChange={fetchSearchEndFilterOrgs}
					clearFilters={handleClearOrgFilter}
					filterNavigation={filterNavigation}
					additionalElementsRenderer={() => (
						<IconButton className={classes.createEventButton} startIcon={<Add />} onClick={requestToAddOrganization}>
							Add Volunteer Organization
						</IconButton>
					)}
				/>
			)}
			<div className={classes.container}>
				{canUseFilter && (
					<ItemsSectionTitle
						title="Discover Volunteer Opportunities"
						tabs={['Volunteer Events', 'Volunteer Organizations']}
						handleChangeTab={handleChangeTab}
						activeTab={tab}
					/>
				)}
				<Router
					routes={ROUTES.volunteers}
					redirectPaths={[
						{
							from: PATHS.APP.VOLUNTEER,
							to: PATHS.APP.VOLUNTEERS_EVENTS,
						},
					]}
				/>
			</div>
		</div>
	)
}

export default VolunteerPage

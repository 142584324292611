import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

const getOrganizationActivity = async (page: number, userId: number, organizationId: string, schoolId: number) => {
	const url = `${schoolId}/activity/${userId}/organization`

	const params = {
		page,
		organizationId,
		perPage: settings.SEARCH_ACTIVITY_LIST_PER_PAGE,
		light: true,
	}

	return http.get(url, { params })
}

export default getOrganizationActivity

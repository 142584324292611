import { makeStyles } from '@material-ui/core'

import { loadMessageReactions, TMessageReactionsApiParams, TMessageReactionsDto } from '@/api/http/messages'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { useInfiniteScrollList } from '@/hooks/useInfiniteScrollList'
import { rootStyles } from '@/styles'

import { getFullName } from '@/utils/common'

import { MessageProfileImage } from '../MessageProfileImage'

const useStyles = makeStyles((theme) => ({
	...rootStyles,
	modalLikersLabel: {
		color: theme.palette.text.secondary,
		fontSize: 16,
	},
	modalContent: {
		padding: 20,
		width: '100%',
		boxSizing: 'border-box',
	},
	modalLiker: {
		color: theme.colors.grey[600],
		fontSize: 18,
	},

	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, calc(50% - 5px))`,
		gridGap: 10,
		margin: 0,
	},
	contentContainer: {
		margin: `20px 0 0 0`,
	},
}))

export type IReactionsListProps = TMessageReactionsApiParams

const ReactionsList: React.FC<IReactionsListProps> = (props) => {
	const classes = useStyles()
	const { canLoadMore, items, loadMore, resetScrollPosition } = useInfiniteScrollList<TMessageReactionsApiParams, TMessageReactionsDto>(
		props,
		loadMessageReactions,
	)

	return (
		<InfiniteScrollComponent
			classnames={{
				content: classes.contentContainer,
			}}
			internalScroll
			height={200}
			resetScroll={resetScrollPosition}
			canLoadMore={canLoadMore}
			onLoadMore={loadMore}
			dataLength={items.length}
		>
			{items.map(({ externalId, firstName, lastName, id, photoUrl }) => (
				<div key={id} className={classes.flexCenteredItemsY}>
					<MessageProfileImage externalId={externalId} photoUrl={photoUrl} userId={id} />
					{getFullName(firstName, lastName)}
				</div>
			))}
		</InfiniteScrollComponent>
	)
}

export { ReactionsList }

import IconButton from '@/components/Buttons/IconButton'
import SelfCreatedItemsList from '@/components/PathBuilder/SelfCreatedItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import SearchInput from '@/components/SearchInput'
import { getArrayMinSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { getCountries } from '@/store/pathBuilder'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '1vh 1vw',
		height: '40vh',
		overflowY: 'auto',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 15,
		padding: 30,
		textAlign: 'center',
	},
	header: {
		fontWeight: 700,
		fontSize: 18,
		margin: 20,
	},
	iconText: {
		fontSize: 20,
	},
	text: {
		fontSize: 18,
	},
	listItem: {
		width: '100%',
		color: theme.palette.text.primary,
		textAlign: 'left',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		fontWeight: 500,
	},
	searchInput: {
		width: '100%',
		margin: '2vh 0',
	},
	placeholder: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '1vh 1vw',
		height: '40vh',
	},
	placeholderBoldText: {
		fontWeight: 700,
		fontSize: 18,
	},
	placeholderText: {
		fontSize: 18,
	},
}))

const Question3 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const countries = useSelector(getCountries)

	const [list, setList] = useState<string[]>(countries)
	const [searchValue, setSearchValue] = useState('')
	// const [searchSelectedValue, setSearchSelectedValue] = useState('');

	const handleChangeSearchInput = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(value)
	}

	const handleClearSearchInput = () => {
		setSearchValue('')
	}

	// const handleChangeSelectedSearchInput = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
	//   setSearchSelectedValue(value);
	// };

	// const handleClearSelectedSearchInput = () => {
	//   setSearchSelectedValue('');
	// };

	const handleAddItem = (v: string[], onChange: (v: string[]) => void) => (newItem: string) => () => {
		onChange((v ?? []).concat([newItem]))
		setList((prev) => prev.filter((item) => item !== newItem))
	}

	const handleDeleteItem = (onChange: (v: string[]) => void) => (v: string[]) => {
		onChange(v)
		setList(countries.filter((item) => !(v ?? []).includes(item)))
	}

	const getList = (query: string, items: string[]) => (items ?? []).filter((item) => item.toLowerCase().includes(query.toLowerCase()))

	const items = getList(searchValue, list)

	return (
		<Controller
			name={dataKey}
			control={control}
			render={({ field: { value, onChange } }) => (
				<StepperContent
					leftColumnContent={
						<StepperCard
							title="Where would you like to study abroad?"
							subTitle="You can select more than one."
							onClose={onClose}
							onBack={onBack}
							showBackButton={showBackButton}
						>
							<SearchInput
								className={classes.searchInput}
								value={searchValue}
								placeholder="Search countries"
								onChange={handleChangeSearchInput}
								onClear={handleClearSearchInput}
							/>
							{!!items.length ? (
								<div className={classes.itemsContainer}>
									{items.map((item, index) => (
										<IconButton key={item} className={classes.listItem} onClick={handleAddItem(value, onChange)(item)}>
											{item}
										</IconButton>
									))}
								</div>
							) : (
								<div className={classes.placeholder}>
									<Typography className={classes.placeholderBoldText}>No Results.</Typography>
								</div>
							)}
						</StepperCard>
					}
					rightColumnContent={
						<div>
							{/* <SearchInput
            className={classes.searchInput}
            value={searchSelectedValue}
            placeholder='Search countries'
            onChange={handleChangeSelectedSearchInput}
            onClear={handleClearSelectedSearchInput}
          /> */}
							{/* <SelfCreatedItemsList
            title='Selected'
            placeholder={(!searchSelectedValue && !value?.length) ? 'Add countries.' : 'No results found.'}
            onChange={handleDeleteItem(onChange)}
            items={getList(searchSelectedValue, value)}
          /> */}
							<SelfCreatedItemsList title="Selected" placeholder="Add countries." onChange={handleDeleteItem(onChange)} items={value} />
						</div>
					}
				/>
			)}
		/>
	)
}

export default Question3

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { DtoInvites } from '@navengage/sen-types'

interface SearchNetworkParams {
	q?: string
	page: number
	campusId?: number
	groupId?: string
	schoolId: number
}

const searchMembersToNewGroup = async ({ q, page, campusId, groupId, schoolId }: SearchNetworkParams) => {
	const url = `${schoolId}/groups/members/invites`

	const params = {
		q,
		page,
		campusId,
		groupId,
		limit: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get<DtoInvites>(url, { params })
}

export default searchMembersToNewGroup

import { SvgIcon } from '@material-ui/core'
import { useEffect } from 'react'

type StepperDotIconProps = {
	isActive: boolean
	color: string
	className: string
}

const StepperDotIcon = ({ isActive, color, ...rest }: StepperDotIconProps) => {
	useEffect(
		() =>
			console.log({
				color,
			}),
		[color],
	)

	return (
		<SvgIcon {...rest} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
			<circle
				data-name="\u042D\u043B\u043B\u0438\u043F\u0441 274"
				cx={7}
				cy={7}
				r={7}
				fill={color ? color : '#fff'}
				opacity={isActive ? 1 : 0.158}
			/>
		</SvgIcon>
	)
}

export default StepperDotIcon

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

const getOrganizationChangePositionRequests = async (orgId: string, page: number, userId: number, schoolId: number) => {
	const url = `${schoolId}/organizations/${userId}/org/${orgId}/positionRequests`
	const params = {
		page,
		perPage: settings.ORG_MEMBERS_PER_PAGE,
	}

	return http.get(url, { params })
}

export default getOrganizationChangePositionRequests

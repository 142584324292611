import * as http from '@/api/http'
import { ReportActions, SearchConfig, UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/internships/constants'
import { normalizeSelectedTreeNodes } from '@/utils/categoryTree'
import { applyInterestedStatus, getCurrentFilterValues, getNormalizedUserData } from '@/utils/common'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setInternships = createAction<any>(`${MODULE_NAME}/SET_INTERNSHIPS`)
export const setFilters = createAction<any>(`${MODULE_NAME}/SET_FILTERS`)

export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setUserInternships = createAction<any>(`${MODULE_NAME}/SET_USER_INTERNSHIPS`)
export const setIndividualInternships = createAction<any>(`${MODULE_NAME}/SET_INDIVIDUAL_INTERNSHIPS`)
export const setUserResponse = createAction<{ response: UserResponseStatuses; id: string }>(`${MODULE_NAME}/SET_USER_RESPONSE`)

export const loadInternshipsListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_INTERNSHIPS_LIST_WITH_FILTER_REQUEST`, async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
	const rootState = getState()
	const { schoolId, userId } = selectAuth(rootState)

	const { nodes, rootNode } = rootState.APP_CONSTANTS.jobFilters.data.majors
	const storedInternships = rootState.INTERNSHIPS.internships
	const storedSearch = rootState.INTERNSHIPS.search
	try {
		dispatch(setIsLoading(true))

		const normalizedMajors = normalizeSelectedTreeNodes(nodes, rootNode.code) as Array<{ left: number; right: number; id: number | string }>

		const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

		const res = await http.internships.searchInternships({
			q: params?.query,
			page,
			userId,
			positionPaid: params?.paid,
			employer: params?.employers,
			minGpa: params?.minimumGPA,
			jobTypeId: params?.positionTypes,
			majors: normalizedMajors,
			classLevel: params?.classLevels,
			schoolId,
		})

		const internships = fetchMore ? [...storedInternships, ...res.data] : res.data

		const internshipsWithFollowStatus = internships.map((res: any) => ({
			...res,
			responseStatus: res.response ? res.response.response : null,
		}))

		dispatch(
			setFilters({
				...currentConfig,
				endHasBeenReached: !res.data?.length,
				page,
				filterIsReady,
			}),
		)
		await dispatch(setInternships(internshipsWithFollowStatus))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadUserInternshipsRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_INTERNSHIPS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const response = await http.internships.getAllUserInternshipsResponses(userId, schoolId)

		const internshipsResponses = response.data?.response || {}

		//@ts-ignore
		const interestedInternshipsIds = Object.keys(internshipsResponses).reduce(
			(acc, id) => (internshipsResponses[id] === UserResponseStatuses.interested ? [...acc, id] : acc),
			[],
		) as string[]
		const internships = await Promise.all(interestedInternshipsIds.map((id) => http.internships.getInternshipById(id, schoolId)))
		const internshipsWithUserStatus = internships.map(({ data: { job } }) => ({
			...job,
			responseStatus: interestedInternshipsIds.includes(job.id) ? UserResponseStatuses.interested : null,
		}))

		dispatch(setUserInternships(internshipsWithUserStatus))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

const loadInternshipWithUserStatus = async (id: string, userId: number, schoolId: number) => {
	const allUserInternshipsResponses = await http.internships.getAllUserInternshipsResponses(userId, schoolId)

	const internshipsResponse = allUserInternshipsResponses.data?.response || {}
	//@ts-ignore
	const interestedInternshipsIds = Object.keys(internshipsResponse).reduce(
		(acc, id) => (internshipsResponse[id] === UserResponseStatuses.interested ? [...acc, id] : acc),
		[],
	) as string[]

	const { data } = await http.internships.getInternshipById(id, schoolId)

	const res = data.job

	res.responseStatus = interestedInternshipsIds.includes(res.id) ? UserResponseStatuses.interested : null

	const {
		data: {
			response: { interested },
		},
	} = await http.internships.getInternshipAttendeesList(id, schoolId)

	res.interestedUsers = getNormalizedUserData(interested)

	return res
}

export const loadInternshipByIdRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_INTERNSHIPS_REQUEST`, async (id: string, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const {
			data: { job },
		} = await http.internships.getInternshipById(id, schoolId)

		dispatch(setIndividualInternships(applyInterestedStatus(job)))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const setInternshipResponseRequest = createAsyncThunk<
	any,
	{
		id: string
		status: UserResponseStatuses | ReportActions
		reportMessage?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SET_INTERNSHIPS_RESPONSE_REQUEST`, async ({ id, status, reportMessage }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		await http.internships.postInternshipUserResponse({ id, userId, response: status, reportMessage, schoolId })

		const newRes = await loadInternshipWithUserStatus(id, userId, schoolId)

		dispatch(setIndividualInternships(newRes))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

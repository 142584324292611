import { PATHS } from '@/constants'
import { isEngage } from '@/constants/configuration'
import { default as ContentModerationPage } from '@/features/adminConsole/pages/ContentModerationPage'
import AppRoute from '@/interfaces/route'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import EventsMainPage from '../pages/EventsMainPage'
import FeedMainPage from '../pages/FeedMainPage'
import DashboardMainPage from '../pages/Home'
import NotificationsMainPage from '../pages/NotificationsMainPage'
import OrganizationsMainPage from '../pages/OrganizationsMainPage'
import PathBuilderMainPage from '../pages/PathBuilderMainPage'
import SignupDashboardMainPage from '../pages/SignupDashboard'
import UsersMainPage from '../pages/UsersMainPage'
import { Route } from './config'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const createMainRoute = ({ component, route, title }: { component: any; route: Route; title?: string }): AppRoute => ({
	component,
	name: route,
	title,
	path: `${PATHS.ADMIN}/${route}`,
	exact: false,
	isPrivate: true,
	allowedRoles: allAuthorizedRolesAllowed,
	analyticsRole: true,
})

const routes: AppRoute[] = [
	createMainRoute({
		component: DashboardMainPage,
		route: Route.Home,
	}),
	createMainRoute({
		component: UsersMainPage,
		route: Route.Users,
	}),
	createMainRoute({
		component: EventsMainPage,
		route: Route.Events,
	}),
	createMainRoute({
		component: OrganizationsMainPage,
		route: Route.Organizations,
	}),
	createMainRoute({
		component: PathBuilderMainPage,
		route: isEngage() ? Route.GoalSetting : Route.PathBuilder,
	}),
	createMainRoute({
		component: FeedMainPage,
		route: Route.Feed,
	}),
	createMainRoute({
		component: SignupDashboardMainPage,
		route: Route.Signup,
	}),
	createMainRoute({
		component: NotificationsMainPage,
		route: Route.Notifications,
	}),
	createMainRoute({
		component: ContentModerationPage,
		route: Route.ModerateContent,
	}),
]

export default routes

import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { OutlinedButton } from '@/components/Buttons'
import { ButtonColors } from '@/components/Buttons/types'
import Logo from '@/components/icons/png/PSU_HOR_RGB_REV_2C.png'
import PathBuilderImage from '@/components/icons/png/PathBuilder/grant_background.png'
import { CheckBox } from '@/components/widgets'
import { PATHS, STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { getForm, setIsEditForm, updateForm } from '@/store/pathBuilder'
import { pathBuilder } from './PathBuilderSteps'

const useStyles = makeStyles((theme) => ({
	root: {
		height: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		width: '100vw',
		background: `url(${PathBuilderImage}) top center / cover no-repeat`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		padding: '0 35px',
		minWidth: 205,
		height: 50,
		margin: '3vh',
		fontWeight: 600,
		color: theme.palette.common.white,
	},
	card: {
		maxWidth: 580,
		minWidth: 350,
		// @TODO: check the color to find existing one
		backgroundColor: '#00153e1f',
		borderRadius: 10,
		width: '50vw',
		color: theme.palette.common.white,
		textAlign: 'center',
	},
	logo: {
		height: 45,
		margin: '20px 0',
	},
	headerText: {
		fontWeight: 700,
		fontSize: 30,
	},
	buttonImage: {
		padding: 0,
		width: 224,
		height: 64,
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
		margin: '3vh',
		height: 'calc(100% - 6vh)',
	},
	text: {
		fontWeight: 500,
		fontSize: 20,
		width: 444,
	},
	checkboxText: {
		fontSize: 18,
		fontWeight: 700,
	},
	checkbox: {
		margin: '3vh 0',
	},
	headerButtons: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
	},
	backIcon: {
		color: theme.palette.common.white,
	},
}))

const GrantPage = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const formData = useSelector(getForm)

	const [checked, setChecked] = useState(false)

	const handleClickCheckbox = () => setChecked(!checked)
	const handleContinueClick = () => {
		dispatch(
			updateForm({
				emailsAboutGrantProgramOffers: checked,
			}),
		)
		push(PATHS.APP.PATH_BUILDER_SUMMARY)
	}

	const handleClickBack = () => {
		dispatch(
			setIsEditForm({
				isEdit: false,
				stepToOpenIndex: pathBuilder.length - 1,
				formData,
			}),
		)

		push(PATHS.APP.PATH_BUILDER_QUESTIONS)
	}

	return (
		<div className={classes.root}>
			<div className={classes.card}>
				<div className={classes.container}>
					<div className={classes.content}>
						<div className={classes.headerButtons}>
							<IconButton onClick={handleClickBack}>
								<KeyboardArrowLeftIcon className={classes.backIcon} fontSize="large" />
							</IconButton>
						</div>
						<img className={classes.logo} src={Logo} alt="pennstate logo" />
						{/* TODO - #theming */}
						<div className={classes.header}>
							<Typography className={classes.headerText} variant="h3">
								Interested in applying for a
							</Typography>
							<Typography className={classes.headerText} variant="h3">
								$2,000 SEN Grant?
							</Typography>
						</div>
						<Typography className={classes.text}>
							The Student Engagement Network’s Grant Program offers a unique funding opportunity for students which supports the breadth of
							student engagement experiences in the spirit of both in-person and virtual/remote engagement experiences.
						</Typography>
					</div>
					<div className={classes.checkbox}>
						<CheckBox
							variant="secondary"
							value={checked}
							label={
								<Typography className={classes.checkboxText} variant="subtitle1">
									Yes, receive more information
								</Typography>
							}
							onChange={handleClickCheckbox}
						/>
					</div>
				</div>
			</div>
			<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={handleContinueClick}>
				{checked ? 'Next' : 'Skip'}
			</OutlinedButton>
		</div>
	)
}

export default GrantPage

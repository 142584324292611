import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Card as MaterialCard, CardContent } from '@material-ui/core'
import { useSchoolConfig } from '../hooks/useSchoolConfig'

const useStyles = makeStyles((theme) => ({
	card: ({ variant, disableBorder }: any) => {
		let css = {
			borderRadius: 15,
			background: theme.palette.background.paper,
			border: theme.palette.divider,
			color: theme.palette.text.primary,
		}
		if (theme.palette.type === 'light') {
			css = {
				...css,
				background: variant === 'primary' ? theme.palette.background.paper : theme.palette.specialColors.transparent,
				border: variant === 'primary' || disableBorder ? 'none' : `solid 1px ${theme.palette.background.paper}`,
				color: variant === 'primary' ? theme.palette.common.black : theme.palette.common.white,
			}
		}
		return css
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0 2vw',
		[theme.breakpoints.down('xs')]: {
			margin: 0,
		},
	},
	img: {
		height: '7.5vh',
		paddingBottom: '2vh',
	},
}))

interface CardProps {
	children: React.ReactNode
	className?: string
	cardContentClass?: string
	variant?: 'primary' | 'secondary'
	showIcon?: boolean
	disableBorder?: boolean
}

const Card = ({
	children,
	className = '',
	cardContentClass = '',
	variant = 'primary',
	showIcon = true,
	disableBorder = false,
}: CardProps) => {
	const classes = useStyles({ variant, disableBorder })
	const appTheme = useTheme()

	const {
		school: { shortName },
		logo: { alt: logoAlt, main: mainLogo },
	} = useSchoolConfig()
	return (
		<MaterialCard className={classNames(classes.card, className)}>
			<CardContent className={classNames(classes.cardContent, cardContentClass)}>
				{variant === 'primary' && showIcon && (
					<img className={classes.img} src={appTheme.palette.type === 'light' ? logoAlt : mainLogo} alt={`${shortName} logo`} />
				)}
				{children}
			</CardContent>
		</MaterialCard>
	)
}

export default Card

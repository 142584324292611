import React from 'react'
import { DtoOrganization } from '../../types/dto/DtoOrganization'
import InfoBadge from '../InfoBadge'
import useGetOrganizationPostCount from '../../hooks/useGetOrganizationPostCount'

export type IPostsCountProps = {
	orgId: DtoOrganization['id']
}

const PostsCount: React.FC<IPostsCountProps> = ({ orgId: id }) => {
	const { count, isLoading } = useGetOrganizationPostCount(id)

	return <InfoBadge count={count} isLoading={isLoading} label="Posts" />
}

export default PostsCount

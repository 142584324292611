import { PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'
import { MODULE_NAME } from '@/store/auth/constants'
import { AuthState } from '@/store/auth/types'
import { createSelector } from '@reduxjs/toolkit'

const selectState = (state: { [MODULE_NAME]: AuthState }) => state[MODULE_NAME]

export const getUserRole = createSelector(selectState, (state) => state.role)
export const getUserInfo = createSelector(selectState, (state) => state.userInfo)
export const getUserCampus = createSelector(selectState, (state) => state.userInfo?.campus)
export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getSignUpFormInfo = createSelector(selectState, (state) => state.signUpForm)
export const getSignUpFormFilled = createSelector(selectState, (state) => !!Object.keys(state.signUpForm.patches!).length)
export const getUpcomingShiftsCount = createSelector(selectState, (state) => state.upcomingShiftsCount || 0)

export const getUserGoals = createSelector(selectState, (state) => state.goals)
export const getProfileTab = createSelector(selectState, (state) => state.profileTabToOpen)
export const getUserActivityState = createSelector(selectState, (state) => state.activity)
export const getAllUserOrganizationRoles = createSelector(selectState, (state) => state.organizationRoles)
export const getUserOrganizationRoles = (orgId: string) => createSelector(selectState, (state) => state.organizationRoles[orgId])
export const getUserPrivacyOptions = createSelector(selectState, (state) => state.userPrivacyOptions)
export const getEntityPrivacy = (type: PrivacyTypes) => createSelector(selectState, (state) => state.entityPrivacy[type])
export const getIsOpenWelcomeNewUserPopup = createSelector(selectState, (state) => state.isOpenWelcomeNewUserPopup)
export const getLatestTermsAndConditions = createSelector(selectState, (state) => state.termsAndConditions)
export const getLatestPrivacyPolicy = createSelector(selectState, (state) => state.privacyPolicy)
export const getIsOpenAcceptNewTermsPopup = createSelector(selectState, (state) => state.isOpenAcceptNewTermsPopup)

export const selectConnectToUserIds = createSelector(selectState, (state) => state.signUpForm.patches.connectToUserIds)

export const selectUserGoalResponses = createSelector(selectState, (state) => state.goalResponses)

export const selectNotificationPreferences = createSelector(selectState, (state) => state.preferences.notifications.data)
export const selectNotificationPreferenceMap = createSelector(selectState, (state) => state.preferences.notifications.preferenceMap)
export const selectNotificationPreferencesLoading = createSelector(selectState, (state) => state.preferences.notifications.isLoading)
export const selectAuth = createSelector(selectState, (state) => ({
	userId: state.userInfo.id,
	token: state.token,
	schoolId: state.schoolId,
}))

import { useUserOrganizationsQuery } from '@/anthology/organizations/api'
import { isEngage } from '@/constants/configuration'

import { UserOrganizationTypes } from '@/anthology/organizations/types/UserOrganizationTypes'
import settings from '@/constants/http'
import { useCurrentUser } from '@/hooks/userHooks'
import { useAppSelector } from '@/store'
import { selectOrganizationsByType } from '@/store/organizations'
import { useCallback, useMemo, useState } from 'react'

type TSelectedAsFilters = {
	skip: number
	take: number
}
interface ISelectPostingAsProps {
	roleType: UserOrganizationTypes
}

const useCurrentUserOrgs = ({ roleType }: ISelectPostingAsProps) => {
	const [filters, setFilters] = useState<TSelectedAsFilters>({
		skip: 0,
		take: settings.SELECT_POST_AS_PER_PAGE,
	})
	const user = useCurrentUser()
	const { skip, take } = filters
	const { data, isLoading, isFetching } = useUserOrganizationsQuery(
		{
			type: roleType,
			skip,
			take,
			userId: user.id,
		},
		{ skip: !isEngage() },
	)

	const psuOrgs = useAppSelector((state) => selectOrganizationsByType(state, roleType))

	const canLoadMore = data?.items && data?.totalItems ? data.items.length < data.totalItems : false

	const loadMore = useCallback(() => {
		if (isFetching || isLoading || !data || !canLoadMore) return
		setFilters((prev) => ({
			...prev,
			skip: prev.skip + prev.take,
		}))
	}, [isFetching, isLoading, data, canLoadMore])

	const normalizedOrgs = useMemo(
		() =>
			data
				? data?.items?.map((org) => ({
						...org,
						photoUrl: org.profilePicture,
						name: org.name,
						id: org.id,
				  }))
				: [],
		[data],
	)

	return isEngage()
		? {
				organizations: normalizedOrgs,
				isLoading,
				isFetching,
				loadMore,
				canLoadMore,
		  }
		: {
				organizations: psuOrgs,
				isLoading: false,
				isFetching: false,
		  }
}

export default useCurrentUserOrgs

import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Popover from '@/components/Popover'

const useStyles = makeStyles((theme) => ({
	popoverButton: {
		color: theme.palette.text.primary,
		borderRadius: 0,
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	paper: {
		marginTop: 5,
		minWidth: 250,
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: '7px 0 7px 7px',
		'-webkit-box-shadow': '0px 0px 10px 0px rgba(34, 60, 80, 0.19)',
		'-moz-box-shadow': '0px 0px 10px 0px rgba(34, 60, 80, 0.19)',
		boxShadow: '0px 0px 10px 0px rgba(34, 60, 80, 0.19)',
	},
	popperContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
	},
	buttonLabel: {
		display: 'flex',
		alignItems: 'center',

		'&>svg': {
			width: 20,
			height: 20,
			marginRight: 10,
		},
	},
	arrowIcon: {
		color: theme.palette.text.secondary,
		fontSize: 15,
		marginLeft: 10,
	},
	icon: {
		width: 20,
		height: 20,
	},
	buttonText: {
		fontSize: 15,
		fontWeight: 500,
	},
	header: {
		margin: 12,
		fontWeight: 700,
	},
}))

interface MenuPopoverProps {
	renderButtons: any[]
	handleClose: () => void
	anchorEl: HTMLButtonElement | null
	header?: string
}

const MenuPopover = ({ renderButtons = [], handleClose = () => {}, anchorEl = null, header }: MenuPopoverProps) => {
	const classes = useStyles()

	return (
		<>
			<Popover
				classnames={{
					container: classes.popperContent,
					paper: classes.paper,
				}}
				anchorEl={anchorEl}
				handleClose={handleClose}
			>
				{header && <Typography className={classes.header}>{header}</Typography>}
				<div className={classes.buttonContainer}>
					{renderButtons.map((btn) => (
						<IconButton key={btn.label} className={classes.popoverButton} onClick={btn.onClick}>
							<div className={classes.buttonLabel}>
								{btn.icon}
								<Typography className={classes.buttonText}>{btn.label}</Typography>
							</div>
							{btn.showArrow && <ArrowForwardIosIcon className={classes.arrowIcon} />}
						</IconButton>
					))}
				</div>
			</Popover>
		</>
	)
}

export default MenuPopover

import FeedsActivityRow, { FeedsActivityRowProps } from '@/components/Feeds/FeedsActivityRow'
import Card from '@/components/IndividualPage/Card'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import { PostActivity } from '@/interfaces/user'
import { useAppDispatch } from '@/store'
import { getOrganizationActivityState, loadOrganizationActivityRequest } from '@/store/organizations'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CircleIconButton } from '@/components'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		margin: '2vh 0',
	},
	header: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	titleText: {
		fontWeight: 700,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		margin: 0,
	},
}))

interface ActivityTabProps {
	handleOpenCreateFeedModal: () => void
	canCreateFeeds: boolean
	organizationInfo?: FeedsActivityRowProps['organizationInfo']
	orgId: string
}

const ActivityTab = ({ handleOpenCreateFeedModal, canCreateFeeds, organizationInfo, orgId }: ActivityTabProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const activity = useSelector(getOrganizationActivityState)

	const hasActivity = !!activity?.list?.length

	const handleLoadMoreActivity = () => dispatch(loadOrganizationActivityRequest({ orgId }))

	useEffect(() => {
		dispatch(loadOrganizationActivityRequest({ resetPage: true, orgId }))
	}, [dispatch, orgId])

	return (
		<Card>
			<div className={classes.header}>
				<Typography className={classes.titleText} variant="body1">
					Activity
				</Typography>
				{canCreateFeeds && <CircleIconButton onClick={handleOpenCreateFeedModal} />}
			</div>
			{hasActivity ? (
				<ItemsSection
					classnames={{
						content: classes.cardContainer,
					}}
					handleLoadMoreItems={handleLoadMoreActivity}
					items={activity!.list!}
					isLoading={!!activity.isLoading}
					canLoadMore={!activity.endHasBeenReached}
					renderItems={(items: PostActivity[]) =>
						items.map((postActivity) => (
							<FeedsActivityRow key={`activity-post-${postActivity.id}`} organizationInfo={organizationInfo} activity={postActivity} />
						))
					}
				/>
			) : (
				<div className={classes.placeholder}>
					<Typography align="center">This organization has no activity on</Typography>
					<Typography align="center">the feeds yet.</Typography>
				</div>
			)}
		</Card>
	)
}

export default ActivityTab

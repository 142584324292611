import CheckBoxCard from '@/components/CheckBoxCard'
import React from 'react'
import { IEntityItemProps } from './IEntityItemProps'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: `${theme.spacing(1)}px 0px`,
		width: '100%',
		height: 45,
		[theme.breakpoints.up('xl')]: {
			height: 50,
		},
	},
}))

const ButtonItem: React.FC<IEntityItemProps> = ({ onClick, checked, name }) => {
	const classes = useStyles()
	return <CheckBoxCard className={classes.root} onClick={onClick} checked={checked} label={name} />
}

export { ButtonItem }

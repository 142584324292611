import { baseApi } from '@/anthology/general/baseApi'
import { SESSION_TIME } from '@/constants/configuration'
import DtoSchoolData from './types/dto/DtoSchoolData'

const API_BASE_URL = '/school'

export const schoolApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getSchoolConfig: builder.query<DtoSchoolData, number>({
			query: (schoolId) => ({
				url: `${API_BASE_URL}/${schoolId}/config`,
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetSchoolConfigQuery } = schoolApi

import { useAppDispatch } from '@/store'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import DotButton from '@/components/Buttons/DotButton'
import ItemButton from '@/components/Buttons/ToggleButton'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import ItemCardContainer from '@/components/ExperienceCards/ItemCardContainer'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS, STYLES } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'

import IconWrapper from '@/components/IconWrapper'
import { ReportActions } from '@/interfaces/common'
import { setOrganizationResponseRequest } from '@/store/organizations'
import useGlobalStyles from '@/styles/globalStyles'
import { getStringCategories } from '@/utils/events'
import { makeStyles } from '@material-ui/core/styles'
import { FEATURE } from '@navengage/sen-shared-assets'
import { useToggleOrganizationFollowMutation } from '../api'
import Category from '../types/Category'
import { DtoOrganization } from '../types/dto/DtoOrganization'

const useStyles = makeStyles((theme) => ({
	footerFullWidthButton: {
		height: 50,
		width: 'calc(100% - 60px)',
		fontWeight: 600,
	},
	responseBtn: {
		height: 50,
		width: 'calc(100% - 46% - 60px)',
	},
	roleBtn: {
		height: 50,
		width: 'calc(100% - 37% - 60px)',
	},
	contentContainer: ({ descriptionClamp }: any) => ({
		marginTop: 0,
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': descriptionClamp,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	}),
	title: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	headerButtonsContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 'calc(100% - 30px)',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
	},

	outlinedButton: {
		color: theme.palette.text.primary,
	},
}))

const getOrgDescriptionLineClamp = (title: string, categories: any[], variant: string) => {
	if (variant === 'secondary') return 2

	const titleLength = +(title.length < 33)
	const categoriesLength = +(getStringCategories(categories).length < 3)

	return 2 + titleLength + categoriesLength
}

interface OrganizationCardProps {
	photoUrl: string
	title: string
	id: DtoOrganization['id']
	categories: Category[]
	description?: string
	followed?: boolean
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
	canEdit?: boolean
}

const OrganizationCard = ({
	photoUrl = '',
	title = '',
	categories = [],
	id,
	followed = false,
	description = '',
	variant = 'primary',
	showActionButtons = true,
	showBadge = false,
	canEdit,
}: OrganizationCardProps) => {
	const classes = useStyles({
		descriptionClamp: getOrgDescriptionLineClamp(title, categories, variant),
	})
	const globalClasses = useGlobalStyles()
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const subTitle = categories.map((c) => c.name).join(',')
	const sharingUrl = PATHS.APP.ORGANIZATIONS_SINGLE(id)

	const handleReportOrganization = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setOrganizationResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.ORGANIZATIONS_SINGLE(id))
	}, [id, push])

	const handleRenderCardHeader = useCallback(
		() => (
			<div className={classes.headerContainer}>
				<div className={classes.headerButtonsContainer}>
					<div className={globalClasses.alignCenter}>
						{showBadge ? (
							<CardIconContainer>
								<IconWrapper iconKey={FEATURE.organizations} weight="fill" size={ICON_SIZES.md} aria-label="Organization" />
							</CardIconContainer>
						) : null}
					</div>
					{showActionButtons && (
						<DotButton itemId={id} onSubmitReport={handleReportOrganization} reportSubject={ReportSubject.Organization} />
					)}
				</div>
			</div>
		),
		[
			classes.headerButtonsContainer,
			classes.headerContainer,
			globalClasses.alignCenter,
			handleReportOrganization,
			id,
			showActionButtons,
			showBadge,
		],
	)

	const [toggleFollowOrgMutation] = useToggleOrganizationFollowMutation()

	const handleToggleResponse = useCallback(
		(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			event.stopPropagation()
			toggleFollowOrgMutation({ id, action: followed ? 'unfollow' : 'follow' })
		},
		[followed, id, toggleFollowOrgMutation],
	)
	const handleRenderActionButtons = useCallback(
		() => (
			<>
				<ItemButton className={classes.footerFullWidthButton} onClick={handleToggleResponse} isActive={followed}>
					{followed ? 'Following' : 'Follow'}
				</ItemButton>
			</>
		),
		[classes.footerFullWidthButton, followed, handleToggleResponse],
	)

	const renderContent = useCallback(() => {
		return description
	}, [description])

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: `${id}`, sharedEntityType: EntityTypes.organization } }), [id])

	return variant === 'primary' ? (
		<ItemCardContainer
			showActionButtons={showActionButtons}
			title={title}
			subTitle={subTitle}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={renderContent}
			sharingUrl={sharingUrl}
			renderFooterButtons={handleRenderActionButtons}
		/>
	) : (
		<FeedsCardContainer
			showActionButtons={showActionButtons}
			variant="secondary"
			title={title}
			subTitle={subTitle}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			renderFooterButtons={handleRenderActionButtons}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={renderContent}
			sharingUrl={sharingUrl}
		/>
	)
}

export default OrganizationCard

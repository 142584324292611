import { Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CancelOutlined, FaceOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { ColorButton, ShareButton, IconButton, ModalWithSubmittedPopup, OutlinedButton } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CannotUnfollowPopper from '@/components/IndividualPage/CannotUnfollowPopper'
import Card from '@/components/IndividualPage/Card'
import RequestToChangePositionModal from '@/components/IndividualPage/RequestToChangePositionModal'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import CancelRequestModal from '@/components/UserNetwork/CancelRequestModal'
import { PATHS } from '@/constants'
import { ORG_CENTRAL_URL } from '@/constants/configuration'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { SharedData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import {
	cancelMembershipRequestRequest,
	getLeaveFromOrganizationModal,
	getUserMembership,
	setLeaveFromOrganizationModal,
} from '@/store/organizations'
import { orgTypes } from '@/utils/organizationRoles'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		justifyContent: 'space-between',
	},
	responseButton: {
		height: '50px',
		width: '48%',
		marginRight: 10,
	},
	feedbackButton: {
		minWidth: 50,
		height: 50,
	},
	cardRoot: {
		padding: '2vh 2vw 0 2vw',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '1vh 0',
		width: '50%',
	},
	tab: {
		marginTop: 0,
	},
	outlinedButton: {
		color: theme.palette.text.primary,
	},
	optionsContainer: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginTop: 10,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	requestedToJoinMark: {
		width: '100%',
		height: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		borderRadius: 7,
	},
}))

interface OrganizationTitleCardProps {
	title: string
	tabs: string[]
	handleChangeTab: (tab: number) => void
	followed?: boolean
	dataLake?: boolean
	externalUrl?: string
	handleToggleResponse: () => void
	handleCreateRequestToJoin: () => void
	handleClaimMembership: () => void
	handleRequestToChangePosition: (newMembershipTypeId: orgTypes) => void
	activeTab: number
	sharedData?: SharedData
	id: string
	userRequestedToJoin?: boolean
	userRole?: ORGANIZATION_ROLE
	userMembershipTypeId?: orgTypes
	canLeaveOrganization: boolean
}

const OrganizationTitleCard = ({
	title = '',
	followed = false,
	handleToggleResponse,
	tabs,
	handleChangeTab,
	activeTab,
	sharedData = {},
	id,
	dataLake,
	externalUrl,
	handleCreateRequestToJoin,
	handleClaimMembership,
	handleRequestToChangePosition,
	userRequestedToJoin = false,
	userRole,
	userMembershipTypeId,
	canLeaveOrganization,
}: OrganizationTitleCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const leaveOrganizationModalState = useSelector(getLeaveFromOrganizationModal)
	const userInfo = useSelector(getUserInfo)
	const userMembership = useSelector(getUserMembership(userInfo.id))

	const sharingUrl = PATHS.APP.ORGANIZATIONS_SINGLE(id)

	const [
		{
			isOpenRequestInformationModal,
			isOpenRequestToJoinModal,
			isOpenJoinToOrgPopper,
			isOpenRequestToChangePositionModal,
			isOpenRolePopper,
			isOpenCancelRequestModal,
		},
		setState,
	] = useState({
		isOpenRequestInformationModal: false,
		isOpenRequestToJoinModal: false,
		isOpenJoinToOrgPopper: false,
		isOpenRolePopper: false,
		isOpenRequestToChangePositionModal: false,
		isOpenCancelRequestModal: false,
	})
	const [unfollowAnchorEl, setUnfollowAnchorEl] = useState<any>(null)

	const joinButtonAnchorRef = useRef<HTMLButtonElement>(null)
	const roleButtonAnchorRef = useRef<HTMLButtonElement>(null)
	const joinToOrgPopperRef = useRef<any>(null)
	const rolePopperRef = useRef<any>(null)

	const handleClickRequestToJoin = () => {
		if (dataLake) {
			const link = externalUrl || ORG_CENTRAL_URL
			window.open(link, '_blank')
		} else {
			setState((prev) => ({
				...prev,
				isOpenRequestToJoinModal: true,
			}))
		}
	}
	const renderButtons = useMemo(
		() => [
			{
				label: 'Request information',
				icon: null,
				onClick: () => {
					setState((prev) => ({
						...prev,
						isOpenRequestInformationModal: true,
					}))
				},
			},
			{
				label: 'Request to join',
				icon: null,
				onClick: () => {
					handleClickRequestToJoin() //FIXME - Make this one line
				},
			},
		],
		// @TODO: #esLint need to be addressed properly
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const handleToggleJoinToOrgPopper = (e: any) => {
		e.stopPropagation()
		setState((prev) => ({
			...prev,
			isOpenJoinToOrgPopper: !prev.isOpenJoinToOrgPopper,
		}))
	}

	const handleCancelRequest = () => {
		dispatch(cancelMembershipRequestRequest(id))
	}

	const handleOpenCancelRequestModal = () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
		}))
	}

	const handleCloseCancelRequestModal = () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
		}))
	}

	const handleToggleUnfollowPopper = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation()
		if (unfollowAnchorEl) {
			setUnfollowAnchorEl(null)
		} else {
			setUnfollowAnchorEl(event.currentTarget)
		}
	}

	const handleCloseUnfollowPopper = () => {
		setUnfollowAnchorEl(null)
	}

	const handleToggleRolePopper = (e: any) => {
		e.stopPropagation()
		setState((prev) => ({
			...prev,
			isOpenRolePopper: !prev.isOpenRolePopper,
		}))
	}

	const handleToggleChangePositionModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRequestToChangePositionModal: !prev.isOpenRequestToChangePositionModal,
		}))
	}

	const handleToggleLeaveFromOrganizationModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRolePopper: !prev.isOpenRolePopper,
		}))

		dispatch(
			setLeaveFromOrganizationModal({
				isOpen: !leaveOrganizationModalState.isOpen,
				orgId: id,
			}),
		)
	}

	const handleClickPopperButton = (callback: (e: any) => void) => (e: any) => {
		setState((prev) => ({
			...prev,
			isOpenJoinToOrgPopper: false,
			isOpenRolePopper: false,
		}))
		callback(e)
	}

	const onRequestToChangePosition = (membershipTypeId: orgTypes) => {
		handleRequestToChangePosition(membershipTypeId)
		handleToggleChangePositionModal()
	}

	const handleCloseJoinToOrgPopper = (event: React.MouseEvent<EventTarget>) => {
		if (joinButtonAnchorRef.current && joinButtonAnchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setState((prev) => ({
			...prev,
			isOpenJoinToOrgPopper: false,
		}))
	}

	const handleCloseRolePopper = (event: React.MouseEvent<EventTarget>) => {
		if (roleButtonAnchorRef.current && roleButtonAnchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setState((prev) => ({
			...prev,
			isOpenRolePopper: false,
		}))
	}

	const handleCloseModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRequestToJoinModal: false,
			isOpenRequestInformationModal: false,
		}))
	}

	useOutsideClickCallback(joinToOrgPopperRef, handleCloseJoinToOrgPopper)
	useOutsideClickCallback(rolePopperRef, handleCloseRolePopper)

	return (
		<>
			<CancelRequestModal
				isOpenModal={isOpenCancelRequestModal}
				handleCloseModal={handleCloseCancelRequestModal}
				onSubmit={handleCancelRequest}
			/>
			<RequestToChangePositionModal
				isOpen={isOpenRequestToChangePositionModal}
				userMembershipTypeId={userMembershipTypeId}
				onSubmit={onRequestToChangePosition}
				handleCloseModal={handleToggleChangePositionModal}
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenRequestInformationModal}
				onSubmit={handleCreateRequestToJoin}
				onClose={handleCloseModal}
				title="Request Information"
				content={[
					'Want to learn more about this organization?',
					'Notify the organization by requesting information.',
					'They’ll receive an email about your request',
				]}
				submittedMessage={['We sent an email to the Primary ', 'Contact of this organization.']}
				submitButtonLabel="Request Information"
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenRequestToJoinModal}
				onSubmit={handleClaimMembership}
				onClose={handleCloseModal}
				title="Request To Join"
				content={[
					'If you’d like to join this organization, or if you’re already a member and would like to claim membership, please continue. This organization’s admins will be notified to assist you.',
				]}
				submittedMessage={['You’ve requested to join.']}
				submitButtonLabel="Request to Join"
			/>
			{userRole && <CannotUnfollowPopper anchorEl={unfollowAnchorEl} handleClose={handleCloseUnfollowPopper} userRole={userRole} />}
			<Popper
				ref={joinToOrgPopperRef}
				className={classes.paper}
				open={isOpenJoinToOrgPopper}
				anchorEl={joinButtonAnchorRef.current}
				role={undefined}
				placement="bottom-end"
			>
				<div className={classes.optionsContainer}>
					{renderButtons.map((btn) => (
						<IconButton
							key={btn.label}
							className={classes.popoverButton}
							onClick={handleClickPopperButton(btn.onClick)}
							startIcon={btn.icon}
						>
							{btn.label}
						</IconButton>
					))}
				</div>
			</Popper>
			<Popper
				ref={rolePopperRef}
				className={classes.paper}
				open={isOpenRolePopper}
				anchorEl={roleButtonAnchorRef.current}
				role={undefined}
				placement="bottom-end"
			>
				<div className={classes.optionsContainer}>
					{userRole !== ORGANIZATION_ROLE.SUPER_ADMIN && !userMembership?.dataLake && (
						<IconButton
							className={classes.popoverButton}
							startIcon={<FaceOutlined />}
							onClick={handleClickPopperButton(handleToggleChangePositionModal)}
						>
							Request to change position
						</IconButton>
					)}
					<IconButton
						className={classes.popoverButton}
						startIcon={<CancelOutlined />}
						disabled={!canLeaveOrganization}
						onClick={handleToggleLeaveFromOrganizationModal}
					>
						Leave organization
					</IconButton>
				</div>
			</Popper>
			<Card className={classes.cardRoot}>
				<Typography className={classes.boldText} variant="h2">
					{title}
				</Typography>
				<div className={classes.contentContainer}>
					<NavigationTabs
						activeTabIndex={activeTab}
						centered={false}
						tabsRenderer={(props) =>
							tabs.map((tab, index) => (
								<NavigationTab
									className={classes.tab}
									key={`${tab}-${index}`}
									index={index}
									label={tab}
									onClick={handleChangeTab}
									{...props}
								/>
							))
						}
					/>
					<div className={classes.buttonsContainer}>
						{!followed ? (
							<ColorButton onClick={handleToggleResponse} className={classes.responseButton}>
								Follow
							</ColorButton>
						) : (
							<OutlinedButton
								color={ButtonColors.GREY}
								onClick={userRole ? handleToggleUnfollowPopper : handleToggleResponse}
								className={classNames(classes.responseButton, classes.outlinedButton)}
							>
								Following
							</OutlinedButton>
						)}
						{!userRole ? (
							<>
								{!userRequestedToJoin ? (
									<ColorButton
										ref={joinButtonAnchorRef}
										onClick={handleToggleJoinToOrgPopper}
										color={ButtonColors.GREY}
										className={classes.responseButton}
									>
										Join
									</ColorButton>
								) : (
									<ColorButton
										ref={joinButtonAnchorRef}
										onClick={handleOpenCancelRequestModal}
										color={ButtonColors.LIGHT_BLUE}
										className={classes.responseButton}
									>
										Requested
									</ColorButton>
								)}
							</>
						) : (
							<>
								<OutlinedButton
									ref={roleButtonAnchorRef}
									onClick={handleToggleRolePopper}
									color={ButtonColors.GREY}
									className={classNames(classes.responseButton, classes.outlinedButton)}
								>
									{userRole}
								</OutlinedButton>
							</>
						)}
						<ShareButton className={classes.feedbackButton} variant="secondary" sharedData={sharedData} sharingUrl={sharingUrl} />
					</div>
				</div>
			</Card>
			{userRequestedToJoin && (
				<Card>
					<div className={classes.requestedToJoinMark}>
						<Typography variant="body1">Requested to Join</Typography>
					</div>
				</Card>
			)}
		</>
	)
}

export default OrganizationTitleCard

import PostCard from '@/components/Feeds/PostCard'
import useFeedPostById from '@/features/posts/hooks/useFeedPostById'
import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedPostProps = ISharedEntityProps

const SharedPost: React.FC<ISharedPostProps> = ({ sharedEntityId: id, sharedEntityType: type, skip }) => {
	const { data, isLoading, isUninitialized, isError } = useFeedPostById(id, skip || type !== EntityTypes.post)

	return withErrorHandler({
		type,
		currentType: EntityTypes.post,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? <PostCard shouldSkipViewMonitor={true} {...data} showAsPreview clickable /> : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedPost

import AddMessageButton from '@/components/Messages/AddMessageButton'
import SearchMessagesList from '@/components/Messages/SearchMessagesList'
import { PATHS, STYLES } from '@/constants'
import { loadChatsListRequest, selectChatListIsLoading } from '@/store/messages'
import { Typography, Link as MuiLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: ({ isMobile }: any) => ({
		minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.XL,
		maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.XL,
		borderRight: `solid 1.5px ${theme.palette.border.bold}`,
		paddingTop: 10,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.LG,
			maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.MD,
			maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: 'none',
			maxWidth: '100vw',
		},
	}),
	container: ({ isMobile }: any) => ({
		position: isMobile ? 'inherit' : 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.XL,
		maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.XL,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.LG,
			maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.MD,
			maxWidth: isMobile ? 'none' : STYLES.MESSAGES_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: 'none',
			maxWidth: '100vw',
		},
	}),
	title: {
		fontWeight: 700,
	},
	header: {
		minHeight: 50,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1.5vh 7% 0 7%',
	},
	pageTitle: {
		padding: '0 7%',
		fontSize: '1rem',
		fontWeight: 700,
		margin: `${theme.spacing(2)}px auto`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},

	messagesList: {
		paddingBottom: `calc(${STYLES.LANDING_FOOTER_HEIGHT}vh + 20px)`,
	},
}))

export enum MessagesTabs {
	MY_CHATS = 'My chats',
	JOIN_GROUP = 'Join a group (Beta)',
}

interface MessagesBarProps {
	handleAddNewMessage: () => void
	handleAddNewGroup: () => void
	showAddNewMessageButton?: boolean
	isMobile?: boolean
}

const MessagesBar = ({ handleAddNewMessage, handleAddNewGroup, showAddNewMessageButton = true, isMobile = false }: MessagesBarProps) => {
	const classes = useStyles({ isMobile })
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()
	const isLoading = useSelector(selectChatListIsLoading)

	const pageTitle = useMemo(() => {
		if (pathname !== PATHS.APP.DISCOVER_GROUP) {
			return (
				<MuiLink component={Link} to={PATHS.APP.DISCOVER_GROUP}>
					&lt; Discover group chats
				</MuiLink>
			)
		} else {
			return 'My Chats'
		}
	}, [pathname])

	useEffect(() => {
		dispatch(loadChatsListRequest())
	}, [dispatch])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography className={classes.title} variant="h2">
						Chats
					</Typography>
					{showAddNewMessageButton && <AddMessageButton onStartDirectMessage={handleAddNewMessage} onStartNewGroup={handleAddNewGroup} />}
				</div>
				<div className={classes.pageTitle}>{pageTitle}</div>
				<SearchMessagesList listClassName={classes.messagesList} isLoading={isLoading} />
			</div>
		</div>
	)
}

export default MessagesBar

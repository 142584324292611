export type TFilterTreeNodes = Record<string | number, any>

export type FnRecursiveTree = (nodes: TFilterTreeNodes, code: string | number) => TFilterTreeNodes

export const createTreeMap = (self: string, parent: string, data: any[]) => {
	const nodesMap = data.reduce((acc, d) => {
		const selfKey = d[self]
		acc[selfKey] = {
			...d,
			selected: false,
			children: [],
			selectedCount: 0,
		}
		return acc
	}, {})

	data.forEach((d) => {
		const selfKey = d[self]
		const parentKey = d[parent]
		if (nodesMap[parentKey]) {
			nodesMap[parentKey].children.push(selfKey)
		}
	})
	return nodesMap
}

export const selectChildrenRecursively: FnRecursiveTree = (nodes, code) => {
	const currentNode = nodes[code]
	if (!currentNode) {
		return nodes
	}
	currentNode.selected = true
	currentNode.indeterminate = false
	currentNode.selectedCount = currentNode.children.length
	if (currentNode.children) {
		currentNode.children.forEach((key) => selectChildrenRecursively(nodes, key))
	}

	return nodes
}

export const deselectChildrenRecursively: FnRecursiveTree = (nodes, code) => {
	const currentNode = nodes[code]
	if (!currentNode) {
		return nodes
	}
	currentNode.selected = false
	currentNode.selectedCount = 0
	if (currentNode.children) {
		currentNode.children.forEach((k) => {
			nodes[k].selected = false
			nodes[k].selectedCount = 0

			deselectChildrenRecursively(nodes, k)
		})
	}

	return nodes
}

export const deselectParentsRecursively: FnRecursiveTree = (nodes, code) => {
	const currentNode = nodes[code]
	if (!currentNode) {
		return nodes
	}

	const { parent } = currentNode
	if (nodes[parent]) {
		const indeterminate = nodes[parent].children.some((child) => nodes[child].selected || nodes[child].indeterminate)
		nodes[parent].indeterminate = indeterminate
		nodes[parent].selected = false
		nodes[parent].selectedCount = nodes[parent].selectedCount - 1
		deselectParentsRecursively(nodes, parent)
	}

	return nodes
}

export const selectParentsRecursively: FnRecursiveTree = (nodes, code) => {
	const currentNode = nodes[code]

	if (!currentNode) {
		return nodes
	}
	const { parent } = currentNode
	if (nodes[parent]) {
		const selectedCount = nodes[parent].children.filter((child) => nodes[child].selected).length
		const indeterminate = nodes[parent].children.some((child) => nodes[child].selected || nodes[child].indeterminate)
		const parentIsSelected = selectedCount === nodes[parent].children.length

		nodes[parent].indeterminate = parentIsSelected ? false : indeterminate
		nodes[parent].selectedCount = selectedCount
		nodes[parent].selected = selectedCount === nodes[parent].children.length
		selectParentsRecursively(nodes, parent)
	}

	return nodes
}

export const normalizeSelectedTreeNodes = (nodes: TFilterTreeNodes, rootNode: string | number, selectedNodes = []) => {
	const currentNode = nodes[rootNode]
	if (!currentNode) {
		return selectedNodes
	}

	const { id, left, right, selected, children } = currentNode
	if (selected) {
		return selectedNodes.push({
			id,
			left,
			right,
		})
	}
	children.forEach((child) => selectedNodes.concat(normalizeSelectedTreeNodes(nodes, child, selectedNodes)))

	return selectedNodes
}

import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { ColorButton, CommonModalCard, IconButton, IconWrapper, InfoModalCard, Modal } from '@/components'
import { useAppDispatch } from '@/store'
import { updateEventVolunteerShifts } from '@/store/events'
import { formatDateNew } from '@/utils/dateTime2'
import { isUserSubmittedToShift } from '@/utils/events'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	submitButton: {
		width: 200,
		height: 50,
		margin: '20px 0',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderBottom: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	itemPopupButtonActive: {
		color: theme.palette.primary.main,
	},
	textContainer: {
		textAlign: 'center',
		padding: '20px 8vw',
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
	checkText: {
		margin: '2vh 3vw 3vh 3vw',
	},
}))

interface CancelShiftsModalProps {
	eventId: string
	shifts: any[]
	userId: number
	isOpenCancelShiftsModal: boolean
	handleToggleCancelShiftsModal: () => void
}

const CancelShiftsModal = ({
	eventId,
	shifts = [],
	userId,
	isOpenCancelShiftsModal,
	handleToggleCancelShiftsModal,
}: CancelShiftsModalProps) => {
	const classes = useStyles()
	const theme = useTheme()
	const dispatch = useAppDispatch()

	const [checkBoxes, setCheckBoxes] = useState(shifts)
	const [showCanceledPopup, setShowCanceledPopup] = useState(false)

	const handleCheckBoxChange = (id: string) =>
		setCheckBoxes(checkBoxes.map((checkBox) => (checkBox.id === id ? { ...checkBox, checked: !checkBox.checked } : checkBox)))

	const handleClickSubmitShifts = () => {
		dispatch(
			updateEventVolunteerShifts({
				eventId,
				submittedIds: checkBoxes.filter((check) => check.submitted && !check.checked).map((check) => check.id),
			}),
		)
		setShowCanceledPopup(true)
	}

	const handleCloseModal = () => {
		handleToggleCancelShiftsModal()
		setShowCanceledPopup(false)
	}

	useEffect(() => {
		setCheckBoxes(
			shifts.map((shift, index) => (isUserSubmittedToShift(shift, userId) ? { ...shift, submitted: true, checked: false, index } : shift)),
		)
	}, [shifts, userId])

	const disabled = !checkBoxes.some((shift) => shift.checked)

	return (
		<Modal isOpen={isOpenCancelShiftsModal} onClose={handleCloseModal}>
			{!showCanceledPopup ? (
				<CommonModalCard title="Cancel Shifts" onClose={handleCloseModal}>
					<Typography className={classes.textContainer}>Which shifts would you like to cancel?</Typography>
					{checkBoxes
						.filter((checkBox) => checkBox.submitted)
						.map((shift) => (
							<IconButton
								key={`Shift ${shift.index + 1}`}
								className={classNames(classes.itemPopupButton, shift.checked ? classes.itemPopupButtonActive : '')}
								onClick={() => handleCheckBoxChange(shift.id)}
							>
								{`Shift ${shift.index + 1} - ${formatDateNew(shift.startDate, 'timeSimple')} - ${formatDateNew(
									shift.endDate,
									'timeSimple',
								)}`}
							</IconButton>
						))}
					<ColorButton className={classes.submitButton} onClick={handleClickSubmitShifts} disabled={disabled}>
						Cancel
					</ColorButton>
				</CommonModalCard>
			) : (
				<InfoModalCard
					icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
					onClose={handleCloseModal}
				>
					<Typography className={classes.checkText}>You’ve canceled your shifts.</Typography>
				</InfoModalCard>
			)}
		</Modal>
	)
}

export default CancelShiftsModal

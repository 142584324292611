import InternshipCard from '@/components/ExperienceCards/InternshipCard'
import useJobByIdQuery from '@/features/job/hooks/useJobByIdQuery'
import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedJobProps = ISharedEntityProps

const SharedJob: React.FC<ISharedJobProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useJobByIdQuery(id, skip || type !== EntityTypes.job)

	return withErrorHandler({
		type,
		currentType: EntityTypes.job,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<InternshipCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.photoUrl}
				employer={data.employer}
				positionPaid={data.positionPaid}
				status={data.responseStatus}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedJob

import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { ComponentType, useMemo } from 'react'

import { Modal } from '@/components'
import useToggleValue from '@/hooks/useToggleValue'
import CardSelectButton from '../CardSelect/CardSelectButton'
import CardSelectOptionsContainer from '../CardSelect/CardSelectOptionsContainer'
import { TOption } from '@/store/types'

const useStyles = makeStyles(() => ({
	button: {
		width: 'auto',
	},
	icon: {
		width: 25,
		height: 25,
	},
	cardHeader: {
		borderBottom: 'none',
	},
	rootCard: {
		minWidth: 530,
	},
}))

export type IModalOptionPickerProps = {
	cardClasses?: {
		header?: string
		card?: string
		option?: string
	}
	Icon?: ComponentType<any>
	options: TOption[]
	modalTitle?: string
	modalSubTitle?: string
	buttonDefaultLabel?: string
	selected?: number
	buttonClassName?: string
	onChange: (v?: number | null, subOption?: number) => void
}

export const ModalOptionPicker: React.FC<IModalOptionPickerProps> = ({
	cardClasses,
	selected,
	options,
	buttonClassName,
	onChange,
	modalSubTitle,
	modalTitle,
	buttonDefaultLabel,
	Icon,
}) => {
	const classes = useStyles()

	const [isOpenModal, handleToggleModal] = useToggleValue(false)
	const selectedLabel = selected ? options.find((option) => option.value === selected)?.label : undefined

	const modalClasses = useMemo(() => {
		if (cardClasses) {
			return cardClasses
		} else {
			return {
				header: classes.cardHeader,
				card: classes.rootCard,
			}
		}
	}, [cardClasses, classes.cardHeader, classes.rootCard])

	return (
		<>
			<Modal isOpen={isOpenModal} onClose={handleToggleModal}>
				<CardSelectOptionsContainer
					classnames={modalClasses}
					subTitle={modalSubTitle}
					title={modalTitle}
					options={options}
					value={selected}
					onChange={onChange}
					shouldAcceptChanges
					handleClose={handleToggleModal}
				/>
			</Modal>
			<CardSelectButton
				variant="filled"
				className={classNames(buttonClassName, classes.button)}
				defaultLabel={buttonDefaultLabel}
				label={selectedLabel}
				startIcon={Icon ? <Icon className={classes.icon} /> : null}
				onClick={handleToggleModal}
			/>
		</>
	)
}

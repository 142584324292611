import { makeStyles } from '@material-ui/core'
import { Divider, Theme, Typography } from '@mui/material'
import { PaperPlaneTilt } from '@phosphor-icons/react'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/query'
import React, { useCallback, useEffect } from 'react'
import * as yup from 'yup'

import { OutlinedButton } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'
import { toText } from '@/utils/toHTML'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useForm } from 'react-hook-form'
import { useFormValidation } from '../../hooks/useFormValidation'
import { FieldKind, FieldList, FormField, ModalKind, ModalState } from '../../types/dashboardTypes'
import { EditDateTime } from '../dataTable/templates/GridDateTimeTemplate'
import FormFieldContainer from './FormFieldContainer'
import { FormInput, TextArea } from './FormText'

export interface StyleProps {
	variant: 'primary' | 'secondary'
	isOpen: boolean
	hasValue: boolean
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	postButton: {
		border: 'none',
		height: 68,
		width: 200,
		alignSelf: 'flex-end',
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
		'&:disabled': {
			border: 'none',
			opacity: 0.4,
		},
		'&>span': {
			color: theme.palette.primary.main,
			fontSize: '1.4rem',
		},
	},
	form: {
		display: 'flex',
		padding: theme.spacing(4),
		boxSizing: 'border-box',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		justifySelf: 'flex-end',
		width: '100%',
		flexGrow: 1,
	},
	formFields: {
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: `${theme.spacing(2)}px 0`,
	},
}))

export type FormProps<T extends FieldList> = {
	createMutation: MutationTrigger<MutationDefinition<unknown, BaseQueryFn<any, unknown, unknown, {}, {}>, string, unknown>>
	setModalState: React.Dispatch<React.SetStateAction<ModalState>>
	fields: T
	additionalSubmissionContext?: Record<string, unknown>
	title?: string
}

const Form = <T extends FieldList>(props: FormProps<T>) => {
	const { createMutation, setModalState, fields, additionalSubmissionContext } = props
	const classes = useStyles({ variant: 'primary', isOpen: false, hasValue: false })

	const { getValidationSchema } = useFormValidation({ fields })
	const {
		watch,
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<any>({
		resolver: yupResolver(getValidationSchema()),
		mode: 'all',
		criteriaMode: 'firstError',
	})
	const formValues = watch()

	const onSubmit = handleSubmit(async () => {
		const sanitizedFieldValues = Object.values(fields).reduce(
			(acc, { kind, name }, i) => ({ ...acc, [name]: kind === FieldKind.TEXT_AREA ? toText(formValues[name].data) : formValues[name] }),
			additionalSubmissionContext,
		)
		createMutation(sanitizedFieldValues)
		setModalState({ isOpen: false, modalKind: ModalKind.FORM })
	})

	const renderFormField = useCallback(
		(renderField: FormField<FieldKind>, index: number): JSX.Element => {
			const { kind, isRequired, placeholder = '', name, label } = renderField

			let renderComponent: React.ComponentType<any>
			let renderProps: any // @TODO: typify this
			switch (kind) {
				case FieldKind.INPUT:
					renderComponent = FormInput
					renderProps = { placeholder }
					break
				case FieldKind.TEXT_AREA:
					renderComponent = TextArea
					renderProps = { placeholder }
					break
				case FieldKind.DATE:
				case FieldKind.DATE_TIME:
					const DateComponent = kind === FieldKind.DATE ? DatePicker : EditDateTime

					// set minute to the next 5 minute interval
					const currentTime = DateTime.now()
					const nextFiveMinuteInterval = Math.ceil(currentTime.minute / 5) * 5
					const nextFiveMinuteIntervalDateTime = currentTime.set({ minute: nextFiveMinuteInterval })

					renderComponent = DateComponent
					renderProps = {
						disablePast: true,
						closeOnSelect: true,
						defaultValue: nextFiveMinuteIntervalDateTime.toJSDate(),
					}
					break
				default:
					renderComponent = TextArea
					renderProps = { placeholder }
					break
			}

			return (
				<FormFieldContainer
					key={index}
					control={control}
					label={label}
					isRequired={isRequired}
					name={name}
					renderComponent={renderComponent}
					renderProps={renderProps}
				/>
			)
		},
		[control],
	)

	return (
		<form className={classes.form}>
			<Typography variant="h4" sx={{ paddingBottom: 2, alignSelf: 'flex-start' }}>
				{props.title ?? 'Create Entity'}
			</Typography>
			<Divider orientation="horizontal" component={'div'} sx={{ height: '1px', width: '30%', display: 'block', alignSelf: 'flex-start' }} />
			<ul className={classes.formFields}>{fields.map((field, i) => renderFormField(field, i))}</ul>
			<div className={classes.buttonContainer}>
				<OutlinedButton className={classes.postButton} onClick={onSubmit} disabled={!isValid}>
					<PaperPlaneTilt size={ICON_SIZES.md} fontWeight="bold" style={{ marginRight: 10 }} /> Schedule
				</OutlinedButton>
			</div>
		</form>
	)
}

export default Form

import MenuIconButton from '@/components/Buttons/IconButton'
import { ButtonColors } from '@/components/Buttons/types'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { Popper, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	root: ({ buttonColor }: { buttonColor: ButtonColors }) => {
		const color = theme.palette.buttonColors[buttonColor ?? ButtonColors.PRIMARY]

		return {
			'&:hover': {
				color: color.textColor,
				backgroundColor: color.main,
			},
		}
	},
	label: {
		position: 'absolute',
		fontSize: 15,
		width: '1%',
		right: '-1%',
		textAlign: 'left',
	},
	tooltip: {
		fontSize: 16,
		padding: '5px 15px',
		borderRadius: 2,
		backgroundColor: theme.palette.text.secondary,
	},
	popper: {
		opacity: 0.95,
	},
	paper: {
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
}))

interface ShareButtonWithTooltipProps {
	tooltipTitle: string
	label?: string
	children: React.ReactNode
	color?: ButtonColors
	renderButtons: {
		className?: string
		label: string
		onClick: (e: any) => void
		icon?: React.ReactNode
	}[]
	disabled?: boolean
}

const ShareButtonWithTooltip = ({
	tooltipTitle,
	label,
	children,
	color = ButtonColors.LIGHT_BLUE,
	renderButtons,
	disabled,
}: ShareButtonWithTooltipProps) => {
	const classes = useStyles({ buttonColor: color })

	const [open, setOpen] = useState(false)

	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleToggle = (e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const handleCloseAfterItemClick = (callback: (e: any) => void) => (e: any) => {
		handleToggle(e)
		callback(e)
	}

	useOutsideClickCallback(popperRef, handleClose)

	return (
		<>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					{renderButtons.map((btn) => (
						<MenuIconButton
							key={btn.label}
							className={classNames(classes.popoverButton, btn.className)}
							onClick={handleCloseAfterItemClick(btn.onClick)}
							startIcon={btn.icon}
						>
							{btn.label}
						</MenuIconButton>
					))}
				</div>
			</Popper>
			<Tooltip
				title={tooltipTitle}
				placement="top"
				classes={{
					tooltip: classes.tooltip,
					popper: classes.popper,
				}}
			>
				<IconButton ref={anchorRef} onClick={handleToggle} className={classes.root} disabled={disabled}>
					{children}
					{label && <span className={classes.label}>{label}</span>}
				</IconButton>
			</Tooltip>
		</>
	)
}

export default ShareButtonWithTooltip

import { STYLES } from '@/constants'
import { Button, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

interface StyleProps {
	active: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ active }) => ({
		minHeight: 55,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: 5,
		padding: '0 15px',
		backgroundColor: active ? theme.palette.primary.light : theme.palette.specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: active ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: active ? theme.palette.primary.light : theme.palette.specialColors.transparent,
		},
		fontSize: theme.typography.body1.fontSize,
	}),
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		width: '-webkit-fill-available; width: -moz-available',
		alignItems: 'center',
	},
	icon: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		height: 'fit-content',
		width: 66,
		minWidth: 66,
	},
	textContainer: {
		alignSelf: 'center',
	},
}))

export interface SideBarNavigation {
	label: string
	icon: React.ReactNode | null
	path: string
	external?: boolean
}

export interface NavigationButtonClassNames {
	root?: string
	contentContainer?: string
	icon?: string
	label?: string
}

export interface NavigationButtonProps {
	classnames?: NavigationButtonClassNames
	navigation: SideBarNavigation
	onClick?: (path: string) => void
	hideLabel?: boolean
}

const NavigationButton = ({
	classnames = {
		root: '',
		contentContainer: '',
		icon: '',
		label: '',
	},
	navigation: { icon, label, path, external },
	onClick = () => {},
	hideLabel = false,
}: NavigationButtonProps) => {
	const { pathname } = window.location

	const active = pathname.includes(path)

	const classes = useStyles({ active })

	const handleOnClick = () => (external ? window.open(path, '_tab') : onClick(path))

	return (
		<Button className={classNames(classnames.root, classes.root)} onClick={handleOnClick}>
			<div className={classNames(classnames.contentContainer, classes.contentContainer)}>
				<div className={classNames(classnames.icon, classes.icon)}>{icon}</div>
				{!hideLabel && (
					<div className={classes.textContainer}>
						<Typography className={classnames.label}>{label}</Typography>
					</div>
				)}
			</div>
		</Button>
	)
}

export default NavigationButton

import { yupResolver } from '@hookform/resolvers/yup'
import { Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ColorButton, CommonModalCard, CommonSelect, DatePickerSelect, Label, OutlinedButton } from '@/components'
import FieldErrorMessage from '@/components/FieldErrorMessage'
import { ERRORS, STYLES } from '@/constants'
import { getTime, getTimeOptionsArray, mergeDateTime } from '@/utils/dateTime2'
import FormCardSubTitle from './CardSubtitle'

const useStyles = makeStyles((theme) => ({
	rootCard: {
		minWidth: 530,
		maxWidth: 660,
		width: '70vw',
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	cardHeader: {
		borderBottom: 'none',
	},
	rootContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	contentContainer: {
		maxHeight: '50vh',
		overflow: 'auto',
		paddingBottom: 10,
	},
	footer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	footerButtons: {
		display: 'flex',
		gap: 16,
	},
	secondaryButton: {
		color: `${theme.palette.primary.main}`,
	},
	blockContainer: {
		padding: 15,
		borderTop: `solid 1px ${theme.palette.divider}`,
	},
	activeSinceControls: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingBottom: theme.spacing(10),
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
	dateTimeLabel: {
		width: '40%',
		marginRight: '5%',
	},
	datePicker: {
		'& > button': {
			height: 48,
		},
	},
	formSelect: {
		width: '40%',
		height: 48,
		marginRight: 15,
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	timeSelect: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 48,
		marginRight: 15,
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	tooltip: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.specialColors.blacktransparent80,
		padding: '5px 20px',
	},
	popover: {
		pointerEvents: 'none',
	},
}))
const schema = yup.object().shape({
	date: yup.date().required(),
	time: yup.string().required(),
})

export default function Form({ value, onChange, onClose }) {
	const [openTooltip, setOpenTooltip] = useState(false)

	const defaultValues = useMemo(() => {
		if (value) {
			return {
				date: value,
				time: getTime(value),
			}
		}
	}, [value])

	const classes = useStyles()

	const {
		watch,
		control,
		formState: { errors, isValid },
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'all',
		criteriaMode: 'firstError',
		defaultValues,
	})

	const date = watch('date')
	const time = watch('time')

	const fullDate = useMemo(() => {
		if (date && time) {
			return mergeDateTime(new Date(date), time)
		}
		return null
	}, [date, time])

	const anchorRef = useRef<HTMLButtonElement | null>(null)

	const canSubmit = isValid && fullDate > new Date()

	const shouldShowTooltip = !canSubmit && openTooltip

	const handleOpenTooltip = useCallback(() => {
		setOpenTooltip(true)
	}, [])
	const handleCloseTooltip = useCallback(() => {
		setOpenTooltip(false)
	}, [])

	const timeOptions = useMemo(() => {
		let changedDate = undefined
		if (date) {
			changedDate = time ? mergeDateTime(new Date(date), time) : new Date(date)
		}
		return getTimeOptionsArray(changedDate, new Date())
	}, [date, time])

	const handleSave = useCallback(() => {
		if (canSubmit) {
			onChange(fullDate)
			onClose()
		}
	}, [canSubmit, fullDate, onChange, onClose])

	return (
		<CommonModalCard
			title="Schedule Post"
			titleContent={<FormCardSubTitle labelPrefix="Will post on" date={fullDate} />}
			onClose={onClose}
			classnames={{ header: classes.cardHeader, card: classes.rootCard }}
		>
			<div className={classes.rootContainer}>
				<div className={classNames(classes.blockContainer, classes.activeSinceControls)}>
					<Controller
						name="date"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="Date"
								required
							>
								<DatePickerSelect
									closeOnSelect
									allowSameDate
									value={value}
									onChange={onChange}
									disablePast
									defaultLabel="Select Date"
									className={classes.datePicker}
								/>
								<FieldErrorMessage hasError={!!errors['date']} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
					<Controller
						name="time"
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="Time"
								required
							>
								<CommonSelect
									classnames={{
										input: classNames(classes.timeSelect, classes.formSelect),
									}}
									value={value}
									onChange={onChange}
									options={timeOptions}
									placeholder="Start Time"
								/>
								<FieldErrorMessage hasError={!!errors['time']} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				</div>
				{anchorRef?.current && (
					<Popover
						className={classes.popover}
						open={shouldShowTooltip}
						anchorEl={anchorRef?.current}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						disableRestoreFocus
					>
						<Typography className={classes.tooltip}>Provide Proper Date</Typography>
					</Popover>
				)}
				<div className={classNames(classes.footer, classes.blockContainer)}>
					<div className={classes.footerButtons}>
						<OutlinedButton className={classes.secondaryButton} onClick={onClose}>
							Back
						</OutlinedButton>
						<ColorButton onClick={handleSave} ref={anchorRef} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
							Next
						</ColorButton>
					</div>
				</div>
			</div>
		</CommonModalCard>
	)
}

import { PATHS } from '@/constants'
import { isEngage } from '@/constants/configuration'
import getNameOfPathBuilder from '../../../utils/getNameOfPathBuilder'
import { AnalyticsFrame } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { Layout } from '../components/Layout'
import { Route } from '../routes/config'
import { DashboardType } from '../types/dashboardTypes'

const nameOfActivity = getNameOfPathBuilder(isEngage())

const PathBuilderMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title={nameOfActivity}
				subHeader={
					<>
						Welcome to the {nameOfActivity} dashboard! Find aggregate information about user responses to {nameOfActivity}. For individual
						responses to {nameOfActivity}, please see <a href={`${PATHS.ADMIN}/${Route.Users}/table`}>User Details.</a>
					</>
				}
			>
				<AnalyticsFrame dashboardType={DashboardType.PATH_BUILDER} />
			</CommonCard>
		</Layout>
	)
}
export default PathBuilderMainPage

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

interface SearchResearchParams {
	q?: string
	page: number
	userId: number
	campusId?: number
	areaId?: number[]
	positionTypeId?: number[]
	workSettingsId?: number[]
	termOfAvailabilityId?: number[]
	schoolId?: number
}

const searchResearch = async ({
	q,
	page,
	userId,
	campusId,
	areaId,
	positionTypeId,
	workSettingsId,
	termOfAvailabilityId,
	schoolId,
}: SearchResearchParams) => {
	const url = `${schoolId}/research/search`
	const params = {
		q,
		page,
		userId,
		campusId,
		areaId,
		positionTypeId,
		workSettingsId,
		termOfAvailabilityId,
		perPage: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get(url, { params })
}

export default searchResearch

import { LinearProgress, LinearProgressProps, Theme, makeStyles } from '@material-ui/core'
import React from 'react'

interface StyleProps {
	height?: number
	borderRadius?: number
	barColor?: string
	primaryColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ borderRadius, height }) => ({
		height,
		borderRadius: borderRadius || 0,
	}),
	colorPrimary: ({ primaryColor }) => ({
		backgroundColor: primaryColor || theme.palette.background.paper,
	}),
	bar: ({ barColor, borderRadius }) => ({
		borderRadius: borderRadius || 0,
		backgroundColor: barColor || theme.palette.primary.main,
	}),
}))

export interface IBorderLinearProgressProps extends StyleProps, Omit<LinearProgressProps, 'classes'> {}

const BorderLinearProgress: React.FC<IBorderLinearProgressProps> = ({ height, borderRadius, barColor, primaryColor, ...muiProps }) => {
	const classes = useStyles({ height, borderRadius, barColor, primaryColor })
	return <LinearProgress classes={classes} {...muiProps} />
}

export default BorderLinearProgress

import settings from '@/constants/http'
import { baseApi } from '../general/baseApi'

import MentionTypeaheadOption from '@/components/Editor/plugins/MentionPlugin/MentionTypeaheadOption'
import { SESSION_TIME } from '@/constants/configuration'
import { TCampus } from '@/features/campus/types/TCampus'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { RootState } from '@/store'
import utils from '@/utils'
import { Dictionary, createSelector } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'
import ExtendFallBackImageParam from '../types/ExtendFallBackImageParam'
import { paginateQuery } from '../utils'
import OrganizationRoleTypeStatus from './types/OrganizationRoleTypeStatus'
import { TUserOrganizationTypes, USER_ORGANIZATIONS_TABS } from './types/UserOrganizationTypes'
import { DtoOrganization } from './types/dto/DtoOrganization'
import DtoOrganizationBranchesResults from './types/dto/DtoOrganizationBranchesResults'
import DtoOrganizationCategoryResults from './types/dto/DtoOrganizationCategoryResults'
import DtoOrganizationFollowerResults from './types/dto/DtoOrganizationFollowersResults'
import DtoOrganizationMembersResults from './types/dto/DtoOrganizationMembersResults'
import DtoOrganizationResults from './types/dto/DtoOrganizationResults'
import DtoUserOrganizationsCount from './types/dto/DtoUserOrganizationsCount'
import GetMembersParams from './types/queryParams/GetMembersParams'
import ReportOrganizationParams from './types/queryParams/ReportOrganizationParams'
import SearchOrganizationEventsParams from './types/queryParams/SearchOrganizationEventsParams'
import SearchOrganizationsParams from './types/queryParams/SearchOrganizationsParams'
import UserOrganizationsCountParams from './types/queryParams/UserOrganizationsCountParams'
import UserOrganizationsParams from './types/queryParams/UserOrganizationsParams'

const API_BASE_URL = '/organizations'

const ORGANIZATION_TAGS = {
	COUNTS: 'counts',
} as const

export const organizationsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(ORGANIZATION_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			searchOrganizations: builder.query<DtoOrganizationResults, ExtendFallBackImageParam<SearchOrganizationsParams>>({
				query: ({ includeFollow = true, withFallBackImage = true, skip = 0, take = settings.SEARCH_LIST_PER_PAGE, ...rest }) => {
					const queryArgs = utils.rtkQueryUtils.generateQueryParams(rest)

					return {
						url: `${API_BASE_URL}?${queryArgs}`,
						params: {
							skip,
							take,
							includeFollow,
							withFallBackImage,
						},
					}
				},
				transformResponse: (response: DtoOrganizationResults) => ({
					items: response.items.map((item) => ({ ...item, entityType: EntityTypes.organization })),
					totalItems: response?.totalItems,
				}),
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}`
					return serializedKey
				},
				...paginateQuery,
			}),
			// Retrieves single organization by id
			getOrganizationById: builder.query<DtoOrganization, { id: DtoOrganization['id']; includeRole?: boolean }>({
				query: ({ id, includeRole = true }) => ({
					url: `${API_BASE_URL}/${id}`,
					params: {
						includeRole,
						includeFollow: true,
						withFallBackImage: true,
					},
				}),
				keepUnusedDataFor: 600,
			}),
			getMembers: builder.query<DtoOrganizationMembersResults, GetMembersParams>({
				query: ({ id, type, ...params }) => ({
					url: `${API_BASE_URL}/${id}/${type}`,
					params,
				}),
				serializeQueryArgs: ({ endpointName, queryArgs: { id, type } }) => {
					const serializedKey = `${endpointName}-${id}-${type}`
					return serializedKey
				},
				...paginateQuery,
			}),

			searchOrganizationEvents: builder.query<DtoOrganizationResults, SearchOrganizationEventsParams>({
				query: ({ id, ...params }) => ({
					url: `${API_BASE_URL}/${id}/events`,
					params,
				}),
				serializeQueryArgs: ({ endpointName, queryArgs: { id } }) => {
					const serializedKey = `${endpointName}-${id}`
					return serializedKey
				},
				merge: (currentCache, { items, totalItems }, { arg: { skip } }) => {
					if (skip > 0) {
						currentCache.items.push(...items)
						currentCache.totalItems = totalItems
					} else {
						currentCache.items = items
						currentCache.totalItems = totalItems
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
			}),
			getOrgCategories: builder.query<DtoOrganizationCategoryResults, void>({
				query: () => ({
					url: `${API_BASE_URL}/categories`,
					params: {
						take: 50,
						skip: 0,
					},
				}),
				keepUnusedDataFor: SESSION_TIME,
			}),
			getOrgBranches: builder.query<DtoOrganizationBranchesResults, void>({
				query: () => ({
					url: `${API_BASE_URL}/branches`,
					params: {
						take: 50,
						skip: 0,
					},
				}),
				keepUnusedDataFor: SESSION_TIME,
			}),
			joinRequest: builder.mutation<any, { id: DtoOrganization['id'] }>({
				query: ({ id }) => ({
					url: `${API_BASE_URL}/${id}/request`,
					method: 'POST',
				}),
				onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
					const patchIndividualOrganizationRole = dispatch(
						organizationsApi.util.updateQueryData('getOrganizationById', { id: String(id) }, (draft) => {
							if (draft) {
								if (!draft.role) {
									draft.role = { type: OrganizationRoleTypeStatus.PENDING }
								}
							}
						}),
					)
					try {
						await queryFulfilled
					} catch {
						patchIndividualOrganizationRole.undo()
					}
				},
			}),
			toggleOrganizationFollow: builder.mutation<void, { id: DtoOrganization['id']; action: 'follow' | 'unfollow' }>({
				query: ({ action, id }) => ({
					url: `${API_BASE_URL}/${id}/${action}`,
					method: 'POST',
					body: {},
				}),
				onQueryStarted: async ({ action, id }, { dispatch, queryFulfilled, getState }) => {
					const userId = (getState() as RootState).AUTH.userInfo.id

					const patchAggregateCount = dispatch(
						organizationsApi.util.updateQueryData('userOrganizationsCount', { userId }, (draft) => {
							draft.followed += action === 'unfollow' ? -1 : 1
						}),
					)

					const patchIndividualOrganization = dispatch(
						organizationsApi.util.updateQueryData('getOrganizationById', { id: String(id) }, (draft) => {
							const newResponse = action === 'follow' ? 'followed' : 'cleared'
							if (draft.follow) {
								draft.follow = { ...draft.follow, response: newResponse }
							} else {
								draft.follow = { response: newResponse }
							}
						}),
					)
					// @TODO: normalize data instead of looping through
					const patchOrgList = dispatch(
						organizationsApi.util.updateQueryData('searchOrganizations', { includeFollow: true }, (draft) => {
							const newResponse = action === 'follow' ? 'followed' : 'cleared'
							draft.items.forEach((org) => {
								if (org.id === id) {
									if (org.follow) {
										org.follow = { ...org.follow, response: newResponse }
									} else {
										org.follow = { response: newResponse }
									}
								}
							})
						}),
					)

					const patchUserOrgList = (dispatch, organizationsApi, userId, id, action, type) => {
						const typeMapping = {
							followed: 'followed',
							admin: 'admin',
							member: 'member',
						}

						return dispatch(
							organizationsApi.util.updateQueryData(
								'userOrganizations',
								{
									userId,
									type: typeMapping[type],
								},
								(draft) => {
									const newResponse = action === 'follow' ? 'followed' : 'cleared'
									draft.items.forEach((org) => {
										if (org.id === id) {
											if (org.follow) {
												org.follow = { ...org.follow, response: newResponse }
											} else {
												org.follow = { response: newResponse }
											}
										}
									})
								},
							),
						)
					}

					// Usage:
					const patchUserOrgListFollows = patchUserOrgList(dispatch, organizationsApi, userId, id, action, 'followed')
					const patchUserOrgListAdmins = patchUserOrgList(dispatch, organizationsApi, userId, id, action, 'admin')
					const patchUserOrgListMembers = patchUserOrgList(dispatch, organizationsApi, userId, id, action, 'member')

					try {
						await queryFulfilled
					} catch {
						patchAggregateCount.undo()
						patchIndividualOrganization.undo()
						patchOrgList.undo()
						patchUserOrgListFollows && patchUserOrgListFollows.undo()
						patchUserOrgListAdmins && patchUserOrgListAdmins.undo()
						patchUserOrgListMembers && patchUserOrgListMembers.undo()
					}
				},
			}),

			followOrganization: builder.mutation<void, { id: DtoOrganization['id'] }>({
				query: ({ id }) => ({
					url: `${API_BASE_URL}/${id}/follow`,
					method: 'POST',
					body: {},
				}),
				invalidatesTags: [ORGANIZATION_TAGS.COUNTS],
			}),
			unfollowOrganization: builder.mutation<void, { id: DtoOrganization['id'] }>({
				query: ({ id }) => ({
					url: `${API_BASE_URL}/${id}/unfollow`,
					method: 'POST',
					body: {},
				}),
				invalidatesTags: [ORGANIZATION_TAGS.COUNTS],
			}),
			getOrganizationFollowers: builder.query<DtoOrganizationFollowerResults, { id: DtoOrganization['id'] }>({
				query: ({ id }) => ({
					url: `${API_BASE_URL}/${id}/followers`,
				}),
			}),

			reportOrganization: builder.mutation<void, ReportOrganizationParams>({
				query: ({ id, ...body }) => ({
					url: `${API_BASE_URL}/${id}/report`,
					method: 'POST',
					body,
				}),
			}),
			userOrganizations: builder.query<DtoOrganizationResults, UserOrganizationsParams>({
				query: ({ userId, type, ...params }) => {
					return {
						url: `/users/${userId}/organizations/${type}`,
						params: { ...params, withFallBackImage: true },
					}
				},
				serializeQueryArgs: ({ endpointName, queryArgs: { type } }) => {
					const serializedKey = `${endpointName}-${type}`
					return serializedKey
				},
				...paginateQuery,
			}),
			userOrganizationsCount: builder.query<DtoUserOrganizationsCount, UserOrganizationsCountParams>({
				query: ({ userId }) => ({
					url: `/users/${userId}/organizations/aggregate`,
				}),
				providesTags: [ORGANIZATION_TAGS.COUNTS],
			}),
		}),
	})

export const {
	useSearchOrganizationsQuery,
	useGetOrganizationByIdQuery,
	useGetMembersQuery,
	useSearchOrganizationEventsQuery,
	useGetOrgCategoriesQuery,
	useJoinRequestMutation,
	useReportOrganizationMutation,
	useFollowOrganizationMutation,
	useUnfollowOrganizationMutation,
	useUserOrganizationsCountQuery,
	useUserOrganizationsQuery,
	useToggleOrganizationFollowMutation,
	useGetOrgBranchesQuery,
	useGetOrganizationFollowersQuery,
} = organizationsApi

// selectors

export const selectOrganizationsCount = () =>
	createSelector(
		(data?: DtoUserOrganizationsCount) => data,
		(data?: DtoUserOrganizationsCount): DtoUserOrganizationsCount => {
			if (data) {
				return (Object.keys(data) as TUserOrganizationTypes[]).reduce((acc, type) => {
					acc[type] = Number(data[type])
					return acc
				}, {} as DtoUserOrganizationsCount)
			} else {
				return Object.keys(USER_ORGANIZATIONS_TABS).reduce((acc, type) => {
					acc[type] = 0
					return acc
				}, {} as DtoUserOrganizationsCount)
			}
		},
	)

export const selectOrgsAsAutocompleteOption = () => {
	const emptyArray = []
	return createSelector(
		(campusMap: Dictionary<TCampus>) => campusMap,
		(_: Dictionary<TCampus>, results?: DtoOrganizationResults) => results,
		(campusMap, results): MentionTypeaheadOption[] => {
			return results?.items && results?.items.length > 0
				? results.items.map((org) => {
						return new MentionTypeaheadOption({
							name: org.name,
							summary: org.summary,
							picture: org.profilePicture,
							entityType: EntityTypes.organization,
							id: org.id,
						})
				  })
				: emptyArray
		},
	)
}

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { PrivacyLevels } from '@/api/http/user/patchUserPrivacySettings'
import { AddButton, ColorButton, CommonModalCard, IconButton, Modal } from '@/components'
import { ProfileInfoItemBadge } from '@/components/ProfileInfoItemBadge'
import SuccessPopup from '@/components/SuccessPopup'
import PopupModalCard from '@/components/UserProfile/EditUserModal/PopupModalCard'
import { PATHS, STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { updateUserSelfReportedItemsRequest } from '@/store/auth'
import { getLeaveFromOrganizationModal, setLeaveFromOrganizationModal } from '@/store/organizations'
import { formatDateRange } from '@/utils/dateTime2'
import { ProfileInfoCardTypes, getSingleItemLabel } from '@/utils/userExperienceItems'
import EmptyProfileInfoItemPlaceholder from './EmptyProfileInfoItemPlaceholder'
import ItemForm from './ItemForm'
import ProfileInfoItem from './ProfileInfoItem'

const useStyles = makeStyles((theme) => ({
	boldText: {
		color: theme.palette.text.primary,
		fontWeight: 700,
	},
	editModalCard: {
		minWidth: '850px',
		height: '57vh',
		width: '60vw',
		padding: 30,
		[theme.breakpoints.down('md')]: {
			minWidth: '300px',
		},
	},
	editModalCardHeader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	editModalCardContent: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	editModalCardItemsList: {
		width: '100%',
		maxHeight: '50vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
	},
	updateProfileButton: {
		padding: '1vh 1vw',
		minWidth: 230,
		fontWeight: 600,
	},
	item: {
		margin: '15px 0 0 0',
	},
	membershipInfoModalCard: {
		textAlign: 'center',
		maxWidth: 350,
		minHeight: 100,
		padding: '25px 60px',
		display: 'flex',
		alignItems: 'center',
	},
	itemPopupContainer: {
		color: theme.palette.text.primary,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '0 0 0 24px',
	},
	itemPopupRootContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '86%',
		padding: '7%',
	},
	itemPopupImage: {
		margin: 0,
		padding: 0,
	},
	itemPopupRoot: {
		display: 'flex',
		margin: 0,
		width: '100%',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		color: theme.palette.text.primary,
		padding: '1.5vh 0',
	},

	itemImage: {
		width: 50,
		height: 50,
		minWidth: 50,
		minHeight: 50,
		margin: 0,
		padding: 0,
	},
	membershipLogo: {
		borderRadius: '50%',
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	closeButton: {
		color: theme.palette.text.secondary,
		cursor: 'pointer',
	},
}))

const createUpdatedObject = (type: ProfileInfoCardTypes, data: any[]) => {
	let updates: any = {}

	switch (type) {
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
			updates.additionalInvolvement = data
			break
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			updates.studyAbroad = data
			break
		case ProfileInfoCardTypes.RESEARCH:
			updates.research = data
			break
		case ProfileInfoCardTypes.INTERNSHIPS:
			updates.internships = data
			break
	}

	return updates
}

interface EditUserExperienceItemsModalProps {
	isOpen: boolean
	items?: any[]
	type: ProfileInfoCardTypes
	onClose: () => void
}

const EditUserExperienceItemsModal = ({ isOpen, type, items = [], onClose }: EditUserExperienceItemsModalProps) => {
	const classes = useStyles()
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const leaveOrganizationModalState = useSelector(getLeaveFromOrganizationModal)

	const [itemsState, setItemsState] = useState(items)

	const hasItems = !!items.length
	const hasStoredItems = !!itemsState.length

	const [editState, setEditState] = useState<{
		isEditOrAddNew: boolean
		itemToEdit: any
		isOpenSuccessPopup: boolean
	}>({
		isEditOrAddNew: false,
		itemToEdit: undefined,
		isOpenSuccessPopup: false,
	})

	const [membershipModals, setMembershipModals] = useState<{
		isOpenLockedInfoModal: boolean
		isOpenEditModal: boolean
		isOpenAddModal: boolean
		itemToEdit: any
	}>({
		isOpenLockedInfoModal: false,
		isOpenEditModal: false,
		isOpenAddModal: false,
		itemToEdit: undefined,
	})

	const handleSaveItem = (isEdit: boolean) => (newItem: any) => {
		let newItems = []
		if (isEdit) {
			newItems = itemsState.map((el) => (el.id === newItem.id ? newItem : el))
		} else {
			newItems = [...items, newItem]
		}

		setItemsState(newItems)
		setEditState({
			isEditOrAddNew: false,
			itemToEdit: undefined,
			isOpenSuccessPopup: false,
		})
	}

	const closeAllMembershipModals = () =>
		setMembershipModals({
			isOpenLockedInfoModal: false,
			isOpenEditModal: false,
			isOpenAddModal: false,
			itemToEdit: undefined,
		})

	const handleOpenAddNewItemModal = () => {
		if (type === ProfileInfoCardTypes.MEMBERSHIPS) {
			setMembershipModals((prev) => ({
				...prev,
				isOpenAddModal: true,
			}))
		} else {
			setEditState({
				isEditOrAddNew: true,
				itemToEdit: undefined,
				isOpenSuccessPopup: false,
			})
		}
	}

	const handleOpenLockedItemModal = () => {
		setMembershipModals((prev) => ({
			...prev,
			isOpenLockedInfoModal: true,
		}))
	}

	const handleOpenEditMembershipItemModal = (itemId: string | number) => () => {
		setMembershipModals((prev) => ({
			...prev,
			isOpenEditModal: true,
			itemToEdit: itemsState.find(({ id }) => id === itemId),
		}))
	}

	const handleOpenEditMembershipPosition = () => {
		push(PATHS.APP.ORGANIZATIONS_SINGLE(membershipModals.itemToEdit?.organizationId))

		closeAllMembershipModals()
		onClose()
	}

	const handleOpenLeaveFromOrgModal = () => {
		dispatch(
			setLeaveFromOrganizationModal({
				isOpen: !leaveOrganizationModalState.isOpen,
				orgId: membershipModals.itemToEdit?.organizationId,
			}),
		)

		closeAllMembershipModals()
		onClose()
	}

	const handleDeleteItem = (id: string | number) => {
		const newItems = itemsState.filter((el) => el.id !== id)

		setItemsState(newItems)
		setEditState({
			isEditOrAddNew: false,
			itemToEdit: undefined,
			isOpenSuccessPopup: true,
		})

		dispatch(updateUserSelfReportedItemsRequest(createUpdatedObject(type, newItems)))
	}

	const handleCloseSuccessDeletePopup = () => {
		setEditState({
			isEditOrAddNew: false,
			itemToEdit: undefined,
			isOpenSuccessPopup: false,
		})
	}

	const handleOpenEditItemModal = (itemId: string | number) => {
		setEditState({
			isEditOrAddNew: true,
			itemToEdit: itemsState.find(({ id }) => id === itemId),
			isOpenSuccessPopup: false,
		})
	}

	const handleCloseEditItemModal = () => {
		setMembershipModals((prev) => ({
			...prev,
			isOpenAddModal: false,
		}))
		setEditState({
			isEditOrAddNew: false,
			itemToEdit: undefined,
			isOpenSuccessPopup: false,
		})

		if (!hasItems && !hasStoredItems) {
			onClose()
		}
	}

	const handleChangePrivacyLevel = (id: string | number, newPrivacyLevel: PrivacyLevels) => {
		setItemsState((prev) => prev.map((item) => (item.id === id ? { ...item, privacyLevel: newPrivacyLevel } : item)))
	}

	const handleSubmitChanges = () => {
		dispatch(updateUserSelfReportedItemsRequest(createUpdatedObject(type, itemsState)))
		onClose()
	}

	useEffect(() => {
		if (isOpen) {
			setItemsState(items)
			setEditState({
				isEditOrAddNew: !hasItems,
				itemToEdit: undefined,
				isOpenSuccessPopup: false,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const showMainModal = isOpen && !editState.isEditOrAddNew
	const showEditItemForm = !!editState.itemToEdit

	return (
		<>
			<Modal isOpen={membershipModals.isOpenAddModal} onClose={closeAllMembershipModals}>
				<CommonModalCard title="Add Membership" onClose={closeAllMembershipModals}>
					<div className={classes.membershipInfoModalCard}>
						<Typography>Ask your organization’s admin to add you as a member, or click “join” on your organization’s page.</Typography>
					</div>
				</CommonModalCard>
			</Modal>
			<Modal isOpen={membershipModals.isOpenLockedInfoModal} onClose={closeAllMembershipModals}>
				<CommonModalCard title="Locked Membership" onClose={closeAllMembershipModals}>
					<div className={classes.membershipInfoModalCard}>
						<Typography>Locked membership can be edited in OrgCentral.</Typography>
					</div>
				</CommonModalCard>
			</Modal>
			<Modal isOpen={membershipModals.isOpenEditModal} onClose={closeAllMembershipModals}>
				<PopupModalCard className={classes.itemPopupImage} contentClassName={classes.itemPopupRoot}>
					<div className={classes.itemPopupRootContainer}>
						<div className={classes.itemPopupContainer}>
							{membershipModals.itemToEdit?.photoUrl ? (
								<img
									className={classNames(classes.itemImage, classes.membershipLogo)}
									src={membershipModals.itemToEdit?.photoUrl}
									alt="Membership Logo"
								/>
							) : (
								<ProfileInfoItemBadge type={type} weight="fill" className={classes.itemImage} />
							)}

							<Typography>{membershipModals.itemToEdit?.title}</Typography>
							<Typography>{membershipModals.itemToEdit?.membershipType}</Typography>
							<Typography className={classes.grayText}>
								{formatDateRange(membershipModals.itemToEdit?.startDate, membershipModals.itemToEdit?.endDate)}
							</Typography>
						</div>
						<div className={classes.closeButton}>
							<CloseIcon onClick={closeAllMembershipModals} />
						</div>
					</div>
					{membershipModals.itemToEdit?.isAdmin && (
						<IconButton className={classes.itemPopupButton} onClick={handleOpenEditMembershipPosition}>
							Edit Position
						</IconButton>
					)}
					<IconButton className={classes.itemPopupButton} onClick={handleOpenLeaveFromOrgModal}>
						Leave
					</IconButton>
				</PopupModalCard>
			</Modal>
			<SuccessPopup
				isOpen={editState.isOpenSuccessPopup}
				onClose={handleCloseSuccessDeletePopup}
				submittedMessage={['This engagement experience has ', 'been removed from your profile.']}
			/>
			<Modal isOpen={editState.isEditOrAddNew} onClose={handleCloseEditItemModal}>
				<ItemForm
					className={classes.editModalCard}
					isEdit={showEditItemForm}
					type={type}
					item={editState.itemToEdit}
					onSubmit={handleSaveItem(showEditItemForm)}
					onClose={handleCloseEditItemModal}
				/>
			</Modal>
			<Modal isOpen={showMainModal} onClose={onClose}>
				<CommonModalCard
					title="Edit Profile"
					onClose={onClose}
					footerContent={
						<ColorButton className={classes.updateProfileButton} disabled={!hasStoredItems} onClick={handleSubmitChanges}>
							Save
						</ColorButton>
					}
					showFooter
				>
					<div className={classes.editModalCard}>
						<div className={classes.editModalCardContent}>
							<div className={classes.editModalCardHeader}>
								<Typography className={classes.boldText}>{getSingleItemLabel(type)}</Typography>
								<AddButton variant="secondary" onClick={handleOpenAddNewItemModal}>
									{`Add ${getSingleItemLabel(type)}`}
								</AddButton>
							</div>
							{hasStoredItems ? (
								<div className={classes.editModalCardItemsList}>
									{itemsState.map((item) => (
										<ProfileInfoItem
											key={`${type}-${item.id}`}
											className={classes.item}
											type={type}
											item={item}
											onClickDelete={handleDeleteItem}
											onClickEdit={handleOpenEditItemModal}
											onClickLockIcon={handleOpenLockedItemModal}
											onChangePrivacy={handleChangePrivacyLevel}
											onClickMoreButton={type === ProfileInfoCardTypes.MEMBERSHIPS ? handleOpenEditMembershipItemModal : undefined}
											outlined
											canEdit
											showInfoIcons
										/>
									))}
								</div>
							) : (
								<EmptyProfileInfoItemPlaceholder type={type} />
							)}
						</div>
					</div>
				</CommonModalCard>
			</Modal>
		</>
	)
}

export default EditUserExperienceItemsModal

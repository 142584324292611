import { inflect, transform } from 'inflection'
import { SetOptional, Writable } from 'type-fest'

type TransformOptions = Writable<Parameters<typeof transform>[1]>

type InflectStringParams = { count: number; subj: string; options?: TransformOptions }

type GenLabelParams = {
	prefix?: string
	suffix?: string
} & SetOptional<InflectStringParams, 'count'>

export const specialCases = {
	studyAbroad: 'Study Abroad',
	autoConnectUsers: 'Auto-Connect User',
} as const
export const defaultOpts = ['underscore', 'humanize', 'titleize'] as TransformOptions

/**
 * A wrapper around the `inflect` and `transform` functions from the `inflection` library.
 * It inflects the provided subject based on the count and transforms it based on the specified options.
 * @param {Object} params - The parameters for inflecting and transforming the string.
 * @param {number} params.count - The count of entities, used for inflection.
 * @param {string} params.subj - The subject to be inflected and transformed.
 * @param {TransformOptions} [params.options=['underscore', 'humanize', 'titleize']] - An array of transformation options to apply to the subject.
 *
 * @returns {string} The inflected and transformed string.
 *
 * @example
 * const inflectedStr = inflectString({
 * 	 count: 5,
 * 	 subj: 'item',
 * 	 options: ['humanize']
 * });
 * // Returns: 'items'
 */
export const inflectString = ({ subj, count, options = defaultOpts }: InflectStringParams) => {
	const inflectedStr = specialCases[subj] ?? inflect(subj, count)

	return transform(inflectedStr, options)
}

/**
 * Generates a dynamic label string based on the provided parameters.
 *
 * This function constructs a label with an optional prefix and suffix, an entity count,
 * and a subject that is inflected and transformed based on the specified options.
 *
 * @param {Object} params - The parameters for constructing the label.
 * @param {string} [params.prefix=''] - An optional string to prepend to the label.
 * @param {number} params.count - The count of entities, used for inflection.
 * @param {string} params.subject - The subject to be inflected and transformed.
 * @param {string} [params.suffix=''] - An optional string to append to the label.
 * @param {TransformOptions} [params.options=['underscore', 'humanize', 'titleize']] -
 *        An array of transformation options to apply to the subject.
 *
 * @returns {string} The constructed label string.
 *
 * @example
 * const label = generateDynamicLabel({
 *     prefix: 'Total',
 *     count: 5,
 *     subject: 'item',
 *     suffix: 'found',
 *     options: ['humanize']
 * });
 * // Returns: 'Total 5 Items found'
 */
export const generateDynamicLabel = ({ prefix = '', count = 0, subj, suffix = '', options = defaultOpts }: GenLabelParams) => {
	const entityCount = count || ''
	const descriptor = inflectString({ subj, count, options })
	return `${prefix ? `${prefix} ` : ''}${entityCount} ${descriptor}${suffix ? ` ${suffix}` : ''}`
}

import { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClickCallback = (ref: any, callback: (e: any) => void) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, callback])
}

export default useOutsideClickCallback

import { ContentPreloader } from '@/components/ContentPreloader'
import { CommonCard } from '@/features/adminConsole/components/CommonCard'
import React, { useMemo } from 'react'
import { useGetAnalyticsFacultyStaffDataQuery } from '../../../../api/usersApi'
import InfoList, { TInfoListItem } from '../../InfoList'
import IProfileCardProps from './IProfileCardProps'

export interface IFacultyStaffCardProps extends IProfileCardProps {}

const FacultyStaffCard: React.FC<IFacultyStaffCardProps> = ({ externalId, title = 'Faculty / Staff', ...commonCardProps }) => {
	const { isLoading, data } = useGetAnalyticsFacultyStaffDataQuery({ externalId })

	const mainInfoListItems = useMemo(() => {
		if (!data) {
			return []
		}
		const items: TInfoListItem[] = []
		if (data.positionTitle) {
			items.push({ primaryText: data.positionTitle, icon: 'position' })
		}
		if (data.academicCollegeName) {
			items.push({ primaryText: data.academicCollegeName, icon: 'book' })
		}

		return items
	}, [data])
	return (
		<CommonCard {...commonCardProps} title={title}>
			{isLoading ? <ContentPreloader /> : <InfoList items={mainInfoListItems} />}
		</CommonCard>
	)
}

export default FacultyStaffCard

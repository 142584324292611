import AddNewCommentRow from '@/components/Feeds/AddNewCommentRow'
import CommentsRow from '@/components/Feeds/CommentsRow'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { campusSelectors } from '@/features/campus/slice'
import usePostComments from '@/features/posts/comments/hooks/usePostComments'
import { commentsSelectors, selectListInfo, selectTotal } from '@/features/posts/comments/slice'
import { DtoComment } from '@/features/posts/comments/types/DtoComment'

import ReportCommentParams from '@/features/posts/comments/types/queryParams/ReportCommentParams'
import { Id } from '@/lib/types'
import { useAppSelector } from '@/store'

import { TextButton } from '@/components/Buttons'
import { prepareCommentData } from '@/utils/transformers'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useMemo, useState } from 'react'
import { ContentPreloader } from '../ContentPreloader'
import { OnChangePluginProps } from '../Editor/plugins/OnChangePlugin'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
	},
	content: {
		margin: '10px 1.5vw',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	placeholder: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2vh 4vw',
	},
	commentRoot: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '15px 1.5vw',
	},
	commentsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column-reverse',
	},
	showMoreContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 30,
	},
	showMoreButton: {
		textDecoration: 'underline',
	},
}))

interface PostCommentsProps {
	postId: Id
	isOpen?: boolean
	onClose?: () => void
}

export const PostComments = ({ postId, isOpen = true }: PostCommentsProps) => {
	const { ...listProps } = usePostComments(postId)
	return <CommentsList {...listProps} postId={postId} isOpen={isOpen} />
}

export interface CommentsListProps extends PostCommentsProps {
	toggleLike: (commentId: DtoComment['id']) => void
	createComment: OnChangePluginProps['onChange']
	reportComment: (reportData: Omit<ReportCommentParams, 'userId'>) => void
	deleteComment: (commentId: DtoComment['id']) => void
	loadMore: () => void
}

interface CommentProps {
	id: DtoComment['id']
	onLikeComment: CommentsListProps['toggleLike']
	onDeleteComment: CommentsListProps['deleteComment']
	onReportComment: CommentsListProps['reportComment']
}

const Comment: React.FC<CommentProps> = ({ id, onDeleteComment, onLikeComment, onReportComment }) => {
	const comment = useAppSelector((state) => commentsSelectors.selectById(state, id))
	const campusMap = useAppSelector(campusSelectors.selectEntities)
	if (!comment) {
		return null
	}

	const normalizedCommentData = prepareCommentData(comment, campusMap)

	return (
		<CommentsRow
			{...normalizedCommentData}
			onDeleteComment={onDeleteComment}
			onReportComment={onReportComment}
			onLikeComment={onLikeComment}
		/>
	)
}

const MemoizedComment = React.memo(Comment)

const CommentsList = ({ createComment, deleteComment, loadMore, reportComment, toggleLike, postId }: CommentsListProps) => {
	const classes = useStyles()
	const { canLoadMore, isLoading } = useAppSelector(selectListInfo)
	const comments = useAppSelector((state) => commentsSelectors.selectAll(state))
	const total = useAppSelector(selectTotal)
	const [addNewComment, setAddNewComment] = useState(false)

	const handleShowAddCommentRow = useCallback(() => setAddNewComment(true), [])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'outlined',
				label: `Comment`,
				onClick: handleShowAddCommentRow,
			},
		],
		[handleShowAddCommentRow],
	)

	const hasComments = comments.length > 0 || total > 0

	return (
		<div className={classes.root}>
			{canLoadMore && (
				<div className={classes.showMoreContainer}>
					<TextButton className={classes.showMoreButton} onClick={loadMore}>
						Show more
					</TextButton>
				</div>
			)}

			{isLoading ? (
				<ContentPreloader />
			) : (
				!hasComments &&
				!addNewComment && (
					<div>
						<EmptyPlaceholder className={classes.placeholder} isEmptyPlaceholder iconKey="comment" buttons={placeholderButtons}>
							<Typography variant={'body1'}>Be the first to comment!</Typography>
						</EmptyPlaceholder>
					</div>
				)
			)}
			{hasComments && (
				<div className={classes.commentRoot}>
					<Typography>Comments</Typography>
					<div className={classes.commentsContainer}>
						{comments.map((comment) => {
							return (
								<MemoizedComment
									key={`${comment.id}`}
									{...comment}
									id={comment.id}
									onReportComment={reportComment}
									onDeleteComment={deleteComment}
									onLikeComment={toggleLike}
								/>
							)
						})}
					</div>
				</div>
			)}
			{(hasComments || addNewComment) && <AddNewCommentRow postId={postId} onAddComment={createComment} />}
		</div>
	)
}

export default CommentsList

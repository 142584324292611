import { Redirect, Route } from 'react-router-dom'
import AppRoute from '@/interfaces/route'
import { PATHS } from '@/constants'

const WebviewRoute = ({ component: Component, allowedRoles, isPrivate, isRestricted, ...rest }: AppRoute) => {
	const isWebview = true
	return (
		<Route
			{...rest}
			render={(props) =>
				isWebview ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: PATHS.ROOT,
						}}
					/>
				)
			}
		/>
	)
}

export default WebviewRoute

import { useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Slider, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	card: {
		width: '100%',
		backgroundColor: theme.palette.specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: theme.palette.text.primary,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		fontSize: '16px',
	},
	contentContainer: {
		width: '92%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 50,
		padding: '6px 8px 0 8px',
	},
	icon: {
		marginRight: '5%',
		alignItems: 'center',
		display: 'flex',
	},
	left: {
		width: '100%',
		alignItems: 'center',
		display: 'flex',
	},
	toggle: {
		margin: 0,
	},
	slider: {
		width: '95%',
	},
	sliderContainer: {
		padding: '0 8px 6px 8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))

interface FilterNumberSliderProps {
	className?: string
	value?: number
	icon?: React.ReactNode
	label: string
	onChange: (v: number) => void
	preselectedValue?: number
}

const FilterNumberSlider = ({
	className = '',
	icon = null,
	label,
	onChange = (v) => {},
	value,
	preselectedValue,
}: FilterNumberSliderProps) => {
	const classes = useStyles()

	const [rangeValue, setRangeValue] = useState((preselectedValue ?? value) || 0)

	const handleChange = (e: any, newValue: any) => {
		setRangeValue(newValue)
		onChange(newValue)
	}

	return (
		<div className={classes.root}>
			<div className={classNames(classes.card, className)}>
				<div className={classes.contentContainer}>
					<Typography className={classes.left}>
						<span className={classes.icon}>{icon}</span>
						{label}
					</Typography>
					<Typography>{rangeValue > 0 ? rangeValue : ''}</Typography>
				</div>
				<div className={classes.sliderContainer}>
					<Slider className={classes.slider} defaultValue={rangeValue} onChange={handleChange} step={0.2} min={0} max={4} />
				</div>
			</div>
		</div>
	)
}

export default FilterNumberSlider

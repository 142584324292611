import NotificationsBlock from '@/components/Notifications/NotificationsBlock'
import SideBar from '@/components/SideBar/EmbeddedSideBar'
import InfoSideBar from '@/components/SideBar/InfoSideBar'
import { STYLES } from '@/constants'
import { useGetUserNotificationsQuery } from '@/features/notifications/api'
import { useCurrentUser } from '@/hooks/userHooks'
import { NotificationPages } from '@/store/auth/types'

import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { upperFirst } from 'lodash'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'row',
	},
	card: {
		backgroundColor: theme.palette.background.paper,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		padding: '3.5vh 2.5vw',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	notificationsPopper: {
		marginTop: 5,
		padding: '10px 20px',
		minWidth: '20vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 8,
		maxHeight: 'none',
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	rootContainer: {
		width: '100%',
	},
	leftColumn: {
		width: '25%',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('md')]: {
			width: '35%',
		},
	},
	rightColumn: {
		width: '25%',
	},
	middleColumn: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 940,
		border: `solid 1.5px ${theme.palette.border.bold}`,
		borderTop: 'none',
		borderBottom: 'none',
		[theme.breakpoints.down('md')]: {
			width: '65%',
			maxWidth: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			maxWidth: 'none',
		},
	},
}))

const NotificationsPage = () => {
	const classes = useStyles()
	const currentUser = useCurrentUser()
	const params = useParams<{ type: NotificationPages }>()

	const { data: newNotificationsData } = useGetUserNotificationsQuery({
		userId: currentUser.id,
		type: params.type,
	})

	const appTheme = useTheme()
	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))
	const downMdScreenSize = useMediaQuery(appTheme.breakpoints.down('md'))

	const hasNewNotifications = newNotificationsData ? newNotificationsData.notifications.length > 0 : true
	const showPlaceholder = !hasNewNotifications

	return (
		<div className={classes.root}>
			{mdScreenSize && (
				<div className={classes.leftColumn}>
					<SideBar />
				</div>
			)}
			<div className={classes.middleColumn}>
				<div className={classes.card}>
					<div className={classes.rootContainer}>
						<div className={classes.titleContainer}>
							<Typography className={classes.notificationsPopperTitle} variant="subtitle1">
								{`${upperFirst(params.type!)}`} Notifications
							</Typography>
						</div>
						{showPlaceholder ? (
							<Typography>No notifications yet!</Typography>
						) : (
							<>
								{params.type !== 'past' && <NotificationsBlock type={params.type} hideTitle={true} />}
								{params.type === 'past' && <NotificationsBlock type={params.type} hideTitle={true} showDivider={false} />}
							</>
						)}
					</div>
				</div>
			</div>
			{!downMdScreenSize && (
				<div className={classes.rightColumn}>
					<InfoSideBar />
				</div>
			)}
		</div>
	)
}

export default NotificationsPage

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { baseApi as engageApi } from '@/anthology/general/baseApi'
import { baseApi as analyticsApi } from '@/features/adminConsole/api/baseApi'
import { baseApi } from '@/features/general/baseApi'
import { MODULE_NAME as PREFERENCE_MODULE_NAME, preferenceSlice } from '@/features/preferences'
import { init as initSplit } from '@/lib/splitio'
import { appReducer, MODULE_NAME as APP_MODULE_NAME } from '@/store/app'
import { authReducer, MODULE_NAME as AUTH_MODULE_NAME, signOut } from '@/store/auth'
import errorMiddleware from '@/store/errorMiddleware'
import { eventsReducer, MODULE_NAME as EVENTS_MODULE_NAME } from '@/store/events'
import { feedsReducer, MODULE_NAME as FEEDS_MODULE_NAME } from '@/store/feeds'
import { groupsReducer, MODULE_NAME as GROUPS_MODULE_NAME } from '@/store/groups'
import { internshipsReducer, MODULE_NAME as INTERNSHIPS_MODULE_NAME } from '@/store/internships'
import { messagesReducer, MODULE_NAME as MESSAGES_MODULE_NAME } from '@/store/messages'
import { MODULE_NAME as NETWORK_MODULE_NAME, networkReducer } from '@/store/network'
import { MODULE_NAME as ORGANIZATIONS_MODULE_NAME, organizationsReducer } from '@/store/organizations'
import { MODULE_NAME as PATH_BUILDER_MODULE_NAME, pathBuilderReducer } from '@/store/pathBuilder'
import { MODULE_NAME as RESEARCH_MODULE_NAME, researchReducer } from '@/store/research'
import { MODULE_NAME as EDUCATION_ABROAD_MODULE_NAME, studyAbroadReducer } from '@/store/studyAbroad'
import { MODULE_NAME as VOLUNTEER_MODULE_NAME, volunteerReducer } from '@/store/volunteer'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { generatedBaseApi } from '@/features/adminConsole/api/baseGeneratedApi'
import { campusSlice } from '@/features/campus/slice'
import { onboardingSlice } from '@/features/onboarding/slice'
import { commentsSlice } from '@/features/posts/comments/slice'
import { feedSlice } from '@/features/posts/feed/slice'
import { schoolSlice } from '@/features/school/slice'
import { getLogger } from '@/init/DIContainer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { dashboardSlice } from '../features/adminConsole/slice'
import { constantsSlice } from './constants/constantsSlice'
import { sentryMiddleware } from './sentryMiddleware'

export const history = createBrowserHistory()

const sentryReduxEnhancer = getLogger().createReduxEnhancer({
	// Optionally pass options listed below
	// https://docs.sentry.io/platforms/javascript/guides/react/redux/
})

const combinedReducer = combineReducers({
	//@ts-ignore
	router: connectRouter(history),
	split: splitReducer,
	[analyticsApi.reducerPath]: analyticsApi.reducer,
	[engageApi.reducerPath]: engageApi.reducer,
	[generatedBaseApi.reducerPath]: generatedBaseApi.reducer,
	[commentsSlice.name]: commentsSlice.reducer,
	[campusSlice.name]: campusSlice.reducer,
	[schoolSlice.name]: schoolSlice.reducer,
	[onboardingSlice.name]: onboardingSlice.reducer,
	[feedSlice.name]: feedSlice.reducer,
	[baseApi.reducerPath]: baseApi.reducer,
	[APP_MODULE_NAME]: appReducer,
	[AUTH_MODULE_NAME]: authReducer,
	[EVENTS_MODULE_NAME]: eventsReducer,
	[ORGANIZATIONS_MODULE_NAME]: organizationsReducer,
	[VOLUNTEER_MODULE_NAME]: volunteerReducer,
	[RESEARCH_MODULE_NAME]: researchReducer,
	[EDUCATION_ABROAD_MODULE_NAME]: studyAbroadReducer,
	[INTERNSHIPS_MODULE_NAME]: internshipsReducer,
	[NETWORK_MODULE_NAME]: networkReducer,
	[FEEDS_MODULE_NAME]: feedsReducer,
	[MESSAGES_MODULE_NAME]: messagesReducer,
	[PREFERENCE_MODULE_NAME]: preferenceSlice.reducer,
	[PATH_BUILDER_MODULE_NAME]: pathBuilderReducer,
	[GROUPS_MODULE_NAME]: groupsReducer,
	[constantsSlice.name]: constantsSlice.reducer,
	[dashboardSlice.name]: dashboardSlice.reducer,
})

export const store = configureStore({
	reducer: (state, action) => {
		if (signOut.match(action)) {
			state = undefined
		}
		return combinedReducer(state, action)
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat([
			routerMiddleware(history),
			sentryMiddleware,
			errorMiddleware,
			baseApi.middleware,
			engageApi.middleware,
			analyticsApi.middleware,
			generatedBaseApi.middleware,
		]),
	enhancers: process.env.REACT_APP_ENV !== 'local' ? [sentryReduxEnhancer] : [],
})

initSplit(store)

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import { TextButton } from '@/components/Buttons'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import { PATHS, STYLES } from '@/constants'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		position: 'fixed',
		height: 90,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.paper,
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			padding: '0 7%',
			width: '100vw',
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.SM,
		},
		borderBottom: `solid 1.5px ${theme.palette.border.bold}`,
	},
	blockHeader: {
		marginTop: 10,
		paddingLeft: '1.5vw',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleText: {
		fontWeight: 700,
		marginRight: 5,
	},
	tabs: {
		minWidth: 'fit-content',
		paddingRight: '3vw',
		height: 90,
	},
	tabWrapper: {
		marginTop: 20,
	},
	textButton: {
		marginLeft: 5,
	},
	autoSizer: {
		height: 'fit-content !important',
	},
}))

interface ItemsSectionTitleProps {
	title: string
	pageInfo?: string
	tabs?: string[]
	activeTab?: number
	handleChangeTab?: (tab: number) => void
}

const NetworkPageHeader = ({ title = '', handleChangeTab = () => {}, tabs = [], pageInfo = '', activeTab = 0 }: ItemsSectionTitleProps) => {
	const classes = useStyles()
	const { push } = useHistory()
	const { pathname } = useLocation()

	const handleClickConnections = () => push(PATHS.APP.NETWORK_MY)

	const clickableTitle =
		pathname !== PATHS.APP.NETWORK_MY ? (
			<>
				<Typography className={classes.titleText}>{title}</Typography>
				<Typography>{' | '}</Typography>
				<TextButton className={classes.textButton} onClick={handleClickConnections}>
					{`${pageInfo} Connections`}
				</TextButton>
			</>
		) : (
			<>
				<Typography className={classes.titleText}>{title}</Typography>
				<Typography>{` | ${pageInfo} Connections`}</Typography>
			</>
		)

	return (
		<AutoSizer className={classes.autoSizer}>
			{({ height, width }) => (
				<div
					className={classes.root}
					style={{
						width,
					}}
				>
					<div className={classes.blockHeader}>{clickableTitle}</div>
					{!!tabs.length && (
						<div className={classes.tabs}>
							<NavigationTabs
								activeTabIndex={activeTab}
								tabsRenderer={(props) =>
									tabs.map((tab, index) => (
										<NavigationTab
											className={classes.tabWrapper}
											key={`${tab}-${index}`}
											index={index}
											label={tab}
											onClick={handleChangeTab}
											{...props}
										/>
									))
								}
							/>
						</div>
					)}
				</div>
			)}
		</AutoSizer>
	)
}

export default NetworkPageHeader

import { Typography } from '@material-ui/core'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { STYLES } from '@/constants'
import { GroupListItem } from '@/store/groups/types'
import { getCountLabel } from '@/utils/common'
import { isNewItem } from '@navengage/sen-shared-assets'
import Badge from './Badge'
import GroupImage from './GroupImage'

const borderRadius = 7

interface StylesProps {
	url?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingTop: '100%',
		position: 'relative',
		cursor: 'pointer',
		borderRadius,
		overflow: 'hidden',
	},
	imageContainer: {
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	image: {
		position: 'relative',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
	},
	contentContainer: {
		padding: 10,
		position: 'absolute',
		width: 'calc(100% - 20px)',
		height: 'calc(100% - 20px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		justifyContent: 'space-between',
	},
	text: {
		color: theme.palette.common.white,
	},
	name: {
		fontWeight: 700,
	},
	newBadge: {
		textTransform: 'uppercase',
	},
	groupInfo: {
		zIndex: 2,
		width: '100%',
		position: 'absolute',
		left: 0,
		bottom: 0,
		padding: '10px',
		boxSizing: 'border-box',
		'&:before': {
			content: "''",
			display: 'block',
			width: '100%',
			height: '100%',
			zIndex: -1,
			position: 'absolute',
			background: alpha(theme.palette.common.black, 0.7),
			left: 0,
			bottom: 0,
		},
	},
}))

interface GroupCardProps {
	onClick: (id: string) => void
	className?: string
	data: GroupListItem
}

const GroupCard = ({ data, onClick, className }: GroupCardProps) => {
	const classes = useStyles({ url: data.thumbnail })

	const handleClickCard = () => onClick(data.id)

	const isNewGroup = isNewItem(data?.createdAt)

	return (
		<div className={classNames(classes.root, className)} onClick={handleClickCard}>
			<div className={classes.imageContainer}>
				<div className={classes.image}>
					<GroupImage url={data.thumbnail} groupName={data.name} borderRadius={borderRadius} placeholderSize={3} />
					<div className={classes.contentContainer}>
						{isNewGroup && <Badge className={classes.newBadge}>new</Badge>}
						<div className={classes.groupInfo}>
							<Typography noWrap={true} className={classNames(classes.text, classes.name)} variant="h6">
								{data.name}
							</Typography>
							<Typography className={classes.text} variant="body2">
								{getCountLabel(data.memberCount, 'member')}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GroupCard

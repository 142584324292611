// @TODO: remove ts-nocheck
// @ts-nocheck
import { PrivacyLevels, PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'
import { SCHOOL_ID } from '@/constants/configuration'
import ROLE from '@/constants/roles'
import * as actions from '@/store/auth/actions'
import { AuthState } from '@/store/auth/types'
import { resolveSchoolId } from '@/utils'
import { getUserType } from '@/utils/authHandlers'
import { createReducer } from '@reduxjs/toolkit'

const initialState: AuthState = {
	role: ROLE.GUEST,
	firstLogin: false,
	isLoading: true,
	signUpForm: {
		patches: {
			connectToUserIds: {},
		},
	},
	userInfo: {
		campusId: 0,
		id: 0,
		externalId: '',
		appUserTypeId: getUserType(ROLE.GUEST),
	},
	goalResponses: [],
	goals: [],
	preferences: {
		notifications: {
			isLoading: false,
		},
	},
	userPreferences: {
		receiveEmailsAboutProfessionalOpportunitiesAndResources: true,
		receiveEmailsAboutResearchOpportunitiesAndResources: true,
		receiveEmailsAboutVolunteerAndServiceOpportunities: true,
		receiveEmailsEducationAbroadOpportunitiesAndResources: true,
		receiveEmailsAboutEvents: true,
		receiveEmailsAboutOrganizations: true,
		notificationsConnectionRequests: true,
		notificationsUpcomingEventsGoing: true,
		notificationsUpcomingEventsInterested: true,
		notificationsLikesOnMyPosts: true,
		notificationsCommentsOnMyPosts: true,
		notificationsDirectMessages: true,
	},
	profileTabToOpen: 0,
	activity: {
		list: [],
		page: 1,
		endHasBeenReached: false,
	},
	organizationRoles: {},
	userPrivacyOptions: [],
	entityPrivacy: {
		[PrivacyTypes.volunteerHours]: PrivacyLevels.allUsers,
	},
	isOpenWelcomeNewUserPopup: false,
	isOpenAcceptNewTermsPopup: false,
	connectToUserIds: {},
	schoolId: resolveSchoolId(),
}

const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.signIn, (state, { payload: { userInfo, role, token } }) => ({
			...state,
			userInfo,
			role,
			token,
		}))
		.addCase(actions.signUp, (state, { payload: { userInfo, role, token } }) => ({
			...state,
			userInfo,
			role,
			token,
			firstLogin: true,
		}))
		.addCase(actions.setUserAfterPageRefresh, (state, { payload: { userInfo, role, token } }) => {
			state.userInfo = userInfo
			state.role = role
			if (token) {
				state.token = token
			}
		})
		.addCase(actions.setAccessToken, (state, { payload: { token } }) => {
			state.token = token
		})
		.addCase(actions.setProfileTabToOpen, (state, { payload }) => ({
			...state,
			profileTabToOpen: payload,
		}))
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.signOut, () => ({
			...initialState,
			schoolId: SCHOOL_ID,
		}))
		.addCase(actions.setSignUpForm, (state, { payload: { patches } }) => {
			state.signUpForm.patches = { ...state.signUpForm.patches, ...patches }
		})
		.addCase(actions.storeSignUpFormRequest.fulfilled, (state) => ({
			...state,
			firstLogin: false,
		}))
		.addCase(actions.setUserInfo, (state, action) => {
			return {
				...state,
				userInfo: action.payload.userInfo ? action.payload.userInfo : state.userInfo,
				upcomingShiftsCount: action.payload.upcomingShiftsCount || state.upcomingShiftsCount,
				goals: action.payload.goals ?? state.goals,
				goalResponses: action.payload.goalResponses ?? state.goalResponses,
			}
		})
		.addCase(actions.setUserActivity, (state, { payload }) => ({
			...state,
			activity: {
				...state.activity,
				...payload,
			},
		}))
		.addCase(actions.setUserOrganizationRoles, (state, { payload }) => ({
			...state,
			organizationRoles: payload,
		}))
		.addCase(actions.setUserPrivacyOptions, (state, { payload }) => ({
			...state,
			userPrivacyOptions: payload,
		}))
		.addCase(actions.setEntityPrivacy, (state, { payload }) => ({
			...state,
			entityPrivacy: {
				...state.entityPrivacy,
				...payload,
			},
		}))
		.addCase(actions.setIsOpenWelcomeNewUserPopup, (state, { payload }) => ({
			...state,
			isOpenWelcomeNewUserPopup: payload,
		}))
		.addCase(actions.storeLatestTerms, (state, { payload }) => ({
			...state,
			termsAndConditions: payload,
		}))
		.addCase(actions.storeLatestPrivacyPolicy, (state, { payload }) => ({
			...state,
			privacyPolicy: payload,
		}))
		.addCase(actions.setIsOpenAcceptNewTermsPopup, (state, { payload }) => ({
			...state,
			isOpenAcceptNewTermsPopup: payload,
		}))
		.addCase(actions.updateUserAcceptedTerms, (state, { payload }) => ({
			...state,
			userInfo: {
				...state.userInfo,
				acceptedTermsConditions: payload,
			},
		}))
		.addCase(actions.toggleSignUpConnectionId, (state, { payload }) => {
			const { connectToUserIds } = state.signUpForm.patches
			if (connectToUserIds[payload]) {
				delete connectToUserIds[payload]
			} else {
				connectToUserIds[payload] = true
			}
		})
})

export { authReducer }

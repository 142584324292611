import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import { IconWrapper, StepperCheckMarkIcon } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'
import { TIconName } from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'start',
		},
	},
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '2vh 0',
		textAlign: 'end',
		[theme.breakpoints.down('md')]: {
			width: 'fit-content',
		},
	},
	activeMark: {
		color: theme.palette.type === 'light' ? theme.colors.grey[300] : theme.palette.text.secondary,
		fontSize: 35,
		fontWeight: 700,
		paddingBottom: 4,
	},
	text: {
		color: theme.palette.type === 'light' ? theme.colors.grey[300] : theme.palette.text.secondary,
		fontWeight: 700,
		fontSize: 20,
	},
	activeText: {
		color: theme.palette.primary.main,
	},
	listMark: {
		width: 100,
		minWidth: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}))

interface StepperProps {
	className?: string
	steps: {
		label: string
		icon: TIconName
	}[]
	completedSteps?: number[]
	currentStep: number
	onClickStep: (idx: number) => () => void
}

const Stepper = ({ className = '', steps, completedSteps = [], currentStep, onClickStep }: StepperProps) => {
	const classes = useStyles()
	const theme = useTheme()

	return (
		<div className={classNames(classes.root, className)}>
			{steps.map((step, index) => {
				const filled = completedSteps.includes(index)
				const current = index === currentStep

				return (
					<div
						key={step.label}
						className={classNames(classes.item, filled ? classes.cursorPointer : '')}
						onClick={filled ? onClickStep(index) : undefined}
					>
						<Typography className={classNames(classes.text, current ? classes.activeText : '')}>{step.label}</Typography>
						<div className={classes.listMark}>
							{current && (
								<IconWrapper
									iconKey={step.icon}
									weight="fill"
									size={ICON_SIZES.lg}
									aria-label={step.label}
									color={theme.palette.primary.main}
								/>
							)}
							{filled && !current && <StepperCheckMarkIcon />}
							{!filled && !current && <div className={classes.activeMark}>•</div>}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default Stepper

import DotButton from '@/components/Buttons/DotButton'
import ItemsSectionPagination from '@/components/ItemsSections/ItemsSectionPagination'
import SettingsPageHeader from '@/components/SettingsPageHeader'
import NetworkUserRow from '@/components/UserNetwork/NetworkUserRow'
import { STYLES } from '@/constants'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { getBlockedUsers, loadBlockedUsersListRequest, unblockUserRequest } from '@/store/network'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	container: {
		height: 90,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	cardContainer: {
		gridTemplateColumns: `repeat(auto-fill, 49%)`,
		justifyContent: 'flex-start',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: `repeat(auto-fill, 100%)`,
		},
	},
	userInfoRowContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 7,
		padding: 7,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	userInfoRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		border: 'none',
		margin: 0,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	moreButton: {
		margin: '0 10px',
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	placeholder: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const BlockedAccounts = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const blockedUsers = useSelector(getBlockedUsers)

	const handleUnblockUser = (id: number) => {
		dispatch(unblockUserRequest(id))
	}

	useEffect(() => {
		dispatch(loadBlockedUsersListRequest())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<SettingsPageHeader title="Privacy" pageInfo={`Blocked Accounts (${blockedUsers.length})`} />
			</div>
			{blockedUsers.length ? (
				<ItemsSectionPagination
					classnames={{
						content: classes.cardContainer,
					}}
					items={blockedUsers}
					renderItems={(users: UserData[]) => (
						<>
							{users.map((user, index) => (
								<div key={`blocked-${user.id}-${index}`} className={classes.userInfoRowContainer}>
									<NetworkUserRow className={classes.userInfoRow} user={user} />
									<div className={classes.buttonsContainer}>
										<DotButton
											variant="secondary"
											className={classes.moreButton}
											showReportButton={false}
											renderButtons={[
												{
													label: 'Unblock',
													onClick: (e: any) => {
														e.stopPropagation()
														handleUnblockUser(user.id!)
													},
													icon: null,
												},
											]}
										/>
									</div>
								</div>
							))}
						</>
					)}
				/>
			) : (
				<div className={classes.placeholder}>
					<Typography>No blocked users.</Typography>
				</div>
			)}
		</div>
	)
}

export default BlockedAccounts

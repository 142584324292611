import { TextField } from '@/components'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useEffect, useRef, useState } from 'react'

import Label from '@/components/Label'
import { STYLES } from '@/constants'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '42vw',
		marginTop: '2vh',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
		[theme.breakpoints.down('sm')]: {
			width: '94vw',
		},
	},
	form: {
		width: '100%',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	userImage: {
		width: 100,
		height: 100,
		margin: '2vh 0',
	},
	formFieldsContainer: {
		width: '100%',
	},
	selectsContainer: {
		width: '60%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	select: {
		width: '48%',
	},
	notSingleInput: {
		marginBottom: '1.5vh',
	},
	logoInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	logoInput: {
		marginLeft: -5,
		marginRight: 10,
	},
	previewContainer: {
		width: '85%',
		margin: '3vh 0',
		padding: '3% 3%',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	textFieldWithoutLabel: {
		margin: '0.5vh 0',
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
}))

interface NameEditorProps {
	userName: {
		firstName: string
		lastName: string
	}
	onFinishEdit: (userName: { firstName: string; lastName: string }) => void
}

const NameEditor = ({
	userName = {
		firstName: '',
		lastName: '',
	},
	onFinishEdit,
}: NameEditorProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const [showEditForm, setShowEditForm] = useState(false)
	const [{ firstName, lastName }, setUserName] = useState({
		firstName: userName.firstName,
		lastName: userName.lastName,
	})

	const containerRef = useRef<any>(null)
	const previewInputRef = useRef<any>(null)
	const firstNameInputRef = useRef<any>(null)
	const lastNameInputRef = useRef<any>(null)

	const firstNameError = !firstName?.trim()
	const lastNameError = !lastName?.trim()
	const hasErrors = firstNameError || lastNameError

	const handleChangeName = (key: string) => (e: any) =>
		setUserName((prev) => ({
			...prev,
			[key]: e.target.value,
		}))

	useEffect(() => {
		if (showEditForm) {
			setUserName({
				firstName: userName.firstName,
				lastName: userName.lastName,
			})
		}

		if (firstNameInputRef && firstNameInputRef.current) {
			firstNameInputRef.current.focus()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showEditForm])

	useOutsideClickCallback(containerRef, () => {
		if (!hasErrors && (firstName !== userName.firstName || lastName !== userName.lastName)) {
			onFinishEdit({ firstName, lastName })
		}
		setShowEditForm(false)
	})

	const fullName = `${userName.firstName} ${userName.lastName}`
	const {
		school: { applicationName },
	} = useSchoolConfig()

	return (
		<div ref={containerRef} className={classes.formFieldsContainer}>
			<Label classnames={{ label: classes.label }} title="Name" semiBold={true}>
				{!showEditForm ? (
					<TextField
						placeholderColor={appTheme.palette.primary.main}
						onFocus={() => setShowEditForm(true)}
						ref={previewInputRef}
						placeholder="Add your name"
						value={fullName}
					/>
				) : (
					<>
						<TextField
							className={classes.textFieldWithoutLabel}
							autoFocus
							onChange={handleChangeName('firstName')}
							ref={firstNameInputRef}
							placeholder="First name"
							value={firstName}
							error={firstNameError}
						/>
						<TextField
							className={classes.textFieldWithoutLabel}
							onChange={handleChangeName('lastName')}
							ref={lastNameInputRef}
							placeholder="Last name"
							value={lastName}
							error={lastNameError}
						/>
						<Typography className={classes.grayText} variant="subtitle1">
							This is how your name will be displayed in {applicationName}.
						</Typography>
					</>
				)}
			</Label>
		</div>
	)
}

export default NameEditor

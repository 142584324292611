import IconButton from '@/components/Buttons/IconButton'
import EventCard from '@/components/ExperienceCards/EventCard'
import BuildYourPathCard from '@/components/PathBuilder/BuildYourPathCard'
import ConnectionRequestsBlock, { ConnectionRequestType } from '@/components/UserNetwork/ConnectionRequestsBlock'
import { PATHS, ROLE, STYLES } from '@/constants'

import { TextButton } from '@/components/Buttons'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useAppSelector } from '@/store'
import { getUserRole, selectUserGoalResponses } from '@/store/auth'
import { getUserGoingEvents, getUserInterestedEvents, selectUpcomingEventsInfo } from '@/store/events'
import { getConnectionRequests } from '@/store/network'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PersonAdd } from '@material-ui/icons'
import { FEATURE } from '@navengage/sen-shared-assets'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		backgroundColor: 'none',
		boxShadow: 'none',
		zIndex: 1,
	},
	container: {
		position: 'fixed',
		margin: 15,
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		width: `calc(25% - 30px)`,
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
	},
	connectionRequestsBlock: {
		padding: 15,
		borderRadius: 10,
		backgroundColor: theme.palette.neutral.light,
	},
	upcomingEventsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: 15,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	upcomingEventsHeaderContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 15,
		borderRadius: 10,
		backgroundColor: theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.background.paper,
		marginBottom: 30,
	},
	searchUsersButton: {
		width: '100%',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
	discoverConnectionsCard: {
		padding: 25,
		margin: 25,
		borderRadius: 10,
		backgroundColor: theme.palette.background.paper,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		border: `solid 1px ${theme.palette.border.bold}`,
		height: 200,
		justifyContent: 'space-between',
	},
	discoverConnectionsHeader: {
		fontWeight: 700,
	},
	eventIcon: {
		width: 34,
		height: 34,
		marginRight: 10,
	},
	upcomingEventsHeader: {
		width: '70%',
		display: 'flex',
		alignItems: 'center',
	},
	pathBuilderCard: {
		width: '85% !important',
	},
}))

const InfoSideBar = () => {
	const classes = useStyles()
	const { push } = useHistory()

	const connectionRequests = useSelector(getConnectionRequests)
	const goingEvents = useSelector(getUserGoingEvents)
	const interestedEvents = useSelector(getUserInterestedEvents)
	const upcomingEvents = useMemo(() => goingEvents.concat(interestedEvents), [goingEvents, interestedEvents])
	const userGoals = useSelector(selectUserGoalResponses)
	const userRole = useSelector(getUserRole)
	const { total, upcomingEvent } = useAppSelector(selectUpcomingEventsInfo)

	const handleSeeAllEvents = () => push(PATHS.APP.EVENTS_MY)
	const handleDiscoverConnections = () => push(PATHS.APP.NETWORK_SEARCH)

	const showBuildYourPathCard = !userGoals.length && userRole !== ROLE.FACULTY && userRole !== ROLE.STAFF
	const showConnectionRequestsList = !!connectionRequests.length
	const showUpcomingEvents = !!upcomingEvents.length
	const showPlaceholder = !showUpcomingEvents && !showConnectionRequestsList && !showBuildYourPathCard

	const {
		school: { shortName },
	} = useSchoolConfig()

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{showPlaceholder && (
					<div className={classes.discoverConnectionsCard}>
						<Typography className={classes.discoverConnectionsHeader}>More connections lead to more opportunities.</Typography>
						<Typography>Grow your network at {shortName}!</Typography>
						<IconButton className={classes.searchUsersButton} onClick={handleDiscoverConnections} startIcon={<PersonAdd />}>
							Discover Connections
						</IconButton>
					</div>
				)}
				{showBuildYourPathCard && <BuildYourPathCard className={classes.pathBuilderCard} />}
				{showConnectionRequestsList && (
					<ConnectionRequestsBlock
						className={classes.connectionRequestsBlock}
						title="Connection Requests"
						connectionRequests={connectionRequests}
						connectionRequestType={ConnectionRequestType.INCOMING}
					/>
				)}
				{total > 0 && (
					<div className={classes.upcomingEventsContainer}>
						<div className={classes.upcomingEventsHeaderContainer}>
							<div className={classes.upcomingEventsHeader}>
								<IconWrapper className={classes.eventIcon} iconKey={FEATURE.events} />
								<Typography>
									<b>Upcoming Events</b> | {total}
								</Typography>
							</div>
							<TextButton onClick={handleSeeAllEvents}>See All</TextButton>
						</div>
						<EventCard
							id={upcomingEvent.id}
							title={upcomingEvent.name}
							photoUrl={upcomingEvent.photoUrl}
							categories={upcomingEvent.categories}
							startDate={upcomingEvent.startDate}
							endDate={upcomingEvent.endDate}
							description={upcomingEvent.description}
							organization={upcomingEvent.organization}
							locationName={upcomingEvent.locationName}
							eventResponse={upcomingEvent.responseStatus}
							volunteerShifts={upcomingEvent.volunteerShifts}
							submittedByUserId={upcomingEvent.submittedByUserId}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default InfoSideBar

import http from '@/api/http/requestHttp'
import { PostResponseStatuses } from '@/interfaces/common'

interface CreatePostUserResponseParams {
	postId: string
	userId: number
	response: PostResponseStatuses
	reportMessage?: string
	schoolId: number
}

const createPostUserResponse = async ({ postId, userId, response, reportMessage, schoolId }: CreatePostUserResponseParams) => {
	const url = `${schoolId}/feeds/${userId}/post/response`
	const body = { postId, response, reportMessage }

	return await http.post(url, body)
}

export default createPostUserResponse

import { SharedData, SharedDataTypes, UserData } from '@/interfaces/common'

import WithSharedEntity from '@/features/shareEntity/types/WithSharedEntity'
import { MinimalGroupChatInfo } from '../groups/types'

export interface MessageData extends WithSharedEntity {
	id: string
	sourceUserId: number
	targetUserId?: number
	groupId?: string
	message?: string
	photoUrl?: string
	linkPreviewUrl?: string
}
export interface Message extends MessageData {
	sourceUser?: UserData | null
	targetUser?: UserData | null
	sourceGroup?: MinimalGroupChatInfo | null
	viewed: boolean
	createdAt: string
	likes?: number
	currentUserReacteions?: TReactions[]
}
// Fix me - had to add Reacteions to cspell, but issue originates
// in backend

// Other reactions will be added later on
export enum TMessageReactions {
	like = 'Like',
	dislike = 'Dislike',
}
export type TReactions = `${TMessageReactions}`

export interface NewMessageContent {
	message?: string
	photoUrl?: string
	sharedEntity?: WithSharedEntity

	linkPreviewUrl?: string
}

export interface MessageWithUserInfo {
	user: UserData
	message: Message
}

export interface Chat {
	roomId: string
	user: UserData | null
	message: Message
	group: MinimalGroupChatInfo | null
	unviewed: number
}

export interface MessagesState {
	isLoading: boolean
	error?: any
	newMessageContent: NewMessageContent
	chats: {
		list: Chat[]
		canLoadMore: boolean
		isLoading: boolean
		numberOfUnViewed: number
	}
	messages: {
		isLoading: boolean
		canLoadMore: boolean
		list: Message[]
	}
	searchUsers: {
		q: string
		users: UserData[]
		page: number
		canLoadMore: boolean
		isLoading: boolean
	}
	targetUser?: UserData | null
	showSelectUserInput: boolean
	shareModal: {
		isOpen: boolean
		data?: SharedData
		type?: SharedDataTypes
	}
}

export type TUpdateViewStatusData = { id: string; userId: number; targetUserId?: number; sourceUserId?: number; groupId?: string }

export type DtoSocketMessageReaction = { messageId: string; reaction: 'like' | 'dislike'; groupId: string }

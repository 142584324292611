import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import { getIsLoading, getUserInfo } from '@/store/auth'
import { useSelector } from 'react-redux'
import { PATHS } from '@/constants'
import { useAppDispatch } from '@/store'

const useStyles = makeStyles((theme) => ({
	h4: {
		fontWeight: 700,
		textAlign: 'center',
	},
	textContainer: {
		margin: '2vh 0',
		width: '80%',
		textAlign: 'center',
	},
	card: {
		width: '30vw',
		[theme.breakpoints.down('md')]: {
			width: '50vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '85vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100vw',
		},
		paddingBottom: '2vh',
	},
	loader: {
		width: 20,
		height: 20,
	},
}))

const Thanks = () => {
	const classes = useStyles()
	const history = useHistory()
	const dispatch = useAppDispatch()
	const userInfo = useSelector(getUserInfo)
	const isLoading = useSelector(getIsLoading)

	useEffect(() => {
		let timeoutId: NodeJS.Timeout
		if (!isLoading) {
			if (!userInfo?.acceptedTermsConditions) history.push(PATHS.SIGN_UP.TERMS)
			timeoutId = setTimeout(() => history.push(PATHS.APP.ROOT), 3000)
		}

		return () => timeoutId && clearTimeout(timeoutId)
	}, [dispatch, history, isLoading, userInfo?.acceptedTermsConditions])

	return (
		<CardLayout>
			<Card className={classes.card}>
				<Typography className={classes.h4} variant="h1">
					{`You’re all set, ${userInfo?.firstName || 'Friend'}!`}
				</Typography>
				<Typography className={classes.textContainer} variant="body1">
					Sit tight while we build your profile...
				</Typography>
				<CircularProgress className={classes.loader} />
			</Card>
		</CardLayout>
	)
}

export default Thanks

import { makeStyles } from '@material-ui/core/styles'
import { Compass } from '@phosphor-icons/react'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { InternshipsIcon } from '@/components'
import Filter from '@/components/Filter'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { useGetEntityResponseCountQuery } from '@/features/responses/api'
import useEqualToPath from '@/hooks/useEqualToPath'
import { Filters, SearchConfig } from '@/interfaces/common'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import { APP_CONSTANTS } from '@/store/constants/constants'
import { getMajors, getPositionTypes, loadDependencies, selectJobFiltersAreLoaded } from '@/store/constants/constantsSlice'
import { clearFilters, getSearchConfig, loadInternshipsListWithFilterRequest } from '@/store/internships'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
}))

const InternshipsPage = () => {
	const classes = useStyles()

	const dispatch = useAppDispatch()
	const { data: count } = useGetEntityResponseCountQuery({ entityType: 'jobs' })
	const searchConfig = useSelector(getSearchConfig)
	// const employers = useSelector(getEmployers)
	const positionTypes = useSelector(getPositionTypes)
	const majors = useSelector(getMajors)

	const jobFiltersAreLoaded = useSelector(selectJobFiltersAreLoaded)

	// const classLevels = useSelector(getClassLevels)
	const canUseFilter = useEqualToPath(PATHS.APP.INTERNSHIPS)
	const handleClearFilters = () => {
		dispatch(clearFilters())
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			if (canUseFilter) {
				dispatch(loadInternshipsListWithFilterRequest(config))
				window.scrollTo({ top: 0 })
			}
		},
		[dispatch, canUseFilter],
	)

	useEffect(() => {
		dispatch(loadDependencies([APP_CONSTANTS.jobFilters]))
	}, [dispatch])

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Jobs',
				icon: <Compass size={ICON_SIZES.md} />,
				path: PATHS.APP.INTERNSHIPS,
			},
			{
				label: 'My Jobs',
				icon: <InternshipsIcon fontSize="medium" />,
				path: PATHS.APP.INTERNSHIPS_MY,
				info: [`${count?.total ?? ''} Bookmarked`],
			},
		],
		[count?.total],
	)

	return (
		<div className={classes.root}>
			<Filter
				isLoading={!jobFiltersAreLoaded}
				title="Jobs"
				searchPlaceholder="Search jobs & employers"
				value={searchConfig}
				filters={[
					// Filters.employers,
					Filters.positionTypes,
					Filters.majors,
					// Filters.classLevels,
					Filters.minimumGPA,
					// Filters.paid,
				]}
				filterOptions={{
					// employers,
					positionTypes,
					majors,
					// classLevels,
				}}
				canUseFilter={canUseFilter}
				onFilterChange={fetchSearchAndFilter}
				clearFilters={handleClearFilters}
				filterNavigation={filterNavigation}
			/>

			<Router routes={ROUTES.internships} />
		</div>
	)
}

export default InternshipsPage

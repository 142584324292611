import * as http from '@/api/http'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/volunteer/constants'
import { VolunteerShifts } from '@/store/volunteer/types'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { uniq } from 'lodash'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setEvents = createAction<{ events: VolunteerShifts }>(`${MODULE_NAME}/SET_EVENTS`)

export const loadUserVolunteerShiftsRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_VOLUNTEER_SHIFTS_REQUEST`, async (_undefiend, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const [pastRes, futureRes] = await Promise.all([
			http.volunteers.getUserPastVolunteerShifts(userId, schoolId),
			http.volunteers.getUserFutureVolunteerShifts(userId, schoolId),
		])

		const futureEventsIds = uniq(futureRes.data.userVolunteerOpps.map((shift: any) => shift.eventId)) as string[]
		const pastEventsIds = uniq(pastRes.data.userVolunteerOpps.map((shift: any) => shift.eventId)) as string[]

		const uniqEventsIds = uniq([...futureEventsIds, ...pastEventsIds])

		const eventsData = await (
			await Promise.all(uniqEventsIds.map((id: string) => http.events.getEventById(id, schoolId)))
		).map((res) => res.data.event)

		const events = {
			future: futureEventsIds.map((id) => ({
				...eventsData.find((event) => id === event.id),
				scheduledShifts: futureRes.data.userVolunteerOpps.filter((shift: any) => shift.eventId === id).length,
			})),
			past: pastEventsIds.map((id) => ({
				...eventsData.find((event) => id === event.id),
				scheduledShifts: pastRes.data.userVolunteerOpps.filter((shift: any) => shift.eventId === id).length,
			})),
		}

		await dispatch(setEvents({ events }))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

const appMessages = {
	NOTIFICATIONS: {
		ALL_CAUGHT_UP: 'You’re all caught up! No new notifications.',
		NO_MORE: 'No more notifications.',
	},
	GENERALS: {
		SEE_MORE: 'See More',
	},
}
export default appMessages

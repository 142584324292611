import { makeStyles } from '@material-ui/core'
import { Tabs as MuiTabs, Tab } from '@mui/material'
import React, { FC, ReactNode, cloneElement, useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

const useStyles = makeStyles((theme) => ({
	navTabs: {
		marginBottom: theme.spacing(2),
		flexGrow: 0,
	},
}))

export interface INavTabsProps {
	tabs: Array<{ component: ReactNode; label: string; route?: string }>
	initialTab?: number
	sharedProps?: Record<string, any>
	withHistory?: boolean
}

// Bellow implementation needs to be adjusted, because of
// 1. Causing re-renders
// 2. Alters history
// 3. Unnecessary cloning of elements
// 4. Could've been simply replaced by Switch from react-router-dom https://v5.reactrouter.com/web/api/Switch

export const NavTabs: FC<INavTabsProps> = ({ tabs, initialTab = 0, sharedProps = {}, withHistory = false }) => {
	const classes = useStyles({ fullWidth: true })
	const [activeTab, setActiveTab] = useState(initialTab)
	const history = useHistory()

	const handleChangeTab = useCallback(
		(event, newValue: number) => {
			event.preventDefault()
			setActiveTab(newValue)
			if (withHistory && tabs[newValue].route) {
				history.replace(tabs[newValue].route)
			}
		},
		[tabs, history, withHistory],
	)

	// @TODO: Abstract this out into a hook for stateful URLs
	useEffect(() => {
		if (!withHistory) return

		const activeIndex = tabs.findIndex((tab) => tab.route === window.location.pathname)
		if (activeIndex !== -1) {
			setActiveTab(activeIndex)
		}
	}, [tabs, withHistory])

	return (
		<>
			<MuiTabs
				value={activeTab}
				onChange={handleChangeTab}
				className={classes.navTabs}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
			>
				{tabs.map(({ label, route }, index) => (
					<Tab key={index} label={label} component={route && withHistory ? Link : undefined} to={route} />
				))}
			</MuiTabs>
			{tabs.map(({ component }, index) =>
				activeTab === index ? (
					<React.Fragment key={`tab-${index}`}>
						{cloneElement(component as React.ReactElement<any>, {
							...sharedProps,
							active: activeTab,
							key: uuid(),
						})}
					</React.Fragment>
				) : null,
			)}
		</>
	)
}

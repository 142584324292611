import PageContainer from '@/components/PageContainer'
import ActivityTab from '@/components/UserProfile/ActivityTab'
import EditUserModal from '@/components/UserProfile/EditUserModal'
import GoalsTab from '@/components/UserProfile/GoalsTab'
import ProfileTab from '@/components/UserProfile/ProfileTab'
import UserInfoCard from '@/components/UserProfile/UserInfoCard'
import { NetworkUserInfo, UserInfo } from '@/interfaces/user'
import { getUserInfo } from '@/store/auth'
import { PostActivityState } from '@/store/types'
import { isCurrentUser } from '@/utils/authHandlers'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export enum ProfileTabs {
	PROFILE = 'About',
	GOALS = 'Goals',
	ACTIVITY = 'Activity',
}

interface UserProfileProps {
	userInfo?: UserInfo | NetworkUserInfo
	handleCreateConnectionRequest?: () => void
	handleClickNumberOfConnections?: () => void
	onToggleProfileView?: (isPublicProfileView: boolean) => void
	tabs: ProfileTabs[]
	userGoalResponses?: any[]
	defaultTabIndex?: number
	isEditUserGoals?: boolean
	activity?: PostActivityState
	handleLoadMoreUserActivity?: () => void
}

const UserProfile = ({
	userInfo,
	handleCreateConnectionRequest = () => {},
	onToggleProfileView,
	handleClickNumberOfConnections,
	tabs,
	userGoalResponses = [],
	defaultTabIndex = 0,
	isEditUserGoals = false,
	activity,
	handleLoadMoreUserActivity,
}: UserProfileProps) => {
	const [activeTab, setActiveTab] = useState(defaultTabIndex)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [isPublicProfileView, setIsPublicProfileView] = useState(false)

	const currentUserInfo = useSelector(getUserInfo)

	const isMyProfilePage = isCurrentUser(currentUserInfo.id, userInfo?.id)

	const canEditProfile = !isPublicProfileView && isMyProfilePage

	const handleChangeTab = (value: number) => setActiveTab(value)

	const handleOpenEditModal = () => setEditModalOpen(true)
	const handleCloseEditModal = () => setEditModalOpen(false)

	const handleToggleProfileView = () => {
		const currentView = !isPublicProfileView
		if (onToggleProfileView) {
			onToggleProfileView(currentView)
		}
		setActiveTab(0)
		setIsPublicProfileView(currentView)
	}

	const tabComponentsByIndex = useMemo(
		() =>
			tabs.map((tab) => {
				switch (tab) {
					case ProfileTabs.PROFILE:
						return <ProfileTab key={tab} handleOpenEditModal={handleOpenEditModal} canEdit={canEditProfile} userInfo={userInfo} />
					case ProfileTabs.ACTIVITY:
						return (
							<ActivityTab
								key={tab}
								userInfo={userInfo}
								handleLoadUserActivity={handleLoadMoreUserActivity}
								activity={activity}
								isMyProfile={isMyProfilePage}
							/>
						)
					case ProfileTabs.GOALS:
						return <GoalsTab key={tab} goalResponses={userGoalResponses} canEdit={canEditProfile} />
					default:
						return null
				}
			}),
		[tabs, canEditProfile, userInfo, handleLoadMoreUserActivity, activity, isMyProfilePage, userGoalResponses],
	)

	const activeTabComponent = useMemo(() => tabComponentsByIndex[activeTab], [activeTab, tabComponentsByIndex])

	if (!userInfo) return null

	return (
		<PageContainer>
			<EditUserModal isOpen={editModalOpen} onClose={handleCloseEditModal} userInfo={userInfo} />
			<UserInfoCard
				activeTab={activeTab}
				handleChangeTab={handleChangeTab}
				tabs={tabs}
				userInfo={userInfo}
				isMyProfile={isMyProfilePage}
				isPublicProfileView={isPublicProfileView}
				handlePublicProfileView={handleToggleProfileView}
				handleSendConnectRequest={handleCreateConnectionRequest}
				handleClickNumberOfConnections={handleClickNumberOfConnections}
			/>
			{activeTabComponent}
		</PageContainer>
	)
}

export default UserProfile

import { useMemo } from 'react'

import useTakeSkip from '@/anthology/hooks/useTakeSkip'
import {
	selectOrgsAsAutocompleteOption as selectEngageOrgsAsAutocompleteOption,
	useSearchOrganizationsQuery as useSearchEngageOrganizationsQuery,
} from '@/anthology/organizations/api'
import { DEFAULT_ENTITIES } from '@/components/Editor/config'
import { IS_ENGAGE } from '@/constants/configuration'
import settings from '@/constants/http'
import { campusSelectors } from '@/features/campus/slice'
import {
	selectOrgsAsAutocompleteOption as selectNativeOrgsAsAutocompleteOption,
	useSearchOrganizationsQuery as useSearchNativeOrganizationsQuery,
} from '@/features/organizations/api'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { selectUsersAsAutocompleteOption, useAutoCompleteUsersQuery } from '@/features/users/api'
import useAuth from '@/hooks/useAuth'
import { useAppSelector } from '@/store'

const useOrganizationAutocomplete = (search: string, skipQuery: boolean) => {
	const { userId, schoolId } = useAuth()
	const campusMap = useAppSelector(campusSelectors.selectEntities)
	const { take, skip, loadMore } = useTakeSkip(IS_ENGAGE ? settings.SEARCH_LIST_PER_PAGE : 1, IS_ENGAGE ? 0 : 1)

	const selectEngageData = useMemo(selectEngageOrgsAsAutocompleteOption, [])
	const engageOrgsQuery = useSearchEngageOrganizationsQuery(
		{ name: search, take, skip },
		{
			selectFromResult: (query) => ({
				...query,
				options: selectEngageData(campusMap, query.data),
			}),
			skip: skipQuery || !IS_ENGAGE,
		},
	)

	const selectNativeData = useMemo(selectNativeOrgsAsAutocompleteOption, [])

	const nativeOrgsQuery = useSearchNativeOrganizationsQuery(
		{ userId, q: search, page: skip, schoolId },
		{
			selectFromResult: (query) => ({
				...query,
				options: selectNativeData(campusMap, query.data),
			}),
			skip: skipQuery || IS_ENGAGE,
		},
	)

	const query = IS_ENGAGE ? engageOrgsQuery : nativeOrgsQuery

	return {
		...query,
		loadMore,
	}
}

const useMentionAutocomplete = (search: string | null = null) => {
	const campusMap = useAppSelector(campusSelectors.selectEntities)
	const selectUsersData = useMemo(selectUsersAsAutocompleteOption, [])
	const { loadMore, skip, take } = useTakeSkip()

	const usersQuery = useAutoCompleteUsersQuery(
		{ search, skip, take },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectUsersData(campusMap, result.data),
			}),
			skip: false,
		},
	)

	const orgsQuery = useOrganizationAutocomplete(search, !DEFAULT_ENTITIES.includes(EntityTypes.organization))

	return { [EntityTypes.user]: { ...usersQuery, loadMore }, [EntityTypes.organization]: orgsQuery }
}

export default useMentionAutocomplete

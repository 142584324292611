import http, { formDataConfig } from '@/api/http/requestHttp'

interface UploadPhotoParams {
	file: File
	directory?: string
	isPrivateImage?: boolean
	schoolId: number
}

const uploadPhoto = async ({ file, directory, isPrivateImage, schoolId }: UploadPhotoParams) => {
	const url = `${schoolId}/s3/uploads/photoUpload`
	const privateUploadUrl = `${schoolId}/s3/uploads/secure/photoUpload`

	const data = new FormData()

	data.append('file', file)
	if (directory) data.append('directory', directory)

	return http.post(isPrivateImage ? privateUploadUrl : url, data, formDataConfig)
}

export default uploadPhoto

import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import { STYLES } from '@/constants'
import { ColorVariant } from '@navengage/sen-shared-assets'

interface StylesProps {
	backgroundColor: ColorVariant
	backgroundHoverColor: string
	color?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ color, backgroundColor, backgroundHoverColor }) => ({
		color: color || theme.palette.common.white,
		backgroundColor: backgroundColor[500],
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '28%',
		margin: '1% 0',
		padding: '35px 25px',
		borderRadius: 8,
		textTransform: 'none',
		...theme.typography.body1,
		textAlign: 'left',
		'& > span': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'&:hover': {
			backgroundColor: backgroundHoverColor,
		},
	}),
	title: {
		fontWeight: 700,
		margin: '10px 0',
		textAlign: 'left',
	},
}))

interface GroupTypeButtonProps extends StylesProps {
	name: string
	description: string
	onClick?: () => void
}

const GroupTypeButton = ({ name, description, backgroundColor, backgroundHoverColor, color, onClick }: GroupTypeButtonProps) => {
	const classes = useStyles({ backgroundColor, backgroundHoverColor, color })

	return (
		<Button className={classes.root} onClick={onClick}>
			<Typography className={classes.title}>{name}</Typography>
			<Typography>{description}</Typography>
		</Button>
	)
}

export default GroupTypeButton

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import NavigationTabs from '@/components/NavigationTabs'
import { STYLES } from '@/constants'
import NavigationTab from '@/components/NavigationTab'
import { ReactNode } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 60,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		margin: '2vh 2vw',
		[theme.breakpoints.down('sm')]: {
			width: '96vw',
		},
	},
	blockHeader: {
		paddingLeft: '1.5vw',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleText: {
		fontWeight: 700,
		marginRight: 5,
	},
	tabs: {
		minWidth: 'fit-content',
		paddingRight: '3vw',
		height: 60,
	},
	tabWrapper: {
		marginTop: 0,
	},
}))

interface ItemsSectionTitleProps {
	title: string
	pageInfo?: ReactNode
	tabs?: string[]
	activeTab?: number
	handleChangeTab?: (tab: number) => void
}

const ItemsSectionTitle = ({ title = '', handleChangeTab = () => {}, tabs = [], pageInfo = '', activeTab = 0 }: ItemsSectionTitleProps) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.blockHeader}>
				<Typography className={classes.titleText} variant="h2">
					{title}
				</Typography>
				{pageInfo && (
					<Typography variant="h2">
						{`| `}
						{pageInfo}
					</Typography>
				)}
			</div>
			{!!tabs.length && (
				<div className={classes.tabs}>
					<NavigationTabs
						activeTabIndex={activeTab}
						tabsRenderer={(props) =>
							tabs.map((tab, index) => (
								<NavigationTab
									className={classes.tabWrapper}
									key={`${tab}-${index}`}
									index={index}
									label={tab}
									onClick={handleChangeTab}
									{...props}
								/>
							))
						}
					/>
				</div>
			)}
		</div>
	)
}

export default ItemsSectionTitle

import { useAppDispatch, useAppSelector } from '@/store'
import { getIsOpenDeleteConfirmationModal, setDeleteConfirmationModal } from '@/store/feeds'
import { push } from 'connected-react-router'
import { useCallback } from 'react'

const useDeleteConfirmationModal = (redirectPath: string): [boolean, (e: any) => void] => {
	const dispatch = useAppDispatch()
	const isOpenConfirmationModal = useAppSelector(getIsOpenDeleteConfirmationModal)

	const handleCloseDeleteConfirmation = useCallback(
		(e: any) => {
			e.stopPropagation()

			dispatch(setDeleteConfirmationModal(false))
			if (redirectPath) {
				push(redirectPath)
			}
		},
		[dispatch, redirectPath],
	)
	return [isOpenConfirmationModal, handleCloseDeleteConfirmation]
}

export default useDeleteConfirmationModal

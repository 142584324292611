const ROOT = 'network'

const NETWORK = {
	ROOT,
	MY: `${ROOT}/my`,
	SEARCH: `${ROOT}/search`,
	USER: (id?: string) => `${ROOT}/user/${id !== undefined ? id : ':id'}`,
}

export default NETWORK

import { MiddlewareAPI, Action } from '@reduxjs/toolkit'
import { store } from '.'
import { getLogger } from '../init/DIContainer'
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/auth'
import { resolveSchoolId } from '../utils'

export const sentryMiddleware = (api: MiddlewareAPI) => (next) => (action: Action) => {
	const authActions = [
		`${AUTH_MODULE_NAME}/SIGN_IN`,
		`${AUTH_MODULE_NAME}/SIGN_OUT`,
		`${AUTH_MODULE_NAME}/SIGN_UP`,
		`${AUTH_MODULE_NAME}/SET_SIGN_UP_FORM}`,
	]
	// Skip if not an auth action
	if (!authActions.some((type) => type === action.type)) return next(action)

	const sentry = getLogger()
	const currentState = store.getState()
	const user = currentState.AUTH.userInfo

	const currScope = sentry.getCurrentHub().getScope()
	if (!(currScope.getUser()?.id === (user?.id ?? -1).toString())) {
		sentry.configureScope((scope) => {
			scope.setUser({
				id: user.id.toString(),
				username: user.firstName + ' ' + user.lastName,
				school: resolveSchoolId(),
			})
		})
	}
	return next(action)
}

import { Typography, makeStyles } from '@material-ui/core'
import { useCallback } from 'react'

import { Toggle } from '@/components'
import { useAppDispatch, useAppSelector } from '@/store'
import { selectFeedCampusId, selectIncludeAllCampuses, setFilterIncludeAllCampuses } from '../../feed/slice'

const useStyles = makeStyles(() => ({
	root: {
		margin: '15px 0px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 7,
	},
}))

const ToggleAllCampuses = () => {
	const selectedCampus = useAppSelector(selectFeedCampusId)
	const classes = useStyles()
	const includeAllCampuses = useAppSelector(selectIncludeAllCampuses)
	const dispatch = useAppDispatch()
	const handleToggle = useCallback(() => {
		dispatch(setFilterIncludeAllCampuses(!includeAllCampuses))
	}, [dispatch, includeAllCampuses])

	return selectedCampus ? (
		<div className={classes.root}>
			<Toggle value={includeAllCampuses} onChange={handleToggle} labelPosition="end" label={''} />
			<Typography>Include posts shared to all campuses</Typography>
		</div>
	) : null
}

export default ToggleAllCampuses

import CategoryTag from '@/components/CategoryTag'
import Card from '@/components/IndividualPage/Card'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Category from '../types/Category'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	categories: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	rootContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '2vh',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	infoSection: {
		display: 'flex',
		flexDirection: 'row',
	},
	categoryTag: {
		marginBottom: 0,
	},
	descriptionText: {
		fontSize: 18,
		'&>p': {
			margin: 0,
			fontSize: 18,
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: 16,
			'&>p': {
				margin: 0,
				fontSize: 16,
			},
		},
	},
}))

interface AboutOrganizationCardProps {
	categories?: Category[]
	description?: string
	organizationStats?: React.ReactNode
}

const AboutOrganizationCard = ({ categories, description, organizationStats = null }: AboutOrganizationCardProps) => {
	const classes = useStyles()

	return (
		<Card>
			<div className={classes.rootContainer}>
				<div className={classes.headerContainer}>
					<Typography className={classes.title} variant="h3">
						About this organization
					</Typography>
					{categories.length > 0 && (
						<div className={classes.categories}>
							{categories?.map(({ id, name }) => (
								<CategoryTag key={id} className={classes.categoryTag} name={name} />
							))}
						</div>
					)}
				</div>
				<div className={classes.infoSection}>{organizationStats}</div>
			</div>
			{description && <DescriptionHtml className={classes.descriptionText} description={description} />}
		</Card>
	)
}

export default AboutOrganizationCard

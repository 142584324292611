import * as http from '@/api/http'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/feeds/constants'
import { DtoFeedsFilterOptions, FeedPost, FeedsFilters } from '@/store/feeds/types'

import { campusSelectors } from '@/features/campus/slice'
import { getMinimumOrgInfo, getNormalizeUser } from '@/utils/transformers'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setConstants = createAction<DtoFeedsFilterOptions>(`${MODULE_NAME}/SET_FEEDS_CONSTANTS`)

export const setPost = createAction<any>(`${MODULE_NAME}/SET_POST`)

export const setFilters = createAction<FeedsFilters>(`${MODULE_NAME}/SET_FILTERS`)
export const clearFeedsList = createAction<undefined>(`${MODULE_NAME}/CLEAR_FEEDS_LIST`)
export const resetFilters = createAction<undefined>(`${MODULE_NAME}/RESET_FEEDS_FILTERS`)

// @TODO: this temporary solution, long run use RouterModal instead of keeping state
// You can check implementation in the oms and how it works
export const setIsScheduledPostModalOpened = createAction<boolean>(`${MODULE_NAME}/SET_SCHEDULED_POSTS_MODAL`)

export const updateSingleComment = createAction<any>(`${MODULE_NAME}/UPDATE_SINGLE_COMMENT`)
export const deleteSingleComment = createAction<{
	postId: string
	commentId: number
}>(`${MODULE_NAME}/DELETE_SINGLE_COMMENT`)
export const setSelectedPost = createAction<FeedPost>(`${MODULE_NAME}/SET_SELECTED_FEED`)
export const setDeleteConfirmationModal = createAction<boolean>(`${MODULE_NAME}/SET_DELETE_CONFIRMATION_MODAL`)

export const preparePostData = (post: any, state: RootState) => {
	const campusesMap = campusSelectors.selectEntities(state)

	let updatedData = {
		...post,
	} as FeedPost

	if (updatedData.ownerUser) {
		updatedData.ownerUser = getNormalizeUser(updatedData.ownerUser, campusesMap)
	}

	if (updatedData.ownerOrganization) {
		updatedData.ownerOrganization = getMinimumOrgInfo(updatedData.ownerOrganization, campusesMap)
	}

	updatedData.liked = +updatedData.liked > 0

	return updatedData
}

export const loadFeedConstantsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_FEEDS_CONSTANTS_REQUEST`, async (_undefiend, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()

		const { schoolId, userId } = selectAuth(rootState)

		const { data } = await http.feeds.getConstants(userId, schoolId)

		dispatch(setConstants(data))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const setPostResponseRequest = createAsyncThunk<
	any,
	{
		id: string
		status: UserResponseStatuses | ReportActions
		reportMessage?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_POST_RESPONSE_REQUEST`, async ({ id, status, reportMessage }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		await http.feeds.createPostUserResponse({ postId: id, userId, response: status, reportMessage, schoolId })
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { SvgIcon } from '@material-ui/core'

const PersonOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 24 24" width={13} height={16} {...props}>
			<g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 5439" fill="none" stroke="currentColor">
				<g data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1740">
					<path
						d="M6.364 9h.273A6.364 6.364 0 0113 15.364a.636.636 0 01-.636.636H.636A.636.636 0 010 15.364 6.364 6.364 0 016.364 9z"
						stroke="none"
					/>
					<path d="M6.2 9.5h.6a5.7 5.7 0 015.7 5.7h0a.3.3 0 01-.3.3H.8a.3.3 0 01-.3-.3h0a5.7 5.7 0 015.7-5.7z" />
				</g>
				<g data-name="\u042D\u043B\u043B\u0438\u043F\u0441 38" transform="translate(3)">
					<circle cx={3.5} cy={3.5} r={3.5} stroke="none" />
					<circle cx={3.5} cy={3.5} r={3} />
				</g>
			</g>
		</SvgIcon>
	)
}

export default PersonOutlinedIcon

import EventCard from '@/components/ExperienceCards/EventCard'
import useEventByIdQuery from '@/features/events/types/hooks/useEventByIdQuery'

import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedEventProps = ISharedEntityProps

const SharedEvent: React.FC<ISharedEventProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useEventByIdQuery(id, skip || type !== EntityTypes.event)

	return withErrorHandler({
		type,
		currentType: EntityTypes.event,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<EventCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.photoUrl}
				startDate={data.startDate}
				endDate={data.endDate}
				categories={data.categories}
				description={data.description}
				organization={data.organization}
				locationName={data.locationName}
				eventResponse={data.response}
				volunteerShifts={data.volunteerShifts}
				submittedByUserId={data.submittedByUserId}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedEvent

import { useCallback, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { PATHS, STYLES } from '@/constants'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router-dom'
import SearchUserHeader from '@/components/Messages/SearchUserHeader'
import { UserData } from '@/interfaces/common'
import {
	clearAddNewUserToMessagesListInput,
	clearTargetUser,
	getChatsList,
	getShowSelectUserInput,
	getTargetUser,
	setShowSelectUserInput,
} from '@/store/messages'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'

import { getSearchGroupsData, getShowCreateGroupModal, getTargetGroup, setShowCreateGroupModal } from '@/store/groups'
import EmptyMessagesPlaceholder from '../../components/Messages/NoMessagesPlaceholder'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'calc(100% - 90px)',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,

		[theme.breakpoints.down('sm')]: {
			paddingBottom: 100,
		},
	},
}))

const NewMessagePage = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const appTheme = useTheme()
	const messagesList = useSelector(getChatsList)

	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))

	const targetUser = useSelector(getTargetUser)
	const targetGroup = useSelector(getTargetGroup)

	const showSelectUserInput = useSelector(getShowSelectUserInput)
	const showCreateGroupModal = useSelector(getShowCreateGroupModal)
	const { q: searchGroupValue } = useSelector(getSearchGroupsData)

	const handleSelectUser = useCallback(
		(user: UserData) => {
			push(PATHS.APP.MESSAGES_USER_DIALOGUE(user.id))
		},
		[push],
	)

	const handleClearUserData = useCallback(() => dispatch(clearAddNewUserToMessagesListInput()), [dispatch])

	const handleCloseSelectUserInput = useCallback(() => {
		dispatch(setShowSelectUserInput(false))
	}, [dispatch])

	const handleShowCreateGroupModal = useCallback(() => {
		handleClearUserData()
		dispatch(setShowCreateGroupModal(true))
		dispatch(setShowSelectUserInput(false))
	}, [dispatch, handleClearUserData])

	const showOnlyMessages = !mdScreenSize && (!!targetUser || showSelectUserInput || !!targetGroup)

	useEffect(() => {
		if (showCreateGroupModal || showSelectUserInput || searchGroupValue) return

		// automatic selection of the last dialogue between users if target user not found
		if (messagesList.length && !showSelectUserInput) {
			if (messagesList[0].user) {
				push(PATHS.APP.MESSAGES_USER_DIALOGUE(messagesList[0].user.id))
			} else {
				// push(PATHS.APP.MESSAGES_GROUP(messagesList[0].user.id));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{(mdScreenSize || showOnlyMessages) && !targetUser && (
				<div className={classes.container}>
					{showSelectUserInput ? (
						<SearchUserHeader
							handleSelectUser={handleSelectUser}
							onClickStartGroup={handleShowCreateGroupModal}
							onClose={handleCloseSelectUserInput}
						>
							{showOnlyMessages && (
								<IconButton onClick={() => dispatch(clearTargetUser())}>
									<KeyboardArrowLeft fontSize="large" />
								</IconButton>
							)}
						</SearchUserHeader>
					) : (
						<EmptyMessagesPlaceholder />
					)}
				</div>
			)}
		</>
	)
}

export default NewMessagePage

import { CircularProgress, Popper } from '@material-ui/core'
import MUIIconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useRef, useState } from 'react'

import { EllipsisHorizontalIcon, ExclamationCircleIcon, IconButton } from '@/components'
import ReportModal, { ReportSubject } from '@/components/ReportModal'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { ReportActions } from '@/interfaces/common'

const useStyles = makeStyles((theme) => ({
	root: ({ variant }: any) => {
		const mode = theme.palette.type
		const cssOptions = {
			width: 42,
			height: 42,
			color: variant === 'primary' ? theme.palette.common.white : theme.palette.common.black,
			backgroundColor:
				variant === 'primary'
					? theme.palette.specialColors.blacktransparent60
					: theme.palette.type === 'dark'
					? theme.palette.background.default
					: theme.palette.grey[200],
			'&:hover': {
				backgroundColor: variant === 'primary' ? theme.palette.specialColors.blacktransparent80 : theme.palette.grey[500],
			},
		}
		if (mode === 'dark') {
			cssOptions.color = variant === 'primary' ? theme.palette.text.primary : theme.palette.text.secondary
			cssOptions.backgroundColor = variant === 'primary' ? theme.palette.background.default : theme.palette.background.paper
			cssOptions['&:hover'] = {
				backgroundColor: theme.palette.action.hover,
			}
		}
		return cssOptions
	},
	optionsContainer: {
		backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		zIndex: 1445,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	preloader: {
		minHeight: 100,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

export interface DotButtonProps {
	renderButtons?: {
		label: string
		icon: React.ReactNode
		onClick: (e: any) => void
	}[]
	isLoading?: boolean
	itemId?: any
	reportSubject?: ReportSubject
	onSubmitReport?: (id: string, reportType: ReportActions, comment: string) => void
	variant?: 'primary' | 'secondary'
	redirect?: string
	className?: string
	showReportButton?: boolean
	onClick?: (e: any) => void
}

const DotButton = ({
	renderButtons = [],
	itemId,
	reportSubject,
	onSubmitReport,
	variant = 'primary',
	redirect,
	className,
	showReportButton = true,
	isLoading = false,
	onClick,
}: DotButtonProps) => {
	const classes = useStyles({ variant })
	const [open, setOpen] = useState(false)
	const [isOpenReportModal, setIsOpenReportModal] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleOpenReportModal = (e: any) => {
		e.stopPropagation()
		setOpen(false)
		setIsOpenReportModal(true)
	}

	const handleCloseReportModal = (e: any) => {
		e.stopPropagation()
		setIsOpenReportModal(false)
	}

	const handleToggle = (e: any) => {
		if (onClick) {
			onClick(e)
			return
		}
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClickModal = (e: any) => {
		e.stopPropagation()
	}

	const handleClickModalButton = (callback: (e: any) => void) => (e: any) => {
		setOpen(false)
		callback(e)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	useOutsideClickCallback(popperRef, handleClose)

	return (
		<div onClick={handleClickModal}>
			{reportSubject && (
				<ReportModal
					subjectId={itemId!}
					subject={reportSubject}
					onSubmitReport={onSubmitReport!}
					isOpenModal={isOpenReportModal}
					handleCloseModal={handleCloseReportModal}
					redirect={redirect}
				/>
			)}
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					{isLoading ? (
						<div className={classes.preloader}>
							<CircularProgress />
						</div>
					) : (
						<>
							{renderButtons.map((btn) => (
								<IconButton
									key={btn.label}
									className={classes.popoverButton}
									onClick={handleClickModalButton(btn.onClick)}
									startIcon={btn.icon}
								>
									{btn.label}
								</IconButton>
							))}
							{showReportButton && (
								<IconButton className={classes.popoverButton} onClick={handleOpenReportModal} startIcon={<ExclamationCircleIcon />}>
									{reportSubject === ReportSubject.User ? `Report this user` : `Report`}
								</IconButton>
							)}
						</>
					)}
				</div>
			</Popper>
			<MUIIconButton className={classNames(classes.root, className)} ref={anchorRef} onClick={handleToggle}>
				<EllipsisHorizontalIcon />
			</MUIIconButton>
		</div>
	)
}

export default DotButton

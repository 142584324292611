import { FC, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import { UserOrganizationTypes } from '@/anthology/organizations/types/UserOrganizationTypes'
import { SubmissionStatusModal } from '@/anthology/components/ModalWithSubmittedPopup'
import useCurrentUserOrgs from '@/anthology/organizations/hooks/useCurrentUserOrgs'
import { Modal } from '@/components'
import { AVAILABLE_EVENT_RESPONSES } from '../../constants'
import useCreateUpdateRsvpMutation, { FormData } from '../../hooks/useCreateUpdateRsvpMutation'
import EventRsvpOption from '../../types/EventRsvpOption'
import DtoEvent from '../../types/dto/DtoEvent'
import { IQuestionsFormProps } from './QuestionsForm'
import RsvpButton from './RsvpButton'
import RsvpModal from './RsvpModal'

export type IEventRsvpProps = {
	eventId: DtoEvent['id']
	settings: DtoEvent['rsvpSettings']
	response: DtoEvent['response']
	pastEvent?: boolean
}

const useStyles = makeStyles((theme) => ({
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
}))

type TRsvpState = {
	isRsvpModalOpen: boolean
	userResponse?: EventRsvpOption
	isSubmissionModalOpen: boolean
}

const DEFAULT_STATE: TRsvpState = {
	isRsvpModalOpen: false,
	userResponse: null,
	isSubmissionModalOpen: false,
}

const EventRsvp: FC<IEventRsvpProps> = ({ settings, eventId, response: currentResponse, pastEvent }) => {
	const classes = useStyles()

	const [rsvpState, setRsvpState] = useState<TRsvpState>({
		...DEFAULT_STATE,
		userResponse: currentResponse?.response,
	})
	const {
		handleSubmitRsvpForm,
		query: { isError, isLoading, isSuccess, reset },
	} = useCreateUpdateRsvpMutation({ eventId, response: currentResponse })

	const { isLoading: isOrganizationCheckLoading, data: orgMembershipData } = useCurrentUserOrgs(
		UserOrganizationTypes.member,
		!settings?.organizationRepresentationEnabled,
		1,
	)

	const showOrganizationError = orgMembershipData?.items.length === 0 && settings?.organizationRepresentationRequired

	const shouldOpenRsvpModal = useCallback(
		(response: EventRsvpOption) => {
			return response === 'No'
				? false
				: !!settings.questions || (settings.organizationRepresentationEnabled && !!orgMembershipData?.items.length)
		},
		[orgMembershipData?.items.length, settings.organizationRepresentationEnabled, settings.questions],
	)

	const handleOpenRsvpModal = useCallback(
		(response: EventRsvpOption) => () => {
			const newState: Partial<TRsvpState> = {
				userResponse: response,
			}

			newState.isSubmissionModalOpen = showOrganizationError

			if (response !== currentResponse?.response && !showOrganizationError) {
				if (shouldOpenRsvpModal(response)) {
					newState.isRsvpModalOpen = true
				} else {
					newState.isSubmissionModalOpen = true

					handleSubmitRsvpForm({
						response,
					})
				}
			}
			setRsvpState((prev) => ({
				...prev,
				...newState,
			}))
		},
		[currentResponse?.response, handleSubmitRsvpForm, shouldOpenRsvpModal, showOrganizationError],
	)

	const onSubmit: IQuestionsFormProps['onSubmit'] = useCallback(
		({ data, organizationIds }) => {
			setRsvpState((prev) => ({
				...prev,
				isSubmissionModalOpen: true,
				isRsvpModalOpen: false,
			}))
			const formData: FormData = {
				response: rsvpState.userResponse,
				organizationIds,
			}

			if (rsvpState.userResponse === 'Yes' && data?.formData) {
				formData.answers = JSON.stringify(data.formData)
			}

			handleSubmitRsvpForm(formData)
		},
		[handleSubmitRsvpForm, rsvpState.userResponse],
	)

	const closeModal = useCallback(() => {
		reset()
		setRsvpState({ ...DEFAULT_STATE })
	}, [reset])

	const getActiveButtonStyle = useCallback(
		(res: EventRsvpOption) => (res === currentResponse?.response ? classes.menuActiveButton : ''),
		[classes.menuActiveButton, currentResponse?.response],
	)
	const rsvpButtons = useMemo(
		() =>
			(Object.keys(AVAILABLE_EVENT_RESPONSES) as EventRsvpOption[]).map((res) => ({
				label: AVAILABLE_EVENT_RESPONSES[res],
				onClick: handleOpenRsvpModal(res),
				className: getActiveButtonStyle(res),
			})),
		[getActiveButtonStyle, handleOpenRsvpModal],
	)

	return (
		<>
			{!showOrganizationError && (
				<RsvpModal
					hasMemberships={!!orgMembershipData?.items.length}
					settings={settings}
					isOpen={rsvpState.isRsvpModalOpen}
					response={currentResponse}
					onClose={closeModal}
					onSubmit={onSubmit}
				/>
			)}

			<Modal overflow="inherit" isOpen={rsvpState.isSubmissionModalOpen}>
				<SubmissionStatusModal
					handleClose={closeModal}
					isError={isError || showOrganizationError}
					isLoading={(isLoading || isOrganizationCheckLoading) && !showOrganizationError}
					isSuccess={isSuccess}
					submittedMessage="Your RSVP has been submitted"
					errorMessage={
						showOrganizationError
							? 'Membership Required: To RSVP for this event, you must be a member of the organization.'
							: 'Unable to submit RSVP. Please try again later.'
					}
				/>
			</Modal>

			<RsvpButton
				rsvpButtons={rsvpButtons}
				currentResponse={currentResponse?.id ? currentResponse?.response : undefined}
				isInviteOnly={settings.isInviteOnly}
				hasResponse={!!currentResponse?.id}
				pastEvent={pastEvent}
			/>
		</>
	)
}

export default EventRsvp

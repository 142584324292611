import { GroupsState } from '@/store/groups/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/groups/constants'

const selectState = (state: { [MODULE_NAME]: GroupsState }) => state[MODULE_NAME]

export const getError = createSelector(selectState, (state) => state.error)

export const getSearchUserResults = createSelector(selectState, (state) => state.searchUsers.users)
export const getCanLoadMoreSearchUserResults = createSelector(selectState, (state) => state.searchUsers.canLoadMore)
export const getIsLoadingSearchUserResults = createSelector(selectState, (state) => state.searchUsers.isLoading)

export const getSearchGroupsQuery = createSelector(selectState, (state) => state.searchGroups.q)
export const getSearchGroupsResults = createSelector(selectState, (state) => state.searchGroups.groups)
export const getCanLoadMoreSearchGroupsResults = createSelector(selectState, (state) => state.searchGroups.canLoadMore)
export const getIsLoadingSearchGroupsResults = createSelector(selectState, (state) => state.searchGroups.isLoading)

export const getTargetGroup = createSelector(selectState, (state) => state.targetGroup)
export const getGroupRole = createSelector(selectState, (state) => state.targetGroup?.userMemberType)
export const getShowCreateGroupModal = createSelector(selectState, (state) => state.showAddNewGroupModal)
export const getIsCreatingNewGroup = createSelector(selectState, (state) => state.isCreatingNewGroup)

export const getSearchGroupsData = createSelector(selectState, (state) => state.searchGroups)
export const getSearchedGroup = (groupId: string) =>
	createSelector(selectState, (state) => state.searchGroups.groups.find(({ id }) => id === groupId))

export const getSearchMembersData = createSelector(selectState, (state) => state.searchMembers)

export const getMemberToView = createSelector(selectState, (state) => state.memberToView)

export const getSearchMemberRequestsData = createSelector(selectState, (state) => state.searchMemberRequests)

export const getIsOpenDrawer = createSelector(selectState, (state) => state.isOpenDrawer)
export const getIsOpenAddNewMemberPopup = createSelector(selectState, (state) => state.isOpenAddNewMembers)

export const selectTargetedGroupGallery = createSelector(selectState, (state) => state.gallery)

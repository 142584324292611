import { useAppSelector } from '@/store'
import { selectAuth } from '@/store/auth'

export const useSchoolId = () => {
	const { schoolId } = useAppSelector(selectAuth)

	return schoolId
}

export default useSchoolId

import { STYLES } from '@/constants'
import { CircularProgress, Theme, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { ReactNode } from 'react'

const useStyles = makeStyles<Theme, Pick<IContentPreloaderProps, 'fullPage'>>(() => ({
	root: ({ fullPage }) => ({
		minHeight: fullPage ? `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)` : 100,

		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	}),
}))

export type IContentPreloaderProps = {
	root?: string
	preloader?: ReactNode
	preloaderClass?: string
	fullPage?: boolean
}

const ContentPreloader: React.FC<IContentPreloaderProps> = ({ root, preloader, preloaderClass, fullPage }) => {
	const classes = useStyles({ fullPage })
	return <div className={classNames(classes.root, root)}>{preloader ? preloader : <CircularProgress className={preloaderClass} />}</div>
}

export { ContentPreloader }

import { isEngage } from '@/constants/configuration'
import SharedEvent from './components/SharedEvent'
import SharedJob from './components/SharedJob'
import SharedOrganization from './components/SharedOrganization'
import SharedPost from './components/SharedPost'
import SharedResearch from './components/SharedResearch'
import SharedStudyAbroad from './components/SharedStudyAbroad'
import EntityTypes from './types/EntityTypes'

// Engage shared cards
import EngageSharedEvent from './components/engage/SharedEvent'
import EngageSharedOrganization from './components/engage/SharedOrganization'

export const MAP_SHARED_ENTITY_TYPE_TO_COMPONENT = {
	[EntityTypes.event]: isEngage() ? EngageSharedEvent : SharedEvent,
	[EntityTypes.organization]: isEngage() ? EngageSharedOrganization : SharedOrganization,
	[EntityTypes.job]: SharedJob,
	[EntityTypes.research]: SharedResearch,
	[EntityTypes.studyAbroad]: SharedStudyAbroad,
	[EntityTypes.post]: SharedPost,
}

export const SHARED_ENTITY_NAMES = {
	[EntityTypes.event]: 'Event',
	[EntityTypes.organization]: 'Organization',
	[EntityTypes.job]: 'Internship / Job',
	[EntityTypes.research]: 'Research',
	[EntityTypes.studyAbroad]: 'Study Abroad',
	[EntityTypes.post]: 'Post',
}

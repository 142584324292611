import Card from '@/components/IndividualPage/Card'
import NetworkUserRow from '@/components/IndividualPage/NetworkUserRow'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import { useGetEventResponsesQuery } from '@/features/events/api'
import EmptyResponseListPlaceholder from '@/features/events/components/EmptyResponseListPlaceholder'
import { EventResponseList } from '@/features/events/components/EventResponseList'
import { InfoBlock } from '@/features/events/components/ResponseListInfo'
import { EVENT_RESPONSE_MAP } from '@/features/events/constants'
import { EventResponseType } from '@/features/events/types/EventResponseType'
import { UserData } from '@/interfaces/common'
import { formatDateNew } from '@/utils/dateTime2'

import { getShiftsInfo } from '@/utils/events'
import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Fragment, useCallback, useMemo, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginRight: 30,
		cursor: 'pointer',
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	expandIcon: {
		color: theme.palette.text.secondary,
	},
	smallText: {
		fontSize: 15,
	},
	iconButton: {
		padding: 5,
	},
	navigationContainer: {
		width: '100%',
		borderBottom: `solid 1px ${theme.palette.divider}`,
		marginBottom: '3vh',
	},

	infoBlockContainer: {
		display: 'flex',
		margin: '2vh 0',
	},

	shiftInfoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	shiftInfoRoot: {
		borderBottom: `solid 1px ${theme.palette.divider}`,
		margin: '2vh 0 3vh 0',
	},
	shiftInfo: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 'min-content%',
	},
	shiftInfoTitle: {
		fontWeight: 700,
		marginRight: 20,
	},
	shiftInfoTime: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 38,
		width: 200,
		backgroundColor: theme.palette.background.default,
		padding: '0 10px',
	},
	shiftInfoUsersInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
		width: 82,
		height: 38,
	},
	shiftInfoUsersInfoRed: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.red[500],
		backgroundColor: theme.colors.red[200],
		width: 82,
		height: 38,
	},
	shiftInfoPlaceholder: {
		color: theme.palette.text.secondary,
		margin: '2vh 0',
	},
}))

interface ResponsesListCardProps {
	defaultTab?: number
	volunteers?: { [k: string]: UserData[] }
	shifts?: any[]
	eventId: string
}

interface EventResponseTab {
	type: EventResponseType | 'volunteers'
	total: string | number
	label: string
	extraLabel?: string
	isLoading: boolean
}

const ResponsesListCard = ({ defaultTab = 0, volunteers, shifts, eventId }: ResponsesListCardProps) => {
	const [expandMore, setExpandMore] = useState(false)
	const [currentTab, setCurrentTab] = useState(defaultTab)
	const classes = useStyles()

	const { isLoading: attendeeIsLoading, data: attendeeData } = useGetEventResponsesQuery(
		{ response: 'attendee', id: eventId },
		{
			refetchOnMountOrArgChange: true,
		},
	)
	const { isLoading: goingIsLoading, data: goingData } = useGetEventResponsesQuery(
		{ response: 'going', id: eventId },
		{
			refetchOnMountOrArgChange: true,
		},
	)
	const { isLoading: interestedIsLoading, data: interestedData } = useGetEventResponsesQuery(
		{ response: 'interested', id: eventId },
		{
			refetchOnMountOrArgChange: true,
		},
	)

	const { totalSpots, bookedSpots } = getShiftsInfo(shifts || [])
	const totalVolunteers = `${bookedSpots}/${totalSpots}`

	const formedData: EventResponseTab[] = useMemo(() => {
		const tabs = []
		tabs.push({ type: 'going', total: goingData?.total, label: EVENT_RESPONSE_MAP.going, isLoading: goingIsLoading })
		tabs.push({ type: 'interested', total: interestedData?.total, label: EVENT_RESPONSE_MAP.interested, isLoading: interestedIsLoading })
		tabs.push({ type: 'attendee', total: attendeeData?.total, label: EVENT_RESPONSE_MAP.attendee, isLoading: attendeeIsLoading })
		tabs.push({ type: 'volunteers', total: totalVolunteers, label: 'Volunteers', extraLabel: 'volunteering' })
		return tabs
	}, [
		attendeeData?.total,
		attendeeIsLoading,
		goingData?.total,
		goingIsLoading,
		interestedData?.total,
		interestedIsLoading,
		totalVolunteers,
	])

	const tabLabel = formedData[currentTab].extraLabel || formedData[currentTab].label.toLocaleLowerCase()
	const tabData = formedData[currentTab].total

	const handleToggleExpandMore = () => setExpandMore((prev) => !prev)

	const handleChangeTab = useCallback((newTab: number) => {
		setCurrentTab(newTab)
	}, [])

	const expandedTabLabel = (
		<>
			<b>See who’s {tabLabel}</b> {`${expandMore && tabData ? ' | ' + tabData : ''}`}
		</>
	)

	return (
		<Card>
			<div className={classes.titleContainer}>
				{!expandMore ? (
					<Typography variant="h3">
						<b>Attendance Overview</b>
					</Typography>
				) : (
					<Typography variant="body1">{expandedTabLabel}</Typography>
				)}
				<IconButton className={classes.iconButton} onClick={handleToggleExpandMore}>
					{!expandMore ? <ExpandMore className={classes.expandIcon} /> : <ExpandLess className={classes.expandIcon} />}
				</IconButton>
			</div>
			{!expandMore && (
				<div className={classes.infoBlockContainer}>
					{formedData.map(({ label, type, total, isLoading }) => (
						<InfoBlock isLoading={isLoading} key={type} value={total} label={label} />
					))}
				</div>
			)}
			{expandMore && (
				<div className={classes.navigationContainer}>
					<NavigationTabs
						activeTabIndex={currentTab}
						centered={false}
						tabsRenderer={(props) =>
							formedData.map(({ label, type }, index) => (
								<NavigationTab key={`${type}-${index}`} index={index} label={label} onClick={handleChangeTab} {...props} />
							))
						}
					/>
				</div>
			)}

			{!expandMore ? null : (
				<div className={classes.root}>
					{formedData.map(({ type }, index) => {
						return type !== 'volunteers' ? (
							<Fragment key={type}>
								{currentTab === index ? (
									<EventResponseList title={tabLabel} eventId={eventId} responseType={formedData[currentTab].type as EventResponseType} />
								) : null}
							</Fragment>
						) : (
							<>
								{currentTab === index ? (
									volunteers && volunteers.length ? (
										shifts?.map((shift) => {
											const label = `SHIFT ${shift.id}`
											const time = `${formatDateNew(shift.startDate, 'timeSimple')}-${formatDateNew(shift.endDate, 'timeSimple')}`
											const { bookedSpots, totalSpots } = getShiftsInfo([shift])

											return (
												<div key={label} className={classes.shiftInfoRoot}>
													<div className={classes.shiftInfoContainer}>
														<div className={classes.shiftInfo}>
															<Typography className={classes.shiftInfoTitle}>{label}</Typography>
															<Typography className={classes.shiftInfoTime}>{time}</Typography>
														</div>
														<Typography
															className={bookedSpots ? classes.shiftInfoUsersInfo : classes.shiftInfoUsersInfoRed}
														>{`${bookedSpots}/${totalSpots}`}</Typography>
													</div>
													{volunteers && volunteers[shift.id]?.length ? (
														volunteers[shift.id]?.map((user: UserData, i: number) => <NetworkUserRow key={i} user={user} />)
													) : (
														<Typography className={classes.shiftInfoPlaceholder}>No one has singed up for a slot yet.</Typography>
													)}
												</div>
											)
										})
									) : (
										<EmptyResponseListPlaceholder type="volunteers" />
									)
								) : null}
							</>
						)
					})}
				</div>
			)}
		</Card>
	)
}

export default ResponsesListCard

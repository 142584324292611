import { InputAdornment, IconButton as MUIIconButton, Popper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'

import { IconButton, TextButton, UserRow } from '@/components'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import { STYLES } from '@/constants'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import {
	getCanLoadMoreSearchUserResults,
	getIsLoadingSearchUserResults,
	getSearchUserQuery,
	getSearchUserResults,
	searchUserRequest,
	setSearchUsersQuery,
} from '@/store/messages'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		position: 'fixed',
		height: 90,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.paper,
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			padding: '0 7%',
			width: '100vw',
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.SM,
		},
		borderBottom: `solid 1.5px ${theme.palette.border.bold}`,
	},
	blockHeader: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '0 1.5vw',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	autoSizer: {
		height: 'fit-content !important',
	},
	textInput: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
	},
	inputRoot: {
		caretColor: theme.palette.primary.dark,
		caretShape: 'bar',
		'&:not(.Mui-disabled)::-webkit-input-placeholder': {
			color: theme.palette.primary.light,
			opacity: 1,
		},
		'&.Mui-disabled::-webkit-input-placeholder': {
			color: theme.palette.text.secondary,
			opacity: 0.7,
		},
		'&:focus::-webkit-input-placeholder': {
			color: theme.palette.text.secondary,
			opacity: 0.7,
		},
	},
	optionsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		minWidth: 250,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '30vh',
		overflowY: 'auto',
	},
	paper: {
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	optionImage: {
		width: 28,
		height: 28,
		minWidth: 28,
		minHeight: 28,
		marginRight: 10,
	},
	selectedOptionImage: {
		width: 42,
		height: 42,
		minWidth: 42,
		minHeight: 42,
	},
	optionButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: theme.palette.text.primary,
		fontSize: 15,
		borderRadius: 0,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	searchPlaceholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		padding: '30px',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		margin: 0,
	},
	listSpinner: {
		width: 15,
		height: 15,
	},
	startGroupButton: {
		margin: '10px 0',
	},
}))

interface SearchUserHeaderProps {
	handleSelectUser?: (user: UserData) => void
	children?: React.ReactNode
	onClickStartGroup?: (e: any) => void
	onClose?: (e: any) => void
}

const SearchUserHeader = ({
	handleSelectUser = () => {},
	children = null,
	onClickStartGroup = () => {},
	onClose,
}: SearchUserHeaderProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const searchValue = useSelector(getSearchUserQuery)
	const canLoadMore = useSelector(getCanLoadMoreSearchUserResults)
	const isLoading = useSelector(getIsLoadingSearchUserResults)
	const users = useSelector(getSearchUserResults)

	const [focused, setFocused] = useState(true)
	const [showSuggestions, setShowSuggestions] = useState(true)

	const anchorRef = useRef<any>(null)
	const popperRef = useRef<any>(null)

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setSearchUsersQuery(value))
			setFocused(true)
			setShowSuggestions(true)
		},
		[dispatch],
	)

	const handleClosePopper = useCallback(() => {
		setShowSuggestions(false)
	}, [])

	const handleOpenPopper = useCallback(() => {
		setShowSuggestions(true)
	}, [])

	const handleStartGroup = useCallback(
		(e: any) => {
			onClickStartGroup(e)
			setShowSuggestions(false)
		},
		[onClickStartGroup],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(searchUserRequest({ fetchMore: true }))
	}, [dispatch])

	const selectUser = useCallback(
		(user: UserData) => () => {
			const { fullName } = user

			dispatch(setSearchUsersQuery(`${fullName}`))

			handleSelectUser(user)
			setShowSuggestions(false)
		},
		[dispatch, handleSelectUser],
	)

	const handleSearch = useCallback(() => {
		dispatch(searchUserRequest({}))
	}, [dispatch])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[],
	)

	useOutsideClickCallback(popperRef, handleClosePopper)

	useEffect(() => {
		delayedSearch()

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue])

	const open = focused && showSuggestions && !!anchorRef.current

	return (
		<AutoSizer className={classes.autoSizer}>
			{({ width }) => (
				<div
					ref={popperRef}
					className={classes.root}
					style={{
						width,
					}}
				>
					<div className={classes.blockHeader}>
						{children}
						<TextField
							ref={anchorRef}
							className={classes.textInput}
							onChange={handleChangeInput}
							onClick={handleOpenPopper}
							value={searchValue}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<Typography>Send a message to:</Typography>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<MUIIconButton onClick={onClose}>
											<Close />
										</MUIIconButton>
									</InputAdornment>
								),
								classes: { input: classes.inputRoot },
							}}
							autoFocus
						/>
					</div>
					<Popper
						className={classes.paper}
						container={popperRef.current}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						placement="bottom-start"
					>
						<div className={classes.optionsContainer}>
							{!!users.length ? (
								<ItemsSection
									handleLoadMoreItems={handleLoadMore}
									items={users}
									classnames={{
										content: classes.cardContainer,
										spinner: classes.listSpinner,
									}}
									canLoadMore={canLoadMore}
									isLoading={isLoading}
									renderItems={(items: any) => (
										<>
											{items.map((user: UserData, idx: number) => (
												<IconButton key={user.externalId} className={classNames(classes.optionButton)} onClick={selectUser(user)}>
													<UserRow
														profileImageRadius={7}
														user={user}
														classnames={{
															info: classes.greyText,
														}}
														showPrimaryTitle={false}
														openProfileOnClick={false}
													/>
												</IconButton>
											))}
										</>
									)}
								/>
							) : (
								<div className={classes.searchPlaceholder}>
									<Typography>No results found.</Typography>
								</div>
							)}
						</div>
						<TextButton className={classes.startGroupButton} onClick={handleStartGroup}>
							Start Group (Beta)
						</TextButton>
					</Popper>
				</div>
			)}
		</AutoSizer>
	)
}

export default SearchUserHeader

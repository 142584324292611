import { Avatar, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core'
import { TruncateTextWithTooltip } from '@/components/Text/TruncateTextWithTooltip'
import MentionTypeaheadOption from './MentionTypeaheadOption'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'

const useStyles = makeStyles((theme) => ({
	listItem: {
		minWidth: '320px',
	},
	userAvatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}))

export type ISuggestionItemProps = {
	index: number
	isSelected: boolean
	onClick: () => void
	onMouseEnter: () => void
	option: MentionTypeaheadOption
}

const SuggestionItem = ({ index, isSelected, onClick, option: { data }, onMouseEnter }: ISuggestionItemProps) => {
	const classes = useStyles()
	const { entityType, name, picture, externalId, summary } = data
	return (
		<ListItem className={classes.listItem} selected={isSelected} component="div" button dense onMouseEnter={onMouseEnter} onClick={onClick}>
			<ListItemAvatar>
				{entityType === EntityTypes.organization ? (
					<Avatar alt={name} src={picture} />
				) : (
					<LazyLoadProfileImage className={classes.userAvatar} externalUserId={externalId || ''} photoUrl={picture} />
				)}
			</ListItemAvatar>
			<ListItemText
				id={'typeahead-item-' + index}
				primary={name}
				secondary={
					<TruncateTextWithTooltip lines={1}>
						<span>{summary}</span>
					</TruncateTextWithTooltip>
				}
			/>
		</ListItem>
	)
}
export default SuggestionItem

import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import * as http from '@/api/http'
import { CreatePostReq } from '@/api/http/feeds/createPost'
import { PHOTO_BASE_URL } from '@/constants/configuration'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import useAuth from '@/hooks/useAuth'
import { useReduxState } from '@/hooks/useReduxState'
import { useSelector } from '@/hooks/useSelector'
import { globalStates, useGlobalState } from '@/lib/globalState'
import { usePubsubPublishers } from '@/lib/pubsub/hooks'
import { useAppDispatch } from '@/store'
import { closeSnackbar, enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import { preparePostData, setIsScheduledPostModalOpened, setPost } from '@/store/feeds'
import { getSelectedOrganization, loadOrganizationActivityRequest } from '@/store/organizations'
import { createPhotoUrl } from '@/utils/common'
import uploadPhoto from '@/utils/photoUpload'

export const useFeedPostCreator = () => {
	const dispatch = useAppDispatch()
	const { schoolId, userId } = useAuth()
	const { publishFeedPostCreated, publishFeedPostUpdated } = usePubsubPublishers(schoolId)

	const [createFeedsModal] = useGlobalState(globalStates.createFeedsModal)

	const reduxState = useReduxState()

	const { isEdit, content: feedContent } = createFeedsModal

	const selectedOrganizationInfo = useSelector(getSelectedOrganization)

	const createPost = useCallback(async () => {
		const postId = isEdit ? feedContent.postToEdit.id : null
		const snackId = uuidv4()

		try {
			const orgId = selectedOrganizationInfo?.id

			const {
				ownerOrganizationId,
				tag,
				usersAudience,
				audienceScope,
				campus,
				message,
				photoUrl,
				activeSince,
				sharedEntity,
				linkPreviewUrl,
				mentions,
			} = feedContent

			const snackMessage = isEdit ? 'Updating Post' : activeSince ? 'Scheduling Post' : 'Uploading Post'

			// TODO - snackbar takes forever to load
			dispatch(
				enqueueSnackbar({
					key: snackId,
					notification: {
						message: {
							type: SnackbarType.uploading,
							message: snackMessage,
						},
					},
				}),
			)

			const imageName = uuidv4()
			const imageLocationToUpload = getPhotoUrlToUpload({ photoUrl, imageName })

			const data: CreatePostReq = {
				userId,
				ownerOrganizationId,
				campusId: campus,
				usersAudience: usersAudience!,
				usersAudienceScope: audienceScope,
				tag: tag!,
				message,
				sharedEntityId: sharedEntity?.sharedEntityId,
				sharedEntityType: sharedEntity?.sharedEntityType,
				linkPreviewUrl,
				schoolId,
				mentions: mentions ? mentions.map(({ id, entityType }) => ({ id, entityType })) : [],
			}

			if (activeSince && activeSince > new Date().toISOString()) {
				data.activeSince = activeSince
			}

			if (isEdit) {
				data.id = postId
			}

			if (imageLocationToUpload) {
				data.photoUrl = imageLocationToUpload
			}

			if (imageLocationToUpload) {
				await uploadPhoto({
					schoolId,
					fileUrl: photoUrl,
					imageName,
					directory: PhotoUploadDirectories.POST,
					options: {
						compressImage: true,
						onError: {
							uploadErrors: UploadErrors.POST,
							uploadErrorMessage: UploadErrorMessage.POST,
						},
					},
				})
			} else if (isEdit && photoUrl) {
				// Keep the existing photo url
				data.photoUrl = photoUrl
			}

			const {
				data: { post: newPost },
			} = await (isEdit ? http.feeds.updatePost(data) : http.feeds.createPost(data))

			dispatch(closeSnackbar({ key: snackId }))

			dispatch(setPost(preparePostData(newPost, reduxState)))

			if (isEdit) {
				publishFeedPostUpdated({ postId, newPost })
			} else {
				publishFeedPostCreated({ post: newPost })
			}

			if (ownerOrganizationId && orgId) {
				dispatch(loadOrganizationActivityRequest({ resetPage: true, orgId }))
			}
			const showScheduleSuccessSnackbar = !isEdit && activeSince
			if (showScheduleSuccessSnackbar) {
				const successId = uuidv4()
				dispatch(
					enqueueSnackbar({
						key: successId,
						notification: {
							message: {
								message: 'Post Scheduled.',
								type: SnackbarType.success,
								callback: {
									label: 'See scheduled posts',
									onClick: () => {
										dispatch(setIsScheduledPostModalOpened(true))
										dispatch(closeSnackbar({ key: successId }))
									},
								},
							},
						},
					}),
				)
			}
		} catch (err: any) {
			dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						message: {
							type: SnackbarType.error,
							message: UploadErrorMessage.POST,
						},
					},
				}),
			)

			throw err
		} finally {
			//dispatch(closeSnackbar({ key: postId }))
		}
	}, [
		isEdit,
		feedContent,
		userId,
		selectedOrganizationInfo?.id,
		dispatch,
		schoolId,
		reduxState,
		publishFeedPostUpdated,
		publishFeedPostCreated,
	])

	return { createPost, updatePost: createPost }
}

const getPhotoUrlToUpload = ({ photoUrl, imageName }) => {
	if (photoUrl?.includes(PHOTO_BASE_URL)) {
		return
	}
	return photoUrl ? createPhotoUrl(imageName, PhotoUploadDirectories.POST) : undefined
}

import ColorButton from '@/components/Buttons/ColorButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	select: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&:hover': {
			color: theme.palette.text.primary,
			backgroundColor: theme.palette.grey[200],
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: '48%',
		},
	},
	outlinedSelect: {
		color: theme.palette.text.primary,
	},
	selectContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		...theme.typography.body1,
	},
	requiredMark: {
		color: theme.colors.red[500],
	},
	icon: {
		width: 25,
		height: 25,
		marginRight: 10,
	},
	semiBold: {
		fontWeight: 600,
	},
}))

interface CardSelectButtonProps {
	className?: string
	label?: string
	defaultLabel: string
	required?: boolean
	onClick?: () => void
	startIcon?: React.ReactNode
	variant?: 'filled' | 'outlined'
}

const CardSelectButton = ({
	className = '',
	label,
	defaultLabel,
	required = false,
	onClick = () => {},
	startIcon,
	variant = 'outlined',
}: CardSelectButtonProps) => {
	const classes = useStyles()

	const selectContent = (
		<span className={classes.selectContainer}>
			{startIcon && <span className={classes.icon}>{startIcon}</span>}
			<div className={classes.semiBold}>{label || defaultLabel}</div>
			{required && !label && <Typography className={classes.requiredMark}>*</Typography>}
		</span>
	)

	return variant === 'outlined' ? (
		<OutlinedButton
			className={classNames(className, variant === 'outlined' ? (classes.select, classes.outlinedSelect) : classes.select)}
			color={ButtonColors.GREY}
			onClick={onClick}
		>
			{selectContent}
			<ExpandMore />
		</OutlinedButton>
	) : (
		<ColorButton className={classNames(className, classes.select)} color={ButtonColors.GREY} onClick={onClick}>
			{selectContent}
			<ExpandMore />
		</ColorButton>
	)
}

export default CardSelectButton

import { PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { UserMembership } from '@/interfaces/user'
import { isAdmin, isSuperAdmin } from './organizationRoles'

export enum ProfileInfoCardTypes {
	INTERESTS = 'Interests',
	MEMBERSHIPS = 'Memberships',
	INTERNSHIPS = 'Internships',
	EDUCATION_ABROAD = 'Education Abroad',
	RESEARCH = 'Research',
	VOLUNTEER = 'Volunteer',
	ADDITIONAL_INVOLVEMENT = 'Additional Involvement',
}

export const getAddProfileInfoItemLabel = (type: ProfileInfoCardTypes) => {
	switch (type) {
		case ProfileInfoCardTypes.MEMBERSHIPS:
			return 'Share your membership experience.'
		case ProfileInfoCardTypes.INTERNSHIPS:
			return 'Share your internship experience.'
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			return 'Share your education abroad experience.'
		case ProfileInfoCardTypes.RESEARCH:
			return 'Share your research experience.'
		case ProfileInfoCardTypes.VOLUNTEER:
			return 'Your volunteer experience on the app will appear here.'
		default:
			return 'Share your experience.'
	}
}

export const getSingleItemLabel = (type: ProfileInfoCardTypes, isLowerCase = false, additionalText = '', withPrefix = false) => {
	let label = ''

	switch (type) {
		case ProfileInfoCardTypes.MEMBERSHIPS:
			label = 'Membership'
			break
		case ProfileInfoCardTypes.INTERNSHIPS:
			label = 'Internship'
			break
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			label = ProfileInfoCardTypes.EDUCATION_ABROAD
			break
		case ProfileInfoCardTypes.RESEARCH:
			label = ProfileInfoCardTypes.RESEARCH
			break
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
			label = ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT
			break
		default:
			break
	}

	const prefix = withPrefix ? (type === ProfileInfoCardTypes.RESEARCH ? 'a ' : 'an ') : ''

	if (isLowerCase) label = `${prefix}${label}${additionalText}`.toLowerCase()

	return label
}

export const convertMemberships = (memberships?: UserMembership[]) => {
	if (!memberships) return []

	return memberships.map((item) => ({
		id: item.id,
		startDate: item.startDate,
		endDate: item.endDate,
		title: item.organization.name,
		membershipType: item.membershipTitle ?? ORGANIZATION_ROLE.MEMBER,
		photoUrl: item.organization.photoUrl,
		isLocked: item.dataLake,
		organizationId: item.organization.id,
		isAdmin: isSuperAdmin([item.organizationMembershipTypeId]) || isAdmin([item.organizationMembershipTypeId]),
		privacyLevel: item.privacyLevel,
	}))
}

export const getItemPrivacyType = (type: ProfileInfoCardTypes) => {
	switch (type) {
		case ProfileInfoCardTypes.MEMBERSHIPS:
			return PrivacyTypes.membership
		case ProfileInfoCardTypes.INTERNSHIPS:
			return PrivacyTypes.internship
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			return PrivacyTypes.educationAbroad
		case ProfileInfoCardTypes.RESEARCH:
			return PrivacyTypes.research
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
		default:
			return PrivacyTypes.additionalInvolvement
	}
}

import { TextButton } from '@/components/Buttons'
import MenuIconButton from '@/components/Buttons/IconButton'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import FetchedItemsList from '@/components/PathBuilder/FetchedItemsList'
import OutlinedInput from '@/components/PathBuilder/OutlinedInput'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { STYLES } from '@/constants'
import { getArrayMinSchema } from '@/constants/pathBuilder'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { useAppDispatch } from '@/store'
import {
	getCanLoadMoreSearchOrganizationResults,
	getIsLoadingSearchOrganizationResults,
	getSearchOrganizationQuery,
	getSearchOrganizationResults,
	searchOrganizationsRequest,
	setSearchOrganizationsQuery,
} from '@/store/pathBuilder'
import { Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '6vh 0 3vh 0',
		width: '45%',
	},
	containerRoot: {
		height: '40vh',
	},
	header: {
		width: '53%',
	},
	skipButton: {
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
		fontWeight: 600,
	},
	optionsContainer: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '30vh',
		overflowY: 'auto',
	},
	paper: ({ anchorOffsetWidth }: any) => ({
		zIndex: 1300,
		width: anchorOffsetWidth - 20 || 0,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	}),
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	searchPlaceholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		padding: '30px',
	},
	optionButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: theme.palette.text.primary,
		fontSize: 15,
		borderRadius: 0,
		textAlign: 'left',
	},
	greyText: {
		color: theme.palette.text.primary,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'space-evenly',
		margin: 0,
	},
}))

const Question5 = ({
	onClose,
	onValidation,
	onBack,
	showBackButton,
	data,
	pathBuilderForm,
	dataKey,
	onSkipStep,
}: PathBuilderQuestionProps) => {
	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const dispatch = useAppDispatch()

	const searchValue = useSelector(getSearchOrganizationQuery)
	const canLoadMore = useSelector(getCanLoadMoreSearchOrganizationResults)
	const isLoading = useSelector(getIsLoadingSearchOrganizationResults)
	const organizations = useSelector(getSearchOrganizationResults)

	const [isTouched, setIsTouched] = useState(false)
	const [showSuggestions, setShowSuggestions] = useState(false)

	const anchorRef = useRef<any>(null)
	const popperRef = useRef<any>(null)

	const classes = useStyles({
		anchorOffsetWidth: anchorRef.current?.offsetWidth,
	})

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setSearchOrganizationsQuery(value))
			setIsTouched(true)
			setShowSuggestions(true)
		},
		[dispatch],
	)

	const handleClosePopper = useCallback(() => {
		setShowSuggestions(false)
	}, [])

	const handleLoadMore = useCallback(() => {
		dispatch(searchOrganizationsRequest({ fetchMore: true }))
	}, [dispatch])

	const handleSearch = useCallback(() => {
		dispatch(searchOrganizationsRequest({}))
	}, [dispatch])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[],
	)

	const handleAddItem = (v: any[], onChange: (v: any[]) => void) => (newItem: any) => () => {
		onChange((v ?? []).concat([newItem]))
		dispatch(setSearchOrganizationsQuery(''))
		setIsTouched(false)
		setShowSuggestions(false)
	}

	useOutsideClickCallback(popperRef, handleClosePopper)

	useEffect(() => {
		if (isTouched) delayedSearch()

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, isTouched])

	const open = isTouched && showSuggestions

	return (
		<Controller
			name={dataKey}
			control={control}
			render={({ field: { value, onChange } }) => (
				<StepperContent
					leftColumnContent={
						<StepperCard
							classnames={{
								header: classes.header,
								container: classes.containerRoot,
							}}
							title="Are there any specific organizations 
          that you’d like to join?"
							onClose={onClose}
							onBack={onBack}
							showBackButton={showBackButton}
						>
							<OutlinedInput
								ref={anchorRef}
								onChange={handleChangeInput}
								value={searchValue}
								className={classes.container}
								placeholder={!value?.length ? 'Add an organization' : 'Add another organization'}
							/>
							<Popper
								ref={popperRef}
								className={classes.paper}
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement="bottom-start"
							>
								<div className={classes.optionsContainer}>
									{!!organizations.length ? (
										<ItemsSection
											classnames={{
												content: classes.cardContainer,
											}}
											handleLoadMoreItems={handleLoadMore}
											items={organizations}
											canLoadMore={canLoadMore}
											isLoading={isLoading}
											renderItems={(items: any) => (
												<>
													{items
														.filter((item: any) => !value?.some((v: any) => v.value === item.value))
														.map((item: any, idx: number) => (
															<MenuIconButton
																key={item.value}
																className={classes.optionButton}
																onClick={handleAddItem(value, onChange)(item)}
															>
																{item.label}
															</MenuIconButton>
														))}
												</>
											)}
										/>
									) : (
										<div className={classes.searchPlaceholder}>
											<Typography>No results found.</Typography>
										</div>
									)}
								</div>
							</Popper>
							{!value?.length && (
								<TextButton className={classes.skipButton} onClick={onSkipStep(1)}>
									I don’t have any in mind
								</TextButton>
							)}
						</StepperCard>
					}
					rightColumnContent={<div>{!!value?.length && <FetchedItemsList title="Added" onChange={onChange} items={value} />}</div>}
				/>
			)}
		/>
	)
}

export default Question5

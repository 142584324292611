import { SvgIcon } from '@material-ui/core'

const DollarIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
			<path d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4zm-1 6v2.8c-5.6.3-6.55 4.5-6.55 6.4 0 9.4 13.7 4.4 13.7 11.5 0 .9-.2 4.9-6.1 4.9-.2 0-6.3 0-6.1-6.3h-2.1c.2 6.7 4.45 7.6 7.15 7.9V40h2v-2.8c6.7-.5 7.15-5.5 7.15-6.5 0-9-13.7-4.4-13.7-11.3 0-2.3.9-5 5.6-5 4.8 0 5.4 3.8 5.5 5.5h2.1c.2-2.8-1.8-6.9-6.6-7.1V10H24z" />
		</SvgIcon>
	)
}

export default DollarIcon

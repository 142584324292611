import { ComponentType } from 'react'
import useRBACErrors, { ErrorCondition } from '../../../hooks/useRBACError'

const withRoleBasedErrors = <P extends object>(WrappedComponent: ComponentType<P>, providedConditions?: ErrorCondition[]) => {
	return (props: P) => {
		const errorMessage = useRBACErrors(providedConditions)

		return <>{errorMessage ? <p dangerouslySetInnerHTML={{ __html: errorMessage }}></p> : <WrappedComponent {...props} />}</>
	}
}

export default withRoleBasedErrors

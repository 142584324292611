import { Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import StepperContent from '@/components/PathBuilder/StepperContent'
import StepperCard from '@/components/PathBuilder/StepperCard'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import SelfCreatedItemsList from '@/components/PathBuilder/SelfCreatedItemsList'
import CreateItemInput from '@/components/PathBuilder/CreateItemInput'
import { getArrayMinSchema } from '@/constants/pathBuilder'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '6vh 0 0 0',
		width: '45%',
	},
	header: {
		width: '60%',
	},
	text: {
		fontSize: 18,
		color: theme.palette.text.secondary,
		margin: '2vh 0 12vh 0',
		textAlign: 'center',
	},
}))

const Question1 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const handleAddItem = (v: string[], onChange: (v: string[]) => void) => (newItem: string) => {
		onChange((v ?? []).concat([newItem]))
	}

	return (
		<Controller
			name={dataKey}
			control={control}
			render={({ field: { value, onChange } }) => (
				<StepperContent
					leftColumnContent={
						<StepperCard
							classnames={{
								header: classes.header,
							}}
							title="What else would you like to do outside 
          of the classroom?"
							onClose={onClose}
							onBack={onBack}
							showBackButton={showBackButton}
						>
							<CreateItemInput
								className={classes.container}
								onCreate={handleAddItem(value, onChange)}
								placeholder={!value.length ? 'Add your interests, hobbies, etc.' : 'Add more'}
							/>
							<Typography className={classes.text}>Examples: Intramural Sports, Entrepreneurship, Veterinary</Typography>
						</StepperCard>
					}
					rightColumnContent={<div>{!!value.length && <SelfCreatedItemsList title="Added" onChange={onChange} items={value} />}</div>}
				/>
			)}
		/>
	)
}

export default Question1

import { TextButton } from '@/components/Buttons'
import NotificationsBlock from '@/components/Notifications/NotificationsBlock'
import Popover from '@/components/Popover'
import { PATHS, STYLES } from '@/constants'
import { DISPLAYED_NOTIFICATIONS_COUNT } from '@/constants/notifications'
import { useGetUserNotificationsQuery } from '@/features/notifications/api'
import { useCurrentUser } from '@/hooks/userHooks'
import { NotificationPages } from '@/store/auth/types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
	notificationsPopper: {
		marginTop: 5,
		padding: '10px 20px',
		minWidth: '20vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 8,
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - 40px)`,
		overflowY: 'hidden',
	},
	popperContainer: {
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - 60px)`,
	},
	notificationsPopperTitle: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	rootContainer: {
		width: '100%',
	},
	seeAll: {
		fontWeight: 600,
	},
	content: {
		overflowY: 'auto',
		height: 'calc(100% - 30px)',
	},
}))

interface NotificationsPopperProps {
	anchorEl: any
	handleClose: () => void
}

const NotificationsPopper = ({ anchorEl, handleClose = () => {} }: NotificationsPopperProps) => {
	const classes = useStyles()
	const currentUser = useCurrentUser()

	const { data: newNotificationsData } = useGetUserNotificationsQuery({
		userId: currentUser.id,
		type: 'new',
	})
	const { data: pastNotificationsData } = useGetUserNotificationsQuery({ userId: currentUser.id, type: 'past' })

	const { push } = useHistory()

	const navigateToPage = useCallback(
		(type: NotificationPages) => () => {
			push(PATHS.APP.NOTIFICATIONS(type))
			handleClose()
		},
		[handleClose, push],
	)

	const closeAndSetViewed = useCallback(() => {
		handleClose()
	}, [handleClose])

	const hasNewNotifications = newNotificationsData && newNotificationsData.notifications.length > 0
	const hasPastNotifications = pastNotificationsData && pastNotificationsData.notifications.length > 0

	const showPlaceholder = !hasNewNotifications && !hasPastNotifications

	return (
		<Popover
			anchorEl={anchorEl}
			handleClose={closeAndSetViewed}
			boxShadow={false}
			classnames={{
				paper: classes.notificationsPopper,
				container: classes.popperContainer,
			}}
		>
			<div className={classes.rootContainer}>
				<div className={classes.titleContainer}>
					<Typography className={classes.notificationsPopperTitle} variant="h3">
						Notifications
					</Typography>
					{!showPlaceholder && (
						<TextButton className={classes.seeAll} onClick={navigateToPage('all')}>
							See All
						</TextButton>
					)}
				</div>

				{showPlaceholder ? (
					<Typography>No notifications yet!</Typography>
				) : (
					<div className={classes.content}>
						<NotificationsBlock
							maxToShow={DISPLAYED_NOTIFICATIONS_COUNT}
							navigateToPage={navigateToPage('all')}
							type="new"
							compactView
							showDivider={hasPastNotifications}
							handleClosePopup={closeAndSetViewed}
						/>
						{hasPastNotifications && (
							<NotificationsBlock
								maxToShow={DISPLAYED_NOTIFICATIONS_COUNT}
								type="past"
								navigateToPage={navigateToPage('past')}
								compactView
								showDivider={false}
								handleClosePopup={closeAndSetViewed}
							/>
						)}
					</div>
				)}
			</div>
		</Popover>
	)
}

export default NotificationsPopper

import { SvgIcon } from '@material-ui/core'

const SearchOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 22 22" width="1em" height="1em" {...props}>
			<g data-name="\u042D\u043B\u043B\u0438\u043F\u0441 1" fill="none" stroke="currentColor" strokeLinecap="round">
				<circle cx={6.17} cy={6.17} r={6.17} stroke="none" />
				<circle cx={6.17} cy={6.17} r={5.67} />
			</g>
			<path
				data-name="\u041B\u0438\u043D\u0438\u044F 8"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				d="M10.534 9.965l7.003 6.491"
			/>
		</SvgIcon>
	)
}

export default SearchOutlinedIcon

import { MODULE_NAME } from '@/store/messages/constants'
import { MessagesState } from '@/store/messages/types'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

const selectState = (state: { [MODULE_NAME]: MessagesState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getMessages = createSelector(selectState, (state) => state.messages)

export const getSearchUserQuery = createSelector(selectState, (state) => state.searchUsers.q)
export const getSearchUserResults = createSelector(selectState, (state) => state.searchUsers.users)
export const getCanLoadMoreSearchUserResults = createSelector(selectState, (state) => state.searchUsers.canLoadMore)
export const getIsLoadingSearchUserResults = createSelector(selectState, (state) => state.searchUsers.isLoading)

export const getNewMessageContent = createSelector(selectState, (state) => state.newMessageContent)

export const getChatsList = createSelector(selectState, (state) => state.chats.list)
export const getNumberOfUnViewed = createSelector(selectState, (state) => state.chats.numberOfUnViewed)
export const getTargetUser = createSelector(selectState, (state) => state.targetUser)
export const getShowSelectUserInput = createSelector(selectState, (state) => state.showSelectUserInput)
export const getShareModalData = createSelector(selectState, (state) => state.shareModal)
export const selectChatListIsLoading = createSelector(selectState, (state) => state.chats.isLoading)

export const getUnviewedCount = createSelector(
	selectState,
	(state: RootState, chatInfo: { groupId?: string; userId?: number }) => chatInfo,
	(state: MessagesState, { groupId, userId }) => {
		const room = state.chats?.list.find((chat) => {
			return (groupId && chat.group && chat.group.id === groupId) || (userId && chat.user && chat.user.id === userId)
		})
		if (room) {
			return room.unviewed
		} else {
			return 0
		}
	},
)

import { FC, useMemo } from 'react'

import DtoEvent from '../../types/dto/DtoEvent'

import { Modal } from '@/components'
import QuestionsForm, { IQuestionsFormProps } from './QuestionsForm'

export type IRsvpModalProps = {
	response: DtoEvent['response']
	settings: DtoEvent['rsvpSettings']
	onSubmit: IQuestionsFormProps['onSubmit']
	onClose?: () => void
	isOpen: boolean
	hasMemberships?: boolean
}

const RsvpModal: FC<IRsvpModalProps> = ({ isOpen, response, settings, onClose, onSubmit, hasMemberships }) => {
	const answers = response?.answers ? JSON.parse(response.answers) : null
	const questions: IQuestionsFormProps['form'] = settings.questions ? JSON.parse(settings.questions) : null
	const organizationRepresentation = useMemo(() => {
		return settings?.organizationRepresentationEnabled && hasMemberships
			? {
					enabled: settings?.organizationRepresentationEnabled,
					required: settings?.organizationRepresentationRequired,
			  }
			: undefined
	}, [hasMemberships, settings?.organizationRepresentationEnabled, settings?.organizationRepresentationRequired])

	return (
		<Modal isOpen={isOpen} overflow="inherit">
			<div>
				<QuestionsForm
					organizationRepresentation={organizationRepresentation}
					answers={answers}
					onClose={onClose}
					onSubmit={onSubmit}
					form={questions}
				/>
			</div>
		</Modal>
	)
}

export default RsvpModal

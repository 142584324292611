import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'

import { toggleSelectMajors } from '@/store/constants/constantsSlice'

import { TMajorDto } from '@/store/internships/types'
import { TFilterTreeNodes } from '@/utils/categoryTree'
import { Button, Checkbox, makeStyles, Tooltip, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: ({ hasChildren }: any) => ({
		width: '100%',
		borderBottom: hasChildren ? `1px solid ${theme.palette.divider}` : 'none',
	}),
	children: {
		padding: '0px 0 20px 16px',
		'& > div:last-of-type': {
			borderBottom: 'none',
		},
	},
	card: ({ open }: any) => ({
		width: '100%',
		height: 50,
		backgroundColor: theme.palette.specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: open ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			backgroundColor: theme.palette.specialColors.transparent,
			color: theme.palette.primary.main,
		},
		fontSize: '16px',
	}),
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: '5%',
		alignItems: 'center',
		display: 'flex',
		padding: 0,
	},
	left: {
		width: '100%',
		alignItems: 'center',
		display: 'flex',
	},
	single: {
		width: '100%',
		height: 50,
		fontSize: '16px',
	},
	singleContent: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
	},
	overflowEllipses: {
		overflow: 'hidden',
		maxWidth: 215,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}))

export type IFilterCategoryTreeProps = {
	className?: string
	value?: any | any[]
	icon?: React.ReactNode
	defaultLabel: string
	onChange: (v: any | any[]) => void
	options: any[]
	preselectedValues?: any[]
	multiple?: boolean
	rootNode?: number | string
	isParentSelected?: boolean
	treeSelector: (state: any) => { nodes: TFilterTreeNodes; rootNode: TMajorDto }
}

const FilterCategoryTree: React.FC<IFilterCategoryTreeProps> = ({ rootNode, ...rest }) => {
	const [opened, setOpened] = useState<boolean>(false)
	const { treeSelector, icon, className, onChange } = rest
	const { nodes, rootNode: superParent } = useSelector(treeSelector)

	const dispatch = useAppDispatch()
	const code = rootNode ?? superParent?.code

	const currentNode = nodes[code] || {}

	const { children, selected, category, indeterminate } = currentNode
	const classes = useStyles({ open: opened, selected, hasChildren: children && children.length > 0 })

	const handleToggleSelect = () => {
		setOpened(!opened)
	}

	const toggleFilter = (e) => {
		e.stopPropagation()
		dispatch(toggleSelectMajors(code))
		onChange('changed')
	}

	return (
		<div className={classes.root}>
			{children && children.length > 0 ? (
				<Tooltip placement="right" title={category.length > 26 ? category : ''} arrow>
					<Button className={classNames(classes.card, className)} onClick={handleToggleSelect}>
						<div className={classes.contentContainer}>
							<Typography className={classes.left}>
								{code !== superParent.code ? (
									<Checkbox
										disableRipple
										indeterminate={indeterminate}
										className={classes.icon}
										color="primary"
										checked={selected}
										onClick={toggleFilter}
									/>
								) : (
									<span className={classes.icon}>{icon}</span>
								)}
								<span className={classes.overflowEllipses}>{category}</span>
							</Typography>
							{opened ? <KeyboardArrowUpIcon fontSize="medium" /> : <KeyboardArrowDownIcon fontSize="medium" />}
						</div>
					</Button>
				</Tooltip>
			) : (
				category && (
					<Tooltip placement="right" title={category.length > 26 ? category : ''} arrow>
						<Button className={classes.single} onClick={toggleFilter}>
							<Typography className={classes.singleContent}>
								<Checkbox disableRipple className={classes.icon} color="primary" checked={selected} onClick={toggleFilter} />
								<span className={classes.overflowEllipses}>{category}</span>
							</Typography>
						</Button>
					</Tooltip>
				)
			)}
			{opened && (
				<div className={superParent.code === code ? '' : classes.children}>
					{children.map((child) => (
						<FilterCategoryTree isParentSelected={selected} {...rest} rootNode={child} />
					))}
				</div>
			)}
		</div>
	)
}

export { FilterCategoryTree }

import { rootStyles } from '@/styles'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		...rootStyles.flexCenteredItems,
		flexDirection: 'column',
		minWidth: 500,
	},
	icon: {
		width: '100%',
		userSelect: 'none',
	},
	body: {
		...rootStyles.flexCenteredItems,
		flexDirection: 'column',
		marginTop: 22,
	},
	title: {
		fontWeight: 700,
		textAlign: 'center',
		marginBottom: 5,
	},
	description: {
		marginTop: 15,
		width: '75%',
		textAlign: 'center',
	},
	iconWrapper: {
		width: 150,
		minHeight: 80,
	},
}))

export const Slide = ({
	title,
	description,
	icon,
	renderFooter,
}: {
	title: string
	description: string
	icon?: string
	renderFooter?: () => JSX.Element
}) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.iconWrapper}>{!!icon && <img className={classes.icon} src={icon} alt={title} />}</div>
			<div className={classes.body}>
				<Typography className={classes.title} variant="h2">
					{title}
				</Typography>
				<Typography className={classes.description} variant="body1">
					{description}
				</Typography>
			</div>

			{!!renderFooter && renderFooter()}
		</div>
	)
}

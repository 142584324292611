import { TextField } from '@/components'
import { TextButton } from '@/components/Buttons'
import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { TextCheckBox } from '@/components/widgets'
import { isEngage } from '@/constants/configuration'
import { getWithCustomAnswerSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 80%)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		margin: 0,
	},
	header: {
		width: '47%',
	},
	input: {
		margin: '3vh 0',
	},
	skipButton: {
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
		marginBottom: '3vh',
		fontWeight: 600,
	},
}))

const options = [
	{
		label: 'Virtual abroad experience',
		value: 'Virtual abroad experience',
	},
	{
		label: 'Global classes without travel',
		value: 'Global classes without travel',
	},
	{
		label: 'Global Engagement and Leadership Experience (GELE) Weekends',
		value: 'Global Engagement and Leadership Experience (GELE) Weekends',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]
const filteredOptions = !isEngage
	? options
	: options.filter((option) => option.label !== 'Global Engagement and Leadership Experience (GELE) Weekends')

const Question2 = ({
	onClose,
	onValidation,
	onBack,
	showBackButton,
	data,
	pathBuilderForm,
	dataKey,
	onSkipStep,
}: PathBuilderQuestionProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const { schema, defaultValues } = getWithCustomAnswerSchema(dataKey, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.header,
					}}
					title="Would you like to participate in any of the following?"
					subTitle="You can select more than one."
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={`${dataKey}.answers`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									filteredOptions.map((item) => (
										<div key={item.label}>
											<TextCheckBox
												key={item.value}
												label={item.label}
												value={item.value}
												onChange={handleSelectItem}
												checked={isChecked(item.value)}
											/>
											{item.label === 'Other' && (value ?? []).includes('Other') && (
												<div>
													<Controller
														name={`${dataKey}.otherAnswer`}
														control={control}
														render={({ field }) => (
															<TextField
																className={classes.input}
																placeholder="What would you like to do?"
																placeholderColor={appTheme.palette.text.secondary}
																{...field}
															/>
														)}
													/>
												</div>
											)}
										</div>
									))
								}
							/>
						)}
					/>
					<TextButton className={classes.skipButton} onClick={onSkipStep(1)}>
						None of the above
					</TextButton>
				</StepperCard>
			}
		/>
	)
}

export default Question2

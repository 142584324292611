import { useMemo } from 'react'
import { Typography, makeStyles } from '@material-ui/core'

import EmptyPlaceholder from '@/components/Placeholder'
import { EventResponseType } from '../types/EventResponseType'
import EventsIcon from '@/components/icons/png/Events-Detailed-LightBlue-Filled-RGB.png'

const useStyles = makeStyles((theme) => ({
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
	placeholder: {
		marginTop: '2vh',
	},
}))

interface IEmptyListPlaceholderProps {
	title?: string
	type: EventResponseType | 'volunteers'
}

const EmptyResponseListPlaceholder: React.FC<IEmptyListPlaceholderProps> = ({ title, type }) => {
	const classes = useStyles()

	const cardInfo = useMemo(() => {
		switch (type) {
			case 'going':
			case 'interested':
				return { content: <Typography align="center">No one has RSVP’d as {title} yet.</Typography>, title: `Who’s ${title}` }
			case 'attendee':
				return {
					title: 'Who went?',
					content: (
						<>
							<Typography align="center">No one has scanned into this event yet.</Typography>
							<Typography align="center">Generate a QR Code on the App and have users scan in!</Typography>
						</>
					),
				}
			case 'volunteers':
				return {
					title: 'No Volunteers',
					content: <Typography align="center">No one has signed up for a shift yet.</Typography>,
				}
			default:
				return {
					content: '',
					title: '',
				}
		}
	}, [type, title])

	return (
		<EmptyPlaceholder className={classes.placeholder} isEmptyPlaceholder src={EventsIcon}>
			<Typography align="center" className={classes.placeholderHeader}>
				{cardInfo.title}
			</Typography>
			{cardInfo.content}
		</EmptyPlaceholder>
	)
}

export default EmptyResponseListPlaceholder

import IconButton from '@/components/Buttons/IconButton'
import { isNavApp } from '@/constants/configuration'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import classNames from 'classnames'
import { useSchoolConfig } from '../hooks/useSchoolConfig'

const useStyles = makeStyles(({ palette: { primary } }) => ({
	root: ({ background, isNavApp }: any) => {
		return {
			width: '100%',
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'row',
			background: isNavApp
				? `url(${background})`
				: `linear-gradient(
			rgba(0, 0, 0, 0.7), 
			rgba(0, 0, 0, 0.7)
		  ), url(${background})`,
			backgroundPosition: 'center center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundColor: primary.dark,
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	backButton: {
		position: 'absolute',
		top: '4vh',
		left: '3vw',
		color: primary.light,
		fontWeight: 700,
	},
}))

interface CardLayoutProps {
	classnames?: {
		backButton?: string
		root?: string
	}
	onClickBackHandler?: () => void
	children: any
}

const CardLayout = ({
	onClickBackHandler,
	children,
	classnames = {
		root: '',
		backButton: '',
	},
}: CardLayoutProps) => {
	const { defaultBackground } = useSchoolConfig()
	const classes = useStyles({ background: defaultBackground, isNavApp: isNavApp() })

	return (
		<div className={classNames(classnames.root, classes.root)}>
			{onClickBackHandler && (
				<IconButton
					onClick={onClickBackHandler}
					className={classNames(classnames.backButton, classes.backButton)}
					startIcon={<ArrowBackIcon fontSize="large" />}
				>
					Back to Homepage
				</IconButton>
			)}
			{children}
		</div>
	)
}

export default CardLayout

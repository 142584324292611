import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ModalWithSubmittedPopup, MoreButton } from '@/components'
import AddToCalendarModal from '@/components/AddToCalendarModal'
import { ButtonColors } from '@/components/Buttons/types'
import AboutEventCard from '@/components/IndividualPage/AboutEventCard'
import ContactCard from '@/components/IndividualPage/ContactCard'
import EventTitleCard from '@/components/IndividualPage/EventTitleCard'
import ExternalUrlCard from '@/components/IndividualPage/ExternalUrlCard'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import PageContainer from '@/components/IndividualPage/PageContainer'
import ResponsesListCard from '@/components/IndividualPage/ResponsesListCard'
import ShiftsCard from '@/components/IndividualPage/ShiftsCard'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import { useGetEventPermissionsQuery } from '@/features/permissions/api'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import {
	deleteEventRequest,
	getIsLoadingIndividualEvent,
	getSelectedEvent,
	loadEventByIdRequest,
	setEventResponseRequest,
} from '@/store/events'
import { isPast } from '@/utils/dateTime2'
import { getStringCategoriesMap, hasVolunteerCategory, isEventAdmin, isUserSubmittedToShift } from '@/utils/events'

const useStyles = makeStyles((theme) => ({
	editButton: {
		width: 175,
		height: 50,
		marginLeft: 10,
		fontSize: 18,
		fontWeight: 550,
	},
}))

const IndividualEvent = ({
	match: {
		params: { id },
	},
	location,
}: any) => {
	const { push } = useHistory()
	const dispatch = useAppDispatch()
	const classes = useStyles()
	const appTheme = useTheme()

	const urlSearchParams = new URLSearchParams(location.search)

	const [isEditShifts, setIsEditShifts] = useState(!!urlSearchParams.get('editShifts'))
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

	const isLoading = useSelector(getIsLoadingIndividualEvent)
	const event = useSelector(getSelectedEvent)
	const userId = useSelector(getUserInfo)!.id

	const eventName = event?.name

	useWatchPageTitle({
		watchEntity: eventName,
	})

	const handleToggleEditShifts = useCallback(() => setIsEditShifts((prev) => !prev), [])
	const handleToggleOpenDeleteModal = useCallback(() => setIsOpenDeleteModal((prev) => !prev), [])

	const handleChangeEventResponse = (status: UserResponseStatuses) => {
		dispatch(setEventResponseRequest({ id, status }))
	}

	const handleReportEvent = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setEventResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const handleSubmitShifts = () => {
		handleToggleEditShifts()
	}

	const handleDeleteEvent = useCallback(() => dispatch(deleteEventRequest(id)), [dispatch, id])
	const handleCloseDeletedModal = useCallback(() => setTimeout(() => push(PATHS.APP.EVENTS), 100), [push])

	const handleEditEvent = useCallback(() => {
		push(PATHS.APP.EVENTS_EDIT(id))
	}, [id, push])

	useLayoutEffect(() => {
		if (id) dispatch(loadEventByIdRequest(id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	useEffect(() => {
		if (!isLoading && isEditShifts) {
			setTimeout(() => {
				document.getElementById('EditShiftsSection')?.scrollIntoView({ block: 'center' })
			}, 200)
		}
	}, [isEditShifts, isLoading])

	const isVolunteer = hasVolunteerCategory(event?.categories || [])
	const isAdmin = isEventAdmin(userId, event?.submittedByUserId)
	const categories = getStringCategoriesMap(event?.categories || [])
	const skipPermissionCheck = isAdmin || event?.organization.length === 0
	const { data: permissionsData } = useGetEventPermissionsQuery(
		{ eventId: id },
		{
			skip: skipPermissionCheck,
			selectFromResult: (result) => ({
				...result,
				data: {
					canEdit: result.data ? result.data.canEdit : isAdmin,
					canDelete: result.data ? result.data.canDelete : isAdmin,
				},
			}),
		},
	)

	const editButtons = useMemo(() => {
		const buttons = []
		if (permissionsData.canEdit) {
			buttons.push({
				label: 'Edit Event',
				onClick: handleEditEvent,
			})
		}
		if (permissionsData.canDelete) {
			buttons.push({
				label: 'Delete Event',
				onClick: handleToggleOpenDeleteModal,
			})
		}
		return buttons
	}, [handleEditEvent, handleToggleOpenDeleteModal, permissionsData.canDelete, permissionsData.canEdit])

	const userSubmittedShifts = event?.volunteerShifts?.filter((shift: any) => isUserSubmittedToShift(shift, userId)).length ?? 0
	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.event } }), [id])

	if (!event?.id || isLoading) return <LoadingPlaceholder />

	const pastEvent = isPast(event.endDate)

	return (
		<PageContainer>
			<AddToCalendarModal />
			<ModalWithSubmittedPopup
				isOpen={isOpenDeleteModal}
				onClose={handleToggleOpenDeleteModal}
				onSubmit={handleDeleteEvent}
				onCloseSubmittedModal={handleCloseDeletedModal}
				title="Delete Event"
				content={['This event will be deleted']}
				// content={['This event will not be saved. Anyone who has', 'RSVP’d will be notified that this event ', 'has been deleted.']}
				submittedMessage={'Your event has been deleted.'}
				submitButtonColor={appTheme.colors.red[500]}
				submitButtonLabel="Delete Event"
			/>
			<ImageCard
				dotButton={{
					itemId: event.id,
					reportSubject: ReportSubject.Event,
					onSubmitReport: handleReportEvent,
				}}
				photoUrl={event.photoUrl}
				isAdmin={permissionsData.canDelete || permissionsData.canDelete}
				privacyType={event.privacyType}
				editPageElement={
					permissionsData.canDelete || permissionsData.canEdit ? (
						<MoreButton
							className={classes.editButton}
							color={ButtonColors.YELLOW}
							openedColor={ButtonColors.YELLOW}
							renderButtons={editButtons}
							hideIcon
						>
							Edit Event
						</MoreButton>
					) : null
				}
			/>
			<EventTitleCard
				name={`${eventName}`}
				userId={userId}
				eventId={event.id}
				shifts={event.volunteerShifts}
				description={event.description}
				startDate={event.startDate}
				endDate={event.endDate}
				organizations={event.organization}
				locationName={event.locationName}
				streetAddress={event.streetAddress}
				response={event.response}
				handleChangeEventResponse={handleChangeEventResponse}
				isEditShifts={isEditShifts}
				userSubmittedShifts={userSubmittedShifts}
				handleEditShifts={handleToggleEditShifts}
				sharedData={sharedData}
				pastEvent={pastEvent}
			/>
			{isVolunteer && !!event.volunteerShifts?.length && (
				<ShiftsCard
					userId={userId}
					eventId={event.id}
					shifts={event.volunteerShifts}
					isEditShifts={isEditShifts}
					handleOpenEditShiftDialog={() => setIsEditShifts((prev) => !prev)}
					onSubmitShifts={handleSubmitShifts}
					pastEvent={pastEvent}
				/>
			)}
			{(isAdmin || permissionsData.canEdit) && (
				<ResponsesListCard volunteers={event.volunteers} shifts={event.volunteerShifts} eventId={event.id} />
			)}
			<AboutEventCard categories={categories} description={event.description} />
			<ExternalUrlCard url={event.externalUrl} />
			<ContactCard name={event.contactName} email={event.contactEmail} subject={event.name} />
		</PageContainer>
	)
}

export default IndividualEvent

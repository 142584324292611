import { STYLES } from '@/constants'
import { makeStyles } from '@material-ui/core/styles'
import { FC } from 'react'

export const useGridStyles = makeStyles((theme) => ({
	cardsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 10,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: 0,
		justifyContent: 'center',
	},
}))

export type IGridLayoutProps = {}

export const GridLayout: FC<IGridLayoutProps> = ({ children }) => {
	const classes = useGridStyles()

	return <div className={classes.cardsContainer}>{children}</div>
}

import MenuIconButton from '@/components/Buttons/IconButton'
import usePopper from '@/hooks/usePopper'
import { Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CircleIconButton } from '../Buttons'

const useStyles = makeStyles((theme) => ({
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginTop: 5,
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: 7,
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}))

interface AddMessageButtonProps {
	className?: string
	disabled?: boolean
	onStartDirectMessage: () => void
	onStartNewGroup: () => void
}

const AddMessageButton = ({ className = '', disabled = false, onStartDirectMessage, onStartNewGroup }: AddMessageButtonProps) => {
	const classes = useStyles()

	const { isOpen, anchorEl, handleClick, handleCloseAfterItemClick, popperRef } = usePopper()

	return (
		<>
			<Popper ref={popperRef} className={classes.paper} open={isOpen} anchorEl={anchorEl} role={undefined} disablePortal>
				<div className={classes.optionsContainer}>
					<MenuIconButton className={classes.popoverButton} onClick={handleCloseAfterItemClick(onStartNewGroup)}>
						Start Group (Beta)
					</MenuIconButton>
					<MenuIconButton className={classes.popoverButton} onClick={handleCloseAfterItemClick(onStartDirectMessage)}>
						Start Direct Message
					</MenuIconButton>
				</div>
			</Popper>
			<CircleIconButton className={className} onClick={handleClick} disabled={disabled} />
		</>
	)
}

export default AddMessageButton

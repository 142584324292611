import { yupResolver } from '@hookform/resolvers/yup'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ColorButton, CommonModalCard, CommonSelect, Modal } from '@/components'
import { STYLES } from '@/constants'
import { orgTypes, organizationRoleOptions } from '@/utils/organizationRoles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '80%',
	},
	saveButton: {
		margin: '4.5vh 0',
		padding: '1vh',
		fontWeight: 600,
		minWidth: 200,
	},
	label: {
		fontSize: 13,
		textAlign: 'left',
		margin: '5px 0',
	},
	positionInput: {
		margin: 0,
		width: '100%',
	},
	roleSelect: {
		height: 56,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	modalCard: {
		width: '40vw',
		minWidth: 300,
		maxWidth: 580,
	},
	formItem: {
		width: '100%',
		margin: '1vh 0',
	},
	userRowContainer: {
		margin: '15px 0',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 5,
	},
	userRow: {
		margin: 15,
	},
	userRowImage: {
		width: 40,
		height: 40,
	},
}))

const schema = yup.object().shape({
	newMembershipTypeId: yup.number().required(),
})

interface RequestToChangePositionModalProps {
	isOpen: boolean
	userMembershipTypeId?: orgTypes
	onSubmit: (membershipTypeId: orgTypes) => void
	handleCloseModal: (e: any) => void
}

const RequestToChangePositionModal = ({
	isOpen,
	userMembershipTypeId,
	onSubmit,
	handleCloseModal = () => {},
}: RequestToChangePositionModalProps) => {
	const classes = useStyles()

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: {
			newMembershipTypeId: null,
		},
	})

	const onClose = (e: any) => {
		handleCloseModal(e)
	}

	const saveMembership = ({ newMembershipTypeId }: any) => {
		onSubmit(newMembershipTypeId)
	}

	const handleClickSave = (e: any) => {
		handleSubmit(saveMembership)(e)
	}

	const disableSubmit = !isDirty || !isValid

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<CommonModalCard
				title={`Request a new position`}
				onClose={onClose}
				classnames={{
					card: classes.modalCard,
				}}
			>
				<div className={classes.formContainer}>
					<div className={classes.formItem}>
						<Typography className={classes.label}>Role</Typography>
						<Controller
							name="newMembershipTypeId"
							control={control}
							render={({ field: { value, onChange } }) => (
								<CommonSelect
									classnames={{
										input: classes.roleSelect,
									}}
									value={value}
									onChange={onChange}
									options={organizationRoleOptions.filter(({ value }) => value !== userMembershipTypeId)}
									placeholder="Choose a new role"
								/>
							)}
						/>
					</div>
					<ColorButton className={classes.saveButton} disabled={disableSubmit} onClick={handleClickSave}>
						Save
					</ColorButton>
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export default RequestToChangePositionModal

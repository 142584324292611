import { baseApi } from '../general/baseApi'

const API_BASE_URL = '/s3/uploads'

const PHOTO_TAGS = {
	IMAGE: 'image',
}

// @TODO: add post method here and revalidate tags accordingly

export const photoApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(PHOTO_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getSecurePhotoBySlug: builder.query<string, { slug: string }>({
				query: (params) => ({
					url: `${API_BASE_URL}/secure/getPhoto`,
					params,
					responseHandler: 'text',
				}),

				providesTags: (__, error, { slug }) => (!error ? [{ type: PHOTO_TAGS.IMAGE, id: slug }] : []),
			}),
		}),
	})

export const { useGetSecurePhotoBySlugQuery } = photoApi

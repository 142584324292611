import { CampusUnits } from '@/interfaces/campus'
import { UserInfo } from '@/interfaces/user'
import { SignUpForm } from '@/store/auth/types'
import { createContext, useContext } from 'react'

export interface SignupStepsContextValue {
	currentStep: number
	formData: SignUpForm
	userInfo: UserInfo
	selectedUserType: string
	isStudent: boolean
	campusData?: CampusUnits
	isLoadingCampusData?: boolean
	progress: { total: number; completed: number; currentStep: number }
	loadCampusData?: () => void
	patchFormData: (body: { [key: string]: any; patches?: Record<string, any> }) => void
	onClickBack: () => void
	onClickNext: () => void
	setNavigationMiddleware: (middleware: (context: { isForward: boolean; isBack: boolean } | undefined) => void) => void
}

const contextValue: SignupStepsContextValue = {
	currentStep: 0,
	selectedUserType: '',
	isStudent: false,
	formData: { patches: {} } as SignUpForm,
	// @ts-ignore
	useInfo: {},
	progress: { total: 0, completed: 0, currentStep: 0 },
	patchFormData: () => {},
	onClickBack: () => {},
	onClickNext: () => {},
	setNavigationMiddleware: () => {},
	loadCampusData: () => {},
}

export const SignupStepsContext = createContext(contextValue)
export const useSignupStepsContext = (): SignupStepsContextValue => {
	return useContext(SignupStepsContext)
}

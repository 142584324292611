import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import classNames from 'classnames'
import { useState } from 'react'

import { FilledTextField } from '@/components'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
	},
	inputIcon: {
		color: theme.palette.primary.main,
	},
	button: {
		position: 'absolute',
		top: 0,
		right: -50,
	},
}))

interface CreateItemInputProps {
	className?: string
	placeholder?: string
	onCreate: (item: string) => void
}

const CreateItemInput = ({ className = '', onCreate, placeholder }: CreateItemInputProps) => {
	const classes = useStyles()

	const [item, setItem] = useState('')

	const handleChange = (e: any) => setItem(e.currentTarget.value)
	const handlePressEnter = (e: any) => {
		if (e.key.toLowerCase() === 'enter' && item) {
			handleCreateItem()
		}
	}

	const handleCreateItem = () => {
		onCreate(item)
		setItem('')
	}

	const hasValue = !!item.trim()

	return (
		<div className={classNames(classes.root, className)}>
			<FilledTextField placeholder={placeholder} value={item} onChange={handleChange} onKeyPress={handlePressEnter} />
			{hasValue && (
				<IconButton className={classes.button} onClick={handleCreateItem}>
					<Add className={classes.inputIcon} fontSize="medium" />
				</IconButton>
			)}
		</div>
	)
}

export default CreateItemInput

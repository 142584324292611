import { FC, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { InViewMonitorProps } from './types'

/**
 * Monitors when the child element is in the visible viewport.
 *
 * See https://github.com/thebuilder/react-intersection-observer#plain-children
 */
export const InViewMonitor: FC<InViewMonitorProps & { doNotObserve?: boolean }> = (props) => {
	const [inViewOccurred, setInViewOccurred] = useState(false)

	const { children, repeatOnInView, onInView, doNotObserve, ...inViewProps } = props

	const onChange = (inView) => {
		const { repeatOnInView } = props

		if (inView && (!inViewOccurred || repeatOnInView)) {
			onInView()
		}

		if (inView && !inViewOccurred) {
			setInViewOccurred(true)
		}
	}

	const newProps = { ...inViewProps, skip: !!doNotObserve }

	return (
		<InView onChange={onChange} threshold={0} {...newProps}>
			{children}
		</InView>
	)
}

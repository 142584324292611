import ShareButton from '@/components/Buttons/ShareButton'
import Card from '@/components/IndividualPage/Card'
import { SharedData } from '@/interfaces/common'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	typeMark: {
		color: theme.palette.primary.main,
		padding: '0 10px',
		height: '3vh',
		minWidth: 195,
		minHeight: 48,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.primary.light,
		textTransform: 'uppercase',
		justifyContent: 'center',
		[theme.breakpoints.up('xl')]: {
			fontSize: '0.85em',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75em',
		},
	},
	buttonsContainer: {
		display: 'flex',
		marginTop: '2.5vh',
	},
	renderButtonsContainer: {
		display: 'flex',
		marginRight: '0.5vw',
	},
	feedbackButton: {
		minWidth: 50,
		height: 50,
	},
	subTitle: {
		fontSize: 15,
		fontWeight: 450,
	},
}))

interface TitleProps {
	title: string
	subTitle?: string
	type?: string
	renderButtons: () => React.ReactNode
	sharedData?: SharedData
	sharingUrl: string
}

const Title = ({ title = '', type, subTitle, renderButtons, sharedData = {}, sharingUrl }: TitleProps) => {
	const classes = useStyles()

	return (
		<Card>
			<div className={classes.titleContainer}>
				<div>
					<Typography className={classes.boldText} variant="h2">
						{title}
					</Typography>
					<Typography className={classes.subTitle}>{subTitle}</Typography>
				</div>
				{type && <div className={classes.typeMark}>{type}</div>}
			</div>
			<div className={classes.buttonsContainer}>
				<div className={classes.renderButtonsContainer}>{renderButtons()}</div>
				<ShareButton className={classes.feedbackButton} sharedData={sharedData} sharingUrl={sharingUrl} />
			</div>
		</Card>
	)
}

export default Title

import searchInternships from './searchInternships'
import postInternshipUserResponse from './postInternshipUserResponse'
import getInternshipById from './getInternshipById'
import getInternshipAttendeesList from './getInternshipAttendeesList'
import getAllUserInternshipsResponses from './getAllUserInternshipsResponses'
import getInternshipsFilters from './getInternshipsFilters'

const internships = {
	getInternshipsFilters,
	getAllUserInternshipsResponses,
	searchInternships,
	postInternshipUserResponse,
	getInternshipAttendeesList,
	getInternshipById,
}

export default internships

import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { ButtonColors } from './types'

interface StylesProps {
	color?: ButtonColors
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ color }) => {
		const buttonColor = theme.palette.buttonColors[color ?? ButtonColors.PRIMARY]

		return {
			'&:hover': {
				color: buttonColor.textColor,
				backgroundColor: buttonColor.main,
			},
		}
	},
	label: {
		position: 'absolute',
		fontSize: 15,
		width: '1%',
		right: '-1%',
		textAlign: 'left',
	},
	tooltip: {
		fontSize: 16,
		padding: '5px 15px',
		borderRadius: 2,
		backgroundColor: theme.palette.neutral?.main,
	},
	popper: {
		opacity: 0.95,
	},
}))

interface IconButtonWithTooltipProps extends StylesProps, Omit<IconButtonProps, 'color'> {
	classnames?: {
		tooltip?: string
		tooltipPopper?: string
		button?: string
		label?: string
	}
	tooltipTitle: string
	onClick?: (e: any) => void
	label?: string
	children: React.ReactNode
	disabled?: boolean
	placement?:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
}

const IconButtonWithTooltip = ({
	classnames = {
		tooltip: '',
		tooltipPopper: '',
		button: '',
		label: '',
	},
	tooltipTitle,
	onClick = () => {},
	label,
	children,
	color = ButtonColors.PRIMARY,
	disabled,
	placement = 'top',
	...buttonProps
}: IconButtonWithTooltipProps) => {
	const classes = useStyles({ color })
	const tooltipClasses = useMemo(
		() => ({
			tooltip: classNames(classnames.tooltip, classes.tooltip),
			popper: classNames(classnames.tooltipPopper, classes.popper),
		}),
		[classes.popper, classes.tooltip, classnames.tooltip, classnames.tooltipPopper],
	)
	return (
		<Tooltip title={tooltipTitle} placement={placement} classes={tooltipClasses}>
			<IconButton {...buttonProps} onClick={onClick} className={classNames(classnames.button, classes.root)} disabled={disabled}>
				{children}
				{label && <span className={classNames(classnames.label, classes.label)}>{label}</span>}
			</IconButton>
		</Tooltip>
	)
}

export default IconButtonWithTooltip

import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'

import { CommonSelect } from '@/components/Selects'
import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import { intendedGraduationTermOptions, intendedGraduationYearOptions } from '@/constants/defaultValues'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import { useSignupStepsContext } from '../SignupStepsContext'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from '../hooks'

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '40%',
		marginTop: '20%',
	},
	selectsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	select: {
		width: '48%',
	},
	continueButton: {
		marginBottom: 15,
	},
	card: {
		height: '75vh',
	},
}))

const IntendedGraduationDateStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const { formData } = useSignupStepsContext()
	const graduationConfig = useOnboardingStepConfig('graduation')
	const existingValue = formData.patches.intendedGraduationDate

	const [selectedValues, setSelectedValues] = useState({
		term: existingValue?.term || '',
		year: existingValue?.year || '',
	})

	const disableContinueButton = !checkCanProceed(selectedValues)

	const handleChange = (key: string) => (value: any) =>
		setSelectedValues({
			...selectedValues,
			[key]: value,
		})

	useSignupStepDataPatcher(
		{
			intendedGraduationDate: selectedValues,
		},
		[selectedValues],
	)

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.SELECTED_GRADUATION_YEAR, {
			term: selectedValues.term,
			year: selectedValues.year,
		})
	}

	return (
		<SignUpCard
			classnames={{
				root: classes.card,
				continueButton: classes.continueButton,
			}}
			title={graduationConfig?.title}
			subTitle={graduationConfig?.subTitle}
			continueButtonDisabled={disableContinueButton}
			onClickContinue={onClickContinue}
		>
			<div className={classes.content}>
				<div className={classes.selectsContainer}>
					<CommonSelect
						classnames={{
							input: classes.select,
						}}
						value={selectedValues.term}
						onChange={handleChange('term')}
						options={intendedGraduationTermOptions}
						placeholder="Term"
					/>
					<CommonSelect
						classnames={{
							input: classes.select,
						}}
						value={selectedValues.year}
						onChange={handleChange('year')}
						options={intendedGraduationYearOptions}
						placeholder="Year"
					/>
				</div>
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (values) => {
	const selectedValues = Object.values(values)
	return selectedValues.length && selectedValues.every((value) => !!value)
}

IntendedGraduationDateStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.intendedGraduationDate)
}

export default IntendedGraduationDateStep

import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { CommonSelect } from '@/components/Selects'
import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import { getActiveCampuses } from '@/features/campus/slice'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import { useSignupStepsContext } from './SignupStepsContext'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from './hooks'

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '40%',
		marginTop: '10%',
	},
	selectsContainer: {
		width: '100%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
	},
	select: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	continueButton: {
		marginBottom: 15,
	},
	card: {
		height: '75vh',
	},
}))

const CampusStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const campuses = useSelector(getActiveCampuses)

	const { formData } = useSignupStepsContext()

	const [selectedCampus, setSelectedCampus] = useState(formData.patches.campusId || -1)

	const disableContinueButton = !checkCanProceed(selectedCampus)

	const handleChange = (value: any) => setSelectedCampus(value)
	const campusConfig = useOnboardingStepConfig('campus')

	useSignupStepDataPatcher(
		{
			campusId: selectedCampus,
		},
		[selectedCampus],
	)

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.SELECTED_CAMPUS, {
			campus: selectedCampus,
		})
	}

	return (
		<SignUpCard
			classnames={{
				root: classes.card,
				continueButton: classes.continueButton,
			}}
			title={campusConfig?.title}
			continueButtonDisabled={disableContinueButton}
			onClickContinue={onClickContinue}
		>
			<div className={classes.content}>
				<div className={classes.selectsContainer}>
					{!!(campuses.length > 1) && (
						<CommonSelect
							classnames={{
								input: classes.select,
							}}
							value={selectedCampus}
							onChange={handleChange}
							options={campuses}
							placeholder="Select campus"
						/>
					)}
				</div>
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (selectedCampus) => {
	return !!selectedCampus && selectedCampus !== -1
}

CampusStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.campusId)
}

export default CampusStep

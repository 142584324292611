import getCountries from './getCountries'
import postNewGoals from './postNewGoals'
import getUserGoals from './getUserGoals'

const pathBuilder = {
	getCountries,
	postNewGoals,
	getUserGoals,
}

export default pathBuilder

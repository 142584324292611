import ContinueButtonImage from '@/components/icons/ContinueButtonImage'
import { PATHS, STYLES } from '@/constants'
import { isEngage } from '@/constants/configuration'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useHistory } from 'react-router-dom'

interface StylesProps {
	landingBackground: string
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		width: '100vw',
		background: ({ landingBackground }: StylesProps) => `linear-gradient(
			rgba(0, 0, 0, 0.7), 
			rgba(0, 0, 0, 0.7)
		  ), url(${landingBackground}) center center / cover no-repeat`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		padding: 0,
		width: 224,
		height: 65,
		borderRadius: 10,
	},
	card: {
		maxWidth: 580,
		maxHeight: 520,
		minWidth: 350,
		backgroundColor: '#00153e1f',
		borderRadius: 10,
		width: '50vw',
		height: 520,
		color: theme.palette.common.white,
		textAlign: 'center',
	},
	logo: {
		height: 45,
		margin: '20px 0',
	},
	headerText: {
		fontWeight: 700,
		fontSize: 30,
	},
	buttonImage: {
		padding: 0,
		width: 224,
		height: 64,
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
		margin: '3vh',
		height: 'calc(100% - 6vh)',
	},
	text: {
		fontWeight: 500,
		fontSize: 20,
	},
}))

const WelcomePage = () => {
	const { push } = useHistory()

	const {
		logo: { main },
		school: { name },
		landingBackground,
	} = useSchoolConfig()
	const classes = useStyles({ landingBackground })
	const handleContinueClick = () => push(PATHS.APP.PATH_BUILDER_QUESTIONS)

	return (
		<div className={classes.root}>
			<div className={classes.card}>
				<div className={classes.container}>
					<div className={classes.content}>
						<img className={classes.logo} src={main} alt={`${name} logo`} />
						<div className={classes.header}>
							<Typography className={classes.headerText} variant="h3">
								Welcome to your personal
							</Typography>
							<Typography className={classes.headerText} variant="h3">
								{isEngage() ? 'Goal Setting Tool' : 'Path Builder'}
							</Typography>
						</div>
						<Typography className={classes.text}>Set your goals and receive</Typography>
						<Typography className={classes.text}>next steps to achieve success.</Typography>
					</div>
					<Button className={classes.button} onClick={handleContinueClick}>
						<ContinueButtonImage className={classes.buttonImage} />
					</Button>
				</div>
			</div>
		</div>
	)
}

export default WelcomePage

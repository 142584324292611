import React, { useCallback } from 'react'
import { CircularProgress } from '@material-ui/core'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'

export type ICreatePageHeaderConfig<T extends string> = {
	[K in T]: {
		order: number
		label: string
		placeholder?: {
			title?: string
			description?: string[]
		}
	}
}

export type IPageHeaderTabsProps<TabConfig extends ICreatePageHeaderConfig<any>> = {
	currentTab: keyof TabConfig
	onChange: (type: keyof TabConfig) => void
	title?: string
	totalCount?: string
	showPreloader?: boolean
	tabConfig: TabConfig
}

export default function PageHeaderTabs<TabConfig extends ICreatePageHeaderConfig<any>>({
	currentTab,
	onChange,
	tabConfig,
	showPreloader,
	title,
	totalCount,
}: IPageHeaderTabsProps<TabConfig>) {
	const allTabs = Object.values(tabConfig)
		.sort((a, b) => a.order - b.order)
		.map((val) => val.label)
	const activeTab = tabConfig[currentTab]

	const tabInfo = showPreloader ? (
		<span>
			<CircularProgress size={16} /> {` ${activeTab.label}`}
		</span>
	) : (
		`${totalCount} ${activeTab.label}`
	)

	const handleChangeTab = useCallback(
		(tabIndex) => {
			const selectedType = (Object.keys(tabConfig) as (keyof TabConfig)[]).find((type) => {
				return tabConfig[type].order === tabIndex
			})
			if (selectedType) {
				onChange(selectedType)
			}
		},
		[onChange, tabConfig],
	)

	return <ItemsSectionTitle title={title} pageInfo={tabInfo} tabs={allTabs} handleChangeTab={handleChangeTab} activeTab={activeTab.order} />
}

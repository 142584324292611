import { PATHS } from '@/constants'

export const REQUIRE_CONFIRMATION_BEFORE_CHANGE_PATH = [PATHS.APP.EVENTS_CREATE]

export const SITE_NAME = window.document.title
export const UNIQUE_PAGE_TITLE_REQUIRED = 'unique-page-title-required'

// TODO - temporary, moved over from https://github.com/Navengage/sen-analytics/blob/main/src/types/pages.ts
export const PAGE_NAME = {
	MY_PROFILE: 'My Profile',
	ADMIN_DASHBOARD: 'Admin Dashboard',
	HOME: 'Home',
	NOTIFICATIONS: 'Notifications',
	MESSAGES: 'Messages',
	DISCOVER_EVENTS: 'Discover Events',
	MY_EVENTS: 'My Events',
	CREATE_EVENT: 'Create Event',
	EDIT_EVENT: 'Edit Event',
	DISCOVER_INTERNSHIPS: 'Discover Internships',
	MY_INTERNSHIPS: 'My Internships',
	DISCOVER_NETWORK: 'Discover Network',
	MY_NETWORK: 'My Network',
	DISCOVER_ORGANIZATIONS: 'Discover Organizations',
	MY_ORGANIZATIONS: 'My Organizations',
	PATHBUILDER_WELCOME: 'Welcome to Path Builder',
	GOAL_SETTING_WELCOME: 'Welcome to Goal Setting',
	PATHBUILDER_STEPS: 'Path Builder',
	PATHBUILDER_GRANT: 'Grant',
	PATHBUILDER_SUMMARY: 'Path Builder Summary',
	PATHBUILDER_LAST_STEP: 'Last Step',
	DISCOVER_RESEARCH: 'Discover Research',
	MY_RESEARCH: 'My Research',
	BLOCKED_ACCOUNTS: 'Blocked Accounts',
	NOTIFICATIONS_SETTINGS: 'Notifications Settings',
	PROFILE_SETTINGS: 'Profile Settings',
	SIGN_UP_INTRODUCTION: 'Introduction',
	SIGN_UP_STEPS: 'Sign Up Steps',
	SIGN_UP_THANKS: 'Thanks',
	SIGN_UP_PLEDGE: 'Pledge',
	DISCOVER_EDUCATION_ABROAD: 'Discover Education Abroad',
	MY_EDUCATION_ABROAD: 'My Education Abroad',
	DISCOVER_VOLUNTEER_EVENTS: 'Discover Volunteer Events',
	DISCOVER_VOLUNTEER_ORGANIZATIONS: 'Discover Volunteer Organizations',
	MY_VOLUNTEER_SHIFTS: 'My Volunteer Shifts',
	LOGIN: 'Login',
}

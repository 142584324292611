import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { PATHS, STYLES } from '@/constants'
import { UserData } from '@/interfaces/common'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import { UserPrimaryTitle } from './UserProfile/UserPrimaryTitle'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '1vh 15px',
	},
	informationContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	profileImage: {
		width: 40,
		height: 40,
		minWidth: 40,
		minHeight: 40,
		marginRight: 20,
	},
	clickable: {
		cursor: 'pointer',
	},
	childrenContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginTop: 10,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	text: {
		textTransform: 'capitalize',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
}))

interface UserRowProps {
	user: UserData
	children?: React.ReactNode
	profileImageRadius?: number
	classnames?: {
		root?: string
		userName?: string
		info?: string
		profileImage?: string
		informationContainer?: string
		additionalInfoText?: string
	}
	showProfilePhoto?: boolean
	showPrimaryTitle?: boolean
	openProfileOnClick?: boolean
	endAdornment?: React.ReactNode
	showAdditionalInfo?: boolean
}

export const UserRow = ({
	user,
	children,
	profileImageRadius = 50,
	classnames = {
		root: '',
		userName: '',
		info: '',
		profileImage: '',
		informationContainer: '',
		additionalInfoText: '',
	},
	showProfilePhoto = true,
	showPrimaryTitle = true,
	openProfileOnClick = true,
	showAdditionalInfo = true,
	endAdornment,
}: UserRowProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const { externalId, id, fullName, type, campus, primaryTitle, primaryTitleDepartment, photoUrl } = user

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${id}`))
	}

	return (
		<div
			className={classNames(classes.root, openProfileOnClick ? classes.clickable : '', classnames.root)}
			onClick={openProfileOnClick ? handleClickViewProfile : undefined}
		>
			{showProfilePhoto && (
				<LazyLoadProfileImage
					borderRadius={profileImageRadius}
					className={classNames(classes.profileImage, classnames.profileImage)}
					externalUserId={externalId || ''}
					photoUrl={photoUrl}
				/>
			)}
			<div className={classNames(classnames.informationContainer, classes.informationContainer)}>
				<Typography className={classNames(classes.userName, classes.text, classnames.userName)}>{fullName}</Typography>

				{showPrimaryTitle && (
					<UserPrimaryTitle
						variant={'subtitle1'}
						className={classes.text}
						primaryTitle={primaryTitle}
						primaryTitleDepartment={primaryTitleDepartment}
					/>
				)}
				{showAdditionalInfo && (
					<Typography className={classNames(classes.text, classnames.info)} variant={'subtitle1'}>
						{`${type} ${campus ? ` | ${campus}` : ''}`}
					</Typography>
				)}
				{/* Prevent event propagation when click to the button inside a row */}
				{children && (
					<div className={classes.childrenContainer} onClick={(e: any) => e.stopPropagation()}>
						{children}
					</div>
				)}
			</div>
			{endAdornment}
		</div>
	)
}

export default UserRow

// @ts-nocheck

import * as actions from '@/store/messages/actions'
import { MessagesState, NewMessageContent } from '@/store/messages/types'
import { createReducer } from '@reduxjs/toolkit'
import { orderBy } from 'lodash'

const initialState: MessagesState = {
	isLoading: false,
	shareModal: {
		isOpen: false,
	},
	showSelectUserInput: false,
	newMessageContent: {},
	chats: {
		list: [],
		canLoadMore: true,
		isLoading: true,
		numberOfUnViewed: 0,
	},
	messages: {
		isLoading: false,
		canLoadMore: false,
		list: [],
	},
	searchUsers: {
		q: '',
		users: [],
		page: 1,
		canLoadMore: true,
		isLoading: false,
	},
}

const messagesReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, { payload }) => ({
			...state,
			isLoading: payload,
			error: payload ? null : state.error,
		}))
		.addCase(actions.setError, (state, { payload }) => ({
			...state,
			isLoading: false,
			error: payload,
		}))
		.addCase(actions.loadMessagesListRequest.pending, (state) => ({
			...state,
			messages: {
				...state.messages,
				isLoading: true,
			},
		}))
		.addCase(actions.loadMessagesListRequest.rejected, (state) => ({
			...state,
			messages: {
				...state.messages,
				isLoading: false,
			},
		}))
		.addCase(actions.loadMessagesListRequest.fulfilled, (state) => ({
			...state,
			messages: {
				...state.messages,
				isLoading: false,
			},
		}))
		.addCase(actions.setMessages, (state, { payload: { list, canLoadMore } }) => ({
			...state,
			messages: {
				...state.messages,
				list,
				canLoadMore,
			},
		}))
		.addCase(actions.clearMessages, (state) => ({
			...state,
			messages: {
				list: [],
				isLoading: false,
				canLoadMore: true,
			},
		}))
		.addCase(actions.setSearchUsersQuery, (state, { payload }) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				q: payload,
				canLoadMore: true,
			},
		}))
		.addCase(actions.searchUserRequest.pending, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: true,
			},
		}))
		.addCase(actions.searchUserRequest.rejected, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: false,
			},
		}))
		.addCase(actions.searchUserRequest.fulfilled, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: false,
			},
		}))
		.addCase(actions.setSearchUsers, (state, { payload: { page, canLoadMore, users } }) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				page,
				canLoadMore,
				users,
			},
		}))
		.addCase(actions.clearSearchUsers, (state) => ({
			...state,
			searchUsers: {
				q: '',
				page: 1,
				canLoadMore: true,
				isLoading: false,
				users: [],
			},
		}))
		.addCase(actions.setNewMessageContent, (state, { payload }) => {
			let newContent: NewMessageContent = { ...state.newMessageContent }

			if (payload.sharedEntity) {
				const { sharedEntity, ...prevData } = newContent

				newContent = {
					...prevData,
					...payload,
				}
			} else {
				newContent = {
					...newContent,
					...payload,
				}
			}

			return {
				...state,
				newMessageContent: newContent,
			}
		})
		.addCase(actions.removeNewMessageSharedItems, (state) => {
			const { sharedEntity, ...prevData } = state.newMessageContent

			const newContent: NewMessageContent = {
				...prevData,
			}

			return {
				...state,
				newMessageContent: newContent,
			}
		})
		.addCase(actions.clearNewMessageContent, (state) => ({
			...state,
			newMessageContent: {},
		}))
		.addCase(actions.setChatsList, (state, { payload: { canLoadMore, list } }) => ({
			...state,
			chats: {
				...state.chats,
				canLoadMore,
				list,
			},
		}))
		.addCase(actions.updateChatsList, (state, { payload }) => {
			const chatIndex = state.chats.list.findIndex((data) =>
				payload.group ? data.group?.id === payload.group.id : data.user?.id === payload.user?.id,
			)

			if (chatIndex >= 0) {
				const updatedList = state.chats.list.map((data, index) => (index === chatIndex ? payload : data))

				const sortedList = orderBy(updatedList, ['message.createdAt'], ['desc'])

				return {
					...state,
					chats: {
						...state.chats,
						list: sortedList,
					},
				}
			}

			return {
				...state,
				chats: {
					...state.chats,
					list: [payload].concat(state.chats.list),
				},
			}
		})
		.addCase(actions.loadChatsListRequest.pending, (state) => ({
			...state,
			chats: {
				...state.chats,
				isLoading: true,
			},
		}))
		.addCase(actions.loadChatsListRequest.fulfilled, (state) => ({
			...state,
			chats: {
				...state.chats,
				isLoading: false,
			},
		}))
		.addCase(actions.loadChatsListRequest.rejected, (state) => ({
			...state,
			chats: {
				...state.chats,
				isLoading: false,
			},
		}))
		.addCase(actions.setTargetUser, (state, { payload }) => ({
			...state,
			targetUser: payload,
		}))
		.addCase(actions.clearTargetUser, (state) => ({
			...state,
			targetUser: null,
		}))
		.addCase(actions.setShowSelectUserInput, (state, { payload }) => ({
			...state,
			showSelectUserInput: payload,
			searchUsers: {
				q: '',
				page: 1,
				canLoadMore: true,
				isLoading: false,
				users: [],
			},
			// userMessages: payload ? {} : state.userMessages,
		}))
		.addCase(actions.setShareModal, (state, { payload }) => ({
			...state,
			shareModal: payload,
		}))
		.addCase(actions.clearShareModal, (state) => ({
			...state,
			shareModal: {
				isOpen: false,
			},
		}))
		.addCase(actions.addMessage, (state, { payload }) => ({
			...state,
			messages: {
				...state.messages,
				list: [payload].concat(state.messages.list),
			},
		}))
		.addCase(actions.deleteMessage, (state, { payload }) => {
			state.messages.list = state.messages.list.filter((message) => message.id !== payload.id)
		})
		.addCase(actions.setMessageViewStatus, (state, { payload: { list, message, numberOfUnViewed } }) => {
			return {
				...state,
				chats: {
					...state.chats,
					list,
					numberOfUnViewed,
				},
				messages: {
					...state.messages,
					list: state.messages.list.map((m) => (m.id === message.id ? { ...m, viewed: message.viewed } : m)),
				},
			}
		})

		.addCase(actions.setNumberOfUnViewedMessages, (state, { payload }) => ({
			...state,
			chats: {
				...state.chats,
				numberOfUnViewed: payload,
			},
		}))
		.addCase(actions.updateCurrentUserMessageReactions, (state, { payload: { messageId, type, updateType, isCurrentUser } }) => {
			return {
				...state,
				messages: {
					...state.messages,
					list: state.messages.list.map((message) => {
						if (message.id === messageId) {
							const newMessage = {
								...message,
							}
							const { currentUserReacteions = [], likes = 0 } = newMessage
							if (isCurrentUser) {
								newMessage.currentUserReacteions =
									updateType === 'add' ? [...currentUserReacteions, type] : currentUserReacteions.filter((r) => r !== type) ?? []
							}

							newMessage.likes = updateType === 'add' ? likes + 1 : likes - 1
							return newMessage
						}
						return message
					}),
				},
			}
		})
		.addCase(actions.markAllRead, (state, { payload: { targetUserId, groupId } }) => {
			const chatRoom = state.chats.list.find((chat) => {
				return (chat.user && targetUserId && chat.user.id === targetUserId) || (chat.group && groupId && groupId === chat.group.id)
			})
			if (chatRoom) {
				if (state.chats.numberOfUnViewed - chatRoom.unviewed >= 0) {
					state.chats.numberOfUnViewed -= chatRoom.unviewed
				}

				chatRoom.unviewed = 0
			}
		})
})

export { messagesReducer }

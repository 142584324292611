import { Drawer } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ModalWithSubmittedPopup } from '@/components'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { getIsOpenAddNewMemberPopup, getIsOpenDrawer, readGroupRequest, setAddNewMembersPopup, setDrawer } from '@/store/groups'
import { IGroupChat } from '@navengage/sen-types'
import EditGroupInformation from './EditGroupInformation'
import EditGroupSettings from './EditGroupSettings'
import { GroupGallery } from './GroupGallery'
import GroupInformation from './GroupInformation'
import GroupMemberRequests from './GroupMemberRequests'
import GroupMembers from './GroupMembers'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.GROUP_BAR.XL,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			width: STYLES.GROUP_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			width: STYLES.GROUP_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			width: STYLES.GROUP_BAR.SM,
		},
		height: '100%',
	},
	drawer: {
		flexShrink: 0,
	},
}))

export enum EditTabs {
	EDIT = 'Edit',
	MEMBERS = 'Members',
	MEMBER_REQUESTS = 'Member Requests',
	GALLERY = 'Gallery',
	SETTINGS = 'Settings',
}

interface GroupSideBarProps {
	data?: IGroupChat
	onClose?: () => void
}

const GroupSideBar = ({ data, onClose }: GroupSideBarProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const isOpen = useSelector(getIsOpenDrawer)
	const isOpenAddNewMember = useSelector(getIsOpenAddNewMemberPopup)

	const [openEditTab, setOpenEditTab] = useState<EditTabs | null>(null)
	const [isOpenUnsavedChangesPopup, setIsOpenUnsavedChangesPopup] = useState(false)
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

	const handleOpenEditTab = (tab: EditTabs) => () => setOpenEditTab(tab)

	const resetAllTabsAndCloseDrawer = () => {
		if (onClose) onClose()
		dispatch(setDrawer(false))
		dispatch(setAddNewMembersPopup(false))
		setHasUnsavedChanges(false)
		setIsOpenUnsavedChangesPopup(false)
		setOpenEditTab(null)
	}

	const handleCheckUnsavedChanges = useCallback(
		(close: () => void) => () => {
			if (hasUnsavedChanges) {
				setIsOpenUnsavedChangesPopup(true)
			} else {
				close()
			}
		},
		[hasUnsavedChanges],
	)

	const handleCloseDrawer = handleCheckUnsavedChanges(resetAllTabsAndCloseDrawer)

	const handleCloseUnsavedChangesPopup = () => setIsOpenUnsavedChangesPopup(false)

	const handleAddUnsavedChanges = () => setHasUnsavedChanges(true)
	const handleClearUnsavedChanges = () => setHasUnsavedChanges(false)
	const handleCloseEditTab = () => setOpenEditTab(null)
	const handleResetUnsavedChangesAndCloseTab = useCallback(() => {
		handleCloseEditTab()
		setHasUnsavedChanges(false)
		setIsOpenUnsavedChangesPopup(false)
	}, [])
	const handleCheckTabChangesAndClose = handleCheckUnsavedChanges(handleCloseEditTab)
	const handleCancelUnsavedChangesAndClose = () => {
		if (hasUnsavedChanges) {
			handleResetUnsavedChangesAndCloseTab()
		} else {
			resetAllTabsAndCloseDrawer()
		}
	}

	const tabs = useMemo(
		() =>
			data
				? {
						[EditTabs.EDIT]: (
							<EditGroupInformation
								data={data}
								onClose={handleCheckTabChangesAndClose}
								onTouch={handleAddUnsavedChanges}
								onSubmit={handleResetUnsavedChangesAndCloseTab}
							/>
						),
						[EditTabs.MEMBERS]: (
							<GroupMembers
								data={data}
								onTouch={handleAddUnsavedChanges}
								onClose={handleCloseEditTab}
								onSubmitUnsavedChanges={handleClearUnsavedChanges}
							/>
						),
						[EditTabs.MEMBER_REQUESTS]: <GroupMemberRequests onClose={handleCloseEditTab} />,
						[EditTabs.GALLERY]: <GroupGallery data={data} onClose={handleCloseEditTab} />,
						[EditTabs.SETTINGS]: (
							<EditGroupSettings
								data={data}
								onTouch={handleAddUnsavedChanges}
								onClose={handleCheckTabChangesAndClose}
								onSubmit={handleResetUnsavedChangesAndCloseTab}
							/>
						),
				  }
				: {},
		[data, handleCheckTabChangesAndClose, handleResetUnsavedChangesAndCloseTab],
	)

	useEffect(() => {
		if (!isOpen) resetAllTabsAndCloseDrawer()
		else dispatch(readGroupRequest({ id: data!.id }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		if (isOpenAddNewMember) setOpenEditTab(EditTabs.MEMBERS)
	}, [isOpenAddNewMember])

	return (
		<>
			<ModalWithSubmittedPopup
				isOpen={isOpenUnsavedChangesPopup}
				onSubmit={handleCancelUnsavedChangesAndClose}
				onClose={handleCloseUnsavedChangesPopup}
				title="Want to cancel?"
				content={['Your work will not be saved.']}
				submitButtonColor={appTheme.colors.red[500]}
				submitButtonLabel="Yes, cancel"
				cancelButtonLabel="No, keep editing"
				showSubmittedModal={false}
			/>
			<Drawer anchor="right" open={isOpen} onClose={handleCloseDrawer} className={classes.drawer}>
				<div className={classes.root}>
					{!openEditTab ? <GroupInformation data={data} handleOpenTab={handleOpenEditTab} /> : tabs[openEditTab]}
				</div>
			</Drawer>
		</>
	)
}

export default GroupSideBar

import http from '@/api/http/requestHttp'
import { Id } from '@/lib/types'

export interface LikePostReq {
	postId: Id
	userId: number
	schoolId: number
}
const likePost = async ({ postId, userId, schoolId }: LikePostReq) => {
	const url = `${schoolId}/feeds/${userId}/post/${postId}/like`

	return await http.patch(url, {})
}

export default likePost

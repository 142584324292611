import searchOrganizations from './searchOrganizations'
import getListCategories from './getListCategories'
import getAllUserOrganizations from './getAllUserOrganizations'
import getOrganizationById from './getOrganizationById'
import getOrganizationUserResponse from './getOrganizationUserResponse'
import postOrganizationUserResponse from './postOrganizationUserResponse'
import getOrganizationsByMembershipType from './getOrganizationsByMembershipType'
import getOrganizationActivity from './getOrganizationActivity'
import getOrganizationFollowers from './getOrganizationFollowers'
import patchOrganizationMember from './patchOrganizationMember'
import patchOrganizationMembershipRequest from './patchOrganizationMembershipRequest'
import postOrganizationMembershipRequest from './postOrganizationMembershipRequest'
import postOrganizationsMembershipJoinInfo from './postOrganizationsMembershipJoinInfo'
import getOrganizationChangePositionRequests from './getOrganizationChangePositionRequests'
import getOrganizationMemberRequests from './getOrganizationMemberRequests'
import getOrganizationUserPendingMembershipRequests from './getOrganizationUserPendingMembershipRequests'
import deleteOrganizationMember from './deleteOrganizationMember'
import patchOrganizationContact from './patchOrganizationContact'
import patchOrganization from './patchOrganization'
import patchOrganizationMembershipRequestToViewed from './patchOrganizationMembershipRequestToViewed'
import searchUsers from './searchUsers'
import searchOrganizationMemberships from './searchOrganizationMemberships'
import getUserTypeOptions from './getUserTypeOptions'
import postNewMembership from './postNewMembership'

const organizations = {
	searchOrganizations,
	getListCategories,
	getAllUserOrganizations,
	getOrganizationById,
	getOrganizationUserResponse,
	postOrganizationUserResponse,
	getOrganizationsByMembershipType,
	getOrganizationActivity,
	getOrganizationFollowers,
	patchOrganizationMember,
	patchOrganizationMembershipRequest,
	postOrganizationMembershipRequest,
	postOrganizationsMembershipJoinInfo,
	getOrganizationChangePositionRequests,
	getOrganizationMemberRequests,
	getOrganizationUserPendingMembershipRequests,
	deleteOrganizationMember,
	patchOrganizationContact,
	patchOrganization,
	patchOrganizationMembershipRequestToViewed,
	searchUsers,
	searchOrganizationMemberships,
	getUserTypeOptions,
	postNewMembership,
}

export default organizations

import { Popper, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandLess, ExpandMore, Visibility } from '@material-ui/icons'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { PrivacyLevels, PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { useAppDispatch } from '@/store'
import { getUserPrivacyOptions, updateItemPrivacyRequest } from '@/store/auth'
import IconWrapper from './IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		height: 40,
		minWidth: 200,
		color: theme.palette.text.primary,
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 7,
		backgroundColor: theme.palette.background.paper,
		...theme.typography.body1,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[500] : theme.palette.action.hover,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
	},
	privacyLabel: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	labelText: {
		margin: '0 5px',
	},
	optionsContainer: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		zIndex: 1445,
		minWidth: 200,
		maxWidth: 450,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		borderRadius: 0,
		textAlign: 'left',
		...theme.typography.body1,
		'&>span': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'flex-start',
		},
	},
	buttonContent: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 10,
	},
	selectedOption: {
		color: theme.palette.primary.main,
	},
	title: {
		color: theme.palette.text.primary,
		fontWeight: 700,
		padding: '16px 12px',
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
}))

const isSelected = (value: PrivacyLevels | undefined, optionValue: PrivacyLevels) =>
	((value === null || value === undefined) && optionValue === PrivacyLevels.allUsers) || optionValue === value

export interface PrivacySelectProps {
	itemId?: number
	type: PrivacyTypes
	privacyLevel?: PrivacyLevels
	className?: string
	selectClassName?: string
	onChangePrivacy?: (id: string | number, newPrivacyLevel: PrivacyLevels) => void
}

const PrivacySelect = ({ className, type, privacyLevel, itemId, selectClassName, onChangePrivacy }: PrivacySelectProps) => {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)
	const dispatch = useAppDispatch()

	const options = useSelector(getUserPrivacyOptions)

	const handleToggle = (e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClickModal = (e: any) => {
		e.stopPropagation()
	}

	const handleClickModalButton = (newPrivacyLevel: PrivacyLevels) => () => {
		setOpen(false)
		if (onChangePrivacy) {
			onChangePrivacy(itemId!, newPrivacyLevel)
		}
		if ((onChangePrivacy && type === PrivacyTypes.membership) || !onChangePrivacy) {
			dispatch(
				updateItemPrivacyRequest({
					entityId: itemId,
					entityType: type,
					privacyLevel: newPrivacyLevel,
				}),
			)
		}
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const label = useMemo(() => options.find((opt) => isSelected(privacyLevel, opt.value)), [options, privacyLevel])?.label

	useOutsideClickCallback(popperRef, handleClose)

	return (
		<div onClick={handleClickModal} className={className}>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<Typography className={classes.title}>Who can see this?</Typography>
				<div className={classes.optionsContainer}>
					{options.map((option) => {
						const selected = isSelected(privacyLevel, option.value)

						return (
							<IconButton key={option.label} className={classes.popoverButton} onClick={handleClickModalButton(option.value)}>
								<div className={classNames(classes.buttonContent, selected ? classes.selectedOption : '')}>
									<Typography>{option.label}</Typography>
									{selected && <IconWrapper iconKey="checkCircle" weight="fill" />}
								</div>
								<Typography>{option.description}</Typography>
							</IconButton>
						)
					})}
				</div>
			</Popper>
			<IconButton className={classNames(classes.root, selectClassName)} ref={anchorRef} onClick={handleToggle}>
				<div className={classes.privacyLabel}>
					<Visibility />
					<Typography className={classes.labelText} variant="body1">
						{label}
					</Typography>
				</div>
				{!open ? <ExpandMore /> : <ExpandLess />}
			</IconButton>
		</div>
	)
}

export default PrivacySelect

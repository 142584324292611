import NavigationTabs from '@/components/NavigationTabs'
import { useAppDispatch, useAppSelector } from '@/store'

import NavigationTab from '@/components/NavigationTab'
import { Typography, makeStyles } from '@material-ui/core'
import { useCallback } from 'react'
import { FEED_TABS, FILTER_CONFIG, feedPlainTabs, feedsTabInfo } from '../../feed/config'
import { selectFeedUserAudience, setFilterUserAudience } from '../../feed/slice'

const useStyles = makeStyles(() => ({
	tabs: {
		minWidth: 'fit-content',
	},
	tabWrapper: {
		marginTop: 0,
	},
}))

const FeedTabs = () => {
	const classes = useStyles()
	const userAudience = useAppSelector(selectFeedUserAudience)
	const dispatch = useAppDispatch()
	const handleChangeTab = useCallback(
		(index) => {
			dispatch(setFilterUserAudience(feedsTabInfo[index].value))
		},
		[dispatch],
	)

	const tab = FEED_TABS[userAudience] ?? FEED_TABS[FILTER_CONFIG.usersAudience.defaultValue]

	const { order: activeTab, description } = tab

	return (
		<>
			{description && <Typography>{description}</Typography>}

			<div className={classes.tabs}>
				<NavigationTabs
					activeTabIndex={activeTab}
					tabsRenderer={(props) =>
						feedPlainTabs.map((tab, index) => (
							<NavigationTab
								className={classes.tabWrapper}
								key={`${tab}-${index}`}
								index={index}
								label={tab}
								onClick={handleChangeTab}
								{...props}
							/>
						))
					}
				/>
			</div>
		</>
	)
}

export default FeedTabs

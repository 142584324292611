import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import { camelizeKeys } from 'humps'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ModalWithSubmittedPopup } from '@/components'
import { AlertAction } from '@/components/AlertAction'
import ExitConfirmationModal from '@/components/ExitConfirmationModal'
import { CreateFeedsModal } from '@/components/Feeds/CreateFeedsModal'
import MainLayout from '@/components/MainLayout'
import ShareItemShareItemViaMessageModalModal from '@/components/Messages/ShareItemViaMessageModal'
import NewTermsAndConditionsPopup from '@/components/NewTermsAndConditionsPopup'
import Router from '@/components/Router'
import SideBar from '@/components/SideBar'
import { NewUserWelcomeModal } from '@/components/newUserWelcomeModal'
import { PATHS } from '@/constants'
import { NOTIFICATION_TYPES } from '@/constants/notifications'
import { notificationsApi } from '@/features/notifications/api'
import useBrowserBackStack from '@/hooks/useBrowserBackStack'
import useScrollToTop from '@/hooks/useScrollToTop'
import useSocket from '@/hooks/useSocket'
import { selectIsRouteRestricted } from '@/lib/splitio'
import NoMatch from '@/pages/NoMatch'
import ROUTES from '@/routes'
import { RootState, useAppDispatch } from '@/store'
import { loadConstantsRequest, showUploadErrors } from '@/store/app'
import { getUserInfo, setIsOpenAcceptNewTermsPopup } from '@/store/auth'
import { NotificationPages, UserNotification } from '@/store/auth/types'
import { newMessageReceived, updateCurrentUserMessageReactions, updateMessageViewStatus } from '@/store/messages'
import { DtoSocketMessageReaction, Message, TMessageReactions } from '@/store/messages/types'
import { loadUserConnectionsRequest } from '@/store/network'
import { getLeaveFromOrganizationModal, leaveOrganizationRequest, setLeaveFromOrganizationModal } from '@/store/organizations'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	restrictionAlertContent: {
		textAlign: 'center',
		margin: `${theme.spacing(2)}px 0px`,
	},
}))

const MainRouter = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const userInfo = useSelector(getUserInfo)
	const { pathname } = useLocation()
	const { backStack, clearStack } = useBrowserBackStack()

	const hasEffectRun = useRef(false)

	const leaveOrganizationModalState = useSelector(getLeaveFromOrganizationModal)
	const isRestricted = useSelector((state: RootState) => selectIsRouteRestricted(state, pathname))

	const restrictedRoutes = useMemo(() => {
		if (backStack.length === 0 && isRestricted) {
			return ROUTES.app.map((route) => (`${route.path}/`.includes(pathname) ? { ...route, isRestricted: true } : route))
		} else {
			return ROUTES.app
		}
	}, [isRestricted, pathname, backStack.length])

	const handleRestrictionModalClose = useCallback(() => {
		clearStack()

		dispatch(push(PATHS.APP.HOME))
	}, [clearStack, dispatch])

	const restrictedAlertButtons = useMemo(() => [{ label: 'Ok', onClick: handleRestrictionModalClose }], [handleRestrictionModalClose])

	const { activateSocket, closeSocket } = useSocket()

	useScrollToTop()

	useEffect(() => {
		if (!hasEffectRun.current) {
			dispatch(loadConstantsRequest())
			dispatch(showUploadErrors())
			hasEffectRun.current = true
		}

		activateSocket({
			onReceiveNotification: (data: unknown) => {
				const camelizedData = camelizeKeys(data) as UserNotification

				if (camelizedData.data?.type === NOTIFICATION_TYPES.NEW_CONNECTION) {
					dispatch(loadUserConnectionsRequest())
				}
				const cachedNotificationTypes: NotificationPages[] = ['all', 'new']
				cachedNotificationTypes.forEach((type) =>
					dispatch(
						notificationsApi.util.updateQueryData('getUserNotifications', { userId: userInfo.id, type }, (draft) => {
							if (draft) {
								draft.unviewed += 1
								draft.total += 1
								draft.notifications.unshift({
									...camelizedData,
									receiver: {
										viewed: false,
									},
								})
							}
						}),
					),
				)
			},
			onReceiveNewMessage: (data: unknown) => {
				const camelizedData = camelizeKeys(data) as Message
				// Skip handling message if it posted by current user as it is already handled
				if (camelizedData.sourceUserId !== userInfo.id) {
					dispatch(newMessageReceived(camelizeKeys(data) as Message))
				}
			},
			onMessageViewStatusChanged: (data: any) => {
				dispatch(updateMessageViewStatus({ ...data, id: data.messageId }))
			},
			onError: (e: any) => {
				console.error('error', e)
			},
			onMessageReaction: ({ groupId, messageId, reaction }: DtoSocketMessageReaction) => {
				// @TODO inconsistency in the backend we need to send every time same value either Like or like
				dispatch(
					updateCurrentUserMessageReactions({
						messageId,
						isCurrentUser: false,
						type: TMessageReactions.like,
						updateType: reaction === 'like' ? 'add' : 'remove',
					}),
				)
			},
		})

		return closeSocket
	}, [activateSocket, closeSocket, dispatch, userInfo.id])

	useEffect(() => {
		if (!userInfo?.acceptedTermsConditions) {
			dispatch(setIsOpenAcceptNewTermsPopup(true))
		}
	}, [dispatch, userInfo?.acceptedTermsConditions])

	const handleLeaveOrganization = () => {
		dispatch(leaveOrganizationRequest())
	}

	const handleCloseLeaveFromOrganizationModal = () => {
		dispatch(setLeaveFromOrganizationModal({ isOpen: false, orgId: '' }))
	}

	return (
		<MainLayout sidebar={<SideBar />}>
			<NewUserWelcomeModal />
			<NewTermsAndConditionsPopup />
			<CreateFeedsModal />
			<ShareItemShareItemViaMessageModalModal />
			<ModalWithSubmittedPopup
				isOpen={leaveOrganizationModalState.isOpen}
				onSubmit={handleLeaveOrganization}
				onClose={handleCloseLeaveFromOrganizationModal}
				title="Leave organization"
				content={['Are you sure you want to leave the organization?']}
				submittedMessage={['You left the organization.']}
				submitButtonLabel="Yes"
			/>

			<Router
				routes={restrictedRoutes}
				redirectPaths={[
					{
						from: PATHS.APP.ROOT,
						to: PATHS.APP.HOME,
					},
				]}
				noMatch={<NoMatch />}
			/>
			<AlertAction
				isOpen={isRestricted}
				buttons={restrictedAlertButtons}
				content={
					<div className={classes.restrictionAlertContent}>
						<Typography variant="body1">This feature is not currently available.</Typography>
						<Typography variant="body1"> Please contact your administrator.</Typography>
					</div>
				}
				handleClose={handleRestrictionModalClose}
			/>

			<ExitConfirmationModal />
		</MainLayout>
	)
}

export default MainRouter

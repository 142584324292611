import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { DtoGroupMemberRequest } from '@navengage/sen-types'

interface SearchParams {
	id: string
	page: number
	schoolId?: number
}

const getGroupMembers = async ({ id, page, schoolId }: SearchParams) => {
	const url = `${schoolId}/groups/${id}/members/requests`

	const params = {
		page,
		limit: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get<DtoGroupMemberRequest[]>(url, { params })
}

export default getGroupMembers

const ROOT = 'events'

const EVENTS = {
	ROOT,
	MY: `${ROOT}/my`,
	SINGLE: (id?: string | number) => `${ROOT}/event/${id !== undefined ? id : ':id'}`,
	CREATE: `${ROOT}/create`,
}

export default EVENTS

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { SCHOOL_KEY } from '@/constants/app'
import { DEFAULT_SCHOOL_CONFIG } from '@/init/config'
import { RootState } from '@/store'
import { Path, nestedValueByPath } from '@/utils/nestedValueByPath'
import { TSchoolConfiguration, ThemeOutputColors, selectPalette, getSchoolConfiguration } from '@navengage/sen-shared-assets'
import { NAV_APP_ID } from '@/constants/configuration'
import DtoSchoolData from './types/dto/DtoSchoolData'

export const FEATURE_NAME = 'SCHOOL'

type TSchoolState = {
	config: TSchoolConfiguration
	palette: ThemeOutputColors
	initialized: boolean
}

const initialState: TSchoolState = {
	config: getSchoolConfiguration(SCHOOL_KEY, DEFAULT_SCHOOL_CONFIG),
	palette: selectPalette(SCHOOL_KEY),
	initialized: false,
}

export const schoolSlice = createSlice({
	name: FEATURE_NAME,
	initialState: initialState,
	reducers: {
		setConfig: (state, { payload }: PayloadAction<TSchoolConfiguration>) => {
			state.config = payload
		},
		setSchoolData: (state, { payload: { config, theme } }: PayloadAction<DtoSchoolData>) => {
			state.config = config
			state.palette = theme
		},
		resetSchoolData: () => initialState,
	},
})

export const { setConfig, setSchoolData, resetSchoolData } = schoolSlice.actions

const selectState = (state: RootState) => state[FEATURE_NAME]

export const selectSchoolConfig = createSelector(selectState, (state) => state.config)

export const selectSchoolTheme = createSelector(selectState, (state) => state.palette)

export const selectSchoolId = createSelector(
	(state: RootState) => state.AUTH,
	(state) => state.schoolId,
)

export const selectIsNavApp = (state: RootState) => state.AUTH.schoolId === NAV_APP_ID

export const selectIsInitialized = (state: RootState) => state[FEATURE_NAME].initialized

export const selectConfigByPath = createSelector(
	selectSchoolConfig,
	(configState: RootState, path) => path,
	(state: TSchoolConfiguration, path?: Path<TSchoolConfiguration>) => {
		if (path) {
			return nestedValueByPath(path, state)
		} else {
			return state
		}
	},
)

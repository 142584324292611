import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'

import { DotButton, IconButton, IconWrapper, UserRow } from '@/components'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { ReportSubject } from '@/components/ReportModal'
import { STYLES } from '@/constants'
import { DtoComment } from '@/features/posts/comments/types/DtoComment'
import { useCurrentUser } from '@/hooks/userHooks'
import { ReportActions } from '@/interfaces/common'
import { isCurrentUser } from '@/utils/authHandlers'
import { getFromNow } from '@/utils/dateTime2'

import useMentionClickHandler from '../Editor/hooks/useMentionClickHandler'
import { CommentsListProps } from './CommentsList'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
	},
	content: {
		margin: '10px 1.5vw',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'min-content',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	userName: {
		...theme.typography.body1,
		fontWeight: 700,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	userInfoRow: {
		margin: 0,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	headerButtons: {
		height: 'fit-content',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	likeIcon: {
		color: theme.palette.icons.like,
	},
	commentRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '15px 0',
		display: 'flex',
	},
	commentContent: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		marginLeft: 10,
		padding: 20,
		borderRadius: 7,
	},
	likeButton: {
		color: theme.palette.text.secondary,
		'&:hover': {
			color: theme.palette.common.black,
			backgroundColor: theme.palette.specialColors.transparent,
		},
		padding: 0,
		margin: 10,
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	commentText: {
		'&>p': {
			margin: 0,
		},
		...theme.typography.body1,
	},
	blackTextColor: {
		color: theme.palette.text.primary,
	},
	timeText: {
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
	},
}))

interface CommentsRowProps extends DtoComment {
	onLikeComment: CommentsListProps['toggleLike']
	onDeleteComment: CommentsListProps['deleteComment']
	onReportComment: CommentsListProps['reportComment']
}

const CommentsRow = ({
	id,
	message,
	user,
	createdAt,
	numberOfLikes,
	liked,
	onLikeComment,
	onDeleteComment,
	onReportComment,
}: CommentsRowProps) => {
	const classes = useStyles()

	const appTheme = useTheme()

	const currentUser = useCurrentUser()

	const canDeleteComment = isCurrentUser(currentUser?.id, user?.id)

	const handleLikeComment = useCallback(() => {
		onLikeComment(id)
	}, [id, onLikeComment])

	const handleReportComment = useCallback(
		(_: string, status: ReportActions, reportMessage: string) => {
			onReportComment({ commentId: id, response: status, reportMessage })
		},

		[id, onReportComment],
	)

	const actionButtons = useMemo(() => {
		const buttons = []

		if (canDeleteComment) {
			buttons.push({
				label: 'Delete comment',
				onClick: () => onDeleteComment(id),
				icon: null,
			})
		}

		return buttons
	}, [canDeleteComment, onDeleteComment, id])
	const contentRef = useMentionClickHandler(message)
	return (
		<div className={classes.commentRow}>
			<LazyLoadProfileImage className={classes.profileImage} externalUserId={user.externalId || ''} photoUrl={user.photoUrl} />
			<div className={classes.contentContainer}>
				<div className={classes.commentContent}>
					<div className={classes.header}>
						<UserRow
							classnames={{
								root: classes.userInfoRow,
								userName: classes.userName,
								info: classes.greyText,
							}}
							user={user}
							showProfilePhoto={false}
						/>
						<div className={classes.headerButtons}>
							<Typography className={classes.timeText}>{getFromNow(createdAt)}</Typography>
							<DotButton
								variant="secondary"
								className={classes.moreButton}
								renderButtons={actionButtons}
								itemId={id}
								onSubmitReport={handleReportComment}
								reportSubject={ReportSubject.Comment}
								showReportButton={!canDeleteComment}
							/>
						</div>
					</div>
					<div ref={contentRef}>
						<DescriptionHtml className={classes.commentText} description={message} />
					</div>
				</div>
				<div>
					<IconButton
						className={classNames(classes.likeButton, liked ? classes.blackTextColor : '')}
						endIcon={
							<IconWrapper
								iconKey="heart"
								color={liked ? appTheme.colors.red[500] : appTheme.palette.grey[400]}
								weight={liked ? 'fill' : 'regular'}
							/>
						}
						onClick={handleLikeComment}
					>
						{`${liked ? 'Liked' : 'Like'}`} {numberOfLikes}
					</IconButton>
				</div>
			</div>
		</div>
	)
}

export default CommentsRow

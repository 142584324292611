import { EventsState } from '@/store/events/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/events/constants'
import { SearchConfig } from '@/interfaces/common'

const selectState = (state: { [MODULE_NAME]: EventsState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getIsLoadingUserEvents = createSelector(selectState, (state) => state.isLoadingUserEvents)
export const getIsLoadingIndividualEvent = createSelector(selectState, (state) => state.isLoadingIndividualEvent)

export const getEvents = createSelector(selectState, (state) => state.events)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)
export const getCategories = createSelector(selectState, (state) => state.categories)
export const getUserCreatedEvents = createSelector(selectState, (state) => state.userEvents.createdByUser)
export const getUserGoingEvents = createSelector(selectState, (state) => state.userEvents.going)
export const getUserInterestedEvents = createSelector(selectState, (state) => state.userEvents.interested)
export const getUserNotInterestedEvents = createSelector(selectState, (state) => state.userEvents.notInterested)
export const getUserPastEvents = createSelector(selectState, (state) => state.userEvents.past)
export const getSelectedEvent = createSelector(selectState, (state) => state.selectedEvent)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)
export const getAddToCalendarModal = createSelector(selectState, (state) => state.addToCalendarModal || {})
export const getNotInterestedEventPopup = createSelector(selectState, (state) => state.isOpenNotInterestedEventModal)
export const getEventById = (id?: string) => createSelector(selectState, (state) => state.events.find((event) => event.id === id))
export const getCreateEventDefaultValues = createSelector(selectState, (state) => state.createEventDefaultValues)

export const selectUpcomingEventsInfo = createSelector(selectState, (state) => {
	const {
		userEvents: { going, interested },
	} = state
	const upcomingEvents = going.concat(interested)
	return {
		upcomingEvent: upcomingEvents.length > 0 ? upcomingEvents[0] : null,
		total: upcomingEvents.length,
	}
})

import http from '@/api/http/requestHttp'
import { TJobFilterDto } from '@/store/internships/types'

const getInternshipsFilters = async (schoolId: number) => {
	const url = `${schoolId}/jobs/filters`

	return http.get<TJobFilterDto>(url)
}

export default getInternshipsFilters

import { PATHS } from '@/constants'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import FeedsList from '@/pages/HomePage/FeedsList'
import IndividualPostPage from '@/pages/HomePage/IndividualPostPage'
import AppRoute from '@/interfaces/route'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const routes: AppRoute[] = [
	{
		component: IndividualPostPage,
		title: createPageTitle('Post'),
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.POST_INFO(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: FeedsList,
		name: 'feeds-list',
		path: PATHS.APP.HOME,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

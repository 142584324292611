import * as http from '@/api/http'
import { GroupMemberStatusParam, GroupMemberTypeParam } from '@/api/http/groups/getGroupMembers'
import { ChangeGroupMember } from '@/api/http/groups/patchGroupMember'
import { ChangeGroupMemberRequestStatus } from '@/api/http/groups/patchGroupMemberRequest'
import { GroupInfoData } from '@/components/Messages/Group/ManageCards/GroupInfo'
import { GroupSettingsData } from '@/components/Messages/Group/ManageCards/GroupSettings'
import { PATHS } from '@/constants'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { UserData } from '@/interfaces/common'
import { AppDispatch, RootState } from '@/store'
import { MODULE_NAME } from '@/store/groups/constants'
import { createPhotoUrl } from '@/utils/common'
import uploadPhoto from '@/utils/photoUpload'
import { GroupChatRoles, GroupChatTypes, IGroupChat } from '@navengage/sen-types'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'
import { clearAddNewUserToMessagesListInput, loadChatsListRequest, updateCurrentUserMessageReactions } from '../messages'
import { TReactions } from '../messages/types'
import { getUserInfoRequest } from '../network'
import { GroupListItem, GroupMember, GroupMemberRequest, TGroupGallery } from './types'
import { getNormalizeUser } from '@/utils/transformers'

import { selectAuth } from '../auth'

export const setError = createAction<string>(`${MODULE_NAME}/SET_ERROR`)

export const setShowCreateGroupModal = createAction<boolean>(`${MODULE_NAME}/SET_SHOW_CREATE_GROUP_MODAL`)

export const setTargetGroup = createAction<IGroupChat>(`${MODULE_NAME}/SET_TARGET_GROUP`)
export const updateTargetGroup = createAction<IGroupChat>(`${MODULE_NAME}/UPDATE_TARGET_GROUP`)
export const clearTargetGroup = createAction(`${MODULE_NAME}/CLEAR_TARGET_GROUP`)
export const updateGroupGallery = createAction<Partial<TGroupGallery>>(`${MODULE_NAME}/UPDATE_GROUP_GALLERY`)
export const paginateGroupGallery = createAction<Partial<TGroupGallery>>(`${MODULE_NAME}/PAGINATE_GROUP_GALLERY`)
export const removeGalleryImage = createAction<string>(`${MODULE_NAME}/REMOVE_GALLERY_IMAGE`)

export const setSearchUsers = createAction<{
	page: number
	canLoadMore: boolean
	users: UserData[]
}>(`${MODULE_NAME}/SET_SEARCH_USERS`)
export const clearSearchUsers = createAction(`${MODULE_NAME}/CLEAR_SEARCH_USERS`)

export const setSearchGroups = createAction<{
	page: number
	canLoadMore: boolean
	groups: GroupListItem[]
}>(`${MODULE_NAME}/SET_SEARCH_GROUPS`)
export const setSearchGroupsQuery = createAction<string>(`${MODULE_NAME}/SET_SEARCH_GROUPS_QUERY`)
export const setSearchGroupsCampus = createAction<number | null>(`${MODULE_NAME}/SET_SEARCH_GROUPS_CAMPUS`)
export const updateSearchGroupItem = createAction<string>(`${MODULE_NAME}/UPDATE_SEARCH_GROUP_ITEM`)
export const removeSearchGroupItem = createAction<string>(`${MODULE_NAME}/REMOVE_SEARCH_GROUP_ITEM`)

export const setSearchMembers = createAction<{
	page: number
	canLoadMore: boolean
	members: GroupMember[]
}>(`${MODULE_NAME}/SET_SEARCH_MEMBERS`)
export const removeSearchMemberItem = createAction<string>(`${MODULE_NAME}/REMOVE_SEARCH_MEMBER_ITEM`)
export const clearSearchMembers = createAction(`${MODULE_NAME}/CLEAR_SEARCH_MEMBERS`)

export const setSearchMemberRequests = createAction<{
	page: number
	canLoadMore: boolean
	requests: GroupMemberRequest[]
}>(`${MODULE_NAME}/SET_SEARCH_MEMBER_REQUESTS`)
export const updateSearchMemberRequestItem = createAction<{
	id: string
	status: `${ChangeGroupMemberRequestStatus}`
}>(`${MODULE_NAME}/UPDATE_SEARCH_MEMBER_REQUEST_ITEM`)
export const clearSearchMemberRequests = createAction(`${MODULE_NAME}/CLEAR_SEARCH_MEMBER_REQUESTS`)

export const setMemberToView = createAction<GroupMember | null>(`${MODULE_NAME}/SET_MEMBER_TO_VIEW`)
export const setDrawer = createAction<boolean>(`${MODULE_NAME}/SET_DRAWER`)
export const setAddNewMembersPopup = createAction<boolean>(`${MODULE_NAME}/SET_ADD_NEW_MEMBER`)

//@TODO: will be addressed when we switch to rtk query or react-query etc...
export const createGroupRequest = createAsyncThunk<
	any, //TODO: Should be return type of promise
	{
		groupType?: GroupChatTypes
		info?: GroupInfoData
		settings?: GroupSettingsData
		members?: UserData[]
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_GROUP_REQUEST`, async (data, { dispatch, rejectWithValue, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const imageName = uuidv4()
		const localPhotoUrl = data.info!.thumbnail
		const photoUrl = localPhotoUrl ? createPhotoUrl(imageName, PhotoUploadDirectories.GROUP) : undefined

		const { data: groupData } = await http.groups.createGroup({
			name: data.info!.name,
			description: data.info!.description,
			type: data.groupType!,
			thumbnail: photoUrl,
			restrictedEdit: !!data.settings?.restrictedEdit,
			restrictedMemberManagement: !!data.settings?.restrictedMemberManagement,
			restrictedAdminManagement: !!data.settings?.restrictedAdminManagement,
			invites: data.members!.map((user) => user.id!),
			schoolId,
		})

		if (localPhotoUrl) {
			await uploadPhoto({
				schoolId,
				fileUrl: localPhotoUrl,
				imageName,
				directory: PhotoUploadDirectories.GROUP,
				options: {
					compressImage: true,
					onError: {
						uploadErrors: UploadErrors.GROUP,
						uploadErrorMessage: UploadErrorMessage.GROUP,
					},
				},
			})
		}

		dispatch(push(PATHS.APP.MESSAGES_GROUP(groupData.id)))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	} finally {
		dispatch(setShowCreateGroupModal(false))
	}
})

export const readGroupRequest = createAsyncThunk<
	any, //TODO: Should be return type of promise
	{
		id: string
		clearBeforeLoading?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_GROUP_REQUEST`, async ({ id, clearBeforeLoading }, { dispatch, rejectWithValue, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		if (clearBeforeLoading) dispatch(clearTargetGroup())

		const { data } = await http.groups.getGroupById(id, schoolId)

		dispatch(setTargetGroup(data as IGroupChat))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		dispatch(clearAddNewUserToMessagesListInput())
		return rejectWithValue(e)
	}
})

export const editGroupInfoRequest = createAsyncThunk<
	any, //TODO: Should be return type of promise
	GroupInfoData,
	{
		state: RootState
	}
>(`${MODULE_NAME}/EDIT_GROUP_INFO_REQUEST`, async (data, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id, thumbnail, ...rest } = rootState.GROUPS.targetGroup!
		const imageName = uuidv4()
		const localPhotoUrl = data!.thumbnail
		const photoEdited = localPhotoUrl !== thumbnail
		const photoUrl = photoEdited ? (localPhotoUrl ? createPhotoUrl(imageName, PhotoUploadDirectories.GROUP) : '') : localPhotoUrl

		const newData = {
			name: data.name,
			description: data.description,
			thumbnail: photoUrl,
		}

		// optimistic update
		dispatch(
			updateTargetGroup({
				...rest,
				...newData,
				thumbnail,
			} as IGroupChat),
		)

		const { data: groupData } = await http.groups.patchGroupById(id, newData, schoolId)

		if (photoEdited && localPhotoUrl) {
			await uploadPhoto({
				schoolId,
				fileUrl: localPhotoUrl,
				imageName,
				directory: PhotoUploadDirectories.GROUP,
				options: {
					compressImage: true,
					onError: {
						uploadErrors: UploadErrors.GROUP,
						uploadErrorMessage: UploadErrorMessage.GROUP,
					},
				},
			})
		}

		dispatch(updateTargetGroup(groupData as IGroupChat))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const editGroupSettingsRequest = createAsyncThunk<
	any,
	GroupSettingsData,
	{
		state: RootState
	}
>(`${MODULE_NAME}/EDIT_GROUP_SETTINGS_REQUEST`, async (data, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id } = rootState.GROUPS.targetGroup!

		const { data: groupData } = await http.groups.patchGroupById(
			id,
			{
				restrictedEdit: !!data?.restrictedEdit,
				restrictedMemberManagement: !!data?.restrictedMemberManagement,
				restrictedAdminManagement: !!data?.restrictedAdminManagement,
			},
			schoolId,
		)

		dispatch(updateTargetGroup(groupData as IGroupChat))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const searchUsersToInviteRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
		q?: string
		campusId?: number
		groupId?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SEARCH_USERS_TO_INVITE`, async ({ fetchMore, q, campusId, groupId }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const storedUsers = rootState.GROUPS.searchUsers.users
		const currentPage = rootState.GROUPS.searchUsers.page

		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.groups.searchMembersToNewGroup({
			q,
			page,
			campusId,
			groupId,
			schoolId,
		})

		const newUsers = data.map((user: any) => getNormalizeUser(user, rootState.CAMPUSES.entities))

		const users = fetchMore ? storedUsers.concat(newUsers) : newUsers

		dispatch(setSearchUsers({ page, canLoadMore: !!newUsers.length, users }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const searchGroupsRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SEARCH_GROUPS`, async ({ fetchMore }, { dispatch, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const state = rootState.GROUPS.searchGroups

		const storedGroups = state.groups
		const currentPage = state.page

		const { q, campus: campusId } = state

		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.groups.searchGroups({
			page,
			q,
			campusId,
			schoolId,
		})

		const result = data as GroupListItem[]

		const groups = fetchMore ? storedGroups.concat(result) : result

		dispatch(setSearchGroups({ page, canLoadMore: !!result.length, groups }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
	}
})

export const joinGroupRequest = createAsyncThunk<
	any,
	{
		id: string
		type: `${GroupChatTypes}`
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/JOIN_GROUP`, async ({ id, type }, { dispatch, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		await http.groups.joinGroup(id, schoolId)

		if (type === GroupChatTypes.Public) {
			dispatch(push(PATHS.APP.MESSAGES_GROUP(id)))
			dispatch(removeSearchGroupItem(id))
		} else {
			dispatch(updateSearchGroupItem(id))
		}
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
	}
})

export const inviteUsersToGroupRequest = createAsyncThunk<
	any,
	UserData[],
	{
		state: RootState
	}
>(`${MODULE_NAME}/INVITE_USERS_TO_GROUP`, async (users, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id } = rootState.GROUPS.targetGroup!

		await http.groups.inviteUsersToGroup(
			id,
			{
				invites: users.map(({ id }) => id),
			},
			schoolId,
		)

		dispatch(readGroupRequest({ id }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const readGroupMembersRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
		type?: GroupMemberTypeParam
		status: GroupMemberStatusParam
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_GROUP_MEMBERS`, async ({ fetchMore, type, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id } = rootState.GROUPS.targetGroup!
		const storedMembers = rootState.GROUPS.searchMembers.members
		const currentPage = rootState.GROUPS.searchMembers.page

		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.groups.getGroupMembers({
			id,
			page,
			status,
			type,
			schoolId,
		})

		const members = fetchMore ? storedMembers.concat(data) : data

		dispatch(setSearchMembers({ page, canLoadMore: !!data.length, members }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const showMemberInfoRequest = createAsyncThunk<
	any,
	GroupMember,
	{
		state: RootState
	}
>(`${MODULE_NAME}/SHOW_MEMBER_INFO_REQUEST`, async (member, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(getUserInfoRequest(member.userId))
		dispatch(setMemberToView(member))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const changeMemberRoleRequest = createAsyncThunk<
	any,
	{
		member: GroupMember
		newRole: GroupChatRoles.Admin | GroupChatRoles.Member
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_MEMBER_ROLE_REQUEST`, async ({ member, newRole }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id } = rootState.GROUPS.targetGroup!

		const isAdminRole = newRole === GroupChatRoles.Admin

		await http.groups.patchGroupMember(
			id,
			member.id,
			{
				action: isAdminRole ? ChangeGroupMember.UPGRADE : ChangeGroupMember.DOWNGRADE,
			},
			schoolId,
		)

		dispatch(removeSearchMemberItem(member.id))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const removeMemberFromGroupRequest = createAsyncThunk<
	any,
	GroupMember,
	{
		state: RootState
	}
>(`${MODULE_NAME}/REMOVE_MEMBER_FROM_GROUP_REQUEST`, async (member, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const { id } = rootState.GROUPS.targetGroup!

		await http.groups.patchGroupMember(
			id,
			member.id,
			{
				action: ChangeGroupMember.REMOVE,
			},
			schoolId,
		)

		dispatch(removeSearchMemberItem(member.id))
		dispatch(readGroupRequest({ id }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const changeBlockedMemberRequest = createAsyncThunk<
	any,
	{
		memberId: string
		action: ChangeGroupMember.BLOCK | ChangeGroupMember.UNBLOCK
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_BLOCKED_MEMBER_REQUEST`, async ({ memberId, action }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const { id } = rootState.GROUPS.targetGroup!

		await http.groups.patchGroupMember(id, memberId, { action }, schoolId)

		dispatch(removeSearchMemberItem(memberId))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const readGroupMemberRequestsRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_GROUP_MEMBER_REQUESTS`, async ({ fetchMore }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const { id } = rootState.GROUPS.targetGroup!
		const storedMembers = rootState.GROUPS.searchMemberRequests.requests
		const currentPage = rootState.GROUPS.searchMemberRequests.page

		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.groups.getGroupMemberRequests({
			id,
			page,
			schoolId,
		})

		const requests = fetchMore ? storedMembers.concat(data) : data

		dispatch(setSearchMemberRequests({ page, canLoadMore: !!data.length, requests }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const changeMemberRequestStatusRequest = createAsyncThunk<
	any,
	{
		requestId: string
		status: `${ChangeGroupMemberRequestStatus}`
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_MEMBER_REQUEST_STATUS_REQUEST`, async ({ requestId, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const { id } = rootState.GROUPS.targetGroup!

		dispatch(updateSearchMemberRequestItem({ id: requestId, status }))

		await http.groups.patchGroupMemberRequest(id, requestId, status, schoolId)
		dispatch(readGroupRequest({ id }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const removeOrLeaveGroupRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/REMOVE_OR_LEAVE_GROUP_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const { id } = rootState.GROUPS.targetGroup!

		dispatch(push(PATHS.APP.MESSAGES))

		await http.groups.removeOrLeaveGroup(id, schoolId)
		dispatch(loadChatsListRequest())
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const toggleGroupNotificationsRequest = () => async (dispatch: AppDispatch, getState: () => RootState) => {
	const rootState = getState()
	const { schoolId } = selectAuth(rootState)
	const {
		GROUPS: { targetGroup },
	} = rootState
	try {
		if (targetGroup) {
			dispatch(
				updateTargetGroup({
					...targetGroup,
					isMutedNotifications: !targetGroup?.isMutedNotifications,
				}),
			)
			await http.groups.patchMemberGroupNotifications(
				targetGroup.id,
				{
					isMutedNotifications: !targetGroup?.isMutedNotifications,
				},
				schoolId,
			)
		}
		/**
		 * @TODO: this shouldn't be any
		 * Need to be corrected based on interceptor written in
		 * check api/http/requestHttp.ts
		 * <code>
		 *    extended: get(error, 'response.data.error.extended', {}),
		 *    message: get(error, 'response.data.error.message', error),
		 *    code: get(error, 'response.data.error.errcode', 0),
		 * </code>
		 */
	} catch (e: any) {
		dispatch(setError(e.message.message))
	}
}

export const initGroupGallery =
	(fetchMore = false) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const {
			GROUPS: { targetGroup, gallery },
		} = rootState
		try {
			if (targetGroup) {
				const groupId = targetGroup.id
				dispatch(updateGroupGallery({ isLoading: true }))

				const currentPage = fetchMore ? gallery.page + 1 : 1
				const response = await http.groups.getGroupGallery({
					id: groupId,
					params: {
						page: currentPage,
					},
					schoolId,
				})
				const canLoadMore = response.data.length === 20
				const payload = {
					images: response.data,
					canLoadMore,
					isLoading: false,
					page: currentPage,
				}
				if (fetchMore) {
					dispatch(paginateGroupGallery(payload))
				} else {
					dispatch(updateGroupGallery(payload))
				}
			}
		} catch (e: any) {
			dispatch(setError(e.message.message))
		}
	}

export const removeGroupGalleryImageRequest = (messageId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
	const rootState = getState()
	const { schoolId } = selectAuth(rootState)
	const {
		GROUPS: { targetGroup },
	} = rootState
	try {
		if (targetGroup) {
			await http.groups.removeGroupGalleryImage(targetGroup?.id, messageId, schoolId)
			dispatch(removeGalleryImage(messageId))
		}
	} catch (e: any) {
		dispatch(setError(e.message.message))
	}
}
export const toggleLikeRequest =
	(messageId: string, newReaction: { type: TReactions; reacted: boolean; count: number }, reactions: TReactions[] | undefined) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const { reacted, type } = newReaction
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		dispatch(
			updateCurrentUserMessageReactions({
				messageId,
				type,
				isCurrentUser: true,
				updateType: !reacted ? 'add' : 'remove',
			}),
		)
		if (!reacted) {
			await http.groups.createReaction({ messageId, schoolId })
		} else {
			await http.groups.deleteReaction({ messageId, schoolId })
		}
	}

export const flexStyles = {
	flex: {
		display: 'flex',
	},
	flex0: {
		flex: ' 0 0 auto',
	},
	flex1: {
		flex: '1 1 auto',
	},
	flexCenteredItems: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	flexCenteredItemsY: {
		display: 'flex',
		alignItems: 'center',
	},
}

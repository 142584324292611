import http from '@/api/http/requestHttp'
import { PostResponseStatuses } from '@/interfaces/common'

interface PostInternshipUserResponseProps {
	id: string
	userId: number
	response: PostResponseStatuses
	reportMessage?: string
	schoolId: number
}

const postInternshipUserResponse = async ({ id, userId, response, reportMessage, schoolId }: PostInternshipUserResponseProps) => {
	const url = `${schoolId}/jobs/job/${id}/response`
	const body = { userId, response, reportMessage }

	return http.post(url, body)
}

export default postInternshipUserResponse

import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'

import { CommonSelect } from '@/components/Selects'
import TimeSaverTool from '@/components/TimeSaverTool'

const useStyles = makeStyles((theme) => ({
	shiftsTable: {
		width: '100%',
		color: theme.palette.text.secondary,
	},
	row: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		height: '5vh',
		margin: '0 0 3vh 0',
		'&:first-child': {
			margin: '3vh 0 0 0',
		},
	},
	contentRow: {
		alignItems: 'center',
	},
	cellNumberHeader: {
		width: '43%',
	},
	cellName: {
		width: '17%',
	},
	cellTime: {
		width: '43%',
		marginRight: '7%',
		display: 'flex',
		flexDirection: 'row',
	},
	cellNumber: {
		width: '33%',
		display: 'flex',
		alignItems: 'center',
	},
	startTimeSelect: {
		marginRight: 15,
		width: '45%',
	},
}))

export const ShiftsCreationForm = ({ onChange, value, shiftsNumber = 1, volunteersPerShift = 5, options = [], disabled }: any) => {
	const classes = useStyles()
	const [shiftsValues, setShiftsValues] = useState(value || Array.from({ length: shiftsNumber }).fill({ start: null, end: null }))

	const handleChangeSelect = (index: number, newValue: any, key: 'start' | 'end') => {
		setShiftsValues(shiftsValues.map((val: any, idx: number) => (index === idx ? { ...val, [key]: newValue } : val)))
	}

	useEffect(() => {
		onChange(shiftsValues)
	}, [shiftsValues, onChange])

	useEffect(() => {
		if (shiftsNumber > shiftsValues.length)
			setShiftsValues(
				shiftsValues.concat(
					Array.from({ length: shiftsNumber - shiftsValues.length }).fill({
						start: null,
						end: null,
					}),
				),
			)
		else setShiftsValues(shiftsValues.slice(0, shiftsNumber))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shiftsNumber])

	return (
		<div className={classes.shiftsTable}>
			<div className={classes.row}>
				<span className={classes.cellName}>
					<div>SHIFT</div>
				</span>
				<span className={classes.cellTime}>
					<div>SHIFT TIME</div>
				</span>
				<span className={classes.cellNumberHeader}>
					<div>VOLUNTEERS PER SHIFT</div>
				</span>
			</div>
			{shiftsValues.map((val: any, idx: number) => (
				<Fragment key={idx}>
					<div className={classNames(classes.row, classes.contentRow)}>
						<span className={classes.cellName}>Shift {idx + 1}</span>
						<span className={classes.cellTime}>
							<CommonSelect
								placeholder="Start Time"
								value={val?.start}
								onChange={(v) => handleChangeSelect(idx, v, 'start')}
								options={options}
								classnames={{
									input: classes.startTimeSelect,
								}}
								disabled={disabled}
							/>
							<CommonSelect
								placeholder="End Time"
								value={val?.end}
								onChange={(v) => handleChangeSelect(idx, v, 'end')}
								options={options}
								classnames={{
									input: classes.startTimeSelect,
								}}
								disabled={disabled}
							/>
						</span>
						<span className={classes.cellNumber}>
							<PersonIcon /> {volunteersPerShift}
						</span>
					</div>
					{!idx && val?.start && val?.end && !disabled && (
						<TimeSaverTool onChange={setShiftsValues} values={shiftsValues} options={options} />
					)}
				</Fragment>
			))}
		</div>
	)
}

export default ShiftsCreationForm

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { cloneDeep } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
	ColorButton,
	CommonSelect,
	FieldErrorMessage,
	FormModalCard,
	IconWrapper,
	ImageUploadField,
	Label,
	Modal,
	ModalWithSubmittedPopup,
	TextField,
} from '@/components'
import { ERRORS } from '@/constants'
import useImageUploadError from '@/hooks/useImageUploadError'
import { useAppDispatch } from '@/store'
import { getCategories, updateOrganizationInfoRequest } from '@/store/organizations'
import { OrganizationEditForm } from '@/store/organizations/types'

const useStyles = makeStyles((theme) => ({
	form: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	userImage: {
		width: 152,
		height: 152,
		marginTop: '2vh',
		[theme.breakpoints.up('xl')]: {
			minWidth: 152,
			minHeight: 152,
		},
	},
	formFieldsContainer: {
		width: '100%',
	},
	selectsContainer: {
		width: '60%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	select: {
		width: '48%',
	},
	logoInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	logoInput: {
		marginLeft: -5,
		marginRight: 15,
		width: 25,
		height: 25,
	},
	changeProfilePictureText: {
		margin: '4vh 0',
	},
	textFieldWithoutLabel: {
		margin: '0.5vh 0',
	},
	updateProfileButton: {
		padding: '1vh 1vw',
		minWidth: 250,
		fontWeight: 600,
	},
	popupModalTitle: {
		fontWeight: 700,
		textAlign: 'center',
	},
	popupModalContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '4vh 0',
		textAlign: 'center',
	},
	popupModalFooter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		textAlign: 'center',
	},
	popupList: {
		marginBottom: '4vh',
		alignItems: 'start',
		textAlign: 'left',
	},
	unsavedChangesModalButton: {
		width: '90%',
		margin: '0.5vh 0',
		padding: '1vh 0',
	},
	popupButton: {
		padding: '1vh 3vw',
		fontWeight: 600,
	},
	membershipPopupCard: {
		width: '30vw',
		[theme.breakpoints.down('lg')]: {
			width: '60vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
			minWidth: 300,
		},
	},
	addMembershipButton: {
		width: '100%',
	},
	lockIcon: {
		width: 15,
		marginBottom: -5,
	},
	primaryTitlePopup: {
		marginBottom: '3vh',
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
}))

interface EditOrganizationModalProps {
	isOpen: boolean
	orgInfo: OrganizationEditForm
	onClose: () => void
	dataLake: boolean
	sourceSystem?: string
}

const EditOrganizationModal = ({
	isOpen = false,
	onClose = () => {},
	orgInfo,
	dataLake,
	sourceSystem = 'Org Central',
}: EditOrganizationModalProps) => {
	const classes = useStyles()
	const formRef = useRef(null)
	const dispatch = useAppDispatch()
	const appTheme = useTheme()

	const categories = useSelector(getCategories)

	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

	const validateImageSize = useImageUploadError()

	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, errors },
	} = useForm<any>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: cloneDeep(orgInfo),
	})

	const handleCloseModal = () => {
		if (isDirty) {
			setHasUnsavedChanges(true)
		} else {
			onClose()
		}
	}

	const handleCloseUnsavedChangesModal = () => setHasUnsavedChanges(false)
	const handleSubmitUnsavedChangesModal = () => {
		setHasUnsavedChanges(false)
		onClose()
	}

	const onSubmit = (data: any) => {
		dispatch(updateOrganizationInfoRequest(data))
		onClose()
	}

	useEffect(() => {
		if (isOpen) {
			reset(cloneDeep(orgInfo))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<>
			<ModalWithSubmittedPopup
				isOpen={hasUnsavedChanges}
				onSubmit={handleSubmitUnsavedChangesModal}
				onClose={handleCloseUnsavedChangesModal}
				title="Unsaved Changes"
				content={['Your changes will not be saved.', 'Are you sure you want to cancel?']}
				submitButtonLabel="Yes, cancel"
				cancelButtonLabel="No, keep editing"
				showSubmittedModal={false}
			/>

			<ModalWithSubmittedPopup
				onClose={onClose}
				isOpen={dataLake && isOpen}
				title="Please edit in source system"
				content={[`This data originated in ${sourceSystem}.`, `You may not edit this data, please edit in ${sourceSystem}.`]}
				cancelButtonLabel="Ok"
				cancelButtonColor={appTheme.palette.primary.main}
				showSubmittedModal={false}
			/>

			<Modal isOpen={isOpen && !dataLake} onClose={handleCloseModal}>
				<div className={classes.form}>
					<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
						<FormModalCard
							title="Edit Organization"
							handleClose={handleCloseModal}
							footerContent={
								<ColorButton className={classes.updateProfileButton} disabled={!isDirty} type="submit">
									Save
								</ColorButton>
							}
							showFooter
						>
							<div className={classes.formFieldsContainer}>
								<Controller
									name="name"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<Label classnames={{ label: classes.label }} title="Organization Name" semiBold={true}>
											<TextField placeholder="Organization Name" error={!!errors['name']} {...field} />
										</Label>
									)}
								/>
								<Label
									classnames={{ label: classes.label }}
									title="Categories"
									semiBold={true}
									description="You can select multiple categories"
								>
									<Controller
										name="categories"
										control={control}
										render={({ field: { value, onChange } }) => (
											<CommonSelect
												classnames={{
													input: classes.select,
												}}
												value={value}
												onChange={onChange}
												options={categories}
												placeholder="Selected"
												multiple
												renderTags
											/>
										)}
									/>
								</Label>

								<Controller
									name="description"
									control={control}
									render={({ field }) => (
										<Label classnames={{ label: classes.label }} title="Description" semiBold={true}>
											<TextField multiline rows={10} placeholder="Add a description" error={!!errors['description']} {...field} />
										</Label>
									)}
								/>
								<Label classnames={{ label: classes.label }} title="Social Media" semiBold={true}>
									<Controller
										name="websiteUrl"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<div className={classes.logoInputContainer}>
												<IconWrapper iconKey="link" color={appTheme.palette.text.primary} className={classes.logoInput} />
												<TextField
													placeholderColor={appTheme.palette.text.secondary}
													placeholder="Add Website URL"
													error={!!errors['websiteUrl']}
													{...field}
												/>
											</div>
										)}
									/>
									<Controller
										name="linkedinUrl"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<div className={classes.logoInputContainer}>
												<IconWrapper iconKey="socialMediaLinkedIn" color={appTheme.palette.text.primary} className={classes.logoInput} />
												<TextField
													placeholderColor={appTheme.palette.text.secondary}
													placeholder="Add LinkedIn URL"
													error={!!errors['linkedinUrl']}
													{...field}
												/>
											</div>
										)}
									/>
									<Controller
										name="instagramUrl"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<div className={classes.logoInputContainer}>
												<IconWrapper iconKey="socialMediaInstagram" color={appTheme.palette.text.primary} className={classes.logoInput} />

												<TextField
													placeholderColor={appTheme.palette.text.secondary}
													placeholder="Add Instagram URL"
													error={!!errors['instagramUrl']}
													{...field}
												/>
											</div>
										)}
									/>
									<Controller
										name="twitterUrl"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<div className={classes.logoInputContainer}>
												<IconWrapper iconKey="socialMediaTwitter" color={appTheme.palette.text.primary} className={classes.logoInput} />
												<TextField
													placeholderColor={appTheme.palette.text.secondary}
													placeholder="Add Twitter URL"
													error={!!errors['twitterUrl']}
													{...field}
												/>
											</div>
										)}
									/>
									<Controller
										name="facebookUrl"
										control={control}
										defaultValue=""
										render={({ field }) => (
											<div className={classes.logoInputContainer}>
												<IconWrapper iconKey="socialMediaFacebook" color={appTheme.palette.text.primary} className={classes.logoInput} />
												<TextField
													placeholderColor={appTheme.palette.text.secondary}
													placeholder="Add Facebook URL"
													error={!!errors['facebookUrl']}
													{...field}
												/>
											</div>
										)}
									/>
								</Label>

								<Controller
									name={'photoUrl'}
									control={control}
									defaultValue=""
									render={({ field: { onChange, value } }) => (
										<Label
											classnames={{ label: classes.label }}
											title="Upload a Photo"
											description="Please be aware that your photo will be public."
										>
											<ImageUploadField maxWidth={400} onChange={onChange} value={value} validateImageSize={validateImageSize} />
											<FieldErrorMessage hasError={!!errors['photoUrl']} message={ERRORS.requiredField} />
										</Label>
									)}
								/>
							</div>
						</FormModalCard>
					</form>
				</div>
			</Modal>
		</>
	)
}

export default EditOrganizationModal

import { STYLES } from '@/constants'
import { Paper } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

type StyleProps = {
	height?: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ height }) => ({
		position: 'fixed',
		zIndex: 10,
		top: 0,
		left: 0,
		width: '100%',
		borderRadius: 0,
		height: `${height ?? STYLES.HEADER_HEIGHT}vh`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.33)' : theme.shadows[15],
	}),
	left: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	content: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	// TODO: header should have exact same container with same paddings as content
	// TODO: general refactor is needed
	logo: ({ height }) => ({
		marginTop: '0.15vw',
		marginLeft: '1.3vw',
		height: '100%',
		cursor: 'pointer',
		maxWidth: `${2 * (height ?? STYLES.HEADER_HEIGHT)}vh`,
	}),
}))

export interface HeaderProps extends StyleProps {
	logoSrc: string
	onClickLogo?: (e: React.SyntheticEvent<HTMLDivElement>) => void
	children?: React.ReactNode
	logoAlt?: string
}

export const Header = ({ height, onClickLogo, children, logoSrc, logoAlt }: HeaderProps) => {
	const classes = useStyles({ height })

	return (
		<Paper elevation={15} className={classes.root}>
			<div onClick={onClickLogo} className={classes.left}>
				<img className={classes.logo} src={logoSrc} alt={logoAlt} />
			</div>
			<div className={classes.content}>{children}</div>
		</Paper>
	)
}

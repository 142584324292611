import ColorButton from '@/components/Buttons/ColorButton'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { formatDateNew } from '@/utils/dateTime2'
import { Button, Popper } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { DatePicker } from './DatePicker'

interface StyleProps {
	variant: 'primary' | 'secondary'
	isOpen: boolean
	hasValue: boolean
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: {
		width: '100%',
	},
	selectButton: ({ variant, isOpen, hasValue, error }) => {
		const borderColor = error ? theme.palette.error.main : theme.palette.divider

		return {
			width: '100%',
			color: error ? theme.palette.error.main : theme.palette.text.primary,
			backgroundColor: isOpen
				? variant === 'primary'
					? theme.palette.background.paper
					: theme.palette.background.default
				: variant === 'primary'
				? hasValue
					? theme.palette.background.paper
					: theme.palette.background.default
				: theme.palette.background.default,
			border: (isOpen && variant === 'primary') || !!error ? `solid 1px ${borderColor}` : 'none',
			padding: '10px 20px',
			textTransform: 'none',
			boxShadow: 'none',
			'& >span': {
				justifyContent: 'space-between',
			},
			...theme.typography.body1,
			[theme.breakpoints.down('xs')]: {
				minWidth: 100,
				minHeight: 36,
			},
		}
	},
	paper: {
		zIndex: 1300,
		boxShadow: theme.palette.type === 'light' ? '0px 0px 8px 4px rgba(0,0,0,0.13)' : theme.shadows[8],
		backgroundColor: theme.palette.background.paper,
	},

	presentButton: {
		margin: '5%',
		width: '90%',
	},
}))

interface DatePickerSelectProps {
	className?: string
	defaultLabel?: string
	value?: Date
	onChange: (date: Date | null) => void
	disablePast?: boolean
	minDate?: any
	maxDate?: any
	variant?: 'primary' | 'secondary'
	showPresentButton?: boolean
	disabled?: boolean
	error?: boolean
	allowSameDate?: boolean
	closeOnSelect?: boolean
}

const getLabel = (value: any, allowSameDate?: boolean) => {
	const isPresent = value === null || (formatDateNew(new Date()) === formatDateNew(value) && !allowSameDate)
	return value || value === null ? (isPresent ? 'Present' : formatDateNew(value)) : ''
}

export const DatePickerSelect = ({
	className = '',
	value,
	defaultLabel = '',
	onChange = (v) => {},
	disablePast = false,
	minDate = '',
	maxDate = '',
	variant = 'primary',
	showPresentButton,
	disabled,
	error,
	allowSameDate,
	closeOnSelect,
}: DatePickerSelectProps) => {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const label = getLabel(value, allowSameDate)

	const classes = useStyles({ variant, isOpen: open || !!label, hasValue: !!label, error, disabled })

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	useOutsideClickCallback(popperRef, handleClose)

	const minimumDate = minDate || disablePast ? DateTime.fromISO(minDate || new Date().toISOString()).toJSDate() : undefined
	const maximumDate = maxDate ? DateTime.fromISO(maxDate).toJSDate() : DateTime.fromJSDate(new Date()).plus({ years: 20 }).toJSDate()

	const handleChangeDate = (date: any) => {
		onChange(date)
		if (closeOnSelect) setOpen(false)
	}

	const handleClickPresent = () => {
		onChange(null)
		if (closeOnSelect) setOpen(false)
	}

	return (
		<div className={classNames(classes.root, className)}>
			<Button
				ref={anchorRef}
				className={classes.selectButton}
				endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
				onClick={handleToggle}
				disabled={disabled}
			>
				{label || defaultLabel}
			</Button>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined}>
				<DatePicker selected={value} onChange={handleChangeDate} minDate={minimumDate} maxDate={maximumDate} />
				{showPresentButton && (
					<ColorButton className={classes.presentButton} onClick={handleClickPresent}>
						Present
					</ColorButton>
				)}
			</Popper>
		</div>
	)
}

import ColorButton from '@/components/Buttons/ColorButton'
import Card from '@/components/Card'
import { TErrorResponse } from '@/utils/rtkQueryUtils'
import { Theme, Typography, makeStyles } from '@material-ui/core'

import React, { CSSProperties } from 'react'
import { useHistory } from 'react-router-dom'

type StyledProps = {
	width?: CSSProperties['width']
	height?: CSSProperties['height']
}

const useStyles = makeStyles<Theme, StyledProps>((theme) => ({
	root: ({ height, width }) => ({
		width: width || '100vw',
		minHeight: height || '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default,
	}),
	titleText: {
		fontWeight: 700,
		margin: '4vh 12vw 1vh 12vw',
		color: theme.palette.primary.main,
	},
	contentText: {
		color: theme.palette.text.secondary,
		marginBottom: '7vh',
	},
}))

export type IErrorResponseCardProps = {
	context?: string
	showLogo?: boolean
	showButton?: boolean
	buttonText?: string
	buttonPath?: string
	error: TErrorResponse | null
} & StyledProps

const ERROR_TEMPLATE_MAPPER: Record<number, (error: TErrorResponse & { context: string }) => React.ReactNode> = {
	400: () => 'BAD REQUEST',
	403: () => 'ACCESS DENIED',
	404: ({ context }) => `${context} NOT FOUND`,
	500: () => 'SOMETHING WENT WRONG',
}

const ErrorResponseCard: React.FC<IErrorResponseCardProps> = ({
	context = 'PAGE',
	showButton = true,
	showLogo = false,
	buttonText = 'Go back',
	buttonPath,
	children,
	error,
	height,
	width,
}) => {
	const { status } = error
	const classes = useStyles({ width, height })
	const history = useHistory()
	const onButtonClick = () => {
		if (buttonPath) {
			history.push(buttonPath)
		} else {
			history.goBack()
		}
	}
	const template = ERROR_TEMPLATE_MAPPER[status]({ context, ...error }) || ERROR_TEMPLATE_MAPPER[500]
	return (
		<div className={classes.root}>
			<Card showIcon={showLogo}>
				<Typography className={classes.titleText} variant="h1">
					{status}
				</Typography>
				<Typography className={classes.contentText} variant="h3">
					{template}
				</Typography>
				{children}
				{showButton && <ColorButton onClick={onButtonClick}>{buttonText}</ColorButton>}
			</Card>
		</div>
	)
}

export default ErrorResponseCard

import imageCompression from 'browser-image-compression'
import { DragEvent } from 'react'
import { FILE_FORMATS, FILE_SIZES } from '../constants'

export const preventDefaults = (e: DragEvent<HTMLDivElement>) => {
	e.preventDefault()
	e.stopPropagation()
}

export const handleDrop = (e: DragEvent<HTMLDivElement>): FileList => {
	preventDefaults(e)
	const dt = e.dataTransfer
	const { files } = dt
	return files
}

export const getLocalFileByUrl = async (url: string, name: string, compressImage?: boolean): Promise<File> => {
	const response = await fetch(url)
	const data = await response.blob()
	const metadata = {
		type: FILE_FORMATS.imageJpeg,
	}
	const file = new File([data], name, metadata)

	if (compressImage) {
		const compressedFileBlob = await imageCompression(file, {
			maxSizeMB: FILE_SIZES.maxImageCompressionSizeMB,
			maxWidthOrHeight: FILE_SIZES.maxImageCompressionWidthOrHeight,
		})

		const compressedFile = new File([compressedFileBlob], compressedFileBlob.name, {
			type: compressedFileBlob.type,
		})

		return compressedFile
	}

	return file
}

import { buildApiMethod } from '@/api/http/requestHttp'
import { selectSchoolId } from '@/features/school/slice'

import { AxiosError, AxiosRequestConfig } from 'axios'
import { Merge } from 'type-fest'

export type TErrorResponse = {
	code: string
	status: number
	data: any
	statusText: string
}

export const generateQueryParams = (params: Record<string, any>) => {
	const query = new URLSearchParams()

	Object.entries(params).forEach(([k, v]) => {
		if (Array.isArray(v)) {
			v.forEach((vv) => query.append(k, vv))
		} else if (v) {
			query.append(k, v as string)
		}
	})
	return query.toString()
}

const mutationMethods = ['POST', 'PATCH', 'PUT']

export const axiosBaseQuery =
	(BASE_URL: string, includeSchoolId: boolean = false) =>
	async (params: Merge<AxiosRequestConfig, { body?: Record<string, any> }>, { getState }) => {
		const state = getState()
		const schoolId = selectSchoolId(state)
		if (mutationMethods.includes(params.method) && params.body) {
			params.data = params.body
		}
		try {
			const axios = buildApiMethod(`${BASE_URL}${includeSchoolId ? schoolId : ''}`)
			const result = await axios(params)
			return { data: result.data }
		} catch (axiosError) {
			const err = axiosError as AxiosError

			return {
				error: {
					code: err.code,
					status: err.response?.status,
					data: err.response?.data || err.message,
					statusText: err.response?.statusText,
				},
			}
		}
	}

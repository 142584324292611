import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
	card: {
		width: '50vw',
		padding: '3vh 0',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			width: '74vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '94vw',
		},
		margin: '2vh 0',
	},
	cardHeader: {
		color: theme.palette.text.primary,
		width: '92%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: `solid 1px ${theme.palette.divider}`,
		padding: '0 4% 2vh 4%',
		marginBottom: '2vh',
		[theme.breakpoints.down('xs')]: {
			width: '82%',
			padding: '0 8% 2vh 8%',
		},
	},
	cardFooter: {
		width: '92%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		padding: '3vh 4% 0 4%',
		marginTop: '2vh',
		[theme.breakpoints.down('xs')]: {
			width: '82%',
			padding: '3vh 8% 0 8%',
		},
	},
	headerText: {
		width: '98%',
		fontWeight: 700,
		textAlign: 'center',
	},
	headerCloseIcon: {
		width: '2%',
		cursor: 'pointer',
	},
	content: {
		width: '46vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 2vw',
		[theme.breakpoints.down('md')]: {
			width: '70vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '85vw',
			padding: '0 6vw',
		},
	},
}))

interface ModalCardProps {
	id?: string
	title?: string
	handleClose?: () => void
	children: React.ReactNode
	showFooter?: boolean
	footerContent?: React.ReactNode
}

export const FormModalCard = ({
	children,
	title = '',
	handleClose = () => {},
	id,
	showFooter = false,
	footerContent = null,
}: ModalCardProps) => {
	const classes = useStyles()

	return (
		<div id={id} className={classes.card}>
			{title && (
				<div className={classes.cardHeader}>
					<div className={classes.headerText}>{title}</div>
					<div onClick={handleClose} className={classes.headerCloseIcon}>
						<CloseIcon />
					</div>
				</div>
			)}
			<div className={classes.content}>{children}</div>
			{showFooter && <div className={classes.cardFooter}>{footerContent}</div>}
		</div>
	)
}

export default FormModalCard

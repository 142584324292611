const ROOT = 'organizations'

const ORGANIZATIONS = {
	ROOT,
	MY: `${ROOT}/my`,
	// @TODO: #uuid type conflict
	SINGLE: (id?: string | number) => `${ROOT}/organization/${id !== undefined ? id : ':id'}`,
}

export default ORGANIZATIONS

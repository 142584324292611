import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useCallback } from 'react'

import { IconWrapper, InfoModalCard, Modal } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	doneText: {
		margin: '10px 0',
		textAlign: 'center',
		fontWeight: 600,
	},
	submitText: {
		margin: '10px 0',
		textAlign: 'center',
	},
	text: {
		width: 300,
	},
}))

interface DeleteConfirmationProps {
	isOpenModal: boolean
	handleCloseModal: (e: any) => void
	message: string
}

const DeleteConfirmation = ({ isOpenModal = false, handleCloseModal = () => {}, message }: DeleteConfirmationProps) => {
	const classes = useStyles()
	const theme = useTheme()

	const onClose = useCallback(
		(e: any) => {
			handleCloseModal(e)
		},
		[handleCloseModal],
	)

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<InfoModalCard
				onClose={onClose}
				icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
			>
				<Typography className={classes.doneText}>Done.</Typography>
				<Typography className={classNames(classes.submitText, classes.text)}>{message}</Typography>
			</InfoModalCard>
		</Modal>
	)
}

export default DeleteConfirmation

import MenuIconButton from '@/components/Buttons/IconButton'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { UserData } from '@/interfaces/common'
import { MinimalGroupChatInfo } from '@/store/groups/types'
import { getFromNow } from '@/utils/dateTime2'
import { toText } from '@/utils/toHTML'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { CounterBadge } from '../CounterBadge'
import GroupImage from './Group/GroupImage'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	informationContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
		marginLeft: 20,
	},
	image: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	message: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	userName: {
		color: theme.palette.text.primary,
		fontWeight: 500,
	},
	text: {
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
		fontSize: 16,
		textAlign: 'left',
		textTransform: 'none',
		maxWidth: 260,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '70vw',
		},
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	optionButton: {
		padding: '5px 7%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		color: theme.palette.text.primary,
		borderRadius: 0,
		fontSize: 15,
	},
	selectedOptionButton: {
		backgroundColor: theme.colors.grey[200],
	},
	time: {
		marginLeft: 4,
		fontSize: 16,
		whiteSpace: 'nowrap',
	},
	notificationDot: {
		width: 10,
		height: 10,
		backgroundColor: theme.colors.pink[500],
		border: `solid 1px ${theme.palette.common.white}`,
		borderRadius: '50%',
		position: 'absolute',
		top: -5,
		right: -5,
	},
	groupImage: {
		position: 'relative',
	},
}))

interface ChatListItemProps {
	group: MinimalGroupChatInfo | null
	user: UserData | null
	message?: any
	onClick?: (e: any) => void
	unviewed: number
	selected?: boolean
}

const ChatListItem = ({ group, user, message, unviewed, onClick = () => {}, selected }: ChatListItemProps) => {
	const classes = useStyles()

	const title = user?.fullName ?? group?.name

	return (
		<MenuIconButton
			className={classNames(classes.optionButton, classes.root, selected ? classes.selectedOptionButton : '')}
			onClick={onClick}
		>
			{user && (
				<CounterBadge badgeContent={unviewed}>
					<LazyLoadProfileImage
						borderRadius={7}
						className={classes.image}
						externalUserId={user.externalId || ''}
						photoUrl={user.photoUrl}
					/>
				</CounterBadge>
			)}
			{group && (
				<CounterBadge badgeContent={unviewed}>
					<div className={classNames(classes.groupImage, classes.image)}>
						<GroupImage url={group.thumbnail} groupName={group.name} borderRadius={7} />
					</div>
				</CounterBadge>
			)}
			<div className={classes.informationContainer}>
				<Typography className={classNames(classes.userName, classes.text)} variant="subtitle1">
					{title}
				</Typography>
				{message && (
					<div className={classes.message}>
						<Typography className={classNames(classes.text, message.viewed ? classes.greyText : '')}>
							{toText(message.message) || 'Sent an attachment.'}
						</Typography>
						<Typography className={classNames(classes.time, classes.greyText)}>{getFromNow(message.createdAt)}</Typography>
					</div>
				)}
			</div>
		</MenuIconButton>
	)
}

export default ChatListItem

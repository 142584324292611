import http from '@/api/http/requestHttp'

const postVolunteerResponse = async (eventVolunteerShiftId: string, userId: number, schoolId: number) => {
	const url = `${schoolId}/events/${eventVolunteerShiftId}/volunteerResponse`
	const body = { userId, eventVolunteerShiftId }

	return http.post(url, body)
}

export default postVolunteerResponse

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { DtoGroupMember } from '@navengage/sen-types'

export enum GroupMemberTypeParam {
	ADMIN = 'admin',
	MEMBER = 'member',
}

export enum GroupMemberStatusParam {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

interface SearchParams {
	id: string
	status: `${GroupMemberStatusParam}`
	type?: `${GroupMemberTypeParam}`
	page: number
	schoolId?: number
}

const getGroupMembers = async ({ id, status, type, page, schoolId }: SearchParams) => {
	const url = `${schoolId}/groups/${id}/members`

	const params = {
		type,
		page,
		status,
		limit: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get<DtoGroupMember[]>(url, { params })
}

export default getGroupMembers

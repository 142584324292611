import CardLayout from '@/components/CardLayout'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { useCurrentUser } from '@/hooks/userHooks'
import NoMatch from '@/pages/NoMatch'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import { getIsLoading, loadUserConstantsRequest } from '@/store/auth'
import { getUserRole } from '@/utils/authHandlers'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const SignUp = () => {
	const isLoading = useSelector(getIsLoading)
	const currentUser = useCurrentUser()
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(loadUserConstantsRequest())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const userCompletedSignup = currentUser?.acceptedTermsConditions && !!getUserRole(currentUser?.appUserTypeId)

		if (!isLoading && userCompletedSignup) {
			push(PATHS.APP.ROOT)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, currentUser])

	return (
		<CardLayout>
			<Router
				routes={ROUTES.signUp}
				redirectPaths={[
					{
						from: PATHS.SIGN_UP.STEPS,
						to: PATHS.SIGN_UP.ROOT,
					},
				]}
				noMatch={<NoMatch />}
			/>
		</CardLayout>
	)
}

export default SignUp

import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PATHS, STYLES } from '@/constants'
import SettingsNav, { SettingsNavigation } from '@/components/SettingsSidebar/SettingsNav'
import { Typography } from '@material-ui/core'
import { openAboutAppPage, submitFeedback } from '@/utils/services'
import { isEngage } from '@/constants/configuration'

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: STYLES.FILTER.XL,
		maxWidth: STYLES.FILTER.XL,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.FILTER.LG,
			maxWidth: STYLES.FILTER.LG,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: STYLES.FILTER.SM,
			maxWidth: 'none',
		},
		borderRight: `solid 1.5px ${theme.palette.border.bold}`,
	},
	container: {
		position: 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: STYLES.FILTER.XL,
		maxWidth: STYLES.FILTER.XL,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.FILTER.LG,
			maxWidth: STYLES.FILTER.LG,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: STYLES.FILTER.SM,
			maxWidth: 'none',
			paddingBottom: 20,
		},
	},
	header: {
		fontWeight: 700,
		padding: '3vh 5%',
	},
}))

const settingsNavigation: SettingsNavigation[] = [
	{
		label: 'Privacy',
		subs: [
			{
				label: 'Blocked Accounts',
				path: PATHS.APP.SETTINGS_BLOCKED_ACCOUNTS,
			},
			{
				label: 'Profile',
				path: PATHS.APP.SETTINGS_PROFILE,
			},
		],
	},
	{
		label: 'Notifications',
		path: PATHS.APP.SETTINGS_NOTIFICATIONS,
	},
	{
		label: 'Help & Feedback',
		onClick: submitFeedback,
	},
	...(isEngage()
		? []
		: [
				{
					label: 'About',
					onClick: openAboutAppPage,
				},
		  ]),
]

const SettingsSidebar = () => {
	const classes = useStyles()

	const [activeNavIndex, setActiveNavIndex] = useState(0)

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography className={classes.header} variant="h1">
					Settings
				</Typography>
				{settingsNavigation.map((nav, index) => (
					<SettingsNav key={nav.label} navigation={nav} activeNavIndex={activeNavIndex} index={index} handleChangeNav={setActiveNavIndex} />
				))}
			</div>
		</div>
	)
}

export default SettingsSidebar

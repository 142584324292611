import { makeStyles } from '@material-ui/core/styles'
import ColorButton from '@/components/Buttons/ColorButton'
import { PATHS } from '@/constants'
import Card from '@/components/CommonCard'
import { Typography } from '@material-ui/core'
import { NetworkUserInfo, PostActivity } from '@/interfaces/user'
import { ConnectivityStatus } from '@/interfaces/common'
import { PostActivityState } from '@/store/types'
import { useHistory } from 'react-router'
import FeedsActivityRow from '@/components/Feeds/FeedsActivityRow'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'

const useStyles = makeStyles((theme) => ({
	greyText: {
		color: theme.palette.text.secondary,
	},
	container: {
		margin: '15px 0',
	},
	divider: {
		borderBottom: `solid 1px ${theme.palette.divider}`,
		width: '40%',
		margin: '10px 0 15px 0',
	},
	noActivityPlaceholderContainer: {
		width: '100%',
	},
	noActivityPlaceholderSection: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	button: {
		minWidth: 'fit-content',
		padding: '10px 2vw',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'space-evenly',
		margin: 0,
	},
}))
interface ActivityTabProps {
	userInfo?: NetworkUserInfo
	activity?: PostActivityState
	handleLoadUserActivity?: () => void
	isMyProfile: boolean
}

const ActivityTab = ({ userInfo, activity, handleLoadUserActivity = () => {}, isMyProfile }: ActivityTabProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const isUserConnected = userInfo?.connectionStatus === ConnectivityStatus.ACCEPTED
	const hasActivity = !!activity?.list?.length

	const showActivityList = (isMyProfile || isUserConnected) && hasActivity
	const showConnectWithUserPlaceholder = !isMyProfile && !isUserConnected
	const showNoActivityPlaceholder = (isMyProfile || isUserConnected) && !hasActivity

	const handleOpenFeedsPage = () => push(PATHS.APP.HOME)
	const {
		school: { shortName },
	} = useSchoolConfig()

	return (
		<Card title="Activity">
			<div className={classes.container}>
				{showConnectWithUserPlaceholder && <Typography>Connect with this user to see their activity on the feed.</Typography>}
				{showNoActivityPlaceholder && (
					<>
						{isMyProfile ? (
							<div className={classes.noActivityPlaceholderContainer}>
								<div className={classes.noActivityPlaceholderSection}>
									<Typography>See what people are talking about in the {shortName} community.</Typography>
									<ColorButton className={classes.button} onClick={handleOpenFeedsPage}>
										Explore Feeds
									</ColorButton>
								</div>
								<div className={classes.divider} />
								<Typography className={classes.greyText}>Your activity on the feed will appear here.</Typography>
							</div>
						) : (
							<Typography className={classes.greyText}>No feed activity.</Typography>
						)}
					</>
				)}
				{showActivityList && (
					<ItemsSection
						classnames={{
							content: classes.cardContainer,
						}}
						handleLoadMoreItems={handleLoadUserActivity}
						items={activity!.list!}
						isLoading={!!activity.isLoading}
						canLoadMore={!activity.endHasBeenReached}
						renderItems={(items: PostActivity[]) =>
							items.map((postActivity) => (
								<FeedsActivityRow key={`activity-post-${postActivity.id}`} userInfo={userInfo!} activity={postActivity} />
							))
						}
					/>
				)}
			</div>
		</Card>
	)
}

export default ActivityTab

import RoutePath from '@penname/routepath'

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { IdInt } from '@/lib/types'

interface SearchPostsParams {
	page: number
	userId: IdInt
	campusId?: IdInt | null
	tags?: number[]
	usersAudience?: number
	campusAll?: boolean
	scheduled?: boolean
	perPage?: number
	schoolId: number
	limit: number
}

const searchPosts = async ({ limit, schoolId, ...buildUrlParams }: SearchPostsParams) => {
	const url = searchPosts.buildUrl({
		...buildUrlParams,
		schoolId,
		limit,
	})

	return http.get(url)
}

searchPosts.buildUrl = ({
	userId,
	page,
	campusId,
	tags,
	usersAudience,
	campusAll,
	scheduled,
	limit,
	schoolId,
}: Omit<SearchPostsParams, 'perPage'> & { limit?: number }) => {
	limit ||= settings.SEARCH_FEEDS_LIST_PER_PAGE

	const route = RoutePath.instance({ path: `${schoolId}/feeds/:userId/search`, baseUrl: '/' })

	const queryParams = { page, campusId, tags, usersAudience, campusAll, perPage: limit, scheduled }
	if (!campusId) {
		delete queryParams.campusId
	}

	if (!scheduled) {
		delete queryParams.scheduled
	}

	return route.buildPath({ params: { userId }, query: queryParams })
}

export default searchPosts

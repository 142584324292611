import { SvgIcon } from '@material-ui/core'

const AdminIcon = (props: any) => {
	return (
		<SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width={29} height={29} viewBox="0 0 24 24" {...props}>
			<path d="M12 3a4 4 0 0 0-4 4 4 4 0 0 0 4 4 4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 11c-3.141 0-9 1.545-9 4.5V21h9.295a6.968 6.968 0 0 1 1.658-6.844A13.706 13.706 0 0 0 12 14zm6.064 0a.254.254 0 0 0-.252.225l-.117 1.011c-.484.168-.92.423-1.3.752l-.938-.406a.251.251 0 0 0-.318.107l-.938 1.622a.254.254 0 0 0 .069.33l.806.597A4.082 4.082 0 0 0 15 19c0 .26.028.513.076.76l-.805.6a.253.253 0 0 0-.068.33l.936 1.62a.253.253 0 0 0 .32.106l.936-.404c.379.328.816.584 1.3.752l.117 1.011a.252.252 0 0 0 .252.225h1.872a.254.254 0 0 0 .252-.225l.117-1.011c.484-.168.92-.423 1.3-.752l.938.406a.252.252 0 0 0 .318-.107l.938-1.622a.254.254 0 0 0-.069-.33l-.806-.6c.048-.246.076-.499.076-.759s-.028-.513-.076-.76l.805-.6a.253.253 0 0 0 .068-.33l-.936-1.62a.253.253 0 0 0-.32-.106l-.936.404a3.974 3.974 0 0 0-1.3-.752l-.117-1.011a.252.252 0 0 0-.252-.225h-1.872zM19 17.25a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5z" />
		</SvgIcon>
	)
}

export default AdminIcon

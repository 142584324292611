import { createNumberOptionsArray } from '@/utils/events'

export const MAX_VOLUNTEERS_PER_SHIFT = 1001
export const MAX_SHIFTS = 51

export const MAX_EVENT_REPEAT_COUNT = 5

export enum EventLocationTypes {
	IN_PERSON = 'In-Person',
	VIRTUAL = 'Virtual',
}

export enum EventRepeatingOptions {
	DISABLED = 'Disabled',
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
}

export const EVENT_PRIVACY_TYPES = {
	Public: 0,
	'Members Only': 1,
	'Members and Followers': 2,
} as const

export const EVENT_REPEATING_SELECT_OPTIONS = [
	{
		label: 'Does not repeat',
		value: EventRepeatingOptions.DISABLED,
	},
	{
		label: 'Daily',
		value: EventRepeatingOptions.DAILY,
	},
	{
		label: 'Weekly',
		value: EventRepeatingOptions.WEEKLY,
	},
	{
		label: 'Monthly',
		value: EventRepeatingOptions.MONTHLY,
	},
]

export const AVAILABLE_REPEAT_COUNTS_OPTIONS = createNumberOptionsArray(MAX_EVENT_REPEAT_COUNT)

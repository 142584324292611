import { orgTypes } from '../../../utils/organizationRoles'
import http from '../requestHttp'

interface PostNewMembershipParams {
	userId: number
	orgId: string
	targetUserId: number
	membershipTypeId: orgTypes
	schoolId: number
}

const postNewMembership = async ({ userId, orgId, targetUserId, membershipTypeId, schoolId }: PostNewMembershipParams) => {
	const url = `${schoolId}/organizations/${userId}/org/${orgId}/memberships/new`
	const body = { targetUserId, membershipTypeId }

	return http.post(url, body)
}

export default postNewMembership

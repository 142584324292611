import { TUserPrimaryTitle } from '@/interfaces/common'
import { formPrimaryTitle } from '@/utils/common'
import { Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
// @TODO: property names are off, should be simply title, department - suggested to unify for faculty and student
type IUserPrimaryTitleProps = TypographyProps & TUserPrimaryTitle

const UserPrimaryTitle: React.FC<IUserPrimaryTitleProps> = ({ primaryTitle, primaryTitleDepartment, ...typographyProps }) => {
	return primaryTitle || primaryTitleDepartment ? (
		<Typography {...typographyProps}>{formPrimaryTitle([primaryTitle, primaryTitleDepartment])}</Typography>
	) : null
}

export { UserPrimaryTitle }

import IconWrapper, { IconWrapperProps, TIconName } from '@/components/IconWrapper'
import { ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import { FEATURE } from '@navengage/sen-shared-assets'
import { Except } from 'type-fest'

export const getProfileInfoItemBadge = (type: ProfileInfoCardTypes): TIconName => {
	let iconKey = null
	switch (type) {
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
		case ProfileInfoCardTypes.MEMBERSHIPS:
			iconKey = FEATURE.organizations
			break
		case ProfileInfoCardTypes.INTERNSHIPS:
			iconKey = FEATURE.jobs
			break
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			iconKey = FEATURE.studyAbroad
			break
		case ProfileInfoCardTypes.RESEARCH:
			iconKey = FEATURE.research
			break
		case ProfileInfoCardTypes.VOLUNTEER:
			iconKey = FEATURE.volunteers
			break
		case ProfileInfoCardTypes.INTERESTS:
			iconKey = 'interests'
	}
	return iconKey
}

interface ProfileInfoItemBadgeProps extends Except<IconWrapperProps, 'iconKey'> {
	type: ProfileInfoCardTypes
}

export const ProfileInfoItemBadge = ({ type, ...iconProps }: ProfileInfoItemBadgeProps) => {
	const iconKey = getProfileInfoItemBadge(type)

	return iconKey ? <IconWrapper iconKey={iconKey} {...iconProps} /> : null
}

import {
	createTreeMap,
	deselectChildrenRecursively,
	deselectParentsRecursively,
	selectChildrenRecursively,
	selectParentsRecursively,
} from '@/utils/categoryTree'
import { createOptionMapper } from '@/utils/common'
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { APP_CONSTANTS, FEATURE_NAME } from './constants'
import { loadJobFiltersRequest } from './constantsRequest'
import { IConstantsSate, TJobFilters } from './types'

export const MAP_CONSTANT_TO_ACTION = {
	[APP_CONSTANTS.jobFilters]: loadJobFiltersRequest,
}

const initialState: IConstantsSate = {
	[APP_CONSTANTS.jobFilters]: {
		isLoaded: false,
		data: {
			majors: {
				nodes: {},
				rootNode: {},
			},
			classLevels: [],
			employers: [],
			positionTypes: [],
		} as TJobFilters,
	},
}

export const loadDependencies = createAsyncThunk<
	any,
	Array<`${APP_CONSTANTS}`>,
	{
		state: RootState
	}
>(`${FEATURE_NAME}/LOAD_DEPENDENCIES`, async (dependencies, { dispatch, getState, rejectWithValue }) => {
	try {
		const { APP_CONSTANTS } = getState()
		const notLoaded = dependencies.filter((dep) => APP_CONSTANTS[dep] && !APP_CONSTANTS[dep].isLoaded)

		if (notLoaded.length > 0) {
			await Promise.all(notLoaded.map((constantKey) => dispatch(MAP_CONSTANT_TO_ACTION[constantKey]())))
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const constantsSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		toggleSelectMajors: (state, { payload }: PayloadAction<number | string>) => {
			const { nodes } = state[APP_CONSTANTS.jobFilters].data.majors
			const currentNode = nodes[payload] ?? null
			if (!currentNode) {
				return
			}

			if (nodes[payload].selected) {
				deselectChildrenRecursively(nodes, payload)
				deselectParentsRecursively(nodes, payload)
			} else {
				selectChildrenRecursively(nodes, payload)
				selectParentsRecursively(nodes, payload)
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadJobFiltersRequest.fulfilled, (state, { payload: { classLevels, employers, majors, positionTypes } }) => {
			const { list, rootNode } = majors

			const nodesMap = createTreeMap('code', 'parent', list)

			state[APP_CONSTANTS.jobFilters].isLoaded = true
			state[APP_CONSTANTS.jobFilters].data.classLevels = classLevels.map(createOptionMapper('id', 'classLevel'))
			state[APP_CONSTANTS.jobFilters].data.employers = employers.map(createOptionMapper('employer'))
			state[APP_CONSTANTS.jobFilters].data.positionTypes = positionTypes.map(createOptionMapper('id', 'jobType'))
			state[APP_CONSTANTS.jobFilters].data.majors = {
				nodes: nodesMap,
				rootNode,
			}
		})
	},
})

const selectState = (state: { [FEATURE_NAME]: IConstantsSate }) => state[FEATURE_NAME]

export const { toggleSelectMajors } = constantsSlice.actions

export const getEmployers = createSelector(selectState, (state) => state[APP_CONSTANTS.jobFilters].data.employers)
export const getPositionTypes = createSelector(selectState, (state) => state[APP_CONSTANTS.jobFilters].data.positionTypes)
export const getMajors = createSelector(selectState, (state) => state[APP_CONSTANTS.jobFilters].data.majors)
export const getClassLevels = createSelector(selectState, (state) => state[APP_CONSTANTS.jobFilters].data.classLevels)
export const selectJobFiltersAreLoaded = createSelector(selectState, (state) => state[APP_CONSTANTS.jobFilters].isLoaded)

import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import CreateEvent from '@/pages/EventsPage/CreateEvent'
import DiscoverEvents from '@/pages/EventsPage/DiscoverEvents'
import EditEvent from '@/pages/EventsPage/EditEvent'
import IndividualEvent from '@/pages/EventsPage/IndividualEvent'
import YourEvents from '@/pages/EventsPage/YourEvents'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_EVENTS = createPageTitle(PAGE_NAME.DISCOVER_EVENTS)
const MY_EVENTS = createPageTitle(PAGE_NAME.MY_EVENTS)
const CREATE_EVENT = createPageTitle(PAGE_NAME.CREATE_EVENT)
const EDIT_EVENT = createPageTitle(PAGE_NAME.EDIT_EVENT)

const routes: AppRoute[] = [
	{
		component: EditEvent,
		title: EDIT_EVENT,
		name: EDIT_EVENT,
		path: PATHS.APP.EVENTS_EDIT(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: IndividualEvent,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.EVENTS_SINGLE(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: CreateEvent,
		title: CREATE_EVENT,
		name: CREATE_EVENT,
		path: PATHS.APP.EVENTS_CREATE,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourEvents,
		title: MY_EVENTS,
		name: MY_EVENTS,
		path: PATHS.APP.EVENTS_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverEvents,
		title: DISCOVER_EVENTS,
		name: DISCOVER_EVENTS,
		path: PATHS.APP.EVENTS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

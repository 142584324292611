import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import http from '@/api/http/requestHttp'
import { RecoilObserver } from '@/components/RecoilObserver'
import { SocketProvider } from '@/lib/socket/SocketProvider'
import { history, store } from '@/store'
import { NavThemeProvider } from './Theme'

interface ProvidersProps {
	children: React.ReactNode
}

const Providers = ({ children }: ProvidersProps) => {
	return (
		<RecoilRoot>
			<RecoilObserver />
			<SWRConfig
				value={{
					fetcher: (resource, init) => http.get(resource).then((res) => res.data),
				}}
			>
				<ReduxProvider store={store}>
					{/* <SnackbarProvider> */}
					<SocketProvider>
						{/* Uses Luxon as dateAdapter*/}
						<LocalizationProvider dateAdapter={AdapterLuxon}>
							<Router history={history}>
								<QueryParamProvider adapter={ReactRouter5Adapter}>
									<NavThemeProvider>{children}</NavThemeProvider>
								</QueryParamProvider>
							</Router>
						</LocalizationProvider>
					</SocketProvider>
					{/* </SnackbarProvider> */}
				</ReduxProvider>
			</SWRConfig>
		</RecoilRoot>
	)
}

export default Providers

import StudyAbroadCard from '@/components/ExperienceCards/StudyAbroadCard'
import useStudyAbroadByIdQuery from '@/features/studyAbroad/hooks/useStudyAbroadByIdQuery'
import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedStudyAbroadProps = ISharedEntityProps

const SharedStudyAbroad: React.FC<ISharedStudyAbroadProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useStudyAbroadByIdQuery(id, skip || type !== EntityTypes.studyAbroad)

	return withErrorHandler({
		type,
		currentType: EntityTypes.studyAbroad,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<StudyAbroadCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.photoUrl}
				locations={data.locations}
				termsOfAvailability={data.termsOfAvailability}
				status={data.responseStatus}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedStudyAbroad

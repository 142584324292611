import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { submitFeedback } from '@/utils/services'
import { makeStyles } from '@material-ui/core/styles'

import { EmptyTabPlaceholder } from '@/anthology/components/EmptyTabPlaceholder'
import PageHeaderTabs, { ICreatePageHeaderConfig } from '@/anthology/components/PageHeaderTabs'
import useCurrentUserOrgs from '@/anthology/organizations/hooks/useCurrentUserOrgs'
import { ContentPreloader } from '@/components/ContentPreloader'
import { FEATURE } from '@navengage/sen-shared-assets'
import OrganizationCard from '../components/OrganizationCard'
import { TUserOrganizationTypes, USER_ORGANIZATIONS_TABS } from '../types/UserOrganizationTypes'
import { DtoOrganization } from '../types/dto/DtoOrganization'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const YourOrganizations = () => {
	const classes = useStyles()

	const { push } = useHistory()

	const {
		data,
		isLoading,
		isFetching,
		canLoadMore,
		showPreloader,
		isUninitialized,
		loadMore,
		onChangeType,
		filters: { type: tab },
	} = useCurrentUserOrgs()
	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Organizations',
				onClick: () => push(PATHS.APP.ORGANIZATIONS),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const emptyPlaceholder = useMemo(() => {
		const showNoOrgsPlaceholder = !isLoading && data && data.totalItems === 0
		const placeholderInfo = USER_ORGANIZATIONS_TABS[tab].placeholder

		return showNoOrgsPlaceholder ? (
			<EmptyTabPlaceholder
				buttons={placeholderButtons}
				iconKey={FEATURE.organizations}
				title={placeholderInfo?.title}
				description={placeholderInfo?.description}
			/>
		) : null
	}, [isLoading, data, tab, placeholderButtons])

	return (
		<div className={classes.root}>
			<PageHeaderTabs<ICreatePageHeaderConfig<TUserOrganizationTypes>>
				tabConfig={USER_ORGANIZATIONS_TABS}
				title="My Organizations"
				showPreloader={showPreloader}
				totalCount={`${data ? data.totalItems : 0}`}
				currentTab={tab}
				onChange={onChangeType}
			/>

			{showPreloader ? (
				<ContentPreloader />
			) : emptyPlaceholder ? (
				emptyPlaceholder
			) : (
				!isUninitialized &&
				!isLoading && (
					<ExperienceCardsSection
						items={data.items}
						isLoading={isLoading || isFetching}
						handleLoadMoreItems={loadMore}
						canLoadMore={canLoadMore}
						renderItems={(items: any) => (
							<>
								{items.map(({ name, startDate, profilePicture, id, follow, categories, summary }: DtoOrganization) => (
									<OrganizationCard
										key={`${id}-${name}-${startDate}`}
										id={id}
										title={name}
										photoUrl={profilePicture}
										description={summary}
										categories={categories}
										followed={follow?.response === 'followed'}
									/>
								))}
							</>
						)}
					/>
				)
			)}
		</div>
	)
}

export default YourOrganizations

import StartGroupModal from '@/components/Messages/Group/StartGroupModal'
import MessagesBar from '@/components/Messages/MessagesBar'
import Router from '@/components/Router'
import { PATHS, STYLES } from '@/constants'
import ROUTES from '@/routes'
import { getShowCreateGroupModal, getTargetGroup, setShowCreateGroupModal } from '@/store/groups'
import { clearAddNewUserToMessagesListInput, getShowSelectUserInput, getTargetUser, setShowSelectUserInput } from '@/store/messages'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'calc(100% - 90px)',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,

		[theme.breakpoints.down('sm')]: {
			paddingBottom: 100,
		},
	},
}))

const MessagesPage = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))
	const { pathname } = useLocation()
	const targetUser = useSelector(getTargetUser)
	const targetGroup = useSelector(getTargetGroup)

	const showSelectUserInput = useSelector(getShowSelectUserInput)
	const showCreateGroupModal = useSelector(getShowCreateGroupModal)

	const handleClearUserData = useCallback(() => dispatch(clearAddNewUserToMessagesListInput()), [dispatch])

	const handleShowSelectUserInput = useCallback(() => {
		handleClearUserData()
		dispatch(setShowSelectUserInput(true))
		dispatch(setShowCreateGroupModal(false))
	}, [dispatch, handleClearUserData])

	const handleCloseCreateGroupModal = useCallback(() => {
		handleClearUserData()
		dispatch(setShowCreateGroupModal(false))
	}, [dispatch, handleClearUserData])

	const handleShowCreateGroupModal = useCallback(() => {
		handleClearUserData()
		dispatch(setShowCreateGroupModal(true))
		dispatch(setShowSelectUserInput(false))
	}, [dispatch, handleClearUserData])

	const showOnlyMessages = !mdScreenSize && (!!targetUser || showSelectUserInput || !!targetGroup || pathname === PATHS.APP.DISCOVER_GROUP)
	const showOnlyList = !mdScreenSize && !showOnlyMessages

	return (
		<div className={classes.root}>
			<StartGroupModal isOpen={showCreateGroupModal} onClose={handleCloseCreateGroupModal} />
			{(mdScreenSize || showOnlyList) && (
				<MessagesBar
					isMobile={showOnlyList}
					handleAddNewMessage={handleShowSelectUserInput}
					handleAddNewGroup={handleShowCreateGroupModal}
					showAddNewMessageButton
				/>
			)}
			<Router
				routes={ROUTES.messages}
				redirectPaths={[
					{
						from: PATHS.APP.MESSAGES,
						to: PATHS.APP.MESSAGES_LIST,
					},
				]}
			/>
		</div>
	)
}

export default MessagesPage

import { Box, Button } from '@mui/material'
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from '@mui/x-data-grid'
import { ActionButtonProps } from '../../types/dashboardTypes'

export type ToolbarProps = {
	actions: ActionButtonProps[]
	isFilterEnabled: boolean
}

export const Toolbar = (props: ToolbarProps) => {
	return (
		<GridToolbarContainer sx={{ marginTop: '1.2rem' }}>
			<GridToolbarColumnsButton />
			{props.isFilterEnabled && <GridToolbarFilterButton />}
			<GridToolbarExport />
			<GridToolbarDensitySelector />

			<Box sx={{ flexGrow: 1 }} />
			{(props.actions ?? [])
				.filter((action) => !!action.isVisible)
				.map((action, index) => (
					<Button key={index} onClick={action.onClick} size="small" variant="outlined">
						{action.icon}
						&nbsp;{action.label}
					</Button>
				))}
		</GridToolbarContainer>
	)
}

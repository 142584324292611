import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'

import { ContentPreloader } from '@/components/ContentPreloader'
import SettingsPageHeader from '@/components/SettingsPageHeader'
import { Preference } from '@/components/widgets'
import { STYLES } from '@/constants'
import { arePreferencesLoading, groupSelectors, loadUserPreferencesRequest } from '@/features/preferences'
import { store, useAppDispatch, useAppSelector } from '@/store'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	container: {
		height: 90,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	contentContainer: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '1.5vw',
	},
	blockTitle: {
		margin: '20px 0',
		fontWeight: 700,
	},
}))

const Notifications = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const preferenceGroups = useAppSelector(groupSelectors.selectAll)
	const isLoading = arePreferencesLoading(store.getState().preferences)

	useEffect(() => {
		dispatch(loadUserPreferencesRequest())
	}, [dispatch])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<SettingsPageHeader title="Notifications" />
			</div>
			<div className={classes.contentContainer}>
				{isLoading ? (
					<ContentPreloader />
				) : (
					preferenceGroups
						.filter(({ preferences }) => !!preferences.length)
						.map(({ id: groupId, name, preferences }) => (
							<div key={groupId}>
								<Typography className={classes.blockTitle} variant="h3">
									{name}
								</Typography>
								<div>
									{preferences.map((id) => (
										<Preference preferenceId={id} key={id} />
									))}
								</div>
							</div>
						))
				)}
			</div>
		</div>
	)
}

export default Notifications

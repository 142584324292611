import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const useBrowserBackStack = (maxHistory: number = 3) => {
	const history = useHistory()
	const [backStack, setBackStack] = useState<any[]>([])
	const clearStack = useCallback(() => {
		setBackStack([])
	}, [])
	useEffect(() => {
		history.listen((location, action) => {
			setBackStack((backStack) => {
				let newStack = backStack
				switch (action) {
					case 'POP':
						newStack = newStack.slice(0, backStack.length - 1)
						break
					case 'PUSH':
						newStack = [...newStack, location]
						break
					case 'REPLACE':
						newStack = [...newStack.slice(0, newStack.length - 1), location]
						break
				}
				if (maxHistory === 0) {
					return newStack
				} else {
					return newStack.filter((stack, index) => index < maxHistory)
				}
			})
		})
	}, [setBackStack, history, maxHistory])
	return { backStack, clearStack }
}

export default useBrowserBackStack

import { Chip, ChipProps, Theme, alpha, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
	root: ({ color }) => ({
		backgroundColor: alpha(color, 0.1),
		fontWeight: 500,
		color,
		borderRadius: 5,
		border: 'none',
		margin: `0px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px 0px`,
	}),
}))

export interface IInfoBadgeProps extends Omit<ChipProps, 'color'> {
	color: string
}

const InfoBadge: React.FC<IInfoBadgeProps> = ({ color, ...rest }) => {
	const classes = useStyles({ color })
	return <Chip variant="outlined" classes={{ root: classes.root }} {...rest} />
}

export default InfoBadge

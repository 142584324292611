import OrganizationCard from '@/components/ExperienceCards/OrganizationCard'
import useOrganizationByIdQuery from '@/features/organizations/hooks/useOrganizationByIdQuery'
import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedOrganizationProps = ISharedEntityProps

const SharedOrganization: React.FC<ISharedOrganizationProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useOrganizationByIdQuery(id, skip || type !== EntityTypes.organization)

	return withErrorHandler({
		type,
		currentType: EntityTypes.organization,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<OrganizationCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.photoUrl}
				followed={data.followed}
				description={data.description}
				categories={data.categories}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedOrganization

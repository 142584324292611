import APP from '@/constants/paths/main'
import SIGN_UP from '@/constants/paths/signUp'
import { ADMIN_ROOT_PATH } from '@/features/adminConsole/routes/config'

const paths = {
	ROOT: '/',
	ADMIN: ADMIN_ROOT_PATH,
	DOWNLOAD_APP: '/download',
	AUTH_REDIRECT: '/redirect',
	MISSING_ACCOUNT_REDIRECT: '/missing-account',
	ERROR: '/error',
	NOT_FOUND_ERROR: '/404',
	AUTH_ERROR: '/auth-error',
	NEW_USER: '/welcome',
	SIGN_UP,
	LOGIN: '/login',
	APP,
}

export default paths

import http from '@/api/http/requestHttp'
import { orgTypes } from '@/utils/organizationRoles'

const getOrganizationsByMembershipType = async (userId: number, membershipType: orgTypes, schoolId: number) => {
	const url = `${schoolId}/organizations/user/${userId}/memberships/${membershipType}`

	return http.get(url)
}

export default getOrganizationsByMembershipType

import http from '@/api/http/requestHttp'
import { orgTypes } from '@/utils/organizationRoles'

interface UserOrganizationPermissionsResponse {
	list: {
		organizationId: string
		organizationMembershipTypeId: orgTypes
	}[]
}

const getUserOrganizationPermissions = async (userId: number, schoolId: number) => {
	const url = `${schoolId}/organizations/user/${userId}/permissions`

	return http.get<UserOrganizationPermissionsResponse>(url)
}

export default getUserOrganizationPermissions

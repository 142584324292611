import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ROUTES from '@/routes'
import AppRoute from '@/interfaces/route'
import { includeUrl } from '@/utils/urlHandlers'
import { createPageTitle, setDocumentTitle } from '@/utils/page'

type ChangePageTitleCallback = (title?: string) => void

interface WatchPageTitleHookParams {
	onWatch?: ChangePageTitleCallback
	watchEntity?: string
	isWatchingPathName?: boolean
}

const useWatchPageTitle = ({ onWatch, watchEntity, isWatchingPathName = false }: WatchPageTitleHookParams) => {
	const { pathname } = useLocation()

	useEffect(() => {
		let pageTitle

		if (isWatchingPathName) {
			const allRoutes = Object.values(ROUTES).reduce((acc, routes) => {
				return [...acc, ...routes]
			}, [] as AppRoute[])

			pageTitle = allRoutes.find((route) => route.title && includeUrl([route.path]))?.title
		} else if (watchEntity) {
			pageTitle = createPageTitle(watchEntity)
		}

		if (pageTitle) {
			setDocumentTitle(pageTitle)
			if (onWatch) onWatch(pageTitle)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, watchEntity])
}

export default useWatchPageTitle

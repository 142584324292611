import { FeedPost, FeedsFilters, UserAudience } from '@/store/feeds/types'
import { PayloadAction, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { postsApi } from '../api'
import { RootState } from '@/store'
import { FILTER_CONFIG } from './config'
import { TCampus } from '@/features/campus/types/TCampus'
import { cloneDeep } from 'lodash'
import { UserInfo } from '@/interfaces/user'
import { LikePayload } from '../types/socket'
import { UiState } from '../types/UiStates'

const FEATURE_NAME = 'APP_FEED'

const postMatchesFilters = (post: FeedPost, filter: FeedsFilters): boolean => {
	const shouldMatch = []
	if (!filter.includeAllCampuses) {
		shouldMatch.push(filter.campusId === post.campusId)
	}
	if (filter.tags && filter.tags.length > 0) {
		shouldMatch.push(filter.tags.includes(+post.tag))
	}
	// check user audience only when user is in network and want to see only network feed
	if (filter.usersAudience === UserAudience.NETWORK) {
		shouldMatch.push(filter.usersAudience === +post.usersAudience)
	}
	if (filter.audienceScope) {
		shouldMatch.push(filter.audienceScope === post.usersAudienceScope)
	}

	return shouldMatch.every((match) => match)
}

export const postAdapter = createEntityAdapter<FeedPost>({
	sortComparer: (a, b) => b.publishedAt.localeCompare(a.publishedAt),
})

const feedUpdatesAdapter = createEntityAdapter<FeedPost>({
	sortComparer: (a, b) => b.publishedAt.localeCompare(a.publishedAt),
})

const individualPostAdapter = createEntityAdapter<FeedPost>({
	sortComparer: (a, b) => b.publishedAt.localeCompare(a.publishedAt),
})

const initialFilters: FeedsFilters = Object.keys(FILTER_CONFIG).reduce((acc, key) => {
	acc[key] = FILTER_CONFIG[key].defaultValue ?? undefined
	return acc
}, {})

type CounterChangeType = 'increment' | 'decrement'

const createNumberOfCommentsChanges = (post: FeedPost, count: number, type: CounterChangeType) => {
	const prevCommentsCount = +post.numberOfComments
	const changedCommentCount = type === 'increment' ? prevCommentsCount + count : prevCommentsCount - count
	return {
		numberOfComments: changedCommentCount,
	}
}

const LISTS_SUBSCRIBED_TO_CHANGES = [
	{ adapter: postAdapter, key: null },
	{ adapter: feedUpdatesAdapter, key: 'updates' },
	{ adapter: individualPostAdapter, key: 'individual' },
]

const initialState = postAdapter.getInitialState({
	updates: feedUpdatesAdapter.getInitialState(),
	individual: individualPostAdapter.getInitialState(),
	deletingIndex: -1,
	showUpdates: true,
	scrollPosition: 0,
	initialized: false,
	isLoading: false,
	isFetching: false,
	canLoadMore: false,
	total: 0,
	filters: { offset: 0, isFiltersReady: false, ...cloneDeep(initialFilters) },
})

export const feedSlice = createSlice({
	name: FEATURE_NAME,
	initialState,
	reducers: {
		resetFeedData: (state) => {
			feedUpdatesAdapter.removeAll(state.updates)
			postAdapter.removeAll(state)
			state.initialized = false
			state.scrollPosition = 0
			state.initialized = false
			state.isLoading = false
			state.isFetching = false
			state.canLoadMore = false
			state.total = 0
			state.filters = { offset: 0, isFiltersReady: true, ...cloneDeep(initialFilters) }
		},
		deleteById: (state, { payload }: PayloadAction<FeedPost['id']>) => {
			if (state.entities[payload]) {
				state.total -= 1
			}

			feedUpdatesAdapter.removeOne(state, payload)
			postAdapter.removeOne(state, payload)
		},

		onPostReceived: (state, { payload: { posts, userId } }: PayloadAction<{ posts: FeedPost[]; userId: UserInfo['id'] }>) => {
			const matchingPosts = posts.filter((p) => postMatchesFilters(p, state.filters))
			const currentUserPosts = matchingPosts.filter((p) => p.ownerUserId === userId)
			const otherUserPosts = matchingPosts.filter((p) => p.ownerUserId !== userId)
			state.total += matchingPosts.length
			// posts created by other users go to updates
			if (otherUserPosts && otherUserPosts.length > 0) {
				state.showUpdates = true
				feedUpdatesAdapter.upsertMany(
					state.updates,
					// Don't add post as an update for owner
					otherUserPosts.filter((p) => p.ownerUserId !== userId),
				)
			}
			// posts created by current user will be added to feed right away
			if (currentUserPosts && currentUserPosts.length > 0) {
				postAdapter.upsertMany(state, currentUserPosts)
			}
		},
		// @TODO backend sends wrong data for liked we just ignore it and handle in different socket event
		onPostUpdated: (state, { payload: { liked, id, ...rest } }: PayloadAction<FeedPost>) => {
			LISTS_SUBSCRIBED_TO_CHANGES.forEach(({ adapter, key }) => {
				const storeState = key ? state[key] : state
				adapter.updateOne(storeState, { changes: rest, id })
			})
		},
		setFilterTags: (state, { payload }: PayloadAction<number[]>) => {
			state.filters.tags = payload
			state.filters.offset = 0
		},
		setFilterCampus: (state, { payload }: PayloadAction<TCampus['id']>) => {
			state.filters.campusId = payload
			state.filters.offset = 0
		},
		setFilterIncludeAllCampuses: (state, { payload }: PayloadAction<boolean>) => {
			state.filters.includeAllCampuses = payload
			state.filters.offset = 0
		},
		setFilterAudience: (state, { payload }: PayloadAction<number>) => {
			state.filters.audienceScope = payload
			state.filters.offset = 0
		},
		setFilterUserAudience: (state, { payload }: PayloadAction<UserAudience>) => {
			state.filters.usersAudience = payload
			state.filters.offset = 0
		},
		setFeedScrollPosition: (state, { payload }: PayloadAction<number>) => {
			state.scrollPosition = payload
		},
		setFeedOffset: (state) => {
			state.filters.offset = state.ids.length + state.updates.ids.length
		},
		hideUpdatesBadge: (state) => {
			state.showUpdates = false
		},
		applyFeedUpdates: (state) => {
			const updatesEntities = state.updates.entities
			postAdapter.upsertMany(state, Object.values(updatesEntities))
			feedUpdatesAdapter.removeAll(state.updates)
		},
		postsViewed: (state, { payload }: PayloadAction<Array<FeedPost['id']>>) => {
			feedUpdatesAdapter.removeMany(state.updates, payload)
		},
		postLiked: (state, { payload: { userId, post, currentUserId } }: PayloadAction<LikePayload & { currentUserId: UserInfo['id'] }>) => {
			const oldPost = state.entities[post.id]
			if (oldPost) {
				let changes = null
				if (currentUserId === userId) {
					const liked = +oldPost.liked > 0
					changes = { changes: { ...post, liked: !liked }, id: post.id }
				} else {
					changes = { changes: { ...post, liked: oldPost.liked }, id: post.id }
				}
				feedUpdatesAdapter.updateOne(state, changes)
				postAdapter.updateOne(state, changes)
			}
		},
		updatePostUiState: (state, { payload: { id, uiState } }: PayloadAction<{ uiState?: UiState; id }>) => {
			if (uiState === 'deleted') {
				state.deletingIndex = state.ids.findIndex((val) => val === id)
			}
			postAdapter.updateOne(state, { changes: { uiState }, id })
		},
		clearListDeletion: (state) => {
			state.deletingIndex = -1
		},
		changeCommentsCount: (
			state,
			{ payload: { count, postId, type } }: PayloadAction<{ postId: FeedPost['id']; count: number; type: CounterChangeType }>,
		) => {
			LISTS_SUBSCRIBED_TO_CHANGES.forEach(({ adapter, key }) => {
				const storePost = key ? state[key].entities[postId] : state.entities[postId]
				if (storePost) {
					adapter.updateOne(state, { changes: createNumberOfCommentsChanges(storePost, count, type), id: postId })
				}
			})
		},
		initFilters: (state, { payload }: PayloadAction<Partial<(typeof initialState)['filters']>>) => {
			state.filters = { ...state.filters, ...payload, offset: 0, isFiltersReady: true }
		},
	},
	extraReducers: (builder) =>
		builder
			.addMatcher(isAnyOf(postsApi.endpoints.searchPosts.matchFulfilled), (state, { payload: { results, total, offset } }) => {
				state.total = total
				state.isLoading = false
				state.isFetching = false

				if (offset > 0) {
					postAdapter.upsertMany(state, results)
				} else {
					state.scrollPosition = 0
					postAdapter.setAll(state, results)
				}
				if (results.length === 0) {
					state.canLoadMore = false
				} else {
					state.canLoadMore = state.ids.length < total
				}
				state.initialized = true
			})
			.addMatcher(isAnyOf(postsApi.endpoints.getPost.matchFulfilled), (state, { payload: { post } }) => {
				if (state.entities[post.id] || state.updates.entities[post.id]) {
					LISTS_SUBSCRIBED_TO_CHANGES.forEach(({ adapter, key }) => {
						if (key !== 'individual') {
							const storeState = key ? state[key] : state
							adapter.updateOne(storeState, { changes: post, id: post.id })
						}
					})
				}
				individualPostAdapter.upsertOne(state, post)
			})
			.addMatcher(isAnyOf(postsApi.endpoints.searchPosts.matchPending), (state) => {
				state.isLoading = state.ids && state.ids.length > 0 ? false : true
				state.isFetching = true
			})
			.addMatcher(isAnyOf(postsApi.endpoints.searchPosts.matchRejected), (state) => {
				state.isLoading = false
				state.isFetching = false
			}),
})

export const {
	applyFeedUpdates,
	hideUpdatesBadge,
	onPostReceived,
	onPostUpdated,
	deleteById,
	resetFeedData,
	setFilterAudience,
	setFilterCampus,
	setFilterIncludeAllCampuses,
	setFilterTags,
	setFilterUserAudience,
	setFeedScrollPosition,
	setFeedOffset,
	postsViewed,
	postLiked,
	clearListDeletion,
	updatePostUiState,
	changeCommentsCount,
	initFilters,
} = feedSlice.actions

export const feedActions = feedSlice.actions

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME]
const selectFeedUpdateState = (state: RootState) => state[FEATURE_NAME].updates
const selectIndividualState = (state: RootState) => state[FEATURE_NAME].individual

export const postSelectors = postAdapter.getSelectors(selectState)
export const feedUpdatesSelectors = feedUpdatesAdapter.getSelectors(selectFeedUpdateState)
export const individualPostSelectors = individualPostAdapter.getSelectors(selectIndividualState)

export const selectCanLoadMore = createSelector(selectState, (state) => state.canLoadMore)

export const selectFeedFilters = createSelector(selectState, (state) => state.filters)
export const selectFeedTags = createSelector(selectState, (state) => state.filters.tags)
export const selectFeedCampusId = createSelector(selectState, (state) => state.filters.campusId)
export const selectIncludeAllCampuses = createSelector(selectState, (state) => state.filters.includeAllCampuses)
export const selectFeedAudience = createSelector(selectState, (state) => state.filters.audienceScope)
export const selectFeedUserAudience = createSelector(selectState, (state) => state.filters.usersAudience)
export const selectFeedScrollPosition = createSelector(selectState, (state) => state.scrollPosition)
export const selectFeedOffset = createSelector(selectState, (state) => state.filters.offset)
export const selectShowUpdates = createSelector(selectState, (state) => state.showUpdates)
export const selectDeletingIndex = createSelector(selectState, (state) => state.deletingIndex)

export const selectFeedQueryState = createSelector(selectState, ({ isFetching, initialized, isLoading }) => ({
	isFetching,
	initialized,
	isLoading,
}))

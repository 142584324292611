import { GridRenderCellParams } from '@mui/x-data-grid'
import { ReactNode } from 'react'
import GridDateTemplate from './GridDateTemplate'

import { PATHS } from '@/constants'
import { PropsFrom } from '@/features/adminConsole/types/common'
import { DatePicker } from '@mui/x-date-pickers'
import { Route } from '../../../routes/config'
import GridDateFullTemplate from './GridDateFullTemplate'
import { DateTimeCell, EditDateTime } from './GridDateTimeTemplate'
import GridLinkTemplate from './GridLinkTemplate'

export const CELL_TEMPLATE_DEFAULTS: Record<string, (params: GridRenderCellParams) => ReactNode> = {
	date: GridDateTemplate,
	dateTime: DateTimeCell,
	fullDate: GridDateFullTemplate,
	eventLink: (params: GridRenderCellParams) => GridLinkTemplate({ ...params, path: PATHS.APP.EVENTS_SINGLE(params.row?.id) }),
	postLink: (params: GridRenderCellParams) => GridLinkTemplate({ ...params, path: PATHS.APP.POST_INFO(params.row?.id) }),
	organizationLink: (params: GridRenderCellParams) => GridLinkTemplate({ ...params, path: PATHS.APP.ORGANIZATIONS_SINGLE(params.row?.id) }),
	analyticsProfile: (params: GridRenderCellParams) =>
		GridLinkTemplate({ ...params, path: `${PATHS.ADMIN}/${Route.Users}/${params.row?.userId || params.row?.id}` }),
}

export const RENDER_EDIT_CELL_TEMPLATE_DEFAULTS: Record<string, (params: PropsFrom<typeof DatePicker | typeof EditDateTime>) => ReactNode> =
	{
		date: DatePicker,
		dateTime: EditDateTime,
	}

import { flexStyles } from './flexStyles'
import { marginStyles } from './marginStyles'
import { paddingStyles } from './paddingStyles'

export const rootStyles = {
	...flexStyles,
	...paddingStyles,
	...marginStyles,
	circle: {
		borderRadius: '50%',
	},
	buttonPlain: {
		'font-size': 'inherit',
		'letter-spacing': 0,
		'font-weight': 'normal',
		'font-style': 'normal',
		'text-decoration': 'none',
		cursor: 'pointer',
		color: 'inherit',
		margin: 0,
		padding: 0,
		border: 'none',
		background: ' 0 0',
		'background-color': 'none',
		outline: 'none',
		'&:disabled': {
			cursor: 'not-allowed',
			opacity: 0.5,
		},
	},
}

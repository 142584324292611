import { UserOrganizationTypes } from '@/anthology/organizations/types/UserOrganizationTypes'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { SearchConfig } from '@/interfaces/common'
import { MODULE_NAME } from '@/store/organizations/constants'
import { OrganizationsState, OrgMembership } from '@/store/organizations/types'
import { getOrganizationType, hasOrganizationRole, isMember, orgTypes } from '@/utils/organizationRoles'
import { createSelector } from '@reduxjs/toolkit'

const selectState = (state: { [MODULE_NAME]: OrganizationsState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getIsLoadingUserOrganizations = createSelector(selectState, (state) => state.isLoadingUserOrganizations)
export const getIsLoadingIndividualOrganization = createSelector(selectState, (state) => state.isLoadingIndividualOrganization)

export const getOrganizations = createSelector(selectState, (state) => state.organizations)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)
export const getCategories = createSelector(selectState, (state) => state.categories)
export const getUserOrganizations = createSelector(selectState, (state) => state.userOrganizations)
export const getSelectedOrganization = createSelector(selectState, (state) => state.selectedOrganization?.info)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)
export const getOrganizationById = (id?: string) =>
	createSelector(selectState, (state) => state.organizations.find((organization) => organization.id === id))
export const getAdminOrSuperAdminOrgs = createSelector(selectState, (state) => [
	...state.userOrganizations.superAdmin,
	...state.userOrganizations.admin,
])

export const getIsSuperAdmin = (id?: string) =>
	createSelector(selectState, (state) => state.superAdminOrganizations.findIndex((org) => org.id === id) !== -1)
export const getOrganizationActivityState = createSelector(selectState, (state) => state.selectedOrganization?.activity)
export const getOrganizationFollowers = createSelector(selectState, (state) => state.selectedOrganization?.followers)
export const getOrganizationMemberships = (membershipType: orgTypes) =>
	createSelector(
		selectState,
		(state) =>
			state.selectedOrganization?.memberships[membershipType] ?? {
				list: [],
				total: 0,
			},
	)
export const getOrganizationMembershipRequests = createSelector(selectState, (state) => state.selectedOrganization.newMemberRequests)
export const getOrganizationPositionRequests = createSelector(selectState, (state) => state.selectedOrganization.positionRequests)
export const getIsLoadingOrganizationData = createSelector(selectState, (state) => state.selectedOrganization.isLoading)
export const getPendingUserRequests = createSelector(selectState, (state) => state.pendingUserRequests)
export const getHasPendingJoinToOrganizationRequest = (orgId: string, types?: orgTypes[]) =>
	createSelector(selectState, (state) =>
		state.pendingUserRequests[orgId]?.some((req) => !hasOrganizationRole(types) && isMember([req.organizationMembershipTypeId])),
	)
export const getOrganizationUserPendingRequests = createSelector(selectState, (state) => state.selectedOrganization.pendingUserRequests)
export const getLeaveFromOrganizationModal = createSelector(selectState, (state) => state.leaveFromOrganizationModal)
export const getOpenOrgDefaultOptions = createSelector(selectState, (state) => state.openOrgDefaultOptions)
export const getUserMembership = (userId: number) =>
	createSelector(selectState, (state) => {
		const orgMemberships = state.selectedOrganization.memberships

		const allMemberships = Object.keys(orgMemberships).reduce((acc, membershipType) => {
			// @ts-ignore
			const list = orgMemberships[membershipType].list

			return [...acc, ...list]
		}, [] as OrgMembership[])

		return allMemberships.find((m) => m.userId === userId)
	})
// @TODO: should be handled by backend meanwhile, backend already filters hidden results based on current user permissions
export const getOrganizationPossibleContacts = createSelector(selectState, (state) => {
	const memberships = state.selectedOrganization?.memberships[getOrganizationType(ORGANIZATION_ROLE.SUPER_ADMIN)] ?? { list: [], total: 0 }

	return memberships.list.filter(({ hidden }) => !hidden)
})

export const getSearchUserResults = createSelector(selectState, (state) => state.selectedOrganization.searchUsers.users)
export const getCanLoadMoreSearchUserResults = createSelector(selectState, (state) => state.selectedOrganization.searchUsers.canLoadMore)
export const getIsLoadingSearchUserResults = createSelector(selectState, (state) => state.selectedOrganization.searchUsers.isLoading)

export const getSearchOrgMembershipsQuery = createSelector(selectState, (state) => state.selectedOrganization.searchOrgMemberships.q)
export const getSearchOrgMembershipsResults = createSelector(
	selectState,
	(state) => state.selectedOrganization.searchOrgMemberships.memberships,
)
export const getCanLoadMoreSearchOrgMembershipsResults = createSelector(
	selectState,
	(state) => state.selectedOrganization.searchOrgMemberships.canLoadMore,
)
export const getIsLoadingSearchOrgMembershipsResults = createSelector(
	selectState,
	(state) => state.selectedOrganization.searchOrgMemberships.isLoading,
)
export const getUserTypeOptions = createSelector(selectState, (state) => state.userTypeOptions)

export const selectOrganizationsByType = createSelector(
	selectState,
	(_, type: UserOrganizationTypes) => type,
	(state: OrganizationsState, type) => {
		switch (type) {
			case UserOrganizationTypes.admin:
				return [...state.userOrganizations.superAdmin, ...state.userOrganizations.admin]
			case UserOrganizationTypes.followed:
				return state.userOrganizations.followed
			case UserOrganizationTypes.member:
				return state.userOrganizations.member
		}
	},
)

import SignUpCard from '@/components/SignUpCard'
import OnboardingEntityList from '@/features/onboarding/components/onboardingEntities/list'
import { GridItem } from '@/features/onboarding/components/onboardingEntities/templates/GridItem'
import { GridLayout } from '@/features/onboarding/components/onboardingEntities/layouts/GridLayout'
import { selectUserChoicesByType } from '@/features/onboarding/slice'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppSelector } from '@/store'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import { useOnboardingStepConfig } from '../hooks'
import EntityScope from '@/features/onboarding/types/EntityScope'

export const FollowOrganizationsStep = ({ onClickNext = () => {} }) => {
	const userSelections = useAppSelector((state) =>
		selectUserChoicesByType(state, { entityType: EntityTypes.organization, entityScope: EntityScope.Standard }),
	)

	const disableContinueButton = userSelections.length === 0
	const followOrgsConfig = useOnboardingStepConfig('followOrgs')

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.FOLLOWED_ORGANIZATIONS_SIGNUP, {
			choices: userSelections,
		})
	}

	return (
		<SignUpCard
			title={followOrgsConfig?.title}
			subTitle={followOrgsConfig?.subTitle}
			continueButtonDisabled={disableContinueButton}
			showSkipButtonWhenContinueDisabled={true}
			onClickContinue={onClickContinue}
		>
			<GridLayout>
				<OnboardingEntityList
					entityType={EntityTypes.organization}
					entityScope={EntityScope.Standard}
					renderItem={({ item, onClick }) => <GridItem {...item} onClick={onClick} showDescription={false} />}
				/>
			</GridLayout>
		</SignUpCard>
	)
}

import { FEATURE } from '@navengage/sen-shared-assets'
import { getTreatments, initSplitSdk } from '@splitsoftware/splitio-redux'
import { sdkBrowserConfig } from './config'
import { resolveSchoolId } from '@/utils'

export const init = (store: any) => {
	if (sdkBrowserConfig.core.authorizationKey && sdkBrowserConfig.core.key) {
		store.dispatch(initSplitSdk({ config: sdkBrowserConfig }))

		store.dispatch(
			getTreatments({
				key: resolveSchoolId(),
				splitNames: Object.values(FEATURE),
				evalOnUpdate: true,
			}),
		)
	}
}

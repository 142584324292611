import { useCallback, useState } from 'react'
import settings from '@/constants/http'
import EntityResultsParams from '../types/EntityResultsParams'

export type CreateListingByType<T> = EntityResultsParams & { type: T }

export const useEntityListingByType = <TListType>(defaultType: TListType, defaultTake: number = settings.SEARCH_LIST_PER_PAGE) => {
	const [filters, setFilters] = useState<CreateListingByType<TListType>>({
		skip: 0,
		take: defaultTake,
		type: defaultType,
	})
	const onChangeType = useCallback((type: TListType) => {
		setFilters((prev) => ({
			...prev,
			skip: 0,
			type,
		}))
	}, [])

	const loadMore = useCallback(() => {
		setFilters((prev) => ({
			...prev,
			skip: prev.skip + prev.take,
		}))
	}, [])

	return {
		filters,
		onChangeType,
		loadMore,
	}
}

import { ClickToComponent } from 'click-to-react-component'
import ReactDOM from 'react-dom'

import App from '@/App'
import '@/index.css'
import Providers from '@/Providers'
import reportWebVitals from '@/reportWebVitals'

import { initApp } from './init'
;(async () => {
	await initApp()

	ReactDOM.render(
		<Providers>
			<ClickToComponent />
			<App />
		</Providers>,
		document.getElementById('root'),
	)
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

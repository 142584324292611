import { AnalyticsFrame } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { Layout } from '../components/Layout'
import { DashboardType } from '../types/dashboardTypes'

const DashboardMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Home"
				subHeader="Learn about user activity, including Daily, Weekly, and Monthly Active Users. Data collection began October 3rd, 2023."
			>
				<AnalyticsFrame dashboardType={DashboardType.HOME} startDate="2023-10-01" />
			</CommonCard>
		</Layout>
	)
}

export default DashboardMainPage

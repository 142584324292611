import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import ItemsSectionPagination from '@/components/ItemsSections/ItemsSectionPagination'
import { useMemo } from 'react'

interface AutoLoadingSectionProps {
	placeholder?: string
	handleLoadMoreItems?: () => void
	canLoadMore?: boolean
}

interface PaginationSectionProps {
	fullWidth?: boolean
}

interface SectionProps {
	isLoading: boolean
	items: any[]
	renderItems: (items: any) => React.ReactNode
	gridGap?: number
	centered?: boolean
	showPagination?: boolean
	stretched?: boolean
}

type ExperienceCardsSectionProps = SectionProps & PaginationSectionProps & AutoLoadingSectionProps

enum CardSizes {
	LG = '344px',
	MD = '310px',
	SM = '344px',
	XS = '80vw',
}

const useStyles = makeStyles((theme) => ({
	cardContainer: ({ fullWidth, gridGap, centered, stretched }: any) => ({
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, ${CardSizes.LG})`,
		justifyContent: centered ? 'space-evenly' : stretched ? 'space-between' : 'flex-start',
		gridGap: gridGap,
		margin: '1vh 2vw',
		width: fullWidth ? '100%' : STYLES.FILL_AVAILABLE_WIDTH,
		[theme.breakpoints.up('xl')]: {
			gridTemplateColumns: `repeat(auto-fill, ${CardSizes.LG})`,
		},
		[theme.breakpoints.down('lg')]: {
			gridTemplateColumns: `repeat(auto-fill, ${CardSizes.MD})`,
		},
		[theme.breakpoints.down('md')]: {
			justifyContent: 'space-evenly',
		},
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: `repeat(auto-fill, ${CardSizes.SM})`,
			width: fullWidth ? '100%' : '96vw',
		},
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: `repeat(auto-fill, ${CardSizes.XS})`,
		},
	}),
}))

// @TODO: type needs to be corrected here
// @TODO: instead of renderItems it should be single memoized item renderItem,
// this component going to render entire list on very pagination or state change
const ExperienceCardsSection = ({
	placeholder,
	handleLoadMoreItems = () => {},
	items = [],
	isLoading = false,
	canLoadMore = false,
	renderItems,
	gridGap = 10,
	centered = false,
	showPagination = false,
	fullWidth = false,
	stretched = false,
}: ExperienceCardsSectionProps) => {
	const classes = useStyles({ fullWidth, gridGap, centered, stretched })
	const cardClasses = useMemo(
		() => ({
			content: classes.cardContainer,
		}),
		[classes.cardContainer],
	)
	return !showPagination ? (
		<ItemsSection
			placeholder={placeholder}
			handleLoadMoreItems={handleLoadMoreItems}
			items={items}
			isLoading={isLoading}
			canLoadMore={canLoadMore}
			renderItems={renderItems}
			classnames={cardClasses}
		/>
	) : (
		<ItemsSectionPagination items={items} renderItems={renderItems} fullWidth={fullWidth} classnames={cardClasses} />
	)
}

export default ExperienceCardsSection

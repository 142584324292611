import { SESSION_TIME } from '@/constants/configuration'
import settings from '@/constants/http'
import { baseApi } from '../general/baseApi'
import { paginateQuery } from '../utils'
import DtoEvent from './types/dto/DtoEvent'
import DtoEventCategoryResults from './types/dto/DtoEventCategoryResults'
import DtoEventResults from './types/dto/DtoEventResults'
import SearchEventsParams from './types/queryParams/SearchEventsParams'
import CreateEventRsvpParams from './types/queryParams/CreateEventRsvpParams'
import utils from '@/utils'
import ReportEventParams from './types/queryParams/ReportEventParams'
import PatchEventRsvpParams from './types/queryParams/PatchEventRsvpParams'
import DtoUserEventsCounts from './types/dto/DtoUserEventsCounts'
import GetUserEventsCountsParams from './types/queryParams/GetUserEventsCountsParams'
import GetUserEventsByTypeParams from './types/queryParams/GetUserEventsByTypeParams'
import { applyReducer } from 'fast-json-patch'
import { RootState } from '@/store'
import GetEventByIdParams from './types/queryParams/GetEventByIdParams'
import ExtendFallBackImageParam from '../types/ExtendFallBackImageParam'
import isEqual from 'lodash/isEqual'

const API_BASE_URL = '/events'

export const EVENT_TAGS = {
	SINGLE: 'Event',
}

export const eventsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(EVENT_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			searchEvents: builder.query<DtoEventResults, ExtendFallBackImageParam<SearchEventsParams>>({
				query: ({
					skip,
					take = settings.SEARCH_LIST_PER_PAGE,
					endsAfter = new Date().toISOString(),
					withFallBackImage = true,
					...rest
				}) => {
					const queryArgs = utils.rtkQueryUtils.generateQueryParams({
						...rest,
						endsAfter,
					})

					return {
						url: `${API_BASE_URL}/search?${queryArgs}`,
						params: {
							skip: skip ?? 0,
							take,
							withFallBackImage,
						},
					}
				},
				transformResponse: (response: DtoEventResults) => {
					return {
						canLoadMore: response.items.length !== 0,
						...response,
					}
				},
				serializeQueryArgs: ({ endpointName }) => {
					const serializedKey = `${endpointName}`
					return serializedKey
				},
				merge: (currentCache, { items, totalItems, canLoadMore }, { arg: { skip = 0 } }) => {
					if (skip > 0) {
						currentCache.items.push(...items)
						currentCache.totalItems = totalItems
					} else {
						currentCache.items = items
						currentCache.totalItems = totalItems
					}
					currentCache.canLoadMore = canLoadMore
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
			}),
			getEventById: builder.query<DtoEvent, ExtendFallBackImageParam<GetEventByIdParams>>({
				query: ({ id, withFallBackImage = true, includeResponse = true }) => ({
					url: `${API_BASE_URL}/${id}`,
					params: {
						includeResponse,
						withFallBackImage,
					},
				}),
				providesTags: (result, error, { id }) => [{ type: EVENT_TAGS.SINGLE, id }],
			}),
			getCategories: builder.query<DtoEventCategoryResults, void>({
				query: () => ({
					url: `${API_BASE_URL}/categories`,
					params: {
						take: 50,
						skip: 0,
					},
				}),
				keepUnusedDataFor: SESSION_TIME,
			}),
			createEventRsvp: builder.mutation<DtoEvent['response'], CreateEventRsvpParams>({
				query: ({ eventId, ...body }) => ({
					url: `${API_BASE_URL}/${eventId}/rsvp`,
					method: 'POST',
					body,
				}),
				onQueryStarted: async (
					{ eventId, response, answers = '', guests = 0, organizationIds = [] },
					{ dispatch, queryFulfilled, getState },
				) => {
					const userId = (getState() as RootState).AUTH.userInfo.id
					const patchEvent = dispatch(
						eventsApi.util.updateQueryData('getEventById', { id: String(eventId) }, (draft) => {
							draft.response = { eventId, answers, guests, organizationIds, response }
						}),
					)
					const patchUserEventsCount = dispatch(
						eventsApi.util.updateQueryData('getUserEventsCounts', { userId }, (draft) => {
							if (response === 'Yes') {
								draft.upcoming += 1
							}
						}),
					)
					try {
						const serverData = await queryFulfilled
						dispatch(
							eventsApi.util.updateQueryData('getEventById', { id: String(eventId) }, (draft) => {
								draft.response = serverData.data
							}),
						)
					} catch {
						patchEvent.undo()
						patchUserEventsCount.undo()
					}
				},
			}),
			patchEventRsvp: builder.mutation<DtoEvent['response'], PatchEventRsvpParams>({
				query: ({ eventId, id, body }) => ({
					url: `${API_BASE_URL}/${eventId}/rsvp/${id}`,
					method: 'PATCH',
					body,
				}),

				onQueryStarted: async ({ body, eventId, id }, { dispatch, queryFulfilled, getState }) => {
					let newResponse = null
					const userId = (getState() as RootState).AUTH.userInfo.id
					const patchEvent = dispatch(
						eventsApi.util.updateQueryData('getEventById', { id: String(eventId) }, (draft) => {
							const oldResponse = draft.response.response
							draft.response = { ...draft.response, ...body.reduce(applyReducer, draft.response) }

							newResponse = oldResponse !== draft.response.response ? draft.response.response : null
						}),
					)
					const patchUserEventsCount = dispatch(
						eventsApi.util.updateQueryData('getUserEventsCounts', { userId }, (draft) => {
							// update count only if user response has been changed
							if (newResponse) {
								draft.upcoming += newResponse === 'Yes' ? 1 : -1
							}
						}),
					)
					try {
						const serverData = await queryFulfilled
						dispatch(
							eventsApi.util.updateQueryData('getEventById', { id: String(eventId) }, (draft) => {
								draft.response = serverData.data
							}),
						)
					} catch {
						patchEvent.undo()
						patchUserEventsCount.undo()
					}
				},
			}),
			reportEvent: builder.mutation<void, ReportEventParams>({
				query: ({ id, ...body }) => ({
					url: `${API_BASE_URL}/${id}/report`,
					method: 'POST',
					body,
				}),
			}),
			getUserEventsCounts: builder.query<DtoUserEventsCounts, GetUserEventsCountsParams>({
				query: ({ userId }) => ({
					url: `/users/${userId}/events/aggregate`,
				}),
			}),
			getUserEventsByType: builder.query<DtoEventResults, GetUserEventsByTypeParams>({
				query: ({ userId, type, ...params }) => ({
					url: `/users/${userId}/events/${type}`,
					params: { ...params, withFallBackImage: true },
				}),
				serializeQueryArgs: ({ endpointName, queryArgs: { type } }) => {
					const serializedKey = `${endpointName}-${type}`
					return serializedKey
				},
				...paginateQuery,
			}),
		}),
	})

export const {
	useGetEventByIdQuery,
	useSearchEventsQuery,
	useGetCategoriesQuery,
	useCreateEventRsvpMutation,
	useReportEventMutation,
	usePatchEventRsvpMutation,
	useGetUserEventsCountsQuery,
	useGetUserEventsByTypeQuery,
} = eventsApi

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

import { PaginationListParams } from '@/hooks/useInfiniteScrollList'
import { MessageData, TReactions } from '@/store/messages/types'
import { MakeOptional } from '@/store/types'

export const createMessage = async (userId: number, data: MakeOptional<MessageData, 'id'>, schoolId: number) => {
	const url = `${schoolId}/messages/${userId}/create`

	return http.post<{ id: string }>(url, data)
}

export const getChatsList = async (userId: number, schoolId: number) => {
	const url = `${schoolId}/messages/${userId}/messages`

	return http.get(url)
}

interface GetMessagesParams {
	userId: number
	targetUserId: number
	offset: number
	direction: 'ASC' | 'DESC'
	fromMessageId?: string
	schoolId?: number
}

export const getMessages = async ({ userId, targetUserId, offset, direction, fromMessageId, schoolId }: GetMessagesParams) => {
	const url = `${schoolId}/messages/${userId}/user/${targetUserId}`

	const params = {
		direction,
		offset,
		perPage: settings.SEARCH_MESSAGES_PER_PAGE,
		fromMessageId,
	}

	return http.get(url, { params })
}

interface SearchMessagesParams {
	userId: number
	page: number
	q?: string
	limit?: number
	schoolId?: number
}

export const searchMessages = async ({ userId, page, q, limit, schoolId }: SearchMessagesParams) => {
	const url = `${schoolId}/messages/${userId}/message/search`

	const params = {
		q,
		page,
		perPage: limit || settings.SEARCH_MESSAGES_PER_PAGE,
	}

	return http.get(url, { params })
}

interface deleteMessageParams {
	schoolId: number
	id: string
}

export const removeMessageById = async ({ schoolId, id }: deleteMessageParams) => {
	const url = `${schoolId}/messages/${id}`
	return http.delete(url)
}

export type TMessageReactionsApiParams = { messageId: string; type?: TReactions; responseType?: 'object' | 'plain'; schoolId: number }
// @TODO: can be refactored and reused as TUserBasicInfo
export type TMessageReactionsDto = { id: number; externalId: string; photoUrl: string; firstName: string; lastName: string }

export const loadMessageReactions = ({
	messageId,
	page,
	limit,
	type = 'Like',
	responseType = 'object',
	schoolId,
}: PaginationListParams<TMessageReactionsApiParams>) => {
	const url = `${schoolId}/messages/${messageId}/reaction`
	return http.get<TMessageReactionsDto[]>(url, { params: { page, limit, type, responseType } })
}

const messages = {
	getMessages,
	getChatsList,
	createMessage,
	searchMessages,
	removeMessageById,
}

export default messages

import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { ColorButton, CommonModalCard, CommonSelect, FormModalCard, Label, Modal, OutlinedButton, TextField } from '@/components'
import FieldErrorMessage from '@/components/FieldErrorMessage'
import IconWrapper from '@/components/IconWrapper'
import NameEditor from '@/components/UserProfile/EditUserModal/NameEditor'
import PopupModalCard from '@/components/UserProfile/EditUserModal/PopupModalCard'
import PrimaryTitleEditor from '@/components/UserProfile/EditUserModal/PrimaryTitleEditor'
import UserPhotoDropArea from '@/components/UserProfile/UserPhotoDropArea'
import { intendedGraduationTermOptions, intendedGraduationYearOptions, pronouns } from '@/constants/defaultValues'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import { useProfilePhoto } from '@/hooks/useProfilePhoto'
import { UserInfo } from '@/interfaces/user'
import { useAppDispatch } from '@/store'
import { updateUserProfileRequest } from '@/store/auth'
import { getUserRole, isStudent } from '@/utils/authHandlers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	form: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	formFieldsContainer: {
		width: '100%',
	},
	selectsContainer: {
		width: '60%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	select: {
		width: '48%',
	},
	logoInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	logoInput: {
		marginLeft: -5,
		marginRight: 15,
		width: 25,
		height: 25,
	},
	changeProfilePictureText: {
		margin: '30px 0',
	},
	textFieldWithoutLabel: {
		margin: '0.5vh 0',
	},
	updateProfileButton: {
		padding: '1vh 1vw',
		minWidth: 250,
		fontWeight: 600,
	},
	popupModalTitle: {
		fontWeight: 700,
		textAlign: 'center',
	},
	popupModalContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '4vh 0',
		textAlign: 'center',
	},
	popupModalFooter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		textAlign: 'center',
	},
	popupList: {
		marginBottom: '4vh',
		alignItems: 'start',
		textAlign: 'left',
	},
	unsavedChangesModalButton: {
		width: '90%',
		margin: '0.5vh 0',
		padding: '1vh 0',
	},
	popupButton: {
		padding: '1vh 3vw',
		fontWeight: 600,
	},
	membershipPopupCard: {
		width: '30vw',
		[theme.breakpoints.down('lg')]: {
			width: '60vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
			minWidth: 300,
		},
	},
	addMembershipButton: {
		width: '100%',
	},
	lockIcon: {
		width: 15,
		marginBottom: -5,
	},
	primaryTitlePopup: {
		marginBottom: '3vh',
	},
	cancelChangesModalCard: {
		color: theme.palette.text.primary,
		textAlign: 'center',
		padding: '25px 60px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cancelChangesModalButton: {
		marginTop: 15,
		minWidth: 230,
		height: 50,
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
}))

interface EditUserModalProps {
	isOpen: boolean
	userInfo: UserInfo
	onClose: () => void
}

enum EditUserModals {
	campus = 'campus',
	unsavedChanges = 'unsavedChanges',
	intendedMajors = 'intendedMajors',
	preferredPronouns = 'preferredPronouns',
	intendedMinors = 'intendedMinors',
	primaryTitle = 'primaryTitle',
}

const FORM = {
	bio: 'bio',
}

const schema = yup.object().shape({
	[FORM.bio]: yup
		.string()
		.nullable()
		.max(1000, ({ max }) => `Must be less than ${max} characters`),
})

const EditUserModal = ({ isOpen = false, onClose = () => {}, userInfo }: EditUserModalProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const formRef = useRef(null)
	const dispatch = useAppDispatch()

	const campuses = useSelector(getActiveCampuses)
	const isStudentRole = isStudent(getUserRole(userInfo.appUserTypeId))

	const [isOpenModal, setIsOpenModal] = useState({
		[EditUserModals.campus]: false,
		[EditUserModals.unsavedChanges]: false,
		[EditUserModals.intendedMajors]: false,
		[EditUserModals.preferredPronouns]: false,
		[EditUserModals.intendedMinors]: false,
		[EditUserModals.primaryTitle]: false,
	})

	const handleTogglePopupModal = (modalName: EditUserModals) => () =>
		setIsOpenModal({ ...isOpenModal, [modalName]: !isOpenModal[modalName] })

	const intendedGraduation = userInfo.intendedGraduationDate ? JSON.parse(userInfo.intendedGraduationDate) : null

	const defaultValues = useMemo(
		() => ({
			userName: {
				firstName: userInfo.firstName,
				lastName: userInfo.lastName,
			},
			primaryTitleData: {
				primaryTitle: userInfo.primaryTitle,
				primaryTitleDepartment: userInfo.primaryTitleDepartment,
			},

			preferredPronouns: userInfo.preferredPronouns,
			positionTitle: userInfo.positionTitle,
			department: userInfo.department,
			bio: userInfo.bio,
			intendedGraduationTerm: intendedGraduation ? intendedGraduation['term'] : '',
			intendedGraduationYear: intendedGraduation ? String(intendedGraduation['year']) : '',
			websiteUrl: userInfo.websiteUrl,
			youtubeUrl: userInfo.youtubeUrl,
			twitterUrl: userInfo.twitterUrl,
			linkedinUrl: userInfo.linkedinUrl,
			instagramUrl: userInfo.instagramUrl,
			facebookUrl: userInfo.facebookUrl,
		}),
		[intendedGraduation, userInfo],
	)

	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty, errors },
	} = useForm<any>({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues,
	})

	const handleCloseModal = () => {
		if (isDirty) {
			handleTogglePopupModal(EditUserModals.unsavedChanges)()
		} else {
			onClose()
		}
	}

	const showCampuses = useSelector(selectShowCampuses)

	const { mutate } = useProfilePhoto(userInfo.externalId, true)

	const onSubmit = async (data: any) => {
		onClose()
		const { profilePhotoUpdated } = await dispatch(updateUserProfileRequest(data)).unwrap()
		if (profilePhotoUpdated) {
			mutate()
		}
	}

	useEffect(() => {
		if (isOpen) reset(cloneDeep(defaultValues))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<div className={classes.form}>
				<Modal isOpen={isOpenModal.campus} onClose={handleTogglePopupModal(EditUserModals.campus)}>
					<PopupModalCard handleClose={handleTogglePopupModal(EditUserModals.campus)}>
						<Typography className={classes.popupModalTitle}>Campus</Typography>
						<div className={classes.popupModalContent}>
							<Typography>Campus can only be edited in system of record.</Typography>
						</div>
					</PopupModalCard>
				</Modal>
				<Modal isOpen={isOpenModal.unsavedChanges} onClose={handleTogglePopupModal(EditUserModals.unsavedChanges)}>
					<CommonModalCard onClose={handleTogglePopupModal(EditUserModals.unsavedChanges)} title="Unsaved Changes">
						<div className={classes.cancelChangesModalCard}>
							<Typography>Your changes will not be saved.</Typography>
							<Typography>Are you sure you want to cancel?</Typography>
							<ColorButton
								className={classes.cancelChangesModalButton}
								onClick={() => {
									handleTogglePopupModal(EditUserModals.unsavedChanges)()
									onClose()
								}}
							>
								Yes, cancel
							</ColorButton>
							<OutlinedButton className={classes.cancelChangesModalButton} onClick={handleTogglePopupModal(EditUserModals.unsavedChanges)}>
								No, keep editing
							</OutlinedButton>
						</div>
					</CommonModalCard>
				</Modal>
				<Modal isOpen={isOpenModal.intendedMajors} onClose={handleTogglePopupModal(EditUserModals.intendedMajors)}>
					<PopupModalCard handleClose={handleTogglePopupModal(EditUserModals.intendedMajors)}>
						<Typography className={classes.popupModalTitle}>Intended Majors</Typography>
						<div className={classes.popupModalContent}>
							<Typography>Majors can only be edited in system of record.</Typography>
						</div>
					</PopupModalCard>
				</Modal>
				<Modal isOpen={isOpenModal.preferredPronouns} onClose={handleTogglePopupModal(EditUserModals.preferredPronouns)}>
					<PopupModalCard handleClose={handleTogglePopupModal(EditUserModals.preferredPronouns)}>
						<Typography className={classes.popupModalTitle}>Pronouns</Typography>
						<div className={classes.popupModalContent}>
							<Typography>Let others know how to refer to you.</Typography>
							<Typography>Pronouns are currently self reported. In the future, these will be automated from your student record</Typography>
						</div>
					</PopupModalCard>
				</Modal>
				<Modal isOpen={isOpenModal.primaryTitle} onClose={handleTogglePopupModal(EditUserModals.primaryTitle)}>
					<PopupModalCard handleClose={handleTogglePopupModal(EditUserModals.primaryTitle)}>
						<Typography className={classes.popupModalTitle}>Primary Title</Typography>
						{isStudentRole ? (
							<>
								<div className={classes.popupModalContent}>
									<Typography>
										Let others know what you do by adding a primary title, which will appear under your name throughout the app.
									</Typography>
								</div>
								<div className={classNames(classes.popupModalFooter, classes.primaryTitlePopup)}>
									<Typography>You can add additional titles in the membership section of your profile.</Typography>
								</div>
							</>
						) : (
							<>
								<div className={classNames(classes.popupModalContent, classes.primaryTitlePopup)}>
									<Typography>
										Your primary title appears under your name throughout the app. It defaults to your primary position. Editing your
										primary title will not change your primary position.
									</Typography>
								</div>
							</>
						)}
					</PopupModalCard>
				</Modal>
				<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
					<FormModalCard
						title="Edit Profile"
						handleClose={handleCloseModal}
						footerContent={
							<ColorButton className={classes.updateProfileButton} disabled={!isDirty} type="submit">
								Save
							</ColorButton>
						}
						showFooter
					>
						<Controller
							name="photoUrl"
							control={control}
							render={({ field: { value, onChange } }) => (
								<UserPhotoDropArea externalUserId={userInfo.externalId} userPhotoUrl={userInfo.photoUrl} url={value} onChange={onChange} />
							)}
						/>
						<div className={classes.formFieldsContainer}>
							<Controller
								name="userName"
								control={control}
								render={({ field: { value, onChange } }) => <NameEditor userName={value} onFinishEdit={onChange} />}
							/>
							<Label
								classnames={{ label: classes.label }}
								title="Pronouns"
								semiBold={true}
								onClickQuestion={handleTogglePopupModal(EditUserModals.preferredPronouns)}
							>
								<Controller
									name="preferredPronouns"
									control={control}
									render={({ field: { value, onChange } }) => (
										<CommonSelect
											classnames={{
												input: classes.select,
											}}
											value={value}
											onChange={onChange}
											options={pronouns}
											placeholder={userInfo.preferredPronouns || 'Add pronouns'}
										/>
									)}
								/>
							</Label>

							<Controller
								name="primaryTitleData"
								control={control}
								render={({ field: { value, onChange } }) => (
									<PrimaryTitleEditor
										value={value}
										onFinishEdit={onChange}
										onClickQuestion={handleTogglePopupModal(EditUserModals.primaryTitle)}
									/>
								)}
							/>
							<Controller
								name={FORM.bio}
								control={control}
								render={({ field }) => (
									<Label classnames={{ label: classes.label }} title="Bio" semiBold={true}>
										<TextField
											placeholderColor={appTheme.palette.primary.main}
											placeholder="Add a bio"
											error={!!errors['bio']}
											{...field}
										/>
										<FieldErrorMessage
											hasError={!!errors[FORM.bio]}
											message={typeof errors[FORM.bio]?.message === 'string' ? (errors[FORM.bio]?.message as string) : null}
										/>
									</Label>
								)}
							/>
							{isStudentRole ? (
								<>
									{showCampuses && (
										<Label
											classnames={{ label: classes.label }}
											title="Campus"
											semiBold={true}
											disabled
											onClickQuestion={handleTogglePopupModal(EditUserModals.campus)}
										>
											<TextField value={userInfo.campus} placeholder="Campus" disabled />
										</Label>
									)}
									<Label
										classnames={{ label: classes.label }}
										title="Intended Majors"
										semiBold={true}
										disabled
										onClickQuestion={handleTogglePopupModal(EditUserModals.intendedMajors)}
									>
										<TextField placeholder="Intended Majors" value={userInfo.major} disabled />
									</Label>
									<Label classnames={{ label: classes.label }} title="Intended Graduation Date" semiBold={true}>
										<div className={classes.selectsContainer}>
											<Controller
												name="intendedGraduationTerm"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CommonSelect
														classnames={{
															input: classes.select,
														}}
														value={value}
														onChange={onChange}
														options={intendedGraduationTermOptions}
														placeholder="Term"
													/>
												)}
											/>
											<Controller
												name="intendedGraduationYear"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CommonSelect
														classnames={{
															input: classes.select,
														}}
														value={value}
														onChange={onChange}
														options={intendedGraduationYearOptions}
														placeholder="Year"
													/>
												)}
											/>
										</div>
									</Label>
								</>
							) : (
								<>
									{showCampuses && (
										<Label classnames={{ label: classes.label }} title="Campus" semiBold={true}>
											<Controller
												name="campus"
												control={control}
												render={({ field: { value, onChange } }) => (
													<CommonSelect
														classnames={{
															input: classes.select,
														}}
														value={value}
														onChange={onChange}
														options={campuses}
														placeholder={userInfo.campus || 'Select'}
													/>
												)}
											/>
										</Label>
									)}
									<Label classnames={{ label: classes.label }} title="Primary Position" semiBold={true}>
										<Controller
											name="positionTitle"
											control={control}
											render={({ field }) => (
												<TextField
													placeholderColor={appTheme.palette.primary.main}
													placeholder="Edit Position"
													error={!!errors['positionTitle']}
													{...field}
												/>
											)}
										/>
										<Controller
											name="department"
											control={control}
											render={({ field }) => (
												<TextField
													placeholderColor={appTheme.palette.primary.main}
													placeholder="Edit unit/department"
													error={!!errors['department']}
													{...field}
												/>
											)}
										/>
									</Label>
								</>
							)}
							<Label classnames={{ label: classes.label }} title="Social Media" semiBold={true}>
								<Controller
									name="websiteUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="link" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add Website URL"
												error={!!errors['websiteUrl']}
												{...field}
											/>
										</div>
									)}
								/>
								<Controller
									name="linkedinUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="socialMediaLinkedIn" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add LinkedIn URL"
												error={!!errors['linkedinUrl']}
												{...field}
											/>
										</div>
									)}
								/>
								<Controller
									name="instagramUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="socialMediaInstagram" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add Instagram URL"
												error={!!errors['instagramUrl']}
												{...field}
											/>
										</div>
									)}
								/>
								<Controller
									name="twitterUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="socialMediaTwitter" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add Twitter URL"
												error={!!errors['twitterUrl']}
												{...field}
											/>
										</div>
									)}
								/>
								<Controller
									name="youtubeUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="socialMediaYoutube" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add YouTube URL"
												error={!!errors['youtubeUrl']}
												{...field}
											/>
										</div>
									)}
								/>
								<Controller
									name="facebookUrl"
									control={control}
									defaultValue=""
									render={({ field }) => (
										<div className={classes.logoInputContainer}>
											<IconWrapper iconKey="socialMediaFacebook" color={appTheme.palette.text.primary} className={classes.logoInput} />
											<TextField
												placeholderColor={appTheme.palette.text.secondary}
												placeholder="Add Facebook URL"
												error={!!errors['facebookUrl']}
												{...field}
											/>
										</div>
									)}
								/>
							</Label>
						</div>
					</FormModalCard>
				</form>
			</div>
		</Modal>
	)
}

export default EditUserModal

export const marginStyles = {
	marginTop5: {
		marginTop: 5,
	},
	marginTop10: {
		marginTop: 10,
	},
	marginTop20: {
		marginTop: 20,
	},
	marginLeft5: {
		marginLeft: 5,
	},
}

import { useAppDispatch } from '@/store'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { Modal } from '@/components'
import ColorButton from '@/components/Buttons/ColorButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { isEngage } from '@/constants/configuration'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { getIsOpenWelcomeNewUserPopup, setIsOpenWelcomeNewUserPopup } from '@/store/auth'
import { rootStyles } from '@/styles'
import { submitFeedback } from '@/utils/services'
import { ChevronLeft } from '@material-ui/icons'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Close from '@material-ui/icons/Close'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { Slide } from './Slide'
import { SlideNavigationDot } from './SlideNavigationDot'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '95%',
		maxWidth: 500,
		position: 'relative',
	},
	slideWrapper: { ...rootStyles.flexCenteredItemsY },
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '35px 0',
	},
	button: {
		width: 220,
		height: 50,
		margin: '5px 0',
	},
	closeButton: {
		...rootStyles.buttonPlain,
		position: 'absolute',
		top: 20,
		right: 20,
	},
	navigationDots: {
		...rootStyles.flexCenteredItems,
		gap: 5,
		marginTop: 32,
	},
	navigationArrowWrapper: {
		...rootStyles.buttonPlain,
	},
	navigationArrow: {
		width: 30,
		height: 30,
	},
	disabledNavigationArrowWrapper: {
		visibility: 'hidden',
	},
}))

export const NewUserWelcomeModal = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const isOpen = useSelector(getIsOpenWelcomeNewUserPopup)

	const handleClose = useCallback(() => dispatch(setIsOpenWelcomeNewUserPopup(false)), [dispatch])
	const handleSubmitFeedback = useCallback(() => {
		handleClose()
		submitFeedback()
	}, [handleClose])
	const {
		features: {
			welcome: { config: welcomeSlides },
		},
	} = useSchoolConfig()
	const slides = useMemo(() => {
		const conditionalSlide = !isEngage() ? [welcomeSlides.pathBuilder] : []
		return [
			welcomeSlides.happening,
			welcomeSlides.follow,
			welcomeSlides.meet,
			welcomeSlides.find,
			...conditionalSlide,
			{
				...welcomeSlides.feedback,
				renderFooter: () => {
					return (
						<div>
							<div className={classes.buttonsContainer}>
								<ColorButton className={classes.button} onClick={handleSubmitFeedback}>
									Submit feedback
								</ColorButton>
								<OutlinedButton className={classes.button} onClick={handleClose}>
									Skip for now
								</OutlinedButton>
							</div>
						</div>
					)
				},
			},
		]
	}, [welcomeSlides, classes.buttonsContainer, classes.button, handleSubmitFeedback, handleClose])

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

	const onClickNext = () => setCurrentSlideIndex(currentSlideIndex + 1)
	const onClickBack = () => setCurrentSlideIndex(currentSlideIndex - 1)

	const canClickBack = currentSlideIndex > 0
	const canClickNext = currentSlideIndex < slides.length - 1

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<button className={classes.closeButton} onClick={handleClose}>
				<Close />
			</button>
			<div className={classes.slideWrapper}>
				<button
					className={classNames(classes.navigationArrowWrapper, {
						[classes.disabledNavigationArrowWrapper]: !canClickBack,
					})}
					onClick={onClickBack}
				>
					<ChevronLeft className={classes.navigationArrow} />
				</button>

				<Slide {...{ ...slides[currentSlideIndex] }} />

				<button
					className={classNames(classes.navigationArrowWrapper, {
						[classes.disabledNavigationArrowWrapper]: !canClickNext,
					})}
					onClick={onClickNext}
				>
					<ChevronRight className={classes.navigationArrow} />
				</button>
			</div>
			<div className={classes.navigationDots}>
				{slides.map((_, i) => {
					return <SlideNavigationDot key={i} {...{ isSelected: i === currentSlideIndex, onClick: () => setCurrentSlideIndex(i) }} />
				})}
			</div>
		</Modal>
	)
}

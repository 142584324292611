import { TextButton } from '@/components/Buttons'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { UserReportedInterest } from '../../interfaces/user'
import { ProfileInfoCardTypes } from '../../utils/userExperienceItems'
import CategoryTag from '../CategoryTag'
import Card from '../CommonCard'
import EmptyProfileInfoItemPlaceholder from './UserExperienceItems/EmptyProfileInfoItemPlaceholder'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	itemImage: {
		[theme.breakpoints.up('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '3.5vw',
			height: '3.5vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 50,
			minHeight: 50,
		},
	},
	itemContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 10,
	},
	volunteerInfo: {
		marginTop: '1vh',
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		marginLeft: '1.5vw',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	exploreBtn: {
		marginLeft: 3,
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
	},
	titleText: {
		fontWeight: 400,
	},
}))

interface UserInterestsCardProps {
	items?: UserReportedInterest[]
	canEdit?: boolean
	handleOpenEditModal: () => void
}

const UserInterestsCard = ({ items, handleOpenEditModal, canEdit = false }: UserInterestsCardProps) => {
	const classes = useStyles()

	const hasItems = !!items?.length

	if (!hasItems && !canEdit) return null

	return (
		<Card>
			<div className={classes.headerContainer}>
				<Typography className={classes.boldText} variant="h3">
					{ProfileInfoCardTypes.INTERESTS}
				</Typography>
				{canEdit && <TextButton onClick={handleOpenEditModal}>Edit</TextButton>}
			</div>
			<div className={classes.itemContainer}>
				{hasItems ? (
					items.map((item) => <CategoryTag key={item.id} name={item.interest} />)
				) : (
					<EmptyProfileInfoItemPlaceholder
						classnames={{
							title: classes.titleText,
						}}
						type={ProfileInfoCardTypes.INTERESTS}
						onClickAdd={handleOpenEditModal}
						showSubTitle={false}
					/>
				)}
			</div>
		</Card>
	)
}

export default UserInterestsCard

import { STYLES } from '@/constants'
import { makeStyles } from '@material-ui/core'

export const useHeaderRootStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			padding: '0 7%',
		},
	},
}))

import { profileLink } from '@/utils/common'

import React from 'react'
import { Link } from 'react-router-dom'
import LazyLoadProfileImage from '../LazyLoadProfileImage'
import { makeStyles } from '@material-ui/core'

const BOX_SIZE = 47

const useStyles = makeStyles({
	profileImage: {
		width: BOX_SIZE,
		height: BOX_SIZE,
		minWidth: BOX_SIZE,
		minHeight: BOX_SIZE,
		marginRight: 15,
	},
})

export type IMessageProfileImageProps = {
	userId: number
	photoUrl?: string
	externalId: string
}

const MessageProfileImage: React.FC<IMessageProfileImageProps> = ({ userId, photoUrl, externalId }) => {
	const classes = useStyles()
	return (
		<Link to={profileLink(userId)}>
			<LazyLoadProfileImage className={classes.profileImage} externalUserId={externalId} photoUrl={photoUrl} />
		</Link>
	)
}

export { MessageProfileImage }

import { OAUTH_BASE_URL } from '@/constants/configuration'
import { resolveSchoolId } from '@/utils'
import AuthService from '@/utils/auth/AuthService'

import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'

let authService: AuthService = null

export const initDependencies = () => {
	if (process.env.REACT_APP_ENV !== 'local') {
		Sentry.init({
			environment: process.env.REACT_APP_ENV,
			dsn: 'https://d4749e049bc44e89b9529e77b9181b40@o569813.ingest.sentry.io/6541999',
			// @TODO this needed for upload source maps
			// further reading https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps/uploading/cli/
			// release: process.env.APP_VERSION | 0.1.0
			integrations: [
				new Sentry.BrowserTracing(),
				new CaptureConsoleIntegration({
					// array of methods that should be captured
					// defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
					levels: ['error', 'warn', 'info'],
				}),
			],
			normalizeDepth: 10,
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
		})

		Sentry.setContext('school', {
			schoolId: resolveSchoolId(),
		})
	}
}

export const getLogger = () => {
	return Sentry
}

export const getAuthService = (): AuthService => {
	if (!authService) {
		authService = new AuthService(OAUTH_BASE_URL, resolveSchoolId())
	}
	return authService
}

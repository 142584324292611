import { Link, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Person from '@material-ui/icons/Person'
import classNames from 'classnames'

import { CampusIcon, Card, DegreeHatIcon, IconWrapper, TextButton } from '@/components'
import { UserInfo } from '@/interfaces/user'
import { getUserRole, isStudent } from '@/utils/authHandlers'
import { addPrefixToLink } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	categories: {
		color: theme.palette.text.secondary,
		margin: '0.5vh 0',
	},
	aboutBlock: {
		display: 'flex',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	materialIcon: {
		marginLeft: -2,
		marginRight: 10,
	},
	icon: {
		marginRight: 10,
	},
	mailIcon: {
		marginRight: 10,
	},
	campusIcon: {
		marginLeft: -2,
		marginRight: 10,
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	spaceBetweenContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1.5vh 0',
	},
	contactsContainer: {
		width: 'fit-content',
		display: 'flex',
		alignItems: 'center',
	},
	contactLink: {
		margin: '0 0.7vw',
		color: theme.palette.text.primary,
	},
	editBtn: {
		color: theme.palette.primary.main,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	archived: {
		marginTop: 10,
		paddingBottom: 10,
		fontStyle: 'italic',
		fontSize: 15,
	},
}))

const convertIntendedGraduationDate = (value: string | undefined) => {
	const jsonData = JSON.parse(value!)
	return `Class of ${jsonData['year']} (${jsonData['term']})`
}

interface AboutCardProps {
	canEdit: boolean
	userInfo: UserInfo
	displayEmail?: boolean
	handleOpenEditModal: () => void
}

const AboutCard = ({
	canEdit,
	userInfo: {
		bio,
		campus,
		major,
		academicCollege,
		intendedGraduationDate,
		youtubeUrl,
		websiteUrl,
		twitterUrl,
		linkedinUrl,
		instagramUrl,
		facebookUrl,
		appUserTypeId,
		positionTitle,
		department,
		isArchived,
	},
	handleOpenEditModal = () => {},
}: AboutCardProps) => {
	const classes = useStyles()

	const appTheme = useTheme()

	const isStudentProfile = isStudent(getUserRole(appUserTypeId))

	return (
		<Card title="About" titleRightComponent={canEdit && <TextButton onClick={handleOpenEditModal}>Edit</TextButton>}>
			{isArchived && (
				<div className={classNames(classes.aboutBlock, classes.archived)}>
					<Typography>
						Note - this account has been archived. All information is read-only. If you have any questions about this account please contact
						our support team via the feedback form.
					</Typography>
				</div>
			)}
			{bio && (
				<div className={classes.aboutBlock}>
					<Typography>{bio}</Typography>
				</div>
			)}
			{campus && (
				<div className={classes.aboutBlock}>
					<IconWrapper iconKey="location" color={appTheme.palette.text.primary} className={classes.materialIcon} />
					<Typography variant={'body1'}>{campus}</Typography>
				</div>
			)}
			{isStudentProfile ? (
				<>
					{major && (
						<div className={classes.aboutBlock}>
							<IconWrapper iconKey="book" color={appTheme.palette.text.primary} className={classes.icon} />
							<div>
								{major && <Typography>{major}</Typography>}
								{academicCollege && <Typography className={classes.grayText}>{academicCollege}</Typography>}
							</div>
						</div>
					)}
					{intendedGraduationDate && (
						<div className={classes.aboutBlock}>
							<DegreeHatIcon className={classes.icon} />
							<Typography>{convertIntendedGraduationDate(intendedGraduationDate)}</Typography>
						</div>
					)}
				</>
			) : (
				<>
					{department && (
						<div className={classes.aboutBlock}>
							<CampusIcon className={classes.campusIcon} />
							<Typography>{department}</Typography>
						</div>
					)}
					{positionTitle && (
						<div className={classes.aboutBlock}>
							<Person className={classes.materialIcon} />
							<Typography>{positionTitle}</Typography>
						</div>
					)}
				</>
			)}
			<div className={classes.spaceBetweenContainer}>
				<span className={classes.contactsContainer}>
					{linkedinUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(linkedinUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaLinkedIn" color={appTheme.palette.text.primary} />{' '}
						</Link>
					)}
					{instagramUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(instagramUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaInstagram" color={appTheme.palette.text.primary} />
						</Link>
					)}
					{twitterUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(twitterUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaTwitter" color={appTheme.palette.text.primary} />
						</Link>
					)}
					{facebookUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(facebookUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaFacebook" color={appTheme.palette.text.primary} />
						</Link>
					)}
					{youtubeUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(youtubeUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaYoutube" color={appTheme.palette.text.primary} />
						</Link>
					)}
					{websiteUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(websiteUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="link" color={appTheme.palette.text.primary} />
						</Link>
					)}
				</span>
			</div>
		</Card>
	)
}

export default AboutCard

import { TCampus } from '@/features/campus/types/TCampus'
import { OrganizationData, UserData } from '@/interfaces/common'
import { FeedPost } from '@/store/feeds/types'
import { getFullName, getRoleBadgeLabel } from '@/utils/common'
import { Dictionary } from '@reduxjs/toolkit'

// @TODO: need to standardize data sent by backend and avoid
// this kind of transformations causing bugs, in some cases appUserId is missing and we get type
// boolean values sent as string, numbers sent as string, in some cases there is campusId instead of campus string and so on...

export const getNormalizeUser = (user: any, campusMap?: Dictionary<TCampus>): UserData => {
	const campus = user.campus || campusMap ? campusMap[user.campusId] : null

	const fullName = getFullName(user.firstName, user.lastName)

	const type = user.type || getRoleBadgeLabel(user.appUserTypeId)

	const organizationMembershipId = user.organizationMembershipId
	const organizationMembershipTypeId = user.organizationMembershipTypeId

	return {
		id: +user.id,
		firstName: user.firstName,
		lastName: user.lastName,
		fullName,
		type,
		primaryTitle: user.primaryTitle,
		primaryTitleDepartment: user.primaryTitleDepartment,
		externalId: user.externalId,
		numberOfConnections: +user.numberOfConnections,
		connected: !!+user.connected,
		blocked: !!+user.blocked,
		campus: campus?.name || null,
		photoUrl: user.photoUrl,
		organizationMembershipTypeId,
		organizationMembershipId,
		isArchived: !!+user.isArchived,
	}
}

export const getMinimumOrgInfo = (org: any, campusMap?: Dictionary<TCampus>): OrganizationData => {
	const campus = campusMap ? campusMap[org.campusId] : null
	return {
		id: org.id,
		name: org.name,
		photoUrl: org.photoUrl,
		campus: campus?.name,
	}
}

export const normalizePostData = (post: FeedPost, campuses?: Dictionary<TCampus>): FeedPost => ({
	...post,
	ownerUser: post.ownerUser ? getNormalizeUser(post.ownerUser, campuses) : null,
	ownerOrganization: post.ownerOrganization ? getMinimumOrgInfo(post.ownerOrganization, campuses) : null,
	liked: +post.liked > 0,
})

export const prepareCommentData = (comment: any, campuses?: Dictionary<TCampus>) => {
	let updatedData = {
		...comment,
		user: getNormalizeUser(comment.user, campuses),
	}

	updatedData.liked = +updatedData.liked > 0

	return updatedData
}

import { Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CommonModalCard, IconButton, IconWrapper, InfoModalCard } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'

interface StylesProps {
	submitButtonColor?: string
	cancelButtonColor?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: ({ submitButtonColor }) => ({
		color: submitButtonColor || theme.palette.primary.main,
	}),
	submitText: {
		margin: '10px 0',
	},
	doneText: {
		margin: '10px 0',
		fontWeight: 600,
	},
	content: {
		color: theme.palette.text.primary,
		borderTop: `solid 1px ${theme.palette.divider}`,
		width: 'calc(100% - 100px)',
		padding: '20px 50px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	cardRoot: {
		maxWidth: 500,
	},
	successCardRoot: {
		maxWidth: 350,
		minWidth: 300,
		textAlign: 'center',
	},
	cancelButton: ({ cancelButtonColor }) => ({
		color: cancelButtonColor,
		borderRadius: '0 0 8px 8px',
	}),
}))

interface ModalWithSubmittedPopupProps extends StylesProps {
	isOpen: boolean
	onSubmit?: () => void
	onClose?: () => void
	title: string
	content: string[]
	submittedMessage?: string | string[]
	submitButtonLabel?: string
	cancelButtonLabel?: string
	showSubmittedModal?: boolean
	onCloseSubmittedModal?: () => void
}
// TODO: this is anti-pattern there should be one common modal for all this cases
// It should receive buttons and other info via props
export const ModalWithSubmittedPopup = ({
	isOpen,
	onSubmit,
	onClose,
	title,
	content,
	submittedMessage,
	submitButtonLabel = 'Yes',
	cancelButtonLabel = 'Cancel',
	showSubmittedModal = true,
	submitButtonColor,
	cancelButtonColor,
	onCloseSubmittedModal,
}: ModalWithSubmittedPopupProps) => {
	const classes = useStyles({ submitButtonColor, cancelButtonColor })
	const theme = useTheme()

	const [submitted, setSubmitted] = useState(false)

	const handleCloseSubmittedPopup = useCallback(() => {
		setSubmitted(false)
		if (submitted) {
			if (onCloseSubmittedModal) onCloseSubmittedModal()
			else onClose()
		}
	}, [onCloseSubmittedModal, submitted, onClose])

	const handleClose = useCallback(() => {
		handleCloseSubmittedPopup()
		onClose()
	}, [handleCloseSubmittedPopup, onClose])

	const handleConfirm = useCallback(() => {
		onSubmit()

		if (showSubmittedModal) {
			setSubmitted(true)
		} else {
			handleCloseSubmittedPopup()
		}
	}, [handleCloseSubmittedPopup, onSubmit, showSubmittedModal])

	const renderButtons = useMemo(() => {
		const buttons = []
		if (onSubmit) {
			buttons.push({
				label: submitButtonLabel,
				onClick: handleConfirm,
				className: classes.acceptButton,
			})
		}
		if (onClose) {
			buttons.push({
				label: cancelButtonLabel,
				onClick: handleClose,
				className: classes.cancelButton,
			})
		}
		return buttons
	}, [cancelButtonLabel, classes.acceptButton, classes.cancelButton, handleClose, handleConfirm, submitButtonLabel, onClose, onSubmit])

	useEffect(() => {
		if (!isOpen) {
			setSubmitted(false)
		}
	}, [isOpen])

	return (
		<Modal open={isOpen} onClose={handleClose} className={classes.modal}>
			<div>
				{!submitted ? (
					<CommonModalCard
						title={title}
						onClose={handleClose}
						classnames={{
							header: classes.cardHeader,
							card: classes.cardRoot,
						}}
					>
						<div className={classes.content}>
							{content.map((text, idx) => (
								<Typography key={`${text.trim()}-${idx}`}>{text}</Typography>
							))}
						</div>
						{renderButtons.map((btn) => (
							<IconButton key={btn.label} className={classNames(classes.itemPopupButton, btn.className)} onClick={btn.onClick}>
								{btn.label}
							</IconButton>
						))}
					</CommonModalCard>
				) : (
					<InfoModalCard
						onClose={handleClose}
						icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
						className={classes.successCardRoot}
					>
						<Typography className={classes.doneText}>Done</Typography>
						<Typography>{submittedMessage}</Typography>
					</InfoModalCard>
				)}
			</div>
		</Modal>
	)
}

export default ModalWithSubmittedPopup

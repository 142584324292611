import Filter from '@/components/Filter'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import useEqualToPath from '@/hooks/useEqualToPath'
import { Filters, SearchConfig } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { clearFilters, getSearchConfig, setFilters } from '@/store/organizations'
import { createOptionMapper } from '@/utils/common'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import useCurrentUserOrgsCount from '@/anthology/organizations/hooks/useCurrentUserOrgsCount'
import { StoreSearchParams } from '@/store/types'
import { useGetOrgBranchesQuery, useGetOrgCategoriesQuery } from '../api'
import routes from '../routes'
import DtoOrganizationCategory from '../types/dto/DtoOrganizationCategory'
import { DtoOrganization } from '../types/dto/DtoOrganization'

import { Compass, UsersThree } from '@phosphor-icons/react'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	createOrgButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
}))

const OrganizationsPage = () => {
	const classes = useStyles()

	const dispatch = useAppDispatch()
	const searchConfig = useSelector(getSearchConfig)

	const { data, isLoading } = useGetOrgCategoriesQuery()
	const { data: branchData, isLoading: isLoadingBranchData } = useGetOrgBranchesQuery()

	const { data: organizationsCount } = useCurrentUserOrgsCount()

	const canUseFilter = useEqualToPath(PATHS.APP.ORGANIZATIONS)

	const handleClearFilters = () => {
		dispatch(clearFilters())
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			if (canUseFilter) {
				dispatch(setFilters(config as StoreSearchParams))
				window.scrollTo({ top: 0 })
			}
		},
		[dispatch, canUseFilter],
	)

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Organizations',
				icon: <Compass size={ICON_SIZES.md} />,
				path: PATHS.APP.ORGANIZATIONS,
			},
			{
				label: 'My Organizations',
				icon: <UsersThree size={ICON_SIZES.md} />,
				path: PATHS.APP.ORGANIZATIONS_MY,
				info: [`${organizationsCount.followed} Following `, `${organizationsCount.member} Joined`, `${organizationsCount.admin} Admin`],
			},
		],
		[organizationsCount.admin, organizationsCount.followed, organizationsCount.member],
	)
	const categories = useMemo(() => {
		if (data) {
			return data.items.map(createOptionMapper<DtoOrganizationCategory['id']>('id', 'name'))
		} else {
			return []
		}
	}, [data])

	const branches = useMemo(() => {
		if (branchData) {
			return branchData.items.map(createOptionMapper<DtoOrganization['id']>('id', 'name'))
		} else {
			return []
		}
	}, [branchData])

	return (
		<div className={classes.root}>
			<Filter
				title="Organizations"
				isLoading={isLoading && isLoadingBranchData}
				searchPlaceholder="Search organizations"
				value={searchConfig}
				filters={[Filters.categories, Filters.branches]}
				filterOptions={{
					branches,
					categories,
				}}
				canUseFilter={canUseFilter}
				onFilterChange={fetchSearchAndFilter}
				clearFilters={handleClearFilters}
				filterNavigation={filterNavigation}
			/>
			<Router routes={routes} />
		</div>
	)
}

export default OrganizationsPage

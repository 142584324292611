import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'

const getUserInfo = async (id: UserInfo['id'], schoolId: number) => {
	const url = `${schoolId}/users/user/${id}`

	return http.get(url)
}

export default getUserInfo

import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'

import UserProfilePage from '../pages/UserProfilePage'
import UsersListPage from '../pages/UsersListPage'
import { Route } from './config'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()
// Bellow approach is incorrect way to handle routes
const routes: AppRoute[] = [
	{
		component: UsersListPage,
		name: 'analytics-user',
		path: `${PATHS.ADMIN}/${Route.Users}`,
		exact: true,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-list',
		path: `${PATHS.ADMIN}/${Route.Users}/table`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-research-list',
		path: `${PATHS.ADMIN}/${Route.Users}/research`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-interest-list',
		path: `${PATHS.ADMIN}/${Route.Users}/interest`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-studyAbroad-list',
		path: `${PATHS.ADMIN}/${Route.Users}/studyAbroad`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-internship-list',
		path: `${PATHS.ADMIN}/${Route.Users}/internship`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UsersListPage,
		name: 'analytics-users-involvement-list',
		path: `${PATHS.ADMIN}/${Route.Users}/involvement`,
		exact: false,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UserProfilePage,
		name: 'analytics-user-profile',
		path: `${PATHS.ADMIN}/${Route.Users}/:id`,
		exact: true,
		isPrivate: true,
		analyticsRole: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import axios, { AxiosError, AxiosInstance } from 'axios'
import withErrorHandling, { ErrorHandledResult, FnWithErrorHandler } from '../../withErrorHandling'

import DtoTokenResponse from './dto/DtoTokenResponse'
import AuthorizeCodeFlowParams from './params/AuthorizeCodeFlowParams'
import ExchangeCodeForTokenParams from './params/ExchangeCodeForTokenParams'
import RefreshTokenParams from './params/RefreshTokenParams'
import UserInfoParams from './params/UserInfoParams'
import DtoUseInfo from './dto/DtoUserInfo'
import camelizeResponseInterceptor from '@/api/http/interceptors/camelizeResponseInterceptor'

type ConstructWithAxiosError<TResponse> = ErrorHandledResult<TResponse, AxiosError<any, any>>

export default class OauthClient {
	private axiosInstance: AxiosInstance = null

	public constructor(private readonly baseUrl, private readonly handleErrors: FnWithErrorHandler = withErrorHandling) {
		this.axiosInstance = axios.create({ baseURL: baseUrl })
		this.axiosInstance.interceptors.response.use(camelizeResponseInterceptor)
	}

	public async authorizeCodeFlow({ schoolId, ...optionalParams }: AuthorizeCodeFlowParams) {
		const params = new URLSearchParams({
			schoolId: String(schoolId),
		})
		Object.keys(optionalParams).forEach((paramKey) => {
			if (optionalParams[paramKey]) {
				params.append(paramKey, optionalParams[paramKey])
			}
		})

		const url = `${this.baseUrl}/authorize?${params.toString()}`
		window.open(url, '_self')
	}

	public async exchangeCodeForToken(params: ExchangeCodeForTokenParams): Promise<ConstructWithAxiosError<DtoTokenResponse>> {
		try {
			const { data } = await this.axiosInstance.post<DtoTokenResponse>('token', params)
			return [data, null]
		} catch (e) {
			return [null, e]
		}
	}
	public async refreshToken(params: RefreshTokenParams): Promise<ConstructWithAxiosError<DtoTokenResponse>> {
		try {
			const { data } = await this.axiosInstance.post<DtoTokenResponse>('token', params)
			return [data, null]
		} catch (e) {
			return [null, e]
		}
	}
	public async userInfo({ schoolId, authorization }: UserInfoParams): Promise<ConstructWithAxiosError<DtoUseInfo>> {
		try {
			const { data } = await this.axiosInstance.get<DtoUseInfo>('userInfo', {
				params: { schoolId },
				headers: {
					Authorization: authorization,
				},
			})
			return [data, null]
		} catch (e) {
			return [null, e]
		}
	}
}

import { TFeedFilterKeys } from './feed/config'

function createConfigChecker<TKeys extends string>() {
	return (configKey: TKeys, element: any, config: Record<TKeys, any>) => {
		if (!config[configKey]) {
			return null
		}
		return config[configKey]?.enabled ? element : null
	}
}

export const displayIfEnabled = createConfigChecker<TFeedFilterKeys>()

import Entity from '@/anthology/types/Entity'

import UsersListCard from '@/components/IndividualPage/UsersListCard'
import { useAppDispatch } from '@/store'
import { getIsLoadingOrganizationData, getOrganizationFollowers, loadOrganizationFollowersRequest } from '@/store/organizations'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetOrganizationByIdQuery } from '../../api'
import AboutOrganizationCard from '../../components/AboutOrganizationCard'
import OrganizationContactCard from '../../components/OrganizationContactCard'
import FollowersCount from '../../components/details/FollowersCount'
import MembersCount from '../../components/details/MembersCount'
import PostsCount from '../../components/details/PostsCount'
import { default as OrganizationMembershipsListCard } from '../../components/members/ListCard'

interface ProfileTabProps {
	id: Entity['id']
	onClickPostsInfo: () => void
}

const ProfileTab = ({ onClickPostsInfo, id }: ProfileTabProps) => {
	const { data: organization } = useGetOrganizationByIdQuery({ id })

	const dispatch = useAppDispatch()

	const followers = useSelector(getOrganizationFollowers)
	const { isLoadingFollowers } = useSelector(getIsLoadingOrganizationData)

	const [showFollowers] = useState(false)

	const followersComponentId = 'org-leadership'
	const membershipComponentId = 'org-membership'

	const handleLoadMoreFollowers = (orgId: string, page?: number) => {
		dispatch(
			loadOrganizationFollowersRequest({
				orgId,
				newPage: page,
			}),
		)
	}

	const organizationInfo = useMemo(
		() => (
			<>
				<FollowersCount orgId={id} />
				<MembersCount orgId={id} />
				<PostsCount orgId={id} />
			</>
		),
		[id],
	)

	if (!organization?.id) return null

	return (
		<>
			<AboutOrganizationCard
				categories={organization.categories}
				description={organization.description}
				organizationStats={organizationInfo}
			/>
			{showFollowers ? (
				<UsersListCard
					id={followersComponentId}
					title="Followers"
					orgId={organization.id}
					users={followers.list}
					numberOfFollowers={followers.total}
					onLoadMoreItems={handleLoadMoreFollowers}
					isLoading={!!isLoadingFollowers}
					canLoadMore={followers.canLoadMore}
				/>
			) : (
				<>
					{organization.primaryContactId && (
						<OrganizationContactCard
							pointOfContact={organization.primaryContactId}
							contactName={organization.primaryContactId.username}
							contactEmail={organization.primaryContactId.campusEmail}
							socialMedia={organization.socialMedia}
						/>
					)}

					<OrganizationMembershipsListCard orgId={id} cardId={membershipComponentId} title="Membership" />
					<OrganizationMembershipsListCard orgId={id} type="leaders" title="Leadership" />
				</>
			)}
		</>
	)
}

export default ProfileTab

import { Button, Step, StepButton, StepLabel, Stepper, Typography, makeStyles } from '@material-ui/core'
import { IChangeEvent } from '@rjsf/core'
import { Form } from '@rjsf/material-ui'
import { RJSFSchema, UiSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import React, { useState } from 'react'

import OrganizationPicker from '@/anthology/organizations/components/OrganizationPicker'
import { DtoOrganization } from '@/anthology/organizations/types/dto/DtoOrganization'
import { CommonModalCard, IconWrapper } from '@/components'

export type IQuestionsFormProps = {
	form?: {
		formSchema: RJSFSchema
		uiSchema: UiSchema
	}
	answers?: any
	onSubmit: (params: { data: IChangeEvent<any, RJSFSchema, any>; organizationIds?: Array<DtoOrganization['id']> }) => void
	onClose?: () => void
	organizationRepresentation?: {
		enabled?: boolean
		required?: boolean
	}
}

/* eslint-disable prettier/prettier */
type TAvailableSteps = (typeof AVAILABLE_STEPS)[number]['key']

type TConfig = { [key in TAvailableSteps]: IQuestionsFormProps[key] }

const useStyles = makeStyles((theme) => ({
	form: {
		maxWidth: '100%',
		maxHeight: 500,
		padding: 20,
		overflowY: 'auto',
		overflowX: 'hidden',
		'& .MuiButton-containedPrimary': {
			margin: '0 auto',
			display: 'inherit',
		},
	},
	cardRoot: {
		maxWidth: 500,
	},
	continueButton: {
		margin: `${theme.spacing(2)}px auto`,
	},
	stepper: {
		width: '100%',
		boxSizing: 'border-box',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	headerContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		marginTop: theme.spacing(2),
	},
	header: {
		marginBottom: theme.spacing(2),
	},

	stepperLabel: {
		marginTop: '0 !important',
		'&.MuiStepLabel-active': {
			color: theme.palette.primary.main,
		},
		'&.MuiStepLabel-completed': {
			color: theme.palette.success.main,
		},
	},
}))

const AVAILABLE_STEPS = [
	{
		label: 'Organization Representation',
		icon: () => <IconWrapper iconKey="organizations" />,
		key: 'organizationRepresentation',
	},
	{
		label: 'Form',
		icon: () => <IconWrapper iconKey="forms" />,
		key: 'form',
	},
] as const

const constructFormSteps = (config: TConfig) => {
	return AVAILABLE_STEPS.map((step) => {
		if (config?.organizationRepresentation?.enabled && step.key === 'organizationRepresentation') {
			return {
				...step,
				completed: false,
				isValid: !config?.organizationRepresentation?.required,
			}
		}
		return {
			...step,
			completed: false,
			isValid: true,
		}
	}).filter((step) => !!config[step.key])
}

const QuestionsForm: React.FC<IQuestionsFormProps> = ({ form, organizationRepresentation, onSubmit, onClose, answers }) => {
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(0)
	const [organizations, setOrganizations] = useState<Record<DtoOrganization['id'], boolean>>({})
	const [steps, setSteps] = useState(constructFormSteps({ organizationRepresentation, form }))

	const handleToggle = (id: DtoOrganization['id']) => {
		setSteps((prev) =>
			prev.map((step) => {
				if (step.key === 'organizationRepresentation') {
					step.isValid = true
					step.completed = true
				}
				return step
			}),
		)
		setOrganizations({ [id]: true })
	}

	const nextStep = () => {
		if (activeStep === steps.length - 1) {
			onSubmit({ data: null, organizationIds: Object.keys(organizations) })
		} else {
			setActiveStep((prev) => prev + 1)
		}
	}

	const onFormSubmit = (data: IChangeEvent<any, RJSFSchema, any>) => {
		onSubmit({ data, organizationIds: Object.keys(organizations) })
	}

	const renderStep = () => {
		const activeStepKey = steps[activeStep]?.key

		switch (activeStepKey) {
			case 'form': {
				return (
					<Form
						formData={answers}
						className={classes.form}
						schema={form?.formSchema}
						uiSchema={form?.uiSchema}
						onSubmit={onFormSubmit}
						validator={validator}
					/>
				)
			}
			case 'organizationRepresentation': {
				return (
					<>
						<div className={classes.headerContainer}>
							<Typography variant="h3" className={classes.header}>
								Organization Representation
							</Typography>
							<Typography className={classes.header}>
								Choose an organization from your current memberships that you would like to represent at this event
							</Typography>
						</div>

						<OrganizationPicker onToggle={handleToggle} value={organizations} />
						<Button
							onClick={nextStep}
							className={classes.continueButton}
							variant="contained"
							color="primary"
							disabled={organizationRepresentation?.required && Object.keys(organizations).length === 0}
						>
							{organizationRepresentation?.required || Object.keys(organizations).length > 0 ? 'Continue' : 'Skip'}
						</Button>
					</>
				)
			}
		}
	}

	const isDisabled = (index: number) => steps.filter((_, idx) => idx < index).some((step) => !step.isValid)

	const handleStepClick = (index: number) => () => {
		if (!isDisabled(index)) {
			setActiveStep(index)
		}
	}
	return (
		<CommonModalCard title="RSVP Form" onClose={onClose} classnames={{ card: classes.cardRoot }}>
			{steps.length > 1 && (
				<Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStep}>
					{steps.map(({ completed, icon, key, label }, index) => (
						<Step disabled={isDisabled(index)} key={key}>
							<StepButton onClick={handleStepClick(index)} completed={completed}>
								<StepLabel className={classes.stepperLabel} StepIconComponent={icon}>
									{label}
								</StepLabel>
							</StepButton>
						</Step>
					))}
				</Stepper>
			)}
			{renderStep()}
		</CommonModalCard>
	)
}

export default QuestionsForm

import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { TOnBoardingStep, TOnboarding } from '@navengage/sen-shared-assets'
import { useEffect } from 'react'
import { useSignupStepsContext } from './SignupStepsContext'

export const useSignupStepDataPatcher = (patches, dependencies: any[]) => {
	const { patchFormData, setNavigationMiddleware } = useSignupStepsContext()

	useEffect(() => {
		setNavigationMiddleware(() => {
			patchFormData({
				patches,
			})
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setNavigationMiddleware, patchFormData, ...dependencies])
}

export const useOnboardingStepConfig = (key: keyof TOnboarding): TOnBoardingStep | undefined => {
	const {
		features: {
			onboarding: { config },
		},
	} = useSchoolConfig()
	const { [key]: stepData } = config

	return stepData
}

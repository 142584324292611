export function convertUserEventsToEvents(list: any[]) {
	return list.map((returnedEvent) => ({
		...returnedEvent.event,
		responseStatus: returnedEvent.response,
	}))
}

export function setEventUserResponse(events: any[], userEvents: any) {
	const eventsResponses = Object.keys(userEvents).reduce(
		(acc: any[], key) => [...acc, ...userEvents[key].map(({ id, response }: any) => ({ id, response }))],
		[],
	)

	return events.map((event) => ({
		...event,
		response: eventsResponses.find(({ id }: any) => id === event.id)?.response,
	}))
}

export const getShiftsInfo = (shifts: any[]) => {
	let totalSpots = 0
	let bookedSpots = 0
	shifts.forEach(({ maxUsers, users }) => {
		totalSpots += maxUsers
		bookedSpots += users.length
	})
	const availableSpots = totalSpots - bookedSpots

	return {
		totalSpots,
		bookedSpots,
		availableSpots,
	}
}

export const isUserSubmittedToShift = (shift: any, userId: number) => {
	return shift.users.some(({ appUserId }: any) => +appUserId === +userId)
}

export const createNumberOptionsArray = (length: number) => Array.from({ length }).map((e, i) => ({ label: `${i + 1}`, value: i + 1 }))

export const isEventAdmin = (userId: number, submittedById?: number) => {
	return submittedById ? +submittedById === +userId : false
}

export const hasVolunteerCategory = (categories: any[]): boolean => {
	return categories?.some(({ category }) => category.toLowerCase() === 'volunteer') || false
}

export const getStringCategoriesMap = (categories: any[]): string[] => {
	return categories?.map(({ category }) => category)
}

export const getStringCategories = (categories: any[]): string => {
	return getStringCategoriesMap(categories).join(', ') || ''
}

export const getVolunteersNumber = (volunteersList: { [shiftId: string]: { [userId: string]: any } }): number => {
	let counter = 0
	Object.keys(volunteersList || {}).forEach((key) => {
		counter += Object.keys(volunteersList[key] || {}).filter((key) => key !== 'null').length
	})
	return counter
}

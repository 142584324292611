import { IconButton, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'

import { IconWrapper } from '@/components'
import { STYLES } from '@/constants'
import useSocket from '@/hooks/useSocket'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { toggleLikeRequest } from '@/store/groups'
import { Message, TMessageReactions } from '@/store/messages/types'
import { rootStyles } from '@/styles'
import { isCurrentUser } from '@/utils/authHandlers'
import { formatDateNew } from '@/utils/dateTime2'
import { MessageProfileImage } from '../MessageProfileImage'
import { IReactionModalData } from '../reactions/MessageReactionsModal'
import { MessageContents } from './MessageContents'

interface MessageBubbleProps {
	id: string
	message: Message
	showTimestamp?: boolean
	groupId: string
	showReactions: (data: IReactionModalData) => void
}

const MessageBubble = ({ id, message, showTimestamp, groupId, showReactions }: MessageBubbleProps) => {
	const currentUser = useSelector(getUserInfo)

	const appTheme = useTheme()

	const { changeMessageViewStatus } = useSocket()
	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 })

	const { viewed } = message

	const senderUserId = message.sourceUser!.id!

	const dispatch = useAppDispatch()
	const isCurrentUserMessage = isCurrentUser(currentUser.id, senderUserId)

	const classes = useStyles()

	useEffect(() => {
		if (!viewed && !isCurrentUserMessage && inView) {
			changeMessageViewStatus({
				id,
				groupId,
				userId: currentUser.id,
			})
		}
	}, [changeMessageViewStatus, currentUser.id, groupId, id, inView, isCurrentUserMessage, viewed])
	const isLiked = useMemo(() => {
		if (message.currentUserReacteions && message.currentUserReacteions?.includes(TMessageReactions.like)) {
			return true
		} else {
			return false
		}
	}, [message])

	// @TODO: Add reaction type later on
	const hasReaction = useMemo(() => {
		return message.likes && message.likes > 0
	}, [message])
	const toggleLike = useCallback(() => {
		dispatch(
			toggleLikeRequest(
				message.id,
				{ reacted: isLiked, type: TMessageReactions.like, count: message.likes ?? 0 },
				message.currentUserReacteions,
			),
		)
	}, [dispatch, message, isLiked])

	const onClickLikesButton = useCallback(() => {
		showReactions({ message, type: 'Like' })
	}, [showReactions, message])

	const [isHovered, setIsHovered] = useState(false)

	return (
		<div
			ref={ref}
			id={`message-${id}`}
			className={classes.root}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{showTimestamp && <Typography className={classes.time}>{formatDateNew(message.createdAt, 'full')}</Typography>}
			<div className={classes.message}>
				{message.sourceUser && (
					<MessageProfileImage
						externalId={message.sourceUser.externalId}
						userId={message.sourceUser.id}
						photoUrl={message.sourceUser.photoUrl}
					/>
				)}
				<div className={classes.container}>
					<MessageContents {...{ message, onClickLikesButton, isHovered }} />
					<IconButton className={classes.likeButton} onClick={() => toggleLike()}>
						<IconWrapper
							iconKey="heart"
							color={isLiked ? appTheme.colors.red[500] : appTheme.palette.grey[400]}
							weight={isLiked ? 'fill' : 'regular'}
						/>
						{message.likes > 0 && <span className={classes.likesCount}>{message.likes}</span>}
					</IconButton>
				</div>
			</div>
		</div>
	)
}

export default MessageBubble

const useStyles = makeStyles((theme) => ({
	...rootStyles,
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	message: {
		display: 'flex',
		flexDirection: 'row',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		justifyContent: 'start',
		padding: '5px 15px',
		'&:hover': {
			backgroundColor: theme.colors.grey[100],
		},
	},

	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	time: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '5px 0',
		color: theme.palette.text.secondary,
		fontSize: 15,
		textAlign: 'center',
	},

	likeButton: {
		position: 'relative',
		marginRight: 15,
	},
	likesCount: {
		bottom: '0.75em',
		right: '0.25em',
		position: 'absolute',
		color: theme.palette.text.secondary,
		fontSize: '0.5em',
	},
}))

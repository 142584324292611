import http from '@/api/http/requestHttp'

interface PatchUserPreferenceParams {
	preferenceId: number
	value: boolean
	schoolId: number
}

const patchUserPreference = ({ schoolId, preferenceId, value }: PatchUserPreferenceParams) => {
	const url = `${schoolId}/users/preferences/${preferenceId}`

	return http.patch(url, { value })
}

export default patchUserPreference

import { StudyAbroadState } from '@/store/studyAbroad/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/studyAbroad/constants'
import { SearchConfig, UserResponseStatuses } from '@/interfaces/common'

const selectState = (state: { [MODULE_NAME]: StudyAbroadState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
// @TODO: remove
// export const getIsLoadingUserStudyAbroad = createSelector(selectState, (state) => state.isLoadingUserStudyAbroad)
export const getIsLoadingIndividualStudyAbroad = createSelector(selectState, (state) => state.isLoadingIndividualStudyAbroad)

export const getStudyAbroad = createSelector(selectState, (state) => state.studyAbroad)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)

export const getUserStudyAbroad = createSelector(selectState, (state) => state.userStudyAbroad)
export const getSelectedStudyAbroad = createSelector(selectState, (state) => state.selectedStudyAbroad)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)

/**
 * @TODO: #WARNING! copy past, need to create higher abstraction and more generic selector
 */
export const selectCurrentUserHasResponse = createSelector(
	[selectState, (__, response: UserResponseStatuses) => response],
	(studyAbroadState, response) => {
		return studyAbroadState.selectedStudyAbroad && studyAbroadState.selectedStudyAbroad.responseStatus === response
	},
)

import { Theme, makeStyles } from '@material-ui/core'
import React from 'react'

interface StyledProps {
	position?: {
		top?: number
		left?: number
		bottom?: number
		right?: number
	}
}

const useStyles = makeStyles<Theme, StyledProps>((theme) => ({
	root: ({ position }) => ({
		color: theme.palette.grey[500],
		overflow: 'hidden',
		position: 'absolute',
		textOverflow: 'ellipsis',
		top: `${position?.left || 0}px`,
		left: `${position?.top || 0}px`,
		bottom: `${position?.bottom}px`,
		right: `${position?.right}px`,
		userSelect: 'none',
		display: 'inline-block',
		pointerEvents: 'none',
	}),
}))

export interface IPlaceholderProps extends StyledProps {}

const Placeholder: React.FC<IPlaceholderProps> = ({ position, children }) => {
	const classes = useStyles({ position })
	return <div className={classes.root}>{children}</div>
}

export default Placeholder

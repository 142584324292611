import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = ['Analytics Event'] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			eventsControllerCreate: build.mutation<EventsControllerCreateApiResponse, EventsControllerCreateApiArg>({
				query: (queryArg) => ({ url: `/analytics/events`, method: 'POST', body: queryArg.createEventDto }),
				invalidatesTags: ['Analytics Event'],
			}),
			eventsControllerFindOne: build.query<EventsControllerFindOneApiResponse, EventsControllerFindOneApiArg>({
				query: (queryArg) => ({ url: `/analytics/events/${queryArg.id}` }),
				providesTags: ['Analytics Event'],
			}),
			eventsControllerUpdate: build.mutation<EventsControllerUpdateApiResponse, EventsControllerUpdateApiArg>({
				query: (queryArg) => ({ url: `/analytics/events/${queryArg.id}`, method: 'PATCH', body: queryArg.updateEventDto }),
				invalidatesTags: ['Analytics Event'],
			}),
			eventsControllerRemove: build.mutation<EventsControllerRemoveApiResponse, EventsControllerRemoveApiArg>({
				query: (queryArg) => ({ url: `/analytics/events/${queryArg.id}`, method: 'DELETE' }),
				invalidatesTags: ['Analytics Event'],
			}),
			eventsControllerGetGridMeta: build.query<EventsControllerGetGridMetaApiResponse, EventsControllerGetGridMetaApiArg>({
				query: () => ({ url: `/analytics/events/grid/meta` }),
				providesTags: ['Analytics Event'],
			}),
			eventsControllerGetGridData: build.query<EventsControllerGetGridDataApiResponse, EventsControllerGetGridDataApiArg>({
				query: (queryArg) => ({
					url: `/analytics/events/grid/data`,
					params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
				}),
				providesTags: ['Analytics Event'],
			}),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsEventsApi }
export type EventsControllerCreateApiResponse = unknown
export type EventsControllerCreateApiArg = {
	createEventDto: CreateEventDto
}
export type EventsControllerFindOneApiResponse = unknown
export type EventsControllerFindOneApiArg = {
	id: string
}
export type EventsControllerUpdateApiResponse = unknown
export type EventsControllerUpdateApiArg = {
	id: string
	updateEventDto: UpdateEventDto
}
export type EventsControllerRemoveApiResponse = unknown
export type EventsControllerRemoveApiArg = {
	id: string
}
export type EventsControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type EventsControllerGetGridMetaApiArg = void
export type EventsControllerGetGridDataApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: Event[]
}
export type EventsControllerGetGridDataApiArg = {
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type CreateEventDto = {}
export type UpdateEventDto = {}
export type GridColumnDto = {
	field: string
	headerName: string
	description?: string
	width?: number
	flex?: number
	minWidth?: number
	maxWidth?: number
	hide?: boolean
	hideable?: boolean
	sortable?: boolean
	editable?: boolean
	groupable?: boolean
	pinnable?: boolean
	searchable?: boolean
	filterable?: boolean
	template?: string
	type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'
}
export type GridConfigDto = {
	fields: string[]
	dictionary: GridColumnDto[]
	config: object
}
export type Event = {}
export type QueryFilterItem = {
	field: string
	operator:
		| 'contains'
		| 'equals'
		| 'startsWith'
		| 'endsWith'
		| 'isEmpty'
		| 'isNotEmpty'
		| 'isAnyOf'
		| 'onOrAfter'
		| 'after'
		| 'onOrBefore'
		| 'before'
		| '='
		| '!='
		| '<'
		| '<='
		| '>'
		| '>='
	value?: object
}
export type QueryGridFiltersDto = {
	filters?: QueryFilterItem[]
}
export type QuerySortByItem = {
	field: string
	sort: 'asc' | 'desc'
}
export type QueryGridSortByDto = {
	sortBy?: QuerySortByItem[]
}
export const {
	useEventsControllerCreateMutation,
	useEventsControllerFindOneQuery,
	useEventsControllerUpdateMutation,
	useEventsControllerRemoveMutation,
	useEventsControllerGetGridMetaQuery,
	useEventsControllerGetGridDataQuery,
} = injectedRtkApi

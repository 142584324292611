import { useCallback } from 'react'
import DtoEvent from '../types/dto/DtoEvent'
import { useCreateEventRsvpMutation, usePatchEventRsvpMutation } from '../api'
import { DtoOrganization } from '@/anthology/organizations/types/dto/DtoOrganization'
import { Operation, compare } from 'fast-json-patch'
import EventRsvpOption from '../types/EventRsvpOption'

export type FormData = {
	answers?: any
	response: EventRsvpOption
	guests?: number
	organizationIds?: Array<DtoOrganization['id']>
}

const useCreateUpdateRsvpMutation = ({ eventId, response }: { eventId: DtoEvent['id']; response: DtoEvent['response'] }) => {
	const [createEventRsvpMutation, createQuery] = useCreateEventRsvpMutation()
	const [updateEventRsvpMutation, updateQuery] = usePatchEventRsvpMutation()
	const handleSubmitRsvpForm = useCallback(
		(data: FormData) => {
			if (response?.id) {
				const { id, eventId, answers, guests, organizationIds, userId, ...oldResponse } = response
				const patchOperations: Operation[] = compare(oldResponse, data)

				updateEventRsvpMutation({ body: patchOperations, eventId, id: response.id })
			} else {
				createEventRsvpMutation({
					answers: data.answers,
					guests: data.guests,
					organizationIds: data.organizationIds || [],
					eventId,
					response: data.response,
				})
			}
		},
		[createEventRsvpMutation, eventId, response, updateEventRsvpMutation],
	)

	return {
		query: response?.id ? updateQuery : createQuery,
		handleSubmitRsvpForm,
	}
}

export default useCreateUpdateRsvpMutation

import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import * as http from '@/api/http'
import ImageViewModal from '../ImageViewModal'
import { Skeleton } from '../Skeleton'
import useSchoolId from '@/features/school/hooks/useSchoolId'

const useStyles = makeStyles((theme) => ({
	image: {
		cursor: 'pointer',
	},
}))

interface SecureImageProps {
	src?: string
	alt?: string
	className?: string
	containerClass?: string
	showSkeleton?: boolean
}

const SecureImage = ({ src = '', alt = '', className = '', containerClass = '', showSkeleton = false }: SecureImageProps) => {
	const classes = useStyles()
	const schoolId = useSchoolId()
	const [image, setImage] = useState('')
	const [fullImageView, setFullImageView] = useState('')

	const handleOpenImageView = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		setFullImageView(image)
	}

	const handleCloseImageView = () => setFullImageView('')

	useEffect(() => {
		let isMounted = true

		const fetchImage = async () => {
			try {
				const { data } = await http.getSecurePhoto(src, schoolId)

				if (isMounted) {
					setImage(`data:image/jpeg;base64, ${data}`)
				}
			} catch (e: any) {}
		}
		fetchImage()

		return () => {
			isMounted = false
		}
	}, [schoolId, src])

	return (
		<div className={classNames(containerClass)}>
			{showSkeleton && image === '' && <Skeleton />}

			{image && (
				<>
					<ImageViewModal imageSrc={fullImageView} onClose={handleCloseImageView} />

					<img className={classNames(classes.image, className)} onClick={handleOpenImageView} src={image} alt={alt} />
				</>
			)}
		</div>
	)
}

export default SecureImage

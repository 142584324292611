import { ContentPreloader } from '@/components/ContentPreloader'
import { CommonCard } from '@/features/adminConsole/components/CommonCard'
import { campusSelectors } from '@/features/campus/slice'
import { useAppSelector } from '@/store'
import { FC, useMemo } from 'react'
import { useGetAnalyticsUserQuery } from '../../../../api/usersApi'
import InfoList, { TInfoListItem } from '../../InfoList'
import IUserCardProps from './IUserCardProps'

export interface IUserContactCardProps extends IUserCardProps {}

const UserContactCard: FC<IUserContactCardProps> = ({ userId, title = 'Contact', ...commonCardProps }) => {
	const { data, isLoading } = useGetAnalyticsUserQuery({ userId })
	const userCampus = useAppSelector((state) => campusSelectors.selectById(state, data?.campusId))
	const mainInfoListItems = useMemo(() => {
		if (!data) {
			return []
		}
		const items: TInfoListItem[] = []
		if (userCampus) {
			items.push({ primaryText: userCampus.name, icon: 'location' })
		}
		items.push({ icon: 'email', link: `mailto:${data.email}`, primaryText: data.email })

		return items
	}, [data, userCampus])
	return (
		<CommonCard {...commonCardProps} title={title}>
			{isLoading ? <ContentPreloader /> : <InfoList items={mainInfoListItems} />}
		</CommonCard>
	)
}

export default UserContactCard

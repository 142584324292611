export enum PhotoUploadDirectories {
	EVENT = 'event_uploads',
	POST = 'post_photos',
	MESSAGE = 'message_photos',
	ORGANIZATION = 'organization_photos',
	GROUP = 'group_photos',
	PROFILE_PHOTO = 'profile_photos',
}

export const maxAllowedImageSize = 8192 // https://caniuse.com/offscreencanvas
export const maxSizeMB = 4
export const maxImageWidth = 1920

export enum UploadErrors {
	EVENT = 'event',
	POST = 'post',
	GROUP = 'group',
	MESSAGE = 'message',
	ORGANIZATION = 'organization',
	PROFILE = 'profile',
}

export enum UploadErrorMessage {
	POST = 'Something went wrong. Try posting again.',
	PROFILE = 'Something went wrong. Try updating your profile again.',
	EVENT = 'Something went wrong. Try updating event again.',
	ORGANIZATION = 'Something went wrong. Try updating organization again.',
	GROUP = 'Something went wrong. Try updating your group image.',
}

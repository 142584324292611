import { forwardRef } from 'react'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TextField as MaterialTextField, TextFieldProps } from '@material-ui/core'

interface StyleProps {
	error?: boolean
	disabled?: boolean
	placeholderColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	textInput: ({ error }: any) => ({
		width: '95%',
		'& .MuiInput-underline:after': {
			borderBottom: `solid 1px ${error ? theme.palette.error.main : theme.palette.divider}`,
		},
		'& .MuiInput-underline:before': {
			borderBottom: `solid 1px ${error ? theme.palette.error.main : theme.palette.divider}`,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: `solid 1px ${theme.palette.primary.dark}`,
		},
	}),
	inputRoot: ({ placeholderColor, error }) => ({
		caretColor: theme.palette.primary.dark,
		caretShape: 'bar',
		'&:not(.Mui-disabled)::-webkit-input-placeholder': {
			color: error ? theme.palette.error.main : placeholderColor ?? theme.palette.text.secondary,
			opacity: 1,
		},
		'&.Mui-disabled::-webkit-input-placeholder': {
			color: theme.palette.text.secondary,
			opacity: 0.7,
		},
		'&:focus::-webkit-input-placeholder': {
			color: theme.palette.text.secondary,
			opacity: 0.7,
		},
	}),
}))

const TextField = forwardRef<HTMLDivElement | null, TextFieldProps & StyleProps>(({ placeholderColor, ...rest }, ref) => {
	const classes = useStyles({ placeholderColor, error: !!rest.error })

	return (
		<MaterialTextField
			ref={ref}
			{...rest}
			className={classNames(classes.textInput, rest.className)}
			InputProps={{
				...rest.InputProps,
				classes: { input: classes.inputRoot },
			}}
		/>
	)
})

export default TextField

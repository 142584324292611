import http from '@/api/http/requestHttp'
import DtoUserGoals from './DtoUserGoals'

const getUserGoals = async (userId: number, schoolId: number) => {
	const url = `${schoolId}/path-builder/${userId}/goals`

	return http.get<DtoUserGoals>(url)
}

export default getUserGoals

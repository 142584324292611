import { TextButton } from '@/components/Buttons'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import { PATHS, STYLES } from '@/constants'
import { ConnectivityStatus } from '@/interfaces/common'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo, useState } from 'react'
// import OutlinedButton from '@/components/OutlinedButton';
import CancelRequestModal from '@/components/UserNetwork/CancelRequestModal'
import ManageConnectionsModal from '@/components/UserNetwork/ManageConnectionsModal'
import UserRow from '@/components/UserRow'
import { useAppDispatch } from '@/store'
import { changeConnectionStatusRequest } from '@/store/network'
import classNames from 'classnames'
import { useHistory } from 'react-router'
// import { clearNewMessageContent } from '@/store/messages';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		margin: '10px 20px',
		'&:first-child': {
			borderBottom: `solid 1px ${theme.palette.divider}`,
		},
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		margin: '1vh 0',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	contentContainer: {
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	connectionRequestButton: {
		marginRight: 10,
		width: '45%',
	},
	placeholder: {
		height: '30vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	blackText: {
		color: theme.palette.text.secondary,
	},
}))

export enum ConnectionRequestType {
	INCOMING = 0,
	OUTGOING = 1,
}

export interface ConnectionRequestsBlockProps {
	title: string
	connectionRequests: any[]
	emptyPlaceholder?: React.ReactNode
	connectionRequestType: ConnectionRequestType
	className?: string
}

const MAX_SHOWED_REQUESTS = 3

const ConnectionRequestsBlock = ({
	title,
	connectionRequests,
	emptyPlaceholder = <></>,
	connectionRequestType,
	className = '',
}: ConnectionRequestsBlockProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
	const [requestToCancelId, setRequestToCancelId] = useState<number | undefined>(undefined)

	const requestsCount = connectionRequests?.length || 0

	const handleToggleModal = () => setIsOpenModal((prev) => !prev)
	const handleFindNewConnections = () => push(PATHS.APP.NETWORK_SEARCH)

	const handleOpenCancelModal = useCallback((id: number) => {
		setRequestToCancelId(id)
		setIsOpenCancelModal(true)
	}, [])
	const handleCloseCancelModal = useCallback(() => {
		setIsOpenCancelModal(false)
		setRequestToCancelId(undefined)
	}, [])

	const handleChangeRequest = useCallback(
		(status: ConnectivityStatus, id?: number) => {
			if (!id) return
			dispatch(changeConnectionStatusRequest({ status, id }))
		},
		[dispatch],
	)

	const showedRequests = useMemo(() => {
		const req = connectionRequests?.slice(0, MAX_SHOWED_REQUESTS).map((request, index) => (
			<UserRow key={`${request.user.id}-${index}`} user={request.user}>
				{connectionRequestType === ConnectionRequestType.INCOMING
					? [
							<ColorButton
								key="btn-connect-1"
								className={classes.connectionRequestButton}
								onClick={() => handleChangeRequest(ConnectivityStatus.ACCEPTED, request.id)}
							>
								Connect
							</ColorButton>,
							<ColorButton
								key="btn-connect-2"
								className={classes.connectionRequestButton}
								color={ButtonColors.GREY}
								onClick={() => handleChangeRequest(ConnectivityStatus.REJECTED, request.id)}
							>
								Decline
							</ColorButton>,
					  ]
					: [
							<ColorButton
								key="btn-connect-4"
								className={classes.connectionRequestButton}
								color={ButtonColors.GREY}
								onClick={() => handleOpenCancelModal(request.id)}
							>
								Cancel
							</ColorButton>,
					  ]}
			</UserRow>
		))
		return req
	}, [classes.connectionRequestButton, connectionRequestType, connectionRequests, handleChangeRequest, handleOpenCancelModal])

	return (
		<div className={classNames(classes.root, className)}>
			<CancelRequestModal
				isOpenModal={isOpenCancelModal}
				handleCloseModal={handleCloseCancelModal}
				onSubmit={() => handleChangeRequest(ConnectivityStatus.CANCELED, requestToCancelId)}
			/>
			<ManageConnectionsModal
				isOpenModal={isOpenModal}
				connectionRequestType={connectionRequestType}
				handleCloseModal={handleToggleModal}
				connectionRequests={connectionRequests}
			/>
			<div className={classes.title}>
				<Typography>
					<b>{title}</b> | {requestsCount}
				</Typography>
				{!!requestsCount && <TextButton onClick={handleToggleModal}>See All</TextButton>}
			</div>
			<div className={classes.contentContainer}>
				{!requestsCount ? (
					<div className={classes.placeholder}>
						{emptyPlaceholder}
						<TextButton onClick={handleFindNewConnections}>Find new connections.</TextButton>
					</div>
				) : (
					showedRequests
				)}
			</div>
		</div>
	)
}

export default ConnectionRequestsBlock

import { IdInt } from '@/lib/types'
import searchPosts from './searchPosts'

// @TODO: delete after switching to rtk query on schedule posts #cleanup, @refactor
const getScheduledPosts = ({ page, userId, schoolId, limit }: { page: number; userId: IdInt; schoolId: number; limit: number }) => {
	// @TODO: searchPosts needs to change it's signature
	return searchPosts({ page, userId, scheduled: true, schoolId, limit })
}

export default getScheduledPosts

import { ContentPreloader } from '@/components/ContentPreloader'
import EventCard from '@/components/ExperienceCards/EventCard'

import { TextButton } from '@/components/Buttons'
import Card from '@/components/IndividualPage/Card'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import EmptyPlaceholder from '@/components/Placeholder'
import { useGetOrganizationEventsQuery } from '@/features/organizations/api'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FEATURE } from '@navengage/sen-shared-assets'
import { useMemo, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: '100%',
		padding: '20px 0',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	cards: {
		width: '100%',
		marginTop: 20,
	},
	buttonsDivider: {
		color: theme.palette.primary.main,
		margin: '0 5px',
	},
	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
	},
}))

interface EventsTabProps {
	orgId: string
	canCreateEvents: boolean
	handleClickCreateEvent: () => void
}

const EventsTab = ({ canCreateEvents, handleClickCreateEvent, orgId }: EventsTabProps) => {
	const classes = useStyles()

	const [seePastEvents, setSeePastEvents] = useState(false)

	const { data, isLoading } = useGetOrganizationEventsQuery(
		{ orgId, filter: seePastEvents ? 'past' : 'future' },
		{
			refetchOnMountOrArgChange: true,
		},
	)

	const handleToggleView = () => setSeePastEvents((prev) => !prev)

	const currentEvents = useMemo(() => {
		return data && data.results ? data.results : []
	}, [data])

	return (
		<>
			<Card>
				<div className={classes.header}>
					<Typography variant="body1">
						<b>{!seePastEvents ? 'Upcoming Events' : 'Past Events'}</b> | {currentEvents?.length ?? 0}
					</Typography>
					<div className={classes.buttonsContainer}>
						{canCreateEvents && (
							<>
								<TextButton onClick={handleClickCreateEvent}>Create Event</TextButton>
								<Typography className={classes.buttonsDivider} variant="body1">
									|
								</Typography>
							</>
						)}
						<TextButton onClick={handleToggleView}>{!seePastEvents ? 'See Past Events' : 'See Future Events'}</TextButton>
					</div>
				</div>
			</Card>
			{!currentEvents?.length || isLoading ? (
				<Card>
					{isLoading ? (
						<ContentPreloader />
					) : (
						<EmptyPlaceholder className={classes.placeholder} isEmptyPlaceholder iconKey={FEATURE.events}>
							<Typography align="center">This organization does not have</Typography>
							<Typography align="center">any {!seePastEvents ? 'upcoming' : 'past'} events.</Typography>
						</EmptyPlaceholder>
					)}
				</Card>
			) : (
				<Card>
					<div className={classes.cards}>
						<ExperienceCardsSection
							isLoading={isLoading}
							items={currentEvents}
							fullWidth
							showPagination={currentEvents.length < data.total}
							stretched
							renderItems={(items: any) => (
								<>
									{items.map(
										(
											{
												name,
												startDate,
												endDate,
												photoUrl,
												locationName,
												id,
												description,
												responseStatus,
												categories,
												organization,
												volunteerShifts,
												submittedByUserId,
											}: any,
											index: number,
										) => (
											<EventCard
												key={`${index}-${id}-${name}-${startDate}`}
												id={id}
												title={name}
												photoUrl={photoUrl}
												startDate={startDate}
												categories={categories}
												endDate={endDate}
												description={description}
												organization={organization}
												locationName={locationName}
												eventResponse={responseStatus}
												volunteerShifts={volunteerShifts}
												submittedByUserId={submittedByUserId}
											/>
										),
									)}
								</>
							)}
						/>
					</div>
				</Card>
			)}
		</>
	)
}

export default EventsTab

import Card from '@/components/IndividualPage/Card'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useOrganizationMembershipsList from '@/features/organizations/hooks/useOrganizationMembershipsList'

import { OrgMembership } from '@/store/organizations/types'
import { getOrganizationType, orgTypes } from '@/utils/organizationRoles'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import LockIcon from '@material-ui/icons/Lock'
import { getShortCounterLabel } from '@navengage/sen-shared-assets'
import ItemsSectionWithButton from '../ItemsSections/ItemsSectionWithButton'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userRow: {
		margin: '8px 0',
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	itemsContainer: {
		margin: '1vh 0',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},

	addButton: {
		width: 25,
		height: 25,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	addIcon: {
		width: 18,
		height: 18,
	},
}))

interface OrganizationMembershipsListCardProps {
	id?: string
	title: string
	roles: ORGANIZATION_ROLE[]
	canAdd?: boolean
	canEdit?: boolean
	orgId: string
	showHidden?: boolean
	renderMembershipRowButtons?: (membership: OrgMembership, canEdit: boolean) => React.ReactNode
	handleAddMembership?: (memberTypeId: orgTypes) => void
	sourceSystem?: string
}

const OrganizationMembershipsListCard = (props: OrganizationMembershipsListCardProps) => {
	const {
		sourceSystem = 'Org Central',
		title,
		id,
		roles,
		canAdd = true,
		canEdit = false,
		orgId,
		renderMembershipRowButtons = () => null,
		handleAddMembership,
	} = props

	const classes = useStyles()
	const membershipTypeIds = roles.map((role) => getOrganizationType(role))
	const { canLoadMore, loadMore, memberships, total, isFetching } = useOrganizationMembershipsList({ id: orgId, membershipTypeIds })

	return (
		<Card>
			<div id={id} className={classes.titleContainer}>
				<Typography variant="body1">
					<b>{title}</b> {`${total ? ' | ' + getShortCounterLabel(total) : '0'}`}
				</Typography>
				{handleAddMembership ? (
					canAdd ? (
						<IconButton className={classes.addButton} onClick={() => handleAddMembership(membershipTypeIds[0])}>
							<AddIcon className={classes.addIcon} />
						</IconButton>
					) : (
						<Tooltip title={`You can edit members in ${sourceSystem}`} arrow>
							<IconButton>
								<LockIcon />
							</IconButton>
						</Tooltip>
					)
				) : null}
			</div>
			<div>
				<ItemsSectionWithButton
					classnames={{
						content: classes.cardContainer,
					}}
					isLoading={isFetching}
					items={memberships}
					canLoadMore={canLoadMore}
					handleLoadMoreItems={loadMore}
					renderItems={(items: OrgMembership[]) =>
						items.map((member) => (
							<MembershipInfoRow
								classnames={{
									userRow: classes.userRow,
								}}
								key={`member-${membershipTypeIds.join('-')}-${member.id}`}
								// TODO - add lock icon here
								user={member.user}
								membershipTitle={roles.length === 1 && roles[0] === ORGANIZATION_ROLE.MEMBER ? roles[0] : member.membershipTitle}
								openProfileOnClick
								userEmail={member.userEmail}
								buttons={renderMembershipRowButtons(member, canEdit)}
							/>
						))
					}
				/>
			</div>
		</Card>
	)
}

export default OrganizationMembershipsListCard

import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import StepperContent from '@/components/PathBuilder/StepperContent'
import StepperCard from '@/components/PathBuilder/StepperCard'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import AddChip from '@/components/PathBuilder/AddChip'
import SelfCreatedItemsList from '@/components/PathBuilder/SelfCreatedItemsList'
import { getArrayMinSchema } from '@/constants/pathBuilder'

const useStyles = makeStyles((theme) => ({
	container: {
		width: 'calc(100% - 2vw)',
		display: 'flex',
		flexWrap: 'wrap',
		margin: '4vh 0',
	},
}))

const items = [
	{
		label: 'Any',
		value: 'Any',
	},
	{
		label: 'Ag Sciences',
		value: 'Ag Sciences',
	},
	{
		label: 'Architecture',
		value: 'Architecture',
	},
	{
		label: 'Business',
		value: 'Business',
	},
	{
		label: 'Communication and Journalism',
		value: 'Communication and Journalism',
	},
	{
		label: 'Education',
		value: 'Education',
	},
	{
		label: 'Engineering',
		value: 'Engineering',
	},
	{
		label: 'Environmental and Geosciences',
		value: 'Environmental and Geosciences',
	},
	{
		label: 'Humanities',
		value: 'Humanities',
	},
	{
		label: 'Information Technology and Computer Science',
		value: 'Information Technology and Computer Science',
	},
	{
		label: 'International Relations, Political Science, and Law',
		value: 'International Relations, Political Science, and Law',
	},
	{
		label: 'Life Science',
		value: 'Life Science',
	},
	{
		label: 'Mathematics',
		value: 'Mathematics',
	},
	{
		label: 'Music',
		value: 'Music',
	},
	{
		label: 'Nursing and Health Sciences',
		value: 'Nursing and Health Sciences',
	},
	{
		label: 'Physical Sciences',
		value: 'Physical Sciences',
	},
	{
		label: 'Social and Behavioral Sciences',
		value: 'Social and Behavioral Sciences',
	},
	{
		label: 'Statistics',
		value: 'Statistics',
	},
	{
		label: 'Visual and Performing Arts',
		value: 'Visual and Performing Arts',
	},
]

const Question2 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const [itemsMap, setItemsMap] = useState(items)

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const handleAddItem = (v: string[], onChange: (v: string[]) => void) => (newItem: string) => {
		onChange((v ?? []).concat([newItem]))
		setItemsMap((prev) => prev.filter((item) => item.value !== newItem))
	}

	const handleDeleteItem = (onChange: (v: string[]) => void) => (v: string[]) => {
		onChange(v)
		setItemsMap(items.filter((item) => !v.includes(item.value)))
	}

	return (
		<Controller
			name={dataKey}
			control={control}
			render={({ field: { value, onChange } }) => (
				<StepperContent
					leftColumnContent={
						<StepperCard
							title="What areas of research or creative inquiry 
          are you interested in?"
							subTitle="You can select more than one."
							onClose={onClose}
							onBack={onBack}
							showBackButton={showBackButton}
						>
							<div className={classes.container}>
								{itemsMap.map((item) => (
									<AddChip key={item.value} onChange={handleAddItem(value, onChange)} label={item.label} value={item.value} />
								))}
							</div>
						</StepperCard>
					}
					rightColumnContent={
						<div>
							<SelfCreatedItemsList
								title="Selected"
								placeholder="Add research areas."
								onChange={handleDeleteItem(onChange)}
								items={value}
							/>
						</div>
					}
				/>
			)}
		/>
	)
}

export default Question2

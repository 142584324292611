import { IconWrapper, InfoModalCard, Modal } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'
import { useAppDispatch } from '@/store'
import { getNotInterestedEventPopup, openNotInterestedEventPopup } from '@/store/events'

import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	submitText: {
		margin: '10px 0',
	},
	doneText: {
		color: theme.palette.text.primary,
		margin: '10px 0',
		fontWeight: 600,
	},
}))

const NotInterestedEventPopup = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const isOpen = useSelector(getNotInterestedEventPopup)
	const theme = useTheme()

	const handleCloseModal = useCallback(() => {
		dispatch(openNotInterestedEventPopup(false))
	}, [dispatch])

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<InfoModalCard
				onClose={handleCloseModal}
				icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
			>
				<Typography className={classes.doneText}>Thanks for your feedback.</Typography>
			</InfoModalCard>
		</Modal>
	)
}

export default NotInterestedEventPopup

import * as http from '@/api/http'

import { OrgMembershipRequestStatus } from '@/api/http/organizations/patchOrganizationMembershipRequest'
import settings from '@/constants/http'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { campusSelectors } from '@/features/campus/slice'
import { organizationApi } from '@/features/organizations/api'
import { ReportActions, SearchConfig, UserData, UserResponseStatuses } from '@/interfaces/common'
import { PostActivity } from '@/interfaces/user'
import { RootState } from '@/store'
import { closeSnackbar } from '@/store/app'
import { loadUserOrganizationRolesRequest, selectAuth, setUserAfterPageRefresh } from '@/store/auth'
import { preparePostData } from '@/store/feeds'

import { TCampus } from '@/features/campus/types/TCampus'
import { MODULE_NAME } from '@/store/organizations/constants'
import {
	OpenOrgDefaultOptions,
	OrgFollowersList,
	OrgLoadingState,
	OrgMembership,
	OrgMembershipRequest,
	OrgMembershipRequestList,
	OrgMemberships,
	OrganizationEditForm,
	UserOrganizations,
	UserTypeOption,
} from '@/store/organizations/types'
import { StoreSearchParams } from '@/store/types'
import { getUserRole } from '@/utils/authHandlers'
import { createPhotoUrl, getCurrentFilterValues } from '@/utils/common'
import { getOrganizationType, orgTypes } from '@/utils/organizationRoles'
import uploadPhoto from '@/utils/photoUpload'
import { getMinimumOrgInfo, getNormalizeUser } from '@/utils/transformers'
import { Dictionary, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setIsLoadingOrganizationData = createAction<OrgLoadingState>(`${MODULE_NAME}/SET_IS_LOADING_ORGANIZATION_DATA`)

export const setOrganizations = createAction<any>(`${MODULE_NAME}/SET_ORGANIZATIONS`)
export const setUserOrganizations = createAction<UserOrganizations>(`${MODULE_NAME}/SET_USER_ORGANIZATIONS`)
export const setCategories = createAction<any>(`${MODULE_NAME}/SET_CATEGORIES`)
export const setFilters = createAction<StoreSearchParams>(`${MODULE_NAME}/SET_FILTERS`)
export const loadMore = createAction<void>(`${MODULE_NAME}/PAGINATE_ORGS`)
export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setIndividualOrganization = createAction<any>(`${MODULE_NAME}/SET_INDIVIDUAL_ORGANIZATION`)
export const setSuperAdminOrganizations = createAction<any[]>(`${MODULE_NAME}/SET_SUPER_ADMIN_ORGANIZATIONS`)
export const setOrganizationActivity = createAction<{
	page?: number
	list?: PostActivity[]
	endHasBeenReached?: boolean
	isLoading: boolean
}>(`${MODULE_NAME}/SET_ORGANIZATION_ACTIVITY`)
export const setOrganizationFollowers = createAction<OrgFollowersList>(`${MODULE_NAME}/SET_ORGANIZATION_FOLLOWERS`)
export const setOrganizationMemberships = createAction<OrgMemberships>(`${MODULE_NAME}/SET_ORGANIZATION_MEMBERSHIPS`)
export const updateOrganizationMemberships = createAction<OrgMembership>(`${MODULE_NAME}/UPDATE_ORGANIZATION_MEMBERSHIPS`)
export const setOrganizationMembershipRequests = createAction<OrgMembershipRequestList>(
	`${MODULE_NAME}/SET_ORGANIZATION_MEMBERSHIP_REQUESTS`,
)
export const setOrganizationPendingUserRequests = createAction<{
	id: string
	requests: OrgMembershipRequest[]
}>(`${MODULE_NAME}/SET_ORGANIZATION_PENDING_USER_REQUESTS`)
export const setOrganizationPositionRequests = createAction<OrgMembershipRequestList>(`${MODULE_NAME}/SET_ORGANIZATION_POSITION_REQUESTS`)
export const removeOrganizationMembership = createAction<number>(`${MODULE_NAME}/REMOVE_ORGANIZATION_MEMBERSHIP`)
export const setLeaveFromOrganizationModal = createAction<{
	isOpen: boolean
	orgId: string
}>(`${MODULE_NAME}/SET_LEAVE_ORGANIZATION_MODAL`)
export const setOrganizationPointOfContact = createAction<OrgMembership>(`${MODULE_NAME}/SET_ORGANIZATION_POINT_OF_CONTACT`)
export const setOpenOrgDefaultOptions = createAction<OpenOrgDefaultOptions | null>(`${MODULE_NAME}/SET_OPEN_ORGANIZATION_WITH_OPTIONS`)
export const setViewedRequests = createAction<number[]>(`${MODULE_NAME}/SET_Viewed_REQUESTS`)

export const setSearchUsers = createAction<{
	page: number
	canLoadMore: boolean
	users: UserData[]
}>(`${MODULE_NAME}/SET_SEARCH_USERS`)
export const clearSearchUsers = createAction(`${MODULE_NAME}/CLEAR_SEARCH_USERS`)

export const setSearchOrgMemberships = createAction<{
	page: number
	canLoadMore: boolean
	memberships: OrgMembership[]
}>(`${MODULE_NAME}/SET_SEARCH_MEMBERSHIPS`)
export const clearSearchOrgMemberships = createAction(`${MODULE_NAME}/CLEAR_SEARCH_MEMBERSHIPS`)
export const setSearchOrgMembershipsQuery = createAction<string>(`${MODULE_NAME}/SET_SEARCH_MEMBERSHIPS_QUERY`)

// @TODO: remove when organizations endpoint are completed in the backend
// switch to optimistic update as we did for jobs, research and study abroad
export const setOrganizationsListFollowStatus = createAction<{ id: string; followed: boolean }>(
	`${MODULE_NAME}/SET_ORGANIZATIONS_LIST_FOLLOW_STATUS`,
)

export const setUserTypeOptions = createAction<UserTypeOption[]>(`${MODULE_NAME}/SET_USER_TYPE_OPTIONS`)

const prepareMembershipData = ({ user, ...rest }: any, campuses?: Dictionary<TCampus>) => ({
	...rest,
	user: user ? getNormalizeUser(user, campuses) : null,
})

const prepareMembershipRequestsData = ({ sourceUser, ...rest }: any, campuses?: Dictionary<TCampus>) => ({
	...rest,
	membership: rest.membership ? prepareMembershipData(rest.membership, campuses) : null,
	sourceUser: sourceUser ? getNormalizeUser(sourceUser, campuses) : null,
})

export const updateUserInSearchResult = createAction<{
	id: number
	membershipTypeId: orgTypes
}>(`${MODULE_NAME}/UPDATE_USER_IN_SEARCH`)

export const loadOrganizationsListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATIONS_LIST_WITH_FILTER_REQUEST`, async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))

		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const storedOrganizations = rootState.ORGANIZATIONS.organizations
		const userOrganizations = rootState.ORGANIZATIONS.userOrganizations
		const storedSearch = rootState.ORGANIZATIONS.search

		const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

		const res = await http.organizations.searchOrganizations({
			q: params?.query,
			page,
			userId,
			campusId: params?.campuses,
			categoryId: params?.categories,
			schoolId,
		})

		const organizations = fetchMore ? [...storedOrganizations, ...res.data] : res.data

		const followedOrganizationsIds = userOrganizations.followed.reduce(
			(acc, org) => (org.followed ? [...acc, org.id] : acc),
			[] as string[],
		)
		const organizationsWithFollowStatus = organizations.map((org: any) => ({
			...org,
			followed: followedOrganizationsIds.includes(org.id),
		}))

		dispatch(
			setFilters({
				...currentConfig,
				endHasBeenReached: !res.data?.length,
				page,
				filterIsReady,
			}),
		)
		await dispatch(setOrganizations(organizationsWithFollowStatus))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadOrganizationsConstantsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATIONS_CONSTANTS_REQUEST`, async (_undefined, { dispatch, rejectWithValue, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const categories = await http.organizations.getListCategories(schoolId)
		const {
			data: { userTypes },
		} = await http.organizations.getUserTypeOptions(schoolId)

		const createOption = (arr: any[]) =>
			arr.map((item) => ({
				label: item.name || item.category,
				value: item.id,
			}))

		await dispatch(setCategories(createOption(categories.data.categories)))
		await dispatch(setUserTypeOptions(userTypes))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadUserOrganizationsRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_ORGANIZATIONS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			data: { followed, member, admin, superAdmin },
		} = await http.organizations.getAllUserOrganizations(userId, schoolId)

		const followedOrganizations = followed.map((org: any) => ({
			...org,
			followed: true,
		}))
		const memberOrganizations = member.map((org: any) => ({
			...org,
			followed: true,
		}))
		const adminOrganizations = admin.map((org: any) => ({
			...org,
			followed: true,
		}))
		const superAdminOrganizations = superAdmin.map((org: any) => ({
			...org,
			followed: true,
		}))

		dispatch(
			setUserOrganizations({
				followed: followedOrganizations,
				member: memberOrganizations,
				admin: adminOrganizations,
				superAdmin: superAdminOrganizations,
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

interface LoadOrganizationWithUserStatusParams {
	id: string
	userId: number
	campuses?: Dictionary<TCampus>
	schoolId: number
}

const loadOrganizationWithUserStatus = async ({ id, userId, campuses, schoolId }: LoadOrganizationWithUserStatusParams) => {
	const {
		data: { response },
	} = await http.organizations.getOrganizationUserResponse(id, userId, schoolId)

	const { data } = await http.organizations.getOrganizationById(id, userId, schoolId)

	const organization = data.organizations

	organization.followed = response === UserResponseStatuses.followed

	organization.pointOfContact = organization.pointOfContact ? prepareMembershipData(organization.pointOfContact, campuses) : null

	return organization
}

export const loadOrganizationActivityRequest = createAsyncThunk<
	any,
	{
		orgId: string
		resetPage?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_ACTIVITY_REQUEST`, async ({ resetPage, orgId }, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(
			setOrganizationActivity({
				isLoading: true,
			}),
		)

		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { page, list } = rootState.ORGANIZATIONS.selectedOrganization.activity

		const prevActivity = !resetPage && list ? list : []
		const newPage = !resetPage && page ? page + 1 : 1

		const { data } = await http.organizations.getOrganizationActivity(newPage, userId, orgId, schoolId)

		const activityArr = data.map((activity: PostActivity) => ({
			...activity,
			post: preparePostData(activity.post, getState()),
		}))

		const mergedActivity = resetPage ? activityArr : [...prevActivity, ...activityArr]

		dispatch(
			setOrganizationActivity({
				page: newPage,
				endHasBeenReached: !activityArr.length || activityArr.length < settings.SEARCH_ACTIVITY_LIST_PER_PAGE,
				list: mergedActivity,
				isLoading: false,
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadOrganizationUserPendingRequestsRequest = createAsyncThunk<
	any,
	{
		orgId: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_USER_PENDING_REQUESTS_REQUEST`, async ({ orgId }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(getState())

		const { data } = await http.organizations.getOrganizationUserPendingMembershipRequests(orgId, userId, schoolId)

		const requests = data.map((request: any) => prepareMembershipRequestsData(request, campusMap))

		dispatch(setOrganizationPendingUserRequests({ id: orgId, requests }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadOrganizationByIdRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_REQUEST`, async (id: string, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(getState())

		const organization = await loadOrganizationWithUserStatus({ id, userId, campuses: campusMap, schoolId })

		dispatch(setIndividualOrganization(organization))
		// @TODO: move to rtk and don't use await later on, if requests don't rely on each other
		await dispatch(loadOrganizationUserPendingRequestsRequest({ orgId: id }))

		// reload organization user roles on the background
		dispatch(loadUserOrganizationRolesRequest())

		return organization
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadOrganizationFollowersRequest = createAsyncThunk<
	any,
	{
		orgId: string
		newPage?: number
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_FOLLOWERS_REQUEST`, async ({ orgId, newPage }, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoadingOrganizationData({ isLoadingFollowers: true }))
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const campusesMap = campusSelectors.selectEntities(rootState)
		const { list, page } = getState().ORGANIZATIONS.selectedOrganization.followers

		const currentPage = newPage ?? page

		const {
			data: { total, followers },
		} = await http.organizations.getOrganizationFollowers(orgId, currentPage, schoolId)

		const newFollowers: UserData[] = followers.map((user: any) => getNormalizeUser(user, campusesMap))

		const followersList = currentPage > 1 ? [...list, ...newFollowers] : newFollowers

		dispatch(
			setOrganizationFollowers({
				total,
				list: followersList,
				page: currentPage + 1,
				canLoadMore: followersList.length < total,
			}),
		)
		dispatch(setIsLoadingOrganizationData({ isLoadingFollowers: false }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const setOrganizationResponseRequest = createAsyncThunk<
	any,
	{
		id: string
		status: UserResponseStatuses | ReportActions
		reportMessage?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SET_ORGANIZATION_STATUS_REQUEST`, async ({ id, status, reportMessage }, { dispatch, getState, rejectWithValue }) => {
	const notificationId = uuidv4()

	try {
		// dispatch(
		//   enqueueSnackbar({
		//     key: notificationId,
		//     notification: {
		//       message: {
		//         type: SnackbarType.uploading,
		//         message: 'Saving response...',
		//       },
		//     },
		//   })
		// );

		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(getState())
		const { selectedOrganization } = rootState.ORGANIZATIONS

		await http.organizations.postOrganizationUserResponse({ id, userId, response: status, reportMessage, schoolId })

		const rsvpStatus = status as UserResponseStatuses
		dispatch(
			organizationApi.util.updateQueryData('getOrganizationInfo', { id, userId }, (draft) => {
				const { organizations } = draft
				organizations.followed = rsvpStatus === UserResponseStatuses.followed ? true : false
				organizations.response = { ...draft.organizations.response, userId, organizationId: id, response: rsvpStatus }
			}),
		)

		// @TODO: This needs to be corrected all this requests will be send, when user just clicks follow button :(
		if (selectedOrganization && selectedOrganization.info?.id === id) {
			dispatch(loadOrganizationFollowersRequest({ orgId: id, newPage: 1 }))
			const organization = await loadOrganizationWithUserStatus({ id, userId, campuses: campusesMap, schoolId })
			dispatch(setIndividualOrganization(organization))
		}

		dispatch(setOrganizationsListFollowStatus({ id, followed: rsvpStatus === UserResponseStatuses.followed ? true : false }))
		dispatch(loadUserOrganizationsRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: notificationId }))
	}
})

export const loadSuperAdminOrganizationsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_SUPER_ADMIN_ORGANIZATIONS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { data } = await http.organizations.getOrganizationsByMembershipType(
			userId,
			getOrganizationType(ORGANIZATION_ROLE.SUPER_ADMIN),
			schoolId,
		)

		dispatch(setSuperAdminOrganizations(data.map((org: any) => getMinimumOrgInfo(org))))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const changeOrganizationMemberRequest = createAsyncThunk<
	any,
	{
		id: number
		membershipTypeId: orgTypes
		title?: string
		membership: OrgMembership
	},
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/CHANGE_ORGANIZATION_MEMBER_REQUEST`,
	async ({ id, membershipTypeId, title, membership }, { dispatch, getState, rejectWithValue }) => {
		try {
			const rootState = getState()
			const { schoolId, userId } = selectAuth(rootState)

			const orgId = rootState.ORGANIZATIONS.selectedOrganization!.info!.id

			const {
				data: { membershipInfo: info },
			} = await http.organizations.patchOrganizationMember({
				id,
				changeType: 'Edit',
				orgId,
				patchedUserId: userId,
				membershipTypeId,
				title,
				schoolId,
			})

			const newMembership: OrgMembership = {
				...membership,
				...info,
			}

			dispatch(updateOrganizationMemberships(newMembership))

			dispatch(loadUserOrganizationsRequest())
			dispatch(loadSuperAdminOrganizationsRequest())
			dispatch(loadUserOrganizationRolesRequest())
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const removeOrganizationMemberRequest = createAsyncThunk<
	any,
	{
		id: number
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/REMOVE_ORGANIZATION_MEMBER_REQUEST`, async ({ id }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const orgId = rootState.ORGANIZATIONS.selectedOrganization!.info!.id

		dispatch(removeOrganizationMembership(id))

		await http.organizations.patchOrganizationMember({ id, changeType: 'Remove', orgId, patchedUserId: userId, schoolId })

		await dispatch(loadOrganizationUserPendingRequestsRequest({ orgId }))

		dispatch(loadUserOrganizationsRequest())
		dispatch(loadSuperAdminOrganizationsRequest())
		dispatch(loadUserOrganizationRolesRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadOrganizationMemberRequestsRequest = createAsyncThunk<
	any,
	{
		page: number
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_MEMBERS_REQUESTS_REQUEST`, async ({ page }, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoadingOrganizationData({ isLoadingNewMemberRequests: true }))
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(getState())
		const id = getState().ORGANIZATIONS.selectedOrganization.info!.id
		const { list } = getState().ORGANIZATIONS.selectedOrganization.newMemberRequests

		const {
			data: { total, requests, unseenCount },
		} = await http.organizations.getOrganizationMemberRequests(id, page, schoolId)

		const newRequests = requests.map((data: any) => prepareMembershipRequestsData(data, campusesMap))

		const requestsList = page > 1 && list ? [...list, ...newRequests] : newRequests

		dispatch(
			setOrganizationMembershipRequests({
				total,
				list: requestsList,
				unseenCount,
				page,
			}),
		)
		dispatch(setIsLoadingOrganizationData({ isLoadingNewMemberRequests: false }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const setViewedOrganizationRequestsRequest = createAsyncThunk<
	any,
	{
		requests: OrgMembershipRequest[]
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SET_VIEWED_ORGANIZATION_REQUESTS_REQUEST`, async ({ requests }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const id = rootState.ORGANIZATIONS.selectedOrganization.info!.id

		const unseenRequestIds = requests.reduce((acc: number[], request: any) => (request.viewed ? acc : [...acc, request.id]), [])

		if (unseenRequestIds.length) {
			await http.organizations.patchOrganizationMembershipRequestToViewed({ orgId: id, data: unseenRequestIds, schoolId })

			dispatch(setViewedRequests(unseenRequestIds))
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadOrganizationPositionRequestsRequest = createAsyncThunk<
	any,
	{
		page: number
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_ORGANIZATION_POSITION_REQUESTS_REQUEST`, async ({ page }, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoadingOrganizationData({ isLoadingPositionRequests: true }))

		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)
		const id = rootState.ORGANIZATIONS.selectedOrganization.info!.id
		const { list } = rootState.ORGANIZATIONS.selectedOrganization.positionRequests

		const {
			data: { total, requests, unseenCount },
		} = await http.organizations.getOrganizationChangePositionRequests(id, page, userId, schoolId)

		const newRequests = requests.map((data: any) => prepareMembershipRequestsData(data, campusesMap))

		const requestsList = page > 1 && list ? [...list, ...newRequests] : newRequests

		dispatch(
			setOrganizationPositionRequests({
				total,
				unseenCount,
				list: requestsList,
				page,
			}),
		)
		dispatch(setIsLoadingOrganizationData({ isLoadingPositionRequests: false }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const changeMembershipRequestStatusRequest = createAsyncThunk<
	any,
	{
		requestId: number
		status: OrgMembershipRequestStatus
	},
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/CHANGE_ORGANIZATION_MEMBERSHIP_REQUESTS_STATUS`,
	async ({ requestId, status }, { dispatch, getState, rejectWithValue }) => {
		try {
			const rootState = getState()
			const { schoolId, userId } = selectAuth(rootState)

			const campusesMap = campusSelectors.selectEntities(rootState)

			const {
				data: { membership, request },
			} = await http.organizations.patchOrganizationMembershipRequest({ id: requestId, patchedUserId: userId, status, schoolId })

			const { list, total, unseenCount, page } = rootState.ORGANIZATIONS.selectedOrganization.newMemberRequests

			if (status === OrgMembershipRequestStatus.ACCEPTED) {
				const newMembership = prepareMembershipData(membership, campusesMap)

				dispatch(updateOrganizationMemberships(newMembership))
			}

			dispatch(
				setOrganizationMembershipRequests({
					total: total - 1,
					unseenCount: unseenCount - 1,
					list: list.map((req) => (req.id !== requestId ? req : prepareMembershipRequestsData(request, campusesMap))),
					page,
				}),
			)
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const cancelMembershipRequestRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CANCEL_ORGANIZATION_MEMBERSHIP_REQUESTS_STATUS`, async (orgId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)
		const requests = rootState.ORGANIZATIONS.pendingUserRequests[orgId] ?? []

		if (!requests.length) return null

		const requestId = requests.find(
			(req) =>
				req.status === OrgMembershipRequestStatus.PENDING &&
				req.organizationMembershipTypeId === getOrganizationType(ORGANIZATION_ROLE.MEMBER),
		)?.id

		if (!requestId) return null

		await http.organizations.patchOrganizationMembershipRequest({
			id: requestId,
			patchedUserId: userId,
			status: OrgMembershipRequestStatus.CANCELED,
			schoolId,
		})

		const organization = await loadOrganizationWithUserStatus({ id: orgId, userId, campuses: campusesMap, schoolId })

		dispatch(setIndividualOrganization(organization))
		dispatch(loadUserOrganizationsRequest())
		dispatch(loadOrganizationUserPendingRequestsRequest({ orgId }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const changePositionRequestStatusRequest = createAsyncThunk<
	any,
	{
		requestId: number
		status: OrgMembershipRequestStatus
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_ORGANIZATION_POSITION_STATUS_REQUEST`, async ({ requestId, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)

		const {
			data: { membership, request },
		} = await http.organizations.patchOrganizationMembershipRequest({ id: requestId, patchedUserId: userId, status, schoolId })

		const { list, total, unseenCount, page } = rootState.ORGANIZATIONS.selectedOrganization.positionRequests

		if (status === OrgMembershipRequestStatus.ACCEPTED) {
			const newMembership = prepareMembershipData(membership, campusesMap)

			dispatch(updateOrganizationMemberships(newMembership))
		}

		dispatch(
			setOrganizationPositionRequests({
				total: total - 1,
				unseenCount: unseenCount - 1,
				list: list.map((req) => (req.id !== requestId ? req : prepareMembershipRequestsData(request, campusesMap))),
				page,
			}),
		)
		dispatch(loadUserOrganizationsRequest())
		dispatch(loadSuperAdminOrganizationsRequest())
		dispatch(loadUserOrganizationRolesRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const createOrganizationMembershipRequestRequest = createAsyncThunk<
	any,
	{
		membershipTypeId: orgTypes
		orgId: string
	},
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/CREATE_ORGANIZATION_MEMBERSHIP_REQUEST_REQUEST`,
	async ({ membershipTypeId, orgId }, { dispatch, getState, rejectWithValue }) => {
		try {
			const rootState = getState()
			const { schoolId, userId } = selectAuth(rootState)

			await http.organizations.postOrganizationMembershipRequest({ orgId, userId, membershipTypeId, schoolId })

			await dispatch(loadOrganizationUserPendingRequestsRequest({ orgId }))
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const createInfoRequest = createAsyncThunk<
	any,
	{
		membershipTypeId: orgTypes
		orgId: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/GET_ORGANIZATION_JOIN_INFO`, async ({ orgId }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		await http.organizations.postOrganizationsMembershipJoinInfo({ orgId, userId, schoolId })

		await dispatch(loadOrganizationUserPendingRequestsRequest({ orgId }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const leaveOrganizationRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LEAVE_ORGANIZATION_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { orgId } = rootState.ORGANIZATIONS.leaveFromOrganizationModal

		await http.organizations.deleteOrganizationMember(orgId, userId, schoolId)

		await dispatch(loadOrganizationUserPendingRequestsRequest({ orgId }))
		dispatch(loadUserOrganizationsRequest())
		dispatch(loadSuperAdminOrganizationsRequest())
		dispatch(loadUserOrganizationRolesRequest())

		const {
			data: { user: userData },
		} = await http.user.getUserInfo(userId, schoolId)

		dispatch(
			setUserAfterPageRefresh({
				userInfo: userData,
				role: getUserRole(userData.appUserTypeId),
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const changeOrganizationContactRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_ORGANIZATION_CONTACT_REQUEST`, async (requestId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)

		const { data } = await http.organizations.patchOrganizationContact(requestId, userId, schoolId)

		const membership = prepareMembershipData(data, campusesMap)

		dispatch(setOrganizationPointOfContact(membership))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const updateOrganizationInfoRequest = createAsyncThunk<
	any,
	OrganizationEditForm,
	{
		state: RootState
	}
>(`${MODULE_NAME}/UPDATE_ORGANIZATION_INFO_REQUEST`, async (formData, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)
		const orgId = rootState.ORGANIZATIONS.selectedOrganization.info!.id
		const oldPhotoUrl = rootState.ORGANIZATIONS.selectedOrganization.info!.photoUrl

		const imageName = uuidv4()
		const photoUrl = createPhotoUrl(imageName, PhotoUploadDirectories.ORGANIZATION)

		const photoUpdated = !!formData.photoUrl && formData.photoUrl !== oldPhotoUrl

		const response = await http.organizations.patchOrganization(
			orgId,
			userId,
			{
				...formData,
				photoUrl: photoUpdated ? photoUrl : oldPhotoUrl,
			},
			schoolId,
		)

		if (response.status === 200 && photoUpdated) {
			await uploadPhoto({
				schoolId,
				fileUrl: formData.photoUrl!,
				imageName,
				directory: PhotoUploadDirectories.ORGANIZATION,
				options: {
					compressImage: true,
					onError: {
						uploadErrors: UploadErrors.ORGANIZATION,
						uploadErrorMessage: UploadErrorMessage.ORGANIZATION,
					},
				},
			})
		}

		const organization = await loadOrganizationWithUserStatus({ id: orgId, userId, campuses: campusesMap, schoolId })

		dispatch(setIndividualOrganization(organization))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const searchUserRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
		query?: string
		campusId?: null | number
		organizationMembershipTypeId?: null | orgTypes
		maxMembershipTypeId: orgTypes
	},
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/SEARCH_USER`,
	async ({ fetchMore, query, campusId, organizationMembershipTypeId, maxMembershipTypeId }, { dispatch, getState, rejectWithValue }) => {
		try {
			const rootState = getState()
			const { schoolId, userId } = selectAuth(rootState)

			const campusesMap = campusSelectors.selectEntities(rootState)
			const storedUsers = rootState.ORGANIZATIONS.selectedOrganization.searchUsers.users
			const currentPage = rootState.ORGANIZATIONS.selectedOrganization.searchUsers.page
			const orgId = rootState.ORGANIZATIONS.selectedOrganization.info!.id

			const page = fetchMore ? currentPage + 1 : 1

			const {
				data: { users },
			} = await http.organizations.searchUsers({
				userId,
				q: query,
				page,
				organizationId: orgId,
				campusId,
				organizationMembershipTypeId,
				maxMembershipTypeId,
				schoolId,
			})

			const newUsers = users.map((user: any) => getNormalizeUser(user, campusesMap))

			const usersList = fetchMore ? storedUsers.concat(newUsers) : newUsers

			dispatch(
				setSearchUsers({
					page,
					canLoadMore: !!newUsers.length,
					users: usersList,
				}),
			)
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

export const searchOrgMembershipsRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SEARCH_MEMBERSHIPS`, async ({ fetchMore }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(rootState)
		const storedMemberships = rootState.ORGANIZATIONS.selectedOrganization.searchOrgMemberships.memberships
		const currentPage = rootState.ORGANIZATIONS.selectedOrganization.searchOrgMemberships.page
		const orgId = rootState.ORGANIZATIONS.selectedOrganization.info!.id

		const searchQuery = rootState.ORGANIZATIONS.selectedOrganization.searchOrgMemberships.q
		const page = fetchMore ? currentPage + 1 : 1

		const {
			data: { memberships },
		} = await http.organizations.searchOrganizationMemberships({
			userId,
			q: searchQuery,
			page,
			organizationId: orgId,
			schoolId,
		})

		const newMemberships = memberships.map((user: any) => prepareMembershipData(user, campusesMap))

		const membershipsList = fetchMore ? storedMemberships.concat(newMemberships) : newMemberships

		dispatch(
			setSearchOrgMemberships({
				page,
				canLoadMore: !!newMemberships.length,
				memberships: membershipsList,
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const createMembershipsRequest = createAsyncThunk<
	any,
	{
		targetUserId: number
		membershipTypeId: orgTypes
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_MEMBERSHIP`, async ({ targetUserId, membershipTypeId }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const orgId = rootState.ORGANIZATIONS.selectedOrganization.info!.id

		dispatch(
			updateUserInSearchResult({
				id: targetUserId,
				membershipTypeId,
			}),
		)

		await http.organizations.postNewMembership({ userId, orgId, targetUserId, membershipTypeId, schoolId })
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { Chip, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Cancel } from '@material-ui/icons'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ColorButton, FilledTextField, OutlinedSelect } from '@/components'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import UserRow from '@/components/UserRow'
import { STYLES } from '@/constants'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import {
	clearSearchUsers,
	getCanLoadMoreSearchUserResults,
	getIsLoadingSearchUserResults,
	getSearchUserResults,
	searchUsersToInviteRequest,
} from '@/store/groups'
import { submitFeedback } from '@/utils/services'

import IconWrapper from '@/components/IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 20,
	},
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	inputIcon: ({ focused }: any) => ({
		color: focused ? theme.palette.text.primary : theme.palette.text.secondary,
	}),
	clearInputButton: {
		cursor: 'pointer',
	},
	searchResultContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 350,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '15px 0',
	},
	row: {
		padding: 10,
		borderRadius: 5,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.grey[100],
		},
	},
	userRow: {
		margin: 0,
	},
	userRowInformationContainer: {
		justifyContent: 'center',
	},
	userName: {
		fontWeight: 600,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},
	select: {
		minWidth: 100,
		width: 200,
		marginLeft: 15,
		height: 50,
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	submitButton: {
		minWidth: 200,
		margin: '35px 0 15px 0',
	},
	checkUserIcon: {
		width: '1.5em',
		height: '1.5em',
		color: theme.palette.text.secondary,
	},
	tagsContainer: {
		marginTop: 15,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		overflowX: 'auto',
		overflowY: 'hidden',
	},
	tag: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 32,

		padding: '2vh 1vw',
		margin: '0 0.5vw',
		fontSize: 14,
		fontWeight: 550,
	},
	tagIcon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
}))

interface AddGroupMembersProps {
	onSubmit: (users: UserData[]) => void
	users?: UserData[]
	submitLabel?: string
	onTouch?: () => void
	classnames?: {
		searchResultContainer?: string
		root?: string
		header?: string
		input?: string
		select?: string
	}
	groupId?: string
}

const AddGroupMembers = ({
	onSubmit,
	users,
	submitLabel,
	onTouch,
	classnames = {
		searchResultContainer: '',
		root: '',
		header: '',
		input: '',
		select: '',
	},
	groupId,
}: AddGroupMembersProps) => {
	const dispatch = useAppDispatch()

	const campuses = useSelector(getActiveCampuses)
	const showCampuses = useSelector(selectShowCampuses)
	const canLoadMore = useSelector(getCanLoadMoreSearchUserResults)
	const isLoading = useSelector(getIsLoadingSearchUserResults)
	const searchResults = useSelector(getSearchUserResults)

	const [focused, setFocused] = useState(false)
	const [resetScrollPosition, setResetScrollPosition] = useState(false)

	const [searchValue, setSearchValue] = useState('')
	const [campus, setCampus] = useState<any>(null)
	const [usersToAdd, setUsersToAdd] = useState<UserData[]>(users ?? [])

	const classes = useStyles({ focused })

	const campusesOptions = useMemo(
		() => [
			{
				label: 'All campuses',
				value: null,
			},
			...campuses,
		],
		[campuses],
	)

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[],
	)

	const handleSetFocus = useCallback(
		(val: boolean) => () => {
			setFocused(val)
		},
		[],
	)

	const handleClearInput = useCallback(() => {
		setSearchValue('')
		dispatch(clearSearchUsers())
	}, [dispatch])

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (value && !value.trim()) return handleClearInput()

			setSearchValue(value)
		},
		[handleClearInput],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(
			searchUsersToInviteRequest({
				fetchMore: true,
				q: searchValue,
				campusId: campus,
				groupId,
			}),
		)
	}, [campus, dispatch, searchValue, groupId])

	const handleSearch = useCallback(() => {
		setResetScrollPosition(true)
		dispatch(
			searchUsersToInviteRequest({
				fetchMore: false,
				q: searchValue,
				campusId: campus,
				groupId,
			}),
		)
	}, [campus, dispatch, searchValue, groupId])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[handleSearch],
	)

	const handleRemoveUser = (id: number) => (e: any) => {
		e.stopPropagation()
		e.preventDefault()

		if (onTouch) onTouch()
		setUsersToAdd((prev) => prev.filter((user) => user.id !== id))
	}

	const isUserSelected = (userId: number) => usersToAdd.some((u) => u.id === userId)

	const handleAddUser = (user: UserData) => (e: any) => {
		if (onTouch) onTouch()
		if (isUserSelected(user.id!)) {
			handleRemoveUser(user.id!)(e)
		} else {
			setUsersToAdd((prev) => [...prev, user])
		}
	}

	const handleSubmit = () => {
		onSubmit(usersToAdd)
	}

	useEffect(() => {
		delayedSearch()

		return () => {
			delayedSearch.cancel()
			dispatch(clearSearchUsers())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campus, searchValue, groupId])

	useEffect(() => {
		if (!isLoading) setResetScrollPosition(false)
	}, [isLoading])

	const showNoResultFoundPlaceholder = !isLoading && !searchResults.length && !canLoadMore

	return (
		<div className={classNames(classnames.root, classes.root)}>
			<div className={classes.container}>
				<div className={classNames(classnames.header, classes.header)}>
					<FilledTextField
						className={classNames(classnames.input, classes.input)}
						onChange={handleChangeInput}
						value={searchValue}
						placeholder="Search all users"
						inputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							),
							endAdornment: searchValue ? (
								<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
									<Close className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							) : undefined,
						}}
						onFocus={handleSetFocus(true)}
						onBlur={handleSetFocus(false)}
					/>
					{showCampuses && (
						<OutlinedSelect
							classnames={{
								input: classNames(classnames.select, classes.select),
							}}
							value={campus}
							onChange={setCampus}
							options={campusesOptions}
							placeholder="Select campus"
						/>
					)}
				</div>
				<div className={classes.tagsContainer}>
					{usersToAdd.map((user, idx) => (
						<Chip
							className={classes.tag}
							key={`chip-${user.id}-${idx}`}
							label={user.fullName}
							onDelete={handleRemoveUser(user.id!)}
							deleteIcon={<Cancel className={classes.tagIcon} />}
							variant="outlined"
						/>
					))}
				</div>
				<div className={classNames(classnames.searchResultContainer, classes.searchResultContainer)}>
					{showNoResultFoundPlaceholder ? (
						<NotFoundPlaceholder buttons={placeholderButtons} showNothingSeemsToMatchMessage={false} />
					) : (
						<InfiniteScrollComponent
							classnames={{
								content: classes.cardContainer,
							}}
							onLoadMore={handleLoadMore}
							dataLength={searchResults.length}
							canLoadMore={canLoadMore}
							// height={500}
							resetScroll={resetScrollPosition}
							internalScroll
						>
							{searchResults.map((user) => (
								<div className={classes.row} key={`search-user-${user.id}`} onClick={handleAddUser(user)}>
									<UserRow
										classnames={{
											root: classes.userRow,
											informationContainer: classes.userRowInformationContainer,
											userName: classes.userName,
										}}
										user={user}
										profileImageRadius={7}
										openProfileOnClick={false}
										endAdornment={
											<IconWrapper
												iconKey={isUserSelected(user.id) ? 'checkCircle' : 'circle'}
												className={classes.checkUserIcon}
												weight={isUserSelected(user.id) ? 'fill' : 'regular'}
											/>
										}
										showAdditionalInfo={false}
									/>
								</div>
							))}
						</InfiniteScrollComponent>
					)}
				</div>
			</div>
			<ColorButton onClick={handleSubmit} className={classes.submitButton}>
				{submitLabel ? submitLabel : usersToAdd.length ? 'Next' : 'Skip'}
			</ColorButton>
		</div>
	)
}

export default AddGroupMembers

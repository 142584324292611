import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	messageText: {
		color: theme.palette.error.main,
		margin: '1vh 0',
	},
}))

interface FieldErrorMessageProps {
	hasError?: boolean
	message?: string
	children?: React.ReactNode
}

export const FieldErrorMessage = ({ hasError = false, message = '', children }: FieldErrorMessageProps) => {
	const classes = useStyles()

	return hasError ? (
		<Typography className={classes.messageText}>
			{message}
			{children}
		</Typography>
	) : (
		<>{children}</>
	)
}

export default FieldErrorMessage

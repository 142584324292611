import { useAppDispatch } from '@/store'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import { OrgDashboardActions } from '@/components/IndividualPage/OrganizationOwnerDashboard'

import PageContainer from '@/components/IndividualPage/PageContainer'
import { ReportSubject } from '@/components/ReportModal'
import FORM from '@/constants/createEventFormFields'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { ReportActions } from '@/interfaces/common'
import { getUserOrganizationRoles } from '@/store/auth'
import { openCreateEventPage } from '@/store/events'
import {
	createOrganizationMembershipRequestRequest,
	getHasPendingJoinToOrganizationRequest,
	getOpenOrgDefaultOptions,
	setOpenOrgDefaultOptions,
} from '@/store/organizations'

import { getMaxMembershipPermissionLevel, orgTypes } from '@/utils/organizationRoles'

import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import { useGetOrganizationByIdQuery, useReportOrganizationMutation, useToggleOrganizationFollowMutation } from '../../api'
import OrganizationTitleCard from '../../components/OrganizationTitleCard'
import ActivityTab from './ActivityTab'
import EventsTab from './EventsTab'
import ProfileTab from './ProfileTab'
import OrganizationOwnerDashboard from '../../components/OrganizationOwnerDashboard'
import OrganizationRoleTypeStatus from '../../types/OrganizationRoleTypeStatus'

enum OrganizationTabs {
	PROFILE = 'Profile',
	ACTIVITY = 'Activity',
	EVENTS = 'Events',
}

const tabIndex = {
	[OrganizationTabs.PROFILE]: 0,
	[OrganizationTabs.ACTIVITY]: 1,
	[OrganizationTabs.EVENTS]: 2,
}

const navigationTabs = Object.values(OrganizationTabs)

const IndividualOrganization = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()

	const { data: organization, isLoading } = useGetOrganizationByIdQuery({ id })
	const followed = organization && organization?.follow && organization?.follow.response === 'followed'

	const userRoles = useSelector(getUserOrganizationRoles(id))
	const userRequestedToJoin = useSelector(getHasPendingJoinToOrganizationRequest(id, userRoles))

	const openOrgDefaultOptions = useSelector(getOpenOrgDefaultOptions)

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const userPermissionsLevel = getMaxMembershipPermissionLevel(userRoles)
	const [activeTab, setActiveTab] = useState(openOrgDefaultOptions ? -1 : tabIndex[OrganizationTabs.PROFILE])

	useWatchPageTitle({
		watchEntity: organization?.name,
	})

	const handleClickCreateEvent = useCallback(() => {
		dispatch(
			openCreateEventPage({
				[FORM.hostOrganization]: [id],
			}),
		)
	}, [dispatch, id])

	const handleOpenCreateFeedModal = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.isOpen = true
			data.content.ownerOrganizationId = id
		})
	}, [id, setCreateFeedsModal])

	const handleOpenActivityTab = useCallback(() => {
		setActiveTab(tabIndex[OrganizationTabs.ACTIVITY])
	}, [])

	const tabComponentsByIndex = useMemo(() => {
		const organizationInfo = {
			photoUrl: organization?.profilePicture,
			name: organization?.name,
		}
		return {
			[tabIndex[OrganizationTabs.PROFILE]]: <ProfileTab id={id} onClickPostsInfo={handleOpenActivityTab} />,
			[tabIndex[OrganizationTabs.ACTIVITY]]: (
				<ActivityTab
					orgId={id}
					organizationInfo={organizationInfo}
					handleOpenCreateFeedModal={handleOpenCreateFeedModal}
					canCreateFeeds={false}
				/>
			),
			[tabIndex[OrganizationTabs.EVENTS]]: <EventsTab orgId={id} canCreateEvents={false} handleClickCreateEvent={handleClickCreateEvent} />,
		}
	}, [organization?.profilePicture, organization?.name, id, handleOpenActivityTab, handleOpenCreateFeedModal, handleClickCreateEvent])

	const activeTabComponent = useMemo(() => tabComponentsByIndex[activeTab], [activeTab, tabComponentsByIndex])
	const organizationName = `${organization?.name}`
	const handleTabChange = (value: number) => {
		setActiveTab(value)
	}

	const handleSelectAdminAction = (action: OrgDashboardActions) => {
		setActiveTab(-1)
	}
	const [toggleFollowOrgMutation] = useToggleOrganizationFollowMutation()

	const handleToggleResponse = () => {
		toggleFollowOrgMutation({ id, action: followed ? 'unfollow' : 'follow' })
	}

	const [reportMutation] = useReportOrganizationMutation()
	const handleReportOrganization = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			if (status === ReportActions.inaccurate || status === ReportActions.inappropriate) {
				reportMutation({ id, response: status, reportMessage })
			}
		},
		[reportMutation],
	)

	const handleRequestToChangePosition = useCallback(
		(membershipTypeId: orgTypes) => {
			dispatch(
				createOrganizationMembershipRequestRequest({
					orgId: id,
					membershipTypeId,
				}),
			)
		},
		[dispatch, id],
	)

	const scrollTarget = openOrgDefaultOptions ? document.getElementById(openOrgDefaultOptions.scrollTo) : null

	useEffect(() => {
		if (openOrgDefaultOptions && organization?.id && !isLoading && scrollTarget) {
			handleSelectAdminAction(openOrgDefaultOptions.activeDashboardTab)

			setTimeout(() => {
				scrollTarget?.scrollIntoView({ block: 'start' })
			}, 300)

			dispatch(setOpenOrgDefaultOptions(null))
		}
	}, [organization?.id, isLoading, openOrgDefaultOptions, scrollTarget, dispatch])

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.organization } }), [id])

	if (!organization?.id || isLoading) return <LoadingPlaceholder />

	return (
		<PageContainer>
			<ImageCard
				dotButton={{
					itemId: organization.id,
					reportSubject: ReportSubject.Organization,
					onSubmitReport: handleReportOrganization,
				}}
				photoUrl={organization.profilePicture}
				isEvent={false}
			/>
			<OrganizationTitleCard
				followed={followed}
				handleToggleResponse={handleToggleResponse}
				id={id}
				title={organizationName}
				externalUrl={organization.websiteKey}
				activeTab={activeTab}
				tabs={navigationTabs}
				handleChangeTab={handleTabChange}
				sharedData={sharedData}
				userRole={userPermissionsLevel?.role}
				userRequestedToJoin={userRequestedToJoin}
				userMembershipTypeId={userPermissionsLevel?.type}
				handleRequestToChangePosition={handleRequestToChangePosition}
				canLeaveOrganization={false}
				userOrganizationRole={organization.role}
				showJoin={organization.showJoin}
			/>

			{organization.role?.type === OrganizationRoleTypeStatus.OFFICER ? (
				<OrganizationOwnerDashboard handleOpenCreateFeedModal={handleOpenCreateFeedModal} userOrganizationRole={organization.role} />
			) : null}

			{activeTabComponent}
		</PageContainer>
	)
}

export default IndividualOrganization

import { ContentPreloader } from '@/components/ContentPreloader'
import { UserInfo } from '@/interfaces/user'
import { Grid } from '@material-ui/core'
import { inflect } from 'inflection'
import React from 'react'
import { useGetAnalyticsUserAppUsageQuery } from '../../api/usersApi'
import { CommonCard } from '../CommonCard'
import { Stat } from '../Stat'

export type IAppUsageProps = {
	userId: UserInfo['id']
}

const AppUsage: React.FC<IAppUsageProps> = ({ userId }) => {
	const { data, isLoading } = useGetAnalyticsUserAppUsageQuery({ userId })

	return (
		<CommonCard title="App Usage">
			{isLoading ? (
				<ContentPreloader />
			) : (
				data && (
					<Grid container spacing={2}>
						<Grid xs={3} item>
							<Stat color="blue" count={data.connectionsCount} text={inflect('Connection', data.connectionsCount)} />
						</Grid>
						<Grid xs={3} item>
							<Stat color="orange" count={data.groupsCount} text={`${inflect('Group Chat', data.groupsCount)} Joined`} />
						</Grid>
						<Grid xs={3} item>
							<Stat color="green" count={data.postCount} text={inflect('Post', data.postCount)} />
						</Grid>
					</Grid>
				)
			)}
		</CommonCard>
	)
}

export default AppUsage

import { SvgIcon } from '@material-ui/core'

const AddToCalendarOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="14 10 24 24" width="1em" height="1em" {...props}>
			<defs>
				<filter id="prefix__a" x={0} y={0} width={51.924} height={50.944} filterUnits="userSpaceOnUse">
					<feOffset dy={3} />
					<feGaussianBlur stdDeviation={5} result="blur" />
					<feFlood floodOpacity={0.161} />
					<feComposite operator="in" in2="blur" />
					<feComposite in="SourceGraphic" />
				</filter>
			</defs>
			<g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 4120">
				<path
					data-name="\u041A\u043E\u043D\u0442\u0443\u0440 455"
					d="M19.887 10.69v4.085"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth={1.5}
				/>
				<path
					data-name="\u041B\u0438\u043D\u0438\u044F 10"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth={1.5}
					d="M32.037 10.69v4.085"
				/>
				<g filter="url(#prefix__a)" data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 13">
					<g data-name="\u041A\u043E\u043D\u0442\u0443\u0440 10" fill="none">
						<path d="M16.42 12H35.5a1.508 1.508 0 011.42 1.583v17.778a1.508 1.508 0 01-1.42 1.583H16.42A1.508 1.508 0 0115 31.361V13.583A1.508 1.508 0 0116.42 12z" />
						<path
							d="M16.514 13.5a.235.235 0 00-.014.083v17.778c0 .037.006.064.014.083H35.41a.235.235 0 00.013-.083V13.583a.235.235 0 00-.013-.083H16.514M16.42 12h19.084c.784 0 1.42.709 1.42 1.583v17.778c0 .874-.636 1.583-1.42 1.583H16.42c-.784 0-1.42-.709-1.42-1.583V13.583c0-.874.636-1.583 1.42-1.583z"
							fill="#fff"
						/>
					</g>
				</g>
			</g>
			<g data-name="ADD (1)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={1.5}>
				<path data-name="\u041B\u0438\u043D\u0438\u044F 271" d="M25.749 19.223l-.072 7.146" strokeWidth={1.50021} />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 272" d="M29.286 22.832l-7.146-.072" strokeWidth={1.50021} />
			</g>
		</SvgIcon>
	)
}

export default AddToCalendarOutlinedIcon

import { TOnboardingEntity, onboardingEntitySelectors, onboardingSlice } from '@/features/onboarding/slice'
import { TOnboardingEntitiesParams } from '@/features/onboarding/types/queryParams/GetOnboardingEntitiesParams'
import { useAppSelector } from '@/store'
import { EntityId } from '@reduxjs/toolkit'
import { FC, ReactNode, useCallback } from 'react'
import { useDispatch } from 'react-redux'

export type IOnboardingItemProps = Omit<TOnboardingEntitiesParams, 'campusId'> & {
	renderItem: (params: { item: TOnboardingEntity; onClick: () => void }) => ReactNode
	entityId: EntityId
}

const OnboardingItem: FC<IOnboardingItemProps> = ({ entityId, renderItem, entityType, entityScope }) => {
	const dispatch = useDispatch()
	const item = useAppSelector((state) => onboardingEntitySelectors.selectById(state, entityId))
	const onClick = useCallback(() => {
		dispatch(onboardingSlice.actions.toggleSelect({ entityType, entityScope, entityId }))
	}, [dispatch, entityId, entityScope, entityType])
	return <>{renderItem({ item, onClick })}</>
}

export default OnboardingItem

import EventCard from '@/components/ExperienceCards/EventCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { getEndHasBeenReached, getEvents, getFilterIsReady, getIsLoading } from '@/store/events'
import { loadEventsListWithFilterRequest, openCreateEventPage } from '@/store/events/actions'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		height: '90%',
	},
	placeholder: {
		height: '83%',
	},
}))

const DiscoverEvents = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const events = useSelector(getEvents)
	const isLoading = useSelector(getIsLoading)
	const filterIsReady = useSelector(getFilterIsReady)
	const endHasBeenReached = useSelector(getEndHasBeenReached)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) dispatch(loadEventsListWithFilterRequest({ fetchMore: true }))
	}, [dispatch, filterIsReady])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Create Event',
				onClick: () => {
					dispatch(openCreateEventPage({}))
				},
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[dispatch],
	)

	const showPlaceholder = !canLoadMore && !isLoading && !events.length

	if (showPlaceholder)
		return (
			<NotFoundPlaceholder className={classes.placeholder} iconKey={FEATURE.volunteers} buttons={placeholderButtons}>
				<Typography align="center">Try expanding your search, or create a</Typography>
				<Typography align="center">volunteer event yourself.</Typography>
			</NotFoundPlaceholder>
		)

	return (
		<div className={classes.root}>
			<ExperienceCardsSection
				placeholder="That’s all for now. Check back soon for more events!"
				handleLoadMoreItems={handleLoadMore}
				items={events}
				canLoadMore={canLoadMore}
				isLoading={isLoading}
				renderItems={(items: any) => (
					<>
						{items.map(
							({
								name,
								startDate,
								endDate,
								photoUrl,
								locationName,
								id,
								description,
								response,
								categories,
								organization,
								volunteerShifts,
								submittedByUserId,
							}: any) => (
								<EventCard
									key={`${id}-${name}-${startDate}`}
									id={id}
									title={name}
									photoUrl={photoUrl}
									startDate={startDate}
									categories={categories}
									endDate={endDate}
									description={description}
									organization={organization}
									locationName={locationName}
									eventResponse={response}
									volunteerShifts={volunteerShifts}
									submittedByUserId={submittedByUserId}
								/>
							),
						)}
					</>
				)}
			/>
		</div>
	)
}

export default DiscoverEvents

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'
interface PatchUserSelfReportedItemsParams {
	userId: UserInfo['id']
	body: any
	schoolId: number
}

const patchUserSelfReportedItems = async ({ body, schoolId, userId }: PatchUserSelfReportedItemsParams) => {
	const url = `${schoolId}/users/user/${userId}/editItems`

	return http.patch(url, body)
}

export default patchUserSelfReportedItems

import http from '@/api/http/requestHttp'
import { UserPrivacyOption } from '@/store/auth/types'

export interface DtoUserPrivacyTypes {
	userPrivacyTypes: UserPrivacyOption[]
}

const getUserPrivacyTypes = async (schoolId: number) => {
	const url = `${schoolId}/users/privacyTypes`

	return http.get<DtoUserPrivacyTypes>(url)
}

export default getUserPrivacyTypes

import { makeStyles } from '@material-ui/core'
import React from 'react'
import { RequireExactlyOne } from 'type-fest'
import { useAppSelector } from '../../../store'
import { getUserInfo } from '../../../store/auth'
import { useGetKibanaDashboardQuery } from '../api/settingsApi'
import { DashboardType } from '../types/dashboardTypes'
import withRoleBasedErrors from './withRoleBasedErrors'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	frameContainer: {
		display: 'flex',
		flex: 1,
	},
	frame: {
		border: 0,
		width: '100%',
	},
}))

export interface IAnalyticsFrameProps {
	source?: string
	dashboardType?: DashboardType
	startDate?: string
}

const AnalyticsFrame: React.FC<RequireExactlyOne<IAnalyticsFrameProps, 'source' | 'dashboardType'>> = ({
	source,
	dashboardType,
	startDate,
}) => {
	const classes = useStyles()
	let { campus, analyticsRole, academicCollege } = useAppSelector(getUserInfo)
	const { data } = useGetKibanaDashboardQuery({ dashboardType, campus, academicCollege, analyticsRole })

	return <iframe className={classes.frame} src={data?.dashboardURL} height="100%" width="100%" title="Analytics Frame"></iframe>
}

const AnalyticsFrameWithRoleCheck = withRoleBasedErrors(AnalyticsFrame)
export { AnalyticsFrame, AnalyticsFrameWithRoleCheck }

import * as http from '@/api/http'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { RootState } from '../../store'
import { selectAuth } from '../../store/auth'
import { MODULE_NAME } from './constants'
import { loadUserPreferencesRequestFulfilled, togglePreference } from './slice'
import { DtoPreferences } from './types'

export const loadUserPreferencesRequest = createAsyncThunk<
	DtoPreferences,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_PREFERENCES_REQUEST`, async (_undefined, { getState, dispatch, rejectWithValue }) => {
	const rootState = getState()
	const { schoolId } = selectAuth(rootState)

	try {
		const { data } = await http.user.getUserPreferences(schoolId)
		dispatch(loadUserPreferencesRequestFulfilled(data))
		return data
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const toggleNotificationPreferenceRequest = createAsyncThunk<
	boolean,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/TOGGLE_USER_NOTIFICATION_PREFERENCES_REQUEST`, async (preferenceId, { dispatch, getState, rejectWithValue }) => {
	const rootState = getState()
	const { schoolId } = selectAuth(rootState)
	// use entityAdapter to get preference
	const preference = rootState[MODULE_NAME].preferences.entities[preferenceId]
	if (preference) {
		try {
			// optimistic update
			dispatch(togglePreference(preferenceId))
			const { data } = await http.user.patchUserPreference({ preferenceId, value: !preference.value, schoolId })
			return data
		} catch (e) {
			dispatch(togglePreference(preferenceId))
			return rejectWithValue(e)
		}
	}
})

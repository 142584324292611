import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useMemo, useRef } from 'react'

import FeedHeader from '@/features/posts/components/feeds/header'

import { STYLES } from '@/constants'

import { VirtualizedInfiniteScrollComponent } from '@/features/posts/components/VirtualizedInfiniteScrollComponent'
import EmptyPlaceholder from '@/features/posts/components/feeds/EmptyPlaceholder'
import FeedItem from '@/features/posts/components/feeds/FeedItem'
import {
	postSelectors,
	selectCanLoadMore,
	selectDeletingIndex,
	selectFeedFilters,
	selectFeedQueryState,
	selectFeedScrollPosition,
	setFeedOffset,
	setFeedScrollPosition,
} from '@/features/posts/feed/slice'
import { useAppDispatch, useAppSelector } from '@/store'
import { UserAudience } from '@/store/feeds/types'
import { getTotalConnections } from '@/store/network'
import CreatePostButton from '@/features/posts/components/feeds/CreatePostButton'

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},

	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'space-evenly',
		gridGap: 0,
		margin: 0,
	},
}))

const FeedsList = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	// Ref for feeds container
	const virtualizedListRef = useRef<HTMLDivElement>(null)

	// selectors
	const deletingIndex = useAppSelector(selectDeletingIndex)
	const scrollToPosition = useAppSelector(selectFeedScrollPosition)
	const posts = useAppSelector(postSelectors.selectAll)
	const feedFilters = useAppSelector(selectFeedFilters)
	const { isFetching, isLoading } = useAppSelector(selectFeedQueryState)
	const hasConnections = !!useAppSelector(getTotalConnections)
	const canLoadMore = useAppSelector(selectCanLoadMore)

	const handleLoadMore = useCallback(() => {
		dispatch(setFeedOffset())
	}, [dispatch])

	const reloadingData = isLoading || (!!feedFilters && feedFilters.offset === 0 && isFetching)

	const showPlaceholder =
		!isLoading && !isFetching && !hasConnections && feedFilters?.usersAudience === UserAudience.NETWORK && posts && posts.length === 0

	const onPostClick = useCallback(() => {
		dispatch(setFeedScrollPosition(virtualizedListRef.current.scrollTop))
	}, [dispatch])

	const renderItem = useCallback(
		(item) => {
			const post = posts[item.index]

			return post ? <FeedItem onClick={onPostClick} scrollIndex={item.index} key={post.id} postId={post.id} /> : null
		},
		[onPostClick, posts],
	)
	const getItemKey = useCallback(
		(index) => {
			if (posts[index]) {
				return posts[index].id
			} else {
				return index
			}
		},
		[posts],
	)

	const scrollToTop = useCallback(() => {
		if (virtualizedListRef.current) {
			virtualizedListRef.current.scroll({ top: 0, behavior: 'smooth' })
		}
	}, [])

	const placeholder = useMemo(() => {
		return showPlaceholder ? <EmptyPlaceholder /> : null
	}, [showPlaceholder])

	return (
		<div className={classes.container}>
			<VirtualizedInfiniteScrollComponent
				deletingIndex={deletingIndex}
				parentRef={virtualizedListRef}
				placeholder="Check back soon for more posts!"
				isFetching={isFetching}
				isLoading={reloadingData}
				header={<FeedHeader scrollToTop={scrollToTop} />}
				canLoadMore={canLoadMore}
				getItemKey={getItemKey}
				dataLength={posts.length}
				onLoadMore={handleLoadMore}
				renderItem={renderItem}
				emptyListPlaceholder={placeholder}
				scrollToPosition={scrollToPosition}
			/>
			<CreatePostButton />
		</div>
	)
}

export default React.memo(FeedsList)

import { createTheme, ThemeOptions, ThemeProvider } from '@material-ui/core'
import { useMemo } from 'react'

import { SCHOOL_KEY } from '@/constants/app'
import {
	AllButtonColors,
	AllColors,
	BorderPalette,
	ColorPalette,
	IconPalette,
	selectPalette,
	SemanticColors,
	SpecialColorsPalette,
	StatusPalette,
	TSchoolKeys,
} from '@navengage/sen-shared-assets'

declare module '@material-ui/core/styles/createTheme' {
	interface Theme {
		colors: SemanticColors & AllColors
	}
	interface ThemeOptions {
		colors: SemanticColors & AllColors
	}
}

declare module '@material-ui/core/styles/createPalette' {
	interface PaletteOptions {
		neutral?: ColorPalette
		icons?: IconPalette
		border?: BorderPalette
		statuses?: StatusPalette
		specialColors: SpecialColorsPalette
	}
	interface Palette {
		neutral?: ColorPalette
		icons?: IconPalette
		border?: BorderPalette
		statuses?: StatusPalette
		specialColors: SpecialColorsPalette
		buttonColors: AllButtonColors
	}
}

declare module '@mui/material/styles' {
	interface Palette {
		custom: Palette['primary']
	}

	interface PaletteOptions {
		custom?: PaletteOptions['primary']
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		custom: true
	}
}

export const createThemeConfig = (schoolKey: TSchoolKeys): ThemeOptions => {
	const defaultPalette = selectPalette(schoolKey)
	const { customColors: colors, ...palette } = defaultPalette

	const schoolTheme: ThemeOptions = {
		colors,
		palette,
		typography: {
			fontFamily: ['Open Sans'].join(','),
		},
		overrides: {
			MuiTypography: {
				// Primary (Regular) (18px)
				body1: {
					'@media (max-width:1919px)': {
						fontSize: '0.9em', // 18px
					},
					'@media (min-width:1920px)': {
						fontSize: '1em', // 20px
					},
				},

				// Secondary (Small) (15)
				subtitle1: {
					'@media (max-width:1919px)': {
						fontSize: '0.85em', // 15px
					},
					'@media (min-width:1920px)': {
						fontSize: '0.95em', //17px
					},
				},

				// Sub-Text (XS) (12)
				subtitle2: {
					'@media (max-width:1919px)': {
						fontSize: '0.6em', // 12px
					},
					'@media (min-width:1920px)': {
						fontSize: '0.7em', //14px
					},
				},

				// XXL - 30
				h1: {
					'@media (max-width:1919px)': {
						fontSize: '1.5em', //30px
					},
					'@media (min-width:1920px)': {
						fontSize: '1.6em', //32px
					},
				},

				// XL - 25
				h2: {
					'@media (max-width:1919px)': {
						fontSize: '1.25em', // 25px
					},
					'@media (min-width:1920px)': {
						fontSize: '1.35em', // 27px
					},
				},

				// Large (20px)
				h3: {
					'@media (max-width:1919px)': {
						fontSize: '1em', // 20px
					},
					'@media (min-width:1920px)': {
						fontSize: '1.1em', // 22px
					},
				},
			},
		},
	}

	return {
		...schoolTheme,
		palette: {
			...palette,
			type: 'light',
		},
	}
}

const themeConfig = createThemeConfig(SCHOOL_KEY)
const appTheme = createTheme(themeConfig)

export default appTheme

export const NavThemeProvider: React.FC = ({ children }) => {
	const theme = useMemo(() => appTheme, [])

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

import { VolunteerState } from '@/store/volunteer/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/volunteer/constants'

const selectState = (state: { [MODULE_NAME]: VolunteerState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)

export const getFutureShifts = createSelector(selectState, (state) => state.events.future)
export const getPastShifts = createSelector(selectState, (state) => state.events.past)
export const getEventScheduledShiftsNumber = (eventId: string) =>
	createSelector(
		selectState,
		(state) => [...state.events.past, ...state.events.future].find((event) => event.id === eventId)?.scheduledShifts,
	)

import MenuIconButton from '@/components/Buttons/IconButton'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { formatDateNew } from '@/utils/dateTime2'
import { getShiftsInfo } from '@/utils/events'
import { Button, Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import classNames from 'classnames'
import { useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	buttonCard: (props: { checked: boolean }) => ({
		height: 58,
		backgroundColor: props.checked ? theme.palette.primary.main : theme.palette.specialColors.transparent,
		borderRadius: 7,
		color: props.checked ? theme.palette.common.white : theme.palette.text.primary,
		width: '100%',
		marginBottom: 5,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.light,
		},
		'&:disabled': {
			opacity: 0.7,
			backgroundColor: theme.palette.specialColors.transparent,
		},
		fontSize: 16,
	}),
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		justifyContent: 'space-between',
		alignItems: 'center',
		lineHeight: '20px',
		width: '100%',
		margin: '0 20px',
	},
	availableSpotsContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	userIcon: {
		marginRight: '0.5vw',
	},
	paper: {
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
}))

interface ShiftCheckBoxProps {
	shift: any
	className?: string
	onClick: (id: string) => void
	checked: boolean
	shiftNumber?: number
	userId: number
}

const ShiftCheckBox = ({
	shift = {},
	className = '',
	onClick = (id: string) => {},
	checked = false,
	shiftNumber = 1,
	userId,
}: ShiftCheckBoxProps) => {
	const classes = useStyles({ checked })
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const { availableSpots } = getShiftsInfo([shift])

	const disabled = !availableSpots

	useOutsideClickCallback(popperRef, handleClose)

	return (
		<Button
			className={classNames(classes.buttonCard, className)}
			onClick={() => (checked ? handleToggle() : onClick(shift.id))}
			disabled={disabled}
			ref={anchorRef}
		>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					<MenuIconButton className={classes.popoverButton} onClick={() => onClick(shift.id)}>
						Cancel
					</MenuIconButton>
				</div>
			</Popper>
			<div className={classes.contentContainer}>
				<Typography>{`SHIFT ${shiftNumber + 1}`}</Typography>
				<Typography>
					{formatDateNew(shift.startDate, 'timeSimple')} - {formatDateNew(shift.endDate, 'timeSimple')}
				</Typography>
				<div className={classes.availableSpotsContainer}>
					<PersonIcon className={classes.userIcon} />
					<Typography>{availableSpots}</Typography>
				</div>
			</div>
		</Button>
	)
}

export default ShiftCheckBox

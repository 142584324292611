// @ts-nocheck
// @TODO: No typing and lazy coding has been done here

import { searchMessages } from '@/api/http/messages'
import ChatListItem from '@/components/Messages/ChatListItem'
import { PATHS, STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { getTargetGroup } from '@/store/groups'
import { clearNewMessageContent, getChatsList, getTargetUser } from '@/store/messages'
import { InputAdornment, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import { debounce, isArray } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Iff } from '../Iff'
import useAuth from '@/hooks/useAuth'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontSize: '1em',
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.33)' : theme.shadows[10],
		margin: '1.5vh 7%',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
		'&.MuiOutlinedInput-input': {
			padding: '15.5px 14px',
		},
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	listContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: `calc(100vh - 4.5vh - 160px - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		overflow: 'auto',
		flex: 1,
		padding: '20px 0',
		boxSizing: 'border-box',
	},
	searchPlaceholder: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: `calc(100vh - 4.5vh - 160px - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 10,
		margin: 0,
	},
	contentContainer: {
		paddingBottom: '2vh',
	},
}))

interface SearchMessagesListProps {
	onSelectMessage?: () => void
	listClassName?: string
	rootClassName?: string
	isLoading?: boolean
}
// @TODO: Random constant 50, should be standardized
// @TODO Preloader needed here
const SEARCH_LIMIT = 50

const SearchMessagesList = ({
	onSelectMessage = () => {},
	listClassName = '',
	rootClassName = '',
	isLoading = true,
}: SearchMessagesListProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const { schoolId, userId } = useAuth()

	const [searchQueryResults, setSearchQueryResults] = useState({})

	const messagesList = useSelector(getChatsList)
	const targetGroup = useSelector(getTargetGroup)
	const targetUser = useSelector(getTargetUser)

	const [searchValue, setSearchValue] = useState('')
	const [searchingQueryMap, setSearchingQueryMap] = useState({})

	const [isTouched, setIsTouched] = useState(false)

	const handleChangeInput = useCallback(({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(value)
		setIsTouched(true)
	}, [])

	const handleClearInput = useCallback(() => {
		setSearchValue('')
	}, [])

	const handleSearch = useCallback(() => {
		const q = searchValue.trim()
		const queryKey = getQueryKey(q)

		setSearchingQueryMap((map) => {
			return { ...map, [queryKey]: true }
		})

		return searchMessages({ userId, q, limit: SEARCH_LIMIT, schoolId })
			.then((messages) => {
				setSearchQueryResults((map) => {
					return { ...map, [queryKey]: messages }
				})
			})
			.finally(() =>
				setSearchingQueryMap((map) => {
					return { ...map, [queryKey]: false }
				}),
			)
	}, [searchValue, userId, schoolId])

	const isChatSelected = (user, group) => {
		return (targetUser && user?.id === targetUser.id) || (targetGroup && group?.id === targetGroup.id)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(debounce(handleSearch, 300), [handleSearch])

	const handleClickMessage = useCallback(
		({ message, user, group }) =>
			() => {
				const groupId = group?.id || message?.groupId
				const userId = user?.id

				dispatch(clearNewMessageContent())
				push(groupId ? PATHS.APP.MESSAGES_GROUP(groupId) : PATHS.APP.MESSAGES_USER_DIALOGUE(userId))
				onSelectMessage()
			},
		[dispatch, push, onSelectMessage],
	)

	useEffect(() => {
		if (isTouched) delayedSearch(searchValue)

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, isTouched])

	const searchResults = searchQueryResults[getQueryKey(searchValue)] || getSimilarSearchFallback(searchValue, searchQueryResults) || []

	const isSearching =
		!searchResults.length && (searchingQueryMap[getQueryKey(searchValue)] || !!getSimilarSearchFallback(searchValue, searchQueryResults))

	return (
		<div className={classNames(classes.root, rootClassName)}>
			<TextField
				className={classes.input}
				onChange={handleChangeInput}
				value={searchValue}
				placeholder="Search Messages"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon className={classes.inputIcon} fontSize="medium" />
						</InputAdornment>
					),
					endAdornment: searchValue ? (
						<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
							<Close className={classes.inputIcon} fontSize="medium" />
						</InputAdornment>
					) : undefined,
				}}
				variant="outlined"
			/>
			<div className={classNames(classes.listContainer, listClassName)}>
				<Iff
					if={searchValue && (isSearching || searchResults.length)}
					render={() => {
						return searchResults.map(({ user, message, group, unviewed }) => (
							<ChatListItem
								selected={isChatSelected(user, group)}
								key={message.id}
								{...{ user, message, group, unviewed, onClick: handleClickMessage({ message, user, group }) }}
							/>
						))
					}}
					elseIf={searchValue && !isSearching && !isLoading}
					elseIfRender={() => {
						return (
							<div className={classes.searchPlaceholder}>
								<Typography>No results found.</Typography>
							</div>
						)
					}}
					else={() => {
						return messagesList.map(({ user, message, group, unviewed }) => (
							<ChatListItem
								selected={isChatSelected(user, group)}
								key={message.id}
								{...{ user, message, group, unviewed, onClick: handleClickMessage({ user, message, group }) }}
							/>
						))
					}}
				/>
			</div>
		</div>
	)
}

const getQueryKey = (q) => {
	return q.trim().toLowerCase()
}

/**
 * Falls back to the results of a similar search that already succeeded.
 */
const getSimilarSearchFallback = (q, searchQueryResults) => {
	const result = Object.entries(searchQueryResults).find(([key, value]) => {
		return !!key && getQueryKey(q).startsWith(key) && !!value
	})

	if (!result) {
		return
	}

	const { 1: value } = result

	if (!isArray(value) || !value.length) {
		return
	}

	return value
}

export default SearchMessagesList

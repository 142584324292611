import AddButton from '@/components/Buttons/AddButton'
import { ProfileInfoItemBadge } from '@/components/ProfileInfoItemBadge'
import { STYLES } from '@/constants'
import { ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	text: {
		...theme.typography.body1,
		margin: '15px 0',
	},
	titleText: {
		...theme.typography.body1,
		margin: '15px 0 0 0',
		fontWeight: 600,
	},
	addButton: {
		...theme.typography.body1,
		minWidth: 250,
		marginTop: 25,
	},
	itemImage: {
		color: theme.palette.primary.main,
		width: '3.5vw',
		height: '3.5vw',
		[theme.breakpoints.down('md')]: {
			width: '3.5vw',
			height: '3.5vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 50,
			minHeight: 50,
		},
	},
}))

interface EmptyProfileInfoItemPlaceholderProps {
	title?: string
	type: ProfileInfoCardTypes
	onClickAdd?: (e: any) => void
	showSubTitle?: boolean
	classnames?: {
		title?: string
	}
}

const getTitleByType = (type) => {
	switch (type) {
		case ProfileInfoCardTypes.MEMBERSHIPS:
			return 'Involved in an organization?'
		case ProfileInfoCardTypes.INTERNSHIPS:
			return 'Completed an Internship?'
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			return 'Completed an Education Abroad experience?'
		case ProfileInfoCardTypes.RESEARCH:
			return 'Completed Research?'
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
			return 'Involved outside of your main role?'
		case ProfileInfoCardTypes.INTERESTS:
			return 'Build your profile by lettings others know what you’re interested in.'
		default:
			return ''
	}
}

const EmptyProfileInfoItemPlaceholder = ({
	title,
	type,
	onClickAdd,
	showSubTitle = true,
	classnames = {
		title: '',
	},
}: EmptyProfileInfoItemPlaceholderProps) => {
	const classes = useStyles()

	const displayTitle = title || getTitleByType(type)

	return (
		<div className={classes.root}>
			<div className={classes.contentContainer}>
				<ProfileInfoItemBadge type={type} weight="fill" className={classes.itemImage} />
				<Typography className={classNames(classnames.title, classes.titleText)}>{displayTitle}</Typography>
				{showSubTitle && <Typography className={classes.text}>Showcase your experience on your profile!</Typography>}
			</div>
			{onClickAdd && (
				<AddButton className={classes.addButton} variant="secondary" onClick={onClickAdd}>
					Add to profile
				</AddButton>
			)}
		</div>
	)
}

export default EmptyProfileInfoItemPlaceholder

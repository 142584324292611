import { AxiosResponse } from 'axios'
import { camelizeKeys } from 'humps'

const camelizeResponseInterceptor = (response: AxiosResponse) => {
	if (response.config.transformResponse === null) {
		if (response.data) {
			response.data = JSON.parse(response.data)
		}
		return response
	}

	if (response.data && response.headers['content-type'] === 'application/json; charset=utf-8' && !response.data?.theme) {
		response.data = camelizeKeys(response.data, (key, convert) => {
			// converter remove '-' from string, in some cases we might use uuid as object key and this will cause issues
			// E.g. this  21a0fe90-9bb2-408c-ad30-eab29444f2fe was converted into 21a0fe909bb2408cad30eab29444f2fe
			return key.includes('-') ? key : convert(key)
		})
	}

	return response
}

export default camelizeResponseInterceptor

import { STYLES } from '@/constants'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.primary.main,
		height: `${STYLES.LANDING_HEADER_HEIGHT}vh`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		[theme.breakpoints.up('md')]: {
			minHeight: 80,
		},
		[theme.breakpoints.up('lg')]: {
			minHeight: 120,
		},
		[theme.breakpoints.up('xl')]: {
			minHeight: 120,
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: 80,
			height: `${STYLES.LANDING_HEADER_HEIGHT_MOBILE}vh`,
		},
	},
	right: {
		width: '50%',
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
	},
	logo: {
		width: '15vw',
		minWidth: 168,
	},
	logoLink: {
		width: '100%',
		display: 'inline-flex',
		alignItems: 'center',
		paddingLeft: '4.5vw',
	},
}))

const Header = ({ children }: any) => {
	const classes = useStyles()
	const {
		school: { shortName },
		logo: { main },
	} = useSchoolConfig()

	return (
		<header className={classes.root}>
			<div className={classes.logoLink}>
				<img className={classes.logo} src={main} alt={`${shortName} logo`} />
			</div>
			<div className={classes.right}>{children}</div>
		</header>
	)
}

export default Header

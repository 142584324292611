import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

const useMentionClickHandler = (content: string) => {
	const containerRef = useRef<HTMLDivElement>()
	const history = useHistory()
	useEffect(() => {
		const handleClick = (event) => {
			const isMention = event.target.getAttribute('data-lexical-mention')
			if (isMention) {
				event.preventDefault()

				history.push(event.target.getAttribute('href'))
			}
		}

		containerRef.current?.addEventListener('click', handleClick)

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			containerRef.current?.removeEventListener('click', handleClick)
		}
	}, [content, history])
	return containerRef
}

export default useMentionClickHandler

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { NavigateBefore } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 15,
		padding: 20,
		marginBottom: 20,
	},
	headerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
	buttonsContainer: ({ showBackButton }: any) => ({
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: showBackButton ? 'space-between' : 'flex-end',
	}),
	iconButton: {
		padding: 0,
	},
	icon: {
		width: 30,
		height: 30,
		color: theme.palette.text.secondary,
	},
	closeIcon: {
		width: 25,
		height: 25,
		color: theme.palette.text.secondary,
	},
	headerText: {
		fontWeight: 700,
		fontSize: 22,
		marginTop: -10,
		width: '80%',
	},
	subHeaderText: {
		color: theme.palette.text.secondary,
		fontSize: 16,
		margin: '15px 0',
		width: '80%',
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

interface StepperCardProps {
	classnames?: {
		container?: string
		header?: string
	}
	children: React.ReactNode
	onBack: () => void
	onClose: () => void
	subTitle?: string
	title?: string
	showBackButton?: boolean
}

const StepperCard = ({
	classnames = {
		container: '',
		header: '',
	},
	children,
	onBack,
	onClose,
	subTitle,
	title = '',
	showBackButton,
}: StepperCardProps) => {
	const classes = useStyles({ showBackButton })

	return (
		<div className={classNames(classes.root, classnames.container)}>
			<div className={classes.headerContainer}>
				<div className={classes.buttonsContainer}>
					{showBackButton && (
						<IconButton className={classes.iconButton} onClick={onBack}>
							<NavigateBefore className={classes.icon} />
						</IconButton>
					)}
					<IconButton className={classes.iconButton} onClick={onClose}>
						<Close className={classes.closeIcon} />
					</IconButton>
				</div>
				{title && (
					<Typography className={classNames(classes.headerText, classnames.header)} variant="h1">
						{title}
					</Typography>
				)}
				{subTitle && (
					<Typography className={classes.subHeaderText} variant="subtitle1">
						{subTitle}
					</Typography>
				)}
			</div>
			<div className={classes.content}>{children}</div>
		</div>
	)
}

export default StepperCard

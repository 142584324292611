import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import { getShiftsInfo, isUserSubmittedToShift } from '@/utils/events'
import Card from '@/components/IndividualPage/Card'
import EditShiftsCard from '@/components/IndividualPage/EditShiftsCard'

import { ICON_SIZES } from '@/constants/iconSizes'
import { FEATURE } from '@navengage/sen-shared-assets'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '2vh 0 1vh 0',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	button: {
		height: 50,
		width: 200,
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '20px 0',
		},
	},
	infoBlockValue: {
		fontWeight: 700,
	},
	infoBlock: {
		width: '10vw',
		minWidth: 100,
		height: 93,
		borderRadius: 5,
		backgroundColor: theme.colors.grey[100],
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	volunteerIconContainer: {
		height: 50,
		width: 50,
		borderRadius: 7,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		marginRight: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonsContainer: {
		display: 'flex',
		width: '100%',
	},
}))

interface ShiftsCardProps {
	shifts: any[]
	eventId: string
	userId: number
	isEditShifts: boolean
	handleOpenEditShiftDialog: () => void
	onSubmitShifts: () => void
	pastEvent: boolean
}

const ShiftsCard = ({
	shifts = [],
	eventId,
	userId,
	isEditShifts,
	handleOpenEditShiftDialog,
	onSubmitShifts,
	pastEvent,
}: ShiftsCardProps) => {
	const classes = useStyles()

	const { availableSpots } = getShiftsInfo(shifts)

	const userSubmittedShifts = shifts.filter((shift) => isUserSubmittedToShift(shift, userId)).length

	const shiftsInfo = [
		{
			label: 'Shifts',
			value: shifts.length,
		},
		{
			label: 'Available Spots',
			value: availableSpots,
		},
	]

	if (isEditShifts) return <EditShiftsCard shifts={shifts} eventId={eventId} userId={userId} onSubmitShifts={onSubmitShifts} />

	return (
		<Card title="This volunteer event has shifts">
			<div className={classes.root}>
				{!userSubmittedShifts && (
					<div className={classes.buttonsContainer}>
						<div className={classes.volunteerIconContainer}>
							<IconWrapper iconKey={FEATURE.volunteers} aria-label="Volunteer" weight="fill" size={ICON_SIZES.md} />
						</div>
						<ColorButton className={classes.button} color={ButtonColors.BLUE} onClick={handleOpenEditShiftDialog} disabled={pastEvent}>
							Sign Up
						</ColorButton>
					</div>
				)}
				<div className={classes.infoContainer}>
					{shiftsInfo.map(({ label, value }) => (
						<div key={label} className={classes.infoBlock}>
							<Typography className={classes.infoBlockValue} variant="subtitle1">
								{value}
							</Typography>
							<Typography>{label}</Typography>
						</div>
					))}
				</div>
			</div>
		</Card>
	)
}

export default ShiftsCard

import { ContentPreloader } from '@/components/ContentPreloader'
import MainLayout from '@/components/MainLayout'
import Router from '@/components/Router'
import { DynamicSidebar } from '@/components/SideBar'
import { PATHS } from '@/constants'
import { IFeature } from '@/lib/splitio'
import { makeStyles } from '@material-ui/core'
import { SignOut } from '@phosphor-icons/react'
import { useMemo } from 'react'
import { useGetAnalyticsSettingsQuery } from '../api/settingsApi'
import { NAVIGATION_MAPPER } from './config'
import routes from './routes'

const AnalyticsMainRouter = () => {
	const { data, isLoading } = useGetAnalyticsSettingsQuery(undefined, { refetchOnMountOrArgChange: true })
	const useStyles = makeStyles({
		rotatedIcon: {
			transform: 'rotate(180deg)',
		},
	})

	const classes = useStyles()
	const sidebar = useMemo(() => {
		if (!data) {
			return null
		}
		const navigation = data.navigation
			.filter((item) => item.path && !!NAVIGATION_MAPPER[item.path])
			.map((item) => {
				return {
					...NAVIGATION_MAPPER[item.path],
					...item,
					path: `${PATHS.ADMIN}/${item.path}`,
				}
			})

		// Can be moved to useGetAnalyticsSettingsQuery if needed
		const bottomNavigation: IFeature[] = [
			{
				label: 'Exit Dashboard',
				path: PATHS.APP.HOME,
				enabled: true,
				icon: (
					<div className={classes.rotatedIcon}>
						<SignOut />
					</div>
				),
			},
		]
		return <DynamicSidebar navigation={navigation} bottomNavigation={bottomNavigation} />
	}, [classes.rotatedIcon, data])

	const userRoutes = useMemo(() => {
		if (!data) {
			return []
		}
		return routes.filter((route) => {
			return data.navigation.some((item) => item.path === route.name)
		})
	}, [data])

	return <MainLayout sidebar={sidebar}>{isLoading ? <ContentPreloader fullPage /> : <Router routes={userRoutes} />}</MainLayout>
}

export { AnalyticsMainRouter }

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

const dashboardAdapter = createEntityAdapter<{ id: string } & Record<string, unknown>>({
	// Assuming your posts have a field 'id' as the unique identifier
	selectId: (item) => item.id,
})

export type TDashboardState = {
	dashboardSelection: ReturnType<typeof dashboardSlice.reducer>
}

export const {
	selectIds: selectDashboardIds,
	// Add more selectors as needed
} = dashboardAdapter.getSelectors((state: TDashboardState) => state.dashboardSelection) // Adjust based on where your slice lives in the Redux state

export const dashboardSlice = createSlice({
	name: 'dashboardSelection',
	initialState: dashboardAdapter.getInitialState(),
	reducers: {
		addSelection: dashboardAdapter.addOne,
		removeSelection: dashboardAdapter.removeOne,
		clearSelection: dashboardAdapter.removeAll,
		// add more reducers as needed
	},
})

export const {
	addSelection,
	removeSelection,
	clearSelection,
	// export more reducers as needed
} = dashboardSlice.actions

export default dashboardSlice.reducer

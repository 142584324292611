import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

const getOrganizationMemberRequests = async (orgId: string, page: number, schoolId: number) => {
	const url = `${schoolId}/organizations/org/${orgId}/requests`
	const params = {
		page,
		perPage: settings.ORG_MEMBERS_PER_PAGE,
	}

	return http.get(url, { params })
}

export default getOrganizationMemberRequests

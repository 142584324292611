import Card from '@/components/CommonCard'
import EmptyProfileInfoItemPlaceholder from '@/components/UserProfile/UserExperienceItems/EmptyProfileInfoItemPlaceholder'
import ProfileInfoItem from '@/components/UserProfile/UserExperienceItems/ProfileInfoItem'

import { TextButton } from '@/components/Buttons'
import { dateDiff } from '@/utils/dateTime2'
import { ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'
import { ProfileInfoItemBadge } from '../ProfileInfoItemBadge'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	contentContainer: {
		margin: '0.5vh 0',
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	itemImage: {
		color: theme.palette.primary.main,
		[theme.breakpoints.up('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '3.5vw',
			height: '3.5vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 50,
			minHeight: 50,
		},
	},
	itemContainer: {
		display: 'flex',
		padding: '2.5vh 0',
	},
	volunteerInfo: {
		marginTop: '1vh',
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		marginLeft: '1.5vw',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	exploreBtn: {
		marginLeft: 3,
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
	},
}))

interface ProfileInfoCardProps {
	type: ProfileInfoCardTypes
	items?: any[]
	canEdit?: boolean
	handleOpenEditModal: () => void
}

const ProfileInfoCard = ({ items, type, handleOpenEditModal, canEdit = false }: ProfileInfoCardProps) => {
	const classes = useStyles()

	const isVolunteer = type === ProfileInfoCardTypes.VOLUNTEER
	const hasItems = !!items?.length

	const totalShiftHours = useMemo(() => {
		if (!isVolunteer) return 0

		let counter = 0
		items?.forEach(({ startDate, endDate }) => {
			const diff = dateDiff(startDate, endDate)
			counter += diff
		})

		return counter
	}, [isVolunteer, items])

	if (!hasItems && !canEdit) return null

	return (
		<Card>
			<div className={classes.headerContainer}>
				<Typography className={classes.boldText} variant="h3">
					{type}
				</Typography>
				{canEdit && <TextButton onClick={handleOpenEditModal}>Edit</TextButton>}
			</div>
			{isVolunteer && !!totalShiftHours && (
				<Typography className={classes.volunteerInfo}>{`Total Shift Hours: ${totalShiftHours} hrs`}</Typography>
			)}
			<div className={classes.contentContainer}>
				{items && items.length ? (
					items.map((item) => (
						<ProfileInfoItem key={`${type}-${item.id}`} type={type} item={item} isVolunteer={isVolunteer} showInfoIcons={canEdit} />
					))
				) : (
					<div className={classes.itemContainer}>
						{isVolunteer ? (
							<>
								<ProfileInfoItemBadge type={type} weight="fill" className={classes.itemImage} />

								<div className={classes.infoContainer}>
									<Typography className={classes.grayText}>Your volunteer experience on the app will appear here.</Typography>
									<TextButton className={classes.exploreBtn} onClick={handleOpenEditModal}>
										Explore now.
									</TextButton>
								</div>
							</>
						) : (
							<EmptyProfileInfoItemPlaceholder type={type} onClickAdd={handleOpenEditModal} />
						)}
					</div>
				)}
			</div>
		</Card>
	)
}

export default ProfileInfoCard

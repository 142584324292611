import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Popover from '@/components/Popover'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		margin: '15px 0',
	},
	content: {
		maxWidth: 300,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2vh 3vw',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: '0 7px 7px 7px',
		backgroundColor: theme.palette.background.paper,
		textAlign: 'center',
	},
}))

interface CannotUnfollowPopperProps {
	anchorEl: any
	handleClose: () => void
	userRole: ORGANIZATION_ROLE
}

const CannotUnfollowPopper = ({ anchorEl, handleClose, userRole }: CannotUnfollowPopperProps) => {
	const classes = useStyles()

	const getContent = () => {
		const content = {
			[ORGANIZATION_ROLE.MEMBER]: `Members cannot unfollow organizations they 
      belong to. Reach out to your organization’s 
      super admin for more information.`,
			[ORGANIZATION_ROLE.ADMIN]: `Super Admins and Admins cannot 
      unfollow their own organizations.`,
			[ORGANIZATION_ROLE.SUPER_ADMIN]: `Super Admins and Admins moderators cannot 
      unfollow their own organizations.`,
		}

		return content[userRole]
	}

	return (
		<Popover
			anchorEl={anchorEl}
			handleClose={handleClose}
			boxShadow={false}
			classnames={{
				container: classes.content,
			}}
		>
			<Typography className={classes.title}>Cannot Unfollow</Typography>
			<Typography>{getContent()}</Typography>
		</Popover>
	)
}

export default CannotUnfollowPopper

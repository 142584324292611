import * as http from '@/api/http'
import settings from '@/constants/http'
import { campusSelectors } from '@/features/campus/slice'
import { notificationsApi } from '@/features/notifications/api'
import {
	ConnectivityStatus,
	ConnectivityStatusIndex,
	ReportActions,
	SearchConfig,
	UserData,
	UserResponseStatuses,
} from '@/interfaces/common'
import { PostActivity } from '@/interfaces/user'
import { RootState } from '@/store'
import { preparePostData } from '@/store/feeds'
import { MODULE_NAME } from '@/store/network/constants'
import { getCurrentFilterValues } from '@/utils/common'
import { getNormalizeUser } from '@/utils/transformers'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import isNumber from 'lodash/isNumber'
import { selectAuth } from '../auth'
import { NotificationPages } from '../auth/types'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setNetwork = createAction<any>(`${MODULE_NAME}/SET_NETWORK`)
export const updateSingleUserStatus = createAction<any>(`${MODULE_NAME}/SET_SINGLE_NETWORK`)
export const deleteSingleUserConnection = createAction<any>(`${MODULE_NAME}/DELETE_SINGLE_NETWORK`)
export const setFilters = createAction<any>(`${MODULE_NAME}/SET_FILTERS`)
export const setConstants = createAction<any>(`${MODULE_NAME}/SET_NETWORK_CONSTANTS`)
export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setConnections = createAction<any>(`${MODULE_NAME}/SET_USER_NETWORK`)
export const setUser = createAction<any>(`${MODULE_NAME}/SET_USER`)
export const clearUser = createAction(`${MODULE_NAME}/CLEAR_USER`)

export const setBlockedUsers = createAction<UserData[]>(`${MODULE_NAME}/SET_BLOCKED_USERS`)

export const setUserActivity = createAction<{
	page?: number
	list?: PostActivity[]
	endHasBeenReached?: boolean
	isLoading: boolean
}>(`${MODULE_NAME}/SET_USER_ACTIVITY`)

export const loadNetworkListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_NETWORK_LIST_WITH_FILTER_REQUEST`, async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const users = rootState.NETWORK.users
		const connections = rootState.NETWORK.connections
		const storedSearch = rootState.NETWORK.search
		const campusesMap = campusSelectors.selectEntities(rootState)

		const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

		const connectivityStatus =
			params && Array.isArray(params?.connectivityStatus) ? ConnectivityStatusIndex[params.connectivityStatus[0]] : undefined

		const allCampuses = params?.campuses && params?.campuses.some((c) => c === null)

		const res = await http.network.searchNetwork({
			q: params?.query,
			page,
			userId,
			campusId: allCampuses ? undefined : params?.campuses,
			connectivityStatus,
			appUserTypeId: params?.userTypeId,
			schoolId,
		})

		const normUsers = res.data.map((user: any) => getNormalizeUser(user, campusesMap))
		const network = fetchMore ? [...users, ...normUsers] : normUsers

		const reqUserIds = [...connections.connectionRequests, ...connections.pendingRequests].map((req) => req.user.id)
		const networkWithStatus = network.map((network: any) => {
			const userData = isNumber(network.id) ? network : network.user
			const hasConnectionRequest = reqUserIds.includes(userData.id)
			const connected = userData?.connected
			const blocked = userData?.blocked

			const status = blocked ? 'blocked' : connected ? 'connected' : hasConnectionRequest ? 'requested' : ''

			return { user: userData, status }
		})

		dispatch(
			setFilters({
				...currentConfig,
				endHasBeenReached: !res.data?.length,
				page,
				filterIsReady,
			}),
		)
		await dispatch(setNetwork(networkWithStatus))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadUserConnectionsRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_NETWORK_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusesMap = campusSelectors.selectEntities(getState())

		const {
			data: { connections },
		} = await http.network.getUserConnections({ userId, schoolId })

		const connectionWithUserData = (c: any) =>
			c.map((connection: any) => {
				const {
					userId: id,
					firstName,
					lastName,
					displayName,
					appUserTypeId,
					primaryTitle,
					primaryTitleDepartment,
					campusId,
					externalId,
					...rest
				} = connection
				const user = getNormalizeUser(
					{
						id,
						firstName,
						lastName,
						displayName,
						appUserTypeId,
						primaryTitle,
						primaryTitleDepartment,
						campusId,
						externalId,
					},
					campusesMap,
				)

				return { ...rest, user }
			})

		dispatch(
			setConnections({
				...connections,
				connectionRequests: connectionWithUserData(connections.connectionRequests),
				pendingRequests: connectionWithUserData(connections.pendingRequests),
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadUserActivityRequest = createAsyncThunk<
	any,
	{
		resetPage?: boolean
		userId: number
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_ACTIVITY_REQUEST`, async ({ resetPage, userId: targetUserId }, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(
			setUserActivity({
				isLoading: true,
			}),
		)

		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { page, list } = rootState.NETWORK.selectedUser?.activity ?? {}

		const prevActivity = !resetPage && list ? list : []
		const newPage = !resetPage && page ? page + 1 : 1

		const { data } = await http.user.getUserActivity({ page: newPage, userId, targetUserId, schoolId })

		const activityArr = data.map((activity: PostActivity) => ({
			...activity,
			post: preparePostData(activity.post, rootState),
		}))

		const mergedActivity = resetPage ? activityArr : [...prevActivity, ...activityArr]

		dispatch(
			setUserActivity({
				page: newPage,
				endHasBeenReached: !activityArr.length || activityArr.length < settings.SEARCH_ACTIVITY_LIST_PER_PAGE,
				list: mergedActivity,
				isLoading: false,
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const getUserInfoRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_INFO_REQUEST`, async (targetUserId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			data: { user: userData },
		} = await http.network.getUserInfo({ userId, targetUserId, schoolId })

		dispatch(setUser(userData))
		await dispatch(loadUserActivityRequest({ resetPage: true, userId: targetUserId }))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const changeConnectionStatusRequest = createAsyncThunk<
	any,
	{ status: ConnectivityStatus; id: number },
	{
		state: RootState
	}
>(`${MODULE_NAME}/CHANGE_USER_CONNECTION_STATUS_REQUEST`, async ({ id, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const selectedUser = rootState.NETWORK.selectedUser?.info

		const {
			data: { connection },
		} = await http.network.patchUserConnection({ userId, status, id, schoolId })

		const cachedNotificationTypes: NotificationPages[] = ['all', 'new', 'past']
		cachedNotificationTypes.forEach((type) =>
			dispatch(
				notificationsApi.util.updateQueryData('getUserNotifications', { userId, type }, (draft) => {
					const notification = draft.notifications.find(
						(n) => n.data.payload.connectionRequest && n.data.payload.connectionRequest.id === id,
					)
					if (notification) {
						notification.data.payload.connectionRequest.status = status
					}
				}),
			),
		)

		dispatch(updateSingleUserStatus({ ...connection, status }))
		dispatch(loadUserConnectionsRequest())

		if (selectedUser && selectedUser.id) {
			dispatch(getUserInfoRequest(selectedUser.id))
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const createConnectionRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_USER_CONNECTION_REQUEST_REQUEST`, async (targetUserId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const selectedUser = rootState.NETWORK.selectedUser?.info

		const {
			data: { connection },
		} = await http.network.postUserConnection({ userId, targetUserId, schoolId })

		dispatch(updateSingleUserStatus(connection))
		dispatch(loadUserConnectionsRequest())

		if (selectedUser && selectedUser.id) {
			dispatch(getUserInfoRequest(selectedUser.id))
		}
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const postUserResponseRequest = createAsyncThunk<
	any,
	{
		id: number
		status: UserResponseStatuses | ReportActions
		reportMessage?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/POST_USER_RESPONSE_REQUEST`, async ({ id, reportMessage, status }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		await http.network.postUserResponse({ userId, targetUserId: id, response: status, reportMessage, schoolId })
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const deleteConnectionRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/DELETE_CONNECTION_REQUEST`, async (targetUserId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			data: { connection },
		} = await http.network.deleteUserConnection({ userId, targetUserId, schoolId })

		dispatch(deleteSingleUserConnection(connection))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadBlockedUsersListRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_BLOCKED_USERS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(getState())

		const {
			data: { users },
		} = await http.network.getBlockedUsersList({ userId, schoolId })

		const usersMap = users.map((user: any) => getNormalizeUser(user.blockedUser, campusMap))

		dispatch(setBlockedUsers(usersMap))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const unblockUserRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/UNBLOCK_USER_REQUEST`, async (targetUserId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { blockedUsers } = rootState.NETWORK

		dispatch(setBlockedUsers(blockedUsers.filter((user) => user.id !== targetUserId)))

		await http.network.patchUnblockUser({ userId, targetUserId, schoolId })

		dispatch(loadBlockedUsersListRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	card: {
		minWidth: 500,
		backgroundColor: theme.palette.background.paper,
		borderRadius: 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
	popupModalHeader: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	popupModalHeaderContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		borderBottom: `solid 1px ${theme.palette.divider}`,
	},
	modalTitle: ({ subTitle }: any) => ({
		margin: subTitle ? '30px 0 15px 0' : '30px 0',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		fontWeight: 700,
		color: theme.palette.text.primary,
	}),
	closeIcon: {
		color: theme.palette.text.secondary,
		cursor: 'pointer',
		margin: '0 20px 0 0',
		width: 24,
		height: 24,
	},
	backIcon: {
		cursor: 'pointer',
		margin: '30px 0 15px 20px',
		width: 18,
		height: 18,
		color: theme.palette.text.primary,
	},
	subTitle: {
		color: theme.palette.text.secondary,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		marginBottom: 10,
	},
	cardFooter: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		padding: 30,
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			width: '82%',
		},
	},
	headerTop: {
		display: 'flex',
	},
	navButtonContainer: {
		width: 44,
		display: 'flex',
		alignItems: 'center',
	},
}))

interface CommonPopupModalCardProps {
	title?: string
	titleContent?: React.ReactNode
	subTitle?: string
	onClose?: (e: any) => void
	handleBack?: (e: any) => void
	children: React.ReactNode
	classnames?: {
		header?: string
		footer?: string
		card?: string
	}
	showFooter?: boolean
	footerContent?: React.ReactNode
}

export const CommonModalCard = forwardRef<any, CommonPopupModalCardProps>(
	(
		{ title = '', subTitle = '', titleContent, children, onClose, handleBack, classnames = {}, showFooter = false, footerContent = null },
		ref,
	) => {
		const classes = useStyles({ subTitle })

		return (
			<div ref={ref} className={classNames(classes.card, classnames.card)}>
				{(title || onClose) && (
					<div className={classNames(classes.popupModalHeaderContainer, classnames.header)}>
						<div className={classes.headerTop}>
							<div className={classes.navButtonContainer}>
								{' '}
								{handleBack && <ArrowBackIos className={classes.backIcon} onClick={handleBack} />}
							</div>
							<div className={classes.popupModalHeader}>
								<Typography className={classes.modalTitle}>{title}</Typography>
								{subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
							</div>
							<div className={classes.navButtonContainer}> {onClose && <CloseIcon className={classes.closeIcon} onClick={onClose} />}</div>
						</div>
						{titleContent}
					</div>
				)}
				{children}
				{showFooter && <div className={classNames(classnames.footer, classes.cardFooter)}>{footerContent}</div>}
			</div>
		)
	},
)

export default CommonModalCard

// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CommonModalCard, IconButton, Modal } from '@/components'
import { useAppDispatch } from '@/store'
import { getAddToCalendarModal, openAddToCalendarModal } from '@/store/events'
import { createGoogleLink, createOutlookLink } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
}))

const AddToCalendarModal = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { isOpen, name, description, startDate, endDate } = useSelector(getAddToCalendarModal)

	const handleCloseModal = useCallback(() => {
		dispatch(
			openAddToCalendarModal({
				isOpen: false,
				name: '',
				description: '',
				startDate: '',
				endDate: '',
			}),
		)
	}, [dispatch])

	const calendarButtons = useMemo(
		() => [
			{
				label: 'Add to Outlook Calendar',
				onClick: () => {
					handleCloseModal()
					window.open(createOutlookLink(name, startDate, endDate, description))
				},
			},
			{
				label: 'Add to Google Calendar',
				onClick: () => {
					handleCloseModal()
					window.open(createGoogleLink(name, startDate, endDate, description))
				},
			},
		],
		[description, endDate, handleCloseModal, name, startDate],
	)

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<CommonModalCard title="Add to Calendar" onClose={handleCloseModal}>
				{calendarButtons.map((btn) => (
					<IconButton key={btn.label} className={classes.itemPopupButton} onClick={btn.onClick}>
						{btn.label}
					</IconButton>
				))}
			</CommonModalCard>
		</Modal>
	)
}

export default AddToCalendarModal

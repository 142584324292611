import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import { IGroupChat } from '@navengage/sen-types'
import GroupInfo, { GroupInfoData } from '../ManageCards/GroupInfo'
import SideBarTabHeader from './SideBarTabHeader'
import { useAppDispatch } from '@/store'
import { editGroupInfoRequest } from '@/store/groups'

const useStyles = makeStyles((theme) => ({
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
		height: '100%',
	},
	form: {
		height: '100%',
		justifyContent: 'space-between',
	},
}))

interface EditGroupInformationProps {
	data?: IGroupChat
	onClose?: () => void
	onTouch?: () => void
	onSubmit?: () => void
}

const EditGroupInformation = ({ data, onClose, onTouch, onSubmit }: EditGroupInformationProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const handleSubmit = (data: GroupInfoData) => {
		if (onSubmit) onSubmit()
		dispatch(editGroupInfoRequest(data))
	}
	if (!data) return null

	return (
		<div className={classes.groupInformation}>
			<SideBarTabHeader title="Edit Group" onCancel={onClose} />
			<GroupInfo
				className={classes.form}
				onSubmit={handleSubmit}
				data={{
					...data,
					campusId: null,
				}}
				onTouch={onTouch}
			/>
		</div>
	)
}

export default EditGroupInformation

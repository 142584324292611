import { createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { TCampus } from './types/TCampus'
import { campusApi } from './api'
import { RootState } from '@/store'
import { createOptionMapper } from '@/utils/common'

export const FEATURE_NAME = 'CAMPUSES'

export const campusAdapter = createEntityAdapter<TCampus>()

type CampusOption = {
	label: string
	value: number
}

export const campusSlice = createSlice({
	name: FEATURE_NAME,
	initialState: campusAdapter.getInitialState({
		activeCampuses: [] as CampusOption[],
		initialized: false,
		isLoading: false,
	}),
	reducers: {},
	extraReducers: (builder) =>
		builder.addMatcher(isAnyOf(campusApi.endpoints.getCampuses.matchFulfilled), (state, { payload: { campuses } }) => {
			state.activeCampuses = campuses.filter((campus) => campus.active).map(createOptionMapper<number>('id', 'name'))
			state.initialized = true
			campusAdapter.setAll(state, campuses)
		}),
})

const selectState = (state: RootState) => state[FEATURE_NAME]
export const campusSelectors = campusAdapter.getSelectors(selectState)

export const getActiveCampuses = createSelector(selectState, (state) => state.activeCampuses)

export const selectShowCampuses = createSelector(selectState, (state) => state.activeCampuses.length > 1)

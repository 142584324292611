import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { MODULE_NAME } from './constants'
import { TPreference, TPreferenceGroup, DtoPreferences, PreferenceState } from './types'

export const groupsAdapter = createEntityAdapter<TPreferenceGroup>()
export const preferencesAdapter = createEntityAdapter<TPreference>()

export const preferenceSlice = createSlice({
	name: MODULE_NAME,
	initialState: {
		groups: groupsAdapter.getInitialState(),
		preferences: preferencesAdapter.getInitialState(),
		isLoading: false,
	} as PreferenceState,
	reducers: {
		togglePreference: ({ preferences }, action: PayloadAction<number>) => {
			const preferenceId = action.payload
			const preference = preferences.entities[preferenceId]
			if (preference) {
				preferencesAdapter.updateOne(preferences, {
					id: preferenceId,
					changes: { value: !preference.value },
				})
			}
		},

		// TODO: I imagine these belong in extraReducers
		loadUserPreferencesRequestPending: (state) => {
			state.isLoading = true
		},
		loadUserPreferencesRequestFulfilled: (state, action: PayloadAction<DtoPreferences>) => {
			state.isLoading = false
			groupsAdapter.setAll(state.groups, action.payload.groups)
			preferencesAdapter.setAll(state.preferences, action.payload.preferences)
		},
		loadUserPreferencesRequestRejected: (state) => {
			state.isLoading = false
		},
	},
})

export const {
	togglePreference,
	loadUserPreferencesRequestFulfilled,
	loadUserPreferencesRequestPending,
	loadUserPreferencesRequestRejected,
} = preferenceSlice.actions

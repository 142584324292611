import http from '@/api/http/requestHttp'
import { EventInfo } from '@/store/events/types'

const postNewEvent = async (eventInfo: EventInfo, schoolId: number) => {
	const url = `${schoolId}/events/postEvent`
	const body = { eventInfo }

	return http.post(url, body)
}

export default postNewEvent

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useMemo } from 'react'

import { PrivacyLevels } from '@/api/http/user/patchUserPrivacySettings'
import { LockIcon } from '@/components'
import { AdminBadge } from '@/components/Badges/AdminBadge'
import DotButton from '@/components/Buttons/DotButton'
import PrivacySelect from '@/components/PrivacySelect'
import { ProfileInfoItemBadge } from '@/components/ProfileInfoItemBadge'
import { STYLES } from '@/constants'
import { dateDiff, formatDateNew, formatDateRange } from '@/utils/dateTime2'
import { ProfileInfoCardTypes, getItemPrivacyType } from '@/utils/userExperienceItems'

const useStyles = makeStyles((theme) => ({
	profileInfoItem: ({ outlined, interactive }: any) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		justifyContent: 'space-between',
		border: outlined ? `solid 1px ${theme.palette.divider}` : 'none',
		padding: outlined ? '10px 20px' : 0,
		margin: '2vh 0',
		cursor: interactive ? 'pointer' : 'inherit',
	}),
	lockIcon: {
		width: 15,
	},

	grayText: {
		color: theme.palette.text.secondary,
	},
	membershipLogo: {
		borderRadius: '50%',
	},
	itemImage: {
		color: theme.palette.primary.main,
		width: '3vw',
		height: '3vw',
		marginRight: '25px',
		[theme.breakpoints.up('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('lg')]: {
			width: '2.5vw',
			height: '2.5vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '3.5vw',
			height: '3.5vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '4.5vw',
			height: '4.5vw',
			minWidth: 50,
			minHeight: 50,
		},
	},
	lockIconContainer: {
		width: 40,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default,
		borderRadius: 5,
		cursor: 'pointer',
		marginLeft: 10,
	},
	descriptionContainer: {
		color: theme.palette.text.primary,
		display: 'flex',
	},
	adminBadge: {
		marginLeft: 10,
		marginRight: 0,
	},
	select: {
		marginLeft: 10,
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
}))

interface ProfileInfoItemProps {
	type: ProfileInfoCardTypes
	className?: string
	item: any
	onClick?: () => void
	outlined?: boolean
	canEdit?: boolean
	isVolunteer?: boolean
	showInfoIcons?: boolean
	onClickLockIcon?: () => void
	onClickEdit?: (id: string | number) => void
	onClickDelete?: (id: string | number) => void
	onClickMoreButton?: (id: string | number) => () => void
	onChangePrivacy?: (id: string | number, newPrivacyLevel: PrivacyLevels) => void
}

const ProfileInfoItem = ({
	className = '',
	item: {
		id,
		title,
		area,
		employer,
		position,
		startDate,
		endDate,
		programTitle,
		location,
		eventName,
		department,
		membershipType,
		photoUrl,
		isLocked,
		isAdmin,
		privacyLevel,
	},
	type,
	onClick,
	outlined,
	canEdit = false,
	isVolunteer = false,
	showInfoIcons = false,
	onClickEdit,
	onClickDelete,
	onClickLockIcon,
	onClickMoreButton,
	onChangePrivacy,
}: ProfileInfoItemProps) => {
	const classes = useStyles({ outlined, interactive: !!onClick })

	const itemTitle = title || position || programTitle || eventName
	const itemSubTitle = isVolunteer ? formatDateNew(startDate, 'dateShort') : membershipType || area || employer || location || department
	const itemDate = isVolunteer
		? `${dateDiff(startDate, endDate)} ${dateDiff(startDate, endDate) === 1 ? 'hour' : 'hours'}`
		: formatDateRange(startDate, endDate)

	const renderButtons = useMemo(
		() => [
			{
				label: 'Edit',
				onClick: (e: any) => {
					e.stopPropagation()
					if (onClickEdit) onClickEdit(id)
				},
				icon: null,
			},
			{
				label: 'Delete',
				onClick: (e: any) => {
					e.stopPropagation()
					if (onClickDelete) onClickDelete(id)
				},
				icon: null,
			},
		],
		[id, onClickDelete, onClickEdit],
	)

	return (
		<div className={classNames(className, classes.profileInfoItem)} onClick={onClick}>
			<div className={classes.descriptionContainer}>
				{photoUrl ? (
					<img
						className={classNames(type === ProfileInfoCardTypes.MEMBERSHIPS ? classes.membershipLogo : '', classes.itemImage)}
						src={photoUrl}
						alt=""
					/>
				) : (
					<ProfileInfoItemBadge type={type} weight="fill" className={classes.itemImage} />
				)}

				<div>
					<Typography>{itemTitle}</Typography>
					<Typography>{itemSubTitle}</Typography>
					<Typography className={classes.grayText}>{itemDate}</Typography>
				</div>
			</div>
			<div className={classes.descriptionContainer}>
				{isLocked && showInfoIcons && canEdit && (
					<span className={classes.lockIconContainer} onClick={onClickLockIcon}>
						<LockIcon className={classes.lockIcon} />
					</span>
				)}
				{isAdmin && showInfoIcons && <AdminBadge size={40} iconSize={20} rootClass={classes.adminBadge} />}
				{canEdit && (
					<PrivacySelect
						className={classes.select}
						itemId={id}
						type={getItemPrivacyType(type)}
						privacyLevel={privacyLevel}
						onChangePrivacy={onChangePrivacy}
					/>
				)}
				{canEdit && !endDate && (
					<DotButton
						variant="secondary"
						className={classes.moreButton}
						renderButtons={renderButtons}
						showReportButton={false}
						onClick={isLocked ? onClickLockIcon : onClickMoreButton ? onClickMoreButton(id) : undefined}
					/>
				)}
			</div>
		</div>
	)
}

export default ProfileInfoItem

import { useEntityListingByType } from '@/anthology/hooks/useEntityListingByType'
import settings from '@/constants/http'
import { useCurrentUser } from '@/hooks/userHooks'
import { useUserOrganizationsQuery } from '../api'
import { TUserOrganizationTypes } from '../types/UserOrganizationTypes'

const useCurrentUserOrgs = (
	defaultType: TUserOrganizationTypes = 'followed',
	skipRequest: boolean = false,
	defaultTake: number = settings.SEARCH_LIST_PER_PAGE,
) => {
	const currentUser = useCurrentUser()

	const { filters, loadMore, onChangeType } = useEntityListingByType<TUserOrganizationTypes>(defaultType)
	const { skip, take, type } = filters
	const query = useUserOrganizationsQuery(
		{ skip, take, type, userId: currentUser.id },
		{
			skip: skipRequest,
			refetchOnMountOrArgChange: true,
		},
	)
	const canLoadMore = query.data && query.data.items.length < query.data.totalItems
	const showPreloader = query.isLoading || (query.isFetching && skip === 0)
	return {
		...query,
		loadMore,
		canLoadMore,
		showPreloader,
		onChangeType,
		filters,
	}
}

export default useCurrentUserOrgs

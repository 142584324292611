import http from '@/api/http/requestHttp'
import { UserInfo } from '../../../interfaces/user'

export type LoginParams = {
	externalId: string
	email: string
	firstName: string
	lastName: string
	// Only for PSU
	userType?: string
	schoolId: number
}

const login = async ({ schoolId, externalId, ...body }: LoginParams) => {
	const url = `${schoolId}/users/login/${externalId}/v2`

	return http.post<{ user: UserInfo; returning: boolean }>(url, body)
}

export default login

import { SocketEventTypes, useSocketEventTypeListener } from '@/hooks/useSocket'
import { useCurrentUser } from '@/hooks/userHooks'
import { UserInfo } from '@/interfaces/user'
import { useAppDispatch } from '@/store'
import { FeedPost } from '@/store/feeds/types'
import { camelizeKeys } from 'humps'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import { UI_STATES_ENABLED } from '../feed/config'
import { clearListDeletion, deleteById, onPostReceived, onPostUpdated, postLiked, updatePostUiState } from '../feed/slice'
import useRealtimeComments from '../comments/hooks/useRealtimeComments'

// @TODO: add REAL_TIME_IS_ENABLED in the config is it is not enabled pass empty function to sockets
// this can be useful in case there are schools where we don;t support real time feed
// const emptyFn = () => {}

export const EmitTypes = {
	created: 'created',
	updated: 'updated',
	deleted: 'deleted',
	like: 'like',
} as const

type EmitKeys = keyof typeof EmitTypes

type LikePayload = {
	userId: UserInfo['id']
	post: FeedPost
}

export type CreatePayloadData<TKey extends EmitKeys, TPayload> = {
	type: TKey
	payload: TPayload
}

type SocketData = CreatePayloadData<Exclude<EmitKeys, 'like'>, FeedPost> | CreatePayloadData<'like', LikePayload>

const DELAY_ACTUAL_UPDATE = 1000
// @TODO: need to come back to uiState and switch to proper handling of transitions (CSSTransition or using a lib)
const useRealtimeFeed = () => {
	const dispatch = useAppDispatch()
	const currentUser = useCurrentUser()
	const handlePosts = useCallback(
		({ type, payload }: SocketData) => {
			const post = type !== 'like' ? (camelizeKeys(payload) as FeedPost) : (camelizeKeys(payload.post) as FeedPost)
			const clearUiState = debounce(() => {
				dispatch(updatePostUiState({ id: post.id, uiState: undefined }))
			}, DELAY_ACTUAL_UPDATE)

			switch (type) {
				case 'created':
					dispatch(onPostReceived({ posts: [post], userId: currentUser.id }))

					break
				case 'updated':
					if (UI_STATES_ENABLED) {
						dispatch(updatePostUiState({ id: post.id, uiState: type }))
						clearUiState()
					}
					dispatch(onPostUpdated(post))
					break
				case 'deleted':
					if (UI_STATES_ENABLED) {
						dispatch(updatePostUiState({ id: post.id, uiState: type }))
						debounce(() => {
							dispatch(deleteById(post.id))
						}, DELAY_ACTUAL_UPDATE)()

						debounce(() => {
							dispatch(clearListDeletion())
						}, DELAY_ACTUAL_UPDATE + 1000)()
					} else {
						dispatch(deleteById(post.id))
					}

					break
				case 'like':
					dispatch(postLiked({ post, userId: payload.userId, currentUserId: currentUser.id }))
					break
			}
		},
		[currentUser.id, dispatch],
	)
	useRealtimeComments()
	useSocketEventTypeListener(SocketEventTypes.POST, handlePosts)
}

export default useRealtimeFeed

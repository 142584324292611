import { STYLES } from '@/constants'
import { makeStyles, Theme, Typography } from '@material-ui/core'

import React from 'react'

import placeholderImage from './images/placeholder_image.svg'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - ${STYLES.LANDING_FOOTER_HEIGHT}px - ${theme.spacing(2)})`,
	},
	content: {
		width: '70%',
		backgroundColor: theme.palette.background.paper,
		padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
		borderRadius: theme.spacing(2),
		position: 'relative',
		textAlign: 'center',
	},
	messageBox: {
		textAlign: 'center',
		marginTop: '30px',
	},
	messageTitle: {
		...theme.typography.h4,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			...theme.typography.h5,
		},
	},
	message: {
		...theme.typography.body2,
	},
	image: {
		width: '100%',
		height: 'auto',
	},
}))

const ErrorCard: React.FC = () => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<img alt="Page is temporary unavailable" src={placeholderImage} className={classes.image} />
				<div className={classes.messageBox}>
					<Typography className={classes.messageTitle}>Sorry, something went wrong</Typography>
					<Typography className={classes.message}>We are working hard to fix the problem</Typography>
				</div>
			</div>
		</div>
	)
}

export { ErrorCard }

import axios, { AxiosError, AxiosInstance } from 'axios'
import get from 'lodash/get'

import { isEngage } from '@/constants/configuration'
import * as DIContainer from '@/init/DIContainer'
import { store } from '@/store'
import { setAccessToken } from '@/store/auth'
import util from '@/utils'
import { getAppUrl } from '@/utils/urlHandlers'
import { Mutex } from 'async-mutex'
import platform from 'platform'
import camelizeResponseInterceptor from './interceptors/camelizeResponseInterceptor'

const authService = DIContainer.getAuthService()

const mutex = new Mutex()

export const formDataConfig = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
	},
}

const Http = (basePath?: string): AxiosInstance => {
	const instance = axios.create({
		baseURL: util.urlHandler.getBaseUrl(basePath),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	const UNAUTHORIZED_STATUS = 401

	const handleUnauthorized = async (error: AxiosError) => {
		const status = get(error, 'response.status')

		if (status === UNAUTHORIZED_STATUS) {
			if (!mutex.isLocked()) {
				const release = await mutex.acquire()

				const {
					data: { accessToken, tokenType },
					error: tokenError,
				} = await authService.refreshToken()
				release()
				if (accessToken && tokenType && !tokenError) {
					if (isEngage()) {
						error.config.headers['Authorization'] = `${tokenType} ${accessToken}`
						instance.defaults.headers['Authorization'] = `${tokenType} ${accessToken}`
					} else {
						instance.defaults.headers['accesstoken'] = accessToken
						error.config.headers['accesstoken'] = accessToken
					}
					store.dispatch(setAccessToken({ token: accessToken }))
					const result = await instance.request(error.config)
					return result
				} else {
					window.location.href = getAppUrl()
					authService.clearStorage()
				}
			} else {
				await mutex.waitForUnlock()
				const result = instance.request(error.config)
				return result
			}
		}
		return Promise.reject(error)
	}

	instance.interceptors.response.use(camelizeResponseInterceptor, (error) => handleUnauthorized(error))

	instance.interceptors.request.use(async (req: any) => {
		await mutex.waitForUnlock()

		const token = authService.getStorageData('accessToken')
		const tokenType = authService.getStorageData('tokenType')
		const userId = authService.getStorageData('currentUserId')

		const userAgent = `${platform.os.family}/${platform.description}/${platform.layout}`

		if (token) {
			req.headers = {
				...req.headers,
				userId: `${userId}`,
				userAgent,
			}

			// @TODO: legacy access token remove after full switch
			if (isEngage()) {
				req.headers['Authorization'] = `${tokenType || 'Bearer'} ${token}`
			} else {
				req.headers['accesstoken'] = token
			}
		}
		return req
	})
	return instance
}
export const buildApiMethod = (basePath: string) => {
	return Http(basePath)
}

export default Http()

import { createDraft, current, Draft, original } from 'immer'
import { cloneDeep, isEqual, omit } from 'lodash'
import { atom, RecoilState, Snapshot } from 'recoil'
import { _genGlobalStateDraftSetter } from '.'
import { IdInt } from '../types'
import { ValueOrUpdater } from './types'

type StateSetter = (globalState: GlobalState<any>, value: any) => void

interface StateChangeStatus {
	didChange: boolean
	newValue: any
	prevValue: any
	diff?: Record<string, any>
}

type StateDiffChecker = (globalState?: GlobalState<any>) => StateChangeStatus
interface OnStoreChangeArgs {
	snapshot: Snapshot
	prevSnapshot: Snapshot
	setState: StateSetter
	setOwnState: (value: any) => void
	checkDidChange: StateDiffChecker
	currentUserId: IdInt
}

interface OnStateChangeArgs<T> {
	prevValue: Draft<T>
	newValue: Draft<T>
	diff: Partial<T>
	currentUserId: IdInt
	storeSnapshot: Snapshot
	prevStoreSnapshot: Snapshot
	debug: () => {
		prevValue: T
		newValue: T
		diff: Partial<T>
	}
}

type OnStoreChange = (args: OnStoreChangeArgs) => void
type OnStateChange<T> = (args: OnStateChangeArgs<T>) => { newValue?: T } | void

export class GlobalState<T> {
	_config: { defaultValue?: T }
	_atom: RecoilState<T>
	_onStoreChange?: OnStoreChange
	_onChange?: OnStateChange<T>

	constructor(config = {}, handlers: { onStoreChange?: OnStoreChange; onChange?: OnStateChange<T> } = undefined) {
		this._config = config
		this._atom = null
		this._onStoreChange = handlers?.onStoreChange
		this._onChange = handlers?.onChange
	}

	initAtomWithKey(key) {
		const atomConfig = omit(
			{
				...this._config,
				default: this._config.defaultValue,
			},
			['defaultValue'],
		)

		this._atom = atom({ ...atomConfig, key })
	}

	get atom() {
		return this._atom
	}

	getDefaultValue() {
		return cloneDeep(this._config.defaultValue)
	}

	/**
	 * Set the value of this global state.
	 */
	setValue(valueOrSetter: ValueOrUpdater<T>) {
		// @ts-ignore
		const currentValue = window.__GLOBAL_RECOIL__.getState(this)
		_genGlobalStateDraftSetter((getNewValue) => {
			const newValue = getNewValue(currentValue)
			// @ts-ignore
			window.__GLOBAL_RECOIL__.setState(this, newValue)
		})(valueOrSetter)
	}

	onStoreChange(data: Omit<OnStoreChangeArgs, 'setOwnState'>) {
		if (!this._onStoreChange && !this._onChange) {
			return
		}

		const checkDidChange = (globalState?: GlobalState<any>) => data.checkDidChange(globalState || this)

		const newData = {
			...data,
			checkDidChange,
			setOwnState: (value: T) => data.setState(this, value),
		}

		this._onStoreChange?.(newData)

		if (!this._onChange) {
			return
		}

		const ownChange = checkDidChange()

		if (!ownChange.didChange) {
			return
		}

		const draftPrevValue = createDraft(ownChange.prevValue)
		const draftNewValue = createDraft(ownChange.newValue)
		const ownDiff = ownChange.diff as Partial<T>

		const result = this._onChange({
			diff: ownDiff,
			prevValue: draftPrevValue,
			newValue: draftNewValue,
			currentUserId: newData.currentUserId,
			storeSnapshot: newData.snapshot,
			prevStoreSnapshot: newData.prevSnapshot,
			debug: () => {
				return { prevValue: current(draftPrevValue), newValue: current(draftNewValue), diff: ownDiff }
			},
		})

		const wasChangedAgain = result && result.newValue && !isEqual(original(result.newValue), draftNewValue)
		if (wasChangedAgain) {
			newData.setOwnState(current(result.newValue))
		}
	}
}

import { init } from '@amplitude/analytics-browser'
import { track, setUserId, Identify, identify } from '@amplitude/analytics-browser'
import { isDevEnv } from './authHandlers'
import { AnalyticsUser } from '@navengage/amplitude'

export class Analytics {
	private static isDev: boolean

	static init() {
		this.isDev = isDevEnv()
		try {
			if (!this.isDev) {
				init(process.env.REACT_APP_ANALYTICS_KEY)
			}
		} catch (e) {
			console.error(e)
		}
	}

	static setUser(user: AnalyticsUser) {
		if (this.isDev) {
			console.info('Analytics user has been set')
			console.table(user)
			return
		}
		try {
			setUserId(String(user.userId).padStart(5, '0'))
			const identifyObj = new Identify()

			for (const property in user) {
				identifyObj.set(property, String(user[property]))
			}
			identify(identifyObj)
		} catch (e) {
			// TO DO - Implement some error reporting
			console.error(e)
		}
	}

	static track(event_type: string, properties: any = {}) {
		if (this.isDev) {
			console.info(
				'Analytics event fired:',
				JSON.stringify({
					event_type,
					...properties,
				}),
			)
		} else {
			track(event_type, properties)
		}
	}
}

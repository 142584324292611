import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { STYLES } from '@/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 10,
	},
	title: {
		fontWeight: 700,
	},
	navButton: {
		width: 50,
		height: 50,
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	contentElement: {
		width: 30,
		height: 30,
		padding: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	icon: {
		width: 30,
		height: 30,
	},
	content: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

interface SideBarTabHeaderProps {
	title: string
	onCancel?: () => void
	rightElement?: React.ReactNode
	middleElement?: React.ReactNode
}

const SideBarTabHeader = ({ title, onCancel, rightElement, middleElement }: SideBarTabHeaderProps) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<IconButton className={classes.navButton} onClick={onCancel}>
				<ChevronLeftIcon className={classes.icon} />
			</IconButton>
			<div className={classes.content}>
				<div className={classes.contentElement}> </div>
				<Typography className={classes.title}>{title}</Typography>
				<div className={classes.contentElement}> {middleElement}</div>
			</div>
			<div className={classes.navButton}> {rightElement}</div>
		</div>
	)
}

export default SideBarTabHeader

import { includeUrl } from '@/utils/urlHandlers'

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useUrlIncludesPaths = (urls: Array<string>) => {
	const { pathname } = useLocation()
	const [includes, setIncludes] = useState(false)

	useEffect(() => {
		if (includeUrl(urls)) {
			setIncludes(true)
		} else {
			setIncludes(false)
		}
	}, [pathname, urls])

	return includes
}

export default useUrlIncludesPaths

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { NetworkUserInfo, PostActivity } from '@/interfaces/user'
import PostCard from '@/components/Feeds/PostCard'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { getFullName } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: 20,
	},
	profileImage: {
		width: 40,
		height: 40,
		minWidth: 40,
		minHeight: 40,
		marginRight: 15,
	},
	selectedOptionImage: {
		width: 42,
		height: 42,
		minWidth: 42,
		minHeight: 42,
		borderRadius: '50%',
		marginRight: 15,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	text: {
		fontSize: 15,
		fontWeight: 450,
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	userInfoContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginBottom: 15,
		alignItems: 'center',
	},
}))

export interface FeedsActivityRowProps {
	userInfo?: NetworkUserInfo
	activity: PostActivity
	organizationInfo?: {
		name: string
		photoUrl: string
	}
}

const getActivityType = (type: string) => {
	const userActivityTypes: { [k: string]: string } = {
		likedPost: 'liked',
		commentedOnPost: 'commented on',
		repostedPost: 'reposted',
		createdNewPost: 'posted',
	}

	return userActivityTypes[type]
}

const FeedsActivityRow = ({ userInfo, activity, organizationInfo }: FeedsActivityRowProps) => {
	const classes = useStyles()

	const externalId = userInfo?.externalId
	const firstName = userInfo?.firstName
	const lastName = userInfo?.lastName
	const photoUrl = userInfo?.photoUrl

	const userName = getFullName(firstName, lastName)
	const organizationName = organizationInfo?.name

	const displayName = organizationName || userName

	return (
		<div className={classes.container}>
			<div className={classes.userInfoContainer}>
				{userInfo ? (
					<LazyLoadProfileImage className={classes.profileImage} externalUserId={externalId || ''} photoUrl={photoUrl} />
				) : (
					<img className={classes.selectedOptionImage} src={organizationInfo?.photoUrl} alt={displayName} />
				)}
				<Typography className={classNames(classes.userName, classes.text)}>{`${displayName} ${getActivityType(activity.type)}`}</Typography>
			</div>
			<PostCard {...activity.post} showAsPreview clickable />
		</div>
	)
}

export default FeedsActivityRow

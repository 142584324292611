import { Skeleton } from '@/components/Skeleton'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	infoBlockValue: {
		fontWeight: 700,
		position: 'relative',
		zIndex: 1,
	},
	infoBlock: {
		position: 'relative',
		width: 100,
		height: 100,
		borderRadius: 5,
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		textAlign: 'center',
	},

	infoBlockLabel: {
		fontSize: 15,
		position: 'relative',
		zIndex: 1,
	},
}))

interface InfoBlockProps {
	value?: string | number
	label: string
	isLoading?: boolean
}

const InfoBlock: React.FC<InfoBlockProps> = ({ value, label, isLoading = false }) => {
	const classes = useStyles()

	return (
		<div className={classes.infoBlock}>
			{isLoading && <Skeleton backgroundColor="none" />}
			<Typography className={classes.infoBlockValue} variant="subtitle1">
				{value}
			</Typography>
			<Typography className={classes.infoBlockLabel}>{label}</Typography>
		</div>
	)
}

export { InfoBlock }

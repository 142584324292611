import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	card: {
		'&:first-child': {
			marginTop: '10vh',
		},
		width: '38vw',
		padding: '2vh 2vw',
		marginTop: '2vh',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		[theme.breakpoints.down('lg')]: {
			width: '46vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90vw',
		},
	},
	title: {
		fontWeight: 700,
		marginBottom: '1.5vh',
	},
}))

interface CardProps {
	title?: string
	children: React.ReactNode
	className?: string
	id?: string
}

const Card = ({ className = '', title = '', id = '', children }: CardProps) => {
	const classes = useStyles()

	return (
		<div id={id} className={classNames(classes.card, className)}>
			{title && (
				<Typography className={classes.title} variant="body1">
					{title}
				</Typography>
			)}
			{children}
		</div>
	)
}

export default Card

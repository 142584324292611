import utils from '@/utils'
import { axiosBaseQuery } from '@/utils/rtkQueryUtils'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

// Todo - tear down the generatedApi
const API_BASE_URL = utils.urlHandler.getBaseUrl()
const BASE_URL = `${API_BASE_URL}`

export const generatedBaseApi = createApi({
	reducerPath: 'analyticsGeneratedApi',
	baseQuery: axiosBaseQuery(BASE_URL),
	endpoints: (builder) => ({}),
})

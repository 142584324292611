import http from '@/api/http/requestHttp'

export interface ToggleReactionParams {
	messageId: string
	reactionType?: string
	schoolId: number
}

const createReaction = async ({ messageId, reactionType = 'like', schoolId }: ToggleReactionParams) => {
	const url = `${schoolId}/messages/${messageId}/reaction`
	const data = {
		action: reactionType,
	}
	return http.post(url, data)
}

export default createReaction

import createDOMPurify from 'dompurify'

// @TODO: would be better to do this once in the backend on post addition / update
// most of scripting time in front end takes this function
export function createDescription(description: string) {
	const DOMPurify = createDOMPurify(window)
	const cleanDescription = DOMPurify.sanitize(description, {
		ADD_ATTR: ['target'],
	})

	return { __html: cleanDescription }
}

export const customEntityTransform = (text: string) => {
	const createLink = (value: string) => `<a href='${value}' target='_blank' rel='noopener noreferrer'>${value}</a>`

	const validateUrl = (value: string) => {
		const expression =
			// eslint-disable-next-line no-useless-escape
			/\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i
		const regex = new RegExp(expression)

		return value.match(regex)?.filter((url) => !!url)
	}

	const replace = (text: string) => {
		const urls = validateUrl(text)
		let newText = text

		urls?.forEach((url: string) => (newText = text.replace(url, createLink(url))))

		return newText
	}

	const validation = validateUrl(text)

	if (validation && validation.length < 2) return createLink(text)

	return `<p>${replace(text)}</p>`
}

export const toHtml = (state: any) => {
	if (state?.blocks && Array.isArray(state.blocks)) {
		const preparedBlocks = state.blocks.map((block: any) => customEntityTransform(block.text))
		return preparedBlocks.join('\n')
	}

	return ''
}

export const toText = (value: string) => {
	return value?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''
}

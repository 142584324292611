import { SCHOOL_ID, isNavApp } from '@/constants/configuration'
import { Analytics } from '@/utils/amplitude'
import { getQueryParamByName } from '@/utils/urlHandlers'
import { WebViewActions, getWebviewHelper } from '@/utils/webviewHelper'
import { getAuthService, initDependencies } from './DIContainer'
import { initSchoolConfig } from './config'
import authStorage from '@/utils/auth/storage/authStorage'

const getSchoolIdParam = () => {
	const schoolIdParam = getQueryParamByName('appMode')

	const schoolId = isNavApp() ? Number(schoolIdParam) : SCHOOL_ID
	if (!schoolId) {
		return null
	}
	authStorage.setItem('schoolId', schoolId)
	return schoolId
}

const checkWebviewLogin = () => {
	const { isWebview } = getWebviewHelper()
	const webViewAction = getQueryParamByName('webViewAction')
	const appMode = getQueryParamByName('appMode')
	const schoolId = getSchoolIdParam()
	const authService = getAuthService()
	if (appMode && appMode === 'testflight') {
		return
	}
	if (isWebview && (webViewAction === WebViewActions.signIn || webViewAction === WebViewActions.signUp) && schoolId) {
		authService.authorize({ schoolId })
	}
}

export const initApp = async () => {
	initDependencies()
	checkWebviewLogin()
	initSchoolConfig()

	Analytics.init()
}

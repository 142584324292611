const FORM = {
	eventName: 'eventName',
	startDate: 'startDate',
	startTime: 'startTime',
	endDate: 'endDate',
	endTime: 'endTime',
	description: 'description',
	repeating: 'repeating',
	repeatCount: 'repeatCount',
	campus: 'campus',
	locationType: 'locationType',
	locationName: 'locationName',
	locationAddress: 'locationAddress',
	virtualLink: 'virtualLink',
	hostOrganization: 'hostOrganization',
	categories: 'categories',
	contactName: 'contactName',
	contactEmail: 'contactEmail',
	photo: 'photo',
	privacyType: 'privacyType',
	addingShifts: 'addingShifts',
	shiftsNumber: 'shiftsNumber',
	volunteersPerShift: 'volunteersPerShift',
	shifts: 'shifts',
	postToFeed: 'postToFeed',
	externalUrl: 'externalUrl',
}

export type CreateEventFormKeys = keyof typeof FORM
export type CreateEventFormType = {
	[key in CreateEventFormKeys]?: any
}

export default FORM

import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import DialoguePage from '@/pages/MessagesPage/DialoguePage'
import { DiscoverGroupsPage } from '@/pages/MessagesPage/DiscoverGroupsPage'
import GroupPage from '@/pages/MessagesPage/GroupPage'
import NewMessagePage from '@/pages/MessagesPage/NewMessagePage'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const NEW_MESSAGE_PAGE = createPageTitle('New Message')
const DISCOVER_GROUPS_PAGE = createPageTitle('Discover Groups')

const routes: AppRoute[] = [
	{
		component: NewMessagePage,
		title: NEW_MESSAGE_PAGE,
		name: NEW_MESSAGE_PAGE,
		path: PATHS.APP.MESSAGES_LIST,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DialoguePage,
		name: `${UNIQUE_PAGE_TITLE_REQUIRED}_DIALOGUE`,
		path: PATHS.APP.MESSAGES_USER_DIALOGUE(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: GroupPage,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.MESSAGES_GROUP(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverGroupsPage,
		name: DISCOVER_GROUPS_PAGE,
		path: PATHS.APP.DISCOVER_GROUP,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import { useCallback, useMemo } from 'react'

import { ContentPreloader } from '@/components/ContentPreloader'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/store'
import { getSearchConfig, loadMore } from '@/store/organizations'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useSearchOrganizationsQuery } from '../api'
import OrganizationCard from '../components/OrganizationCard'
import { DtoOrganization } from '../types/dto/DtoOrganization'
import SearchOrganizationsParams from '../types/queryParams/SearchOrganizationsParams'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const DiscoverOrganizations = () => {
	const classes = useStyles()
	const searchConfig = useAppSelector(getSearchConfig)

	const { data, isLoading } = useSearchOrganizationsQuery(
		{
			skip: searchConfig.page,
			categoryIds: searchConfig.params.categories && searchConfig.params.categories.length > 0 ? searchConfig.params.categories : undefined,
			name: searchConfig.params.query ?? undefined,
			branchId: searchConfig.params.branches ?? undefined,
		} as SearchOrganizationsParams,
		{
			refetchOnMountOrArgChange: true,
		},
	)
	const dispatch = useAppDispatch()
	const organizations = data ? data.items : []
	// can create general transformer for this
	const canLoadMore = data ? data.items.length < data.totalItems : false

	const handleLoadMore = useCallback(() => {
		dispatch(loadMore())
	}, [dispatch])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
			// {
			// 	variant: 'outlined',
			// 	label: 'Request to add organization',
			// 	onClick: requestToAddOrganization,
			// },
		],
		[],
	)

	const showPlaceholder = !canLoadMore && !isLoading && !organizations.length
	if (isLoading) {
		return <ContentPreloader />
	}
	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="Discover Organizations" />
			{showPlaceholder ? (
				<NotFoundPlaceholder iconKey={FEATURE.organizations} buttons={placeholderButtons}>
					<Typography align="center">Try expanding your search.</Typography>
				</NotFoundPlaceholder>
			) : (
				<ExperienceCardsSection
					placeholder="That’s all for now. Check back soon for more organizations!"
					handleLoadMoreItems={handleLoadMore}
					items={organizations}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={(items: DtoOrganization[]) => (
						<>
							{items.map(({ id, name, categories, profilePicture, summary, follow }: DtoOrganization) => {
								return (
									<OrganizationCard
										key={`${id}`}
										id={id}
										title={name}
										photoUrl={profilePicture}
										description={summary}
										categories={categories}
										followed={follow?.response === 'followed'}
									/>
								)
							})}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default DiscoverOrganizations

import { canLoadMore } from '@/anthology/utils'
import { ContentPreloader } from '@/components/ContentPreloader'
import Card from '@/components/IndividualPage/Card'
import ItemsSectionWithButton from '@/components/ItemsSections/ItemsSectionWithButton'
import settings from '@/constants/http'
import { Typography, makeStyles } from '@material-ui/core'
import { getShortCounterLabel } from '@navengage/sen-shared-assets'
import React, { useCallback, useState } from 'react'
import { useGetMembersQuery } from '../../api'
import { DtoOrganization } from '../../types/dto/DtoOrganization'
import { DtoOrganizationMember } from '../../types/dto/DtoOrganizationMember'
import GetMembersParams from '../../types/queryParams/GetMembersParams'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userRow: {
		margin: '8px 0',
	},
	itemsContainer: {
		margin: '1vh 0',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},

	addButton: {
		width: 25,
		height: 25,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	addIcon: {
		width: 18,
		height: 18,
	},
}))

export type IListCardProps = {
	orgId: DtoOrganization['id']
	cardId?: string
	title: string
	type?: GetMembersParams['type']
}

const ListCard: React.FC<IListCardProps> = ({ orgId: id, title, cardId, type = 'members' }) => {
	const [offset, setOffset] = useState(0)
	const { data, isLoading, isFetching } = useGetMembersQuery({ id, skip: offset, type })
	const classes = useStyles()
	const loadMore = useCallback(() => {
		setOffset((prev) => prev + settings.SEARCH_LIST_PER_PAGE)
	}, [])
	const total = data?.totalItems
	return (
		<Card>
			<div id={cardId} className={classes.titleContainer}>
				<Typography variant="body1">
					<b>{title}</b> {`${total ? ' | ' + getShortCounterLabel(total) : '0'}`}
				</Typography>
			</div>
			<div>
				{isLoading ? (
					<ContentPreloader />
				) : (
					<ItemsSectionWithButton
						classnames={{
							content: classes.cardContainer,
						}}
						isLoading={isFetching}
						items={data.items}
						canLoadMore={canLoadMore(data)}
						handleLoadMoreItems={loadMore}
						renderItems={(items: DtoOrganizationMember[]) =>
							items.map((member) => (
								<MembershipInfoRow
									openProfileOnClick
									user={member.data}
									classnames={{
										userRow: classes.userRow, //@TODO: stylings, displaying lists needs to be corrected this component is going to be re-rendered always, this is general issue in all places
									}}
									userEmail={member.userId.campusEmail}
								/>
							))
						}
					/>
				)}
			</div>
		</Card>
	)
}

export default ListCard

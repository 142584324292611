import { FeedsState } from '@/store/feeds/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/feeds/constants'

const selectState = (state: { [MODULE_NAME]: FeedsState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)

export const getFeedsTagsOptions = createSelector(selectState, (state) => state.filterOptions.tags)
export const getFeedsFilterOption = createSelector(selectState, (state) => state.filterOptions)

export const getIsOpenDeleteConfirmationModal = createSelector(selectState, (state) => state.isOpenDeleteConfirmationModal)

export const selectIsScheduledPostsModalOpened = createSelector(selectState, (state) => state.isScheduledPostsModalOpened)

import { Link, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { ColorButton } from '@/components'
import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import { CheckBox } from '@/components/widgets'
import { PATHS } from '@/constants'
import { useIsMobileScreen } from '@/hooks/useIsMobileScreen'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useAppDispatch } from '@/store'
import {
	getLatestPrivacyPolicy,
	getLatestTermsAndConditions,
	getSignUpFormFilled,
	getUserInfo,
	getUserRole,
	storeSignUpFormRequest,
} from '@/store/auth'
import { Analytics } from '@/utils/amplitude'
import { userHasSignUpData } from '@/utils/authHandlers'
import { UserEvents } from '@navengage/amplitude'
import { useOnboardingStepConfig } from './hooks'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		color: theme.palette.common.white,
		[theme.breakpoints.up('xl')]: {
			marginTop: '5vh',
		},
	},
	card: {
		width: '37vw',
		[theme.breakpoints.up('xl')]: {
			minHeight: 980,
		},
		[theme.breakpoints.down('lg')]: {
			width: '35vw',
			minWidth: 550,
		},
		[theme.breakpoints.down('md')]: {
			width: '42vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '60vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			minWidth: '100%',
			boxShadow: 'none',
		},
		paddingBottom: '2vh',
		margin: 35,
	},
	cardContentContainer: {
		margin: '4vh 3vw 1vh 3vw',
		minHeight: '60vh',
		alignItems: 'start',
		[theme.breakpoints.down('xs')]: {
			margin: 0,
		},
	},
	divider: {
		width: '50%',
		marginTop: '2vh',
		marginBottom: '4vh',
		borderBottom: `solid 2px ${theme.palette.primary.main}`,
	},
	boldText: {
		fontWeight: 700,
	},
	pledgeItem: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		margin: '1.5vh 0',
	},
	itemIcon: {
		width: '15%',
	},
	icon: {
		width: '2.5em',
		height: '2.5em',
		[theme.breakpoints.up('xl')]: {
			width: '3.1875em',
			height: '3.1875em',
		},
		[theme.breakpoints.down('xs')]: {
			width: '1.5em',
			height: '1.5em',
		},
	},
	itemContentContainer: {
		width: '85%',
		marginTop: '0.5vh',
	},
	itemSubtitle: {
		width: '100%',
		margin: '1.5vh 0',
	},
	itemContent: {
		width: '80%',
	},
	cardFooter: {
		color: theme.palette.common.white,
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		alignSelf: 'center',
		marginTop: '4vh',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			flexDirection: 'column',
		},
	},
	link: {
		color: theme.palette.common.white,
		textDecoration: 'underline',
	},
	acceptTerms: {
		color: theme.palette.common.white,
		padding: '0 1vw',
	},
	continueButton: {
		width: '11vw',
		minHeight: 50,
		fontWeight: 600,
		minWidth: 200,
		marginTop: 50,
		marginBottom: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2.5),
		},
	},
}))

const TermsConditions = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const signUpFormFilled = useSelector(getSignUpFormFilled)
	const userInfo = useSelector(getUserInfo)
	const userRole = useSelector(getUserRole)
	const termsAndConditions = useSelector(getLatestTermsAndConditions)
	const privacyPolicy = useSelector(getLatestPrivacyPolicy)
	const isMobile = useIsMobileScreen()
	const [checked, setChecked] = useState(false)
	const theme = useTheme()

	const {
		school: { shortName, applicationName },
	} = useSchoolConfig()
	const pledgeConfig = useOnboardingStepConfig('pledge')

	const handleClickCheckbox = () => setChecked(!checked)

	const handleClickNext = () => {
		dispatch(storeSignUpFormRequest(4))
		push(PATHS.SIGN_UP.THANKS)
		Analytics.track(UserEvents.AGREED_TERMS_CONDITIONS)
	}

	if (!signUpFormFilled && !userHasSignUpData(userRole, userInfo)) {
		return <Redirect to={PATHS.SIGN_UP.STEPS} />
	}

	return (
		<CardLayout>
			<Card
				disableBorder={isMobile ? true : false}
				className={classes.card}
				cardContentClass={classes.cardContentContainer}
				variant="secondary"
			>
				<Typography variant="h1" className={classes.title}>
					{pledgeConfig.title}
				</Typography>
				<div className={classes.divider} />
				{pledgeConfig?.choices.map((content) => (
					<div key={content.label} className={classes.pledgeItem}>
						<div className={classes.itemContentContainer}>
							<Typography variant="h2" className={classes.boldText}>
								{content.label}
							</Typography>
							<Typography component="span" className={classes.itemContent}>
								{content.description}
								{content.learnMore && (
									<Link className={classes.link} href={content.learnMore} target="_blank">
										Learn More
									</Link>
								)}
							</Typography>
						</div>
					</div>
				))}
				{pledgeConfig.subTitle && (
					<Typography variant="body1" className={classes.itemSubtitle}>
						{pledgeConfig.subTitle}
					</Typography>
				)}
				<div className={classes.cardFooter}>
					<CheckBox
						value={checked}
						checkColor={theme.palette.primary.main}
						label={
							<Typography className={classes.acceptTerms} variant="subtitle1">
								I agree to the&nbsp;
								<Link className={classes.link} href={termsAndConditions?.externalUrl} target="_blank">
									Terms & Conditions
								</Link>
								&nbsp; and&nbsp;
								<Link className={classes.link} href={privacyPolicy?.externalUrl} target="_blank">
									Privacy Policy
								</Link>
								&nbsp; and to use {applicationName} in accordance with all {shortName} policies.
							</Typography>
						}
						onChange={handleClickCheckbox}
					/>
					<ColorButton className={classes.continueButton} onClick={handleClickNext} disabled={!checked}>
						Next
					</ColorButton>
				</div>
			</Card>
		</CardLayout>
	)
}

export default TermsConditions

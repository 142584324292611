import { IconButton } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'

import { CameraIcon } from '@/components'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import LinkPreview from '@/components/Feeds/LinkPreview'
import ImagePreview from '@/components/Messages/ImagePreview'
import ItemWithCancelButton from '@/components/Messages/ItemWithCancelButton'
import { STYLES } from '@/constants'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { createMessageRequest, getNewMessageContent, removeNewMessageSharedItems, setNewMessageContent } from '@/store/messages'
import { toText } from '@/utils/toHTML'
import Editor from '../Editor'
import { OnChangePluginProps } from '../Editor/plugins/OnChangePlugin'

const useStyles = makeStyles((theme) => ({
	content: {
		margin: '10px 1.5vw 0 1.5vw',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	newMessageRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '1vw 0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'end',
	},
	contentContainer: {
		width: 'calc(100% - 4vw)',
		margin: '1vw',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'end',
	},
	postButton: {
		border: 'none',
		height: 52,
		'&:disabled': {
			border: 'none',
			color: theme.palette.primary.main,
			'&>span': {
				opacity: 0.6,
			},
		},
	},
	textEditor: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '10px 20px',
		minHeight: 'auto',
		maxWidth: 'none',
	},
	rowContainer: {
		width: 'calc(100% - 50px - 80px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.background.default,
		padding: '7px 0',
		minHeight: 30,
		borderRadius: 7,
	},
	addPhotoIcon: {
		color: theme.palette.primary.main,
	},
	autoSizer: {
		height: 'fit-content !important',
	},
	input: {
		display: 'none',
	},
	shareItem: {
		width: 344,
		padding: 20,
	},
	sharePost: {
		maxWidth: 550,
		width: 'fit-content',
		padding: 20,
	},
}))

interface AddNewMessageRowProps {
	handleHideSelectUserInput?: () => void
	targetUser?: any
}

const AddNewMessageRow = ({ targetUser, handleHideSelectUserInput }: AddNewMessageRowProps) => {
	const theme = useTheme()
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const user = useSelector(getUserInfo)
	const [placeholder, setPlaceholder] = useState('')

	const [isUserArchived, setIsUserArchived] = useState(false)

	useEffect(() => setIsUserArchived(!!targetUser?.isArchived), [targetUser?.isArchived])

	useEffect(() => {
		// @TODO: refine implementation such that isUserArchived is reactive without reliance on React hooks
		const accountName = targetUser?.firstName || targetUser?.lastName ? `${targetUser?.firstName} ${targetUser?.lastName}` : 'this user'
		const newPlaceholder = isUserArchived ? `Messaging is disabled because ${accountName}'s account is archived.` : 'Start your message'

		setPlaceholder(newPlaceholder)
	}, [isUserArchived, targetUser])

	const { message, photoUrl, sharedEntity, linkPreviewUrl } = useSelector(getNewMessageContent)

	const [previewLink, setPreviewLink] = useState<any>(null)
	const [showLinkPreview, setShowLinkPreview] = useState(true)
	const [resetTextEditorValues, setResetTextEditorValues] = useState(0)

	const hasValue = !!toText(message || '').trim()

	const sharedItemIsEmpty = !sharedEntity
	const canAddLinkPreview = !photoUrl && sharedItemIsEmpty
	const showCardContainer = !!sharedEntity

	const canPostMessage = !isUserArchived && (!sharedItemIsEmpty || photoUrl || hasValue)

	const resetMessage = useCallback(() => {
		setResetTextEditorValues((prev) => prev + 1)
	}, [])

	const handleChangeMessage: OnChangePluginProps['onChange'] = useCallback(
		({ data: htmlText, links }) => {
			dispatch(setNewMessageContent({ message: htmlText }))

			if (!canAddLinkPreview) return

			const linkToPreview = links?.[0] || ''
			const resetPreviewLink = linkToPreview !== previewLink
			if (resetPreviewLink) {
				setShowLinkPreview(true)
				setPreviewLink(linkToPreview)
			}

			if (showLinkPreview || resetPreviewLink) {
				dispatch(setNewMessageContent({ linkPreviewUrl: linkToPreview }))
			}
		},
		[canAddLinkPreview, dispatch, previewLink, showLinkPreview],
	)

	const handleCancelPreviewLink = useCallback(() => {
		setShowLinkPreview(false)
		dispatch(setNewMessageContent({ linkPreviewUrl: '' }))
	}, [dispatch])

	const handlePostMessage = useCallback(() => {
		if (!canPostMessage) return

		resetMessage()

		dispatch(createMessageRequest())
	}, [canPostMessage, dispatch, resetMessage])

	const onChangeImageInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files

		if (!(files && files.length)) return

		const url = URL.createObjectURL(files[0])

		dispatch(setNewMessageContent({ photoUrl: url }))
	}

	const handleClearImage = () => {
		dispatch(setNewMessageContent({ photoUrl: undefined }))
	}

	const handleClearItemsToShare = () => {
		dispatch(removeNewMessageSharedItems())
	}

	useEffect(() => {
		if (hasValue && handleHideSelectUserInput) {
			handleHideSelectUserInput()
		}
	}, [handleHideSelectUserInput, hasValue])

	useEffect(() => {
		if (canAddLinkPreview) {
			setShowLinkPreview(true)
			dispatch(setNewMessageContent({ linkPreviewUrl: previewLink }))
		} else {
			setShowLinkPreview(false)
			dispatch(setNewMessageContent({ linkPreviewUrl: '' }))
		}
	}, [canAddLinkPreview, dispatch, previewLink])

	return (
		<AutoSizer disableHeight className={classes.autoSizer}>
			{({ width }) => (
				<div
					className={classes.newMessageRow}
					style={{
						width,
					}}
				>
					<div className={classes.contentContainer}>
						<input
							accept="image/*"
							className={classes.input}
							id="icon-button-file"
							type="file"
							onChange={onChangeImageInputHandler}
							multiple={false}
							key={photoUrl}
							disabled={!sharedItemIsEmpty}
						/>
						<label htmlFor="icon-button-file">
							<IconButton aria-label="upload picture" component="span" disabled={!sharedItemIsEmpty}>
								<CameraIcon className={classes.addPhotoIcon} />
							</IconButton>
						</label>
						<div className={classes.rowContainer}>
							<Editor
								reset={resetTextEditorValues}
								disableMentions
								styles={{
									editorContainer: {
										maxWidth: 'calc(100% - 40px)',
										margin: '10px auto',
										backgroundColor: theme.palette.background.default,
									},
									editorInput: { minHeight: 60 },
								}}
								namespace="Messages"
								placeholder={placeholder}
								value={message}
								disabled={isUserArchived}
								onChange={handleChangeMessage}
							/>

							{photoUrl && <ImagePreview url={photoUrl} onClear={handleClearImage} />}
							{showLinkPreview && linkPreviewUrl && <LinkPreview userId={user.id} url={linkPreviewUrl} onClose={handleCancelPreviewLink} />}
							{showCardContainer && (
								<ItemWithCancelButton onCancel={handleClearItemsToShare}>
									<div className={sharedEntity.sharedEntityType === EntityTypes.post ? classes.sharePost : classes.shareItem}>
										{sharedEntity && (
											<SharedEntity
												variant="primary"
												sharedEntityId={sharedEntity.sharedEntityId}
												sharedEntityType={sharedEntity.sharedEntityType}
											/>
										)}
									</div>
								</ItemWithCancelButton>
							)}
						</div>
						<OutlinedButton className={classes.postButton} onClick={handlePostMessage} disabled={!canPostMessage}>
							{/* FIXME when the above issue on state is fixed */}
							{isUserArchived ? 'Messaging is disabled, this account is archived' : 'Send'}
						</OutlinedButton>
					</div>
				</div>
			)}
		</AutoSizer>
	)
}

export default AddNewMessageRow

import { useGetEventByIdQuery } from '@/anthology/events/api'
import EventCard from '@/anthology/events/components/EventCard'
import EntityTypes from '../../types/EntityTypes'
import { ISharedEntityProps } from '../SharedEntity'
import { withErrorHandler } from '../withErrorHandler'

export type ISharedEventProps = ISharedEntityProps

const SharedEvent: React.FC<ISharedEventProps> = ({ variant = 'secondary', sharedEntityId: id, sharedEntityType: type, skip }) => {
	const { data, isLoading, isUninitialized, isError } = useGetEventByIdQuery(
		{ id },
		{
			skip: skip || type !== EntityTypes.event,
		},
	)

	return withErrorHandler({
		type,
		currentType: EntityTypes.event,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? <EventCard variant={variant} {...data} /> : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedEvent

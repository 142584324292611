import { makeStyles } from '@material-ui/core/styles'
import ROUTES from '@/routes'
import SettingsSidebar from '@/components/SettingsSidebar'
import Router from '@/components/Router'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
}))

const SettingsPage = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<SettingsSidebar />
			<Router routes={ROUTES.settings} />
		</div>
	)
}

export default SettingsPage

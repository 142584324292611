import { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { loadNetworkListWithFilterRequest } from '@/store/network/actions'
import { getNetworkUsers, getIsLoading, getEndHasBeenReached, getFilterIsReady } from '@/store/network'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import NotFoundPlaceholder from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { Typography } from '@material-ui/core'
import NetworkUserCard from '@/components/UserNetwork/NetworkUserCard'

const useStyles = makeStyles((theme) => ({
	root: {
		height: 'calc(100% - 90px)',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 90,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100%',
			marginTop: 0,
		},
		backgroundColor: theme.palette.background.paper,
	},
	cardContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 225px)`,
		justifyContent: 'space-evenly',
	},
}))

const Discover = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const network = useSelector(getNetworkUsers)
	const isLoading = useSelector(getIsLoading)
	const filterIsReady = useSelector(getFilterIsReady)
	const endHasBeenReached = useSelector(getEndHasBeenReached)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) dispatch(loadNetworkListWithFilterRequest({ fetchMore: true }))
	}, [dispatch, filterIsReady])

	const showPlaceholder = !canLoadMore && !isLoading && !network.length

	return (
		<div className={classes.root}>
			{showPlaceholder ? (
				<NotFoundPlaceholder src={''} isEmptyPlaceholder>
					<Typography align="center">No results found.</Typography>
				</NotFoundPlaceholder>
			) : (
				<ItemsSection
					classnames={{
						content: classes.cardContainer,
					}}
					handleLoadMoreItems={handleLoadMore}
					items={network}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ user, status }: any, idx: number) => (
								<NetworkUserCard key={`${idx}-my-network`} user={user} status={status} />
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default Discover

import EventForm from '@/components/IndividualPage/EventForm'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { createEventRequest, getCreateEventDefaultValues } from '@/store/events'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	pageRoot: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
}))

const CreateEvent = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const defaultValues = useSelector(getCreateEventDefaultValues)

	const onSubmit = (data: any) => {
		dispatch(createEventRequest(data))
	}

	return (
		<div className={classes.pageRoot}>
			<EventForm onSubmit={onSubmit} data={defaultValues} />
		</div>
	)
}

export default CreateEvent

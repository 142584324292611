import http from '@/api/http/requestHttp'

interface PostOrganizationsMembershipJoinInfoParams {
	orgId: string
	userId: number
	schoolId: number
}

const postOrganizationsMembershipJoinInfo = async ({ orgId, userId, schoolId }: PostOrganizationsMembershipJoinInfoParams) => {
	const url = `${schoolId}/organizations/org/${orgId}/requestInfo`
	const body = { userId }

	return http.post(url, body)
}

export default postOrganizationsMembershipJoinInfo

import { SvgIcon } from '@material-ui/core'

const InternshipsIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path d="M22 5h-5V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v1H2C.897 5 0 5.897 0 7v13c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2zM8 4c0-.551.449-1 1-1h6c.551 0 1 .449 1 1v1H8V4zm14 17H2c-.551 0-1-.449-1-1v-7.278c.295.172.634.278 1 .278h8v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V13h8c.366 0 .705-.106 1-.278V20c0 .551-.449 1-1 1zm-11-7v-2h2v2h-2zm11-2h-8v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.5H2c-.551 0-1-.449-1-1V7c0-.551.449-1 1-1h20c.551 0 1 .449 1 1v4c0 .551-.449 1-1 1z" />
		</SvgIcon>
	)
}

export default InternshipsIcon

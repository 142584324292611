import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { useCallback, useMemo } from 'react'

import { CircleIconButton, IconButton } from '@/components'
import Card from '@/components/IndividualPage/Card'
import { OrgDashboardActions } from '@/components/IndividualPage/OrganizationOwnerDashboard'
import { STYLES } from '@/constants'
import OrganizationRoleTypeStatus from '../types/OrganizationRoleTypeStatus'
import { DtoOrganization } from '../types/dto/DtoOrganization'

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	infoBlock: {
		minWidth: 161,
		height: 161,
		borderRadius: 5,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '10px 10px 0 0',
		...STYLES.BOX_SHADOW,
		'&:last-child': {
			marginRight: 0,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	infoButton: {
		padding: 0,
		height: 163,
	},
	icon: {
		height: 50,
		width: 50,
	},
	actionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		flexWrap: 'wrap',
	},
	actionInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	actionLabel: {
		marginTop: 10,
	},
}))

interface OrganizationOwnerDashboardProps {
	handleOpenCreateFeedModal: () => void
	userOrganizationRole: DtoOrganization['role']
}

const OrganizationOwnerDashboard = ({ handleOpenCreateFeedModal, userOrganizationRole }: OrganizationOwnerDashboardProps) => {
	const classes = useStyles()

	const handleSelectAction = useCallback(
		(action: OrgDashboardActions) => () => {
			if (action === OrgDashboardActions.POST_TO_FEED) {
				handleOpenCreateFeedModal()
			}
		},
		[handleOpenCreateFeedModal],
	)

	const allActions = useMemo(
		() => [
			{
				label: OrgDashboardActions.POST_TO_FEED,
				value: OrgDashboardActions.POST_TO_FEED,
				icon: <CircleIconButton />,
				onClick: handleSelectAction,
				allowedRoles: [OrganizationRoleTypeStatus.OFFICER],
			},
		],
		[handleSelectAction],
	)

	const userActions = useMemo(
		() => allActions.filter((action) => action.allowedRoles.includes(userOrganizationRole.type)),
		[allActions, userOrganizationRole.type],
	)

	return (
		<Card>
			<div className={classes.titleContainer}>
				<Typography variant="body1">
					<b>Dashboard</b> | {capitalize(userOrganizationRole.type)}
				</Typography>
			</div>
			<div className={classes.actionsContainer}>
				{userActions.map((action) => (
					<IconButton
						key={action.value}
						className={classNames(classes.infoButton, classes.infoBlock)}
						onClick={handleSelectAction(action.value)}
					>
						<div className={classes.actionInfo}>
							{action.icon}
							<Typography className={classes.actionLabel}>{action.label}</Typography>
						</div>
					</IconButton>
				))}
			</div>
		</Card>
	)
}

export default OrganizationOwnerDashboard

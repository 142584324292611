import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FC } from 'react'

import { AdminIcon } from '@/components'
import CardIconContainer from '../ExperienceCards/CardIconContainer'

interface StyleProps {
	iconSize?: number
}

const useStyles = makeStyles(() => ({
	adminIcon: ({ iconSize }: StyleProps) => ({
		width: iconSize,
	}),
}))

interface AdminBadgeProps extends StyleProps {
	rootClass?: string
	size?: number
}

export const AdminBadge: FC<AdminBadgeProps> = ({ size, rootClass, iconSize = 30 }) => {
	const appTheme = useTheme()
	const classes = useStyles({ iconSize })
	return (
		<CardIconContainer rootClass={rootClass} color={appTheme.palette.common.black} background={appTheme.palette.icons.admin} size={size}>
			<AdminIcon className={classes.adminIcon} />
		</CardIconContainer>
	)
}

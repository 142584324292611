import React from 'react'
import { DtoOrganization } from '../../types/dto/DtoOrganization'
import InfoBadge from '../InfoBadge'
import useGetOrganizationFollowerCount from '../../hooks/useGetOrganizationFollowerCount'

export type IFollowersCountProps = {
	orgId: DtoOrganization['id']
}

const FollowersCount: React.FC<IFollowersCountProps> = ({ orgId: id }) => {
	const { totalFollowers, isLoading, isFetching } = useGetOrganizationFollowerCount(id)
	return <InfoBadge isLoading={isLoading || isFetching} count={totalFollowers} label="Followers" />
}

export default FollowersCount

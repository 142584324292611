import { IconButton, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { CloseOutlined, CropOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import React, { ChangeEvent, useRef, useState } from 'react'

import { CameraIcon, ImageCropperModal, OutlinedButton } from '@/components'
import { FILE_FORMATS, STYLES } from '@/constants'
import { handleDrop, preventDefaults } from '@/utils/uploads'
import { ButtonColors } from '@navengage/sen-shared-assets'

interface StyleProps {
	url: string
	aspect: number
	disabled: boolean
	maxWidth?: number
	canCloseUploadField: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ aspect, maxWidth }) => ({
		display: 'flex',
		justifyContent: 'center',
		width: maxWidth ? maxWidth : '100%',
		paddingTop: maxWidth ? `${maxWidth / aspect}px` : `${100 / aspect}%`,
		position: 'relative',
	}),
	areaRoot: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'end',
	},
	dropArea: ({ disabled }) => ({
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: theme.palette.background.default,
		cursor: disabled ? 'auto' : 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	}),
	fileInput: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: '-1px',
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		width: 1,
	},
	cameraIcon: {
		width: '14%',
		height: '35%',
		color: theme.palette.text.primary,
	},
	uploadText: {
		color: theme.palette.text.secondary,
		marginBottom: 2,
		fontSize: 18,
	},
	uploadSubText: {
		color: theme.palette.text.secondary,
		fontSize: 16,
		textAlign: 'center',
	},
	changePhotoButton: {
		color: theme.palette.text.secondary,
		padding: 15,
		position: 'absolute',
	},
	imageContainer: {
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	image: ({ url }) => ({
		position: 'relative',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
		padding: 15,
		borderRadius: 5,
		background: `url(${url}) center center / cover no-repeat`,
	}),
	relativeContainer: {
		position: 'absolute',
		width: 'calc(100% - 30px)',
		height: 'calc(100% - 30px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	},
	controlButtonsContainer: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
	},
	clearImageButton: {
		width: 30,
		height: 30,
		color: theme.palette.common.white,
		margin: 10,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
	closeBlockButton: {
		height: 'fit-content',
	},
	outlinedButton: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		border: 'none',
		textTransform: 'none',
		fontSize: '0.9em',
		'&:hover': {
			border: 'none',
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
		minWidth: 135,
	},
}))

interface ImageUploadFieldProps {
	value?: string
	helperText?: string
	imageFormats?: string
	onChange?: (url: string) => void
	onClose?: () => void
	onClear?: () => void
	className?: string
	disabled?: boolean
	validateImageSize?: (imgUrl: string) => Promise<boolean>
	cropBeforeUpload?: boolean
	maxWidth?: number
	aspect?: { w: number; h?: number }
	placeholder?: React.ReactNode
}

export const ImageUploadField = ({
	maxWidth,
	imageFormats = FILE_FORMATS.commonImages,
	onChange = (url: string) => null,
	onClose,
	helperText = 'Preferred pixels: 1920:1080px',
	onClear,
	value = '',
	className = '',
	aspect = { w: 16, h: 9 },
	disabled = false,
	cropBeforeUpload,
	validateImageSize = () => new Promise((res) => res(true)),
	placeholder,
}: ImageUploadFieldProps) => {
	const [originalFileUrl, setOriginalFileUrl] = useState(value)
	const [fileUrl, setFileUrl] = useState(value)
	const [imageToCrop, setImageToCrop] = useState('')
	const [openCropModal, setOpenCropModal] = useState(false)

	const inputFileRef = useRef<any>(null)

	const canCloseUploadField = !!onClose
	const canClearUploadField = !!onClear
	const aspectRatio = aspect.w / (aspect?.h ? aspect?.h : aspect.w)

	const classes = useStyles({
		url: fileUrl,
		aspect: aspectRatio,
		disabled,
		maxWidth,
		canCloseUploadField,
	})

	const handleUploadImage = (imageSrc: string) => {
		setFileUrl(imageSrc)
		onChange(imageSrc)
	}

	const uploadHandler = async (files: FileList) => {
		if (!(files && files.length) || disabled) return

		const url = URL.createObjectURL(files[0])

		const isValid = await validateImageSize(url)

		if (!isValid) return

		setOriginalFileUrl(url)
		if (cropBeforeUpload) {
			setImageToCrop(url)
			setOpenCropModal(true)
		} else {
			handleUploadImage(url)
		}
	}

	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => uploadHandler(e.target.files as FileList)

	const handleCloseModal = () => {
		setOpenCropModal(false)
	}

	const handleClear = () => {
		setFileUrl('')
		if (onClear) onClear()
	}

	const handleClose = (e: any) => {
		e.stopPropagation()
		if (onClose) onClose()
	}

	const handleOpenCropModal = (e: any) => {
		e.stopPropagation()
		setImageToCrop(originalFileUrl)
		setOpenCropModal(true)
	}

	const handleOpenUploadFileModal = () => inputFileRef.current?.click()

	return (
		<div className={classNames(classes.root, className)}>
			<ImageCropperModal
				isOpen={openCropModal}
				onClose={handleCloseModal}
				onChange={handleUploadImage}
				imageUrl={imageToCrop}
				aspect={aspectRatio}
			/>
			<div className={classes.imageContainer}>
				<input
					key={fileUrl}
					ref={inputFileRef}
					id="add-photo-input"
					className={classes.fileInput}
					type="file"
					accept={imageFormats}
					onChange={onChangeHandler}
					multiple={false}
					disabled={disabled}
				/>
				{!fileUrl ? (
					<div className={classes.areaRoot}>
						<label htmlFor="add-photo-input" className={classes.dropArea}>
							<div
								className={classes.dropArea}
								onDragEnter={preventDefaults}
								onDragOver={preventDefaults}
								onDragLeave={preventDefaults}
								onDrop={(e) => uploadHandler(handleDrop(e))}
							>
								<CameraIcon className={classes.cameraIcon} />
								{placeholder ? (
									placeholder
								) : (
									<>
										<Typography className={classes.uploadText}>Add a Photo</Typography>
										<Typography className={classes.uploadSubText}>
											{`${helperText} (Aspect ratio ${aspect.w}:${aspect?.h ? aspect?.h : aspect.w})`}
										</Typography>
										<Typography className={classes.uploadSubText}>Preferred file size: 5mb</Typography>
									</>
								)}
							</div>
						</label>
						<div className={classes.changePhotoButton}>
							{!disabled && canCloseUploadField && (
								<IconButton className={classes.closeBlockButton} onClick={handleClose}>
									<CloseOutlined fontSize="small" />
								</IconButton>
							)}
						</div>
					</div>
				) : (
					<div className={classes.image}>
						{!disabled && (
							<>
								{!canCloseUploadField && !canClearUploadField && (
									<div className={classes.relativeContainer}>
										<OutlinedButton className={classes.outlinedButton} color={ButtonColors.WHITE} onClick={handleOpenUploadFileModal}>
											Change Photo
										</OutlinedButton>
									</div>
								)}
								{(canCloseUploadField || canClearUploadField || !cropBeforeUpload) && (
									<div className={classes.controlButtonsContainer}>
										{(canClearUploadField || canCloseUploadField) && (
											<IconButton className={classes.clearImageButton} onClick={handleClear}>
												<CloseOutlined fontSize="small" />
											</IconButton>
										)}
										{!cropBeforeUpload && (
											<IconButton className={classes.clearImageButton} onClick={handleOpenCropModal}>
												<CropOutlined fontSize="small" />
											</IconButton>
										)}
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

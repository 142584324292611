import { useCurrentUser } from '@/hooks/userHooks'
import { selectOrganizationsCount, useUserOrganizationsCountQuery } from '../api'
import { useMemo } from 'react'

const useCurrentUserOrgsCount = () => {
	const currentUser = useCurrentUser()
	const selectNormalizedCounts = useMemo(selectOrganizationsCount, [])
	const query = useUserOrganizationsCountQuery(
		{ userId: currentUser.id },
		{
			selectFromResult: (result) => ({
				...result,
				data: selectNormalizedCounts(result.data),
			}),
		},
	)
	return query
}

export default useCurrentUserOrgsCount

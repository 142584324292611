import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { useAppDispatch, useAppSelector } from '@/store'
import { loadMore } from '@/store/events/actions'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'

import { STYLES } from '@/constants'
import { Typography } from '@material-ui/core'

import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import settings from '@/constants/http'
import { getSearchConfig } from '@/store/events'
import { submitFeedback } from '@/utils/services'

import { useSearchEventsQuery } from '../api'
import EventCard from '../components/EventCard'

import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const DiscoverEvents = () => {
	const search = useAppSelector(getSearchConfig)

	const { isLoading, data, isFetching } = useSearchEventsQuery(
		{
			skip: search.page,
			search: search.params.query ? search.params.query : undefined,
			take: settings.SEARCH_LIST_PER_PAGE,
			categoryIds: search.params.categories && search.params.categories.length > 0 ? search.params.categories : undefined,
			endsAfter: search.params.date ? search.params.date.startDate : undefined,
			startsBefore: search.params.date ? search.params.date.endDate : undefined,
			branchId: search.params.branches ?? undefined,
		},
		{
			refetchOnMountOrArgChange: true,
		},
	)

	const classes = useStyles()
	const dispatch = useAppDispatch()
	const events = data ? data.items : []

	const handleLoadMore = useCallback(() => {
		dispatch(loadMore())
	}, [dispatch])

	const canLoadMore = data?.canLoadMore ? data.totalItems > data.items.length : false

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[],
	)

	const showPlaceholder = !canLoadMore && !isLoading && !events.length

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="Discover Events" />

			{showPlaceholder ? (
				<NotFoundPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center">Try expanding your search.</Typography>
				</NotFoundPlaceholder>
			) : (
				<ExperienceCardsSection
					placeholder="That’s all for now. Check back soon for more events!"
					handleLoadMoreItems={handleLoadMore}
					items={events}
					canLoadMore={canLoadMore}
					isLoading={isLoading || isFetching}
					renderItems={(items: any) => (
						<>
							{items.map((event) => (
								<EventCard key={`${event.id}`} {...event} />
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default DiscoverEvents

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

interface StyleProps {
	background?: string
	size?: number
	color?: string
}

interface CardIconContainerProps extends StyleProps {
	rootClass?: string
	children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
	root: ({ background = theme.palette.primary.dark, size = 45, color = theme.palette.common.white }: StyleProps) => ({
		borderRadius: 5,
		height: size,
		width: size,
		background,
		color,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
	}),
}))

const CardIconContainer = ({ background, size, color, children, rootClass }: CardIconContainerProps) => {
	const classes = useStyles({ background, size, color })

	return <div className={classNames(classes.root, rootClass)}>{children}</div>
}

export default CardIconContainer

import ShareButton from '@/components/Buttons/ShareButton'
import { STYLES } from '@/constants'
import { CardTypes, SharedData } from '@/interfaces/common'
import { Card as MaterialCard, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const useStyles = makeStyles((theme) => ({
	rootContainer: ({ showActionButtons }: any) => ({
		width: '100%',
		paddingTop: showActionButtons ? 452 : 350,
		position: 'relative',
	}),
	card: {
		borderRadius: 10,
		cursor: 'pointer',
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		height: '100%',
		width: '100%',
		maxWidth: 344,
		[theme.breakpoints.down('xs')]: {
			maxWidth: 'auto',
		},
	},
	cardHeader: ({ showActionButtons }: any) => ({
		position: 'relative',
		height: showActionButtons ? '43%' : '50%',
		aspectRatio: '16/9',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		padding: 0,
		background: `linear-gradient(0deg, rgba(154,154,154,1) 0%, rgba(0,0,0,1) 94%)`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	}),
	cardContent: ({ showActionButtons }: any) => ({
		height: showActionButtons ? '47%' : '40%',
		padding: '7%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		...theme.typography.subtitle1,
	}),
	cardContentTitle: {
		fontWeight: 700,
	},
	cardContentSubTitle: {
		color: theme.palette.text.secondary,
		fontWeight: 600,
		...theme.typography.subtitle1,
	},
	cardContentTitleContainer: {
		marginTop: 7,
		...theme.typography.body1,
	},
	cardContentInfo: {
		position: 'relative',
		margin: '10px 0',
		height: '100%',
		// maxHeight: '100px', // you can adjust this value to your preference
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'&::after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			height: '24%', // You can adjust the height of the fade effect
			background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
		},
	},
	cardContentFooter: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	image: {
		width: '100%',
		aspectRatio: '16/9',
		objectFit: 'cover',
	},
	gradientImage: {
		borderRadius: 10,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		position: 'absolute',
		background: `linear-gradient(0deg, rgba(154,154,154,0) 40%, rgba(0,0,0,0.6) 90%)`,
	},
	imageButtonsContainer: {
		position: 'absolute',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'calc(100% - 30px)',
		padding: 15,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	// @TODO: #copyPast address when switch to styled components
	feedbackButton: {
		height: 50,
		minWidth: 50,
		marginLeft: 'auto',
	},
}))

interface ItemCardProps {
	cardType?: CardTypes
	containerClasses?: {
		root?: string
		titleContainer?: string
		contentContainer?: string
		footerContainer?: string
	}
	photoUrl: string
	title: string
	subTitle?: string
	handleOnClickCard?: () => void
	renderHeader?: () => React.ReactNode
	renderContent: () => React.ReactNode
	renderFooterButtons?: () => React.ReactNode
	sharedData?: SharedData
	showActionButtons?: boolean
	sharingUrl: string
}

const ItemCardContainer = ({
	containerClasses,
	cardType = CardTypes.EVENTS,
	photoUrl = '',
	title = '',
	subTitle = '',
	handleOnClickCard = () => {},
	renderHeader = () => null,
	renderContent,
	renderFooterButtons,
	sharedData = {},
	showActionButtons = true,
	sharingUrl = '',
}: ItemCardProps) => {
	const [imageUrl, setImageUrl] = useState(photoUrl)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const classes = useStyles({ showActionButtons })

	const handleClick = (e: any) => {
		if (anchorRef.current && !anchorRef.current.contains(e.target as HTMLElement)) {
			return
		}

		handleOnClickCard()
	}

	useEffect(() => {
		if (photoUrl) {
			setImageUrl(photoUrl)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photoUrl])

	return (
		<div className={classes.rootContainer}>
			<MaterialCard ref={anchorRef} className={classNames(classes.card, containerClasses?.root)} onClick={handleClick}>
				<div className={classes.cardHeader}>
					<LazyLoadImage className={classes.image} alt={''} src={imageUrl} />
					<div className={classes.gradientImage} />

					<div className={classes.imageButtonsContainer}>{renderHeader()}</div>
				</div>
				<div className={classes.cardContent}>
					<div className={classNames(classes.cardContentTitleContainer, containerClasses?.titleContainer)}>
						<Typography className={classes.cardContentTitle}>{title}</Typography>
						<Typography className={classNames(classes.cardContentSubTitle, containerClasses?.titleContainer)}>{subTitle}</Typography>
					</div>
					<div className={classNames(classes.cardContentInfo, containerClasses?.contentContainer)}>{renderContent()}</div>
					{showActionButtons && (
						<div className={classNames(classes.cardContentFooter, containerClasses?.footerContainer)}>
							{renderFooterButtons ? renderFooterButtons() : null}
							<ShareButton className={classes.feedbackButton} sharedData={sharedData} sharingUrl={sharingUrl} />
						</div>
					)}
				</div>
			</MaterialCard>
		</div>
	)
}

export default React.memo(ItemCardContainer)

import * as http from '@/api/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TJobFilterDto } from '../internships/types'
import { FEATURE_NAME } from './constants'

import { RootState } from '..'
import { selectAuth } from '../auth'

export const loadJobFiltersRequest = createAsyncThunk<TJobFilterDto, undefined, { state: RootState }>(
	`${FEATURE_NAME}/LOAD_JOBS_CONSTANTS_REQUEST`,
	async (_undefined, { dispatch, rejectWithValue, getState }) => {
		try {
			const rootState = getState()
			const { schoolId } = selectAuth(rootState)
			const response = await http.internships.getInternshipsFilters(schoolId)

			return response.data
		} catch (e: any) {
			return rejectWithValue(e)
		}
	},
)

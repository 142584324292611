import { ColorButton, CommonModalCard } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CardSelectOption from '@/components/CardSelect/CardSelectOption'
import { STYLES } from '@/constants'
import { TOption } from '@/store/types'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	optionsContainer: {
		borderTop: `solid 1px ${theme.palette.divider}`,
		maxHeight: '35vh',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		padding: '20px 20px 0 20px',
	},
	footer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '20px 0',
	},
	acceptButton: {
		width: 200,
		padding: 7,
		'&:hover': {
			boxShadow: '0px 0px 10px 0px rgba(34, 60, 80, 0.19)',
		},
	},
	gridContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 48%)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
	},
	flexContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	option: {
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: '#fcfcfc',
		},
	},
}))

interface CardSelectOptionsContainerProps {
	title: string
	subTitle?: string
	classnames?: {
		header?: string
		card?: string
		option?: string
	}
	value?: number | null
	subOptionValue?: number
	onChange: (v?: number | null, subOption?: number) => void
	options: {
		label: string
		description?: string
		value?: number | null
		options?: Array<TOption>
	}[]
	required?: boolean
	multiple?: boolean
	shouldAcceptChanges?: boolean
	handleBack?: () => void
	handleClose?: () => void
	variant?: 'primary' | 'secondary'
	disabled?: boolean
}

const CardSelectOptionsContainer = ({
	classnames = {},
	title,
	subTitle,
	value,
	subOptionValue,
	onChange = (v) => {},
	options = [],
	multiple = false,
	shouldAcceptChanges = false,
	handleBack,
	handleClose,
	variant = 'primary',
	disabled,
}: CardSelectOptionsContainerProps) => {
	const [selectedOptions, setSelectedOptions] = useState(value)
	const [selectedSubOption, setSelectedSubOption] = useState(subOptionValue)
	const classes = useStyles()

	const handleSubmit = (newValue?: number | null) => () => {
		onChange(newValue || selectedOptions, selectedSubOption)
		if (handleBack) handleBack()
		if (handleClose) handleClose()
	}

	const isValueSelected = useCallback(
		(value?: number | null) => {
			return Array.isArray(selectedOptions) ? selectedOptions.includes(Number(value)) : value === selectedOptions
		},
		[selectedOptions],
	)
	const handleSelectOption = (newValue?: number | null) => () => {
		setSelectedOptions(newValue)
		if (!shouldAcceptChanges) {
			handleSubmit(newValue)()
		}
	}

	const handleSubOptionSelection = (v: any) => {
		setSelectedSubOption(v)
	}

	return (
		<CommonModalCard
			classnames={{
				header: classnames.header,
				card: classnames.card,
			}}
			title={title}
			subTitle={subTitle}
			handleBack={handleBack}
			onClose={handleClose}
		>
			<div className={classes.optionsContainer}>
				<div className={variant === 'primary' ? classes.flexContainer : classes.gridContainer}>
					{options.map((opt) =>
						variant === 'primary' ? (
							<CardSelectOption
								disabled={disabled}
								className={classnames.option}
								key={`${opt.label}-${opt.value}`}
								onClick={handleSelectOption(opt.value)}
								multiple={multiple}
								checked={isValueSelected(opt.value)}
								checkedOption={selectedSubOption}
								subOptionClick={handleSubOptionSelection}
								{...opt}
							/>
						) : (
							<ColorButton
								key={`${opt.label}-${opt.value}`}
								color={isValueSelected(opt.value) ? ButtonColors.PRIMARY : ButtonColors.GREY}
								onClick={handleSelectOption(opt.value)}
								disabled={isValueSelected(opt.value) && !multiple}
								className={classes.option}
							>
								{opt.label}
							</ColorButton>
						),
					)}
				</div>
			</div>
			<div className={classes.footer}>
				{shouldAcceptChanges && (
					<ColorButton className={classes.acceptButton} onClick={handleSubmit()}>
						Done
					</ColorButton>
				)}
			</div>
		</CommonModalCard>
	)
}

export default CardSelectOptionsContainer

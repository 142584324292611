import IconButton from '@/components/Buttons/IconButton'
import Card from '@/components/IndividualPage/Card'
import { useAppDispatch } from '@/store'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import {
	CheckBox,
	ColorButton,
	CommonSelect,
	DatePickerSelect,
	FieldErrorMessage,
	ImageUploadField,
	Label,
	ShiftsCreationForm,
	TextField,
	Toggle,
} from '@/components'
import { ERRORS, PATHS, STYLES } from '@/constants'
import FORM, { CreateEventFormType } from '@/constants/createEventFormFields'
import {
	AVAILABLE_REPEAT_COUNTS_OPTIONS,
	EVENT_PRIVACY_TYPES,
	EVENT_REPEATING_SELECT_OPTIONS,
	EventLocationTypes,
	EventRepeatingOptions,
	MAX_EVENT_REPEAT_COUNT,
	MAX_SHIFTS,
	MAX_VOLUNTEERS_PER_SHIFT,
} from '@/constants/events'
import { getActiveCampuses } from '@/features/campus/slice'
import useImageUploadError from '@/hooks/useImageUploadError'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { showExitConfirmationModal } from '@/store/app'
import { getCategories } from '@/store/events'
import { getUserOrganizations } from '@/store/organizations'
import { getTimeOptionsArray } from '@/utils/dateTime2'
import { FEATURE } from '@navengage/sen-shared-assets'
import IconWrapper from '../IconWrapper'
import { Iff } from '../Iff'

const useStyles = makeStyles((theme) => ({
	pageRoot: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	card: {
		width: '50vw',
		padding: '4vh 4vw',
		[theme.breakpoints.down('md')]: {
			width: '90vw',
		},
	},
	title: {
		fontWeight: 700,
	},
	closeButton: {
		backgroundColor: theme.colors.red[200],
		color: theme.colors.red[500],
		'& >span': {
			margin: '3px 10px',
		},
		'&:hover': {
			backgroundColor: theme.colors.red[200],
			color: theme.colors.red[500],
		},
	},
	calendarIcon: {
		width: 60,
		marginRight: '1vw',
	},
	headerBlock: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '2vh',
	},
	headerWithIcon: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	formDescription: {
		color: theme.palette.text.secondary,
	},
	formInput: {
		width: '60%',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
	formDatePickers: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	datePicker: {
		'& > button': {
			height: 48,
		},
	},
	formSelect: {
		width: '40%',
		height: 48,
		marginRight: 15,
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	timeSelect: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 48,
		marginRight: 15,
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
	},
	dateTimeLabel: {
		width: '40%',
		marginRight: '5%',
	},
	selectWithTags: {
		flexDirection: 'column',
	},
	formFooter: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '4vh 0 1vh 0',
	},
	submitEventButton: {
		padding: '10px 20px',
		width: '15vw',
		minWidth: 200,
	},
	toggleLabel: {
		margin: '10px 0',
		color: theme.palette.text.primary,
		'&>span:last-child': {
			...theme.typography.body1,
		},
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'row',
	},
}))

const schema = yup.object().shape({
	[FORM.eventName]: yup.string().required(),
	[FORM.startDate]: yup.date().required(),
	[FORM.startTime]: yup.string().required(),
	[FORM.endDate]: yup.date().required(),
	[FORM.endTime]: yup.string().required(),
	[FORM.description]: yup.string().required(),
	[FORM.campus]: yup.array().min(1).required(),
	[FORM.repeating]: yup.string().oneOf(Object.keys(EventRepeatingOptions).map((key) => EventRepeatingOptions[key])),
	[FORM.repeatCount]: yup.number().when(FORM.repeating, {
		is: EventRepeatingOptions.DISABLED,
		then: yup.number().nullable(),
		otherwise: yup.number().min(1).max(MAX_EVENT_REPEAT_COUNT).required(),
	}),
	[FORM.locationType]: yup.string().oneOf(Object.keys(EventLocationTypes).map((key) => EventLocationTypes[key])),
	[FORM.virtualLink]: yup.string().nullable().when(FORM.locationType, {
		is: EventLocationTypes.VIRTUAL,
		then: yup.string().required(),
	}),
	[FORM.locationName]: yup.string().required(),
	[FORM.locationAddress]: yup.string().nullable(),
	[FORM.hostOrganization]: yup.array(),
	[FORM.externalUrl]: yup.string().nullable(),
	[FORM.categories]: yup.array().min(1).required(),
	[FORM.contactName]: yup.string().required(),
	[FORM.contactEmail]: yup.string().email().required(),
	[FORM.photo]: yup.string().required(),
	[FORM.addingShifts]: yup.boolean().nullable(),
	[FORM.postToFeed]: yup.boolean().nullable(),
	[FORM.shiftsNumber]: yup.number().when(FORM.addingShifts, {
		is: true,
		then: yup
			.number()
			.typeError(ERRORS.requiredField)
			.moreThan(0, ERRORS.moreThan(0))
			.lessThan(MAX_SHIFTS, ERRORS.lessThan(MAX_SHIFTS))
			.required(ERRORS.requiredField),
	}),
	[FORM.volunteersPerShift]: yup.number().when(FORM.addingShifts, {
		is: true,
		then: yup
			.number()
			.typeError(ERRORS.requiredField)
			.moreThan(0, ERRORS.moreThan(0))
			.lessThan(MAX_VOLUNTEERS_PER_SHIFT, ERRORS.lessThan(MAX_VOLUNTEERS_PER_SHIFT))
			.required(ERRORS.requiredField),
	}),
	[FORM.shifts]: yup
		.array()
		.of(
			yup.object().shape({
				start: yup.string(),
				end: yup.string(),
			}),
		)
		.when(FORM.addingShifts, {
			is: true,
			then: yup
				.array()
				.of(
					yup.object().shape({
						start: yup.string(),
						end: yup.string(),
					}),
				)
				.required(),
		}),
})

interface EventFormProps {
	onSubmit: (data: any) => void
	data: CreateEventFormType
	isEdit?: boolean
	cancelRedirectPath?: string
}

const EventForm = ({ onSubmit, data, isEdit = false, cancelRedirectPath = PATHS.APP.EVENTS }: EventFormProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors, isDirty },
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: data,
	})

	const [isUpdating, setIsUpdating] = useState(false)

	const validateImageSize = useImageUploadError()

	const categories = useSelector(getCategories)
	const campuses = useSelector(getActiveCampuses)
	const { superAdmin, admin } = useSelector(getUserOrganizations)

	const organizationsAsOptions = useMemo(
		() =>
			[...superAdmin, ...admin].map((org) => ({
				label: org.name,
				value: org.id,
			})),
		[admin, superAdmin],
	)

	const handleOpenModal = () => {
		dispatch(
			showExitConfirmationModal({
				isOpen: true,
				redirectPath: cancelRedirectPath,
			}),
		)
	}

	const selectedCategories = watch(FORM.categories)
	const selectedStartDate = watch(FORM.startDate)
	const selectedEndDate = watch(FORM.endDate)
	const shiftsNumber = watch(FORM.shiftsNumber)
	const volunteersPerShift = watch(FORM.volunteersPerShift)
	const addingShifts = watch(FORM.addingShifts)
	const locationType = watch(FORM.locationType)
	const repeating = watch(FORM.repeating)
	const organizations = watch(FORM.hostOrganization)
	const isVirtual = locationType === EventLocationTypes.VIRTUAL

	const volunteerCategory = useMemo(() => categories.find((c) => c.label.toLowerCase().includes('volunteer')), [categories])
	const timeOptions = useMemo(() => getTimeOptionsArray(), [])
	const canAddShifts = useMemo(
		() => Array.isArray(selectedCategories) && selectedCategories.some((c: any) => c === volunteerCategory?.value),
		[selectedCategories, volunteerCategory?.value],
	)

	const formHasErrors = !!Object.keys(errors).length

	const disableEditShifts = isEdit && data.categories?.includes(volunteerCategory?.value)

	const availableCategories = useMemo(() => {
		const shouldDisableVolunteerCategory = isEdit && data.categories?.includes(volunteerCategory?.value)

		if (!shouldDisableVolunteerCategory) return categories

		return categories.map((category) => (category.value === volunteerCategory?.value ? { ...category, disabled: true } : category))
	}, [categories, data.categories, isEdit, volunteerCategory?.value])

	const submitAndSetUpdating = (data: any) => {
		setIsUpdating(true)
		onSubmit(data)
	}

	useEffect(() => {
		if (!canAddShifts) setValue(FORM.addingShifts, canAddShifts)
	}, [canAddShifts, setValue])

	useEffect(() => {
		if (!addingShifts) {
			setValue(FORM.shiftsNumber, 0)
			setValue(FORM.volunteersPerShift, 0)
			setValue(FORM.shifts, [])
		}
	}, [addingShifts, setValue])

	const {
		school: { shortName, abbreviation },
	} = useSchoolConfig()
	const submitButtonLabel = isEdit ? 'Edit Event' : 'Create'
	const title = isEdit ? 'Edit Event' : 'Create Event'
	const disableSubmitButton = isUpdating || (isEdit && !isDirty)

	const {
		school: { applicationName },
	} = useSchoolConfig()

	return (
		<form onSubmit={handleSubmit(submitAndSetUpdating)}>
			<Card className={classes.card}>
				<div className={classes.headerBlock}>
					<div className={classes.headerWithIcon}>
						<IconWrapper iconKey={FEATURE.events} />
						<Typography component="span" className={classes.title} variant="h1">
							{title}
						</Typography>
					</div>
					<IconButton className={classes.closeButton} startIcon={<CloseIcon />} onClick={handleOpenModal}>
						Cancel
					</IconButton>
				</div>
				<Typography className={classes.formDescription}>
					Once your event is submitted, it will immediately post on {applicationName}.
				</Typography>
				<Typography className={classes.formDescription}>You can edit your event later.</Typography>
				<Controller
					name={FORM.eventName}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Name of Event" required>
							<TextField
								className={classes.formInput}
								placeholder="Event Name"
								error={!!errors[FORM.eventName]}
								disabled={isUpdating}
								{...field}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.eventName]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<div className={classes.formDatePickers}>
					<Controller
						name={FORM.startDate}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="Start Date"
								required
							>
								<DatePickerSelect
									maxDate={selectedEndDate}
									value={value}
									onChange={onChange}
									disablePast
									defaultLabel="Select Date"
									disabled={isUpdating}
									className={classes.datePicker}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.startDate]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
					<Controller
						name={FORM.startTime}
						control={control}
						defaultValue=""
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="Start Time"
								required
							>
								<CommonSelect
									classnames={{
										input: classNames(classes.timeSelect, classes.formSelect),
									}}
									value={value}
									onChange={onChange}
									options={timeOptions}
									placeholder="Start Time"
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.startTime]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				</div>
				<div className={classes.formDatePickers}>
					<Controller
						name={FORM.endDate}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="End Date"
								required
							>
								<DatePickerSelect
									minDate={selectedStartDate}
									value={value}
									onChange={onChange}
									disablePast
									defaultLabel="Select Date"
									disabled={isUpdating}
									className={classes.datePicker}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.endDate]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
					<Controller
						name={FORM.endTime}
						control={control}
						defaultValue=""
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.dateTimeLabel,
									label: classes.label,
								}}
								title="End Time"
								required
							>
								<CommonSelect
									classnames={{
										input: classNames(classes.timeSelect, classes.formSelect),
									}}
									value={value}
									onChange={onChange}
									options={timeOptions}
									placeholder="End Time"
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.endTime]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				</div>
				<Controller
					name={FORM.repeating}
					control={control}
					defaultValue={EVENT_REPEATING_SELECT_OPTIONS[0].value}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Is this a repeating event?">
							<CommonSelect
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								options={EVENT_REPEATING_SELECT_OPTIONS}
								placeholder="Select repeating option"
								disabled={isUpdating}
							/>
						</Label>
					)}
				/>
				{repeating !== EventRepeatingOptions.DISABLED && (
					<div className={classes.formInput}>
						<Controller
							name={FORM.repeatCount}
							control={control}
							render={({ field: { value, onChange } }) => (
								<Label classnames={{ label: classes.label }} title="Repeat count?" required>
									<CommonSelect
										value={value}
										onChange={onChange}
										options={AVAILABLE_REPEAT_COUNTS_OPTIONS}
										placeholder="Select repeat count"
										disabled={isUpdating}
									/>
								</Label>
							)}
						/>
					</div>
				)}
				<Controller
					name={FORM.description}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Description" required>
							<TextField
								error={!!errors[FORM.description]}
								className={classes.formInput}
								placeholder={`Tell the ${shortName} community about your event`}
								multiline
								disabled={isUpdating}
								{...field}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.description]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.campus}
					control={control}
					defaultValue={[]}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Campus" required>
							<CommonSelect
								renderTags
								multiple
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								options={campuses}
								placeholder="Select campus"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.campus]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.locationType}
					control={control}
					defaultValue={data.locationType ?? EventLocationTypes.IN_PERSON}
					render={({ field: { value, onChange, name } }) => (
						<Label classnames={{ label: classes.label }} title="Location Type">
							<RadioGroup aria-labelledby="Location Type" name={name} value={value} onChange={onChange} className={classes.radioGroup}>
								<FormControlLabel
									value={EventLocationTypes.IN_PERSON}
									control={<Radio color="primary" />}
									label={EventLocationTypes.IN_PERSON}
								/>
								<FormControlLabel
									value={EventLocationTypes.VIRTUAL}
									control={<Radio color="primary" />}
									label={EventLocationTypes.VIRTUAL}
								/>
							</RadioGroup>
						</Label>
					)}
				/>
				<Controller
					name={FORM.locationName}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Location Name" required>
							<TextField
								error={!!errors[FORM.locationName]}
								{...field}
								className={classes.formInput}
								placeholder="Ex. Nittany Lion Building, Room. 30" //#THEMING - ignore, event upload not used at new schools
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.locationName]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				{!isVirtual && (
					<Controller
						name={FORM.locationAddress}
						control={control}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="Location Address" optional>
								<TextField
									error={!!errors[FORM.locationAddress]}
									className={classes.formInput}
									placeholder="Ex. 123 Nittany Lion Road, State College, PA, 16801" //#THEMING - ignore, event upload not used at new schools
									{...field}
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.locationAddress]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				)}
				{isVirtual && (
					<Controller
						name={FORM.virtualLink}
						control={control}
						render={({ field }) => (
							<Label classnames={{ label: classes.label }} title="Virtual Link" required>
								<TextField
									variant="standard"
									error={!!errors[FORM.virtualLink]}
									className={classes.formInput}
									placeholder="Add link"
									{...field}
									disabled={isUpdating}
								/>
								<FieldErrorMessage hasError={!!errors[FORM.virtualLink]} message={ERRORS.requiredField} />
							</Label>
						)}
					/>
				)}
				<Controller
					name={FORM.externalUrl}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="External Url" optional>
							<TextField
								error={!!errors[FORM.externalUrl]}
								{...field}
								className={classes.formInput}
								placeholder="Add external url"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.externalUrl]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.hostOrganization}
					defaultValue={[]}
					control={control}
					render={({ field: { value, onChange } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Host Organization"
							optional
							description={
								!!organizationsAsOptions.length
									? 'You must be an admin or super admin of an organization to create an event on behalf of that organization.'
									: 'No organizations to add at the moment. If you would like to add an event on behalf of an organization, you must be an admin or super admin of that organization.'
							}
						>
							{!!organizationsAsOptions.length && (
								<>
									<CommonSelect
										renderTags
										multiple
										value={value}
										onChange={(e) => {
											onChange(e)
											if (Array.isArray(e) && !e.length) {
												setValue(FORM.privacyType, EVENT_PRIVACY_TYPES.Public)
											}
										}}
										classnames={{
											input: classes.formSelect,
										}}
										options={organizationsAsOptions}
										placeholder="Select Organization"
										disabled={isUpdating}
									/>
									<FieldErrorMessage hasError={!!errors[FORM.hostOrganization]} message={ERRORS.requiredField} />
								</>
							)}
						</Label>
					)}
				/>
				<Controller
					name={FORM.privacyType}
					control={control}
					defaultValue={data.privacyType ?? EVENT_PRIVACY_TYPES.Public}
					render={({ field: { value, onChange, name } }) => (
						<Label
							classnames={{ label: classes.label }}
							required
							title="Privacy Settings"
							description="Adding on behalf of an organization? Share this event with just your organization's members, both its members & followers, or share to everyone. Events without an organization must be public."
						>
							<RadioGroup
								aria-labelledby="Privacy Settings"
								name={name}
								value={value}
								onChange={(e) => onChange(Number(e.target.value))}
								className={classes.radioGroup}
							>
								{Object.entries(EVENT_PRIVACY_TYPES).map(([buttonLabel, buttonValue]) => (
									<FormControlLabel
										key={buttonValue}
										value={buttonValue}
										control={<Radio color="primary" />}
										label={buttonLabel}
										disabled={!organizations?.length && buttonValue !== EVENT_PRIVACY_TYPES.Public}
									/>
								))}
							</RadioGroup>
						</Label>
					)}
				/>
				<Controller
					name={FORM.categories}
					control={control}
					defaultValue={[]}
					render={({ field: { value, onChange } }) => (
						<Label classnames={{ label: classes.label }} title="Categories" required description="You can select multiple categories.">
							<CommonSelect
								value={value}
								onChange={onChange}
								classnames={{
									input: classes.formSelect,
								}}
								options={availableCategories}
								placeholder="Select Categories"
								multiple
								renderTags
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.categories]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.contactName}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Contact Name" required>
							<TextField
								error={!!errors[FORM.contactName]}
								{...field}
								className={classes.formInput}
								placeholder="First & Last Name"
								disabled={isUpdating}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.contactName]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.contactEmail}
					control={control}
					defaultValue=""
					render={({ field }) => (
						<Label classnames={{ label: classes.label }} title="Contact Email" required>
							<TextField
								error={!!errors[FORM.contactEmail]}
								{...field}
								className={classes.formInput}
								placeholder={`Ex. ehp1855@${abbreviation.toLowerCase()}.edu`}
								disabled={isUpdating}
							/>
							{/* TODO - #theming, will probably need to revisit this for new schools, maybe an "example email"? */}
							<FieldErrorMessage hasError={!!errors[FORM.contactEmail]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.photo}
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Upload a Photo"
							required
							description="Please be aware that your photo will be public."
						>
							<ImageUploadField
								maxWidth={400}
								onChange={onChange}
								value={value}
								disabled={isUpdating}
								validateImageSize={validateImageSize}
							/>
							<FieldErrorMessage hasError={!!errors[FORM.photo]} message={ERRORS.requiredField} />
						</Label>
					)}
				/>
				<Controller
					name={FORM.addingShifts}
					control={control}
					defaultValue={false}
					render={({ field: { value, onChange } }) => (
						<Label
							classnames={{ label: classes.label }}
							title="Adding Shifts? "
							description={`Shifts can be added to volunteer events.
          To make this a volunteer event, add “volunteer” as a category.
          You can't edit shifts after the event has been submitted.`}
						>
							<Toggle
								className={classes.toggleLabel}
								value={value}
								onChange={onChange}
								disabled={!canAddShifts || disableEditShifts || isUpdating}
								label="Add shifts to this volunteer event"
								labelPosition="end"
							/>
						</Label>
					)}
				/>

				<Controller
					name={FORM.postToFeed}
					control={control}
					defaultValue={true}
					render={({ field: { value, onChange } }) => {
						return (
							<Label classnames={{ label: classes.label }} title="Get more views on your event!">
								<CheckBox value={value} label="Post event to the feed" onChange={onChange} />
							</Label>
						)
					}}
				/>

				{!addingShifts && (
					<div className={classes.formFooter}>
						<ColorButton className={classes.submitEventButton} type="submit" disabled={disableSubmitButton}>
							{submitButtonLabel}
						</ColorButton>
						<FieldErrorMessage hasError={formHasErrors} message={ERRORS.fieldsMissing} />
					</div>
				)}
			</Card>
			<Iff
				if={addingShifts}
				render={() => {
					return (
						<Card className={classes.card}>
							<div className={classes.headerBlock}>
								<div className={classes.headerWithIcon}>
									<Typography component="span" className={classes.title} variant="h2">
										Volunteer Shifts
									</Typography>
								</div>
							</div>
							<Controller
								name={FORM.shiftsNumber}
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<Label classnames={{ label: classes.label }} title="How many shifts do you have?" required>
										<TextField
											error={!!errors[FORM.shiftsNumber]}
											className={classes.formInput}
											type="number"
											placeholder="Set Number"
											disabled={disableEditShifts || isUpdating}
											{...field}
										/>
										<FieldErrorMessage hasError={!!errors[FORM.shiftsNumber]} message={errors[FORM.shiftsNumber]?.message as string} />
									</Label>
								)}
							/>
							<Controller
								name={FORM.volunteersPerShift}
								control={control}
								defaultValue={0}
								render={({ field }) => (
									<Label classnames={{ label: classes.label }} title="How many volunteers per shift?" required>
										<TextField
											error={!!errors[FORM.volunteersPerShift]}
											className={classes.formInput}
											type="number"
											placeholder="Set Number"
											disabled={disableEditShifts || isUpdating}
											{...field}
										/>
										<FieldErrorMessage
											hasError={!!errors[FORM.volunteersPerShift]}
											message={errors[FORM.volunteersPerShift]?.message as string}
										/>
									</Label>
								)}
							/>
							{!!(
								shiftsNumber &&
								volunteersPerShift &&
								!errors[FORM.shiftsNumber] &&
								!errors[FORM.volunteersPerShift] &&
								volunteersPerShift > 0 &&
								shiftsNumber > 0 &&
								volunteersPerShift < MAX_VOLUNTEERS_PER_SHIFT &&
								shiftsNumber < MAX_SHIFTS
							) && (
								<Controller
									name={FORM.shifts}
									control={control}
									defaultValue={[]}
									render={({ field: { value, onChange } }) => (
										<Label classnames={{ label: classes.label }} title="Set shift times" required>
											<ShiftsCreationForm
												value={value}
												onChange={onChange}
												shiftsNumber={shiftsNumber}
												volunteersPerShift={volunteersPerShift}
												options={timeOptions}
												disabled={disableEditShifts || isUpdating}
											/>
											<FieldErrorMessage hasError={!!errors[FORM.shifts]} message={ERRORS.requiredField} />
										</Label>
									)}
								/>
							)}
							<div className={classes.formFooter}>
								<ColorButton className={classes.submitEventButton} type="submit" disabled={disableSubmitButton}>
									{submitButtonLabel}
								</ColorButton>
								<FieldErrorMessage hasError={formHasErrors} message={ERRORS.fieldsMissing} />
							</div>
						</Card>
					)
				}}
			/>
		</form>
	)
}

export default EventForm

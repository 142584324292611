import http from '@/api/http/requestHttp'

const deleteVolunteerSubscription = async (eventVolunteerShiftId: string, userId: number, schoolId: number) => {
	const url = `${schoolId}/events/${eventVolunteerShiftId}/volunteerResponse`
	const data = { userId, eventVolunteerShiftId }

	return await http.delete(url, { data })
}

export default deleteVolunteerSubscription

import { InternshipsState } from '@/store/internships/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/internships/constants'
import { SearchConfig } from '@/interfaces/common'

const selectState = (state: { [MODULE_NAME]: InternshipsState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getIsLoadingUserInternships = createSelector(selectState, (state) => state.isLoadingUserInternships)
export const getIsLoadingIndividualInternship = createSelector(selectState, (state) => state.isLoadingIndividualInternship)

export const getInternships = createSelector(selectState, (state) => state.internships)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)
export const getUserInternships = createSelector(selectState, (state) => state.userInternships)
export const getSelectedInternship = createSelector(selectState, (state) => state.selectedInternship)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)
export const getInternshipsById = (id?: string) =>
	createSelector(selectState, (state) => state.internships.find((internships) => internships.id === id))

import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback } from 'react'

import { AddToCalendarOutlinedIcon } from '@/components'
import ShareButton from '@/components/Buttons/ShareButton'
import Card from '@/components/IndividualPage/Card'
import DateItemAboutRow from '@/components/IndividualPage/DateItemAboutRow'
import { PATHS } from '@/constants'
import { SharedData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { openAddToCalendarModal } from '@/store/events'
import { default as IAddress } from '../types/Address'
import DtoEvent from '../types/dto/DtoEvent'
import { Address } from './Address'
import EventOrganizations from './EventOrganizations'
import EventRsvp from './rsvp/EventRsvp'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		margin: '1vh 0 3vh 0',
	},
	icon: {
		height: '10%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 5,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px',
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	addToCalendarBtn: {
		minWidth: 50,
		width: 50,
		height: 50,
		borderRadius: 7,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark ?? theme.palette.primary.main,
		},
	},
	feedbackButton: {
		minWidth: 50,
		height: 50,
		marginLeft: 'auto',
	},
	rsvpBtnContainer: {
		width: '60%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
}))

interface EventTitleCardProps {
	name: string
	description: string
	startDate: string
	endDate: string
	address?: IAddress
	organizations?: DtoEvent['organizationIds']
	eventId: DtoEvent['id']
	sharedData?: SharedData
	pastEvent: boolean
	rsvpSettings?: DtoEvent['rsvpSettings']
	userResponse?: DtoEvent['response']
}

const EventTitleCard = ({
	name,
	description,
	startDate,
	endDate,
	address,
	organizations,
	eventId,
	sharedData = {},
	rsvpSettings,
	userResponse,
	pastEvent,
}: EventTitleCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const { name: locationName } = address

	const sharingUrl = PATHS.APP.EVENTS_SINGLE(eventId)

	const handleOpenAddToCalendarModal = useCallback(() => {
		dispatch(
			openAddToCalendarModal({
				isOpen: true,
				name,
				startDate,
				endDate,
				description,
			}),
		)
	}, [description, dispatch, endDate, name, startDate])

	return (
		<Card>
			<Typography className={classes.title} variant="h2">
				{name}
			</Typography>
			<div className={classes.infoContainer}>
				<div>
					<DateItemAboutRow startDate={startDate} endDate={endDate} />
					{locationName && <Address {...address} />}
					<EventOrganizations orgIds={organizations} />
				</div>
				<div>
					<Button className={classes.addToCalendarBtn} variant="contained" onClick={handleOpenAddToCalendarModal}>
						<AddToCalendarOutlinedIcon />
					</Button>
				</div>
			</div>
			<div className={classes.buttonsContainer}>
				<div className={classes.rsvpBtnContainer}>
					{rsvpSettings && <EventRsvp pastEvent={pastEvent} response={userResponse} eventId={eventId} settings={rsvpSettings} />}
				</div>
				<ShareButton className={classes.feedbackButton} sharedData={sharedData} sharingUrl={sharingUrl} />
			</div>
		</Card>
	)
}

export default EventTitleCard

import { ColorButton, DotButton, ModalWithSubmittedPopup, MoreButton } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CannotUnfollowPopper from '@/components/IndividualPage/CannotUnfollowPopper'
import CancelRequestModal from '@/components/UserNetwork/CancelRequestModal'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { useAppDispatch } from '@/store'
import {
	cancelMembershipRequestRequest,
	createInfoRequest,
	createOrganizationMembershipRequestRequest,
	getLeaveFromOrganizationModal,
	getPendingUserRequests,
	getUserOrganizations,
	setLeaveFromOrganizationModal,
	setOrganizationResponseRequest,
} from '@/store/organizations'
import { updateForm } from '@/store/pathBuilder'
import { getOrganizationType } from '@/utils/organizationRoles'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CancelOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { UserResponseStatuses } from '@/interfaces/common'
import { FEATURE } from '@navengage/sen-shared-assets'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	boldText: {
		fontWeight: 700,
	},
	item: {
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 10,
		padding: 15,
		marginBottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	orgInfo: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	itemsContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		marginTop: '2vh',
		width: '100%',
	},
	logo: {
		width: 35,
		height: 35,
		borderRadius: 5,
	},
	organizationNameText: {
		fontSize: 15,
	},
	categoriesText: {
		fontSize: 15,
		color: theme.palette.text.secondary,
	},
	container: {
		margin: '0 10px',
	},
	button: {
		marginLeft: 5,
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	outlinedButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},
	outlinedButtonGrey: {
		color: theme.palette.text.primary,
		borderColor: theme.palette.border.bold,
	},
}))

interface OrganizationsListProps {
	title?: string
	items?: {
		label: string
		value: string
		categories: string[]
		photoUrl: string
	}[]
	showButtons?: boolean
}

const OrganizationsList = ({ title, items, showButtons }: OrganizationsListProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const { followed, member, admin, superAdmin } = useSelector(getUserOrganizations)
	const pendingRequests = useSelector(getPendingUserRequests)
	const leaveOrganizationModalState = useSelector(getLeaveFromOrganizationModal)

	const [{ orgId, isOpenRequestInformationModal, isOpenRequestToJoinModal, isOpenCancelRequestModal }, setState] = useState({
		orgId: '',
		isOpenRequestInformationModal: false,
		isOpenRequestToJoinModal: false,
		isOpenCancelRequestModal: false,
	})

	const [unfollowAnchorEl, setUnfollowAnchorEl] = useState<any>(null)
	const [orgRole, setOrgRole] = useState<ORGANIZATION_ROLE | null>(null)

	const handleToggleUnfollowPopper = (role: ORGANIZATION_ROLE | null) => (event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
		event.stopPropagation()

		if (unfollowAnchorEl) {
			setOrgRole(null)
			setUnfollowAnchorEl(null)
		} else {
			setOrgRole(role)
			setUnfollowAnchorEl(event.currentTarget)
		}
	}

	const handleCloseUnfollowPopper = () => {
		setUnfollowAnchorEl(null)
	}

	const handleChangeFollowStatus = (id: string, isFollowing: boolean) => () => {
		dispatch(
			setOrganizationResponseRequest({
				id,
				status: isFollowing ? UserResponseStatuses.cleared : UserResponseStatuses.followed,
			}),
		)
	}

	const handleCancelRequest = () => {
		dispatch(cancelMembershipRequestRequest(orgId))
	}

	const handleRemoveOrg = (id: string) => () => {
		dispatch(
			updateForm({
				organizationsWouldLikeToJoin: items?.filter(({ value }) => value !== id),
			}),
		)
	}

	const handleOpenCancelRequestModal = (id: string) => () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
			orgId: id,
		}))
	}

	const handleCloseCancelRequestModal = () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
			orgId: '',
		}))
	}

	const handleCreateRequestToJoin = () => {
		dispatch(
			createInfoRequest({
				orgId,
				membershipTypeId: getOrganizationType(ORGANIZATION_ROLE.MEMBER),
			}),
		)
	}

	const handleClaimMembership = () => {
		dispatch(
			createOrganizationMembershipRequestRequest({
				orgId,
				membershipTypeId: getOrganizationType(ORGANIZATION_ROLE.MEMBER),
			}),
		)
	}

	const handleToggleLeaveFromOrganizationModal = (orgId: string) => (e: any) => {
		e.stopPropagation()

		dispatch(
			setLeaveFromOrganizationModal({
				isOpen: !leaveOrganizationModalState.isOpen,
				orgId,
			}),
		)
	}

	const handleCloseModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRequestToJoinModal: false,
			isOpenRequestInformationModal: false,
			orgId: '',
		}))
	}

	if (!items?.length) return null

	return (
		<div className={classes.root}>
			{orgRole && <CannotUnfollowPopper anchorEl={unfollowAnchorEl} handleClose={handleCloseUnfollowPopper} userRole={orgRole} />}
			<CancelRequestModal
				isOpenModal={isOpenCancelRequestModal}
				handleCloseModal={handleCloseCancelRequestModal}
				onSubmit={handleCancelRequest}
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenRequestInformationModal}
				onSubmit={handleCreateRequestToJoin}
				onClose={handleCloseModal}
				title="Request Information"
				content={[
					'Want to learn more about this organization?',
					'Notify the organization by requesting information.',
					'They’ll receive an email about your request',
				]}
				submittedMessage={['We sent an email to the Primary ', 'Contact of this organization.']}
				submitButtonLabel="Request Information"
			/>
			<ModalWithSubmittedPopup
				isOpen={isOpenRequestToJoinModal}
				onSubmit={handleClaimMembership}
				onClose={handleCloseModal}
				title="Request To Join"
				content={[
					'If you’d like to join this organization, or if you’re already a member and would like to claim membership, please continue. This organization’s admins will be notified to assist you.',
				]}
				submittedMessage={['You’ve requested to join.']}
				submitButtonLabel="Request to Join"
			/>
			{title && <Typography className={classes.boldText}>{title}</Typography>}
			<div className={classes.itemsContainer}>
				{items[0]?.label && // Work around to prevent crashing path builder page
					items?.map((item) => {
						const categories = item.categories.join(', ')
						const userRequestedToJoin = pendingRequests[item.value]?.length
						const isMember = member.some(({ id }) => id === item.value)
						const isAdmin = admin.some(({ id }) => id === item.value)
						const isSuperAdmin = superAdmin.some(({ id }) => id === item.value)
						const isFollowing = followed.some(({ id }) => id === item.value)

						const userRole = isMember
							? ORGANIZATION_ROLE.MEMBER
							: isAdmin
							? ORGANIZATION_ROLE.ADMIN
							: isSuperAdmin
							? ORGANIZATION_ROLE.SUPER_ADMIN
							: null

						return (
							<span key={item.value} className={classes.item}>
								<div className={classes.orgInfo}>
									{item.photoUrl ? (
										<IconWrapper className={classes.logo} iconKey={FEATURE.organizations} />
									) : (
										<img className={classes.logo} src={item.photoUrl} alt="organization logo" />
									)}

									<div className={classes.container}>
										<Typography className={classes.organizationNameText}>{item.label}</Typography>
										<Typography className={classes.categoriesText}>{categories}</Typography>
									</div>
								</div>
								{showButtons && (
									<div className={classes.orgInfo}>
										{!isFollowing ? (
											<ColorButton className={classes.button} onClick={handleChangeFollowStatus(item.value, isFollowing)}>
												Follow
											</ColorButton>
										) : (
											<ColorButton
												className={classes.button}
												color={ButtonColors.PRIMARY}
												onClick={
													isFollowing && userRole ? handleToggleUnfollowPopper(userRole) : handleChangeFollowStatus(item.value, isFollowing)
												}
											>
												Following
											</ColorButton>
										)}
										{!userRole ? (
											<>
												{!userRequestedToJoin ? (
													<MoreButton
														color={ButtonColors.WHITE}
														className={classNames(classes.button, classes.outlinedButton)}
														renderButtons={[
															{
																label: 'Request information',
																icon: null,
																onClick: () => {
																	setState((prev) => ({
																		...prev,
																		isOpenRequestInformationModal: true,
																		orgId: item.value,
																	}))
																},
															},
															{
																label: 'Request to join',
																icon: null,
																onClick: () => {
																	setState((prev) => ({
																		...prev,
																		isOpenRequestToJoinModal: true,
																		orgId: item.value,
																	}))
																},
															},
														]}
														variant="outlined"
														hideIcon
													>
														Request to Join
													</MoreButton>
												) : (
													<ColorButton
														onClick={handleOpenCancelRequestModal(item.value)}
														color={ButtonColors.PRIMARY}
														className={classes.button}
													>
														Member Request Sent
													</ColorButton>
												)}
											</>
										) : (
											<MoreButton
												color={ButtonColors.WHITE}
												className={classNames(classes.button, classes.outlinedButtonGrey)}
												renderButtons={[
													{
														label: 'Leave organization',
														onClick: handleToggleLeaveFromOrganizationModal(item.value),
														icon: <CancelOutlined />,
													},
												]}
												variant="outlined"
												hideIcon
											>
												{userRole}
											</MoreButton>
										)}
										<DotButton
											variant="secondary"
											className={classes.moreButton}
											renderButtons={[
												{
													label: 'Remove',
													onClick: handleRemoveOrg(item.value),
													icon: null,
												},
											]}
											showReportButton={false}
										/>
									</div>
								)}
							</span>
						)
					})}
			</div>
		</div>
	)
}

export default OrganizationsList

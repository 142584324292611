import { TextButton } from '@/components/Buttons'
import ColorButton from '@/components/Buttons/ColorButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import SearchMessagesList from '@/components/Messages/SearchMessagesList'
import Popover from '@/components/Popover'
import { PATHS, STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { setShowCreateGroupModal } from '@/store/groups'
import {
	clearAddNewUserToMessagesListInput,
	getChatsList,
	loadChatsListRequest,
	selectChatListIsLoading,
	setShowSelectUserInput,
} from '@/store/messages'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import AddMessageButton from './AddMessageButton'

const useStyles = makeStyles((theme) => ({
	popperRoot: {
		marginTop: 5,
		maxWidth: 390,
		minWidth: '32vw',
		boxShadow: 'none',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: 8,
		maxHeight: 'none',
	},
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '1.5vh 7%',
		marginBottom: 10,
	},
	rootContainer: {
		width: '100%',
		height: `calc(90vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
	},
	placeholderContainer: {
		width: '100%',
		padding: 20,
	},
	placeholderTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 10,
	},
	placeholder: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonsContainer: {
		marginTop: 15,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		padding: '10px 20px',
		marginTop: 10,
		minWidth: 200,
	},
	addMessageButton: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		margin: '30px 20px',
		width: 60,
		height: 60,
	},
	list: {
		maxHeight: `calc(90vh - ${STYLES.MAIN_HEADER_HEIGHT}vh - ${STYLES.LANDING_FOOTER_HEIGHT}vh)`,
	},
	popperContent: {
		padding: '1vh 0',
	},
}))

interface MessagesPopperProps {
	anchorEl: any
	handleClose: () => void
}

const MessagesPopper = ({ anchorEl, handleClose = () => {} }: MessagesPopperProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const messagesList = useSelector(getChatsList)
	const isLoading = useSelector(selectChatListIsLoading)

	const handleOpenNetwork = useCallback(() => {
		push(PATHS.APP.NETWORK_SEARCH)
		handleClose()
	}, [push, handleClose])

	const handleClearUserData = useCallback(() => {
		push(PATHS.APP.MESSAGES)
		dispatch(clearAddNewUserToMessagesListInput())
		handleClose()
	}, [dispatch, handleClose, push])

	const handleClickSeeAll = useCallback(() => {
		handleClose()
		push(PATHS.APP.MESSAGES)
	}, [push, handleClose])

	const handleAddNewMessage = useCallback(() => {
		handleClearUserData()
		dispatch(setShowCreateGroupModal(false))
		dispatch(setShowSelectUserInput(true))
	}, [dispatch, handleClearUserData])

	const handleAddNewGroup = useCallback(() => {
		handleClearUserData()
		dispatch(setShowSelectUserInput(false))
		dispatch(setShowCreateGroupModal(true))
	}, [dispatch, handleClearUserData])

	const showPlaceholder = !messagesList?.length

	const open = !!anchorEl

	useEffect(() => {
		if (open) {
			dispatch(loadChatsListRequest())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<Popover
			anchorEl={anchorEl}
			handleClose={handleClose}
			boxShadow={false}
			classnames={{
				container: classes.popperContent,
				paper: classes.popperRoot,
			}}
		>
			{showPlaceholder && !isLoading ? (
				<div className={classes.placeholderContainer}>
					<div className={classes.placeholderTitleContainer}>
						<Typography className={classes.title} variant="h3">
							Chats
						</Typography>
					</div>
					<div className={classes.placeholder}>
						<Typography>You do not have any messages. Start</Typography>
						<Typography>up a conversation with anyone in your</Typography>
						<Typography>network!</Typography>
						<div className={classes.buttonsContainer}>
							<ColorButton className={classes.button} onClick={handleAddNewMessage}>
								New Message
							</ColorButton>
							<OutlinedButton className={classes.button} onClick={handleOpenNetwork}>
								See Network
							</OutlinedButton>
						</div>
					</div>
				</div>
			) : (
				<div className={classes.rootContainer}>
					<div className={classes.titleContainer}>
						<Typography className={classes.title} variant="h2">
							Messages
						</Typography>
						<TextButton onClick={handleClickSeeAll}>See All</TextButton>
					</div>
					<SearchMessagesList isLoading={isLoading} onSelectMessage={handleClose} listClassName={classes.list} />
					<AddMessageButton
						className={classes.addMessageButton}
						onStartDirectMessage={handleAddNewMessage}
						onStartNewGroup={handleAddNewGroup}
					/>
				</div>
			)}
		</Popover>
	)
}

export default MessagesPopper

import { DtoOrganization } from '../types/dto/DtoOrganization'
import { useGetOrganizationFollowersQuery } from '../api'

const useGetOrganizationFollowerCount = (id: DtoOrganization['id']) => {
	const { data, ...rest } = useGetOrganizationFollowersQuery({ id }, { refetchOnMountOrArgChange: true })
	const totalFollowers = data?.totalItems || 0
	return {
		totalFollowers,
		...rest,
	}
}

export default useGetOrganizationFollowerCount

import http from '@/api/http/requestHttp'
import { CommonUserConnectionParams } from './types'

const deleteUserConnection = async ({ userId, targetUserId, schoolId }: CommonUserConnectionParams) => {
	const url = `${schoolId}/network/${userId}/disconnect/${targetUserId}`

	return http.delete(url)
}

export default deleteUserConnection

import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Link } from '@material-ui/core'

import useGlobalStyles from '@/styles/globalStyles'

export type ISocialLinksProps = {
	items: Array<{ label: string; icon: ReactNode; href: string }>
	className?: string
}

const SocialLinks: React.FC<ISocialLinksProps> = ({ items, className }) => {
	const globalClasses = useGlobalStyles()
	return (
		<ul className={classNames(globalClasses.inlineList, className)}>
			{items.map(({ href, label, icon }) => (
				<li key={href}>
					<Link underline="always" aria-label={label} href={href} target="_blank" color="inherit">
						{icon ? icon : label}
					</Link>
				</li>
			))}
		</ul>
	)
}

export { SocialLinks }

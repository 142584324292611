import { UserInfo } from '@/interfaces/user'
import { OnboardingSteps } from '@navengage/sen-shared-assets'
import mitt, { Emitter, WildcardHandler } from 'mitt'

export enum WebViewActions {
	signIn = 'SIGN_IN',
	signUp = 'SIGN_UP',
}

export type WebviewEvents = {
	login: {
		status: 'failed' | 'success'
		data: {
			schoolId?: number
			token: string
			refreshToken?: string
			accountId: string
			user: UserInfo
		} | null
		onboarding: boolean
	}
	signup: {
		status: 'completed' | 'inProgress' | 'failed'
		step?: `${OnboardingSteps}`
		data?: {
			schoolId?: number
			token: string
			refreshToken?: string
			accountId: string
			user: UserInfo
		}
	}
}

const handler: WildcardHandler<WebviewEvents> = (type, event) => {
	console.info('Event fired:', type)
	console.info('sending event:', event)
	if (window.ReactNativeWebView) {
		console.info('data sent', event)
		window.ReactNativeWebView.postMessage(JSON.stringify(event))
	}
}

let webviewEmitter: Emitter<WebviewEvents> = null

export const getWebviewHelper = () => {
	if (!webviewEmitter) {
		webviewEmitter = mitt<WebviewEvents>()
		webviewEmitter.on('*', handler)
	}
	return { emitter: webviewEmitter, isWebview: !!window.ReactNativeWebView }
}

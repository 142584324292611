import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { CheckBox, ColorButton, Modal } from '@/components'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useAppDispatch } from '@/store'
import { acceptNewTermsRequest, getIsOpenAcceptNewTermsPopup, getLatestPrivacyPolicy, getLatestTermsAndConditions } from '@/store/auth'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		textAlign: 'center',
		marginBottom: 15,
	},
	textContainer: {
		marginTop: 5,
		width: '75%',
		textAlign: 'center',
	},
	card: {
		width: 650,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '95vw',
			minWidth: 250,
		},
	},
	checkboxContainer: {
		display: 'flex',
		margin: '25px 0',
		width: '75%',
	},
	button: {
		width: 220,
		height: 50,
		margin: '5px 0',
	},
	link: {
		color: theme.palette.text.primary,
		textDecoration: 'underline',
	},
}))

const NewTermsAndConditionsPopup = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const isOpen = useSelector(getIsOpenAcceptNewTermsPopup)
	const termsAndConditions = useSelector(getLatestTermsAndConditions)
	const privacyPolicy = useSelector(getLatestPrivacyPolicy)

	const {
		school: { shortName, applicationName },
	} = useSchoolConfig()

	const [checked, setChecked] = useState(false)

	const handleClickCheckbox = () => setChecked(!checked)

	const handleAcceptTerms = () => dispatch(acceptNewTermsRequest())

	return (
		<Modal isOpen={isOpen}>
			<div className={classes.card}>
				<Typography className={classes.title} variant="h1">
					Terms of Use Update
				</Typography>
				<Typography className={classes.textContainer} variant="body1">
					Our&nbsp;
					<b>
						<Link className={classes.link} href={termsAndConditions?.externalUrl} target="_blank">
							Terms of Use
						</Link>
					</b>
					&nbsp; and&nbsp;
					<b>
						<Link className={classes.link} href={privacyPolicy?.externalUrl} target="_blank">
							Privacy Policy
						</Link>
					</b>
					&nbsp; have recently been updated. To continue, please review and accept the new terms.
				</Typography>
				<div className={classes.checkboxContainer}>
					<CheckBox
						value={checked}
						label={
							<Typography variant="subtitle1">
								I agree to the Terms of Use and Privacy Policy, and to use {applicationName} in accordance with all {shortName} policies.
							</Typography>
						}
						onChange={handleClickCheckbox}
					/>
				</div>
				<ColorButton className={classes.button} onClick={handleAcceptTerms} disabled={!checked}>
					Continue
				</ColorButton>
			</div>
		</Modal>
	)
}

export default NewTermsAndConditionsPopup

import { createEntityAdapter } from '@reduxjs/toolkit'

import { invalidateEntityTags, onboardingApi } from '../onboarding/api'
import EntityScope from '../onboarding/types/EntityScope'
import EntityTypes from '../shareEntity/types/EntityTypes'
import { DtoMajor } from './types/DtoMajor'

const API_BASE_URL = '/majors'

export const majorsAdapter = createEntityAdapter<DtoMajor>()

export const majorsApi = onboardingApi
	.enhanceEndpoints({
		addTagTypes: ['majors'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			createMajors: builder.mutation<
				{ entities: DtoMajor[] },
				{
					entityType?: string
					name?: string
					schoolId: number
					dataSource?: string
					entities?: DtoMajor[]
				}
			>({
				query: ({ entityType, schoolId, dataSource, name, entities }) => ({
					url: `${API_BASE_URL}`,
					method: 'POST',
					body: {
						...(entities ? { entities } : { name, entityType }),
						dataSource,
						schoolId,
					},
				}),
				invalidatesTags: invalidateEntityTags(EntityTypes.major, EntityScope.Standard),
			}),
			deleteMajors: builder.mutation<
				{ entities: string[] },
				{
					entityType: string
					schoolId: number
					entities: string[]
				}
			>({
				query: ({ entityType, schoolId, entities }) => ({
					url: `${API_BASE_URL}`,
					method: 'DELETE',
					params: {
						entityType,
						schoolId,
						entities,
					},
				}),
				invalidatesTags: invalidateEntityTags(EntityTypes.major, EntityScope.Standard),
			}),
		}),
	})

export const { useCreateMajorsMutation, useDeleteMajorsMutation } = majorsApi

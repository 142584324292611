import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { ClockOutlinedIcon, DotButton, IconWrapper, LocationOutlinedIcon, ToggleButton } from '@/components'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import ItemCardContainer from '@/components/ExperienceCards/ItemCardContainer'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import useResponseHandler from '@/features/responses/hooks/useResponseHandler'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { UserResponseStatuses } from '@/interfaces/common'
import useGlobalStyles from '@/styles/globalStyles'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	footerFullWidthButton: {
		height: 50,
		width: 'calc(100% - 60px)',
	},
	contentInfo: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '5px 0',
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	},
	contentInfoIcon: {
		color: theme.palette.primary.main,
		fontSize: '1.25em',
		marginRight: 10,
	},

	contentContainer: {
		justifyContent: 'end',
	},
	title: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 3,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
}))

interface StudyAbroadCardProps {
	photoUrl: string
	title: string
	id: string
	locations?: any[]
	termsOfAvailability?: any[]
	status: UserResponseStatuses
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
}

const StudyAbroadCard = ({
	photoUrl = '',
	title = '',
	locations = [],
	termsOfAvailability = [],
	status,
	id,
	variant = 'primary',
	showActionButtons = true,
	showBadge = false,
}: StudyAbroadCardProps) => {
	const classes = useStyles()
	const globalClasses = useGlobalStyles()
	const { push } = useHistory()

	const isActive = status === UserResponseStatuses.interested
	const locationsLabel = locations.map(({ city, country, continent }) => `${city}, ${country} (${continent})`).join(', ')
	const termsOfAvailabilityLabel = termsOfAvailability.map(({ term }) => term).join(', ')

	const sharingUrl = PATHS.APP.EDUCATION_ABROAD_SINGLE(id)

	const { handleChangeResponse, handleReport } = useResponseHandler('studyAbroad', id)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.EDUCATION_ABROAD_SINGLE(id))
	}, [id, push])

	const handleChangeStatus = useCallback(
		(e: any) => {
			e.stopPropagation()
			handleChangeResponse(isActive ? UserResponseStatuses.cleared : UserResponseStatuses.interested)
		},
		[handleChangeResponse, isActive],
	)

	const handleRenderCardHeader = useCallback(
		() => (
			<>
				<div className={globalClasses.alignCenter}>
					{showBadge ? (
						<CardIconContainer>
							<IconWrapper iconKey={FEATURE.studyAbroad} aria-label="Study Abroad" weight="fill" size={ICON_SIZES.md} />
						</CardIconContainer>
					) : null}
				</div>

				{showActionButtons && <DotButton itemId={id} onSubmitReport={handleReport} reportSubject={ReportSubject.EducationAbroad} />}
			</>
		),
		[globalClasses.alignCenter, handleReport, id, showActionButtons, showBadge],
	)

	const handleRenderCardContent = useCallback(
		() => (
			<>
				{locationsLabel && (
					<div className={classes.contentInfo}>
						<LocationOutlinedIcon className={classes.contentInfoIcon} /> <p>{locationsLabel}</p>
					</div>
				)}
				{termsOfAvailabilityLabel && (
					<div className={classes.contentInfo}>
						<ClockOutlinedIcon className={classes.contentInfoIcon} /> <p>{termsOfAvailabilityLabel}</p>
					</div>
				)}
			</>
		),
		[classes.contentInfo, classes.contentInfoIcon, locationsLabel, termsOfAvailabilityLabel],
	)

	const handleRenderActionButtons = useCallback(
		() => (
			<>
				<ToggleButton className={classes.footerFullWidthButton} onClick={handleChangeStatus} isActive={isActive}>
					{/* Adding this in for future change */}
					{isActive ? 'Interested' : 'Interested'}
				</ToggleButton>
			</>
		),
		[classes.footerFullWidthButton, handleChangeStatus, isActive],
	)

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.studyAbroad } }), [id])

	return variant === 'primary' ? (
		<ItemCardContainer
			showActionButtons={showActionButtons}
			title={title}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	) : (
		<FeedsCardContainer
			showActionButtons={showActionButtons}
			title={title}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	)
}

export default StudyAbroadCard

import { STYLES } from '@/constants'
import { initGroupGallery, removeGroupGalleryImageRequest, selectTargetedGroupGallery } from '@/store/groups'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { GroupChatRoles, IGroupChat } from '@navengage/sen-types'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import SecureImage from '../../SecureImage'
import SideBarTabHeader from './SideBarTabHeader'

export type IGroupGalleryProps = {
	data: IGroupChat
	onClose?: () => void
}

const useStyles = makeStyles((theme) => ({
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
		height: '100%',
	},
	galleryGrid: {
		display: 'flex',
		width: 'calc(100% - 20px)',
		flexWrap: 'wrap',
		flexDirection: 'row',
		padding: 10,
	},
	galleryCols: {
		width: 'calc(33.33333% - 10px)',
		padding: 5,
	},
	galleryItem: {
		width: 'calc(100% - 20)',
		position: 'relative',
		'&::before': {
			content: '""',
			display: 'block',
			paddingTop: '100%',
		},
	},
	galleryImage: {
		height: '100%',
		width: '100%',
		position: 'absolute',
		left: 0,
		top: 0,
		objectFit: 'contain',
		background: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		borderRadius: 7,
	},
	imageWrapper: {
		width: '100%',
		height: '100%',
	},
	deleteIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
		backgroundColor: theme.palette.common.white,
	},
}))

const GroupGallery = ({ data, onClose }: IGroupGalleryProps) => {
	const canDelete = data?.userMemberType === GroupChatRoles.Admin || data?.userMemberType === GroupChatRoles.Owner
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const gallery = useSelector(selectTargetedGroupGallery)
	const deletePhoto = useCallback(
		(messageId: string) => {
			dispatch(removeGroupGalleryImageRequest(messageId))
		},
		[dispatch],
	)
	// Init gallery when targeted group is changed
	useEffect(() => {
		dispatch(initGroupGallery())
	}, [dispatch, data])

	return (
		<div className={classes.groupInformation}>
			<SideBarTabHeader title="Gallery" onCancel={onClose} />
			<div className={classes.galleryGrid}>
				{gallery.images.map(({ messageId, photoUrl }) => (
					<div className={classes.galleryCols} key={messageId}>
						<div className={classes.galleryItem}>
							<SecureImage
								showSkeleton
								containerClass={classes.imageWrapper}
								className={classes.galleryImage}
								key={messageId}
								src={photoUrl}
							/>
							{canDelete && (
								<IconButton onClick={() => deletePhoto(messageId)} size="small" color={'primary'} className={classes.deleteIcon}>
									<CloseIcon fontSize="small" />
								</IconButton>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export { GroupGallery }

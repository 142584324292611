import { AxiosResponse } from 'axios'
import settings from '@/constants/http'
import { useCallback, useEffect, useState } from 'react'

export type PaginationListParams<TParams> = TParams & { limit?: number; page?: number }

export const useInfiniteScrollList = <TApiParams, TResponseItem>(
	params: PaginationListParams<TApiParams>,
	// Second param is request config, which is not needed
	request: (params: PaginationListParams<TApiParams>) => Promise<AxiosResponse<TResponseItem[], any>>,
) => {
	const { limit = settings.SEARCH_FEEDS_LIST_PER_PAGE, page = 1 } = params
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [canLoadMore, setCanLoadMore] = useState<boolean>(true)
	const [items, setItems] = useState<TResponseItem[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [error, setError] = useState<unknown>(null)
	const [resetScrollPosition, setResetScrollPosition] = useState<boolean>(false)

	const loadMore = useCallback(async () => {
		try {
			setIsLoading(true)

			const { data } = await request({ ...params, limit, page: currentPage })

			if (data.length < limit) {
				setCanLoadMore(false)
			}

			setItems((prevItems) => [...prevItems, ...data])

			if (!(data.length === 0 && page > 1)) {
				setCurrentPage((prevPage) => prevPage + 1)
			}
		} catch (e: any) {
			setError(e)
		} finally {
			setIsLoading(false)
		}
	}, [request, params, limit, currentPage, page])

	const refreshItems = useCallback(async () => {
		try {
			setIsLoading(true)
			const { data } = await request({ ...params, limit: items.length ? items.length : limit, page: 1 })
			setItems(data)
		} catch (e: any) {
			setError(e)
		} finally {
			setIsLoading(false)
		}
	}, [items.length, limit, params, request])

	const resetError = useCallback(() => setError(null), [])

	useEffect(() => {
		setCurrentPage(page)
	}, [page])

	useEffect(() => {
		loadMore()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!isLoading) setResetScrollPosition(false)
	}, [isLoading])

	return {
		isLoading,
		resetScrollPosition,
		items,
		error,
		resetError,
		loadMore,
		refreshItems,
		currentPage,
		canLoadMore,
	}
}

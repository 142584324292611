import authStorage from '@/utils/auth/storage/authStorage'
import { getAppUrl } from '@/utils/urlHandlers'
export const NAV_APP_ID = 418
export const PSU_SCHOOL_ID = 1
export const APP_URL = getAppUrl() || 'https://sen.navengage.com/'

export const OAUTH_BASE_URL = `${process.env.REACT_APP_BASE_API_URL}v1.0/auth`

export const REFRESH_TOKEN_EXPIRATION = 365
export const SESSION_TIME = 59 * 60 // 59 seconds * 60 = 59 minutes
export const TOKEN_REFRESH_INTERVAL = SESSION_TIME * 1000

// Other Config
export const SCHOOL_ID = Number(process.env.REACT_APP_SCHOOL_ID || authStorage.getItem('schoolId'))
export const IS_ENGAGE = SCHOOL_ID !== PSU_SCHOOL_ID

export const isEngage = () => SCHOOL_ID !== PSU_SCHOOL_ID
export const isNavApp = () => Number(process.env.REACT_APP_SCHOOL_ID) === NAV_APP_ID

// TODO - in the future, we can move this to sen-shared-assets (already moved just not working)
export const FEEDBACK_URL = 'https://ucroo-platform.atlassian.net/servicedesk/customer/portal/6'
export const PSU_SURVEY_LINK =
	'https://forms.office.com/Pages/DesignPageV2.aspx?prevorigin=shell&origin=NeoPortalPage&subpage=design&id=RY30fNs9iUOpwcEVUm61LgWp3KrBHIBAtanIaE1o3ohUQ0NWV1YyRlJONEtLS1pDWE9BTFdFWFlDQS4u'

export const PHOTO_BASE_URL = isEngage() ? 'https://nav-dev-photo.s3.amazonaws.com' : 'https://psu-engage-prod-photo.s3.amazonaws.com'
export const ABOUT_APP_URL = 'https://www.engage.psu.edu/get-started/' // TODO - #theming - need to add to sen-frontend-lib

// TODO - abstract this to a service, are we integrating with other schools' photo registry?
export const PSU_PHOTO_REGISTRY = 'https://www.absecom.psu.edu/ONLINE_CARD_OFFICE/USER_PAGES/PSU_USER_MENU_WIN.cfm'

// Don't care about yet

export const REQUEST_ADD_ORG_URL = 'https://docs.google.com/forms/d/1aP0jLJpPSNyKxtanDLb9KvSa5Kt_mA1Nfwkq-1dDmAk/edit '
export const REQUEST_ADD_JOB_URL = 'https://pennstate-csm.symplicity.com/employers/?signin_tab=0&signin_tab=0' // #theming - wait to see if other clients use jobs
export const REQUEST_ADD_RESEARCH_URL = 'https://urfm.psu.edu/research/undergraduate-research-opportunities-database' // #theming - wait to see if other clients use researh
export const REQUEST_ADD_EDUCATION_ABROAD_URL = 'https://global.psu.edu/category/developing-and-proposing-program' // #theming - wait to see if other clients use study abroad
export const ORG_CENTRAL_URL = 'https://orgcentral.psu.edu/' //#theming - don't update, won't be used by other clients
export const ORG_CENTRAL_CONTACT_CARD = 'OrgCentral Point of Contact' //#theming - don't need to update, won't be used by other clients

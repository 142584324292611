import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'

import OrganizationCard from '@/components/ExperienceCards/OrganizationCard'

import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import { getEndHasBeenReached, getFilterIsReady, getIsLoading, getOrganizations } from '@/store/organizations'
import { loadOrganizationsListWithFilterRequest } from '@/store/organizations/actions'
import { requestToAddOrganization, submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const DiscoverOrganizations = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const organizations = useSelector(getOrganizations)
	const isLoading = useSelector(getIsLoading)
	const endHasBeenReached = useSelector(getEndHasBeenReached)
	const filterIsReady = useSelector(getFilterIsReady)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) dispatch(loadOrganizationsListWithFilterRequest({ fetchMore: true }))
	}, [dispatch, filterIsReady])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
			{
				variant: 'outlined',
				label: 'Request to add organization',
				onClick: requestToAddOrganization,
			},
		],
		[],
	)

	const showPlaceholder = !canLoadMore && !isLoading && !organizations.length

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="Discover Organizations" />
			{showPlaceholder ? (
				<NotFoundPlaceholder iconKey={FEATURE.organizations} buttons={placeholderButtons}>
					<Typography align="center">Try expanding your search, or utilize one</Typography>
					<Typography align="center">of these links:</Typography>
				</NotFoundPlaceholder>
			) : (
				<ExperienceCardsSection
					placeholder="That’s all for now. Check back soon for more organizations!"
					handleLoadMoreItems={handleLoadMore}
					items={organizations}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, startDate, photoUrl, id, description, categories, followed, canEdit }: any) => (
								<OrganizationCard
									key={`${id}-${name}-${startDate}`}
									id={id}
									title={name}
									photoUrl={photoUrl}
									followed={followed}
									description={description}
									categories={categories}
									canEdit={canEdit}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default DiscoverOrganizations

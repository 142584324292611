import { PATHS } from '@/constants'
import { closeExitConfirmationModal, getExitConfirmationModal } from '@/store/app'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router-dom'
import { AlertAction } from './AlertAction'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: {
		color: theme.palette.primary.main,
	},
}))

const ExitConfirmationModal = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const modalProps = useSelector(getExitConfirmationModal)
	const { push } = useHistory()

	const handleClose = useCallback(() => {
		dispatch(closeExitConfirmationModal())
	}, [dispatch])

	const handleConfirm = useCallback(() => {
		dispatch(closeExitConfirmationModal())
		if (modalProps.onConfirm) modalProps.onConfirm()
		setTimeout(() => push(modalProps.redirectPath || PATHS.APP.EVENTS), 200)
	}, [dispatch, modalProps, push])

	const renderButtons = useMemo(
		() => [
			{
				label: `Yes, leave`,
				onClick: handleConfirm,
				className: classes.acceptButton,
			},
			{
				label: `No, keep working`,
				onClick: handleClose,
			},
		],
		[classes.acceptButton, handleClose, handleConfirm],
	)

	return (
		<AlertAction
			isOpen={modalProps.isOpen}
			buttons={renderButtons}
			title="Are you sure you want to leave?"
			subTitle={modalProps.description ?? 'Your work will not be saved.'}
			handleClose={handleClose}
		/>
	)
}

export default ExitConfirmationModal

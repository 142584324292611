import utils from '@/utils'
import { axiosBaseQuery } from '@/utils/rtkQueryUtils'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

const API_BASE_URL = utils.urlHandler.getBaseUrl()
const BASE_URL = `${API_BASE_URL}`

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: axiosBaseQuery(BASE_URL, true),
	endpoints: (builder) => ({}),
})

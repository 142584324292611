// @ts-nocheck

import * as actions from '@/store/groups/actions'
import { GroupsState } from '@/store/groups/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState: GroupsState = {
	showAddNewGroupModal: false,
	searchGroups: {
		q: '',
		campus: null,
		groups: [],
		page: 1,
		canLoadMore: true,
		isLoading: false,
	},
	searchUsers: {
		users: [],
		page: 1,
		canLoadMore: true,
		isLoading: false,
	},
	searchMembers: {
		members: [],
		page: 1,
		canLoadMore: true,
		isLoading: false,
	},
	searchMemberRequests: {
		requests: [],
		page: 1,
		canLoadMore: true,
		isLoading: false,
	},
	gallery: {
		images: [],
		isLoading: false,
		page: 1,
		canLoadMore: true,
	},
	isCreatingNewGroup: false,
	isOpenDrawer: false,
	isOpenAddNewMembers: false,
}

const groupsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setError, (state, { payload }) => ({
			...state,
			isLoading: false,
			error: payload,
		}))
		.addCase(actions.setShowCreateGroupModal, (state, { payload }) => ({
			...state,
			showAddNewGroupModal: payload,
		}))
		.addCase(actions.createGroupRequest.pending, (state) => ({
			...state,
			isCreatingNewGroup: true,
		}))
		.addCase(actions.createGroupRequest.fulfilled, (state) => ({
			...state,
			isCreatingNewGroup: false,
		}))
		.addCase(actions.createGroupRequest.rejected, (state) => ({
			...state,
			isCreatingNewGroup: false,
		}))
		.addCase(actions.setTargetGroup, (state, { payload }) => ({
			...state,
			targetGroup: payload,
		}))
		.addCase(actions.clearTargetGroup, (state) => ({
			...state,
			targetGroup: undefined,
		}))
		.addCase(actions.updateTargetGroup, (state, { payload }) => ({
			...state,
			targetGroup: { ...state.targetGroup, ...payload },
		}))
		.addCase(actions.searchUsersToInviteRequest.pending, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: true,
			},
		}))
		.addCase(actions.searchUsersToInviteRequest.rejected, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: false,
			},
		}))
		.addCase(actions.searchUsersToInviteRequest.fulfilled, (state) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				isLoading: false,
			},
		}))
		.addCase(actions.setSearchUsers, (state, { payload: { page, canLoadMore, users } }) => ({
			...state,
			searchUsers: {
				...state.searchUsers,
				page,
				canLoadMore,
				users,
			},
		}))
		.addCase(actions.clearSearchUsers, (state) => ({
			...state,
			searchUsers: {
				page: 1,
				canLoadMore: true,
				isLoading: false,
				users: [],
			},
		}))
		.addCase(actions.setSearchGroupsQuery, (state, { payload }) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				q: payload,
				canLoadMore: true,
			},
		}))
		.addCase(actions.setSearchGroupsCampus, (state, { payload }) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				campus: payload,
				canLoadMore: true,
			},
		}))
		.addCase(actions.searchGroupsRequest.pending, (state) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				isLoading: true,
			},
		}))
		.addCase(actions.searchGroupsRequest.rejected, (state) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				isLoading: false,
			},
		}))
		.addCase(actions.searchGroupsRequest.fulfilled, (state) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				isLoading: false,
			},
		}))
		.addCase(actions.setSearchGroups, (state, { payload: { page, canLoadMore, groups } }) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				page,
				canLoadMore,
				groups,
			},
		}))
		.addCase(actions.removeSearchGroupItem, (state, { payload }) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				groups: state.searchGroups.groups.filter(({ id }) => id !== payload),
			},
		}))
		.addCase(actions.updateSearchGroupItem, (state, { payload }) => ({
			...state,
			searchGroups: {
				...state.searchGroups,
				groups: state.searchGroups.groups.map((item) => (item.id === payload ? { ...item, isRequested: true } : item)),
			},
		}))
		.addCase(actions.readGroupMembersRequest.pending, (state) => ({
			...state,
			searchMembers: {
				...state.searchMembers,
				isLoading: true,
			},
		}))
		.addCase(actions.readGroupMembersRequest.rejected, (state) => ({
			...state,
			searchMembers: {
				...state.searchMembers,
				isLoading: false,
			},
		}))
		.addCase(actions.readGroupMembersRequest.fulfilled, (state) => ({
			...state,
			searchMembers: {
				...state.searchMembers,
				isLoading: false,
			},
		}))
		.addCase(actions.setSearchMembers, (state, { payload: { page, canLoadMore, members } }) => ({
			...state,
			searchMembers: {
				...state.searchMembers,
				page,
				canLoadMore,
				members,
			},
		}))
		.addCase(actions.clearSearchMembers, (state) => ({
			...state,
			searchMembers: {
				page: 1,
				canLoadMore: true,
				isLoading: false,
				members: [],
			},
		}))
		.addCase(actions.removeSearchMemberItem, (state, { payload }) => ({
			...state,
			searchMembers: {
				...state.searchMembers,
				members: state.searchMembers.members.filter((m) => m.id !== payload),
			},
		}))
		.addCase(actions.setMemberToView, (state, { payload }) => ({
			...state,
			memberToView: payload,
		}))
		.addCase(actions.readGroupMemberRequestsRequest.pending, (state) => ({
			...state,
			searchMemberRequests: {
				...state.searchMemberRequests,
				isLoading: true,
			},
		}))
		.addCase(actions.readGroupMemberRequestsRequest.rejected, (state) => ({
			...state,
			searchMemberRequests: {
				...state.searchMemberRequests,
				isLoading: false,
			},
		}))
		.addCase(actions.readGroupMemberRequestsRequest.fulfilled, (state) => ({
			...state,
			searchMemberRequests: {
				...state.searchMemberRequests,
				isLoading: false,
			},
		}))
		.addCase(actions.setSearchMemberRequests, (state, { payload: { page, canLoadMore, requests } }) => ({
			...state,
			searchMemberRequests: {
				...state.searchMemberRequests,
				page,
				canLoadMore,
				requests,
			},
		}))
		.addCase(actions.clearSearchMemberRequests, (state) => ({
			...state,
			searchMemberRequests: {
				page: 1,
				canLoadMore: true,
				isLoading: false,
				requests: [],
			},
		}))
		.addCase(actions.updateSearchMemberRequestItem, (state, { payload: { id, status } }) => ({
			...state,
			searchMemberRequests: {
				...state.searchMemberRequests,
				requests: state.searchMemberRequests.requests.map((r) => (r.id === id ? { ...r, status } : r)),
			},
		}))
		.addCase(actions.setDrawer, (state, { payload }) => ({
			...state,
			isOpenDrawer: payload,
		}))
		.addCase(actions.setAddNewMembersPopup, (state, { payload }) => ({
			...state,
			isOpenAddNewMembers: payload,
		}))
		.addCase(actions.updateGroupGallery, (state, { payload }) => ({
			...state,
			gallery: {
				...state.gallery,
				...payload,
			},
		}))
		.addCase(actions.paginateGroupGallery, (state, { payload: { images, ...rest } }) => ({
			...state,
			gallery: {
				...state.gallery,
				images: [...state.gallery.images, ...(images ? images : [])],
				...rest,
			},
		}))
		.addCase(actions.removeGalleryImage, (state, { payload }) => ({
			...state,
			gallery: {
				...state.gallery,
				images: state.gallery.images.filter((image) => image.messageId !== payload),
			},
		}))
})

export { groupsReducer }

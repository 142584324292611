import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import DiscoverInternships from '@/pages/InternshipsPage/DiscoverInternships'
import IndividualInternships from '@/pages/InternshipsPage/IndividualInternship'
import YourInternships from '@/pages/InternshipsPage/YourInternships'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_INTERNSHIPS = createPageTitle('Discover Jobs')
const MY_INTERNSHIPS = createPageTitle('Bookmarked Jobs')

const routes: AppRoute[] = [
	{
		component: IndividualInternships,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.INTERNSHIPS_SINGLE(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourInternships,
		title: MY_INTERNSHIPS,
		name: MY_INTERNSHIPS,
		path: PATHS.APP.INTERNSHIPS_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverInternships,
		title: DISCOVER_INTERNSHIPS,
		name: DISCOVER_INTERNSHIPS,
		path: PATHS.APP.INTERNSHIPS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import http from '@/api/http/requestHttp'

const patchOrganizationContact = async (requestId: number, patchedUserId: number, schoolId: number) => {
	const url = `${schoolId}/organizations/memberships/contact`
	const body = {
		patchedUserId,
		requestId,
	}

	return http.patch(url, body)
}

export default patchOrganizationContact

import { NavTabs } from '../../../components/NavTabs'
import { useEventsControllerGetGridDataQuery, useEventsControllerGetGridMetaQuery } from '../api/eventsApi'
import { AnalyticsFrameWithRoleCheck } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { DataTableWithRoleCheck } from '../components/dataTable/DataTable'
import { Layout } from '../components/Layout'
import { DashboardType } from '../types/dashboardTypes'

const EventsMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Events"
				subHeader="Welcome to the events dashboard! Find information about recent events. Click the “DETAILS” tab to view individual event information."
			>
				<NavTabs
					initialTab={0}
					withHistory
					tabs={[
						{
							label: 'Analytics',
							component: <AnalyticsFrameWithRoleCheck dashboardType={DashboardType.EVENTS} />,
						},
						{
							label: 'Details',
							component: (
								<DataTableWithRoleCheck listQuery={useEventsControllerGetGridDataQuery} metaQuery={useEventsControllerGetGridMetaQuery} />
							),
						},
					]}
				/>
			</CommonCard>
		</Layout>
	)
}

export default EventsMainPage

import { InputAdornment, styled, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ColorButton, FilledTextField, OutlinedButton } from '@/components'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import settings from '@/constants/http'
import { useSearchEntitiesQuery } from '@/features/onboarding/api'
import EntityTypes, { isAddEntityToDBEnabled, isSignupGroup } from '@/features/shareEntity/types/EntityTypes'
import { useAppDispatch } from '@/store'
import { setShowCreateGroupModal } from '@/store/groups'
import { submitFeedback } from '@/utils/services'
import { MagnifyingGlass, Plus, X } from '@phosphor-icons/react'
import { useHistory } from 'react-router-dom'
import { RequireExactlyOne } from 'type-fest'
import { generateDynamicLabel } from '../utils/getTypeLabel'
import { SearchEntitiesModalProps } from './AdminModalContent'
import { CreateNewEntityForm, NewEntityFormState } from './dataTable/CreateNewEntity'
import SearchEntitiesListRow from './SearchEntitiesListRow'

// @TODO: Start pulling out styled components into common directory for reuse
const Root = styled('div')(({ theme }) => ({
	width: STYLES.FILL_AVAILABLE_WIDTH,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: 20,
}))

const Container = styled('div')(({ theme }) => ({
	width: STYLES.FILL_AVAILABLE_WIDTH,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}))

const ButtonContainer = styled('div')(({ theme }) => ({
	width: STYLES.FILL_AVAILABLE_WIDTH,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-end',
}))

const Header = styled('div')(({ theme }) => ({
	width: STYLES.FILL_AVAILABLE_WIDTH,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-around',
}))

const ClearInputButton = styled(InputAdornment)({
	cursor: 'pointer',
})

const SearchResultContainer = styled('div')(({ theme }) => ({
	width: STYLES.FILL_AVAILABLE_WIDTH,
	height: 350,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	margin: '15px 0',
}))

export const Row = styled('div')(({ theme }) => ({
	padding: 10,
	borderRadius: 5,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.grey[100],
	},
}))

const SearchInput = styled(FilledTextField)({
	width: STYLES.FILL_AVAILABLE_WIDTH,
})

const SubmitButton = styled(ColorButton)({
	minWidth: 200,
	margin: '0 15px',
})

type EntityIdOrName = RequireExactlyOne<{ name: string; id: string }, 'id' | 'name'>

export type SearchEntitiesProps = Omit<SearchEntitiesModalProps, 'setModalState'> & {
	onSubmit: (entities: (string | EntityIdOrName)[]) => void
	handleClose: () => void
}

const TAKE = settings.ADMIN_DASHBOARD_PANEL_PAGE_SIZE

const SearchEntities = ({ onSubmit, placeholder, entityType, entityScope, entityName }: SearchEntitiesProps) => {
	const { push } = useHistory()
	const dispatch = useAppDispatch()
	const [searchBarFocused, setSearchBarFocused] = useState(false)
	const [resetScrollPosition, setResetScrollPosition] = useState(false)

	const [newEntities, setNewEntities] = useState<NewEntityFormState[]>([])
	const createsNewEntity = useMemo(() => isAddEntityToDBEnabled(entityType), [entityType])

	const [searchValue, setSearchValue] = useState('')
	const [selectionsToAdd, setSelectionsToAdd] = useState<string[]>([])

	const { palette } = useTheme()

	const [skip, setSkip] = useState<number>(0)
	const { isLoading, data } = useSearchEntitiesQuery({
		entityType,
		entityScope,
		skip,
		take: TAKE,
		q: searchValue,
	})
	const [canLoadMore, setCanLoadMore] = useState(false)

	useEffect(() => setCanLoadMore(data?.items.length < data?.totalItems), [data])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() =>
			createsNewEntity
				? []
				: [
						{
							variant: 'outlined',
							label: 'Submit Feedback',
							onClick: submitFeedback,
						},
				  ],
		[createsNewEntity],
	)

	const handleSetFocus = useCallback(
		(val: boolean) => () => {
			setSearchBarFocused(val)
		},
		[],
	)

	const handleClearSearchInput = useCallback(() => {
		setSearchValue('')
	}, [])

	const handleChangeSearchInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (value && !value.trim()) return handleClearSearchInput()
			setSkip(0)
			resetScrollPosition && setResetScrollPosition(false)
			setSearchValue(value)
		},
		[handleClearSearchInput, resetScrollPosition],
	)

	const handleLoadMore = useCallback(() => {
		setSkip(data?.items.length)
	}, [data, setSkip])

	const isItemSelected = (id: string) => selectionsToAdd.some((entityId) => entityId === id)
	const toggleSelected = (id: string) => {
		if (isItemSelected(id)) {
			setSelectionsToAdd((prev) => prev.filter((entityId) => entityId !== id))
		} else {
			setSelectionsToAdd((prev) => [...prev, id])
		}
	}

	const handleSubmit = useCallback(() => {
		let cleanedEntities: EntityIdOrName[] = selectionsToAdd.map((id) => ({ id }))

		if (createsNewEntity) {
			cleanedEntities.push(...newEntities.filter((e) => !!e.name.length).map(({ name }) => ({ name })))
		}

		onSubmit(cleanedEntities)
	}, [createsNewEntity, newEntities, selectionsToAdd, onSubmit, entityType])

	const isSubmitDisabled = useMemo(
		() => !selectionsToAdd.length && !(createsNewEntity && newEntities.length && newEntities.every(({ isValid }) => isValid)),
		[createsNewEntity, selectionsToAdd, newEntities],
	)

	const submitLabel = useMemo(
		() =>
			generateDynamicLabel({
				prefix: 'Add',
				subj: entityName,
				count: newEntities.length + selectionsToAdd.length,
			}),
		[createsNewEntity, newEntities, selectionsToAdd, entityName],
	)

	const handleNewEntitiesFormChange = useCallback(
		(index: number) => (entityState: NewEntityFormState) => {
			setNewEntities((prev) => {
				const newEntities = [...prev]
				newEntities[index] = entityState
				return newEntities
			})
		},
		[],
	)

	const handleAddNewClick = () => {
		if (isSignupGroup(entityType)) {
			dispatch(setShowCreateGroupModal(true))
			push(PATHS.APP.MESSAGES_LIST)
		} else setNewEntities((prevEntities) => [...prevEntities, { name: '', isValid: false }])
	}

	const showNoResultFoundPlaceholder = useMemo(() => !isLoading && !data && !canLoadMore, [isLoading, data, canLoadMore])

	return (
		<Root>
			<Container>
				<Header>
					<SearchInput
						onChange={handleChangeSearchInput}
						value={searchValue}
						placeholder={placeholder || 'Search'}
						inputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<MagnifyingGlass
										style={{
											color: searchBarFocused ? palette.text.primary : palette.text.secondary,
										}}
										size={24}
									/>
								</InputAdornment>
							),
							endAdornment: searchValue ? (
								<ClearInputButton position="end" onClick={handleClearSearchInput}>
									<X
										style={{
											color: searchBarFocused ? palette.text.primary : palette.text.secondary,
										}}
										size={24}
									/>
								</ClearInputButton>
							) : undefined,
						}}
						onFocus={handleSetFocus(true)}
						onBlur={handleSetFocus(false)}
					/>
				</Header>
				<SearchResultContainer>
					{showNoResultFoundPlaceholder && !newEntities.length ? (
						<NotFoundPlaceholder buttons={placeholderButtons} showNothingSeemsToMatchMessage={false} />
					) : (
						<InfiniteScrollComponent
							onLoadMore={handleLoadMore}
							dataLength={data?.items.length ?? 0}
							canLoadMore={canLoadMore}
							resetScroll={resetScrollPosition}
							internalScroll
							sx={{
								display: 'grid',
								gridTemplateColumns: `repeat(auto-fill, 100%)`,
								gridGap: 0,
								margin: 0,
							}}
						>
							{newEntities.map((entityForm, i) => (
								<CreateNewEntityForm entityName={entityName} handleInputChange={handleNewEntitiesFormChange(i)} />
							))}
							{data?.items.map((item) => {
								return (
									<SearchEntitiesListRow
										key={`search-result-${item.id}`}
										existsInSignupEntity={item.existsInSignupEntity}
										isSelected={isItemSelected(item.id)}
										entity={item}
										showImage={
											![
												EntityTypes.opportunities,
												EntityTypes.experience,
												EntityTypes.interest,
												EntityTypes.major,
												EntityTypes.academicCollege,
											].includes(entityType)
										}
										entityType={entityType}
										handleClick={toggleSelected}
									/>
								)
							})}
						</InfiniteScrollComponent>
					)}
				</SearchResultContainer>
			</Container>
			<ButtonContainer>
				{createsNewEntity && (
					<OutlinedButton onClick={handleAddNewClick}>
						<Plus /> New
					</OutlinedButton>
				)}
				<SubmitButton disabled={isSubmitDisabled} onClick={handleSubmit}>
					{submitLabel}
				</SubmitButton>
			</ButtonContainer>
		</Root>
	)
}

export default SearchEntities

import IconButton from '@/components/Buttons/IconButton'
import { STYLES } from '@/constants'
import { CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { getShortCounterLabel } from '@navengage/sen-shared-assets'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	infoBlock: {
		width: 105,
		height: 91,
		borderRadius: 5,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default,
		border: `solid 1px ${theme.palette.border.bold}`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		...STYLES.BOX_SHADOW,
		'&:last-child': {
			marginRight: 0,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	infoBlockValue: {
		fontWeight: 700,
	},
	infoButton: {
		padding: 0,
		height: 93,
	},
	infoBlockActive: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.primary.light,
		border: `solid 1px ${theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.divider}`,
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	smallText: {
		fontSize: 14,
	},
}))

export type IInfoBadgeProps = {
	active?: boolean
	count?: number
	label: string
	isLoading?: boolean
	onClick?: () => void
}

const InfoBadge: React.FC<IInfoBadgeProps> = ({ active = false, count, label, onClick, isLoading }) => {
	const classes = useStyles()
	return (
		<IconButton className={classNames(classes.infoButton, classes.infoBlock, active ? classes.infoBlockActive : '')} onClick={onClick}>
			{isLoading ? (
				<CircularProgress />
			) : (
				<>
					<Typography className={classes.infoBlockValue} variant="body1">
						{getShortCounterLabel(count ?? 0)}
					</Typography>
					<Typography className={label.length > 9 ? classes.smallText : ''}>{label}</Typography>
				</>
			)}
		</IconButton>
	)
}

export default InfoBadge

import { TextField } from '@/components'
import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { TextCheckBox } from '@/components/widgets'
import { getWithCustomAnswerSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 20vw)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 80%)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		margin: 0,
	},
	header: {
		width: '40%',
	},
	input: {
		margin: '3vh 0',
	},
}))

const options = [
	{
		label: 'Full-Time Employment',
		value: 'Full-Time Employment',
	},
	{
		label: 'Graduate School',
		value: 'Graduate School',
	},
	{
		label: 'Law School',
		value: 'Law School',
	},
	{
		label: 'Medical School',
		value: 'Medical School',
	},
	{
		label: 'Military Service',
		value: 'Military Service',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

const Question4 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const { schema, defaultValues } = getWithCustomAnswerSchema(dataKey, data, pathBuilderForm, 'string', false)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.header,
					}}
					title="What are your goals for
      after graduation?"
					subTitle="Choose one."
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={`${dataKey}.answers`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									options.map((item) => (
										<div key={item.label}>
											<TextCheckBox
												key={item.value}
												label={item.label}
												value={item.value}
												onChange={handleSelectItem}
												checked={isChecked(item.value)}
											/>
											{item.label === 'Other' && value?.includes('Other') && (
												<div>
													<Controller
														name={`${dataKey}.otherAnswer`}
														control={control}
														render={({ field }) => (
															<TextField
																className={classes.input}
																placeholder="What would you like to do?"
																placeholderColor={appTheme.palette.text.secondary}
																{...field}
															/>
														)}
													/>
												</div>
											)}
										</div>
									))
								}
								canSelectMoreThanOne={false}
							/>
						)}
					/>
				</StepperCard>
			}
		/>
	)
}

export default Question4

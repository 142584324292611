import { useMemo } from 'react'
import { selectEvent, useGetEventQuery } from '../../api'

const useEventByIdQuery = (id: string, skip?: boolean) => {
	const selectNormalizedEvent = useMemo(selectEvent, [])
	const eventQuery = useGetEventQuery(
		{ id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedEvent(result.data) : undefined,
				}
			},
		},
	)
	return eventQuery
}

export default useEventByIdQuery

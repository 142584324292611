import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = ['Analytics Organization'] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			organizationsControllerFindMany: build.query<OrganizationsControllerFindManyApiResponse, OrganizationsControllerFindManyApiArg>({
				query: (queryArg) => ({
					url: `/analytics/organizations/grid/data`,
					params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
				}),
				providesTags: ['Analytics Organization'],
			}),
			organizationsControllerGetGridMeta: build.query<
				OrganizationsControllerGetGridMetaApiResponse,
				OrganizationsControllerGetGridMetaApiArg
			>({
				query: () => ({ url: `/analytics/organizations/grid/meta` }),
				providesTags: ['Analytics Organization'],
			}),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsOrganizationsApi }
export type OrganizationsControllerFindManyApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: Organization[]
}
export type OrganizationsControllerFindManyApiArg = {
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type OrganizationsControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type OrganizationsControllerGetGridMetaApiArg = void
export type Organization = {
	id: string
	name: string
	campusId?: number | null
	contactEmail?: string | null
	contactName?: string | null
	createdAt: string
	updatedAt: string
	description: string
	displayName?: string | null
	externalId?: string | null
	isActivated?: boolean
	linkedinUrl?: string | null
	organizationTypeId: number
	photoUrl?: string | null
	rolePrimaryContactAffairsUserId?: number | null
	rolePrimaryContactFinanceUserId?: number | null
	rolePrimaryContactPublicUserId?: number | null
	rolePrimaryLeaderUserId?: number | null
	sourceSystem?: string | null
	status?: string
	tag?: string
	twitterUrl?: string
	websiteUrl?: string
	youtubeUrl?: string
	facebookUrl?: string
	instagramUrl?: string
}
export type QueryFilterItem = {
	field: string
	operator:
		| 'contains'
		| 'equals'
		| 'startsWith'
		| 'endsWith'
		| 'isEmpty'
		| 'isNotEmpty'
		| 'isAnyOf'
		| 'onOrAfter'
		| 'after'
		| 'onOrBefore'
		| 'before'
		| '='
		| '!='
		| '<'
		| '<='
		| '>'
		| '>='
	value?: object
}
export type QueryGridFiltersDto = {
	filters?: QueryFilterItem[]
}
export type QuerySortByItem = {
	field: string
	sort: 'asc' | 'desc'
}
export type QueryGridSortByDto = {
	sortBy?: QuerySortByItem[]
}
export type GridColumnDto = {
	field: string
	headerName: string
	description?: string
	width?: number
	flex?: number
	minWidth?: number
	maxWidth?: number
	hide?: boolean
	hideable?: boolean
	sortable?: boolean
	editable?: boolean
	groupable?: boolean
	pinnable?: boolean
	searchable?: boolean
	filterable?: boolean
	template?: string
	type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'
}
export type GridConfigDto = {
	fields: string[]
	dictionary: GridColumnDto[]
	config: object
}
export const { useOrganizationsControllerFindManyQuery, useOrganizationsControllerGetGridMetaQuery } = injectedRtkApi

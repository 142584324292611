import { isEqual, isString } from 'lodash'
import EntityResults from './types/EntityResults'
import MapPin from './types/MapPin'
// @TODO: no types
export const paginateQuery = {
	merge: (currentCache, { items, totalItems }, { arg: { skip = 0 } }) => {
		if (skip > 0) {
			currentCache.items.push(...items)
			currentCache.totalItems = totalItems
		} else {
			currentCache.items = items
			currentCache.totalItems = totalItems
		}
	},

	forceRefetch({ currentArg, previousArg }) {
		return !isEqual(currentArg, previousArg)
	},
}

export const stripTags = (text: string) => {
	if (!text || !isString(text)) {
		return ''
	}
	const regex = /(<([^>]+)>)/gi
	return text.replace(regex, '')
}

export const canLoadMore = (data?: EntityResults<unknown>) => {
	return data ? data.totalItems > data.items.length : false
}

export const GOOGLE_MAP_URL = 'https://maps.google.com/'

export const generatePinUrl = ({ lat, lng }: MapPin) => {
	return `${GOOGLE_MAP_URL}?q=${lat}, ${lng}`
}

import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import { clearSearchMembers, getSearchMembersData, readGroupMembersRequest } from '@/store/groups'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { GroupMemberStatusParam, GroupMemberTypeParam } from '@/api/http/groups/getGroupMembers'
import { GroupMember } from '@/store/groups/types'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 20,
	},
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	searchResultContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'calc(100vh - 170px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	row: {
		padding: 10,
		borderRadius: 5,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.palette.neutral.light,
		},
	},
	userRow: {
		margin: 0,
	},
	userRowInformationContainer: {
		justifyContent: 'center',
	},
	userName: {
		fontWeight: 600,
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},
	select: {
		minWidth: 100,
		width: 200,
		marginLeft: 15,
		height: 50,
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	submitButton: {
		minWidth: 200,
		margin: '35px 0 15px 0',
	},
	checkUserIcon: {
		width: '1.5em',
		height: '1.5em',
		color: theme.palette.text.secondary,
	},
	tagsContainer: {
		marginTop: 15,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		overflowX: 'auto',
		overflowY: 'hidden',
	},

	tag: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 32,
		padding: '2vh 1vw',
		margin: '0 0.5vw',
		fontSize: 14,
		fontWeight: 550,
	},
	tagIcon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
}))

interface GroupMembersListProps {
	children: (users: GroupMember[]) => React.ReactNode[]
	classnames?: {
		searchResultContainer?: string
		root?: string
	}
	type?: GroupMemberTypeParam
	status: GroupMemberStatusParam
	emptyPlaceholder?: React.ReactNode
}

const GroupMembersList = ({
	classnames = {
		searchResultContainer: '',
		root: '',
	},
	children,
	type,
	status,
	emptyPlaceholder = null,
}: GroupMembersListProps) => {
	const dispatch = useAppDispatch()
	const classes = useStyles()

	const { isLoading, canLoadMore, members } = useSelector(getSearchMembersData)

	const [resetScrollPosition, setResetScrollPosition] = useState(false)
	const [membersCount, setMembersCount] = useState(members.length)
	const [shouldUpdateList, setShouldUpdateList] = useState(false)

	const handleLoadMore = useCallback(() => {
		dispatch(
			readGroupMembersRequest({
				fetchMore: true,
				type,
				status,
			}),
		)
	}, [dispatch, status, type])

	useEffect(() => {
		dispatch(
			readGroupMembersRequest({
				fetchMore: false,
				type,
				status,
			}),
		)

		return () => {
			dispatch(clearSearchMembers())
		}
	}, [dispatch, status, type, shouldUpdateList])

	useEffect(() => {
		if (!isLoading) setResetScrollPosition(false)
	}, [isLoading])

	useEffect(() => {
		// reload members list if members array was changed
		if (members.length <= membersCount) {
			setShouldUpdateList((prev) => !prev)
		}
		setMembersCount(members.length)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members.length])

	const showNoResultFoundPlaceholder = !isLoading && !members.length && !canLoadMore

	return (
		<div className={classNames(classnames.root, classes.root)}>
			<div className={classNames(classnames.searchResultContainer, classes.searchResultContainer)}>
				{showNoResultFoundPlaceholder ? (
					emptyPlaceholder
				) : (
					<InfiniteScrollComponent
						classnames={{
							content: classes.cardContainer,
						}}
						onLoadMore={handleLoadMore}
						dataLength={members.length}
						canLoadMore={canLoadMore}
						resetScroll={resetScrollPosition}
						internalScroll
					>
						{children(members)}
					</InfiniteScrollComponent>
				)}
			</div>
		</div>
	)
}

export default GroupMembersList

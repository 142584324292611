import { useCallback, useState } from 'react'
import { EntitiesWithResponses, useGetCurrentUserRespondedEntitiesQuery } from '../api'

const useEntityListWithResponse: <T>(entityType: EntitiesWithResponses) => {
	list: T[]
	isLoading: boolean
	isFetching: boolean
	isError: boolean
	loadMore: () => void
	canLoadMore: boolean
} = (entityType) => {
	const [offset, setOffset] = useState(0)
	const { data, isError, isFetching, isLoading } = useGetCurrentUserRespondedEntitiesQuery(
		{ entityType, offset },
		{ refetchOnMountOrArgChange: true },
	)
	const loadMore = useCallback(() => {
		if (data && data.list) {
			setOffset(data.list.length)
		}
	}, [data])

	return {
		list: data?.list ?? [],
		isError,
		isFetching,
		isLoading,
		loadMore,
		canLoadMore: data?.canLoadMore ?? true,
	}
}

export default useEntityListWithResponse

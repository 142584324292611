import { TextButton } from '@/components/Buttons'
import CreateItemInput from '@/components/PathBuilder/CreateItemInput'
import SelfCreatedItemsList from '@/components/PathBuilder/SelfCreatedItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { getArrayMinSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '6vh 0 3vh 0',
		width: '45%',
	},
	header: {
		width: '70%',
	},
	skipButton: {
		color: theme.palette.text.secondary,
		textDecoration: 'underline',
		fontWeight: 600,
	},
	containerRoot: {
		height: '40vh',
	},
}))

const Question2 = ({
	onClose,
	onValidation,
	onBack,
	showBackButton,
	data,
	pathBuilderForm,
	dataKey,
	onSkipStep,
}: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const handleAddItem = (v: string[], onChange: (v: string[]) => void) => (newItem: string) => {
		onChange((v ?? []).concat([newItem]))
	}

	return (
		<Controller
			name={dataKey}
			control={control}
			render={({ field: { value, onChange } }) => (
				<StepperContent
					leftColumnContent={
						<StepperCard
							classnames={{
								header: classes.header,
								container: classes.containerRoot,
							}}
							title="What causes would you like to volunteer for?"
							subTitle="You’ll receive more information about these causes."
							onClose={onClose}
							onBack={onBack}
							showBackButton={showBackButton}
						>
							<CreateItemInput
								className={classes.container}
								onCreate={handleAddItem(value, onChange)}
								placeholder={!value?.length ? 'Add a cause' : 'Add another cause'}
							/>
							{!value?.length && (
								<TextButton className={classes.skipButton} onClick={onSkipStep(1)}>
									I don’t have any in mind
								</TextButton>
							)}
						</StepperCard>
					}
					rightColumnContent={<div>{!!value?.length && <SelfCreatedItemsList title="Added" onChange={onChange} items={value} />}</div>}
				/>
			)}
		/>
	)
}

export default Question2

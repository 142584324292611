import Filter from '@/components/Filter'
import NotInterestedEventPopup from '@/components/NotInterestedEventPopup'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { getActiveCampuses } from '@/features/campus/slice'
import useEqualToPath from '@/hooks/useEqualToPath'
import { Filters, SearchConfig } from '@/interfaces/common'
import { useAppDispatch } from '@/store'

import { clearFilters, getSearchConfig, setFilters } from '@/store/events'
import { StoreSearchParams } from '@/store/types'
import { includeUrl } from '@/utils/urlHandlers'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createOptionMapper } from '../../../utils/common'
import { useGetCategoriesQuery } from '../api'

import { CalendarBlank, Compass } from '@phosphor-icons/react'
import { useGetOrgBranchesQuery } from '../../organizations/api'

import routes from '../routes'
import DtoEventCategory from '../types/dto/DtoEventCategory'
import { DtoOrganization } from '@/anthology/organizations/types/dto/DtoOrganization'

import { ICON_SIZES } from '@/constants/iconSizes'
import useCurrentUserCountsQuery from '../hooks/useCurrentUserCountsQuery'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	createEventButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
}))

const ROUTES_WITHOUT_FILTER = [PATHS.APP.EVENTS_CREATE, PATHS.APP.EVENTS_EDIT()]

const EventsPage = () => {
	const classes = useStyles()
	const { pathname } = useLocation()
	const dispatch = useAppDispatch()

	const [showFilter, setShowFilter] = useState(true)

	const searchConfig = useSelector(getSearchConfig)

	const campuses = useSelector(getActiveCampuses)
	const { data, isLoading } = useGetCategoriesQuery()
	const { data: branchData, isLoading: isLoadingBranchData } = useGetOrgBranchesQuery()

	const { data: eventsCountsData } = useCurrentUserCountsQuery()

	const canUseFilter = useEqualToPath(PATHS.APP.EVENTS)

	const handleClearFilters = () => {
		dispatch(clearFilters())
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			if (canUseFilter) {
				// @TODO: #correct, handling of filters isn't correct and there are lot of logical and anti-pattern issues here
				dispatch(setFilters({ ...(config as StoreSearchParams), page: 0 }))
				window.scrollTo({ top: 0 })
			}
		},
		[dispatch, canUseFilter],
	)

	useLayoutEffect(() => {
		setShowFilter(!includeUrl(ROUTES_WITHOUT_FILTER))
	}, [pathname])

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Events',
				icon: <Compass size={ICON_SIZES.md} />,
				path: PATHS.APP.EVENTS,
			},
			{
				label: 'My Events',
				icon: <CalendarBlank size={ICON_SIZES.md} />,
				path: PATHS.APP.EVENTS_MY,
				info: [`${eventsCountsData?.upcoming || 0} Upcoming `, `${eventsCountsData?.past || 0} Past`],
			},
		],
		[eventsCountsData?.past, eventsCountsData?.upcoming],
	)
	const categories = useMemo(() => {
		if (data) {
			return data.items.map(createOptionMapper<DtoEventCategory['id']>('id', 'name'))
		} else {
			return []
		}
	}, [data])

	// Set up for BRANCH AS FILTER FOR EVENTS
	const branches = useMemo(() => {
		if (branchData) {
			return branchData.items.map(createOptionMapper<DtoOrganization['id']>('id', 'name'))
		} else {
			return []
		}
	}, [branchData])
	return (
		<div className={classes.root}>
			<NotInterestedEventPopup />
			{showFilter && (
				<Filter
					isLoading={isLoading || isLoadingBranchData}
					title="Events"
					searchPlaceholder="Search events"
					value={searchConfig}
					filters={[Filters.date, Filters.categories, Filters.branches]}
					filterOptions={{
						campuses,
						categories,
						branches,
					}}
					canUseFilter={canUseFilter}
					onFilterChange={fetchSearchAndFilter}
					clearFilters={handleClearFilters}
					filterNavigation={filterNavigation}
				/>
			)}
			<Router routes={routes} />
		</div>
	)
}

export default EventsPage

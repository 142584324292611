import IconButton from '@/components/Buttons/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	iconButton: ({ variant }: any) => ({
		border: variant === 'primary' ? 'none' : `solid 1px ${theme.palette.primary.main}`,
		color: variant === 'primary' ? theme.palette.common.white : theme.palette.primary.main,
		backgroundColor: variant === 'primary' ? theme.palette.primary.main : theme.palette.specialColors.transparent,
		padding: '7px 14px',
		width: 230,
		'&:hover': {
			backgroundColor: variant === 'primary' ? theme.palette.primary.dark : theme.palette.grey[500],
		},
		'&:disabled': {
			backgroundColor: theme.palette.grey[500],
			color: theme.palette.common.white,
		},
		minWidth: 'fit-content',
	}),
}))

interface AddButtonProps {
	className?: string
	children: string
	disabled?: boolean
	onClick?: (e: any) => void
	variant?: 'primary' | 'secondary'
}

const AddButton = ({ className = '', children, onClick, variant = 'primary', disabled = false }: AddButtonProps) => {
	const classes = useStyles({ variant })

	return (
		<IconButton
			className={classNames(classes.iconButton, className)}
			startIcon={<AddOutlinedIcon fontSize="medium" />}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</IconButton>
	)
}

export default AddButton

import { getActiveCampuses } from '@/features/campus/slice'

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import CampusIcon from '../icons/CampusIcon'
import { IModalOptionPickerProps, ModalOptionPicker } from './ModalPicker'

const CampusPicker: React.FC<Omit<IModalOptionPickerProps, 'options'>> = (props) => {
	const { modalTitle = 'Choose campus', buttonDefaultLabel = 'Choose campus', ...rest } = props

	const campuses = useSelector(getActiveCampuses)

	const campusesOptions = useMemo(
		() => [
			{
				label: 'All campuses',
				value: null,
			},
			...campuses,
		],
		[campuses],
	)

	return (
		<ModalOptionPicker
			Icon={CampusIcon}
			{...rest}
			options={campusesOptions}
			modalTitle={modalTitle}
			buttonDefaultLabel={buttonDefaultLabel}
		/>
	)
}

export { CampusPicker }

import { SESSION_TIME } from '@/constants/configuration'
import DtoAnalyticsSettings from '@/features/adminConsole/types/dto/DtoAnalyticsSettings'
import { baseApi } from './baseApi'

export const analyticsApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getAnalyticsSettings: builder.query<DtoAnalyticsSettings, void>({
			query: () => ({
				url: `/settings`,
				method: 'GET',
			}),

			keepUnusedDataFor: SESSION_TIME,
		}),
		getKibanaDashboard: builder.query({
			query: ({ dashboardType, analyticsRole, academicCollege, campusId, startDate }) => ({
				url: `/settings/dashboard/${dashboardType}`,
				method: 'GET',
				query: {
					analyticsRole,
					campusId,
					startDate,
					academicCollege,
				},
			}),

			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetAnalyticsSettingsQuery, useGetKibanaDashboardQuery } = analyticsApi

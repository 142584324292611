import { useMemo } from 'react'
import { AnalyticsRole } from '../features/adminConsole/types/AnalyticsRole'
import { selectShowCampuses } from '../features/campus/slice'
import { useAppSelector } from '../store'
import { getUserInfo } from '../store/auth'

export type ErrorCondition = {
	condition: boolean
	message: string
	roles: AnalyticsRole[]
}

/**
 * This hook returns an error message if a user has a specific role and a condition is met
 * @param providedConditions An array of conditions to check. There are default conditions that are checked if this is not provided
 * @returns error message if the condition is met and the user has the role
 */
const useRBACErrors = (providedConditions?: ErrorCondition[]) => {
	const { analyticsRole: role, academicCollegeId, campus, schoolId } = useAppSelector(getUserInfo)
	const showCampuses = useAppSelector(selectShowCampuses)
	const defaultErrorConditions = useMemo(
		() => [
			{
				condition: !schoolId,
				message: `Admin doesn't have an associated school ID, please <a href={FEEDBACK_URL}>contact us</a> to complete setup.`,
				roles: [AnalyticsRole.SCHOOL_ADMIN, AnalyticsRole.CAMPUS_ADMIN, AnalyticsRole.STUDENT, AnalyticsRole.COLLEGE_ADMIN],
			},
			{
				condition: showCampuses && !campus,
				message: `Campus admins must be associated with a campus, please <a href={FEEDBACK_URL}>contact us</a> to complete setup.`,
				roles: [AnalyticsRole.CAMPUS_ADMIN],
			},
			{
				condition: !academicCollegeId,
				message: `Academic college admins must be associated with an academic college, please <a href={FEEDBACK_URL}>contact us</a> to complete setup.`,
				roles: [AnalyticsRole.COLLEGE_ADMIN],
			},
		],
		[schoolId, campus, showCampuses, academicCollegeId],
	)

	const conditions = providedConditions || defaultErrorConditions

	return useMemo(() => {
		const error = conditions.find((c) => c.condition && c.roles.includes(role))
		return error?.condition ? error.message : undefined
	}, [conditions, role])
}

export default useRBACErrors

import { Tooltip, withStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import TruncateMarkup from 'react-truncate-markup'

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		maxWidth: 400,
		fontSize: theme.typography.pxToRem(12),
		border: `1px solid ${theme.palette.border.bold}`,
	},
}))(Tooltip)

export type ITruncateTextWithTooltipProps = {
	lines?: number
}

const TruncateTextWithTooltip: React.FC<ITruncateTextWithTooltipProps> = ({ children, lines = 2 }) => {
	const [wasTruncated, setWasTruncated] = useState(false)

	const onTruncate = useCallback((wasTruncated) => setWasTruncated(wasTruncated), [])

	return (
		<HtmlTooltip
			disableHoverListener={!wasTruncated}
			disableTouchListener={!wasTruncated}
			placement="bottom"
			title={<React.Fragment>{children}</React.Fragment>}
		>
			<div>
				<TruncateMarkup lines={lines} onTruncate={onTruncate}>
					{children}
				</TruncateMarkup>
			</div>
		</HtmlTooltip>
	)
}

export { TruncateTextWithTooltip }

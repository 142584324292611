import { useMemo } from 'react'
import { selectJob, useGetJobQuery } from '../api'

const useJobByIdQuery = (id: string, skip?: boolean) => {
	const selectNormalizedJob = useMemo(selectJob, [])
	const studyAbroadQuery = useGetJobQuery(
		{ id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedJob(result.data) : undefined,
				}
			},
		},
	)
	return studyAbroadQuery
}

export default useJobByIdQuery

import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(({ palette: { background, text, border } }) => ({
	root: ({ cardColor, textColor, borderColor }: any) => ({
		height: 40,
		maxWidth: 'calc(100% - 40px)',
		backgroundColor: cardColor,
		color: textColor,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '10px 10px 10px 0',
		padding: '0 15px',
		borderRadius: 4,
		border: `solid 1px ${borderColor || border.bold}`,
		overflowWrap: 'break-word',
	}),
	iconContainer: {
		marginRight: 5,
	},
	text: {
		fontWeight: 600,
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
	},
}))

interface CategoryTagProps {
	name: string
	cardColor?: string
	textColor?: string
	className?: string
	icon?: React.ReactNode
	borderColor?: string
}

const CategoryTag = ({ name, cardColor, textColor, borderColor, className = '', icon }: CategoryTagProps) => {
	const classes = useStyles({ cardColor, textColor, borderColor })

	return (
		<div className={classNames(classes.root, className)}>
			{icon && <span className={classes.iconContainer}>{icon}</span>}
			<Typography className={classes.text} variant={'subtitle1'}>
				{name}
			</Typography>
		</div>
	)
}

export default CategoryTag

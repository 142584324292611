import { PATHS } from '@/constants'
import { UserProfile } from '@/pages'

import EventsPage from '@/pages/EventsPage'
import OrganizationsPage from '@/pages/OrganizationsPage'

import EngageEventsPage from '@/anthology/events/pages'
import EngageOrganizationsPage from '@/anthology/organizations/pages'
import { isEngage } from '@/constants/configuration'
import { PAGE_NAME } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import HomePage from '@/pages/HomePage'
import InternshipsPage from '@/pages/InternshipsPage'
import MessagesPage from '@/pages/MessagesPage'
import NetworkPage from '@/pages/NetworkPage'
import NotificationsPage from '@/pages/NotificationsPage'
import PathBuilderPage from '@/pages/PathBuilderPage'
import ResearchPage from '@/pages/ResearchPage'
import SettingsPage from '@/pages/SettingsPage'
import StudyAbroadPage from '@/pages/StudyAbroadPage'
import VolunteersPage from '@/pages/VolunteersPage'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const MY_PROFILE = createPageTitle(PAGE_NAME.MY_PROFILE)
const HOME = createPageTitle(PAGE_NAME.HOME)
const NOTIFICATIONS = createPageTitle(PAGE_NAME.NOTIFICATIONS)
const MESSAGES = createPageTitle(PAGE_NAME.MESSAGES)

const routes: AppRoute[] = [
	{
		component: isEngage() ? EngageEventsPage : EventsPage,
		name: 'events-root-router',
		path: PATHS.APP.EVENTS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},

	{
		component: isEngage() ? EngageOrganizationsPage : OrganizationsPage,
		name: 'organizations-root-router',
		path: PATHS.APP.ORGANIZATIONS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: VolunteersPage,
		name: 'volunteer-root-router',
		path: PATHS.APP.VOLUNTEER,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: UserProfile,
		title: MY_PROFILE,
		name: MY_PROFILE,
		path: PATHS.APP.PROFILE,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ResearchPage,
		name: 'research-root-router',
		path: PATHS.APP.RESEARCH,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: StudyAbroadPage,
		name: 'education-abroad-root-router',
		path: PATHS.APP.EDUCATION_ABROAD,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: InternshipsPage,
		name: 'internships-root-router',
		path: PATHS.APP.INTERNSHIPS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: HomePage,
		title: HOME,
		name: HOME,
		path: PATHS.APP.HOME,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: NetworkPage,
		name: 'user-network-root-router',
		path: PATHS.APP.NETWORK,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: NotificationsPage,
		title: NOTIFICATIONS,
		name: NOTIFICATIONS,
		path: PATHS.APP.NOTIFICATIONS(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: MessagesPage,
		title: MESSAGES,
		name: MESSAGES,
		path: PATHS.APP.MESSAGES,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: PathBuilderPage,
		name: 'path-builder-root-router',
		path: PATHS.APP.PATH_BUILDER,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: SettingsPage,
		name: 'settings-root-router',
		path: PATHS.APP.SETTINGS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

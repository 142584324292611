import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'

import { ColorButton, Toggle } from '@/components'
import PageContainer from '@/components/PageContainer'
import OrganizationsList from '@/components/PathBuilder/OrganizationsList'
import SummaryCard from '@/components/PathBuilder/SummaryCard'
import { PATHS, STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { setProfileTabToOpen } from '@/store/auth'
import { getBuilderData, getForm, saveGoalsRequest, setIsOpenPathBuilderCompletedPopup, updateForm } from '@/store/pathBuilder'

const useStyles = makeStyles((theme) => ({
	titleText: {
		fontWeight: 700,
		marginBottom: 10,
	},
	button: {
		padding: '10px 5vw',
		margin: '4vh 0',
	},
	sectionTitle: {
		color: theme.palette.primary.main,
		fontWeight: 700,
		fontSize: 18,
		marginBottom: 10,
	},
	sectionContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	preferencesItem: {
		marginBottom: '3vh',
	},
	toggleLabel: {
		'&>span:last-child': {
			position: 'absolute',
			bottom: -22,
		},
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	infoButton: {
		margin: '0 0 10px 5px',
	},
	infoPopper: {
		borderRadius: '0 7px 7px 7px',
		boxShadow: 'none',
	},
	popperContent: {
		maxWidth: '50vw',
		textAlign: 'left',
		padding: 20,
		minWidth: 300,
	},
}))

const RESOURCE_EMAILS = [
	{
		title: 'Volunteer',
		text: 'Receive emails about volunteer and service opportunities.',
		key: 'receiveEmailsAboutVolunteerAndServiceOpportunities',
		checked: true,
	},
	{
		title: 'Education Abroad',
		text: 'Receive emails about education abroad opportunities and resources.',
		key: 'receiveEmailsEducationAbroadOpportunitiesAndResources',
		checked: true,
	},
	{
		title: 'Research',
		text: 'Receive emails about research opportunities and resources.',
		key: 'receiveEmailsAboutResearchOpportunitiesAndResources',
		checked: true,
	},
	{
		title: 'Professional',
		text: 'Receive emails about professional opportunities and resources.',
		key: 'receiveEmailsAboutProfessionalOpportunitiesAndResources',
		checked: true,
	},
]

export type TResourceEmail = (typeof RESOURCE_EMAILS)[number]['key']

const LastStepPage = () => {
	const classes = useStyles()

	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const formData = useSelector(getForm)
	const builder = useSelector(getBuilderData)

	const [userResourceEmails, setUserResourceEmails] = useState(RESOURCE_EMAILS)
	const resourceEmailsArray = userResourceEmails.map(({ key, checked }) => ({ [key]: checked }))

	const handleClickContinue = () => {
		dispatch(
			updateForm({
				resourceEmails: resourceEmailsArray,
			}),
		)
		dispatch(saveGoalsRequest())
		dispatch(setProfileTabToOpen(1))
		dispatch(setIsOpenPathBuilderCompletedPopup(true))
		push(PATHS.APP.PROFILE)
	}

	const handleSetPreferences =
		(key: string) =>
		({ currentTarget: { checked } }: any) => {
			setUserResourceEmails((prevPreferences) => prevPreferences.map((item) => (item.key === key ? { ...item, checked } : item)))
		}

	if (!Object.keys(builder).length) return <Redirect to={PATHS.APP.PROFILE} />

	return (
		<PageContainer>
			<SummaryCard>
				<Typography className={classes.titleText} variant="h3">
					Last step! Customize your next steps.
				</Typography>
				<Typography variant="subtitle1">Get connected to the resources that’ll help you achieve your goals.</Typography>
			</SummaryCard>
			{!!formData.organizationsWouldLikeToJoin?.length && (
				<SummaryCard>
					<Typography className={classes.sectionTitle} variant="subtitle1">
						Organizations
					</Typography>
					<OrganizationsList items={formData.organizationsWouldLikeToJoin} showButtons />
				</SummaryCard>
			)}
			<SummaryCard>
				{userResourceEmails.map((item) => (
					<div key={item.key} className={classes.sectionContainer}>
						<span className={classes.preferencesItem}>
							<div className={classes.titleContainer}>
								<Typography className={classes.sectionTitle} variant="subtitle1">
									{item.title}
								</Typography>
							</div>
							<Typography>{item.text}</Typography>
						</span>
						<Toggle className={classes.toggleLabel} value={item.checked} onChange={handleSetPreferences(item.key)} labelPosition="bottom" />
					</div>
				))}
			</SummaryCard>
			<ColorButton className={classes.button} onClick={handleClickContinue}>
				Continue
			</ColorButton>
		</PageContainer>
	)
}

export default LastStepPage

import { isEqual } from 'lodash'

import settings from '@/constants/http'

import { baseApi } from '@/features/general/baseApi'
import { getNormalizeUser } from '@/utils/transformers'
import { Dictionary, createSelector } from '@reduxjs/toolkit'
import { DtoComment } from './types/DtoComment'
import DtoCommentsResults from './types/DtoCommentsResults'
import GetCommentsParams from './types/queryParams/GetCommentsParams'
import CreateCommentParams from './types/queryParams/CreateCommentParams'
import { API_BASE } from '../api'
import ReportCommentParams from './types/queryParams/ReportCommentParams'
import LikeCommentParams from './types/queryParams/LikeCommentParams'
import DeleteCommentParams from './types/queryParams/DeleteCommentParams'
import { COMMENTS_LIMIT } from './slice'
import { TCampus } from '@/features/campus/types/TCampus'

export const TAGS = {
	SINGLE: 'Comment',
}

export const commentsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [TAGS.SINGLE],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getComments: builder.query<DtoCommentsResults, GetCommentsParams>({
				query: ({ userId, postId, limit = settings.SEARCH_COMMENTS_LIST_PER_PAGE, offset = 0 }) => ({
					url: `${API_BASE}/${userId}/post/${postId}/comments`,
					params: {
						limit: offset >= settings.SEARCH_COMMENTS_LIST_PER_PAGE ? COMMENTS_LIMIT : limit,
						offset,
					},
				}),

				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}-${queryArgs.postId}`
					return serializedKey
				},
				merge: (currentCache, { results, total }, { arg: { offset } }) => {
					if (offset > 0) {
						currentCache.results.push(...results)
						currentCache.total = total
					} else {
						currentCache.results = results
						currentCache.total = total
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				providesTags: (response, error, { postId }) =>
					response
						? [...response.results.map(({ id }) => ({ type: TAGS.SINGLE, id })), { type: TAGS.SINGLE, id: `LIST-${postId}` }]
						: [{ type: TAGS.SINGLE, id: `LIST-${postId}` }],
			}),
			reportComment: builder.mutation<any, ReportCommentParams>({
				query: ({ userId, ...body }) => ({
					url: `${API_BASE}/${userId}/comment/response`,
					method: 'POST',
					body,
				}),
			}),
			likeComment: builder.mutation<unknown, LikeCommentParams>({
				query: ({ commentId, userId }) => ({
					url: `${API_BASE}/${userId}/comment/${commentId}/like`,
					method: 'PATCH',
				}),
			}),
			deleteComment: builder.mutation<unknown, DeleteCommentParams>({
				query: ({ commentId, userId }) => ({
					url: `${API_BASE}/${userId}/comment/${commentId}/delete`,
					method: 'DELETE',
				}),
			}),
			createComment: builder.mutation<{ comment: DtoComment }, CreateCommentParams>({
				query: ({ message, postId, userId, mentions }) => ({
					url: `${API_BASE}/${userId}/post/${postId}/comment`,
					method: 'POST',
					body: {
						message,
						mentions,
					},
				}),
			}),
		}),
	})

export const { useGetCommentsQuery, useCreateCommentMutation, useDeleteCommentMutation, useLikeCommentMutation, useReportCommentMutation } =
	commentsApi

export const selectCommentsNormalizedUserData = () => {
	const emptyArray = []

	return createSelector(
		(data: DtoCommentsResults) => data,
		(data: DtoCommentsResults, campuses?: Dictionary<TCampus>) => campuses,
		(data: DtoCommentsResults, campuses?: Dictionary<TCampus>): DtoComment[] => {
			return data && data.results
				? data.results.map((comment) => ({
						...comment,
						liked: +comment.liked > 0,
						user: comment.user ? getNormalizeUser(comment.user, campuses) : null,
				  }))
				: emptyArray
		},
	)
}

import { ClockOutlinedIcon } from '@/components'
import ItemAboutRow from '@/components/IndividualPage/ItemAboutRow'
import { formatDateNew, getTime } from '@/utils/dateTime2'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	itemContainer: {
		display: 'flex',
		margin: '0.5vh 0',
		alignItems: 'center',
	},
	iconContainer: {
		width: 50,
		height: 50,
		marginRight: 13,
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	blueText: {
		color: theme.palette.primary.main,
	},
	subLabel: {
		color: theme.palette.text.secondary,
	},
	divider: {
		height: 20,
		width: 25,
		borderRight: `solid 1px ${theme.palette.primary.main}`,
	},
	startDateRow: {
		marginBottom: '0px',
	},
	endDateRow: {
		marginTop: '0px',
	},
}))

interface DateItemAboutRowProps {
	startDate: string
	endDate: string
}

const DateItemAboutRow = ({ startDate, endDate }: DateItemAboutRowProps) => {
	const classes = useStyles()

	const lessThanOneDay = formatDateNew(startDate) === formatDateNew(endDate)

	const start = formatDateNew(startDate, 'dateMed')
	const end = formatDateNew(endDate, 'dateMed')

	const startTime = getTime(startDate)
	const endTime = getTime(endDate)

	const startTimeLabel = lessThanOneDay ? `${startTime} - ${endTime}` : startTime

	return (
		<div>
			<ItemAboutRow className={classes.startDateRow} label={start} subLabel={startTimeLabel} icon={<ClockOutlinedIcon />} />
			{!lessThanOneDay && (
				<>
					<div className={classes.divider} />
					<ItemAboutRow className={classes.endDateRow} label={end} subLabel={endTime} icon={<ClockOutlinedIcon />} />
				</>
			)}
		</div>
	)
}

export default DateItemAboutRow

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'
interface UpdateUserProfileParams {
	userId: UserInfo['id']
	body: any
	schoolId: number
}
const updateUserProfile = async ({ body, schoolId, userId }: UpdateUserProfileParams) => {
	const url = `${schoolId}/users/user/${userId}/edit`

	return http.patch(url, body)
}

export default updateUserProfile

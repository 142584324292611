import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import { PATHS } from '@/constants'
import { getAuthService } from '@/init/DIContainer'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	h2: {
		fontWeight: 700,
		textAlign: 'center',
	},
	explainerText: {
		margin: '1em 2rem',
		// On xl screens max width is 60%, on smaller screens max width is 80%
		maxWidth: '60%',
		textAlign: 'center',
		[theme.breakpoints.down('lg')]: {
			maxWidth: '80%',
		},
	},
	card: {
		paddingBottom: '1vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	link: {
		textDecoration: 'underline',
		color: theme.palette.primary.main,
		fontWeight: 700,
		paddingTop: '20px',
		minWidth: 'min-content',
		minHeight: 'fit-content',
		textAlign: 'center',
	},
	secondLink: {
		textDecoration: 'underline',
		color: theme.palette.primary.dark,
		fontWeight: 700,
		paddingTop: '20px',
		minWidth: 'min-content',
		minHeight: 'fit-content',
		textAlign: 'center',
	},
}))

const MissingAccountPage = () => {
	const classes = useStyles()
	const history = useHistory()
	const engageUrl = useSchoolConfig().links.global.engage
	const SIGN_UP_LINK = `${engageUrl}/account/create?returnUrl=%2Fengage%2F`
	const {
		school: { applicationName, engageInstanceName },
	} = useSchoolConfig()

	const handleBack = () => history.push(PATHS.ROOT)
	const authService = getAuthService()

	return (
		<CardLayout onClickBackHandler={handleBack}>
			<Card className={classes.card}>
				<Typography className={classes.h2} variant="h2">
					Create an Account
				</Typography>
				<Typography className={classes.explainerText}>
					Oops! Looks like you don't have an account in {engageInstanceName} yet, which you need to access {applicationName}. You can create
					an account using the link below. Afterwards, come back here and try again.
				</Typography>
				<Link className={classes.link} href={SIGN_UP_LINK} target="_blank" color="inherit">
					Create account in {engageInstanceName}.
				</Link>
				<Link className={classes.secondLink} onClick={() => authService.authorize()} target="_blank" color="secondary">
					Created account? Click here to login again.
				</Link>
			</Card>
		</CardLayout>
	)
}

export default MissingAccountPage

import { useCurrentUser } from '@/hooks/userHooks'
import { DtoOrganization } from '../types/dto/DtoOrganization'
import { useGetOrganizationActivityQuery } from '@/features/organizations/api'

const useGetOrganizationPostCount = (id: DtoOrganization['id']) => {
	const currentUser = useCurrentUser()
	const { data, ...rest } = useGetOrganizationActivityQuery(
		{ orgId: id, userId: currentUser.id, count: true },
		{ refetchOnMountOrArgChange: true },
	)
	const countData = data as { count: number }
	return {
		...rest,
		count: countData?.count || 0,
	}
}

export default useGetOrganizationPostCount

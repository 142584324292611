import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { TOption } from '@/store/types'
import { makeStyles } from '@material-ui/core'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import { Fragment, useCallback } from 'react'

interface ITagsProps {
	selectedTags?: number[]
	tags: TOption[]
	toggleFilter: (id: TOption['value']) => void
}

const useStyles = makeStyles({
	root: {
		margin: '1.5vh 0 0.2vh 0',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	button: {
		marginRight: 10,
		marginBottom: 10,
		'&:last-child': {
			marginRight: 0,
		},
	},
})

const Tags: React.FC<ITagsProps> = ({ tags, selectedTags, toggleFilter }) => {
	const classes = useStyles()

	const handleToggle = useCallback(
		(id: TOption['value']) => () => {
			toggleFilter(id)
		},
		[toggleFilter],
	)

	return (
		<div className={classes.root}>
			{tags.map((button) => (
				<Fragment key={button.value}>
					{selectedTags.includes(button.value) ? (
						<ColorButton className={classes.button} onClick={handleToggle(button.value)} color={ButtonColors.PRIMARY}>
							{button.label}
						</ColorButton>
					) : (
						<OutlinedButton className={classes.button} onClick={handleToggle(button.value)}>
							{button.label}
						</OutlinedButton>
					)}
				</Fragment>
			))}
		</div>
	)
}

export default Tags

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

interface SearchStudyAbroadParams {
	q?: string
	page: number
	userId: number
	country?: string[]
	termOfAvailabilityId?: number[]
	housingTypeId?: number[]
	scholarship?: boolean
	schoolId: number
}

const searchStudyAbroad = async ({
	q,
	page,
	userId,
	country,
	termOfAvailabilityId,
	housingTypeId,
	scholarship,
	schoolId,
}: SearchStudyAbroadParams) => {
	const url = `${schoolId}/studyAbroad/search`
	const params = {
		q,
		page,
		userId,
		termOfAvailabilityId,
		housingTypeId,
		scholarship,
		country,
		perPage: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get(url, { params })
}

export default searchStudyAbroad

import { EVENT_PRIVACY_TYPES } from '@/constants/events'
import { ICON_SIZES } from '@/constants/iconSizes'
import { makeStyles, Tooltip } from '@material-ui/core'
import { Lock } from '@phosphor-icons/react'
import appTheme from '../../Theme'

const useStyles = makeStyles((theme) => ({
	styledDiv: {
		backgroundColor: (props: { backgroundColor: string }) => props.backgroundColor,
		position: 'absolute',
		left: theme.spacing(2),
		top: theme.spacing(3),
		borderRadius: theme.shape.borderRadius,
		zIndex: 1,
		color: theme.palette.common.white,
		padding: theme.spacing(1),
		display: 'flex',
		cursor: 'default',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))

type PrivacyLockIconProps = {
	size?: keyof typeof ICON_SIZES
	/* eslint-disable prettier/prettier */
	privacyType: (typeof EVENT_PRIVACY_TYPES)[keyof typeof EVENT_PRIVACY_TYPES]
	backgroundColor?: string
}

const PrivacyLockIcon = ({ size = 'md', backgroundColor = appTheme.palette.icons.admin, privacyType }: PrivacyLockIconProps) => {
	const classes = useStyles({ backgroundColor })

	return (
		<>
			{privacyType !== EVENT_PRIVACY_TYPES.Public && (
				<Tooltip
					placement="right"
					title={`This event is for ${privacyType === 1 ? 'organization members' : 'organization members and followers'} only`}
					arrow
				>
					<div className={classes.styledDiv}>
						<Lock size={ICON_SIZES[size]} color="black" />
					</div>
				</Tooltip>
			)}
		</>
	)
}

export default PrivacyLockIcon

import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'
import IndividualOrganization from './pages/IndividualPage'
import YourOrganizations from './pages/YourOrganizations'
import DiscoverOrganizations from './pages/DiscoverOrganizations'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_ORGANIZATIONS = createPageTitle(PAGE_NAME.DISCOVER_ORGANIZATIONS)
const MY_ORGANIZATIONS = createPageTitle(PAGE_NAME.MY_ORGANIZATIONS)

const routes: AppRoute[] = [
	{
		component: IndividualOrganization,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.ORGANIZATIONS_SINGLE(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourOrganizations,
		title: MY_ORGANIZATIONS,
		name: MY_ORGANIZATIONS,
		path: PATHS.APP.ORGANIZATIONS_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverOrganizations,
		title: DISCOVER_ORGANIZATIONS,
		name: DISCOVER_ORGANIZATIONS,
		path: PATHS.APP.ORGANIZATIONS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import type { Spread } from 'lexical'

import { PATHS } from '@/constants'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import {
	$applyNodeReplacement,
	TextNode,
	type DOMConversionMap,
	type DOMConversionOutput,
	type DOMExportOutput,
	type EditorConfig,
	type LexicalNode,
	type NodeKey,
	type SerializedTextNode,
} from 'lexical'
import { TMentionData } from '../plugins/MentionPlugin/MentionTypeaheadOption'

export type SerializedMentionNode = Spread<
	{
		mention: TMentionData
	},
	SerializedTextNode
>

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
	const textContent = domNode.textContent
	const entityId = Number(domNode.dataset.mentionId)
	const entityType = Number(domNode.dataset.mentionType)
	if (textContent !== null) {
		const node = $createMentionNode({ name: textContent, entityType: entityType, id: entityId })
		return {
			node,
		}
	}

	return null
}

const mentionStyle = 'color: var(--color-primary);font-weight: 700; text-decoration:none;'

export class MentionNode extends TextNode {
	__mention: TMentionData

	static getType(): string {
		return 'mention'
	}

	static clone(node: MentionNode): MentionNode {
		return new MentionNode(node.__mention, node.__key)
	}
	static importJSON(serializedNode: SerializedMentionNode): MentionNode {
		const node = $createMentionNode(serializedNode.mention)
		node.setTextContent(serializedNode.text)
		node.setFormat(serializedNode.format)
		node.setDetail(serializedNode.detail)
		node.setMode(serializedNode.mode)
		node.setStyle(serializedNode.style)
		return node
	}

	constructor(data: TMentionData, key?: NodeKey) {
		super(data.name, key)
		this.__mention = data
	}

	exportJSON(): SerializedMentionNode {
		return {
			...super.exportJSON(),
			mention: this.__mention,
			type: 'mention',
			version: 1,
		}
	}

	createDOM(config: EditorConfig): HTMLElement {
		const dom = document.createElement('a')
		const resolvePath = this.__mention.entityType === EntityTypes.user ? PATHS.APP.NETWORK_USER : PATHS.APP.ORGANIZATIONS_SINGLE

		dom.setAttribute('href', resolvePath(this.__mention.id.toString()))
		dom.textContent = this.__mention.name
		dom.style.cssText = mentionStyle
		dom.className = 'mention'
		return dom
	}

	exportDOM(): DOMExportOutput {
		const element = document.createElement('a')
		element.setAttribute('data-lexical-mention', 'true')
		element.setAttribute('data-mention-id', this.__mention.id.toString())
		element.setAttribute('data-mention-type', this.__mention.entityType.toString())
		element.style.cssText = mentionStyle
		const resolvePath = this.__mention.entityType === EntityTypes.user ? PATHS.APP.NETWORK_USER : PATHS.APP.ORGANIZATIONS_SINGLE
		element.setAttribute('href', resolvePath(this.__mention.id.toString()))
		element.textContent = this.__text

		return { element }
	}

	static importDOM(): DOMConversionMap | null {
		return {
			a: (domNode: HTMLElement) => {
				if (!domNode.hasAttribute('data-lexical-mention')) {
					return null
				}
				return {
					conversion: convertMentionElement,
					priority: 1,
				}
			},
		}
	}

	isTextEntity(): true {
		return true
	}

	canInsertTextBefore(): boolean {
		return false
	}

	canInsertTextAfter(): boolean {
		return false
	}
}

export function $createMentionNode(data: TMentionData): MentionNode {
	const mentionNode = new MentionNode(data)
	mentionNode.setMode('segmented').toggleDirectionless()
	return $applyNodeReplacement(mentionNode)
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
	return node instanceof MentionNode
}

import { getUserRole, isStudent as isStudentProfile } from '@/utils/authHandlers'
import { getFullName } from '@/utils/common'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import upperFirst from 'lodash/upperFirst'
import { DateTime } from 'luxon'
import React from 'react'
import useCommonStyles from '../../styles/useCommonStyles'
import DtoUser from '../../types/users/dto/DtoUser'
import BackButton from '../BackButton'
import FacultyStaffCard from './cards/profile/FacultyStaffCard'
import StudentCard from './cards/profile/StudentCard'
import UserContactCard from './cards/user/UserContactCard'
import { UserGoalsCard } from './cards/user/UserGoalsCard'
import UserInfoRow from './UserInfoRow'

const useStyles = makeStyles((theme) => ({
	userName: {
		fontSize: 25,
		fontWeight: 700,
		marginTop: theme.spacing(4.5),
		marginBottom: theme.spacing(2),
	},
	joinedDate: {
		color: theme.palette.text.secondary,
		fontWeight: 500,
	},
}))

export interface IUserInfoGridProps extends DtoUser {}

const UserInfoGrid: React.FC<IUserInfoGridProps> = ({
	appUserTypeId,
	externalId,
	id: userId,
	firstName,
	lastName,
	createdAt,
	lastLogin,
}) => {
	const classes = useStyles()
	const userRole = getUserRole(appUserTypeId)
	const isStudent = isStudentProfile(userRole)

	const parsedDate = DateTime.fromISO(createdAt, { zone: 'UTC' })
	const formattedDate = parsedDate.toFormat('MMMM dd, yyyy')

	const lasLoginParsedDate = DateTime.fromISO(lastLogin, { zone: 'UTC' })
	const formattedLastLoginDate = lasLoginParsedDate.toFormat('MMMM dd, yyyy')
	const commonClasses = useCommonStyles()
	const userType = userRole ? upperFirst(userRole) : 'Unknown'
	return (
		<UserInfoRow>
			<div>
				<BackButton />
				<Typography className={classes.userName} variant="h1">
					{getFullName(firstName, lastName)}
				</Typography>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Typography>Joined: {formattedDate} </Typography>
					{lastLogin && <Typography> | Last Login: {formattedLastLoginDate}</Typography>}
				</div>
			</div>
			<Grid className={commonClasses.verticalGap} container spacing={3}>
				<Grid item md={4} sm={6} xs={12}>
					<UserContactCard userId={userId} />
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					{isStudent ? (
						<StudentCard title={`User Type: ${userType}`} externalId={externalId} />
					) : (
						<FacultyStaffCard title={`User Type: ${userType}`} externalId={externalId} />
					)}
				</Grid>
				{isStudent && (
					<Grid item md={4} sm={6} xs={12}>
						<UserGoalsCard userId={userId} />
					</Grid>
				)}
			</Grid>
		</UserInfoRow>
	)
}

export { UserInfoGrid }

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'
import { PrivacyTypes } from './patchUserPrivacySettings'

const getEntityPrivacySettings = async (userId: UserInfo['id'], type: PrivacyTypes, schoolId: number) => {
	const url = `${schoolId}/users/user/${userId}/privacy/${type}`

	return http.get(url)
}

export default getEntityPrivacySettings

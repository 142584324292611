import { useCurrentUser } from '@/hooks/userHooks'
import { useSelector } from '@/hooks/useSelector'
import { getIsSuperAdmin } from '@/store/organizations'
import { isCurrentUser } from '@/utils/authHandlers'

export const usePostPermissions = (post) => {
	const { ownerUser, ownerOrganization } = post

	const currentUser = useCurrentUser()
	const isSuperAdmin = useSelector(getIsSuperAdmin(ownerOrganization?.id))
	const isOwnPost = isCurrentUser(currentUser?.id, ownerUser?.id)
	const isOwnerOrAdmin = isOwnPost || isSuperAdmin

	return { isOwnerOrAdmin, canDeletePost: isOwnerOrAdmin, canViewStats: isOwnerOrAdmin, canEditPost: isOwnerOrAdmin }
}

import { CreateFeedsModal } from '@/store/feeds/types'
import { isEmpty } from 'lodash'
import { GlobalState } from '../GlobalState'
import { _homeFeedGlobals } from './homeFeedGlobals'

/**
 * 🌟🌎 Add your global state here,
 *
 * but only if it truly needs to be global.
 * Global state should be the last resort.
 *
 * Hint: Does your state need to be globally available
 * or cached beyond component and page destruction and recreation?
 * If not, then it doesn't need to be global. Use useState() or useRef()
 *
 * Hint: If your state is just the result of an API network request, try uswSwr().
 *
 * @protected This starts with underscores as to not clutter autocomplete in text editors.
 */
export const __rawGlobalStateMap = {
	HomeFeed: _homeFeedGlobals,
	createFeedsModal: new GlobalState<CreateFeedsModal>(
		{ defaultValue: { isOpen: false, isEdit: false, isSchedule: false, content: {} } },
		{
			onChange: ({ newValue, prevValue, diff, debug }) => {
				if (!prevValue.isOpen && diff.isOpen !== false && !isEmpty(diff.content)) {
					newValue.isOpen = true
				}

				if (prevValue.content && !isEmpty(diff.content)) {
					// We want to patch content instead of replacing it completely
					newValue.content = { ...prevValue.content, ...newValue.content }
				}

				if (!newValue.isOpen && prevValue.isOpen) {
					newValue.content = {}
					newValue.isEdit = false
				}

				if (newValue.isEdit && !prevValue.isEdit) {
					// -- Prepare the modal content with the content from the existing post
					const {
						tag,
						usersAudience,
						campusId,
						message,
						photoUrl,
						activeSince,
						usersAudienceScope,
						linkPreviewUrl,
						sharedEntityId,
						sharedEntityType,
						ownerOrganizationId,
					} = newValue.content.postToEdit

					newValue.content.tag ||= tag ? +tag : tag
					newValue.content.usersAudience ||= usersAudience ? +usersAudience : null
					newValue.content.audienceScope ||= usersAudienceScope ? +usersAudienceScope : null
					newValue.content.sharedEntity ||= sharedEntityId && sharedEntityType ? { sharedEntityId, sharedEntityType } : null
					newValue.content.campus ||= campusId || undefined
					newValue.content.message ||= message
					newValue.content.photoUrl ||= photoUrl
					newValue.content.activeSince ||= activeSince
					newValue.content.linkPreviewUrl ||= linkPreviewUrl
					newValue.content.ownerOrganizationId = ownerOrganizationId
				}

				return { newValue }
			},
		},
	),
}

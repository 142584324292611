import useSWR from 'swr'
import { useSWRConfig } from 'swr'

import { SWR_NO_FETCH_KEY } from '@/lib/swr'
import { isObject } from 'lodash'

export const useSwr = (key, ...rest) => {
	const cachedValue = useSWRConfig().cache.get(key)
	const alreadyExisted = !!cachedValue

	// @ts-ignore
	const result = useSWR(key || SWR_NO_FETCH_KEY, ...rest)

	return {
		...result,
		hadCache: alreadyExisted,
		isFromCache: result.data !== undefined && result.data === cachedValue,
		mutateWithoutRevalidate: (data, ...args) => result.mutate(data, { ...args, revalidate: false }),
	}
}

/**
 * This is the same as useSwr() but it will not auto revalidate stale keys.
 */
export const useSwrImmutable = (key, ...rest) => {
	const lastArg = rest[rest.length - 1]
	const opts: { revalidateIfStale?: boolean; fallbackData?: any } = isObject(lastArg) ? lastArg : {}

	const hasKey = useSwrHasKey(key)

	if (opts.fallbackData !== undefined) {
		opts.revalidateIfStale = hasKey
	}

	return useSwr(key || SWR_NO_FETCH_KEY, {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		// Default to revalidating on mount if a cached result exists.
		// This is important for switching between pages
		// or when components are destroyed and recreated later.
		revalidateIfStale: true,
		...opts,
	})
}

export const useSwrHasKey = (key): boolean => {
	return !!useSWRConfig().cache.get(key)
}

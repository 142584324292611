import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { createDescription } from '@/utils/toHTML'

const useStyles = makeStyles((theme) => ({
	root: {
		wordBreak: 'break-word',
		fontFamily: 'Open Sans',

		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	},
}))

interface DescriptionHtmlProps {
	description: string
	className?: string
	onClick?: (e: any) => void
}

const DescriptionHtml = ({ description, className = '', onClick = () => {} }: DescriptionHtmlProps) => {
	const classes = useStyles()

	return <div className={classNames(classes.root, className)} onClick={onClick} dangerouslySetInnerHTML={createDescription(description)} />
}

export default DescriptionHtml

import { AppState } from '@/store/app/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/app/constants'

const selectState = (state: { [MODULE_NAME]: AppState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getNotifications = createSelector(selectState, (state) => state.notifications)
export const getExitConfirmationModal = createSelector(selectState, (state) => state.exitConfirmationModal)
export const getSideBarOpened = createSelector(selectState, (state) => state.sideBarOpened)
export const getError = createSelector(selectState, (state) => state.error)

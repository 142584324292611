import { TextButton } from '@/components/Buttons'
import DotButton from '@/components/Buttons/DotButton'
import Card from '@/components/IndividualPage/Card'
import EditPrimaryContactModal from '@/components/IndividualPage/EditPrimaryContactModal'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useOrganizationMembershipsList from '@/features/organizations/hooks/useOrganizationMembershipsList'
import { useAppDispatch } from '@/store'
import { changeOrganizationContactRequest, getSelectedOrganization } from '@/store/organizations'
import { getOrganizationType } from '@/utils/organizationRoles'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userRow: {
		margin: '8px 0',
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
}))

interface OrganizationPrimaryContactDashboardCardProps {
	canEdit?: boolean
}

const OrganizationPrimaryContactDashboardCard = ({ canEdit = false }: OrganizationPrimaryContactDashboardCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const leadershipMemberTypeIds = [getOrganizationType(ORGANIZATION_ROLE.SUPER_ADMIN), getOrganizationType(ORGANIZATION_ROLE.ADMIN)]

	const organization = useSelector(getSelectedOrganization)

	const [isOpenModal, setIsOpenModal] = useState(false)

	const { canLoadMore, memberships, isFetching, isLoading, loadMore } = useOrganizationMembershipsList({
		id: organization.id,
		membershipTypeIds: leadershipMemberTypeIds,
	})

	const contact = useMemo(
		() => memberships.find(({ id }) => organization?.pointOfContact.id === id),
		[memberships, organization?.pointOfContact.id],
	)

	const handleToggleModal = useCallback(() => {
		setIsOpenModal((prev) => !prev)
	}, [])

	const handleChangePrimaryContact = useCallback(
		(newContactId: number) => {
			dispatch(changeOrganizationContactRequest(newContactId))
		},
		[dispatch],
	)

	const renderButtons = useMemo(
		() => [
			{
				label: 'Change Primary Contact',
				onClick: (e: any) => {
					e.stopPropagation()
					handleToggleModal()
				},
				icon: null,
			},
		],
		[handleToggleModal],
	)

	return (
		<Card>
			<EditPrimaryContactModal
				isFetching={isFetching}
				loadMore={loadMore}
				canLoadMore={canLoadMore}
				isLoading={isLoading}
				isOpen={isOpenModal}
				contact={contact}
				memberships={memberships}
				onChangeContact={handleChangePrimaryContact}
				handleCloseModal={handleToggleModal}
			/>
			<div className={classes.titleContainer}>
				<Typography variant="body1">
					<b>Primary Contact</b>
				</Typography>
				{canEdit && <TextButton onClick={handleToggleModal}>Edit</TextButton>}
			</div>
			{contact ? (
				<MembershipInfoRow
					classnames={{
						userRow: classes.userRow,
					}}
					user={contact.user}
					membershipTitle={contact.membershipTitle}
					userEmail={contact.userEmail}
					openProfileOnClick
					buttons={
						canEdit ? (
							<DotButton variant="secondary" className={classes.moreButton} showReportButton={false} renderButtons={renderButtons} />
						) : null
					}
				/>
			) : (
				<Typography variant="body1">Not selected</Typography>
			)}
		</Card>
	)
}

export default OrganizationPrimaryContactDashboardCard

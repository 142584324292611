import EventCard from '@/components/ExperienceCards/EventCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { getFutureShifts, getIsLoading, getPastShifts } from '@/store/volunteer'
import { loadUserVolunteerShiftsRequest } from '@/store/volunteer/actions'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router-dom'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		height: '90%',
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
}))

const tabs = ['All', 'Scheduled', 'Past']

const YourVolunteerShifts = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const [tab, setTab] = useState(0)

	const isLoading = useSelector(getIsLoading)
	const futureShifts = useSelector(getFutureShifts)
	const pastShifts = useSelector(getPastShifts)

	useEffect(() => {
		dispatch(loadUserVolunteerShiftsRequest())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Volunteer Events',
				onClick: () => push(PATHS.APP.VOLUNTEERS_EVENTS),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const handleChangeTab = useCallback((tabIndex: number) => {
		setTab(tabIndex)
	}, [])

	const tabInfo = useMemo(() => {
		const allShifts = [...futureShifts, ...pastShifts]
		if (tab === 0)
			return {
				title: 'My Volunteer Shifts',
				info: `${allShifts.length}`,
				items: allShifts,
			}
		if (tab === 1)
			return {
				title: 'Scheduled Shifts',
				info: `${futureShifts.length}`,
				items: futureShifts,
			}
		return {
			title: 'Past Shifts',
			info: `${pastShifts.length}`,
			items: pastShifts,
		}
	}, [futureShifts, pastShifts, tab])

	const emptyPlaceholder = useMemo(() => {
		const showNoShiftsPlaceholder = !(futureShifts.length + pastShifts.length) && !isLoading
		const showNoScheduledShiftsPlaceholder = !futureShifts.length && !isLoading
		const showNoPastShiftsPlaceholder = !pastShifts.length && !isLoading

		if (tab === 0 && showNoShiftsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.volunteers} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No shifts at the moment!
					</Typography>
					<Typography align="center">Your upcoming and past volunteer</Typography>
					<Typography align="center">shifts will appear here. You can filter</Typography>
					<Typography align="center">at the top.</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 1 && showNoScheduledShiftsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.volunteers} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No shifts at the moment!
					</Typography>
					<Typography align="center">Your upcoming volunteer</Typography>
					<Typography align="center">shifts will appear here.</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 2 && showNoPastShiftsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.volunteers} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No events at the moment!
					</Typography>
					<Typography align="center">Your past volunteer</Typography>
					<Typography align="center">shifts will appear here.</Typography>
				</EmptyPlaceholder>
			)

		return null
	}, [classes.placeholderHeader, futureShifts.length, isLoading, pastShifts.length, placeholderButtons, tab])

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title={tabInfo.title} pageInfo={tabInfo.info} tabs={tabs} handleChangeTab={handleChangeTab} activeTab={tab} />
			{emptyPlaceholder ? (
				emptyPlaceholder
			) : (
				<ExperienceCardsSection
					showPagination
					items={tabInfo.items}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(
								({
									name,
									startDate,
									endDate,
									photoUrl,
									locationName,
									id,
									description,
									response,
									organization,
									categories,
									volunteerShifts,
									submittedByUserId,
								}: any) => (
									<EventCard
										key={`${id}-${name}-${startDate}`}
										id={id}
										title={name}
										photoUrl={photoUrl}
										startDate={startDate}
										categories={categories}
										endDate={endDate}
										description={description}
										organization={organization}
										locationName={locationName}
										eventResponse={response}
										volunteerShifts={volunteerShifts}
										submittedByUserId={submittedByUserId}
									/>
								),
							)}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourVolunteerShifts

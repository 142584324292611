import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import * as actions from '@/store/events/actions'
import { EventsState } from '@/store/events/types'
import settings from '@/constants/http'

const initialState: EventsState = {
	search: {
		page: 0,
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	events: [],
	isLoading: false,
	isLoadingUserEvents: false,
	isUploadingNewEvent: false,
	categories: [],
	userEvents: {
		createdByUser: [],
		going: [],
		interested: [],
		notInterested: [],
		past: [],
	},
	isLoadingIndividualEvent: false,
	addToCalendarModal: {
		isOpen: false,
		name: '',
		description: '',
		startDate: '',
		endDate: '',
	},
	isOpenNotInterestedEventModal: false,
	createEventDefaultValues: {},
}

const eventsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setEvents, (state, action) => ({
			...state,
			events: action.payload,
		}))
		.addCase(actions.setCategories, (state, action) => ({
			...state,
			categories: action.payload,
		}))
		.addCase(actions.loadUserEventsRequest.pending, (state) => ({
			...state,
			isLoadingUserEvents: true,
		}))
		.addCase(actions.loadUserEventsRequest.fulfilled, (state) => ({
			...state,
			isLoadingUserEvents: false,
		}))
		.addCase(actions.loadUserEventsRequest.rejected, (state) => ({
			...state,
			isLoadingUserEvents: false,
		}))
		.addCase(actions.setUserEvents, (state, action) => ({
			...state,
			userEvents: {
				...action.payload,
			},
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			events: [],
		}))
		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					...rest,
				},
			}
		})
		.addCase(actions.loadMore, (state) => {
			state.search.page += settings.SEARCH_LIST_PER_PAGE
		})
		.addCase(actions.setIndividualEvent, (state, action) => ({
			...state,
			selectedEvent: action.payload,
		}))
		.addCase(actions.loadEventByIdRequest.pending, (state) => ({
			...state,
			isLoadingIndividualEvent: true,
			selectedEvent: undefined,
		}))
		.addCase(actions.loadEventByIdRequest.rejected, (state) => ({
			...state,
			isLoadingIndividualEvent: false,
		}))
		.addCase(actions.loadEventByIdRequest.fulfilled, (state) => ({
			...state,
			isLoadingIndividualEvent: false,
		}))
		.addCase(actions.setIndividualEventResponse, (state, action) => ({
			...state,
			selectedEvent: {
				...state.selectedEvent!,
				response: action.payload,
			},
		}))
		.addCase(actions.createEventRequest.pending, (state) => ({
			...state,
			isLoadingUserEvents: true,
		}))
		.addCase(actions.createEventRequest.rejected, (state) => ({
			...state,
			isLoadingUserEvents: false,
		}))
		.addCase(actions.createEventRequest.fulfilled, (state) => ({
			...state,
			isLoadingUserEvents: false,
		}))
		.addCase(actions.updateEvent, (state, action) => ({
			...state,
			events: state.events.map((event) => (event.id === action.payload.id ? { ...event, response: action.payload.status } : event)),
		}))
		.addCase(actions.openAddToCalendarModal, (state, action) => ({
			...state,
			addToCalendarModal: action.payload,
		}))
		.addCase(actions.openNotInterestedEventPopup, (state, action) => ({
			...state,
			isOpenNotInterestedEventModal: action.payload,
		}))
		.addCase(actions.setCreateEventFormDefaultValues, (state, { payload }) => ({
			...state,
			createEventDefaultValues: payload,
		}))
		.addCase(actions.deleteEvent, (state, { payload }) => ({
			...state,
			events: state.events.filter((e) => e.id !== payload),
		}))
})

export { eventsReducer }

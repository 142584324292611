import getUserActivity from './getUserActivity'
import getUserInfo from './getUserInfo'
import getUserProfileImage from './getUserProfileImage'
import patchUserPreference from './patchUserPreference'
import login from './login'
import storeSignUp from './storeSignUp'
import updateUserProfile from './updateUserProfile'
import getUserOrganizationPermissions from './getUserOrganizationPermissions'
import patchUserPrivacySettings from './patchUserPrivacySettings'
import patchUserSelfReportedItems from './patchUserSelfReportedItems'
import getUserPrivacyTypes from './getUserPrivacyTypes'
import getEntityPrivacySettings from './getEntityPrivacySettings'
import getUserPreferences from './getUserPreferences'

const user = {
	getUserActivity,
	getUserInfo,
	getUserProfileImage,
	patchUserPreference,
	login,
	storeSignUp,
	updateUserProfile,
	getUserOrganizationPermissions,
	patchUserPrivacySettings,
	patchUserSelfReportedItems,
	getUserPrivacyTypes,
	getEntityPrivacySettings,
	getUserPreferences,
}

export default user

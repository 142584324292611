import { useMemo } from 'react'
import { selectStudyAbroad, useGetStudyAbroadQuery } from '../api'

const useStudyAbroadByIdQuery = (id: string, skip?: boolean) => {
	const selectNormalizedStudyAbroad = useMemo(selectStudyAbroad, [])
	const studyAbroadQuery = useGetStudyAbroadQuery(
		{ id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedStudyAbroad(result.data) : undefined,
				}
			},
		},
	)
	return studyAbroadQuery
}

export default useStudyAbroadByIdQuery

import { makeStyles } from '@material-ui/core/styles'
import Card from '@/components/IndividualPage/Card'
import ItemAboutRow from '@/components/IndividualPage/ItemAboutRow'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import ExternalUrlCard from '@/components/IndividualPage/ExternalUrlCard'

const useStyles = makeStyles({
	descriptionContainer: {
		marginTop: 15,
	},
})

interface AboutCardProps {
	url?: string
	description?: string
	infoItems?: {
		header?: string
		label: string
		icon: React.ReactNode
	}[]
	followers?: number
	posts?: number
	members?: number
}

const AboutCard = ({ description, url = '', infoItems = [] }: AboutCardProps) => {
	const classes = useStyles()

	return (
		<>
			<Card title="About">
				{infoItems.map((item) =>
					item.label ? <ItemAboutRow key={item.label} header={item.header} icon={item.icon} label={item.label} /> : null,
				)}
				{description && (
					<div className={classes.descriptionContainer}>
						<DescriptionHtml description={description} />
					</div>
				)}
			</Card>
			<ExternalUrlCard url={url} />
		</>
	)
}

export default AboutCard

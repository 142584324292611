import http from '@/api/http/requestHttp'
import { PostResponseStatuses } from '@/interfaces/common'
import { UserInfo } from '@/interfaces/user'

interface PostOrganizationUserResponseParams {
	id: string
	userId: UserInfo['id']
	response: PostResponseStatuses
	reportMessage?: string
	schoolId: number
}

const postOrganizationUserResponse = async ({ id, userId, response, reportMessage, schoolId }: PostOrganizationUserResponseParams) => {
	const url = `${schoolId}/organizations/${id}/response`
	const body = { userId, response, reportMessage }

	return http.post(url, body)
}

export default postOrganizationUserResponse

import { Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useCallback } from 'react'

import { IconWrapper, InfoModalCard } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	submitText: {
		margin: '10px 0',
	},
	doneText: {
		margin: '10px 0',
	},
}))

interface SuccessPopupProps {
	isOpen: boolean
	onClose: () => void
	submittedMessage: string[]
	title?: string
}

export const SuccessPopup = ({ isOpen, onClose, submittedMessage, title = 'Done' }: SuccessPopupProps) => {
	const classes = useStyles()
	const theme = useTheme()
	const handleClose = useCallback(() => {
		onClose()
	}, [onClose])

	return (
		<Modal open={isOpen} onClose={handleClose} className={classes.modal}>
			<InfoModalCard
				onClose={onClose}
				icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
			>
				<Typography className={classes.doneText}>{title}</Typography>
				{submittedMessage.map((text, idx) => (
					<Typography key={`${text.trim()}-${idx}`}>{text}</Typography>
				))}
			</InfoModalCard>
		</Modal>
	)
}

export default SuccessPopup

import http from '@/api/http/requestHttp'
import { Except } from 'type-fest'
import { CommonUserConnectionParams } from './types'

const getBlockedUsersList = async ({ userId, schoolId }: Except<CommonUserConnectionParams, 'targetUserId'>) => {
	const url = `${schoolId}/network/${userId}/blocked`

	return http.get(url)
}

export default getBlockedUsersList

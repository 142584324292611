import { ReactNode } from 'react'

import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { AnySchema } from 'yup'
import { LearnMoreProps, MODAL_COMPONENT_MAP } from '../components/AdminModalContent'
import { EditableOnboardingSteps } from './EditableOnboardingSteps.enum'
import { SignupDashboardTableProps } from '../components/dataTable/SignupDashboardTable'

export enum DashboardType {
	FEED = 'feed',
	FEED_COMMENTS = 'feedComments',
	HOME = 'home',
	EVENTS = 'events',
	USERS = 'users',
	PATH_BUILDER = 'pathBuilder',
	ORGANIZATIONS = 'organizations',
}

export enum ModalKind {
	ENTITY_SEARCH = 'entitySearch',
	LEARN_MORE = 'learnMore',
	FORM = 'form',
}

export type DataTableConfig = {
	label: string
	entityType: EntityTypes
	entityScope?: EntityScope
	description?: string
	onboardingStep?: EditableOnboardingSteps
	learnMoreProps?: LearnMoreProps
	deleteMutation: SignupDashboardTableProps['deleteMutation']
}

export type ActionButtonProps = {
	label: ReactNode
	onClick?: () => void
	icon?: ReactNode
	isVisible?: boolean
}

type ComponentMap = typeof MODAL_COMPONENT_MAP

export type ModalComponentContent<T extends keyof ComponentMap> = (props: React.ComponentProps<ComponentMap[T]>) => JSX.Element

export type ModalComponentContentProps<T extends keyof ComponentMap> = Parameters<ModalComponentContent<T>>['0']

export type ModalContentPropMap = {
	[K in ModalKind]: React.ComponentProps<ComponentMap[K]>
}

export type OneOrMore<T> = [T, ...T[]]

export type ModalState = {
	modalKind: ModalKind
	isOpen: boolean
}
export type ContentPropsMapKey<T extends keyof ModalContentPropMap> = `${T}Props`

export enum FieldKind {
	INPUT = 'input',
	TEXT_AREA = 'textArea',
	DATE = 'date',
	DATE_TIME = 'dateTime',
	SELECT = 'select',
	RADIO = 'radio',
	CHECKBOX = 'checkbox',
	MULTI_SELECT = 'multiSelect',
}

type SelectableField = FieldKind.SELECT | FieldKind.RADIO | FieldKind.CHECKBOX | FieldKind.MULTI_SELECT

export type BaseFormField<T extends FieldKind> = {
	label: string
	name: string
	kind: T
	isRequired: boolean
	icon?: ReactNode
	placeholder?: string
	enabled?: boolean
	customValidation?: AnySchema | null
}

export type SelectableFormField<T extends SelectableField> = BaseFormField<T> & {
	options: [string, ...string[]]
}

export type FormField<T extends FieldKind> = T extends SelectableField ? SelectableFormField<T> : BaseFormField<T>

export type FieldList = OneOrMore<FormField<FieldKind>>

import { APP_URL, SCHOOL_ID } from '@/constants/configuration'
import { getBaseUrl, getQueryParamByName } from './urlHandlers'

const API_VERSION = 'v1.0'
const BASE_URL = getBaseUrl()
const generateAuthorizationUrl = () => {
	const redirectPath = decodeURIComponent(getQueryParamByName('redirect_path'))
	const params = new URLSearchParams({
		redirectUri: APP_URL,
		schoolId: String(SCHOOL_ID),
	})

	if (!!redirectPath) {
		params.append('state', redirectPath)
	}

	return `${BASE_URL}${API_VERSION}/auth/authorize?${params.toString()}`
}

export default generateAuthorizationUrl

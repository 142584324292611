import { ContentPreloader } from '@/components/ContentPreloader'
import InfiniteScrollComponent from '@/components/ItemsSections/InfiniteScrollComponent'
import { TruncateTextWithTooltip } from '@/components/Text/TruncateTextWithTooltip'
import { Avatar, Checkbox, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Radio, makeStyles } from '@material-ui/core'
import React from 'react'
import useCurrentUserOrgs from '../hooks/useCurrentUserOrgs'
import { UserOrganizationTypes } from '../types/UserOrganizationTypes'
import { DtoOrganization } from '../types/dto/DtoOrganization'

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		'& li': {
			listStyle: 'none',
		},
	},
	content: {
		width: '100%',
		margin: 0,
	},
}))

export type TOrganizationPickerProps = {
	roleType?: UserOrganizationTypes
	multiSelect?: boolean
	value: Record<DtoOrganization['id'], boolean>
	onToggle: (id: DtoOrganization['id']) => void
}

const OrganizationPicker: React.FC<TOrganizationPickerProps> = ({
	onToggle,
	roleType = UserOrganizationTypes.member,
	multiSelect,
	value,
}) => {
	const classes = useStyles()
	const { showPreloader, canLoadMore, loadMore, data } = useCurrentUserOrgs(roleType)

	const handleToggle = (id: DtoOrganization['id']) => () => {
		onToggle(id)
	}

	return showPreloader ? (
		<ContentPreloader />
	) : (
		<InfiniteScrollComponent
			classnames={{
				content: classes.content,
				contentContainer: classes.contentContainer,
			}}
			resetScroll
			internalScroll
			canLoadMore={canLoadMore}
			height={500}
			dataLength={data?.items.length}
			onLoadMore={loadMore}
		>
			{data?.items.map((item) => (
				<ListItem component="div" key={item.id} button dense onClick={handleToggle(item.id)}>
					<ListItemAvatar>
						<Avatar alt={item.name} src={item.profilePicture} />
					</ListItemAvatar>
					<ListItemText
						id={item.id}
						primary={item.name}
						secondary={
							<TruncateTextWithTooltip lines={1}>
								<span>{item.summary}</span>
							</TruncateTextWithTooltip>
						}
					/>
					<ListItemSecondaryAction>
						{multiSelect ? (
							<Checkbox edge="end" checked={!!value[item.id]} inputProps={{ 'aria-labelledby': item.id }} />
						) : (
							<Radio edge="end" checked={!!value[item.id]} inputProps={{ 'aria-labelledby': item.id }} />
						)}
					</ListItemSecondaryAction>
				</ListItem>
			))}
		</InfiniteScrollComponent>
	)
}

export default OrganizationPicker

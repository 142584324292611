import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import ROUTES from '@/routes'
import { getIsStartBuildPath } from '@/store/pathBuilder'
import { PATHS } from '@/constants'
import Router from '@/components/Router'

const PathBuilderPage = () => {
	const pathBuilderOpened = useSelector(getIsStartBuildPath)

	if (!pathBuilderOpened) return <Redirect to={PATHS.APP.PROFILE} />

	return <Router routes={ROUTES.pathBuilder} />
}

export default PathBuilderPage

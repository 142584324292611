import http from '@/api/http/requestHttp'
import { DtoGroupChatItem, GroupChatTypes } from '@navengage/sen-types'

export interface PatchGroupBody {
	name?: string
	description?: string
	type?: GroupChatTypes
	thumbnail?: string
	restrictedEdit?: boolean
	restrictedAdminManagement?: boolean
	restrictedMemberManagement?: boolean
}

const patchGroupById = async (id: string, data: PatchGroupBody, schoolId: number) => {
	const url = `${schoolId}/groups/${id}/edit`

	return http.patch<DtoGroupChatItem>(url, data)
}

export default patchGroupById

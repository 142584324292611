import { MouseEvent, useRef, useState } from 'react'
import useOutsideClickCallback from './useOutsideClickCallback'

type UsePopperProps = void
const usePopper = (props: UsePopperProps) => {
	const popperRef = useRef<any>(null)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const isOpen = Boolean(anchorEl)

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const handleClose = (event: MouseEvent<EventTarget>) => {
		if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
			return
		}
		setAnchorEl(null)
	}

	const handleCloseAfterItemClick = (callback: (e: any) => void) => (e: any) => {
		handleClose(e)
		callback(e)
	}

	useOutsideClickCallback(popperRef, handleClose)

	return {
		popperRef,
		anchorEl,
		isOpen,
		handleClick,
		handleClose,
		handleCloseAfterItemClick,
	}
}

export default usePopper

import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import SignUpCard from '@/components/SignUpCard'
import UserPhotoDropArea from '@/components/UserProfile/UserPhotoDropArea'
import { STYLES } from '@/constants'
import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'
import { SignUpForm } from '@/store/auth/types'
import { rootStyles } from '@/styles'
import { Analytics } from '@/utils/amplitude'
import uploadPhoto from '@/utils/photoUpload'
import { getWebviewHelper } from '@/utils/webviewHelper'
import { Link, Typography } from '@material-ui/core'
import { UserEvents } from '@navengage/amplitude'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useSignupStepsContext } from './SignupStepsContext'
import useSchoolId from '@/features/school/hooks/useSchoolId'

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '45%',
		marginBottom: 40,
	},
	card: {
		height: '75vh',
	},
	cardHeader: {
		width: '70%',
	},
	cardHeaderSubTitle: {
		color: theme.palette.text.primary,
	},
	textField: {
		marginBottom: 15,
	},
	infoText: {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		marginTop: 10,
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
	},
	photoWrapper: {
		border: '1px solid #D7D9DE',
		borderRadius: '10px',

		...rootStyles.flexCenteredItems,
	},
	photo: {
		borderRadius: '50%',
	},
}))

const { emitter: webViewEmitter, isWebview } = getWebviewHelper()

const UserPhotoStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const schoolId = useSchoolId()

	const {
		school: { accounts },
		features: {
			onboarding: {
				config: { profilePic: profilePicConfig },
			},
			backupPhoto,
		},
		links: { global },
	} = useSchoolConfig()
	const { formData, userInfo, patchFormData } = useSignupStepsContext()
	const [userPhotoUrl, setUserPhotoUrl] = useState(formData.patches.photoUrl || '')

	const disableContinueButton = !checkCanProceed(userPhotoUrl)

	const handleChange = (value: string) => {
		if (isWebview) {
			webViewEmitter.emit('signup', { status: 'inProgress', step: 'profilePic' })
		}
		uploadPhotoByBlobUrl(value)
	}

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.UPLOADED_AVATAR_SIGNUP)
	}

	const onClickSkip = () => {
		onClickNext()
		Analytics.track(UserEvents.SKIPPED_AVATAR_SIGNUP)
	}

	const uploadPhotoByBlobUrl = async (blobUrl) => {
		setUserPhotoUrl(blobUrl)

		const { data } = await uploadPhoto({
			schoolId,
			fileUrl: blobUrl,
			isPrivateImage: true,
			imageName: uuidv4(),
			directory: PhotoUploadDirectories.PROFILE_PHOTO,
			options: {
				compressImage: true,
				onError: {
					uploadErrors: UploadErrors.PROFILE,
					uploadErrorMessage: UploadErrorMessage.POST,
				},
			},
		})

		patchFormData({
			patches: {
				photoUrl: data.slug,
			},
		})
	}

	return (
		<SignUpCard
			classnames={{
				root: classes.card,
				header: classes.cardHeader,
				subTitle: classes.cardHeaderSubTitle,
			}}
			title={profilePicConfig.title}
			continueButtonText={disableContinueButton ? 'Continue' : 'Next'}
			continueButtonDisabled={disableContinueButton}
			showSkipButtonWhenContinueDisabled={true}
			onClickContinue={onClickContinue}
			onClickSkip={onClickSkip}
		>
			<div className={classes.content}>
				<div className={classes.photoWrapper}>
					<UserPhotoDropArea
						enableCropping={!isWebview}
						externalUserId={userInfo.externalId}
						userPhotoUrl={userInfo.photoUrl}
						url={userPhotoUrl}
						onChange={handleChange}
						loadFromServer={userPhotoUrl && !userPhotoUrl.startsWith('blob:')}
					/>
				</div>
				{!backupPhoto.disabled && !!global.accounts?.href && (
					<Typography className={classes.infoText} variant="body2">
						This is your photo in
						<b>
							<Link className={classes.link} href={global.accounts.href} target="_blank">
								{global.accounts.label}
							</Link>
						</b>
						. Changing it here will not change your name in {accounts}.
					</Typography>
				)}
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (userPhotoUrl) => {
	return userPhotoUrl?.trim().length
}
UserPhotoStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.photoUrl)
}

export default UserPhotoStep

import { makeStyles, Theme } from '@material-ui/core'

interface StyleProps {
	backgroundColor?: string
}

interface SkeletonProps extends StyleProps {}

const useStyles = makeStyles<Theme, SkeletonProps>((theme) => ({
	'@keyframes shine': {
		to: { backgroundPosition: 'right -40px top 0' },
	},
	skeleton: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		zIndex: 1,
		left: '0',
		top: '0',
		backgroundColor: ({ backgroundColor = theme.palette.background.default }) => backgroundColor,
		backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
		backgroundSize: '40px 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left -40px top 0',
		animation: '$shine 1s ease infinite',
	},
}))

const Skeleton = ({ backgroundColor }: SkeletonProps) => {
	const classes = useStyles({ backgroundColor })
	return <div className={classes.skeleton} />
}

export { Skeleton }

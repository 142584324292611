import React, { useCallback, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '@/store'
import { getFeedsTagsOptions } from '@/store/feeds'
import Tags from '../../../../components/filters/Tags'
import { selectFeedTags, setFilterTags } from '../../feed/slice'

const FeedTags = () => {
	const tagOptions = useAppSelector(getFeedsTagsOptions)
	const dispatch = useAppDispatch()
	const selectedTagsMap = useRef<Map<number, number>>(new Map())
	const selectedTags = useAppSelector(selectFeedTags)
	const handleToggleTag = useCallback(
		(id) => {
			if (!selectedTagsMap.current.has(id)) {
				selectedTagsMap.current.set(id, id)
			} else {
				selectedTagsMap.current.delete(id)
			}
			dispatch(setFilterTags(Array.from(selectedTagsMap.current.keys())))
		},
		[dispatch],
	)

	return <Tags tags={tagOptions} selectedTags={selectedTags} toggleFilter={handleToggleTag} />
}

export default FeedTags

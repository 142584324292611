import React from 'react'
import { useGetMembersQuery } from '../../api'
import { DtoOrganization } from '../../types/dto/DtoOrganization'
import InfoBadge from '../InfoBadge'
import GetMembersParams from '../../types/queryParams/GetMembersParams'

export type IMembersCountProps = {
	orgId: DtoOrganization['id']
	type?: GetMembersParams['type']
}

const MembersCount: React.FC<IMembersCountProps> = ({ orgId: id, type = 'members' }) => {
	const { data, isLoading, isFetching } = useGetMembersQuery({ id, skip: 0, type })

	return <InfoBadge isLoading={isLoading || isFetching} count={data?.totalItems} label="Members" />
}

export default MembersCount

import React from 'react'
import { useStyles } from './FeedsCard/styles'
import { Card as MaterialCard, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import useGlobalStyles from '@/styles/globalStyles'

export type IFeedsCardContainerErrorProps = {
	variant?: 'primary' | 'secondary'
}

const useErrorStyles = makeStyles((theme) => ({
	cardContent: {
		height: 'auto',
		textAlign: 'center',
	},
}))

const ErrorFeedCard = () => {
	const globalClasses = useGlobalStyles()
	const classes = useStyles({ primary: true })
	const errorCardClasses = useErrorStyles()
	const titleBlock = (
		<div className={classNames(classes.cardContentTitleContainer)}>
			<Typography className={classes.cardContentTitle}>Content Unavailable</Typography>
			<Typography className={classNames(classes.cardContentSubTitle)}>
				This content is unavailable. It may have been deleted or you may not have permissions to see it.
			</Typography>
		</div>
	)
	return (
		<div className={classes.rootContainer}>
			<MaterialCard className={classNames(classes.card, globalClasses.alignCenter)}>
				<div className={classNames(classes.cardContent, errorCardClasses.cardContent)}>{titleBlock}</div>
			</MaterialCard>
		</div>
	)
}

export default ErrorFeedCard

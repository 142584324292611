import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { ColorButton, CommonModalCard, CommonSelect, DatePickerSelect, FilledTextField } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import { intendedGraduationTermOptions } from '@/constants/defaultValues'
import { useGetResearchFiltersQuery } from '@/features/research/api'
import { ProfileInfoCardTypes, getSingleItemLabel } from '@/utils/userExperienceItems'

const useStyles = makeStyles((theme) => ({
	selectsContainer: {
		width: '60%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: '1.5vh 0 2.5vh 0',
	},
	select: {
		width: '100%',
	},
	textField: {
		width: '50%',
		margin: '1vh 0',
	},
	addCardAddBtn: {
		marginTop: '3vh',
	},
	saveButton: {
		padding: '1vh 1vw',
		minWidth: 230,
		fontWeight: 600,
	},
	formItem: {
		width: '100%',
		marginTop: '1vh',
		marginRight: 20,
	},
	label: {
		color: theme.palette.text.primary,
		fontSize: 13,
		textAlign: 'left',
		margin: '5px 0',
	},
}))

const createEmptyItem = (type: ProfileInfoCardTypes) => {
	switch (type) {
		case ProfileInfoCardTypes.MEMBERSHIPS:
		case ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT:
			return {
				position: '',
				department: '',
				startDate: '',
				endDate: '',
			}
		case ProfileInfoCardTypes.INTERNSHIPS:
			return {
				position: '',
				employer: '',
				startDate: '',
				endDate: '',
			}
		case ProfileInfoCardTypes.EDUCATION_ABROAD:
			return {
				programTitle: '',
				location: '',
				startDate: '',
				endDate: '',
			}
		case ProfileInfoCardTypes.RESEARCH:
			return {
				title: '',
				area: '',
				startDate: '',
				endDate: '',
			}
		default:
			return {}
	}
}

export const singleType: any = {
	[ProfileInfoCardTypes.MEMBERSHIPS]: 'Membership',
	[ProfileInfoCardTypes.INTERNSHIPS]: 'Internship',
	[ProfileInfoCardTypes.EDUCATION_ABROAD]: 'Education Abroad Experience',
	[ProfileInfoCardTypes.RESEARCH]: 'Research Experience',
	[ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT]: 'Additional Involvement',
}

interface ItemFormProps {
	className?: string
	item?: any
	type: ProfileInfoCardTypes
	onSubmit?: (v: any) => void
	onClose?: () => void
	isEdit?: boolean
}

const ItemForm = ({ className = '', type, item = {}, onSubmit = () => {}, onClose, isEdit }: ItemFormProps) => {
	const classes = useStyles()
	const [newItem, setNewItem] = useState(Object.keys(item).length ? { ...item } : createEmptyItem(type))
	const { data, isLoading } = useGetResearchFiltersQuery()
	const researchAreas = useMemo(() => data?.positionAreas ?? [], [data])
	const researchAreasOptions = useMemo(() => researchAreas.map(({ label }) => ({ label, value: label })), [researchAreas])

	const checkKey = (key: string) => newItem.hasOwnProperty(key)

	const cantSubmit = Object.keys(newItem).some((key) => !newItem[key] && newItem[key] !== null)

	const submitButtonLabel = !isEdit ? `Done` : `Save`
	const handleChangeItem = (key: string) => (e: any) => setNewItem({ ...newItem, [key]: e.currentTarget.value })
	const handleSelectChangeItem = (key: string) => (value: any) => setNewItem({ ...newItem, [key]: value })

	const handleSubmit = () => {
		onSubmit({ ...newItem, id: newItem.id ?? uuidv4() })
	}

	return (
		<CommonModalCard
			title={`${isEdit ? 'Edit' : 'Add'} ${getSingleItemLabel(
				type,
				false,
				type === ProfileInfoCardTypes.EDUCATION_ABROAD ? ' Experience' : '',
			)}`}
			subTitle={`Completed ${getSingleItemLabel(
				type,
				true,
				type === ProfileInfoCardTypes.EDUCATION_ABROAD ? ' Experience' : '',
				true,
			)}? Add it here!`}
			onClose={onClose}
			footerContent={
				<ColorButton className={classes.saveButton} onClick={handleSubmit} disabled={cantSubmit}>
					{submitButtonLabel}
				</ColorButton>
			}
			showFooter
		>
			<div className={className}>
				{isLoading ? (
					<ContentPreloader />
				) : (
					<>
						{checkKey('position') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Position</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add your position"
									onChange={handleChangeItem('position')}
									value={newItem.position}
								/>
							</div>
						)}
						{checkKey('department') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Department</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add your organization"
									onChange={handleChangeItem('department')}
									value={newItem.department}
								/>
							</div>
						)}
						{checkKey('programTitle') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Program Title</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add program title"
									onChange={handleChangeItem('programTitle')}
									value={newItem.programTitle}
								/>
							</div>
						)}
						{checkKey('location') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Location</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add location"
									onChange={handleChangeItem('location')}
									value={newItem.location}
								/>
							</div>
						)}
						{checkKey('employer') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Employer</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add your employer"
									onChange={handleChangeItem('employer')}
									value={newItem.employer}
								/>
							</div>
						)}
						{checkKey('title') && (
							<div className={classes.formItem}>
								<Typography className={classes.label}>Title</Typography>
								<FilledTextField
									variant="secondary"
									className={classes.textField}
									placeholder="Add research title"
									onChange={handleChangeItem('title')}
									value={newItem.title}
								/>
							</div>
						)}
						{(checkKey('startDate') || checkKey('endDate')) && (
							<div className={classes.selectsContainer}>
								<div className={classes.formItem}>
									<Typography className={classes.label}>Start Date</Typography>
									<DatePickerSelect
										className={classes.select}
										onChange={handleSelectChangeItem('startDate')}
										value={newItem.startDate}
										maxDate={newItem.endDate}
										defaultLabel="Select Start Date"
									/>
								</div>
								<div className={classes.formItem}>
									<Typography className={classes.label}>End Date</Typography>
									<DatePickerSelect
										className={classes.select}
										onChange={handleSelectChangeItem('endDate')}
										value={newItem.endDate}
										minDate={newItem.startDate}
										defaultLabel="Select End Date"
										showPresentButton
									/>
								</div>
							</div>
						)}
						{checkKey('area') && (
							<div className={classes.selectsContainer}>
								<div className={classes.formItem}>
									<Typography className={classes.label}>Area</Typography>
									<CommonSelect
										classnames={{
											input: classes.select,
										}}
										onChange={handleSelectChangeItem('area')}
										value={newItem.area}
										options={researchAreasOptions}
										placeholder="Select Area"
									/>
								</div>
							</div>
						)}
						{checkKey('term') && (
							<div className={classes.selectsContainer}>
								<div className={classes.formItem}>
									<Typography className={classes.label}>Term</Typography>
									<CommonSelect
										classnames={{
											input: classes.select,
										}}
										onChange={handleSelectChangeItem('term')}
										value={newItem.term}
										options={intendedGraduationTermOptions}
										placeholder="Select Term"
									/>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</CommonModalCard>
	)
}

export default ItemForm

import DotButton, { DotButtonProps } from '@/components/Buttons/DotButton'
import { preventDefaults } from '@/utils/dragAndDrop'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { IndividualEvent } from '../../store/events/types'
import { AdminBadge } from '../Badges/AdminBadge'
import ImageViewModal from '../ImageViewModal'
import PrivacyLockIcon from './PrivacyLockIcon'

const useStyles = makeStyles((theme) => ({
	image: (props: any) => ({
		position: 'relative',
		cursor: 'pointer',
		marginTop: '2vh',
		borderRadius: 5,
		aspectRatio: '16/9',
		width: '42vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		backgroundColor: theme.palette.background.paper,
		backgroundImage: `url(${props.imageUrl})${
			theme.palette.type === 'light' ? `, linear-gradient(0deg, rgba(154,154,154,1) 0%, rgba(0,0,0,1) 94%)` : ''
		}`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		[theme.breakpoints.down('lg')]: {
			width: '50vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '94vw',
		},
	}),
	buttonsContainer: {
		padding: 25,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},

	adminBadge: {
		margin: 0,
	},
	adminSectionContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	editButton: {
		width: 175,
		height: 50,
		marginLeft: 10,
		fontSize: 18,
		fontWeight: 550,
	},
}))

interface ImageCardProps {
	photoUrl?: IndividualEvent['photoUrl']
	isAdmin?: boolean
	isEvent?: boolean
	className?: string
	dotButton: DotButtonProps
	handleEdit?: () => void
	privacyType?: IndividualEvent['privacyType']
	editPageElement?: React.ReactNode
}

const ImageCard = ({
	className = '',
	photoUrl = '',
	isEvent = true,
	isAdmin = false,
	dotButton,
	editPageElement = null,
	privacyType,
}: ImageCardProps) => {
	const [imageUrl, setImageUrl] = useState(photoUrl)
	const [fullImageView, setFullImageView] = useState('')

	const classes = useStyles({ imageUrl })

	const handleOpenImageView = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		setFullImageView(imageUrl ?? '')
	}

	const handleCloseImageView = () => setFullImageView('')

	useEffect(() => {
		if (photoUrl) {
			setImageUrl(photoUrl)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [photoUrl])

	return (
		<>
			<ImageViewModal imageSrc={fullImageView} onClose={handleCloseImageView} />
			<div className={classNames(classes.image, className)} onClick={handleOpenImageView}>
				{privacyType && <PrivacyLockIcon size={'md'} privacyType={privacyType} />}
				<div className={classes.buttonsContainer}>
					<DotButton {...dotButton} />
					{isAdmin && (
						<div className={classes.adminSectionContainer} onClick={preventDefaults}>
							<AdminBadge rootClass={classes.adminBadge} size={50} />
							{editPageElement}
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default ImageCard

import { ICON_SIZES } from '@/constants/iconSizes'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { forwardRef } from 'react'
import IconWrapper, { IconButtonVariant } from '../IconWrapper'

interface StyleProps {
	variant?: 'primary' | 'secondary' // Primary is not filled, Secondary is filled with a white box to make it visible on a dark background
	value: boolean
	error?: boolean
	textColor?: string
	checkColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	checkBox: ({ variant, error, textColor, checkColor }) => ({
		color: error
			? theme.palette.error.main
			: checkColor
			? checkColor
			: variant === 'primary'
			? theme.palette.primary.dark
			: theme.palette.common.white,
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: error
				? theme.palette.error.main
				: variant === 'primary'
				? theme.palette.primary.dark
				: checkColor
				? checkColor
				: theme.palette.grey[900],
		},
	}),
	checkBoxLabel: ({ error, textColor }) => ({
		color: error ? theme.palette.error.main : textColor ? textColor : theme.palette.text.primary,
	}),
}))

interface CheckBoxProps extends StyleProps {
	disabled?: boolean
	onChange: (v: any) => void
	label?: React.ReactNode
	checkColor?: string
}

export const CheckBox = forwardRef<any, CheckBoxProps>(
	({ value = false, disabled = false, onChange = () => {}, label = '', variant = 'primary', error, textColor, checkColor }, ref) => {
		const classes = useStyles({ value, variant, error, textColor, checkColor })

		return (
			<FormControlLabel
				ref={ref}
				value="end"
				className={classes.checkBoxLabel}
				control={
					<Checkbox
						classes={{
							root: classes.checkBox,
						}}
						checked={value}
						onChange={onChange}
						disabled={disabled}
						inputProps={{ 'aria-label': 'primary checkbox' }}
						checkedIcon={
							<IconWrapper
								iconKey="checkSquare"
								weight={variant === 'primary' ? 'fill' : 'regular'}
								size={ICON_SIZES.lg}
								variant={IconButtonVariant.NO_BG}
								disabled={disabled}
								color={checkColor || (variant === 'primary' ? null : 'black')}
							/>
						}
						icon={
							<IconWrapper
								iconKey="square"
								size={ICON_SIZES.lg}
								variant={IconButtonVariant.NO_BG}
								color={checkColor || (variant === 'primary' ? null : 'black')}
							/>
						}
					/>
				}
				label={label}
				labelPlacement="end"
			/>
		)
	},
)

import * as http from '@/api/http'
import { SearchConfig, UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/studyAbroad/constants'
import { applyInterestedStatus, getCurrentFilterValues } from '@/utils/common'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setStudyAbroad = createAction<any>(`${MODULE_NAME}/SET_EDUCATION_ABROAD`)
export const setFilters = createAction<any>(`${MODULE_NAME}/SET_FILTERS`)

export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setUserStudyAbroad = createAction<any>(`${MODULE_NAME}/SET_USER_EDUCATION_ABROAD`)
export const setSelectedStudyAbroad = createAction<any>(`${MODULE_NAME}/SET_SELECTED_EDUCATION_ABROAD`)
export const setUserResponse = createAction<{ response: UserResponseStatuses; id: string }>(`${MODULE_NAME}/SET_USER_RESPONSE`)

export const loadStudyAbroadListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(
	`${MODULE_NAME}/LOAD_EDUCATION_ABROAD_LIST_WITH_FILTER_REQUEST`,
	async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
		try {
			dispatch(setIsLoading(true))
			const rootState = getState()
			const { schoolId, userId } = selectAuth(rootState)

			const storedStudyAbroad = rootState.EDUCATION_ABROAD.studyAbroad
			const storedSearch = rootState.EDUCATION_ABROAD.search

			const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

			const res = await http.studyAbroad.searchStudyAbroad({
				q: params?.query,
				page,
				userId,
				country: params?.locations,
				termOfAvailabilityId: params?.terms,
				housingTypeId: params?.housingTypes,
				scholarship: params?.scholarshipsOffered,
				schoolId,
			})

			const studyAbroad = fetchMore ? [...storedStudyAbroad, ...res.data] : res.data

			const studyAbroadWithFollowStatus = studyAbroad.map((res: any) => ({
				...res,
				responseStatus: res.response && res.response.response ? res.response.response : null,
			}))

			dispatch(
				setFilters({
					...currentConfig,
					endHasBeenReached: !res.data?.length,
					page,
					filterIsReady,
				}),
			)
			dispatch(setStudyAbroad(studyAbroadWithFollowStatus))
		} catch (e: any) {
			return rejectWithValue(e)
		} finally {
			dispatch(setIsLoading(false))
		}
	},
)

export const loadStudyAbroadByIdRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_EDUCATION_ABROAD_REQUEST`, async (id: string, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)

		const {
			data: { studyAbroad },
		} = await http.studyAbroad.getStudyAbroadById(id, schoolId)

		dispatch(setSelectedStudyAbroad(applyInterestedStatus(studyAbroad)))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { SelectOptionType } from '@/types/components'
import { DateTime } from 'luxon'

export const intendedGraduationTermOptions = [
	{ label: 'Spring', value: 'Spring' },
	{ label: 'Summer', value: 'Summer' },
	{ label: 'Fall', value: 'Fall' },
	{ label: 'Winter', value: 'Winter' },
]

export const pronouns = [
	{ label: 'He, Him, His', value: 'He, Him, His' },
	{ label: 'She, Her, Hers', value: 'She, Her, Hers' },
	{ label: 'They, Them, Theirs', value: 'They, Them, Theirs' },
]

export const intendedGraduationYearOptions = Array.from({ length: 10 }).reduce((acc: any, date, index) => {
	const newYear = DateTime.now().plus({ years: index }).toFormat('yyyy')

	return [...acc, { label: newYear, value: newYear }]
}, []) as SelectOptionType[]

import { ButtonColors } from '@/components/Buttons/types'
import { Button, ButtonProps as MuiButtonProps, Theme, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

interface StylesProps {
	color?: ButtonColors
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => {
	return {
		root: ({ color }) => {
			const buttonColor = theme.palette!.buttonColors[color ?? ButtonColors.PRIMARY]
			return {
				borderRadius: 7,
				backgroundColor: buttonColor.main,
				color: buttonColor.textColor,
				textTransform: 'none',
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: buttonColor.hover,
					color: buttonColor.textColor,
					boxShadow: 'none',
				},
				'&Mui-disabled': {
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.grey[500],
					'&>span': {
						opacity: 0.7,
					},
				},
				...theme.typography.body1,
				[theme.breakpoints.down('xs')]: {
					...theme.typography.subtitle1,
					padding: 0,
					minWidth: 100,
					minHeight: 36,
				},
				'&.Mui-disabled': {
					pointerEvents: 'auto',
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.grey[500],
					'&:hover': {
						backgroundColor: theme.palette.primary.light,
						color: theme.palette.grey[500],
						'&>span': {
							opacity: 0.7,
						},
					},
				},
			}
		},
	}
})

export interface ButtonProps extends StylesProps, Omit<MuiButtonProps, 'color'> {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	component?: any
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
}

const ColorButton = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			color = ButtonColors.PRIMARY,
			children = null,
			onClick,
			disabled,
			className,
			type = 'button',
			onMouseEnter,
			onMouseLeave,
			component,
			startIcon,
			endIcon,
			...buttonProps
		},
		ref,
	) => {
		const classes = useStyles({ color })

		return (
			<Button
				ref={ref}
				{...buttonProps}
				type={type}
				onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
					if (onClick) onClick(e)
				}}
				disabled={disabled}
				className={classNames(classes.root, className)}
				variant="contained"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				component={component}
				startIcon={startIcon}
				endIcon={endIcon}
			>
				{children}
			</Button>
		)
	},
)

export default ColorButton

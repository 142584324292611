import React, { useState } from 'react'

import { STYLES } from '@/constants'
import { CircularProgress, InputAdornment, makeStyles, TextField } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

export type ISearchBarProps = {
	placeholder?: string
	initialValue?: string
	onChange: (q: string) => void
	isLoading?: boolean
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontSize: '1em',
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.33)' : theme.shadows[10],
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
		'&.MuiOutlinedInput-input': {
			padding: '15.5px 14px',
		},
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	clearInputButton: {
		cursor: 'pointer',
	},
}))

const SearchBar: React.FC<ISearchBarProps> = ({ onChange, initialValue, placeholder, isLoading }) => {
	const [value, setValue] = useState(initialValue)
	const classes = useStyles()
	const handleInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setValue(value)
		onChange(value)
	}
	const clearInput = () => {
		setValue('')
		onChange('')
	}
	return (
		<TextField
			className={classes.input}
			onChange={handleInputChange}
			value={value}
			placeholder={placeholder}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon className={classes.inputIcon} fontSize="medium" />
					</InputAdornment>
				),
				endAdornment: isLoading ? (
					<InputAdornment className={classes.clearInputButton} position="end">
						<CircularProgress size={24} />
					</InputAdornment>
				) : value ? (
					<InputAdornment className={classes.clearInputButton} position="end" onClick={clearInput}>
						<Close className={classes.inputIcon} fontSize="medium" />
					</InputAdornment>
				) : undefined,
			}}
			variant="outlined"
		/>
	)
}

export { SearchBar }

import React, { useMemo } from 'react'
import { MAP_SHARED_ENTITY_TYPE_TO_COMPONENT } from '../constants'
import EntityTypes from '../types/EntityTypes'
import WithSharedEntity from '../types/WithSharedEntity'

export type ISharedEntityProps = WithSharedEntity & {
	variant?: 'primary' | 'secondary'
	showAsPreview?: boolean
	skip?: boolean
	skeleton?: 'feeds' | 'list'
	skipTypes?: EntityTypes[]
}

const SharedEntity: React.FC<ISharedEntityProps> = (props) => {
	const { skipTypes, ...childProps } = props
	const { sharedEntityType } = childProps
	if (!MAP_SHARED_ENTITY_TYPE_TO_COMPONENT[sharedEntityType]) {
		console.warn(`No handler for entity type ${sharedEntityType}`)
	}
	const Component = useMemo(() => MAP_SHARED_ENTITY_TYPE_TO_COMPONENT[sharedEntityType], [sharedEntityType])

	if (skipTypes && skipTypes.includes(sharedEntityType)) {
		return null
	}

	return <Component {...childProps} />
}

export default SharedEntity

import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { UserResponseStatuses } from '@/interfaces/common'
import { CircularProgress, makeStyles } from '@material-ui/core'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import classNames from 'classnames'
import React from 'react'

export type IButtonsProps = {
	status: UserResponseStatuses | null
	loading?: boolean
	handleChange: (status: UserResponseStatuses) => void
	text?: string
}

const useStyles = makeStyles((theme) => ({
	responseButton: {
		width: '17vw',
		minWidth: 250,
		height: 50,
	},
	outlinedButton: {
		color: theme.palette.text.primary,
	},
}))

const ResponseButton: React.FC<IButtonsProps> = ({ status, loading = false, handleChange, text = 'Interested' }) => {
	const classes = useStyles()
	const handleClick = (status: UserResponseStatuses) => () => handleChange(status)
	return status !== UserResponseStatuses.interested ? (
		<ColorButton onClick={handleClick(UserResponseStatuses.interested)} className={classes.responseButton}>
			{loading ? <CircularProgress /> : text}
		</ColorButton>
	) : (
		<OutlinedButton
			color={ButtonColors.GREY}
			onClick={handleClick(UserResponseStatuses.cleared)}
			className={classNames(classes.responseButton, classes.outlinedButton)}
		>
			{loading ? <CircularProgress /> : text}
		</OutlinedButton>
	)
}

export default ResponseButton

import createGroup from './createGroup'
import getGroupById from './getGroupById'
import patchGroupById from './patchGroupById'
import searchMembersToNewGroup from './searchMembersToNewGroup'
import searchGroups from './searchGroups'
import joinGroup from './joinGroup'
import inviteUsersToGroup from './inviteUsersToGroup'
import getGroupMembers from './getGroupMembers'
import patchGroupMember from './patchGroupMember'
import getGroupMemberRequests from './getGroupMemberRequests'
import patchGroupMemberRequest from './patchGroupMemberRequest'
import getGroupMessages from './getGroupMessages'
import removeOrLeaveGroup from './removeOrLeaveGroup'
import patchMemberGroupNotifications from './patchMemberGroupNotifications'
import getGroupGallery from './getGroupGallery'
import removeGroupGalleryImage from './removeGroupGalleryImage'
import createReaction from './createReaction'
import deleteReaction from './deleteReaction'

const groups = {
	createGroup,
	getGroupById,
	patchGroupById,
	searchMembersToNewGroup,
	searchGroups,
	joinGroup,
	inviteUsersToGroup,
	getGroupMembers,
	patchGroupMember,
	getGroupMemberRequests,
	patchGroupMemberRequest,
	getGroupMessages,
	removeOrLeaveGroup,
	patchMemberGroupNotifications,
	getGroupGallery,
	removeGroupGalleryImage,
	createReaction,
	deleteReaction,
}

export default groups

import { ICON_SIZES } from '@/constants/iconSizes'
import { alpha, IconButton, Menu, MenuItem, MenuProps, styled, Tooltip } from '@mui/material'
import { DotsThreeVertical } from '@phosphor-icons/react'
import React, { useState } from 'react'
import { ActionButtonProps } from '../types/dashboardTypes'

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: theme.shape.borderRadius,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow: theme.shadows[3],
		'& .icon-container': {
			width: '40px',
			display: 'flex',
			alignItems: 'center',
		},
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			display: 'flex',
			justifyContent: 'stretch',
			color: theme.palette.text.primary,
			padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}))

export type IActionMenuProps = {
	actions: ActionButtonProps[]
}

const ActionMenu: React.FC<IActionMenuProps> = ({ actions }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemClick = (cb) => () => {
		handleClose()
		if (cb) cb()
	}

	return (
		<>
			<Tooltip title="Actions">
				<IconButton color="primary" onClick={handleClick}>
					<DotsThreeVertical size={ICON_SIZES.md} weight="bold" />
				</IconButton>
			</Tooltip>
			<StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{actions.map(({ icon, onClick, label }, index) => (
					<MenuItem key={index} onClick={handleMenuItemClick(onClick)} disableRipple>
						<div className="icon-container">{icon}</div>
						{label}
					</MenuItem>
				))}
			</StyledMenu>
		</>
	)
}

export { ActionMenu }

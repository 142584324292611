import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: '90%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
		},
	},
	left: {
		width: '67%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	right: {
		width: '30%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}))

interface StepperContentProps {
	className?: string
	leftColumnContent: React.ReactNode
	rightColumnContent?: React.ReactNode
}

const StepperContent = ({ className = '', leftColumnContent, rightColumnContent = null }: StepperContentProps) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.content}>
				<div className={classes.left}>{leftColumnContent}</div>
				<div className={classes.right}>{rightColumnContent}</div>
			</div>
		</div>
	)
}

export default StepperContent

import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { TextCheckBox } from '@/components/widgets'
import { getYesOrNoSchema, yesOrNoOptions } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 20vw)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 43%)`,
		justifyContent: 'space-evenly',
		gridGap: 5,
		margin: '6vh 0',
	},
	containerHeader: {
		width: '47%',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 15,
		padding: 30,
		textAlign: 'center',
	},
	header: {
		fontWeight: 700,
		fontSize: 18,
		margin: 20,
	},
	iconText: {
		fontSize: 20,
	},
	text: {
		fontSize: 18,
	},
}))

const Question1 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getYesOrNoSchema(dataKey, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.containerHeader,
					}}
					title="Would you like to participate in 
      undergraduate research or 
      creative inquiry at some point?"
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									yesOrNoOptions.map((item) => (
										<TextCheckBox
											key={`${item.value}`}
											label={item.label}
											value={item.value}
											onChange={handleSelectItem}
											checked={isChecked(item.value)}
										/>
									))
								}
								canSelectMoreThanOne={false}
							/>
						)}
					/>
				</StepperCard>
			}
			rightColumnContent={
				// Note - tooltip intentionally left for all schools
				<div className={classes.card}>
					<Typography className={classes.iconText}>💡</Typography>
					<Typography className={classes.header}>What is creative inquiry?</Typography>
					<Typography className={classes.text}>
						Creative Inquiry is the process of using tools from arts, sciences, humanities, and/or social sciences to answer questions or
						express ideas about life, experiences, the physical world, and interactions.
					</Typography>
				</div>
			}
		/>
	)
}

export default Question1

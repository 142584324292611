import IconButton from '@/components/Buttons/IconButton'
import Filter from '@/components/Filter'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { getActiveCampuses } from '@/features/campus/slice'
import useEqualToPath from '@/hooks/useEqualToPath'
import { Filters, SearchConfig } from '@/interfaces/common'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import {
	clearFilters,
	getCategories,
	getSearchConfig,
	getUserOrganizations,
	loadOrganizationsListWithFilterRequest,
} from '@/store/organizations'
import { requestToAddOrganization } from '@/utils/services'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { Compass, UsersThree } from '@phosphor-icons/react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	createOrgButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
}))

const OrganizationsPage = () => {
	const classes = useStyles()

	const dispatch = useAppDispatch()
	const searchConfig = useSelector(getSearchConfig)
	const categories = useSelector(getCategories)
	const campuses = useSelector(getActiveCampuses)

	const { followed, member, admin, superAdmin } = useSelector(getUserOrganizations)
	const canUseFilter = useEqualToPath(PATHS.APP.ORGANIZATIONS)

	const handleClearFilters = () => {
		dispatch(clearFilters())
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			if (canUseFilter) {
				dispatch(loadOrganizationsListWithFilterRequest(config))
				window.scrollTo({ top: 0 })
			}
		},
		[dispatch, canUseFilter],
	)

	const followingOrgs = followed.length
	const joinedOrgs = member.length
	const adminOrgs = superAdmin.length + admin.length

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Organizations',
				icon: <Compass size={ICON_SIZES.md} />,
				path: PATHS.APP.ORGANIZATIONS,
			},
			{
				label: 'My Organizations',
				icon: <UsersThree size={ICON_SIZES.md} />,
				path: PATHS.APP.ORGANIZATIONS_MY,
				info: [`${followingOrgs} Following `, `${joinedOrgs} Joined`, `${adminOrgs} Admin`],
			},
		],
		[adminOrgs, followingOrgs, joinedOrgs],
	)

	return (
		<div className={classes.root}>
			<Filter
				title="Organizations"
				searchPlaceholder="Search organizations"
				value={searchConfig}
				filters={[Filters.campuses, Filters.categories]}
				filterOptions={{
					campuses,
					categories,
				}}
				canUseFilter={canUseFilter}
				onFilterChange={fetchSearchAndFilter}
				clearFilters={handleClearFilters}
				filterNavigation={filterNavigation}
				additionalElementsRenderer={() => (
					<IconButton className={classes.createOrgButton} onClick={requestToAddOrganization} startIcon={<Add />}>
						Add Organization
					</IconButton>
				)}
			/>
			<Router routes={ROUTES.organizations} />
		</div>
	)
}

export default OrganizationsPage

import { Id } from '@/lib/types'

const ROOT = 'home'

const HOME = {
	ROOT,
	POST_INFO: (id?: Id) => `${ROOT}/post/${id !== undefined ? id : ':id'}`,
}

export default HOME

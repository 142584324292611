import { makeStyles } from '@material-ui/core/styles'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import { useCallback, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ClockOutlinedIcon, ColorButton, DotButton, IconWrapper, LocationOutlinedIcon, MoreButton, PersonOutlinedIcon } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import ItemCardContainer from '@/components/ExperienceCards/ItemCardContainer'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { useGetEventPermissionsQuery } from '@/features/permissions/api'
import { DtoEntityPermission } from '@/features/permissions/types/DtoEntityPermission'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { setEventResponseRequest } from '@/store/events'
import { getEventScheduledShiftsNumber } from '@/store/volunteer'
import useGlobalStyles from '@/styles/globalStyles'
import { getEventResponseLabel } from '@/utils/common'
import { formatMultiOrSingleDay, isPast } from '@/utils/dateTime2'
import { hasVolunteerCategory, isEventAdmin } from '@/utils/events'
import { createGoogleLink, createOutlookLink } from '@/utils/services'
import { FEATURE } from '@navengage/sen-shared-assets'
import { AdminBadge } from '../Badges/AdminBadge'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	contentInfo: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '4px 0',
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	},
	contentInfoIcon: {
		color: theme.palette.primary.main,
		fontSize: '1.25em',
		marginRight: 10,
	},
	responseBtn: {
		height: 50,
		width: 'calc(100% - 36% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	signUpBtn: {
		height: 50,
		width: 'calc(100% - 47% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	responseBtnFullWidth: {
		height: 50,
		width: 'calc(100% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
	adminIcon: {
		width: 30,
	},
	title: {
		minHeight: 50,
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	titlePostCard: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	contentContainer: {
		margin: 0,
	},
}))

interface EventCardProps {
	photoUrl: string
	title: string
	id: string
	startDate?: string
	endDate?: string
	description?: string
	locationName?: string
	organization?: any[]
	categories: any[]
	eventResponse?: string
	volunteerShifts?: any[]
	submittedByUserId: number
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
	permissions?: DtoEntityPermission
}

const EventCard = ({
	photoUrl = '',
	title = '',
	startDate = '',
	endDate = '',
	description = '',
	locationName = '',
	organization = [],
	volunteerShifts = [],
	categories = [],
	eventResponse = '',
	submittedByUserId,
	id,
	variant = 'primary',
	showActionButtons = true,
	showBadge = false,
	permissions,
}: EventCardProps) => {
	const classes = useStyles()
	const globalClasses = useGlobalStyles()
	const { push } = useHistory()
	const dispatch = useAppDispatch()
	const userSubmittedShifts = useSelector(getEventScheduledShiftsNumber(id))
	const userId = useSelector(getUserInfo).id

	const hasVolunteerShifts = (volunteerShifts && !!volunteerShifts.length) || !!userSubmittedShifts
	const date = formatMultiOrSingleDay(startDate, endDate)
	const hostingOrg = organization && organization.length ? organization[0] : null

	const isAdmin = isEventAdmin(userId, submittedByUserId)

	const hasResponse = eventResponse === UserResponseStatuses.interested || eventResponse === UserResponseStatuses.going
	const pastEvent = isPast(endDate)

	const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 })

	const skipPermissionCheck = isAdmin || organization.length === 0 || !inView || !!permissions

	const { data: serverData } = useGetEventPermissionsQuery(
		{ eventId: id },
		{
			skip: skipPermissionCheck,
		},
	)

	const permissionsData = useMemo(() => {
		if (serverData) {
			return serverData
		}
		if (permissions) {
			return permissions
		}
		return { canDelete: isAdmin, canEdit: isAdmin }
	}, [isAdmin, permissions, serverData])

	const handleRSVPClick = useCallback(
		(e: any, status: UserResponseStatuses) => {
			e.stopPropagation()
			dispatch(setEventResponseRequest({ id, status }))
		},
		[dispatch, id],
	)

	const handleReportEvent = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setEventResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const getActiveButtonStyle = useCallback(
		(res: UserResponseStatuses) => (res === eventResponse ? classes.menuActiveButton : ''),
		[classes.menuActiveButton, eventResponse],
	)

	const rsvpButtons = useMemo(
		() =>
			[UserResponseStatuses.going, UserResponseStatuses.interested, UserResponseStatuses.notInterested].map((res) => ({
				label: getEventResponseLabel(res),
				onClick: (e: any) => handleRSVPClick(e, res),
				className: getActiveButtonStyle(res),
			})),
		[getActiveButtonStyle, handleRSVPClick],
	)

	const shiftsButtons = useMemo(
		() => [
			{
				label: 'Edit Shift',
				onClick: () => {
					push(`${PATHS.APP.EVENTS_SINGLE(id)}/?editShifts=true`)
				},
			},
		],
		[id, push],
	)

	const calendarButtons = useMemo(
		() => [
			{
				label: 'Add to Outlook Calendar',
				onClick: () => {
					window.open(createOutlookLink(title, startDate, endDate, description))
				},
				icon: <EventAvailableOutlinedIcon />,
			},
			{
				label: 'Add to Google Calendar',
				onClick: () => {
					window.open(createGoogleLink(title, startDate, endDate, description))
				},
				icon: <EventAvailableOutlinedIcon />,
			},
		],
		[description, endDate, title, startDate],
	)

	const hasVolunteerCategories = hasVolunteerCategory(categories)

	const sharingUrl = PATHS.APP.EVENTS_SINGLE(id)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.EVENTS_SINGLE(id))
	}, [id, push])

	const handleRenderCardHeader = useCallback(
		() => (
			<>
				<div className={globalClasses.alignCenter}>
					{showBadge ? (
						<>
							<CardIconContainer>
								<IconWrapper iconKey={FEATURE.events} aria-label="Event" weight="fill" size={ICON_SIZES.md} />
							</CardIconContainer>
							{hasVolunteerCategories && (
								<CardIconContainer>
									<IconWrapper iconKey={FEATURE.volunteers} aria-label="Volunteer" weight="fill" size={ICON_SIZES.md} />
								</CardIconContainer>
							)}
						</>
					) : null}

					{(permissionsData.canEdit || permissionsData.canDelete) && <AdminBadge />}
				</div>
				{showActionButtons && (
					<DotButton itemId={id} onSubmitReport={handleReportEvent} reportSubject={ReportSubject.Event} renderButtons={calendarButtons} />
				)}
			</>
		),
		[
			globalClasses.alignCenter,
			showBadge,
			hasVolunteerCategories,
			permissionsData.canEdit,
			permissionsData.canDelete,
			showActionButtons,
			id,
			handleReportEvent,
			calendarButtons,
		],
	)

	const handleRenderCardContent = useCallback(
		() => (
			<>
				{startDate && (
					<div className={classes.contentInfo}>
						<ClockOutlinedIcon className={classes.contentInfoIcon} /> <p>{date}</p>
					</div>
				)}
				{locationName && (
					<div className={classes.contentInfo}>
						<LocationOutlinedIcon className={classes.contentInfoIcon} /> <p>{locationName}</p>
					</div>
				)}
				{hostingOrg && (
					<div className={classes.contentInfo}>
						<PersonOutlinedIcon className={classes.contentInfoIcon} /> <p>{hostingOrg.name}</p>
					</div>
				)}
			</>
		),
		[classes.contentInfo, classes.contentInfoIcon, date, hostingOrg, locationName, startDate],
	)

	const handleRenderActionButtons = useCallback(
		() => (
			<>
				{!pastEvent && !userSubmittedShifts && (
					<MoreButton
						color={hasResponse ? ButtonColors.PRIMARY : ButtonColors.PRIMARY}
						className={hasVolunteerShifts ? classes.responseBtn : classes.responseBtnFullWidth}
						hideIcon={!hasResponse}
						renderButtons={rsvpButtons}
					>
						{hasResponse ? getEventResponseLabel(eventResponse) : 'RSVP'}
					</MoreButton>
				)}
				{!pastEvent && !!userSubmittedShifts && (
					<MoreButton className={classes.responseBtnFullWidth} renderButtons={shiftsButtons}>
						{`Signed Up | ${userSubmittedShifts} Shift${userSubmittedShifts !== 1 ? 's' : ''}`}
					</MoreButton>
				)}
				{pastEvent && (
					<ColorButton className={classes.responseBtnFullWidth} color={ButtonColors.GREY} disabled>
						Past Event
					</ColorButton>
				)}
				{hasVolunteerShifts && !userSubmittedShifts && !pastEvent && (
					<ColorButton className={classes.signUpBtn} color={ButtonColors.GREY}>
						Sign Up
					</ColorButton>
				)}
			</>
		),
		[
			classes.responseBtn,
			classes.responseBtnFullWidth,
			classes.signUpBtn,
			eventResponse,
			hasResponse,
			hasVolunteerShifts,
			pastEvent,
			rsvpButtons,
			shiftsButtons,
			userSubmittedShifts,
		],
	)
	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.event } }), [id])

	const cardClasses = useMemo(() => {
		return {
			titleContainer: classes.title,
			contentContainer: classes.contentContainer,
		}
	}, [classes.contentContainer, classes.title])

	const feedCardClasses = useMemo(() => {
		return {
			titleContainer: classes.titlePostCard,
			contentContainer: classes.contentContainer,
		}
	}, [classes.contentContainer, classes.titlePostCard])

	return (
		<div className={classes.root} ref={ref}>
			{variant === 'primary' ? (
				<ItemCardContainer
					showActionButtons={showActionButtons}
					title={title}
					photoUrl={photoUrl}
					containerClasses={cardClasses}
					handleOnClickCard={handleClickCard}
					sharedData={sharedData}
					renderHeader={handleRenderCardHeader}
					renderContent={handleRenderCardContent}
					renderFooterButtons={handleRenderActionButtons}
					sharingUrl={sharingUrl}
				/>
			) : (
				<FeedsCardContainer
					showActionButtons={showActionButtons}
					title={title}
					photoUrl={photoUrl}
					containerClasses={feedCardClasses}
					handleOnClickCard={handleClickCard}
					sharedData={sharedData}
					renderHeader={handleRenderCardHeader}
					renderContent={handleRenderCardContent}
					renderFooterButtons={handleRenderActionButtons}
					sharingUrl={sharingUrl}
				/>
			)}
		</div>
	)
}

export default EventCard

import MentionTypeaheadOption from '@/components/Editor/plugins/MentionPlugin/MentionTypeaheadOption'
import { formPrimaryTitle, getRoleBadgeLabel } from '@/utils/common'
import { Dictionary, createSelector } from '@reduxjs/toolkit'
import { TCampus } from '../campus/types/TCampus'
import { baseApi } from '../general/baseApi'
import EntityTypes from '../shareEntity/types/EntityTypes'
import DtoAutoCompleteUsers from './types/dto/DtoAutoCompleteUsers'

const API_BASE_URL = '/users'

const USER_TAGS: Record<string, string> = {
	AUTO_COMPLETE: 'AUTO_COMPLETE',
} as const

export const userApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(USER_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			autoCompleteUsers: builder.query<DtoAutoCompleteUsers, { search?: string; take?: number; skip?: number }>({
				query: (params) => ({
					url: `${API_BASE_URL}/autocomplete`,
					params,
				}),
				transformResponse: (response: DtoAutoCompleteUsers) => ({
					items: response.items.map((item) => ({ ...item, entityType: EntityTypes.user })),
					totalItems: response?.totalItems,
				}),
				providesTags: (result, error, arg) => {
					return result ? [{ type: USER_TAGS.AUTO_COMPLETE, id: arg.search }, USER_TAGS.AUTO_COMPLETE] : [USER_TAGS.AUTO_COMPLETE]
				},
				async onCacheEntryAdded(arg, { getCacheEntry, updateCachedData, dispatch }) {
					// @TODO: We can limit the size of the cache if we want to store tons of values here
					// updateCachedData((data) => {})
				},
				keepUnusedDataFor: 120, // default is 60; this allows the user to modify their mentions without triggering refetch
			}),
		}),
	})

export const selectUsersAsAutocompleteOption = () => {
	const emptyArray = []
	return createSelector(
		(campusMap: Dictionary<TCampus>) => campusMap,
		(__: Dictionary<TCampus>, results?: DtoAutoCompleteUsers) => results,
		(campusMap, results?: DtoAutoCompleteUsers): MentionTypeaheadOption[] => {
			return results?.items
				? results?.items.map((user) => {
						const primaryTitle = user.primaryTitle ? formPrimaryTitle([user.primaryTitle, user.primaryTitleDepartment]) : ''
						const campusName = user.campusId && campusMap && campusMap[user.campusId] ? campusMap[user.campusId].name : ''
						const role = user.appUserTypeId ? getRoleBadgeLabel(user.appUserTypeId) : ''
						const campusInfo = campusName ? ` | ${campusName}` : ''
						const summary = primaryTitle || role + campusInfo
						return new MentionTypeaheadOption({
							name: user.fullName,
							summary,
							picture: user.photoUrl,
							id: user.id,
							entityType: EntityTypes.user,
							externalId: user.externalId,
						})
				  })
				: emptyArray
		},
	)
}

export const { useAutoCompleteUsersQuery } = userApi

import { RootState, useAppSelector } from '@/store'
import { Path, PathValue } from '@/utils/nestedValueByPath'
import { TSchoolConfiguration } from '@navengage/sen-shared-assets'
import { selectConfigByPath } from '../features/school/slice'

export function useSchoolConfig<T extends Path<TSchoolConfiguration> = undefined>(
	path?: T,
): T extends undefined
	? TSchoolConfiguration & { logo: { main: string; alt: string }; landingBackground: string }
	: PathValue<TSchoolConfiguration, T> {
	return useAppSelector((state: RootState) => selectConfigByPath(state, path))
}

import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import { PATHS } from '@/constants'
import { useAppDispatch } from '@/store'
import { setStartBuilderPath } from '@/store/pathBuilder'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router'

import { isEngage } from '@/constants/configuration'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'

interface StylesProps {
	landingBackground: string
}
const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.background.paper,
		width: '60%',
		minWidth: 322,
		maxWidth: 460,
	},
	image: {
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		height: 'calc(100% - 60px)',
		width: 'calc(100% - 60px)',
		padding: 30,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: ({ landingBackground }: StylesProps) => `linear-gradient(
			rgba(0, 0, 0, 0.7), 
			rgba(0, 0, 0, 0.7)
		  ), url(${landingBackground}) center center / cover no-repeat`,
		borderRadius: 15,
	},
	titleImage: {
		width: '60%',
	},
	cardContainer: {
		width: '100%',
		paddingTop: 'calc(62%)',
		position: 'relative',
		color: theme.palette.common.white,
	},
	header: {
		width: '100%',
	},
	footer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		justifyContent: 'space-between',
	},
	button: {
		minHeight: '60%',
		width: '45%',
		minWidth: 'fit-content',
		backgroundColor: theme.palette.specialColors.whitetransparent10,
		fontWeight: 600,
	},
	headerText: {
		fontWeight: 700,
	},
	text: {
		fontWeight: 500,
		fontSize: theme.spacing(2),

		[theme.breakpoints.up('xl')]: {
			fontSize: theme.spacing(2.5),
		},
	},
}))

interface BuildYourPathCardProps {
	className?: string
}

const BuildYourPathCard = ({ className = '' }: BuildYourPathCardProps) => {
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const handleGetStartedClick = () => {
		dispatch(setStartBuilderPath(true))
		push(PATHS.APP.PATH_BUILDER)
	}
	const {
		school: { shortName },
		landingBackground,
	} = useSchoolConfig()
	const classes = useStyles({ landingBackground })

	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.cardContainer}>
				<div className={classes.image}>
					<div className={classes.header}>
						<Typography className={classes.headerText} variant="h1">
							{isEngage() ? 'Set Your Goals' : 'Build Your Path'}{' '}
						</Typography>

						<Typography className={classes.headerText} variant="h1">
							at {shortName}
						</Typography>
					</div>
					<div className={classes.footer}>
						<div>
							<Typography className={classes.text} variant="subtitle1">
								A few clicks away
							</Typography>
							<Typography className={classes.text} variant="subtitle1">
								from getting on
							</Typography>
							<Typography className={classes.text} variant="subtitle1">
								track.
							</Typography>
						</div>
						<OutlinedButton color={ButtonColors.WHITE} className={classes.button} onClick={handleGetStartedClick}>
							Get Started
						</OutlinedButton>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BuildYourPathCard

import StudyAbroadCard from '@/components/ExperienceCards/StudyAbroadCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { useGetEntityResponseCountQuery } from '@/features/responses/api'
import useEntityListWithResponse from '@/features/responses/hooks/useEntityListWithResponse'
import { StudyAbroad } from '@/store/studyAbroad/types'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
}))

const YourStudyAbroad = () => {
	const classes = useStyles()

	const { push } = useHistory()

	const { data: count } = useGetEntityResponseCountQuery({ entityType: 'studyAbroad' })
	const { canLoadMore, isLoading, list: userStudyAbroad, loadMore } = useEntityListWithResponse<StudyAbroad>('studyAbroad')

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Education Abroad',
				onClick: () => push(PATHS.APP.EDUCATION_ABROAD),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const showEmptyPlaceholder = userStudyAbroad && !userStudyAbroad.length && !isLoading

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="My Education Abroad" pageInfo={`${count?.total ?? ''} Interested`} />
			{showEmptyPlaceholder ? (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.studyAbroad} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						Nothing at the moment!
					</Typography>
					<Typography align="center">Education abroad programs that you</Typography>
					<Typography align="center">are interested in will appear here.</Typography>
				</EmptyPlaceholder>
			) : (
				<ExperienceCardsSection
					handleLoadMoreItems={loadMore}
					canLoadMore={canLoadMore}
					items={userStudyAbroad}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, startDate, photoUrl, id, locations, termsOfAvailability, responseStatus }: any) => (
								<StudyAbroadCard
									key={id}
									id={id}
									title={name}
									photoUrl={photoUrl}
									locations={locations}
									termsOfAvailability={termsOfAvailability}
									status={responseStatus}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourStudyAbroad

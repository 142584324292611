import { InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { FilledTextField } from '@/components'
import Card from '@/components/IndividualPage/Card'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import {
	clearSearchOrgMemberships,
	getCanLoadMoreSearchOrgMembershipsResults,
	getIsLoadingSearchOrgMembershipsResults,
	getSearchOrgMembershipsQuery,
	getSearchOrgMembershipsResults,
	searchOrgMembershipsRequest,
	setSearchOrgMembershipsQuery,
} from '@/store/organizations'
import ORGANIZATION_ROLE from '../../constants/organizationRoles'
import { OrgMembership } from '../../store/organizations/types'
import { canEditMemberships, getOrganizationRole, getOrganizationType, orgTypes } from '../../utils/organizationRoles'
import { submitFeedback } from '../../utils/services'
import InfiniteScrollComponent from '../ItemsSections/InfiniteScrollComponent'
import NotFoundPlaceholder, { PlaceholderButton } from '../Placeholder'
import MembershipInfoRow from './MembershipInfoRow'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	userRow: {
		margin: '8px 0',
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	itemsContainer: {
		margin: '1vh 0',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},
	lockIconContainer: {
		width: 35,
		height: 35,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		borderRadius: 5,
		cursor: 'pointer',
	},
	lockIcon: {
		width: 15,
	},
	membershipInfoModalCard: {
		textAlign: 'center',
		maxWidth: 350,
		minHeight: 100,
		padding: '25px 60px',
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontSize: '1em',
		boxShadow: '0px 7px 15px -1px rgba(181,181,181,0.33)',
		margin: '1.5vh 7%',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
	inputIcon: ({ focused }: any) => ({
		color: focused ? theme.palette.text.primary : theme.palette.text.secondary,
	}),
	clearInputButton: {
		cursor: 'pointer',
	},
	searchResultContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 500,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
	},
	infiniteScroll: {
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

interface SearchOrganizationMembershipCardProps {
	userMembershipTypeId: orgTypes
	renderMembershipRowButtons: (membership: OrgMembership, canEdit: boolean) => React.ReactNode
	handleAddMembership: (memberTypeId: orgTypes) => void
	isDataLakeOrg?: boolean
}

const SearchOrganizationMembershipCard = ({
	isDataLakeOrg = false,
	userMembershipTypeId,
	renderMembershipRowButtons,
	handleAddMembership,
}: SearchOrganizationMembershipCardProps) => {
	const dispatch = useAppDispatch()

	const searchValue = useSelector(getSearchOrgMembershipsQuery)
	const canLoadMore = useSelector(getCanLoadMoreSearchOrgMembershipsResults)
	const isLoading = useSelector(getIsLoadingSearchOrgMembershipsResults)
	const memberships = useSelector(getSearchOrgMembershipsResults)

	const [focused, setFocused] = useState(false)
	const [isTouched, setIsTouched] = useState(false)
	const [resetScrollPosition, setResetScrollPosition] = useState(false)

	const classes = useStyles({ focused })

	const handleSetFocus = useCallback(
		(val: boolean) => () => {
			setFocused(val)
		},
		[],
	)

	const handleClearInput = useCallback(() => {
		dispatch(clearSearchOrgMemberships())
	}, [dispatch])

	const handleChangeInput = useCallback(
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (value && !value.trim()) return handleClearInput()

			dispatch(setSearchOrgMembershipsQuery(value))
			setIsTouched(true)
		},
		[dispatch, handleClearInput],
	)

	const handleLoadMore = useCallback(() => {
		dispatch(searchOrgMembershipsRequest({ fetchMore: true }))
	}, [dispatch])

	const handleSearch = useCallback(() => {
		setResetScrollPosition(true)
		dispatch(searchOrgMembershipsRequest({}))
	}, [dispatch])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedSearch = useCallback(
		debounce(() => handleSearch(), 300),
		[],
	)

	const placeholderButtons: PlaceholderButton[] = useMemo(() => {
		const buttons = []
		if (!isDataLakeOrg) {
			buttons.push({
				variant: 'common',
				label: 'Add member',
				onClick: () => handleAddMembership(getOrganizationType(ORGANIZATION_ROLE.MEMBER)),
			})
		}
		buttons.push({
			variant: 'outlined',
			label: 'Submit Feedback',
			onClick: submitFeedback,
		})
		return buttons
	}, [handleAddMembership, isDataLakeOrg])

	useEffect(() => {
		if (isTouched) delayedSearch()

		return delayedSearch.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, isTouched])

	useEffect(() => {
		if (!isLoading) setResetScrollPosition(false)
	}, [isLoading])

	const hasSearchQuery = !!searchValue
	const showNoResultFoundPlaceholder = isTouched && hasSearchQuery && !isLoading && !memberships.length && !canLoadMore
	const showSearchResult = hasSearchQuery && !!memberships.length && !showNoResultFoundPlaceholder

	return (
		<Card>
			<div className={classes.titleContainer}>
				<FilledTextField
					onChange={handleChangeInput}
					value={searchValue}
					placeholder={'Search members'}
					inputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon className={classes.inputIcon} fontSize="medium" />
							</InputAdornment>
						),
						endAdornment: searchValue ? (
							<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
								<Close className={classes.inputIcon} fontSize="medium" />
							</InputAdornment>
						) : undefined,
					}}
					onFocus={handleSetFocus(true)}
					onBlur={handleSetFocus(false)}
				/>
			</div>
			{hasSearchQuery && (
				<div className={classes.searchResultContainer}>
					{showSearchResult && (
						<InfiniteScrollComponent
							classnames={{
								root: classes.infiniteScroll,
								content: classes.cardContainer,
							}}
							onLoadMore={handleLoadMore}
							dataLength={memberships.length}
							canLoadMore={canLoadMore}
							height={500}
							resetScroll={resetScrollPosition}
						>
							{memberships.map((member) => (
								<MembershipInfoRow
									classnames={{
										userRow: classes.userRow,
									}}
									key={`${member.organizationId}-search-${member.id}`}
									user={member.user}
									membershipTitle={member.membershipTitle || getOrganizationRole(member.organizationMembershipTypeId)}
									openProfileOnClick
									userEmail={member.userEmail}
									buttons={renderMembershipRowButtons(
										member,
										!isDataLakeOrg && canEditMemberships(userMembershipTypeId, member.organizationMembershipTypeId),
									)}
								/>
							))}
						</InfiniteScrollComponent>
					)}
					{showNoResultFoundPlaceholder && <NotFoundPlaceholder buttons={placeholderButtons} showNothingSeemsToMatchMessage={false} />}
				</div>
			)}
		</Card>
	)
}

export default SearchOrganizationMembershipCard

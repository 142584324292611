import { TCampus } from '../campus/types/TCampus'
import settings from '@/constants/http'
import { UserInfo } from '@/interfaces/user'
import { FeedPost } from '@/store/feeds/types'
import utils from '@/utils'
import { Dictionary, createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../general/baseApi'
import DtoPost from './types/DtoPost'

type PostQueryParams = {
	userId: UserInfo['id']
	postId: FeedPost['id']
}

export type SearchPostsQueryParams = {
	offset?: number
	userId: UserInfo['id']
	campusId?: number
	tags?: number[]
	usersAudience?: number
	campusAll?: boolean
	scheduled?: boolean
	limit?: number
	audienceScope?: number
	light?: boolean
}

export type DtoPosts = {
	total: number
	results: FeedPost[]
}

export const TAGS = {
	SINGLE: 'POST',
}

export const API_BASE = '/feeds'

export const postsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getPost: builder.query<DtoPost, PostQueryParams>({
				query: ({ userId, postId }) => ({
					url: `${API_BASE}/${userId}/post/${postId}`,
				}),
				keepUnusedDataFor: 600,
				providesTags: (response, error, { postId }) => (response ? [{ type: TAGS.SINGLE, id: postId }] : []),
			}),

			searchPosts: builder.query<any, SearchPostsQueryParams>({
				query: ({
					campusAll = true,
					offset = 0,
					limit = settings.SEARCH_FEEDS_LIST_PER_PAGE,
					usersAudience = 1,
					userId,
					tags,
					campusId,
					scheduled = false,
					audienceScope,
					light = true,
				}) => {
					const queryArgs = utils.rtkQueryUtils.generateQueryParams({
						usersAudience,
						tags,
						campusId,
						scheduled,
						campusAll,
						usersAudienceScope: audienceScope,
						light,
					})
					return {
						url: `${API_BASE}/${userId}/search?${queryArgs}`,
						params: { offset, limit },
					}
				},
				transformResponse: (response: DtoPost, __, { offset }) => {
					return {
						...response,
						offset,
					}
				},
			}),
			deletePost: builder.mutation<undefined, PostQueryParams>({
				query: ({ postId, userId }) => ({
					url: `${API_BASE}/${userId}/post/${postId}/delete`,
					method: 'DELETE',
				}),
			}),
		}),
	})

export const { useGetPostQuery, useSearchPostsQuery, useDeletePostMutation } = postsApi

// Selectors

export const selectPost = () =>
	createSelector(
		(data: DtoPost) => data,
		(data: DtoPost, campuses: Dictionary<TCampus>) => campuses,
		(data: DtoPost, campuses: Dictionary<TCampus>): FeedPost =>
			data.post ? utils.transformers.normalizePostData(data.post, campuses) : null,
	)

import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router-dom'

import OrganizationCard from '@/components/ExperienceCards/OrganizationCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { getIsLoadingUserOrganizations, getUserOrganizations } from '@/store/organizations'
import { loadUserOrganizationsRequest } from '@/store/organizations/actions'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
}))

const tabs = ['Following', 'Joined', 'Admin']

const YourOrganizations = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const [tab, setTab] = useState(0)

	const isLoading = useSelector(getIsLoadingUserOrganizations)
	const { followed, member, admin, superAdmin } = useSelector(getUserOrganizations)

	useEffect(() => {
		dispatch(loadUserOrganizationsRequest())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const followingOrgs = useMemo(() => followed, [followed])
	const joinedOrgs = useMemo(() => member, [member])
	const adminOrgs = useMemo(() => uniqBy(superAdmin.concat(admin), 'id'), [superAdmin, admin])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Organizations',
				onClick: () => push(PATHS.APP.ORGANIZATIONS),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const emptyPlaceholder = useMemo(() => {
		const showNoFollowingOrgsPlaceholder = !followingOrgs.length && !isLoading
		const showNoJoinedOrgsPlaceholder = !joinedOrgs.length && !isLoading
		const showNoAdminOrgsPlaceholder = !adminOrgs.length && !isLoading

		if (tab === 0 && showNoFollowingOrgsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.organizations} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No organizations at the moment!
					</Typography>
					<Typography align="center">Organizations you follow, belong to,</Typography>
					<Typography align="center">and are the admin or moderator of</Typography>
					<Typography align="center">will appear here.</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 1 && showNoJoinedOrgsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.organizations} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No organizations at the moment!
					</Typography>
					<Typography align="center">You are not a member any organizations.</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 2 && showNoAdminOrgsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.organizations} buttons={placeholderButtons}>
					<Typography align="center">Organizations that you are a</Typography>
					<Typography align="center">super admin or moderator of will</Typography>
					<Typography align="center">appear here.</Typography>
				</EmptyPlaceholder>
			)

		return null
	}, [adminOrgs.length, classes.placeholderHeader, followingOrgs.length, isLoading, joinedOrgs.length, placeholderButtons, tab])

	const handleChangeTab = useCallback((tabIndex: number) => {
		setTab(tabIndex)
	}, [])

	const tabInfo = useMemo(() => {
		if (tab === 0)
			return {
				title: 'My Organizations',
				info: `${followingOrgs.length} Following`,
				items: followingOrgs,
			}
		if (tab === 1)
			return {
				title: 'My Organizations',
				info: `${joinedOrgs.length} Joined`,
				items: joinedOrgs,
			}
		return {
			title: 'My Organizations',
			info: `${adminOrgs.length} Admin`,
			items: adminOrgs,
		}
	}, [adminOrgs, followingOrgs, joinedOrgs, tab])

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title={tabInfo.title} pageInfo={tabInfo.info} tabs={tabs} handleChangeTab={handleChangeTab} activeTab={tab} />
			{emptyPlaceholder ? (
				emptyPlaceholder
			) : (
				<ExperienceCardsSection
					showPagination
					items={tabInfo.items}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, startDate, photoUrl, id, description, categories, followed, canEdit }: any) => (
								<OrganizationCard
									key={`${id}-${name}-${startDate}`}
									id={id}
									title={name}
									photoUrl={photoUrl}
									followed={followed}
									description={description}
									categories={categories}
									canEdit={canEdit}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourOrganizations

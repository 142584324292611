import { useGetOrganizationByIdQuery } from '@/anthology/organizations/api'
import OrganizationCard from '@/anthology/organizations/components/OrganizationCard'
import EntityTypes from '../../types/EntityTypes'
import { ISharedEntityProps } from '../SharedEntity'
import { withErrorHandler } from '../withErrorHandler'

export type ISharedOrganizationProps = ISharedEntityProps

const SharedOrganization: React.FC<ISharedOrganizationProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useGetOrganizationByIdQuery(
		{ id },
		{ skip: skip || type !== EntityTypes.organization },
	)

	return withErrorHandler({
		type,
		currentType: EntityTypes.organization,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<OrganizationCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.profilePicture}
				followed={data?.follow && data.follow.response === 'followed'}
				description={data.summary}
				categories={data.categories}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedOrganization

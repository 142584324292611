import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import SettingsPageHeader from '@/components/SettingsPageHeader'
import { STYLES } from '@/constants'
import { useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { convertMemberships, getItemPrivacyType, ProfileInfoCardTypes } from '@/utils/userExperienceItems'
import { getEntityPrivacy, getUserInfo } from '@/store/auth'
import { getUserRole, isStudent } from '@/utils/authHandlers'
import ProfileInfoItem from '@/components/UserProfile/UserExperienceItems/ProfileInfoItem'
import PrivacySelect from '@/components/PrivacySelect'
import { PrivacyTypes } from '@/api/http/user/patchUserPrivacySettings'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		paddingBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	container: {
		height: 90,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	contentContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: 16,
	},
	title: {
		width: '100%',
		fontWeight: 700,
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	itemsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	itemsList: {
		width: '60%',
	},
	privacySelect: {
		width: '27%',
	},
	description: {
		margin: '10px 0 20px 0',
	},
	content: {
		width: '80%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}))

const ProfilePrivacy = () => {
	const classes = useStyles()

	const userInfo = useSelector(getUserInfo)
	const isStudentProfile = isStudent(getUserRole(userInfo?.appUserTypeId || 0))
	const volunteerPrivacyLevel = useSelector(getEntityPrivacy(PrivacyTypes.volunteerHours))

	const info = useMemo(
		() => ({
			[ProfileInfoCardTypes.MEMBERSHIPS]: convertMemberships(userInfo?.memberships) || [],
			[ProfileInfoCardTypes.INTERNSHIPS]: userInfo?.userReportedInternship || [],
			[ProfileInfoCardTypes.EDUCATION_ABROAD]: userInfo?.userReportedStudyAbroad || [],
			[ProfileInfoCardTypes.RESEARCH]: userInfo?.userReportedResearch || [],
			[ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT]: userInfo?.userReportedAdditionalInvolvement || [],
		}),
		[userInfo],
	)

	const items = useMemo(
		() =>
			isStudentProfile
				? [
						{
							type: ProfileInfoCardTypes.MEMBERSHIPS,
							items: info[ProfileInfoCardTypes.MEMBERSHIPS],
						},
						{
							type: ProfileInfoCardTypes.INTERNSHIPS,
							items: info[ProfileInfoCardTypes.INTERNSHIPS],
						},
						{
							type: ProfileInfoCardTypes.EDUCATION_ABROAD,
							items: info[ProfileInfoCardTypes.EDUCATION_ABROAD],
						},
						{
							type: ProfileInfoCardTypes.RESEARCH,
							items: info[ProfileInfoCardTypes.RESEARCH],
						},
				  ]
				: [
						{
							type: ProfileInfoCardTypes.MEMBERSHIPS,
							items: info[ProfileInfoCardTypes.MEMBERSHIPS],
						},
						{
							type: ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT,
							items: info[ProfileInfoCardTypes.ADDITIONAL_INVOLVEMENT],
						},
				  ],
		[info, isStudentProfile],
	)

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<SettingsPageHeader title="Privacy" pageInfo="Profile" />
			</div>
			<div className={classes.contentContainer}>
				<Typography className={classes.description}>Choose who can see your engagement experiences.</Typography>
				{items.map((item, idx) => {
					if (!item.items.length) return null

					return (
						<div key={item.type} className={classes.content}>
							<div className={classes.titleContainer}>
								<div className={classes.itemsList}>
									<Typography className={classes.title}>{item.type}</Typography>
								</div>
								{!idx && (
									<div className={classes.privacySelect}>
										<Typography className={classes.title}>Who can see this?</Typography>
									</div>
								)}
							</div>
							{item.items.map((privacyItem) => (
								<div key={privacyItem.id} className={classes.itemsContainer}>
									<ProfileInfoItem className={classes.itemsList} type={item.type} item={privacyItem} showInfoIcons={true} outlined />
									<PrivacySelect
										className={classes.privacySelect}
										itemId={privacyItem.id}
										type={getItemPrivacyType(item.type)}
										privacyLevel={privacyItem.privacyLevel}
									/>
								</div>
							))}
						</div>
					)
				})}
				{isStudentProfile && (
					<div>
						<div className={classes.titleContainer}>
							<div className={classes.itemsList}>
								<Typography className={classes.title}>Volunteer Hours</Typography>
							</div>
						</div>
						<Typography className={classes.title}></Typography>
						<Typography className={classes.description}>Your profile only shows volunteer hours from past events.</Typography>
						<PrivacySelect type={PrivacyTypes.volunteerHours} privacyLevel={volunteerPrivacyLevel} />
					</div>
				)}
			</div>
		</div>
	)
}

export default ProfilePrivacy

import { makeStyles } from '@material-ui/core'

export const useFilterButtonStyles = makeStyles((theme) => ({
	button: {
		width: '40%',
		marginLeft: 15,
		[theme.breakpoints.down('lg')]: {
			width: '45%',
		},
	},
}))

import React from 'react'
import FeedTop from './FeedTop'
import CustomizeFeed from '../CustomizeFeed'
import { HasScrollToTop } from '../../types'

interface IFeedHeaderProps extends HasScrollToTop {}

const FeedHeader: React.FC<IFeedHeaderProps> = ({ scrollToTop }) => {
	return (
		<>
			<FeedTop />
			<CustomizeFeed scrollToTop={scrollToTop} />
		</>
	)
}

export default React.memo(FeedHeader)

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { LexicalTypeaheadMenuPlugin, useBasicTypeaheadTriggerMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { TextNode } from 'lexical'
import { useCallback, useEffect, useState } from 'react'
import * as ReactDOM from 'react-dom'

import { $createMentionNode } from '../../nodes/MentionNode'

import Placeholder from '@/components/Placeholder'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useDebouncedHandler from '@/hooks/useDebounce'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import { DEFAULT_ENTITIES, DEFAULT_TABS } from '../../config'
import MentionTypeaheadOption from './MentionTypeaheadOption'
import SuggestionItem from './SuggestionItem'
import useMentionAutocomplete from './hooks/useMentionAutocomplete'
import { getPossibleQueryMatch } from './utils'

type TMentionEntity = keyof typeof DEFAULT_TABS

export interface IMentionsPluginProps {
	entities?: EntityTypes[]
}

const useStyles = makeStyles((theme) => ({
	placeHolder: {
		marginTop: '2rem',
		width: '320px',
		padding: 0,
	},
}))

const MentionsPlugin = ({ entities = DEFAULT_ENTITIES }: IMentionsPluginProps): JSX.Element | null => {
	const classes = useStyles()

	const [editor] = useLexicalComposerContext()

	const [queryString, setQueryString] = useDebouncedHandler<string | null>(null, 250)
	const candidates = useMentionAutocomplete(queryString)

	const [activeTab, setActiveTab] = useState<TMentionEntity>(EntityTypes.user)
	const tabs = entities.map((entity) => ({
		label: DEFAULT_TABS[entity]['label'],
		value: entity,
	}))
	const onTabChanged = useCallback((e: React.ChangeEvent, value: TMentionEntity) => {
		setActiveTab(value)
	}, [])

	useEffect(() => {
		if (tabs.length === 1 || candidates[activeTab].isFetching || candidates[activeTab].options.length) {
			return
		}

		const candidatesKeys = Object.keys(candidates).map(Number)
		const idx = candidatesKeys.find((key) => candidates[key].options.length > 0)
		if (!!idx) {
			setActiveTab(idx)
		}
	}, [JSON.stringify(candidates)])

	const onSelectOption = useCallback(
		(selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
			editor.update(() => {
				const mentionNode = $createMentionNode(selectedOption.data)
				if (nodeToReplace) {
					nodeToReplace.replace(mentionNode)
				}
				mentionNode.select()
				closeMenu()
			})
		},
		[editor],
	)

	const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {})
	const checkForMentionMatch = useCallback(
		(text: string) => {
			const slashMatch = checkForSlashTriggerMatch(text, editor)
			if (slashMatch !== null) {
				return null
			}
			return getPossibleQueryMatch(text)
		},
		[checkForSlashTriggerMatch, editor],
	)

	return (
		<LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
			onQueryChange={setQueryString}
			onSelectOption={onSelectOption}
			triggerFn={checkForMentionMatch}
			options={candidates[activeTab].options}
			anchorClassName="autocompletion-anchor"
			menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex, options }) =>
				(candidates[EntityTypes.user].options.length || candidates[EntityTypes.organization].options) && anchorElementRef.current
					? ReactDOM.createPortal(
							<div className="typeahead-popover mentions-menu">
								{tabs.length > 1 && (
									<Tabs onChange={onTabChanged} indicatorColor="primary" value={activeTab}>
										{tabs.map(({ label, value }) => (
											<Tab value={value} key={value} label={label}></Tab>
										))}
									</Tabs>
								)}
								{options.length ? (
									<ul>
										{options.reduce((acc, option, i) => {
											if (option.data.entityType === activeTab) {
												acc.push(
													<SuggestionItem
														index={i}
														isSelected={selectedIndex === i}
														onClick={() => {
															setHighlightedIndex(i)
															selectOptionAndCleanUp(option)
														}}
														onMouseEnter={() => {
															setHighlightedIndex(i)
														}}
														key={option.data.id}
														option={option}
													/>,
												)
											}
											return acc
										}, [])}
									</ul>
								) : (
									<Placeholder className={classes.placeHolder} />
								)}
							</div>,
							anchorElementRef.current,
					  )
					: null
			}
		/>
	)
}

export default MentionsPlugin

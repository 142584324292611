import { makeStyles } from '@material-ui/core/styles'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import TruncateMarkup from 'react-truncate-markup'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '100%',
	},
	controlButton: {
		color: theme.palette.primary.main,
		fontSize: '14px',
		cursor: 'pointer',
		fontWeight: 700,
	},
}))

export type ITruncateTextProps = {
	controlled?: boolean
	lines?: number
	showMore?: ReactNode
	showLess?: ReactNode
	ellipsis?: string
}

const TruncateText: React.FC<ITruncateTextProps> = ({
	controlled = false,
	lines = 2,
	ellipsis = '...',
	showMore = 'See More',
	showLess = 'See Less',
	children,
}) => {
	const [expanded, setExpanded] = useState(false)
	const classes = useStyles()

	const toggleExpand = useCallback(() => setExpanded((prev) => !prev), [])

	const readMoreEllipsis = useMemo(
		() => (
			<span>
				{ellipsis}{' '}
				{controlled && (
					<span onClick={toggleExpand} className={classes.controlButton}>
						{showMore}
					</span>
				)}
			</span>
		),
		[classes.controlButton, controlled, ellipsis, showMore, toggleExpand],
	)

	return (
		<div className={classes.root}>
			{expanded && controlled ? (
				<>
					{children}
					<span onClick={toggleExpand} className={classes.controlButton}>
						{' '}
						{showLess}
					</span>
				</>
			) : (
				<TruncateMarkup lines={lines} ellipsis={readMoreEllipsis}>
					{children}
				</TruncateMarkup>
			)}
		</div>
	)
}

export { TruncateText }

import storage from '@/utils/storage'
import * as common from '@/utils/common'
import * as urlHandler from '@/utils/urlHandlers'
import * as page from '@/utils/page'
import * as rtkQueryUtils from '@/utils/rtkQueryUtils'
import * as transformers from '@/utils/transformers'

import { SCHOOL_ID } from '@/constants/configuration'
import { getQueryParamByName } from '@/utils/urlHandlers'
import authStorage from './auth/storage/authStorage'

export const resolveSchoolId = () => {
	const appState = getQueryParamByName('state') || null
	const data = JSON.parse(appState) as { redirectPath?: string; schoolId?: number; redirectUri?: string }

	return data?.schoolId || Number(authStorage.getItem('schoolId')) || SCHOOL_ID
}

const utils = {
	storage,
	urlHandler,
	common,
	page,
	rtkQueryUtils,
	transformers,
	resolveSchoolId,
}

export default utils

import { debounce } from 'lodash'
import { useCallback, useState } from 'react'

//TODO: rename to useDebounceValue and create new hook for useDebounceHandler to pass handler functions to the hoo
const useDebouncedHandler = <T>(initialValue: T, delay = 300): [T, (p: T) => void] => {
	const [value, setValue] = useState<T>(initialValue)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedHandler = useCallback(
		debounce((val) => {
			setValue(val)
		}, delay),
		[delay],
	)

	const handleValueChange = useCallback(
		(val: T) => {
			debouncedHandler.cancel()
			debouncedHandler(val)
		},
		[debouncedHandler],
	)

	return [value, handleValueChange]
}

export default useDebouncedHandler

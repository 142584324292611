import http from '@/api/http/requestHttp'
import { CommonUserConnectionParams } from './types'

const postUserConnection = async ({ userId, targetUserId, schoolId }: CommonUserConnectionParams) => {
	const url = `${schoolId}/network/${userId}/connect`

	return http.post(url, { targetUserId })
}

export default postUserConnection

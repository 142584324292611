import ShareButton from '@/components/Buttons/ShareButton'
import { CardTypes, SharedData } from '@/interfaces/common'
import { Card as MaterialCard, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useStyles } from './styles'

interface ItemCardProps {
	cardType?: CardTypes
	containerClasses?: {
		root?: string
		titleContainer?: string
		contentContainer?: string
		footerContainer?: string
	}
	photoUrl: string
	title: string
	subTitle?: string
	handleOnClickCard?: () => void
	renderHeader?: () => React.ReactNode
	renderContent: () => React.ReactNode
	renderFooterButtons?: () => React.ReactNode
	variant?: 'primary' | 'secondary'
	sharedData?: SharedData
	showActionButtons?: boolean
	sharingUrl: string
}

const FeedsCardContainer = ({
	containerClasses,
	cardType = CardTypes.EVENTS,
	photoUrl = '',
	title = '',
	subTitle = '',
	handleOnClickCard = () => {},
	renderHeader = () => null,
	renderContent,
	renderFooterButtons,
	variant = 'primary',
	sharedData = {},
	showActionButtons = true,
	sharingUrl = '',
}: ItemCardProps) => {
	const [imageUrl] = useState(photoUrl)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const classes = useStyles({ primary: variant === 'primary' })

	const handleClick = (e: any) => {
		e.stopPropagation()

		if (anchorRef.current && !anchorRef.current.contains(e.target as HTMLElement)) {
			return
		}

		handleOnClickCard()
	}

	const titleBlock = (
		<div className={classNames(classes.cardContentTitleContainer, containerClasses?.titleContainer)}>
			<Typography className={classes.cardContentTitle}>{title}</Typography>
			<Typography className={classNames(classes.cardContentSubTitle, containerClasses?.titleContainer)}>{subTitle}</Typography>
		</div>
	)

	const contentBlock = <div className={classNames(classes.cardContentInfo, containerClasses?.contentContainer)}>{renderContent()}</div>

	const actionButtons = (
		<div className={classNames(classes.cardContentFooter, containerClasses?.footerContainer)}>
			{showActionButtons && (
				<>
					{renderFooterButtons ? renderFooterButtons() : null}
					<ShareButton className={classes.feedbackButton} sharedData={sharedData} sharingUrl={sharingUrl} />
				</>
			)}
		</div>
	)

	return (
		<div className={classes.rootContainer}>
			<MaterialCard ref={anchorRef} className={classNames(classes.card, containerClasses?.root)} onClick={handleClick}>
				<div className={classes.cardHeader}>
					<LazyLoadImage visibleByDefault className={classes.image} alt={''} src={imageUrl} />

					<div className={classes.gradientImage} />
					<div className={classes.imageButtonsContainer}>{renderHeader()}</div>
				</div>
				{variant === 'primary' ? (
					<div className={classes.cardContent}>
						{titleBlock}
						<div className={classes.description}>
							{contentBlock}
							{actionButtons}
						</div>
					</div>
				) : (
					<div className={classes.cardContent}>
						<div className={classes.description}>
							{titleBlock}
							{actionButtons}
						</div>
						{contentBlock}
					</div>
				)}
			</MaterialCard>
		</div>
	)
}

export default React.memo(FeedsCardContainer)

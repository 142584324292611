import utils from '@/utils'
import { axiosBaseQuery } from '@/utils/rtkQueryUtils'
import { createApi } from '@reduxjs/toolkit/dist/query/react'

const API_BASE_URL = utils.urlHandler.getBaseUrl()
const BASE_URL = `${API_BASE_URL}analytics`

export const baseApi = createApi({
	reducerPath: 'analyticsApi',
	baseQuery: axiosBaseQuery(BASE_URL),
	endpoints: (builder) => ({}),
})

import { emitTypes } from '@/constants/socket'
import { useSocketConnection } from '@/lib/socket/SocketProvider'
import { TUpdateViewStatusData } from '@/store/messages/types'
import { useCallback, useEffect } from 'react'

interface EmitData {
	type: string
	payload: any
}

interface SubscribeToEntity {
	entityType: string
	onUpdate?: (data: EmitData) => void
	onCreate?: (data: EmitData) => void
	onDelete?: (data: EmitData) => void
	entityId?: string | number
}

export enum SocketEventTypes {
	COMMENT = 'comment',
	POST = 'post',
	NOTIFICATION = 'notification',
	NEW_MESSAGE = 'new message',
	MESSAGE_VIEW_STATUS_CHANGED = 'view message',
	MESSAGE_REACTION = 'message reaction',
	CONNECT_ERROR = 'connect_error',
	ERROR = 'error',
}

const useSocket = () => {
	const { connection } = useSocketConnection()
	const socketRef = connection.current
	const activateSocket = useCallback(
		({ onReceiveNotification, onReceiveNewMessage, onMessageViewStatusChanged, onError, onMessageReaction }) => {
			if (socketRef) {
				socketRef.on(SocketEventTypes.NOTIFICATION, onReceiveNotification)
				socketRef.on(SocketEventTypes.NEW_MESSAGE, onReceiveNewMessage)
				socketRef.on(SocketEventTypes.MESSAGE_VIEW_STATUS_CHANGED, onMessageViewStatusChanged)
				socketRef.on(SocketEventTypes.MESSAGE_REACTION, onMessageReaction)
				socketRef.on(SocketEventTypes.CONNECT_ERROR, onError)
				socketRef.on(SocketEventTypes.ERROR, onError)
			}
		},
		[socketRef],
	)

	const closeSocket = useCallback(() => {
		socketRef?.close()
	}, [socketRef])

	const subscribeToEntity = useCallback(
		({ entityId, entityType, onCreate = () => {}, onUpdate = () => {}, onDelete = () => {} }: SubscribeToEntity) => {
			if (!socketRef?.on) {
				console.info(`socket doesn't exist`)
				return
			}

			const emitKey = entityId !== undefined && entityId !== '' ? `${entityType}-${entityId}` : entityType

			socketRef?.on(emitKey, (data: EmitData) => {
				switch (data.type) {
					case emitTypes.created:
						onCreate(data)
						break
					case emitTypes.updated:
						onUpdate(data)
						break
					case emitTypes.deleted:
						onDelete(data)
						break
					default:
						console.info(`could not find a handler`)
						break
				}
			})

			return () => {
				socketRef.removeAllListeners(emitKey)
			}
		},
		[socketRef],
	)

	const unsubscribeAll = useCallback(() => {
		socketRef.removeAllListeners()
	}, [socketRef])

	const changeMessageViewStatus = useCallback(
		(data: TUpdateViewStatusData) => {
			const { userId, groupId, id, sourceUserId } = data

			socketRef.emit('change message status', { id, targetUserId: userId, sourceUserId, groupId })
		},
		[socketRef],
	)

	return {
		activateSocket,
		closeSocket,
		subscribeToEntity,
		unsubscribeAll,
		changeMessageViewStatus,
	}
}
// This hook is needed, if we want to move from redux to local component state.
// We need to subscribe to socket event in that component and update local state there.
// There is no point to keep 100+ notifications, posts, connection requests etc... in the global redux state.
export const useSocketEventTypeListener = <TData>(type: SocketEventTypes, handler: (data: TData) => void) => {
	const { connection } = useSocketConnection()
	const socketRef = connection.current
	useEffect(() => {
		if (socketRef?.on) {
			socketRef?.on(type, handler)
		}
		return () => {
			if (socketRef?.off) {
				socketRef?.off(type, handler)
			}
		}
	}, [handler, socketRef, type])
}

export default useSocket

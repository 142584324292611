//@ts-nocheck
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { ColorInfo } from '@navengage/sen-shared-assets'
import { EntityId } from '@reduxjs/toolkit'

const useStyles = makeStyles((theme) => ({
	card: ({ checked, color }: any) => {
		let styles = {
			backgroundColor: theme.palette.background.paper,
			borderRadius: 4,
			boxShadow: 'none',
			border: `1px solid ${theme.colors.grey[400]}`,
			padding: '5px 20px',
			color: theme.palette.text.primary,
			[theme.breakpoints.up('xl')]: {
				fontSize: '1.125em',
			},
		}

		if (checked) {
			const checkedStyles = {}
			if (color) {
				checkedStyles.backgroundColor = color.hex
				checkedStyles.borderColor = 'transparent'
				checkedStyles.color = color.brightness.isBright ? '#000000' : '#ffffff'

				checkedStyles['&:hover'] = {
					...checkedStyles,
					opacity: 0.85,
				}
			} else {
				checkedStyles.fontWeight = 700
				checkedStyles.border = `2px solid ${theme.palette.primary.main}`
				checkedStyles.backgroundColor = theme.palette.primary.light
				checkedStyles.borderColor = theme.palette.primary.main
				checkedStyles.color = theme.palette.primary.main
			}
			styles = { ...styles, ...checkedStyles }
		} else {
			// Explicitly override any existing hover styles from prior states.
			styles['&:hover'] = {}
		}

		return styles
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		textTransform: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		lineHeight: '20px',
	},
}))

const CheckBoxCard = ({
	name = '',
	className = '',
	icon = null,
	id,
	checkedIcon = null,
	label = '',
	onClick = (id: string) => {},
	checked = false,
	variant = 'primary',
	color,
}: {
	name?: string
	id?: EntityId
	className?: string
	icon?: JSX.Element
	checkedIcon?: JSX.Element
	label?: string
	onClick?: (id: string) => void
	checked?: boolean
	variant?: string
	color?: ColorInfo
}) => {
	const classes = useStyles({ checked, variant, color })

	const cardIcon = checked ? checkedIcon || icon : icon

	return (
		<Button className={classNames(classes.card, className)} onClick={() => onClick(id ?? name)}>
			<div className={classes.contentContainer}>
				{cardIcon}
				{label}
			</div>
		</Button>
	)
}

export default CheckBoxCard

/* eslint-disable prettier/prettier */

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

///////////////////////////////////////
///
// This is a copy of the file in sen-frontend-lib. When we have moved it, delete this file, update the imports everywhere, and remove luxon, javascript-time-ago, types luxon

import { DateTime } from 'luxon'

export const EVENT_DEFAULT_MINUTES_STEP = 5

export const DATE_SHORT_FORMAT = 'M/D/YYYY' //TODO - replace me after fixing FilterDatePicker

export const SUPPORTED_FORMATS = {
	full: DateTime.DATETIME_MED, // Example - Oct 14, 1983, 1:30 PM
	timeSimple: DateTime.TIME_SIMPLE, // Example - 12:00 pm
	dateMed: DateTime.DATE_MED, // Example - Jan 1, 2020
	dateTimeShort: DateTime.DATETIME_SHORT, // Example - 10/14/1983, 1:30 PM
	dateShort: DateTime.DATE_SHORT, // Example - 10/14/1983
}

export const DEFAULT_FORMAT = 'dateMed'

export type TDateFormat = keyof typeof SUPPORTED_FORMATS

//////////

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

// Handle accepting both strings and dates, and convert to date
export const stringDateConversion = (d: string | Date) => {
	if (typeof d === 'string') {
		return DateTime.fromISO(d)
	} else {
		return DateTime.fromJSDate(d)
	}
}

// BEGIN UTILS

/**
 * Format to a date, time, or date/time string
 * No timezone support
 * @param date Date to be formatted
 * @param format Format to be used, from SUPPORTED_FORMATS
 * @returns string Formatted date
 */
export function formatDateNew(d: string | Date, format: TDateFormat = DEFAULT_FORMAT) {
	const date = stringDateConversion(d)
	if (date.isValid) {
		return date.toLocaleString(SUPPORTED_FORMATS[format])
	} else {
		// Sentry capture error, maybe we did some mistake on frontend or in the backend and we send null for date etc..
		return ''
	}
}

/**
 * Take two dates and formats as a range, returning a string
 * No timezone support
 * @param startDate start date
 * @param endDate end date
 * @param format Format to be used, from SUPPORTED_FORMATS
 * @returns string formatted date
 */
export const formatDateRange = (startDate: string, endDate: string, format: TDateFormat = DEFAULT_FORMAT, toUpperCase = false) => {
	const start = formatDateNew(startDate, format)
	const end = !endDate ? 'Present' : formatDateNew(endDate, format)

	if (toUpperCase) return `${start.toUpperCase()} - ${end.toUpperCase()}`
	return `${start} - ${end}`
}

/**
 * Take two dates - if they are the same day, format as range, otherwise format as single day,
 * No timezone support
 * @param startDate start date
 * @param endDate end date
 * @returns string formatted date
 */
export const formatMultiOrSingleDay = (startDate: string, endDate: string) => {
	const lessThanOneDay = formatDateNew(startDate) === formatDateNew(endDate)
	const date = lessThanOneDay ? formatDateNew(startDate, 'full') : formatDateRange(startDate, endDate)
	return date
}

/**
 * Takes a date and returns how long ago it happened (ex, 1 mo, 2 yrs, etc)
 * No timezone support
 * @param date
 * @returns string formatted date
 */

export function getFromNow(date: string) {
	const d = Date.parse(date)
	return timeAgo.format(d, 'mini-minute-now')
}

/**
 * Takes a date, checks to see if it is in the past
 * No timezone support
 * @param date date
 * @returns string formatted date
 */
export function isPast(date: string) {
	return DateTime.fromISO(date) <= DateTime.now()
}

/**
 * These are legacy functions that need to be updated, I just converted them to Moment.
 */

export const getTime = (date: string, roundMinutes = EVENT_DEFAULT_MINUTES_STEP) => {
	const start = DateTime.fromISO(date)
	const minutes = Math.round(start.minute / roundMinutes) * roundMinutes
	return start.set({ minute: minutes }).toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: true })
	// Note - using this hour, minute format to ensure a leading zero, like 'hh:mm A' in moment
}

export const mergeDateTime = (date: Date, time: string) => {
	const formattedDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
	const mergedDateTime = DateTime.fromFormat(`${formattedDate} ${time}`, 'yyyy-MM-dd hh:mm a')
	return mergedDateTime.toJSDate()
}

export const isUpcomingDate = (compare?: Date, date?: Date) => {
	if (compare && date) {
		return date > compare
	}
	return false
}

export const getTimeOptionsArray = (date?: Date, minDate?: Date, maxDate?: Date, step: number = EVENT_DEFAULT_MINUTES_STEP) => {
	const timesArray = []
	const currentDate = date ? DateTime.fromJSDate(date) : DateTime.now()
	let startOfTheDay = currentDate.startOf('day')
	let endOfTheDay = currentDate.endOf('day')

	while (startOfTheDay <= endOfTheDay) {
		const time = startOfTheDay.toFormat('hh:mm a').toUpperCase()
		timesArray.push({
			label: time,
			value: time,
			disabled: isUpcomingDate(startOfTheDay.toJSDate(), minDate) || isUpcomingDate(maxDate, startOfTheDay.toJSDate()),
		})
		startOfTheDay = startOfTheDay.plus({ minutes: step })
	}
	return timesArray
}

export function getDateDiff(date1: string, date2: string, value: 'minutes' | 'days' = 'minutes') {
	const curDate = stringDateConversion(date1)
	const newDate = stringDateConversion(date2)

	return curDate.diff(newDate, value).toObject()[value]
}

// Can be depreciated if we remove volunteer:
export function dateDiff(startDate: string, endDate?: string, value: 'minutes' | 'days' | 'hours' = 'minutes') {
	const start = DateTime.fromISO(startDate)
	const end = endDate ? DateTime.fromISO(endDate) : DateTime.now()
	return end.diff(start, value).toObject()[value]
}

import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { STYLES } from '@/constants'
import { ConnectionRequestType } from '@/components/UserNetwork/ConnectionRequestsBlock'
import ConnectionRequestsBlock from '@/components/UserNetwork/ConnectionRequestsBlock'

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 1,
		minWidth: STYLES.CONNECTION_REQUESTS_BAR.XL,
		paddingTop: 10,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.SM,
		},
		borderLeft: `solid 1.5px ${theme.palette.border.bold}`,
	},
	container: {
		position: 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: STYLES.CONNECTION_REQUESTS_BAR.XL,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.LG,
		},
		[theme.breakpoints.down('md')]: {
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.MD,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: STYLES.CONNECTION_REQUESTS_BAR.SM,
		},
	},
	spacer: {
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
}))

interface ConnectionRequestsProps {
	pendingRequests: any[]
	connectionRequests: any[]
}

const ConnectionRequests = ({ pendingRequests, connectionRequests }: ConnectionRequestsProps) => {
	const classes = useStyles()

	const blocks = useMemo(
		() => [
			{
				title: 'Connection Requests',
				connectionRequests,
				emptyPlaceholder: (
					<>
						<Typography>You do not have any</Typography>
						<Typography>connection requests.</Typography>
					</>
				),
				connectionRequestType: ConnectionRequestType.INCOMING,
			},
			{
				title: 'Pending Requests',
				connectionRequests: pendingRequests,
				emptyPlaceholder: (
					<>
						<Typography>You have not sent connection</Typography>
						<Typography>requests that are pending.</Typography>
					</>
				),
				connectionRequestType: ConnectionRequestType.OUTGOING,
			},
		],
		[connectionRequests, pendingRequests],
	)

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{blocks.map((block) => (
					<ConnectionRequestsBlock key={block.title} {...block} />
				))}
			</div>
		</div>
	)
}

export default ConnectionRequests

import { yupResolver } from '@hookform/resolvers/yup'
import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { CampusIcon, ColorButton, FieldErrorMessage, ImageUploadField, Label, SelectWithIcon, TextField } from '@/components'
import { STYLES } from '@/constants'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import useImageUploadError from '@/hooks/useImageUploadError'
import { getUserCampus } from '@/store/auth'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 20,
	},
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	photoPlaceholder: {
		color: theme.palette.text.secondary,
	},
	label: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: 10,
	},
	submitButton: {
		minWidth: 200,
		margin: '35px 0 15px 0',
	},
	select: {
		minWidth: 200,
		marginTop: 10,
		paddingLeft: 7,
	},
}))

const schema = yup.object().shape({
	name: yup.string().min(1).max(255).required().label('Group Name'),
	description: yup.string().max(1000).label('Group Description').nullable(),
	thumbnail: yup.string().nullable(),
	campusId: yup.number().nullable(),
})

export interface GroupInfoData {
	name: string
	description?: string
	thumbnail?: string
	campusId: number | null
}

interface GroupInfoProps {
	onSubmit: (data: GroupInfoData) => void
	onTouch?: () => void
	data?: GroupInfoData
	submitLabel?: string
	className?: string
	canEditCampus?: boolean
}

const GroupInfo = ({ onSubmit, onTouch, data, submitLabel = 'Done', className, canEditCampus = false }: GroupInfoProps) => {
	const classes = useStyles()

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty, isValid, dirtyFields },
	} = useForm<GroupInfoData>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: data,
	})

	const campuses = useSelector(getActiveCampuses)
	const userCampuses = useSelector(getUserCampus)
	const showCampuses = useSelector(selectShowCampuses)
	const campusesOptions = useMemo(
		() => [
			{
				label: 'All campuses',
				value: null,
			},
			...campuses,
		],
		[campuses],
	)

	const defaultCampus = useMemo(
		() => campusesOptions.find((campus) => campus.label === userCampuses)?.value || null,
		[campusesOptions, userCampuses],
	)

	const validateImageSize = useImageUploadError()

	const disabled = useMemo(() => !isValid || !isDirty || !Object.keys(dirtyFields).length, [isValid, isDirty, dirtyFields])

	const submitAndSetUpdating = (data: GroupInfoData) => {
		if (disabled) return

		onSubmit(data)
	}

	useEffect(() => {
		if (onTouch && isDirty) onTouch()
	}, [isDirty, onTouch])

	return (
		<form className={classNames(className, classes.root)} onSubmit={handleSubmit(submitAndSetUpdating)}>
			<div className={classes.container}>
				<Controller
					name="thumbnail"
					control={control}
					render={({ field: { value, onChange } }) => (
						<FieldErrorMessage hasError={!!errors['thumbnail']}>
							<ImageUploadField
								helperText={'Preferred pixels: 1080:1080'}
								aspect={{ w: 1 }}
								maxWidth={200}
								value={value}
								onChange={onChange}
								onClear={() => onChange('')}
								validateImageSize={validateImageSize}
								placeholder={
									<>
										<Typography className={classes.photoPlaceholder}>Set Group Photo</Typography>
										<Typography className={classes.photoPlaceholder}>(Optional)</Typography>
									</>
								}
							/>
						</FieldErrorMessage>
					)}
				/>
				<Controller
					name="name"
					defaultValue=""
					control={control}
					render={({ field }) => (
						<Label
							classnames={{
								root: classes.label,
							}}
							title="Group Name"
							required
							semiBold
						>
							<FieldErrorMessage hasError={!!errors['name']} message={errors['name']?.message}>
								<TextField {...field} placeholder="Name your group" error={!!errors['name']} />
							</FieldErrorMessage>
						</Label>
					)}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field }) => (
						<Label
							classnames={{
								root: classes.label,
							}}
							title="Group Description"
							optional
							semiBold
						>
							<FieldErrorMessage hasError={!!errors['description']} message={errors['description']?.message}>
								<TextField {...field} placeholder="Describe what this group is about" error={!!errors['description']} />
							</FieldErrorMessage>
						</Label>
					)}
				/>
				{canEditCampus && showCampuses && (
					<Controller
						name="campusId"
						defaultValue={defaultCampus}
						control={control}
						render={({ field: { value, onChange } }) => (
							<Label
								classnames={{
									root: classes.label,
								}}
								title="Choose Campus"
								optional
								semiBold
							>
								<FieldErrorMessage hasError={!!errors['campusId']} message={errors['campusId']?.message}>
									<SelectWithIcon
										classnames={{
											input: classes.select,
										}}
										value={value}
										onChange={onChange}
										options={campusesOptions}
										placeholder="Choose Campus"
										error={!!errors['campusId']}
										startIcon={<CampusIcon />}
									/>
								</FieldErrorMessage>
							</Label>
						)}
					/>
				)}
			</div>
			<ColorButton type="submit" className={classes.submitButton} disabled={disabled}>
				{submitLabel}
			</ColorButton>
		</form>
	)
}

export default GroupInfo

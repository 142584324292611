import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'

export type ICopyrightProps = {
	atName: string
	className?: string
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontWeight: theme.typography.fontWeightBold as number,
	},
}))

const Copyright: React.FC<ICopyrightProps> = ({ atName, className }) => {
	const classes = useStyles()
	return (
		<div className={classNames(classes.root, className)}>
			Copyright {new Date().getFullYear()} &copy; {atName}
		</div>
	)
}

export default Copyright

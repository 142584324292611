import ResearchCard from '@/components/ExperienceCards/ResearchCard'
import useResearchByIdQuery from '@/features/research/types/hooks/useResearchQueryById'
import EntityTypes from '../types/EntityTypes'
import { ISharedEntityProps } from './SharedEntity'
import { withErrorHandler } from './withErrorHandler'

export type ISharedResearchProps = ISharedEntityProps

const SharedResearch: React.FC<ISharedResearchProps> = ({
	variant = 'secondary',
	sharedEntityId: id,
	sharedEntityType: type,
	showAsPreview,
	skip,
}) => {
	const { data, isLoading, isUninitialized, isError } = useResearchByIdQuery(id, skip || type !== EntityTypes.research)

	return withErrorHandler({
		type,
		currentType: EntityTypes.research,
		cachedId: data?.id ? data.id : null,
		card: data?.id ? (
			<ResearchCard
				variant={variant}
				id={data.id}
				title={data.name}
				photoUrl={data.photoUrl}
				researchTerms={data.researchTerms}
				researchAreas={data.researchAreas}
				workSettings={data.workSettings}
				status={data.responseStatus}
				showActionButtons={!showAsPreview}
			/>
		) : null,
		isError,
		isLoading,
		isUninitialized,
	})
}

export default SharedResearch

import CheckBoxCard from '@/components/CheckBoxCard'
import { ContentPreloader } from '@/components/ContentPreloader'
import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import useOnboardingEntities from '@/features/onboarding/hooks/useOnboardingEntities'
import { TOnboardingEntity, onboardingSlice, selectUserChoicesByType } from '@/features/onboarding/slice'
import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppSelector } from '@/store'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { makeStyles } from '@material-ui/core/styles'
import { UserEvents } from '@navengage/amplitude'
import { TOnboardingChoice } from '@navengage/sen-shared-assets'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from '../hooks'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	continueButton: {
		marginTop: 15,
		marginBottom: 15,
	},
	card: {
		margin: `${theme.spacing(1)}px 0`,
		width: '100%',
		height: 45,
		border: `1px solid`,
		borderRadius: 4,
		boxShadow: 'none',

		[theme.breakpoints.up('xl')]: {
			height: 50,
		},
	},
	cardHeader: {
		width: '70%',
	},
}))

const OpportunitiesStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const opportunitiesConfig = useOnboardingStepConfig('opportunities')
	const dispatch = useDispatch()
	const selections = useAppSelector((state) => selectUserChoicesByType(state, { entityType: EntityTypes.opportunities }))
	const { entities, isLoading } = useOnboardingEntities({ entityType: EntityTypes.opportunities })

	const handleCheckBoxChange = useCallback(
		() => (entityId: string) => {
			dispatch(
				onboardingSlice.actions.toggleSelect({
					entityType: EntityTypes.opportunities,
					entityScope: EntityScope.Standard,
					entityId: entityId,
				}),
			)
		},
		[dispatch],
	)

	useSignupStepDataPatcher(
		{
			opportunityChoices: selections,
		},
		[JSON.stringify(selections)],
	)

	const canClickNext = useMemo(() => !entities.length || checkCanProceed(selections), [selections, entities])
	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.SELECTED_UNIQUE_OPPORTUNITY, {
			choices: entities.filter((c) => c.checked),
		})
	}

	return (
		<SignUpCard
			title={opportunitiesConfig?.title}
			subTitle={opportunitiesConfig?.subTitle}
			continueButtonDisabled={!canClickNext}
			onClickContinue={onClickContinue}
		>
			<div className={classes.cardContainer}>
				{isLoading ? (
					<ContentPreloader />
				) : (
					entities.map((e) => (
						<CheckBoxCard
							{...e}
							key={e.name}
							className={classes.card}
							variant="secondary"
							onClick={handleCheckBoxChange()}
							label={e.name}
							checked={e.checked}
						/>
					))
				)}
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (choices: (TOnboardingChoice | TOnboardingEntity)[]) => !!choices.length

OpportunitiesStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.opportunityChoices)
}

export default OpportunitiesStep

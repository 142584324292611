import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTop = () => {
	const { pathname } = useLocation()

	const scroll = useCallback(
		() =>
			setTimeout(() => {
				window.scrollTo({ top: 0 })
			}, 100),
		[],
	)

	useEffect(() => {
		scroll()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	return scroll
}

export default useScrollToTop

import { IsEqual, Primitive } from 'type-fest'

type IsTuple<T extends ReadonlyArray<any>> = number extends T['length'] ? false : true

type TupleKeys<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>

type AnyIsEqual<T1, T2> = T1 extends T2 ? (IsEqual<T1, T2> extends true ? true : never) : never

export type PathValue<T, P extends Path<T>> = T extends any
	? P extends `${infer K}.${infer R}`
		? K extends keyof T
			? R extends Path<T[K]>
				? PathValue<T[K], R>
				: never
			: K extends number
			? T extends ReadonlyArray<infer V>
				? PathValue<V, R & Path<V>>
				: never
			: never
		: P extends keyof T
		? T[P]
		: P extends number
		? T extends ReadonlyArray<infer V>
			? V
			: never
		: never
	: never

type PathImpl<K extends string | number, V, TraversedTypes> = V extends Primitive
	? `${K}`
	: true extends AnyIsEqual<TraversedTypes, V>
	? `${K}`
	: `${K}` | `${K}.${PathInternal<V, TraversedTypes | V>}`

type PathInternal<T, TraversedTypes = T> = T extends ReadonlyArray<infer V>
	? IsTuple<T> extends true
		? {
				[K in TupleKeys<T>]-?: PathImpl<K & string, T[K], TraversedTypes>
		  }[TupleKeys<T>]
		: PathImpl<number, V, TraversedTypes>
	: {
			[K in keyof T]-?: PathImpl<K & string, T[K], TraversedTypes>
	  }[keyof T]

export type Path<T> = T extends any ? PathInternal<T> : never

export const nestedValueByPath = <T, P extends Path<T>>(path: P, root: T): PathValue<T, P> | null => {
	const [current, ...rest] = path.split('.')

	if (typeof root[current] === 'object' && root[current] && rest.length > 0) {
		return nestedValueByPath(rest.join('.'), root[current])
	}

	return root[current]
}

import { useMemo } from 'react'

import { selectResearch, useGetResearchQuery } from '../../api'

const useResearchByIdQuery = (id: string, skip?: boolean) => {
	const selectNormalizedResearch = useMemo(selectResearch, [])
	const researchQuery = useGetResearchQuery(
		{ id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedResearch(result.data) : undefined,
				}
			},
		},
	)
	return researchQuery
}

export default useResearchByIdQuery

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'

import { CommonModalCard, IconButton, Modal } from '@/components'
const useStyles = makeStyles((theme) => ({
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: {
		color: theme.colors.red[500],
	},
}))

interface CancelRequestModalProps {
	onSubmit?: () => void
	isOpenModal: boolean
	handleCloseModal: () => void
}

const CancelRequestModal = ({ onSubmit = () => {}, isOpenModal = false, handleCloseModal = () => {} }: CancelRequestModalProps) => {
	const classes = useStyles()

	const onClose = useCallback(() => {
		handleCloseModal()
	}, [handleCloseModal])

	const handleSubmit = useCallback(() => {
		onSubmit()
		handleCloseModal()
	}, [handleCloseModal, onSubmit])

	const renderButtons = useMemo(
		() => [
			{
				label: `Yes`,
				onClick: handleSubmit,
				className: classes.acceptButton,
			},
			{
				label: `No`,
				onClick: onClose,
			},
		],
		[classes.acceptButton, handleSubmit, onClose],
	)

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<CommonModalCard
				title="Cancel Request"
				subTitle="Are you sure you want to cancel your request?"
				onClose={onClose}
				classnames={{
					header: classes.cardHeader,
				}}
			>
				{renderButtons.map((btn) => (
					<IconButton key={btn.label} className={classNames(classes.itemPopupButton, btn.className)} onClick={btn.onClick}>
						{btn.label}
					</IconButton>
				))}
			</CommonModalCard>
		</Modal>
	)
}

export default CancelRequestModal

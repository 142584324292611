import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import SearchOrganizationsParams from '@/features/organizations/types/queryParams/SearchOrganizationsParams'

// Switch to rtk query
const searchOrganizations = async ({ q, page, userId, campusId, categoryId, schoolId }: SearchOrganizationsParams) => {
	const url = `${schoolId}/organizations/${userId}/search`
	const params = {
		q,
		page,
		campusId,
		categoryId,
		perPage: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get(url, { params })
}

export default searchOrganizations

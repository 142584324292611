export enum ButtonColors {
	PRIMARY = 'PRIMARY',
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	LIGHT_GREEN = 'LIGHT_GREEN',
	LIGHT_BLUE = 'LIGHT_BLUE',
	WHITE = 'WHITE',
	YELLOW = 'YELLOW',
	GREY = 'GREY',
	LIGHT_RED = 'LIGHT_RED',
	RED = 'RED',
}

export interface ButtonColor {
	main: string
	hover: string
	disabled: string
	textColor: string
}

export type ButtonColorKey = keyof typeof ButtonColors
export type AllButtonColors = { [k in ButtonColorKey]: ButtonColor }

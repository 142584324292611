import { makeStyles } from '@material-ui/core/styles'
import { CloseOutlined } from '@material-ui/icons'
import MaterialIconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '80%',
		margin: '20px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 'calc(45%)',
		position: 'relative',
	},
	image: ({ url }: any) => ({
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
		justifyContent: 'flex-start',
		borderRadius: 5,
		background: `url(${url}) center center / cover no-repeat`,
	}),
	clearImageButton: {
		width: 30,
		height: 30,
		color: theme.palette.common.white,
		margin: 10,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
}))

interface ImagePreviewProps {
	url?: string
	onClear?: () => void
	className?: string
}

const ImagePreview = ({ onClear, url = '', className = '' }: ImagePreviewProps) => {
	const classes = useStyles({ url })

	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.image}>
				{onClear && (
					<MaterialIconButton className={classes.clearImageButton} onClick={onClear}>
						<CloseOutlined fontSize="small" />
					</MaterialIconButton>
				)}
			</div>
		</div>
	)
}

export default ImagePreview

import { PATHS } from '@/constants'
import { SITE_NAME } from '@/constants/pages'
import { AnalyticsMainRouter } from '@/features/adminConsole/routes/router'
import AppRoute from '@/interfaces/route'
import AuthErrorPage from '@/pages/AuthErrorPage'
import DownloadPage from '@/pages/DownloadPage'
import ErrorPage from '@/pages/ErrorPage'
import LandingPage from '@/pages/LandingPage'
import MainRouter from '@/pages/MainRouter'
import MissingAccountPage from '@/pages/MissingAccountPage'
import RedirectPage from '@/pages/RedirectPage'
import SignUpPage from '@/pages/SignUp'
import { getAllAuthorizedRoles, getAllRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const allRolesAllowed = getAllRoles()
const allAuthorizedRolesAllowed = getAllAuthorizedRoles()
const APP_TITLE = SITE_NAME
const DOWNLOAD_APP_TITLE = createPageTitle('Download App')
const REDIRECT_TITLE = createPageTitle('Redirect')
const ERROR_TITLE = createPageTitle('Error')
const AUTH_ERROR_TITLE = createPageTitle('Auth Error')
const MISSING_ACCOUNT_REDIRECT_TITLE = createPageTitle('Create Account')

const routes: AppRoute[] = [
	{
		component: AnalyticsMainRouter,
		name: 'analytics-root-router',
		path: PATHS.ADMIN,
		exact: false,
		isPrivate: true,
		allowedRoles: [],
		analyticsRole: true,
	},
	{
		component: MainRouter,
		name: 'app-root-router',
		path: PATHS.APP.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DownloadPage,
		title: DOWNLOAD_APP_TITLE,
		name: DOWNLOAD_APP_TITLE,
		path: PATHS.DOWNLOAD_APP,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: RedirectPage,
		title: REDIRECT_TITLE,
		name: REDIRECT_TITLE,
		path: PATHS.AUTH_REDIRECT,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: ErrorPage,
		title: ERROR_TITLE,
		name: ERROR_TITLE,
		path: PATHS.ERROR,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: AuthErrorPage,
		title: AUTH_ERROR_TITLE,
		name: AUTH_ERROR_TITLE,
		path: PATHS.AUTH_ERROR,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: SignUpPage,
		name: 'sign-up-root-router',
		path: PATHS.SIGN_UP.ROOT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: LandingPage,
		title: APP_TITLE,
		name: APP_TITLE,
		path: PATHS.ROOT,
		exact: true,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
	{
		component: MissingAccountPage,
		title: MISSING_ACCOUNT_REDIRECT_TITLE,
		name: MISSING_ACCOUNT_REDIRECT_TITLE,
		path: PATHS.MISSING_ACCOUNT_REDIRECT,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
]

export default routes

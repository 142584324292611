import { useSelector } from 'react-redux'
import { individualPostSelectors, postSelectors } from '../feed/slice'
import { RootState } from '@/store'
import { campusSelectors } from '@/features/campus/slice'

import usePostByIdQuery from './usePostByIdQuery'
import { FeedPost } from '@/store/feeds/types'
import utils from '@/utils'

const useFeedPostById = (postId: FeedPost['id'], skipFetching: boolean = false) => {
	const postInList = useSelector((state: RootState) => postSelectors.selectById(state, postId))
	const individualPost = useSelector((state: RootState) => individualPostSelectors.selectById(state, postId))
	const post = individualPost || postInList
	const campusMap = useSelector(campusSelectors.selectEntities)

	const query = usePostByIdQuery(postId, !!post || skipFetching)

	const normalizedPost = post ? utils.transformers.normalizePostData(post, campusMap) : undefined

	return {
		...query,
		isUninitialized: !!post && query.isUninitialized,
		data: normalizedPost,
	}
}

export default useFeedPostById

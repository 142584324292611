import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '15px 0',
	},
	descriptionText: {
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	clickable: {
		cursor: 'pointer',
	},
}))

interface InfoRowProps {
	className?: string
	logo: React.ReactNode
	title?: string
	subTitle?: string
	onClickRow?: (e: any) => void
}

const InfoRow = ({ className = '', logo, title, subTitle, onClickRow }: InfoRowProps) => {
	const classes = useStyles()

	const clickable = !!onClickRow

	return (
		<div className={classNames(classes.root, clickable ? classes.clickable : '', className)} onClick={onClickRow}>
			{logo}
			<div className={classes.infoContainer}>
				<Typography>{title}</Typography>
				<Typography className={classes.descriptionText}>{subTitle}</Typography>
			</div>
		</div>
	)
}

export default InfoRow

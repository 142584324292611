import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { TextCheckBox } from '@/components/widgets'
import { getStringSchema } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 10vw)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'space-evenly',
		gridGap: 15,
		margin: '2vh 0',
	},
	header: {
		width: '65%',
	},
}))

const options = [
	{
		label: 'Small (15 or fewer members)',
		value: 'Small (15 or fewer members)',
	},
	{
		label: 'Medium (15-50 members)',
		value: 'Medium (15-50 members)',
	},
	{
		label: 'Large (50 or more members)',
		value: 'Large (50 or more members)',
	},
	{
		label: 'I’m open to any size',
		value: 'I’m open to any size',
	},
]

const Question3 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getStringSchema(dataKey, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.header,
					}}
					title="In terms of size, what type of 
      organization are you interested in joining?"
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									options.map((item) => (
										<TextCheckBox
											key={item.value}
											label={item.label}
											value={item.value}
											onChange={handleSelectItem}
											checked={isChecked(item.value)}
										/>
									))
								}
								canSelectMoreThanOne={false}
							/>
						)}
					/>
				</StepperCard>
			}
		/>
	)
}

export default Question3

import {
	GlobeHemisphereWest,
	Briefcase,
	CalendarBlank,
	ChatsCircle,
	HandHeart,
	ListMagnifyingGlass,
	UsersThree,
} from '@phosphor-icons/react'

import defaultBackground from '@school-assets/common/defaultBackground.jpg'

import landingBackground from '@school-assets/common/homeBackground.jpg'
import logo from '@school-assets/common/logo.svg'
import logoAlt from '@school-assets/common/logoAlt.svg'

// Feature Icons

// Welcome assets
import findIcon from '@/assets/welcomeSlides/find_opportunities.png'
import followIcon from '@/assets/welcomeSlides/follow_organizations.png'
import meetIcon from '@/assets/welcomeSlides/meet_others.png'
import happeningIcon from '@/assets/welcomeSlides/path_builder.png'
import pathBuilderIcon from '@/assets/welcomeSlides/see_whats_happening.png'

import { setSchoolConfiguration } from '@navengage/sen-shared-assets'
import { SCHOOL_KEY } from '../constants/app'

export const DEFAULT_SCHOOL_CONFIG = {
	logo: {
		main: logo,
		alt: logoAlt,
	},
	defaultBackground,
	landingBackground,
	features: {
		research: {
			icon: {
				main: ListMagnifyingGlass,
			},
		},
		events: {
			icon: {
				main: CalendarBlank,
			},
		},
		organizations: {
			icon: {
				main: UsersThree,
			},
		},
		jobs: {
			icon: {
				main: Briefcase,
			},
		},
		volunteers: {
			icon: {
				main: HandHeart,
			},
		},
		study_abroad: {
			icon: {
				main: GlobeHemisphereWest,
			},
		},
		group_chats: {
			icon: {
				main: ChatsCircle,
			},
		},
		welcome: {
			config: {
				happening: {
					icon: happeningIcon,
				},
				find: {
					icon: findIcon,
				},
				follow: {
					icon: followIcon,
				},
				meet: {
					icon: meetIcon,
				},
				pathBuilder: {
					icon: pathBuilderIcon,
				},
			},
		},
	},
}

export const initSchoolConfig = () => {
	setSchoolConfiguration(SCHOOL_KEY, DEFAULT_SCHOOL_CONFIG)
}

import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	card: (props: { checked: boolean }) => ({
		width: '100%',
		minHeight: 50,
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		color: props.checked ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '6px 1%',
		fontSize: '16px',
	}),
	contentContainer: (props: { checked: boolean }) => ({
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingLeft: 'calc(25px + 5%)',
		fontWeight: props.checked ? 600 : 400,
	}),
	icon: {
		color: theme.palette.primary.main,
		paddingRight: 5,
	},
	iconUnchecked: {
		paddingRight: 5,
		color: theme.palette.text.primary,
	},
}))

interface SelectOptionProps {
	className?: string
	value: number | string
	label: string
	onClick: (v: number | string) => void
	checked: boolean
	multiple?: boolean
}

const SelectOption = ({
	className = '',
	value = -1,
	label = '',
	onClick = (v) => {},
	checked = false,
	multiple = false,
}: SelectOptionProps) => {
	const classes = useStyles({ checked })

	const handleClick = () => onClick(value)

	const checkIcon = !multiple ? null : <IconWrapper iconKey="circle" className={classes.iconUnchecked} />
	const checkedIcon = !multiple ? (
		<IconWrapper iconKey="check" className={classes.icon} />
	) : (
		<IconWrapper iconKey="checkCircle" weight="fill" className={classes.icon} />
	)

	return (
		<Button className={classNames(classes.card, className)} onClick={handleClick}>
			<Typography className={classes.contentContainer}>
				{label}
				{checked ? checkedIcon : checkIcon}
			</Typography>
		</Button>
	)
}

export default SelectOption

import { PubsubMessageData, PubsubMessageTopic } from './types'

import Pubsub from 'pubsub-js'
import { generateUuid } from '../uuid'
import getPostInfo from '@/api/http/feeds/getPostInfo'
import { PostId } from '../types'
import { FeedPost } from '@/store/feeds/types'
import utils from '@/utils'
import { STORAGE } from '@/constants'
import { mutateSwr, mutateSwrWithoutRevalidating } from '../swr'

const getCurrentUserId = () => {
	return +(utils.storage.get(STORAGE.CURRENT_USER_ID_PATH) || 0)
}

export const getPubSubPublishers = (schoolId: number) => {
	return {
		publishFeedPostCreated: (data: { post: FeedPost } | PubsubMessageData) => {
			publishToPubsub(PubsubMessageTopic.FeedPostCreated, data)

			mutateSwrWithoutRevalidating(getPostInfo.buildUrl({ userId: getCurrentUserId(), postId: data.post.id, schoolId }), {
				post: data.post,
			})
		},
		publishFeedPostUpdated: (data: { postId: PostId; newPost: FeedPost } | PubsubMessageData) => {
			publishToPubsub(PubsubMessageTopic.FeedPostUpdated, data)

			mutateSwrWithoutRevalidating(getPostInfo.buildUrl({ userId: getCurrentUserId(), postId: data.postId, schoolId }), {
				post: data.newPost,
			})
		},
		publishFeedPostDeleted: (data: { postId: PostId } | PubsubMessageData) => {
			publishToPubsub(PubsubMessageTopic.FeedPostDeleted, data)

			mutateSwr(getPostInfo.buildUrl({ userId: getCurrentUserId(), postId: data.postId, schoolId }), null)
		},
	}
}

/**
 *
 * @param messageTopic
 * @param data
 */
const publishToPubsub = (messageTopic: PubsubMessageTopic, data) => {
	const publishedAt = Date.now()
	const id = `${messageTopic}_${generateUuid()}`

	Pubsub.publish(`${messageTopic}`, {
		id,
		publishedAt,
		data,
	})
}

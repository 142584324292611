import { FeedsFilters, FilterHash, FilterHashData, UserAudience } from '@/store/feeds/types'
import { GlobalState } from '../GlobalState'

export const _homeFeedGlobals = {
	filters: new GlobalState<FeedsFilters>({
		defaultValue: {
			tags: [],
			usersAudience: UserAudience.COMMUNITY,
			filterIsReady: false,
			campusId: undefined,
			includeAllCampuses: true,
		},
	}),
	filterPostResults: new GlobalState<{
		[key: FilterHash]: FilterHashData
	}>({
		defaultValue: {},
	}),
	scrollPosition: new GlobalState<number>({
		defaultValue: 0,
	}),
}

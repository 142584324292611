import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'

import { FilledTextField } from '@/components'
import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import { useSignupStepsContext } from './SignupStepsContext'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from './hooks'

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '45%',
	},
	continueButton: {
		marginBottom: 15,
	},
	card: {
		height: '75vh',
	},
	cardHeader: {
		width: '70%',
	},
	cardHeaderSubTitle: {
		color: theme.palette.text.primary,
	},
	textField: {
		marginBottom: 15,
	},
	infoText: {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		marginTop: 10,
	},
	link: {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
	},
}))

const UserNameStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const {
		school: { accounts },
		links: { global },
	} = useSchoolConfig()
	const userNameConfig = useOnboardingStepConfig('userName')

	const { formData, userInfo } = useSignupStepsContext()

	const [userName, setUserName] = useState(
		formData.patches.userName || {
			firstName: userInfo.firstName ?? '',
			lastName: userInfo.lastName ?? '',
		},
	)

	const disableContinueButton = !checkCanProceed(userName)

	const handleChange =
		(key: string) =>
		({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
			setUserName({
				...userName,
				[key]: value,
			})

	useSignupStepDataPatcher(
		{
			userName,
		},
		[userName],
	)

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.ENTERED_NAME)
	}

	return (
		<SignUpCard
			classnames={{
				root: classes.card,
				continueButton: classes.continueButton,
				header: classes.cardHeader,
				subTitle: classes.cardHeaderSubTitle,
			}}
			title={userNameConfig.title}
			subTitle={userNameConfig.subTitle}
			description={userNameConfig.description}
			continueButtonDisabled={disableContinueButton}
			onClickContinue={onClickContinue}
		>
			<div className={classes.content}>
				<FilledTextField
					variant="secondary"
					className={classes.textField}
					placeholder="First Name"
					onChange={handleChange('firstName')}
					value={userName.firstName}
				/>
				<FilledTextField
					variant="secondary"
					className={classes.textField}
					placeholder="Last Name"
					onChange={handleChange('lastName')}
					value={userName.lastName}
				/>
				{global.accounts && (
					<Typography className={classes.infoText} variant="body2">
						This is your name in&nbsp;
						<b>
							<Link className={classes.link} href={global.accounts.href} target="_blank">
								Accounts
							</Link>
						</b>
						. Changing your name here will not change your name in {accounts}.
					</Typography>
				)}
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (userName) => {
	return Object.values(userName).every((value?: string) => !!value?.trim())
}

UserNameStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.userName)
}

export default UserNameStep

import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'

import {
	ChooseAcademicCollege,
	ChooseExperiences,
	ChooseInterests,
	ChooseOpportunities,
	ChooseYourMajor,
	FollowAcademicColleges,
	FollowOrganizations,
	JoinGroupChats,
	MakeConnections,
} from '@/assets/signupEntities'
import { NavTabs } from '@/components/NavTabs'
import { useDeleteMajorsMutation } from '@/features/majors/majorsApi'
import {
	useDeleteOnboardingEntitiesByIdMutation,
	useDeleteSignupExtraEntitiesMutation,
	useGetOnboardingEntitiesForDashboardQuery,
} from '@/features/onboarding/api'
import EntityScope from '@/features/onboarding/types/EntityScope'
import useSchoolId from '@/features/school/hooks/useSchoolId'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useGetSignupProcessMetaQuery } from '../api/signupDashboardApi'
import { CommonCard } from '../components/CommonCard'
import { SignupDashboardTable } from '../components/dataTable/SignupDashboardTable'
import { Layout } from '../components/Layout'
import { schoolEntityDisabledPages } from '../routes/disabledPages'
import { DataTableConfig } from '../types/dashboardTypes'
import { EditableOnboardingSteps } from '../types/EditableOnboardingSteps.enum'
import { useDeleteAcademicCollegesMutation } from '@/features/academicColleges/academicCollegesApi'

const useStyles = makeStyles((theme) => ({
	cardWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		[theme.breakpoints.up('md')]: {
			maxWidth: '88vw',
		},
	},
}))

const SignupDashboardMainPage = () => {
	const classes = useStyles()
	const schoolId = useSchoolId()
	const disabledSteps = useMemo(() => schoolEntityDisabledPages[schoolId] || [], [schoolId])

	const tables = useMemo(() => {
		const tableConfigs: DataTableConfig[] = [
			{
				label: 'Select Interests',
				entityType: EntityTypes.interest,
				deleteMutation: useDeleteSignupExtraEntitiesMutation,
				description: `Interests Page.`,
				learnMoreProps: {
					title: 'Interests Page.',
					image: { src: ChooseInterests },
					description: `This field allows students to select their interests from a list that you create. Some examples of potential interests are: Service & Philanthropy; Civic Engagement; Health & Wellness; and Sports. We recommend adding 6-8 interests for students to choose from.`,
				},
			},
			{
				label: 'Select Experiences',
				entityType: EntityTypes.experience,
				deleteMutation: useDeleteSignupExtraEntitiesMutation,
				description: `Experience Page.`,
				learnMoreProps: {
					title: 'Experiences Page.',
					image: { src: ChooseExperiences },
					description: `These are experiences, programs, or High-Impact Practices available for students to participate in. We add these here to gain data on what experiences students say they’re interested in. Some examples of potential experiences are: Study abroad; Complete research; and Volunteer. We recommend adding 6-8 experiences for students to choose from.`,
				},
			},
			{
				label: 'Follow Organizations',
				onboardingStep: EditableOnboardingSteps.FOLLOW_ORGS,
				entityType: EntityTypes.organization,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				entityScope: EntityScope.Standard,
				description: `Follow Organizations Page.`,
				learnMoreProps: {
					image: { src: FollowOrganizations },
					title: 'Follow Organizations',
					description: `Students can follow organizations in the app. These organizations that you designate in the sign-up process can be
								campus-significant organizations or new organizations that you want to expand throughout your campus community. Some examples
								of potential organizations are Student Government and Student Publication. We recommend adding 4-8 organizations. Note - these
								organizations must already be listed as organizations in Anthology Engage.`,
				},
			},
			{
				label: 'Choose Your Academic College',
				onboardingStep: EditableOnboardingSteps.ACADEMIC_COLLEGES,
				entityType: EntityTypes.academicCollege,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				description: `Choose Your Academic College Page.`,
				learnMoreProps: {
					image: { src: ChooseAcademicCollege },
					title: 'Choose Your Academic College.',
					description: `Here we list the available academic colleges at your institution and ask students, “What is your academic college?” so they
								can self-select their answer. Note - deleting an Academic College from this list will not delete it from any student who has
								already selected it, it will only delete it from the signup process. Please contact us if you want it fully removed from the
								system.`,
				},
			},
			{
				label: 'Follow Academic Colleges',
				onboardingStep: EditableOnboardingSteps.FOLLOW_ACADEMIC_ORGS,
				entityType: EntityTypes.organization,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				entityScope: EntityScope.Academic,
				description: `Follow Academic Colleges.`,
				learnMoreProps: {
					title: 'Follow Academic Colleges.',
					description: `Students can follow academic colleges in the app. 
					Following academic colleges can be helpful for first-year students who are interested in multiple majors or minors and for students who have not yet declared a major.
					Note - as with the other organizations page, these organizations must already be listed as organizations in Anthology Engage.`,
					image: { src: FollowAcademicColleges },
				},
			},
			{
				label: 'Choose Your Major',
				onboardingStep: EditableOnboardingSteps.MAJORS,
				entityType: EntityTypes.major,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				description: `Choose Your Major.`,
				learnMoreProps: {
					title: 'Choose your Major Page.',
					description: `We list the available majors at your institution and ask students to choose their major or program, so they can self-select their answer.
					If students do not wish to disclose their major, they can select a “Skip” option. If you have an “Undeclared” or “Undecided” major option at your institution, please provide it with other major options.
					Note - deleting a major from this list will not delete it from 	any student who has already selected it, 
					it will only delete it from the signup process. Please contact us if you want it fully removed from the system.`,
					image: { src: ChooseYourMajor },
				},
			},
			{
				label: 'Make Connections',
				entityType: EntityTypes.user,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				onboardingStep: EditableOnboardingSteps.CONNECTIONS,
				description: `Make Connections Page.`,
				learnMoreProps: {
					title: 'Make Connections.',
					description: `Users in the application are able to connect with each other. 
					The users you designate here will show up as people you recommend that students should connect with on the app. 
					To avoid an overload of notifications, these users will not get a connection request; rather,
					 they will automatically connect when a user selects their profile. 
					 They can choose to disconnect later. Some suggestions include the Student Body President, Student Government Representative, or Student Affairs Staff Members
					We recommend adding 6-8 users. Note - users must have logged into the app to be added here.`,
					image: { src: MakeConnections },
				},
			},
			{
				label: 'Auto-Follow Organizations',
				entityType: EntityTypes.autoFollowOrganizations,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				onboardingStep: EditableOnboardingSteps.AUTO_FOLLOW_ORGANIZATIONS,
				description: `Auto-Follow Organizations.`,
				learnMoreProps: {
					title: 'Auto-Followed Organizations',
					description: `Auto-followed organizations are not shown to students during the signup process. Instead, after a student finishes the signup process, they will
					be auto-follow these organizations. For example, you could add the Student Affairs page here. Users can choose to unfollow this organization once in the application.
					Organizations must be listed in Engage prior to adding here.`,
				},
			},
			{
				label: 'Auto-Connect Users',
				entityType: EntityTypes.autoConnectUsers,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				onboardingStep: EditableOnboardingSteps.AUTO_CONNECT_USERS,
				description: `Auto-Connect Users.`,
				learnMoreProps: {
					title: 'Auto-Connect Users.',
					description: `Auto-connected users are not shown to students during the signup process. Instead, after a student finishes the signup process, they will
					be auto-connected with the users here. For example, you could add the Student Body President here. Users can choose to disconnect
					with this user once in the application.`,
				},
			},
			{
				label: 'Join Group Chats',
				entityType: EntityTypes.group,
				deleteMutation: useDeleteOnboardingEntitiesByIdMutation,
				onboardingStep: EditableOnboardingSteps.JOIN_GROUPS,
				description: `Join Group Chats Page.`,
				learnMoreProps: {
					title: 'Join Group Chats.',
					description: `Users in the app can join group chats. 
					The group chats you designate in the sign-up process can be chats that you think a large 
					majority of students will find valuable, or they can be based around 
					specific groups of students (ex. adult learners, international students, etc.). We
					recommend creating 6-10 group chats. Note - group chats must be created before they can be added to signup. go to 
					Chats to Create a Chat.`,
					image: { src: JoinGroupChats },
				},
			},

			{
				label: 'Select Opportunities',
				deleteMutation: useDeleteSignupExtraEntitiesMutation,
				entityType: EntityTypes.opportunities,
				description: `Unique Opportunities Page.`,
				learnMoreProps: {
					title: 'Unique Opportunities Page.',
					description: `These options are opportunities or resources that students can get involved in that they might not otherwise know about. We recommend 4-8 unique opportunities. Some examples of potential unique opportunities are: Financial Literacy Center; Tutoring Center; Free Food on Campus`,
					image: { src: ChooseOpportunities },
				},
			},
		]

		return tableConfigs
			.filter(({ onboardingStep }) => !disabledSteps.includes(onboardingStep))
			.map(({ label, onboardingStep, ...dataTableProps }) => {
				return {
					label,
					component: (
						<SignupDashboardTable
							{...dataTableProps}
							listQuery={useGetOnboardingEntitiesForDashboardQuery}
							metaQuery={useGetSignupProcessMetaQuery}
						/>
					),
				}
			})
	}, [disabledSteps])

	return (
		<Layout>
			<div className={classes.cardWrapper}>
				<CommonCard title="Sign-Up Process" subHeader="Customize the signup process for your users.">
					<NavTabs initialTab={0} withHistory tabs={tables} />
				</CommonCard>
			</div>
		</Layout>
	)
}

export default SignupDashboardMainPage

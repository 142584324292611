import ColorButton from '@/components/Buttons/ColorButton'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { setAddNewMembersPopup, setDrawer } from '@/store/groups'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 7,
		margin: 15,
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	placeholderButton: {
		minWidth: 200,
		margin: 15,
	},
	placeholderText: {
		margin: 10,
		fontWeight: 700,
	},
}))

interface EmptyMembersListPlaceholderProps {
	className?: string
	onClickAddMembers?: () => void
}

const EmptyMembersListPlaceholder = ({ className, onClickAddMembers = () => {} }: EmptyMembersListPlaceholderProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const handleOpenSideBar = () => {
		onClickAddMembers()
		dispatch(setAddNewMembersPopup(true))
		dispatch(setDrawer(true))
	}

	return (
		<div className={classNames(className, classes.placeholder)}>
			<Typography className={classes.placeholderText} variant="body1">
				Get your group started!
			</Typography>
			<ColorButton className={classes.placeholderButton} onClick={handleOpenSideBar}>
				Add Members
			</ColorButton>
		</div>
	)
}

export default EmptyMembersListPlaceholder

// @ts-nocheck

import { createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import * as actions from '@/store/pathBuilder/actions'
import { PathBuilderState } from '@/store/pathBuilder/types'

const initialState: PathBuilderState = {
	form: {},
	formBuilder: {
		currentStep: 0,
		isEdit: false,
		data: {},
	},
	searchOrganization: {
		q: '',
		page: 1,
		list: [],
		isLoading: false,
		canLoadMore: true,
	},
	isOpenPathBuilderCompletedPopup: false,
	startBuildPath: false,
	countries: [],
}

const pathBuilderReducer = createReducer(initialState, (builder) => {
	builder.addCase(actions.setForm, (state, { payload }) => ({
		...state,
		form: payload,
	}))
	builder.addCase(actions.updateForm, (state, { payload }) => ({
		...state,
		form: {
			...state.form,
			...payload,
		},
	}))
	builder.addCase(actions.setIsOpenPathBuilderCompletedPopup, (state, { payload }) => ({
		...state,
		isOpenPathBuilderCompletedPopup: payload,
	}))
	builder.addCase(actions.setStartBuilderPath, (state, { payload }) => ({
		...state,
		startBuildPath: payload,
	}))
	builder.addCase(actions.setIsEditPathBuilder, (state, { payload: { builderData, stepToOpenIndex, isEdit } }) => ({
		...state,
		formBuilder: {
			...state.formBuilder,
			isEdit,
			currentStep: stepToOpenIndex,
			data: builderData,
		},
	}))
	builder.addCase(actions.setPathBuilder, (state, { payload: { key, currentSubStep, answer, clearFollowingAnswers } }) => {
		const builder = state.formBuilder.data
		const stepData = cloneDeep(builder[key] ?? [])
		let newStepData = stepData

		if (clearFollowingAnswers) {
			newStepData = stepData.slice(0, currentSubStep)
		} else {
			newStepData =
				stepData.length > currentSubStep ? stepData.map((data, i) => (i === currentSubStep ? answer : data)) : stepData.concat([answer])
		}

		const newData = {
			...builder,
			[key]: newStepData,
		}

		return {
			...state,
			form: {
				...state.form,
				...answer,
			},
			formBuilder: {
				...state.formBuilder,
				data: newData,
			},
		}
	})
	builder.addCase(actions.setSearchOrganizationsQuery, (state, { payload }) => ({
		...state,
		searchOrganization: {
			...state.searchOrganization,
			q: payload,
		},
	}))
	builder.addCase(actions.setSearchOrganizations, (state, { payload }) => ({
		...state,
		searchOrganization: {
			...state.searchOrganization,
			...payload,
		},
	}))
	builder.addCase(actions.setCountries, (state, { payload }) => ({
		...state,
		countries: payload,
	}))
})

export { pathBuilderReducer }

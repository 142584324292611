import { TextField } from '@/components'
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useEffect, useRef, useState } from 'react'

import Label from '@/components/Label'
import { STYLES } from '@/constants'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { TUserPrimaryTitle } from '@/interfaces/common'
import { formPrimaryTitle } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '42vw',
		marginTop: '2vh',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
		[theme.breakpoints.down('sm')]: {
			width: '94vw',
		},
	},
	form: {
		width: '100%',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	userImage: {
		width: 100,
		height: 100,
		margin: '2vh 0',
	},
	formFieldsContainer: {
		width: '100%',
	},
	selectsContainer: {
		width: '60%',
		height: 'fit-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	select: {
		width: '48%',
	},
	notSingleInput: {
		marginBottom: '1.5vh',
	},
	logoInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '1.5vh 0',
	},
	logoInput: {
		marginLeft: -5,
		marginRight: 10,
	},
	previewContainer: {
		color: theme.palette.text.primary,
		// @TODO: mixed units paddings in percentages are bad
		width: '85%',
		margin: '3vh 0',
		padding: '3% 3%',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	textFieldWithoutLabel: {
		margin: '0.5vh 0',
	},
	label: {
		margin: '4vh 0 1vh 0',
	},
}))

interface PrimaryTitleEditorProps {
	value: TUserPrimaryTitle
	onFinishEdit?: (v: TUserPrimaryTitle) => void
	onClickQuestion?: () => void
}

const PrimaryTitleEditor = ({
	value: { primaryTitle, primaryTitleDepartment },
	onFinishEdit = (v: TUserPrimaryTitle) => {},
	onClickQuestion,
}: PrimaryTitleEditorProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const [showEditForm, setShowEditForm] = useState(false)

	const [position, setPosition] = useState(primaryTitle ?? '')
	const [department, setDepartment] = useState(primaryTitleDepartment ?? '')
	const containerRef = useRef<any>(null)
	const previewInputRef = useRef<any>(null)
	const positionInputRef = useRef<any>(null)
	const departmentInputRef = useRef<any>(null)

	useEffect(() => {
		if (positionInputRef && positionInputRef.current) {
			positionInputRef.current.focus()
		}
	}, [showEditForm])

	useOutsideClickCallback(containerRef, () => {
		if (position !== primaryTitle || department !== primaryTitleDepartment) {
			onFinishEdit({ primaryTitle: position.trim(), primaryTitleDepartment: department.trim() })
		}
		setShowEditForm(false)
	})

	return (
		<div ref={containerRef} className={classes.formFieldsContainer}>
			<Label classnames={{ label: classes.label }} title="Primary Title" boldTitle={false} onClickQuestion={onClickQuestion}>
				{!showEditForm ? (
					<TextField
						placeholderColor={appTheme.palette.primary.main}
						onFocus={() => setShowEditForm(true)}
						ref={previewInputRef}
						placeholder="Add your primary title"
						value={formPrimaryTitle([position, department])}
					/>
				) : (
					<>
						<TextField
							className={classes.textFieldWithoutLabel}
							autoFocus
							onChange={(e: any) => setPosition(e.currentTarget.value)}
							ref={positionInputRef}
							placeholder="Add your position"
							value={position}
						/>
						<TextField
							className={classes.textFieldWithoutLabel}
							onChange={(e: any) => setDepartment(e.currentTarget.value)}
							ref={departmentInputRef}
							placeholder="Add your organization, department, etc."
							value={department}
						/>
						<div className={classes.previewContainer}>
							<Typography>Title Preview</Typography>
							<Typography className={classes.grayText}>
								{formPrimaryTitle([position || 'Position', department || 'Organization'])}
							</Typography>
						</div>
					</>
				)}
			</Label>
		</div>
	)
}

export default PrimaryTitleEditor

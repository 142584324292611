import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import * as actions from '@/store/research/actions'
import { ResearchState } from '@/store/research/types'

const initialState: ResearchState = {
	search: {
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	research: [],
	isLoading: false,
	isLoadingIndividualResearch: true,

	positionAreas: [],
	positionTypes: [],
	workSettings: [],
	userResearch: [],
}

const researchReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setResearch, (state, action) => ({
			...state,
			research: action.payload,
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			research: [],
		}))

		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					...rest,
				},
			}
		})
		.addCase(actions.setUserResearch, (state, action) => ({
			...state,
			userResearch: action.payload,
		}))
		.addCase(actions.setSelectedResearch, (state, action) => ({
			...state,
			selectedResearch: action.payload,
		}))

		.addCase(actions.loadResearchByIdRequest.pending, (state) => ({
			...state,
			isLoadingIndividualResearch: true,
		}))
		.addCase(actions.loadResearchByIdRequest.fulfilled, (state) => ({
			...state,
			isLoadingIndividualResearch: false,
		}))
		.addCase(actions.loadResearchByIdRequest.rejected, (state) => ({
			...state,
			isLoadingIndividualResearch: false,
		}))
		.addCase(actions.setUserResponse, (state, { payload: { id, response } }) => {
			if (state.selectedResearch && state.selectedResearch.id === id) {
				state.selectedResearch.responseStatus = response
			}
			const item = state.research.find((r) => r.id === id)
			if (item) {
				item.responseStatus = response
			}
		})
})

export { researchReducer }

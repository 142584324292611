import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import { Clock, Sparkle, SuitcaseSimple, Tree } from '@phosphor-icons/react'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
	CampusIcon,
	ClockOutlinedIcon,
	DollarIcon,
	GraduationHatOutlinedIcon,
	HouseOutlinedIcon,
	LocationOutlinedIcon,
	PersonOutlinedIcon,
	SearchOutlinedIcon,
} from '@/components'
import FilterDatePicker from '@/components/Filter/FilterDatePicker'
import FilterNav, { FilterNavProps } from '@/components/Filter/FilterNav'
import FilterNumberSlider from '@/components/Filter/FilterNumberSlider'
import CustomSelect from '@/components/Filter/FilterSelect'
import FilterToggle from '@/components/Filter/FilterToggle'
import { STYLES } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { FilterOptionsKeys, Filters, SearchConfig, SearchParams } from '@/interfaces/common'
import { getUserCampus } from '@/store/auth'
import { getMajors } from '@/store/constants/constantsSlice'
import { CircularProgress, InputAdornment, TextField, Typography } from '@material-ui/core'
import { FilterCategoryTree } from './FilterCategoryTree'

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: STYLES.FILTER.XL,
		maxWidth: STYLES.FILTER.XL,
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.FILTER.LG,
			maxWidth: STYLES.FILTER.LG,
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: STYLES.FILTER.SM,
			maxWidth: 'none',
		},
		borderRight: `solid 1.5px ${theme.palette.border.bold} `,
	},
	container: {
		position: 'fixed',
		maxHeight: `calc(100vh - ${STYLES.MAIN_HEADER_HEIGHT}vh)`,
		minWidth: STYLES.FILTER.XL,
		maxWidth: STYLES.FILTER.XL,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		[theme.breakpoints.down('lg')]: {
			minWidth: STYLES.FILTER.LG,
			maxWidth: STYLES.FILTER.LG,
		},
		[theme.breakpoints.down('sm')]: {
			position: 'inherit',
			maxHeight: 'none',
			width: '100vw',
			minWidth: STYLES.FILTER.SM,
			maxWidth: 'none',
		},
	},
	header: {
		fontWeight: 700,
		padding: '3vh 5% 0 5%',
	},
	input: {
		fontSize: '1em',
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.33)' : theme.shadows[10],
		margin: '1.5vh 5%',
		...theme.typography.body1,
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	filterBlock: {
		padding: '0.7vh 0',
		margin: '0 5%',
		// TODO: check was this a mistake or required by the design
		borderBottom: `solid 1px ${theme.palette.divider}`,
		display: 'flex',
		flexDirection: 'column',
	},
	filterBlockHeader: {
		fontWeight: 700,
		padding: '1vh 0',
		margin: '1vh 0',
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	rendererContainer: {
		margin: '0 5%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0,
		},
	},
	filtersPreloader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 200,
	},
}))

interface FilterBlockProps {
	title?: string
	children: React.ReactNode
}

export const FilterBlock = ({ title = '', children }: FilterBlockProps) => {
	const classes = useStyles()

	return (
		<div className={classes.filterBlock}>
			{title && (
				<Typography className={classes.filterBlockHeader} variant="body1">
					{title}
				</Typography>
			)}
			{children}
		</div>
	)
}

export type FilterNavigation = FilterNavProps

type FilterOption = {
	[key in FilterOptionsKeys]?: any
}

type DefaultFilterOptions = {
	[key in FilterOptionsKeys]?: any
}

type FilterAssets = {
	[key in FilterOptionsKeys]: {
		label: string
		icon: React.ReactNode | null
		type: 'select' | 'multiselect' | 'toggle' | 'number' | 'date' | 'tree'
		treeSelector?: (v: any) => any
	}
}

const filterAssets: FilterAssets = {
	[Filters.campuses]: {
		label: 'Campus',
		icon: <CampusIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.branches]: {
		label: 'Branch',
		icon: <Tree size={ICON_SIZES.md} />,
		type: 'select',
	},
	[Filters.categories]: {
		label: 'Category',
		icon: <Sparkle size={ICON_SIZES.md} />,
		type: 'multiselect',
	},
	[Filters.date]: {
		label: 'Date',
		icon: <Clock size={ICON_SIZES.md} />,
		type: 'date',
	},
	[Filters.employers]: {
		label: 'Employer',
		icon: <SuitcaseSimple size={ICON_SIZES.md} />,
		type: 'select',
	},
	[Filters.locations]: {
		label: 'Location',
		icon: <LocationOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.positionAreas]: {
		label: 'Area',
		icon: <SearchOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.disciplines]: {
		label: '',
		icon: null,
		type: 'select',
	},
	[Filters.positionTypes]: {
		label: 'Position Type',
		icon: <PersonOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.majors]: {
		label: 'Category',
		icon: <FilterListIcon fontSize="medium" />, // FIXME
		type: 'tree',
		treeSelector: getMajors,
	},
	[Filters.workSettings]: {
		label: 'Work Setting',
		icon: <LocationOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.terms]: {
		label: 'Term',
		icon: <ClockOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.housingTypes]: {
		label: 'Housing Type',
		icon: <HouseOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.scholarshipsOffered]: {
		label: 'Scholarship Offered',
		icon: <GraduationHatOutlinedIcon fontSize="medium" />,
		type: 'toggle',
	},
	[Filters.classLevels]: {
		label: 'Class Level',
		icon: <GraduationHatOutlinedIcon fontSize="medium" />,
		type: 'multiselect',
	},
	[Filters.minimumGPA]: {
		label: 'Minimum GPA',
		icon: <GraduationHatOutlinedIcon fontSize="medium" />,
		type: 'number',
	},
	[Filters.paid]: {
		label: 'Paid',
		icon: <DollarIcon fontSize="medium" />,
		type: 'toggle',
	},
	[Filters.country]: {
		label: 'Country',
		icon: null,
		type: 'multiselect',
	},
	[Filters.connectivityStatus]: {
		label: '',
		icon: null,
		type: 'select',
	},
	[Filters.userTypeId]: {
		label: '',
		icon: null,
		type: 'multiselect',
	},
}

const renderFilters = (
	filters: Filters[],
	filterOptions: FilterOption,
	selectedFilters: any,
	defaultFilterOptions: DefaultFilterOptions,
	handleChangeFilterValue: (key: string) => (value: any) => void,
) => {
	return filters.map((objKey, idx) => {
		const key = objKey as FilterOptionsKeys
		const filterAsset = filterAssets[key]
		const filterValue = selectedFilters[key]
		const options = filterOptions[key] as any[]
		const defaultValue = defaultFilterOptions[key]

		return (
			<FilterBlock key={key} title={!idx ? 'Filter' : undefined}>
				{(() => {
					switch (filterAsset.type) {
						case 'multiselect':
						case 'select':
							return (
								<CustomSelect
									onChange={handleChangeFilterValue(key)}
									options={options}
									value={filterValue}
									preselectedValues={defaultValue}
									defaultLabel={filterAsset.label}
									icon={filterAsset.icon}
									multiple={filterAsset.type === 'multiselect'}
								/>
							)
						case 'number':
							return (
								<FilterNumberSlider
									onChange={handleChangeFilterValue(key)}
									value={filterValue}
									preselectedValue={defaultValue}
									label={filterAsset.label}
									icon={filterAsset.icon}
								/>
							)
						case 'toggle':
							return (
								<FilterToggle
									onChange={handleChangeFilterValue(key)}
									value={filterValue}
									preselectedValue={defaultValue}
									label={filterAsset.label}
									icon={filterAsset.icon}
								/>
							)
						case 'date':
							return (
								<FilterDatePicker
									onChange={handleChangeFilterValue(key)}
									value={filterValue}
									preselectedValue={defaultValue}
									label={filterAsset.label}
									icon={filterAsset.icon}
								/>
							)
						case 'tree':
							return (
								<FilterCategoryTree
									treeSelector={getMajors}
									onChange={handleChangeFilterValue(key)}
									options={options}
									value={filterValue}
									defaultLabel={filterAsset.label}
									icon={filterAsset.icon}
								/>
							)
						default:
							return null
					}
				})()}
			</FilterBlock>
		)
	})
}

interface FilterProps {
	title: string
	searchPlaceholder: string
	filters: Filters[]
	filterNavigation?: FilterNavigation[]
	onFilterChange: (config: SearchConfig) => void
	value: SearchConfig
	canUseFilter?: boolean
	additionalElementsRenderer?: () => React.ReactNode
	filterOptions?: FilterOption
	defaultFilterOptions?: DefaultFilterOptions
	clearFilters: () => void
	isLoading?: boolean
}

const Filter = ({
	title,
	searchPlaceholder,
	filters,
	filterNavigation = [],
	canUseFilter = true,
	onFilterChange,
	additionalElementsRenderer = () => null,
	filterOptions = {},
	defaultFilterOptions = {},
	value,
	clearFilters,
	isLoading = false,
}: FilterProps) => {
	const classes = useStyles()
	const userCampus = useSelector(getUserCampus)

	const { query, ...rest } = value.params!

	const [searchValue, setSearchValue] = useState(query || '')
	const [isTouched, setIsTouched] = useState(false)
	const [selectedFilters, setSelectedFilters] = useState({ ...rest })

	const shouldShowSearchAndFilter = canUseFilter

	const handleFilterChange = useCallback(
		(newParams: SearchParams) => {
			const config = {
				...value,
				params: {
					...(value.params || {}),
					...selectedFilters,
					...newParams,
				},
				query: searchValue,
				fetchMore: false,
				filterIsReady: true,
			}

			onFilterChange(config)
		},
		[value, searchValue, selectedFilters, onFilterChange],
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedHandleChangeFilter = useCallback(
		debounce((newParams: SearchParams) => handleFilterChange(newParams), 300),
		[handleFilterChange],
	)

	const handleChangeInput = useCallback(({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(value)
		setIsTouched(true)
	}, [])

	const handleClearInput = useCallback(() => {
		setSearchValue('')
	}, [])

	const handleChangeFilterValue = useCallback(
		(key: string) => (val: any) => {
			setSelectedFilters((prev) => ({ ...prev, [key]: val }))
		},
		[],
	)

	useEffect(() => {
		if (isTouched) delayedHandleChangeFilter({ query: searchValue })
		else delayedHandleChangeFilter(selectedFilters)

		return delayedHandleChangeFilter.cancel
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, isTouched, selectedFilters])

	const applyPreselectedValues = useCallback(() => {
		const campus = filterOptions?.campuses?.find((campus: any) => campus.label === userCampus)

		setSelectedFilters({ ...defaultFilterOptions })
		setSearchValue('')
		// if user has no campus assigned
		if (campus) {
			handleChangeFilterValue(Filters.campuses)([campus?.value])
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions?.campuses, userCampus])

	useEffect(() => {
		applyPreselectedValues()
		return clearFilters
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography className={classes.header} variant="h1">
					{title}
				</Typography>
				{shouldShowSearchAndFilter && (
					<TextField
						className={classes.input}
						onChange={handleChangeInput}
						value={searchValue}
						placeholder={searchPlaceholder}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							),
							endAdornment: searchValue ? (
								<InputAdornment className={classes.clearInputButton} position="end" onClick={handleClearInput}>
									<Close className={classes.inputIcon} fontSize="medium" />
								</InputAdornment>
							) : undefined,
						}}
						variant="outlined"
					/>
				)}
				{filterNavigation && (
					<FilterBlock>
						{filterNavigation.map((nav) => (
							<FilterNav key={nav.path} {...nav} />
						))}
					</FilterBlock>
				)}
				{isLoading && shouldShowSearchAndFilter ? (
					<div className={classes.filtersPreloader}>
						<CircularProgress />
					</div>
				) : null}
				{!isLoading &&
					shouldShowSearchAndFilter &&
					filters &&
					renderFilters(filters, filterOptions, selectedFilters, defaultFilterOptions, handleChangeFilterValue)}
				<div className={classes.rendererContainer}>{additionalElementsRenderer()}</div>
			</div>
		</div>
	)
}

export default Filter

import RoutePath from '@penname/routepath'

import req from './requestHttp'
import research from './research'
import internships from './internships'
import events from './events'
import organizations from './organizations'
import volunteers from './volunteers'
import uploadPhoto from './uploadPhoto'
import getSecurePhoto from './getSecurePhoto'
import studyAbroad from './studyAbroad'
import network from './network'
import feeds from './feeds'
import messages from './messages'
import pathBuilder from './pathBuilder'
import user from './user'
import terms from './terms'
import groups from './groups'
import { Url, UrlPath, UrlPathParams, UrlQueryParams } from '@/lib/types'

interface RoutePathConfig {
	baseUrl?: Url
	path: UrlPath
	query?: UrlQueryParams
	params?: UrlPathParams
}

const buildUrl = ({ baseUrl = '/', path, query = {}, params = {} }: RoutePathConfig) => {
	const route = RoutePath.instance({ path, baseUrl })
	return route.buildUrl({ params, query })
}

export {
	req,
	events,
	organizations,
	volunteers,
	uploadPhoto,
	getSecurePhoto,
	research,
	studyAbroad,
	network,
	feeds,
	messages,
	pathBuilder,
	user,
	internships,
	terms,
	groups,
	buildUrl,
}

import React, { ReactNode } from 'react'
import { Link, Theme, makeStyles } from '@material-ui/core'
import useGlobalStyles from '@/styles/globalStyles'
import classNames from 'classnames'

interface StyleProps {
	separator: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	list: ({ separator }) => ({
		'& li:not(:last-child):after': {
			display: 'inline-block',
			content: '"' + separator + '"',
			paddingLeft: '0.25em',
			paddingRight: '0.25em',
			'@media only screen and (max-width: 767px)': {
				display: 'none',
			},
		},
	}),
}))

export interface IFooterNavProps extends Partial<StyleProps> {
	items: Array<{ label: string; href: string; content?: ReactNode }>
	className?: string
}

const FooterNav: React.FC<IFooterNavProps> = ({ items, className, separator = '|' }) => {
	const classes = useStyles({ separator })
	const globalClasses = useGlobalStyles()
	return (
		<nav className={className}>
			<ul className={classNames(globalClasses.inlineList, classes.list, className)}>
				{items.map(({ href, label, content }, index) => (
					<li key={index}>
						<Link underline="always" aria-label={label} href={href} target="_blank" color="inherit" className={classes.link}>
							{content ? content : label}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	)
}

export default FooterNav

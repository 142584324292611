import { Box, styled } from '@mui/material'

const CustomActionsContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-end',
	marginBottom: theme.spacing(1.25),
	paddingRight: theme.spacing(1),
	minHeight: theme.spacing(4.5),
}))

export default CustomActionsContainer

import { STORAGE } from '@/constants'
import { usePrevious } from '@/hooks/usePrevious'
import { getAllGlobalStateConfigs } from '@/lib/globalState'
import { GlobalState } from '@/lib/globalState/GlobalState'
import utils from '@/utils'
import { differenceWith, fromPairs, isEqual, isObject, toPairs } from 'lodash'
import { useEffect } from 'react'
import { Snapshot, useRecoilCallback, useRecoilSnapshot } from 'recoil'

export const RecoilObserver = () => {
	const currentUserId = +(utils.storage.get(STORAGE.CURRENT_USER_ID_PATH) || 0)
	const snapshot = useRecoilSnapshot()
	const prevSnapshot: Snapshot | undefined = usePrevious(snapshot)
	const setState = useRecoilCallback(
		({ set }) =>
			async (globalState: GlobalState<any>, value) => {
				set(globalState.atom, value)
			},
		[],
	)

	useEffect(() => {
		// @ts-ignore
		if (!window.__GLOBAL_RECOIL__) {
			// @ts-ignore
			window.__GLOBAL_RECOIL__ = {}
		}

		// @ts-ignore
		window.__GLOBAL_RECOIL__.setState = setState
		// @ts-ignore
		window.__GLOBAL_RECOIL__.getState = (globalState: GlobalState<any>) => {
			return snapshot.getLoadable(globalState.atom).contents
		}
	}, [setState, snapshot])

	const didChange = prevSnapshot && snapshot !== prevSnapshot

	/**
	 * Alerts subscribers when the store changes
	 */
	useEffect(() => {
		if (!didChange) {
			return
		}

		getAllGlobalStateConfigs().forEach((globalState) => {
			globalState.onStoreChange({
				currentUserId,
				snapshot,
				prevSnapshot,
				setState,
				checkDidChange: (
					globalState: GlobalState<any>,
				): { didChange: boolean; prevValue: any; newValue: any; diff?: Record<string, any> } => {
					const newValue = snapshot.getLoadable(globalState.atom).contents
					const prevValue = prevSnapshot.getLoadable(globalState.atom).contents
					const didChange = !isEqual(newValue, prevValue)
					const diff = (didChange && getValueChanges({ currentValue: newValue, prevValue })) || undefined

					return { didChange, newValue, prevValue, diff }
				},
			})
		})
		// @TODO: #esLint needs to be addressed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prevSnapshot, snapshot, didChange, setState])

	return null
}

const getValueChanges = ({ prevValue, currentValue }) => {
	const oldObject = isObject(prevValue) ? prevValue : { value: prevValue }
	const newObject = isObject(currentValue) ? currentValue : { value: currentValue }

	const changes = differenceWith(toPairs(newObject), toPairs(oldObject), isEqual)

	return fromPairs(changes)
}

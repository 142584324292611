import IconWrapper, { TIconName } from '@/components/IconWrapper'
import { ICON_SIZES } from '@/constants/iconSizes'
import { Theme, makeStyles } from '@material-ui/core'
import React from 'react'
import { ActionMenu, IActionMenuProps } from './ActionMenu'

interface StyleProps {
	color: keyof typeof STAT_COLOR_MAPPER | 'none'
	borderColor?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ color, borderColor }) => {
		const backgroundColor = STAT_COLOR_MAPPER[color] ?? 'none'
		const border = borderColor ? `1px solid ${borderColor}` : 'none'
		return {
			position: 'relative',
			border,
			flexDirection: 'column',
			maxWidth: 160,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 10,
			backgroundColor,
			padding: theme.spacing(2.5),
			aspectRatio: '1 / 1',
			textAlign: 'center',
		}
	},
	title: {
		fontWeight: 700,
		fontSize: '24px',
		marginBottom: theme.spacing(1),
	},
	actions: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
	},
}))

const STAT_COLOR_MAPPER = {
	blue: '#EAF7FD',
	green: '#EBFBF8',
	orange: '#F5EFFE',
	purple: '#FBE9D8',
} as const

export interface IStatProps extends StyleProps {
	count: number
	text: string
	icon?: TIconName
	actions?: IActionMenuProps['actions']
}

const Stat: React.FC<IStatProps> = ({ count, text, icon, actions, ...styleProps }) => {
	const classes = useStyles(styleProps)
	return (
		<div className={classes.root}>
			{actions && (
				<div className={classes.actions}>
					<ActionMenu actions={actions} />
				</div>
			)}
			<div className={classes.title}>{count}</div>
			{icon && <IconWrapper size={ICON_SIZES.lg} iconKey={icon} />}
			<div>{text}</div>
		</div>
	)
}

export { Stat }

import { TextButton } from '@/components/Buttons'
import { formatDateNew } from '@/utils/dateTime2'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'
import { ScheduleIcon } from './Icons'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.spacing(1),
		// @TODO: change/remove this after refactoring a CommonModalCard
		marginTop: -theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}))

interface CardSubtitleProps {
	date: Date | string | null
	labelPrefix?: string
	onEditClick?: () => void
}

export default function CardSubTitle({ date, labelPrefix = '', onEditClick }: CardSubtitleProps) {
	const classes = useStyles()

	const dateLabel = useMemo(() => {
		try {
			if (date) {
				return `${labelPrefix} ${formatDateNew(date, 'full')}`.trim()
			}

			return ''
		} catch (err) {
			console.error(err)
		}
		return 'Invalid date'
	}, [date, labelPrefix])

	if (!date || date < new Date()) {
		return null
	}
	// If there is no date, no need to show
	// If the date is in the future, they cannot edit it

	return (
		<div className={classes.root}>
			<ScheduleIcon />
			<span>{date ? dateLabel : 'Not set'}</span>
			{onEditClick ? <TextButton onClick={onEditClick}>Edit</TextButton> : null}
		</div>
	)
}

import http from '@/api/http/requestHttp'
import { orgTypes } from '@/utils/organizationRoles'

interface PatchOrganizationMemberParams {
	id: number
	changeType: 'Remove' | 'Edit'
	orgId: string
	patchedUserId: number
	membershipTypeId?: orgTypes
	title?: string
	schoolId: number
}

const patchOrganizationMember = async ({
	id,
	changeType,
	orgId,
	patchedUserId,
	membershipTypeId,
	title,
	schoolId,
}: PatchOrganizationMemberParams) => {
	const url = `${schoolId}/organizations/memberships/${id}`
	const body = {
		patchedUserId,
		orgId,
		changeType,
		title,
		membershipTypeId,
	}

	return http.patch(url, body)
}

export default patchOrganizationMember

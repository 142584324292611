import { CircularProgress, Typography } from '@material-ui/core'
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CommonModalCard, IconButton, IconWrapper, InfoModalCard, Modal } from '@/components'
import { ICON_SIZES } from '@/constants/iconSizes'

interface StylesProps {
	submitButtonColor?: string
	cancelButtonColor?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: ({ submitButtonColor }) => ({
		color: submitButtonColor || theme.palette.primary.main,
	}),
	submitText: {
		margin: '10px 0',
	},
	doneText: {
		margin: '10px 0',
		fontWeight: 600,
	},
	content: {
		color: theme.palette.text.primary,
		borderTop: `solid 1px ${theme.palette.divider}`,
		width: 'calc(100% - 100px)',
		padding: '20px 50px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	loadingCard: {
		width: 300,
		height: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardRoot: {
		maxWidth: 500,
	},
	successCardRoot: {
		maxWidth: 350,
		minWidth: 300,
		textAlign: 'center',
	},
	cancelButton: ({ cancelButtonColor }) => ({
		color: cancelButtonColor,
		borderRadius: '0 0 8px 8px',
	}),
}))

interface ISubmissionStatusModalProps {
	isLoading?: boolean
	isSuccess?: boolean
	isError?: boolean
	errorMessage?: string
	submittedMessage?: string
	handleClose?: () => void
}

export const SubmissionStatusModal: React.FC<ISubmissionStatusModalProps> = ({
	isLoading,
	isError,
	isSuccess,
	handleClose,
	submittedMessage,
	errorMessage,
}) => {
	const classes = useStyles({})
	const theme = useTheme()
	return (
		<InfoModalCard
			onClose={handleClose}
			icon={
				isLoading ? (
					<CircularProgress />
				) : isError ? (
					<IconWrapper iconKey="xCircle" color={theme.palette.error.main} weight="fill" size={ICON_SIZES.xl} />
				) : (
					<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />
				)
			}
			className={classes.successCardRoot}
		>
			{!isLoading && <Typography className={classes.doneText}>{isError ? errorMessage || 'Something went wrong' : 'Done'}</Typography>}
			{isSuccess && <Typography>{submittedMessage}</Typography>}
		</InfoModalCard>
	)
}

interface ModalWithSubmittedPopupProps extends StylesProps {
	isOpen: boolean
	isLoading?: boolean
	isSuccess?: boolean
	isError?: boolean
	onSubmit?: () => void
	onClose?: () => void
	title: string
	content: string[]
	submittedMessage?: string | string[]
	submitButtonLabel?: string
	cancelButtonLabel?: string
	showSubmittedModal?: boolean
	onCloseSubmittedModal?: () => void
}
// TODO: this is anti-pattern there should be one common modal for all this cases
// @TODO: needs to be correct
const ModalWithSubmittedPopupContent: React.FC<ModalWithSubmittedPopupProps> = ({
	isOpen,
	isError,
	isLoading,
	isSuccess,
	onSubmit,
	onClose,
	title,
	content,
	submittedMessage,
	submitButtonLabel = 'Yes',
	cancelButtonLabel = 'Cancel',
	showSubmittedModal = true,
	submitButtonColor,
	cancelButtonColor,
	onCloseSubmittedModal,
}) => {
	const theme = useTheme()
	const classes = useStyles({ submitButtonColor, cancelButtonColor })

	const [submitted, setSubmitted] = useState(false)

	const handleCloseSubmittedPopup = useCallback(() => {
		setSubmitted(false)
		if (submitted) {
			if (onCloseSubmittedModal) onCloseSubmittedModal()
			else onClose()
		}
	}, [onCloseSubmittedModal, submitted, onClose])

	const handleClose = useCallback(() => {
		handleCloseSubmittedPopup()
		onClose()
	}, [handleCloseSubmittedPopup, onClose])

	const handleConfirm = useCallback(() => {
		onSubmit()

		if (showSubmittedModal) {
			setSubmitted(true)
		} else {
			handleCloseSubmittedPopup()
		}
	}, [handleCloseSubmittedPopup, onSubmit, showSubmittedModal])

	const renderButtons = useMemo(() => {
		const buttons = []
		if (onSubmit) {
			buttons.push({
				label: submitButtonLabel,
				onClick: handleConfirm,
				className: classes.acceptButton,
			})
		}
		if (onClose) {
			buttons.push({
				label: cancelButtonLabel,
				onClick: handleClose,
				className: classes.cancelButton,
			})
		}
		return buttons
	}, [cancelButtonLabel, classes.acceptButton, classes.cancelButton, handleClose, handleConfirm, submitButtonLabel, onClose, onSubmit])

	useEffect(() => {
		if (!isOpen) {
			setSubmitted(false)
		}
	}, [isOpen])

	if (isError || isSuccess || isLoading) {
		return (
			<InfoModalCard
				onClose={handleClose}
				icon={
					isLoading ? (
						<CircularProgress />
					) : isError ? (
						<IconWrapper iconKey="xCircle" color={theme.palette.error.main} weight="fill" size={ICON_SIZES.xl} />
					) : (
						<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />
					)
				}
				className={classes.successCardRoot}
			>
				{!isLoading && <Typography className={classes.doneText}>{isError ? 'Something went wrong' : 'Done'}</Typography>}
				{isSuccess && <Typography>{submittedMessage}</Typography>}
			</InfoModalCard>
		)
	}

	return (
		<CommonModalCard
			title={title}
			onClose={handleClose}
			classnames={{
				header: classes.cardHeader,
				card: classes.cardRoot,
			}}
		>
			<div className={classes.content}>
				{content.map((text, idx) => (
					<Typography key={`${text.trim()}-${idx}`}>{text}</Typography>
				))}
			</div>
			{renderButtons.map((btn) => (
				<IconButton key={btn.label} className={classNames(classes.itemPopupButton, btn.className)} onClick={btn.onClick}>
					{btn.label}
				</IconButton>
			))}
		</CommonModalCard>
	)
}

const ModalWithSubmittedPopup = (props: ModalWithSubmittedPopupProps) => {
	const { isOpen, onClose } = props
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalWithSubmittedPopupContent {...props} />
		</Modal>
	)
}

export default ModalWithSubmittedPopup

import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
	checkBox: ({ checked }: any) => ({
		borderRadius: 10,
		padding: 0,
		display: 'flex',
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: checked ? theme.palette.primary.main : theme.palette.text.primary,
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
		border: `solid 1px ${checked ? theme.palette.primary.main : theme.palette.border.bold}`,
		minHeight: 60,
		color: checked ? theme.palette.primary.main : theme.palette.text.primary,
		backgroundColor: checked ? theme.palette.primary.light : 'transparent',
	}),
	text: {
		fontSize: 18,
		fontWeight: 600,
	},
}))

interface TextBoxProps {
	className?: string
	label: string
}

export const TextBox = ({ label, className }: TextBoxProps) => <div className={className}>{label}</div>

interface TextCheckBoxProps {
	value: any
	label: string
	checked: boolean
	onChange?: (v: any) => void
}

export const TextCheckBox = ({ value, label, checked, onChange = () => {} }: TextCheckBoxProps) => {
	const classes = useStyles({ checked })

	const handleChange = () => onChange(value)

	return (
		<Checkbox
			color="primary"
			classes={{
				root: classes.checkBox,
			}}
			checked={checked}
			onChange={handleChange}
			inputProps={{ 'aria-label': 'primary checkbox' }}
			checkedIcon={<TextBox className={classes.text} label={label} />}
			icon={<TextBox className={classes.text} label={label} />}
		/>
	)
}

import http from '@/api/http/requestHttp'

export interface GroupNotificationSettings {
	isMutedNotifications: boolean
}

const patchMemberGroupNotifications = async (groupId: string, settings: GroupNotificationSettings, schoolId: number) => {
	const url = `${schoolId}/groups/${groupId}/members/settings`

	return http.patch(url, settings)
}

export default patchMemberGroupNotifications

import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import Footer from '@/components/Footer'
import Header from '@/components/LandingHeader'
import { STYLES } from '@/constants'
import PATHS from '@/constants/paths'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { isNavApp } from '@/constants/configuration'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { Link, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: ({ background, isNavApp }: any) => ({
		background: isNavApp
			? `url(${background})`
			: `linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ), url(${background})`,
		backgroundPosition: 'top center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: '100%',
		height: 'max-content',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	}),
	container: ({ isNavApp, isMobile }: any) => ({
		height: `calc(100vh - ${isMobile && isNavApp ? 0 : STYLES.LANDING_HEADER_HEIGHT}vh)`,
		display: 'flex',
		alignItems: 'center',
	}),
	button: {
		marginRight: '4vw',
		width: '13.5vw',
		height: '5.5vh',
		minWidth: 180,
		fontWeight: 700,
		fontSize: '14pt',
		minHeight: 50,
		[theme.breakpoints.down('lg')]: {
			width: 'auto',
			height: 'auto',
		},
		[theme.breakpoints.down('xs')]: {
			minWidth: 130,
			minHeight: 30,
			fontSize: '0.75rem',
			height: '4vh',
		},
	},
	skipLink: {
		position: 'fixed',
		top: '-40px',
		left: 0,
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		padding: '10px',
		zIndex: 999,
		opacity: 0,
		transition: 'opacity 0.3s',
		'&:focus': {
			top: 0,
			opacity: 1,
			clipPath: 'unset',
		},
	},
}))

const LandingLayout = ({ children }: any) => {
	const config = useSchoolConfig()

	const { landingBackground } = config
	const isMobile = useMediaQuery('@media only screen and (max-width: 767px)')

	const hideFooterAndHeader = isNavApp() && isMobile
	const classes = useStyles({ background: landingBackground, isMobile, isNavApp: isNavApp() })
	const history = useHistory()

	const handleDownload = () => history.push(PATHS.DOWNLOAD_APP)

	return (
		<div className={classes.root}>
			<Link href="#main-content" className={classes.skipLink} aria-label="Skip to main content">
				Skip to main content
			</Link>
			{!hideFooterAndHeader && (
				<Header>
					<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={handleDownload}>
						Download the app!
					</OutlinedButton>
				</Header>
			)}
			<main id="main-content" className={classes.container}>
				{children}
			</main>
			{!hideFooterAndHeader && <Footer landing />}
		</div>
	)
}

export default LandingLayout

import MenuIconButton from '@/components/Buttons/IconButton'
import { ButtonColors } from '@/components/Buttons/types'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { Button, Popper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import { useRef, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	root: ({ open, buttonColor, openedButtonColor }: { open: boolean; buttonColor: ButtonColors; openedButtonColor: ButtonColors }) => {
		const color = theme.palette.buttonColors[buttonColor ?? ButtonColors.PRIMARY]
		const openedColor = theme.palette.buttonColors[openedButtonColor ?? ButtonColors.GREY]

		return {
			borderRadius: 7,
			backgroundColor: open ? openedColor.main : color.main,
			color: open ? openedColor.textColor : color.textColor,
			textTransform: 'none',
			'&>span': {
				display: 'flex',
				justifyContent: 'space-between',
				textAlign: 'center',
				margin: 0,
				opacity: open ? 0.7 : 1,
			},
			'&:hover': {
				backgroundColor: open ? openedColor.hover : color.hover,
			},
			'&:disabled': {
				backgroundColor: open ? openedColor.disabled : color.disabled,
				'&>span': {
					opacity: 0.7,
				},
			},
			'&>span .MuiButton-endIcon': {
				marginRight: 0,
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: '1.1em',
			},
			[theme.breakpoints.up('xl')]: {
				fontSize: '1.125em',
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '0.96em',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '0.7em',
				padding: 0,
				minWidth: 100,
				minHeight: 36,
			},
		}
	},
	expandIcon: {
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.5em !important',
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.8em !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.3em !important',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1em !important',
		},
	},
	buttonLabel: {
		width: '100%',
		textAlign: 'center',
	},
	optionsContainer: {
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginTop: 5,
		zIndex: 1300,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}))

export interface MoreButtonProps {
	className?: string
	disabled?: boolean
	color?: ButtonColors
	openedColor?: ButtonColors
	children: string
	renderButtons: {
		className?: string
		label: string
		onClick: (e: any) => void
		icon?: React.ReactNode
	}[]
	hideIcon?: boolean
	variant?: 'outlined' | 'text'
}

const MoreButton = ({
	className = '',
	disabled = false,
	color = ButtonColors.PRIMARY,
	openedColor = ButtonColors.GREY,
	children,
	renderButtons,
	hideIcon,
	variant = 'text',
}: MoreButtonProps) => {
	const [open, setOpen] = useState(false)
	const classes = useStyles({
		open,
		buttonColor: color,
		openedButtonColor: openedColor,
	})
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleToggle = (e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}

	const handleCloseAfterItemClick = (callback: (e: any) => void) => (e: any) => {
		handleToggle(e)
		callback(e)
	}

	useOutsideClickCallback(popperRef, handleClose)

	const icon = open ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />

	return (
		<>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					{renderButtons.map((btn) => (
						<MenuIconButton
							key={btn.label}
							className={classNames(classes.popoverButton, btn.className)}
							onClick={handleCloseAfterItemClick(btn.onClick)}
							startIcon={btn.icon}
						>
							{btn.label}
						</MenuIconButton>
					))}
				</div>
			</Popper>
			<Button
				variant={variant}
				className={classNames(classes.root, className)}
				ref={anchorRef}
				onClick={handleToggle}
				disabled={disabled}
				endIcon={hideIcon ? undefined : icon}
			>
				<span className={classes.buttonLabel}>{children}</span>
			</Button>
		</>
	)
}

export default MoreButton

import SideBar from '@/components/SideBar/EmbeddedSideBar'
import InfoSideBar from '@/components/SideBar/InfoSideBar'
import { PATHS } from '@/constants'
import { useAppDispatch } from '@/store'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Feeds from '@/features/posts/components/feeds'
import { useCurrentUser } from '@/hooks/userHooks'
import { enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import { getUserRole } from '@/utils/authHandlers'
import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
	},
	middleColumn: {
		width: '44%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 940,
		border: `solid 1.5px ${theme.palette.border.bold}`,
		borderTop: 'none',
		borderBottom: 'none',
		backgroundColor: theme.palette.type === 'light' ? theme.palette.neutral.light : theme.palette.background.paper,
		[theme.breakpoints.down('md')]: {
			width: '65%',
			maxWidth: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			maxWidth: 'none',
		},
	},
	leftColumn: {
		width: '28%',
		[theme.breakpoints.down('md')]: {
			width: '35%',
		},
	},
	rightColumn: {
		width: '28%',
	},
}))

const HomePage = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const currentUser = useCurrentUser()
	const appTheme = useTheme()
	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))
	const downMdScreenSize = useMediaQuery(appTheme.breakpoints.down('md'))

	useEffect(() => {
		if (getUserRole(currentUser.appUserTypeId)) {
			return
		}

		// The user still needs to complete the sign up steps
		dispatch(
			enqueueSnackbar({
				key: uuidv4(),
				notification: {
					message: {
						type: SnackbarType.error,
						message: 'Please complete all the sign up steps.',
					},
				},
			}),
		)

		setTimeout(() => {
			push(PATHS.SIGN_UP.STEPS)
		}, 100)
	}, [currentUser.appUserTypeId, dispatch, push])

	return (
		<div className={classes.root}>
			{mdScreenSize && (
				<div className={classes.leftColumn}>
					<SideBar />
				</div>
			)}
			<div className={classes.middleColumn}>
				<Feeds />
			</div>
			{!downMdScreenSize && (
				<div className={classes.rightColumn}>
					<InfoSideBar />
				</div>
			)}
		</div>
	)
}

export default HomePage

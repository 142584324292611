import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { makeStyles } from '@material-ui/core/styles'
import { UserEvents } from '@navengage/amplitude'
import { useState } from 'react'
import CheckBoxCard from '../../components/CheckBoxCard'
import { useSignupStepsContext } from './SignupStepsContext'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from './hooks'
import { Typography } from '@material-ui/core'

// FIXME - make single select
// FIXME - handle storage here and on backend

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	continueButton: {
		marginTop: 15,
		marginBottom: 15,
	},
	card: {
		margin: `${theme.spacing(1)}px 0px`,
		width: '100%',
		height: 45,
		[theme.breakpoints.up('xl')]: {
			height: 50,
		},
	},
	cardHeader: {
		width: '70%',
	},
}))

const UserTypeStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()

	const { formData } = useSignupStepsContext()
	const userTypeStepConfig = useOnboardingStepConfig('userType')

	const [choices, setCheckBoxes] = useState(() => {
		const choices = formData.patches.userTypeChoices || [
			{
				name: 'student',
				label: 'Student',
				isExclusive: true,
				checked: false,
			},
			{
				name: 'faculty',
				label: 'Faculty',
				isExclusive: true,
				checked: false,
			},
			{
				name: 'staff',
				label: 'Staff',
				isExclusive: true,
				checked: false,
			},
		]

		return choices
	})

	const canClickNext = !checkCanProceed(choices)
	const handleCheckBoxChange = (name: string) => {
		setCheckBoxes(
			choices.map((checkBox) => {
				return { ...checkBox, checked: checkBox.name === name }
			}),
		)
	}

	useSignupStepDataPatcher(
		{
			userTypeChoices: choices,
		},
		[choices],
	)

	const onClickContinue = () => {
		onClickNext()
		const choice = choices.filter((c) => c.checked) || []
		Analytics.track(UserEvents.SELECTED_ROLE, {
			role: choice[0]!.label,
		})
	}

	return (
		<SignUpCard
			classnames={{
				header: classes.cardHeader,
				continueButton: classes.continueButton,
			}}
			title={userTypeStepConfig.title}
			subTitle={userTypeStepConfig.subTitle}
			continueButtonDisabled={canClickNext}
			onClickContinue={onClickContinue}
		>
			<div className={classes.cardContainer}>
				{choices.map((checkBox) => (
					<CheckBoxCard key={checkBox.name} className={classes.card} onClick={handleCheckBoxChange} {...checkBox} />
				))}
			</div>
			{userTypeStepConfig.description && <Typography>{userTypeStepConfig.description}</Typography>}
		</SignUpCard>
	)
}

const checkCanProceed = (choices) => {
	return !choices.length || choices.some((box) => box.checked)
}

UserTypeStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.userTypeChoices)
}
export default UserTypeStep

import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import { IGroupChat } from '@navengage/sen-types'
import SideBarTabHeader from './SideBarTabHeader'
import { useAppDispatch } from '@/store'
import { editGroupSettingsRequest } from '@/store/groups'
import GroupSettings, { GroupSettingsData } from '../ManageCards/GroupSettings'

const useStyles = makeStyles((theme) => ({
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
		height: '100%',
	},
}))

interface EditGroupSettingsProps {
	data?: IGroupChat
	onClose?: () => void
	onTouch?: () => void
	onSubmit?: () => void
}

const EditGroupSettings = ({ data, onClose, onTouch, onSubmit }: EditGroupSettingsProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const handleSubmit = (data: GroupSettingsData) => {
		if (onSubmit) onSubmit()
		dispatch(editGroupSettingsRequest(data))
	}
	if (!data) return null

	return (
		<div className={classes.groupInformation}>
			<SideBarTabHeader title="Edit Settings" onCancel={onClose} />
			<GroupSettings groupType={data.type} onSubmit={handleSubmit} data={data} onTouch={onTouch} submitLabel="Save" />
		</div>
	)
}

export default EditGroupSettings

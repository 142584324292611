import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'

import { FilledTextField } from '@/components'
import SignUpCard from '@/components/SignUpCard'
import { SignUpForm } from '@/store/auth/types'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import { useSignupStepsContext } from '../SignupStepsContext'
import { useSignupStepDataPatcher } from '../hooks'

const useStyles = makeStyles((theme) => ({
	label: {
		marginBottom: '2vh',
		fontWeight: theme.typography.fontWeightBold as number,
	},
	contentContainer: {
		width: '100%',
	},
	blockContainer: {
		width: '100%',
		marginBottom: 30,
	},
	inputRoot: {
		width: '100%',
		height: 50,
		backgroundColor: theme.palette.grey[200],
		'&.MuiOutlinedInput-root': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		borderRadius: 5,
	},
	input: {
		padding: '15.5px 14px',
	},
	previewContainer: {
		width: '100%',
		borderTop: `solid 1px ${theme.palette.divider}`,
		marginBottom: 60,
		marginTop: 30,
		paddingTop: 25,
		textAlign: 'left',
	},
	previewTitle: {
		color: theme.palette.text.primary,
		fontSize: 15,
		fontWeight: 700,
		marginBottom: 10,
	},
	previewText: {
		color: theme.palette.text.primary,
		fontSize: 15,
		fontWeight: 400,
	},
	textField: {
		marginBottom: 15,
	},
}))

const FacultyPositionStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const { formData } = useSignupStepsContext()

	const [values, setValues] = useState({
		position: formData.patches.facultyPosition?.title || '',
		department: formData.patches.facultyPosition?.department || '',
	})
	const disableContinueButton = !checkCanProceed(values)

	const handleChange = (key: string) => (e: any) => {
		setValues({
			...values,
			[key]: e.currentTarget.value,
		})
	}

	useSignupStepDataPatcher(
		{
			facultyPosition: { title: values.position, department: values.department },
		},
		[values],
	)

	const onClickContinue = () => {
		onClickNext()
		Analytics.track(UserEvents.SELECTED_ROLE, {
			position: values.position,
			department: values.department,
		})
	}

	return (
		<SignUpCard onClickContinue={onClickContinue} continueButtonDisabled={disableContinueButton}>
			<div className={classes.contentContainer}>
				<div className={classes.blockContainer}>
					<Typography className={classes.label} variant="h2">
						What is your position?
					</Typography>

					<FilledTextField
						variant="secondary"
						className={classes.textField}
						placeholder="Add your position"
						onChange={handleChange('position')}
						value={values.position}
					/>
				</div>
				<div className={classes.blockContainer}>
					<Typography className={classes.label} variant="h2">
						What is your unit/department?
					</Typography>

					<FilledTextField
						variant="secondary"
						className={classes.textField}
						placeholder="Add your unit/department"
						onChange={handleChange('department')}
						value={values.department}
					/>
				</div>
				<div className={classes.previewContainer}>
					<Typography className={classes.previewTitle} variant="h3">
						Title Preview:
					</Typography>
					<Typography className={classes.previewText}>
						{values.position || 'Position'}, {values.department || 'Unit/Department'}
					</Typography>
				</div>
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (values) => {
	return Object.values(values).every((value?: string) => !!value?.trim())
}

FacultyPositionStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.facultyPosition)
}

export default FacultyPositionStep

import { Button, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'

import SelectOption from '@/components/Filter/FilterSelectOption'
import { SelectOptionType } from '@/types/components'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	card: ({ open }: any) => ({
		width: '100%',
		height: 50,
		backgroundColor: 'transparent',
		borderRadius: 5,
		border: 'none',
		color: open ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		fontSize: '16px',
	}),
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: '5%',
		alignItems: 'center',
		display: 'flex',
	},
	left: {
		width: '100%',
		alignItems: 'center',
		display: 'flex',
	},
	optionsContainer: {
		maxHeight: '20vh',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
	tagsContainer: {
		margin: '1vh 0',
	},
	tag: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 32,
		fontSize: '0.9em',
		margin: '0.5vh 0.5vw',
		maxWidth: 230,
	},
	tagIcon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
}))

interface SelectProps {
	className?: string
	value?: any | any[]
	icon?: React.ReactNode
	defaultLabel: string
	onChange: (v: any | any[]) => void
	options: SelectOptionType[]
	preselectedValues?: any[]
	multiple?: boolean
}

const FilterSelect = ({
	className = '',
	icon = null,
	defaultLabel = '',
	onChange = (v) => {},
	options = [],
	value,
	multiple,
	preselectedValues = [],
}: SelectProps) => {
	const [open, setOpen] = useState(false)

	const label = useMemo(
		() =>
			multiple || (!Array.isArray(value) && value === undefined)
				? defaultLabel
				: options.find((opt) => opt.value === value)?.label || defaultLabel,
		[defaultLabel, multiple, options, value],
	)
	const hasValue = useMemo(() => (!Array.isArray(value) && !!value) || (value && !!value.length), [value])

	const classes = useStyles({ open, selected: hasValue })

	const handleToggleSelect = useCallback(() => setOpen((prev) => !prev), [])

	const handleSelectOption = useCallback(
		(idx: number) => {
			const newValue = options
				.filter((option) => {
					if (idx === option.value) {
						if (value?.length) return !value.includes(idx)
						return value !== idx
					}
					return multiple ? value?.includes(option.value) : false
				})
				.map((opt) => opt.value)

			if (multiple) {
				onChange(newValue)
			} else {
				onChange(newValue.length ? newValue[0] : null)
			}
		},
		[multiple, onChange, options, value],
	)

	const availableOptions = useMemo(() => options.filter((opt) => !preselectedValues.includes(opt.value)), [options, preselectedValues])

	return (
		<div className={classes.root}>
			<Button className={classNames(classes.card, className)} onClick={handleToggleSelect}>
				<div className={classes.contentContainer}>
					<Typography className={classes.left}>
						<span className={classes.icon}>{icon}</span>
						{label}
					</Typography>
					{open ? <KeyboardArrowUpIcon fontSize="medium" /> : <KeyboardArrowDownIcon fontSize="medium" />}
				</div>
			</Button>
			<div className={classes.optionsContainer}>
				{open &&
					availableOptions.map((opt) => (
						<SelectOption
							key={opt.label}
							label={opt.label}
							checked={Array.isArray(value) ? value.includes(opt.value) : value === opt.value}
							value={opt.value}
							onClick={handleSelectOption}
							multiple={multiple}
						/>
					))}
			</div>
			{!open && multiple && hasValue && (
				<div className={classes.tagsContainer}>
					{(Array.isArray(value) ? value : [value]).map((val) => {
						const option = options.find((opt) => opt.value === val)!
						const preselected = preselectedValues.includes(val)
						if (!option) {
							return null
						}
						const handleDelete = (e: any) => {
							e.stopPropagation()
							e.preventDefault()
							handleSelectOption(option.value as number)
						}

						return (
							<Chip
								className={classes.tag}
								key={option.label}
								label={option.label}
								onDelete={!preselected ? handleDelete : undefined}
								deleteIcon={!preselected ? <CancelIcon className={classes.tagIcon} /> : undefined}
								variant="outlined"
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default FilterSelect

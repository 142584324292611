import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import * as actions from '@/store/studyAbroad/actions'
import { StudyAbroadState } from '@/store/studyAbroad/types'

const initialState: StudyAbroadState = {
	search: {
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	studyAbroad: [],
	isLoading: false,
	isLoadingIndividualStudyAbroad: true,
	isLoadingUserStudyAbroad: true,
	countries: [],
	housingType: [],
	languageOfInstruction: [],
	scholarshipsGe: [],
	termsOfAvailability: [],
	userStudyAbroad: [],
}

const studyAbroadReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setStudyAbroad, (state, action) => ({
			...state,
			studyAbroad: action.payload,
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			studyAbroad: [],
		}))

		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					...rest,
				},
			}
		})
		.addCase(actions.setUserStudyAbroad, (state, action) => ({
			...state,
			userStudyAbroad: action.payload,
		}))
		.addCase(actions.setSelectedStudyAbroad, (state, action) => ({
			...state,
			selectedStudyAbroad: action.payload,
		}))

		.addCase(actions.loadStudyAbroadByIdRequest.pending, (state) => ({
			...state,
			isLoadingIndividualStudyAbroad: true,
		}))
		.addCase(actions.loadStudyAbroadByIdRequest.fulfilled, (state) => ({
			...state,
			isLoadingIndividualStudyAbroad: false,
		}))
		.addCase(actions.loadStudyAbroadByIdRequest.rejected, (state) => ({
			...state,
			isLoadingIndividualStudyAbroad: false,
		}))
		.addCase(actions.setUserResponse, (state, { payload: { id, response } }) => {
			if (state.selectedStudyAbroad && state.selectedStudyAbroad.id === id) {
				state.selectedStudyAbroad.responseStatus = response
			}
			const item = state.studyAbroad.find((r) => r.id === id)
			if (item) {
				item.responseStatus = response
			}
		})
})

export { studyAbroadReducer }

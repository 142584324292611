import { Typography } from '@material-ui/core'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import Card from '@/components/CommonCard'
import { makeStyles } from '@material-ui/core/styles'
import { PSU_SURVEY_LINK } from '@/constants/configuration'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	description: {
		marginRight: '2vw',
		width: '100%',
		flexDirection: 'column',
	},
	button: {
		minWidth: 'fit-content',
		padding: '10px 2vw',
	},
	headerText: {
		fontWeight: 700,
	},
	subHeader: {
		margin: '1vh 0',
	},
}))

export const SurveyCard = () => {
	const classes = useStyles()
	return (
		<Card className={classes.root}>
			<div className={classes.description}>
				<Typography className={classes.headerText} variant="h1">
					Maximize your Penn State experience!
				</Typography>
				<Typography className={classes.subHeader} variant="body1">
					Complete this quick survey to get started!
				</Typography>
				<ColorButton className={classes.button} color={ButtonColors.PRIMARY} onClick={() => window.open(PSU_SURVEY_LINK, '_blank')}>
					Start Survey
				</ColorButton>
			</div>
		</Card>
	)
}

import { useCurrentUser } from '@/hooks/userHooks'
import { campusSelectors } from '@/features/campus/slice'
import { FeedPost } from '@/store/feeds/types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectPost, useGetPostQuery } from '../api'

const usePostByIdQuery = (id: FeedPost['id'], skip?: boolean) => {
	const currentUser = useCurrentUser()
	const campuses = useSelector(campusSelectors.selectEntities)
	const selectNormalizedPost = useMemo(selectPost, [])

	const postQuery = useGetPostQuery(
		{ postId: id, userId: currentUser.id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedPost(result.data, campuses) : undefined,
				}
			},
		},
	)

	return postQuery
}

export default usePostByIdQuery

import { UserInfo } from '@/interfaces/user'
import settings from '../../../constants/http'
import { orgTypes } from '../../../utils/organizationRoles'
import http from '../requestHttp'

interface OrganizationSearchUsers {
	userId: UserInfo['id']
	q?: string
	page: number
	campusId?: number | null
	organizationId: string
	organizationMembershipTypeId?: orgTypes | null
	maxMembershipTypeId: orgTypes
	schoolId: number
}

const searchUsers = async ({
	userId,
	q,
	page,
	campusId,
	organizationId,
	organizationMembershipTypeId,
	maxMembershipTypeId,
	schoolId,
}: OrganizationSearchUsers) => {
	const url = `${schoolId}/organizations/${userId}/search/users`

	const params = {
		q,
		page,
		campusId,
		organizationId,
		organizationMembershipTypeId,
		perPage: settings.USERS_PER_PAGE,
		maxMembershipTypeId,
	}

	return http.get(url, { params })
}

export default searchUsers

import ErrorFeedCard from '@/components/ExperienceCards/ErrorFeedCard'
import EntityTypes from '../types/EntityTypes'
import FeedsCardContainerSkeleton from '@/components/ExperienceCards/FeedsCard/FeedsCardContainerSkeleton'

export const withErrorHandler = ({
	type,
	currentType,
	cachedId,
	isError,
	isLoading,
	isUninitialized,
	card,
}: {
	type: EntityTypes
	currentType: EntityTypes
	isLoading: boolean
	isError: boolean
	isUninitialized: boolean
	cachedId: string | number
	card: JSX.Element
}) => {
	if (type !== currentType) {
		return null
	}

	if (isError) {
		return <ErrorFeedCard />
	}

	if ((isLoading || isUninitialized) && !cachedId) {
		return <FeedsCardContainerSkeleton variant="secondary" />
	}
	return card
}

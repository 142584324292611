import { createReducer } from '@reduxjs/toolkit'
import * as actions from '@/store/volunteer/actions'
import { VolunteerState } from '@/store/volunteer/types'

const initialState: VolunteerState = {
	events: {
		future: [],
		past: [],
	},
	isLoading: false,
}

const volunteerReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setEvents, (state, action) => ({
			...state,
			events: action.payload.events,
		}))
})

export { volunteerReducer }

import searchEvents from './searchEvents'
import getListCategories from './getListCategories'
import getAllUserSubmittedEvents from './getAllUserSubmittedEvents'
import getEventsByUserId from './getEventsByUserId'
import getEventById from './getEventById'
import getEventUserResponse from './getEventUserResponse'
import postEventUserResponse from './postEventUserResponse'
import getEventAttendees from './getEventAttendees'
import postVolunteerResponse from './postVolunteerResponse'
import deleteVolunteerSubscription from './deleteVolunteerSubscription'
import getEventVolunteerShifts from './getEventVolunteerShifts'
import getEventVolunteers from './getEventVolunteers'
import postNewEvent from './postNewEvent'
import patchEvent from './patchEvent'
import deleteEvent from './deleteEvent'

const events = {
	searchEvents,
	getListCategories,
	getAllUserSubmittedEvents,
	getEventsByUserId,
	getEventById,
	getEventUserResponse,
	postEventUserResponse,
	getEventAttendees,
	postVolunteerResponse,
	deleteVolunteerSubscription,
	getEventVolunteerShifts,
	getEventVolunteers,
	postNewEvent,
	patchEvent,
	deleteEvent,
}

export default events

export enum NOTIFICATION_TYPES {
	CHANGE_GROUP_CHAT_NAME = 'changeGroupChatName',
	DOWNGRADE_GROUP_CHAT_ROLE = 'downgradeGroupChatRole',
	EVENT_CANCELED = 'eventCanceled',
	EVENT_TIME_CHANGED = 'eventTimeChanged',
	INVITE_TO_GROUP_CHAT = 'inviteToGroupChat',
	MENTION = 'mention',
	NEW_MESSAGE = 'newMessage',
	NEW_ORGANIZATION_MEMBERSHIP = 'newOrganizationMembership',
	NEW_ORGANIZATION_MEMBERSHIP_RESPONSE = 'newOrganizationMembershipResponse',
	CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_REQUEST = 'changeOrganizationMembershipRoleRequest',
	CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_RESPONSE = 'changeOrganizationMembershipRoleResponse',
	POST_RESPONSE = 'postResponse',
	REMOVE_GROUP_CHAT = 'removeGroupChat',
	REQUEST_TO_JOIN_GROUP_CHAT = 'requestToJoinGroupChat',
	UPCOMING_EVENT = 'upcomingEvent',
	UPGRADE_GROUP_CHAT_ROLE = 'upgradeGroupChatRole',
	NEW_CONNECTION = 'newConnection',
	NEW_CONNECTION_ACCEPT = 'newConnectionAccept',
	REQUEST_TO_JOIN_GROUP_CHAT_ACCEPTED = 'requestToJoinGroupChatAccepted',
	REQUEST_TO_JOIN_GROUP_CHAT_DECLINED = 'requestToJoinGroupChatDeclined',
}

export const DISPLAYED_NOTIFICATIONS_COUNT = 3

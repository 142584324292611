import { PATHS } from '@/constants'
import { enqueueSnackbar, setError } from '@/store/app'
import { MODULE_NAME as AUTH_MODULE_NAME } from '@/store/auth'
import { isDevEnv } from '@/utils/authHandlers'
import { prepareError } from '@/utils/common'
import { Action, Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { v4 as uuidv4 } from 'uuid'
import { SnackbarType } from './app/types'
import { getLogger } from '../init/DIContainer'

const authActionTypes = [`${AUTH_MODULE_NAME}/SIGN_IN`, `${AUTH_MODULE_NAME}/USER_INFO_REQUEST`, `${AUTH_MODULE_NAME}/AUTO_SIGN_IN`]

const errorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: Action) => {
	if (isRejectedWithValue(action)) {
		const err = prepareError(action.payload)
		const sentry = getLogger()
		sentry.captureException(err)

		api.dispatch(setError(err))

		if (err.status === 404) {
			api.dispatch(push(PATHS.NOT_FOUND_ERROR))
		} else if (err.status === 403 && authActionTypes.some((type) => action.type.includes(type))) {
			api.dispatch(push(PATHS.AUTH_ERROR))
		} else {
			api.dispatch(
				enqueueSnackbar({
					key: uuidv4(),
					notification: {
						message: {
							type: SnackbarType.error,
							message: err.message,
						},
					},
				}),
			)
		}

		if (isDevEnv()) {
			console.error({ action, err })
		}
	}

	return next(action)
}

export default errorMiddleware

import http from '@/api/http/requestHttp'

const getSecurePhoto = async (photoUrl: string, schoolId: number) => {
	const url = `${schoolId}/s3/uploads/secure/getPhoto`

	const params = {
		slug: photoUrl,
	}

	return http.get(url, { params })
}

export default getSecurePhoto

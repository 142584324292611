import { MODULE_NAME, groupsAdapter, preferencesAdapter } from '@/features/preferences'
import { RootState } from '../../store'

const getState = (state: RootState) => state[MODULE_NAME]

const selectGroupState = (state: RootState) => getState(state).groups
export const groupSelectors = groupsAdapter.getSelectors(selectGroupState)

const selectPreferenceState = (state: RootState) => getState(state).preferences
export const preferenceSelectors = preferencesAdapter.getSelectors(selectPreferenceState)

export const arePreferencesLoading = (state: RootState) => getState(state).isLoading

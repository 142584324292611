import { makeStyles } from '@material-ui/core/styles'

// Common / Repeating styles which can be reused in several places
const useGlobalStyles = makeStyles((theme) => ({
	inlineList: {
		listStyle: 'none',
		padding: 0,
		margin: 0,
		display: 'flex',
		alignItems: 'center',
	},
	alignCenter: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	'@global': {
		':root': {
			'--color-primary': theme.palette.primary.main,
			'--color-secondary': theme.palette.secondary.main,
			'--color-primary-text': theme.palette.text.primary,
			'--color-hint-text': theme.palette.text.hint,
			'--color-grey-text': theme.palette.grey['500'],
		},
	},
}))

export default useGlobalStyles

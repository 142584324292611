const ROOT = '/sign-up'

const SIGN_UP = {
	ROOT,
	WELCOME: `${ROOT}/welcome`,
	STEPS: `${ROOT}/steps`,
	THANKS: `${ROOT}/thanks`,
	TERMS: `${ROOT}/terms`,
}

export default SIGN_UP

import { Link, Typography, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import { generatePinUrl } from '@/anthology/utils'
import { LocationOutlinedIcon } from '@/components'
import ItemAboutRow from '@/components/IndividualPage/ItemAboutRow'
import Popover from '@/components/Popover'
import { default as IAddress } from '../types/Address'

const useStyles = makeStyles((theme) => ({
	locationInfoTitle: {
		fontWeight: 700,
	},
	locationInfoDivider: {
		width: '70%',
		borderBottom: `solid 1px ${theme.palette.divider}`,
		margin: '2vh 0',
	},
	locationInfo: {
		maxWidth: 390,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2vh 3vw',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: '0 7px 7px 7px',
		backgroundColor: theme.palette.background.paper,
	},
}))

export interface IAddressProps extends IAddress {}

const Address: React.FC<IAddressProps> = ({ name, address, latitude, longitude, onlineLocation, instructions }) => {
	const classes = useStyles()
	const [locationAnchorEl, setLocationAnchorEl] = useState<any>(null)
	const handleOpenLocation = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setLocationAnchorEl(event.currentTarget)
	}
	const handleCloseLocation = () => {
		setLocationAnchorEl(null)
	}
	const addressInfo =
		latitude && longitude ? (
			<Link target="_blank" aria-label={address} href={generatePinUrl({ lat: latitude, lng: longitude })}>
				{address}
			</Link>
		) : (
			address
		)
	return (
		<>
			<Popover
				anchorEl={locationAnchorEl}
				handleClose={handleCloseLocation}
				classnames={{
					container: classes.locationInfo,
				}}
				boxShadow={false}
			>
				<Typography className={classes.locationInfoTitle}>{onlineLocation || name}</Typography>
				<div className={classes.locationInfoDivider} />
				<Typography>{onlineLocation ? instructions : addressInfo}</Typography>
			</Popover>
			<ItemAboutRow icon={<LocationOutlinedIcon />} label={name} onClick={handleOpenLocation} />
		</>
	)
}

export { Address }

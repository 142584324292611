import { SvgIcon } from '@material-ui/core'

const MemberIcon = (props: any) => {
	const color = props.color || '#1E407C'
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 24 24" {...props}>
			<g data-name="group 6361">
				<g data-name="group 6358" transform="translate(-642 -295)">
					<circle data-name="elips 541" cx={8} cy={8} r={8} transform="translate(642 295)" fill={color} />
					<g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={1.5} data-name="group 6354">
						<path data-name="line 269" d="m650 305-3-1" />
						<path data-name="line 270" d="m650 305 2-5" />
					</g>
				</g>
			</g>
		</SvgIcon>
	)
}

export default MemberIcon

import { ButtonProps } from '@/interfaces/common'
import MaterialButton from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { ButtonColors } from '@/components/Buttons/types'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	root: ({ buttonColor }: { buttonColor: ButtonColors }) => {
		const color = theme.palette.buttonColors[buttonColor ?? ButtonColors.PRIMARY]

		return {
			borderRadius: 7,
			backgroundColor: theme.palette.specialColors.transparent,
			color: color.main,
			borderColor: color.main,
			textTransform: 'none',
			...theme.typography.body1,
			[theme.breakpoints.down('xs')]: {
				...theme.typography.subtitle1,
				padding: 0,
				minWidth: 100,
				minHeight: 36,
			},
		}
	},
}))

const OutlinedButton = forwardRef<any, ButtonProps>(
	({ color = ButtonColors.PRIMARY, children = '', onClick = () => {}, className, disabled }, ref) => {
		const classes = useStyles({ buttonColor: color })

		return (
			<MaterialButton ref={ref} onClick={onClick} disabled={disabled} className={classNames(className, classes.root)} variant="outlined">
				{children}
			</MaterialButton>
		)
	},
)

export default OutlinedButton

import { makeStyles } from '@material-ui/core/styles'

import { STYLES } from '@/constants'
import { useGetLinkPreviewDataQuery } from '@/features/common/api'
import { Link, Typography } from '@material-ui/core'
import MaterialIconButton from '@material-ui/core/IconButton'
import { CloseOutlined, ImageOutlined } from '@material-ui/icons'
import { useMemo } from 'react'

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		width: '100%',
		paddingTop: 'calc(62%)',
		position: 'relative',
		backgroundColor: theme.palette.neutral.light,
	},
	root: {
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		height: '100%',
		width: '100%',
	},
	content: {
		position: 'relative',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		justifyContent: 'end',
		backgroundColor: theme.palette.neutral.light,
	},
	image: ({ url }: any) => ({
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
		height: 'calc(100% - 30px)',
		width: 'calc(100% - 30px)',
		padding: 15,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		background: `url(${url}) center center / cover no-repeat`,
	}),
	triangleRight: {
		position: 'absolute',
		width: 0,
		height: 0,
		borderTop: '2vw solid transparent',
		borderLeft: `4vw solid ${theme.palette.divider}`,
		borderBottom: '2vw solid transparent',
	},
	link: {
		fontSize: 15,
		color: theme.palette.text.secondary,
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	title: {
		fontSize: 18,
		fontWeight: 700,
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	descriptionContainer: {
		height: '19%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: '0 20px',
		color: theme.palette.text.primary,
		textDecoration: 'none',
		backgroundColor: theme.palette.neutral.light,
	},
	clearImageButton: {
		zIndex: 1,
		position: 'absolute',
		width: 30,
		height: 30,
		color: theme.palette.common.white,
		margin: 10,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
	linkContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	imageContainer: {
		width: '100%',
		paddingTop: 'calc(50%)',
		position: 'relative',
	},
}))

interface LinkPreviewProps {
	url: string
	userId: number
	onClose?: () => void
}

const LinkPreview = ({ url, userId, onClose }: LinkPreviewProps) => {
	const { data } = useGetLinkPreviewDataQuery({ previewUrl: url, userId })

	const previewData = useMemo(() => {
		return {
			description: data?.description ?? '',
			image: data?.images && data.images.length > 0 ? data.images[0] : '',
			mediaType: data?.mediaType ?? '',
			title: data?.title ?? '',
			url: data?.url ?? '',
		}
	}, [data])

	const classes = useStyles({ url: previewData.image })

	const isVideoLink = previewData.mediaType.includes('video')

	const handleClick = (e: any) => {
		e.stopPropagation()
	}

	return (
		<div className={classes.rootContainer} onClick={handleClick}>
			<div className={classes.root}>
				<div className={classes.content}>
					{onClose && (
						<MaterialIconButton className={classes.clearImageButton} onClick={onClose}>
							<CloseOutlined fontSize="small" />
						</MaterialIconButton>
					)}
					<Link className={classes.linkContainer} href={previewData.url} target="_blank" rel="noopener noreferrer">
						<div className={classes.imageContainer}>
							<div className={classes.image}>
								{!previewData.image && <ImageOutlined fontSize="large" />}
								{previewData.image && isVideoLink && <div className={classes.triangleRight} />}
							</div>
						</div>
						<div className={classes.descriptionContainer}>
							<Typography className={classes.title}>{previewData.title}</Typography>
							<Typography className={classes.link}>{previewData.url}</Typography>
						</div>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default LinkPreview

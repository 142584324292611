import { Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import { useMemo, useState } from 'react'

import { ColorButton, Toggle } from '@/components'
import { STYLES } from '@/constants'
import { GroupChatTypes } from '@navengage/sen-types'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: 'calc(100% - 60px)',
		margin: 30,
	},
	optionsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	option: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		marginTop: 15,
	},
	toggle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		marginBottom: 7,
	},
	label: {
		fontWeight: 600,
	},
	submitButton: {
		minWidth: 200,
		margin: '35px 0 15px 0',
	},
}))

export interface GroupSettingsData {
	restrictedEdit: boolean
	restrictedMemberManagement?: boolean
	restrictedAdminManagement?: boolean
}

interface SettingsOption {
	label: string
	description: string
	storeKey: 'restrictedEdit' | 'restrictedMemberManagement' | 'restrictedAdminManagement'
}

const getSettingsOptions = (groupType: `${GroupChatTypes}`): SettingsOption[] => {
	let settings = [
		{
			label: 'Restricted editing',
			description: `Only admins can change the name, photo, 
    and description.`,
			storeKey: 'restrictedEdit',
		},
	]

	if (groupType === GroupChatTypes.Restricted) {
		settings.push({
			label: 'Restricted member management',
			description: `Only admins can add/remove members and 
    approve new users who request to join.`,
			storeKey: 'restrictedMemberManagement',
		})
	}
	if (groupType === GroupChatTypes.Private) {
		settings.push({
			label: 'Restricted member management',
			description: `Only admins can add and remove members.`,
			storeKey: 'restrictedMemberManagement',
		})
	}

	settings.push({
		label: 'Restricted admin management',
		description: `Only the owner (you) can add/remove admins.  
  If turned off, admins can add/remove admins.`,
		storeKey: 'restrictedAdminManagement',
	})

	return settings as SettingsOption[]
}

export interface GroupSettingsData {
	restrictedEdit: boolean
	restrictedMemberManagement?: boolean
	restrictedAdminManagement?: boolean
}

interface GroupSettingsProps {
	onSubmit: (data: GroupSettingsData) => void
	data?: GroupSettingsData
	groupType: `${GroupChatTypes}`
	submitLabel?: string
	onTouch?: () => void
}

const GroupSettings = ({ groupType, onSubmit, onTouch, data, submitLabel = 'Done' }: GroupSettingsProps) => {
	const classes = useStyles()

	const [state, setState] = useState<GroupSettingsData>(
		data ??
			({
				restrictedEdit: true,
				restrictedMemberManagement: true,
				restrictedAdminManagement: true,
			} as GroupSettingsData),
	)

	const settings = useMemo(() => getSettingsOptions(groupType), [groupType])

	const handleChangeToggle = (key: string) => (e: any) => {
		if (onTouch) onTouch()
		setState((prev: any) => ({
			...prev,
			[key]: e.target.checked,
		}))
	}

	const handleSubmit = () => {
		onSubmit(state)
	}

	return (
		<div className={classes.root}>
			<div className={classes.optionsContainer}>
				{settings.map((item) => (
					<div key={item.label} className={classes.option}>
						<div className={classes.toggle}>
							<Typography className={classes.label}>{item.label}</Typography>
							<Toggle value={!!state[item.storeKey]} onChange={handleChangeToggle(item.storeKey)} labelPosition="start" label={''} />
						</div>
						<Typography>{item.description}</Typography>
					</div>
				))}
			</div>
			<ColorButton className={classes.submitButton} onClick={handleSubmit}>
				{submitLabel}
			</ColorButton>
		</div>
	)
}

export default GroupSettings

import { SvgIcon } from '@material-ui/core'

const GraduationHatOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
			<path d="M62.373 38.765A3.44 3.44 0 0060 36.164V25a1 1 0 00-.591-.912l-16-7.173a1 1 0 10-.818 1.824L54.325 24H33.763A4.024 4.024 0 0030 22c-2.243 0-4 1.317-4 3s1.757 3 4 3a4.024 4.024 0 003.763-2h20.562L30 36.9 3.445 25 30 13.1l8.591 3.85a1 1 0 10.818-1.824l-9-4.034a1 1 0 00-.818 0l-29 13a1 1 0 000 1.824L10 30.13v16.516a3.018 3.018 0 001.891 2.8A49.169 49.169 0 0030 52.986a49.227 49.227 0 0018.114-3.546A3.013 3.013 0 0050 46.65V30.13l8-3.587v9.621a3.44 3.44 0 00-2.373 2.6L54.02 46.8A1 1 0 0055 48h8a1 1 0 00.98-1.2zM30 26c-1.221 0-2-.592-2-1s.779-1 2-1 2 .592 2 1-.779 1-2 1zm18 20.65a1 1 0 01-.618.929 46.1 46.1 0 01-34.759 0 1 1 0 01-.623-.933V31.027l17.591 7.885a.994.994 0 00.818 0L48 31.026zm8.22-.65l1.368-6.843a1.44 1.44 0 012.824 0L61.78 46z" />
		</SvgIcon>
	)
}

export default GraduationHatOutlinedIcon

import Router from '@/components/Router'
import { CommonCard } from '../components/CommonCard'
import { Layout } from '../components/Layout'
import routes from '../routes/usersRoutes'

const UsersMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Users"
				subHeader="Welcome to the user dashboard! Find information about user activity. Click the “DETAILS” tab to view individual user information. "
			>
				<Router routes={routes} />
			</CommonCard>
		</Layout>
	)
}

export default UsersMainPage

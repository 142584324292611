import { rootStyles } from '@/styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: ({ isSelected }: { isSelected: boolean }) => {
		const styles = {
			...rootStyles.buttonPlain,
			width: 10,
			height: 10,
			backgroundColor: theme.palette.text.secondary,
			opacity: 1,
			borderRadius: '50%',
		}

		if (!isSelected) {
			styles.opacity = 0.3
		}
		return styles
	},
}))

export const SlideNavigationDot = ({ isSelected, onClick }: { isSelected: boolean; onClick: () => void }) => {
	const classes = useStyles({ isSelected })

	return (
		<button className={classes.root} onClick={onClick}>
			&nbsp;
		</button>
	)
}

import { ROLE } from '@/constants'
import localStorageKeys from '@/constants/localStorage'
import { UserInfo } from '@/interfaces/user'
import LocalStorage from '@/utils/storage'
import { isNumber } from 'lodash'
import generateAuthorizationUrl from './generateAuthorizationUrl'

export const USER_TYPES: { [type: string]: number } = {
	[ROLE.STUDENT]: 1,
	[ROLE.FACULTY]: 2,
	[ROLE.STAFF]: 3,
	[ROLE.UNKNOWN]: 0,
}

export const getUserType = (type: string): number => {
	const userType = type.toLowerCase()
	if (USER_TYPES[userType]) return USER_TYPES[userType]
	return 0
}

export const getUserRole = (appUserTypeId: number) => {
	const roleById = Object.keys(USER_TYPES).reduce((acc, key) => {
		return {
			...acc,
			[USER_TYPES[key]]: key,
		}
	}, {}) as { [key: string]: ROLE }

	if (!appUserTypeId) {
		return
	}

	return roleById[`${appUserTypeId}`] || ROLE.GUEST
}

export const isStudent = (role: ROLE) => role === ROLE.STUDENT

export const isUnknown = (role: ROLE) => role === ROLE.UNKNOWN

export const getAllRoles = () => Object.values(ROLE)
export const getAllAuthorizedRoles = () => Object.values(ROLE).filter((role) => role !== ROLE.GUEST)

export const getAllAuthorizedNonStudentRoles = () => getAllAuthorizedRoles().filter((role) => role !== ROLE.STUDENT)

export const handleSignIn = () => {
	window.open(generateAuthorizationUrl(), '_self')
}

export const getTokenExpirationDate = (expiresIn: number) => {
	let expiration = new Date() // note - this is all in UTC
	expiration.setSeconds(expiration.getSeconds() + expiresIn)

	return expiration
}

const devEnvs = ['local', 'dev'] // @TODO: #Dan maybe disable tracking staging too ?
export const isDevEnv = () => devEnvs.includes(process.env.REACT_APP_ENV)

export const getMockAccessToken = (userId: number) => `mock-access-token-${userId}`

export function checkTestingData() {
	if (!isDevEnv()) return false

	const token = LocalStorage.get(localStorageKeys.ACCESS_TOKEN_PATH)

	return JSON.stringify(token).includes('mock')
}

export const userHasSignUpData = (role: ROLE, userInfo?: UserInfo) =>
	role === ROLE.STUDENT
		? Object.keys(userInfo?.signupResponses || {}).length && userInfo?.intendedGraduationDate
		: userInfo?.positionTitle && userInfo?.department

export const isCurrentUser = (currentUserId?: number, id?: number) => isNumber(currentUserId) && isNumber(id) && currentUserId === id

export const getToken = () => {
	const token = LocalStorage.get(localStorageKeys.ACCESS_TOKEN_PATH) || ''

	return token
}

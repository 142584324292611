import http from '@/api/http/requestHttp'
import { DtoGroupChatItem } from '@navengage/sen-types'

const getGroupById = async (id: string, schoolId: number) => {
	const url = `${schoolId}/groups/${id}/info`

	return http.get<DtoGroupChatItem>(url)
}

export default getGroupById

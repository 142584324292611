import { preferenceSelectors, toggleNotificationPreferenceRequest } from '@/features/preferences'
import { RootState, useAppDispatch } from '@/store'
import { Typography, makeStyles } from '@material-ui/core'
import { EntityId } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { Toggle } from './Toggle'

type TPreferenceProps = {
	preferenceId: EntityId
}

const useStyles = makeStyles((theme) => ({
	option: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: '15px 0',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
}))

export const Preference = ({ preferenceId }: TPreferenceProps) => {
	const dispatch = useAppDispatch()
	const classes = useStyles()
	const preference = useSelector((state: RootState) => preferenceSelectors.selectById(state, preferenceId))

	const handleChangeToggle = (preferenceId) => (e: any) => {
		dispatch(toggleNotificationPreferenceRequest(preferenceId))
	}

	return (
		<>
			{!preference ? (
				<></>
			) : (
				<div className={classes.option}>
					<Typography variant="h3">{preference.name}</Typography>
					<Toggle
						value={preference.value}
						onChange={handleChangeToggle(preferenceId)}
						labelPosition="end"
						label={{
							checked: 'Turned On',
							unchecked: 'Turned Off',
						}}
					/>
				</div>
			)}
		</>
	)
}

import { ContentPreloader } from '@/components/ContentPreloader'
import { CircularProgress, Typography, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { ReactElement, ReactNode, useEffect, useLayoutEffect, useRef } from 'react'
import { VirtualItem, useVirtual } from 'react-virtual'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'

const useStyles = makeStyles((theme) => ({
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	infoText: {
		fontSize: '0.9em',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
}))

export type VirtualizedInfiniteScrollComponentProps = {
	emptyListPlaceholder?: ReactNode
	isLoading?: boolean
	isFetching?: boolean
	header?: ReactNode
	placeholder?: ReactNode
	onLoadMore: () => void
	dataLength: number
	canLoadMore: boolean
	renderItem: (item: VirtualItem) => ReactElement
	deletingIndex?: number
	getItemKey: (id: number) => string | number
	scrollToItemId?: string
	resetScroll?: boolean
	scrollThreshold?: number
	renderLoader?: () => JSX.Element | string
	scrollToPosition?: number
	parentRef?: React.RefObject<HTMLDivElement>
}

function easeInOutQuint(t) {
	return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
}

// @TODO: need to implement reversed virtualized list

const VirtualizedInfiniteScrollComponent: React.FC<VirtualizedInfiniteScrollComponentProps> = ({
	deletingIndex = -1,
	emptyListPlaceholder,
	isLoading = false,
	isFetching = false,
	header,
	placeholder,
	canLoadMore,
	onLoadMore,
	dataLength,
	renderItem,
	getItemKey,
	scrollToPosition,
	parentRef = null,
}) => {
	const classes = useStyles()
	const scrolledToPosition = useRef<number>(null)

	const scrollingRef = React.useRef<number>()

	const scrollToFn = React.useCallback(
		(offset, defaultScrollTo) => {
			const duration = 1000
			const start = parentRef.current.scrollTop
			const startTime = (scrollingRef.current = Date.now())

			const run = () => {
				if (scrollingRef.current !== startTime) return
				const now = Date.now()
				const elapsed = now - startTime
				const progress = easeInOutQuint(Math.min(elapsed / duration, 1))
				const interpolated = start + (offset - start) * progress

				if (elapsed < duration) {
					defaultScrollTo(interpolated)
					requestAnimationFrame(run)
				} else {
					defaultScrollTo(interpolated)
				}
			}

			requestAnimationFrame(run)
		},
		[parentRef],
	)

	const rowVirtualizer = useVirtual({
		// @TODO: need to standardize feed content, so will be easy to calculate height
		//estimateSize: React.useCallback((index) => ??, []),
		size: dataLength + 1,
		parentRef,
		scrollToFn,
		keyExtractor: getItemKey,
		overscan: 1,
	})

	useLayoutEffect(() => {
		if (
			(scrollToPosition && !scrolledToPosition.current) ||
			(scrolledToPosition.current && scrolledToPosition.current !== scrollToPosition)
		) {
			setTimeout(() => {
				rowVirtualizer.scrollToOffset(scrollToPosition, {
					align: 'start',
				})
			}, 0)

			scrolledToPosition.current = scrollToPosition
		}
	}, [rowVirtualizer, scrollToPosition])

	useEffect(() => {
		const [lastItem] = [...rowVirtualizer.virtualItems].reverse()

		if (!lastItem) {
			return
		}

		if (lastItem.index >= dataLength - 1 && canLoadMore && !isFetching) {
			onLoadMore()
		}
	}, [canLoadMore, dataLength, isFetching, onLoadMore, rowVirtualizer.virtualItems])

	return (
		<ReactVirtualizedAutoSizer>
			{({ height, width }) => (
				<div
					ref={parentRef}
					className="List"
					style={{
						height: height,
						width: width,
						overflow: 'auto',
					}}
				>
					{header}
					{isLoading ? (
						<ContentPreloader />
					) : dataLength === 0 && emptyListPlaceholder ? (
						emptyListPlaceholder
					) : (
						<div
							style={{
								minHeight: `${rowVirtualizer.totalSize}px`,
								width: '100%',
								position: 'relative',
							}}
						>
							{rowVirtualizer.virtualItems.map((virtualRow) => {
								const isLoaderRow = virtualRow.index > dataLength - 1

								return (
									<div
										key={virtualRow.key}
										ref={virtualRow.measureRef}
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											transition: deletingIndex > -1 && virtualRow.index >= deletingIndex ? 'transform 0.15s linear 0s' : 'initial',
											transform: `translateY(${virtualRow.start}px)`,
										}}
									>
										{isLoaderRow ? (
											canLoadMore && isFetching ? (
												<div className={classes.controlPanel}>
													<CircularProgress />
												</div>
											) : (
												<Typography className={classNames(classes.infoText, classes.controlPanel)}>{placeholder}</Typography>
											)
										) : (
											renderItem(virtualRow)
										)}
									</div>
								)
							})}
						</div>
					)}
				</div>
			)}
		</ReactVirtualizedAutoSizer>
	)
}

export { VirtualizedInfiniteScrollComponent }

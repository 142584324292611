import FloatingButton from '@/components/Buttons/FloatingButton'
import AddNewMessageRow from '@/components/Messages/AddNewMessageRow'
import EmptyMembersListPlaceholder from '@/components/Messages/Group/EmptyMembersListPlaceholder'
import GroupMessageBubble from '@/components/Messages/Group/GroupMessageBubble'
import GroupPageHeader, { GROUP_HEADER_HEIGHT } from '@/components/Messages/Group/GroupPageHeader'
import GroupSideBar from '@/components/Messages/Group/GroupSideBar'
import ViewGroupMemberModal from '@/components/Messages/Group/ManageCards/ViewGroupMemberModal'
import MessagesList from '@/components/Messages/MessagesList'
import { IReactionModalData, MessageReactionsModal } from '@/components/Messages/reactions/MessageReactionsModal'
import { STYLES } from '@/constants'
import settings from '@/constants/http'
import { useMarkAsViewedGroupMessagesMutation } from '@/features/messages/api'
import { RootState } from '@/store'
import { clearTargetGroup, getTargetGroup, readGroupRequest, setDrawer } from '@/store/groups'
import {
	clearMessages,
	getMessages,
	getShowSelectUserInput,
	getTargetUser,
	getUnviewedCount,
	loadMessagesListRequest,
	setShowSelectUserInput,
} from '@/store/messages'
import { Message } from '@/store/messages/types'

import { getDateDiff } from '@/utils/dateTime2'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	userName: {
		color: theme.palette.text.primary,
	},
	messagesContainer: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	messagesList: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'inherit',
		minHeight: '50vh',
		paddingTop: 72,
	},

	autoSizer: {
		height: 'fit-content !important',
	},
}))

const GroupPage = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))

	const targetUser = useSelector(getTargetUser)
	const targetGroup = useSelector(getTargetGroup)
	const showSelectUserInput = useSelector(getShowSelectUserInput)
	const { list, canLoadMore, isLoading } = useSelector(getMessages)

	const [markAllRead, { isLoading: patchingMessages }] = useMarkAsViewedGroupMessagesMutation()
	const unviewedCount = useSelector((state: RootState) => getUnviewedCount(state, { groupId: id }))
	const showFloatingButton = unviewedCount > settings.SEARCH_MESSAGES_PER_PAGE

	const [showMessageReactions, setShowMessageReactions] = useState<IReactionModalData | null>(null)

	const handleOpenSideBar = () => dispatch(setDrawer(true))

	const handleHideSelectUserInput = useCallback(() => dispatch(setShowSelectUserInput(false)), [dispatch])

	const showReactions = useCallback((data: IReactionModalData) => {
		setShowMessageReactions(data)
	}, [])

	const showOnlyMessages = !mdScreenSize && (!!targetUser || showSelectUserInput || !!targetGroup)

	const onMarkAllReadClick = useCallback(() => {
		if (id) {
			markAllRead({ groupId: id })
		}
	}, [markAllRead, id])

	const rowRenderer = (message: Message, index: number, array: Message[]) => {
		const diffMin = 1
		const showTimestamp = index !== array.length - 1 ? getDateDiff(message.createdAt, array[index + 1].createdAt) > diffMin : true
		if (!targetGroup) {
			return null
		}
		return (
			<div key={message.id}>
				<GroupMessageBubble
					showReactions={showReactions}
					groupId={targetGroup.id}
					id={message.id}
					message={message}
					showTimestamp={showTimestamp}
				/>
			</div>
		)
	}

	const handleLoadMore = () =>
		dispatch(
			loadMessagesListRequest({
				fetchMore: true,
				groupId: id,
			}),
		)
	const handleCloseReactionModal = useCallback(() => {
		setShowMessageReactions(null)
	}, [])

	useEffect(() => {
		dispatch(readGroupRequest({ id, clearBeforeLoading: true }))
		dispatch(
			loadMessagesListRequest({
				groupId: id,
			}),
		)

		return () => {
			dispatch(clearTargetGroup())
			dispatch(clearMessages())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			<ViewGroupMemberModal />
			{showMessageReactions && <MessageReactionsModal handleClose={handleCloseReactionModal} {...showMessageReactions} />}
			{(mdScreenSize || showOnlyMessages) && (
				<div className={classes.container}>
					{(!showSelectUserInput || !!targetUser) && (
						<div className={classes.messagesContainer}>
							<GroupPageHeader onClick={handleOpenSideBar} data={targetGroup} />
							<div className={classes.messagesList}>
								{showFloatingButton ? (
									<FloatingButton
										top={GROUP_HEADER_HEIGHT}
										onClick={onMarkAllReadClick}
										label={patchingMessages ? 'Loading...' : 'Mark All Read'}
									/>
								) : null}
								<MessagesList
									isLoading={isLoading}
									rowRenderer={rowRenderer}
									handleLoadMore={handleLoadMore}
									targetId={id}
									canLoadMore={canLoadMore}
									messages={list}
									startPlaceholder={(targetGroup?.memberCount || 0) <= 1 ? <EmptyMembersListPlaceholder /> : null}
									listStart="flex-start"
								/>
							</div>
							<AddNewMessageRow handleHideSelectUserInput={handleHideSelectUserInput} />
						</div>
					)}
				</div>
			)}
			{!!targetGroup && <GroupSideBar data={targetGroup} />}
		</>
	)
}

export default GroupPage

import { ContentPreloader } from '@/components/ContentPreloader'
import Card from '@/components/IndividualPage/Card'
import { EntitiesWithResponses, useGetEntityResponsesQuery } from '@/features/responses/api'
import { makeStyles, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { ReactNode, useCallback, useState } from 'react'
import { InterestedList } from './InterestedList'

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	expandIcon: {
		color: theme.colors.grey[500],
	},

	iconButton: {
		padding: 5,
	},
}))

export type IExpandableCardProps = {
	currentUserHasResponse?: boolean
	title: ReactNode
	entityId: string
	entityType: EntitiesWithResponses
}

const ExpandableCard: React.FC<IExpandableCardProps> = ({ currentUserHasResponse, title, entityId, entityType }) => {
	const classes = useStyles()

	const { data: users, isLoading } = useGetEntityResponsesQuery({ entityId, entityType })

	const [expanded, setExpanded] = useState(false)
	const handleToggleExpandMore = useCallback(() => {
		setExpanded((prev) => !prev)
	}, [])

	return (
		<Card>
			<div className={classes.titleContainer}>
				<Typography variant="body1">
					<b>{title}</b> {expanded && users ? ` | ${users.length}` : ''}
				</Typography>
				<IconButton className={classes.iconButton} onClick={handleToggleExpandMore}>
					{!expanded ? <ExpandMore className={classes.expandIcon} /> : <ExpandLess className={classes.expandIcon} />}
				</IconButton>
			</div>
			{isLoading ? (
				<div>
					<ContentPreloader />
				</div>
			) : (
				<InterestedList currentUserHasResponse={currentUserHasResponse} users={users} short={!expanded} />
			)}
		</Card>
	)
}

export { ExpandableCard }

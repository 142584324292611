import { NotificationAudience } from '@/features/notifications/types'
import { formatDateNew } from '@/utils/dateTime2'
import { GridValueGetter } from '@mui/x-data-grid'

const VALUE_GETTERS_MAP = {
	date: (...[value, row]: Parameters<GridValueGetter<Date | null>>) => {
		if (!value) {
			return null
		}
		return new Date(value)
	},
	datePresent: (...[value, row]: Parameters<GridValueGetter>): string => {
		if (!value) {
			return 'Present'
		}
		return formatDateNew(value, 'dateMed')
	},
	fullName: (...[value, row]: Parameters<GridValueGetter>): string => {
		return `${row?.firstName} ${row?.lastName}`
	},
	fullNameFromUser: (...[value, row]: Parameters<GridValueGetter>): string => {
		return `${row?.user?.firstName} ${row?.user?.lastName}`
	},
	stripHtml: (...[value, row]: Parameters<GridValueGetter>): string => {
		return (value as string)?.replace(/<[^>]*>?/gm, '') ?? ''
	},
	stripNan: (...[value, row]: Parameters<GridValueGetter>): any => {
		if (value === 'NaN') {
			return null
		}
		return value
	},
	audience: (...[value, row]: Parameters<GridValueGetter>): string => {
		return Object.keys(NotificationAudience).find((key) => NotificationAudience[key] === row?.audienceId) || ''
	},
}

export default VALUE_GETTERS_MAP

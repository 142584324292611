import http from '@/api/http/requestHttp'

interface PatchOrganizationMembershipRequestToViewed {
	orgId: string
	data: number[]
	schoolId: number
}

const patchOrganizationMembershipRequestToViewed = async ({ orgId, data, schoolId }: PatchOrganizationMembershipRequestToViewed) => {
	const url = `${schoolId}/organizations/org/${orgId}/requests/view`

	return http.patch(url, data)
}

export default patchOrganizationMembershipRequestToViewed

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'

export enum OrgMembershipRequestStatus {
	ACCEPTED = 'accepted',
	REJECTED = 'rejected',
	PENDING = 'pending',
	CANCELED = 'canceled',
}

interface PatchOrganizationMembershipRequestParams {
	id: number
	patchedUserId: UserInfo['id']
	status: OrgMembershipRequestStatus
	schoolId: number
}

const patchOrganizationMembershipRequest = async ({ id, patchedUserId, status, schoolId }: PatchOrganizationMembershipRequestParams) => {
	const url = `${schoolId}/organizations/membershipRequest/${id}`
	const body = { patchedUserId, status }

	return http.patch(url, body)
}

export default patchOrganizationMembershipRequest

import http from '@/api/http/requestHttp'

export enum ChangeGroupMemberRequestStatus {
	APPROVE = 'approve',
	DECLINE = 'decline',
}

const patchGroupMemberRequest = async (id: string, requestId: string, action: `${ChangeGroupMemberRequestStatus}`, schoolId: number) => {
	const url = `${schoolId}/groups/${id}/members/requests/${requestId}`

	return http.patch(url, { action })
}

export default patchGroupMemberRequest

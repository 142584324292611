import settings from '@/constants/http'
import { ReportActions, UserData, UserResponseStatuses } from '@/interfaces/common'
import { setUserResponse as setUserResearchResponse } from '@/store/research'
import { setUserResponse as setUserStudyAbroadResponse } from '@/store/studyAbroad'
import { setUserResponse as setUserJobResponse } from '@/store/internships'
import { getNormalizedUserData } from '@/utils/common'
import { isEqual } from 'lodash'
import { baseApi } from '../general/baseApi'
import { researchApi } from '../research/api'
import { studyAbroadApi } from '../studyAbroad/api'
import { jobApi } from '../job/api'
import { UserInfo } from '@/interfaces/user'
import ExtendFallBackImageParam from '@/anthology/types/ExtendFallBackImageParam'
import GetCurrentUserRespondedEntitiesParams from './types/queryParams/GetCurrentUserRespondedEntitiesParams'

interface DtoUserResponses {
	response: {
		[key: string]: UserResponseStatuses
	}
}

interface DtoEntityResponses {
	response: Partial<Record<`${UserResponseStatuses}`, { appUser: Array<string | null> }[]>>
}

const ENTITY_TAG_MAP = {
	studyAbroad: { currentUser: 'StudyAbroadResponse', allUsers: 'StudyAbroadUsersResponse' },
	research: { currentUser: 'ResearchResponse', allUsers: 'ResearchUsersResponse' },
	jobs: { currentUser: 'JobResponse', allUsers: 'JobUsersResponse' },
} as const

const ALL_RESPONSE_TAGS = Object.values(ENTITY_TAG_MAP).reduce((acc, value) => {
	acc.push(...[value.allUsers, value.currentUser])
	return acc
}, [])

export type EntitiesWithResponses = keyof typeof ENTITY_TAG_MAP

const getEntityPatchUtil = (entityType: EntitiesWithResponses, id: string, response: UserResponseStatuses, userId: UserInfo['id']) => {
	switch (entityType) {
		case 'research':
			return researchApi.util.updateQueryData('getResearch', { id }, (draft) => {
				draft.research.responseStatus = response
				draft.research.response = { ...draft.research.response, userId, response, researchId: id }
			})

		case 'studyAbroad':
			return studyAbroadApi.util.updateQueryData('getStudyAbroad', { id }, (draft) => {
				draft.studyAbroad.response = { ...draft.studyAbroad.response, userId, response, studyAbroadId: id }
			})

		case 'jobs':
			return jobApi.util.updateQueryData('getJob', { id }, (draft) => {
				draft.job.response = { ...draft.job.response, userId, response, jobId: id }
			})
	}
}

export const responsesApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ALL_RESPONSE_TAGS,
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			/**
			 * Retrieves current user all responses for specified entity type
			 */
			getCurrentUserResponses: builder.query<DtoUserResponses, { entityType: EntitiesWithResponses; userId: number }>({
				query: ({ entityType, userId }) => ({
					url: `/${entityType}/${userId}/allresponses`,
				}),
				providesTags: (result, error, { entityType }) => [ENTITY_TAG_MAP[entityType].currentUser],
			}),
			// @TODO: need pagination here
			// @TODO: api endpoint should be changed so we can pass response type and retrieve only those responses
			// @TODO: user data response structure should be corrected here
			// Example {"app_user":[1,"Tests","Authentication","authenticationschema@gmail.com","psu_test_user_1",null]}
			// if backend responds in different order this will break entire app, this isn't good pattern
			/**
			 * Retrieves all user responses for specified entity and entity id
			 * Right now we have implementation only for interested response type
			 */
			getEntityResponses: builder.query<UserData[], { entityType: EntitiesWithResponses; entityId: string }>({
				query: ({ entityType, entityId }) => ({
					url: `/${entityType}/${entityId}/userResponses`,
				}),
				providesTags: (result, error, { entityType }) => [ENTITY_TAG_MAP[entityType].allUsers],
				transformResponse: ({ response }: DtoEntityResponses, meta, arg) => {
					if (response && response.interested) {
						return getNormalizedUserData(response.interested)
					}
					return []
				},
			}),
			/**
			 * Retrieves current user response count for given entity type
			 * Right now only implemented for interested response type
			 */
			getEntityResponseCount: builder.query<{ total: number }, { entityType: EntitiesWithResponses; response?: UserResponseStatuses }>({
				query: ({ entityType, response = UserResponseStatuses.interested }) => ({
					url: `/${entityType}/search/count`,
					params: {
						responses: [response],
					},
				}),
			}),
			getCurrentUserRespondedEntities: builder.query<
				{ list: any[]; canLoadMore: boolean },
				ExtendFallBackImageParam<GetCurrentUserRespondedEntitiesParams>
			>({
				query: ({
					entityType,
					withFallBackImage = true,
					response = UserResponseStatuses.interested,
					limit = settings.SEARCH_LIST_PER_PAGE,
					offset = 0,
				}) => ({
					url: `/${entityType}/search`,
					params: {
						withFallBackImage,
						responses: [response],
						limit,
						offset: offset > 0 ? offset : undefined,
					},
				}),
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}-${queryArgs.response}`
					return serializedKey
				},
				merge: (currentCache, { list, canLoadMore }, { arg: { offset } }) => {
					if (offset > 0) {
						currentCache.list.push(...list)
						currentCache.canLoadMore = canLoadMore
					} else {
						currentCache.list = list
						currentCache.canLoadMore = canLoadMore
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				transformResponse: (result: any[], meta, { limit = settings.SEARCH_LIST_PER_PAGE, response = UserResponseStatuses.interested }) => {
					return {
						list: result.map((r) => ({
							...r,
							responseStatus: r.response && r.response.response === response ? response : null,
						})),
						canLoadMore: result.length === limit,
					}
				},
			}),
			patchEntityReport: builder.mutation<
				undefined,
				{ entityType: EntitiesWithResponses; userId: number; response: ReportActions; reportMessage: string; entityId: string }
			>({
				query: ({ entityId, entityType, reportMessage, response, userId }) => ({
					// @TODO: inconsistency in the api
					url: entityType !== 'jobs' ? `/${entityType}/${entityId}/response` : `/${entityType}/job/${entityId}/response`,
					method: 'POST',
					body: {
						userId,
						response,
						reportMessage,
					},
				}),
			}),
			/**
			 * Patches response for given entity type and entity id (only interested at the moment)
			 */
			patchUserResponse: builder.mutation<
				string,
				{ entityId: string; entityType: EntitiesWithResponses; userId: number; response: UserResponseStatuses }
			>({
				query: ({ entityType, entityId, userId, response }) => ({
					url: entityType !== 'jobs' ? `/${entityType}/${entityId}/response` : `/${entityType}/job/${entityId}/response`,
					method: 'POST',
					body: {
						userId,
						response,
					},
					responseHandler: (response) => response.text(),
				}),
				onQueryStarted: async ({ entityId, entityType, response, userId }, { dispatch, queryFulfilled, getState }) => {
					const patchResponseCount = dispatch(
						responsesApi.util.updateQueryData('getEntityResponseCount', { entityType }, (draft) => {
							draft.total += response === UserResponseStatuses.cleared ? -1 : 1
						}),
					)
					const patchListWithResponses = dispatch(
						responsesApi.util.updateQueryData('getCurrentUserRespondedEntities', { entityType }, (draft) => {
							if (response === UserResponseStatuses.cleared) {
								draft.list = draft.list.filter((entity) => entity.id !== entityId)
							}
						}),
					)
					const patchIndividual = dispatch(getEntityPatchUtil(entityType, entityId, response, userId))

					try {
						await queryFulfilled
					} catch {
						patchIndividual.undo()
						patchListWithResponses.undo()
						patchResponseCount.undo()
					}
				},
				onCacheEntryAdded: async ({ response, entityId, entityType }, { dispatch, cacheDataLoaded }) => {
					await cacheDataLoaded
					// @TODO: switch to optimistic update after changing individual to rtk query
					switch (entityType) {
						case 'research':
							dispatch(setUserResearchResponse({ response, id: entityId }))

							break
						case 'studyAbroad':
							dispatch(setUserStudyAbroadResponse({ response, id: entityId }))
							break
						case 'jobs':
							dispatch(setUserJobResponse({ response, id: entityId }))
					}
				},
				invalidatesTags: (result, error, { entityId, entityType }) => [ENTITY_TAG_MAP[entityType].allUsers],
			}),
		}),
	})

export const {
	useGetCurrentUserRespondedEntitiesQuery,
	useGetCurrentUserResponsesQuery,
	useGetEntityResponsesQuery,
	usePatchUserResponseMutation,
	useGetEntityResponseCountQuery,
	usePatchEntityReportMutation,
} = responsesApi

import snakeCase from 'lodash/snakeCase'
import toUpper from 'lodash/toUpper'
import IStorageOptions from './types/IStorageOptions'
import TRetrievalMode from './types/TRetrievalMode'
import { parse, serialize } from './utils'
import { isObject } from 'lodash'

export default class TypedStorageManager<TSchema> {
	constructor(
		private readonly storage: Storage = localStorage,
		private readonly options: IStorageOptions = {
			parse,
			serialize,
			prefix: 'NAVENGAGE',
			screamingSnakeCase: true,
			retrievalMod: 'safe',
		},
	) {}
	public get length(): number {
		return this.storage?.length
	}
	public key<TKey extends keyof TSchema>(index: number): TKey {
		return this.storage?.key(index) as TKey
	}

	public getItem<TKey extends keyof TSchema>(key: TKey, retrievalMode: TRetrievalMode = 'raw'): TSchema[TKey] | null {
		const item = this.storage.getItem(this.getKey(key))

		if (item === null) {
			return item as null
		}

		try {
			return this.options.parse<TSchema[TKey]>(item)
		} catch (error) {
			switch (retrievalMode) {
				case 'safe':
					return null
				case 'raw':
					return item as unknown as TSchema[TKey]
				default:
					throw error
			}
		}
	}

	public setMany<TKey extends keyof TSchema>(data: Partial<TSchema>): void {
		const keys = Object.keys(data) as TKey[]
		keys.forEach((key: TKey) => {
			this.setItem(key, data[key])
		})
	}

	public setItem<TKey extends keyof TSchema>(key: TKey, value: TSchema[TKey]): void {
		this.storage.setItem(this.getKey(key), isObject(value) ? this.options.serialize(value) : value.toString())
	}

	public removeItem<TKey extends keyof TSchema>(key: TKey): void {
		this.storage.removeItem(this.getKey(key))
	}

	public clear(keys?: Array<keyof TSchema>): void {
		if (keys) {
			keys.forEach((key) => {
				this.removeItem(key)
			})
		} else {
			this.storage.clear()
		}
	}
	public getKey<TKey extends keyof TSchema>(key: TKey) {
		const convertedKey = this.options.screamingSnakeCase ? toUpper(snakeCase(key.toString())) : key.toString()
		return this.options.prefix ? `${this.options.prefix}_${convertedKey}` : convertedKey
	}
}

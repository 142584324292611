import { useCurrentUser } from '@/hooks/userHooks'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'

import { EntitiesWithResponses, usePatchEntityReportMutation, usePatchUserResponseMutation } from '../api'
import { useCallback } from 'react'

const useResponseHandler = (entityType: EntitiesWithResponses, id: string) => {
	const currentUser = useCurrentUser()
	const [patchResponse, responseQuery] = usePatchUserResponseMutation()
	const [patchEntityReport, reportQuery] = usePatchEntityReportMutation()

	const handleChangeResponse = useCallback(
		(status: UserResponseStatuses) => {
			patchResponse({ entityId: id, entityType, response: status, userId: currentUser.id })
		},
		[currentUser.id, entityType, id, patchResponse],
	)

	const handleReport = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			patchEntityReport({ entityId: id, entityType, reportMessage, response: status, userId: currentUser.id })
		},
		[currentUser.id, entityType, patchEntityReport],
	)

	return {
		handleChangeResponse,
		handleReport,
		responseQuery,
		reportQuery,
	}
}

export default useResponseHandler

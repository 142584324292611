import http from '@/api/http/requestHttp'
import { ToggleReactionParams } from './createReaction'

const deleteReaction = async ({ messageId, reactionType = 'like', schoolId }: ToggleReactionParams) => {
	const url = `${schoolId}/messages/${messageId}/reaction`
	const params = {
		action: 'like',
	}
	return http.delete(url, { params })
}

export default deleteReaction

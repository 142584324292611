// @ts-nocheck

import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import { ORGANIZATION_ROLE_IDS } from '@/utils/organizationRoles'
import * as actions from '@/store/organizations/actions'
import { OrganizationsState, OrgMembership, OrgMemberships, OrgMembershipRequest } from '@/store/organizations/types'
import settings from '@/constants/http'

const isEqualMembershipId = (membership: OrgMembership, id: number) => membership.id === id || id === membership.referenceToReplacedId

const initialState: OrganizationsState = {
	search: {
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	organizations: [],
	isLoading: false,
	isLoadingUserOrganizations: false,
	isLoadingIndividualOrganization: false,
	categories: [],
	userOrganizations: {
		followed: [],
		member: [],
		admin: [],
		superAdmin: [],
	},
	superAdminOrganizations: [],
	selectedOrganization: {
		isLoading: {
			isLoadingMemberships: {},
		},
		activity: {
			list: [],
			page: 1,
			endHasBeenReached: false,
		},
		followers: {
			list: [],
			total: 0,
			page: 1,
			canLoadMore: true,
		},
		memberships: {},
		newMemberRequests: {
			list: [],
			total: 0,
			unseenCount: 0,
			page: 1,
		},
		positionRequests: {
			list: [],
			total: 0,
			unseenCount: 0,
			page: 1,
		},
		pendingUserRequests: [],
		searchUsers: {
			users: [],
			page: 1,
			canLoadMore: true,
			isLoading: false,
		},
		searchOrgMemberships: {
			q: '',
			memberships: [],
			page: 1,
			canLoadMore: true,
			isLoading: false,
		},
	},
	leaveFromOrganizationModal: {
		orgId: '',
		isOpen: false,
	},
	openOrgDefaultOptions: null,
	pendingUserRequests: {},
	userTypeOptions: [],
}

const organizationsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setOrganizations, (state, action) => ({
			...state,
			organizations: action.payload,
		}))
		.addCase(actions.setCategories, (state, action) => ({
			...state,
			categories: action.payload,
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			organizations: [],
		}))
		.addCase(actions.loadUserOrganizationsRequest.pending, (state) => ({
			...state,
			isLoadingUserOrganizations: true,
		}))
		.addCase(actions.loadUserOrganizationsRequest.fulfilled, (state) => ({
			...state,
			isLoadingUserOrganizations: false,
		}))
		.addCase(actions.loadUserOrganizationsRequest.rejected, (state) => ({
			...state,
			isLoadingUserOrganizations: false,
		}))
		.addCase(actions.setUserOrganizations, (state, action) => ({
			...state,
			userOrganizations: action.payload,
		}))
		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					page: 0,
					...rest,
				},
			}
		})
		.addCase(actions.loadMore, (state) => {
			state.search.page = state.search.page += settings.SEARCH_LIST_PER_PAGE
		})
		.addCase(actions.setIndividualOrganization, (state, action) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				isLoading: {
					isLoadingMemberships: {},
				},
				info: action.payload,
				newMemberRequests: {
					list: [],
					total: 0,
					unseenCount: 0,
					page: 1,
				},
				positionRequests: {
					list: [],
					total: 0,
					unseenCount: 0,
					page: 1,
				},
				searchUsers: {
					users: [],
					page: 1,
					canLoadMore: true,
					isLoading: false,
				},
				searchOrgMemberships: {
					q: '',
					memberships: [],
					page: 1,
					canLoadMore: true,
					isLoading: false,
				},
			},
			organizations: state.organizations.map((org) => (org.id !== action.payload.id ? org : { ...org, followed: action.payload.followed })),
		}))
		.addCase(actions.setOrganizationsListFollowStatus, (state, { payload: { followed, id } }) => {
			state.organizations.forEach((org) => {
				if (org.id === id) {
					org.followed = followed
				}
			})
			//state.organizations = state.organizations.map((org) => (org.id !== payload ? org : { ...org, followed: action.payload.followed }))
		})
		.addCase(actions.loadOrganizationByIdRequest.pending, (state) => ({
			...state,
			isLoadingIndividualOrganization: true,
		}))
		.addCase(actions.loadOrganizationByIdRequest.fulfilled, (state) => ({
			...state,
			isLoadingIndividualOrganization: false,
		}))
		.addCase(actions.loadOrganizationByIdRequest.rejected, (state) => ({
			...state,
			isLoadingIndividualOrganization: false,
		}))
		.addCase(actions.setSuperAdminOrganizations, (state, { payload }) => ({
			...state,
			superAdminOrganizations: payload,
		}))
		.addCase(actions.setOrganizationActivity, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				info: {
					...state.selectedOrganization.info!,
					numberOfPosts: `${payload.list?.length}`,
				},
				activity: {
					...state.selectedOrganization?.activity,
					...payload,
				},
			},
		}))
		.addCase(actions.setOrganizationFollowers, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				followers: payload,
			},
		}))
		// @TODO: need to check this one
		.addCase(actions.setOrganizationPointOfContact, (state, { payload }) => {
			const newMemberships = ORGANIZATION_ROLE_IDS.reduce((acc, membershipTypeId) => {
				const membershipsByType = state.selectedOrganization.memberships[membershipTypeId]

				let updatedMemberships: OrgMemberships = {}

				if (membershipsByType?.total) {
					let updatedList = membershipsByType.list.map((membership) =>
						isEqualMembershipId(payload, membership.id) ? payload : { ...membership, pointOfContact: false },
					)

					updatedMemberships[membershipTypeId] = {
						total: updatedList.length,
						list: updatedList,
					}
				}

				return {
					...acc,
					...updatedMemberships,
				}
			}, {})

			return {
				...state,
				selectedOrganization: {
					...state.selectedOrganization,
					info: {
						...state.selectedOrganization.info!,
						pointOfContact: payload,
					},
					memberships: newMemberships,
				},
			}
		})

		.addCase(actions.updateOrganizationMemberships, (state, { payload }) => {
			const newMemberships = ORGANIZATION_ROLE_IDS.reduce((acc, membershipTypeId) => {
				const membershipsByType = state.selectedOrganization.memberships[membershipTypeId]

				let updatedMemberships: OrgMemberships = {}

				if (!membershipsByType?.total && payload.organizationMembershipTypeId === membershipTypeId) {
					updatedMemberships[membershipTypeId] = {
						total: 1,
						list: [payload],
					}
				} else if (membershipsByType?.total) {
					let updated = false

					let updatedList = membershipsByType.list.reduce((acc, membership) => {
						if (isEqualMembershipId(payload, membership.id)) {
							updated = true

							if (membership.organizationMembershipTypeId === payload.organizationMembershipTypeId) {
								return [...acc, payload]
							} else {
								return acc
							}
						}

						return [...acc, membership]
					}, [] as OrgMembership[])

					if (!updated && payload.organizationMembershipTypeId === membershipTypeId) {
						updatedList = [payload, ...updatedList]
					}

					updatedMemberships[membershipTypeId] = {
						total: updatedList.length,
						list: updatedList,
					}
				}

				return {
					...acc,
					...updatedMemberships,
				}
			}, {})

			return {
				...state,
				selectedOrganization: {
					...state.selectedOrganization,
					memberships: newMemberships,
					searchOrgMemberships: {
						...state.selectedOrganization.searchOrgMemberships,
						memberships: state.selectedOrganization.searchOrgMemberships.memberships.map((membership) =>
							isEqualMembershipId(payload, membership.id) ? payload : membership,
						),
					},
				},
			}
		})
		.addCase(actions.removeOrganizationMembership, (state, { payload }) => {
			const newMemberships = ORGANIZATION_ROLE_IDS.reduce((acc, membershipTypeId) => {
				const membershipsByType = state.selectedOrganization.memberships[membershipTypeId]

				let updatedMemberships: OrgMemberships = {}

				if (membershipsByType?.total) {
					let updatedList = membershipsByType.list.filter((membership) => membership.id !== payload)

					updatedMemberships[membershipTypeId] = {
						total: updatedList.length,
						list: updatedList,
					}
				}

				return {
					...acc,
					...updatedMemberships,
				}
			}, {})

			return {
				...state,
				selectedOrganization: {
					...state.selectedOrganization,
					memberships: newMemberships,
					searchOrgMemberships: {
						...state.selectedOrganization.searchOrgMemberships,
						memberships: state.selectedOrganization.searchOrgMemberships.memberships.filter((membership) => membership.id !== payload),
					},
				},
			}
		})
		.addCase(actions.setOrganizationPositionRequests, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				positionRequests: payload,
			},
		}))
		.addCase(actions.setOrganizationMembershipRequests, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				newMemberRequests: payload,
			},
		}))
		.addCase(actions.setIsLoadingOrganizationData, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				isLoading: {
					...state.selectedOrganization.isLoading,
					...payload,
				},
			},
		}))
		.addCase(actions.setOrganizationPendingUserRequests, (state, { payload: { id, requests } }) => ({
			...state,
			pendingUserRequests: {
				...state.pendingUserRequests,
				[id]: requests,
			},
		}))
		.addCase(actions.setLeaveFromOrganizationModal, (state, { payload }) => ({
			...state,
			leaveFromOrganizationModal: {
				...state.leaveFromOrganizationModal,
				...payload,
			},
		}))
		.addCase(actions.setOpenOrgDefaultOptions, (state, { payload }) => ({
			...state,
			openOrgDefaultOptions: payload,
		}))
		.addCase(actions.setViewedRequests, (state, { payload }) => {
			const setViewed = (list: OrgMembershipRequest[], unseenCount: number) => {
				let numberOfChanged = 0
				const newList: OrgMembershipRequest[] = list.map((req) => {
					const changed = payload.includes(req.id)

					numberOfChanged = changed ? numberOfChanged + 1 : numberOfChanged

					return changed ? { ...req, viewed: true } : req
				})

				return {
					list: newList,
					unseenCount: unseenCount - numberOfChanged,
				}
			}

			const newMemberRequests = state.selectedOrganization.newMemberRequests
			const positionRequests = state.selectedOrganization.positionRequests

			return {
				...state,
				selectedOrganization: {
					...state.selectedOrganization,
					newMemberRequests: {
						...newMemberRequests,
						...setViewed(newMemberRequests.list, newMemberRequests.unseenCount),
					},
					positionRequests: {
						...positionRequests,
						...setViewed(positionRequests.list, positionRequests.unseenCount),
					},
				},
			}
		})
		.addCase(actions.searchUserRequest.pending, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					...state.selectedOrganization.searchUsers,
					isLoading: true,
				},
			},
		}))
		.addCase(actions.searchUserRequest.rejected, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					...state.selectedOrganization.searchUsers,
					isLoading: false,
				},
			},
		}))
		.addCase(actions.searchUserRequest.fulfilled, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					...state.selectedOrganization.searchUsers,
					isLoading: false,
				},
			},
		}))
		.addCase(actions.setSearchUsers, (state, { payload: { page, canLoadMore, users } }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					...state.selectedOrganization.searchUsers,
					page,
					canLoadMore,
					users,
				},
			},
		}))
		.addCase(actions.clearSearchUsers, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					page: 1,
					canLoadMore: true,
					isLoading: false,
					users: [],
				},
			},
		}))

		.addCase(actions.setSearchOrgMembershipsQuery, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					...state.selectedOrganization.searchOrgMemberships,
					q: payload,
					canLoadMore: true,
				},
			},
		}))
		.addCase(actions.searchOrgMembershipsRequest.pending, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					...state.selectedOrganization.searchOrgMemberships,
					isLoading: true,
				},
			},
		}))
		.addCase(actions.searchOrgMembershipsRequest.rejected, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					...state.selectedOrganization.searchOrgMemberships,
					isLoading: false,
				},
			},
		}))
		.addCase(actions.searchOrgMembershipsRequest.fulfilled, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					...state.selectedOrganization.searchOrgMemberships,
					isLoading: false,
				},
			},
		}))
		.addCase(actions.setSearchOrgMemberships, (state, { payload: { page, canLoadMore, memberships } }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					...state.selectedOrganization.searchOrgMemberships,
					page,
					canLoadMore,
					memberships,
				},
			},
		}))
		.addCase(actions.clearSearchOrgMemberships, (state) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchOrgMemberships: {
					q: '',
					page: 1,
					canLoadMore: true,
					isLoading: false,
					memberships: [],
				},
			},
		}))
		.addCase(actions.setUserTypeOptions, (state, { payload }) => ({
			...state,
			userTypeOptions: payload,
		}))
		.addCase(actions.updateUserInSearchResult, (state, { payload }) => ({
			...state,
			selectedOrganization: {
				...state.selectedOrganization,
				searchUsers: {
					...state.selectedOrganization.searchUsers,
					users: state.selectedOrganization.searchUsers.users.map((user) =>
						user.id === payload.id
							? {
									...user,
									added: true,
									organizationMembershipTypeId: payload.membershipTypeId,
							  }
							: user,
					),
				},
			},
		}))
})

export { organizationsReducer }

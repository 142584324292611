import { useRef } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const usePrevious = (value) => {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef()
	const dummyNonPrimitiveDependency = {}
	// Store current value in ref
	useDeepCompareEffect(() => {
		ref.current = value
	}, [value, dummyNonPrimitiveDependency]) // Only re-run if value changes
	// Return previous value (happens before update in useEffect above)
	return ref.current
}

import { recordPostImpressions } from '@/api/http/feeds'
import { feedUpdatesSelectors, postsViewed } from '@/features/posts/feed/slice'
import useAuth from '@/hooks/useAuth'
import { PostId } from '@/lib/types'
import { store, useAppDispatch } from '@/store'
import { FeedPost } from '@/store/feeds/types'
import DataLoader from 'dataloader'
import { uniq } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { InViewMonitor } from '../vendor/InViewMonitor'

const postIdRecordedStatusMap: Record<string, boolean> = {}
const BATCH_DELAY_MILLISECONDS = 1000
/**
 * Monitor a Post to record impression when it appears in the viewport.
 */
export const PostImpressionRecorder: FC<{
	id: PostId
	post: FeedPost
	className: string
	onInView?: any
	repeatOnInView?: boolean
	as?: keyof JSX.IntrinsicElements
}> = (props) => {
	const dispatch = useAppDispatch()
	const { userId, schoolId } = useAuth()
	const { post, ...otherProps } = props

	const isOwnPost = post.ownerUserId === userId

	const impressionsBatcher = useMemo(
		() =>
			new DataLoader(
				(ids: PostId[]) => {
					const uniqIds = uniq(ids)
					recordPostImpressions({ postIds: uniqIds, userId, schoolId })
					const hasUpdates = feedUpdatesSelectors.selectAll(store.getState())
					if (hasUpdates.length > 0) {
						dispatch(postsViewed(uniqIds))
					}

					// We have to return a resolved array of results. The ids are fine here.
					return Promise.resolve(ids)
				},
				{
					batchScheduleFn: (callback) => setTimeout(callback, BATCH_DELAY_MILLISECONDS),
				},
			),
		[dispatch, schoolId, userId],
	)

	const newProps = {
		...otherProps,
		repeatOnInView: true,
		doNotObserve: isOwnPost || !!postIdRecordedStatusMap[post.id],
	}

	const trackImpression = useCallback(() => {
		if (postIdRecordedStatusMap[post.id]) {
			return
		}

		impressionsBatcher.load(post.id)
		postIdRecordedStatusMap[post.id] = true
	}, [impressionsBatcher, post.id])

	return <InViewMonitor key={post.id} onInView={trackImpression} {...newProps} />
}

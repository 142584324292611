import Placeholder, { PlaceholderProps } from '@/components/Placeholder'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
		fontWeight: 600,
	},
}))

export interface IEmptyTabPlaceholderProps extends PlaceholderProps {
	title?: string
	description?: string[]
}

const EmptyTabPlaceholder: React.FC<IEmptyTabPlaceholderProps> = ({ title, description, isEmptyPlaceholder, ...placeholderProps }) => {
	const classes = useStyles()
	return (
		<Placeholder {...placeholderProps} isEmptyPlaceholder>
			{title && (
				<Typography align="center" className={classes.placeholderHeader}>
					{title}
				</Typography>
			)}
			{description &&
				description.map((text, index) => (
					<Typography key={index} align="center">
						{text}
					</Typography>
				))}
		</Placeholder>
	)
}

export { EmptyTabPlaceholder }

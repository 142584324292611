import React from 'react'
import PostCard from '@/components/Feeds/PostCard'
import useFeedPostById from '../../hooks/useFeedPostById'

interface FeedItemProps {
	postId: string
	scrollIndex: number
	onClick: () => void
}

const FeedItem: React.FC<FeedItemProps> = ({ postId, scrollIndex, onClick }) => {
	const { data } = useFeedPostById(postId)

	return <PostCard shouldSkipViewMonitor clickable onClick={onClick} key={data.id} {...data} />
}

export default React.memo(FeedItem)

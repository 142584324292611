import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { PATHS } from '@/constants'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		margin: '1vh 15px',
		cursor: 'pointer',
	},
	informationContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
	},
	organizationImage: {
		width: 40,
		height: 40,
		minWidth: 40,
		minHeight: 40,
		marginRight: 20,
		borderRadius: '50%',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		marginTop: 10,
	},
	organizationName: {
		color: theme.palette.text.primary,
	},
	text: {
		fontSize: 15,
		textTransform: 'capitalize',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
}))

interface OrganizationRowProps {
	organization: any
	children?: React.ReactNode
	classnames?: {
		root?: string
		organizationName?: string
		organizationImage?: string
		info?: string
	}
}

const OrganizationRow = ({
	organization,
	children,
	classnames = {
		root: '',
		organizationName: '',
		organizationImage: '',
		info: '',
	},
}: OrganizationRowProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const { id, name, photoUrl, campus } = organization

	const handleClick = (e: any) => {
		e.stopPropagation()
		push(PATHS.APP.ORGANIZATIONS_SINGLE(`${id}`))
	}

	return (
		<div className={classNames(classes.root, classnames.root)} onClick={handleClick}>
			<img className={classNames(classes.organizationImage, classnames.organizationImage)} src={photoUrl} alt={name} />
			<div className={classes.informationContainer}>
				<Typography className={classNames(classes.organizationName, classes.text, classnames.organizationName)}>{name}</Typography>
				<Typography className={classNames(classes.text, classnames.info)}>{`Organization ${campus ? ` | ${campus}` : ''}`}</Typography>
				{children && <div className={classes.buttonsContainer}>{children}</div>}
			</div>
		</div>
	)
}

export default OrganizationRow

import root from '@/routes/root'
import app from '@/routes/app'
import signUp from '@/routes/signUp'
import events from '@/routes/events'
import organizations from '@/routes/organizations'
import volunteers from '@/routes/volunteers'
import research from '@/routes/research'
import studyAbroad from '@/routes/studyAbroad'
import network from '@/routes/network'
import feeds from '@/routes/feeds'
import pathBuilder from '@/routes/pathBuilder'
import internships from '@/routes/internships'
import settings from '@/routes/settings'
import messages from '@/routes/messages'
import webview from '@/routes/webview'

const ROUTES = {
	root,
	signUp,
	app,
	events,
	organizations,
	volunteers,
	research,
	studyAbroad,
	network,
	feeds,
	pathBuilder,
	internships,
	settings,
	messages,
	webview,
}

export default ROUTES

import { CircularProgress, Step, StepButton, StepLabel, Stepper, Typography } from '@material-ui/core'
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles'
import { Check, GroupAdd, Info, LockOpen, Settings } from '@material-ui/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { CommonModalCard, Modal, ModalWithSubmittedPopup } from '@/components'
import { STYLES } from '@/constants'
import { UserData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { createGroupRequest, getIsCreatingNewGroup } from '@/store/groups'
import { GroupChatTypes } from '@navengage/sen-types'
import AddGroupMembers from './ManageCards/AddGroupMembers'
import GroupInfo, { GroupInfoData } from './ManageCards/GroupInfo'
import GroupSettings, { GroupSettingsData } from './ManageCards/GroupSettings'
import GroupType from './ManageCards/GroupType'

const useStyles = makeStyles((theme) => ({
	rootCard: {
		minWidth: 530,
		width: '75vw',
		[theme.breakpoints.up('lg')]: {
			width: '45vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: 'none',
			minWidth: 'auto',
		},
	},
	cardHeader: {},
	rootContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		minHeight: '60vh',
		// padding: 40,
	},
	contentContainer: {
		maxHeight: '80vh',
		overflow: 'auto',
		paddingBottom: 25,
	},
	content: {
		margin: '0 20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	input: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		fontSize: '1em',
		// TODO: check for shadow unification
		boxShadow: '0px 7px 15px -1px rgba(181,181,181,0.33)',
		margin: '2vh 0',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	searchInputContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '0 1.5vw',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	cardToShareContainer: {
		width: '50vw',
		margin: '2vh 0',
		[theme.breakpoints.up('lg')]: {
			width: '35vw',
		},
	},
	itemsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		overflow: 'auto',
		maxHeight: 320,
		margin: '20px 0',
	},
	searchPlaceholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		textAlign: 'center',
		padding: '10vh',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 48%)`,
		justifyContent: 'space-evenly',
		gridGap: 0,
	},
	stepper: {
		padding: 10,
	},
	stepperLabel: {
		marginTop: '0 !important',
		'&.MuiStepLabel-active': {
			color: theme.palette.primary.main,
		},
		'&.MuiStepLabel-completed': {
			color: theme.palette.success.main,
		},
	},
	stepInfo: {
		fontWeight: 700,
		marginBottom: 15,
	},
	loadingPlaceholder: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	placeholderText: {
		marginTop: 10,
	},
}))

interface IconStylesProps {
	active: boolean
	completed: boolean
}

const useIconStyles = makeStyles<Theme, IconStylesProps>((theme) => ({
	icon: ({ active, completed }) => ({
		color: completed ? theme.palette.success.main : active ? theme.palette.primary.main : theme.palette.text.secondary,
	}),
}))

interface StartGroupModalProps {
	isOpen: boolean
	onClose: () => void
}

enum Steps {
	ChooseType = 'Choose type',
	Info = 'Info',
	AddMembers = 'Add members',
	Settings = 'Settings',
}

const stepIndex = Object.values(Steps).reduce((acc, v, i) => ({ ...acc, [v]: i }), {} as { [k in Steps]: number })

interface StepIconProps extends IconStylesProps {
	icon: 1 | 2 | 3 | 4
}

const StepIcon = ({ active, completed, icon }: StepIconProps) => {
	const classes = useIconStyles({ active, completed })

	const icons = {
		1: <LockOpen className={classes.icon} />,
		2: <Info className={classes.icon} />,
		3: <GroupAdd className={classes.icon} />,
		4: <Settings className={classes.icon} />,
	}

	return <div>{completed ? <Check className={classes.icon} /> : icons[icon]}</div>
}

const StartGroupModal = ({ isOpen, onClose }: StartGroupModalProps) => {
	const appTheme = useTheme()
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const isCreatingNewGroup = useSelector(getIsCreatingNewGroup)

	const [activeStep, setActiveStep] = useState(0)
	const [completed, setCompleted] = useState<{ [k: number]: boolean }>({})
	const [groupData, setGroupData] = useState<{
		groupType?: GroupChatTypes
		info?: GroupInfoData
		settings?: GroupSettingsData
		members?: UserData[]
	}>({})

	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

	const resetAll = () => {
		setActiveStep(0)
		setCompleted({})
		setGroupData({})
	}

	const handleCloseModal = () => {
		if (Object.keys(groupData).length) {
			setHasUnsavedChanges(true)
		} else {
			onClose()
		}
	}

	const handleCloseUnsavedChangesModal = () => setHasUnsavedChanges(false)
	const handleSubmitUnsavedChangesModal = () => {
		setHasUnsavedChanges(false)
		resetAll()
		onClose()
	}

	const getPrevStepIndex = (curIndex: number) => {
		const prev = curIndex - 1

		return prev >= 0 ? prev : 0
	}

	const handleNext = useCallback(() => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}, [])

	const handleComplete = useCallback(() => {
		setCompleted((prev) => ({ ...prev, [activeStep]: true }))
		handleNext()
	}, [activeStep, handleNext])

	const handleResetCompletedSteps = useCallback(() => {
		setCompleted((prev) => {
			const steps = Object.values(Steps)

			let newCompleted = { ...prev }
			steps.forEach((_, idx) => (idx >= activeStep ? (newCompleted = { ...newCompleted, [idx]: false }) : newCompleted))

			return newCompleted
		})
	}, [activeStep])

	const handleStep = (step: number) => () => setActiveStep(step)

	const handleSelectGroupType = useCallback(
		(type: GroupChatTypes) => {
			setGroupData((prev) => ({ ...prev, groupType: type }))
			handleComplete()
		},
		[handleComplete],
	)

	const handleSubmitGroupInfo = useCallback(
		(info: GroupInfoData) => {
			setGroupData((prev) => ({ ...prev, info }))
			handleComplete()
		},
		[handleComplete],
	)

	const handleSubmitGroupMembers = useCallback(
		(members: UserData[]) => {
			setGroupData((prev) => ({ ...prev, members }))
			handleComplete()
		},
		[handleComplete],
	)

	const handleSubmitGroupSettings = useCallback(
		(settings: GroupSettingsData) => {
			setGroupData((prev) => ({ ...prev, settings }))
			handleComplete()
			dispatch(createGroupRequest({ ...groupData, settings }))
		},
		[dispatch, groupData, handleComplete],
	)

	const stepComponentsByIndex = useMemo(
		() => ({
			[stepIndex[Steps.ChooseType]]: <GroupType onSelectType={handleSelectGroupType} />,
			[stepIndex[Steps.Info]]: (
				<GroupInfo
					data={groupData.info}
					onSubmit={handleSubmitGroupInfo}
					onTouch={handleResetCompletedSteps}
					submitLabel="Next"
					canEditCampus
				/>
			),
			[stepIndex[Steps.AddMembers]]: (
				<AddGroupMembers onSubmit={handleSubmitGroupMembers} onTouch={handleResetCompletedSteps} users={groupData.members} />
			),
			[stepIndex[Steps.Settings]]: (
				<GroupSettings
					groupType={groupData.groupType!}
					data={groupData.settings}
					onSubmit={handleSubmitGroupSettings}
					onTouch={handleResetCompletedSteps}
					submitLabel="Create Group"
				/>
			),
		}),
		[
			handleSelectGroupType,
			groupData.info,
			groupData.members,
			groupData.groupType,
			groupData.settings,
			handleSubmitGroupInfo,
			handleResetCompletedSteps,
			handleSubmitGroupMembers,
			handleSubmitGroupSettings,
		],
	)

	const activeStepComponent = useMemo(() => stepComponentsByIndex[activeStep], [activeStep, stepComponentsByIndex])

	useEffect(() => {
		if (!isCreatingNewGroup) resetAll()
	}, [isCreatingNewGroup])

	return (
		<>
			<ModalWithSubmittedPopup
				isOpen={hasUnsavedChanges}
				onSubmit={handleSubmitUnsavedChangesModal}
				onClose={handleCloseUnsavedChangesModal}
				title="Want to cancel?"
				content={['Your work will not be saved.']}
				submitButtonColor={appTheme.colors.red[500]}
				submitButtonLabel="Yes, cancel"
				cancelButtonLabel="No, keep editing"
				showSubmittedModal={false}
			/>
			<Modal isOpen={isOpen && !hasUnsavedChanges} onClose={isCreatingNewGroup ? undefined : handleCloseModal}>
				<CommonModalCard
					title="New Group"
					onClose={isCreatingNewGroup ? undefined : handleCloseModal}
					classnames={{
						header: classes.cardHeader,
						card: classes.rootCard,
					}}
					titleContent={
						<Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStep}>
							{Object.values(Steps).map((label, index) => (
								<Step key={label} disabled={!completed[getPrevStepIndex(index)]}>
									<StepButton onClick={handleStep(index)} completed={completed[index]}>
										<StepLabel
											classes={{
												label: classes.stepperLabel,
											}}
											StepIconComponent={StepIcon}
										>
											{label}
										</StepLabel>
									</StepButton>
								</Step>
							))}
						</Stepper>
					}
				>
					<div className={classes.rootContainer}>
						{activeStepComponent ? (
							activeStepComponent
						) : (
							<div className={classes.loadingPlaceholder}>
								<CircularProgress />
								<Typography className={classes.placeholderText}>Creating Group</Typography>
							</div>
						)}
					</div>
				</CommonModalCard>
			</Modal>
		</>
	)
}

export default StartGroupModal

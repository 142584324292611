import { STYLES } from '@/constants'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: `${theme.spacing(5)}px ${theme.spacing(7.5)}px 0px ${theme.spacing(7.5)}px`,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(3)}px`,
		},
		marginBottom: `${STYLES.MAIN_HEADER_HEIGHT}vh `, // FIXME - cleanup - why doesn't it scroll to bottom? LANDING_FOOT_HEIGHT doesn't work
	},
}))

export type ILayoutProps = {
	header?: JSX.Element
}

const Layout: React.FC<ILayoutProps> = ({ header, children }) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			{header}
			{children}
		</div>
	)
}

export { Layout }

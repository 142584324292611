import IconButton from '@/components/Buttons/IconButton'
import AboutOrganizationCard from '@/components/IndividualPage/AboutOrganizationCard'
import OrganizationContactCard from '@/components/IndividualPage/OrganizationContactCard'
import OrganizationMembershipsListCard from '@/components/IndividualPage/OrganizationMembershipsListCard'
import UsersListCard from '@/components/IndividualPage/UsersListCard'
import { STYLES } from '@/constants'
import { ORG_CENTRAL_CONTACT_CARD } from '@/constants/configuration'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { useAppDispatch } from '@/store'
import {
	getIsLoadingOrganizationData,
	getOrganizationFollowers,
	getSelectedOrganization,
	loadOrganizationFollowersRequest,
} from '@/store/organizations'
import { scrollToElement } from '@/utils/common'
import { getStringCategoriesMap } from '@/utils/events'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getShortCounterLabel } from '@navengage/sen-shared-assets'
import classNames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
	infoBlock: {
		width: 105,
		height: 91,
		borderRadius: 5,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default,
		border: `solid 1px ${theme.palette.border.bold}`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		...STYLES.BOX_SHADOW,
		'&:last-child': {
			marginRight: 0,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	infoBlockValue: {
		fontWeight: 700,
	},
	infoButton: {
		padding: 0,
		height: 93,
	},
	infoBlockActive: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.background.default,
		border: `solid 1px ${theme.palette.type === 'light' ? theme.palette.primary.light : theme.palette.divider}`,
		'&:hover': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.action.hover,
		},
	},
	smallText: {
		fontSize: 14,
	},
}))

interface ProfileTabProps {
	onClickPostsInfo: () => void
}

const ProfileTab = ({ onClickPostsInfo }: ProfileTabProps) => {
	const classes = useStyles()

	const dispatch = useAppDispatch()

	const organization = useSelector(getSelectedOrganization)
	const followers = useSelector(getOrganizationFollowers)
	const { isLoadingFollowers } = useSelector(getIsLoadingOrganizationData)

	const categories = getStringCategoriesMap(organization?.categories ?? [])

	const [showFollowers, setShowFollowers] = useState(false)

	const followersComponentId = 'org-leadership'
	const membershipComponentId = 'org-membership'

	const handleShowFollowers = () => {
		setShowFollowers(true)
		scrollToElement(followersComponentId, -100)
	}

	const handleHideFollowers = () => {
		setShowFollowers(false)
	}

	const handleShowMembers = () => {
		handleHideFollowers()
		scrollToElement(membershipComponentId, -100)
	}

	const handleLoadMoreFollowers = (orgId: string, page?: number) => {
		dispatch(
			loadOrganizationFollowersRequest({
				orgId,
				newPage: page,
			}),
		)
	}

	const organizationInfo = (
		<>
			<IconButton
				className={classNames(classes.infoButton, classes.infoBlock, showFollowers ? classes.infoBlockActive : '')}
				onClick={showFollowers ? handleHideFollowers : handleShowFollowers}
			>
				<div>
					<Typography className={classes.infoBlockValue} variant="body1">
						{getShortCounterLabel(organization?.numberOfFollowers ?? 0)}
					</Typography>
					<Typography>Followers</Typography>
				</div>
			</IconButton>
			<IconButton className={classNames(classes.infoButton, classes.infoBlock)} onClick={handleShowMembers}>
				<div>
					<Typography className={classes.infoBlockValue} variant="body1">
						{getShortCounterLabel(organization?.numberOfMembers ?? 0)}
					</Typography>
					{organization?.organizationTypeId !== 2 ? (
						<Typography>Members</Typography>
					) : (
						<Typography className={classes.smallText}>Students & Fac/Staff</Typography>
					)}
				</div>
			</IconButton>
			<IconButton className={classNames(classes.infoButton, classes.infoBlock)} onClick={onClickPostsInfo}>
				<div>
					<Typography className={classes.infoBlockValue} variant="body1">
						{getShortCounterLabel(organization?.numberOfPosts ?? 0)}
					</Typography>
					<Typography>Posts</Typography>
				</div>
			</IconButton>
		</>
	)

	if (!organization?.id) return null

	return (
		<>
			<AboutOrganizationCard categories={categories} description={organization.description} organizationStats={organizationInfo} />
			{showFollowers ? (
				<UsersListCard
					id={followersComponentId}
					title="Followers"
					orgId={organization.id}
					users={followers.list}
					numberOfFollowers={followers.total}
					onLoadMoreItems={handleLoadMoreFollowers}
					isLoading={!!isLoadingFollowers}
					canLoadMore={followers.canLoadMore}
				/>
			) : (
				<>
					{(organization.contactEmail || organization.pointOfContact) && (
						<OrganizationContactCard
							pointOfContact={organization.pointOfContact}
							contactName={organization.contactName}
							contactEmail={organization.contactEmail}
							youtubeUrl={organization.youtubeUrl}
							websiteUrl={organization.websiteUrl}
							twitterUrl={organization.twitterUrl}
							linkedinUrl={organization.linkedinUrl}
							instagramUrl={organization.instagramUrl}
							facebookUrl={organization.facebookUrl}
						/>
					)}
					{organization.dataLake && organization.contactEmail && (
						<OrganizationContactCard
							title={ORG_CENTRAL_CONTACT_CARD}
							contactName={organization.contactName}
							contactEmail={organization.contactEmail}
							youtubeUrl={organization.youtubeUrl}
							websiteUrl={organization.websiteUrl}
							twitterUrl={organization.twitterUrl}
							linkedinUrl={organization.linkedinUrl}
							instagramUrl={organization.instagramUrl}
							facebookUrl={organization.facebookUrl}
						/>
					)}
					<OrganizationMembershipsListCard
						orgId={organization.id}
						title="Leadership"
						roles={[ORGANIZATION_ROLE.SUPER_ADMIN, ORGANIZATION_ROLE.ADMIN]}
					/>
					<OrganizationMembershipsListCard
						orgId={organization.id}
						id={membershipComponentId}
						title="Membership"
						roles={[ORGANIZATION_ROLE.MEMBER]}
					/>
				</>
			)}
		</>
	)
}

export default ProfileTab

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

interface SearchInternshipsReq {
	q?: string
	page: number
	userId: number
	positionPaid?: boolean
	employer?: number
	majors?: Array<{ left: number; right: number; id: number | string }>
	minGpa?: number
	jobTypeId?: number
	classLevel?: number
	schoolId: number
	perPage?: number
}

const searchInternships = async ({ schoolId, perPage = settings.SEARCH_LIST_PER_PAGE, ...body }: SearchInternshipsReq) => {
	const url = `${schoolId}/jobs/search`

	return http.post(url, { perPage, ...body })
}

export default searchInternships

import { PATHS } from '@/constants'

import {
	Briefcase,
	CalendarBlank,
	ChartPie,
	ChatsCircle,
	GlobeHemisphereWest,
	HandHeart,
	House,
	IconProps,
	Lightbulb,
	ListChecks,
	ListMagnifyingGlass,
	ShareNetwork,
	Signpost,
	User,
	UsersThree,
} from '@phosphor-icons/react'

import { SCHOOL_KEY } from '@/constants/app'
import { isEngage } from '@/constants/configuration'
import { ICON_SIZES } from '@/constants/iconSizes'
import { Route } from '@/features/adminConsole/routes/config'
import { FEATURE, getRouterLink } from '@navengage/sen-shared-assets'
import { ReactNode } from 'react'

/**
 * Available features for the school, this should be moved to school config should be
 * initialized based on school slug or id, by default all features are enabled this fallback in
 * case we aren't able to connect split.io server
 */

const DEFAULT_PROPS: IconProps = {
	size: ICON_SIZES.lg,
	weight: 'fill',
}

export interface IFeature {
	label: string
	icon: ReactNode
	path: string
	enabled: boolean
	featureKey?: string
	external?: boolean
}

export function getFeatures({ iconProps = DEFAULT_PROPS } = {}) {
	const featureSet = {
		[FEATURE.feed]: {
			label: 'Feed',
			icon: <House {...iconProps} />,
			path: PATHS.APP.HOME,
			enabled: true,
			featureKey: FEATURE.feed,
		},
		[FEATURE.profile]: {
			label: 'Profile',
			icon: <User {...iconProps} />,
			path: PATHS.APP.PROFILE,
			enabled: true,
			featureKey: FEATURE.profile,
		},
		[FEATURE.myNetwork]: {
			label: 'My Network',
			icon: <ShareNetwork {...iconProps} />,
			path: PATHS.APP.NETWORK,
			enabled: true,
			featureKey: FEATURE.myNetwork,
		},
		[FEATURE.events]: {
			label: 'Events',
			icon: <CalendarBlank aria-label="Events" {...iconProps} />,
			path: PATHS.APP.EVENTS,
			enabled: true,
			featureKey: FEATURE.events,
		},
		[FEATURE.organizations]: {
			label: 'Organizations',
			icon: <UsersThree aria-label="Organization" {...iconProps} />,
			path: PATHS.APP.ORGANIZATIONS,
			enabled: true,
			featureKey: FEATURE.organizations,
		},
		[FEATURE.volunteers]: {
			label: 'Volunteer',
			icon: <HandHeart aria-label="Volunteer" {...iconProps} />,
			path: PATHS.APP.VOLUNTEER,
			enabled: !isEngage(),
			featureKey: FEATURE.volunteers,
		},
		[FEATURE.research]: {
			label: 'Research',
			icon: <ListMagnifyingGlass aria-label="Research" {...iconProps} />,
			path: PATHS.APP.RESEARCH,
			enabled: true,
			featureKey: FEATURE.research,
		},
		[FEATURE.studyAbroad]: {
			label: 'Education Abroad',
			icon: <GlobeHemisphereWest aria-label="Education Abroad" {...iconProps} />,
			path: PATHS.APP.EDUCATION_ABROAD,
			enabled: true,
			featureKey: FEATURE.studyAbroad,
		},
		[FEATURE.jobs]: {
			label: 'Jobs',
			icon: <Briefcase aria-label="Jobs" {...iconProps} />,
			path: PATHS.APP.INTERNSHIPS,
			enabled: true,
			featureKey: FEATURE.jobs,
		},
		[FEATURE.groupChats]: {
			label: 'Chats',
			icon: <ChatsCircle aria-label="Group Chats" {...iconProps} />,
			path: `${PATHS.APP.DISCOVER_GROUP}`,
			enabled: true,
			featureKey: FEATURE.groupChats,
		},
		[FEATURE.forms]: {
			label: 'Forms',
			icon: <ListChecks {...iconProps} />,
			enabled: isEngage(),
			path: getRouterLink({
				schoolKey: SCHOOL_KEY,
				path: FEATURE.forms,
				engage: true,
			}),
			external: true,
			featureKey: FEATURE.forms,
		},
		[FEATURE.resources]: {
			label: isEngage() ? 'Additional Resources' : 'Resources',
			icon: <Lightbulb {...iconProps} />,
			enabled: true,
			path: isEngage()
				? getRouterLink({
						schoolKey: SCHOOL_KEY,
						path: FEATURE.resources,
						engage: true,
				  })
				: 'https://sen.psu.edu/resource-hub/',
			external: true,
			featureKey: FEATURE.resources,
		},
		[FEATURE.paths]: {
			label: 'Paths',
			icon: <Signpost {...iconProps} />,
			enabled: isEngage(),
			path: getRouterLink({
				schoolKey: SCHOOL_KEY,
				path: FEATURE.paths,
				engage: true,
			}),
			external: true,
			featureKey: FEATURE.paths,
		},
		analytics: {
			label: 'Admin Dashboard',
			icon: <ChartPie {...iconProps} />,
			enabled: true,
			path: `${PATHS.ADMIN}/${Route.Home}`,
			featureKey: 'analytics',
		},
	}
	return featureSet
}

import ColorButton from '@/components/Buttons/ColorButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { UserNotification } from '@/store/auth/types'
import { makeStyles } from '@material-ui/core/styles'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import NotificationIcon from './NotificationIcon'
import NotificationSkeleton from './NotificationSkeleton'
import { NOTIFICATIONS_CONFIG, TNotificationIcon } from './config'

const useStyles = makeStyles((theme) => ({
	image: {
		minWidth: 47,
		minHeight: 47,
		width: 47,
		height: 47,
		marginRight: 15,
	},
	button: {
		width: '48%',
		marginRight: 10,
	},
}))

interface NotificationProps {
	notification: UserNotification
	compactView?: boolean
	handleClosePopup: () => void
	onViewed?: (id: number) => void
}

const notificationDotPosition = {
	width: 10,
	height: 10,
	top: -5,
	right: -5,
}

const formNotificationIcon: (viewed?: boolean, iconClass?: string, icon?: TNotificationIcon) => ReactNode = (viewed, iconClass, icon) => {
	if (icon && icon.type === 'image') {
		return (
			<LazyLoadProfileImage
				externalUserId={icon.externalUserId || ''}
				photoUrl={icon.url}
				className={iconClass}
				borderRadius={5}
				notificationDot={viewed ? undefined : notificationDotPosition}
			/>
		)
	}

	return (
		<NotificationIcon
			alt={icon && icon.alt ? icon.alt : ''}
			imageUrl={icon && icon.url ? icon.url : ''}
			className={iconClass}
			borderRadius={5}
			notificationDot={viewed ? undefined : notificationDotPosition}
		/>
	)
}

const Notification = ({ compactView = false, notification, handleClosePopup, onViewed }: NotificationProps) => {
	const classes = useStyles({ compactView })
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const {
		content,
		heading,
		createdAt,
		data: { type },
		receiver: { viewed },
	} = notification

	const { ref, inView } = useInView({ threshold: 1, triggerOnce: true })

	const currentUserId = useSelector(getUserInfo).id

	const applyClosePopup = useCallback(
		(fn: Function) => (e: any) => {
			fn()
			handleClosePopup()
		},
		[handleClosePopup],
	)

	const { icon, buttons } = useMemo(() => {
		if (NOTIFICATIONS_CONFIG[type]) {
			const config = NOTIFICATIONS_CONFIG[type](notification, dispatch, currentUserId)
			return {
				icon: formNotificationIcon(viewed, classes.image, config.icon),
				buttons: config.buttons
					? config.buttons.map(({ label, onClick, redirectTo, type, color }) => {
							const handlers = []
							if (onClick) {
								handlers.push(() => {
									onClick()
								})
							}
							if (redirectTo) {
								handlers.push(() => {
									push(redirectTo)
								})
							}

							const onClickHandler = redirectTo
								? applyClosePopup(() => {
										handlers.forEach((handler) => handler())
								  })
								: () => handlers.forEach((handler) => handler())
							if (type === 'colored') {
								return (
									<ColorButton color={color} className={classes.button} onClick={onClickHandler}>
										{label}
									</ColorButton>
								)
							} else {
								return (
									<OutlinedButton className={classes.button} onClick={onClickHandler}>
										{label}
									</OutlinedButton>
								)
							}
					  })
					: null,
			}
		}
		return {
			icon: formNotificationIcon(viewed, classes.image),
			buttons: null,
		}
	}, [applyClosePopup, classes.button, classes.image, currentUserId, dispatch, notification, push, type, viewed])

	useEffect(() => {
		if (!viewed && inView) {
			if (onViewed) onViewed(notification.id)
		}
	}, [viewed, inView, onViewed, notification.id])
	return (
		<NotificationSkeleton
			ref={ref}
			content={content}
			heading={heading}
			createdAt={createdAt}
			compactView={compactView}
			iconComponent={icon}
			actionButtons={buttons}
		/>
	)
}

export default Notification

import { Button, Typography } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'

import { DatePicker } from '@/components'
import { formatDateNew, formatDateRange } from '@/utils/dateTime2'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	card: ({ open }: any) => ({
		width: '100%',
		height: 50,
		backgroundColor: 'transparent',
		borderRadius: 5,
		border: 'none',
		color: open ? theme.palette.primary.main : theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		},
		fontSize: '16px',
	}),
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: '5%',
		alignItems: 'center',
		display: 'flex',
	},
	left: {
		width: '100%',
		alignItems: 'center',
		display: 'flex',
	},
	optionsContainer: {
		overflowY: 'auto',
		'&>.MuiPickersStaticWrapper-staticWrapperRoot': {
			minWidth: 'unset',
			'&>div': {
				'&>.MuiPickersBasePicker-pickerView': {
					minWidth: 'unset',
					maxWidth: 'unset',
				},
			},
		},
	},
	tagsContainer: {
		margin: '1vh 0',
	},
	tag: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 32,
		fontSize: '0.9em',
		margin: '0.5vh 0.5vw',
	},
	tagIcon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
}))

interface FilterDatePickerProps {
	className?: string
	value?: {
		startDate?: string
		endDate?: string
	}
	icon?: React.ReactNode
	label: string
	onChange: (v: { startDate?: string; endDate?: string }) => void
	preselectedValue?: {
		startDate?: string
		endDate?: string
	}
}

const FilterDatePicker = ({ className = '', icon = null, label, onChange = (v) => {}, value, preselectedValue }: FilterDatePickerProps) => {
	const [open, setOpen] = useState(false)

	const startDate = DateTime.fromISO(value?.startDate ?? preselectedValue?.startDate ?? new Date().toISOString()).toJSDate()
	const [date, setDate] = useState<{ startDate?: Date; endDate?: Date }>({
		startDate,
	})

	const hasValue = !!value?.startDate

	const classes = useStyles({ open, hasValue })

	const handleToggleSelect = useCallback(() => setOpen((prev) => !prev), [])

	const handleChangeDate = useCallback(
		(dates: any) => {
			const [start, end] = dates
			const newStringDates = {
				startDate: start?.toISOString(),
				endDate: end?.toISOString(),
			}

			onChange(newStringDates)
			setDate({
				startDate: start,
				endDate: end,
			})
			handleToggleSelect()
		},
		[onChange, handleToggleSelect],
	)

	const handleDelete = (e: any) => {
		e.stopPropagation()
		e.preventDefault()
		onChange({})
	}

	return (
		<div className={classes.root}>
			<Button className={classNames(classes.card, className)} onClick={handleToggleSelect}>
				<div className={classes.contentContainer}>
					<Typography className={classes.left}>
						<span className={classes.icon}>{icon}</span>
						{label}
					</Typography>
					{open ? <KeyboardArrowUpIcon fontSize="medium" /> : <KeyboardArrowDownIcon fontSize="medium" />}
				</div>
			</Button>
			{open && (
				<div className={classes.optionsContainer}>
					<DatePicker
						selected={date.startDate}
						onChange={handleChangeDate}
						startDate={date.startDate}
						endDate={date.endDate}
						selectsRange
					/>
				</div>
			)}
			{!open && hasValue && (
				<div className={classes.tagsContainer}>
					<Chip
						className={classes.tag}
						label={value.endDate ? formatDateRange(value.startDate!, value.endDate!) : formatDateNew(value.startDate!)}
						onDelete={handleDelete}
						deleteIcon={<CancelIcon className={classes.tagIcon} />}
						variant="outlined"
					/>
				</div>
			)}
		</div>
	)
}

export default FilterDatePicker

import { SocketEventTypes, useSocketEventTypeListener } from '@/hooks/useSocket'
import { useCurrentUser } from '@/hooks/userHooks'
import { UserInfo } from '@/interfaces/user'
import { useAppDispatch } from '@/store'
import { camelizeKeys } from 'humps'
import { useCallback } from 'react'
import { changeCommentsCount } from '../../feed/slice'
import { CreatePayloadData } from '../../hooks/useRealtimeFeed'
import { EmitKeys } from '../../types/socket'
import { commentLiked, deleteById, onCommentReceived } from '../slice'
import { DtoComment } from '../types/DtoComment'

type LikePayload = {
	userId: UserInfo['id']
	comment: DtoComment
}

type SocketData = CreatePayloadData<Exclude<EmitKeys, 'like'>, DtoComment> | CreatePayloadData<'like', LikePayload>

const useRealtimeComments = () => {
	const dispatch = useAppDispatch()
	const currentUser = useCurrentUser()
	const handleComments = useCallback(
		({ payload, type }: SocketData) => {
			const comment = camelizeKeys(type === 'like' ? payload.comment : payload) as DtoComment
			switch (type) {
				case 'created':
					dispatch(onCommentReceived([comment]))
					dispatch(changeCommentsCount({ postId: comment.postId, count: 1, type: 'increment' }))
					break
				case 'deleted':
					dispatch(deleteById(comment.id))
					dispatch(changeCommentsCount({ postId: comment.postId, count: 1, type: 'decrement' }))
					break
				case 'like':
					dispatch(commentLiked({ userId: payload.userId, comment, currentUserId: currentUser.id }))
					break
				// @TODO: implemented editing comment at some point
				//case 'updated'
			}
		},
		[currentUser.id, dispatch],
	)
	useSocketEventTypeListener(SocketEventTypes.COMMENT, handleComments)
}

export default useRealtimeComments

import { makeStyles } from '@material-ui/core/styles'

import { StepperDotIcon } from '@/components'

const useStyles = makeStyles((theme) => ({
	root: {
		margin: '0 4px',
	},
	icon: {
		width: '0.5vw',
		height: '0.5vw',
		minWidth: 13,
		minHeight: 13,
	},
}))

const StepperDot = ({ isActive = false, color }: { isActive: boolean; color?: string }) => {
	const classes = useStyles()

	return (
		<span className={classes.root}>
			<StepperDotIcon isActive={isActive} color={color} className={classes.icon} />
		</span>
	)
}

export default StepperDot

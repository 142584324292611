import UserProfile, { ProfileTabs } from '@/components/UserProfile'
import { PATHS, ROLE } from '@/constants'
import {
	getProfileTab,
	getUserActivityState,
	getUserInfo,
	getUserInfoRequest,
	getUserRole,
	loadUserActivityRequest,
	selectUserGoalResponses,
	setProfileTabToOpen,
} from '@/store/auth'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router'

const allTabs = [ProfileTabs.GOALS, ProfileTabs.PROFILE, ProfileTabs.ACTIVITY]

const publicTabs = [ProfileTabs.PROFILE, ProfileTabs.ACTIVITY]

const UserProfilePage = () => {
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const userInfo = useSelector(getUserInfo)
	const profileTabToOpen = useSelector(getProfileTab)
	const userGoalResponses = useSelector(selectUserGoalResponses)
	const userRole = useSelector(getUserRole)
	const userActivity = useSelector(getUserActivityState)

	const tabsToDisplay = userRole === ROLE.FACULTY || userRole === ROLE.STAFF ? publicTabs : allTabs

	const [tabs, setTabs] = useState(tabsToDisplay)
	const [defaultTab] = useState(profileTabToOpen)

	const handleChangeProfileView = (isPublicView: boolean) => setTabs(isPublicView ? publicTabs : tabsToDisplay)
	const handleLoadMoreUserActivity = useCallback(() => dispatch(loadUserActivityRequest({})), [dispatch])

	const handleClickNumberOfConnections = () => push(PATHS.APP.NETWORK_MY)

	useEffect(() => {
		dispatch(getUserInfoRequest())
		dispatch(setProfileTabToOpen(0))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (userInfo.id) {
			dispatch(loadUserActivityRequest({ resetPage: true }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.id])

	return (
		<UserProfile
			tabs={tabs}
			userInfo={userInfo}
			handleClickNumberOfConnections={handleClickNumberOfConnections}
			onToggleProfileView={handleChangeProfileView}
			userGoalResponses={userGoalResponses}
			defaultTabIndex={defaultTab}
			activity={userActivity}
			handleLoadMoreUserActivity={handleLoadMoreUserActivity}
		/>
	)
}

export default UserProfilePage

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'

interface PatchUserNewTermsAnswerParams {
	userId: UserInfo['id']
	termsId: number
	schoolId: number
}

const patchUserNewTermsAnswer = async ({ schoolId, termsId, userId }: PatchUserNewTermsAnswerParams) => {
	const url = `${schoolId}/terms/user/${userId}`

	return http.post(url, {
		termsId,
	})
}

export default patchUserNewTermsAnswer

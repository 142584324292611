import ColorButton from '@/components/Buttons/ColorButton'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.75rem',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '20rem',
		marginBottom: theme.spacing(10),
	},
	text: {
		fontSize: '1.2rem',
		fontWeight: 700,
	},
}))

const EmptyList = ({ onScheduleClick }) => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Typography className={classes.text}>No scheduled posts.</Typography>
			<ColorButton onClick={onScheduleClick}>Schedule</ColorButton>
		</div>
	)
}

export default EmptyList

import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { Compass } from '@phosphor-icons/react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IconButton, ResearchOutlinedIcon } from '@/components'
import Filter from '@/components/Filter'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import { TERMS_OF_AVAILABILITY } from '@/constants/filter'
import { ICON_SIZES } from '@/constants/iconSizes'
import { getActiveCampuses } from '@/features/campus/slice'
import { useGetResearchFiltersQuery } from '@/features/research/api'
import { useGetEntityResponseCountQuery } from '@/features/responses/api'
import useEqualToPath from '@/hooks/useEqualToPath'
import { Filters, SearchConfig } from '@/interfaces/common'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import { clearFilters, getSearchConfig, loadResearchListWithFilterRequest } from '@/store/research'
import { requestToAddResearch } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	addResearchButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
}))

const ResearchPage = () => {
	const classes = useStyles()

	const { pathname } = useLocation()
	const dispatch = useAppDispatch()

	const searchConfig = useSelector(getSearchConfig)
	const campuses = useSelector(getActiveCampuses)

	const { data: count } = useGetEntityResponseCountQuery({ entityType: 'research' })
	const { data: filterData, isLoading: filterIsLoading } = useGetResearchFiltersQuery()
	const canUseFilter = useEqualToPath(PATHS.APP.RESEARCH)

	const handleClearFilters = () => {
		dispatch(clearFilters())
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			if (canUseFilter) {
				dispatch(loadResearchListWithFilterRequest(config))
				window.scrollTo({ top: 0 })
			}
		},
		[dispatch, canUseFilter],
	)

	const filterNavigation = useMemo(
		() => [
			{
				label: 'Discover Research',
				icon: <Compass size={ICON_SIZES.md} />,
				path: PATHS.APP.RESEARCH,
			},
			{
				label: 'My Research',
				icon: <ResearchOutlinedIcon fontSize="medium" />,
				path: PATHS.APP.RESEARCH_MY,
				info: [`${count?.total ?? ''} Interested`],
			},
		],
		[count],
	)

	return (
		<div className={classes.root}>
			<Filter
				isLoading={filterIsLoading}
				title="Research"
				searchPlaceholder="Search research"
				value={searchConfig}
				filters={[Filters.campuses, Filters.positionAreas, Filters.positionTypes, Filters.workSettings, Filters.terms]}
				filterOptions={{
					campuses,
					...filterData,
					terms: TERMS_OF_AVAILABILITY,
				}}
				canUseFilter={pathname === PATHS.APP.RESEARCH}
				onFilterChange={fetchSearchAndFilter}
				clearFilters={handleClearFilters}
				filterNavigation={filterNavigation}
				additionalElementsRenderer={() => (
					<IconButton className={classes.addResearchButton} startIcon={<Add />} onClick={requestToAddResearch}>
						Add Research
					</IconButton>
				)}
			/>
			<Router routes={ROUTES.research} />
		</div>
	)
}

export default ResearchPage

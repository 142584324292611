import { Dialog, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { CommonModalCard, IconButton } from '@/components'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	acceptButton: {
		color: theme.palette.primary.main,
	},
}))

export type IAlertActionProps = {
	isOpen: boolean
	handleClose: () => void
	buttons: Array<{ label: string; onClick: () => void; className?: string }>
	title?: string
	subTitle?: string
	content?: ReactNode
}

const AlertAction: React.FC<IAlertActionProps> = ({ isOpen, handleClose, buttons, title, subTitle, content }) => {
	const classes = useStyles()
	return (
		<Dialog open={isOpen} onClose={handleClose} className={classes.modal}>
			<CommonModalCard
				title={title}
				subTitle={subTitle}
				onClose={handleClose}
				classnames={{
					header: classes.cardHeader,
				}}
			>
				<>
					{content}
					{buttons.map((btn) => (
						<IconButton key={btn.label} className={classNames(classes.itemPopupButton, btn.className)} onClick={btn.onClick}>
							{btn.label}
						</IconButton>
					))}
				</>
			</CommonModalCard>
		</Dialog>
	)
}

export { AlertAction }

import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AddToCalendarOutlinedIcon, ColorButton, ShareButton, LocationOutlinedIcon, MoreButton, PersonOutlinedIcon } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import CancelShiftsModal from '@/components/IndividualPage/CancelShiftsModal'
import Card from '@/components/IndividualPage/Card'
import DateItemAboutRow from '@/components/IndividualPage/DateItemAboutRow'
import ItemAboutRow from '@/components/IndividualPage/ItemAboutRow'
import Popover from '@/components/Popover'
import { PATHS } from '@/constants'
import { SharedData, UserResponseStatuses } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { openAddToCalendarModal } from '@/store/events'
import { Organization } from '@/store/organizations/types'
import { getEventResponseLabel } from '@/utils/common'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		margin: '1vh 0 3vh 0',
	},
	icon: {
		height: '10%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 5,
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px',
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	addToCalendarBtn: {
		minWidth: 50,
		width: 50,
		height: 50,
		borderRadius: 7,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	feedbackButton: {
		minWidth: 50,
		height: 50,
	},
	responseBtn: {
		height: 50,
		width: '48%',
	},
	responseBtnFullWidth: {
		height: 50,
		width: '100%',
	},
	rsvpBtnContainer: {
		width: '60%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
	locationInfoTitle: {
		fontWeight: 700,
	},
	locationInfo: {
		maxWidth: 390,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2vh 3vw',
		border: `solid 1px ${theme.palette.divider}`,
		borderRadius: '0 7px 7px 7px',
		backgroundColor: theme.palette.background.paper,
	},
	locationInfoDivider: {
		width: '70%',
		borderBottom: `solid 1px ${theme.palette.divider}`,
		margin: '2vh 0',
	},
}))

interface EventTitleCardProps {
	name: string
	description: string
	startDate: string
	endDate: string
	locationName?: string
	streetAddress?: string
	organizationId?: string
	organizations?: Organization[] | null
	response: UserResponseStatuses
	handleChangeEventResponse: (status: UserResponseStatuses) => void
	isEditShifts: boolean
	userSubmittedShifts: number
	handleEditShifts: () => void
	userId: number
	shifts: any[]
	eventId: string
	sharedData?: SharedData
	pastEvent: boolean
}

const EventTitleCard = ({
	name,
	description,
	startDate,
	endDate,
	locationName,
	streetAddress,
	organizations,
	response,
	handleChangeEventResponse,
	isEditShifts,
	userSubmittedShifts,
	handleEditShifts,
	userId,
	shifts,
	eventId,
	sharedData = {},

	pastEvent,
}: EventTitleCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const { push } = useHistory()

	const [locationAnchorEl, setLocationAnchorEl] = useState<any>(null)
	const [isOpenCancelShiftsModal, setIsOpenCancelShiftsModal] = useState(false)

	const hasResponse = response === UserResponseStatuses.interested || response === UserResponseStatuses.going

	const sharingUrl = PATHS.APP.EVENTS_SINGLE(eventId)

	const handleToggleCancelShiftsModal = useCallback(() => {
		setIsOpenCancelShiftsModal((prev) => !prev)
	}, [])

	const handleOpenAddToCalendarModal = useCallback(() => {
		dispatch(
			openAddToCalendarModal({
				isOpen: true,
				name,
				startDate,
				endDate,
				description,
			}),
		)
	}, [description, dispatch, endDate, name, startDate])

	const handleOpenLocation = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setLocationAnchorEl(event.currentTarget)
	}
	const handleCloseLocation = () => {
		setLocationAnchorEl(null)
	}

	const handleOpenOrganizationPage = useCallback((organizationId) => () => push(PATHS.APP.ORGANIZATIONS_SINGLE(organizationId)), [push])

	const setGoingResponse = useCallback(() => handleChangeEventResponse(UserResponseStatuses.going), [handleChangeEventResponse])
	const setInterestedResponse = useCallback(() => handleChangeEventResponse(UserResponseStatuses.interested), [handleChangeEventResponse])

	const getActiveButtonStyle = useCallback(
		(res: UserResponseStatuses) => (res === response ? classes.menuActiveButton : ''),
		[classes.menuActiveButton, response],
	)

	const rsvpButtons = useMemo(
		() =>
			[UserResponseStatuses.going, UserResponseStatuses.interested, UserResponseStatuses.notInterested].map((res) => ({
				label: getEventResponseLabel(res),
				onClick: () => handleChangeEventResponse(res),
				className: getActiveButtonStyle(res),
			})),
		[getActiveButtonStyle, handleChangeEventResponse],
	)

	const shiftsButtons = useMemo(
		() => [
			{
				label: 'Edit Shift',
				onClick: handleEditShifts,
			},
			{
				label: 'Cancel Shifts',
				onClick: handleToggleCancelShiftsModal,
			},
		],
		[handleToggleCancelShiftsModal, handleEditShifts],
	)

	return (
		<Card>
			<CancelShiftsModal
				eventId={eventId}
				isOpenCancelShiftsModal={isOpenCancelShiftsModal}
				handleToggleCancelShiftsModal={handleToggleCancelShiftsModal}
				userId={userId}
				shifts={shifts}
			/>
			<Popover
				anchorEl={locationAnchorEl}
				handleClose={handleCloseLocation}
				classnames={{
					container: classes.locationInfo,
				}}
				boxShadow={false}
			>
				<Typography className={classes.locationInfoTitle}>{locationName}</Typography>
				<div className={classes.locationInfoDivider} />
				<Typography>{streetAddress}</Typography>
			</Popover>
			<Typography className={classes.title} variant="h2">
				{name}
			</Typography>
			<div className={classes.infoContainer}>
				<div>
					<DateItemAboutRow startDate={startDate} endDate={endDate} />
					{locationName && <ItemAboutRow icon={<LocationOutlinedIcon />} label={locationName} onClick={handleOpenLocation} />}
					{organizations &&
						organizations.map((organization) => (
							<ItemAboutRow
								key={organization.id}
								icon={<PersonOutlinedIcon />}
								label={organization.name}
								onClick={handleOpenOrganizationPage(organization.id)}
							/>
						))}
				</div>
				<div>
					<Button className={classes.addToCalendarBtn} variant="contained" onClick={handleOpenAddToCalendarModal}>
						<AddToCalendarOutlinedIcon />
					</Button>
				</div>
			</div>
			{!isEditShifts && (
				<div className={classes.buttonsContainer}>
					<div className={classes.rsvpBtnContainer}>
						{!hasResponse && !pastEvent && !userSubmittedShifts && (
							<>
								<ColorButton className={classes.responseBtn} color={ButtonColors.PRIMARY} onClick={setGoingResponse}>
									Going
								</ColorButton>
								<ColorButton className={classes.responseBtn} color={ButtonColors.GREY} onClick={setInterestedResponse}>
									Interested
								</ColorButton>
							</>
						)}
						{hasResponse && !pastEvent && !userSubmittedShifts && (
							<MoreButton className={classes.responseBtnFullWidth} renderButtons={rsvpButtons}>
								{getEventResponseLabel(response)}
							</MoreButton>
						)}
						{!pastEvent && !!userSubmittedShifts && (
							<MoreButton className={classes.responseBtnFullWidth} renderButtons={shiftsButtons}>
								{`Signed Up | ${userSubmittedShifts} Shift${userSubmittedShifts !== 1 ? 's' : ''}`}
							</MoreButton>
						)}
						{pastEvent && (
							<ColorButton className={classes.responseBtnFullWidth} color={ButtonColors.GREY} disabled>
								Past Event
							</ColorButton>
						)}
					</div>
					<ShareButton className={classes.feedbackButton} sharedData={sharedData} sharingUrl={sharingUrl} />
				</div>
			)}
		</Card>
	)
}

export default EventTitleCard

import * as http from '@/api/http'
import { getNumberOfUnViewedMessages } from '@/api/http/messages/getUnViewedCount'
import { PATHS } from '@/constants'
import settings from '@/constants/http'
import { PhotoUploadDirectories } from '@/constants/uploads'
import { ConnectivityStatus, SharedData, SharedDataTypes, UserData } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/messages/constants'
import { Chat, Message, MessageData, NewMessageContent, TReactions, TUpdateViewStatusData } from '@/store/messages/types'
import { isCurrentUser } from '@/utils/authHandlers'

import { campusSelectors } from '@/features/campus/slice'
import { TCampus } from '@/features/campus/types/TCampus'
import { fromRoomId } from '@/utils/messages'
import uploadPhoto from '@/utils/photoUpload'
import { getNormalizeUser } from '@/utils/transformers'
import { Dictionary, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { selectAuth } from '../auth'
import { MinimalGroupChatInfo } from '../groups/types'
import { MakeOptional } from '../types'
import { removeMessageById } from '@/api/http/messages'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)
export const setError = createAction<any>(`${MODULE_NAME}/SET_ERROR`)

export const setMessages = createAction<{
	list: Message[]
	canLoadMore: boolean
}>(`${MODULE_NAME}/SET_MESSAGES`)
export const clearMessages = createAction(`${MODULE_NAME}/CLEAR_MESSAGES`)
export const addMessage = createAction<Message>(`${MODULE_NAME}/ADD_MESSAGE`)
export const deleteMessage = createAction<Message>(`${MODULE_NAME}/DELETE_MESSAGE`)

export const setSearchUsers = createAction<{
	page: number
	canLoadMore: boolean
	users: UserData[]
}>(`${MODULE_NAME}/SET_SEARCH_USERS`)
export const clearSearchUsers = createAction(`${MODULE_NAME}/CLEAR_SEARCH_USERS`)
export const setSearchUsersQuery = createAction<string>(`${MODULE_NAME}/SET_SEARCH_USERS_QUERY`)
export const setNewMessageContent = createAction<NewMessageContent>(`${MODULE_NAME}/SET_NEW_MESSAGE_CONTENT`)
export const removeNewMessageSharedItems = createAction(`${MODULE_NAME}/REMOVE_NEW_MESSAGE_SHARED_ITEMS`)
export const clearNewMessageContent = createAction(`${MODULE_NAME}/CLEAR_NEW_MESSAGE_CONTENT`)
export const markAllRead = createAction<{ targetUserId?: number; groupId?: string }>(`${MODULE_NAME}/MARK_ALL_READ`)

export const setChatsList = createAction<{
	canLoadMore: boolean
	list: Chat[]
}>(`${MODULE_NAME}/SET_CHATS_LIST`)
export const updateChatsList = createAction<Chat>(`${MODULE_NAME}/UPDATE_CHATS_LIST`)
export const setMessageViewStatus = createAction<{
	message: { id: string; viewed: boolean }
	list: Chat[]
	numberOfUnViewed: number
}>(`${MODULE_NAME}/SET_MESSAGE_VIEW_STATUS`)

export const setNumberOfUnViewedMessages = createAction<number>(`${MODULE_NAME}/SET_NUMBER_OF_UNVIEWED_MESSAGES`)
export const updateCurrentUserMessageReactions = createAction<{
	messageId: string
	type: TReactions
	isCurrentUser: boolean
	updateType: 'add' | 'remove'
}>(`${MODULE_NAME}/SET_CURRENT_USER_MESSAGE_REACTIONS`)
export const setTargetUser = createAction<UserData>(`${MODULE_NAME}/SET_TARGET_USER`)
export const clearTargetUser = createAction(`${MODULE_NAME}/CLEAR_TARGET_USER`)
export const setShowSelectUserInput = createAction<boolean>(`${MODULE_NAME}/SET_SHOW_SELECT_USER_INPUT`)
export const setShareModal = createAction<{
	isOpen: boolean
	data?: SharedData
	type?: SharedDataTypes
}>(`${MODULE_NAME}/SET_SHARE_MODAL`)
export const clearShareModal = createAction(`${MODULE_NAME}/CLEAR_SHARE_MODAL`)

const parseMessage = (data: any, campuses?: Dictionary<TCampus>) => {
	let updatedData: Message = {
		...data,
	}

	if (updatedData.sourceUser) {
		updatedData.sourceUser = getNormalizeUser(updatedData.sourceUser, campuses)
	}

	if (updatedData.targetUser) {
		updatedData.targetUser = getNormalizeUser(updatedData.targetUser, campuses)
	}

	return updatedData
}

const parseUserChatsList = (
	data: { message: any; user: any; group: MinimalGroupChatInfo | null; unviewed: number }[],
	campuses?: Dictionary<TCampus>,
) =>
	data.map(({ message, user, group, unviewed }) => {
		const parsedMessage = parseMessage(message, campuses)
		return {
			user: user ? getNormalizeUser(user, campuses) : null,
			message: parsedMessage,
			group,
			unviewed: +unviewed,
			roomId: group?.id ?? fromRoomId(parsedMessage.sourceUserId, parsedMessage.targetUserId),
		}
	})

export const loadMessagesListRequest = createAsyncThunk<
	any,
	{
		userId?: number
		groupId?: string
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_MESSAGES_REQUEST`, async ({ userId, groupId, fetchMore }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId: currentUserId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(rootState)
		const storedMessages = rootState.MESSAGES.messages.list

		const offset = fetchMore ? storedMessages.length : 0
		const page = fetchMore ? Math.floor(storedMessages.length / settings.SEARCH_MESSAGES_PER_PAGE) + 1 : 1

		let data: any[] = []

		if (groupId) {
			const {
				data: { messages },
			} = await http.groups.getGroupMessages({ id: groupId, page, direction: 'desc', schoolId })

			data = messages
		} else {
			const { data: messages } = await http.messages.getMessages({
				userId: currentUserId,
				targetUserId: userId!,
				offset,
				direction: 'DESC',
				schoolId,
			})

			data = messages
		}

		const parsedList: Message[] = data.map((message: any) => parseMessage(message, campusMap))
		const list = fetchMore ? storedMessages.concat(parsedList) : parsedList

		dispatch(
			setMessages({
				list,
				canLoadMore: parsedList.length >= settings.SEARCH_MESSAGES_PER_PAGE,
			}),
		)
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const searchUserRequest = createAsyncThunk<
	any,
	{
		fetchMore?: boolean
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SEARCH_USER`, async ({ fetchMore }, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(rootState)

		const storedUsers = rootState.MESSAGES.searchUsers.users
		const currentPage = rootState.MESSAGES.searchUsers.page

		const searchQuery = rootState.MESSAGES.searchUsers.q
		const page = fetchMore ? currentPage + 1 : 1

		const { data } = await http.network.searchNetwork({
			q: searchQuery,
			page,
			userId,
			campusId: undefined,
			connectivityStatus: ConnectivityStatus.ACCEPTED,
			schoolId,
		})

		const newUsers = data.map((user: any) => getNormalizeUser(user, campusMap))

		const users = fetchMore ? storedUsers.concat(newUsers) : newUsers

		dispatch(setSearchUsers({ page, canLoadMore: !!newUsers.length, users }))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const loadChatsListRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_CHATS_LIST_REQUEST`, async (_undefiend, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(rootState)

		const { data } = await http.messages.getChatsList(userId, schoolId)

		const list = parseUserChatsList(data, campusMap)

		dispatch(
			setChatsList({
				canLoadMore: !!list.length,
				list,
			}),
		)
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const newMessageReceived = createAsyncThunk<
	any,
	Message,
	{
		state: RootState
	}
>(`${MODULE_NAME}/NEW_MESSAGE_RECEIVED`, async (newMessage, { dispatch, getState, rejectWithValue }) => {
	try {
		const state = getState()

		const {
			userInfo: { id },
		} = state.AUTH
		const campusMap = campusSelectors.selectEntities(state)
		const {
			targetUser,
			chats: { numberOfUnViewed, list },
		} = state.MESSAGES
		const targetGroup = state.GROUPS.targetGroup

		const parsedMessage = parseMessage(newMessage, campusMap)

		const roomId = parsedMessage.groupId ?? fromRoomId(parsedMessage.sourceUserId, parsedMessage.targetUserId)
		const messageChatRoom = list.find((chat) => chat.roomId === roomId)

		const isMyMessage = isCurrentUser(id, parsedMessage.sourceUser?.id)

		const user = parsedMessage?.groupId ? null : isMyMessage ? parsedMessage.targetUser! : parsedMessage.sourceUser!

		const messageListData: Chat = {
			user,
			message: parsedMessage,
			group: parsedMessage?.sourceGroup ?? null,

			unviewed: messageChatRoom ? messageChatRoom.unviewed : 0,
			roomId,
		}
		if (isMyMessage) {
			parsedMessage['viewed'] = true
		} else {
			parsedMessage['viewed'] = false
			messageListData.unviewed += 1

			dispatch(setNumberOfUnViewedMessages(numberOfUnViewed + 1))
		}

		dispatch(updateChatsList(messageListData))

		if (
			(targetUser && targetUser.id === parsedMessage.sourceUserId && !parsedMessage.groupId) ||
			(targetGroup && targetGroup.id === parsedMessage.groupId)
		) {
			dispatch(addMessage(parsedMessage))
		}
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const updateMessageViewStatus = createAsyncThunk<
	any,
	TUpdateViewStatusData,
	{
		state: RootState
	}
>(`${MODULE_NAME}/UPDATE_MESSAGE_STATUS`, async (message, { getState, dispatch, rejectWithValue }) => {
	try {
		const state = getState()
		const { id } = state.AUTH.userInfo
		const {
			chats: { list, numberOfUnViewed },
		} = state.MESSAGES

		const roomId = message.groupId ?? fromRoomId(message.sourceUserId, message.targetUserId)
		// updating viewed for current user
		if (message.userId === id) {
			const updatedList = list.map((data) =>
				data.roomId === roomId ? { ...data, unviewed: data.unviewed > 0 ? data.unviewed - 1 : 0 } : data,
			)

			dispatch(
				setMessageViewStatus({
					message: {
						id: message.id,
						viewed: true,
					},
					list: updatedList,
					numberOfUnViewed: numberOfUnViewed > 0 ? numberOfUnViewed - 1 : 0,
				}),
			)
		}
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const deleteMessageRequest = createAsyncThunk<
	any,
	Message,
	{
		state: RootState
	}
>(`${MODULE_NAME}/DELETE_MESSAGE_REQUEST`, async (message, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()

		const { schoolId } = selectAuth(rootState)
		await removeMessageById({ id: message.id, schoolId })
		dispatch(deleteMessage(message))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const createMessageRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_MESSAGE_REQUEST`, async (_undefiend, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()

		const { schoolId, userId: id } = selectAuth(rootState)

		const targetUserId = rootState.MESSAGES.targetUser?.id
		const targetGroup = rootState.GROUPS.targetGroup
		const groupId = rootState.GROUPS.targetGroup?.id

		const { message, photoUrl, linkPreviewUrl, sharedEntity } = rootState.MESSAGES.newMessageContent

		const imageName = uuidv4()

		const roomId = groupId ?? fromRoomId(id, targetUserId)

		const messageData: MakeOptional<MessageData, 'id'> = {
			sourceUserId: id,
			targetUserId,
			groupId,
			message,
			photoUrl,
			sharedEntityId: sharedEntity?.sharedEntityId,
			sharedEntityType: sharedEntity?.sharedEntityType,
			linkPreviewUrl,
		}

		if (photoUrl) {
			const {
				data: { slug },
			} = await uploadPhoto({
				schoolId,
				fileUrl: photoUrl,
				imageName,
				directory: PhotoUploadDirectories.MESSAGE,
				isPrivateImage: true,
				options: {
					compressImage: true,
				},
			})

			messageData.photoUrl = slug
		}
		dispatch(clearNewMessageContent())
		const {
			data: { id: messageId },
		} = await http.messages.createMessage(id, messageData, schoolId)

		messageData.id = messageId

		const storeMessage: Message = cloneDeep({
			id: messageId,
			message,
			photoUrl: undefined,
			linkPreviewUrl,
			sharedEntityId: sharedEntity?.sharedEntityId,
			sharedEntityType: sharedEntity?.sharedEntityType,
			sourceUser: rootState.AUTH.userInfo,
			targetUser: rootState.MESSAGES.targetUser,
			sourceUserId: rootState.AUTH.userInfo.id,
			targetUserId: rootState.MESSAGES.targetUser?.id,

			viewed: true,
			createdAt: new Date().toISOString(),
		})
		const isMyMessage = isCurrentUser(id, storeMessage.sourceUser?.id)

		const user = isMyMessage ? storeMessage.targetUser! : storeMessage.sourceUser!
		const messageChatRoom = rootState.MESSAGES.chats.list.find((chat) => chat.roomId === roomId)
		const messageListData = {
			user,
			message: storeMessage,
			group: targetGroup
				? {
						id: targetGroup.id,
						name: targetGroup.name,
						thumbnail: targetGroup.thumbnail,
				  }
				: null,
			unviewed: messageChatRoom ? messageChatRoom.unviewed : 0,
			roomId,
		}

		// Local state update
		dispatch(updateChatsList(messageListData))
		dispatch(addMessage(storeMessage))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const clearAddNewUserToMessagesListInput = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CLEAR_ADD_NEW_USER_TO_LIST_INPUT`, async (_undefiend, { dispatch, rejectWithValue }) => {
	try {
		dispatch(clearTargetUser())
		dispatch(clearSearchUsers())
		dispatch(clearNewMessageContent())
		dispatch(push(PATHS.APP.MESSAGES))
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const readUserDialogueRequest = createAsyncThunk<
	any,
	number,
	{
		state: RootState
	}
>(`${MODULE_NAME}/READ_USER_DIALOGUE_REQUEST`, async (targetUserId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const campusMap = campusSelectors.selectEntities(rootState)
		dispatch(clearTargetUser())

		const {
			data: { user },
		} = await http.network.getUserInfo({ userId, targetUserId, schoolId })

		if (user.connectionStatus === ConnectivityStatus.ACCEPTED) {
			dispatch(setTargetUser(getNormalizeUser(user, campusMap)))
		} else {
			dispatch(clearAddNewUserToMessagesListInput())
		}
	} catch (e: any) {
		console.error(e)
		dispatch(setError(e.message.message))
		return rejectWithValue(e)
	}
})

export const initUnViewedCountRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/INIT_UN_VIEWED_COUNT_REQUEST`, async (__, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const {
			data: { total },
		} = await getNumberOfUnViewedMessages(userId, schoolId)
		dispatch(setNumberOfUnViewedMessages(total))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { FilterOptionsKeys, Filters } from '@/interfaces/common'

export type DefaultFilters = {
	[key in FilterOptionsKeys]: number | number[] | null | undefined
}

export const defaultFilters: DefaultFilters = {
	[Filters.campuses]: undefined,
	[Filters.categories]: [],
	[Filters.branches]: [],
	[Filters.date]: undefined,
	[Filters.employers]: undefined,
	[Filters.locations]: [],
	[Filters.positionAreas]: [],
	[Filters.disciplines]: undefined,
	[Filters.positionTypes]: [],
	[Filters.majors]: [],
	[Filters.workSettings]: [],
	[Filters.terms]: [],
	[Filters.housingTypes]: [],
	[Filters.scholarshipsOffered]: undefined,
	[Filters.classLevels]: [],
	[Filters.minimumGPA]: 0,
	[Filters.paid]: undefined,
	[Filters.country]: [],
	[Filters.connectivityStatus]: undefined,
	[Filters.userTypeId]: [],
}

export const allCampusesOption = {
	label: 'All Campuses',
	value: null,
}

export const TERMS_OF_AVAILABILITY = [
	{ label: 'Academic Year', value: 2 },
	{ label: 'Fall', value: 1 },
	{ label: 'Spring', value: 3 },
	{ label: 'Summer', value: 4 },
]

import getLatestTermsAndConditions from './getLatestTermsAndConditions'
import getLatestPrivacyPolicy from './getLatestPrivacyPolicy'
import patchUserNewTermsAnswer from './patchUserNewTermsAnswer'

const terms = {
	getLatestTermsAndConditions,
	patchUserNewTermsAnswer,
	getLatestPrivacyPolicy,
}

export default terms

import { STYLES } from '@/constants'
import { UiState } from '@/features/posts/types/UiStates'
import { flexStyles } from '@/styles/flexStyles'
import { marginStyles } from '@/styles/marginStyles'
import { Theme, alpha, makeStyles } from '@material-ui/core'

const getUiStateBackground = (theme: Theme): Record<UiState, string> => {
	return {
		created: alpha(theme.colors.Success[500], 0.5),
		updated: theme.palette.primary.light,
		deleted: alpha(theme.colors.Error[500], 0.5),
	}
}

export const useStyles = makeStyles<Theme, { showAsPreview?: boolean; clickable?: boolean; uiState?: UiState }>((theme) => ({
	...flexStyles,
	root: ({ showAsPreview, uiState }) => ({
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.paper,
		border: showAsPreview ? `solid 1px ${theme.palette.border.bold}` : 'none',
		borderRadius: showAsPreview ? 5 : 0,
		borderBottom: `solid 1px ${theme.palette.border.bold}`,
	}),
	contentContainer: ({ clickable, showAsPreview, uiState }) => ({
		cursor: clickable ? 'pointer' : 'default',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: uiState
			? getUiStateBackground(theme)[uiState]
			: clickable
			? theme.palette.background.paper
			: theme.palette.specialColors.transparent,
		borderRadius: showAsPreview ? 5 : 0,
		padding: '1.5vw 0',

		'&:hover': {
			backgroundColor: clickable
				? theme.palette.type === 'light'
					? theme.palette.neutral.light
					: theme.palette.background.default
				: theme.palette.specialColors.transparent,
		},
	}),
	footer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 1.5vw',
	},
	footerLeftBlock: {
		width: '50%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	footerRightBlock: {
		...flexStyles.flexCenteredItemsY,
		width: '50%',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	content: {
		margin: '10px 1.5vw',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	postInfo: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		margin: '5px 1.5vw',
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 1.5vw',
	},
	userName: {
		...theme.typography.body1,
		fontWeight: 700,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	userInfoRow: {
		margin: 0,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	headerButtons: {
		height: 'fit-content',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	sharedObject: {
		width: '100%',
		margin: '20px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	placeholder: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2vh 4vw',
	},
	sharedPhoto: {
		width: '100%',
		objectFit: 'cover',
		position: 'relative',
		aspectRatio: '1 / 1',
		// inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
	},
	sharedPhotoContainer: {
		cursor: 'pointer',
		width: '100%',
		aspectRatio: '1 / 1',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'blue',
		alignItems: 'center',
		position: 'relative',
	},
	previewLinkContainer: {
		margin: '20px 0',
	},
	postText: {
		'&>p': {
			margin: '2px 0',
		},
		...theme.typography.body1,
	},
	metaLine: {
		...flexStyles.flexCenteredItemsY,
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
	},
	numViewsText: { ...marginStyles.marginLeft5 },

	adminBadge: {
		margin: '10px 10px 10px 0px',
	},
}))

import ModalWithSubmittedPopup from '@/anthology/components/ModalWithSubmittedPopup'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { useAppDispatch } from '@/store'
import { createInfoRequest } from '@/store/organizations'
import { getOrganizationType } from '@/utils/organizationRoles'
import React, { useCallback } from 'react'
import IOrganizationRequestModalProps from './IOrganizationRequestModalProps'

export interface IOrganizationInfoRequestModalProps extends IOrganizationRequestModalProps {}

const OrganizationInfoRequestModal: React.FC<IOrganizationInfoRequestModalProps> = ({ orgId, isOpen, handleClose }) => {
	const dispatch = useAppDispatch()
	const onSubmit = useCallback(() => {
		dispatch(
			createInfoRequest({
				orgId,
				membershipTypeId: getOrganizationType(ORGANIZATION_ROLE.MEMBER),
			}),
		)
	}, [dispatch, orgId])

	return (
		<ModalWithSubmittedPopup
			isOpen={isOpen}
			onSubmit={onSubmit}
			onClose={handleClose}
			title="Request Information"
			content={[
				'Want to learn more about this organization?',
				'Notify the organization by requesting information.',
				'They’ll receive an email about your request',
			]}
			submittedMessage={['We sent an email to the Primary ', 'Contact of this organization.']}
			submitButtonLabel="Request Information"
		/>
	)
}

export default OrganizationInfoRequestModal

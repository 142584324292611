import { APP_MESSAGES, STYLES } from '@/constants'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import IconButton from '../Buttons/IconButton'

const useStyles = makeStyles((theme) => ({
	root: ({ fullWidth }: any) => ({
		width: fullWidth ? '100%' : STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: fullWidth ? '100%' : '100vw',
		},
		paddingBottom: 20,
	}),
	cardContainer: ({ fullWidth }: any) => ({
		width: fullWidth ? '100%' : STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'flex-start',
		gridGap: 10,
		margin: '1vh 2vw',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-evenly',
			width: fullWidth ? '100%' : '96vw',
		},
	}),
	seeMoreButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
		padding: '10px 20px',
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	infoText: {
		fontSize: '1.1em',
	},
	icon: {
		fontSize: '2.1em',
	},
}))

interface ItemsSectionPaginationProps {
	items: any[]
	renderItems: (items: any) => React.ReactNode
	fullWidth?: boolean
	containerClassName?: string
	hasMore?: boolean
	loadMore?: () => void | null
	classnames?: {
		root?: string
		content?: string
	}
}

const ITEMS_PER_PAGE = 8

const pickItems = (items: any[], currentPage: number, itemsPerPage: number, isEvents = true) => {
	const maxItems = items.length
	const start = 0
	const calculatedEnd = currentPage * itemsPerPage
	const end = calculatedEnd < maxItems ? calculatedEnd : maxItems

	return items.slice(start, end)
}

const ItemsSectionPagination = ({
	items = [],
	renderItems,
	fullWidth = false,
	classnames = {
		root: '',
		content: '',
	},
}: ItemsSectionPaginationProps) => {
	const classes = useStyles({ fullWidth })
	const [allItems, setAllItems] = useState(items)
	const [currentPage, setCurrentPage] = useState(1)
	const [itemsToShow, setItemsToShow] = useState(pickItems(items, currentPage, ITEMS_PER_PAGE))
	const [canLoadMore, setCanLoadMore] = useState(!!items.length)

	const handleLoadMore = () => setCurrentPage((prev) => prev + 1)

	useEffect(() => {
		setAllItems(items)
		setCanLoadMore(!!items.length)
		setCurrentPage(1)
	}, [items])

	useEffect(() => {
		if (allItems.length - 1 < currentPage * ITEMS_PER_PAGE) setCanLoadMore(false)
		setItemsToShow(pickItems(allItems, currentPage, ITEMS_PER_PAGE))
	}, [currentPage, allItems])

	if (!allItems.length) return <></>

	return (
		<div className={classNames(classes.root, classnames.root)}>
			<div className={classNames(classes.cardContainer, classnames.content)}>{renderItems(itemsToShow)}</div>
			{canLoadMore && (
				<div className={classes.controlPanel}>
					<IconButton
						className={classes.seeMoreButton}
						endIcon={<KeyboardArrowDownIcon className={classes.icon} fontSize="medium" />}
						onClick={handleLoadMore}
					>
						{APP_MESSAGES.GENERALS.SEE_MORE}
					</IconButton>
				</div>
			)}
		</div>
	)
}

export default ItemsSectionPagination

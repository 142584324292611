import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core'
import classNames from 'classnames'
import { forwardRef } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
	input: {
		fontSize: '1em',
		boxShadow: theme.palette.type === 'light' ? '0px 7px 15px -1px rgba(181,181,181,0.33)' : theme.shadows[10],
		margin: '1.5vh 7%',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
	inputIcon: {
		color: theme.palette.text.secondary,
	},
	clearInputButton: {
		cursor: 'pointer',
	},
}))

const SearchInput = forwardRef<HTMLDivElement | null, TextFieldProps & { onClear?: () => void }>(({ onClear = () => {}, ...rest }, ref) => {
	const classes = useStyles()

	return (
		<TextField
			ref={ref}
			{...rest}
			className={classNames(classes.input, rest.className)}
			variant="outlined"
			InputProps={{
				...rest.InputProps,
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon className={classes.inputIcon} fontSize="medium" />
					</InputAdornment>
				),
				endAdornment: rest.value ? (
					<InputAdornment className={classes.clearInputButton} position="end" onClick={onClear}>
						<Close className={classes.inputIcon} fontSize="medium" />
					</InputAdornment>
				) : undefined,
			}}
		/>
	)
})

export default SearchInput

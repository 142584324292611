import ColorButton from '@/components/Buttons/ColorButton'
import Card from '@/components/Card'
import { PATHS } from '@/constants'
import { getError } from '@/store/app'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default,
	},
	titleText: {
		fontWeight: 700,
		margin: '4vh 12vw 1vh 12vw',
		color: theme.palette.primary.main,
	},
	contentText: {
		color: theme.palette.text.secondary,
		marginBottom: '7vh',
	},
}))

const NoMatch = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const error = useSelector(getError)!

	if (!error) return <Redirect to={PATHS.APP.ROOT} />

	return (
		<div className={classes.root}>
			<Card>
				<Typography className={classes.titleText} variant="h1">
					404
				</Typography>
				<Typography className={classes.contentText} variant="h3">
					PAGE NOT FOUND
				</Typography>
				<ColorButton onClick={() => push(PATHS.APP.ROOT)}>Back to Homepage</ColorButton>
			</Card>
		</div>
	)
}

export default NoMatch

import { useCallback } from 'react'
import * as yup from 'yup'
import { FieldKind, FormField } from '../types/dashboardTypes'

type UseFormValidationProps = {
	fields: FormField<FieldKind>[]
}
export const useFormValidation = ({ fields }: UseFormValidationProps) => {
	const getValidationSchema = useCallback(
		() =>
			yup.object(
				fields?.reduce((acc: { [key: string]: yup.AnySchema }, { name, kind, isRequired, label, customValidation }) => {
					let validation: yup.AnySchema

					if (customValidation === null) {
						validation = yup.mixed()
					} else {
						switch (kind) {
							case FieldKind.INPUT:
								validation = yup.string().typeError(`${label} must be a string`)
								break
							case FieldKind.TEXT_AREA:
								// must check the .data field of the field
								validation = yup.object({ data: yup.string().typeError(`${label} must be a string`) })
								break
							case FieldKind.DATE:
							case FieldKind.DATE_TIME:
								validation = yup.date().typeError('Invalid date')
								break
							default:
								validation = yup.string()
						}
						validation = validation.concat(customValidation)
					}

					if (isRequired) validation = validation.required(`${label} is required`)

					acc[name] = validation
					return acc
				}, {}),
			),
		[fields],
	)

	return { getValidationSchema }
}

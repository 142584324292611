import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import classNames from 'classnames'
import { useCallback } from 'react'

import { ChangeGroupMemberRequestStatus } from '@/api/http/groups/patchGroupMemberRequest'
import { ColorButton, IconButton, UserRow } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import { STYLES } from '@/constants'
import { useAppDispatch } from '@/store'
import { changeMemberRequestStatusRequest } from '@/store/groups'
import { GroupMemberRequest } from '@/store/groups/types'
import { getFullName } from '@/utils/common'
import GroupMemberRequestsList from '../ManageCards/GroupMemberRequestsList'
import SideBarTabHeader from './SideBarTabHeader'

const useStyles = makeStyles((theme) => ({
	groupInformation: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		alignItems: 'center',
		marginBottom: 30,
	},
	buttonsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
	},
	requestButton: {
		width: 90,
		height: 32,
		marginRight: 10,
		padding: '5px 18px',
		...theme.typography.body2,
		fontWeight: 600,
	},
	acceptedButton: {
		width: 120,
		backgroundColor: theme.palette.primary.light,
		cursor: 'not-allowed',
		'&>span': {
			color: theme.palette.primary.main,
			textTransform: 'capitalize',
		},
	},
	rejectedButton: {
		width: 120,
		cursor: 'not-allowed',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		'&>span': {
			color: theme.palette.text.secondary,
			textTransform: 'capitalize',
		},
	},
	userRowRoot: {
		margin: '0 0 10px 0',
	},
	userName: {
		fontWeight: 600,
	},
	additionalInfoText: {
		color: theme.palette.text.secondary,
	},
	buttonIcon: {
		width: 20,
		height: 20,
	},
}))

interface GroupMemberRequestsProps {
	onClose?: () => void
}

const GroupMemberRequests = ({ onClose }: GroupMemberRequestsProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const handleChangeStatus = useCallback(
		(requestId: string, status: ChangeGroupMemberRequestStatus) => () =>
			dispatch(
				changeMemberRequestStatusRequest({
					requestId,
					status,
				}),
			),
		[dispatch],
	)

	const renderMemberRequests = useCallback(
		(requests: GroupMemberRequest[]) =>
			requests.map((r) => (
				<UserRow
					classnames={{
						root: classes.userRowRoot,
						userName: classes.userName,
						info: classes.additionalInfoText,
					}}
					key={r.id}
					profileImageRadius={7}
					user={{
						id: r.userId,
						externalId: r.externalId,
						fullName: getFullName(r.firstName, r.lastName),
						type: r.userType,
						campus: r.campusName,
						photoUrl: r.photoUrl,
					}}
				>
					<div className={classes.buttonsContainer}>
						{!r.status && (
							<>
								<ColorButton className={classes.requestButton} onClick={handleChangeStatus(r.id, ChangeGroupMemberRequestStatus.APPROVE)}>
									Approve
								</ColorButton>
								<ColorButton
									color={ButtonColors.GREY}
									className={classes.requestButton}
									onClick={handleChangeStatus(r.id, ChangeGroupMemberRequestStatus.DECLINE)}
								>
									Decline
								</ColorButton>
							</>
						)}
						{r.status === ChangeGroupMemberRequestStatus.APPROVE && (
							<IconButton
								className={classNames(classes.requestButton, classes.acceptedButton)}
								endIcon={<Check className={classes.buttonIcon} />}
								disabled
							>
								Approved
							</IconButton>
						)}
						{r.status === ChangeGroupMemberRequestStatus.DECLINE && (
							<IconButton
								className={classNames(classes.requestButton, classes.rejectedButton)}
								endIcon={<Close className={classes.buttonIcon} />}
								disabled
							>
								Declined
							</IconButton>
						)}
					</div>
				</UserRow>
			)),
		[
			classes.acceptedButton,
			classes.additionalInfoText,
			classes.buttonIcon,
			classes.buttonsContainer,
			classes.rejectedButton,
			classes.requestButton,
			classes.userName,
			classes.userRowRoot,
			handleChangeStatus,
		],
	)

	return (
		<div className={classes.groupInformation}>
			<SideBarTabHeader title="Member Requests" onCancel={onClose} />
			<GroupMemberRequestsList emptyPlaceholder={<Typography>No member requests yet</Typography>}>
				{renderMemberRequests}
			</GroupMemberRequestsList>
		</div>
	)
}

export default GroupMemberRequests

import { isNavApp } from '@/constants/configuration'
import { CircularProgress, makeStyles } from '@material-ui/core'
import RedirectPage from '../RedirectPage'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const Processing = () => {
	const classes = useStyles()

	return isNavApp() ? (
		<RedirectPage />
	) : (
		<div className={classes.placeholder}>
			<CircularProgress />
		</div>
	)
}

export default Processing

import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'
import StepperContent from '@/components/PathBuilder/StepperContent'
import StepperCard from '@/components/PathBuilder/StepperCard'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { getStringSchema } from '@/constants/pathBuilder'
import OutlinedInput from '@/components/PathBuilder/OutlinedInput'

const useStyles = makeStyles((theme) => ({
	header: {
		width: '50%',
	},
	input: {
		width: '50%',
		margin: '6vh 0',
	},
}))

const Question3 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getStringSchema(dataKey, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.header,
					}}
					title="What type of job or internship are you 
      currently looking for?"
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field }) => <OutlinedInput className={classes.input} placeholder="Describe job" {...field} />}
					/>
				</StepperCard>
			}
		/>
	)
}

export default Question3

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'

interface GetGroupMessagesParams {
	id: string
	page: number
	direction: 'desc' | 'asc'
	schoolId?: number
}

const getGroupMessages = async ({ id, page, direction, schoolId }: GetGroupMessagesParams) => {
	const url = `${schoolId}/groups/${id}`

	const params = {
		direction,
		page,
		limit: settings.SEARCH_MESSAGES_PER_PAGE,
	}

	return await http.get(url, { params })
}

export default getGroupMessages

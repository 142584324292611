import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'

export enum PrivacyTypes {
	membership = 'membership',
	internship = 'internship',
	educationAbroad = 'educationAbroad',
	research = 'research',
	volunteerHours = 'volunteerHours',
	additionalInvolvement = 'additionalInvolvement',
}

export enum PrivacyLevels {
	allUsers = '0',
	myNetwork = '1',
	justMe = '2',
}

export interface PrivacySettings {
	entityId?: string | number
	entityType: PrivacyTypes
	privacyLevel: PrivacyLevels
}

interface PatchUserPrivacySettingsParams {
	userId: UserInfo['id']
	body: PrivacySettings
	schoolId: number
}

const patchUserPrivacySettings = async ({ body, schoolId, userId }: PatchUserPrivacySettingsParams) => {
	const url = `${schoolId}/users/user/${userId}/privacy`

	return http.patch(url, body)
}

export default patchUserPrivacySettings

import { STYLES } from '@/constants'
import { Typography, makeStyles } from '@material-ui/core'
import FeedCampus from '../filters/FeedCampus'
import FeedAudience from '../filters/FeedAudience'
import ToggleAllCampuses from '../filters/ToggleAllCampuses'
import FeedTags from '../filters/FeedTags'
import { FeedUpdatesInfo } from '../FeedUpdatesInfo'
import { displayIfEnabled } from '../../utils'
import classNames from 'classnames'
import { useHeaderRootStyles } from './headerRootStyles'
import { FILTER_CONFIG } from '../../feed/config'
import { HasScrollToTop } from '../types'
import { useSelector } from 'react-redux'
import { selectShowCampuses } from '@/features/campus/slice'

const useStyles = makeStyles((theme) => ({
	sticky: {
		position: 'sticky',
		top: 0,
		zIndex: 9,
		borderBottom: `solid 1.5px ${theme.palette.divider}`,
	},

	customizeFeeds: {
		borderTop: `solid 0.5px ${theme.palette.divider}`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '1.5vh 0',
		margin: '0 1.5vw',
	},

	customizeFeedsHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	customizeFeedsHeaderRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		flex: 1,
	},
}))

interface ICustomizeFeedProps extends HasScrollToTop {}

const CustomizeFeed: React.FC<ICustomizeFeedProps> = ({ scrollToTop }) => {
	const showCampuses = useSelector(selectShowCampuses)
	const classes = useStyles()
	const rootClasses = useHeaderRootStyles()
	return (
		<div className={classNames(rootClasses.root, classes.sticky)}>
			<div className={classes.customizeFeeds}>
				<div className={classes.customizeFeedsHeader}>
					<Typography variant="h3">
						<b>Customize your feed</b>
					</Typography>
					<div className={classes.customizeFeedsHeaderRight}>
						{showCampuses ? displayIfEnabled('campusId', <FeedCampus />, FILTER_CONFIG) : null}
						{displayIfEnabled('audienceScope', <FeedAudience />, FILTER_CONFIG)}
					</div>
				</div>
				{displayIfEnabled('includeAllCampuses', <ToggleAllCampuses />, FILTER_CONFIG)}
				{displayIfEnabled('tags', <FeedTags />, FILTER_CONFIG)}
			</div>
			<FeedUpdatesInfo onClick={scrollToTop} />
		</div>
	)
}

export default CustomizeFeed

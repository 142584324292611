import MUIScheduleIcon from '@material-ui/icons/Schedule'
import MUIEditIcon from '@material-ui/icons/Edit'
import MUIDeleteIcon from '@material-ui/icons/Delete'
import AttachFileIcon from '@material-ui/icons/AttachFile'

const ScheduleIcon = (props) => <MUIScheduleIcon {...props} />
const EditIcon = (props) => <MUIEditIcon {...props} />
const DeleteIcon = (props) => <MUIDeleteIcon {...props} />
const AttachmentIcon = (props) => <AttachFileIcon {...props} />

export { ScheduleIcon, EditIcon, DeleteIcon, AttachmentIcon }

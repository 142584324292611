import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import { ConnectivityStatus } from '@/interfaces/common'
import * as actions from '@/store/network/actions'
import { NetworkState } from '@/store/network/types'

const initialState: NetworkState = {
	search: {
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	users: [],
	isLoading: false,
	isLoadingUserNetwork: true,
	connections: {
		pendingRequests: [],
		connectionRequests: [],
		totalConnections: '0',
	},
	blockedUsers: [],
}

const networkReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setNetwork, (state, action) => ({
			...state,
			users: action.payload,
		}))
		.addCase(actions.updateSingleUserStatus, (state, action) => ({
			...state,
			users: state.users.map((userData) => {
				const match = action.payload.targetUserId === userData.user.id || action.payload.sourceUserId === userData.user.id

				if (match)
					return {
						...userData,
						status: action.payload.status !== ConnectivityStatus.CANCELED ? 'requested' : ConnectivityStatus.CANCELED,
					}
				return userData
			}),
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			users: [],
		}))
		.addCase(actions.setConstants, (state, action) => ({
			...state,
			countries: action.payload.countries,
			housingType: action.payload.housingType,
			languageOfInstruction: action.payload.languageOfInstruction,
			scholarshipsGe: action.payload.scholarshipsGe,
			termsOfAvailability: action.payload.termsOfAvailability,
		}))
		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					...rest,
				},
			}
		})
		.addCase(actions.setConnections, (state, action) => ({
			...state,
			connections: action.payload,
		}))
		.addCase(actions.setUser, (state, action) => ({
			...state,
			selectedUser: {
				...state.selectedUser,
				info: action.payload,
			},
		}))
		.addCase(actions.deleteSingleUserConnection, (state, action) => ({
			...state,
			selectedUser: {
				...state.selectedUser,
				info: {
					...state.selectedUser?.info!,
					connectionStatus: ConnectivityStatus.CANCELED,
				},
			},
		}))
		.addCase(actions.clearUser, (state, action) => ({
			...state,
			selectedUser: undefined,
		}))
		.addCase(actions.setUserActivity, (state, { payload }) => ({
			...state,
			selectedUser: {
				...state.selectedUser,
				activity: {
					...state.selectedUser?.activity,
					...payload,
				},
			},
		}))
		.addCase(actions.setBlockedUsers, (state, { payload }) => ({
			...state,
			blockedUsers: payload,
		}))
})

export { networkReducer }

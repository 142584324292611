import { STYLES } from '@/constants'
import appTheme from '@/Theme'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { GroupChatTypes } from '@navengage/sen-types'
import GroupTypeButton from './GroupTypeButton'

const useStyles = makeStyles<Theme>((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		margin: 30,
		height: 'calc(100% - 60px)',
	},
	stepInfo: {
		fontWeight: 700,
		marginBottom: 10,
	},
}))

const groupTypes = [
	{
		name: GroupChatTypes.Public,
		description: `Anyone can search and join. 
    Member/admin approval is not required 
    to join.`,
		backgroundColor: appTheme.colors.blue,
		backgroundHoverColor: '#1da6fc',
	},
	{
		name: GroupChatTypes.Restricted,
		description: `Anyone can search and request to join.
    Member/admin approval is required to 
    join.`,
		backgroundColor: appTheme.colors.green,
		backgroundHoverColor: '#103527',
	},
	{
		name: GroupChatTypes.Private,
		description: `Group does not appear on the app. 
    Must be added by members/admins to 
    join.`,
		backgroundColor: appTheme.colors.orange,
		backgroundHoverColor: '#F57B00',
	},
]

interface GroupTypeProps {
	onSelectType?: (type: GroupChatTypes) => void
}

const GroupType = ({ onSelectType }: GroupTypeProps) => {
	const classes = useStyles()

	const handleSelectType = (type: GroupChatTypes) => () => {
		if (onSelectType) onSelectType(type)
	}

	return (
		<div className={classes.root}>
			<Typography className={classes.stepInfo}>What type of group?</Typography>
			{groupTypes.map((props) => (
				<GroupTypeButton key={props.name} onClick={handleSelectType(props.name)} {...props} />
			))}
		</div>
	)
}

export default GroupType

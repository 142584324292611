import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'fit-content',
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 30,
		padding: '7px 12px',
		fontSize: 18,
		fontWeight: 450,
		margin: '10px 10px 5px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	tagIcon: {
		color: theme.palette.neutral.main,
		margin: 0,
	},
	button: {
		padding: 0,
		marginLeft: 10,
	},
}))

interface AddChipProps {
	onChange: (v: string) => void
	label: string
	value: string
}

const AddChip = ({ onChange, label, value }: AddChipProps) => {
	const classes = useStyles()

	const handleAddItem = (v: string) => () => {
		onChange(v)
	}

	return (
		<span className={classes.root}>
			{label}
			<IconButton className={classes.button} onClick={handleAddItem(value)}>
				<AddIcon className={classes.tagIcon} />
			</IconButton>
		</span>
	)
}

export default AddChip

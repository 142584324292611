import classNames from 'classnames'
import { makeStyles, Button } from '@material-ui/core'
import { ButtonProps } from '@/interfaces/common'
import { ReactNode } from 'react'

const TEXT_SIZES = {
	xs: '0.7em',
	sm: '0.96em',
	md: '1.125em',
	lg: '1.1em',
} as const

const useStyles = makeStyles((theme) => ({
	root: ({ variant, size }: any) => ({
		borderRadius: 7,
		fontWeight: 600,
		backgroundColor: variant === 'primary' ? theme.palette.primary.main : theme.palette.specialColors.transparent,
		color: variant === 'primary' ? theme.palette.common.white : theme.palette.text.primary,
		textTransform: 'none',
		boxShadow: 'none',
		padding: variant === 'primary' ? `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px` : `${theme.spacing(0.75)}p-x ${theme.spacing(1)}px`,
		[theme.breakpoints.up('lg')]: {
			fontSize: TEXT_SIZES[size] ?? '1.1em',
		},
		[theme.breakpoints.up('xl')]: {
			fontSize: TEXT_SIZES[size] ?? '1.125em',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: TEXT_SIZES[size] ?? '0.96em',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: TEXT_SIZES[size] ?? '0.7em',
			minWidth: 100,
			minHeight: 36,
		},
		'&:hover': {
			backgroundColor: variant === 'primary' ? theme.palette.primary.dark : theme.palette.grey[200],
		},
	}),
}))

const IconButton = ({
	variant = 'secondary',
	children = '',
	onClick,
	className,
	disabled,
	startIcon,
	endIcon,
	component,
	size,
}: ButtonProps & {
	size?: keyof typeof TEXT_SIZES
	startIcon?: ReactNode
	endIcon?: ReactNode
	component?: any
}) => {
	const classes = useStyles({ variant, size })

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			className={classNames(classes.root, className)}
			startIcon={startIcon}
			component={component}
			endIcon={endIcon}
		>
			{children}
		</Button>
	)
}

export default IconButton

import ItemsList from '@/components/PathBuilder/ItemsList'
import StepperCard from '@/components/PathBuilder/StepperCard'
import StepperContent from '@/components/PathBuilder/StepperContent'
import { TextCheckBox } from '@/components/widgets'
import { isEngage } from '@/constants/configuration'
import { getArrayMinSchema, whenWouldYouLikeOptions } from '@/constants/pathBuilder'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 10vw)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 30%)`,
		justifyContent: 'space-evenly',
		gridGap: 20,
		margin: 0,
	},
	containerHeader: {
		width: '34%',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 15,
		padding: 30,
		textAlign: 'center',
	},
	header: {
		fontWeight: 700,
		fontSize: 18,
		margin: 20,
	},
	iconText: {
		fontSize: 20,
	},
	text: {
		fontSize: 18,
	},
}))

const Question5 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getArrayMinSchema(dataKey, 1, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	const {
		school: { shortName },
	} = useSchoolConfig()

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.containerHeader,
					}}
					title="When would you like to study abroad?"
					subTitle="You can select more than one."
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field: { value, onChange } }) => (
							<ItemsList
								classnames={{
									container: classes.itemsContainer,
								}}
								value={value}
								onChange={onChange}
								renderItems={(handleSelectItem, isChecked) =>
									whenWouldYouLikeOptions.map((item) => (
										<TextCheckBox
											key={item.value}
											label={item.label}
											value={item.value}
											onChange={handleSelectItem}
											checked={isChecked(item.value)}
										/>
									))
								}
							/>
						)}
					/>
				</StepperCard>
			}
			rightColumnContent={
				!isEngage() && (
					<div className={classes.card}>
						<Typography className={classes.iconText}>✈️</Typography>
						<Typography className={classes.header}>Heads up!</Typography>
						<Typography className={classes.text}>Most abroad opportunities require</Typography>
						<Typography className={classes.text}>completing one year at {shortName}.</Typography>
					</div>
				)
			}
		/>
	)
}

export default Question5

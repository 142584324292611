import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

import PostCard from '@/components/Feeds/PostCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import { PATHS, STYLES } from '@/constants'
import ErrorResponseCard from '@/features/adminConsole/components/ErrorResponseCard'
import useFeedPostById from '@/features/posts/hooks/useFeedPostById'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},

	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.text.primary,
		padding: 20,
		backgroundColor: theme.palette.background.paper,
		borderBottom: `solid 1.5px ${theme.palette.border.bold}`,
	},
	backButton: {
		color: theme.palette.text.primary,
	},
	title: {
		fontWeight: 700,
	},
}))

const IndividualPostPage = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const { push } = useHistory()
	const { data: postData, isLoading, isError, error } = useFeedPostById(id)

	const handleClickBack = useCallback(() => {
		push(PATHS.APP.HOME)
	}, [push])

	if (isError) {
		return 'status' in error ? <ErrorResponseCard buttonPath={PATHS.APP.HOME} width="auto" error={error} context="POST" /> : null
	}

	if (isLoading || !postData) {
		return <LoadingPlaceholder />
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<IconButton className={classes.backButton} onClick={handleClickBack}>
					<ArrowBack />
				</IconButton>
				<Typography className={classes.title} variant="h1">
					Post
				</Typography>
			</div>
			<PostCard openComments={true} {...postData} />
		</div>
	)
}

export default IndividualPostPage

import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useState } from 'react'

import Select, { SelectClasses, isSelectHasValue } from './Select'
import { SelectOptionType, SelectValue } from '@/types/components'

interface StyleProps {
	isOpen: boolean
	hasValue: boolean
	error: boolean
	disabled: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	selectOpenIcon: {
		color: theme.palette.text.secondary,
	},
	selectMenuListContainer: {
		maxHeight: 350,
	},
	inputRoot: ({ isOpen, hasValue, error }) => {
		const borderColor = error ? theme.palette.error.main : theme.palette.border?.bold
		const border = isOpen || hasValue || error ? `solid 1px ${borderColor}` : 'none'

		return {
			'&.MuiOutlinedInput-root': {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.background.paper,
				boxShadow: 'none',

				'& fieldset': {
					border,
					borderRadius: isOpen ? '5px 5px 0 0' : 5,
					background: 'none',
				},
				'&:hover fieldset': {
					border,
					borderRadius: isOpen ? '5px 5px 0 0' : 5,
					background: 'none',
				},
				'&.Mui-focused fieldset': {
					border,
					borderRadius: isOpen ? '5px 5px 0 0' : 5,
					background: 'none',
				},
				'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
					borderColor,
				},
			},
		}
	},
	selectRoot: ({ error }) => ({
		color: error ? theme.palette.error.main : theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		padding: '10px 20px',
		boxShadow: 'none',
		'&.MuiSelect-select:focus': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	}),
	selectMenuPaper: {
		boxShadow: 'none',
		backgroundColor: theme.palette.background.paper,
		marginTop: -1,
		borderBottom: `solid 1px ${theme.palette.border?.bold}`,
		borderTop: `solid 1px ${theme.palette.border?.bold}`,
		borderRadius: '0 0 5px 5px',
	},
	selectMenuList: {
		width: 'calc(100% - 2px) !important',
		boxShadow: 'none',
		backgroundColor: theme.palette.background.paper,
		padding: '10px 0',
		border: `solid 1px ${theme.palette.border?.bold}`,
		borderBottom: 'none',
		borderTop: 'none',
		borderRadius: '0 0 5px 5px',
	},
	menuItemIcon: {
		color: theme.palette.primary.main,
		paddingLeft: 5,
	},
	menuItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&.Mui-selected': {
			background: 'none',
		},
		...theme.typography.subtitle2,
	},
	selectLabel: {
		width: 'auto',
	},
}))

interface OutlinedSelectProps {
	classnames?: SelectClasses
	label?: string
	value: SelectValue | SelectValue[]
	placeholder?: string
	onChange: (v: SelectValue | SelectValue[]) => void
	options: SelectOptionType[]
	multiple?: boolean
	disabled?: boolean
	onToggleSelect?: (state: boolean) => void
	error?: boolean
}

const OutlinedSelect = ({
	classnames = {
		input: '',
		select: '',
		selectOpenIcon: '',
		selectLabel: '',
		selectMenuPaper: '',
		selectMenuList: '',
		selectMenuListContainer: '',
		menuItem: '',
		menuItemIcon: '',
	},
	label,
	value,
	placeholder = '',
	onChange,
	options = [],
	multiple = false,
	disabled = false,
	error = false,
}: OutlinedSelectProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const hasValue = isSelectHasValue(multiple, options, value)

	const classes = useStyles({ hasValue, isOpen, error, disabled })

	const onToggleSelect = useCallback((open: boolean) => setIsOpen(open), [])

	return (
		<Select
			classnames={{
				input: classNames(classnames.input, classes.inputRoot),
				select: classNames(classnames.select, classes.selectRoot),
				selectMenuPaper: classNames(classnames.selectMenuPaper, classes.selectMenuPaper),
				selectMenuList: classNames(classnames.selectMenuList, classes.selectMenuList),
				selectMenuListContainer: classNames(classnames.selectMenuListContainer, classes.selectMenuListContainer),
				selectLabel: classNames(classnames.selectLabel, classes.selectLabel),
				menuItem: classNames(classnames.menuItem, classes.menuItem),
				menuItemIcon: classNames(classnames.menuItemIcon, classes.menuItemIcon),
				selectOpenIcon: classNames(classnames.selectOpenIcon, classes.selectOpenIcon),
			}}
			label={label}
			placeholder={placeholder}
			options={options}
			onToggleSelect={onToggleSelect}
			disabled={disabled}
			value={value}
			onChange={onChange}
			multiple={multiple}
		/>
	)
}

export default OutlinedSelect

import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import IconWrapper, { TIconName } from '../../../../components/IconWrapper'
import useCommonStyles from '../../styles/useCommonStyles'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
		'& > *': {
			paddingLeft: 0,
			paddingRight: 0,
		},
		'& .MuiListItemIcon-root': {
			minWidth: `${theme.spacing(4)}px`,
		},
	},
}))

export type TInfoListItem = {
	icon?: TIconName
	primaryText: string
	secondaryText?: string
	link?: string
}

export interface IInfoListProps {
	items: TInfoListItem[]
	className?: string
}

const InfoList: React.FC<IInfoListProps> = ({ items, className }) => {
	const classes = useStyles()
	const commonClasses = useCommonStyles()
	return (
		<List className={classNames(classes.root, className)} dense>
			{items.map(({ primaryText, icon, link, secondaryText }, index) => (
				<ListItem key={index} className={link ? commonClasses.link : undefined} component={link ? 'a' : 'li'} href={link}>
					<ListItemIcon>
						<IconWrapper iconKey={icon} />
					</ListItemIcon>
					<ListItemText primary={primaryText} secondary={secondaryText} />
				</ListItem>
			))}
		</List>
	)
}

export default InfoList

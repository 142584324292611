import { UploadErrors } from '@/constants/uploads'

interface CreateUploadErrorHandler {
	path: UploadErrors
	message: string
}

const uploadErrorsStoreKey = 'uploadErrors'

export const createUploadErrorBreakpoint = ({ path, message }: CreateUploadErrorHandler) => {
	try {
		const errorsStore = JSON.parse(sessionStorage.getItem(uploadErrorsStoreKey) ?? '{}')

		if (errorsStore[path]?.length) {
			errorsStore[path] = [...errorsStore[path], message]
		} else {
			errorsStore[path] = [message]
		}

		sessionStorage.setItem(uploadErrorsStoreKey, JSON.stringify(errorsStore))

		return () => sessionStorage.setItem(uploadErrorsStoreKey, JSON.stringify({}))
	} catch (error) {
		console.error(error)
		return () => null
	}
}

export const getUploadErrors = (path: string): string[] => {
	try {
		const errorsStore = JSON.parse(sessionStorage.getItem(uploadErrorsStoreKey) ?? '{}')
		const errors = errorsStore[path] ?? []

		sessionStorage.setItem(
			uploadErrorsStoreKey,
			JSON.stringify({
				...errorsStore,
				[path]: [],
			}),
		)

		return errors
	} catch (error) {
		console.error(error)
		return []
	}
}

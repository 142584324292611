import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Button, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: ({ isActive, variant }: any) => ({
		borderRadius: 7,
		height: 40,
		backgroundColor:
			variant === 'primary'
				? isActive
					? theme.palette.primary.light
					: theme.palette.primary.main
				: isActive
				? theme.palette.primary.main
				: theme.palette.grey[200],
		color:
			variant === 'primary'
				? isActive
					? theme.palette.primary.main
					: theme.palette.common.white
				: isActive
				? theme.palette.common.white
				: theme.palette.text.primary,
		textTransform: 'none',
		'&:hover': {
			backgroundColor:
				variant === 'primary'
					? isActive
						? theme.palette.primary.dark
						: theme.palette.grey[200]
					: isActive
					? theme.palette.primary.dark
					: theme.palette.grey[200],
			color:
				variant === 'primary'
					? isActive
						? theme.palette.primary.main
						: theme.palette.text.primary
					: isActive
					? theme.palette.common.white
					: theme.palette.common.black,
		},
		'&>span': {
			fontSize: '16px',
		},
	}),
	text: {
		fontWeight: 600,
	},
}))

interface ToggleButtonProps {
	className?: string
	isActive?: boolean
	children: string
	onClick?: (e: any) => void
	disabled?: boolean
	variant?: 'primary' | 'secondary'
}

const ToggleButton = ({
	className = '',
	isActive = false,
	children,
	onClick = () => {},
	disabled = false,
	variant = 'primary',
}: ToggleButtonProps) => {
	const classes = useStyles({ isActive, variant })

	return (
		<Button className={classNames(classes.root, className)} onClick={onClick} disabled={disabled}>
			<Typography className={classes.text}>{children}</Typography>
		</Button>
	)
}

export default ToggleButton

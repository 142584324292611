import IconButton from '@/components/Buttons/IconButton'
import { APP_MESSAGES, STYLES } from '@/constants'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	cardContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'flex-start',
		gridGap: 10,
		margin: '1vh 2vw',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			justifyContent: 'space-evenly',
		},
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '30px 0 10px 0',
	},
	seeMoreButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
		padding: '10px 20px',
	},
	icon: {
		fontSize: '2.1em',
	},
}))

interface ItemsSectionWithButtonProps {
	handleLoadMoreItems: () => void
	items: any[]
	renderItems: (items: any) => React.ReactNode
	isLoading: boolean
	canLoadMore: boolean
	classnames?: {
		root?: string
		contentContainer?: string
		content?: string
	}
}

const ItemsSectionWithButton = ({
	handleLoadMoreItems,
	items = [],
	isLoading = false,
	canLoadMore = false,
	renderItems,
	classnames = {
		root: '',
		contentContainer: '',
		content: '',
	},
}: ItemsSectionWithButtonProps) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.root, classnames.root)}>
			<div className={classNames(classes.contentContainer, classnames.contentContainer)}>
				<div className={classNames(classes.cardContainer, classnames.content)}>{renderItems(items)}</div>
				{canLoadMore && isLoading && (
					<div className={classes.controlPanel}>
						<CircularProgress />
					</div>
				)}
			</div>
			{canLoadMore && !isLoading && (
				<div className={classes.controlPanel}>
					<IconButton
						className={classes.seeMoreButton}
						endIcon={<KeyboardArrowDownIcon className={classes.icon} fontSize="medium" />}
						onClick={handleLoadMoreItems}
					>
						{APP_MESSAGES.GENERALS.SEE_MORE}
					</IconButton>
				</div>
			)}
		</div>
	)
}

export default ItemsSectionWithButton

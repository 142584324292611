import { baseApi } from '../general/baseApi'
import { DtoEntityPermission } from './types/DtoEntityPermission'

export const permissionsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getPostPermissions: builder.query<DtoEntityPermission, { userId: number; postId: string }>({
			query: ({ postId, userId }) => ({
				url: `/feeds/${userId}/post/${postId}/permissions`,
			}),
		}),
		getEventPermissions: builder.query<DtoEntityPermission, { eventId: string }>({
			query: ({ eventId }) => ({
				url: `/v2/events/${eventId}/permissions`,
			}),
		}),
	}),
})

export const { useGetPostPermissionsQuery, useGetEventPermissionsQuery, usePrefetch } = permissionsApi

import { SvgIcon } from '@material-ui/core'

const EllipsisHorizontalIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" className="prefix__ionicon" viewBox="0 0 512 512" {...props}>
			<circle cx={256} cy={256} r={48} />
			<circle cx={416} cy={256} r={48} />
			<circle cx={96} cy={256} r={48} />
		</SvgIcon>
	)
}

export default EllipsisHorizontalIcon

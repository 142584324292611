import { ContentPreloader } from '@/components/ContentPreloader'
import { CommonCard } from '@/features/adminConsole/components/CommonCard'
import React, { useMemo } from 'react'
import { useGetAnalyticsStudentDataQuery } from '../../../../api/usersApi'
import InfoList, { TInfoListItem } from '../../InfoList'
import IProfileCardProps from './IProfileCardProps'

export interface IStudentCardProps extends IProfileCardProps {}

const StudentCard: React.FC<IStudentCardProps> = ({ externalId, title = 'Student', ...commonCardProps }) => {
	const { isLoading, data } = useGetAnalyticsStudentDataQuery({ externalId })

	const mainInfoListItems = useMemo(() => {
		if (!data) {
			return []
		}
		const items: TInfoListItem[] = []
		if (data.intendedGraduationDate) {
			items.push({ primaryText: `Class of ${data.intendedGraduationDate.year} (${data.intendedGraduationDate.term})`, icon: 'graduation' })
		}
		if (data.academicCollegeName) {
			items.push({ primaryText: data.academicCollegeName, icon: 'book' })
		}

		return items
	}, [data])
	return (
		<CommonCard {...commonCardProps} title={title}>
			{isLoading ? <ContentPreloader /> : <InfoList items={mainInfoListItems} />}
		</CommonCard>
	)
}

export default StudentCard

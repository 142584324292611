import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { FieldErrorMessage, Label, TextField } from '@/components'
import { Row } from '../SearchEntities'

export type NewEntityFormState = { name: string; isValid: boolean }
type NewEntityFormProps = {
	entityName: string
	handleInputChange: (entity: NewEntityFormState) => void
}

const useStyles = makeStyles((theme) => ({
	formFieldsContainer: {
		width: '100%',
	},
	updateProfileButton: {
		padding: '1vh 1vw',
		minWidth: 250,
		fontWeight: 600,
	},
	label: {
		margin: '.2vh 0 .2vh 0',
	},
}))

const schema = yup.object().shape({
	name: yup
		.string()
		.min(1)
		.max(1000, ({ max }) => `Must be less than ${max} characters`),
})

export const CreateNewEntityForm = ({ entityName, handleInputChange }: NewEntityFormProps) => {
	const classes = useStyles()

	const { control } = useForm<any>({
		mode: 'onSubmit',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues: {
			name: '',
		},
	})

	const handleFieldChange =
		(field: ControllerRenderProps<any, 'name'>) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
			field.onChange(e)
			handleInputChange({ name: e.target.value, isValid: schema.isValidSync({ name: e.target.value }) })
		}

	return (
		<Row>
			<Controller
				name="name"
				defaultValue=""
				control={control}
				render={({ field, fieldState }) => {
					return (
						<Label
							classnames={{
								root: classes.label,
							}}
							title="Name"
							required
							semiBold
						>
							<FieldErrorMessage hasError={fieldState.invalid} message={fieldState.error?.message.toString()}>
								<TextField
									{...field}
									placeholder={`Name of the new ${entityName.toLowerCase()}`}
									error={!!fieldState.error}
									onChange={handleFieldChange(field)}
								/>
							</FieldErrorMessage>
						</Label>
					)
				}}
			/>
		</Row>
	)
}

import useHover from '@/hooks/useHover'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import IconWrapper, { TIconName, IconButtonVariant as Variant } from '../IconWrapper'

const useStyles = makeStyles((theme) => ({
	iconContainer: ({ styleOverrides, variant }: { styleOverrides: BaseCSSProperties; variant: Variant }) => {
		let variantStyles = {}
		switch (variant) {
			case Variant.SOLID:
				variantStyles = {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.common.white,
					border: 'none',
				}
				break
			case Variant.NO_BG:
				variantStyles = {
					backgroundColor: theme.palette.common.white,
					color: theme.palette.primary.main,
				}
				break
			case Variant.OUTLINED:
				variantStyles = {
					backgroundColor: 'transparent',
					color: theme.palette.text.primary,
					border: `solid 1px ${theme.palette.primary.main}`,
				}
				break
		}

		return {
			'&:hover': {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.primary.main,
			},
			...variantStyles,
			...styleOverrides,
		}
	},
}))

interface CircleButtonProps {
	handleToggle?: string
	ref?: React.MutableRefObject<HTMLButtonElement>
	iconKey?: TIconName
	onClick?: (e: any) => void
	disabled?: boolean
	className?: any
	styleOverrides?: BaseCSSProperties
	variant?: Variant
}

const CircleButton = ({
	iconKey = 'plus',
	ref,
	onClick,
	disabled = false,
	className,
	styleOverrides,
	variant = Variant.SOLID,
}: CircleButtonProps) => {
	const classes = useStyles({ styleOverrides, variant })
	const [isHovered, eventHandlers] = useHover()

	return (
		<IconButton className={classNames(classes.iconContainer, className)} ref={ref} onClick={onClick} disabled={disabled} {...eventHandlers}>
			<IconWrapper variant={variant} iconKey={iconKey} isHovered={isHovered} />
		</IconButton>
	)
}

export default CircleButton

import ColorButton from '@/components/Buttons/ColorButton'
import PageContainer from '@/components/PageContainer'
import GoalsForm from '@/components/PathBuilder/GoalsForm'
import SuccessPopup from '@/components/PathBuilder/SuccessPopupWithButton'
import SummaryCard from '@/components/PathBuilder/SummaryCard'
import { PATHS, STYLES } from '@/constants'
import { isEngage } from '@/constants/configuration'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { getBuilderData, getForm, getIsOpenPathBuilderCompletedPopup, setIsOpenPathBuilderCompletedPopup } from '@/store/pathBuilder'
import getNameOfPathBuilder from '@/utils/getNameOfPathBuilder'
import { Popover, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
	titleText: {
		fontWeight: 700,
		marginBottom: 10,
	},
	relativeContainer: {
		position: 'sticky',
		bottom: '1vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		marginRight: 30,
	},
	footerOffset: {
		bottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	button: {
		padding: '10px 5vw',
		marginBottom: '3vh',
	},
	tooltip: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.neutral.main,
		borderRadius: 3,
		padding: '5px 10px',
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		backgroundColor: theme.palette.specialColors.transparent,
		paddingBottom: '1vh',
		boxShadow: 'none',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
}))

const SummaryPage = () => {
	const classes = useStyles()

	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const anchorRef = useRef<any>(null)

	const [disabledSubmit, setDisabledSubmit] = useState(true)
	const [openTooltip, setOpenTooltip] = useState(false)

	const isOpenPopup = useSelector(getIsOpenPathBuilderCompletedPopup)

	const nameOfActivity = getNameOfPathBuilder(isEngage())
	const formData = useSelector(getForm)
	const builder = useSelector(getBuilderData)
	const userInfo = useSelector(getUserInfo)

	const shouldShowTooltip = disabledSubmit && openTooltip

	const handleOpenTooltip = () => {
		setOpenTooltip(true)
	}
	const handleCloseTooltip = () => {
		setOpenTooltip(false)
	}

	const handleClickSaveGoals = () => {
		push(PATHS.APP.PATH_BUILDER_LAST_STEP)
	}

	const handleClosePopup = () => {
		dispatch(setIsOpenPathBuilderCompletedPopup(false))
	}

	useEffect(() => {
		const handleScroll = () => {
			const winScroll = document.body.scrollTop || document.documentElement.scrollTop

			const height = document.documentElement.scrollHeight - document.documentElement.clientHeight

			const scrolled = winScroll / height
			if (disabledSubmit && scrolled >= 0.9) {
				setDisabledSubmit(false)
			}
		}

		document.addEventListener('scroll', handleScroll)

		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (!Object.keys(builder).length) return <Redirect to={PATHS.APP.PROFILE} />

	return (
		<div className={classes.container}>
			<SuccessPopup
				title={[`Way to go, ${userInfo.firstName}!`, `You’ve completed ${nameOfActivity}.`]}
				header=""
				text="Next, review each section of your 
        summary to customize next steps. "
				buttonLabel="Continue"
				isOpenModal={isOpenPopup}
				handleCloseModal={handleClosePopup}
			/>
			<PageContainer>
				<SummaryCard>
					<Typography className={classes.titleText} variant="h3">
						Here’s a summary of your goals
					</Typography>
					<Typography variant="subtitle1">
						Review each section to submit. If your plans change, you can edit at any time on your profile.
					</Typography>
				</SummaryCard>
				<GoalsForm canEdit data={formData} />
			</PageContainer>
			<div className={classes.relativeContainer}>
				{anchorRef?.current && (
					<Popover
						className={classes.popover}
						classes={{
							paper: classes.paper,
						}}
						open={shouldShowTooltip}
						anchorEl={anchorRef?.current}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						disableRestoreFocus
					>
						<Typography className={classes.tooltip}>Scroll through each section</Typography>
					</Popover>
				)}
				<ColorButton
					className={classes.button}
					disabled={disabledSubmit}
					onClick={!disabledSubmit ? handleClickSaveGoals : undefined}
					ref={anchorRef}
					onMouseEnter={handleOpenTooltip}
					onMouseLeave={handleCloseTooltip}
					component={disabledSubmit ? 'div' : undefined}
				>
					Set Goals
				</ColorButton>
			</div>
		</div>
	)
}

export default SummaryPage

import produce from 'immer'
import { isFunction } from 'lodash'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { GlobalState } from './GlobalState'
import { __rawGlobalStateMap } from './globalStates/global-states'
import { DraftableSetter, GlobalStateAtomKey, GlobalStateGroup, GlobalStateGroupKey, ValueOrUpdater } from './types'

const allGlobalStates: GlobalState<any>[] = []
export const getAllGlobalStateConfigs = () => {
	return allGlobalStates
}

/**
 * Recursively generates the recoil atoms with unique keys in the provided global state group.
 */
const prepareGlobalStateGroup = (globalStateMap: GlobalStateGroup, groupKey: GlobalStateGroupKey = '') => {
	Object.entries(globalStateMap).forEach(([key, value]) => {
		const finalKey: GlobalStateAtomKey = groupKey ? `${groupKey}.${key}` : key

		if (!(value instanceof GlobalState)) {
			if (!key.startsWith(key.substring(0, 1).toUpperCase())) {
				throw new Error(
					`GlobalStateGroup container key must start with an uppercase letter and be in the form of UpperCamelCase: "${key}" is in violation at "${finalKey}"`,
				)
			}

			// Support infinitely nested global state groupings
			prepareGlobalStateGroup(value, finalKey)
			return
		}

		value.initAtomWithKey(finalKey)
		allGlobalStates.push(value)
	})
}

// Recursively generate all the global state atoms
prepareGlobalStateGroup(__rawGlobalStateMap)

export const globalStates = __rawGlobalStateMap

/**
 * @returns Returns a the value and a setter function for a global state value.
 */
export const useGlobalState = <T>(globalState: GlobalState<T>): [T, DraftableSetter<T>] => {
	const [value, setValue] = useRecoilState<T>(globalState.atom)
	return [value, _genGlobalStateDraftSetter(setValue)]
}

/**
 * @returns Returns a global state value.
 */
export const useGlobalStateValue = <T>(globalState: GlobalState<T>) => {
	return useRecoilValue<T>(globalState.atom)
}

/**
 * @returns Returns a setter function for updating a global state value.
 * Does not subscribe the component to changes in the value.
 */
export const useGlobalStateSetter = <T>(globalState: GlobalState<T>) => {
	const setValue = useSetRecoilState<T>(globalState.atom)
	return _genGlobalStateDraftSetter<T>(setValue)
}

/**
 * @returns Returns a setter that provides a mutable draft version of the current state
 * to update in place or return a new value.
 */
export const _genGlobalStateDraftSetter = <T>(setValue): DraftableSetter<T> => {
	return (valueOrFn: ValueOrUpdater<T>) => {
		if (!isFunction(valueOrFn)) {
			const newValue = valueOrFn
			valueOrFn = () => newValue
		}

		setValue((oldValue) => {
			const result = produce(oldValue, valueOrFn)
			return result
		})
	}
}

import getPostInfo from '@/api/http/feeds/getPostInfo'
import { entityTypes } from '@/constants/socket'
import useAuth from '@/hooks/useAuth'
import { useReduxState } from '@/hooks/useReduxState'
import useSocket from '@/hooks/useSocket'
import { useSwrImmutable } from '@/hooks/useSwr'
import { usePubsubPublishers } from '@/lib/pubsub/hooks'
import { preparePostData } from '@/store/feeds'
import { FeedPost } from '@/store/feeds/types'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useFeedPostDeleter } from './useFeedPostDeleter'

export const useFeedPost = (postId, initialPost?: FeedPost) => {
	const reduxState = useReduxState() // entire apps re-renders because of this :(
	const { schoolId, userId } = useAuth()
	const { data, error, mutateWithoutRevalidate } = useSwrImmutable(getPostInfo.buildUrl({ userId, postId, schoolId }), {
		...(initialPost ? { fallbackData: { post: initialPost } } : null),
	})

	const { deletePostById } = useFeedPostDeleter()

	const { subscribeToEntity } = useSocket()

	const { publishFeedPostDeleted } = usePubsubPublishers(schoolId)

	const post = data ? data.post : initialPost

	const shapePost = (post) => preparePostData(post, reduxState)

	const postData = useMemo(() => {
		if (post) {
			return shapePost(post)
		}
		// @TODO: #esLint need to be handled properly
		// @TODO: using entire redux state as dependency not good idea
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post, reduxState])

	useEffect(() => {
		const unsubscribe = subscribeToEntity({
			entityId: postId,
			entityType: entityTypes.post,
			onUpdate: async ({ payload }) => {
				if (isEqual(postData, shapePost(payload))) {
					return
				}
				mutateWithoutRevalidate({ post: payload })
			},
			onDelete: ({ payload }) => {
				publishFeedPostDeleted({ postId: payload.id })
			},
		})

		return unsubscribe
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postData])

	const deletePost = useCallback(() => {
		deletePostById(postId)
		// @TODO: #esLint need to be addressed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postId])

	return { isLoading: !post && !error, post: postData, deletePost }
}

import './PrimaryTheme.css'

const PrimaryTheme = {
	ltr: 'ltr',
	rtl: 'rtl',
	placeholder: 'editor-placeholder',
	paragraph: 'editor-paragraph',
}

export default PrimaryTheme

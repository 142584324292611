import ResearchCard from '@/components/ExperienceCards/ResearchCard'

import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { useGetEntityResponseCountQuery } from '@/features/responses/api'
import useEntityListWithResponse from '@/features/responses/hooks/useEntityListWithResponse'

import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Research from '@/features/research/types/Research'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
}))

const YourResearch = () => {
	const classes = useStyles()

	const { push } = useHistory()

	const { data: count } = useGetEntityResponseCountQuery({ entityType: 'research' })
	const { list: userResearch, isLoading, loadMore, canLoadMore } = useEntityListWithResponse<Research>('research')

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Research',
				onClick: () => push(PATHS.APP.RESEARCH),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const showEmptyPlaceholder = userResearch && !userResearch.length && !isLoading

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="My Research" pageInfo={`${count?.total ?? ''} Interested`} />
			{showEmptyPlaceholder ? (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.research} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						Nothing at the moment!
					</Typography>
					<Typography align="center">Research opportunities that you</Typography>
					<Typography align="center">are interested in will appear here.</Typography>
				</EmptyPlaceholder>
			) : (
				<ExperienceCardsSection
					canLoadMore={canLoadMore}
					handleLoadMoreItems={loadMore}
					items={userResearch}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, startDate, photoUrl, id, researchTerms, researchAreas, workSettings, responseStatus }: any) => (
								<ResearchCard
									key={id}
									id={id}
									title={name}
									photoUrl={photoUrl}
									researchTerms={researchTerms}
									researchAreas={researchAreas}
									workSettings={workSettings}
									status={responseStatus}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourResearch

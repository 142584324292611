import { MenuTextMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin'

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;'
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']'

const DocumentMentionsRegex = {
	NAME,
	PUNCTUATION,
}

const TRIGGERS = ['@'].join('')

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + DocumentMentionsRegex.PUNCTUATION + '\\s]'

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
	'(?:' +
	'\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
	' |' + // E.g. " " in "Josh Duck"
	'[' +
	DocumentMentionsRegex.PUNCTUATION +
	']|' + // E.g. "-' in "Doctor-Smith"
	')'

const LENGTH_LIMIT = 75

const mentionsExp = '(^|\\s|\\()([' + TRIGGERS + ']((?:' + VALID_CHARS + VALID_JOINS + '){0,' + LENGTH_LIMIT + '}))$'
export const AtSignMentionsRegex = new RegExp(mentionsExp, 'g')

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50

// Regex used to match alias.
const aliasExp = '(^|\\s|\\()([' + TRIGGERS + ']((?:' + VALID_CHARS + '){0,' + ALIAS_LENGTH_LIMIT + '}))$'
export const AtSignMentionsRegexAliasRegex = new RegExp(aliasExp, 'g')

export const getPossibleQueryMatch = (text: string): MenuTextMatch | null => {
	return checkForAtSignMentions(text, 1)
}

export const checkForAtSignMentions = (text: string, minMatchLength: number = 0): MenuTextMatch | null => {
	AtSignMentionsRegex.lastIndex = 0
	AtSignMentionsRegexAliasRegex.lastIndex = 0

	let match = AtSignMentionsRegex.exec(text)

	if (match === null) {
		match = AtSignMentionsRegexAliasRegex.exec(text)
	}
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1]

		const matchingString = match[3]
		if (matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: match[2],
			}
		}
	}
	return null
}

import { PATHS } from '@/constants'
import { NOTIFICATION_TYPES } from '@/constants/notifications'
import { ConnectivityStatus } from '@/interfaces/common'
import { AppDispatch } from '@/store'
import { UserNotification } from '@/store/auth/types'
import { changeConnectionStatusRequest } from '@/store/network'
import { setOpenOrgDefaultOptions } from '@/store/organizations'
import { ButtonColors } from '@/components/Buttons/types'
import { RequestType } from '../IndividualPage/OrganizationManageRequestsCard'
import { OrgDashboardActions } from '../IndividualPage/OrganizationOwnerDashboard'

export type TNotificationIcon = {
	type: 'image' | 'icon'
	alt?: string
	url?: string
	externalUserId?: string
}

export type TNotificationButton = {
	label: string
	redirectTo?: string
	onClick?: () => void
	type?: 'outlined' | 'colored'
	color?: ButtonColors
}

type TConfig = {
	icon?: TNotificationIcon
	buttons?: TNotificationButton[]
}

type FnConfigCreator = (notification: UserNotification, dispatch: AppDispatch, currentUserId: number) => TConfig

const createGoToOrganization: (label?: string) => FnConfigCreator =
	(label = 'Go to organization') =>
	({
		data: {
			payload: { organization },
		},
	}) => ({
		icon: {
			type: 'icon',
			url: organization.photoUrl,
		},
		buttons: [
			{
				label: 'Go to organization',
				redirectTo: PATHS.APP.ORGANIZATIONS_SINGLE(`${organization.id}`),
			},
		],
	})
const createGoToGroup: () => FnConfigCreator =
	() =>
	({
		data: {
			payload: { group },
		},
	}) => ({
		icon: {
			type: 'icon',
			alt: group.name,
			url: group.thumbnail,
		},
		buttons: [
			{
				label: 'View Group',
				redirectTo: PATHS.APP.MESSAGES_GROUP(group.id),
			},
		],
	})

const createGoToPost: () => FnConfigCreator =
	() =>
	({ data: { payload } }) =>
		(() => {
			const { post, organization } = payload
			return {
				icon: {
					type: 'image',
					url: organization?.photoUrl ?? post?.photoUrl ?? '',
				},
				buttons: [
					{
						label: 'View Post',
						redirectTo: PATHS.APP.POST_INFO(post?.id),
					},
				],
			}
		})()

const createOrganizationMembershipConfig: (
	type: NOTIFICATION_TYPES.CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_REQUEST | NOTIFICATION_TYPES.NEW_ORGANIZATION_MEMBERSHIP,
) => FnConfigCreator =
	(type) =>
	(
		{
			data: {
				payload: { sourceUser, organization },
			},
		},
		dispatch,
	) => ({
		icon: {
			type: 'image',
			url: sourceUser ? sourceUser.photoUrl : '',
			externalUserId: sourceUser ? sourceUser.externalId : '',
		},
		buttons: [
			{
				label: 'See request',
				redirectTo: PATHS.APP.ORGANIZATIONS_SINGLE(`${organization.id}`),
				onClick: () => {
					dispatch(
						setOpenOrgDefaultOptions({
							scrollTo: type === NOTIFICATION_TYPES.NEW_ORGANIZATION_MEMBERSHIP ? RequestType.JOIN : RequestType.CHANGE_POSITION,
							activeDashboardTab: OrgDashboardActions.MANAGE_MEMBERS,
						}),
					)
				},
			},
		],
	})

export const NOTIFICATIONS_CONFIG: {
	[key in NOTIFICATION_TYPES]?: FnConfigCreator
} = {
	[NOTIFICATION_TYPES.UPCOMING_EVENT]: ({
		data: {
			payload: { eventId },
		},
	}) => ({
		buttons: [
			{
				label: 'See event',
				redirectTo: PATHS.APP.EVENTS_SINGLE(`${eventId}`),
			},
		],
	}),
	[NOTIFICATION_TYPES.NEW_ORGANIZATION_MEMBERSHIP]: createOrganizationMembershipConfig(NOTIFICATION_TYPES.NEW_ORGANIZATION_MEMBERSHIP),
	[NOTIFICATION_TYPES.CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_REQUEST]: createOrganizationMembershipConfig(
		NOTIFICATION_TYPES.CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_REQUEST,
	),
	[NOTIFICATION_TYPES.NEW_ORGANIZATION_MEMBERSHIP_RESPONSE]: createGoToOrganization(),
	[NOTIFICATION_TYPES.CHANGE_ORGANIZATION_MEMBERSHIP_ROLE_RESPONSE]: createGoToOrganization(),
	[NOTIFICATION_TYPES.POST_RESPONSE]: (
		{
			data: {
				payload: { sourceUser, post },
			},
		},
		dispatch,
	) => ({
		icon: {
			type: 'image',
			url: sourceUser.photoUrl,
			externalUserId: sourceUser.externalId,
		},
		buttons: [
			{
				label: 'See Post',
				redirectTo: PATHS.APP.POST_INFO(`${post.id}`),
			},
		],
	}),
	[NOTIFICATION_TYPES.NEW_CONNECTION]: ({ data: { payload } }, dispatch, currentUserId) => {
		const { connectionRequest } = payload
		const connectivityStatus = connectionRequest?.status

		const otherProfile = currentUserId === connectionRequest?.targetUser.id ? connectionRequest?.sourceUser : connectionRequest?.targetUser
		const buttons = []
		const showActionButtons = otherProfile && connectionRequest

		if (connectivityStatus === 'pending') {
			buttons.push({
				label: 'Connect',
				type: 'colored',
				onClick: () => {
					dispatch(changeConnectionStatusRequest({ status: ConnectivityStatus.ACCEPTED, id: connectionRequest.id }))
				},
			})
			buttons.push({
				label: 'Decline',
				type: 'colored',
				color: ButtonColors.GREY,
				onClick: () => {
					dispatch(changeConnectionStatusRequest({ status: ConnectivityStatus.REJECTED, id: connectionRequest.id }))
				},
			})
		} else {
			buttons.push({
				label: 'See Profile',
				redirectTo: PATHS.APP.NETWORK_USER(`${otherProfile.id}`),
			})
		}

		return {
			buttons: showActionButtons ? buttons : [],
			icon: {
				type: 'image',
				url: otherProfile.photoUrl,
				externalUserId: otherProfile.externalId,
			},
		}
	},
	[NOTIFICATION_TYPES.INVITE_TO_GROUP_CHAT]: createGoToGroup(),
	[NOTIFICATION_TYPES.REQUEST_TO_JOIN_GROUP_CHAT_ACCEPTED]: createGoToGroup(),
	// @TODO: backend doesn't send user data so we can show his picture instead of notification bell
	[NOTIFICATION_TYPES.REQUEST_TO_JOIN_GROUP_CHAT]: ({
		data: {
			payload: { groupMemberRequest },
		},
	}) => {
		return {
			buttons: [
				{
					label: 'View Profile',
					redirectTo: PATHS.APP.NETWORK_USER(`${groupMemberRequest.sentBy}`),
				},
			],
		}
	},
	[NOTIFICATION_TYPES.MENTION]: createGoToPost(),
}

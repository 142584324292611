import http from '@/api/http/requestHttp'
// @TODO: #Davit #RTK_QUERY
// Switch to rtk query and create separate type for endpoint params
const getResearchById = async (id: string, schoolId: number) => {
	const url = `${schoolId}/research/${id}`

	return http.get(url, {
		params: {
			withFallBackImage: true,
		},
	})
}

export default getResearchById

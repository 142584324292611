import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import { useCallback } from 'react'

const useOpenCreateModal = (isSchedule: boolean = false) => {
	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)
	const handleOpenModal = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.isOpen = true
			data.isSchedule = isSchedule
		})
	}, [isSchedule, setCreateFeedsModal])

	return handleOpenModal
}

export default useOpenCreateModal

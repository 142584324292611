import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import UserInfo from '@/anthology/types/UserInfo'
import { LocationFilledIcon, OutlinedButton } from '@/components'
import Card from '@/components/IndividualPage/Card'
import InfoRow from '@/components/IndividualPage/InfoRow'
import SocialMedia from '../types/SocialMedia'
import SocialMediaList from './SocialMediaList'

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	links: {
		justifyContent: 'flex-end',
	},
	userInfo: {
		marginBottom: '1.5vh',
		width: '100%',
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	messageButton: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		margin: '0 0.7vw',
		width: 35,
		height: 35,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	messageIcon: {
		width: 20,
		height: 20,
	},
	locationLogo: {
		borderRadius: 10,
		width: 47,
		height: 47,
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.text.secondary,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	userRowContainer: {
		width: '100%',
		margin: 0,
	},
	userRow: {
		margin: 0,
		width: '100%',
	},
	userRowImage: {
		width: 40,
		height: 40,
	},
	userRowButtonsContainer: {
		margin: '15px 0',
	},
	outlinedButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
	},
	contactButton: {
		width: 'fit-content',
	},
	contactInfo: {
		marginBottom: '1.5vh',
	},
	contactInfoContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
}))

interface OrganizationContactCardProps {
	pointOfContact?: UserInfo
	title?: string
	contactEmail: string
	contactName: string
	socialMedia?: SocialMedia
	locationName?: string
	locationAddress?: string
}

const OrganizationContactCard = ({
	pointOfContact,
	locationName,
	title = 'Contact',
	locationAddress,
	contactEmail,
	socialMedia,
	contactName,
}: OrganizationContactCardProps) => {
	const classes = useStyles()

	const handleMailClick = () => {
		window.location.href = `mailto:${contactEmail}`
	}

	return (
		<Card title={title}>
			<div className={classes.row}>
				<div className={classes.contactInfoContainer}>
					<div className={classes.contactInfo}>
						<Typography>{contactName}</Typography>
					</div>
					{contactEmail && (
						<OutlinedButton className={classNames(classes.outlinedButton, classes.contactButton)} onClick={handleMailClick}>
							{contactEmail}
						</OutlinedButton>
					)}
				</div>
			</div>
			<div className={classNames(classes.row, locationName ? '' : classes.links)}>
				{locationName && (
					<InfoRow
						logo={
							<div className={classes.locationLogo}>
								<LocationFilledIcon />
							</div>
						}
						title={locationName}
						subTitle={locationAddress}
					/>
				)}
				{socialMedia && <SocialMediaList {...socialMedia} />}
			</div>
		</Card>
	)
}

export default OrganizationContactCard

import React from 'react'
import useCommonStyles from '../../styles/useCommonStyles'

export type IUserInfoRowProps = {}

const UserInfoRow: React.FC<IUserInfoRowProps> = ({ children }) => {
	const classes = useCommonStyles()
	return <div className={classes.verticalGap}>{children}</div>
}

export default UserInfoRow

import { useAppSelector } from '@/store'
import { selectAuth } from '@/store/auth/selectors'

import { getBaseUrl } from '@/utils/urlHandlers'
import { MutableRefObject, createContext, useContext, useEffect, useRef } from 'react'
import io, { Socket } from 'socket.io-client'
type SocketContextType = {
	connection: MutableRefObject<Socket> | null
}

const endpoint = getBaseUrl()

const config = {
	reconnectionDelay: 10000,
	reconnectionAttempts: 10,
	transports: ['websocket', 'polling'], // Order is important!
	withCredentials: true,
}

const SocketContext = createContext<SocketContextType>(null)

export const useSocketConnection = () => {
	return useContext(SocketContext)
}

export const SocketProvider = ({ children }) => {
	const { token, userId, schoolId } = useAppSelector(selectAuth)

	const socketRef = useRef<Socket | null>(null)

	useEffect(() => {
		if (socketRef.current) {
			socketRef.current.disconnect()
		}
		if (!token || !userId || !schoolId) {
			return
		}
		const newSocket = io(endpoint, {
			...config,
			auth: {
				token,
				userId,
				schoolId,
			},
		})
		socketRef.current = newSocket

		return () => {
			// Clean up the socket connection when the component unmounts
			if (socketRef.current) {
				socketRef.current.disconnect()
			}
		}
	}, [schoolId, token, userId])

	return <SocketContext.Provider value={{ connection: socketRef }}>{children}</SocketContext.Provider>
}

import { TextField } from '@/components'
import { STYLES } from '@/constants'
import { makeStyles } from '@material-ui/core/styles'
import { keyBy, uniqBy } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import CheckBoxCard from '../../components/CheckBoxCard'
import SignUpCard from '../../components/SignUpCard'
import { useSignupStepsContext } from './SignupStepsContext'
import { useSignupStepDataPatcher } from './hooks'
import { CheckboxData } from './types'

import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { SignUpForm } from '@/store/auth/types'
import { rootStyles } from '@/styles'
import { Analytics } from '@/utils/amplitude'
import { UserEvents } from '@navengage/amplitude'
import classNames from 'classnames'
import { isEngage } from '../../constants/configuration'

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	continueButton: {
		marginTop: 15,
		marginBottom: 15,
	},
	card: {
		margin: `${theme.spacing(1)}px 0`,
		width: '100%',
		height: 45,

		border: `1px solid`,
		borderRadius: 4,
		boxShadow: 'none',

		[theme.breakpoints.up('xl')]: {
			height: 50,
		},
	},
	otherChoiceCard: {
		...rootStyles.flexCenteredItems,
		width: '100%',
		boxShadow: 'none',
		borderColor: theme.palette.primary.main,
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: '0',
		},
		'& .MuiInput-underline:before': {
			borderBottom: '0',
		},
		'& .MuiInput-underline:after': {
			borderBottom: '0',
		},
		'& .MuiInput-formControl': {
			width: '100%',
			padding: '0 20px',
		},
	},
	otherChoiceInput: {
		textAlign: 'center',
		height: '100%',
		border: 0,
		boxShadow: 'none',
		color: theme.palette.primary.main,
	},
	cardHeader: {
		width: '70%',
	},
}))

const HowDidYouHearStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const { formData, isStudent } = useSignupStepsContext()

	const {
		school: { applicationName },
	} = useSchoolConfig()

	const [choices, setChoices] = useState<CheckboxData[]>(() => getChoicesByType({ formData, isStudent }))
	const [userInputs, setUserInputs] = useState(initializeUserInputs(choices))

	const disableContinueButton = !checkCanProceed(choices)

	const handleCheckBoxChange = (name: string) =>
		setChoices((oldChoices) => {
			return oldChoices.map((choice) => {
				if (choice.name !== name) {
					return choice
				}

				return { ...choice, checked: !choice.checked }
			})
		})

	useSignupStepDataPatcher(
		{
			// @TODO: this is a hack to get around the fact that we are using the label to store the text input
			howDidYouHearChoices: choices.map((choice) => {
				if (choice.hasTextInput && choice.checked) {
					return {
						...choice,
						label: userInputs[choice.name],
					}
				}
				return choice
			}),
		},
		[choices, userInputs],
	)

	const onTextChange = (e) => {
		const text = e.target.value || ''

		setUserInputs(() => {
			return {
				...userInputs,
				[e.target.name]: text,
			}
		})
	}

	const inputRef = useRef(null)
	const isInputActive = !!inputRef.current

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [isInputActive])

	const onClickContinue = () => {
		onClickNext()
		const selected = choices.filter((c) => c.checked) || []
		Analytics.track(UserEvents.SELECTED_HOW_DID_YOU_HEAR, {
			method: selected.map((s) => s.name).join(', '),
		})
	}

	return (
		<SignUpCard
			classnames={{
				header: classes.cardHeader,
				continueButton: classes.continueButton,
			}}
			title={`How did you hear about ${applicationName}?`}
			continueButtonDisabled={disableContinueButton}
			onClickContinue={onClickContinue}
		>
			<div className={classes.cardContainer}>
				{choices.map((choice) => {
					if (choice.hasTextInput && choice.checked) {
						return (
							<TextField
								key={choice.name}
								onChange={onTextChange}
								value={userInputs[choice.name]}
								className={classNames(classes.card, classes.otherChoiceCard)}
								placeholder={choice.placeholder ?? 'How else did you hear about us?'}
								name={choice.name}
								onBlur={() => {
									// if the user has not entered anything, uncheck the box
									if (!userInputs[choice.name]) {
										handleCheckBoxChange(choice.name)
									}
								}}
								inputProps={{ className: classes.otherChoiceInput, ref: inputRef }}
							/>
						)
					}
					return <CheckBoxCard key={choice.name} className={classes.card} onClick={handleCheckBoxChange} {...choice} />
				})}
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (choices) => {
	return !choices.length || choices.some((box) => box.checked)
}

HowDidYouHearStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.howDidYouHearChoices)
}

const getChoicesByType = ({ formData, isStudent }): CheckboxData[] => {
	const choices: CheckboxData[] = [
		...(isStudent
			? [
					{
						name: 'Friend',
						label: 'Friend',
						checked: false,
						hasTextInput: true,
						placeholder: 'What are their first and last name?',
					},
					{
						name: 'Faculty / Staff',
						label: 'Faculty / Staff',
						checked: false,
						placeholder: 'What are their first and last name?',
						hasTextInput: true,
					},
			  ]
			: [
					{
						name: 'Colleague',
						label: 'Colleague',
						checked: false,
						placeholder: 'What are their first and last name?',
						hasTextInput: true,
					},
					{
						name: 'Student',
						label: 'Student',
						checked: false,
						placeholder: 'What are their first and last name?',
						hasTextInput: true,
					},
			  ]),
		...(isEngage() // @FIXME: This is a temporary inclusion while we aren't pulling in the school config
			? []
			: [
					{
						name: 'The Engagement Space',
						label: 'The Engagement Space',
						checked: false,
					},
			  ]),
		{
			name: 'Social Media',
			label: 'Social Media',
			checked: false,
		},
		{
			name: 'News Story',
			label: 'News Story',
			checked: false,
		},
		{
			name: 'Email',
			label: 'Email',
			checked: false,
		},
		{
			name: 'Meeting/Event',
			label: 'Meeting/Event',
			checked: false,
		},
		{
			name: 'Other',
			label: 'Other',
			checked: false,
			hasTextInput: true,
			placeholder: 'How else did you hear about us?',
		},
	]

	const existingValueNameMap = keyBy(formData.patches.howDidYouHearChoices || [], 'name')

	return uniqBy(choices, 'name').map((choice) => {
		choice.checked = existingValueNameMap[choice.name]?.checked
		return choice
	})
}

/**
 * Initialize the user inputs for the text fields
 *	any text field with the hasTextInput flag will be initialized with an empty string
 * */
const initializeUserInputs = (choices: CheckboxData[]): { [key: string]: string } => {
	return choices.reduce((acc, choice) => {
		if (choice.hasTextInput) {
			acc[choice.name] = ''
		}
		return acc
	}, {} as { [key: string]: string })
}

export default HowDidYouHearStep

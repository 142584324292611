import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.text.secondary,
		margin: '30px 0',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '4vh 0',
		width: '100%',
	},
}))

interface ItemsListProps {
	classnames?: {
		container?: string
		header?: string
		root?: string
	}
	title?: string
	value: any
	onChange: (v: any) => void
	renderItems: (handleSelectItem: (val: any) => void, isChecked: (val: any) => boolean) => React.ReactNode
	canSelectMoreThanOne?: boolean
}

const ItemsList = ({
	classnames = {
		container: '',
		root: '',
		header: '',
	},
	title = '',
	value,
	onChange,
	renderItems,
	canSelectMoreThanOne = true,
}: ItemsListProps) => {
	const classes = useStyles()

	const isChecked = (val: any) => (Array.isArray(value) ? value.includes(val) : value === val)

	const handleSelectItem = (itemValue: string) => {
		const checked = isChecked(itemValue)

		if (checked) {
			onChange(canSelectMoreThanOne ? (value ?? []).filter((v: any) => v !== itemValue) : itemValue)
		} else {
			onChange(canSelectMoreThanOne ? (value ?? []).concat([itemValue]) : itemValue)
		}
	}

	return (
		<div className={classNames(classes.root, classnames.root)}>
			{title && <Typography className={classNames(classes.title, classnames.header)}>{title}</Typography>}
			<div className={classnames.container}>{renderItems(handleSelectItem, isChecked)}</div>
		</div>
	)
}

export default ItemsList

import { Button, Popper } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'

import { IconButton, IconWrapper } from '@/components'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { SharedData, SharedDataTypes } from '@/interfaces/common'
import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import { useAppDispatch } from '@/store'
import { setShareModal } from '@/store/messages'
import { copyToClipboard, createShareLink } from '@/utils/common'

const useStyles = makeStyles(
	({
		colors: { grey },
		palette: {
			action,
			type,
			divider,
			background: { paper, default: defaultBackground },
			text: { primary: primaryText },
		},
	}) => ({
		root: ({ isActive, variant }: any) => ({
			borderRadius: 7,
			height: 40,
			minWidth: 55,
			backgroundColor: isActive ? paper : variant === 'primary' ? defaultBackground : paper,
			border: isActive ? `solid 1px ${primaryText}` : `solid 1px ${divider}`,
			color: primaryText,
			textTransform: 'none',
			'&:hover': {
				backgroundColor: type === 'dark' ? action.hover : grey[200],
				border: `solid 1px ${divider}`,
			},
		}),
		optionsContainer: {
			overflowY: 'auto',
			backgroundColor: paper,
			display: 'flex',
			flexDirection: 'column',
		},
		paper: {
			zIndex: 1300,
			minWidth: 200,
			backgroundColor: paper,
			padding: 10,
			borderRadius: '7px 0 7px 7px',
			border: `solid 1px ${divider}`,
		},
		popoverButton: {
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
	}),
)

interface FeedbackButtonProps {
	className?: string
	disabled?: boolean
	variant?: 'primary' | 'secondary'
	sharedData?: SharedData
	sharingUrl: string
}

const ShareButton = ({ className = '', disabled = false, variant = 'primary', sharedData = {}, sharingUrl = '' }: FeedbackButtonProps) => {
	const dispatch = useAppDispatch()

	const appTheme = useTheme()
	const [open, setOpen] = useState(false)
	const classes = useStyles({ isActive: open, variant })
	const anchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)

	const handleToggle = useCallback((e: any) => {
		e.stopPropagation()
		setOpen((prevOpen) => !prevOpen)
	}, [])

	const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setOpen(false)
	}, [])

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const handlePostToFeed = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.content = { ...data.content, ...sharedData }
		})
		setOpen(false)
	}, [setCreateFeedsModal, sharedData])

	const handleShareViaMessage = useCallback(() => {
		//@ts-ignore
		const type = SharedDataTypes[Object.keys(sharedData)[0]]

		dispatch(setShareModal({ isOpen: true, data: sharedData, type }))
		setOpen(false)
	}, [dispatch, sharedData])

	useOutsideClickCallback(popperRef, handleClose)

	const renderButtons = useMemo(
		() => [
			{
				label: 'Post to Feed',
				onClick: handlePostToFeed,
				icon: <IconWrapper iconKey="plus" color={appTheme.palette.text.primary} />,
			},
			{
				label: 'Direct Message',
				onClick: handleShareViaMessage,
				icon: <IconWrapper iconKey="chatsCircle" color={appTheme.palette.text.primary} />,
			},
			{
				label: 'Copy Link',
				onClick: (e: any) => {
					e.stopPropagation()

					const shareLink = createShareLink(sharingUrl)
					copyToClipboard(shareLink)
					setOpen(false)
				},
				icon: <IconWrapper iconKey="copy" color={appTheme.palette.text.primary} />,
			},
		],
		[appTheme.palette.text.primary, handlePostToFeed, handleShareViaMessage, sharingUrl],
	)

	return (
		<>
			<Popper ref={popperRef} className={classes.paper} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end">
				<div className={classes.optionsContainer}>
					{renderButtons.map((btn) => (
						<IconButton key={btn.label} className={classes.popoverButton} onClick={btn.onClick} startIcon={btn.icon}>
							{btn.label}
						</IconButton>
					))}
				</div>
			</Popper>
			<Button className={classNames(classes.root, className)} ref={anchorRef} onClick={handleToggle} disabled={disabled}>
				<IconWrapper iconKey="shareFat" color={appTheme.palette.text.primary} weight="fill" />
			</Button>
		</>
	)
}

export default ShareButton

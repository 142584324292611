import React from 'react'

import { Link as LinkIcon, InstagramLogo, LinkedinLogo, YoutubeLogo } from '@phosphor-icons/react'

import { addPrefixToLink } from '@/utils/services'
import { Link, makeStyles } from '@material-ui/core'

import { ICON_SIZES } from '@/constants/iconSizes'
import SocialMedia from '../types/SocialMedia'

const useStyles = makeStyles((theme) => ({
	container: {
		width: 'fit-content',
		display: 'flex',
		alignItems: 'center',
	},
	item: {
		margin: '0 0.7vw',
		color: theme.palette.text.primary,
	},
}))

type TSocialMediaKey = keyof SocialMedia

// eslint-disable-next-line no-unused-vars
const SOCIAL_MEDIA_MAP: { [key in TSocialMediaKey]?: any } = {
	linkedInUrl: LinkedinLogo,
	instagramUrl: InstagramLogo,
	youtubeUrl: YoutubeLogo,
	externalWebsite: LinkIcon,
	flickrUrl: Link,
	googlePlusUrl: Link,
	pinterestUrl: Link,
	googleCalendarUrl: Link,
	tumblrUrl: Link,
	vimeoUrl: Link,
}

export type ISocialMediaListProps = SocialMedia

const SocialMediaList: React.FC<ISocialMediaListProps> = (props) => {
	const classes = useStyles()
	const list = (Object.keys(props) as TSocialMediaKey[])
		.filter((key) => !!SOCIAL_MEDIA_MAP[key] && !!props[key])
		.map((key) => ({
			key,
			url: props[key],
			icon: SOCIAL_MEDIA_MAP[key],
		}))

	return (
		<div className={classes.container}>
			{list.map(({ key, url, icon: IconComponent }) => (
				<Link key={key} className={classes.item} href={addPrefixToLink(url)} rel="noreferrer" target="_blank">
					<IconComponent size={ICON_SIZES.lg} />
				</Link>
			))}
		</div>
	)
}

export default SocialMediaList

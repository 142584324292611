import { NavTabs } from '@/components/NavTabs'
import { useOrganizationsControllerFindManyQuery, useOrganizationsControllerGetGridMetaQuery } from '../api/organizationApi'
import { AnalyticsFrameWithRoleCheck } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { DataTableWithRoleCheck } from '../components/dataTable/DataTable'
import { Layout } from '../components/Layout'
import { DashboardType } from '../types/dashboardTypes'

const OrganizationsMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Organizations"
				subHeader="Welcome to the organizations dashboard! Find information about organizations. Click the “DETAILS” tab to view individual organization information. "
			>
				<NavTabs
					initialTab={0}
					withHistory
					tabs={[
						{
							label: 'Analytics',
							component: <AnalyticsFrameWithRoleCheck dashboardType={DashboardType.ORGANIZATIONS} />,
						},
						{
							label: 'Details',
							component: (
								<DataTableWithRoleCheck
									listQuery={useOrganizationsControllerFindManyQuery}
									metaQuery={useOrganizationsControllerGetGridMetaQuery}
								/>
							),
						},
					]}
				/>
			</CommonCard>
		</Layout>
	)
}

export default OrganizationsMainPage

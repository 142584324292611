import http from '@/api/http/requestHttp'

// @TODO: delete after switching to rtk query on schedule posts #cleanup, @refactor
export interface PostCommentReq {
	postId: string
	userId: number
	schoolId: number
}
const deletePost = async ({ postId, userId, schoolId }: PostCommentReq) => {
	const url = `${schoolId}/feeds/${userId}/post/${postId}/delete`

	return http.delete(url)
}

export default deletePost

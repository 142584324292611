import { makeStyles, useTheme } from '@material-ui/core'
import React, { useState } from 'react'

import { CommonModalCard, Modal } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import GoalsForm from '@/components/PathBuilder/GoalsForm'
import { isEngage } from '@/constants/configuration'
import InfoBadge from '@/features/adminConsole/components/Badge'
import { CommonCard } from '@/features/adminConsole/components/CommonCard'
import { formatDateNew } from '@/utils/dateTime2'
import getNameOfPathBuilder from '@/utils/getNameOfPathBuilder'
import { useGetAnalyticsUserGoalsQuery } from '../../../../api/usersApi'
import IUserCardProps from './IUserCardProps'

export interface IUserGoalsCardProps extends IUserCardProps {}

const useStyles = makeStyles((theme) => ({
	modalCard: {
		maxHeight: '800px',
		overflowY: 'auto',
		backgroundColor: theme.palette.background.default,
		boxSizing: 'border-box',
		borderRadius: '10px',
		padding: '0px 20px',
		display: 'block',
	},
}))

const UserGoalsCard: React.FC<IUserGoalsCardProps> = ({ userId, title = getNameOfPathBuilder(isEngage()), ...commonCardProps }) => {
	const classes = useStyles()
	const [selectedGoal, setSelectedGoal] = useState(null)
	const { data, isLoading } = useGetAnalyticsUserGoalsQuery({ userId })
	const theme = useTheme()
	const showGoals = (id) => () => {
		setSelectedGoal(data?.history?.find((goal) => goal.id === id))
	}
	const resetSelectedGoal = () => {
		setSelectedGoal(null)
	}
	return (
		<>
			<CommonCard {...commonCardProps} title={title}>
				{isLoading ? (
					<ContentPreloader />
				) : data?.history.length ? (
					data.history.map((goal) => (
						<InfoBadge
							key={goal.id}
							onClick={showGoals(goal.id)}
							color={theme.palette.success.main}
							label={`Submitted on ${formatDateNew(goal.createdAt, 'dateShort')}`}
						/>
					))
				) : (
					<InfoBadge label="Student has not yet submitted." color={theme.palette.info.main} />
				)}
			</CommonCard>
			<Modal isOpen={!!selectedGoal} onClose={resetSelectedGoal}>
				{selectedGoal && (
					<CommonModalCard
						onClose={resetSelectedGoal}
						title={`Submitted on ${formatDateNew(selectedGoal.createdAt, 'dateShort')}`}
						classnames={{ card: classes.modalCard }}
					>
						<GoalsForm data={selectedGoal} canEdit={false} />
					</CommonModalCard>
				)}
			</Modal>
		</>
	)
}

export { UserGoalsCard }

import { NavTabs } from '@/components/NavTabs'
import { usePostsControllerFindManyQuery, usePostsControllerGetGridMetaQuery } from '../api/feedApi'
import { AnalyticsFrame } from '../components/AnalyticsFrame'
import { CommonCard } from '../components/CommonCard'
import { DataTableWithRoleCheck } from '../components/dataTable/DataTable'
import { Layout } from '../components/Layout'
import { DashboardType } from '../types/dashboardTypes'

const FeedMainPage = () => {
	return (
		<Layout>
			<CommonCard
				title="Feed"
				subHeader="Welcome to the feed dashboard! Find information about posts and post activity. Click the “DETAILS” tab to view individual post information."
			>
				<NavTabs
					initialTab={0}
					withHistory
					tabs={[
						{
							label: 'Posts',
							component: <AnalyticsFrame dashboardType={DashboardType.FEED} />,
						},
						{
							label: 'Comments',
							component: <AnalyticsFrame dashboardType={DashboardType.FEED_COMMENTS} />,
						},
						{
							label: 'Details',
							component: (
								<DataTableWithRoleCheck listQuery={usePostsControllerFindManyQuery} metaQuery={usePostsControllerGetGridMetaQuery} />
							),
						},
					]}
				/>
			</CommonCard>
		</Layout>
	)
}

export default FeedMainPage

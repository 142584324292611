import { TextButton } from '@/components/Buttons'
import IconButton from '@/components/Buttons/IconButton'
import Notification from '@/components/Notifications/Notification'
import { APP_MESSAGES } from '@/constants'
import settings from '@/constants/http'
import { useGetUserNotificationsQuery, useMarkAllReadMutation, usePatchUserNotificationsMutation } from '@/features/notifications/api'
import { useCurrentUser } from '@/hooks/userHooks'
import { NotificationPages } from '@/store/auth/types'
import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import classNames from 'classnames'
import { capitalize, debounce } from 'lodash'
import { useCallback, useMemo, useRef, useState } from 'react'
import FloatingButton from '../Buttons/FloatingButton'

interface StyledProps {
	showDivider?: boolean
}

const useStyles = makeStyles<Theme, StyledProps>((theme) => ({
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
	},
	noMoreMessage: {
		margin: '20px auto',
	},
	rootContainer: ({ showDivider }) => ({
		width: '100%',
		margin: '15px 0',
		borderBottom: showDivider ? `solid 1px ${theme.palette.divider}` : 'none',
	}),
	title: {
		marginBottom: 15,
		fontWeight: 600,
	},
	placeholder: {
		padding: '5px 0 25px 0',
		width: '100%',
	},
	seeMoreButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
		padding: '10px 20px',
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	icon: {
		fontSize: '2.1em',
	},
	seeMoreLink: {
		fontWeight: 600,
	},
}))

type NotificationsBlockProps = {
	classnames?: {
		root?: string
		contentContainer?: string
	}
	navigateToPage?: () => void
	maxToShow?: number
	hideTitle?: boolean
	type?: NotificationPages
	compactView?: boolean
	isLoading?: boolean
	handleClosePopup?: () => void
} & StyledProps

const truncateNotificationList: <T>(list: T[], count?: number) => T[] = (list, count) => {
	if (!count) {
		return list
	}
	return list.slice(0, count)
}

const NotificationsBlock = ({
	classnames = {
		root: '',
		contentContainer: '',
	},
	navigateToPage,
	maxToShow,
	hideTitle,
	type = 'all',
	compactView = false,
	showDivider = true,

	handleClosePopup = () => {},
}: NotificationsBlockProps) => {
	const classes = useStyles({ showDivider })
	const [offset, setOffset] = useState<number>(0)

	const currentUser = useCurrentUser()

	const {
		data: { notifications, unviewed, total },

		isLoading,

		refetch,
	} = useGetUserNotificationsQuery(
		{ userId: currentUser.id, offset, type, perPage: settings.SEARCH_LIST_PER_PAGE },
		{
			selectFromResult: (result) => ({
				...result,
				data: result.data
					? {
							...result.data,
							notifications: truncateNotificationList(result.data.notifications, maxToShow),
					  }
					: { notifications: [], unviewed: 0, total: 0, canLoadMore: true },
			}),
			refetchOnMountOrArgChange: true,
		},
	)

	const canLoadMore = total > notifications.length
	const [markAllRead, { isLoading: isUpdatingList }] = useMarkAllReadMutation()

	const markAllReadHandler = useCallback(async () => {
		setOffset(0)
		await markAllRead({ userId: currentUser.id })

		refetch()
	}, [currentUser.id, markAllRead, refetch])

	const floatingButton = useMemo(() => {
		return <FloatingButton onClick={markAllReadHandler} label={isUpdatingList ? 'Loading ...' : 'Mark All As Read'} />
	}, [isUpdatingList, markAllReadHandler])

	const [patchNotifications] = usePatchUserNotificationsMutation()

	const viewedNotifications = useRef<Map<number, boolean>>(new Map())
	const processingNotifications = useRef<number[]>([])
	const delayedPatchRequest = debounce(async () => {
		await patchNotifications({ userId: currentUser.id, viewedIds: processingNotifications.current, type })
		processingNotifications.current.splice(0, processingNotifications.current.length)
	}, 1000)

	const patchViewedNotifications = useCallback(() => {
		delayedPatchRequest()
	}, [delayedPatchRequest])

	const onNotificationView = useCallback(
		(id: number) => {
			if (!viewedNotifications.current.has(id)) {
				processingNotifications.current.push(id)
				viewedNotifications.current.set(id, true)
				patchViewedNotifications()
			}
		},
		[patchViewedNotifications],
	)

	const handleLoadMore = () => {
		setOffset((prev) => prev + settings.SEARCH_LIST_PER_PAGE)
	}

	const showMarkAllButton = type !== 'past' && !maxToShow && unviewed > settings.SEARCH_LIST_PER_PAGE
	const seeMoreLink = maxToShow && maxToShow <= notifications.length
	return (
		<div className={classNames(classnames.root, classes.rootContainer)}>
			{showMarkAllButton ? floatingButton : null}
			{!hideTitle && <Typography className={classes.title}>{capitalize(type)}</Typography>}
			{(type === 'new' || type === 'all') && !notifications.length && !isLoading ? (
				<div className={classes.placeholder}>
					<Typography>{APP_MESSAGES.NOTIFICATIONS.ALL_CAUGHT_UP}</Typography>
				</div>
			) : (
				<div className={classNames(classnames.contentContainer, classes.contentContainer)}>
					{notifications.map((notification) => {
						return (
							<Notification
								key={notification.id}
								onViewed={onNotificationView}
								compactView={compactView}
								notification={notification}
								handleClosePopup={handleClosePopup}
							/>
						)
					})}
					<div className={classes.controlPanel}>
						{isLoading ? (
							<CircularProgress />
						) : seeMoreLink ? (
							<TextButton className={classes.seeMoreLink} onClick={navigateToPage}>
								{APP_MESSAGES.GENERALS.SEE_MORE}
							</TextButton>
						) : canLoadMore && !maxToShow ? (
							<IconButton
								className={classes.seeMoreButton}
								endIcon={<KeyboardArrowDownIcon className={classes.icon} fontSize="medium" />}
								onClick={handleLoadMore}
							>
								{APP_MESSAGES.GENERALS.SEE_MORE}
							</IconButton>
						) : (
							<Typography className={classes.noMoreMessage}>{APP_MESSAGES.NOTIFICATIONS.NO_MORE} 🎉</Typography>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default NotificationsBlock

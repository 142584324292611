import { SvgIcon } from '@material-ui/core'

const OrganizationCardIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 15 128 128" {...props}>
			<path d="M64 37a23 23 0 000 46 23 23 0 000-46zM34.5 47c-11.5.9-20.3 11.2-18.8 23.1 1.2 9 8.4 16.3 17.4 17.6 4.4.6 8.6-.2 12.2-2 1.2-.6 1.4-2.4.4-3.3C39.1 77.1 35 69 35 60c0-3.6.7-7.1 1.9-10.3.5-1.3-.5-2.7-1.9-2.7h-.5zM93 47c-1.4 0-2.4 1.4-1.9 2.7C92.3 52.9 93 56.4 93 60c0 9-4.1 17.1-10.6 22.4-1.1.9-.9 2.6.4 3.3 3.6 1.8 7.7 2.7 12.2 2 9.1-1.3 16.3-8.6 17.4-17.7 1.5-12-7.6-22.4-19.2-23H93zM64 91c-15 0-29.1 7.4-37.5 19.7-1.7 2.5-2.5 5.4-2.5 8.4 0 1.6 1.4 2.9 3 2.9h74c1.6 0 3-1.3 3-2.9.1-3-.8-6-2.5-8.4C93.1 98.4 79 91 64 91zm-36.334 4.041c-.119.009-.24.027-.365.059-9.9 2.2-18.7 8-24.5 16.4-1.2 2 .2 4.5 2.5 4.5h10.398c1.3 0 2.501-.9 2.801-2.2.4-1.8 1.1-3.5 2.1-5.1v-.1c2.4-3.8 5.3-7.1 8.5-10.1 1.406-1.313.351-3.591-1.434-3.459zm72.568.018c-1.785-.05-2.84 2.222-1.433 3.441 2.9 2.7 5.598 5.7 7.898 9.1.2.3.501.7.801 1.2.9 1.6 1.6 3.2 2.1 5 .3 1.3 1.5 2.1 2.8 2.1h10.4c2.3 0 3.7-2.5 2.4-4.4-5.9-8.4-14.7-14.2-24.6-16.4a2.163 2.163 0 00-.366-.041z" />
		</SvgIcon>
	)
}

export default OrganizationCardIcon

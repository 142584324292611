import { TextButton } from '@/components/Buttons'
import CommonCard from '@/components/CommonCard'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	titleText: {
		fontWeight: 700,
		color: theme.palette.primary.main,
	},
	headerContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
}))

interface SummaryCardProps {
	children?: React.ReactNode
	canEdit?: boolean
	handleOpenEditModal?: () => void
	title?: string
}

const SummaryCard = ({ title = '', children, handleOpenEditModal = () => {}, canEdit = false }: SummaryCardProps) => {
	const classes = useStyles()

	return (
		<CommonCard>
			{title && (
				<div className={classes.headerContainer}>
					<Typography className={classes.titleText} variant="h1">
						{title}
					</Typography>
					{canEdit && <TextButton onClick={handleOpenEditModal}>Edit</TextButton>}
				</div>
			)}
			<div className={classes.content}>{children}</div>
		</CommonCard>
	)
}

export default SummaryCard

const settings = {
	ADMIN_DASHBOARD_PER_PAGE: 10,
	SEARCH_ACTIVITY_LIST_PER_PAGE: 16,
	SEARCH_LIST_PER_PAGE: 16,
	SEARCH_FEEDS_LIST_PER_PAGE: 10,
	SEARCH_COMMENTS_LIST_PER_PAGE: 3,
	SEARCH_MESSAGES_PER_PAGE: 20,
	ORG_MEMBERS_PER_PAGE: 10,
	USERS_PER_PAGE: 20,
	GROUPS_PER_PAGE: 20,
	SELECT_POST_AS_PER_PAGE: 10,
	ADMIN_DASHBOARD_PANEL_PAGE_SIZE: 10,
	DATA_GRID_PAGE_SIZE: 20,
}

export default settings

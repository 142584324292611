import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useState } from 'react'

import { Toggle } from '@/components/widgets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	card: {
		width: '100%',
		height: 50,
		backgroundColor: theme.palette.specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			backgroundColor: theme.palette.specialColors.transparent,
			color: theme.palette.primary.main,
		},
		fontSize: '16px',
	},
	contentContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: '5%',
		alignItems: 'center',
		display: 'flex',
	},
	left: {
		width: '100%',
		alignItems: 'center',
		display: 'flex',
	},
	toggle: {
		margin: 0,
	},
}))

interface FilterToggleProps {
	className?: string
	value?: boolean
	icon?: React.ReactNode
	label: string
	onChange: (v: boolean) => void
	preselectedValue?: boolean
}

const FilterToggle = ({ className = '', icon = null, label, onChange = (v) => {}, value, preselectedValue }: FilterToggleProps) => {
	const classes = useStyles()

	const [isChecked, setIsChecked] = useState(preselectedValue ?? !!value)

	const handleToggle = ({ currentTarget: { checked } }: any) => {
		setIsChecked(checked)
		onChange(checked)
	}

	const handleClickContainer = (e: any) => {
		setIsChecked(!isChecked)
		onChange(!isChecked)
	}

	return (
		<div className={classes.root}>
			<Button className={classNames(classes.card, className)} onClick={handleClickContainer}>
				<div className={classes.contentContainer}>
					<Typography className={classes.left}>
						<span className={classes.icon}>{icon}</span>
						{label}
					</Typography>
					<Toggle className={classes.toggle} value={isChecked} onChange={handleToggle} showLabel={false} labelPosition="bottom" />
				</div>
			</Button>
		</div>
	)
}

export default FilterToggle

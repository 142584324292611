import CheckBoxCard from '@/components/CheckBoxCard'
import { ContentPreloader } from '@/components/ContentPreloader'
import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import useOnboardingEntities from '@/features/onboarding/hooks/useOnboardingEntities'
import { onboardingSlice, selectUserChoicesByType } from '@/features/onboarding/slice'
import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useAppSelector } from '@/store'
import { SignUpForm } from '@/store/auth/types'
import { makeStyles } from '@material-ui/core/styles'
import { styled } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useOnboardingStepConfig, useSignupStepDataPatcher } from '../../hooks'

const useStyles = makeStyles((theme) => ({
	checkboxesContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 15,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},

	cardHeader: {
		width: '70%',
	},
}))

const InterestCheckCard = styled(CheckBoxCard)(({ theme }) => ({
	borderRadius: 9999,
	width: 'fit-content',
	minHeight: 45,
	padding: '5px 20px',
	boxShadow: 'none',
	fontWeight: 500,
	[theme.breakpoints.up('xl')]: {
		minHeight: 50,
	},
}))

export const InterestsStep = ({ onClickNext = () => {} }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const selections = useAppSelector((state) => selectUserChoicesByType(state, { entityType: EntityTypes.interest }))
	const { entities, isLoading } = useOnboardingEntities({ entityType: EntityTypes.interest })
	const interestsConfig = useOnboardingStepConfig('interests')

	const handleCheckBoxChange = useCallback(
		() => (entityId: string) => {
			dispatch(
				onboardingSlice.actions.toggleSelect({ entityType: EntityTypes.interest, entityScope: EntityScope.Standard, entityId: entityId }),
			)
		},
		[dispatch],
	)
	const canClickNext = useMemo(() => !entities.length || checkCanProceed(selections), [selections, entities.length])

	useSignupStepDataPatcher({ interestChoices: selections }, [JSON.stringify(selections)])

	const onClickContinue = () => {
		onClickNext()
	}

	return (
		<SignUpCard
			classnames={{
				header: classes.cardHeader,
			}}
			title={interestsConfig?.title}
			subTitle={interestsConfig?.subTitle}
			continueButtonDisabled={!canClickNext}
			onClickContinue={onClickContinue}
		>
			<div className={classes.checkboxesContainer}>
				{isLoading ? (
					<ContentPreloader />
				) : (
					entities.map((e) => (
						<InterestCheckCard key={e.name} checked={e.checked} name={e.name} label={e.name} id={e.id} onClick={handleCheckBoxChange()} />
					))
				)}
			</div>
		</SignUpCard>
	)
}

const checkCanProceed = (choices) => {
	return choices.filter((box) => !!box.checked).length >= 2
}

InterestsStep.checkIsCompleted = (formData: SignUpForm) => {
	return checkCanProceed(formData.patches.interestChoices)
}

import { IS_ENGAGE } from '@/constants/configuration'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'

export const DEFAULT_TABS = {
	[EntityTypes.user]: {
		label: 'Users',
		entity: EntityTypes.user,
	},
	[EntityTypes.organization]: {
		label: 'Organizations',
	},
} as const

// NOTE: For performance reasons, the (non-)engage-specific value here is *implied* rather than derived in the useMentionAutocomplete hook (via the skip query call).
// If you update this value, be sure to change the behavior there, too
export const DEFAULT_ENTITIES = IS_ENGAGE ? [EntityTypes.user] : [EntityTypes.user, EntityTypes.organization]

import { createEntityAdapter } from '@reduxjs/toolkit'

import { invalidateEntityTags, ONBOARDING_DASH_ENTITY_TAG, onboardingApi } from '../onboarding/api'
import EntityScope from '../onboarding/types/EntityScope'
import EntityTypes from '../shareEntity/types/EntityTypes'
import { DtoAcademicCollege } from './types/DtoAcademicCollege'

const API_BASE_URL = '/academic-colleges'

export const academicCollegesAdapter = createEntityAdapter<DtoAcademicCollege>()

const ACADEMIC_COLLEGES_DASH_ENTITY_TAG = `${ONBOARDING_DASH_ENTITY_TAG}-${EntityTypes.academicCollege}-${EntityScope.Standard}`

export const majorsApi = onboardingApi
	.enhanceEndpoints({
		addTagTypes: ['academicColleges'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			createAcademicColleges: builder.mutation<
				{ entities: DtoAcademicCollege[] },
				{
					entityType?: string
					name?: string
					schoolId: number
					dataSource?: string
					entities?: DtoAcademicCollege[]
				}
			>({
				query: ({ entityType, schoolId, dataSource, name, entities }) => ({
					url: `${API_BASE_URL}`,
					method: 'POST',
					body: {
						...(entities ? { entities } : { name, entityType }),
						dataSource,
						schoolId,
					},
				}),
				invalidatesTags: invalidateEntityTags(EntityTypes.academicCollege, EntityScope.Standard),
			}),
			deleteAcademicColleges: builder.mutation<
				{ entities: string[] },
				{
					entityType: string
					schoolId: number
					entities: string[]
				}
			>({
				query: ({ entityType, schoolId, entities }) => ({
					url: `${API_BASE_URL}`,
					method: 'DELETE',
					params: {
						entityType,
						schoolId,
						entities,
					},
				}),
				invalidatesTags: invalidateEntityTags(EntityTypes.academicCollege, EntityScope.Standard),
			}),
		}),
	})

export const { useCreateAcademicCollegesMutation, useDeleteAcademicCollegesMutation } = majorsApi

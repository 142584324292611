import { paginateQuery } from '@/anthology/utils'
import { PaginationParams } from '@/types/paginationParams'
import { createEntityAdapter } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'

import { baseApi } from '@/anthology/general/baseApi'
import EntityResults from '@/anthology/types/EntityResults'
import ExtendFallBackImageParam from '@/anthology/types/ExtendFallBackImageParam'
import settings from '@/constants/http'
import EntityTypes from '../shareEntity/types/EntityTypes'
import DtoOnboardingEntity, { CreateOnboardingEntityParams } from './types/dto/DtoOnboardingEntity'
import DtoOnboardingEntityResults from './types/dto/DtoOnboardingEntityResults'
import { EntityIdentity } from './types/EntityIdentity'
import EntityScope from './types/EntityScope'
import { TDeleteEntitiesParams } from './types/queryParams/DeleteEntitiesParams'
import { GetOnboardingEntitiesParams } from './types/queryParams/GetOnboardingEntitiesParams'

const API_BASE_URL = '/signup'

export type SearchQuery = {
	q: string
} & EntityIdentity &
	PaginationParams

export const onboardingEntityAdapter = createEntityAdapter<DtoOnboardingEntity>()

export const ONBOARDING_DASH_ENTITY_TAG = 'onboardingDashboardEntities' as const

const generateEntityCacheTag = (entityType: EntityTypes, entityScope: EntityScope) =>
	`${ONBOARDING_DASH_ENTITY_TAG}-${entityType}-${entityScope ?? EntityScope.Standard}`

export const providesTags = (result, error, arg) =>
	arg ? [{ type: generateEntityCacheTag(arg.entityType, arg.entityScope), id: 'LIST' }] : []

const invalidatesTags = (result, error, arg) => [{ type: generateEntityCacheTag(arg.entityType, arg.entityScope), id: 'LIST' }]

export const invalidateEntityTags = (entityType: EntityTypes, entityScope: EntityScope) =>
	invalidatesTags(null, null, { entityType, entityScope })

export const onboardingApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getOnboardingEntities: builder.query<
				DtoOnboardingEntityResults & GetOnboardingEntitiesParams,
				ExtendFallBackImageParam<GetOnboardingEntitiesParams>
			>({
				query: ({ skip = 0, take = settings.SEARCH_LIST_PER_PAGE, withFallBackImage = true, search, ...rest }) => ({
					url: `${API_BASE_URL}/entities`,
					params: {
						...rest,
						withFallBackImage,
						skip,
						take,
						search: search || undefined,
					},
				}),
				transformResponse: (response: DtoOnboardingEntityResults, __, params) => ({
					...response,
					...params,
				}),
				...paginateQuery,
			}),
			getOnboardingEntitiesForDashboard: builder.query<GetOnboardingEntitiesParams, ExtendFallBackImageParam<GetOnboardingEntitiesParams>>({
				transformResponse: (response, meta, arg) => {
					return response
				},
				query: ({
					skip = 0,
					entityType,
					entityScope,
					take = settings.SEARCH_LIST_PER_PAGE,
					withFallBackImage = true,
					search,
					...rest
				}) => ({
					url: `${API_BASE_URL}/entities`,
					params: {
						entityType,
						entityScope,
						withFallBackImage,
						skip,
						take,
						search: search || undefined,
					},
				}),
				providesTags,
			}),
			searchEntities: builder.query<EntityResults<DtoOnboardingEntity>, SearchQuery>({
				query: ({ q, entityType, entityScope, take, skip }) => {
					return {
						url: `${API_BASE_URL}/entities/search`,
						method: 'GET',
						params: {
							q,
							entityType,
							entityScope,
							take,
							skip,
						},
					}
				},
				transformResponse: (response, meta, arg) => {
					return response
				},
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					return `${endpointName}-${queryArgs.entityType}-${queryArgs.entityScope}`
				},
				merge: (currentCache, { items, totalItems }, { arg: { skip, q } }) => {
					if (skip > 0 && !q.length) {
						currentCache.items.push(...items)
						currentCache.totalItems = totalItems
					} else {
						currentCache.items = items
						currentCache.totalItems = totalItems
					}
				},
				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				providesTags,
			}),
			deleteOnboardingEntitiesById: builder.mutation({
				query: ({ entities, entityType, entityScope, schoolId }: TDeleteEntitiesParams) => ({
					url: `${API_BASE_URL}/entities`,
					method: 'DELETE',
					params: {
						entities,
						entityType,
						schoolId,
						entityScope,
					},
				}),
				invalidatesTags,
			}),
			createOnboardingEntities: builder.mutation<EntityResults<DtoOnboardingEntity>, CreateOnboardingEntityParams>({
				query: ({ entities, schoolId, dataSource, entityType, entityScope }) => {
					return {
						url: `${API_BASE_URL}/entities`,
						method: 'POST',
						body: {
							entities,
							dataSource,
							schoolId,
							entityType,
							entityScope,
						},
					}
				},
				invalidatesTags,
			}),
			createSignupExtraEntities: builder.mutation<EntityResults<DtoOnboardingEntity>, CreateOnboardingEntityParams>({
				query: ({ entityType, schoolId, entities }) => {
					return {
						url: `${API_BASE_URL}/entities/extra`,
						method: 'POST',
						body: {
							entities,
							entityType,
							entityScope: EntityScope.Standard,
							schoolId,
						},
					}
				},
				invalidatesTags,
			}),
			deleteSignupExtraEntities: builder.mutation({
				query: ({ entityType, schoolId, entities }) => ({
					url: `${API_BASE_URL}/entities/extra`,
					method: 'DELETE',
					params: {
						entityType,
						schoolId,
						entities,
					},
				}),
				invalidatesTags,
			}),
		}),
	})

export const {
	useGetOnboardingEntitiesQuery,
	useGetOnboardingEntitiesForDashboardQuery,
	useSearchEntitiesQuery,
	useDeleteOnboardingEntitiesByIdMutation,
	useCreateOnboardingEntitiesMutation,
	useCreateSignupExtraEntitiesMutation,
	useDeleteSignupExtraEntitiesMutation,
} = onboardingApi

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Card from '@/components/IndividualPage/Card'
import classNames from 'classnames'
import { addPrefixToLink } from '@/utils/services'
import IconWrapper from '../IconWrapper'

const useStyles = makeStyles(({ palette: { primary, text } }) => ({
	itemContainer: {
		display: 'flex',
		margin: '0.5vh 0',
		alignItems: 'center',
		cursor: 'pointer',
	},
	iconContainer: {
		width: 50,
		height: 50,
		marginRight: 13,
		backgroundColor: primary.light,
		color: primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	learnMoreIcon: {
		backgroundColor: primary.light,
		color: text.primary,
	},
	worldwideLogo: {
		width: 17,
		height: 17,
	},
}))

interface ExternalUrlCardProps {
	url?: string
	label?: string
}

const ExternalUrlCard = ({ url = '', label = 'Learn more about this opportunity' }: ExternalUrlCardProps) => {
	const classes = useStyles()
	const appTheme = useTheme()

	const handleClickLearnMore = () => window.open(addPrefixToLink(url), '_blank')

	if (!url) return null

	return (
		<Card>
			<div className={classes.itemContainer} onClick={handleClickLearnMore}>
				<div className={classNames(classes.iconContainer, classes.learnMoreIcon)}>
					<IconWrapper iconKey="link" className={classes.worldwideLogo} color={appTheme.palette.text.primary} />
				</div>
				<Typography>{label}</Typography>
			</div>
		</Card>
	)
}

export default ExternalUrlCard

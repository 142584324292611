import ColorButton from '@/components/Buttons/ColorButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import LandingLayout from '@/components/LandingLayout'
import { ROLE } from '@/constants'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useAppDispatch } from '@/store'
import { devSignInRequest, devSignUpRequest } from '@/store/auth'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'

import LoginBox from '@/components/LoginBox'
import LoginButton from '@/components/LoginBox/LoginButton'
import { NAV_APP_ID, isNavApp } from '@/constants/configuration'
import useSchoolId from '@/features/school/hooks/useSchoolId'
import { getAuthService } from '@/init/DIContainer'
import { isDevEnv } from '@/utils/authHandlers'
import { useCallback } from 'react'

export const MOBILE_CONTAINER_WIDTH = 92

const useStyles = makeStyles<Theme, { isNavApp: boolean }>((theme) => ({
	root: ({ isNavApp }) => ({
		margin: isNavApp ? 0 : 'auto 5%',
		textAlign: 'center',
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			margin: '10% auto',
			maxWidth: `${MOBILE_CONTAINER_WIDTH}vw`,
		},
		'@media only screen and (max-width: 767px)': isNavApp
			? {
					flexDirection: 'column-reverse',
					display: 'flex',
					height: '100vh',
					justifyContent: 'space-between',
			  }
			: {},
	}),
	welcomeText: {
		fontSize: '45px',
		lineHeight: '1.3',
		[theme.breakpoints.down('md')]: {
			fontSize: '30px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '25px',
		},
		color: theme.palette.common.white,
	},

	button: {
		height: '5.5vh',
		width: '13.5vw',
		minWidth: 180,
		minHeight: 50,
		marginRight: '1%',
		fontSize: '14pt',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			minWidth: 120,
			minHeight: 30,
			marginRight: 0,
			width: '100%',
			marginBottom: '1rem',
		},
	},
	buttonContainer: {
		marginTop: theme.spacing(8.5),
		width: '100%',
	},
	title: {
		color: theme.palette.common.white,
		fontSize: '125px',
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			fontSize: '80px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '60px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '45px',
		},
	},
	logo: {
		height: 230,
		[theme.breakpoints.down('lg')]: {
			height: 195,
		},
		[theme.breakpoints.down('sm')]: {
			height: 135,
		},
		[theme.breakpoints.down('xs')]: {
			height: 75,
		},
	},
	inputEmailContainer: {
		marginBottom: 10,
	},
	emailInput: {
		background: '#ffffff',
		color: 'black',
	},
}))

const isDev = isDevEnv()

const LandingPage = () => {
	const authService = getAuthService()
	const schoolId = useSchoolId()
	const showLoginForm = isNavApp() && schoolId === NAV_APP_ID
	const classes = useStyles({ isNavApp: isNavApp() })
	const dispatch = useAppDispatch()
	const config = useSchoolConfig()
	const loginButtonHandler = useCallback(() => {
		authService.authorize()
	}, [authService])

	const {
		school: { applicationName },
	} = config

	return (
		<LandingLayout>
			<div className={classes.root}>
				{showLoginForm ? (
					<LoginBox />
				) : (
					<>
						<Typography className={classes.welcomeText}>Welcome to</Typography>
						<Typography component="h1" className={classes.title}>
							{applicationName}
						</Typography>
						<LoginButton color={ButtonColors.WHITE} onClick={loginButtonHandler} />
					</>
				)}

				{isDev && (
					<div className={classes.buttonContainer}>
						<ColorButton className={classes.button} onClick={() => dispatch(devSignInRequest(ROLE.STUDENT))}>
							Dev Student Sign In (Maya)
						</ColorButton>
						<ColorButton className={classes.button} onClick={() => dispatch(devSignInRequest(ROLE.FACULTY))}>
							Dev Faculty Sign In (Emma)
						</ColorButton>
						<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={() => dispatch(devSignUpRequest(ROLE.STUDENT))}>
							Dev Student Signup (Noah)
						</OutlinedButton>
						<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={() => dispatch(devSignUpRequest(ROLE.FACULTY))}>
							Dev Faculty Signup (Amelia)
						</OutlinedButton>
						<OutlinedButton className={classes.button} color={ButtonColors.WHITE} onClick={() => dispatch(devSignUpRequest(ROLE.UNKNOWN))}>
							Dev Unknown UserType Signup (Jack)
						</OutlinedButton>
					</div>
				)}
			</div>
		</LandingLayout>
	)
}

export default LandingPage

import { Grid, makeStyles, useTheme } from '@material-ui/core'
import { Eye } from '@phosphor-icons/react'
import React, { useMemo, useState } from 'react'

import { CommonModalCard, Modal } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import { TIconName } from '@/components/IconWrapper'
import { ICON_SIZES } from '@/constants/iconSizes'
import { UserInfo } from '@/interfaces/user'
import { getUserRole, isStudent } from '@/utils/authHandlers'
import { ProfileItemDto, useProfileItemsControllerAggregateCountsByUserIdQuery } from '../../../api/usersProfileItemsApi'
import { generateDynamicLabel, inflectString } from '../../../utils/getTypeLabel'
import { CommonCard } from '../../CommonCard'
import { Stat } from '../../Stat'
import { UsrReportedProfileItemsList } from './UsrReportedProfileItemsList'

const useStyles = makeStyles((theme) => ({
	modalCard: {
		backgroundColor: theme.palette.background.default,
		boxSizing: 'border-box',
		borderRadius: '10px',
		padding: '0px 20px',
		display: 'block',
	},
}))
export type IUserReportedProfileItemsStatsProps = {
	userId: UserInfo['id']
	appUserTypeId: number
}

const ICON_MAPPER: Record<ProfileItemDto['type'], TIconName> = {
	interest: 'interests',
	internship: 'jobs',
	involvement: 'organizations',
	research: 'research',
	studyAbroad: 'study_abroad',
}

const UserReportedProfileItemsStats: React.FC<IUserReportedProfileItemsStatsProps> = ({ userId, appUserTypeId }) => {
	const classes = useStyles()
	const [selectedType, setSelectedType] = useState(null)
	const isStudentProfile = isStudent(getUserRole(appUserTypeId))
	const types = useMemo(() => {
		const profileItemTypes: Array<ProfileItemDto['type']> = []
		if (isStudentProfile) {
			profileItemTypes.push('internship', 'studyAbroad', 'research')
		} else {
			profileItemTypes.push('involvement')
		}
		return profileItemTypes
	}, [isStudentProfile])

	const showList = (type) => () => {
		setSelectedType(type)
	}

	const resetSelectedType = () => {
		setSelectedType(null)
	}

	const { data, isLoading } = useProfileItemsControllerAggregateCountsByUserIdQuery({ id: userId, types })
	const theme = useTheme()
	return (
		<>
			<CommonCard title="User Reported Data">
				{isLoading ? (
					<ContentPreloader />
				) : (
					data && (
						<Grid container spacing={2}>
							{types.map((item) => (
								<Grid key={item} xs={3} item>
									<Stat
										actions={[
											{
												label: 'View',
												icon: <Eye size={ICON_SIZES.md} />,
												onClick: showList(item),
											},
										]}
										borderColor={theme.palette.border.bold}
										color="none"
										count={data[item] || 0}
										text={inflectString({ subj: item, count: data[item] })}
										icon={ICON_MAPPER[item]}
									/>
								</Grid>
							))}
						</Grid>
					)
				)}
			</CommonCard>
			<Modal isOpen={!!selectedType} onClose={resetSelectedType}>
				{selectedType && (
					<CommonModalCard
						onClose={resetSelectedType}
						title={generateDynamicLabel({ prefix: 'User Reported', subj: selectedType })}
						classnames={{ card: classes.modalCard }}
					>
						<UsrReportedProfileItemsList userId={userId} type={selectedType} />
					</CommonModalCard>
				)}
			</Modal>
		</>
	)
}

export default UserReportedProfileItemsStats

import { FeedPost } from '@/store/feeds/types'
import { useCallback, useEffect } from 'react'
import { useDeletePostMutation } from '../../api'
import { useCurrentUser } from '@/hooks/userHooks'
import { useAppDispatch } from '@/store'
import { setDeleteConfirmationModal } from '@/store/feeds'
import { deleteById } from '../../feed/slice'

const usePostDelete = (id: FeedPost['id'], confirmation: boolean = true) => {
	const dispatch = useAppDispatch()

	const currentUser = useCurrentUser()
	const [deletePostMutation, { isSuccess }] = useDeletePostMutation()

	const deletePost = useCallback(() => {
		deletePostMutation({ postId: id, userId: currentUser.id })
	}, [currentUser.id, deletePostMutation, id])

	useEffect(() => {
		if (isSuccess) {
			dispatch(deleteById(id))
			if (confirmation) {
				dispatch(setDeleteConfirmationModal(true))
			}
		}
	}, [confirmation, dispatch, id, isSuccess])

	return deletePost
}

export default usePostDelete

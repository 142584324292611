import { ContentPreloader } from '@/components/ContentPreloader'
import { isEngage } from '@/constants/configuration'
import { makeStyles } from '@material-ui/core'
import { RouteComponentProps } from 'react-router-dom'
import { useGetAnalyticsUserQuery } from '../api/usersApi'
import ErrorResponseCard from '../components/ErrorResponseCard'
import { OrganizationMemberships } from '../components/users/OrganizationMemberships'
import { UserInfoGrid } from '../components/users/UserInfoGrid'
import UserProfileStats from '../components/users/UserProfileStats'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
}))
const UserProfilePage = (props: RouteComponentProps<{ id: string }>) => {
	const classes = useStyles()
	const userId = Number(props.match.params.id)
	const { data, isLoading, isError, error } = useGetAnalyticsUserQuery({ userId })

	if (isError) {
		return 'status' in error ? <ErrorResponseCard error={error} /> : null
	}

	return (
		<div className={classes.root}>
			{isLoading ? (
				<ContentPreloader fullPage />
			) : (
				data && (
					<>
						<UserInfoGrid {...data} />
						<UserProfileStats appUserTypeId={data.appUserTypeId} userId={userId} />
						{!isEngage() ? <OrganizationMemberships userId={userId} /> : null}
					</>
				)
			)}
		</div>
	)
}

export default UserProfilePage

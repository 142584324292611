import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	placeholder: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

const LoadingPlaceholder = () => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.placeholder}>
				<CircularProgress />
			</div>
		</div>
	)
}

export default LoadingPlaceholder

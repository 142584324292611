import PrivateRoute from '@/components/PrivateRoute'
import AppRoute from '@/interfaces/route'
import WebviewRoute from '@/pages/WebviewRoute'
import { Redirect, Route, Switch } from 'react-router-dom'
import ROUTES from '@/routes'
interface RouterProps {
	routes: AppRoute[]
	noMatch?: React.ReactNode
	redirectPaths?: {
		from?: string
		to: string
	}[]
}

const Router = ({ routes, noMatch = null, redirectPaths = [] }: RouterProps) => {
	return (
		<Switch>
			{ROUTES.webview.map((route) => (
				<WebviewRoute key={route.name} {...route} />
			))}
			{routes.map((route) => (
				<PrivateRoute key={route.name} {...route} />
			))}
			{redirectPaths.map(({ from, to }, index) => (
				<Redirect key={`redirect-${index}-${to}`} from={from} to={to} />
			))}
			{noMatch && <Route path="*">{noMatch}</Route>}
		</Switch>
	)
}

export default Router

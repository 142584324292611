import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { UserInfo } from '@/interfaces/user'
interface GetUserActivityParams {
	page: number
	userId: UserInfo['id']
	targetUserId: UserInfo['id']
	schoolId: number
}
const getUserActivity = async ({ userId, targetUserId, page, schoolId }: GetUserActivityParams) => {
	const url = `${schoolId}/activity/${userId}/user`

	const params = {
		page,
		targetUserId,
		perPage: settings.SEARCH_ACTIVITY_LIST_PER_PAGE,
		light: true,
	}

	return http.get(url, { params })
}

export default getUserActivity

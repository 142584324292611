import DeleteConfirmation from '@/components/Feeds/DeleteConfirmation'
import Router from '@/components/Router'
import { PATHS } from '@/constants'
import useDeleteConfirmationModal from '@/hooks/useDeleteConfirmationModal'
import ROUTES from '@/routes'
import useFeed from '../../hooks/useFeed'
import { useAppDispatch } from '@/store'
import { useEffect } from 'react'
import { resetFeedData } from '../../feed/slice'

const Feeds = () => {
	useFeed()
	const [isOpenConfirmationModal, handleCloseDeleteConfirmation] = useDeleteConfirmationModal(PATHS.APP.HOME)
	const dispatch = useAppDispatch()

	useEffect(() => {
		return () => {
			dispatch(resetFeedData())
		}
	}, [dispatch])

	return (
		<>
			<DeleteConfirmation
				isOpenModal={isOpenConfirmationModal}
				handleCloseModal={handleCloseDeleteConfirmation}
				message="Your post has been deleted."
			/>
			<Router routes={ROUTES.feeds} />
		</>
	)
}

export default Feeds

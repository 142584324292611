import { UserInfo } from '@/interfaces/user'
import { RootState } from '@/store'
import { FeedPost } from '@/store/feeds/types'
import { PayloadAction, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { commentsApi } from './api'
import { DtoComment } from './types/DtoComment'

const FEATURE_NAME = 'FEED_COMMENTS'

export const COMMENTS_LIMIT = 5

export const commentsAdapter = createEntityAdapter<DtoComment>({
	sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

const commentUpdatesAdapter = createEntityAdapter<DtoComment>({
	sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

const initialState = commentsAdapter.getInitialState({
	postId: null,
	updates: commentUpdatesAdapter.getInitialState(),
	showUpdates: true,
	initialized: false,
	isLoading: true,
	isFetching: false,
	canLoadMore: false,
	total: 0,
})

export const commentsSlice = createSlice({
	name: FEATURE_NAME,
	initialState: initialState,
	reducers: {
		setCommentsPostId: (state, { payload }: PayloadAction<FeedPost['id']>) => {
			state.postId = payload
		},
		// Using array in case we decide to send a batch of comments
		onCommentReceived: (state, { payload }: PayloadAction<DtoComment[]>) => {
			commentsAdapter.upsertMany(state, payload)
			state.total += payload.length
		},
		deleteById: (state, { payload }: PayloadAction<DtoComment['id']>) => {
			commentsAdapter.removeOne(state, payload)
			state.total -= 1
		},

		commentLiked: (
			state,
			{
				payload: { comment, currentUserId, userId },
			}: PayloadAction<{ comment: DtoComment; userId: UserInfo['id']; currentUserId: UserInfo['id'] }>,
		) => {
			const storeComment = state.entities[comment.id]
			if (storeComment) {
				if (currentUserId === userId) {
					const liked = +storeComment.liked > 0

					commentsAdapter.updateOne(state, { id: comment.id, changes: { ...comment, liked: liked ? '0' : '1' } })
				} else {
					commentsAdapter.updateOne(state, { id: comment.id, changes: { ...comment, liked: storeComment.liked } })
				}
			}
		},
		resetData: (state) => {
			state.total = 0
			state.postId = null
			commentsAdapter.removeAll(state)
			commentUpdatesAdapter.removeAll(state.updates)
			state.showUpdates = true
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(isAnyOf(commentsApi.endpoints.getComments.matchFulfilled), (state, { payload: { total, results } }) => {
				state.total = total
				state.isFetching = false
				state.isLoading = false
				state.initialized = true
				commentsAdapter.upsertMany(state, results)
				state.canLoadMore = total > state.ids.length
			})
			.addMatcher(isAnyOf(commentsApi.endpoints.getComments.matchPending), (state) => {
				if (state.ids.length === 0) {
					state.isLoading = true
				} else {
					state.isFetching = true
				}
			})
			.addMatcher(isAnyOf(commentsApi.endpoints.getComments.matchRejected), (state) => {
				state.isFetching = false
				state.isLoading = false
			})
	},
})

export const { setCommentsPostId, resetData, commentLiked, deleteById, onCommentReceived } = commentsSlice.actions

export const commentsActions = commentsSlice.actions

// selectors

const selectState = (state: RootState) => state[FEATURE_NAME]
const selectCommentsUpdateState = (state: RootState) => state[FEATURE_NAME].updates

export const commentsSelectors = commentsAdapter.getSelectors(selectState)
export const commentUpdatesSelectors = commentUpdatesAdapter.getSelectors(selectCommentsUpdateState)

export const selectListInfo = createSelector(selectState, (state) => ({
	total: state.total,
	canLoadMore: state.canLoadMore,
	isLoading: state.isLoading,
	isFetching: state.isFetching,
}))

export const selectTotal = (state: RootState) => state[FEATURE_NAME].total

import { ButtonColors } from '@/components/Buttons/types'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	root: ({ color }: { color: ButtonColors }) => {
		const buttonColor = theme.palette.buttonColors[color ?? ButtonColors.PRIMARY]
		return {
			color: buttonColor.main,
			'&:hover': {
				backgroundColor: buttonColor.main,
				color: buttonColor.textColor,
			},
			'&:disabled': {
				color: buttonColor.textColor,
				backgroundColor: buttonColor.main,
				'&>span': {
					opacity: 1,
				},
			},
		}
	},
	label: {
		position: 'absolute',
		color: theme.palette.primary.main,
		fontSize: 15,
		width: 'max-content',
		right: '-150%',
		textAlign: 'left',
	},
}))

interface IconButtonWithLabelProps {
	onClick?: (e: any) => void
	label?: string
	children: React.ReactNode
	color?: ButtonColors
	disabled?: boolean
}

const IconButtonWithLabel = forwardRef<any, IconButtonWithLabelProps>(
	({ onClick = () => {}, label, children, color = ButtonColors.PRIMARY, disabled }, ref) => {
		const classes = useStyles({ color })

		return (
			<IconButton ref={ref} onClick={onClick} className={classes.root} disabled={disabled}>
				{children}
				{label && !disabled && <span className={classes.label}>{label}</span>}
			</IconButton>
		)
	},
)

export default IconButtonWithLabel

import { useMemo } from 'react'

import { useSelector } from 'react-redux'

import { selectAllFeatures } from '@/lib/splitio/store'
import { getFeatures, IFeature } from '../lib/splitio'
import useAuth from './useAuth'
import { useCurrentUser } from './userHooks'

export const useMainNavigation = (navigationList?: Array<IFeature>) => {
	// @TODO: this should be moved to useSchoolConfig
	const allFeatures = useSelector(selectAllFeatures)

	const features = getFeatures()
	const { schoolId } = useAuth()
	const currentUser = useCurrentUser()

	const {
		feed,
		myNetwork,
		events,
		organizations,
		volunteers,
		jobs,
		study_abroad,
		research,
		group_chats,
		forms,
		analytics,
		resources,
		paths,
	} = features

	// @TODO: move Enabled to  useFeatures for consistency
	const navigation = useMemo(() => {
		const navigation: IFeature[] =
			navigationList && navigationList.length > 0
				? navigationList
				: [
						feed,
						analytics,
						myNetwork,
						events,
						organizations,
						volunteers,
						jobs,
						study_abroad,
						research,
						group_chats,
						forms,
						resources,
						paths,
				  ]
		const navFilteredByRole = !currentUser.analyticsRole ? navigation.filter((n) => n.featureKey !== 'analytics') : navigation
		return navFilteredByRole.filter((item) => {
			if (item?.featureKey && allFeatures[item?.featureKey] && allFeatures[item?.featureKey][schoolId]?.treatment !== 'control') {
				const featureConfig = allFeatures[item?.featureKey]
				return featureConfig[schoolId].treatment === 'on'
			} else {
				return item.enabled
			}
		})
	}, [
		navigationList,
		feed,
		myNetwork,
		events,
		organizations,
		volunteers,
		jobs,
		study_abroad,
		research,
		group_chats,
		forms,
		paths,
		resources,
		analytics,
		allFeatures,
		currentUser.analyticsRole,
		schoolId,
	])

	return navigation
}

import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import IconWrapper from '../IconWrapper'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
	},
}))

const NextButton = ({ onClick = (e: any) => {}, disabled = false }: any) => {
	const classes = useStyles()
	const appTheme = useTheme()

	return (
		<IconButton className={classes.root} onClick={onClick} disabled={disabled}>
			<IconWrapper
				iconKey="arrowCircleRight"
				weight="fill"
				color={disabled ? appTheme.palette.grey[400] : appTheme.palette.primary.main}
				disabled={disabled}
				size={ICON_SIZES.xxl}
			/>
		</IconButton>
	)
}

export default NextButton

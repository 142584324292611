import { useState } from 'react'
import useSWR from 'swr'
import * as http from '@/api/http'
import useSchoolId from '@/features/school/hooks/useSchoolId'

export const useProfilePhoto = (externalId: string | null, inView?: boolean, cache: boolean = true) => {
	const [loading, setLoading] = useState(!externalId)
	const schoolId = useSchoolId()
	const path = externalId && inView && cache ? `/profile-image/${externalId}` : null

	const { data, ...rest } = useSWR(
		path,
		async () => {
			const {
				data: { image },
			} = await http.user.getUserProfileImage({ externalId, schoolId })
			setLoading(false)

			return image ? `data:image/jpeg;base64,${image}` : null
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return { loading, image: data, ...rest }
}

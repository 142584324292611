import { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import {
	createConnectionRequest,
	getUserInfoRequest,
	getNetworkUserInfo,
	clearUser,
	getNetworkUserActivityState,
	loadUserActivityRequest,
} from '@/store/network'
import UserProfile, { ProfileTabs } from '@/components/UserProfile'
import { getUserInfo } from '@/store/auth'
import { isCurrentUser } from '@/utils/authHandlers'
import { Redirect } from 'react-router-dom'
import { PATHS } from '@/constants'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { getFullName } from '@/utils/common'

const tabs = [ProfileTabs.PROFILE, ProfileTabs.ACTIVITY]

const UserProfilePage = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()
	const userInfo = useSelector(getNetworkUserInfo)
	const userActivity = useSelector(getNetworkUserActivityState)

	const { id: authUserId } = useSelector(getUserInfo)

	const isCurrent = isCurrentUser(authUserId, id)

	const handleCreateRequest = useCallback(() => {
		dispatch(createConnectionRequest(id))
	}, [dispatch, id])

	const handleLoadMoreUserActivity = useCallback(() => dispatch(loadUserActivityRequest({ userId: id })), [dispatch, id])

	useWatchPageTitle({
		watchEntity: userInfo?.firstName ? getFullName(userInfo?.firstName, userInfo?.lastName) : undefined,
	})

	useEffect(() => {
		dispatch(getUserInfoRequest(id))
		dispatch(loadUserActivityRequest({ resetPage: true, userId: id }))

		return () => {
			dispatch(clearUser())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	if (isCurrent) return <Redirect to={PATHS.APP.PROFILE} />

	if (!userInfo) return null

	return (
		<UserProfile
			tabs={tabs}
			userInfo={userInfo}
			handleCreateConnectionRequest={handleCreateRequest}
			activity={userActivity}
			handleLoadMoreUserActivity={handleLoadMoreUserActivity}
		/>
	)
}

export default UserProfilePage

import { IconButton, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { IconWrapper, LocationFilledIcon, OutlinedButton } from '@/components'
import Card from '@/components/IndividualPage/Card'
import InfoRow from '@/components/IndividualPage/InfoRow'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import { PATHS } from '@/constants'
import { ORG_CENTRAL_CONTACT_CARD } from '@/constants/configuration'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useOrganizationMembershipsList from '@/features/organizations/hooks/useOrganizationMembershipsList'
import { getSelectedOrganization } from '@/store/organizations'
import { OrgMembership } from '@/store/organizations/types'
import { getOrganizationRole, getOrganizationType } from '@/utils/organizationRoles'
import { addPrefixToLink } from '@/utils/services'

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	links: {
		justifyContent: 'flex-end',
	},
	userInfo: {
		marginBottom: '1.5vh',
		width: '100%',
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	contactsContainer: {
		width: 'fit-content',
		display: 'flex',
		alignItems: 'center',
	},
	contactLink: {
		margin: '0 0.7vw',
		color: theme.palette.text.primary,
	},
	contactLinkIcon: {
		width: 30,
		height: 30,
	},
	messageButton: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		margin: '0 0.7vw',
		width: 35,
		height: 35,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	messageIcon: {
		width: 20,
		height: 20,
	},
	locationLogo: {
		borderRadius: 10,
		width: 47,
		height: 47,
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.text.secondary,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	userRowContainer: {
		width: '100%',
		margin: 0,
	},
	userRow: {
		margin: 0,
		width: '100%',
	},
	userRowImage: {
		width: 40,
		height: 40,
	},
	userRowButtonsContainer: {
		margin: '15px 0',
	},
	outlinedButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
	},
	contactButton: {
		width: 'fit-content',
	},
	contactInfo: {
		marginBottom: '1.5vh',
	},
	contactInfoContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
}))

interface OrganizationContactCardProps {
	pointOfContact?: OrgMembership
	title?: string
	contactEmail: string
	contactName: string
	youtubeUrl?: string
	websiteUrl?: string
	twitterUrl?: string
	linkedinUrl?: string
	instagramUrl?: string
	facebookUrl?: string
	locationName?: string
	locationAddress?: string
}

const OrganizationContactCard = ({
	pointOfContact,
	locationName,
	title = 'Contact',
	locationAddress,
	contactEmail,
	contactName,
	youtubeUrl,
	websiteUrl,
	twitterUrl,
	linkedinUrl,
	instagramUrl,
	facebookUrl,
}: OrganizationContactCardProps) => {
	const classes = useStyles()
	const { push } = useHistory()
	// @TODO: discuss and remove, possible contact should be defined by backend
	const organization = useSelector(getSelectedOrganization)
	const { memberships: possibleContacts } = useOrganizationMembershipsList({
		id: organization.id,
		membershipTypeIds: [getOrganizationType(ORGANIZATION_ROLE.SUPER_ADMIN)],
	})

	const adminToContact = useMemo(() => {
		if (possibleContacts.length) {
			const signedInAdmin = possibleContacts.find((membership) => !!membership.user)

			if (signedInAdmin) return signedInAdmin
			return possibleContacts[0]
		}

		return null
	}, [possibleContacts])

	const contact = pointOfContact ?? adminToContact

	const handleSendMessage = (e: any) => {
		e.stopPropagation()
		push(PATHS.APP.MESSAGES_USER_DIALOGUE(contact!.user.id))
	}

	const handleMailClick = () => {
		window.location.href = `mailto:${contactEmail}`
	}

	const handleKnownUserMailClick = () => {
		window.location.href = `mailto:${contact.userEmail}`
	}

	const hasContact = !!contact
	const hasUserInfo = hasContact && !!contact.user

	return (
		<Card title={title}>
			<div className={classes.row}>
				{hasContact && title !== ORG_CENTRAL_CONTACT_CARD ? (
					<div className={classes.userInfo}>
						<MembershipInfoRow
							user={contact.user}
							classnames={{
								root: classes.userRowContainer,
								userRow: classes.userRow,
								profileImage: classes.userRowImage,
								buttonsContainer: classes.userRowButtonsContainer,
							}}
							userEmail={contact.userEmail}
							membershipTitle={contact.membershipTitle ?? getOrganizationRole(contact.organizationMembershipTypeId)}
							buttons={
								hasUserInfo ? (
									<IconButton className={classes.messageButton} onClick={handleSendMessage}>
										<IconWrapper iconKey="chatsCircle" className={classes.messageIcon} />
									</IconButton>
								) : (
									<OutlinedButton className={classNames(classes.outlinedButton, classes.contactButton)} onClick={handleKnownUserMailClick}>
										{contact.userEmail}
									</OutlinedButton>
								)
							}
							openProfileOnClick
						/>
					</div>
				) : (
					<div className={classes.contactInfoContainer}>
						<div className={classes.contactInfo}>
							<Typography>{contactName}</Typography>
						</div>
						{contactEmail && (
							<OutlinedButton className={classNames(classes.outlinedButton, classes.contactButton)} onClick={handleMailClick}>
								{contactEmail}
							</OutlinedButton>
						)}
					</div>
				)}
			</div>
			<div className={classNames(classes.row, locationName ? '' : classes.links)}>
				{locationName && (
					<InfoRow
						logo={
							<div className={classes.locationLogo}>
								<LocationFilledIcon />
							</div>
						}
						title={locationName}
						subTitle={locationAddress}
					/>
				)}
				<span className={classes.contactsContainer}>
					{linkedinUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(linkedinUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaLinkedIn" className={classes.contactLinkIcon} />
						</Link>
					)}
					{instagramUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(instagramUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaInstagram" className={classes.contactLinkIcon} />
						</Link>
					)}
					{twitterUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(twitterUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaTwitter" className={classes.contactLinkIcon} />
						</Link>
					)}
					{facebookUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(facebookUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaFacebook" className={classes.contactLinkIcon} />
						</Link>
					)}
					{youtubeUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(youtubeUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="socialMediaYoutube" className={classes.contactLinkIcon} />
						</Link>
					)}
					{websiteUrl && (
						<Link className={classes.contactLink} href={addPrefixToLink(websiteUrl)} rel="noreferrer" target="_blank">
							<IconWrapper iconKey="link" className={classes.contactLinkIcon} />
						</Link>
					)}
				</span>
			</div>
		</Card>
	)
}

export default OrganizationContactCard

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { ConnectivityStatus } from '@/interfaces/common'

interface SearchNetworkParams {
	q?: string
	page: number
	userId: number
	campusId?: number | Array<number>
	connectivityStatus?: ConnectivityStatus
	appUserTypeId?: number
	schoolId: number
}

const searchNetwork = async ({ q, page, userId, campusId, connectivityStatus, appUserTypeId, schoolId }: SearchNetworkParams) => {
	const url = `${schoolId}/users/${userId}/search`

	const params = {
		q,
		page,
		campusId,
		connectivityStatus,
		appUserTypeId,
		perPage: settings.SEARCH_LIST_PER_PAGE,
	}

	return http.get(url, { params })
}

export default searchNetwork

import { Typography, makeStyles } from '@material-ui/core'
import { isString } from 'lodash'
import React from 'react'

import { STYLES } from '@/constants'
import { useAppSelector } from '@/store'
import { getUserInfo } from '@/store/auth'
import { AnalyticsRole } from '../types/AnalyticsRole'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingRight: 10,
		minHeight: 80,
	},
	content: {
		borderLeft: `solid 1px ${theme.palette.grey[300]}`,
		paddingLeft: 10,
	},
	title: {
		fontWeight: 900,
		color: theme.palette.primary.dark,
	},
	subTitle: {
		fontWeight: 800,
		color: theme.palette.primary.main,
		textTransform: 'uppercase',
	},
}))

export interface IHeaderInfoProps {
	title?: string
	subTitle?: string
}

const generateNameFromRole = (role?: AnalyticsRole) => {
	if (isString(role) && role) {
		return role.split(/(?=[A-Z])/).join(' ')
	}
	return null
}

const HeaderInfo: React.FC<IHeaderInfoProps> = ({ title = 'Admin Console', subTitle, children }) => {
	const { analyticsRole } = useAppSelector(getUserInfo)
	const displayedSubTitle = subTitle || generateNameFromRole(analyticsRole)
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography className={classes.title}>{title}</Typography>
				{displayedSubTitle && (
					<Typography className={classes.subTitle} variant="subtitle2">
						{displayedSubTitle}
					</Typography>
				)}
			</div>
			{children}
		</div>
	)
}

export { HeaderInfo }

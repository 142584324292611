import * as http from '@/api/http'
import { SearchConfig, UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import { MODULE_NAME } from '@/store/research/constants'
import { applyInterestedStatus, getCurrentFilterValues } from '@/utils/common'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setResearch = createAction<any>(`${MODULE_NAME}/SET_RESEARCH`)
export const setFilters = createAction<any>(`${MODULE_NAME}/SET_FILTERS`)

export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setUserResearch = createAction<any>(`${MODULE_NAME}/SET_USER_RESEARCH`)
export const setSelectedResearch = createAction<any>(`${MODULE_NAME}/SET_SELECTED_RESEARCH`)
export const setUserResponse = createAction<{ response: UserResponseStatuses; id: string }>(`${MODULE_NAME}/SET_USER_RESPONSE`)

export const loadResearchListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_RESEARCH_LIST_WITH_FILTER_REQUEST`, async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const storedResearch = rootState.RESEARCH.research
		const storedSearch = rootState.RESEARCH.search

		const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

		const res = await http.research.searchResearch({
			q: params?.query,
			page,
			userId,
			campusId: params?.campuses,
			areaId: params?.positionAreas,
			positionTypeId: params?.positionTypes,
			workSettingsId: params?.workSettings,
			termOfAvailabilityId: params?.terms,
			schoolId,
		})

		const research = fetchMore ? [...storedResearch, ...res.data] : res.data

		const researchWithFollowStatus = research.map((res: any) => ({
			...res,
			responseStatus: res.response && res.response.response === UserResponseStatuses.interested ? UserResponseStatuses.interested : null,
		}))

		dispatch(
			setFilters({
				...currentConfig,
				endHasBeenReached: !res.data?.length,
				page,
				filterIsReady,
			}),
		)
		await dispatch(setResearch(researchWithFollowStatus))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadResearchByIdRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_RESEARCH_REQUEST`, async (id: string, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const {
			data: { research },
		} = await http.research.getResearchById(id, schoolId)

		dispatch(setSelectedResearch(applyInterestedStatus(research)))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { SESSION_TIME } from '@/constants/configuration'
import { createOptionMapper } from '@/utils/common'
import { baseApi } from '../general/baseApi'

import StudyAbroadFilters from './types/StudyAbroadFilters'
import DtoStudyAbroadFilter from './types/dto/DtoStudyAbroadFilter'
import { DtoStudyAbroad } from './types/dto/DtoStudyAbroad'
import StudyAbroad from './types/StudyAbroad'
import { createSelector } from '@reduxjs/toolkit'

const BASE_PATH = '/studyAbroad'

export const STUDY_ABROAD_TAGS = {
	SINGLE: 'StudyAbroad',
	FILTERS: 'StudyAbroadFilters',
}

export const studyAbroadApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(STUDY_ABROAD_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getStudyAbroadFilters: builder.query<StudyAbroadFilters, void>({
				query: () => ({
					url: `${BASE_PATH}/filters`,
				}),
				keepUnusedDataFor: SESSION_TIME,
				transformResponse: (result: DtoStudyAbroadFilter) => {
					return {
						locations: result.countries.map(createOptionMapper<string>('country', 'country')),
						housingTypes: result.housingType.map(createOptionMapper<number>('id', 'housingType')),
						scholarshipsGe: result.scholarshipsGe.map(
							createOptionMapper<number>('id', (item: any) => `${item.scholarshipName} - ${item.scholarshipType}`),
						),
					}
				},
				providesTags: [STUDY_ABROAD_TAGS.FILTERS],
			}),
			getStudyAbroad: builder.query<DtoStudyAbroad, { id: StudyAbroad['id'] }>({
				query: ({ id }) => ({
					url: `${BASE_PATH}/${id}`,
				}),
				keepUnusedDataFor: 600,
				providesTags: (data, error) => (!error && data ? [{ type: STUDY_ABROAD_TAGS.SINGLE, id: data.studyAbroad.id }] : []),
			}),
		}),
	})

export const { useGetStudyAbroadFiltersQuery, useGetStudyAbroadQuery } = studyAbroadApi

export const selectStudyAbroad = () => {
	return createSelector(
		(data?: DtoStudyAbroad) => data,
		(data?: DtoStudyAbroad) => {
			if (data && data.studyAbroad) {
				return { ...data.studyAbroad, responseStatus: data.studyAbroad.response?.response }
			} else {
				return null
			}
		},
	)
}

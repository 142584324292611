import { RootState } from '@/store'
import { createSelector } from '@reduxjs/toolkit'

import { ISplitState } from '@splitsoftware/splitio-redux/types/types'
import { IFeature, MODULE_NAME, getFeatures } from '.'
import { resolveSchoolId } from '@/utils'

const selectState = (state: RootState) => state[MODULE_NAME]

const findFeatureByPathName = (pathName: string) =>
	(Object.values(getFeatures()) as IFeature[]).find((feature) => `${feature.path}/`.includes(pathName))

const isDisabled = (feature: IFeature, treatments: ISplitState['treatments']) => {
	const existsInSplit = !!treatments[feature.featureKey] && !!treatments[feature.featureKey][resolveSchoolId()]

	if (existsInSplit) {
		return treatments[feature.featureKey][resolveSchoolId()].treatment === 'off'
	} else {
		return !feature.enabled
	}
}

export const isPathDisabled = (treatments: ISplitState['treatments'], pathName: string) => {
	const currentFeature = findFeatureByPathName(pathName)

	if (currentFeature && isDisabled(currentFeature, treatments)) {
		return true
	} else {
		return false
	}
}

export const selectMemoizedTreatments = createSelector(selectState, (state) => state.treatments)

export const selectAllFeatures = (state: RootState) => state[MODULE_NAME].treatments

export const selectIsRouteRestricted = createSelector(selectAllFeatures, (__, pathName: string) => pathName, isPathDisabled)

import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import { PATHS } from '@/constants'
import SummaryCard from '@/components/PathBuilder/SummaryCard'
import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import { PathBuilderForm } from '@/store/pathBuilder/types'
import { Check, Close } from '@material-ui/icons'
import { useAppDispatch } from '@/store'
import { setIsEditForm, setStartBuilderPath } from '@/store/pathBuilder'
import OrganizationsList from '@/components/PathBuilder/OrganizationsList'
import { setProfileTabToOpen } from '@/store/auth'
import { isString } from 'lodash'
import { isEngage } from '@/constants/configuration'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: '2vh',
	},
	boldText: {
		fontWeight: 700,
	},
	item: {
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 4,
		padding: '7px 14px',
		marginRight: 10,
		marginBottom: 5,
	},
	checkBox: {
		border: `solid 1px ${theme.palette.border.bold}`,
		borderRadius: 4,
		width: 30,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
	},
	itemsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '2vh',
	},
	flexRow: {
		display: 'flex',
		alignItems: 'center',
	},
	greyIcon: {
		color: theme.palette.text.secondary,
	},
	fullWidthItems: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
	},
	fullWidthItem: {
		width: '100%',
	},
}))

interface CheckBoxViewProps {
	title: string
	value?: boolean
}

const CheckBoxView = ({ title, value }: CheckBoxViewProps) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.root, classes.flexRow)}>
			<span className={classes.checkBox}>
				{value ? <Check fontSize="small" /> : <Close className={classes.greyIcon} fontSize="small" />}
			</span>
			<Typography>{title}</Typography>
		</div>
	)
}

interface ItemsViewProps {
	title: string
	items?: string | string[]
	fullWidthItems?: boolean
}

const ItemsView = ({ title, items, fullWidthItems = false }: ItemsViewProps) => {
	const classes = useStyles()

	const itemsArr = Array.isArray(items) ? items : [items]

	if (!items || (Array.isArray(items) && !items.length)) return null

	return (
		<div className={classes.root}>
			<Typography className={classes.boldText}>{title}</Typography>
			<div className={classNames(classes.itemsContainer, fullWidthItems ? classes.fullWidthItems : '')}>
				{itemsArr.map((item) => (
					<span key={item} className={classNames(classes.item, fullWidthItems ? classes.fullWidthItem : '')}>
						{item}
					</span>
				))}
			</div>
		</div>
	)
}

interface GoalsFormProps {
	canEdit?: boolean
	data: PathBuilderForm
}

const GoalsForm = ({ canEdit = false, data }: GoalsFormProps) => {
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const handleEditSection = (stepToOpenIndex: number) => () => {
		dispatch(setStartBuilderPath(true))
		dispatch(
			setIsEditForm({
				isEdit: true,
				stepToOpenIndex,
				formData: data,
			}),
		)
		dispatch(setProfileTabToOpen(1))
		push(PATHS.APP.PATH_BUILDER_QUESTIONS)
	}

	const parseProfessionalGoals = (goalsJSON) => {
		// This is a hacky approach, because of data storage issues in the backend
		try {
			if (goalsJSON.includes('{')) {
				const goals = JSON.parse(goalsJSON)
				return `${goals.answers}${goals.other_answer ? `; ${goals.other_answer}` : ''}`
			} else {
				return goalsJSON
			}
		} catch (error) {
			return 'Not specified'
		}
	}

	const formatEventTypes = (eventTypes) => {
		// This is a hacky approach, because of data storage issues in the backend
		if (typeof eventTypes === 'string') {
			return eventTypes.split(';')
		} else {
			return eventTypes
		}
	}

	return (
		<>
			<SummaryCard title="Events" canEdit={canEdit} handleOpenEditModal={handleEditSection(0)}>
				<ItemsView title="Types of events that I like" items={formatEventTypes(data?.typesOfEvents)} />
			</SummaryCard>
			<SummaryCard title="Organizations" canEdit={canEdit} handleOpenEditModal={handleEditSection(1)}>
				<CheckBoxView title="I’d like to join a new student organization" value={data.wouldLikeToJoinANewStudentOrganization} />
				<ItemsView title="Number of organizations that I’d like to join " items={data.numberOfOrganizationsToJoin} />
				<ItemsView title="Size of organization I’d like to join" items={data.sizeOfOrganizationWouldLikeToJoin} />
				<ItemsView
					title="Description of organization I’d like to join"
					items={data.descriptionOfOrganizationWouldLikeToJoin}
					fullWidthItems
				/>
				{!isEngage() && (
					<>
						<OrganizationsList title="Organizations that I’d like to join " items={data.organizationsWouldLikeToJoin} />
					</>
				)}
			</SummaryCard>
			<SummaryCard title="Volunteer" canEdit={canEdit} handleOpenEditModal={handleEditSection(2)}>
				<CheckBoxView title="I’d like to volunteer" value={data.wouldLikeToVolunteer} />
				<ItemsView title="Causes that I’d like to volunteer for" items={data.causesWouldLikeToVolunteerFor} />
				<ItemsView
					title="Number of hours that I’d like to volunteer"
					items={
						data.numberOfHoursWouldLikeToVolunteer
							? isString(data.numberOfHoursWouldLikeToVolunteer)
								? data.numberOfHoursWouldLikeToVolunteer.replace(';', ' ')
								: Object.values(data.numberOfHoursWouldLikeToVolunteer).join(' ')
							: ''
					}
				/>
				<ItemsView title="When I’d like to volunteer" items={data.whenWouldLikeToVolunteer} />
				<CheckBoxView
					title="I’m interested in joining a student service organization"
					value={data.interestedInJoiningAStudentServiceOrganization}
				/>
			</SummaryCard>
			<SummaryCard title="Education Abroad" canEdit={canEdit} handleOpenEditModal={handleEditSection(3)}>
				<CheckBoxView title="I’d like to study abroad" value={data.wouldLikeToStudyAbroad} />
				{!isEngage() && (
					<>
						<ItemsView title="I’d like to participate in" items={data.wouldLikeToParticipateIn} />
						<ItemsView title="Countries where I’d like to study abroad" items={data.countriesWhereWouldLikeToStudyAbroad} />
						<ItemsView title="While abroad, I’d like to" items={data.whileAbroadWouldLikeTo} />
						<ItemsView title="When I’d like to study abroad" items={data.whenWouldLikeToStudyAbroad} />
					</>
				)}
			</SummaryCard>
			<SummaryCard title="Research" canEdit={canEdit} handleOpenEditModal={handleEditSection(4)}>
				<CheckBoxView
					title="I’d like to complete undergraduate research or creative inquiry"
					value={data.wouldLikeToCompleteUndergraduateResearch}
				/>
				{!isEngage() && (
					<ItemsView
						title="Areas of research or creative inquiry that I’m interested in"
						items={data.areasOfResearchOrCreativeInquiryInterestedIn}
					/>
				)}
				<ItemsView title="When I’d like to complete research" items={data.whenWouldLikeToCompleteResearch} />
			</SummaryCard>
			<SummaryCard title="Professional" canEdit={canEdit} handleOpenEditModal={handleEditSection(5)}>
				{!isEngage() && (
					<>
						<CheckBoxView title="I currently have a job" value={data.currentlyHaveAJob} />
						<CheckBoxView title="I’m currently looking for a job" value={data.currentlyLookingForAJob} />
						{data.currentlyLookingForAJob && (
							<ItemsView title="Type of job that I’m currently looking for" items={data.typeOfJobCurrentlyLookingFor} />
						)}
						<ItemsView
							title="When I’m looking for my next full-time job or internship "
							items={data.whenLookingForNextJobOrInternship || 'I’m not interested'}
						/>
						<ItemsView
							title="Type of full-time job or internship that I’d like to complete"
							items={data.typeOfJobOrInternshipWouldLikeToComplete}
						/>
						<ItemsView title="I’m interested in these services" items={data.interestedInTheseServices} />
					</>
				)}
				<ItemsView
					title="My professional goals for after graduation"
					items={parseProfessionalGoals(data.professionalGoalsForAfterGraduation)}
				/>
			</SummaryCard>
			<SummaryCard title="Out of Classroom" canEdit={canEdit} handleOpenEditModal={handleEditSection(6)}>
				<ItemsView
					title="Experiences that I’d like to have outside of the classroom"
					items={data.experiencesWouldLikeToHaveOutsideOfClassroom}
				/>
			</SummaryCard>
		</>
	)
}

export default GoalsForm

import http from '@/api/http/requestHttp'
import { CommonUserConnectionParams } from './types'

const getUserInfo = async ({ userId, targetUserId, schoolId }: CommonUserConnectionParams) => {
	const url = `${schoolId}/network/${userId}/user/${targetUserId}`

	return http.get(url)
}

export default getUserInfo

import React from 'react'
import { useStyles } from './styles'
import { Card as MaterialCard, Typography, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'

export type IFeedsCardContainerSkeletonProps = {
	variant?: 'primary' | 'secondary'
	containerClasses?: {
		root?: string
		titleContainer?: string
		contentContainer?: string
		footerContainer?: string
	}
}

const useSkeletonStyles = makeStyles((theme) => ({
	button: {
		transform: 'initial',
		height: 50,
	},
}))

const FeedsCardContainerSkeleton: React.FC<IFeedsCardContainerSkeletonProps> = ({ variant = 'primary', containerClasses }) => {
	const classes = useStyles({ primary: variant === 'primary', skeleton: true })
	const skeletonClasses = useSkeletonStyles()

	const contentBlock = (
		<div className={classNames(classes.cardContentInfo, containerClasses?.contentContainer)}>
			<Skeleton width="80%" />
			<Skeleton width="60%" />
		</div>
	)

	const actionButtons = (
		<div className={classNames(classes.cardContentFooter, containerClasses?.footerContainer)}>
			<Skeleton className={classNames(classes.fullWidthButton, skeletonClasses.button)} />
			<Skeleton className={classNames(classes.shareButtonSkeleton, skeletonClasses.button)} />
		</div>
	)

	const titleBlock = (
		<div className={classNames(classes.cardContentTitleContainer, containerClasses?.titleContainer)}>
			<Typography className={classes.cardContentTitle}>
				<Skeleton width="200px" />
			</Typography>
			<Typography className={classNames(classes.cardContentSubTitle, containerClasses?.titleContainer)}>
				<Skeleton width="150px" />
			</Typography>
		</div>
	)
	return (
		<div className={classes.rootContainer}>
			<MaterialCard className={classNames(classes.card, containerClasses?.root)}>
				<div className={classes.cardHeader}>
					<Skeleton style={{ transform: 'initial' }} height="100%" width="100%" />
				</div>
				{variant === 'primary' ? (
					<div className={classes.cardContent}>
						{titleBlock}
						<div className={classes.description}>
							{contentBlock}
							{actionButtons}
						</div>
					</div>
				) : (
					<div className={classes.cardContent}>
						<div className={classes.description}>
							{titleBlock}
							{actionButtons}
						</div>
						{contentBlock}
					</div>
				)}
			</MaterialCard>
		</div>
	)
}

export default FeedsCardContainerSkeleton

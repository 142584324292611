import { generatedBaseApi as api } from './baseGeneratedApi'
export const addTagTypes = ['Analytics Profile Items'] as const
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			profileItemsControllerAggregateCountsByUserId: build.query<
				ProfileItemsControllerAggregateCountsByUserIdApiResponse,
				ProfileItemsControllerAggregateCountsByUserIdApiArg
			>({
				query: (queryArg) => ({ url: `/analytics/users/profile-items/${queryArg.id}/aggregate`, params: { types: queryArg.types } }),
				providesTags: ['Analytics Profile Items'],
			}),
			profileItemsControllerFindManyByUserId: build.query<
				ProfileItemsControllerFindManyByUserIdApiResponse,
				ProfileItemsControllerFindManyByUserIdApiArg
			>({
				query: (queryArg) => ({
					url: `/analytics/users/profile-items/${queryArg.id}/list`,
					params: { take: queryArg.take, skip: queryArg.skip, type: queryArg['type'] },
				}),
				providesTags: ['Analytics Profile Items'],
			}),
			profileItemsControllerGetGridMeta: build.query<ProfileItemsControllerGetGridMetaApiResponse, ProfileItemsControllerGetGridMetaApiArg>(
				{
					query: (queryArg) => ({ url: `/analytics/users/profile-items/grid/meta/${queryArg['type']}` }),
					providesTags: ['Analytics Profile Items'],
				},
			),
			profileItemsControllerGetGridData: build.query<ProfileItemsControllerGetGridDataApiResponse, ProfileItemsControllerGetGridDataApiArg>(
				{
					query: (queryArg) => ({
						url: `/analytics/users/profile-items/grid/data/${queryArg['type']}`,
						params: { take: queryArg.take, skip: queryArg.skip, filters: queryArg.filters, sortBy: queryArg.sortBy },
					}),
					providesTags: ['Analytics Profile Items'],
				},
			),
		}),
		overrideExisting: false,
	})
export { injectedRtkApi as analyticsProfileItemsApi }
export type ProfileItemsControllerAggregateCountsByUserIdApiResponse = /** status 200  */ AggregateCountsDto
export type ProfileItemsControllerAggregateCountsByUserIdApiArg = {
	id: number
	types?: ('research' | 'internship' | 'studyAbroad' | 'interest' | 'involvement')[]
}
export type ProfileItemsControllerFindManyByUserIdApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: ProfileItemDto[]
}
export type ProfileItemsControllerFindManyByUserIdApiArg = {
	id: number
	take?: number
	skip?: number
	type?: 'research' | 'internship' | 'studyAbroad' | 'interest' | 'involvement'
}
export type ProfileItemsControllerGetGridMetaApiResponse = /** status 200  */ GridConfigDto
export type ProfileItemsControllerGetGridMetaApiArg = {
	type: string
}
export type ProfileItemsControllerGetGridDataApiResponse = /** status 200 Successfully received items result */ {
	totalItems?: number
	items?: ProfileItemDto[]
}
export type ProfileItemsControllerGetGridDataApiArg = {
	type: string
	take?: number
	skip?: number
	filters?: QueryGridFiltersDto
	sortBy?: QueryGridSortByDto
}
export type AggregateCountsDto = {
	research?: number
	internship?: number
	studyAbroad?: number
	involvement?: number
	interest?: number
}
export type ProfileItemDto = {
	id: number
	type: 'research' | 'internship' | 'studyAbroad' | 'interest' | 'involvement'
	userId: number
	startDate?: string
	endDate?: string
	createdAt: string
	updatedAt: string
	privacyLevel?: number
	data: object
}
export type GridColumnDto = {
	field: string
	headerName: string
	description?: string
	width?: number
	flex?: number
	minWidth?: number
	maxWidth?: number
	hide?: boolean
	hideable?: boolean
	sortable?: boolean
	editable?: boolean
	groupable?: boolean
	pinnable?: boolean
	searchable?: boolean
	filterable?: boolean
	template?: string
	type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect'
}
export type GridConfigDto = {
	fields: string[]
	dictionary: GridColumnDto[]
	config: object
}
export type QueryFilterItem = {
	field: string
	operator:
		| 'contains'
		| 'equals'
		| 'startsWith'
		| 'endsWith'
		| 'isEmpty'
		| 'isNotEmpty'
		| 'isAnyOf'
		| 'onOrAfter'
		| 'after'
		| 'onOrBefore'
		| 'before'
		| '='
		| '!='
		| '<'
		| '<='
		| '>'
		| '>='
	value?: object
}
export type QueryGridFiltersDto = {
	filters?: QueryFilterItem[]
}
export type QuerySortByItem = {
	field: string
	sort: 'asc' | 'desc'
}
export type QueryGridSortByDto = {
	sortBy?: QuerySortByItem[]
}
export const {
	useProfileItemsControllerAggregateCountsByUserIdQuery,
	useProfileItemsControllerFindManyByUserIdQuery,
	useProfileItemsControllerGetGridMetaQuery,
	useProfileItemsControllerGetGridDataQuery,
} = injectedRtkApi

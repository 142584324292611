import { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { loadNetworkListWithFilterRequest } from '@/store/network/actions'
import { getNetworkUsers, getIsLoading, getEndHasBeenReached, getFilterIsReady, getTotalConnections } from '@/store/network'
import ItemsSection from '@/components/ItemsSections/ItemsSection'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { useHistory } from 'react-router-dom'
import { PATHS, STYLES } from '@/constants'
import { Typography } from '@material-ui/core'
import NetworkUserRow from '@/components/UserNetwork/NetworkUserRow'
import { getUserInfo } from '@/store/auth'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'

const useStyles = makeStyles((theme) => ({
	root: {
		height: 'calc(100% - 90px)',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 90,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			height: '100%',
			marginTop: 0,
		},
		backgroundColor: theme.palette.background.paper,
	},
	placeholderHeader: {
		fontWeight: 700,
		margin: '15px 0',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 48%)`,
		justifyContent: 'space-evenly',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: `repeat(auto-fill, 100%)`,
		},
	},
}))

const Discover = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const network = useSelector(getNetworkUsers)
	const isLoading = useSelector(getIsLoading)
	const filterIsReady = useSelector(getFilterIsReady)
	const endHasBeenReached = useSelector(getEndHasBeenReached)
	const hasConnections = !!useSelector(getTotalConnections)
	const userInfo = useSelector(getUserInfo)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) dispatch(loadNetworkListWithFilterRequest({ fetchMore: true }))
	}, [dispatch, filterIsReady])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Make Connections',
				onClick: () => {
					push(PATHS.APP.NETWORK_SEARCH)
				},
			},
		],
		[push],
	)

	const {
		school: { shortName },
	} = useSchoolConfig()
	const showPlaceholder = !canLoadMore && !isLoading && !network.length

	return (
		<div className={classes.root}>
			{showPlaceholder ? (
				hasConnections ? (
					<NotFoundPlaceholder src={''} isEmptyPlaceholder>
						<Typography align="center">No results found.</Typography>
					</NotFoundPlaceholder>
				) : (
					<NotFoundPlaceholder src={''} isEmptyPlaceholder buttons={placeholderButtons}>
						<Typography align="center" variant="h2" className={classes.placeholderHeader}>
							No connections yet.
						</Typography>
						<Typography align="center">{`${userInfo.firstName}, start growing your network at ${shortName}!`}</Typography>
						<Typography align="center">Connect with students, faculty, and staff.</Typography>
					</NotFoundPlaceholder>
				)
			) : (
				<ItemsSection
					classnames={{
						content: classes.cardContainer,
					}}
					handleLoadMoreItems={handleLoadMore}
					items={network}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ user }: any, idx: number) => (
								<NetworkUserRow key={`${idx}-my-network`} user={user} />
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default Discover

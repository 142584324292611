import OutlinedButton from '@/components/Buttons/OutlinedButton'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'
import { STYLES } from '@/constants'
import { Id } from '@/lib/types'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { toText } from '@/utils/toHTML'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import Editor from '../Editor'
import { OnChangePluginProps, TFnOnChangeParams } from '../Editor/plugins/OnChangePlugin'

const useStyles = makeStyles((theme) => ({
	content: {
		margin: '10px 1.5vw',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
	newCommentRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '1vw 0',
		display: 'flex',
		borderBottom: `solid 1px ${theme.palette.border.bold}`,
		justifyContent: 'space-between',
	},
	postButton: {
		border: 'none',
		height: 47,
		'&:disabled': {
			border: 'none',
			'&>span': {
				opacity: 1,
			},
		},
	},
	textEditor: {
		width: 'calc(100% - 40px)',
		margin: '0 10px',
		marginTop: 13,
	},
	rowContainer: {
		width: 'calc(100% - 100px)',
		display: 'flex',
		margin: '0 15px',
	},
	autoSizer: {
		height: 'fit-content !important',
	},
}))

interface AddNewCommentRowProps {
	postId: Id
	onAddComment: OnChangePluginProps['onChange']
}

const AddNewCommentRow = ({ postId, onAddComment }: AddNewCommentRowProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const currentUser = useSelector(getUserInfo)

	const [commentData, setCommentData] = useState<TFnOnChangeParams>({ data: '' })
	const [resetTextEditorValues, setResetTextEditorValues] = useState(0)
	const { data: message } = commentData
	const hasValue = !!toText(message).trim()

	const handleChangeMessage: OnChangePluginProps['onChange'] = useCallback(({ data, mentions }) => {
		setCommentData({ data, mentions })
	}, [])

	const resetMessage = useCallback(() => {
		setResetTextEditorValues((prev) => prev + 1)
	}, [])

	const handleAddComment = useCallback(() => {
		if (!hasValue) return

		resetMessage()
		onAddComment(commentData)
		setCommentData({ data: '' })
		// @TODO: #esLint need to be addressed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasValue, resetMessage, dispatch, postId, message])

	return (
		<div className={classes.newCommentRow}>
			<span className={classes.rowContainer}>
				<LazyLoadProfileImage className={classes.profileImage} externalUserId={currentUser.externalId} photoUrl={currentUser.photoUrl} />
				<Editor
					reset={resetTextEditorValues}
					styles={{ editorContainer: { top: 10 }, editorInput: { minHeight: 40 } }}
					namespace="CommentEditor"
					placeholder="Add your comment"
					value={message}
					onChange={handleChangeMessage}
				/>
			</span>
			<OutlinedButton className={classes.postButton} onClick={handleAddComment} disabled={!hasValue}>
				Post
			</OutlinedButton>
		</div>
	)
}

export default AddNewCommentRow

import http from '@/api/http/requestHttp'
import { WithTimestamp } from '@/store/types'

export type DtoTermsAndConditions = {
	termsAndConditions: WithTimestamp<{
		activatedDate: string
		fileName: string | null
		id: number
		externalUrl: string
	}>
}

const getLatestTermsAndConditions = async (schoolId: number) => {
	const url = `${schoolId}/terms/latest`

	return http.get<DtoTermsAndConditions>(url)
}

export default getLatestTermsAndConditions

import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'
import GrantPage from '@/pages/PathBuilderPage/GrantPage'
import LastStepPage from '@/pages/PathBuilderPage/LastStepPage'
import PathBuilderSteps from '@/pages/PathBuilderPage/PathBuilderSteps'
import SummaryPage from '@/pages/PathBuilderPage/SummaryPage'
import WelcomePage from '@/pages/PathBuilderPage/WelcomePage'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'
import { isEngage } from '@/constants/configuration'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const PATHBUILDER_WELCOME = createPageTitle(isEngage() ? PAGE_NAME.GOAL_SETTING_WELCOME : PAGE_NAME.PATHBUILDER_WELCOME)
const PATHBUILDER_STEPS = createPageTitle(PAGE_NAME.PATHBUILDER_STEPS)
const PATHBUILDER_GRANT = createPageTitle(PAGE_NAME.PATHBUILDER_GRANT)
const PATHBUILDER_SUMMARY = createPageTitle(PAGE_NAME.PATHBUILDER_SUMMARY)
const PATHBUILDER_LAST_STEP = createPageTitle(PAGE_NAME.PATHBUILDER_LAST_STEP)

const routes: AppRoute[] = [
	{
		component: WelcomePage,
		title: PATHBUILDER_WELCOME,
		name: PATHBUILDER_WELCOME,
		path: PATHS.APP.PATH_BUILDER,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: PathBuilderSteps,
		title: PATHBUILDER_STEPS,
		name: PATHBUILDER_STEPS,
		path: PATHS.APP.PATH_BUILDER_QUESTIONS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: GrantPage,
		title: PATHBUILDER_GRANT,
		name: PATHBUILDER_GRANT,
		path: PATHS.APP.PATH_BUILDER_GRANT,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: SummaryPage,
		title: PATHBUILDER_SUMMARY,
		name: PATHBUILDER_SUMMARY,
		path: PATHS.APP.PATH_BUILDER_SUMMARY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: LastStepPage,
		title: PATHBUILDER_LAST_STEP,
		name: PATHBUILDER_LAST_STEP,
		path: PATHS.APP.PATH_BUILDER_LAST_STEP,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

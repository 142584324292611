import { IconButtonWithTooltip } from '@/components/Buttons'
import { ButtonColors } from '@/components/Buttons/types'
import { ICON_SIZES } from '@/constants/iconSizes'
import useGlobalStyles from '@/styles/globalStyles'
import useOpenCreateModal from '../../hooks/useOpenCreateModal'

import { makeStyles } from '@material-ui/core'
import { PencilSimple } from '@phosphor-icons/react'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	createPost: {
		padding: 0,
		width: 50,
		height: 50,
		position: 'absolute',
		bottom: 10,
		right: 10,
		zIndex: 10,
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
}))

const CreatePostButton = () => {
	const classes = useStyles()
	const globalClasses = useGlobalStyles()
	const openCreatePostModal = useOpenCreateModal()

	return (
		<IconButtonWithTooltip
			classnames={{
				button: classNames(classes.createPost, globalClasses.alignCenter),
			}}
			tooltipTitle="Create Post"
			onClick={openCreatePostModal}
			color={ButtonColors.PRIMARY}
		>
			<PencilSimple size={ICON_SIZES.lg} color={ButtonColors.WHITE} />
		</IconButtonWithTooltip>
	)
}

export default CreatePostButton

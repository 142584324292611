import { FeedPost } from '@/store/feeds/types'
import { Id, Timestamp } from '../types'

export enum PubsubMessageTopic {
	FeedPostCreated,
	FeedPostUpdated,
	FeedPostDeleted,
}
export interface PubsubMessageData {
	[key: string]: any
	postId?: Id
	newPost?: FeedPost
	liked?: boolean
}
export interface PubsubMessage {
	id: string
	publishedAt: Timestamp
	data: PubsubMessageData
}

export interface PubsubMessageHandlerMap {
	[key: string]: (message: PubsubMessage) => void
}

import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback } from 'react'

import { ColorButton, CommonModalCard, Modal } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import { STYLES } from '@/constants'
import { globalStates, useGlobalStateSetter } from '@/lib/globalState'
import SchedulePostList from './List'

const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	blockContainer: {
		padding: 15,
		borderTop: `solid 1px ${theme.colors.grey[200]}`,
	},
	backButton: {
		width: 150,
		paddingTop: 7,
		paddingBottom: 7,
	},
	createButton: {
		width: 150,
		marginRight: theme.spacing(2),
		padding: 7,
	},
}))

interface Props {
	isOpen: boolean
	onClose?: () => void
}

export default function ScheduledPostsListModal({ isOpen, onClose }: Props) {
	const classes = useStyles()

	const setCreateFeedsModal = useGlobalStateSetter(globalStates.createFeedsModal)

	const handleOpenCreateFeedModal = useCallback(() => {
		setCreateFeedsModal((data) => {
			data.isOpen = true
			data.isSchedule = true
		})
	}, [setCreateFeedsModal])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<CommonModalCard title="Scheduled Posts" onClose={onClose}>
				<SchedulePostList />
				<div className={classNames(classes.footer, classes.blockContainer)}>
					<ColorButton color={ButtonColors.GREY} className={classes.createButton} onClick={handleOpenCreateFeedModal}>
						Create
					</ColorButton>
					<ColorButton className={classes.backButton} onClick={onClose}>
						Back
					</ColorButton>
				</div>
			</CommonModalCard>
		</Modal>
	)
}

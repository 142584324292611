import PrimaryTheme from './themes/PrimaryTheme'

import { $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode } from '@lexical/link'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { makeStyles, Theme } from '@material-ui/core'
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles'
import { $getRoot, $insertNodes, LexicalEditor } from 'lexical'

import React, { useCallback, useMemo } from 'react'
import Placeholder from './components/Placeholder'
import { MentionNode } from './nodes/MentionNode'
import AutoLinkPlugin from './plugins/AutLinkPlugin'
import MentionPlugin from './plugins/MentionPlugin'
import OnChangePlugin, { OnChangePluginProps } from './plugins/OnChangePlugin'

const useStyles = makeStyles<Theme, IEditorProps['styles']>((theme) => ({
	editorContainer: (styles) => {
		const {
			top = 0,
			left = 0,
			backgroundColor = theme.palette.background.paper,
			maxWidth = '100%',
			margin = '0',
			...rest
		} = styles?.editorContainer || {}
		return {
			top: `${top}px`,
			left: `${left}px`,
			background: backgroundColor,
			maxWidth,
			color: theme.palette.text.primary,
			position: 'relative',
			textAlign: 'left',
			width: '100%',
			margin,
			...rest,
		}
	},
	editorInput: (styles) => {
		const { minHeight = 200, ...rest } = styles?.editorInput || {}
		return {
			minHeight: `${minHeight}px`,
			resize: 'none',
			caretColor: theme.palette.primary.dark,
			position: 'relative',
			tabSize: 1,
			outline: 'none',
			...rest,
		}
	},
}))

// @TODO: log errors to Data Dog
const onError = (error) => {
	console.error(error)
}
interface IEditorProps {
	namespace?: string
	placeholder: string
	value?: string
	onChange?: OnChangePluginProps['onChange']
	disableMentions?: boolean
	reset?: number
	disabled?: boolean
	styles?: {
		editorContainer?: BaseCSSProperties
		editorInput?: BaseCSSProperties
	}
}

const initStateFromHtml = (html: string) => (editor: LexicalEditor) => {
	const parser = new DOMParser()
	const dom = parser.parseFromString(html, 'text/html')
	const nodes = $generateNodesFromDOM(editor, dom)

	$getRoot().select()

	$insertNodes(nodes)
}

const Editor = ({
	disabled,
	disableMentions = false,
	reset,
	placeholder,
	namespace = 'MainEditor',
	onChange,
	value,
	styles = {},
}: IEditorProps) => {
	const classes = useStyles(styles)
	const initialConfig: InitialConfigType = useMemo(
		() => ({
			editable: !disabled,
			editorState: value ? initStateFromHtml(value) : undefined,
			namespace,
			theme: PrimaryTheme,
			onError,
			nodes: [MentionNode, AutoLinkNode],
		}),

		[disabled, namespace, value],
	)

	const handleChange = useCallback(
		(html) => {
			if (onChange) {
				onChange(html)
			}
		},
		[onChange],
	)

	return (
		<LexicalComposer key={reset} initialConfig={initialConfig}>
			<div className={classes.editorContainer}>
				<RichTextPlugin
					contentEditable={<ContentEditable className={classes.editorInput} />}
					placeholder={<Placeholder>{placeholder}</Placeholder>}
					ErrorBoundary={LexicalErrorBoundary}
				/>
			</div>
			<OnChangePlugin onChange={handleChange} />
			<AutoLinkPlugin />
			{!disableMentions && <MentionPlugin />}
			<AutoFocusPlugin />
		</LexicalComposer>
	)
}

export default React.memo(Editor, (prevProps, nextProps) => {
	return prevProps.namespace === nextProps.namespace && prevProps.reset === nextProps.reset
})

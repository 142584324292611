import { baseApi } from '../general/baseApi'
import { SESSION_TIME } from '@/constants/configuration'
import { DtoCampuses } from './types/DtoGetCampuses'

export const campusApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getCampuses: builder.query<DtoCampuses, void>({
			query: () => ({
				url: `/campus`,
				method: 'GET',
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetCampusesQuery } = campusApi

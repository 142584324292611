import settings from '@/constants/http'
import { getUserInfo } from '@/store/auth'
import { campusSelectors } from '@/features/campus/slice'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMembershipsNormalizedUserData, useGetMembershipsQuery } from '../api'
import MembershipQueryParams from '../types/queryParams/MembershipQueryParams'

const useOrganizationMembershipsList = ({
	id,
	membershipTypeIds,
	limit = settings.ORG_MEMBERS_PER_PAGE,
}: Omit<MembershipQueryParams, 'offset' | 'userId'>) => {
	const campusMap = useSelector(campusSelectors.selectEntities)
	const { id: userId } = useSelector(getUserInfo)
	const [offset, setOffset] = useState<number>(0)

	const selectNormalizedData = useMemo(selectMembershipsNormalizedUserData, [])

	const result = useGetMembershipsQuery(
		{ id, userId, membershipTypeIds, offset, limit },
		{
			selectFromResult: (result) => {
				return {
					...result,
					memberships: selectNormalizedData(result.data, campusMap),
				}
			},
		},
	)
	const loadMore = useCallback(() => {
		setOffset((prev) => prev + limit)
	}, [limit])
	return {
		...result,
		total: result.data ? result.data.total : 0,
		loadMore,
		canLoadMore: result.data ? result.data.total > result.data.memberships.length : true,
	}
}

export default useOrganizationMembershipsList

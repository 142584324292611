import { PATHS } from '@/constants'
import { PAGE_NAME } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import LoginPage from '@/pages/LoginPage'
import { getAllRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'

const LOGIN_PAGE_TITLE = createPageTitle(PAGE_NAME.LOGIN)
const allRolesAllowed = getAllRoles()

const routes: AppRoute[] = [
	{
		component: LoginPage,
		title: LOGIN_PAGE_TITLE,
		name: LOGIN_PAGE_TITLE,
		path: PATHS.LOGIN,
		exact: false,
		isPrivate: false,
		allowedRoles: allRolesAllowed,
	},
]

export default routes

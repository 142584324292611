import { createReducer } from '@reduxjs/toolkit'
import { defaultFilters } from '@/constants/filter'
import * as actions from '@/store/internships/actions'
import { InternshipsState } from '@/store/internships/types'

const initialState: InternshipsState = {
	search: {
		params: {
			query: '',
			...defaultFilters,
		},
		endHasBeenReached: false,
		fetchMore: true,
	},
	internships: [],
	isLoading: false,
	isLoadingIndividualInternship: true,
	isLoadingUserInternships: true,
	employers: [],
	positionTypes: [],
	majors: [],
	classLevels: [],
	userInternships: [],
}

const internshipsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setInternships, (state, action) => ({
			...state,
			internships: action.payload,
		}))
		.addCase(actions.clearFilters, (state) => ({
			...state,
			search: {
				...initialState.search,
			},
			internships: [],
		}))

		.addCase(actions.setFilters, (state, action) => {
			const { params, ...rest } = action.payload

			return {
				...state,
				search: {
					...state.search,
					params,
					...rest,
				},
			}
		})
		.addCase(actions.setUserInternships, (state, action) => ({
			...state,
			userInternships: action.payload,
		}))
		.addCase(actions.setIndividualInternships, (state, action) => ({
			...state,
			selectedInternship: action.payload,
		}))
		.addCase(actions.loadUserInternshipsRequest.pending, (state) => ({
			...state,
			isLoadingUserInternships: true,
		}))
		.addCase(actions.loadUserInternshipsRequest.fulfilled, (state) => ({
			...state,
			isLoadingUserInternships: false,
		}))
		.addCase(actions.loadUserInternshipsRequest.rejected, (state) => ({
			...state,
			isLoadingUserInternships: false,
		}))
		.addCase(actions.loadInternshipByIdRequest.pending, (state) => ({
			...state,
			isLoadingIndividualInternship: true,
		}))
		.addCase(actions.loadInternshipByIdRequest.fulfilled, (state) => ({
			...state,
			isLoadingIndividualInternship: false,
		}))
		.addCase(actions.loadInternshipByIdRequest.rejected, (state) => ({
			...state,
			isLoadingIndividualInternship: false,
		}))
		.addCase(actions.setUserResponse, (state, { payload: { id, response } }) => {
			if (state.selectedInternship && state.selectedInternship.id === id) {
				state.selectedInternship.responseStatus = response
			}
			const item = state.internships.find((r) => r.id === id)
			if (item) {
				item.responseStatus = response
			}
		})
})

export { internshipsReducer }

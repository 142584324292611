import { Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { BlockOutlined } from '@material-ui/icons'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { ColorButton, DotButton, LazyLoadProfileImage, ModalWithSubmittedPopup, OutlinedButton, TextButton } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import Card from '@/components/CommonCard'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import { ReportSubject } from '@/components/ReportModal'
import BlockUserPopup from '@/components/UserNetwork/BlockUserPopup'
import CancelRequestModal from '@/components/UserNetwork/CancelRequestModal'
import { PATHS, STYLES } from '@/constants'
import { ConnectivityStatus, ReportActions, UserData } from '@/interfaces/common'
import { NetworkUserInfo } from '@/interfaces/user'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import { clearNewMessageContent } from '@/store/messages'
import { changeConnectionStatusRequest, deleteConnectionRequest, postUserResponseRequest } from '@/store/network'
import { getFullName, getRoleBadgeLabel } from '@/utils/common'
import { UserPrimaryTitle } from './UserPrimaryTitle'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
	userName: {
		marginBottom: '0.5vh',
	},
	userNameText: {
		marginRight: '1vw',
	},
	profileImage: {
		borderRadius: '50%',
		width: 152,
		height: 152,
		minWidth: 152,
		minHeight: 152,
		marginBottom: '2.5vh',
		[theme.breakpoints.up('lg')]: {
			width: 152,
			height: 152,
			minWidth: 152,
			minHeight: 152,
		},
		[theme.breakpoints.down('md')]: {
			width: 102,
			height: 102,
			minWidth: 102,
			minHeight: 102,
		},
		[theme.breakpoints.down('xs')]: {
			width: 52,
			height: 52,
			minWidth: 52,
			minHeight: 52,
		},
	},
	spaceBetweenContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	roleMark: {
		margin: '0 25px',
		padding: '1vh 1.7vw',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
	},
	activityInfoContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	activityInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 25px',
		borderRight: `solid 1px ${theme.palette.divider}`,
	},
	profileTabs: {
		width: '29%',
		minWidth: 210,
		...theme.typography.body1,
	},
	divider: {
		borderBottom: `solid 1px ${theme.palette.divider}`,
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	connectControlSection: {
		marginTop: '2vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	connectButtonsContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 3%',
	},
	connectButtonsColumnsContainer: {
		width: '100%',
		maxWidth: 300,
		height: 86,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 3%',
	},
	connectButton: {
		width: '48%',
	},
	connectButtonFullWidth: {
		width: '100%',
		'&:first-child': {
			marginBottom: 10,
		},
	},
	singleConnectButton: {
		margin: '0.5vh 3%',
	},
	infoFooterContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	blackText: {
		color: theme.palette.text.primary,
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	rootCard: {
		paddingBottom: 0,
		backgroundColor: theme.palette.background.paper,
	},
}))

interface UserInfoCardProps {
	activeTab: number
	handleChangeTab: (id: number) => void
	tabs: string[]
	userInfo: NetworkUserInfo
	isPublicProfileView?: boolean
	handlePublicProfileView?: () => void
	handleSendConnectRequest?: () => void
	isMyProfile?: boolean
	numberOfConnections?: number
	numberOfPosts?: number
	handleClickNumberOfConnections?: () => void
}

const getRequestToCancel = (requests: any[] | undefined, currentUserId: number, networkUserId: number) => {
	if (!requests?.length) {
		return undefined
	}

	const request = requests[0]

	const { targetUserId, sourceUserId, status } = request

	if (sourceUserId === +currentUserId && targetUserId === +networkUserId && status === ConnectivityStatus.PENDING) {
		return request
	}
}

const getRequestToChangeStatus = (requests: any[] | undefined, currentUserId: number, networkUserId: number) => {
	if (!requests?.length) {
		return undefined
	}

	const request = requests[0]

	const { targetUserId, sourceUserId, status } = request

	if (targetUserId === +currentUserId && sourceUserId === +networkUserId && status === ConnectivityStatus.PENDING) {
		return request
	}
}

const UserInfoCard = ({
	activeTab,
	handleChangeTab,
	tabs,
	userInfo: {
		id,
		externalId,
		appUserTypeId,
		firstName,
		lastName,
		preferredPronouns,
		primaryTitle,
		primaryTitleDepartment,
		department,
		positionTitle,
		connectionRequests,
		connectionStatus,
		numberOfPosts,
		numberOfConnections,
		photoUrl,
		isArchived,
	},
	isMyProfile = true,
	handlePublicProfileView,
	isPublicProfileView = false,
	handleSendConnectRequest = () => {},
	handleClickNumberOfConnections,
}: UserInfoCardProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const { push } = useHistory()
	const dispatch = useAppDispatch()
	const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
	const [isOpenBlockUserPopup, setIsOpenBlockUserPopup] = useState(false)
	const currentUserId = useSelector(getUserInfo).id

	const [isOpenDeleteConnectionModal, setIsOpenDeleteConnectionModal] = useState(false)

	const isUserConnected = connectionStatus === ConnectivityStatus.ACCEPTED
	const isPendingStatus = connectionStatus === ConnectivityStatus.PENDING

	const requestToCancel = useMemo(() => getRequestToCancel(connectionRequests, currentUserId, id), [connectionRequests, currentUserId, id])
	const requestToChangeStatus = useMemo(
		() => getRequestToChangeStatus(connectionRequests, currentUserId, id),
		[connectionRequests, currentUserId, id],
	)

	const shouldShowConnectButton = !isUserConnected && !isPendingStatus && !isArchived
	const shouldShowDisconnectButton = isUserConnected
	const shouldShowCancelRequestButton = isPendingStatus && !requestToChangeStatus && !!requestToCancel
	const shouldShowAcceptButton = isPendingStatus && !requestToCancel && !!requestToChangeStatus
	const shouldShowDeclineButton = isPendingStatus && !requestToCancel && !!requestToChangeStatus

	const fullName = getFullName(firstName, lastName)

	const activityInfo = useMemo(
		() => [
			{
				count: numberOfPosts,
				type: 'Posts',
			},
			{
				count: numberOfConnections,
				type: 'Connections',
				onClick: handleClickNumberOfConnections,
			},
		],
		[numberOfPosts, handleClickNumberOfConnections, numberOfConnections],
	)

	const handleReportUser = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(
				postUserResponseRequest({
					id: +id,
					status,
					reportMessage,
				}),
			)
		},
		[dispatch],
	)

	const handleDeleteConnection = useCallback(() => {
		dispatch(deleteConnectionRequest(id))
	}, [dispatch, id])

	const handleChangeRequest = useCallback(
		(status) => () => {
			const reqId = status === ConnectivityStatus.CANCELED ? requestToCancel?.id : requestToChangeStatus?.id

			if (reqId) {
				dispatch(changeConnectionStatusRequest({ status, id: reqId }))
			}
		},
		[dispatch, requestToCancel?.id, requestToChangeStatus?.id],
	)

	const handleOpenCancelModal = useCallback(() => setIsOpenCancelModal(true), [])
	const handleCloseCancelModal = useCallback(() => setIsOpenCancelModal(false), [])

	const handleOpenBlockUserPopup = useCallback(() => setIsOpenBlockUserPopup(true), [])
	const handleCloseBlockUserPopup = useCallback(() => {
		setIsOpenBlockUserPopup(false)
		handleReportUser(`${id}`, ReportActions.blockUser, '')
		push(PATHS.APP.NETWORK_SEARCH)
	}, [handleReportUser, id, push])

	const handleSendMessage = useCallback(
		(user: UserData) => () => {
			dispatch(clearNewMessageContent())
			push(PATHS.APP.MESSAGES_USER_DIALOGUE(user.id))
		},
		[dispatch, push],
	)

	const handleCloseDeleteConnectionModal = () => setIsOpenDeleteConnectionModal(false)
	const handleOpenDeleteConnectionModal = () => setIsOpenDeleteConnectionModal(true)

	const reportButtons = useMemo(
		() => [
			{
				label: 'Block this user',
				onClick: handleOpenBlockUserPopup,
				icon: <BlockOutlined />,
			},
		],
		[handleOpenBlockUserPopup],
	)

	const getConnectionButtons = () => {
		if (isMyProfile) return null

		if (isArchived) {
			return (
				<ColorButton disabled className={classNames(classes.connectButton, classes.singleConnectButton)}>
					User Archived
				</ColorButton>
			)
		}
		if (shouldShowConnectButton) {
			return (
				<ColorButton className={classNames(classes.connectButton, classes.singleConnectButton)} onClick={handleSendConnectRequest}>
					Connect
				</ColorButton>
			)
		} else {
			return (
				<div className={classes.connectButtonsContainer}>
					{shouldShowAcceptButton && (
						<ColorButton
							className={classNames(classes.connectButton, classes.singleConnectButton)}
							onClick={handleChangeRequest(ConnectivityStatus.ACCEPTED)}
						>
							Connect
						</ColorButton>
					)}
					{shouldShowCancelRequestButton && (
						<ColorButton color={ButtonColors.PRIMARY} className={classes.connectButtonFullWidth} onClick={handleOpenCancelModal}>
							Connection Request Sent!
						</ColorButton>
					)}
					{shouldShowDisconnectButton && (
						<OutlinedButton
							color={ButtonColors.GREY}
							className={classNames(classes.connectButton, classes.blackText)}
							onClick={handleOpenDeleteConnectionModal}
						>
							Connected
						</OutlinedButton>
					)}
					{shouldShowDisconnectButton && (
						<ColorButton
							className={shouldShowCancelRequestButton ? classes.connectButtonFullWidth : classes.connectButton}
							onClick={handleSendMessage({
								id,
								externalId,
								firstName,
								lastName,
								fullName,
							})}
						>
							Message
						</ColorButton>
					)}
					{shouldShowDeclineButton && (
						<ColorButton
							color={ButtonColors.GREY}
							className={shouldShowCancelRequestButton ? classes.connectButtonFullWidth : classes.connectButton}
							onClick={handleChangeRequest(ConnectivityStatus.REJECTED)}
						>
							Decline
						</ColorButton>
					)}
				</div>
			)
		}
	}

	return (
		<Card className={classes.rootCard}>
			<ModalWithSubmittedPopup
				isOpen={isOpenDeleteConnectionModal}
				onSubmit={handleDeleteConnection}
				onClose={handleCloseDeleteConnectionModal}
				title="Disconnect"
				content={['Are you sure you want to disconnect from this user?']}
				submittedMessage={['This user has been disconnected.']}
				submitButtonLabel="Yes"
				submitButtonColor={appTheme.colors.red[500]}
			/>
			<CancelRequestModal
				isOpenModal={isOpenCancelModal}
				handleCloseModal={handleCloseCancelModal}
				onSubmit={handleChangeRequest(ConnectivityStatus.CANCELED)}
			/>
			<BlockUserPopup isOpenModal={isOpenBlockUserPopup} handleCloseModal={handleCloseBlockUserPopup} />
			<div className={classNames(classes.spaceBetweenContainer)}>
				<LazyLoadProfileImage className={classes.profileImage} externalUserId={externalId} photoUrl={photoUrl} />
				<span className={classes.activityInfoContainer}>
					{activityInfo.map(({ count, type, onClick }, idx) => (
						<span
							key={idx}
							tabIndex={onClick ? 0 : -1}
							className={classNames(classes.activityInfo, onClick ? classes.cursorPointer : '')}
							onClick={onClick ? onClick : () => {}}
						>
							<Typography className={classes.boldText} variant="h3">
								{count}
							</Typography>
							<Typography variant="h3">{type}</Typography>
						</span>
					))}
					<Typography className={classes.roleMark} variant="h3">
						<b>{getRoleBadgeLabel(appUserTypeId)}</b>
					</Typography>
					{!isMyProfile && (
						<DotButton
							variant="secondary"
							reportSubject={ReportSubject.User}
							itemId={`${id}`}
							onSubmitReport={handleReportUser}
							renderButtons={reportButtons}
							redirect={PATHS.APP.NETWORK_SEARCH}
						/>
					)}
				</span>
			</div>
			<div className={classes.userName}>
				<Typography component="span" className={classNames(classes.userNameText, classes.boldText)} variant="h1">
					{fullName}
				</Typography>
			</div>
			{preferredPronouns && (
				<Typography variant={'body1'} className={classes.grayText}>
					{preferredPronouns}
				</Typography>
			)}
			<UserPrimaryTitle variant={'body1'} primaryTitle={primaryTitle} primaryTitleDepartment={primaryTitleDepartment} />
			<UserPrimaryTitle variant={'body1'} primaryTitle={positionTitle} primaryTitleDepartment={department} />

			<div className={classes.connectControlSection}>
				<div className={classes.divider} />
				{getConnectionButtons()}
			</div>
			<div className={classes.infoFooterContainer}>
				<div className={classes.profileTabs}>
					<NavigationTabs
						activeTabIndex={activeTab}
						centered={false}
						tabsRenderer={(props) =>
							tabs.map((tab, index) => (
								<NavigationTab key={`${tab}-${index}`} index={index} label={tab} onClick={handleChangeTab} {...props} />
							))
						}
					/>
				</div>
				{handlePublicProfileView && isMyProfile && (
					<TextButton onClick={handlePublicProfileView}>{isPublicProfileView ? 'Back to my profile' : 'View my public profile'}</TextButton>
				)}
			</div>
		</Card>
	)
}

export default UserInfoCard

import { Typography } from '@material-ui/core'
import MaterialIconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { OrgMembershipRequestStatus } from '@/api/http/organizations/patchOrganizationMembershipRequest'
import { CircleIconButton, DotButton, IconButton, IconWrapper, LockIcon, ModalWithSubmittedPopup, OrganizationCardIcon } from '@/components'
import Card from '@/components/IndividualPage/Card'
import ChangeMembershipPositionModal from '@/components/IndividualPage/ChangeMembershipPositionModal'
import FindNewOrganizationMembersModal from '@/components/IndividualPage/FindNewOrganizationMembersModal'
import OrganizationManageRequestsCard, { RequestType } from '@/components/IndividualPage/OrganizationManageRequestsCard'
import OrganizationMembershipsListCard from '@/components/IndividualPage/OrganizationMembershipsListCard'
import OrganizationPrimaryContactDashboardCard from '@/components/IndividualPage/OrganizationPrimaryContactDashboardCard'
import SearchOrganizationMembershipCard from '@/components/IndividualPage/SearchOrganizationMembershipCard'
import SuccessPopup from '@/components/SuccessPopup'
import { STYLES } from '@/constants'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import { useAppDispatch } from '@/store'
import { getUserInfo } from '@/store/auth'
import {
	changeMembershipRequestStatusRequest,
	changeOrganizationMemberRequest,
	changePositionRequestStatusRequest,
	getIsLoadingOrganizationData,
	getOrganizationMembershipRequests,
	getOrganizationPositionRequests,
	getSearchOrgMembershipsQuery,
	loadOrganizationMemberRequestsRequest,
	loadOrganizationPositionRequestsRequest,
	removeOrganizationMemberRequest,
	setOrganizationMembershipRequests,
	setOrganizationPositionRequests,
} from '@/store/organizations'
import { OrgMembership } from '@/store/organizations/types'
import { isCurrentUser } from '@/utils/authHandlers'
import { canEditMemberships, getOrganizationType, orgTypes } from '@/utils/organizationRoles'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userRow: {
		margin: '8px 0',
	},
	infoBlock: {
		minWidth: 161,
		height: 161,
		borderRadius: 5,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '10px 10px 0 0',
		...STYLES.BOX_SHADOW,
		'&:last-child': {
			marginRight: 0,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	infoBlockValue: {
		fontWeight: 700,
	},
	infoButton: {
		padding: 0,
		height: 163,
	},
	infoBlockActive: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.primary.dark,
		},
	},
	infoBlockWithAdditionalInfo: {
		width: 'calc(100% - 163px - 163px - 20px)',
		minWidth: 'fit-content',
	},
	icon: {
		height: 50,
		width: 50,
	},
	actionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'left',
		flexWrap: 'wrap',
	},
	numberOfRequests: {
		height: 50,
		width: 50,
		borderRadius: '50%',
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.light,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	numberOfRequestsActive: {
		border: `solid 1.5px ${theme.palette.primary.main}`,
	},
	actionInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	additionalInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderLeft: `1px solid ${theme.palette.border.bold}`,
		marginLeft: 10,
		padding: 5,
	},
	additionalInfoItem: {
		position: 'relative',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		border: `1px solid ${theme.palette.primary.main}`,
		padding: '5px 10px',
		color: theme.palette.primary.main,
		margin: '5px 7px',
		borderRadius: 3,
	},
	numberOfRequestsText: {
		fontWeight: 600,
	},
	actionLabel: {
		marginTop: 10,
	},
	actionLabelWithAdditionalInfo: {
		wordBreak: 'normal',
		width: '99%',
	},
	// @TODO: copy pasted in several places component can be created or mui badge can be used
	notificationDot: {
		width: 8,
		height: 8,
		backgroundColor: theme.palette.icons.notificationDot,
		borderRadius: '50%',
		position: 'absolute',
		top: -4,
		right: -4,
	},
	moreButton: {
		marginLeft: 15,
		width: 37,
		height: 37,
		backgroundColor: theme.palette.specialColors.transparent,
	},
	lockIconContainer: {
		width: 35,
		height: 35,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		borderRadius: 5,
		cursor: 'pointer',
	},
	lockIcon: {
		width: 15,
	},
}))

export enum OrgDashboardActions {
	CREATE_EVENT = 'Create Event',
	POST_TO_FEED = 'Post To Feed',
	MANAGE_MEMBERS = 'Manage Members',
}

interface OrganizationOwnerDashboardProps {
	orgId: string
	role: ORGANIZATION_ROLE
	currentUserRole: ORGANIZATION_ROLE
	activeAction: OrgDashboardActions | null
	onSelectAction?: (action: OrgDashboardActions) => void
	handleOpenCreateFeedModal: () => void
	handleClickCreateEvent: () => void
	membershipTypeId: orgTypes
	isDataLakeOrg?: boolean
	sourceSystem?: string
}

const OrganizationOwnerDashboard = ({
	sourceSystem,
	orgId,
	role,
	activeAction,
	onSelectAction = () => {},
	currentUserRole,
	handleOpenCreateFeedModal,
	handleClickCreateEvent,
	membershipTypeId,
	isDataLakeOrg = false,
}: OrganizationOwnerDashboardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const currentUserId = useSelector(getUserInfo).id
	const searchOrgMembershipsQuery = useSelector(getSearchOrgMembershipsQuery)

	const {
		list: membershipRequests,
		total: membershipRequestsTotal,
		unseenCount: membershipRequestsUnseenCount,
		page: membershipRequestsCurrentPage,
	} = useSelector(getOrganizationMembershipRequests)

	const {
		list: positionRequestsList,
		total: positionRequestsTotal,
		unseenCount: positionRequestsUnseenCount,
		page: positionRequestsCurrentPage,
	} = useSelector(getOrganizationPositionRequests)

	const { isLoadingNewMemberRequests, isLoadingPositionRequests } = useSelector(getIsLoadingOrganizationData)

	const [membershipToEdit, setMembershipToEdit] = useState<OrgMembership | null>(null)
	const [removedMemberPopupOpen, setRemovedMemberPopupOpen] = useState(false)
	const [leavedOrganizationPopupOpen, setLeavedOrganizationPopupOpen] = useState(false)
	const [lockedMembershipData, setLockedMembershipData] = useState<{
		id: number
		sourceSystem?: string
	} | null>(null)
	const [membershipTypeToAdd, setMembershipTypeToAdd] = useState<orgTypes | null>(null)

	const isSuperAdmin = currentUserRole === ORGANIZATION_ROLE.SUPER_ADMIN
	const isAdmin = currentUserRole === ORGANIZATION_ROLE.ADMIN

	const handleToggleRemovedMemberPopup = useCallback(() => setRemovedMemberPopupOpen(!removedMemberPopupOpen), [removedMemberPopupOpen])
	const handleToggleLeaveOrganizationPopup = useCallback(
		() => setLeavedOrganizationPopupOpen(!leavedOrganizationPopupOpen),
		[leavedOrganizationPopupOpen],
	)

	const handleOpenAddMembershipModal = useCallback((typeId: orgTypes) => setMembershipTypeToAdd(typeId), [])
	const handleCloseAddMembershipModal = useCallback(() => setMembershipTypeToAdd(null), [])

	const handleSelectAction = useCallback(
		(action: OrgDashboardActions) => () => {
			if (action === OrgDashboardActions.POST_TO_FEED) {
				handleOpenCreateFeedModal()
			} else if (action === OrgDashboardActions.CREATE_EVENT) {
				handleClickCreateEvent()
			} else {
				onSelectAction(action)
			}
		},
		[onSelectAction, handleOpenCreateFeedModal, handleClickCreateEvent],
	)

	const handleCloseLockMembershipModal = () => setLockedMembershipData(null)
	const handleOpenLockMembershipModal = (membershipId: number, sourceSystem?: string) => () =>
		setLockedMembershipData({ id: membershipId, sourceSystem })
	const handleUnlockMembership = () => {
		handleCloseLockMembershipModal()
	}

	const handleOpenEditMembershipModal = (membership: OrgMembership) => {
		setMembershipToEdit(membership)
	}

	const handleCloseEditMembershipModal = () => {
		setMembershipToEdit(null)
	}

	const handleChangeMember = (membership: OrgMembership, newMembershipTypeId: orgTypes, title: string) => {
		dispatch(
			changeOrganizationMemberRequest({
				id: membership.id,
				membership,
				membershipTypeId: newMembershipTypeId,
				title,
			}),
		)

		handleCloseEditMembershipModal()
	}

	const handleRemoveMember = useCallback(
		(id: number, isCurrentUser: boolean) => {
			dispatch(
				removeOrganizationMemberRequest({
					id,
				}),
			)

			if (isCurrentUser) {
				handleToggleLeaveOrganizationPopup()
			} else {
				handleToggleRemovedMemberPopup()
			}
		},
		[dispatch, handleToggleLeaveOrganizationPopup, handleToggleRemovedMemberPopup],
	)

	const allActions = useMemo(
		() => [
			{
				label: OrgDashboardActions.CREATE_EVENT,
				value: OrgDashboardActions.CREATE_EVENT,
				icon: <IconWrapper className={classes.icon} iconKey={FEATURE.events} />,
				onClick: handleSelectAction,
				allowedRoles: [ORGANIZATION_ROLE.SUPER_ADMIN, ORGANIZATION_ROLE.ADMIN],
			},
			{
				label: OrgDashboardActions.POST_TO_FEED,
				value: OrgDashboardActions.POST_TO_FEED,
				icon: <CircleIconButton />,
				onClick: handleSelectAction,
				allowedRoles: [ORGANIZATION_ROLE.SUPER_ADMIN, ORGANIZATION_ROLE.ADMIN],
			},
			{
				label: OrgDashboardActions.MANAGE_MEMBERS,
				value: OrgDashboardActions.MANAGE_MEMBERS,
				icon: <OrganizationCardIcon className={classes.icon} />,
				onClick: handleSelectAction,
				additionalInfo: {
					membershipRequests: {
						total: `Member Requests (${membershipRequestsTotal})`,
						unseenCount: membershipRequestsUnseenCount,
					},
					positionRequests: {
						total: `Position Requests (${positionRequestsTotal})`,
						unseenCount: positionRequestsUnseenCount,
					},
				},
				allowedRoles: [ORGANIZATION_ROLE.SUPER_ADMIN, ORGANIZATION_ROLE.ADMIN],
			},
		],
		[
			classes.icon,
			handleSelectAction,
			membershipRequestsTotal,
			membershipRequestsUnseenCount,
			positionRequestsTotal,
			positionRequestsUnseenCount,
		],
	)

	const userActions = useMemo(
		() => allActions.filter((action) => action.allowedRoles.includes(currentUserRole)),
		[allActions, currentUserRole],
	)

	const handleLoadMoreMembershipRequests = (page: number) =>
		dispatch(
			loadOrganizationMemberRequestsRequest({
				page,
			}),
		)

	const handleChangeMembershipRequestStatus = (requestId: number, status: OrgMembershipRequestStatus) =>
		dispatch(
			changeMembershipRequestStatusRequest({
				status,
				requestId,
			}),
		)

	const handleResetMembershipRequestsState = () =>
		dispatch(
			setOrganizationMembershipRequests({
				list: [],
				total: 0,
				unseenCount: 0,
				page: 1,
			}),
		)

	const handleLoadMorePositionRequests = (page: number) =>
		dispatch(
			loadOrganizationPositionRequestsRequest({
				page,
			}),
		)

	const handleChangePositionRequestsStatus = (requestId: number, status: OrgMembershipRequestStatus) =>
		dispatch(
			changePositionRequestStatusRequest({
				status,
				requestId,
			}),
		)

	const handleResetPositionRequestsState = () =>
		dispatch(
			setOrganizationPositionRequests({
				list: [],
				total: 0,
				unseenCount: 0,
				page: 1,
			}),
		)

	const renderMembershipRowButtons = useCallback(
		(membership: OrgMembership, canEdit: boolean) => {
			const isLocked = membership.dataLake
			const isCurrentUserId = isCurrentUser(currentUserId, membership.userId)

			return canEdit ? (
				<>
					{!isLocked ? (
						<DotButton
							variant="secondary"
							className={classes.moreButton}
							showReportButton={false}
							renderButtons={[
								{
									label: 'Edit Position',
									onClick: (e: any) => {
										e.stopPropagation()
										handleOpenEditMembershipModal(membership)
									},
									icon: null,
								},
								{
									label: isCurrentUserId ? 'Leave organization' : 'Remove from organization',
									onClick: (e: any) => {
										e.stopPropagation()
										handleRemoveMember(membership.id, isCurrentUserId)
									},
									icon: null,
								},
							]}
						/>
					) : (
						<MaterialIconButton className={classes.lockIconContainer} onClick={handleOpenLockMembershipModal(membership.id)}>
							<LockIcon className={classes.lockIcon} />
						</MaterialIconButton>
					)}
				</>
			) : null
		},
		[classes.lockIcon, classes.lockIconContainer, classes.moreButton, currentUserId, handleRemoveMember],
	)

	useEffect(() => {
		handleLoadMoreMembershipRequests(1)
		handleLoadMorePositionRequests(1)

		return () => {
			handleResetMembershipRequestsState()
			handleResetPositionRequestsState()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const memberLists = [
		{ role: ORGANIZATION_ROLE.SUPER_ADMIN, canEdit: !isDataLakeOrg && isSuperAdmin },
		{
			role: ORGANIZATION_ROLE.ADMIN,
			canEdit: !isDataLakeOrg && (isSuperAdmin || isAdmin),
		},
		{
			role: ORGANIZATION_ROLE.MEMBER,
			canEdit: !isDataLakeOrg && (isSuperAdmin || isAdmin),
		},
	]

	return (
		<>
			{!!membershipToEdit && (
				<ChangeMembershipPositionModal
					userMembershipTypeId={membershipTypeId}
					membership={membershipToEdit}
					onSubmit={handleChangeMember}
					handleCloseModal={handleCloseEditMembershipModal}
				/>
			)}
			<SuccessPopup
				isOpen={removedMemberPopupOpen}
				onClose={handleToggleRemovedMemberPopup}
				submittedMessage={['This member has been ', 'removed from the organization.']}
			/>
			<SuccessPopup
				isOpen={leavedOrganizationPopupOpen}
				onClose={handleToggleLeaveOrganizationPopup}
				submittedMessage={['You have removed yourself from ', 'this organization.']}
			/>
			<ModalWithSubmittedPopup
				isOpen={!!lockedMembershipData}
				onClose={handleCloseLockMembershipModal}
				onSubmit={handleUnlockMembership}
				title="Membership Locked"
				content={[
					`This data originated in ${lockedMembershipData?.sourceSystem || 'Org Central'}.`,
					`You may not edit data here, please edit in ${lockedMembershipData?.sourceSystem || 'Org Central'}.`,
				]}
				submitButtonLabel="Unblock"
				showSubmittedModal={false}
			/>
			{membershipTypeToAdd && (
				<FindNewOrganizationMembersModal membershipTypeId={membershipTypeToAdd} handleCloseModal={handleCloseAddMembershipModal} />
			)}
			<Card>
				<div className={classes.titleContainer}>
					<Typography variant="body1">
						<b>Dashboard</b> | {role}
					</Typography>
				</div>
				<div className={classes.actionsContainer}>
					{userActions.map((act) => (
						<IconButton
							key={act.value}
							className={classNames(
								classes.infoButton,
								classes.infoBlock,
								activeAction === act.value ? classes.infoBlockActive : '',
								act.additionalInfo ? classes.infoBlockWithAdditionalInfo : '',
							)}
							onClick={handleSelectAction(act.value)}
						>
							<div className={classes.actionInfo}>
								{act.icon}
								<Typography className={classNames(classes.actionLabel, act.additionalInfo ? classes.actionLabelWithAdditionalInfo : '')}>
									{act.label}
								</Typography>
							</div>
							{act.additionalInfo && (
								<div className={classes.additionalInfo}>
									<div className={classes.additionalInfoItem}>
										{act.additionalInfo.membershipRequests.unseenCount > 0 && <div className={classes.notificationDot} />}
										<Typography variant="body2">{act.additionalInfo.membershipRequests.total}</Typography>
									</div>
									<div className={classes.additionalInfoItem}>
										{act.additionalInfo.positionRequests.unseenCount > 0 && <div className={classes.notificationDot} />}
										<Typography variant="body2">{act.additionalInfo.positionRequests.total}</Typography>
									</div>
								</div>
							)}
						</IconButton>
					))}
				</div>
			</Card>
			{activeAction === OrgDashboardActions.MANAGE_MEMBERS &&
				userActions.some((action) => action.value === OrgDashboardActions.MANAGE_MEMBERS) && (
					<>
						<SearchOrganizationMembershipCard
							isDataLakeOrg={isDataLakeOrg}
							userMembershipTypeId={membershipTypeId}
							renderMembershipRowButtons={renderMembershipRowButtons}
							handleAddMembership={handleOpenAddMembershipModal}
						/>
						{!searchOrgMembershipsQuery && (
							<>
								<OrganizationManageRequestsCard
									requestType={RequestType.JOIN}
									title="Member Requests"
									requests={membershipRequests}
									totalRequests={membershipRequestsTotal}
									unseenRequestsCount={membershipRequestsUnseenCount}
									currentPage={membershipRequestsCurrentPage}
									isLoading={!!isLoadingNewMemberRequests}
									onChangeStatus={handleChangeMembershipRequestStatus}
									onLoadMoreItems={handleLoadMoreMembershipRequests}
								/>
								<OrganizationManageRequestsCard
									requestType={RequestType.CHANGE_POSITION}
									title="Position Requests"
									requests={positionRequestsList}
									totalRequests={positionRequestsTotal}
									unseenRequestsCount={positionRequestsUnseenCount}
									currentPage={positionRequestsCurrentPage}
									isLoading={!!isLoadingPositionRequests}
									onChangeStatus={handleChangePositionRequestsStatus}
									onLoadMoreItems={handleLoadMorePositionRequests}
								/>
								<OrganizationPrimaryContactDashboardCard canEdit={!isDataLakeOrg && (isSuperAdmin || isAdmin)} />
								{memberLists.map(({ canEdit, role }) => {
									const showHandleAddMembership = canEditMemberships(membershipTypeId, getOrganizationType(role))
									return (
										<OrganizationMembershipsListCard
											key={role}
											orgId={orgId}
											title={role}
											roles={[role]}
											canEdit={canEdit}
											canAdd={!isDataLakeOrg}
											renderMembershipRowButtons={renderMembershipRowButtons}
											handleAddMembership={showHandleAddMembership ? handleOpenAddMembershipModal : undefined}
											showHidden
											sourceSystem={sourceSystem ? sourceSystem : undefined}
										/>
									)
								})}
							</>
						)}
					</>
				)}
		</>
	)
}

export default OrganizationOwnerDashboard

import http from '@/api/http/requestHttp'
import { Except } from 'type-fest'
import { CommonUserConnectionParams } from './types'

const getUserConnections = async ({ userId, schoolId }: Except<CommonUserConnectionParams, 'targetUserId'>) => {
	const url = `${schoolId}/network/${userId}/connections`

	return http.get(url)
}

export default getUserConnections

import * as http from '@/api/http'
import useAuth from '@/hooks/useAuth'
import { usePubsubPublishers } from '@/lib/pubsub/hooks'
import { useAppDispatch } from '@/store'
import { setDeleteConfirmationModal } from '@/store/feeds'
import { useCallback } from 'react'
import { useGlobalFeedPosts } from './useGlobalFeedPosts'

export const useFeedPostDeleter = () => {
	const dispatch = useAppDispatch()

	const { schoolId, userId } = useAuth()
	const { setHomeFeedPosts } = useGlobalFeedPosts()
	const { publishFeedPostDeleted } = usePubsubPublishers(schoolId)

	const deletePostById = useCallback(
		async (postId) => {
			await http.feeds.deletePost({ userId, postId, schoolId })

			dispatch(setDeleteConfirmationModal(true))

			// Update the global feed posts
			setHomeFeedPosts((map) => {
				return Object.entries(map).reduce((newMap, [key, filterHashData]) => {
					const posts = filterHashData.posts

					newMap[key] = {
						...filterHashData,
						posts: posts.filter(({ id }, i) => {
							return id !== postId
						}),
					}

					return newMap
				}, {})
			})

			setTimeout(() => {
				publishFeedPostDeleted({ postId })
			}, 0)
		},
		[dispatch, publishFeedPostDeleted, schoolId, setHomeFeedPosts, userId],
	)

	return { deletePostById }
}

import { useCurrentUser } from '@/hooks/userHooks'
import { useGetUserEventsCountsQuery } from '../api'

const useCurrentUserCountsQuery = () => {
	const currentUser = useCurrentUser()
	const query = useGetUserEventsCountsQuery({ userId: currentUser.id })
	return query
}

export default useCurrentUserCountsQuery

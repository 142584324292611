import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import AddNewMessageRow from '@/components/Messages/AddNewMessageRow'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Typography } from '@material-ui/core'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	messagesContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'inherit',
		minHeight: '50vh',
	},
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
	},
	profileImage: {
		width: 75,
		height: 75,
		minWidth: 75,
		minHeight: 75,
	},
	placeholderText: {
		margin: 20,
	},
	placeholder: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
}))

const NoMessagesPlaceholder = () => {
	const classes = useStyles()

	return (
		<div className={classes.container}>
			<div className={classes.messagesContainer}>
				<AutoSizer>
					{({ height, width }) => (
						<div
							className={classes.rootContainer}
							style={{
								height,
								width,
							}}
						>
							<div className={classes.placeholder}>
								<LazyLoadProfileImage className={classes.profileImage} externalUserId={''} />
								<Typography className={classes.placeholderText} variant="subtitle1">
									Start up a chat with someone 👋
								</Typography>
							</div>
						</div>
					)}
				</AutoSizer>
			</div>
			<AddNewMessageRow />
		</div>
	)
}

export default NoMessagesPlaceholder

import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 7,
		backgroundColor: theme.palette.specialColors.transparent,
		color: theme.palette.text.primary,
		textTransform: 'none',
		boxShadow: 'none',
		...theme.typography.body1,
		[theme.breakpoints.down('xs')]: {
			...theme.typography.subtitle1,
			padding: 0,
			minWidth: 100,
			minHeight: 36,
		},
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
		},
	},
}))

export interface OptionButtonProps extends ButtonProps {
	children?: React.ReactNode
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	disabled?: boolean
	className?: string
	type?: 'button' | 'reset' | 'submit'
	onMouseEnter?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onMouseLeave?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	component?: any
	startIcon?: React.ReactNode
	endIcon?: React.ReactNode
}

const OptionButton = forwardRef<HTMLButtonElement, OptionButtonProps>(
	(
		{ children = null, onClick, disabled, className, type, onMouseEnter, onMouseLeave, component, startIcon, endIcon, ...buttonProps },
		ref,
	) => {
		const classes = useStyles()

		return (
			<Button
				ref={ref}
				{...buttonProps}
				className={classNames(className, classes.root)}
				onClick={onClick}
				type={type}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				disabled={disabled}
				startIcon={startIcon}
				component={component}
				endIcon={endIcon}
			>
				{children}
			</Button>
		)
	},
)

export default OptionButton

import InternshipCard from '@/components/ExperienceCards/InternshipCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { useGetEntityResponseCountQuery } from '@/features/responses/api'
import useEntityListWithResponse from '@/features/responses/hooks/useEntityListWithResponse'
import { Internship } from '@/store/internships/types'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
	},
}))

const YourInternships = () => {
	const classes = useStyles()

	const { push } = useHistory()

	const { data: count } = useGetEntityResponseCountQuery({ entityType: 'jobs' })
	const { list: userInternships, isLoading, loadMore, canLoadMore } = useEntityListWithResponse<Internship>('jobs')
	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Jobs',
				onClick: () => push(PATHS.APP.INTERNSHIPS),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const showEmptyPlaceholder = !userInternships.length && !isLoading

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="My Jobs" pageInfo={`${count?.total ?? ''} Bookmarked`} />
			{showEmptyPlaceholder ? (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.jobs} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						Nothing at the moment!
					</Typography>
					<Typography align="center">Job opportunities that you</Typography>
					<Typography align="center">are interested in will appear here.</Typography>
				</EmptyPlaceholder>
			) : (
				<ExperienceCardsSection
					canLoadMore={canLoadMore}
					handleLoadMoreItems={loadMore}
					items={userInternships}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, startDate, photoUrl, id, employer, positionPaid, jobTypeId, responseStatus }: any) => (
								<InternshipCard
									key={`${id}-${name}-${startDate}`}
									id={id}
									title={name}
									photoUrl={photoUrl}
									employer={employer}
									positionPaid={positionPaid}
									jobTypeId={jobTypeId}
									status={responseStatus}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourInternships

import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import AppStoreLogo from '@/components/icons/png/apple-store-badge.png'
import GooglePlayLogo from '@/components/icons/png/google-play-badge.png'
import { PATHS } from '@/constants'
import { Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	h5: {
		fontWeight: 700,
		textAlign: 'center',
	},
	chooseStoreText: {
		margin: '1em 0',
	},
	card: {
		paddingBottom: '1vh',
	},
}))

const DownloadPage = () => {
	const classes = useStyles()
	const history = useHistory()
	const handleBack = () => history.push(PATHS.ROOT)
	const {
		school: { applicationName },
		links,
	} = useSchoolConfig()

	return (
		<CardLayout onClickBackHandler={handleBack}>
			<Card className={classes.card}>
				<Typography className={classes.h5} variant="h3">
					Download {applicationName}!
				</Typography>
				<Typography className={classes.chooseStoreText}>Choose your store</Typography>
				<Link href={links.global.appleStore} target="_blank" color="inherit">
					<img src={AppStoreLogo} alt="apple store" />
				</Link>
				<Link href={links.global.googlePlayMarket} target="_blank" color="inherit">
					<img src={GooglePlayLogo} alt="google play" />
				</Link>
			</Card>
		</CardLayout>
	)
}

export default DownloadPage

import http from '@/api/http/requestHttp'

const deleteEvent = async (userId: number, eventId: string, schoolId: number) => {
	const url = `${schoolId}/events/deleteEvent`

	const body = { userId, eventId }

	return await http.patch(url, body)
}

export default deleteEvent

import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'
import BlockedAccounts from '@/pages/SettingsPage/BlockedAccounts'
import Notifications from '@/pages/SettingsPage/Notifications'
import ProfilePrivacy from '@/pages/SettingsPage/ProfilePrivacy'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const BLOCKED_ACCOUNTS = createPageTitle(PAGE_NAME.BLOCKED_ACCOUNTS)
const NOTIFICATIONS_SETTINGS = createPageTitle(PAGE_NAME.NOTIFICATIONS_SETTINGS)
const PROFILE_SETTINGS = createPageTitle(PAGE_NAME.PROFILE_SETTINGS)

const routes: AppRoute[] = [
	{
		component: BlockedAccounts,
		title: BLOCKED_ACCOUNTS,
		name: BLOCKED_ACCOUNTS,
		path: PATHS.APP.SETTINGS_BLOCKED_ACCOUNTS,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: Notifications,
		title: NOTIFICATIONS_SETTINGS,
		name: NOTIFICATIONS_SETTINGS,
		path: PATHS.APP.SETTINGS_NOTIFICATIONS,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: ProfilePrivacy,
		title: PROFILE_SETTINGS,
		name: PROFILE_SETTINGS,
		path: PATHS.APP.SETTINGS_PROFILE,
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

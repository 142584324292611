import http from '@/api/http/requestHttp'
import { DtoGroupChatItem, GroupChatTypes } from '@navengage/sen-types'

export interface CreateGroupBody {
	name: string
	description?: string
	type: GroupChatTypes
	thumbnail?: string
	restrictedEdit: boolean
	restrictedMemberManagement: boolean
	restrictedAdminManagement: boolean
	invites: number[]
	schoolId?: number
}

const createGroup = async ({ schoolId, ...body }: CreateGroupBody) => {
	const url = `${schoolId}/groups`

	return http.post<DtoGroupChatItem>(url, body)
}

export default createGroup

import ColorButton from '@/components/Buttons/ColorButton'
import MoreButton, { MoreButtonProps } from '@/components/Buttons/MoreButton'
import { ButtonColors } from '@/components/Buttons/types'
import { makeStyles } from '@material-ui/core'
import { FC } from 'react'
import { AVAILABLE_EVENT_RESPONSES } from '../../constants'
import EventRsvpOption from '../../types/EventRsvpOption'

const useStyles = makeStyles((theme) => ({
	button: {
		height: 50,
		width: '48%',
	},
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
	responseBtnFullWidth: {
		height: 50,
		width: '100%',
	},
}))

export type IRsvpButtonProps = {
	isInviteOnly?: boolean
	pastEvent?: boolean
	currentResponse?: EventRsvpOption
	rsvpButtons: MoreButtonProps['renderButtons']
	hasResponse?: boolean
}

const RsvpButton: FC<IRsvpButtonProps> = ({ isInviteOnly, pastEvent, rsvpButtons, currentResponse, hasResponse }) => {
	const classes = useStyles()
	if (isInviteOnly) {
		return (
			<ColorButton disabled className={classes.button} color={ButtonColors.GREY}>
				Invite Only
			</ColorButton>
		)
	}
	if (pastEvent) {
		return (
			<ColorButton className={classes.responseBtnFullWidth} color={ButtonColors.GREY} disabled>
				Past Event
			</ColorButton>
		)
	}
	return (
		<MoreButton
			color={!hasResponse ? ButtonColors.PRIMARY : undefined}
			className={classes.responseBtnFullWidth}
			renderButtons={rsvpButtons}
		>
			{currentResponse ? AVAILABLE_EVENT_RESPONSES[currentResponse] : 'RSVP'}
		</MoreButton>
	)
}

export default RsvpButton

import OutlinedButton from '@/components/Buttons/OutlinedButton'
import Card from '@/components/IndividualPage/Card'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	outlinedButton: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		border: 'solid 1px',
	},
	contactButton: {
		width: 'fit-content',
	},
	userInfo: {
		marginBottom: '1.5vh',
	},
	grayText: {
		color: theme.palette.text.secondary,
	},
}))

interface ContactCardProps {
	name: string
	contactPositionTitle?: string
	email: string
	cc?: string
	subject?: string
}

const ContactCard = ({ name = '', contactPositionTitle = '', email = '', cc = '', subject = '' }: ContactCardProps) => {
	const classes = useStyles()

	const handleMailClick = () => {
		window.location.href = `mailto:${email}?cc=${cc}&subject=${subject}`
	}

	return (
		<Card title="Contact">
			<div className={classes.root}>
				<div className={classes.userInfo}>
					<Typography>{name}</Typography>
					{contactPositionTitle && <Typography className={classes.grayText}>{contactPositionTitle}</Typography>}
				</div>
				{email && (
					<OutlinedButton className={classNames(classes.outlinedButton, classes.contactButton)} onClick={handleMailClick}>
						{email}
					</OutlinedButton>
				)}
			</div>
		</Card>
	)
}

export default ContactCard

import settings from '@/constants/http'
import { useCallback, useState } from 'react'

const useTakeSkip = (take: number = settings.SEARCH_LIST_PER_PAGE, defaultSkip: number = 0) => {
	const [skip, setSkip] = useState<number>(defaultSkip)
	const loadMore = useCallback(() => {
		setSkip((prev) => prev + take)
	}, [take])

	return {
		skip,
		take,
		loadMore,
	}
}

export default useTakeSkip

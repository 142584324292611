import { CommentsListProps } from '@/components/Feeds/CommentsList'
import { useCurrentUser } from '@/hooks/userHooks'
import { FeedPost } from '@/store/feeds/types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	useCreateCommentMutation,
	useDeleteCommentMutation,
	useGetCommentsQuery,
	useLikeCommentMutation,
	useReportCommentMutation,
} from '../api'
import { commentsSelectors, resetData, setCommentsPostId } from '../slice'
import { DtoComment } from '../types/DtoComment'
import { useAppSelector } from '@/store'

const usePostComments = (postId: FeedPost['id']) => {
	const { id: userId } = useCurrentUser()
	const [offset, setOffset] = useState<number>(0)
	const commentsCount = useAppSelector((state) => commentsSelectors.selectTotal(state))
	const dispatch = useDispatch()
	const { refetch } = useGetCommentsQuery(
		{ postId, userId, offset },
		{
			refetchOnMountOrArgChange: true,
		},
	)
	//const canLoadMore = data ? data.results.length < data.total : false

	// Mutations
	// @TODO: leaving isError, isLoading, isSuccess for future, so we will address them or at least discuss
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [likeCommentMutation, { isError: isLikeError, isLoading: isLikeLoading, isSuccess: isLikeSuccess }] = useLikeCommentMutation()
	const [deleteCommentMutation] = useDeleteCommentMutation()
	const [createCommentMutation] = useCreateCommentMutation()
	const [reportCommentMutation] = useReportCommentMutation()

	const toggleLike = useCallback(
		(commentId: DtoComment['id']) => {
			likeCommentMutation({ commentId, userId })
		},
		[likeCommentMutation, userId],
	)

	const deleteComment = useCallback<CommentsListProps['toggleLike']>(
		(commentId) => {
			deleteCommentMutation({ commentId, userId })
		},
		[deleteCommentMutation, userId],
	)

	const createComment = useCallback<CommentsListProps['createComment']>(
		({ data, mentions }) => {
			createCommentMutation({ postId, userId, message: data, mentions: mentions.map(({ id, entityType }) => ({ id, entityType })) })
		},
		[createCommentMutation, postId, userId],
	)

	const reportComment = useCallback<CommentsListProps['reportComment']>(
		(reportData) => {
			reportCommentMutation({ userId, ...reportData })
		},
		[reportCommentMutation, userId],
	)
	const loadMore = useCallback(() => {
		if (commentsCount !== 0) {
			setOffset(commentsCount)
		} else {
			refetch()
		}
	}, [commentsCount, refetch])

	useEffect(() => {
		dispatch(setCommentsPostId(postId))

		return () => {
			dispatch(resetData())
		}
	}, [dispatch, postId])

	return {
		toggleLike,
		deleteComment,
		createComment,
		reportComment,
		loadMore,
	}
}

export default usePostComments

import { isEqual } from 'lodash'

import settings from '@/constants/http'

import { baseApi } from '../general/baseApi'
import DtoEventUsers from './types/dto/DtoEventUsers'
import { EventResponseType } from './types/EventResponseType'
import { EVENT_RESPONSE_MAP } from './constants'
import DtoEvent from './types/dto/DtoEvent'
import { Event } from '@/store/events/types'
import { createSelector } from '@reduxjs/toolkit'

// @TODO: this can be created dynamically with ts-tool belt or similar tool
type SortParams = 'updatedAt' | '-updatedAt' | 'createdAt' | '-createdAt'

export const eventsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// Get the event users list who have response to the event (going, interested, attended)
			getEventResponses: builder.query<
				DtoEventUsers,
				{ sort?: SortParams; id: string; limit?: number; offset?: number; response: EventResponseType }
			>({
				query: ({ id, offset = 0, sort = '-updatedAt', response, limit = settings.USERS_PER_PAGE }) => ({
					url: `/v2/events/${id}/attendees`,
					params: {
						response,
						limit,
						sort,
						offset: offset > 0 ? offset : undefined,
					},
				}),
				serializeQueryArgs: ({ endpointName, queryArgs }) => {
					const serializedKey = `${endpointName}-${queryArgs.id}-${queryArgs.response}`
					return serializedKey
				},
				merge: (currentCache, { result, total }, { arg: { offset } }) => {
					if (offset > 0) {
						currentCache.result.push(...result)
						currentCache.total = total
					} else {
						currentCache.result = result
						currentCache.total = total
					}
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg)
				},
				providesTags: (result, error, { response }) => [EVENT_RESPONSE_MAP[response]],
			}),

			// Retrieves single event by id
			getEvent: builder.query<DtoEvent, { id: Event['id'] }>({
				query: ({ id }) => ({
					url: `/events/${id}`,
				}),
				keepUnusedDataFor: 600,
			}),
		}),
	})

export const { useGetEventResponsesQuery, useGetEventQuery } = eventsApi

// Selectors

export const selectEvent = () => {
	return createSelector(
		(data?: DtoEvent) => data,
		(data?: DtoEvent) => {
			if (data && data.event) {
				return { ...data.event, response: data.event.response?.response }
			} else {
				return null
			}
		},
	)
}

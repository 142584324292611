import http from '@/api/http/requestHttp'
import { OrganizationEditForm } from '@/store/organizations/types'

const patchOrganization = async (orgId: string, userId: number, data: OrganizationEditForm, schoolId: number) => {
	const url = `${schoolId}/organizations/${userId}/org/${orgId}/edit`

	return http.patch(url, data)
}

export default patchOrganization

import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useSearchOrganizationsQuery } from '@/anthology/organizations/api'
import { DtoOrganization } from '@/anthology/organizations/types/dto/DtoOrganization'
import { PersonOutlinedIcon } from '@/components'
import ItemAboutRow from '@/components/IndividualPage/ItemAboutRow'
import { PATHS } from '@/constants'

export type IEventOrganizationsProps = {
	orgIds: Array<DtoOrganization['id']>
}

const EventOrganizations: React.FC<IEventOrganizationsProps> = ({ orgIds }) => {
	const { data, isLoading } = useSearchOrganizationsQuery({ ids: orgIds, skip: 0, take: orgIds.length })
	const { push } = useHistory()
	const handleOpenOrganizationPage = useCallback(
		(organizationId) => () => {
			push(PATHS.APP.ORGANIZATIONS_SINGLE(organizationId))
		},
		[push],
	)
	const normalizedData = useMemo(() => {
		if (data) {
			return data.items.reduce((acc, org) => {
				acc[org.id] = org
				return acc
			}, {})
		} else {
			return {}
		}
	}, [data])
	return (
		<>
			{orgIds.map((orgId) => (
				<ItemAboutRow
					loading={isLoading}
					key={orgId}
					icon={<PersonOutlinedIcon />}
					label={`${normalizedData[orgId]?.name}`}
					onClick={handleOpenOrganizationPage(orgId)}
				/>
			))}
		</>
	)
}

export default EventOrganizations

import CheckedImage from '@/components/icons/png/PathBuilder/EventsQ1/CheckedImage.png'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	checkBoxIcon: {
		width: 81,
		height: 81,
	},
	checkedIcon: {
		width: 81,
		height: 81,
		position: 'absolute',
		top: 0,
		left: 0,
	},
	checkBox: {
		maxHeight: 67,
		maxWidth: 67,
		padding: 0,
		color: theme.palette.text.primary,
		'&.MuiCheckbox-colorPrimary.Mui-checked': {
			color: theme.palette.text.primary,
		},
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	checkBoxLabel: {
		color: theme.palette.text.primary,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		margin: 0,
		marginBottom: 20,
		'&>span': {
			fontSize: 15,
		},
	},
	checkedIconContainer: {
		position: 'relative',
		width: 81,
		height: 81,
	},
}))

interface ImageCheckBoxProps {
	value: string
	label: string
	icon: string
	checked: boolean
	onChange?: (v: any) => void
}

export const ImageCheckBox = ({ value, label, icon, checked, onChange = () => {} }: ImageCheckBoxProps) => {
	const classes = useStyles()

	const checkBox = <img className={classes.checkBoxIcon} src={icon} alt={label} />

	const checkedIcon = (
		<div className={classes.checkedIconContainer}>
			{checkBox}
			<img className={classes.checkedIcon} src={CheckedImage} alt="checked" />
		</div>
	)

	const handleChange = () => onChange(value)

	return (
		<FormControlLabel
			value="bottom"
			className={classes.checkBoxLabel}
			control={
				<Checkbox
					color="primary"
					classes={{
						root: classes.checkBox,
					}}
					checked={checked}
					onChange={handleChange}
					inputProps={{ 'aria-label': 'primary checkbox' }}
					checkedIcon={checkedIcon}
					icon={checkBox}
				/>
			}
			label={label}
			labelPlacement="bottom"
		/>
	)
}

import { SvgIcon } from '@material-ui/core'

const LocationFilledIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
			<path d="M12 0C7.589 0 4 3.589 4 8c0 1.324.331 2.637.96 3.802l6.603 11.94a.5.5 0 00.874 0l6.605-11.944A8.014 8.014 0 0020 8c0-4.411-3.589-8-8-8zm0 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
		</SvgIcon>
	)
}

export default LocationFilledIcon

import { Card, IconButton, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'

import ColorButton from '@/components/Buttons/ColorButton'
import { STYLES } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import { useIsMobileScreen } from '@/hooks/useIsMobileScreen'
import { ProgressBar } from '@/pages/SignUp/ProgressBar'
import { useSignupStepsContext } from '@/pages/SignUp/SignupStepsContext'
import { rootStyles } from '@/styles'

import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { CaretLeft } from '@phosphor-icons/react'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
	...rootStyles,
	root: {
		margin: '40px 0',
		minHeight: '670px',
		borderRadius: 15,
		background: theme.palette.background.paper,
		border: 'none',
		color: theme.palette.text.primary,
		width: '35vw',
		[theme.breakpoints.down('md')]: {
			width: '50vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			borderRadius: 0,
			height: '100vh !important',
			margin: 0,
			boxShadow: 'none',
			overflowY: 'auto',
		},
	},
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'calc(100% - 100px)',
		minHeight: '570px',
		margin: '40px 60px 60px 60px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			margin: 0,
			height: 'auto',
			justifyContent: 'normal',
			paddingBottom: theme.spacing(12.5),
		},
	},
	img: {
		height: '6.5vh',
		marginBottom: 25,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	title: {
		fontWeight: 700,
		lineHeight: '1.5em',
		textAlign: 'center',
		marginBottom: 20,
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(1.5),
			textAlign: 'left',
		},
	},
	subTitle: {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		marginBottom: 20,
		[theme.breakpoints.down('xs')]: {
			textAlign: 'left',
		},
	},
	description: {
		color: theme.palette.text.secondary,
		fontSize: 14,
	},
	button: {
		width: '11vw',
		minHeight: 50,
		fontWeight: 600,
		minWidth: 200,
	},
	header: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important',
		},
	},
	continueButton: {
		marginTop: 50,
		marginBottom: 15,
		[theme.breakpoints.down('xs')]: {
			marginTop: '0 !important',
			marginBottom: theme.spacing(2.5),
		},
	},
	skipButton: {
		marginTop: 50,
		marginBottom: 15,
		background: 'transparent',
		border: `1px solid ${theme.palette.type === 'light' ? theme.colors.grey[400] : theme.palette.divider}`,
		color: theme.palette.text.primary,
		'&:hover': {
			background: theme.palette.type === 'light' ? theme.colors.grey[300] : theme.palette.action.hover,
		},
	},
	backButtonWrapper: {
		...rootStyles.flexCenteredItems,
		marginTop: 10,
	},
	backButton: {
		...rootStyles.flexCenteredItems,
		...rootStyles.buttonPlain,
		fontWeight: 300,
		color: theme.colors.grey[400],
		'&:hover': {
			color: theme.palette.text.secondary,
		},
	},
	mobileContainer: {
		[theme.breakpoints.down('xs')]: {
			boxSizing: 'border-box',
			width: '100%',
			padding: `0px ${theme.spacing(2.5)}px`,
		},
	},
	contentContainer: {
		maxWidth: '100%',
		width: '100%',
	},
	actionsContainer: {
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			zIndex: 10,
		},
	},
	headerTop: {
		[theme.breakpoints.down('xs')]: {
			height: theme.spacing(10),
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			marginBottom: theme.spacing(2.5),
			'& .MuiLinearProgress-root': {
				position: 'absolute',
				left: 0,
				bottom: 0,
				width: '100%',
			},
		},
	},
	mobileBackButton: {
		position: 'absolute',
		left: 0,
		top: '50%',
		transform: 'translate(0,-50%)',
		color: theme.palette.text.primary,
	},

	headerBottom: {
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'start',
		},
	},
}))

interface SignUpCardProps {
	children: React.ReactNode
	classnames?: {
		root?: string
		continueButton?: string
		skipButton?: string
		header?: string
		title?: string
		subTitle?: string
	}
	skipText?: string
	title?: string
	subTitle?: string
	description?: string
	continueButtonText?: string
	onClickContinue?: () => void
	onClickSkip?: () => void
	continueButtonDisabled?: boolean
	showSkipButtonWhenContinueDisabled?: boolean
}

const SignUpCard = ({
	children,
	classnames = {
		root: '',
		continueButton: '',
		skipButton: '',
		header: '',
		title: '',
		subTitle: '',
	},
	title = '',
	subTitle = '',
	skipText = 'Skip for now',
	description,
	continueButtonText = '',
	onClickContinue,
	onClickSkip,
	continueButtonDisabled,
	showSkipButtonWhenContinueDisabled,
}: SignUpCardProps) => {
	const classes = useStyles()
	const appTheme = useTheme()
	const isMobile = useIsMobileScreen()

	const { currentStep, onClickNext, onClickBack } = useSignupStepsContext()
	const {
		school: { shortName },
		logo: { alt: altLogo, main: mainLogo },
	} = useSchoolConfig()
	continueButtonText ||= 'Continue'
	onClickContinue ||= onClickNext
	onClickSkip ||= onClickNext

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Card className={classNames(classes.root, classnames.root)}>
			{!isMobile && <ProgressBar />}
			<div className={classes.container}>
				<div className={classNames(classnames.header, classes.header)}>
					<div className={classNames(classes.headerTop, classes.mobileContainer)}>
						{currentStep > 0 && isMobile && (
							<IconButton disableRipple onClick={onClickBack} className={classes.mobileBackButton}>
								<CaretLeft weight="bold" size={ICON_SIZES.md} />
							</IconButton>
						)}
						<img className={classes.img} src={appTheme.palette.type === 'light' ? altLogo : mainLogo} alt={`${shortName} logo`} />
						{isMobile && <ProgressBar />}
					</div>
					<div className={classNames(classes.headerBottom, classes.mobileContainer)}>
						{title && (
							<Typography className={classNames(classnames.title, classes.title)} variant="h2">
								{title}
							</Typography>
						)}
						{subTitle && <Typography className={classNames(classnames.subTitle, classes.subTitle)}>{subTitle}</Typography>}
					</div>
				</div>
				{isMobile ? (
					<div className={classes.mobileContainer}>
						{children}
						{description && <Typography className={classes.description}>{description}</Typography>}
					</div>
				) : (
					<>
						{children}
						{description && <Typography className={classes.description}>{description}</Typography>}
					</>
				)}

				<div className={classes.actionsContainer}>
					{showSkipButtonWhenContinueDisabled && continueButtonDisabled ? (
						<ColorButton className={classNames(classes.button, classes.skipButton, classnames.skipButton)} onClick={onClickSkip}>
							{skipText}
						</ColorButton>
					) : (
						<ColorButton
							className={classNames(classes.button, classes.continueButton, classnames.continueButton)}
							onClick={onClickContinue}
							disabled={continueButtonDisabled}
						>
							{continueButtonText}
						</ColorButton>
					)}
					{currentStep > 0 && !isMobile && (
						<div className={classes.backButtonWrapper}>
							<button className={classes.backButton} onClick={onClickBack}>
								Go Back
							</button>
						</div>
					)}
				</div>
			</div>
		</Card>
	)
}

export default SignUpCard

import { PATHS } from '@/constants'
import SignUpSteps from '@/pages/SignUp/SignUpSteps'
import Thanks from '@/pages/SignUp/Thanks'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import Pledge from '@/pages/SignUp/TermsConditions'
import AppRoute from '@/interfaces/route'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const SIGN_UP_STEPS = createPageTitle(PAGE_NAME.SIGN_UP_STEPS)
const SIGN_UP_THANKS = createPageTitle(PAGE_NAME.SIGN_UP_THANKS)
const SIGN_UP_PLEDGE = createPageTitle(PAGE_NAME.SIGN_UP_PLEDGE)

const routes: AppRoute[] = [
	{
		component: SignUpSteps,
		title: SIGN_UP_STEPS,
		name: SIGN_UP_STEPS,
		path: PATHS.SIGN_UP.STEPS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: Thanks,
		title: SIGN_UP_THANKS,
		name: SIGN_UP_THANKS,
		path: PATHS.SIGN_UP.THANKS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: Pledge,
		title: SIGN_UP_PLEDGE,
		name: SIGN_UP_PLEDGE,
		path: PATHS.SIGN_UP.TERMS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

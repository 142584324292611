import { useMemo, useState } from 'react'

const useHover = () => {
	const [isHovered, setIsHovered] = useState<boolean>()

	const eventHandlers = useMemo(
		() => ({
			onMouseOver() {
				setIsHovered(true)
			},
			onMouseOut() {
				setIsHovered(false)
			},
		}),
		[],
	)

	return [isHovered, eventHandlers] as const
}

export default useHover

import SignUpCard from '@/components/SignUpCard'
import { STYLES } from '@/constants'
import { GridLayout } from '@/features/onboarding/components/onboardingEntities/layouts/GridLayout'
import OnboardingEntityList from '@/features/onboarding/components/onboardingEntities/list'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useIsMobileScreen } from '@/hooks/useIsMobileScreen'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import { UserItem } from './UserItem'
import { useAppSelector } from '@/store'
import { selectUserChoicesByType } from '@/features/onboarding/slice'
import { useOnboardingStepConfig } from '../hooks'

const MOBILE_CARD_WIDTH = 160
const DESKTOP_CARD_WIDTH = 221

const useStyles = makeStyles((theme) => ({
	sectionContainer: {
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	cardContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		overflow: 'visible',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, ${DESKTOP_CARD_WIDTH}px)`,
		justifyContent: 'space-evenly',
		margin: 0,
		[theme.breakpoints.down('xs')]: {
			gridTemplateColumns: `repeat(auto-fill, ${MOBILE_CARD_WIDTH}px)`,
		},
	},

	continueButton: {
		marginTop: 15,
		marginBottom: 15,
	},
	card: {
		margin: 7,
		width: '100%',
		height: 45,
		[theme.breakpoints.up('xl')]: {
			height: 50,
		},
	},
	cardHeader: {
		width: '70%',
	},
}))

export type IUserConnectionsStepProps = {
	onClickNext: () => void
}

const UserConnectionsStep: React.FC<IUserConnectionsStepProps> = ({ onClickNext }) => {
	const isMobile = useIsMobileScreen()
	const userSelections = useAppSelector((state) => selectUserChoicesByType(state, { entityType: EntityTypes.user }))
	const userConnectionConfig = useOnboardingStepConfig('connections')
	const classes = useStyles()
	const disableContinueButton = userSelections.length === 0
	return (
		<SignUpCard
			classnames={{
				header: classes.cardHeader,
				continueButton: classes.continueButton,
			}}
			title={userConnectionConfig?.title}
			subTitle={userConnectionConfig?.subTitle}
			continueButtonDisabled={disableContinueButton}
			showSkipButtonWhenContinueDisabled={true}
			onClickSkip={onClickNext}
		>
			<GridLayout>
				<OnboardingEntityList
					entityType={EntityTypes.user}
					renderItem={({ item, onClick }) => (
						<UserItem
							externalId={item.meta.externalId}
							verticalMargin={isMobile ? 5 : undefined}
							width={isMobile ? MOBILE_CARD_WIDTH : DESKTOP_CARD_WIDTH}
							height={isMobile ? 220 : undefined}
							type={item.meta?.userType ?? ''}
							campus={item.meta?.campus ?? ''}
							key={item.id}
							checked={item.checked}
							handleClick={onClick}
							fullName={item.name}
							primaryTitle={item.title}
							primaryTitleDepartment={item.subtitle} // Need to update the typing here?
							photoUrl={item.photo}
						/>
					)}
				/>
			</GridLayout>
		</SignUpCard>
	)
}

export { UserConnectionsStep }

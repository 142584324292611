import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import DiscoverPage from '@/pages/StudyAbroadPage/DiscoverPage'
import IndividualPage from '@/pages/StudyAbroadPage/IndividualPage'
import YourPage from '@/pages/StudyAbroadPage/YourPage'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_EDUCATION_ABROAD = createPageTitle(PAGE_NAME.DISCOVER_EDUCATION_ABROAD)
const MY_EDUCATION_ABROAD = createPageTitle(PAGE_NAME.MY_EDUCATION_ABROAD)

const routes: AppRoute[] = [
	{
		component: IndividualPage,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.EDUCATION_ABROAD_SINGLE(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourPage,
		title: MY_EDUCATION_ABROAD,
		name: MY_EDUCATION_ABROAD,
		path: PATHS.APP.EDUCATION_ABROAD_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverPage,
		title: DISCOVER_EDUCATION_ABROAD,
		name: DISCOVER_EDUCATION_ABROAD,
		path: PATHS.APP.EDUCATION_ABROAD,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

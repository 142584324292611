import EventCard from '@/components/ExperienceCards/EventCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import EmptyPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import {
	getIsLoadingUserEvents,
	getUserCreatedEvents,
	getUserGoingEvents,
	getUserInterestedEvents,
	getUserPastEvents,
} from '@/store/events'
import { loadUserEventsRequest } from '@/store/events/actions'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router-dom'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	placeholderHeader: {
		fontSize: 19,
		marginBottom: '2vh',
		fontWeight: 600,
	},
}))

const tabs = ['All', 'Going', 'Interested', 'Past', 'Created']

const YourEvents = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()

	const [tab, setTab] = useState(0)

	const isLoading = useSelector(getIsLoadingUserEvents)
	const interestedEvents = useSelector(getUserInterestedEvents)
	const createdEvents = useSelector(getUserCreatedEvents)
	const goingEvents = useSelector(getUserGoingEvents)
	const pastEvents = useSelector(getUserPastEvents)

	useEffect(() => {
		dispatch(loadUserEventsRequest())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Discover Events',
				onClick: () => push(PATHS.APP.EVENTS),
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[push],
	)

	const handleChangeTab = useCallback((tabIndex: number) => {
		setTab(tabIndex)
	}, [])

	const tabInfo = useMemo(() => {
		const allEvents = uniqBy([...goingEvents, ...interestedEvents, ...pastEvents, ...createdEvents], 'id')
		if (tab === 0)
			return {
				title: 'My Events',
				info: `${allEvents.length}`,
				items: allEvents,
			}
		if (tab === 1)
			return {
				title: 'Upcoming Events',
				info: `${goingEvents.length} Going`,
				items: goingEvents,
			}
		if (tab === 2)
			return {
				title: 'Upcoming Events',
				info: `${interestedEvents.length} Interested`,
				items: interestedEvents,
			}
		if (tab === 3)
			return {
				title: 'Past Events',
				info: `${pastEvents.length} Past`,
				items: pastEvents,
			}
		return {
			title: 'My Events',
			info: `${createdEvents.length} Created`,
			items: createdEvents,
		}
	}, [createdEvents, goingEvents, interestedEvents, pastEvents, tab])

	const emptyPlaceholder = useMemo(() => {
		const showNoEventsPlaceholder = !(interestedEvents.length + createdEvents.length + goingEvents.length + pastEvents.length) && !isLoading
		const showNoInterestedEventsPlaceholder = !interestedEvents.length && !isLoading
		const showNoGoingEventsPlaceholder = !goingEvents.length && !isLoading
		const showNoPastEventsPlaceholder = !pastEvents.length && !isLoading
		const showNoCreatedEventsPlaceholder = !createdEvents.length && !isLoading

		if (tab === 0 && showNoEventsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No events at the moment!
					</Typography>
					<Typography align="center">Events you’re going to, interested in, have created,</Typography>
					<Typography align="center">and past events will all appear here.</Typography>
					<Typography align="center">You can filter at the top.</Typography>
				</EmptyPlaceholder>
			)
		if ((tab === 1 && showNoGoingEventsPlaceholder) || (tab === 2 && showNoInterestedEventsPlaceholder))
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No upcoming events.
					</Typography>
					<Typography align="center">Your schedule is clear!</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 3 && showNoPastEventsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No past events.
					</Typography>
					<Typography align="center">Events you’ve RSVP’d to will</Typography>
					<Typography align="center">appear here once they’ve happened.</Typography>
				</EmptyPlaceholder>
			)
		if (tab === 4 && showNoCreatedEventsPlaceholder)
			return (
				<EmptyPlaceholder isEmptyPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center" className={classes.placeholderHeader}>
						No created events.
					</Typography>
					<Typography align="center">Events you’ve created will</Typography>
					<Typography align="center">appear here for you to manage.</Typography>
				</EmptyPlaceholder>
			)

		return null
	}, [
		classes.placeholderHeader,
		createdEvents.length,
		goingEvents.length,
		interestedEvents.length,
		isLoading,
		pastEvents.length,
		placeholderButtons,
		tab,
	])

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title={tabInfo.title} pageInfo={tabInfo.info} tabs={tabs} handleChangeTab={handleChangeTab} activeTab={tab} />
			{emptyPlaceholder ? (
				emptyPlaceholder
			) : (
				<ExperienceCardsSection
					showPagination
					items={tabInfo.items}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(
								(
									{
										name,
										startDate,
										endDate,
										photoUrl,
										locationName,
										id,
										description,
										response,
										categories,
										organization,
										volunteerShifts,
										submittedByUserId,
									}: any,
									index: number,
								) => (
									<EventCard
										key={`${index}-${id}-${name}-${startDate}`}
										id={id}
										title={name}
										photoUrl={photoUrl}
										categories={categories}
										startDate={startDate}
										endDate={endDate}
										description={description}
										organization={organization}
										locationName={locationName}
										eventResponse={response}
										volunteerShifts={volunteerShifts}
										submittedByUserId={submittedByUserId}
									/>
								),
							)}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default YourEvents

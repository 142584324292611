import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { DollarIcon, DotButton, IconWrapper, LocationOutlinedIcon, PersonOutlinedIcon, ToggleButton } from '@/components'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import ItemCardContainer from '@/components/ExperienceCards/ItemCardContainer'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import useResponseHandler from '@/features/responses/hooks/useResponseHandler'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { UserResponseStatuses } from '@/interfaces/common'
import { getPositionTypes } from '@/store/constants/constantsSlice'
import useGlobalStyles from '@/styles/globalStyles'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	footerFullWidthButton: {
		height: 50,
		width: 'calc(100% - 60px)',
	},
	contentInfo: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '5px 0',
	},
	contentInfoIcon: {
		color: theme.palette.primary.main,
		fontSize: '1.25em',
		marginRight: 10,
	},
	contentContainer: {
		justifyContent: 'end',
	},
	title: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	commonInfo: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	},
}))

interface InternshipCardProps {
	photoUrl: string
	title: string
	id: string
	employer?: string
	positionPaid?: boolean
	jobTypeId?: number
	status: UserResponseStatuses
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
}

const InternshipCard = ({
	photoUrl = '',
	title = '',
	employer = '',
	positionPaid,
	jobTypeId = 0,
	status,
	id,
	variant = 'primary',
	showActionButtons = true,
	showBadge = false,
}: InternshipCardProps) => {
	const { push } = useHistory()

	const classes = useStyles()
	const globalClasses = useGlobalStyles()
	const { handleChangeResponse, handleReport } = useResponseHandler('jobs', id)
	const jobTypes = useSelector(getPositionTypes)

	const isActive = status === UserResponseStatuses.interested
	const jobTypeLabel = jobTypes.find((type) => type.value === jobTypeId)?.label
	const positionPaidLabel = positionPaid === true ? 'Paid' : 'Unpaid'

	const sharingUrl = PATHS.APP.INTERNSHIPS_SINGLE(id)

	const handleChangeStatus = useCallback(
		(e: any) => {
			e.stopPropagation()

			handleChangeResponse(isActive ? UserResponseStatuses.cleared : UserResponseStatuses.interested)
		},
		[handleChangeResponse, isActive],
	)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.INTERNSHIPS_SINGLE(id))
	}, [id, push])

	const handleRenderCardHeader = useCallback(
		() => (
			<>
				<div className={globalClasses.alignCenter}>
					{showBadge ? (
						<CardIconContainer>
							<IconWrapper iconKey={FEATURE.jobs} weight="fill" size={ICON_SIZES.md} aria-label="Job" />
						</CardIconContainer>
					) : null}
				</div>
				{showActionButtons && <DotButton itemId={id} onSubmitReport={handleReport} reportSubject={ReportSubject.Internship} />}
			</>
		),
		[globalClasses.alignCenter, showBadge, showActionButtons, id, handleReport],
	)

	const handleRenderCardContent = useCallback(
		() => (
			<>
				{employer && (
					<div className={classNames(classes.contentInfo, classes.commonInfo)}>
						<LocationOutlinedIcon className={classes.contentInfoIcon} /> <p>{employer}</p>
					</div>
				)}
				{jobTypeLabel && (
					<div className={classNames(classes.contentInfo, classes.commonInfo)}>
						<PersonOutlinedIcon className={classes.contentInfoIcon} /> <p>{jobTypeLabel}</p>
					</div>
				)}
				{positionPaidLabel && (
					<div className={classNames(classes.contentInfo, classes.commonInfo)}>
						<DollarIcon className={classes.contentInfoIcon} /> <p>{positionPaidLabel}</p>
					</div>
				)}
			</>
		),
		[classes.commonInfo, classes.contentInfo, classes.contentInfoIcon, employer, jobTypeLabel, positionPaidLabel],
	)

	const handleRenderActionButtons = useCallback(
		() => (
			<>
				<ToggleButton className={classes.footerFullWidthButton} onClick={handleChangeStatus} isActive={isActive}>
					{/* Adding this in for future change */}
					{isActive ? 'Bookmarked' : 'Bookmark'}
				</ToggleButton>
			</>
		),
		[classes.footerFullWidthButton, handleChangeStatus, isActive],
	)

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.job } }), [id])

	return variant === 'primary' ? (
		<ItemCardContainer
			title={title}
			photoUrl={photoUrl}
			containerClasses={{
				contentContainer: classes.contentContainer,
				titleContainer: classes.title,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	) : (
		<FeedsCardContainer
			showActionButtons={showActionButtons}
			title={title}
			photoUrl={photoUrl}
			containerClasses={{
				contentContainer: classes.contentContainer,
				titleContainer: classes.title,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	)
}

export default InternshipCard

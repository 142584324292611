export const Middot = (props) => (
	<span
		style={{
			paddingRight: '.45em',
			paddingLeft: '.45em',
			color: props.color || 'rgba(0, 0, 0, 0.44)',
		}}
	>
		·
	</span>
)

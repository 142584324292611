import { Link, Typography, useMediaQuery } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import { ButtonColors } from '@/components/Buttons/types'

import { STYLES } from '@/constants'
import { submitFeedback } from '@/utils/services'

import OutlinedButton from '@/components/Buttons/OutlinedButton'

import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { MOBILE_CONTAINER_WIDTH } from '@/pages/LandingPage'
import Copyright from './Footer/Copyright'
import FooterNav from './Footer/FooterNav'
import PhoneLink from './Footer/PhoneLink'
import { SocialLinks } from './Footer/SocialLinks'
import IconWrapper from './IconWrapper'

interface StyleProps {
	landing?: boolean
}
interface IFooterProps extends StyleProps {}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: ({ landing }: any) => ({
		zIndex: 2,
		width: '100%',
		boxShadow: !landing && theme.palette.type === 'dark' ? theme.shadows[15] : 'none',
		backgroundColor: theme.palette.type === 'light' || landing ? theme.palette.primary.main : theme.palette.background.paper,
		height: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
		},
	}),
	logoLinkContainer: {
		display: 'flex',
		width: '90%',
		alignItems: 'center',
		height: 43,
		justifyContent: 'center',
	},
	footerMenu: {
		fontSize: '1rem',
		'@media only screen and (max-width: 767px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			margin: `${theme.spacing(0.5)}px 0px`,
			'& li': {
				margin: `${theme.spacing(0.25)}px 0px`,
			},
		},
	},
	typography: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '1rem',
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: '0.875rem',
		},
	},
	mobileContainer: {
		display: 'flex',
	},
	mobileRow: {
		display: 'flex',
		alignItems: 'center',
	},
	feedback: {
		margin: '10px',
		[theme.breakpoints.down('xs')]: {
			margin: '10px 0',
			width: `${MOBILE_CONTAINER_WIDTH}vw`,
		},
	},
	feedbackText: {
		margin: '0 5px',
	},
	feedbackButton: {
		fontSize: '14pt',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1rem',
			marginTop: theme.spacing(1),
			width: '100%',
		},
	},
	addressContainer: {
		fontStyle: 'normal',
		fontSize: '1rem',
		'& span:not(:last-child):after': {
			display: 'inline-block',
			content: '"|"',
			paddingLeft: '0.25em',
			paddingRight: '0.25em',
			[theme.breakpoints.down('xs')]: {
				content: '""',
			},
		},
	},
	copyrightContainer: {
		marginRight: '0.5em',
		'@media only screen and (max-width: 767px)': {
			fontSize: '0.825rem',
			borderTop: `1px solid ${theme.palette.common.white}`,
			marginTop: theme.spacing(1.5),
			padding: `${theme.spacing(1)}px 0`,
			marginRight: 0,
			width: '100%',
		},
	},
	socialLinks: {
		'& li': {
			display: 'inline-flex',
			marginLeft: '1em',
		},
		'@media only screen and (max-width: 1080px)': {
			marginTop: theme.spacing(1),
			'& li': {
				marginRight: theme.spacing(1),
				marginLeft: 0,
			},
		},
	},
	footerRow: {
		maxWidth: '90%',
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		'@media only screen and (max-width: 767px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: `${MOBILE_CONTAINER_WIDTH}vw`,
			maxWIdth: `${MOBILE_CONTAINER_WIDTH}vw`,
		},
	},

	footerTop: {
		marginBottom: 10,
	},
}))

const Footer: React.FC<IFooterProps> = ({ landing }) => {
	const classes = useStyles({ landing })

	const isMobile = useMediaQuery('@media only screen and (max-width: 767px)')
	const {
		links,
		school: { name: schoolName },
		contact: { address, email, phone },
	} = useSchoolConfig()

	const addressParts = [
		{
			key: 'physical-address',
			element: address,
		},
		...(phone && phone !== '(814) 863-1296'
			? [
					{
						key: 'phone',
						element: <PhoneLink underline="always" color="inherit" aria-label={phone} label={phone} />,
					},
			  ]
			: []),
		...(email && email !== 'undefined'
			? [
					{
						key: 'email',
						element: (
							<Link underline="always" color="inherit" aria-label={email} href={`mailto:${email}`}>
								{email}
							</Link>
						),
					},
			  ]
			: []),
	]
	const socialLinks = [
		...(links?.social?.twitter
			? [
					{
						label: 'Twitter',
						href: links.social.twitter,
						icon: <IconWrapper iconKey="socialMediaTwitter" color="white" weight="fill" />,
					},
			  ]
			: []),
		...(links?.social?.facebook
			? [
					{
						label: 'Facebook',
						href: links?.social?.facebook,
						icon: <IconWrapper iconKey="socialMediaFacebook" color="white" weight="fill" />,
					},
			  ]
			: []),
	]

	return (
		<footer className={classes.root}>
			<Typography className={classNames(classes.typography, classes.mobileContainer, classes.feedback)}>
				👋 <b className={classes.feedbackText}> Need help? Have feedback? Let us know!&nbsp;</b>
				<OutlinedButton className={classes.feedbackButton} onClick={submitFeedback} color={ButtonColors.WHITE}>
					Submit Feedback
				</OutlinedButton>
			</Typography>
			<div className={classNames(classes.footerTop, classes.footerRow)}>
				{!isMobile && <Copyright className={classes.copyrightContainer} atName={`The ${schoolName}`} />}
				<FooterNav className={classes.footerMenu} items={links.footer} />
			</div>

			<div className={classes.footerRow}>
				<address className={classes.addressContainer}>
					{addressParts.map(({ element, key }) => (
						<span key={key}>{element}</span>
					))}
				</address>
				<SocialLinks className={classes.socialLinks} items={socialLinks} />
				{isMobile && <Copyright className={classes.copyrightContainer} atName={`The ${schoolName}`} />}
			</div>
		</footer>
	)
}

export default Footer

import ShareButton from '@/components/Buttons/ShareButton'
import MenuIconButton from '@/components/Buttons/IconButton'
import CannotUnfollowPopper from '@/components/IndividualPage/CannotUnfollowPopper'
import Card from '@/components/IndividualPage/Card'
import RequestToChangePositionModal from '@/components/IndividualPage/RequestToChangePositionModal'

import OutlinedButton from '@/components/Buttons/OutlinedButton'
import NavigationTab from '@/components/NavigationTab'
import NavigationTabs from '@/components/NavigationTabs'
import CancelRequestModal from '@/components/UserNetwork/CancelRequestModal'
import { PATHS } from '@/constants'
import { ORG_CENTRAL_URL } from '@/constants/configuration'
import ORGANIZATION_ROLE from '@/constants/organizationRoles'
import useOutsideClickCallback from '@/hooks/useOutsideClickCallback'
import { SharedData } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { cancelMembershipRequestRequest } from '@/store/organizations'
import { orgTypes } from '@/utils/organizationRoles'
import { Popper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import classNames from 'classnames'
import { FC, MutableRefObject, ReactNode, useMemo, useRef, useState } from 'react'

import { DtoOrganization } from '@/anthology/organizations/types/dto/DtoOrganization'
import { capitalize } from 'lodash'
import OrganizationInfoRequestModal from './modals/OrganizationInfoRequestModal'
import OrganizationJoinRequestModal from './modals/OrganizationJoinRequestModal'

const useStyles = makeStyles((theme) => ({
	boldText: {
		fontWeight: 700,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		justifyContent: 'space-between',
	},
	responseButton: {
		height: '50px',
		width: '48%',
		marginRight: 10,
	},
	feedbackButton: {
		minWidth: 50,
		height: 50,
	},
	cardRoot: {
		padding: '2vh 2vw 0 2vw',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		margin: '1vh 0',
		width: '50%',
	},
	tab: {
		marginTop: 0,
	},
	outlinedButton: {
		color: theme.palette.text.primary,
	},
	optionsContainer: {
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginTop: 10,
		minWidth: 200,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		borderRadius: '7px 0 7px 7px',
		border: `solid 1px ${theme.palette.divider}`,
	},
	popoverButton: {
		width: '100%',
		color: theme.palette.text.primary,
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	requestedToJoinMark: {
		width: '100%',
		height: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
		borderRadius: 7,
	},
}))

interface OrganizationTitleCardProps {
	title: string
	tabs: string[]
	handleChangeTab: (tab: number) => void
	followed?: boolean
	dataLake?: boolean
	externalUrl?: string
	handleToggleResponse?: () => void
	handleRequestToChangePosition?: (newMembershipTypeId: orgTypes) => void
	activeTab: number
	sharedData?: SharedData
	id: string
	userRequestedToJoin?: boolean
	userRole?: ORGANIZATION_ROLE
	userMembershipTypeId?: orgTypes
	userOrganizationRole?: DtoOrganization['role']
	canLeaveOrganization?: boolean
	showJoin?: DtoOrganization['showJoin']
}

interface IDropdownActionsProps {
	actions: Array<{ icon?: ReactNode; label: string; onClick: () => void }>
	renderContent: (data: { ref: MutableRefObject<HTMLButtonElement>; toggle: () => void }) => ReactNode
}

const DropdownActions: FC<IDropdownActionsProps> = ({ actions, renderContent }) => {
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(false)
	const buttonAnchorRef = useRef<HTMLButtonElement>(null)
	const popperRef = useRef<any>(null)
	const handleClickPopperButton = (callback: (e: any) => void) => (e: any) => {
		setIsOpen(false)
		callback(e)
	}
	const handleClosePopper = (event: React.MouseEvent<EventTarget>) => {
		if (buttonAnchorRef.current && buttonAnchorRef.current.contains(event.target as HTMLElement)) {
			return
		}

		setIsOpen(false)
	}

	useOutsideClickCallback(popperRef, handleClosePopper)
	return (
		<>
			<Popper
				ref={popperRef}
				className={classes.paper}
				open={isOpen}
				anchorEl={buttonAnchorRef.current}
				role={undefined}
				placement="bottom-end"
			>
				<div className={classes.optionsContainer}>
					{actions.map((action) => (
						<MenuIconButton
							key={action.label}
							className={classes.popoverButton}
							onClick={handleClickPopperButton(action.onClick)}
							startIcon={action.icon}
						>
							{action.label}
						</MenuIconButton>
					))}
				</div>
			</Popper>
			{renderContent({ ref: buttonAnchorRef, toggle: () => setIsOpen((prev) => !prev) })}
		</>
	)
}

const OrganizationTitleCard = ({
	title = '',
	followed = false,
	handleToggleResponse,
	tabs,
	handleChangeTab,
	activeTab,
	sharedData = {},
	id,
	dataLake,
	externalUrl,
	handleRequestToChangePosition,
	userRole,
	userMembershipTypeId,
	userOrganizationRole,
	showJoin,
}: OrganizationTitleCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const sharingUrl = PATHS.APP.ORGANIZATIONS_SINGLE(id)

	const [
		{ isOpenRequestInformationModal, isOpenRequestToJoinModal, isOpenRequestToChangePositionModal, isOpenCancelRequestModal },
		setState,
	] = useState({
		isOpenRequestInformationModal: false,
		isOpenRequestToJoinModal: false,

		isOpenRequestToChangePositionModal: false,
		isOpenCancelRequestModal: false,
	})
	const [unfollowAnchorEl, setUnfollowAnchorEl] = useState<any>(null)

	const handleClickRequestToJoin = () => {
		if (dataLake) {
			const link = externalUrl || ORG_CENTRAL_URL
			window.open(link, '_blank')
		} else {
			setState((prev) => ({
				...prev,
				isOpenRequestToJoinModal: true,
			}))
		}
	}
	const joinActions = useMemo(() => {
		const buttons = [
			{
				label: 'Request information',
				icon: null,
				onClick: () => {
					setState((prev) => ({
						...prev,
						isOpenRequestInformationModal: true,
					}))
				},
			},
		]
		if (showJoin) {
			buttons.push({
				label: 'Request to join',
				icon: null,
				onClick: () => handleClickRequestToJoin(),
			})
		}
		return buttons
		// @TODO: #esLint need to be addressed properly
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showJoin])

	const handleCancelRequest = () => {
		dispatch(cancelMembershipRequestRequest(id))
	}

	const handleOpenCancelRequestModal = () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
		}))
	}

	const handleCloseCancelRequestModal = () => {
		setState((prev) => ({
			...prev,
			isOpenCancelRequestModal: !prev.isOpenCancelRequestModal,
		}))
	}

	const handleToggleUnfollowPopper = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation()
		if (unfollowAnchorEl) {
			setUnfollowAnchorEl(null)
		} else {
			setUnfollowAnchorEl(event.currentTarget)
		}
	}

	const handleCloseUnfollowPopper = () => {
		setUnfollowAnchorEl(null)
	}

	const handleToggleChangePositionModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRequestToChangePositionModal: !prev.isOpenRequestToChangePositionModal,
		}))
	}

	const onRequestToChangePosition = (membershipTypeId: orgTypes) => {
		handleRequestToChangePosition(membershipTypeId)
		handleToggleChangePositionModal()
	}

	const handleCloseModal = () => {
		setState((prev) => ({
			...prev,
			isOpenRequestToJoinModal: false,
			isOpenRequestInformationModal: false,
		}))
	}

	return (
		<>
			<CancelRequestModal
				isOpenModal={isOpenCancelRequestModal}
				handleCloseModal={handleCloseCancelRequestModal}
				onSubmit={handleCancelRequest}
			/>
			<RequestToChangePositionModal
				isOpen={isOpenRequestToChangePositionModal}
				userMembershipTypeId={userMembershipTypeId}
				onSubmit={onRequestToChangePosition}
				handleCloseModal={handleToggleChangePositionModal}
			/>
			<OrganizationInfoRequestModal orgId={id} handleClose={handleCloseModal} isOpen={isOpenRequestInformationModal} />
			<OrganizationJoinRequestModal orgId={id} handleClose={handleCloseModal} isOpen={isOpenRequestToJoinModal} />

			{userRole && <CannotUnfollowPopper anchorEl={unfollowAnchorEl} handleClose={handleCloseUnfollowPopper} userRole={userRole} />}

			<Card className={classes.cardRoot}>
				<Typography className={classes.boldText} variant="h2">
					{title}
				</Typography>
				<div className={classes.contentContainer}>
					<NavigationTabs
						activeTabIndex={activeTab}
						centered={false}
						tabsRenderer={(props) =>
							tabs.map((tab, index) => (
								<NavigationTab
									className={classes.tab}
									key={`${tab}-${index}`}
									index={index}
									label={tab}
									onClick={handleChangeTab}
									{...props}
								/>
							))
						}
					/>
					<div className={classes.buttonsContainer}>
						{!followed ? (
							<ColorButton onClick={handleToggleResponse} className={classes.responseButton}>
								Follow
							</ColorButton>
						) : (
							<OutlinedButton
								color={ButtonColors.GREY}
								onClick={userRole ? handleToggleUnfollowPopper : handleToggleResponse}
								className={classNames(classes.responseButton, classes.outlinedButton)}
							>
								Following
							</OutlinedButton>
						)}
						{!userOrganizationRole ? (
							<DropdownActions
								actions={joinActions}
								renderContent={({ ref, toggle }) => {
									return (
										<ColorButton ref={ref} onClick={toggle} color={ButtonColors.GREY} className={classes.responseButton}>
											{showJoin ? 'Join' : 'Learn more'}
										</ColorButton>
									)
								}}
							/>
						) : (
							<ColorButton disabled onClick={handleOpenCancelRequestModal} color={ButtonColors.GREY} className={classes.responseButton}>
								{capitalize(userOrganizationRole.type)}
							</ColorButton>
						)}
						<ShareButton className={classes.feedbackButton} variant="secondary" sharedData={sharedData} sharingUrl={sharingUrl} />
					</div>
				</div>
			</Card>
		</>
	)
}

export default OrganizationTitleCard

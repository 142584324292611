import { ContentPreloader } from '@/components/ContentPreloader'
import { UserInfo } from '@/interfaces/user'
import { getOrganizationRole } from '@/utils/organizationRoles'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import React from 'react'
import { useGetAnalyticsUserOrgMembershipsQuery } from '../../api/usersApi'
import { CommonCard } from '../CommonCard'
import UserInfoRow from './UserInfoRow'

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
		'& .MuiTableCell-root': {
			borderBottom: `2px solid ${theme.palette.divider}`,
			padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
		},
		'& .MuiTableCell-head': {
			fontWeight: 500,
			fontSize: '18px',
		},
	},
	emptyResults: {
		padding: `${theme.spacing(10)}px ${theme.spacing(4)}px`,
		textAlign: 'center',
		fontWeight: 700,
		fontSize: '40px',
		color: theme.palette.grey[300],
	},
}))

export type IOrganizationMembershipsProps = {
	userId: UserInfo['id']
}

const EmptyResults = () => {
	const classes = useStyles()
	return <Typography className={classes.emptyResults}>No Memberships</Typography>
}

const OrganizationMemberships: React.FC<IOrganizationMembershipsProps> = ({ userId }) => {
	const { data, isLoading } = useGetAnalyticsUserOrgMembershipsQuery({ userId })
	const classes = useStyles()
	return (
		<UserInfoRow>
			<CommonCard title="Membership">
				{isLoading ? (
					<ContentPreloader fullPage />
				) : (
					<TableContainer>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell align="left">Organization</TableCell>
									<TableCell align="left">Member Type</TableCell>
									<TableCell align="left">Start Date</TableCell>
									<TableCell align="left">End Date</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.items.length > 0 ? (
									data.items.map((row) => {
										const formattedStartDate = DateTime.fromISO(row.startDate, { zone: 'utc' }).toFormat('LLL dd, yyyy')
										const formattedEndDate = row.endDate
											? DateTime.fromISO(row.startDate, { zone: 'utc' }).toFormat('LLL dd, yyyy')
											: 'Present'

										return (
											<TableRow key={row.organizationName}>
												<TableCell align="left">{row.organization?.name}</TableCell>
												<TableCell align="left">
													{getOrganizationRole(row?.organizationMembershipTypeId as 1 | 2 | 3)}
													{row.membershipTitle ? `: ${row.membershipTitle}` : null}
												</TableCell>
												<TableCell align="left">{formattedStartDate}</TableCell>
												<TableCell align="left">{formattedEndDate}</TableCell>
											</TableRow>
										)
									})
								) : (
									<TableRow>
										<TableCell colSpan={4} align="center">
											<EmptyResults />
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</CommonCard>
		</UserInfoRow>
	)
}

export { OrganizationMemberships }

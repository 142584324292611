import { TextButton } from '@/components/Buttons'
import Card from '@/components/CommonCard'
import BuildYourPathCard from '@/components/PathBuilder/BuildYourPathCard'
import GoalsForm from '@/components/PathBuilder/GoalsForm'
import SuccessPopup from '@/components/PathBuilder/SuccessPopupWithButton'
import { SurveyCard } from '@/components/UserProfile/SurveyCard'
import { STYLES } from '@/constants'
import { isEngage } from '@/constants/configuration'
import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useAppDispatch } from '@/store'
import { getUserGoals, initGoalsRequest } from '@/store/auth'
import { getIsOpenPathBuilderCompletedPopup, setIsOpenPathBuilderCompletedPopup } from '@/store/pathBuilder'
import { formatDateNew } from '@/utils/dateTime2'
import getNameOfPathBuilder from '@/utils/getNameOfPathBuilder'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ContentPreloader } from '../ContentPreloader'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	headerText: {
		fontWeight: 700,
	},
	subHeader: {
		margin: '1vh 0',
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		margin: '1vh 0',
	},
	itemMark: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		border: `solid 1px ${theme.palette.primary.main}`,
		color: theme.palette.primary.main,
		minWidth: 37,
		minHeight: 37,
		marginRight: 15,
	},
	description: {
		marginRight: '2vw',
		width: '40%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	pathBuilderHeader: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	pathBuilderHeaderContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
	divider: {
		margin: '0 10px',
		color: theme.palette.border.bold,
	},
	versionsList: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column-reverse',
		alignItems: 'center',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
	greyText: {
		color: theme.palette.text.secondary,
	},
	buttonsContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
}))

interface GoalsTabProps {
	goalResponses: any[]
	canEdit: boolean
}

const GoalsTab = ({ goalResponses }: GoalsTabProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const goals = useSelector(getUserGoals)
	const updatedGoalsIndex = goals.length ? goals.length - 1 : 0
	const nameOfActivity = getNameOfPathBuilder(isEngage())

	const isOpenPopup = useSelector(getIsOpenPathBuilderCompletedPopup)

	const [goalsToDisplayIndex, setGoalsToDisplayIndex] = useState(updatedGoalsIndex)
	const [isEditMode, setIsEditMode] = useState(false)
	const [seeHistory, setSeeHistory] = useState(false)
	const [isOpenDifferentVersion, setIsOpenDifferentVersion] = useState(false)

	const {
		school: { shortName },
	} = useSchoolConfig()

	const steps = useMemo(
		() => [
			'Input your goals and interests.',
			'Unlock resources and next steps customized to your goals.',
			`Get the most out of your ${shortName} experience!`,
		],
		[shortName],
	)

	const handleClickEdit = () => {
		setIsEditMode(!isEditMode)
		setSeeHistory(false)
		setIsOpenDifferentVersion(false)
	}
	const handleClickOpenHistory = () => {
		setIsEditMode(false)
		setSeeHistory(true)
		setIsOpenDifferentVersion(false)
	}
	const handleShowCurrentGoals = () => {
		setIsEditMode(false)
		setSeeHistory(false)
		setIsOpenDifferentVersion(false)
		setGoalsToDisplayIndex(updatedGoalsIndex)
	}

	const handleOpenGoalsVersion = (index: number) => () => {
		setIsEditMode(false)
		setSeeHistory(false)
		setIsOpenDifferentVersion(true)
		setGoalsToDisplayIndex(index)
	}

	const handleClosePopup = () => {
		dispatch(setIsOpenPathBuilderCompletedPopup(false))
	}

	useEffect(() => {
		if (updatedGoalsIndex !== goalsToDisplayIndex) {
			setGoalsToDisplayIndex(updatedGoalsIndex)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goals.length])

	useEffect(() => {
		;(async () => {
			if (goalResponses.length > 0 && goals.length === 0) {
				setIsLoading(true)
				await dispatch(initGoalsRequest()).unwrap()
				setIsLoading(false)
			}
		})()
	}, [goalResponses, goals, dispatch])

	if (!goalResponses.length)
		return (
			<>
				<Card className={classes.root}>
					<div className={classes.description}>
						<Typography className={classes.headerText} variant="h1">
							Welcome to {nameOfActivity} 👋
						</Typography>
						<Typography className={classes.subHeader} variant="body1">
							Your personalized engagement tool
						</Typography>
						{steps.map((text, index) => (
							<div key={index} className={classes.item}>
								<span className={classes.itemMark}>
									<Typography variant="body1">{index + 1}</Typography>
								</span>
								<Typography>{text}</Typography>
							</div>
						))}
					</div>
					<BuildYourPathCard />
				</Card>
				{!isEngage() ? <SurveyCard /> : null}
			</>
		)

	const hasEdits = goals.length > 1

	const currentHeader = seeHistory ? 'History' : isOpenDifferentVersion ? `Summary (Version ${goalsToDisplayIndex + 1})` : 'Summary'

	const currentSubHeader = seeHistory
		? `Since completing ${nameOfActivity}, you have ${
				hasEdits ? `edited your goals ${goals.length - 1} time${goals.length - 1 > 1 ? 's' : ''}.` : 'not edited your goals.'
		  }`
		: !isOpenDifferentVersion
		? 'You can edit at any time.'
		: ''

	const headerButtons = (
		<>
			{!seeHistory && !isOpenDifferentVersion && (
				<div className={classes.buttonsContainer}>
					<TextButton onClick={handleClickEdit}>Edit Goals</TextButton>
					<span className={classes.divider}> | </span>
					<TextButton onClick={handleClickOpenHistory}>See History</TextButton>
				</div>
			)}
			{!isEditMode && seeHistory && !isOpenDifferentVersion && (
				<div className={classes.buttonsContainer}>
					<TextButton onClick={handleShowCurrentGoals}>Back to Current Goals</TextButton>
				</div>
			)}
			{!isEditMode && isOpenDifferentVersion && (
				<div className={classes.buttonsContainer}>
					<TextButton onClick={handleClickOpenHistory}>Back to History</TextButton>
					<span className={classes.divider}> | </span>
					<TextButton onClick={handleShowCurrentGoals}>See Current Goals</TextButton>
				</div>
			)}
		</>
	)
	if (isLoading) {
		return <ContentPreloader />
	}
	return (
		<>
			<SuccessPopup
				title={['You’ve set your goals.', 'Now, it’s time to accomplish them.']}
				header="Success!"
				text="You can find and edit your goals on your profile.
        Each version of your goals will be saved."
				buttonLabel="Go to Profile"
				isOpenModal={isOpenPopup}
				handleCloseModal={handleClosePopup}
			/>
			{!isEngage() ? <SurveyCard /> : null}
			<Card className={classes.root}>
				<div className={classes.pathBuilderHeader}>
					<div className={classes.pathBuilderHeaderContainer}>
						<Typography variant="h1">
							<b>{nameOfActivity}</b> | {currentHeader}
						</Typography>
						{headerButtons}
					</div>
					{!isOpenDifferentVersion ? (
						<Typography className={classes.subHeader} variant="subtitle1">
							{currentSubHeader}
						</Typography>
					) : (
						// @ts-ignore
						<Typography className={classes.greyText}>
							{goalsToDisplayIndex ? 'Edited ' : 'Completed '} {formatDateNew(goals[goalsToDisplayIndex].createdAt, 'dateMed')}
						</Typography>
					)}
				</div>
			</Card>
			{!seeHistory && goals.length > 0 ? (
				<GoalsForm data={goals[goalsToDisplayIndex]} canEdit={isEditMode} />
			) : (
				<div className={classes.versionsList}>
					{goals.map((goal: any, idx) => (
						<Card key={idx} withMargin={false} onClick={handleOpenGoalsVersion(idx)}>
							<div className={classes.pathBuilderHeader}>
								<div className={classes.pathBuilderHeaderContainer}>
									<Typography variant="subtitle1">
										<b>{!idx ? 'Original Summary' : `Version ${idx + 1}`}</b> {!idx && `(Version ${idx + 1})`}
									</Typography>
									<Typography className={classes.greyText}>
										{idx ? 'Edited ' : 'Completed '} {formatDateNew(goal.createdAt, 'dateMed')}
									</Typography>
								</div>
							</div>
						</Card>
					))}
				</div>
			)}
		</>
	)
}

export default GoalsTab

import { makeStyles } from '@material-ui/core/styles'
import { Dialog, IconButton } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
		position: 'absolute',
		inset: 'calc(0% + 0px) calc(100% + 0px) calc(0% + 0px) calc(0% + 0px)',
	},
	container: {
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 'calc(50%)',
		position: 'relative',
		'&:focus-visible': {
			outline: 'none',
		},
	},
	closeButton: {
		position: 'fixed',
		top: 0,
		right: 0,
		width: 40,
		height: 40,
		color: theme.palette.common.white,
		margin: 25,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
}))

interface ImageViewModalProps {
	imageSrc: string
	onClose: (e: any) => void
}

export const ImageViewModal = ({ imageSrc, onClose }: ImageViewModalProps) => {
	const classes = useStyles()

	const open = !!imageSrc

	const handleCloseModal = (e: any) => {
		e.stopPropagation()
		onClose(e)
	}

	return (
		<Dialog open={open} onClose={handleCloseModal} className={classes.modal}>
			<div className={classes.container} onClick={handleCloseModal}>
				<IconButton className={classes.closeButton} onClick={handleCloseModal}>
					<CloseOutlined fontSize="medium" />
				</IconButton>
				<img className={classes.image} src={imageSrc} alt={''} />
			</div>
		</Dialog>
	)
}

export default ImageViewModal

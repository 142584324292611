import { loadMessagesListRequest, markAllRead } from '@/store/messages'
import { baseApi } from '../general/baseApi'
import { AppDispatch } from '@/store'

// @TODO: define entire messages and groups api here and use optimistic updates
const updateStoreAfterMarkAll = (dispatch: AppDispatch, { targetUserId, groupId }: { targetUserId?: number; groupId?: string }) => {
	dispatch(
		loadMessagesListRequest({
			groupId,
			userId: targetUserId,
		}),
	)
	dispatch(markAllRead({ groupId, targetUserId }))
}

export const messagesApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		markAsViewedDirectMessages: builder.mutation<undefined, { userId: number; targetUserId: number }>({
			query: ({ userId, targetUserId }) => ({
				url: `/messages/${userId}/view-all/${targetUserId}`,
				method: 'PATCH',
			}),
			onQueryStarted: async ({ userId, targetUserId }, { dispatch, queryFulfilled, getState }) => {
				try {
					await queryFulfilled
					// After successfully patching messages as viewed, updating store data
					updateStoreAfterMarkAll(dispatch, { targetUserId })
				} catch {
					console.error('Failed to patch direct messages all read')
				}
			},
		}),
		markAsViewedGroupMessages: builder.mutation<undefined, { groupId: string }>({
			query: ({ groupId }) => ({
				url: `/groups/${groupId}/view-all`,
				method: 'PATCH',
			}),
			onQueryStarted: async ({ groupId }, { dispatch, queryFulfilled, getState }) => {
				try {
					await queryFulfilled
					updateStoreAfterMarkAll(dispatch, { groupId })
				} catch {
					console.error('Failed to patch group messages all read')
				}
			},
		}),
	}),
})

export const { useMarkAsViewedDirectMessagesMutation, useMarkAsViewedGroupMessagesMutation } = messagesApi

import { IconButton, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { STYLES } from '@/constants'

interface StylesProps {
	textColor?: string
	textHoverColor?: string
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: 0,
	},
	button: ({ textColor, textHoverColor }) => ({
		width: '100%',
		minHeight: 'min-content',
		height: 55,
		backgroundColor: theme.palette.specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: textColor ?? theme.palette.text.primary,
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			color: textHoverColor ?? theme.palette.primary.main,
			backgroundColor: theme.palette.specialColors.transparent,
		},
		padding: 20,
		paddingRight: 5,
		...theme.typography.body1,
	}),
	content: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		alignItems: 'center',
	},
	buttonText: {
		fontWeight: 600,
		marginRight: 5,
	},
	icon: {
		width: 35,
		height: 35,
		color: theme.colors.grey[300],
	},
}))

export interface SettingsButtonProps extends StylesProps {
	label: string
	icon?: React.ReactNode
	onClick?: () => void
	showArrow?: boolean
}

const SettingsButton = ({ label, icon, onClick, showArrow = true, textColor, textHoverColor }: SettingsButtonProps) => {
	const classes = useStyles({ textColor, textHoverColor })

	const handleClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<div className={classes.root}>
			<IconButton className={classes.button} onClick={handleClick}>
				<div className={classes.content}>
					<Typography className={classes.buttonText}>{label}</Typography>
					{icon}
				</div>
				{showArrow && <ChevronRightIcon className={classes.icon} />}
			</IconButton>
		</div>
	)
}

export default SettingsButton

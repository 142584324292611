import http from '@/api/http/requestHttp'

export enum ChangeGroupMember {
	BLOCK = 'block',
	UNBLOCK = 'unblock',
	REMOVE = 'remove',
	UPGRADE = 'upgrade',
	DOWNGRADE = 'downgrade',
}

export interface PatchGroupMemberBody {
	action: `${ChangeGroupMember}`
}

const patchGroupById = async (id: string, memberId: string, data: PatchGroupMemberBody, schoolId: number) => {
	const url = `${schoolId}/groups/${id}/members/${memberId}`

	return http.patch(url, data)
}

export default patchGroupById

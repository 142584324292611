import { PathBuilderState } from '@/store/pathBuilder/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/pathBuilder/constants'

const selectState = (state: { [MODULE_NAME]: PathBuilderState }) => state[MODULE_NAME]

export const getForm = createSelector(selectState, (state) => state.form)
export const getBuilderData = createSelector(selectState, (state) => state.formBuilder.data)
export const getCurrentStep = createSelector(selectState, (state) => state.formBuilder.currentStep)
export const getIsEditForm = createSelector(selectState, (state) => state.formBuilder.isEdit)

export const getSearchOrganizationQuery = createSelector(selectState, (state) => state.searchOrganization.q)
export const getCanLoadMoreSearchOrganizationResults = createSelector(selectState, (state) => state.searchOrganization.canLoadMore)
export const getIsLoadingSearchOrganizationResults = createSelector(selectState, (state) => state.searchOrganization.isLoading)
export const getSearchOrganizationResults = createSelector(selectState, (state) => state.searchOrganization.list ?? [])
export const getIsOpenPathBuilderCompletedPopup = createSelector(selectState, (state) => state.isOpenPathBuilderCompletedPopup)
export const getIsStartBuildPath = createSelector(selectState, (state) => state.startBuildPath)
export const getCountries = createSelector(selectState, (state) => state.countries)

import IconWrapper from '@/components/IconWrapper'
import { ICON_SIZES } from '@/constants/iconSizes'
import { Card as MaterialCard, Theme, Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { LazyLoadProfileImage } from '@/components'

interface StyleProps {
	width?: number
	height?: number
	verticalMargin?: number
}

const MOBILE_CARD_HEADER_HEIGHT = 40
const DESKTOP_CARD_HEADER_HEIGHT = 60

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	card: ({ height = 305, width = 221, verticalMargin = 20 }) => ({
		borderRadius: 10,
		margin: `${verticalMargin}px 0px`,
		cursor: 'pointer',
		height,
		width,
		overflow: 'initial',
		position: 'relative',
	}),
	cardHeader: {
		height: DESKTOP_CARD_HEADER_HEIGHT,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		padding: 0,
		// @TODO: probably user item can be generalized and color context can be extracted for it
		background: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
		[theme.breakpoints.down('xs')]: {
			height: MOBILE_CARD_HEADER_HEIGHT,
		},
	},
	checkMark: {
		position: 'absolute',
		right: 0,
		top: -20,
		width: 40,
		height: 40,

		borderRadius: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			width: 30,
			height: 30,
			top: -15,
		},
	},
	cardContent: ({ height = 305 }) => ({
		height: height - DESKTOP_CARD_HEADER_HEIGHT,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '0.9375em',
		[theme.breakpoints.down('xs')]: {
			height: height - MOBILE_CARD_HEADER_HEIGHT,
		},
	}),
	cardContentTitle: {
		margin: '15px 0',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	cardContentSubTitle: {
		fontSize: '0.9375em',
		textAlign: 'center',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		overflowY: 'hidden',
		'-webkit-line-clamp': 3,
		'-webkit-box-orient': 'vertical',
		wordBreak: 'break-word',
	},
	cardContentTitleContainer: {
		marginTop: 7,
		fontSize: 16,
	},
	cardContentInfo: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		fontSize: '0.7375em',
		color: theme.palette.text.secondary,
		textTransform: 'capitalize',
	},
	cardContentFooter: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '15px 0',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	imageButtonsContainer: {
		width: 125,
		height: 125,
		padding: 15,
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			width: '84vw',
		},
	},

	profileImage: {
		width: 76,
		height: 76,
		minWidth: 76,
		minHeight: 76,
		marginTop: -30,
		[theme.breakpoints.down('xs')]: {
			width: theme.spacing(6),
			height: theme.spacing(6),
			minWidth: theme.spacing(6),
			minHeight: theme.spacing(6),
			marginTop: -theme.spacing(3),
		},
	},
}))

export interface IUserItemProps extends StyleProps {
	handleClick: () => void
	externalId?: string
	photoUrl?: string
	fullName: string
	primaryTitle: string
	primaryTitleDepartment: string
	campus?: string
	type: string
	checked?: boolean
}
const UserItem: React.FC<IUserItemProps> = ({
	handleClick,
	externalId,
	primaryTitle,
	primaryTitleDepartment,
	campus,
	fullName,
	photoUrl,
	type,
	checked,
	...styleProps
}) => {
	const classes = useStyles(styleProps)

	return (
		<MaterialCard className={classes.card} onClick={handleClick}>
			{checked && (
				<div className={classes.checkMark}>
					<IconWrapper iconKey="checkCircle" weight="fill" size={ICON_SIZES.lg} />
				</div>
			)}
			<div className={classes.cardHeader}></div>
			<div className={classes.cardContent}>
				<LazyLoadProfileImage borderRadius={5} className={classes.profileImage} externalUserId={externalId || ''} photoUrl={photoUrl} />

				<div className={classes.cardContentTitleContainer}>
					<Typography className={classes.cardContentTitle}>{fullName}</Typography>
					<Typography className={classes.cardContentSubTitle}>{primaryTitle}</Typography>
					<Typography className={classes.cardContentSubTitle}>{primaryTitleDepartment}</Typography>
				</div>
				<div className={classes.cardContentInfo}>{`${type} ${campus ? ` | ${campus}` : ''}`}</div>
			</div>
		</MaterialCard>
	)
}

export { UserItem }

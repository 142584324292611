import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	card: {
		minWidth: 370,
		maxWidth: 550,
		padding: 20,
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '2vh 0',
	},
	popupModalHeader: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		color: theme.palette.text.secondary,
	},
	popupModalRoot: {
		width: '80%',
		margin: '2% 10%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}))

interface PopupModalCardProps {
	title?: string
	handleClose?: () => void
	children: React.ReactNode
	className?: string
	contentClassName?: string
}

const PopupModalCard = ({ children, handleClose, className = '', contentClassName = '' }: PopupModalCardProps) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.card, className)}>
			{handleClose && (
				<div className={classes.popupModalHeader}>
					<CloseIcon onClick={handleClose} />
				</div>
			)}
			<div className={classNames(classes.popupModalRoot, contentClassName)}>{children}</div>
		</div>
	)
}

export default PopupModalCard

import { OrganizationData, UserData } from '@/interfaces/common'
import { Id, UserId } from '@/lib/types'

import { TMentionData } from '@/components/Editor/plugins/MentionPlugin/MentionTypeaheadOption'
import { TCampus } from '@/features/campus/types/TCampus'
import { DtoEntityPermission } from '@/features/permissions/types/DtoEntityPermission'
import { UiState } from '@/features/posts/types/UiStates'
import WithSharedEntity from '@/features/shareEntity/types/WithSharedEntity'
import { TOption } from '../types'

export type FilterHash = string
export interface FilterHashData {
	filterHash: FilterHash
	posts: any[]
	canLoadMore: boolean
	page: number
	requestedPage: number
}

export interface FeedPost extends WithSharedEntity {
	uiState?: UiState
	id: string
	ownerUser?: UserData
	ownerUserId: UserId
	ownerOrganization?: OrganizationData
	ownerOrganizationId: Id
	tag: number
	usersAudience: number
	usersAudienceScope: number
	campusId: number
	message?: string
	photoUrl?: string
	activeSince?: string
	linkPreviewUrl?: string
	numberOfLikes: number
	numberOfComments: number
	numberOfReposts: number
	numberOfViews: number
	viewed: boolean
	liked: boolean
	createdAt: string
	publishedAt: string
	permissions?: DtoEntityPermission
}

export interface CreateFeedsModalContent {
	ownerOrganizationId?: string
	tag?: number | null
	usersAudience?: number | null
	audienceScope?: number
	campus?: number | null
	message?: string
	photoUrl?: string
	activeSince?: string
	sharedEntity?: WithSharedEntity
	linkPreviewUrl?: string
	postToEdit?: FeedPost
	mentions?: TMentionData[]
}

export interface CreateFeedsModal {
	isOpen: boolean
	isEdit?: boolean
	isSchedule?: boolean
	content: CreateFeedsModalContent
}

export enum UserAudience {
	COMMUNITY = 1,
	NETWORK = 2,
	FOLLOWERS = 3,
}

export enum UserAudienceScopes {
	ALL_USERS = 1,
	ONLY_STUDENTS = 2,
	ONLY_FACULTY = 3,
}

export interface FeedsFilters {
	campusId?: TCampus['id'] | null
	tags?: number[]
	usersAudience?: UserAudience
	audienceScope?: UserAudienceScopes
	includeAllCampuses?: boolean
}

export interface AudienceOption {
	description: string
	label: string
	value: number
}

export type FeedTagOption = Omit<TOption, 'description'>

export interface DtoFeedsFilterOptions {
	tags: FeedTagOption[]
	usersAudience: Array<AudienceOption & { options: Record<number, string> }>
	organizationAudience: Array<AudienceOption & { options: Record<number, string> }>
	audienceScope: TOption[]
}

export interface FeedsFilterOptions {
	tags: FeedTagOption[]
	usersAudience: Array<AudienceOption & { options: AudienceOption[] }>
	organizationAudience: Array<AudienceOption & { options: AudienceOption[] }>
	audienceScope: TOption[]
}

export interface FeedsState {
	isLoading: boolean
	feeds: any[]
	createFeedsModal: CreateFeedsModal
	filters: FeedsFilters
	filterOptions: FeedsFilterOptions
	allComments: {
		[k: string]: {
			endHasBeenReached?: boolean
			data: any[]
		}
	}
	selectedPost?: FeedPost
	isOpenDeleteConfirmationModal: boolean
	scrollToId?: string
	isLoadingSinglePost: boolean
	isScheduledPostsModalOpened: boolean
}

import * as actions from '@/store/feeds/actions'
import { AudienceOption, FeedsState, UserAudience } from '@/store/feeds/types'
import { createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

const defaultCreateFeedsModal = {
	isOpen: false,
	content: {
		usersAudience: 1,
	},
}

const initialState: FeedsState = {
	feeds: [],
	isLoading: false,
	createFeedsModal: cloneDeep(defaultCreateFeedsModal),
	filterOptions: {
		tags: [],
		audienceScope: [],
		usersAudience: [],
		organizationAudience: [],
	},
	filters: {
		tags: [],
		campusId: null,
		includeAllCampuses: true,
		usersAudience: UserAudience.COMMUNITY,
	},
	isScheduledPostsModalOpened: false,
	allComments: {},
	isOpenDeleteConfirmationModal: false,
	isLoadingSinglePost: false,
}

const feedsReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.setIsLoading, (state, action) => ({
			...state,
			isLoading: action.payload,
		}))
		.addCase(actions.setPost, (state, { payload }) => ({
			...state,
			feeds: [payload]
				.concat(state.feeds)
				.map((post) => (payload.sharedPostId === post.id ? { ...post, numberOfReposts: `${+post.numberOfReposts + 1}` } : post)),
		}))

		.addCase(actions.clearFeedsList, (state) => ({
			...state,
			filters: {
				...state.filters,
				filterIsReady: false,
				endHasBeenReached: false,
			},
			feeds: [],
		}))
		.addCase(actions.setConstants, (state, action) => {
			const { organizationAudience, usersAudience, audienceScope, tags } = action.payload

			const audienceMapper = (audience: AudienceOption & { options: Record<number, string> }) => {
				return {
					...audience,
					options: audienceScope.map((option) => ({
						...option,
						description: audience.options[option.value],
					})),
				}
			}
			state.filterOptions = {
				audienceScope,
				tags,
				organizationAudience: organizationAudience.map(audienceMapper),
				usersAudience: usersAudience.map(audienceMapper),
			}
		})
		.addCase(actions.setSelectedPost, (state, { payload }) => ({
			...state,
			selectedPost: cloneDeep(payload),
		}))
		.addCase(actions.setDeleteConfirmationModal, (state, { payload }) => ({
			...state,
			isOpenDeleteConfirmationModal: payload,
		}))
		.addCase(actions.setIsScheduledPostModalOpened, (state, { payload }) => {
			state.isScheduledPostsModalOpened = payload
		})
})

export { feedsReducer }

import { PATHS } from '@/constants'
import MyNetwork from '@/pages/NetworkPage/MyNetwork'
import Discover from '@/pages/NetworkPage/Discover'
import UserPage from '@/pages/NetworkPage/UserPage'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import AppRoute from '@/interfaces/route'
import { createPageTitle } from '@/utils/page'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const MY_NETWORK = createPageTitle(PAGE_NAME.MY_NETWORK)
const DISCOVER_NETWORK = createPageTitle(PAGE_NAME.DISCOVER_NETWORK)

const routes: AppRoute[] = [
	{
		component: UserPage,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.NETWORK_USER(),
		// We have tabs inside user profile and we can pass via url tab=activity or goal
		// and user will land according tab right away
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: MyNetwork,
		title: MY_NETWORK,
		name: MY_NETWORK,
		path: PATHS.APP.NETWORK_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: Discover,
		title: DISCOVER_NETWORK,
		name: DISCOVER_NETWORK,
		path: PATHS.APP.NETWORK_SEARCH,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'

import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'
import IndividualEvent from '@/anthology/events/pages/IndividualEvent'
import YourEvents from '@/anthology/events/pages/YourEvents'
import DiscoverEvents from '@/anthology/events/pages/DiscoverEvents'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_EVENTS = createPageTitle(PAGE_NAME.DISCOVER_EVENTS)
const MY_EVENTS = createPageTitle(PAGE_NAME.MY_EVENTS)

const routes: AppRoute[] = [
	{
		component: IndividualEvent,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.EVENTS_SINGLE(),
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourEvents,
		title: MY_EVENTS,
		name: MY_EVENTS,
		path: PATHS.APP.EVENTS_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverEvents,
		title: DISCOVER_EVENTS,
		name: DISCOVER_EVENTS,
		path: PATHS.APP.EVENTS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

import { useMemo } from 'react'
import { selectOrganization, useGetOrganizationInfoQuery } from '../api'
import { useCurrentUser } from '@/hooks/userHooks'

const useOrganizationByIdQuery = (id: string, skip?: boolean) => {
	const currentUser = useCurrentUser()
	const selectNormalizedOrganization = useMemo(selectOrganization, [])

	const organizationQuery = useGetOrganizationInfoQuery(
		{ id, userId: currentUser.id },
		{
			skip: !!skip,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data ? selectNormalizedOrganization(result.data) : undefined,
				}
			},
		},
	)

	return organizationQuery
}

export default useOrganizationByIdQuery

import { useSchoolConfig } from '@/hooks/useSchoolConfig'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@/store'

import { CampusPicker } from '@/components/filters/CampusPicker'
import { selectFeedCampusId, setFilterCampus } from '../../feed/slice'
import { useFilterButtonStyles } from './styles/useFilterButtonStyles'

const FeedCampus = () => {
	const dispatch = useAppDispatch()
	const selectedCampus = useAppSelector(selectFeedCampusId)

	const {
		school: { shortName },
	} = useSchoolConfig()

	const classes = useFilterButtonStyles()
	const handleChangeCampus = useCallback(
		(id: number | null) => {
			dispatch(setFilterCampus(id))
		},
		[dispatch],
	)
	return (
		<CampusPicker
			onChange={handleChangeCampus}
			selected={selectedCampus}
			buttonClassName={classes.button}
			modalSubTitle={`See posts created for a specific ${shortName} campus`}
		/>
	)
}

export default FeedCampus

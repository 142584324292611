import BorderLinearProgress from '@/components/progress/BorderLinearProgress'

import { useSignupStepsContext } from './SignupStepsContext'
import { useIsMobileScreen } from '@/hooks/useIsMobileScreen'
import { useTheme } from '@material-ui/core'

export const ProgressBar = () => {
	const {
		progress: { currentStep, total },
	} = useSignupStepsContext()
	const appTheme = useTheme()
	const isMobile = useIsMobileScreen()
	const progressInPercents = Math.ceil(((currentStep + 1) / (total || 1)) * 100)
	return (
		<BorderLinearProgress
			primaryColor={isMobile ? appTheme.palette.divider : undefined}
			height={isMobile ? 6 : 20}
			variant="determinate"
			value={progressInPercents}
		/>
	)
}

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { PATHS } from '@/constants'
import { UserData } from '@/interfaces/common'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: '15px 0',
	},
	descriptionText: {
		color: theme.palette.text.secondary,
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 15,
	},
	clickable: {
		cursor: 'pointer',
	},
	profileImage: {
		width: 47,
		height: 47,
	},
}))

interface NetworkUserRowProps {
	className?: string
	user: UserData
	openProfileOnClick?: boolean
}

const NetworkUserRow = ({ className = '', user, openProfileOnClick = true }: NetworkUserRowProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const { externalId, fullName, type, campus, id, position, photoUrl } = user

	const subTitle = position ? position : type && campus ? `${type} | ${campus}` : ''

	const handleClickViewProfile = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation()
		e.preventDefault()
		push(PATHS.APP.NETWORK_USER(`${id}`))
	}

	return (
		<div
			className={classNames(classes.root, openProfileOnClick ? classes.clickable : '', className)}
			onClick={openProfileOnClick ? handleClickViewProfile : undefined}
		>
			<LazyLoadProfileImage className={classes.profileImage} borderRadius={10} externalUserId={externalId || ''} photoUrl={photoUrl} />
			<div className={classes.infoContainer}>
				<Typography>{fullName}</Typography>
				<Typography className={classes.descriptionText}>{subTitle}</Typography>
			</div>
		</div>
	)
}

export default NetworkUserRow

import { SESSION_TIME } from '@/constants/configuration'
import { baseApi } from './baseApi'

export const analyticsApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getNotificationMeta: builder.query({
			query: () => ({
				url: `/notifications/grid/meta`,
				method: 'GET',
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetNotificationMetaQuery } = analyticsApi

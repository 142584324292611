import { SvgIcon } from '@material-ui/core'

const CampusIcon = (props: any) => {
	return (
		<SvgIcon
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width={35.758}
			height={29.321}
			viewBox="0 0 35.758 29.321"
			{...props}
		>
			<defs>
				<clipPath id="prefix__dwewqeq98">
					<use xlinkHref="#prefix__wds33ewqeq98" />
				</clipPath>
				<clipPath id="prefix__wds3q98">
					<use xlinkHref="#prefix__w3q98" />
				</clipPath>
				<clipPath id="prefix__w3q98dw3">
					<use xlinkHref="#prefix__w38dw3" />
				</clipPath>
			</defs>
			<g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 4985">
				<g data-name="\u041C\u043D\u043E\u0433\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 3" fill="#fff">
					<path d="M31.875 9.114H3.883c-.16 0-.22-.102-.243-.187-.022-.086-.018-.204.122-.282L17.758.923a.25.25 0 01.242 0l13.996 7.722c.14.078.143.196.121.282-.022.085-.082.187-.242.187z" />
					<path
						d="M17.879 1.713L5.824 8.364h24.11l-12.055-6.65m0-1.572a1 1 0 01.483.125l13.996 7.722c.905.499.55 1.875-.483 1.875H3.883c-1.034 0-1.388-1.376-.484-1.875L17.396.267a1 1 0 01.483-.125z"
						fill="currentColor"
					/>
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1693"
					fill="#fff"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path stroke="none" d="M6.166 11.097h3.699V24.66H6.166z" />
					<path fill="none" d="M6.916 11.847h2.199V23.91H6.916z" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1694"
					fill="#fff"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path stroke="none" d="M12.331 11.097h3.699V24.66h-3.699z" />
					<path fill="none" d="M13.081 11.847h2.199V23.91h-2.199z" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1695"
					fill="#fff"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path stroke="none" d="M19.728 11.097h3.699V24.66h-3.699z" />
					<path fill="none" d="M20.478 11.847h2.199V23.91h-2.199z" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1696"
					fill="#fff"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path stroke="none" d="M27.126 11.097h3.699V24.66h-3.699z" />
					<path fill="none" d="M27.876 11.847h2.199V23.91h-2.199z" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1697"
					transform="translate(3.575 10.727)"
					fill="none"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path id="prefix__wds33ewqeq98" d="M0 0h29.321a1.073 1.073 0 01-1.073 1.073H1.073A1.073 1.073 0 010 0z" stroke="none" />
					<path d="M0 .323h29.321M28.571 0v1.073m.75-.323H0m.75.323V0" clipPath="url(#prefix__dwewqeq98)" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1698"
					transform="rotate(180 16.449 13.409)"
					fill="none"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path id="prefix__w3q98" d="M0 0h29.321a1.073 1.073 0 01-1.073 1.073H1.073A1.073 1.073 0 010 0z" stroke="none" />
					<path d="M0 .323h29.321M28.571 0v1.073m.75-.323H0m.75.323V0" clipPath="url(#prefix__wds3q98)" />
				</g>
				<g
					data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 1699"
					transform="rotate(180 16.985 14.66)"
					fill="none"
					stroke="currentColor"
					strokeWidth={1.5}
				>
					<path id="prefix__w38dw3" d="M0 0h31.467a1.073 1.073 0 01-1.073 1.073H1.073A1.073 1.073 0 010 0z" stroke="none" />
					<path d="M0 .323h31.467M30.717 0v1.073m.75-.323H0m.75.323V0" clipPath="url(#prefix__w3q98dw3)" />
				</g>
			</g>
		</SvgIcon>
	)
}

export default CampusIcon

import Pubsub from 'pubsub-js'
import { useEffect } from 'react'

import { getPubSubPublishers } from './pubsubPublishers'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PubsubMessage, PubsubMessageHandlerMap, PubsubMessageTopic } from './types'

/**
 * Checks whether the provided handler can handle the provided message.
 * @returns true if the message has not been handled by the handler before.
 */
const checkCanHandleMessage = ({ handler, message }) => {
	handler.handledIdMap ||= {}
	if (handler.handledIdMap[message.id]) {
		// Prevent duplicate handling of the same message
		return false
	}

	handler.handledIdMap[message.id] = true
	return true
}

/**
 * A hook that provides the publishers for defined {@link PubsubMessageTopic} messages.
 */
export const usePubsubPublishers = (schoolId: number) => {
	return getPubSubPublishers(schoolId)
}

/**
 * Listen to messages being published with handlers.
 *
 * The provided handler for each{@link PubsubMessageTopic} will be invoked
 * whenever a new {@link PubsubMessage} of that type is published.
 */
export const usePubsubHub = (messageHandlerMap: PubsubMessageHandlerMap, dependencies: any[] = []) => {
	const messageTopics = Object.keys(messageHandlerMap)

	useEffect(() => {
		// Subscribe to all requested message types
		const subscriptionTokens = messageTopics.map((messageTopic) => {
			const handler = (_, message: PubsubMessage) => {
				if (!checkCanHandleMessage({ handler, message })) {
					return
				}

				messageHandlerMap?.[messageTopic]?.({ ...message })
			}

			return Pubsub.subscribe(messageTopic, handler)
		})

		return () => {
			// Clean up: unsubscribe from all
			subscriptionTokens.forEach((subscriberToken) => Pubsub.unsubscribe(subscriberToken))
		}
		// @TODO: #esLint needs to be addressed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messageTopics.join(''), ...dependencies])
}

import http from '@/api/http/requestHttp'
import { DtoGroupImage } from '@/store/groups/types'

interface GetGroupGalleryParams {
	id: string
	params: {
		page?: number
		limit?: number
	}
	schoolId: number
}

const getGroupGallery = async ({ id, params = { page: 1, limit: 20 }, schoolId }: GetGroupGalleryParams) => {
	const url = `${schoolId}/groups/${id}/gallery`

	return http.get<DtoGroupImage[]>(url, { params })
}

export default getGroupGallery

import { PATHS } from '@/constants'
import { UNIQUE_PAGE_TITLE_REQUIRED } from '@/constants/pages'
import AppRoute from '@/interfaces/route'
import DiscoverResearch from '@/pages/ResearchPage/DiscoverResearch'
import IndividualResearch from '@/pages/ResearchPage/IndividualResearch'
import YourResearch from '@/pages/ResearchPage/YourResearch'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_RESEARCH = createPageTitle(PAGE_NAME.DISCOVER_RESEARCH)
const MY_RESEARCH = createPageTitle(PAGE_NAME.MY_RESEARCH)

const routes: AppRoute[] = [
	{
		component: IndividualResearch,
		name: UNIQUE_PAGE_TITLE_REQUIRED,
		path: PATHS.APP.RESEARCH_SINGLE(),
		exact: true,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: YourResearch,
		title: MY_RESEARCH,
		name: MY_RESEARCH,
		path: PATHS.APP.RESEARCH_MY,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverResearch,
		title: DISCOVER_RESEARCH,
		name: DISCOVER_RESEARCH,
		path: PATHS.APP.RESEARCH,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

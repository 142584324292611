import getUserConnections from './getUserConnections'
import searchNetwork from './searchNetwork'
import patchUserConnection from './patchUserConnection'
import postUserConnection from './postUserConnection'
import getUserInfo from './getUserInfo'
import postUserResponse from './postUserResponse'
import deleteUserConnection from './deleteUserConnection'
import getBlockedUsersList from './getBlockedUsersList'
import patchUnblockUser from './patchUnblockUser'

const network = {
	getUserConnections,
	searchNetwork,
	patchUserConnection,
	postUserConnection,
	getUserInfo,
	postUserResponse,
	deleteUserConnection,
	getBlockedUsersList,
	patchUnblockUser,
}

export default network

import { makeStyles } from '@material-ui/core'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'

const useStyles = makeStyles((theme) => ({
	button: {
		height: '5.5vh',
		width: '13.5vw',
		minWidth: 180,
		minHeight: 50,
		fontSize: '14pt',
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			minWidth: 120,
			minHeight: 30,
			marginRight: 0,
			width: '100%',
			marginBottom: '1rem',
		},
	},
	buttonContainer: {
		marginTop: theme.spacing(8.5),
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
	},
}))

type TLoginButtonProps = {
	onClick?: () => void
	color?: ButtonColors
	type?: 'button' | 'reset' | 'submit'
	disabled?: boolean
}

const LoginButton = ({ onClick, color = ButtonColors.PRIMARY, type, disabled }: TLoginButtonProps) => {
	const classes = useStyles()
	return (
		<div className={classes.buttonContainer}>
			<ColorButton disabled={disabled} type={type} color={color} className={classes.button} onClick={onClick}>
				Log In
			</ColorButton>
		</div>
	)
}

export default LoginButton

import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

import { ColorButton, CommonModalCard, Modal } from '@/components'
import { useAppDispatch } from '@/store'
import { STYLES } from '../../constants'
import { UserReportedInterest } from '../../interfaces/user'
import { updateUserSelfReportedItemsRequest } from '../../store/auth'
import CreateItemInput from '../PathBuilder/CreateItemInput'
import SelfCreatedItemsList from '../PathBuilder/SelfCreatedItemsList'

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		minWidth: '850px',
		height: '57vh',
		width: '50vw',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 30,
		[theme.breakpoints.down('md')]: {
			minWidth: '300px',
		},
	},
	cardContentContainer: {
		width: '70%',
		height: '90%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	content: {
		width: '100%',
		height: '85%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	updateProfileButton: {
		padding: '1vh 1vw',
		minWidth: 200,
		fontWeight: 600,
	},
	input: {
		width: '100%',
	},
	itemsContainerRoot: {
		marginTop: 20,
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '80%',
	},
	itemsContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		maxHeight: '100%',
		minHeight: 60,
		overflowY: 'auto',
	},
}))

interface EditUserInterestsModalProps {
	isOpen: boolean
	items?: UserReportedInterest[]
	onClose: () => void
}

const EditUserInterestsModal = ({ isOpen, items = [], onClose }: EditUserInterestsModalProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const [itemsState, setItemsState] = useState(items.map(({ interest }) => interest))
	const [touched, setTouched] = useState(false)

	const hasStoredItems = !!itemsState.length

	const handleAddItem = (value: string) => {
		setItemsState((prev) => [...prev, value])
		setTouched(true)
	}

	const handleChangeItems = (value: string[]) => {
		setItemsState(value)
		setTouched(true)
	}

	const handleSubmitChanges = () => {
		dispatch(updateUserSelfReportedItemsRequest({ interests: itemsState }))
		setTouched(false)
		onClose()
	}

	useEffect(() => {
		if (isOpen) {
			setItemsState(items.map(({ interest }) => interest))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<CommonModalCard title="Edit Profile | Interests" onClose={onClose}>
				<div className={classes.cardRoot}>
					<div className={classes.cardContentContainer}>
						<div className={classes.content}>
							<CreateItemInput
								className={classes.input}
								onCreate={handleAddItem}
								placeholder={!hasStoredItems ? 'Add an interest' : 'Add another interest'}
							/>
							<SelfCreatedItemsList
								classnames={{
									root: classes.itemsContainerRoot,
									itemsList: classes.itemsContainer,
								}}
								onChange={handleChangeItems}
								items={itemsState}
							/>
						</div>
						<ColorButton className={classes.updateProfileButton} disabled={!touched} onClick={handleSubmitChanges}>
							Save
						</ColorButton>
					</div>
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export default EditUserInterestsModal

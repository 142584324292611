import DataTable from '@/features/adminConsole/components/dataTable/DataTable'
import { NavTabs } from '../../../components/NavTabs'
import { useUsersControllerGetGridDataQuery, useUsersControllerGetGridMetaQuery } from '../api/usersGeneratedApi'
import {
	ProfileItemDto,
	useProfileItemsControllerGetGridDataQuery,
	useProfileItemsControllerGetGridMetaQuery,
} from '../api/usersProfileItemsApi'
import { AnalyticsFrameWithRoleCheck } from '../components/AnalyticsFrame'
import { DashboardType } from '../types/dashboardTypes'
import { ADMIN_ROOT_PATH } from '../routes/config'

const profileItemsTabs: Array<{ label: string; type: ProfileItemDto['type'] }> = [
	{
		label: 'Reported  Research',
		type: 'research',
	},
	{
		label: 'Reported Internships',
		type: 'internship',
	},
	{
		label: 'Reported Involvements',
		type: 'involvement',
	},
	{
		label: 'Reported Study Abroad',
		type: 'studyAbroad',
	},
	// Removed for now
	// {
	// 	label: 'Reported Interests',
	// 	type: 'interest',
	// },
]

const TABS = [
	{
		label: 'Analytics',
		route: `${ADMIN_ROOT_PATH}/users`,
		exact: true,
		component: <AnalyticsFrameWithRoleCheck dashboardType={DashboardType.USERS} />,
	},
	{
		label: 'Details',
		route: `${ADMIN_ROOT_PATH}/users/table`,
		exact: false,
		component: <DataTable metaQuery={useUsersControllerGetGridMetaQuery} listQuery={useUsersControllerGetGridDataQuery} />,
	},
]

TABS.push(
	...profileItemsTabs.map((item) => ({
		label: item.label,
		route: `${ADMIN_ROOT_PATH}/users/${item.type}`,
		exact: true,
		component: (
			<DataTable
				metaQueryParams={{ type: item.type }}
				queryParams={{ type: item.type }}
				metaQuery={useProfileItemsControllerGetGridMetaQuery}
				listQuery={useProfileItemsControllerGetGridDataQuery}
			/>
		),
	})),
)

const UsersListPage = () => {
	return (
		<div style={{ flex: 1, flexDirection: 'column' }}>
			<NavTabs tabs={TABS} withHistory />
		</div>
	)
}

export default UsersListPage

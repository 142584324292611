import { useCurrentUser } from '@/hooks/userHooks'
import { TUserEventTypes } from '../types/UserEventTypes'
import settings from '@/constants/http'
import { useEntityListingByType } from '@/anthology/hooks/useEntityListingByType'
import { useGetUserEventsByTypeQuery } from '../api'

const useCurrentUserEvents = (defaultType: TUserEventTypes = 'upcoming', defaultTake = settings.SEARCH_FEEDS_LIST_PER_PAGE) => {
	const currentUser = useCurrentUser()
	const { filters, loadMore, onChangeType } = useEntityListingByType<TUserEventTypes>(defaultType)
	const { skip, take, type } = filters
	const query = useGetUserEventsByTypeQuery(
		{ skip, take, type, userId: currentUser.id },
		{
			refetchOnMountOrArgChange: true,
		},
	)

	const canLoadMore = query.data && query.data.items.length < query.data.totalItems
	const showPreloader = query.isLoading || (query.isFetching && skip === 0)

	return {
		...query,
		loadMore,
		canLoadMore,
		showPreloader,
		onChangeType,
		filters,
	}
}

export default useCurrentUserEvents

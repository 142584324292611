import { makeStyles } from '@material-ui/core/styles'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { ClockOutlinedIcon, DotButton, IconWrapper, LocationOutlinedIcon } from '@/components'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import ItemCardContainer from '@/components/ExperienceCards/ItemCardContainer'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import { ICON_SIZES } from '@/constants/iconSizes'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { ReportActions } from '@/interfaces/common'
import useGlobalStyles from '@/styles/globalStyles'
import { formatMultiOrSingleDay } from '@/utils/dateTime2'
import { createGoogleLink, createOutlookLink } from '@/utils/services'
import { FEATURE } from '@navengage/sen-shared-assets'
import { useReportEventMutation } from '../api'
import DtoEvent from '../types/dto/DtoEvent'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	contentInfo: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '4px 0',
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	},
	contentInfoIcon: {
		color: theme.palette.primary.main,
		fontSize: '1.25em',
		marginRight: 10,
	},
	cardTypeIcon: {
		width: 25,
		height: 25,
	},
	responseBtn: {
		height: 50,
		width: 'calc(100% - 36% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	signUpBtn: {
		height: 50,
		width: 'calc(100% - 47% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	responseBtnFullWidth: {
		height: 50,
		width: 'calc(100% - 60px)',
		...theme.typography.body1,
		fontWeight: 600,
	},
	menuActiveButton: {
		color: theme.palette.primary.main,
	},
	adminIcon: {
		width: 30,
	},
	title: {
		minHeight: 50,
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	titlePostCard: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 1,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	contentContainer: {
		margin: 0,
	},
}))

interface EventCardProps extends DtoEvent {
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
}

const EventCard = ({
	id,
	variant = 'primary',
	showActionButtons = true,
	address,
	location,
	imageUrl,
	name,
	startsOn,
	endsOn,
	description,
	showBadge = false,
	organizationIds,
}: EventCardProps) => {
	const classes = useStyles()
	const globalClasses = useGlobalStyles()
	const { push } = useHistory()

	const date = formatMultiOrSingleDay(startsOn, endsOn)
	const [reportEventMutation] = useReportEventMutation()

	const handleReportEvent = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			if (status === ReportActions.inaccurate || status === ReportActions.inappropriate) {
				reportEventMutation({ id, response: status, reportMessage })
			}
		},
		[reportEventMutation],
	)

	const calendarButtons = useMemo(
		() => [
			{
				label: 'Add to Outlook Calendar',
				onClick: () => {
					window.open(createOutlookLink(name, startsOn, endsOn, description))
				},
				icon: <EventAvailableOutlinedIcon />,
			},
			{
				label: 'Add to Google Calendar',
				onClick: () => {
					window.open(createGoogleLink(name, startsOn, endsOn, description))
				},
				icon: <EventAvailableOutlinedIcon />,
			},
		],
		[name, startsOn, endsOn, description],
	)

	const sharingUrl = PATHS.APP.EVENTS_SINGLE(id)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.EVENTS_SINGLE(id))
	}, [id, push])

	const handleRenderCardHeader = useCallback(
		() => (
			<>
				<div className={globalClasses.alignCenter}>
					{showBadge ? (
						<CardIconContainer>
							<IconWrapper
								iconKey={FEATURE.events}
								aria-label="Event"
								weight="fill"
								className={classes.cardTypeIcon}
								size={ICON_SIZES.md}
							/>
						</CardIconContainer>
					) : null}
				</div>
				{showActionButtons && (
					<DotButton itemId={id} onSubmitReport={handleReportEvent} reportSubject={ReportSubject.Event} renderButtons={calendarButtons} />
				)}
			</>
		),
		[globalClasses.alignCenter, showBadge, classes.cardTypeIcon, showActionButtons, id, handleReportEvent, calendarButtons],
	)

	const handleRenderCardContent = useCallback(
		() => (
			<>
				{startsOn && (
					<div className={classes.contentInfo}>
						<ClockOutlinedIcon className={classes.contentInfoIcon} /> <p>{date}</p>
					</div>
				)}
				{(!!address?.name || !!location) && (
					<div className={classes.contentInfo}>
						<LocationOutlinedIcon className={classes.contentInfoIcon} /> <p>{address?.name ? address?.name : location}</p>
					</div>
				)}
				{/* TODO - {<EventOrganizations orgIds={organizationIds} />} */}
			</>
		),
		[address, classes.contentInfo, classes.contentInfoIcon, date, startsOn, location],
	)
	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.event } }), [id])

	const cardClasses = useMemo(() => {
		return {
			titleContainer: classes.title,
			contentContainer: classes.contentContainer,
		}
	}, [classes.contentContainer, classes.title])

	const feedCardClasses = useMemo(() => {
		return {
			titleContainer: classes.titlePostCard,
			contentContainer: classes.contentContainer,
		}
	}, [classes.contentContainer, classes.titlePostCard])

	return (
		<div className={classes.root}>
			{variant === 'primary' ? (
				<ItemCardContainer
					showActionButtons={showActionButtons}
					title={name}
					photoUrl={imageUrl}
					containerClasses={cardClasses}
					handleOnClickCard={handleClickCard}
					sharedData={sharedData}
					renderHeader={handleRenderCardHeader}
					renderContent={handleRenderCardContent}
					sharingUrl={sharingUrl}
				/>
			) : (
				<FeedsCardContainer
					showActionButtons={showActionButtons}
					title={name}
					photoUrl={imageUrl}
					containerClasses={feedCardClasses}
					handleOnClickCard={handleClickCard}
					sharedData={sharedData}
					renderHeader={handleRenderCardHeader}
					renderContent={handleRenderCardContent}
					sharingUrl={sharingUrl}
				/>
			)}
		</div>
	)
}

export default EventCard

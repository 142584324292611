import { mutate } from 'swr'

// Providing null as the key to useSWR()
// tells it not to fetch
export const SWR_NO_FETCH_KEY = null

export const mutateSwr = mutate
export const mutateSwrWithoutRevalidating = (key, value) => {
	mutate(key, value, { revalidate: false })
}

import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import Stepper from '@/components/PathBuilder/Stepper'
import { useAppDispatch } from '@/store'
import { useHistory } from 'react-router'

import ColorButton from '@/components/Buttons/ColorButton'
import IconButton from '@/components/Buttons/IconButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { ButtonColors } from '@/components/Buttons/types'
import StepperDot from '@/components/StepperDot'
import NextButton from '@/components/icons/NextButton'
import { PATHS, STYLES } from '@/constants'
import useScrollToTop from '@/hooks/useScrollToTop'
import EducationAbroadQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/EducationAbroad/Question1'
import EducationAbroadQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/EducationAbroad/Question2'
import EducationAbroadQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/EducationAbroad/Question3'
import EducationAbroadQuestion4 from '@/pages/PathBuilderPage/PathBuilderSteps/EducationAbroad/Question4'
import EducationAbroadQuestion5 from '@/pages/PathBuilderPage/PathBuilderSteps/EducationAbroad/Question5'
import EventsQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/Events/Question1'
import OrganizationsQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/Organizations/Question1'
import OrganizationsQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/Organizations/Question2'
import OrganizationsQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/Organizations/Question3'
import OrganizationsQuestion4 from '@/pages/PathBuilderPage/PathBuilderSteps/Organizations/Question4'
import OrganizationsQuestion5 from '@/pages/PathBuilderPage/PathBuilderSteps/Organizations/Question5'
import OutOfClassroomQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/OutOfClassroom/Question1'
import ProfessionalQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Question1'
import ProfessionalQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Question2'
import ProfessionalQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Question3'
import ProfessionalQuestion4 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Question4'
import ProfessionalQuestion5 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Question5'
import ProfessionalSecondaryQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Secondary/Question2'
import ProfessionalSecondaryQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/Professional/Secondary/Question3'
import ResearchQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/Research/Question1'
import ResearchQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/Research/Question2'
import ResearchQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/Research/Question3'
import VolunteerQuestion1 from '@/pages/PathBuilderPage/PathBuilderSteps/Volunteer/Question1'
import VolunteerQuestion2 from '@/pages/PathBuilderPage/PathBuilderSteps/Volunteer/Question2'
import VolunteerQuestion3 from '@/pages/PathBuilderPage/PathBuilderSteps/Volunteer/Question3'
import VolunteerQuestion4 from '@/pages/PathBuilderPage/PathBuilderSteps/Volunteer/Question4'
import VolunteerQuestion5 from '@/pages/PathBuilderPage/PathBuilderSteps/Volunteer/Question5'
import { showExitConfirmationModal } from '@/store/app'
import { getUserGoals } from '@/store/auth'
import {
	createForm,
	getBuilderData,
	getCurrentStep,
	getForm,
	getIsEditForm,
	saveGoalsRequest,
	setIsOpenPathBuilderCompletedPopup,
	setPathBuilder,
} from '@/store/pathBuilder'
import { PathBuilderForm } from '@/store/pathBuilder/types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { TIconName } from '@/components/IconWrapper'
import { isEngage } from '@/constants/configuration'
import { FEATURE } from '@navengage/sen-shared-assets'

export interface PathBuilderQuestionProps {
	onValidation: (isValid: boolean, isTouched: boolean, stepData: any) => void
	onClose: () => void
	onBack: () => void
	showBackButton?: boolean
	data?: any
	onSkipStep: (numberOfSteps: number) => () => void
	pathBuilderForm: PathBuilderForm
	dataKey: string
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '7vh 0',
	},
	container: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	left: {
		width: '23%',
		[theme.breakpoints.down('md')]: {
			width: '90%',
			padding: '0 5%',
		},
	},
	right: {
		width: '77%',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	icon: {
		width: 50,
	},
	footer: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	dots: {
		margin: '3vh 0',
	},
	whiteButton: {
		borderColor: theme.palette.border.bold,
		color: theme.palette.text.primary,
		height: 60,
		width: 255,
	},
	saveButton: {
		height: 60,
		width: 255,
		marginTop: '5vh',
	},
	nextIcon: {
		marginLeft: 10,
	},
	backButton: {
		color: theme.palette.text.primary,
		position: 'absolute',
		top: `calc(${STYLES.MAIN_HEADER_HEIGHT}vh + 25px)`,
		left: 30,
	},
}))

type TPathBuilderStep = {
	label: string
	icon: TIconName
	key: string
	branches: Array<{
		questions: Array<{
			questionKey: string
			component: React.ComponentType<PathBuilderQuestionProps>
		}>
		condition?: {
			questionKey: string
			answer: boolean
		}
	}>
}

export const pathBuilder: Array<TPathBuilderStep> = [
	{
		label: 'Events',
		icon: FEATURE.events,
		key: 'events',
		branches: [
			{
				questions: [
					{
						questionKey: 'typesOfEvents',
						component: EventsQuestion1,
					},
				],
			},
		],
	},
	{
		label: 'Organizations',
		icon: FEATURE.organizations,
		key: 'organizations',
		branches: [
			{
				questions: [
					{
						questionKey: 'wouldLikeToJoinANewStudentOrganization',
						component: OrganizationsQuestion1,
					},
					{
						questionKey: 'numberOfOrganizationsToJoin',
						component: OrganizationsQuestion2,
					},
					{
						questionKey: 'sizeOfOrganizationWouldLikeToJoin',
						component: OrganizationsQuestion3,
					},
					{
						questionKey: 'descriptionOfOrganizationWouldLikeToJoin',
						component: OrganizationsQuestion4,
					},
					{
						questionKey: 'organizationsWouldLikeToJoin',
						component: OrganizationsQuestion5,
					},
				],
			},
			{
				condition: {
					questionKey: 'wouldLikeToJoinANewStudentOrganization',
					answer: false,
				},
				questions: [
					{
						questionKey: 'wouldLikeToJoinANewStudentOrganization',
						component: OrganizationsQuestion1,
					},
				],
			},
		],
	},
	{
		label: 'Volunteer',
		icon: FEATURE.volunteers,
		key: 'volunteers',
		branches: [
			{
				questions: [
					{
						questionKey: 'wouldLikeToVolunteer',
						component: VolunteerQuestion1,
					},
					{
						questionKey: 'causesWouldLikeToVolunteerFor',
						component: VolunteerQuestion2,
					},
					{
						questionKey: 'numberOfHoursWouldLikeToVolunteer',
						component: VolunteerQuestion3,
					},
					{
						questionKey: 'whenWouldLikeToVolunteer',
						component: VolunteerQuestion4,
					},
					{
						questionKey: 'interestedInJoiningAStudentServiceOrganization',
						component: VolunteerQuestion5,
					},
				],
			},
			{
				condition: {
					questionKey: 'wouldLikeToVolunteer',
					answer: false,
				},
				questions: [
					{
						questionKey: 'wouldLikeToVolunteer',
						component: VolunteerQuestion1,
					},
					{
						questionKey: 'interestedInJoiningAStudentServiceOrganization',
						component: VolunteerQuestion5,
					},
				],
			},
		],
	},
	{
		label: 'Education Abroad',
		icon: FEATURE.studyAbroad,
		key: 'studyAbroad',
		branches: [
			{
				questions: [
					{
						questionKey: 'wouldLikeToStudyAbroad',
						component: EducationAbroadQuestion1,
					},
					{
						questionKey: 'wouldLikeToParticipateIn',
						component: EducationAbroadQuestion2,
					},
					{
						questionKey: 'countriesWhereWouldLikeToStudyAbroad',
						component: EducationAbroadQuestion3,
					},
					{
						questionKey: 'whileAbroadWouldLikeTo',
						component: EducationAbroadQuestion4,
					},
					{
						questionKey: 'whenWouldLikeToStudyAbroad',
						component: EducationAbroadQuestion5,
					},
				],
			},
			{
				condition: {
					questionKey: 'wouldLikeToStudyAbroad',
					answer: false,
				},
				questions: [
					{
						questionKey: 'wouldLikeToStudyAbroad',
						component: EducationAbroadQuestion1,
					},
					{
						questionKey: 'wouldLikeToParticipateIn',
						component: EducationAbroadQuestion2,
					},
				],
			},
		],
	},
	{
		label: 'Research',
		icon: FEATURE.research,
		key: 'research',
		branches: [
			{
				questions: [
					{
						questionKey: 'wouldLikeToCompleteUndergraduateResearch',
						component: ResearchQuestion1,
					},
					{
						questionKey: 'areasOfResearchOrCreativeInquiryInterestedIn',
						component: ResearchQuestion2,
					},
					{
						questionKey: 'whenWouldLikeToCompleteResearch',
						component: ResearchQuestion3,
					},
				],
			},
			{
				condition: {
					questionKey: 'wouldLikeToCompleteUndergraduateResearch',
					answer: false,
				},
				questions: [
					{
						questionKey: 'wouldLikeToCompleteUndergraduateResearch',
						component: ResearchQuestion1,
					},
				],
			},
		],
	},
	{
		label: 'Professional',
		icon: FEATURE.jobs,
		key: 'jobs',
		branches: [
			{
				questions: [
					{
						questionKey: 'currentlyHaveAJob',
						component: ProfessionalQuestion1,
					},
					{
						questionKey: 'whenLookingForNextJobOrInternship',
						component: ProfessionalQuestion2,
					},
					{
						questionKey: 'typeOfJobOrInternshipWouldLikeToComplete',
						component: ProfessionalQuestion3,
					},
					{
						questionKey: 'professionalGoalsForAfterGraduation',
						component: ProfessionalQuestion4,
					},
					{
						questionKey: 'interestedInTheseServices',
						component: ProfessionalQuestion5,
					},
				],
			},
			{
				condition: {
					questionKey: 'currentlyHaveAJob',
					answer: false,
				},
				questions: [
					{
						questionKey: 'currentlyHaveAJob',
						component: ProfessionalQuestion1,
					},
					{
						questionKey: 'currentlyLookingForAJob',
						component: ProfessionalSecondaryQuestion2,
					},
					{
						questionKey: 'typeOfJobCurrentlyLookingFor',
						component: ProfessionalSecondaryQuestion3,
					},
					{
						questionKey: 'whenLookingForNextJobOrInternship',
						component: ProfessionalQuestion2,
					},
					{
						questionKey: 'typeOfJobOrInternshipWouldLikeToComplete',
						component: ProfessionalQuestion3,
					},
					{
						questionKey: 'professionalGoalsForAfterGraduation',
						component: ProfessionalQuestion4,
					},
					{
						questionKey: 'interestedInTheseServices',
						component: ProfessionalQuestion5,
					},
				],
			},
			{
				condition: {
					questionKey: 'currentlyLookingForAJob',
					answer: false,
				},
				questions: [
					{
						questionKey: 'currentlyHaveAJob',
						component: ProfessionalQuestion1,
					},
					{
						questionKey: 'currentlyLookingForAJob',
						component: ProfessionalSecondaryQuestion2,
					},
					{
						questionKey: 'whenLookingForNextJobOrInternship',
						component: ProfessionalQuestion2,
					},
					{
						questionKey: 'typeOfJobOrInternshipWouldLikeToComplete',
						component: ProfessionalQuestion3,
					},
					{
						questionKey: 'professionalGoalsForAfterGraduation',
						component: ProfessionalQuestion4,
					},
					{
						questionKey: 'interestedInTheseServices',
						component: ProfessionalQuestion5,
					},
				],
			},
		],
	},
	{
		label: 'Out of Classroom',
		icon: 'outOfClass',
		key: 'outOfClass',
		branches: [
			{
				questions: [
					{
						questionKey: 'experiencesWouldLikeToHaveOutsideOfClassroom',
						component: OutOfClassroomQuestion1,
					},
				],
			},
		],
	},
]

// Conditionally remove branches after creating the array

if (isEngage()) {
	pathBuilder.forEach((branch) => {
		// Remove all questions but one from research, study abroad, jobs
		const targetQuestions = {
			research: 'wouldLikeToCompleteUndergraduateResearch',
			studyAbroad: 'wouldLikeToStudyAbroad',
			jobs: 'professionalGoalsForAfterGraduation',
		}

		if (branch.key in targetQuestions && branch.branches) {
			const [newBranch] = branch.branches
			newBranch.questions = newBranch.questions.filter((question) => question.questionKey === targetQuestions[branch.key])
			branch.branches = [newBranch]
		}

		// Remove organizationsWouldLikeToJoin, doesn't work with Engage API yet
		if (branch.key === 'organizations') {
			branch.branches.forEach((subBranch) => {
				subBranch.questions = subBranch.questions.filter((question) => question.questionKey !== 'organizationsWouldLikeToJoin')
			})
		}
	})
}

const getNumberOfSkippedQuestions = (currentQuestionIndex: number, currentStepData: any[]) => {
	let numberOfSkippedQuestions = 0

	if (currentQuestionIndex > currentStepData.length) {
		numberOfSkippedQuestions = currentQuestionIndex - currentStepData.length
	}

	const startFromStepIndex = currentQuestionIndex - 1 >= 0 ? currentQuestionIndex - 1 : 0
	for (let i = startFromStepIndex; i >= 0; i--) {
		if (currentStepData.length >= i && (currentStepData[i] === null || currentStepData[i] === undefined)) {
			numberOfSkippedQuestions += 1
		} else {
			break
		}
	}

	return numberOfSkippedQuestions
}

const PathBuilderSteps = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const appTheme = useTheme()
	const { push } = useHistory()
	const scroll = useScrollToTop()

	const isEdit = useSelector(getIsEditForm)
	const editStepIndex = useSelector(getCurrentStep)
	const userGoals = useSelector(getUserGoals)
	const pathBuilderData = useSelector(getBuilderData)
	const storedForm = useSelector(getForm)

	const [
		{ currentStepIndex, currentQuestionIndex, currentBranchIndex, filledSteps, userAnswer, userAnswerIsValid, isTouchedQuestion },
		setPathBuilderState,
	] = useState<{
		currentStepIndex: number
		currentQuestionIndex: number
		currentBranchIndex: number
		filledSteps: number[]
		userAnswer: any
		userAnswerIsValid: boolean
		isTouchedQuestion: boolean
	}>({
		currentStepIndex: editStepIndex ?? 0,
		currentQuestionIndex: 0,
		currentBranchIndex: 0,
		filledSteps: pathBuilder.reduce((acc, { label }, idx) => (pathBuilderData[label]?.length ? [...acc, idx] : acc), [] as number[]),
		userAnswer: null,
		userAnswerIsValid: false,
		isTouchedQuestion: false,
	})

	const isEditGoalsVersion = userGoals.length && isEdit
	const isEditFirstGoals = !userGoals.length && isEdit

	const nextButtonDisabled = !userAnswerIsValid
	const saveChangesButtonDisabled = !userAnswerIsValid || !isTouchedQuestion
	const redirectAfterEdit = isEditGoalsVersion ? PATHS.APP.PROFILE : PATHS.APP.PATH_BUILDER_SUMMARY

	const showBackButton = currentStepIndex > 0 || currentQuestionIndex > 0

	const currentStepInfo = useMemo(() => pathBuilder[currentStepIndex], [currentStepIndex])
	const currentStepBranch = useMemo(() => currentStepInfo?.branches[currentBranchIndex], [currentStepInfo, currentBranchIndex])
	const currentStepQuestions = useMemo(() => currentStepBranch?.questions ?? [], [currentStepBranch])
	const currentStepData = useMemo(() => pathBuilder[currentStepInfo?.label] ?? [], [currentStepInfo?.label])
	const currentQuestionData = useMemo(
		() => (currentStepData?.length > currentQuestionIndex ? currentStepData[currentQuestionIndex] : undefined),
		[currentStepData, currentQuestionIndex],
	)

	const shouldShowNumberOfQuestions = currentStepQuestions?.length > 1
	const currentQuestionDataKey =
		currentQuestionIndex < currentStepQuestions?.length ? currentStepQuestions[currentQuestionIndex].questionKey : ''
	const CurrentQuestionComponent =
		currentQuestionIndex < currentStepQuestions?.length ? currentStepQuestions[currentQuestionIndex].component : null

	const getNewBranchIndex = (stepOffset: number, targetStepIndex?: number, questionAnswers?: any[]): number => {
		const calculatedStepIndex = (targetStepIndex || currentStepIndex) + stepOffset
		let newBranchIndex = 0

		if (calculatedStepIndex >= pathBuilder.length || calculatedStepIndex < 0) return newBranchIndex

		const calculatedStepInfo = pathBuilder[calculatedStepIndex]
		const calculatedStepData = pathBuilderData[calculatedStepInfo.label] ?? []

		const userAnswers = (questionAnswers ?? calculatedStepData).reduce(
			(acc, answersArr) => ({
				...acc,
				...answersArr,
			}),
			{} as any,
		)

		calculatedStepInfo.branches.forEach(({ condition }, index) => {
			if (condition && condition.answer === userAnswers[condition.questionKey]) {
				newBranchIndex = index
			}
		})

		return newBranchIndex
	}

	const handleChangeCompletedSteps = (index: number, completed = false) => {
		if (completed) {
			setPathBuilderState((prev) => ({
				...prev,
				filledSteps: filledSteps.includes(index) ? filledSteps : filledSteps.concat([index]),
			}))
		} else {
			setPathBuilderState((prev) => ({
				...prev,
				filledSteps: filledSteps.filter((step) => step !== index),
			}))
		}
	}

	const handleValidateAnswer = (isValid: boolean, isTouched: boolean, newData: any) => {
		setPathBuilderState((prev) => ({
			...prev,
			userAnswerIsValid: isValid,
			userAnswer: isValid ? newData : null,
			isTouchedQuestion: prev.isTouchedQuestion ? true : isTouched,
		}))
	}

	const handleSaveData = (answer: any, clearFollowingAnswers = false, clearFromIndex?: number, numberOfSteps?: number) => {
		// set skipped questions to null by default
		if (numberOfSteps !== undefined && clearFromIndex !== undefined) {
			for (let i = clearFromIndex; i < clearFromIndex + numberOfSteps; i++) {
				dispatch(
					setPathBuilder({
						key: currentStepInfo.label,
						currentSubStep: i,
						answer,
						clearFollowingAnswers: false,
					}),
				)
			}
		} else {
			dispatch(
				setPathBuilder({
					key: currentStepInfo.label,
					currentSubStep: clearFromIndex ?? currentQuestionIndex,
					answer,
					clearFollowingAnswers,
				}),
			)
		}
	}

	const handleClickBack = () => {
		let numberOfSkippedQuestions = getNumberOfSkippedQuestions(currentQuestionIndex, currentStepData)
		let newQuestionIndex = currentQuestionIndex - (numberOfSkippedQuestions || 1)
		let newStepIndex = currentStepIndex
		let newBranchIndex = getNewBranchIndex(0)

		if (newQuestionIndex < 0) {
			newQuestionIndex = 0
			newStepIndex -= 1
			const newStep = pathBuilder[newStepIndex]
			newBranchIndex = getNewBranchIndex(0, newStepIndex, pathBuilderData[newStep.label] ?? [])
		}

		if (newBranchIndex !== currentBranchIndex || newStepIndex < currentStepIndex) {
			const newStep = pathBuilder[newStepIndex]
			newBranchIndex = getNewBranchIndex(0, newStepIndex, pathBuilderData[newStep.label] ?? [])
			const newQuestions = newStep.branches[newBranchIndex].questions

			newQuestionIndex = newQuestions.length - 1
			numberOfSkippedQuestions = getNumberOfSkippedQuestions(newQuestionIndex, pathBuilderData[newStep.label] ?? [])
			newQuestionIndex = newQuestions.length - (numberOfSkippedQuestions || 1)
			newQuestionIndex = newQuestionIndex < 0 ? 0 : newQuestionIndex
		}
		// update step completion mark
		const newStepData = pathBuilderData[currentStepInfo.label] ?? []
		const updatedBranchIndex = getNewBranchIndex(0, currentStepIndex, newStepData)

		const newQuestions = pathBuilder[currentStepIndex].branches[updatedBranchIndex].questions

		handleChangeCompletedSteps(currentStepIndex, newStepData.length === newQuestions.length)

		setPathBuilderState((prev) => ({
			...prev,
			currentBranchIndex: newBranchIndex,
			currentQuestionIndex: newQuestionIndex,
			currentStepIndex: newStepIndex,
		}))
	}

	const saveDataAndRedirect = () => {
		handleSaveData(userAnswer)
		dispatch(createForm())
		if (isEditGoalsVersion) {
			dispatch(saveGoalsRequest())
		}

		push(redirectAfterEdit)
	}

	const handleOpenGrantPage = () => {
		dispatch(setIsOpenPathBuilderCompletedPopup(true))
		dispatch(createForm())
		push(PATHS.APP.PATH_BUILDER_GRANT)
	}

	const handleClickNext =
		(skipValidation?: boolean) =>
		(numberOfQuestionsToSkip = 1) =>
		() => {
			if (!skipValidation && !userAnswerIsValid) return

			let numberOfQuestions = currentQuestionIndex + numberOfQuestionsToSkip

			if (!skipValidation) {
				handleSaveData(userAnswer)
			} else {
				handleSaveData(null, true, currentQuestionIndex, numberOfQuestions)
			}

			let newQuestionIndex = numberOfQuestions
			let newStepIndex = currentStepIndex
			const newStep = pathBuilder[newStepIndex]

			let newBranchIndex = getNewBranchIndex(0, newStepIndex, (pathBuilderData[newStep.label] ?? []).concat([userAnswer]))

			const newQuestions = pathBuilder[newStepIndex].branches[newBranchIndex].questions

			if (newBranchIndex !== currentBranchIndex && newQuestionIndex < newQuestions.length) {
				newQuestionIndex = newQuestionIndex < newQuestions.length ? newQuestionIndex : newQuestions.length - 1
			} else if (newQuestionIndex >= newQuestions.length) {
				newStepIndex += 1
				newBranchIndex = getNewBranchIndex(0, newStepIndex)
				newQuestionIndex = 0
			}

			if (newStepIndex > currentStepIndex) {
				numberOfQuestions = numberOfQuestions > newQuestions.length ? newQuestions.length : numberOfQuestions
				handleSaveData(null, true, numberOfQuestions)
			}

			if (newStepIndex >= pathBuilder.length) {
				if (isEdit) {
					saveDataAndRedirect()
				} else {
					if (!isEngage()) {
						handleOpenGrantPage()
					} else {
						push(PATHS.APP.PATH_BUILDER_SUMMARY)
					}
				}
			} else {
				handleChangeCompletedSteps(currentStepIndex, true)

				setPathBuilderState((prev) => ({
					...prev,
					currentBranchIndex: newBranchIndex,
					currentQuestionIndex: newQuestionIndex,
					currentStepIndex: newStepIndex,
				}))
			}
		}

	const handleFinishEdit = () => {
		if (!userAnswerIsValid) return
		saveDataAndRedirect()
	}

	const handleClickCancelChanges = () => {
		push(redirectAfterEdit)
	}

	const handleClickClose = () => {
		dispatch(showExitConfirmationModal({ isOpen: true, redirectPath: PATHS.APP.HOME }))
	}

	const handleStepperClick = (idx: number) => () => {
		setPathBuilderState((prev) => ({
			...prev,
			currentBranchIndex: 0,
			currentQuestionIndex: 0,
			currentStepIndex: idx,
		}))
	}

	useEffect(() => {
		scroll()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStepIndex, currentQuestionIndex])

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<div className={classes.left}>
					{isEdit && (
						<IconButton onClick={handleClickCancelChanges} className={classes.backButton} startIcon={<ArrowBackIcon fontSize="large" />}>
							{isEditFirstGoals ? 'Back to Summary' : 'Back to Activity'}
						</IconButton>
					)}

					<Stepper steps={pathBuilder} currentStep={currentStepIndex} completedSteps={filledSteps} onClickStep={handleStepperClick} />
				</div>
				<div className={classes.right}>
					{CurrentQuestionComponent && (
						<CurrentQuestionComponent
							onValidation={handleValidateAnswer}
							onClose={handleClickClose}
							onBack={handleClickBack}
							showBackButton={showBackButton}
							data={currentQuestionData}
							onSkipStep={handleClickNext(true)}
							pathBuilderForm={storedForm}
							dataKey={currentQuestionDataKey}
						/>
					)}
				</div>
			</div>
			<div className={classes.footer}>
				{shouldShowNumberOfQuestions && (
					<div className={classes.dots}>
						{currentStepQuestions.map((step, id) => (
							<StepperDot key={`stepper-dot-${id}`} isActive={id <= currentQuestionIndex} color={appTheme.palette.primary.main} />
						))}
					</div>
				)}
				{!isEdit ? (
					<NextButton onClick={handleClickNext()()} disabled={nextButtonDisabled} />
				) : (
					<>
						<OutlinedButton
							color={ButtonColors.GREY}
							className={classes.whiteButton}
							onClick={handleClickNext()()}
							disabled={nextButtonDisabled}
						>
							Next Question
							<ArrowForwardIcon className={classes.nextIcon} fontSize="medium" />
						</OutlinedButton>
						<ColorButton className={classes.saveButton} disabled={saveChangesButtonDisabled} onClick={handleFinishEdit}>
							Save
						</ColorButton>
					</>
				)}
			</div>
		</div>
	)
}

export default PathBuilderSteps

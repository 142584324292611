export type ErrorHandledResult<T, E = void> = [T | null, E extends void ? Error : E | null]

export type FnWithErrorHandler = <TResponse, TParams = void>(
	asyncFunction: (params?: TParams) => Promise<TResponse>,
	params?: TParams,
) => Promise<ErrorHandledResult<TResponse>>

const withErrorHandling: FnWithErrorHandler = async (asyncFunction, params) => {
	try {
		const data = await asyncFunction(params)
		return [data, null]
	} catch (error) {
		return [null, error]
	}
}

export default withErrorHandling

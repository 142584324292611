import { useCallback } from 'react'

import { UserGroupIcon } from '@/components'
import { ModalOptionPicker } from '@/components/filters/ModalPicker'
import { useAppDispatch, useAppSelector } from '@/store'
import { getFeedsFilterOption } from '@/store/feeds'
import { selectFeedAudience, setFilterAudience } from '../../feed/slice'
import { useFilterButtonStyles } from './styles/useFilterButtonStyles'

const FeedAudience = () => {
	const { audienceScope: availableAudienceScopes } = useAppSelector(getFeedsFilterOption)
	const audienceScope = useAppSelector(selectFeedAudience)
	const dispatch = useAppDispatch()
	const classes = useFilterButtonStyles()

	const handleChangeAudience = useCallback(
		(audience: number) => {
			dispatch(setFilterAudience(audience))
		},
		[dispatch],
	)

	return (
		<ModalOptionPicker
			buttonClassName={classes.button}
			selected={audienceScope}
			onChange={handleChangeAudience}
			buttonDefaultLabel="Choose audience"
			modalTitle="Choose audience"
			modalSubTitle="See posts created for a specific group of users."
			options={availableAudienceScopes}
			Icon={UserGroupIcon}
		/>
	)
}

export default FeedAudience

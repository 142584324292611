import { isEngage } from '../configuration'

const ROOT = isEngage() ? 'goal-setting' : 'path-builder'

const PATH_BUILDER = {
	ROOT,
	QUESTIONS: `${ROOT}/questions`,
	GRANT: `${ROOT}/grant`,
	SUMMARY: `${ROOT}/summary`,
	LAST_STEP: `${ROOT}/last-step`,
}

export default PATH_BUILDER

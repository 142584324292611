import http from '@/api/http/requestHttp'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { PostId, UserId } from '@/lib/types'
import { buildUrl } from '..'
import { DtoMention } from '@/components/Editor/plugins/MentionPlugin/types'

export interface CreatePostReq {
	id?: PostId
	userId: UserId
	ownerOrganizationId?: string
	campusId?: number | null
	usersAudience: number
	usersAudienceScope: number
	tag: number
	message?: string
	photoUrl?: string
	activeSince?: string
	sharedEntityId?: string
	sharedEntityType?: EntityTypes
	linkPreviewUrl?: string
	schoolId: number
	mentions?: DtoMention[]
}
export const createPost = async ({ schoolId, userId, ...post }: CreatePostReq) => {
	const url = `${schoolId}/feeds/${userId}/create`

	return http.post(url, post)
}

export const updatePost = async ({ schoolId, userId, ...post }: CreatePostReq) => {
	const url = buildUrl({
		path: `/:schoolId/feeds/:userId/post/:postId/edit`,
		params: { schoolId, userId, postId: post.id },
	})

	return http.patch(url, post)
}

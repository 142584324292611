const localStorage = {
	TEST_ROLE_PATH: 'TEST_ROLE',
	CURRENT_USER_ID_PATH: 'CURRENT_USER_ID',
	ACCESS_TOKEN_PATH: 'ACCESS_TOKEN',
	REFRESH_TOKEN_PATH: 'REFRESH_TOKEN',
	ACCESS_TOKEN_EXPIRATION_PATH: 'ACCESS_TOKEN_EXPIRATION',
	REFRESH_TOKEN_EXPIRATION_PATH: 'ACCESS_TOKEN_EXPIRATION',
	SCHOOL_ID_PATH: 'SCHOOL_ID',
}

export default localStorage

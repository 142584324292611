import { ResearchState } from '@/store/research/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/research/constants'
import { SearchConfig, UserResponseStatuses } from '@/interfaces/common'

const selectState = (state: { [MODULE_NAME]: ResearchState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)

export const getIsLoadingIndividualResearch = createSelector(selectState, (state) => state.isLoadingIndividualResearch)

export const getResearch = createSelector(selectState, (state) => state.research)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)

export const getUserResearch = createSelector(selectState, (state) => state.userResearch)
export const getSelectedResearch = createSelector(selectState, (state) => state.selectedResearch)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)
export const getResearchById = (id?: string) =>
	createSelector(selectState, (state) => state.research.find((research) => research.id === id))

export const selectCurrentUserHasResponse = createSelector(
	[selectState, (__, response: UserResponseStatuses) => response],
	(researchState, response) => {
		return researchState.selectedResearch && researchState.selectedResearch.responseStatus === response
	},
)

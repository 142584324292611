export const getJwtPayload = (jwt: string): { [key: string]: any } | null => {
	if (typeof jwt !== 'string') {
		return null
	}
	const [, payloadB64] = jwt.split('.')
	if (payloadB64) {
		return JSON.parse(atob(payloadB64))
	}
	return null
}

export const isExpired = (exp: number): boolean => {
	const timestampInSeconds = Math.floor(Date.now() / 1000)

	return exp <= timestampInSeconds
}

export const generateCodeVerifier = (): string => {
	const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
	let codeVerifier = ''
	for (let i = 0; i < 128; i++) {
		const randomIndex = Math.floor(Math.random() * charset.length)
		codeVerifier += charset.charAt(randomIndex)
	}
	return codeVerifier
}

export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
	const encoder = new TextEncoder()
	const data = encoder.encode(codeVerifier)
	const hashBuffer = await crypto.subtle.digest('SHA-256', data)
	const hashArray = Array.from(new Uint8Array(hashBuffer))
	const hashBase64 = btoa(String.fromCharCode(...hashArray))
	return hashBase64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export const generatePkce = async (): Promise<{ codeVerifier: string; codeChallenge: string }> => {
	const codeVerifier = generateCodeVerifier()
	const codeChallenge = await generateCodeChallenge(codeVerifier)
	return { codeChallenge, codeVerifier }
}

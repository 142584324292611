import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Card from '@/components/IndividualPage/Card'
import CategoryTag from '@/components/CategoryTag'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'

const useStyles = makeStyles({
	title: {
		fontWeight: 700,
	},
	categories: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	rootContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '2vh',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
})

interface AboutEventCardProps {
	categories?: string[]
	description?: string
}

const AboutEventCard = ({ categories, description }: AboutEventCardProps) => {
	const classes = useStyles()

	return (
		<Card>
			<div className={classes.rootContainer}>
				<div className={classes.headerContainer}>
					<Typography className={classes.title}>About this event</Typography>
					<div className={classes.categories}>
						{categories?.map((category) => (
							<CategoryTag key={category} name={category} />
						))}
					</div>
				</div>
			</div>
			{description && <DescriptionHtml description={description} />}
		</Card>
	)
}

export default AboutEventCard

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Chip, makeStyles } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

import { useAppSelector } from '@/store'
import { applyFeedUpdates, feedUpdatesSelectors, hideUpdatesBadge, selectShowUpdates } from '../feed/slice'

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		bottom: '-60px',
		left: '50%',
		transform: 'translate(-50%, 0)',
	},
	tag: {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 32,
		fontSize: '0.9em',
		margin: '0.5vh 0.5vw',
		'&:focus': {
			backgroundColor: `${theme.palette.primary.main} !important`,
		},
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main} !important`,
		},
	},
	tagIcon: {
		color: theme.palette.common.white,
	},
}))

interface IFeedUpdateInfoProps {
	onClick?: () => void
}

export const FeedUpdatesInfo: React.FC<IFeedUpdateInfoProps> = ({ onClick }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const updates = useAppSelector(feedUpdatesSelectors.selectAll)
	const showUpdates = useAppSelector(selectShowUpdates)
	const updatesCount = updates.length

	const handleClick = useCallback(async () => {
		await dispatch(applyFeedUpdates())
		if (onClick) onClick()
	}, [dispatch, onClick])

	const onDelete = useCallback(() => {
		dispatch(hideUpdatesBadge())
	}, [dispatch])

	return showUpdates && updatesCount > 0 ? (
		<div className={classes.root}>
			<Chip
				onClick={handleClick}
				onDelete={onDelete}
				className={classes.tag}
				label={`${updatesCount} ${updatesCount > 1 ? 'Updates' : 'Update'}`}
				deleteIcon={<CancelIcon className={classes.tagIcon} />}
				variant="outlined"
			/>
		</div>
	) : null
}

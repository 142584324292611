import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Card from '@/components/IndividualPage/Card'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import { UserData } from '@/interfaces/common'
import ItemsSectionWithButton from '@/components/ItemsSections/ItemsSectionWithButton'
import { getShortCounterLabel } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 15,
	},
	userRow: {
		margin: '8px 0',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
	},
}))

interface UsersListCardProps {
	id?: string
	title: string
	users: UserData[]
	onLoadMoreItems: (orgId: string, page?: number) => void
	numberOfFollowers: number
	orgId: string
	handleClearItems?: () => void
	isLoading: boolean
	canLoadMore: boolean
}

const UsersListCard = ({
	title,
	users,
	id,
	onLoadMoreItems,
	numberOfFollowers,
	orgId,
	handleClearItems,
	isLoading,
	canLoadMore,
}: UsersListCardProps) => {
	const classes = useStyles()

	const handleLoadMoreItems = () => {
		onLoadMoreItems(orgId)
	}

	useEffect(() => {
		onLoadMoreItems(orgId, 1)

		return () => {
			if (handleClearItems) handleClearItems()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Card>
			<div id={id} className={classes.titleContainer}>
				<Typography variant="body1">
					<b>{title}</b> {`${numberOfFollowers ? ' | ' + getShortCounterLabel(numberOfFollowers) : ''}`}
				</Typography>
			</div>
			<div>
				<ItemsSectionWithButton
					classnames={{
						content: classes.cardContainer,
					}}
					handleLoadMoreItems={handleLoadMoreItems}
					items={users}
					isLoading={isLoading}
					canLoadMore={canLoadMore}
					renderItems={(items: UserData[]) =>
						items.map((user) => (
							<MembershipInfoRow
								classnames={{
									userRow: classes.userRow,
								}}
								key={`${orgId}-follower-${user.id}`}
								user={user}
								openProfileOnClick
							/>
						))
					}
				/>
			</div>
		</Card>
	)
}

export default UsersListCard

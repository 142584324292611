import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { makeStyles } from '@material-ui/core/styles'
import { PATHS, STYLES } from '@/constants'
import { editEventRequest, getSelectedEvent } from '@/store/events'
import EventForm from '@/components/IndividualPage/EventForm'
import { getUserInfo } from '@/store/auth'
import { isEventAdmin } from '@/utils/events'
import { Redirect } from 'react-router-dom'
import { useState } from 'react'
import { CreateEventFormType } from '@/constants/createEventFormFields'
import { getTime } from '@/utils/dateTime2'
import { EventLocationTypes } from '@/constants/events'
import { useGetEventPermissionsQuery } from '@/features/permissions/api'

const useStyles = makeStyles((theme) => ({
	pageRoot: {
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: `${STYLES.LANDING_FOOTER_HEIGHT}vh`,
	},
}))

const EditEvent = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const event = useSelector(getSelectedEvent)
	const { id: userId } = useSelector(getUserInfo)

	const hasShifts = !!event?.volunteerShifts?.length

	const [eventData] = useState<CreateEventFormType>({
		eventName: event?.name,
		startDate: event?.startDate,
		startTime: getTime(event?.startDate || ''),
		endDate: event?.endDate,
		endTime: getTime(event?.endDate || ''),
		description: event?.description,
		campus: event?.campus?.map(({ id }) => id),
		locationType: event?.virtualLink ? EventLocationTypes.VIRTUAL : EventLocationTypes.IN_PERSON,
		virtualLink: event?.virtualLink,
		repeatCount: event?.repeatCount > 0 ? event?.repeatCount : null,
		repeating: event?.repeating,
		locationName: event?.locationName,
		locationAddress: event?.streetAddress,
		hostOrganization: event?.organization?.length ? event?.organization.map((org) => org.id) : [],
		categories: event?.categories?.map(({ id }) => id),
		contactName: event?.contactName,
		contactEmail: event?.contactEmail,
		photo: event?.photoUrl,
		privacyType: event?.privacyType,
		addingShifts: hasShifts,
		shiftsNumber: hasShifts ? event?.volunteerShifts?.length : 0,
		volunteersPerShift: hasShifts ? event?.volunteerShifts[0].maxUsers : 0,
		shifts: hasShifts
			? event?.volunteerShifts.map((s) => ({
					start: getTime(s.startDate),
					end: getTime(s.endDate),
			  }))
			: [],
		externalUrl: event?.externalUrl,
	})

	const isAdmin = isEventAdmin(userId, event?.submittedByUserId)
	const skipPermissionCheck = isAdmin || event?.organization.length === 0

	const { data: permissionsData } = useGetEventPermissionsQuery(
		{ eventId: id },
		{
			skip: skipPermissionCheck,
			selectFromResult: (result) => ({
				...result,
				data: {
					canEdit: result.data ? result.data.canEdit : isAdmin,
					canDelete: result.data ? result.data.canDelete : isAdmin,
				},
			}),
		},
	)
	const onSubmit = (data: any) => {
		dispatch(editEventRequest(data))
	}

	const canEdit = isAdmin || permissionsData.canEdit

	if (!event || !canEdit) return <Redirect to={PATHS.APP.EVENTS_SINGLE(id)} />

	return (
		<div className={classes.pageRoot}>
			<EventForm onSubmit={onSubmit} data={eventData} isEdit cancelRedirectPath={PATHS.APP.EVENTS_SINGLE(id)} />
		</div>
	)
}

export default EditEvent

import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'
import StepperContent from '@/components/PathBuilder/StepperContent'
import StepperCard from '@/components/PathBuilder/StepperCard'
import { PathBuilderQuestionProps } from '@/pages/PathBuilderPage/PathBuilderSteps'
import usePathBuilderForm from '@/hooks/usePathBuilderForm'
import { TextField } from '@material-ui/core'
import classNames from 'classnames'
import { getStringSchema } from '@/constants/pathBuilder'

const useStyles = makeStyles((theme) => ({
	itemsContainer: {
		width: 'calc(100% - 120px)',
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, auto-fill)`,
		justifyContent: 'space-evenly',
		gridGap: 10,
		margin: '2vh 60px',
	},
	textArea: {
		borderRadius: 7,
		width: '70%',
		margin: '4vh 0',
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.border.bold,
			},
			'& fieldset': {
				borderColor: theme.palette.specialColors.transparent,
				borderRadius: 7,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.border.bold,
			},
		},
		backgroundColor: theme.palette.grey[200],
	},
	inputRoot: {
		caretColor: theme.palette.primary.dark,
	},
	textAreaFilled: {
		backgroundColor: theme.palette.specialColors.transparent,
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.border.bold,
			},
		},
	},
	header: {
		width: '58%',
	},
}))

const Question4 = ({ onClose, onValidation, onBack, showBackButton, data, pathBuilderForm, dataKey }: PathBuilderQuestionProps) => {
	const classes = useStyles()

	const { schema, defaultValues } = getStringSchema(dataKey, data, pathBuilderForm)

	const { control } = usePathBuilderForm({
		schema,
		defaultValues,
		onValidation,
	})

	return (
		<StepperContent
			leftColumnContent={
				<StepperCard
					classnames={{
						header: classes.header,
					}}
					title="How else would you describe the type 
      of organization you’d like to join?"
					onClose={onClose}
					onBack={onBack}
					showBackButton={showBackButton}
				>
					<Controller
						name={dataKey}
						control={control}
						render={({ field }) => (
							<TextField
								className={classNames(classes.textArea, field.value?.length ? classes.textAreaFilled : '')}
								placeholder={`Describe organization`}
								multiline
								rows={8}
								variant="outlined"
								InputProps={{
									classes: { input: classes.inputRoot },
								}}
								{...field}
							/>
						)}
					/>
				</StepperCard>
			}
		/>
	)
}

export default Question4

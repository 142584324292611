import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

interface UsePathBuilderFormProps {
	schema: yup.ObjectSchema<any>
	defaultValues: any
	onValidation: (isValid: boolean, isTouched: boolean, data: any) => void
}

const usePathBuilderForm = ({ schema, defaultValues, onValidation }: UsePathBuilderFormProps) => {
	const {
		control,
		formState: { isValid, isValidating, isDirty },
		getValues,
	} = useForm<any>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
		defaultValues,
	})

	useEffect(() => {
		if (!isValidating) {
			const currentValues = getValues()
			onValidation(isValid, isDirty, currentValues)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidating, isValid])

	return {
		control,
	}
}

export default usePathBuilderForm

import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ClockOutlinedIcon, DollarIcon, GraduationHatOutlinedIcon, PersonOutlinedIcon } from '@/components'
import AboutCard from '@/components/IndividualPage/AboutCard'
import ExternalUrlCard from '@/components/IndividualPage/ExternalUrlCard'
import ImageCard from '@/components/IndividualPage/ImageCard'
import LoadingPlaceholder from '@/components/IndividualPage/LoadingPlaceholder'
import PageContainer from '@/components/IndividualPage/PageContainer'
import Title from '@/components/IndividualPage/Title'
import ResponseButton from '@/components/IndividualPage/rsvp/ResponseButton'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS } from '@/constants'
import useResponseHandler from '@/features/responses/hooks/useResponseHandler'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useWatchPageTitle from '@/hooks/useWatchPageTitle'
import { UserResponseStatuses } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { getPositionTypes } from '@/store/constants/constantsSlice'
import { getIsLoadingIndividualInternship, getSelectedInternship, loadInternshipByIdRequest } from '@/store/internships'
import { formatDateNew } from '@/utils/dateTime2'

const IndividualInternship = ({
	match: {
		params: { id },
	},
}: any) => {
	const dispatch = useAppDispatch()
	const isLoading = useSelector(getIsLoadingIndividualInternship)
	const internship = useSelector(getSelectedInternship)

	const jobTypes = useSelector(getPositionTypes)

	const {
		handleChangeResponse,
		handleReport,
		responseQuery: { isLoading: isLoadingResponse },
	} = useResponseHandler('jobs', id)

	useWatchPageTitle({
		watchEntity: internship?.name,
	})

	useEffect(() => {
		dispatch(loadInternshipByIdRequest(id))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const internshipName = `${internship?.name}`

	const jobTypeLabel = jobTypes?.find((type) => type.value === internship?.jobTypeId)?.label ?? ''
	const positionPaidLabel = internship?.positionPaid === true ? 'Paid' : 'Unpaid'
	const inPersonLabel = internship?.positionVirtual === true ? 'Remote' : 'In-Person'
	const minGPALabel = `${internship?.minGpa ?? 'No'} Minimum GPA`
	const classLevelLabel = internship?.requiredClassLevel?.map((info: any) => info.classLevel).join(', ') ?? ''
	const postedStartDate = `Posted ${formatDateNew(internship?.postingStartDate, 'dateMed')}`
	const expiryDate = `Expires ${formatDateNew(internship?.postingEndDate, 'dateMed')}`

	const sharingUrl = PATHS.APP.INTERNSHIPS_SINGLE(id)
	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.job } }), [id])

	if (!internship?.id || isLoading) return <LoadingPlaceholder />

	return (
		<PageContainer>
			<ImageCard
				dotButton={{
					itemId: internship.id,
					reportSubject: ReportSubject.Internship,
					onSubmitReport: handleReport,
				}}
				photoUrl={internship.photoUrl}
				isEvent={false}
			/>
			<Title
				type={'Job/Internship'}
				title={internshipName}
				subTitle={internship?.employer}
				renderButtons={() => (
					<ResponseButton
						text={internship.responseStatus === UserResponseStatuses.interested ? 'Bookmarked' : 'Bookmark'}
						handleChange={handleChangeResponse}
						status={internship.responseStatus}
						loading={isLoadingResponse}
					/>
				)}
				sharingUrl={sharingUrl}
				sharedData={sharedData}
			/>

			<ExternalUrlCard url={internship.externalUrl} label={'Apply'} />

			<AboutCard
				description={internship?.description}
				infoItems={[
					{
						label: jobTypeLabel,
						icon: <PersonOutlinedIcon />,
					},
					{
						label: inPersonLabel,
						icon: <PersonOutlinedIcon />,
					},
					{
						label: positionPaidLabel,
						icon: <DollarIcon />,
					},
					{
						label: minGPALabel,
						icon: <GraduationHatOutlinedIcon />,
					},
					{
						label: classLevelLabel,
						icon: <GraduationHatOutlinedIcon />,
					},
					{
						label: postedStartDate,
						icon: <ClockOutlinedIcon />,
					},
					{
						label: expiryDate,
						icon: <ClockOutlinedIcon />,
					},
				]}
			/>
		</PageContainer>
	)
}

export default IndividualInternship

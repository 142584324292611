import { useCallback, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { PATHS, STYLES } from '@/constants'
import UserInfoBar from '@/components/Messages/UserInfoBar'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import {
	getShowSelectUserInput,
	readUserDialogueRequest,
	setShowSelectUserInput,
	getTargetUser,
	getMessages,
	loadMessagesListRequest,
	clearTargetUser,
	clearMessages,
	getUnviewedCount,
} from '@/store/messages'
import { IconButton, Typography } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import LazyLoadProfileImage from '@/components/LazyLoadProfileImage'

import MessageBubble from '@/components/Messages/MessageBubble'
import { getTargetGroup } from '@/store/groups'
import { useHistory } from 'react-router-dom'
import MessagesList from '@/components/Messages/MessagesList'
import AddNewMessageRow from '@/components/Messages/AddNewMessageRow'
import { Message } from '@/store/messages/types'
import { RootState } from '@/store'
import settings from '@/constants/http'
import FloatingButton from '@/components/Buttons/FloatingButton'
import { useMarkAsViewedDirectMessagesMutation } from '@/features/messages/api'
import { useCurrentUser } from '@/hooks/userHooks'

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,

		[theme.breakpoints.down('sm')]: {
			paddingBottom: 100,
		},
	},
	mobileHeader: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		// @TODO: can't find logic in border colors, randomly 200 and 300 are used
		borderBottom: `solid 2px ${theme.palette.divider}`,
		height: 70,
	},
	headerUserInfo: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	userName: {
		color: theme.palette.text.primary,
	},
	profileImage: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
		marginRight: 20,
	},
	messagesContainer: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
	},
	messagesList: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: 'inherit',
		minHeight: '50vh',
	},
	placeholderProfileImage: {
		width: 75,
		height: 75,
		minWidth: 75,
		minHeight: 75,
	},
	placeholderText: {
		margin: 20,
	},
}))

const DialoguePage = ({
	match: {
		params: { id },
	},
}: any) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const { push } = useHistory()
	const appTheme = useTheme()
	const mdScreenSize = useMediaQuery(appTheme.breakpoints.up('md'))
	const downMdScreenSize = useMediaQuery(appTheme.breakpoints.down('md'))
	const currentUser = useCurrentUser()
	const targetUser = useSelector(getTargetUser)
	const targetGroup = useSelector(getTargetGroup)

	const [markAllRead, { isLoading: patchingMessages }] = useMarkAsViewedDirectMessagesMutation()

	const unviewedCount = useSelector((state: RootState) => getUnviewedCount(state, { userId: +id }))
	const showFloatingButton = unviewedCount > settings.SEARCH_MESSAGES_PER_PAGE

	const showSelectUserInput = useSelector(getShowSelectUserInput)

	const { list, canLoadMore, isLoading } = useSelector(getMessages)

	const handleHideSelectUserInput = useCallback(() => dispatch(setShowSelectUserInput(false)), [dispatch])

	const showOnlyMessages = !mdScreenSize && (!!targetUser || showSelectUserInput || !!targetGroup)

	const onMarkAllReadClick = useCallback(() => {
		if (id) {
			markAllRead({ targetUserId: +id, userId: currentUser.id })
		}
	}, [markAllRead, id, currentUser.id])

	const rowRenderer = (message: Message) => {
		return (
			<div key={message.id}>
				<MessageBubble id={message.id} message={message} />
			</div>
		)
	}

	const handleLoadMore = () =>
		dispatch(
			loadMessagesListRequest({
				fetchMore: true,
				userId: +id,
			}),
		)

	useEffect(() => {
		dispatch(readUserDialogueRequest(id))
		dispatch(
			loadMessagesListRequest({
				userId: +id,
			}),
		)

		return () => {
			dispatch(clearTargetUser())
			dispatch(clearMessages())
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	return (
		<>
			{(mdScreenSize || showOnlyMessages) && (
				<div className={classes.container}>
					{showOnlyMessages && (
						<div className={classes.mobileHeader}>
							<IconButton onClick={() => push(PATHS.APP.MESSAGES)}>
								<KeyboardArrowLeft fontSize="large" />
							</IconButton>
							<div className={classes.headerUserInfo}>
								<LazyLoadProfileImage
									className={classes.profileImage}
									externalUserId={targetUser?.externalId || ''}
									photoUrl={targetUser?.photoUrl}
								/>
								<Typography className={classes.userName} variant="subtitle1">
									{targetUser?.fullName}
								</Typography>
							</div>
						</div>
					)}
					{(!showSelectUserInput || !!targetUser) && (
						<div className={classes.messagesContainer}>
							{showFloatingButton ? (
								<FloatingButton onClick={onMarkAllReadClick} label={patchingMessages ? 'Loading...' : 'Mark All Read'} />
							) : null}
							<div className={classes.messagesList}>
								<MessagesList
									isLoading={isLoading}
									rowRenderer={rowRenderer}
									handleLoadMore={handleLoadMore}
									targetId={id}
									canLoadMore={canLoadMore}
									messages={list}
									placeholder={
										<>
											<LazyLoadProfileImage
												className={classes.profileImage}
												externalUserId={targetUser?.externalId ?? ''}
												photoUrl={targetUser?.photoUrl}
											/>
											<Typography className={classes.placeholderText} variant="subtitle1">
												Start up a chat with {targetUser?.firstName || 'someone'} 👋
											</Typography>
										</>
									}
								/>
							</div>
							<AddNewMessageRow targetUser={targetUser} handleHideSelectUserInput={handleHideSelectUserInput} />
						</div>
					)}
				</div>
			)}
			{!downMdScreenSize && !!targetUser && <UserInfoBar user={targetUser} />}
		</>
	)
}

export default DialoguePage

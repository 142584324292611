import ColorButton from '@/components/Buttons/ColorButton'
import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import { PATHS } from '@/constants'
import { getError } from '@/store/app'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
	errorCard: {
		paddingBottom: '1vh',
		minHeight: '45vh',
	},
	h5: {
		fontWeight: 700,
		textAlign: 'center',
		margin: '1em 0',
	},
	contentContainer: {
		maxWidth: '40vw',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
			maxWidth: 'none',
		},
	},
	messageText: {
		textAlign: 'center',
		fontSize: '1.12em',
		lineHeight: '1.6em',
	},
	button: {
		marginTop: 60,
	},
}))

const ErrorPage = () => {
	const classes = useStyles()
	const { push } = useHistory()
	const error = useSelector(getError)!

	if (!error) return <Redirect to={PATHS.APP.ROOT} />

	return (
		<CardLayout>
			<Card className={classes.errorCard}>
				<div className={classes.contentContainer}>
					<Typography className={classes.h5} variant="h3">
						Sorry, something went wrong
					</Typography>
					<div className={classes.messageText}>{error?.statusText}</div>
					<div className={classes.messageText}>{error?.message}</div>
					<ColorButton className={classes.button} onClick={() => push(PATHS.APP.ROOT)}>
						Back to Homepage
					</ColorButton>
				</div>
			</Card>
		</CardLayout>
	)
}

export default ErrorPage

import { styled } from '@mui/material/styles'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)(({ theme }) => ({
	color: theme.palette.primary.main,
	textDecoration: 'none',
	fontWeight: theme.typography.fontWeightMedium,
}))

type ExtendedGridRenderCellParams = GridRenderCellParams & {
	path: string
}

const GridLinkTemplate = ({ value, row, path }: ExtendedGridRenderCellParams) => {
	return <>{row.id ? <StyledLink to={path}>{value}</StyledLink> : { value }}</>
}

export default GridLinkTemplate

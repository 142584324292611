import { PATHS } from '@/constants'
import AppRoute from '@/interfaces/route'
import DiscoverEvents from '@/pages/VolunteersPage/DiscoverEvents'
import DiscoverOrganizations from '@/pages/VolunteersPage/DiscoverOrganizations'
import YourVolunteerShifts from '@/pages/VolunteersPage/YourVolunteerShifts'
import { getAllAuthorizedRoles } from '@/utils/authHandlers'
import { createPageTitle } from '@/utils/page'
import { PAGE_NAME } from '@/constants/pages'

const allAuthorizedRolesAllowed = getAllAuthorizedRoles()

const DISCOVER_VOLUNTEER_EVENTS = createPageTitle(PAGE_NAME.DISCOVER_VOLUNTEER_EVENTS)
const DISCOVER_VOLUNTEER_ORGANIZATIONS = createPageTitle(PAGE_NAME.DISCOVER_VOLUNTEER_ORGANIZATIONS)
const MY_VOLUNTEER_SHIFTS = createPageTitle(PAGE_NAME.MY_VOLUNTEER_SHIFTS)

const routes: AppRoute[] = [
	{
		component: DiscoverEvents,
		title: DISCOVER_VOLUNTEER_EVENTS,
		name: DISCOVER_VOLUNTEER_EVENTS,
		path: PATHS.APP.VOLUNTEERS_EVENTS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
	{
		component: DiscoverOrganizations,
		title: DISCOVER_VOLUNTEER_ORGANIZATIONS,
		name: DISCOVER_VOLUNTEER_ORGANIZATIONS,
		path: PATHS.APP.VOLUNTEERS_ORGANIZATIONS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},

	{
		component: YourVolunteerShifts,
		title: MY_VOLUNTEER_SHIFTS,
		name: MY_VOLUNTEER_SHIFTS,
		path: PATHS.APP.VOLUNTEERS_SHIFTS,
		exact: false,
		isPrivate: true,
		allowedRoles: allAuthorizedRolesAllowed,
	},
]

export default routes

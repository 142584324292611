import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles(({ palette: { primary, text, specialColors } }) => ({
	root: (props: { checked: boolean }) => ({
		minHeight: 50,
		marginTop: 5,
		marginBottom: 5,
		backgroundColor: props.checked ? primary.light : specialColors.transparent,
		borderRadius: 5,
		border: 'none',
		color: props.checked ? primary.main : text.primary,
		display: 'flex',
		justifyContent: 'flex-start',
		'&:hover': {
			color: primary.main,
			backgroundColor: props.checked ? primary.light : specialColors.transparent,
		},
		fontSize: '16px',
	}),
	contentContainer: (props: { checked: boolean }) => ({
		display: 'flex',
		flexDirection: 'row',
		textTransform: 'none',
		textAlign: 'left',
		width: '100%',
		fontWeight: props.checked ? 600 : 400,
		color: !props.checked ? text.primary : 'inherit',
	}),
	icon: {
		alignItems: 'center',
		display: 'flex',
		marginRight: '5%',
		height: 'fit-content',
	},
	text: {
		alignSelf: 'center',
	},
	infoText: ({ checked }: any) => ({
		color: checked ? primary.main : text.secondary,
	}),
}))

export interface FilterNavProps {
	className?: string
	icon: React.ReactNode
	label: string
	path: string
	onClick?: () => void
	info?: string[]
}

const FilterNav = ({ className = '', icon = null, label = '', path = '', onClick = () => {}, info = [] }: FilterNavProps) => {
	const { pathname } = useLocation()
	const { push } = useHistory()

	const condition = `${path}[/A-Z0-9]*$`
	const checked = !!pathname.match(condition)

	const classes = useStyles({ checked })

	const navInfo = info.join(' | ')

	const handleOnClick = () => {
		push(path)
		onClick()
	}

	return (
		<Button className={classNames(classes.root, className)} onClick={handleOnClick}>
			<div className={classes.contentContainer}>
				<div className={classes.icon}>{icon}</div>
				<div className={classes.text}>
					<Typography>{label}</Typography>
					<Typography className={classes.infoText}>{navInfo}</Typography>
				</div>
			</div>
		</Button>
	)
}

export default FilterNav

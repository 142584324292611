import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

interface StyledProps {
	overflow?: string
}

const useModalStyles = makeStyles<Theme, StyledProps>((theme) => ({
	modal: ({ overflow = 'auto' }) => ({
		display: 'flex',
		minWidth: 'min-content',
		alignItems: 'center',
		justifyContent: 'center',
		overflow,
	}),
	paper: {
		maxWidth: '90vw',
		maxHeight: '90vh',
		padding: theme.spacing(2),
		overflow: 'auto',
	},
}))

interface IModalProps extends StyledProps, Omit<DialogProps, 'open'> {
	isOpen: boolean
	onClose?: (e?: React.SyntheticEvent | Event) => void
}

export const Modal = ({ isOpen = false, onClose = () => {}, children, ...styledProps }: IModalProps) => {
	const classes = useModalStyles(styledProps)

	return (
		<Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.paper }} className={classes.modal}>
			{children}
		</Dialog>
	)
}

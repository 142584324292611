import classNames from 'classnames'
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from '@/store'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import DotButton from '@/components/Buttons/DotButton'
import MoreButton from '@/components/Buttons/MoreButton'
import ItemButton from '@/components/Buttons/ToggleButton'
import CardIconContainer from '@/components/ExperienceCards/CardIconContainer'
import FeedsCardContainer from '@/components/ExperienceCards/FeedsCard/FeedsCardContainer'
import CannotUnfollowPopper from '@/components/IndividualPage/CannotUnfollowPopper'
import { ReportSubject } from '@/components/ReportModal'
import { PATHS, STYLES } from '@/constants'
import { ReportActions, UserResponseStatuses } from '@/interfaces/common'
import { getUserOrganizationRoles } from '@/store/auth'
import { getLeaveFromOrganizationModal, setLeaveFromOrganizationModal, setOrganizationResponseRequest } from '@/store/organizations'
import { getStringCategories, hasVolunteerCategory } from '@/utils/events'
import { getMaxMembershipPermissionLevel, isAdmin, isSuperAdmin } from '@/utils/organizationRoles'
import { makeStyles } from '@material-ui/core/styles'
import { CancelOutlined } from '@material-ui/icons'
import { ButtonColors } from '@/components/Buttons/types'

import { ICON_SIZES } from '@/constants/iconSizes'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useGlobalStyles from '@/styles/globalStyles'
import { FEATURE } from '@navengage/sen-shared-assets'
import { AdminBadge } from '../Badges/AdminBadge'
import IconWrapper from '../IconWrapper'
import ItemCardContainer from './ItemCardContainer'

const useStyles = makeStyles((theme) => ({
	footerFullWidthButton: {
		height: 50,
		width: 'calc(100% - 60px)',
		fontWeight: 600,
	},
	responseBtn: {
		height: 50,
		width: 'calc(100% - 46% - 60px)',
	},
	roleBtn: {
		height: 50,
		width: 'calc(100% - 37% - 60px)',
	},
	contentContainer: ({ descriptionClamp }: any) => ({
		marginTop: 0,
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': descriptionClamp,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
			margin: 0,
		},
	}),
	title: {
		'& > p': {
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			overflowY: 'hidden',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			wordBreak: 'break-word',
		},
	},
	headerButtonsContainer: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 'calc(100% - 30px)',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
	},

	outlinedButton: {
		color: theme.palette.text.primary,
	},
}))

const getOrgDescriptionLineClamp = (title: string, categories: any[], variant: string) => {
	if (variant === 'secondary') return 2

	const titleLength = +(title.length < 33)
	const categoriesLength = +(getStringCategories(categories).length < 3)

	return 2 + titleLength + categoriesLength
}

interface OrganizationCardProps {
	photoUrl: string
	title: string
	id: string
	categories: any[]
	description?: string
	followed?: boolean
	variant?: 'primary' | 'secondary'
	showActionButtons?: boolean
	showBadge?: boolean
	canEdit?: boolean
}

const OrganizationCard = ({
	photoUrl = '',
	title = '',
	categories = [],
	id,
	followed = false,
	description = '',
	variant = 'primary',
	showActionButtons = true,
	showBadge = false,
	canEdit,
}: OrganizationCardProps) => {
	const classes = useStyles({
		descriptionClamp: getOrgDescriptionLineClamp(title, categories, variant),
	})
	const globalClasses = useGlobalStyles()
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const userRoles = useSelector(getUserOrganizationRoles(id))

	const leaveOrganizationModalState = useSelector(getLeaveFromOrganizationModal)

	const superAdminPermissions = isSuperAdmin(userRoles)
	const moderatorPermissions = isAdmin(userRoles)

	const isAdminPermissions = canEdit || superAdminPermissions || moderatorPermissions

	const userPermissionsLevel = getMaxMembershipPermissionLevel(userRoles)

	const subTitle = getStringCategories(categories)
	const sharingUrl = PATHS.APP.ORGANIZATIONS_SINGLE(id)

	const [unfollowAnchorEl, setUnfollowAnchorEl] = useState<any>(null)

	const handleToggleUnfollowPopper = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation()
		if (unfollowAnchorEl) {
			setUnfollowAnchorEl(null)
		} else {
			setUnfollowAnchorEl(event.currentTarget)
		}
	}

	const handleCloseUnfollowPopper = () => {
		setUnfollowAnchorEl(null)
	}

	const handleFollowClick = useCallback(
		(e: React.SyntheticEvent) => {
			e.stopPropagation()
			dispatch(
				setOrganizationResponseRequest({
					id,
					status: followed ? UserResponseStatuses.cleared : UserResponseStatuses.followed,
				}),
			)
		},
		[dispatch, followed, id],
	)

	const handleReportOrganization = useCallback(
		(id: string, status: ReportActions, reportMessage: string) => {
			dispatch(setOrganizationResponseRequest({ id, status, reportMessage }))
		},
		[dispatch],
	)

	const hasVolunteerCategories = hasVolunteerCategory(categories)

	const handleClickCard = useCallback(() => {
		push(PATHS.APP.ORGANIZATIONS_SINGLE(id))
	}, [id, push])

	const handleToggleLeaveFromOrganizationModal = useCallback(
		(e: any) => {
			e.stopPropagation()

			dispatch(
				setLeaveFromOrganizationModal({
					isOpen: !leaveOrganizationModalState.isOpen,
					orgId: id,
				}),
			)
		},
		[dispatch, id],
	)

	const roleButtons = useMemo(
		() => [
			{
				label: 'Leave organization',
				onClick: handleToggleLeaveFromOrganizationModal,
				icon: <CancelOutlined />,
			},
		],
		[],
	)

	const handleRenderCardHeader = useCallback(
		() => (
			<div className={classes.headerContainer}>
				<div className={classes.headerButtonsContainer}>
					<div className={globalClasses.alignCenter}>
						{showBadge ? (
							<>
								<CardIconContainer>
									<IconWrapper iconKey={FEATURE.organizations} size={ICON_SIZES.md} weight="fill" aria-label="Organization" />
								</CardIconContainer>
								{hasVolunteerCategories && (
									<CardIconContainer>
										<IconWrapper iconKey={FEATURE.volunteers} weight="fill" aria-label="Volunteer" />
									</CardIconContainer>
								)}
							</>
						) : null}

						{isAdminPermissions && <AdminBadge />}
					</div>
					{showActionButtons && (
						<DotButton itemId={id} onSubmitReport={handleReportOrganization} reportSubject={ReportSubject.Organization} />
					)}
				</div>
			</div>
		),
		[handleReportOrganization, hasVolunteerCategories, id, isAdminPermissions, showActionButtons, showBadge],
	)

	const handleRenderCardContent = useCallback(() => <p>{description}</p>, [description])

	const handleRenderActionButtons = useCallback(
		() => (
			<>
				{userPermissionsLevel?.role && (
					<CannotUnfollowPopper anchorEl={unfollowAnchorEl} handleClose={handleCloseUnfollowPopper} userRole={userPermissionsLevel?.role} />
				)}
				<ItemButton
					className={userPermissionsLevel?.role ? classes.responseBtn : classes.footerFullWidthButton}
					onClick={followed && userPermissionsLevel?.role ? handleToggleUnfollowPopper : handleFollowClick}
					isActive={followed}
				>
					{followed ? 'Following' : 'Follow'}
				</ItemButton>
				{userPermissionsLevel?.role && (
					<MoreButton
						color={ButtonColors.WHITE}
						className={classNames(classes.roleBtn, classes.outlinedButton)}
						renderButtons={roleButtons}
						variant="outlined"
						hideIcon
					>
						{userPermissionsLevel?.role}
					</MoreButton>
				)}
			</>
		),
		[classes.footerFullWidthButton, classes.outlinedButton, unfollowAnchorEl, followed, userPermissionsLevel?.role, handleFollowClick],
	)

	const sharedData = useMemo(() => ({ sharedEntity: { sharedEntityId: id, sharedEntityType: EntityTypes.organization } }), [id])

	return variant === 'primary' ? (
		<ItemCardContainer
			showActionButtons={showActionButtons}
			title={title}
			subTitle={subTitle}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	) : (
		<FeedsCardContainer
			showActionButtons={showActionButtons}
			variant="secondary"
			title={title}
			subTitle={subTitle}
			photoUrl={photoUrl}
			containerClasses={{
				titleContainer: classes.title,
				contentContainer: classes.contentContainer,
			}}
			handleOnClickCard={handleClickCard}
			sharedData={sharedData}
			renderHeader={handleRenderCardHeader}
			renderContent={handleRenderCardContent}
			renderFooterButtons={handleRenderActionButtons}
			sharingUrl={sharingUrl}
		/>
	)
}

export default OrganizationCard

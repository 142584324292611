import { SESSION_TIME } from '@/constants/configuration'

import { createOptionMapper } from '@/utils/common'
import { baseApi } from '../general/baseApi'

import DtoResearchFilter from './types/dto/DtoResearchFilter'
import DtoResearch from './types/dto/DtoResearch'
import ResearchFilter from './types/ResearchFilter'
import Research from './types/Research'
import { createSelector } from '@reduxjs/toolkit'

const BASE_PATH = '/research'

export const RESEARCH_TAGS = {
	SINGLE: 'Research',
	FILTERS: 'ResearchFilters',
}

export const researchApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(RESEARCH_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getResearchFilters: builder.query<ResearchFilter, void>({
				query: () => ({
					url: `${BASE_PATH}/filters`,
				}),
				keepUnusedDataFor: SESSION_TIME,
				transformResponse: (result: DtoResearchFilter) => {
					return {
						workSettings: result.workSettings.map(createOptionMapper<number>('id', 'workSetting')),
						positionTypes: result.positionTypes.map(createOptionMapper<number>('id', 'positionType')),
						positionAreas: result.positionAreas.map(createOptionMapper<number>('id', 'researchArea')),
					}
				},
				providesTags: [RESEARCH_TAGS.FILTERS],
			}),
			getResearch: builder.query<DtoResearch, { id: Research['id'] }>({
				query: ({ id }) => ({
					url: `${BASE_PATH}/${id}`,
				}),
				keepUnusedDataFor: 600,
				providesTags: (data, error) => (!error && data ? [{ type: RESEARCH_TAGS.SINGLE, id: data.research.id }] : []),
			}),
		}),
	})

export const { useGetResearchFiltersQuery, useGetResearchQuery } = researchApi

// Selectors

export const selectResearch = () => {
	return createSelector(
		(data?: DtoResearch) => data,
		(data?: DtoResearch) => {
			if (data && data.research) {
				return { ...data.research, responseStatus: data.research.response?.response }
			} else {
				return null
			}
		},
	)
}

import ExtendFallBackImageParam from '@/anthology/types/ExtendFallBackImageParam'
import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../general/baseApi'
import DtoJob from './types/dto/DtoJob'
import GetJobParams from './types/queryParams/GetJobParams'

const BASE_PATH = '/jobs'

export const JOB_TAGS = {
	SINGLE: 'Job',
	FILTERS: 'JobFilters',
}

export const jobApi = baseApi
	.enhanceEndpoints({
		addTagTypes: Object.values(JOB_TAGS),
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getJob: builder.query<DtoJob, ExtendFallBackImageParam<GetJobParams>>({
				query: ({ id, withFallBackImage = true }) => ({
					url: `${BASE_PATH}/job/${id}`,
					params: {
						withFallBackImage,
					},
				}),

				keepUnusedDataFor: 600,
				providesTags: (data, error, { id }) => {
					return !error && data ? [{ type: JOB_TAGS.SINGLE, id: data.job.id }] : []
				},
			}),
		}),
	})

export const { useGetJobQuery } = jobApi

// Selectors

export const selectJob = () => {
	return createSelector(
		(data?: DtoJob) => data,
		(data?: DtoJob) => {
			if (data && data.job) {
				return { ...data.job, responseStatus: data.job.response?.response }
			} else {
				return null
			}
		},
	)
}

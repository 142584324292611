import { ICreatePageHeaderConfig } from '@/anthology/components/PageHeaderTabs'

export enum UserEventTypes {
	upcoming = 'upcoming',
	past = 'past',
}

export type TUserEventTypes = `${UserEventTypes}`

export const USER_EVENTS_TABS: ICreatePageHeaderConfig<TUserEventTypes> = {
	upcoming: {
		label: 'Upcoming',
		order: 0,
		placeholder: {
			title: 'No upcoming events.',
			description: ['Your schedule is clear!'],
		},
	},
	past: {
		label: 'Past',
		order: 1,
		placeholder: {
			title: 'No past events.',
			description: ['Events you’ve RSVP’d to will', 'appear here once they’ve happened.'],
		},
	},
}

import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { PATHS, STYLES } from '@/constants'
import { Typography, makeStyles } from '@material-ui/core'
import { FEATURE } from '@navengage/sen-shared-assets'
import { push } from 'connected-react-router'
import { useMemo } from 'react'

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		margin: '15px 0',
	},
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.background.default,
	},
}))

const EmptyPlaceholder = () => {
	const classes = useStyles()
	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Make Connections',
				onClick: () => {
					push(PATHS.APP.NETWORK_SEARCH)
				},
			},
		],
		[],
	)

	return (
		<div className={classes.container}>
			<NotFoundPlaceholder iconKey={FEATURE.organizations} isEmptyPlaceholder buttons={placeholderButtons}>
				<Typography align="center" variant={'body1'}>
					Connect with others to
				</Typography>
				<Typography align="center">populate your network post!</Typography>
			</NotFoundPlaceholder>
		</div>
	)
}

export default EmptyPlaceholder

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { STYLES } from '@/constants'

const useStyles = makeStyles((theme) => ({
	card: ({ withMargin, isInteractive }: any) => ({
		'&:first-child': {
			marginTop: withMargin ? '5vh' : '',
		},
		'&:last-child': {
			marginBottom: withMargin ? `${STYLES.LANDING_FOOTER_HEIGHT}vh` : '',
		},
		cursor: isInteractive ? 'pointer' : '',
		width: '53vw',
		padding: '3.5vh 2.5vw',
		marginTop: '2vh',
		backgroundColor: theme.palette.background.paper,
		borderRadius: 5,
		[theme.breakpoints.down('sm')]: {
			width: '90vw',
		},
	}),
	title: {
		fontWeight: 700,
	},
	aboutCardTitleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))

interface CardProps {
	title?: string
	titleRightComponent?: React.ReactNode
	children: React.ReactNode
	className?: string
	withMargin?: boolean
	onClick?: () => void
}

export const Card = ({ className = '', title = '', children, titleRightComponent = null, withMargin = true, onClick }: CardProps) => {
	const classes = useStyles({ withMargin, isInteractive: !!onClick })

	return (
		<div className={classNames(classes.card, className)} onClick={onClick}>
			{title && (
				<div className={classes.aboutCardTitleContainer}>
					<Typography className={classes.title} variant="h3">
						{title}
					</Typography>
					{titleRightComponent}
				</div>
			)}
			{children}
		</div>
	)
}

export default Card

import { makeStyles } from '@material-ui/core'

const useCommonStyles = makeStyles((theme) => ({
	verticalGap: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	link: {
		color: theme.palette.primary.main,
		fontWeight: 500,
	},
}))

export default useCommonStyles

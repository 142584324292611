import { UserAudience } from '@/store/feeds/types'

type TFeedTabOption = { label: string; order: number; disabled?: boolean; description?: string }

type TFeedTabConfig = Record<UserAudience, TFeedTabOption>

export const FILTER_CONFIG = {
	includeAllCampuses: {
		enabled: true,
		label: 'Include posts shared to all campuses',
		defaultValue: true,
	},
	tags: {
		enabled: true,
		defaultValue: [],
	},
	usersAudience: {
		enabled: true,
		defaultValue: UserAudience.COMMUNITY,
	},
	campusId: {
		enabled: true,
		label: 'Choose campus',
		description: 'See posts created for a specific %schoolName% campus',
		defaultValue: null,
	},
	audienceScope: {
		enabled: false,
		label: 'Choose audience',
		description: 'See posts created for a specific group of users.',
	},
} as const

export type TFeedFilterKeys = keyof typeof FILTER_CONFIG

export const FEED_TABS: TFeedTabConfig = {
	[UserAudience.COMMUNITY]: {
		label: 'Community',
		description: 'See what’s new with the community',
		order: 0,
	},
	[UserAudience.NETWORK]: {
		label: 'My Network',
		description: 'See what’s new with your network',
		order: 1,
	},
	[UserAudience.FOLLOWERS]: {
		label: 'Following',
		description: 'See what’s new with your following organizations',
		order: 2,
		disabled: true,
	},
} as const

export const feedsTabInfo: Array<TFeedTabOption & { value: UserAudience }> = Object.keys(FEED_TABS)
	.map((key) => {
		return { ...FEED_TABS[key], value: +key! }
	})
	.sort((a, b) => a.order - b.order)
	.filter((t) => !t.disabled)

export const feedPlainTabs = feedsTabInfo.map((t) => t.label)

export const SCHEDULING_IS_ENABLED = true

export const UI_STATES_ENABLED = true

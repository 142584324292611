import { SvgIcon } from '@material-ui/core'

const HouseOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 24 24" {...props}>
			<g
				data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 5174"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
			>
				<path data-name="\u041B\u0438\u043D\u0438\u044F 153" d="M.706 9.752v10.955" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 154" d="M8.417 20.706H.706" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 155" d="M8.417 12.662v8.045" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 156" d="M8.417 12.662h4.406" />
				<path data-name="\u041A\u043E\u043D\u0442\u0443\u0440 402" d="M12.824 12.662v8.045" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 157" d="M20.535 20.706h-7.711" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 158" d="M20.535 9.752v10.955" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 159" d="M10.62.707L.706 9.645" />
				<path data-name="\u041B\u0438\u043D\u0438\u044F 160" d="M10.62.707l9.914 8.938" />
			</g>
		</SvgIcon>
	)
}

export default HouseOutlinedIcon

import { SESSION_TIME } from '@/constants/configuration'
import { baseApi } from './baseApi'

export const analyticsApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getSignupProcessMeta: builder.query({
			query: ({ entityType }) => ({
				url: `/signup-dashboard/grid/meta/${entityType}`,
				method: 'GET',
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetSignupProcessMetaQuery } = analyticsApi

import { SvgIcon } from '@material-ui/core'

const ClockOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 22 22" width="1em" height="1em" {...props}>
			<g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 4378" fill="none" stroke="currentColor">
				<g data-name="\u042D\u043B\u043B\u0438\u043F\u0441 453">
					<circle cx={9} cy={9} r={9} stroke="none" />
					<circle cx={9} cy={9} r={8.5} />
				</g>
				<g data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 4201" strokeLinecap="round">
					<path data-name="\u041B\u0438\u043D\u0438\u044F 343" d="M7.857 4.091v5.056" />
					<path data-name="\u041B\u0438\u043D\u0438\u044F 344" d="M11.074 10.986L7.857 9.147" />
				</g>
			</g>
		</SvgIcon>
	)
}

export default ClockOutlinedIcon

import http from '@/api/http/requestHttp'
import { EventInfo } from '@/store/events/types'

const patchEvent = async (userId: number, eventInfo: EventInfo, schoolId: number) => {
	const url = `${schoolId}/events/editEvent`
	const body = { userId, eventInfo }

	return http.patch(url, body)
}

export default patchEvent

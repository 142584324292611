import { UserData } from '@/interfaces/common'
import React from 'react'
import NetworkUserRow from '../NetworkUserRow'
import { ShortResponsesList } from './ShortResponsesList'

// @TODO: rename this response list later on and pass response type, so list will be dynamic based on response type
// right now backend doesn't support that endpoint
export type IInterestedListProps = {
	users: UserData[]
	currentUserHasResponse?: boolean
	short?: boolean
}

const InterestedList: React.FC<IInterestedListProps> = ({ users, short, currentUserHasResponse }) => {
	return (
		<div>
			{users.length > 0 ? (
				short ? (
					<ShortResponsesList currentUserHasResponse={currentUserHasResponse} users={users} response="interested" />
				) : (
					users.map((user) => <NetworkUserRow user={user} key={user.externalId} />)
				)
			) : (
				'No Users'
			)}
		</div>
	)
}

export { InterestedList }

import { useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { loadEventsListWithFilterRequest, openCreateEventPage } from '@/store/events/actions'
import { getEvents, getIsLoading, getEndHasBeenReached, getFilterIsReady } from '@/store/events'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'

import { STYLES } from '@/constants'
import { Typography } from '@material-ui/core'
import EventCard from '@/components/ExperienceCards/EventCard'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import { submitFeedback } from '@/utils/services'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const DiscoverEvents = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const events = useSelector(getEvents)
	const isLoading = useSelector(getIsLoading)
	const filterIsReady = useSelector(getFilterIsReady)
	const endHasBeenReached = useSelector(getEndHasBeenReached)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) dispatch(loadEventsListWithFilterRequest({ fetchMore: true }))
	}, [dispatch, filterIsReady])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'common',
				label: 'Create Event',
				onClick: () => {
					dispatch(openCreateEventPage({}))
				},
			},
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[dispatch],
	)
	const renderItem = useCallback(
		(items: any) => (
			<>
				{items.map(
					({
						name,
						startDate,
						endDate,
						photoUrl,
						locationName,
						id,
						description,
						categories,
						response,
						organization,
						volunteerShifts,
						submittedByUserId,
					}: any) => (
						<EventCard
							key={`${id}-${name}-${startDate}`}
							id={id}
							title={name}
							photoUrl={photoUrl}
							startDate={startDate}
							endDate={endDate}
							categories={categories}
							description={description}
							organization={organization}
							locationName={locationName}
							eventResponse={response}
							volunteerShifts={volunteerShifts}
							submittedByUserId={submittedByUserId}
						/>
					),
				)}
			</>
		),
		[],
	)
	const showPlaceholder = !canLoadMore && !isLoading && !events.length

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="Discover Events" />
			{showPlaceholder ? (
				<NotFoundPlaceholder iconKey={FEATURE.events} buttons={placeholderButtons}>
					<Typography align="center">Try expanding your search, or add an event yourself.</Typography>
				</NotFoundPlaceholder>
			) : (
				<ExperienceCardsSection
					placeholder="That’s all for now. Check back soon for more events!"
					handleLoadMoreItems={handleLoadMore}
					items={events}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={renderItem}
				/>
			)}
		</div>
	)
}

export default DiscoverEvents

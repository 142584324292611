import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

import classNames from 'classnames'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme) => ({
	textInput: {
		borderRadius: 7,
		width: '70%',
		margin: '4vh 0',
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.border.bold,
			},
			'& fieldset': {
				borderColor: theme.palette.specialColors.transparent,
				borderRadius: 7,
			},
			'&:hover fieldset': {
				borderColor: theme.palette.specialColors.transparent,
			},
		},
		backgroundColor: theme.palette.background.default,
	},
	inputRoot: {
		caretColor: theme.palette.primary.dark,
	},
}))

const OutlinedInput = forwardRef<HTMLDivElement | null, TextFieldProps & { placeholderColor?: string }>(
	({ placeholderColor, ...rest }, ref) => {
		const classes = useStyles({ placeholderColor })

		return (
			<TextField
				ref={ref}
				{...rest}
				className={classNames(classes.textInput, rest.className)}
				variant="outlined"
				InputProps={{
					...rest.InputProps,
					classes: { input: classes.inputRoot },
				}}
			/>
		)
	},
)

export default OutlinedInput

import { SvgIcon } from '@material-ui/core'

const ResearchOutlinedIcon = (props: any) => {
	return (
		<SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
			<path d="M4 0v48h24c.36.004.695-.184.879-.496a1.01 1.01 0 000-1.008c-.184-.312-.52-.5-.879-.496H6V2h18v14h14v7c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-8.406l-.281-.313-14-14L25.406 0zm22 3.438L36.563 14H26zM36 26c-5.512 0-10 4.488-10 10s4.488 10 10 10c2.395 0 4.59-.84 6.313-2.25l5.968 5.969 1.438-1.438-5.969-5.968A9.922 9.922 0 0046 36c0-5.512-4.488-10-10-10zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8z" />
		</SvgIcon>
	)
}

export default ResearchOutlinedIcon

import http from '@/api/http/requestHttp'
import settings from '@/constants/http'
import { DtoGroupListItem } from '@navengage/sen-types'

interface SearchParams {
	q?: string
	page: number
	campusId?: number | null
	schoolId: number
}

const searchGroups = async ({ q, page, campusId, schoolId }: SearchParams) => {
	const url = `${schoolId}/groups`

	const params = {
		q,
		page,
		campusId,
		limit: settings.GROUPS_PER_PAGE,
	}

	return http.get<DtoGroupListItem[]>(url, { params })
}

export default searchGroups

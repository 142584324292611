import { forwardRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	tab: ({ centered }: any) => ({
		textTransform: 'none',
		minWidth: 'fit-content',
		'& >span': {
			minWidth: 'fit-content',
			width: 'fit-content !important',
		},
		width: 'fit-content',
		height: '100%',
		margin: '0 1vw',
		marginLeft: centered ? '1vw' : 0,
		overflow: 'visible',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.85em',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.74em',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '0.6em',
		},
		'& span': {
			width: 'min-content',
		},
	}),
	selectedTab: {
		color: theme.palette.primary.main,
		opacity: 1,
		fontWeight: 600,
	},
	unselectedTab: {
		color: theme.palette.text.primary,
		opacity: 1,
	},
	tabWrapper: {
		marginTop: '1vh',
	},
}))

interface NavigationTabProps {
	className?: string
	tabButtonClassName?: string
	key: string
	label?: string
	hideIndicator: boolean
	activeTabIndex: number
	icon?: React.ReactElement
	activeIcon?: React.ReactElement
	onClick?: (index: number, e: any) => void
	centered: boolean
	index: number
	onChangeTab?: (newValue: number) => void
}

export const NavigationTab = forwardRef<HTMLDivElement | null, NavigationTabProps>(
	(
		{
			className = '',
			tabButtonClassName = '',
			key,
			label,
			activeTabIndex,
			index,
			icon,
			activeIcon,
			hideIndicator,
			onClick = () => {},
			centered,
		},
		ref,
	) => {
		const classes = useStyles({ centered })

		const tabSelected = index === activeTabIndex && !hideIndicator
		const tabIcon = !tabSelected ? icon : activeIcon ? activeIcon : icon

		const handleClick = useCallback(
			(e: any) => {
				onClick(index, e)
			},
			[index, onClick],
		)

		return (
			<Tab
				ref={ref}
				key={key}
				className={`${tabSelected ? classes.selectedTab : classes.unselectedTab}`}
				classes={{
					root: classNames(tabButtonClassName, classes.tab),
					wrapper: classNames(className, classes.tabWrapper),
				}}
				onClick={handleClick}
				wrapped={true}
				label={label}
				icon={tabIcon}
			/>
		)
	},
)

export default NavigationTab

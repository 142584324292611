import { Card, Typography, makeStyles } from '@material-ui/core'
import * as yup from 'yup'

import { FilledTextField } from '@/components'
import Label from '@/components/Label'
import { MOBILE_CONTAINER_WIDTH } from '@/pages/LandingPage'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { getAuthService } from '../../init/DIContainer'
import FieldErrorMessage from '../FieldErrorMessage'
import LoginButton from './LoginButton'

const useStyles = makeStyles((theme) => ({
	card: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'left',
		width: '54vw',
		padding: '4rem 0',
		margin: '0 auto',
		'@media only screen and (max-width: 767px)': {
			width: '100vw',
			bottom: 0,
			padding: '3rem 2rem 1.2rem',
		},
		[theme.breakpoints.down('xs')]: {
			margin: `0 -${(100 - MOBILE_CONTAINER_WIDTH) / 2}vw`,
		},
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		width: '64%',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			padding: '0 2rem',
		},
	},
	title: () => ({
		fontWeight: 700,
		lineHeight: '1.3',
		color: theme.palette.common.black,
	}),
	subTitle: {
		color: theme.palette.grey[800],
	},
	label: {
		paddingTop: '2rem',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'left',
		alignItems: 'flex-start',
		width: '100%',
	},
	textInput: {
		alignItems: 'center',
		backgroundColor: theme.palette.grey[200],
		color: theme.palette.grey[800],
		width: '100%',
		height: 'fit-content',
		textAlign: 'left',
		marginTop: '1rem',
		'& .MuiFilledInput-root': {
			backgroundColor: theme.palette.grey[200],
			color: theme.palette.grey[800],
			width: '100%',
		},
		'& .MuiInputBase-root.MuiOutlinedInput-root': {
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	errorText: {
		marginBottom: 20,
	},
	form: {
		width: '100%',
	},
}))

interface ILoginForm {
	email: string
}

const schema = yup.object().shape({
	email: yup.string().email().label('School Email').required(),
})

const LoginBox = () => {
	const classes = useStyles()
	const authService = getAuthService()

	const {
		control,
		handleSubmit,
		formState: { isValid, errors },
	} = useForm<ILoginForm>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		reValidateMode: 'onChange',
		criteriaMode: 'firstError',
		shouldFocusError: true,
	})

	const onFormSubmit = ({ email }: ILoginForm) => {
		authService.authorize({ email })
	}
	return (
		<Card className={classes.card}>
			<div className={classes.contentContainer}>
				<Typography className={classes.title} variant={'h2'}>
					Welcome! 👋
				</Typography>
				<Typography variant={'body2'}>Log in with your school email to continue.</Typography>
				<form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
					<Controller
						control={control}
						name="email"
						render={({ field }) => (
							<Label classnames={{ root: classes.label }} title="School Email">
								<FilledTextField variant="secondary" className={classes.textInput} placeholder="user@myschool.edu" {...field} />
								<FieldErrorMessage hasError={!!errors['email']} message={errors['email']?.message} />
							</Label>
						)}
					/>

					<LoginButton disabled={!isValid} type="submit" />
				</form>
			</div>
		</Card>
	)
}

export default LoginBox

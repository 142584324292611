import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'

const useStyles = makeStyles((theme) => ({
	card: {
		paddingBottom: '1vh',
	},
	loader: {
		width: 20,
		height: 20,
	},
}))

const RedirectPage = () => {
	const classes = useStyles()

	return (
		<CardLayout>
			<Card className={classes.card}>
				<CircularProgress className={classes.loader} />
			</Card>
		</CardLayout>
	)
}

export default RedirectPage

export const emitTypes = {
	created: 'created',
	updated: 'updated',
	deleted: 'deleted',
}

export const entityTypes = {
	post: 'post',
	comment: 'comment',
}

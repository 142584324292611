import http from '@/api/http/requestHttp'
import DtoUserGoals from './DtoUserGoals'
import { TResourceEmail } from '@/pages/PathBuilderPage/LastStepPage'

export interface NewGoalsReqBody {
	typesOfEvents?: string
	wouldLikeToJoinANewStudentOrganization?: boolean
	numberOfOrganizationsToJoin?: string
	sizeOfOrganizationWouldLikeToJoin?: string
	descriptionOfOrganizationWouldLikeToJoin?: string
	organizationsWouldLikeToJoin?: string
	wouldLikeToVolunteer?: boolean
	causesWouldLikeToVolunteerFor?: string
	numberOfHoursWouldLikeToVolunteer?: string
	whenWouldLikeToVolunteer?: string
	interestedInJoiningAStudentServiceOrganization?: boolean
	wouldLikeToStudyAbroad?: boolean
	wouldLikeToParticipateIn?: string
	countriesWhereWouldLikeToStudyAbroad?: string
	whileAbroadWouldLikeTo?: string
	whenWouldLikeToStudyAbroad?: string
	wouldLikeToCompleteUndergraduateResearch?: boolean
	areasOfResearchOrCreativeInquiryInterestedIn?: string
	whenWouldLikeToCompleteResearch?: string
	currentlyHaveAJob?: boolean
	whenLookingForNextJobOrInternship?: string
	typeOfJobOrInternshipWouldLikeToComplete?: string
	professionalGoalsForAfterGraduation?: string
	interestedInTheseServices?: string
	currentlyLookingForAJob?: boolean
	typeOfJobCurrentlyLookingFor?: string
	experiencesWouldLikeToHaveOutsideOfClassroom?: string
	emailsAboutGrantProgramOffers?: boolean
	resourceEmails?: Array<Record<TResourceEmail, boolean>>
}

export interface NewGoalsResponse extends NewGoalsReqBody {
	createdAt: string
}

const postNewGoals = async (userId: number, data: NewGoalsReqBody, schoolId: number) => {
	const url = `${schoolId}/path-builder/${userId}/goals`

	return http.post<DtoUserGoals>(url, data)
}

export default postNewGoals

import { OrgMembershipRequestStatus } from '@/api/http/organizations/patchOrganizationMembershipRequest'
import IconButton from '@/components/Buttons/IconButton'
import Card from '@/components/IndividualPage/Card'
import MembershipInfoRow from '@/components/IndividualPage/MembershipInfoRow'
import ItemsSectionWithButton from '@/components/ItemsSections/ItemsSectionWithButton'
import { useAppDispatch } from '@/store'
import { setViewedOrganizationRequestsRequest } from '@/store/organizations'
import { OrgMembershipRequest } from '@/store/organizations/types'
import { getOrganizationRole, orgTypes } from '@/utils/organizationRoles'
import { IconButton as MaterialIconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ColorButton from '@/components/Buttons/ColorButton'
import { ButtonColors } from '@/components/Buttons/types'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	expandIcon: {
		color: theme.palette.text.secondary,
	},
	iconButton: {
		padding: 5,
	},
	title: {
		display: 'flex',
		alignItems: 'center',
	},
	numberOfRequests: {
		position: 'relative',
		borderRadius: '50%',
		border: `solid 1px ${theme.palette.divider}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 36,
		minHeight: 36,
		width: 36,
		height: 36,
		marginLeft: 10,
	},
	numberOfRequestsText: {
		fontWeight: 550,
	},
	greyText: {
		color: theme.palette.text.secondary,
		margin: 5,
	},
	// @TODO: copy pasted in several places component can be created or mui badge can be used
	notificationDot: {
		width: 10,
		height: 10,
		backgroundColor: theme.palette.icons.notificationDot,
		border: `solid 1px ${theme.palette.common.white}`,
		borderRadius: '50%',
		position: 'absolute',
		top: 0,
		right: 0,
	},
	buttonsContainerRoot: {
		width: '75%',
		height: '100%',
	},
	buttonsContainer: {
		width: 'calc(100% - 58px)',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginLeft: 58,
	},
	requestButton: {
		height: 40,
		width: 148,
		marginLeft: 10,
	},
	requestText: {
		fontWeight: 450,
	},
	requestPositionText: {
		fontSize: 15,
	},
	requestButtons: {
		display: 'flex',
	},
	cardContainer: {
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		gridGap: 0,
		margin: 0,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	acceptedButton: {
		width: 296,
		height: 40,
		marginLeft: 10,
		backgroundColor: theme.palette.primary.light,
		'&>span': {
			color: theme.palette.primary.main,
			textTransform: 'capitalize',
			'&>span': {
				position: 'absolute',
				right: 25,
			},
		},
	},
	rejectedButton: {
		width: 296,
		height: 40,
		marginLeft: 10,
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
		'&>span': {
			color: theme.palette.text.secondary,
			textTransform: 'capitalize',
			'&>span': {
				position: 'absolute',
				right: 25,
			},
		},
	},
	userName: {
		marginRight: 15,
		fontWeight: 450,
	},
	rowTitleContainer: {
		width: 'fit-content',
	},
	additionalInfo: {
		color: theme.palette.text.primary,
	},
	userRow: {
		alignItems: 'flex-start',
	},
}))

const getRequestLabel = (newTypeId: orgTypes, oldTypeId?: orgTypes | null) => {
	const getPrefix = (typeId: orgTypes) => ([2].includes(typeId) ? 'an' : 'a')

	const prefix = getPrefix(newTypeId)
	const newRole = getOrganizationRole(newTypeId)

	if (!oldTypeId) {
		return `Requests to be added as ${prefix} ${newRole}`
	} else if (newTypeId < oldTypeId) {
		return `Requests to be added as ${prefix} ${newRole}`
	}

	return `Requests to be removed as ${getPrefix(oldTypeId)} ${getOrganizationRole(oldTypeId)}`
}

export enum RequestType {
	CHANGE_POSITION = 'manage-position-requests',
	JOIN = 'manage-membership-requests',
}

interface OrganizationManageRequestsCardProps {
	requestType: RequestType
	title: string
	requests: OrgMembershipRequest[]
	totalRequests: number
	unseenRequestsCount: number
	isLoading: boolean
	onChangeStatus: (requestId: number, status: OrgMembershipRequestStatus) => void
	onLoadMoreItems: (newPage: number) => void
	description?: string
	currentPage: number
}

const OrganizationManageRequestsCard = ({
	requestType,
	title,
	requests,
	totalRequests,
	unseenRequestsCount,
	isLoading,
	onChangeStatus,
	onLoadMoreItems,
	description,
	currentPage,
}: OrganizationManageRequestsCardProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()

	const [{ expandMore, canLoadMore }, setState] = useState({
		expandMore: false,
		canLoadMore: true,
	})

	const handleToggleExpandMore = () =>
		setState((prev) => ({
			...prev,
			expandMore: !prev.expandMore,
		}))

	const handleLoadMoreItems = () => {
		const newPage = currentPage + 1

		onLoadMoreItems(newPage)
	}

	const handleChangeStatus = (requestId: number, status: OrgMembershipRequestStatus) => () => onChangeStatus(requestId, status)

	useEffect(() => {
		const endHasBeenReached = requests.length >= totalRequests

		setState((prev) => ({
			...prev,
			canLoadMore: !endHasBeenReached,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requests.length])

	useEffect(() => {
		if (expandMore) {
			dispatch(setViewedOrganizationRequestsRequest({ requests }))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expandMore])

	const showDot = unseenRequestsCount > 0

	return (
		<Card id={requestType}>
			<div className={classes.titleContainer}>
				<div className={classes.title}>
					<Typography variant="body1">
						<b>{title}</b>
					</Typography>
					<div className={classes.numberOfRequests}>
						{showDot && <div className={classes.notificationDot} />}
						<Typography className={classes.numberOfRequestsText} variant="subtitle1">
							{totalRequests}
						</Typography>
					</div>
				</div>
				{!!requests.length && (
					<MaterialIconButton className={classes.iconButton} onClick={handleToggleExpandMore}>
						{!expandMore ? <ExpandMore className={classes.expandIcon} /> : <ExpandLess className={classes.expandIcon} />}
					</MaterialIconButton>
				)}
			</div>
			{expandMore && requests.length ? (
				<div>
					{description && <Typography className={classes.greyText}>{description}</Typography>}
					<ItemsSectionWithButton
						classnames={{
							content: classes.cardContainer,
						}}
						handleLoadMoreItems={handleLoadMoreItems}
						items={requests}
						isLoading={isLoading}
						canLoadMore={canLoadMore}
						renderItems={(items: OrgMembershipRequest[]) =>
							items.map((request) => (
								<MembershipInfoRow
									classnames={{
										userRow: requestType === RequestType.CHANGE_POSITION ? classes.userRow : '',
										buttonsContainer: classes.buttonsContainerRoot,
										positionText: classes.requestPositionText,
										titleContainer: classes.rowTitleContainer,
										userName: classes.userName,
										additionalInfo: classes.additionalInfo,
									}}
									key={`${requestType}-${request.id}`}
									user={request.sourceUser}
									createdAt={request.createdAt}
									membershipTitle={
										request.currentOrganizationMembershipTypeId ? getOrganizationRole(request.currentOrganizationMembershipTypeId) : ''
									}
									additionalInfo={
										requestType === RequestType.CHANGE_POSITION
											? getRequestLabel(request.organizationMembershipTypeId, request.membership?.organizationMembershipTypeId)
											: ''
									}
									buttons={
										<div className={classes.buttonsContainer}>
											<div className={classes.requestButtons}>
												{request.status === OrgMembershipRequestStatus.PENDING && (
													<>
														<ColorButton
															className={classes.requestButton}
															onClick={handleChangeStatus(request.id, OrgMembershipRequestStatus.ACCEPTED)}
														>
															Approve
														</ColorButton>
														<ColorButton
															color={ButtonColors.GREY}
															className={classes.requestButton}
															onClick={handleChangeStatus(request.id, OrgMembershipRequestStatus.REJECTED)}
														>
															Decline
														</ColorButton>
													</>
												)}
												{request.status === OrgMembershipRequestStatus.ACCEPTED && (
													<IconButton className={classes.acceptedButton} endIcon={<Check />} disabled>
														Approved
													</IconButton>
												)}
												{request.status === OrgMembershipRequestStatus.REJECTED && (
													<IconButton className={classes.rejectedButton} endIcon={<Close />} disabled>
														Declined
													</IconButton>
												)}
											</div>
										</div>
									}
									showRows
									openProfileOnClick
								/>
							))
						}
					/>
				</div>
			) : null}
		</Card>
	)
}

export default OrganizationManageRequestsCard

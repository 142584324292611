import { LoginParams } from '@/api/http/user/login'
import * as config from '@/constants/configuration'
import { SCHOOL_ID } from '@/constants/configuration'
import axios, { AxiosInstance } from 'axios'
import TypedStorageManager from '../storageManager/TypedStorageManager'
import { getQueryParamByName } from '../urlHandlers'
import { AUTH_ERRORS, TAuthError } from './errors'
import { generatePkce, getJwtPayload, isExpired } from './helper'
import OauthClient from './oauth2/OauthClient'
import DtoTokenResponse from './oauth2/dto/DtoTokenResponse'
import DtoUseInfo from './oauth2/dto/DtoUserInfo'
import AuthorizeCodeFlowParams from './oauth2/params/AuthorizeCodeFlowParams'
import authStorage from './storage/authStorage'
import IAuthStorageSchema from './types/IAuthStorageSchema'

export interface IPkceData {
	codeVerifier: string
	codeChallenge: string
}

export default class AuthService {
	private pkceData: IPkceData = null
	private oauthClient: OauthClient = null
	private readonly client: AxiosInstance

	public constructor(
		private readonly baseUrl: string,
		private readonly schoolId: number,
		private readonly isPKCE: boolean = config.isEngage(),
		private readonly tempStorage: TypedStorageManager<IPkceData> = new TypedStorageManager<IPkceData>(sessionStorage),
		private readonly persistStorage: TypedStorageManager<IAuthStorageSchema> = authStorage,
	) {
		this.oauthClient = new OauthClient(this.baseUrl)
		this.client = axios.create({ baseURL: this.baseUrl })
	}

	public async authorize({ email, schoolId }: { email?: string; schoolId?: number } = {}) {
		const redirectPath = decodeURIComponent(getQueryParamByName('redirect_path'))

		const params: AuthorizeCodeFlowParams = {
			schoolId: schoolId || SCHOOL_ID,
			email,
		}

		if (!!redirectPath) {
			params['state'] = JSON.stringify({ redirectPath })
		}
		if (this.isPKCE) {
			if (!this.tempStorage.getItem('codeChallenge')) {
				const pkceData = await generatePkce()
				this.tempStorage.setMany(pkceData)
			}

			params['codeChallenge'] = this.tempStorage.getItem('codeChallenge')
			params['codeChallengeMethod'] = 'S256'
		}

		this.oauthClient.authorizeCodeFlow(params)
	}
	public async signIn(code: string, schoolId: number) {
		const body = { code, schoolId: this.schoolId }
		const data: {
			loginData: Omit<LoginParams, 'schoolId'>
			error: TAuthError
		} = {
			loginData: null,
			error: null,
		}

		if (this.isPKCE && this.tempStorage.getItem('codeVerifier')) {
			body['codeVerifier'] = this.tempStorage.getItem('codeVerifier')
		}
		const [tokenData, exchangeCodeError] = await this.oauthClient.exchangeCodeForToken(body)
		const { accessToken, tokenType } = tokenData
		// Clear temporary storage and sensitive info
		this.tempStorage.clear(['codeChallenge', 'codeVerifier'])

		if (!exchangeCodeError) {
			const [userInfo, userInfoError] = await this.oauthClient.userInfo({
				schoolId,
				authorization: `${tokenType} ${accessToken}`,
			})
			if (!userInfoError && userInfo) {
				const externalId = this.getExternalId(userInfo, this.schoolId)
				if (externalId) {
					data.loginData = this.shapeLoginData(userInfo, this.schoolId)
				} else {
					data.error = { ...AUTH_ERRORS.MISSING_EXTERNAL_ID, details: userInfo }
				}
			} else if (userInfoError.response.status === 404) {
				data.error = { ...AUTH_ERRORS.MISSING_ACCOUNT, details: userInfoError }
			} else {
				data.error = { ...AUTH_ERRORS.FAILED_TO_RETRIEVE_USER_INFO, details: userInfoError }
			}
		} else {
			data.error = { ...AUTH_ERRORS.FAILED_TO_EXCHANGE_CODE, details: exchangeCodeError }
		}

		if (!data.error) {
			if (config.isNavApp()) {
				this.persistStorage.setItem('schoolId', schoolId)
			}

			this.storeTokenData(tokenData)
		} else {
			this.persistStorage.clear()
		}
		return data
	}
	public async autoSignIn(): Promise<boolean> {
		const currentUserId = this.persistStorage.getItem('currentUserId')
		if (!currentUserId) {
			return false
		}

		const accessTokenExpiration = Number(this.persistStorage.getItem('accessTokenExpiration'))
		const accessToken = this.persistStorage.getItem('accessToken')
		const refreshToken = this.persistStorage.getItem('refreshToken')

		if (!isExpired(accessTokenExpiration) && accessToken) {
			return true
		} else {
			if (refreshToken) {
				const [tokenData, error] = await this.oauthClient.refreshToken({
					refreshToken,
					schoolId: this.persistStorage.getItem('schoolId'),
				})
				if (!error && accessToken) {
					this.storeTokenData(tokenData)
					return true
				}
			}
		}

		return false
	}
	public async refreshToken() {
		const response: {
			data: DtoTokenResponse
			error: TAuthError
		} = {
			data: null,
			error: null,
		}
		const refreshToken = this.persistStorage.getItem('refreshToken')
		const refreshTokenExpiration = this.persistStorage.getItem('refreshTokenExpiration')

		if (refreshToken) {
			if ((refreshTokenExpiration && !isExpired(refreshTokenExpiration)) || !refreshTokenExpiration) {
				const [tokenData, error] = await this.oauthClient.refreshToken({
					schoolId: this.schoolId,
					refreshToken,
				})
				if (error) {
					response.error = { ...AUTH_ERRORS.REFRESH_TOKEN_EXPIRED, details: error }
				}
				response.data = tokenData
				if (tokenData) {
					this.storeTokenData(tokenData)
				}
			}
		} else {
			response.error = { ...AUTH_ERRORS.REFRESH_TOKEN_EXPIRED, details: null }
		}
		return response
	}

	public setPkce(data: IPkceData) {
		this.tempStorage.setMany(data)
	}
	public getStorageData<TKey extends keyof IAuthStorageSchema>(key: TKey): IAuthStorageSchema[TKey] {
		return this.persistStorage.getItem(key)
	}
	public setStorageData<TKey extends keyof IAuthStorageSchema>(key: TKey, value: IAuthStorageSchema[TKey]) {
		this.persistStorage.setItem(key, value)
	}
	public clearStorage() {
		this.persistStorage.clear()
	}
	private getExternalId({ sub, username }: DtoUseInfo, resolvedSchoolId: number) {
		return this.schoolId !== config.PSU_SCHOOL_ID ? sub : username.slice(0, -8)
	}
	private shapeLoginData(data: DtoUseInfo, resolvedSchoolId: number): Omit<LoginParams, 'schoolId'> {
		const { email, firstName, profile, lastName, familyName, givenName } = data
		const externalId = this.getExternalId(data, resolvedSchoolId)
		return {
			email,
			firstName: firstName || givenName,
			lastName: lastName || familyName,
			externalId,
			userType: profile,
		}
	}
	private storeTokenData({ accessToken, refreshToken, tokenType }: DtoTokenResponse) {
		const jwtPayload = getJwtPayload(accessToken)
		const dataToStore: Partial<IAuthStorageSchema> = {
			accessToken,
			tokenType,
		}

		if (refreshToken) {
			dataToStore.refreshToken = refreshToken
		}

		if (jwtPayload && jwtPayload.exp) {
			dataToStore.accessTokenExpiration = jwtPayload.exp
		}

		this.persistStorage.setMany(dataToStore)
	}
}

import LinkPreview from '@/components/Feeds/LinkPreview'
import { Iff } from '@/components/Iff'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'
import { PATHS } from '@/constants'
import SharedEntity from '@/features/shareEntity/components/SharedEntity'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { useCurrentUser } from '@/hooks/userHooks'
import { Message } from '@/store/messages/types'
import { rootStyles } from '@/styles'
import { isCurrentUser } from '@/utils/authHandlers'
import { formatDateNew } from '@/utils/dateTime2'
import { makeStyles, Typography } from '@material-ui/core'
import { STYLES } from '@/constants'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import SecureImage from '../SecureImage'
import { useAppDispatch } from '@/store'
import { deleteMessageRequest } from '@/store/messages/actions'
import IconWrapper from '@/components/IconWrapper'
import Theme from '@/Theme'

const NO_OP_FUNC = () => {
	/** no op */
}

export const MessageContents = React.memo(
	({
		message,
		isHovered,
		isInModal,
		onClickLikesButton,
	}: {
		message: Message
		isHovered: boolean
		isInModal?: boolean
		onClickLikesButton?: () => void
	}) => {
		const classes = useStyles({ isHovered, isInModal })
		const dispatch = useAppDispatch()

		if (!onClickLikesButton) {
			onClickLikesButton = NO_OP_FUNC
		}

		const currentUser = useCurrentUser()

		const { message: text = '', photoUrl, sharedEntityId, sharedEntityType, linkPreviewUrl } = message

		const showCardContainer = sharedEntityId && sharedEntityType

		const senderUserId = message.sourceUser!.id!

		const isCurrentUserMessage = isCurrentUser(currentUser.id, senderUserId)

		const handleDeleteMessage = useCallback((message: Message) => () => dispatch(deleteMessageRequest(message)), [dispatch])

		return (
			<div className={classes.contentContainer}>
				<div className={classes.messageHeader}>
					<Iff
						if={!isCurrentUserMessage}
						render={() => (
							<Link to={PATHS.APP.NETWORK_USER(`${senderUserId}`)} className={classes.userNameLink}>
								<Typography className={classes.userName}>{message.sourceUser?.fullName}</Typography>
							</Link>
						)}
					/>

					<div className={classes.messageMeta}>
						<Typography className={classes.dateAndTime}>{formatDateNew(message.createdAt, 'full')}</Typography>

						<Iff
							if={message.likes}
							render={() => (
								<button className={classes.navigationArrowWrapper} onClick={onClickLikesButton}>
									Liked by
									<IconWrapper iconKey="caretRight" color={Theme.palette.text.secondary} className={classes.navigationArrow} />
								</button>
							)}
						/>

						<Iff
							if={isCurrentUserMessage && message.groupId}
							render={() => (
								<button className={classes.navigationArrowWrapper} onClick={handleDeleteMessage(message)}>
									Delete Message
									<IconWrapper iconKey="trash" color={Theme.palette.text.secondary} className={classes.navigationArrow} />
								</button>
							)}
						/>
					</div>
				</div>

				<Iff
					if={!!text}
					render={() => (
						<div className={classes.bubble}>
							<DescriptionHtml className={classes.content} description={text || ''} />
						</div>
					)}
				/>
				{showCardContainer ? (
					<div className={sharedEntityType === EntityTypes.post ? classes.sharePostContainer : classes.shareItem}>
						<SharedEntity sharedEntityId={sharedEntityId} sharedEntityType={sharedEntityType} />
					</div>
				) : null}

				<Iff
					if={linkPreviewUrl}
					render={() => (
						<div className={classes.previewLinkContainer}>
							<LinkPreview userId={currentUser.id} url={linkPreviewUrl} />
						</div>
					)}
				/>

				<Iff
					if={photoUrl}
					render={() => (
						<SecureImage containerClass={classes.imageMessageContainer} showSkeleton={true} src={photoUrl} className={classes.image} />
					)}
				/>
			</div>
		)
	},
)

interface StyleProps {
	isHovered: boolean
	isInModal?: boolean
}

const useStyles = makeStyles<typeof Theme, StyleProps>((theme) => ({
	content: {
		...theme.typography.body2,
		'& > p': {
			...theme.typography.body2,
			margin: 0,
		},
		'& a': {
			...theme.typography.body2,
			color: theme.palette.primary.main,
			textDecoration: 'underline',
		},
		overflowWrap: 'break-word',
		width: '100%',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: 'flex-start',
	},
	bubble: {
		display: 'flex',
		maxWidth: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		color: theme.palette.text.primary,
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	time: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		margin: '5px 0',
		color: theme.palette.text.secondary,
		fontSize: 15,
		textAlign: 'center',
	},
	dateAndTime: {
		color: theme.palette.text.secondary,
		fontSize: 14,
	},
	shareItem: {
		width: 344,
		margin: '20px 0',
		boxShadow: '0px 4px 8px -1px rgba(34, 60, 80, 0.2)',
		borderRadius: 7,
	},
	sharePostContainer: {
		maxWidth: 550,
		width: '32vw',
		padding: '20px 0',
		overflowY: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '70vw',
		},
	},
	previewLinkContainer: {
		width: 344,
		padding: '20px 0',
		borderRadius: 7,
	},
	image: {
		width: 344,
		margin: '20px 0',
		borderRadius: 7,
	},
	userName: {
		fontWeight: 600,
		color: theme.palette.text.secondary,
	},
	userNameLink: {
		textDecoration: 'none',
	},
	imageMessageContainer({ isInModal }) {
		return {
			position: 'relative',
			width: 344,
			minHeight: !isInModal ? 344 : 'unset',
		}
	},
	messageHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	messageMeta({ isHovered }) {
		return {
			marginRight: 30,
			display: isHovered ? 'flex' : 'none',
			justifyContent: 'center',
			alignItems: 'center',
			color: theme.palette.text.secondary,
			fontSize: 14,
			gap: 10,
		}
	},
	navigationArrowWrapper: {
		...rootStyles.buttonPlain,
	},
	navigationArrow: {
		width: 12,
		height: 12,
	},
}))

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { STYLES } from '@/constants'
import Card from '@/components/IndividualPage/Card'
import CategoryTag from '@/components/CategoryTag'
import DescriptionHtml from '@/components/IndividualPage/DescriptionHtml'

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
	},
	categories: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	rootContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '2vh',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	infoSection: {
		display: 'flex',
		flexDirection: 'row',
	},
	infoBlock: {
		width: 105,
		height: 93,
		borderRadius: 5,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.border.bold}`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 10,
		...STYLES.BOX_SHADOW,
		'&:last-child': {
			marginRight: 0,
		},
		'&>span': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	infoBlockValue: {
		fontWeight: 700,
	},
	categoryTag: {
		marginBottom: 0,
	},
	descriptionText: {
		fontSize: 18,
		'&>p': {
			margin: 0,
			fontSize: 18,
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: 16,
			'&>p': {
				margin: 0,
				fontSize: 16,
			},
		},
	},
}))

interface AboutOrganizationCardProps {
	categories?: string[]
	description?: string
	organizationStats?: React.ReactNode
}

const AboutOrganizationCard = ({ categories, description, organizationStats = null }: AboutOrganizationCardProps) => {
	const classes = useStyles()

	return (
		<Card>
			<div className={classes.rootContainer}>
				<div className={classes.headerContainer}>
					<Typography className={classes.title} variant="h3">
						About this organization
					</Typography>
					<div className={classes.categories}>
						{categories?.map((category) => (
							<CategoryTag key={category} className={classes.categoryTag} name={category} />
						))}
					</div>
				</div>
				<div className={classes.infoSection}>{organizationStats}</div>
			</div>
			{description && <DescriptionHtml className={classes.descriptionText} description={description} />}
		</Card>
	)
}

export default AboutOrganizationCard

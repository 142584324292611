import { SvgIcon } from '@material-ui/core'

const VolunteerIcon = (props: any) => {
	return (
		<SvgIcon {...props} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					id="svg_2"
					d="m506.309,318.062c-10.754,-17.418 -33.484,-23.025 -52.863,-13.048l-88.892,45.632c-7.297,-7.88 -17.719,-12.824 -29.278,-12.824l-60.391,0c-3.599,0 -4.535,-0.3 -4.735,-0.393c-0.577,-0.264 -2.111,-1.713 -3.593,-3.114l-0.519,-0.49c-48.406,-45.555 -89.334,-42.654 -135.896,-24.286c-7.681,3.031 -7.977,3.044 -15.544,3.382l-13.494,0.016l0,-3.756c0,-15.637 -12.724,-28.357 -28.363,-28.357l-44.378,0c-15.639,-0.001 -28.363,12.719 -28.363,28.356l0,140.258c0,15.636 12.724,28.357 28.363,28.357l44.379,0c15.64,0 28.363,-12.721 28.363,-28.357l0,-3.74l24.603,0c2.352,0 2.352,0 5.009,0.746c0.942,0.264 31.741,8.518 31.741,8.518c0.866,0.231 -51.826,-14.657 2.59,0.343c54.416,15 134.458,38.051 99.653,21.582c-34.805,-16.469 9.259,-11.817 -11.075,-18.243c-20.334,-6.426 -116.628,-31.208 -117.507,-31.455c-4.12,-1.156 -5.526,-1.489 -10.412,-1.489l-24.603,0l0,-92.753l13.097,0c0.15,0 0.301,-0.003 0.451,-0.01l0.839,-0.038c9.062,-0.405 11.596,-0.656 21.99,-4.757c41.652,-16.433 73.798,-18.386 114.84,20.237l0.496,0.47c6.745,6.375 10.629,8.972 22.067,8.972l60.391,0c10.988,0 19.927,8.948 19.927,19.945c0,10.982 -8.939,19.917 -19.93,19.917l-97.855,0.028c-5.523,0.002 -9.998,4.48 -9.996,10.002s4.478,9.997 9.999,9.996l0.003,0l97.852,-0.028c22.015,0 39.925,-17.906 39.925,-39.915c0,-3.278 -0.405,-6.462 -1.153,-9.512l88.543,-45.454c8.726,-4.491 20.701,-3.954 26.708,5.776c5.298,8.564 2.421,19.895 -6.705,26.368l-141.485,100.735c-29.53,19.662 -42.014,16.037 -72.117,7.304c-4.589,-1.332 -9.334,-2.709 -14.591,-4.132c-5.33,-1.439 -10.821,1.711 -12.264,7.041c-1.442,5.331 1.71,10.822 7.041,12.265c5.082,1.375 9.739,2.726 14.242,4.032c14.585,4.232 26.84,7.79 39.613,7.789c14.416,-0.001 29.491,-4.534 49.298,-17.744c0.084,-0.057 0.168,-0.114 0.251,-0.173l141.596,-100.815c17.843,-12.656 23.057,-35.523 12.132,-53.184zm-425.202,131.376c0,4.609 -3.752,8.359 -8.365,8.359l-44.379,0c-4.612,0 -8.365,-3.75 -8.365,-8.359l0,-140.258c0,-4.609 3.752,-8.359 8.365,-8.359l44.379,0c4.613,0 8.365,3.75 8.365,8.359l0,140.258z"
				/>
				<path
					id="svg_3"
					d="m152.601,172.631l110.466,106.604c1.937,1.87 4.441,2.804 6.944,2.804s5.006,-0.935 6.943,-2.803l40.209,-38.793l40.219,38.794c1.937,1.868 4.439,2.802 6.942,2.802s5.005,-0.935 6.942,-2.802l63.82,-61.559c0.087,-0.083 0.172,-0.169 0.255,-0.255c21.276,-22.06 21.273,-57.927 0,-79.948c-6.427,-6.662 -14.505,-11.093 -23.438,-12.998c5.15,-29.167 -3.635,-59.085 -24.202,-80.379c-14.558,-15.081 -34.518,-22.903 -56.231,-22.005c-20.581,0.845 -40.455,9.298 -55.958,23.799l-1.011,0.945c-1.529,1.432 -3.181,2.978 -4.473,4.105c-1.293,-1.127 -2.945,-2.673 -4.474,-4.105l-1.009,-0.944c-15.505,-14.502 -35.379,-22.955 -55.96,-23.8c-21.707,-0.891 -41.673,6.924 -56.231,22.005c-34.154,35.384 -34.152,92.934 0.003,128.286c0.077,0.084 0.16,0.166 0.244,0.247zm268.351,-21.267c13.844,14.331 13.881,37.645 0.111,52.053l-56.74,54.73l-56.74,-54.73c-13.77,-14.408 -13.732,-37.722 0.117,-52.059c12.002,-12.441 31.501,-8.868 42.67,1.57l0.595,0.559c4.164,3.911 7.76,7.289 13.347,7.289c5.576,0 9.008,-3.211 13.352,-7.276l0.599,-0.56c11.183,-10.448 30.68,-14.025 42.689,-1.576zm-254.213,-93.377c21.313,-22.079 59.059,-20.951 84.144,2.512l1,0.936c6.843,6.408 11.365,10.641 18.142,10.641s11.298,-4.233 18.142,-10.641l1.002,-0.938c25.085,-23.462 62.83,-24.588 84.144,-2.509c16.888,17.484 23.596,42.467 18.199,66.448c-9.919,1.971 -19.261,6.751 -26.9,13.89l-0.289,0.27l-0.286,-0.269c-9.703,-9.067 -22.147,-14.349 -35.038,-14.874c-13.753,-0.56 -26.43,4.419 -35.687,14.015c-21.278,22.028 -21.281,57.894 -0.005,79.954c0.083,0.086 0.168,0.172 0.255,0.255l9.198,8.872l-32.748,31.595l-103.393,-99.778c-26.64,-27.705 -26.599,-72.698 0.12,-100.379z"
				/>
			</g>
		</SvgIcon>
	)
}

export default VolunteerIcon

import { ICON_SIZES } from '@/constants/iconSizes'
import {
	CalendarBlank,
	ChartPie,
	Eye,
	IconProps,
	LineSegments,
	ListChecks,
	Megaphone,
	PaperPlaneTilt,
	Users,
	UsersThree,
} from '@phosphor-icons/react'
export const ADMIN_ROOT_PATH = '/admin'

const DEFAULT_PROPS: IconProps = {
	size: ICON_SIZES.lg,
	weight: 'fill',
}

export enum Route {
	Home = 'home',
	Users = 'users',
	Events = 'events',
	Organizations = 'organizations',
	PathBuilder = 'pathBuilder',
	GoalSetting = 'goalSetting',
	Feed = 'feed',
	Signup = 'signup',
	Notifications = 'notifications',
	ModerateContent = 'moderate',
}

export const NAVIGATION_MAPPER = {
	[Route.Home]: {
		icon: <ChartPie {...DEFAULT_PROPS} />,
	},
	[Route.Users]: {
		icon: <Users {...DEFAULT_PROPS} />,
	},
	[Route.Events]: {
		icon: <CalendarBlank {...DEFAULT_PROPS} />,
	},
	[Route.Organizations]: {
		icon: <UsersThree {...DEFAULT_PROPS} />,
	},
	[Route.PathBuilder]: {
		icon: <LineSegments {...DEFAULT_PROPS} />,
	},
	[Route.GoalSetting]: {
		icon: <LineSegments {...DEFAULT_PROPS} />,
	},
	[Route.Feed]: {
		icon: <Megaphone {...DEFAULT_PROPS} />,
	},
	[Route.Signup]: {
		icon: <ListChecks {...DEFAULT_PROPS} />,
	},
	[Route.Notifications]: {
		icon: <PaperPlaneTilt {...DEFAULT_PROPS} />,
	},
	[Route.ModerateContent]: {
		icon: <Eye {...DEFAULT_PROPS} />,
	},
}

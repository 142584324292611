import http from '@/api/http/requestHttp'

import { IdInt, PostId } from '@/lib/types'
import { buildUrl } from '..'

// @TODO: delete after switching to rtk query on schedule posts #cleanup, @refactor

export const getPostInfo = async (userId: number, postId: string, schoolId) => {
	return http.get(getPostInfo.buildUrl({ userId, postId, schoolId }))
}

getPostInfo.buildUrl = ({ userId, postId, schoolId }: { userId: IdInt; postId: PostId; schoolId: number }) => {
	return buildUrl({
		path: '/:schoolId/feeds/:userId/post/:postId',
		params: { schoolId, userId, postId },
	})
}
export default getPostInfo

import ColorButton from '@/components/Buttons/ColorButton'
import IconButton from '@/components/Buttons/IconButton'
import OutlinedButton from '@/components/Buttons/OutlinedButton'
import { STYLES } from '@/constants'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import classNames from 'classnames'
import { ReactNode } from 'react'
import IconWrapper, { TIconName } from './IconWrapper'

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			padding: '15vh 0',
		},
	},
	blockHeader: ({ showNothingSeemsToMatchMessage }: any) => ({
		margin: showNothingSeemsToMatchMessage ? '0 0 2vh 0' : 0,
		fontWeight: 600,
	}),
	icon: {
		width: 75,
		marginBottom: '2vh',
		minWidth: 75,
		color: theme.palette.primary.main,
	},
	buttonsContainer: {
		margin: '2.5vh 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	iconButton: {
		backgroundColor: theme.palette.primary.main,
		padding: '10px 20px',
		marginBottom: '1vh',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
		minWidth: 285,
	},
	button: {
		padding: '10px 20px',
		marginBottom: '1vh',
		width: 250,
		fontWeight: 600,
	},
}))

export interface PlaceholderButton {
	label: string
	variant: 'outlined' | 'icon' | 'common'
	onClick: () => void
}

export interface PlaceholderProps {
	iconKey?: TIconName
	src?: string
	children?: ReactNode
	isEmptyPlaceholder?: boolean
	showNothingSeemsToMatchMessage?: boolean
	buttons?: PlaceholderButton[]
	className?: string
}

const Placeholder = ({
	iconKey,
	src,
	buttons = [],
	children = null,
	isEmptyPlaceholder = false,
	className = '',
	showNothingSeemsToMatchMessage = true,
}: PlaceholderProps) => {
	const classes = useStyles({ showNothingSeemsToMatchMessage })

	return (
		<div className={classNames(classes.root, className)}>
			{src && <img className={classes.icon} src={src} alt="" />}
			{iconKey && <IconWrapper iconKey={iconKey} className={classes.icon} weight="fill" size={75} />}
			{!isEmptyPlaceholder && (
				<>
					<Typography className={classes.blockHeader}>No results found.</Typography>
					{showNothingSeemsToMatchMessage && <Typography>Nothing seems to match your search.</Typography>}
				</>
			)}
			{children}
			<div className={classes.buttonsContainer}>
				{buttons.map(({ label, variant, onClick }) => {
					switch (variant) {
						case 'icon':
							return (
								<IconButton key={label} className={classes.iconButton} startIcon={<AddOutlinedIcon fontSize="medium" />} onClick={onClick}>
									{label}
								</IconButton>
							)
						case 'outlined':
							return (
								<OutlinedButton key={label} className={classes.button} onClick={onClick}>
									{label}
								</OutlinedButton>
							)
						case 'common':
						default:
							return (
								<ColorButton key={label} className={classes.button} onClick={onClick}>
									{label}
								</ColorButton>
							)
					}
				})}
			</div>
		</div>
	)
}

export default Placeholder

import { ContentPreloader } from '@/components/ContentPreloader'

import EventCard from '@/anthology/events/components/EventCard'
import DtoEvent from '@/anthology/events/types/dto/DtoEvent'
import { canLoadMore } from '@/anthology/utils'
import { TextButton } from '@/components/Buttons'
import Card from '@/components/IndividualPage/Card'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import EmptyPlaceholder from '@/components/Placeholder'
import settings from '@/constants/http'
import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useTakeSkip from '@/anthology/hooks/useTakeSkip'
import { FEATURE } from '@navengage/sen-shared-assets'
import { useCallback, useMemo, useState } from 'react'
import { useSearchOrganizationEventsQuery } from '../../api'
import { DtoOrganization } from '../../types/dto/DtoOrganization'

const useStyles = makeStyles((theme) => ({
	placeholder: {
		width: '100%',
		padding: '20px 0',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	listTitle: {
		display: 'flex',
		alignItems: 'center',
	},
	cards: {
		width: '100%',
		marginTop: 20,
	},
	buttonsDivider: {
		color: theme.palette.primary.main,
		margin: '0 5px',
	},
	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
	},
}))

interface EventsTabProps {
	orgId: DtoOrganization['id']
	canCreateEvents: boolean
	handleClickCreateEvent: () => void
}

const currentDate = new Date()

const EventsTab = ({ canCreateEvents, handleClickCreateEvent, orgId }: EventsTabProps) => {
	const classes = useStyles()
	const { loadMore, skip, take } = useTakeSkip(settings.SEARCH_LIST_PER_PAGE)

	const [seePastEvents, setSeePastEvents] = useState<string | null>(null)

	const { data, isLoading, isFetching } = useSearchOrganizationEventsQuery(
		{
			id: orgId,
			skip,
			take,
			endsBefore: seePastEvents ? seePastEvents : undefined,
			endsAfter: !seePastEvents ? currentDate.toISOString() : undefined,
		},
		{ refetchOnMountOrArgChange: true },
	)

	const handleToggleView = useCallback(() => {
		const now = new Date()
		setSeePastEvents((prev) => (!prev ? now.toISOString() : null))
	}, [])

	const currentEvents = useMemo(() => {
		return data?.items || []
	}, [data])

	return (
		<>
			<Card>
				<div className={classes.header}>
					<Typography className={classes.listTitle} component="div" variant="body1">
						<b>{!seePastEvents ? 'Upcoming Events' : 'Past Events'}</b> |{' '}
						{isLoading || (isFetching && skip === 0) ? <CircularProgress size={16} /> : data.totalItems}
					</Typography>
					<div className={classes.buttonsContainer}>
						{canCreateEvents && (
							<>
								<TextButton onClick={handleClickCreateEvent}>Create Event</TextButton>
								<Typography className={classes.buttonsDivider} variant="body1">
									|
								</Typography>
							</>
						)}
						<TextButton disabled={isLoading || isFetching} onClick={handleToggleView}>
							{!seePastEvents ? 'See Past Events' : 'See Future Events'}
						</TextButton>
					</div>
				</div>
			</Card>
			{!currentEvents?.length || isLoading ? (
				<Card>
					{isLoading ? (
						<ContentPreloader />
					) : (
						<EmptyPlaceholder className={classes.placeholder} isEmptyPlaceholder iconKey={FEATURE.events}>
							<Typography align="center">This organization does not have</Typography>
							<Typography align="center">any {!seePastEvents ? 'upcoming' : 'past'} events.</Typography>
						</EmptyPlaceholder>
					)}
				</Card>
			) : (
				<Card>
					<div className={classes.cards}>
						<ExperienceCardsSection
							placeholder="That’s all for now. Check back soon for more events!"
							handleLoadMoreItems={loadMore}
							stretched
							items={data?.items}
							canLoadMore={canLoadMore(data)}
							isLoading={isLoading}
							renderItems={(items: DtoEvent[]) => (
								<>
									{items.map((event) => (
										<EventCard key={`${event.id}`} {...event} />
									))}
								</>
							)}
						/>
					</div>
				</Card>
			)}
		</>
	)
}

export default EventsTab

import { makeStyles } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import { CloseOutlined } from '@material-ui/icons'
import MaterialIconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative',
	},
	container: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'end',
	},
	cancelButton: {
		zIndex: 99,
		width: 30,
		height: 30,
		color: theme.palette.common.white,
		margin: 10,
		backgroundColor: theme.palette.specialColors.blacktransparent60,
		'&:hover': {
			backgroundColor: theme.palette.specialColors.blacktransparent80,
		},
	},
}))

interface ItemWithCancelButtonProps {
	children: React.ReactNode
	onCancel?: () => void
	className?: string
}

const ItemWithCancelButton = ({ onCancel, className = '', children }: ItemWithCancelButtonProps) => {
	const classes = useStyles()

	return (
		<div className={classNames(classes.root, className)}>
			<div className={classes.container}>
				{onCancel && (
					<MaterialIconButton className={classes.cancelButton} onClick={onCancel}>
						<CloseOutlined fontSize="small" />
					</MaterialIconButton>
				)}
			</div>
			{children}
		</div>
	)
}

export default ItemWithCancelButton

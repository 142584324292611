import classNames from 'classnames'
import { InputProps, TextField } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

interface StyleProps {
	error?: boolean
	disabled?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
	},
	inputRoot: {
		width: '100%',
		height: 50,
		backgroundColor: theme.palette.background.default,
		'&.MuiOutlinedInput-root': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		borderRadius: 5,
	},
	input: {
		padding: '15.5px 14px',
	},
	clearInputButton: {
		cursor: 'pointer',
	},
	inputIcon: {
		color: theme.palette.primary.main,
	},
	button: {
		position: 'absolute',
		top: 0,
		right: -50,
	},
	inputFilled: ({ error }) => {
		const borderColor = error ? theme.palette.error : theme.palette.divider

		return {
			backgroundColor: theme.palette.background.paper,
			'&.MuiOutlinedInput-root': {
				border: `solid 1px ${borderColor}`,
			},
			'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
			'&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
			'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
				border: `solid 1px ${borderColor}`,
			},
		}
	},
}))

interface FilledTextFieldProps extends StyleProps {
	className?: string
	value?: string
	placeholder?: string
	onChange: (e: any) => void
	onKeyPress?: (e: any) => void
	onFocus?: (e: any) => void
	onBlur?: (e: any) => void
	variant?: 'primary' | 'secondary'
	inputProps?: InputProps
}

const FilledTextField = ({
	className = '',
	value = '',
	placeholder,
	onChange,
	onKeyPress,
	onFocus,
	onBlur,
	variant = 'primary',
	inputProps,
	error,
}: FilledTextFieldProps) => {
	const classes = useStyles({ error })

	const changeFilledColor = variant === 'secondary' && value

	return (
		<TextField
			variant="outlined"
			classes={{
				root: classNames(className, changeFilledColor ? classes.inputFilled : '', classes.inputRoot),
			}}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onKeyPress={onKeyPress}
			onFocus={onFocus}
			onBlur={onBlur}
			InputProps={{
				...inputProps,
				classes: {
					input: classes.input,
				},
			}}
		/>
	)
}

export default FilledTextField

import { SCHOOL_ID } from '@/constants/configuration'
import { baseApi } from '../general/baseApi'
import GetAuthorizationUrlParams from './types/GetAuthorizationUrlParams'
import LoginParams from './types/LoginParams'
import DtoLoginResponse from './types/dto/DtoLoginResponse'
import { getQueryParamByName } from '@/utils/urlHandlers'
import TokenType from './types/TokenType'

const API_BASE_URL = '/auth'
// @TODO: switch to RTK queries later
export const authApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getAuthorizationUrl: builder.query<any, GetAuthorizationUrlParams>({
				query: (params) => ({
					url: `${API_BASE_URL}/authorize`,
					params,
				}),
			}),
			// Not used yet
			// @TODO: correct types when userInfo endpoint is finished
			getAuthUserInfo: builder.query<any, { schoolId?: number }>({
				query: ({ schoolId = SCHOOL_ID }) => ({
					url: `${API_BASE_URL}/userInfo`,
				}),
			}),
			// Used for Test User implementation
			login: builder.mutation<DtoLoginResponse, LoginParams>({
				query: (body) => {
					const schoolId = SCHOOL_ID
					const meta: LoginParams['meta'] = { appMode: getQueryParamByName('appMode'), appVersion: getQueryParamByName('appVersion') }
					const code = getQueryParamByName('appCode')
					return {
						url: `${API_BASE_URL}/login`,
						body: {
							...body,
							meta,
							schoolId,
							code,
						},
						method: 'POST',
					}
				},
			}),
			revoke: builder.mutation<void, { tokenHintType: TokenType; token: string }>({
				query: ({ tokenHintType, token }) => ({
					url: `${API_BASE_URL}/revoke`,
					method: 'POST',
					body: {
						tokenHintType,
						token,
					},
				}),
			}),
		}),
	})

export const { useGetAuthorizationUrlQuery, useGetAuthUserInfoQuery, useLoginMutation } = authApi

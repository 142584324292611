import { UserInfo } from '@/interfaces/user'
import { baseApi } from '../general/baseApi'
import { SESSION_TIME } from '@/constants/configuration'

interface DtoLinkPreviewData {
	url: string
	title: string
	siteName?: string
	description?: string
	mediaType: string
	contentType?: string
	images: string[]
	videos: {
		url?: string
		secureUrl?: string | null
		type?: string | null
		width?: string
		height?: string
	}[]
	favicons: string[]
}

interface LinkPreviewParams {
	userId: UserInfo['id']
	previewUrl: string
}

export const commonApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (builder) => ({
		getLinkPreviewData: builder.query<DtoLinkPreviewData, LinkPreviewParams>({
			query: ({ previewUrl, userId }) => ({
				url: `/linkpreview`,
				method: 'GET',
				params: { previewUrl },
			}),
			keepUnusedDataFor: SESSION_TIME,
		}),
	}),
})

export const { useGetLinkPreviewDataQuery } = commonApi

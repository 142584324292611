import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	container: {
		margin: '2vh 0',
		borderRadius: 10,
	},
	root: {
		width: 'fit-content',
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		border: `solid 1px ${theme.palette.primary.main}`,
		borderRadius: 7,
		padding: 10,
		fontSize: 18,
		fontWeight: 450,
		margin: '5px 5px 5px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	tagIcon: {
		color: theme.palette.primary.main,
		margin: 0,
	},
	button: {
		padding: 0,
		marginLeft: 10,
	},
	title: {
		fontSize: 18,
		fontWeight: 700,
	},
	itemsContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	placeholderContainer: {
		margin: '5px 0',
	},
	placeholderText: {
		color: theme.palette.text.secondary,
		fontSize: 18,
	},
}))

interface SelfCreatedItemsListProps {
	className?: string
	onChange: (v: { label: string; value: any }[]) => void
	items: {
		label: string
		value: any
	}[]
	title?: string
	placeholder?: string
}

const FetchedItemsList = ({ className = '', onChange, items, title, placeholder }: SelfCreatedItemsListProps) => {
	const classes = useStyles()

	const handleDeleteItem = (id: any) => () => {
		onChange(items?.filter((v) => v.value !== id))
	}

	return (
		<div>
			{title && <Typography className={classes.title}>{title}</Typography>}
			{!items?.length && placeholder && (
				<div className={classes.placeholderContainer}>
					<Typography className={classes.placeholderText}>{placeholder}</Typography>
				</div>
			)}
			<div className={classNames(classes.itemsContainer, className)}>
				{items?.map((item) => (
					<span className={classes.root} key={item.value}>
						{item.label}
						<IconButton className={classes.button} onClick={handleDeleteItem(item.value)}>
							<CancelIcon className={classes.tagIcon} />
						</IconButton>
					</span>
				))}
			</div>
		</div>
	)
}

export default FetchedItemsList

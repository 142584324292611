import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, InputAdornment, TextField, makeStyles } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { push } from 'connected-react-router'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useLoginMutation } from '@/anthology/auth/api'
import ColorButton from '@/components/Buttons/ColorButton'
import Card from '@/components/Card'
import CardLayout from '@/components/CardLayout'
import FieldErrorMessage from '@/components/FieldErrorMessage'
import Label from '@/components/Label'
import { PATHS, ROLE } from '@/constants'
import { getAuthService } from '@/init/DIContainer'
import { useAppDispatch } from '@/store'
import { signIn } from '@/store/auth'
import { getWebviewHelper } from '@/utils/webviewHelper'

const MIN_PASSWORD_LENGTH = 8

const useStyles = makeStyles((theme) => ({
	loginCard: {
		margin: 0,
		width: 280,
		padding: '20px 40px',
		[theme.breakpoints.up('xs')]: {
			width: 320,
		},
	},
	form: {
		width: '100%',
	},
	label: {
		marginBottom: 20,
	},
	formField: {
		width: '100%',
	},
	submitButton: {
		height: 48,
		width: '100%',
	},
	submissionErrors: {
		textAlign: 'center',
	},
	errorMessage: {
		color: theme.colors.Error[500],
	},
}))

interface ILoginForm {
	email: string
	password: string
}

const loginSchema = yup.object().shape({
	email: yup.string().label('Email').email('Invalid email').required(),
	password: yup.string().label('Password').required().min(MIN_PASSWORD_LENGTH, 'Invalid password'),
})

const LoginPage = () => {
	const authService = getAuthService()
	const dispatch = useAppDispatch()
	const classes = useStyles()
	const [showPassword, setShowPassword] = useState(false)
	const [loginMutation, { error, isSuccess, data, isLoading, isUninitialized }] = useLoginMutation()
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ILoginForm>({
		resolver: yupResolver(loginSchema),
		mode: 'onChange',
		reValidateMode: 'onChange',
	})

	const togglePasswordVisibility = useCallback(() => {
		setShowPassword((prev) => !prev)
	}, [])

	const handleMouseDownPassword = (event) => {
		event.preventDefault()
	}

	const onFormSubmit = async (data: ILoginForm) => {
		loginMutation({
			...data,
		})
	}
	useEffect(() => {
		if (isUninitialized || !data) {
			return
		}

		const { emitter } = getWebviewHelper()

		// @TODO: pass status and error accordingly, to show appropriate message and action if required // when user login is failed.
		// E.g missing account in engage, account is archived, blocked, some action is required etc... dispatch(push(isSuccess ? PATHS.AUTH_REDIRECT : PATHS.LOGIN_FAILED))
		emitter.emit('login', {
			status: isSuccess ? 'success' : 'failed',
			onboarding: false,
			data: data
				? { token: data.tokens.accessToken, refreshToken: data.tokens.refreshToken, user: data.user, accountId: data.user?.externalId }
				: null,
		})

		if (data.tokens.accessToken && data.user) {
			dispatch(signIn({ userInfo: data.user, role: ROLE.FACULTY, token: data.tokens.accessToken }))
			authService.setStorageData('currentUserId', data.user.id)
			authService.setStorageData('accessToken', data.tokens.accessToken)
			if (data.tokens.refreshToken) {
				authService.setStorageData('refreshToken', data.tokens.refreshToken)
			}
			dispatch(push(PATHS.APP.ROOT))
		}
	}, [authService, data, dispatch, isSuccess, isUninitialized])

	const formHasErrors = !!Object.keys(errors).length
	const disableSubmission = isLoading || formHasErrors
	return (
		<CardLayout>
			<Card cardContentClass={classes.loginCard}>
				<form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
					<Controller
						control={control}
						name="email"
						render={({ field }) => (
							<Label classnames={{ root: classes.label }} title="Email">
								<TextField aria-label="Email" className={classes.formField} variant="outlined" {...field} />
								<FieldErrorMessage hasError={!!errors['email']} message={errors['email']?.message} />
							</Label>
						)}
					/>
					<Controller
						control={control}
						name="password"
						render={({ field }) => (
							<Label classnames={{ root: classes.label }} title="Password">
								<TextField
									{...field}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={togglePasswordVisibility}
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
									aria-label="Password"
									className={classes.formField}
									variant="outlined"
									type={showPassword ? 'text' : 'password'}
								/>
								<FieldErrorMessage hasError={!!errors['password']} message={errors['password']?.message} />
							</Label>
						)}
					/>

					<ColorButton disabled={disableSubmission} className={classes.submitButton} type="submit">
						Login
					</ColorButton>
					<div className={classes.submissionErrors}>
						<FieldErrorMessage hasError={!!error} message={'Incorrect credentials'} />
					</div>
				</form>
			</Card>
		</CardLayout>
	)
}

export default LoginPage

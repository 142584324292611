import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	tabs: {
		height: '100%',
	},
	hideIndicator: {
		backgroundColor: theme.palette.background.paper,
	},
}))

interface NavigationTabsProps {
	tabsRenderer: (props: { hideIndicator: boolean; centered: boolean; activeTabIndex: number }) => React.ReactNode
	hideIndicator?: boolean
	activeTabIndex?: number
	centered?: boolean
	className?: string
	containerClassName?: string
}

export const NavigationTabs = ({
	tabsRenderer,
	hideIndicator = false,
	activeTabIndex = 0,
	centered = true,
	className,
	containerClassName,
}: NavigationTabsProps) => {
	const classes = useStyles({ centered })

	return (
		<Tabs
			classes={{
				root: classNames(className, classes.tabs),
				flexContainer: classNames(containerClassName, classes.tabs),
				indicator: `${hideIndicator ? classes.hideIndicator : ''}`,
			}}
			indicatorColor="primary"
			centered={centered}
			value={activeTabIndex}
		>
			{tabsRenderer({
				hideIndicator,
				centered,
				activeTabIndex,
			})}
		</Tabs>
	)
}

export default NavigationTabs

import { NetworkState } from '@/store/network/types'
import { createSelector } from '@reduxjs/toolkit'
import { MODULE_NAME } from '@/store/network/constants'
import { SearchConfig } from '@/interfaces/common'

const selectState = (state: { [MODULE_NAME]: NetworkState }) => state[MODULE_NAME]

export const getIsLoading = createSelector(selectState, (state) => state.isLoading)
export const getIsLoadingUserNetwork = createSelector(selectState, (state) => state.isLoadingUserNetwork)

export const getNetworkUsers = createSelector(selectState, (state) => state.users)
export const getEndHasBeenReached = createSelector(selectState, (state) => state.search.endHasBeenReached)
export const getSearchConfig = createSelector(selectState, (state) => state.search as SearchConfig)
export const getFilterIsReady = createSelector(selectState, (state) => state.search.filterIsReady)
export const getConnectionRequests = createSelector(selectState, (state) => state.connections.connectionRequests)
export const getPendingRequests = createSelector(selectState, (state) => state.connections.pendingRequests)
export const getTotalConnections = createSelector(selectState, (state) => state.connections.totalConnections)
export const getNetworkUserInfo = createSelector(selectState, (state) => state.selectedUser?.info)
export const getNetworkUserActivityState = createSelector(selectState, (state) => state.selectedUser?.activity)
export const getBlockedUsers = createSelector(selectState, (state) => state.blockedUsers)

import http from '@/api/http/requestHttp'

import { createPost, updatePost } from './createPost'
import createPostUserResponse from './createPostUserResponse'
import deletePost from './deletePost'

import getConstants from './getConstants'
import { getPostInfo } from './getPostInfo'
import likePost from './likePost'
import searchPosts from './searchPosts'

import { PostId, UserId } from '@/lib/types'

interface RecordPostImpressions {
	postIds: PostId[]
	userId: UserId
	schoolId: number
}

export const recordPostImpressions = async ({ postIds, userId, schoolId }: RecordPostImpressions) => {
	const url = `${schoolId}/feeds/${userId}/post/viewed`

	return http.patch(url, { postIds })
}

const feeds = {
	getConstants,
	createPost,
	updatePost,
	searchPosts,
	likePost,
	getPostInfo,
	deletePost,
	createPostUserResponse,
}

export default feeds

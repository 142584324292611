import { STYLES } from '@/constants'
import { Button, Theme, makeStyles } from '@material-ui/core'
import React from 'react'

type StyledProps = {
	top?: number
}

const useStyles = makeStyles<Theme, StyledProps>((theme) => ({
	container: {
		zIndex: 1,
		position: 'sticky',
		top: ({ top }) => `calc(${STYLES.MAIN_HEADER_HEIGHT}vh + ${top ? top + theme.spacing(2) : theme.spacing(2)}px)`,
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		borderRadius: '16px',
		fontSize: '12px',
		fontWeight: 700,
		backgroundColor: theme.palette.background.paper,
	},
}))

export type IFloatingButtonProps = StyledProps & {
	onClick?: () => void
	label: string
}

const FloatingButton: React.FC<IFloatingButtonProps> = ({ label, onClick, ...styledProps }) => {
	const classes = useStyles(styledProps)
	return (
		<div className={classes.container}>
			<Button onClick={onClick} className={classes.button} variant="outlined" color="primary">
				{label}
			</Button>
		</div>
	)
}

export default FloatingButton

import AutoSizer from 'react-virtualized-auto-sizer'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { STYLES } from '@/constants'
import { IconButton, Typography } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { IGroupChat } from '@navengage/sen-types'
import GroupImage from './GroupImage'

interface StylesProps {
	cursorPointer: boolean
}

export const GROUP_HEADER_HEIGHT = 70

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
	root: ({ cursorPointer }) => ({
		cursor: cursorPointer ? 'pointer' : 'auto',
		backgroundColor: theme.palette.background.paper,
	}),
	autoSizer: {
		position: 'fixed',
		zIndex: 1,
		height: 'fit-content !important',
		maxHeight: 72,
	},
	headerRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: '0 20px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `solid 2px ${theme.palette.divider}`,
		height: GROUP_HEADER_HEIGHT,
	},
	headerUserInfo: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		padding: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	headerText: {
		color: theme.palette.text.primary,
		fontWeight: 700,
	},
	column: {
		width: 47,
		height: 47,
		minWidth: 47,
		minHeight: 47,
	},
}))

interface GroupPageHeaderProps {
	onClickBack?: () => void
	onClick?: () => void
	data?: IGroupChat
	showBackButton?: boolean
}

const GroupPageHeader = ({ onClickBack, onClick, data, showBackButton }: GroupPageHeaderProps) => {
	const cursorPointer = !!onClick

	const classes = useStyles({ cursorPointer })

	return (
		<AutoSizer disableHeight className={classes.autoSizer}>
			{({ width }) => (
				<div className={classes.root} style={{ width }}>
					<div className={classes.headerRow} onClick={onClick}>
						<div className={classes.column}>
							{showBackButton && (
								<IconButton onClick={onClickBack}>
									<KeyboardArrowLeft fontSize="large" />
								</IconButton>
							)}
						</div>
						<Typography noWrap={true} className={classes.headerText} variant="body1">
							{data?.name}
						</Typography>
						<div className={classes.column}>
							<GroupImage borderRadius={7} url={data?.thumbnail} groupName={data?.name} />
						</div>
					</div>
				</div>
			)}
		</AutoSizer>
	)
}

export default GroupPageHeader

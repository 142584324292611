import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ColorButton, CommonModalCard, IconButton, IconWrapper, InfoModalCard, Modal } from '@/components'
import { ReportActions } from '@/interfaces/common'
import { ICON_SIZES } from '@/constants/iconSizes'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	reportFormContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	reportFormText: {
		margin: '2.5vh 0',
	},
	greyText: {
		color: theme.palette.text.secondary,
		marginBottom: '2.5vh',
	},
	reportFormSubmitButton: {
		margin: '3.5vh 0',
		padding: '1vh',
		fontWeight: 600,
		minWidth: 200,
	},
	reportTextArea: {
		minWidth: 520,
		margin: '0 40px',
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.text.secondary,
			},
		},
	},
	inputRoot: {
		caretColor: theme.palette.primary.dark,
	},
	submitText: {
		margin: '10px 0',
	},
	doneText: {
		margin: '10px 0',
		fontWeight: 600,
	},
}))

export enum ReportSubject {
	Event = 'Event',
	Organization = 'Organization',
	EducationAbroad = 'Education Abroad Opportunity',
	Research = 'Research Opportunity',
	Internship = 'Internship',
	User = 'User',
	Post = 'Post',
	Comment = 'Comment',
}

interface ReportModalProps {
	redirect?: string
	subjectId: string
	subject: ReportSubject
	onSubmitReport: (id: string, reportType: ReportActions, comment: string) => void
	isOpenModal: boolean
	handleCloseModal: (e: any) => void
}

// @TODO: rtk query mutation should be done here, preloader is needed, need to check response status and handle success and
// error accordingly, in general this needs to be recreated
const ReportModal = ({
	subject = ReportSubject.Event,
	subjectId,
	onSubmitReport,
	isOpenModal = false,
	redirect = '',
	handleCloseModal = () => {},
}: ReportModalProps) => {
	const classes = useStyles()
	const { push } = useHistory()

	const theme = useTheme()

	const reportType = subject === ReportSubject.User ? ReportActions.reportUser : null

	const [type, setType] = useState<ReportActions | null>(reportType)
	const [reportComment, setReportComment] = useState('')
	const [reportSubmitted, setReportSubmitted] = useState(false)

	const onClose = (e: any) => {
		if (reportSubmitted && redirect) push(redirect)

		handleCloseModal(e)
		setType(reportType)
		setReportComment('')
		setReportSubmitted(false)
	}

	const handleSetReportType = (reportType: ReportActions) => () => {
		setType(reportType)
	}

	const submitFeedback = (type: ReportActions, comment: string) => () => {
		setReportSubmitted(true)
		onSubmitReport(subjectId, type, comment)
	}

	const handleChangeComment = (e: any) => setReportComment(e.currentTarget.value)

	const reportButtons = useMemo(
		() => [
			{
				label: `This ${subject.toLowerCase()} is inaccurate`,
				onClick: handleSetReportType(ReportActions.inaccurate),
			},
			{
				label: `This ${subject.toLowerCase()} is inappropriate`,
				onClick: handleSetReportType(ReportActions.inappropriate),
			},
		],
		[subject],
	)

	const disableSubmit = !reportComment

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<>
				{!type && !reportSubmitted && (
					<CommonModalCard
						title={`Report ${subject}`}
						subTitle="Your report is anonymous."
						onClose={onClose}
						classnames={{
							header: classes.cardHeader,
						}}
					>
						{reportButtons.map((btn) => (
							<IconButton key={btn.label} className={classes.itemPopupButton} onClick={btn.onClick}>
								{btn.label}
							</IconButton>
						))}
					</CommonModalCard>
				)}
				{type && !reportSubmitted && (
					<CommonModalCard title={`Report ${subject}`} onClose={onClose}>
						<div className={classes.reportFormContainer} onClick={(e) => e.stopPropagation()}>
							<Typography className={classes.reportFormText}>
								{reportType ? 'Why are you reporting this user?' : `Why is this ${subject.toLowerCase()} ${type}?`}
							</Typography>
							{reportType && <Typography className={classes.greyText}>Your report will be anonymous.</Typography>}
							<TextField
								className={classes.reportTextArea}
								placeholder={`Share why you’re reporting this ${subject.toLowerCase()}`}
								multiline
								rows={12}
								variant="outlined"
								onChange={handleChangeComment}
								InputProps={{
									classes: { input: classes.inputRoot },
								}}
							/>
							<ColorButton
								className={classes.reportFormSubmitButton}
								disabled={disableSubmit}
								onClick={submitFeedback(type, reportComment)}
							>
								Submit
							</ColorButton>
						</div>
					</CommonModalCard>
				)}
				{reportSubmitted && (
					<InfoModalCard
						onClose={onClose}
						icon={<IconWrapper iconKey="checkCircle" color={theme.palette.success.main} weight="fill" size={ICON_SIZES.xl} />}
					>
						<Typography className={classes.doneText}>Thanks for your feedback.</Typography>
						<Typography className={classes.submitText}>Your report has been submitted.</Typography>
					</InfoModalCard>
				)}
			</>
		</Modal>
	)
}

export default ReportModal

import InternshipCard from '@/components/ExperienceCards/InternshipCard'
import ExperienceCardsSection from '@/components/ItemsSections/ExperienceCardsSection'
import ItemsSectionTitle from '@/components/ItemsSections/ItemsSectionTitle'
import NotFoundPlaceholder, { PlaceholderButton } from '@/components/Placeholder'
import { STYLES } from '@/constants'
import {
	getEndHasBeenReached,
	getFilterIsReady,
	getInternships,
	getIsLoading,
	loadInternshipsListWithFilterRequest,
} from '@/store/internships'
import { submitFeedback } from '@/utils/services'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store'
import { FEATURE } from '@navengage/sen-shared-assets'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
}))

const DiscoverInternships = () => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	const internships = useSelector(getInternships)
	const isLoading = useSelector(getIsLoading)
	const endHasBeenReached = useSelector(getEndHasBeenReached)
	const filterIsReady = useSelector(getFilterIsReady)

	const canLoadMore = !endHasBeenReached

	const handleLoadMore = useCallback(() => {
		if (filterIsReady) {
			dispatch(loadInternshipsListWithFilterRequest({ fetchMore: true }))
		}
	}, [dispatch, filterIsReady])

	const placeholderButtons: PlaceholderButton[] = useMemo(
		() => [
			{
				variant: 'outlined',
				label: 'Submit Feedback',
				onClick: submitFeedback,
			},
		],
		[],
	)

	const showPlaceholder = !canLoadMore && !isLoading && !internships.length

	return (
		<div className={classes.root}>
			<ItemsSectionTitle title="Discover Jobs" />
			{showPlaceholder ? (
				<NotFoundPlaceholder iconKey={FEATURE.jobs} buttons={placeholderButtons}>
					<Typography align="center">Try expanding your search</Typography>
				</NotFoundPlaceholder>
			) : (
				<ExperienceCardsSection
					placeholder="That’s all for now. Check back soon for more internships opportunities!"
					handleLoadMoreItems={handleLoadMore}
					items={internships}
					canLoadMore={canLoadMore}
					isLoading={isLoading}
					renderItems={(items: any) => (
						<>
							{items.map(({ name, photoUrl, id, responseStatus, employer, positionPaid, jobTypeId }: any) => (
								<InternshipCard
									key={`${id}-${name}`}
									id={id}
									title={name}
									photoUrl={photoUrl}
									employer={employer}
									positionPaid={positionPaid}
									jobTypeId={jobTypeId}
									status={responseStatus}
								/>
							))}
						</>
					)}
				/>
			)}
		</div>
	)
}

export default DiscoverInternships

import http from '@/api/http/requestHttp'
import { ConnectivityStatus } from '@/interfaces/common'
import { Except } from 'type-fest'
import { CommonUserConnectionParams } from './types'

export type DtoUpdatedConnectionRequest = {
	connection: {
		id: number
		status: string
		createdAt: Date
		updatedAt: Date
	} & Except<CommonUserConnectionParams, 'targetUserId'>
}

interface PatchUserConnectionParams extends Except<CommonUserConnectionParams, 'targetUserId'> {
	id: number
	status: ConnectivityStatus
}

const patchUserConnection = async ({ userId, status, id, schoolId }: PatchUserConnectionParams) => {
	const url = `${schoolId}/network/${userId}/connection/${id}`

	return http.patch<DtoUpdatedConnectionRequest>(url, { status })
}

export default patchUserConnection

import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useInView } from 'react-intersection-observer'
import { CircularProgress, Typography } from '@material-ui/core'
import { STYLES } from '@/constants'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
	root: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
	cardContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		display: 'grid',
		gridTemplateColumns: `repeat(auto-fill, 100%)`,
		justifyContent: 'flex-start',
		gridGap: 10,
		margin: '1vh 2vw',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
			justifyContent: 'space-evenly',
		},
	},
	controlPanel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '3vh 0 6vh 0',
	},
	infoText: {
		fontSize: '0.9em',
		[theme.breakpoints.up('xl')]: {
			fontSize: '1.125em',
		},
	},
}))

interface ItemsSectionProps {
	placeholder?: string
	handleLoadMoreItems?: any
	items: any[]
	renderItems: (items: any) => React.ReactNode
	isLoading: boolean
	canLoadMore?: boolean
	classnames?: {
		root?: string
		contentContainer?: string
		content?: string
		spinner?: string
	}
}

const ItemsSection = ({
	placeholder,
	handleLoadMoreItems = () => {},
	items = [],
	isLoading = false,
	canLoadMore = false,
	renderItems,
	classnames = {
		root: '',
		contentContainer: '',
		content: '',
		spinner: '',
	},
}: ItemsSectionProps) => {
	const classes = useStyles()
	const [ref, inView] = useInView({
		threshold: 0,
		delay: 1000,
	})

	useEffect(() => {
		if (inView && !isLoading && canLoadMore) {
			handleLoadMoreItems()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canLoadMore, inView, isLoading])

	return (
		<div className={classNames(classnames.root, classes.root)}>
			<div className={classNames(classnames.contentContainer, classes.contentContainer)}>
				<div className={classNames(classnames.content, classes.cardContainer)}>{renderItems(items)}</div>
				{canLoadMore && (
					<div className={classes.controlPanel} ref={ref}>
						<CircularProgress className={classnames.spinner} />
					</div>
				)}
			</div>
			{!isLoading && !canLoadMore && placeholder && (
				<Typography className={classNames(classes.infoText, classes.controlPanel)}>{placeholder}</Typography>
			)}
		</div>
	)
}

export default ItemsSection

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'

interface StoreSignUpParams {
	userId: UserInfo['id']
	body: any
	schoolId: number
}

const storeSignUp = async ({ body, schoolId, userId }: StoreSignUpParams) => {
	const url = `${schoolId}/users/user/${userId}/signup/v3`

	return http.post(url, {
		...body,
	})
}

export default storeSignUp

import http from '@/api/http/requestHttp'

interface GetUserProfileImageProps {
	externalId?: string
	schoolId: number
}

const getUserProfileImage = async ({ schoolId, externalId }: GetUserProfileImageProps) => {
	const url = `${schoolId}/photos/profilePhoto/${externalId}`

	return http.get(url)
}

export default getUserProfileImage

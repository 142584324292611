import { makeStyles } from '@material-ui/core/styles'
import { CheckOutlined, CloseOutlined } from '@material-ui/icons'
import { useCallback, useEffect } from 'react'

import { ColorButton, CommonModalCard, Modal } from '@/components'
import { ButtonColors } from '@/components/Buttons/types'
import { ConnectionRequestType } from '@/components/UserNetwork/ConnectionRequestsBlock'
import NetworkUserRow from '@/components/UserNetwork/NetworkUserRow'
import { STYLES } from '@/constants'
import { ConnectivityStatus } from '@/interfaces/common'
import { useAppDispatch } from '@/store'
import { changeConnectionStatusRequest } from '@/store/network'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: 40,
		width: '45vw',
		[theme.breakpoints.up('lg')]: {
			width: '60vw',
		},
		[theme.breakpoints.down('md')]: {
			width: '70vw',
		},
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	reportFormContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxHeight: '60vh',
		minHeight: '40vh',
		overflowY: 'auto',
		width: '100%',
	},
	reportFormText: {
		margin: '2.5vh 0',
	},
	reportFormSubmitButton: {
		margin: '3.5vh 0',
		padding: '1vh',
		fontWeight: 600,
		minWidth: 200,
	},
	reportTextArea: {
		minWidth: 520,
		margin: '0 40px',
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.divider,
			},
		},
	},
	inputRoot: {
		caretColor: theme.palette.primary.dark,
	},
	checkIcon: {
		width: 70,
		height: 70,
	},
	submitText: {
		margin: '10px 0',
	},
	userInfoRowContainer: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		borderBottom: `solid 1px ${theme.palette.divider}`,
		padding: '15px 0',
		margin: '0 25px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	userInfoRow: {
		width: STYLES.FILL_AVAILABLE_WIDTH,
		border: 'none',
		margin: 0,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		minWidth: 120,
		marginRight: 10,
	},
	iconButton: {
		height: 46,
		minWidth: 56,
		marginRight: 10,
	},
}))

export enum ReportSubject {
	Event = 'Event',
	Organization = 'Organization',
	EducationAbroad = 'Education Abroad Opportunity',
	Research = 'Research Opportunity',
}

interface ManageConnectionsModalProps {
	isOpenModal: boolean
	handleCloseModal: () => void
	connectionRequests: any[]
	connectionRequestType: ConnectionRequestType
}

const ManageConnectionsModal = ({
	isOpenModal = false,
	handleCloseModal = () => {},
	connectionRequests,
	connectionRequestType,
}: ManageConnectionsModalProps) => {
	const classes = useStyles()
	const dispatch = useAppDispatch()
	// const { push } = useHistory();

	const title = `Connection Requests | ${connectionRequests?.length}`

	const onClose = useCallback(() => {
		handleCloseModal()
	}, [handleCloseModal])

	const handleChangeRequest = useCallback(
		(status: ConnectivityStatus, id: number) => {
			dispatch(changeConnectionStatusRequest({ status, id }))
		},
		[dispatch],
	)

	useEffect(() => {
		if (isOpenModal && !connectionRequests?.length) onClose()
	}, [connectionRequests?.length, isOpenModal, onClose])

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<CommonModalCard
				title={title}
				onClose={onClose}
				classnames={{
					card: classes.root,
				}}
			>
				<div className={classes.reportFormContainer} onClick={(e) => e.stopPropagation()}>
					{connectionRequests?.map((req, index) => (
						<div key={`modal-req-${req.id}-${index}`} className={classes.userInfoRowContainer}>
							<NetworkUserRow className={classes.userInfoRow} user={req.user} />
							<div className={classes.buttonsContainer}>
								{connectionRequestType === ConnectionRequestType.INCOMING ? (
									<>
										<ColorButton className={classes.iconButton} onClick={() => handleChangeRequest(ConnectivityStatus.ACCEPTED, req.id)}>
											<CheckOutlined />
										</ColorButton>
										<ColorButton
											className={classes.iconButton}
											color={ButtonColors.GREY}
											onClick={() => handleChangeRequest(ConnectivityStatus.REJECTED, req.id)}
										>
											<CloseOutlined />
										</ColorButton>
									</>
								) : (
									<ColorButton
										className={classes.button}
										color={ButtonColors.GREY}
										onClick={() => handleChangeRequest(ConnectivityStatus.CANCELED, req.id)}
									>
										Cancel
									</ColorButton>
								)}
							</div>
						</div>
					))}
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export default ManageConnectionsModal

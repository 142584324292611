import { PathBuilderForm } from '@/store/pathBuilder/types'
import { isArray } from 'lodash'
import * as yup from 'yup'

export const yesOrNoOptions = [
	{
		label: 'Yes',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
]

const generateSemestersArray = (years: number) => {
	const today = new Date().getMonth() + 1 // months start from 0
	const min = new Date().getFullYear()
	const max = min + years - 1
	const semesters = []

	const getSemesterOption = (semesterName: string, year: number) => {
		const semester = `${semesterName} ${year}`

		return {
			label: semester,
			value: semester,
		}
	}

	// Handle this year

	// add spring of this year
	if (today < 3) {
		semesters.push(getSemesterOption('Spring', min))
	}

	// add summer of this year
	if (today < 7) {
		semesters.push(getSemesterOption('Summer', min))
	}

	// add fall of this year
	if (today < 11) {
		semesters.push(getSemesterOption('Fall', min))
	}

	// Handle future years

	for (let i = min + 1; i <= max; i++) {
		;['Spring', 'Summer', 'Fall', 'Winter'].forEach((season) => {
			semesters.push(getSemesterOption(season, i))
		})
	}
	return semesters
}

export const whenWouldYouLikeOptions = generateSemestersArray(5)

export const timePeriodOptions = {
	hours: [
		{
			label: '1-5',
			value: '1-5',
		},
		{
			label: '5-10',
			value: '5-10',
		},
		{
			label: '10-15',
			value: '10-15',
		},
		{
			label: '15+',
			value: '15+',
		},
	],

	timePeriod: [
		{
			label: 'per week',
			value: 'per week',
		},
		{
			label: 'per month',
			value: 'per month',
		},
		{
			label: 'per term',
			value: 'per term',
		},
	],
}

export const getArrayValue = (key: string, pathBuilderForm: PathBuilderForm) => {
	// @ts-ignore
	const formValue = pathBuilderForm[key]
	const valueMap = formValue ? (Array.isArray(formValue) ? formValue : formValue.split(';')) : null

	return valueMap ?? []
}

export const getStringValue = (key: string, pathBuilderForm: PathBuilderForm) => {
	// @ts-ignore
	const formValue = pathBuilderForm[key]

	return formValue ?? ''
}

export const getBooleanValue = (key: string, pathBuilderForm: PathBuilderForm) => {
	// @ts-ignore
	const formValue = pathBuilderForm[key]

	return formValue ?? null
}

export const getWithCustomAnswerValue = (key: string, pathBuilderForm: PathBuilderForm, isArray: boolean) => {
	// @ts-ignore
	const formValue = pathBuilderForm[key]

	let value: any = {
		answers: isArray ? [] : null,
		otherAnswer: '',
	}

	if (formValue) {
		if (Array.isArray(formValue)) {
			value.answers = formValue.map((val) => {
				if (val.toLowerCase().includes('other')) {
					value.otherAnswer = val.replace('Other: ', '')
					return 'Other'
				}
				return val
			})
		} else if (formValue.toLowerCase().includes('other')) {
			value.otherAnswer = formValue.replace('Other: ', '')
			value.answers = 'Other'
		}
	}

	return value
}

export const getTimePeriodValue = (key: string, pathBuilderForm: PathBuilderForm) => {
	// @ts-ignore
	const formValue = pathBuilderForm[key]
	const newValue =
		formValue && typeof formValue === 'string' ? formValue.split(';') : formValue?.hours ? [formValue.hours, formValue.timePeriod] : null

	let value = {
		hours: '',
		timePeriod: timePeriodOptions.timePeriod[0].value,
	}

	if (Array.isArray(newValue) && newValue.length) {
		value.hours = newValue[0]
		value.timePeriod = newValue[1]
	}

	return value
}

export const getYesOrNoSchema = (key: string, data: any, pathBuilderForm: PathBuilderForm) => ({
	schema: yup.object().shape({
		[key]: yup.boolean().required(),
	}),
	defaultValues: data ?? {
		[key]: getBooleanValue(key, pathBuilderForm),
	},
})

export const getArrayMinSchema = (key: string, min: number, data: any, pathBuilderForm: PathBuilderForm) => ({
	schema: yup.object().shape({
		[key]: yup.array().min(min),
	}),
	defaultValues: data ?? {
		[key]: getArrayValue(key, pathBuilderForm),
	},
})

export const getArrayMaxSchema = (key: string, max: number, data: any, pathBuilderForm: PathBuilderForm) => ({
	schema: yup.object().shape({
		[key]: yup.array().max(max),
	}),
	defaultValues: data ?? {
		[key]: getArrayValue(key, pathBuilderForm),
	},
})

export const getWithCustomAnswerSchema = (
	key: string,
	data: any,
	pathBuilderForm: PathBuilderForm,
	customAnswerType: 'string' | 'array' = 'array',
	customAnswerDefaultValue: boolean = true,
) => ({
	schema: yup.object().shape({
		[key]: yup.object().shape({
			answers: customAnswerType === 'array' ? yup.array().min(1) : yup.string(),
			otherAnswer: yup.string().when(['answers'], {
				is: (answers: any) => {
					if (customAnswerType === 'string' && answers === 'Other') {
						return true
					} else {
						return isArray(answers) && answers.includes('Other')
					}
				},
				then: yup.string().required('Please enter your answer.'),
			}),
		}),
	}),
	defaultValues: data ?? {
		[key]: getWithCustomAnswerValue(key, pathBuilderForm, customAnswerDefaultValue),
	},
})

export const getStringSchema = (key: string, data: any, pathBuilderForm: PathBuilderForm) => ({
	schema: yup.object().shape({
		[key]: yup.string().required(),
	}),
	defaultValues: data ?? {
		[key]: getStringValue(key, pathBuilderForm),
	},
})

export const getTimePeriodSchema = (key: string, data: any, pathBuilderForm: PathBuilderForm) => ({
	schema: yup.object().shape({
		[key]: yup.object().shape({
			hours: yup.string().required(),
			timePeriod: yup.string().required(),
		}),
	}),
	defaultValues: data ?? {
		[key]: getTimePeriodValue(key, pathBuilderForm),
	},
})

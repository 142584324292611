import * as http from '@/api/http'
import { PATHS } from '@/constants'
import * as config from '@/constants/configuration'
import FORM, { CreateEventFormType } from '@/constants/createEventFormFields'
import { ReportActions, SearchConfig, UserResponseStatuses } from '@/interfaces/common'
import { RootState } from '@/store'
import { closeSnackbar, enqueueSnackbar } from '@/store/app'
import { SnackbarType } from '@/store/app/types'
import { MODULE_NAME } from '@/store/events/constants'
import { AddToCalendarModal, EventInfo } from '@/store/events/types'
import { StoreSearchParams } from '@/store/types'
import { loadUserVolunteerShiftsRequest } from '@/store/volunteer'
import { createPhotoUrl, getCurrentFilterValues, getNormalizeShiftUser, getNormalizedUserData } from '@/utils/common'
import { mergeDateTime } from '@/utils/dateTime2'
import { convertUserEventsToEvents, isEventAdmin, isUserSubmittedToShift, setEventUserResponse } from '@/utils/events'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { uniq } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { PhotoUploadDirectories, UploadErrorMessage, UploadErrors } from '@/constants/uploads'

import { EventLocationTypes, EventRepeatingOptions } from '@/constants/events'
import { eventsApi } from '@/features/events/api'
import { EVENT_RESPONSE_MAP } from '@/features/events/constants'
import { organizationApi } from '@/features/organizations/api'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { globalStates } from '@/lib/globalState'
import uploadPhoto from '@/utils/photoUpload'
import { selectAuth } from '../auth'

export const setIsLoading = createAction<boolean>(`${MODULE_NAME}/SET_IS_LOADING`)

export const setEvents = createAction<any>(`${MODULE_NAME}/SET_EVENTS`)
export const loadMore = createAction<void>(`${MODULE_NAME}/PAGINATE_EVENTS`)
export const updateEvent = createAction<{ id: string; status: any }>(`${MODULE_NAME}/UPDATE_EVENT`)
export const deleteEvent = createAction<string>(`${MODULE_NAME}/REMOVE_EVENT`)
export const setUserEvents = createAction<any>(`${MODULE_NAME}/SET_USER_EVENTS`)
export const setCategories = createAction<any>(`${MODULE_NAME}/SET_CATEGORIES`)
export const setFilters = createAction<StoreSearchParams>(`${MODULE_NAME}/SET_FILTERS`)
export const clearFilters = createAction(`${MODULE_NAME}/CLEAR_FILTERS`)
export const setIndividualEvent = createAction<any>(`${MODULE_NAME}/SET_INDIVIDUAL_EVENT`)
export const setIndividualEventResponse = createAction<any>(`${MODULE_NAME}/SET_INDIVIDUAL_EVENT_RESPONSE`)
export const openAddToCalendarModal = createAction<AddToCalendarModal>(`${MODULE_NAME}/OPEN_ADD_TO_CALENDAR_MODAL`)
export const openNotInterestedEventPopup = createAction<boolean>(`${MODULE_NAME}/OPEN_NOT_INTERESTED_EVENT_POPUP`)

export const setCreateEventFormDefaultValues = createAction<CreateEventFormType>(`${MODULE_NAME}/SET_CREATE_EVENT_FORM_DEFAULT_VALUES`)

const prepareEventInfo = (userId: number, data: any, eventId: string, oldImageUrl?: string) => {
	const imageName = uuidv4()
	const photoUpdated = oldImageUrl !== data[FORM.photo]
	const imageLocation = createPhotoUrl(imageName, PhotoUploadDirectories.EVENT)

	const photoUrl = photoUpdated ? imageLocation : oldImageUrl

	const shifts = (data[FORM.shifts] || []).map(({ start, end }: any) => {
		const startDate = mergeDateTime(data[FORM.startDate], start)
		const endTime = mergeDateTime(data[FORM.startDate], end)

		return {
			maxUsers: data[FORM.volunteersPerShift],
			time: {
				start: startDate,
				end: endTime,
			},
		}
	})

	const eventInfo: EventInfo = {
		schoolId: config.SCHOOL_ID,
		id: eventId,
		name: data[FORM.eventName],
		categories: data[FORM.categories],
		startDate: mergeDateTime(data[FORM.startDate], data[FORM.startTime]),
		endDate: mergeDateTime(data[FORM.endDate], data[FORM.endTime]),
		campus: null,
		campuses: data[FORM.campus],
		contactName: data[FORM.contactName],
		contactEmail: data[FORM.contactEmail],
		description: data[FORM.description],
		externalUrl: data[FORM.externalUrl],
		hostOrganization: data[FORM.hostOrganization],
		locationName: data[FORM.locationName],
		locationAddress: data[FORM.locationAddress],
		photoUrl: photoUrl!,
		privacyType: data[FORM.privacyType],
		repeating: data[FORM.repeating],
		repeatCount: data[FORM.repeating] !== EventRepeatingOptions.DISABLED ? data[FORM.repeatCount] : 0,
		submitterID: userId,
		shifts,
		virtualLink: data[FORM.locationType] === EventLocationTypes.VIRTUAL ? data[FORM.virtualLink] : null,
	}

	return {
		eventInfo,
		newImageName: photoUpdated ? imageName : null,
	}
}

export const openCreateEventPage = createAsyncThunk<
	any,
	CreateEventFormType,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_EVENTS_LIST_WITH_FILTER_REQUEST`, async (defaultValues: CreateEventFormType, { dispatch, rejectWithValue }) => {
	try {
		dispatch(setCreateEventFormDefaultValues(defaultValues))
		dispatch(push(PATHS.APP.EVENTS_CREATE))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadEventsListWithFilterRequest = createAsyncThunk<
	any,
	SearchConfig,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_EVENTS_LIST_WITH_FILTER_REQUEST`, async (config: SearchConfig, { dispatch, getState, rejectWithValue }) => {
	try {
		dispatch(setIsLoading(true))
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)
		const storedEvents = rootState.EVENTS.events
		const storedSearch = rootState.EVENTS.search
		const userEvents = rootState.EVENTS.userEvents

		const { params, page, filterIsReady, fetchMore, currentConfig } = getCurrentFilterValues(storedSearch, config)

		const res = await http.events.searchEvents({
			q: params?.query,
			page,
			userId,
			campusId: params?.campuses,
			categoryId: params?.categories,
			startDate: params?.date?.startDate,
			endDate: params?.date?.endDate,
			schoolId,
		})

		const eventsWithStatus = setEventUserResponse(res.data, userEvents)
		const events = fetchMore ? [...storedEvents, ...eventsWithStatus] : eventsWithStatus

		dispatch(
			setFilters({
				...currentConfig,
				endHasBeenReached: !res.data?.length,
				page,
				filterIsReady,
			}),
		)
		await dispatch(setEvents(events))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(setIsLoading(false))
	}
})

export const loadEventsConstantsRequest = createAsyncThunk<
	any,
	undefined,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_EVENTS_CONSTANTS_REQUEST`, async (_undefined, { dispatch, rejectWithValue, getState }) => {
	try {
		const rootState = getState()
		const { schoolId } = selectAuth(rootState)
		const categories = await http.events.getListCategories(schoolId)

		const createOption = (arr: any[]) =>
			arr.map((item) => ({
				label: item.name || item.category,
				value: item.id,
			}))

		await dispatch(loadUserVolunteerShiftsRequest())
		await dispatch(setCategories(createOption(categories.data.categories)))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const loadUserEventsRequest = createAsyncThunk<
	any,
	void,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_USER_EVENTS_REQUEST`, async (_undefined, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const [userEvents, userSubmittedEvents] = await Promise.all([
			http.events.getEventsByUserId(userId, schoolId),
			http.events.getAllUserSubmittedEvents(userId, schoolId),
		])

		const fullList = userEvents.data.events as any[]
		const createdByUser = userSubmittedEvents.data?.response.map((event: any) => ({
			...event,
			responseStatus: fullList.find((e) => e.eventId === event.id)?.response,
		}))

		const going = convertUserEventsToEvents(
			fullList.filter((event) => event.response === UserResponseStatuses.going && new Date(event.event.startDate) > new Date()),
		)

		const interested = convertUserEventsToEvents(
			fullList.filter((event) => event.response === UserResponseStatuses.interested && new Date(event.event.startDate) > new Date()),
		)

		const notInterested = convertUserEventsToEvents(
			fullList.filter((event) => event.response === UserResponseStatuses.notInterested && new Date(event.event.startDate) > new Date()),
		)

		const past = convertUserEventsToEvents(
			fullList.filter(
				(event) =>
					(event.response === UserResponseStatuses.going || event.response === UserResponseStatuses.interested) &&
					new Date(event.event.startDate) < new Date(),
			),
		)

		dispatch(
			setUserEvents({
				createdByUser,
				going,
				interested,
				notInterested,
				past,
			}),
		)
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

const loadFullEventData = async (id: string, userId: number, schoolId: number) => {
	const {
		data: { response },
	} = await http.events.getEventUserResponse(id, userId, schoolId)
	const { data } = await http.events.getEventById(id, schoolId)

	const isAdmin = isEventAdmin(userId, data?.event?.submittedByUserId)

	const shifts = await http.events.getEventVolunteerShifts(id, schoolId)
	const res = await http.events.getEventVolunteers(id, schoolId)

	//@ts-ignore
	const volunteers = res.data?.response?.reduce((acc, el, i) => {
		const shiftId = Object.keys(el)[0]
		const userData = getNormalizeShiftUser(el[shiftId])

		let newAcc = {
			...acc,
			[shiftId]: [...(acc[shiftId] || [])],
		}

		if (el[shiftId].appUserId !== null) {
			newAcc = {
				...newAcc,
				[shiftId]: [...(newAcc[shiftId] || []), userData],
			}
		}

		return newAcc
	}, {})

	let attendees: any = {}

	if (isAdmin) {
		const attendeesResponse = await http.events.getEventAttendees(id, schoolId)
		const attendeesRep = attendeesResponse.data?.response || {}

		attendees = Object.keys(attendeesRep).reduce(
			(acc, key) => ({
				...acc,
				[key]: getNormalizedUserData(attendeesRep[key]),
			}),
			{},
		)
	}

	const event = {
		...data?.event,
		response,
		attendees,
		volunteerShifts: shifts.data?.event || [],
		volunteers: volunteers || [],
	}

	return event
}

export const loadEventByIdRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/LOAD_EVENT_REQUEST`, async (id: string, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const event = await loadFullEventData(id, userId, schoolId)

		dispatch(setIndividualEvent(event))
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

export const setEventResponseRequest = createAsyncThunk<
	any,
	{
		id: string
		status: ReportActions | UserResponseStatuses
		reportMessage?: string
		orgId?: string
	},
	{
		state: RootState
	}
>(`${MODULE_NAME}/SET_EVENT_STATUS_REQUEST`, async ({ id, status, reportMessage, orgId }, { dispatch, getState, rejectWithValue }) => {
	const notificationId = uuidv4()

	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		const { selectedOrganization } = getState().ORGANIZATIONS
		const [pastRes, futureRes] = await Promise.all([
			http.volunteers.getUserPastVolunteerShifts(userId, schoolId),
			http.volunteers.getUserFutureVolunteerShifts(userId, schoolId),
		])

		const futureEventsIds = uniq(futureRes.data.userVolunteerOpps.map((shift: any) => shift.eventId)) as string[]
		const pastEventsIds = uniq(pastRes.data.userVolunteerOpps.map((shift: any) => shift.eventId)) as string[]

		const uniqEventsIds = uniq([...futureEventsIds, ...pastEventsIds])

		if (!uniqEventsIds.includes(id)) {
			dispatch(setIndividualEventResponse(status))
			dispatch(updateEvent({ id, status }))

			const rsvpStatus = status as UserResponseStatuses
			dispatch(
				eventsApi.util.updateQueryData('getEvent', { id }, (draft) => {
					if (draft) {
						draft.event.response = { ...draft.event.response, response: rsvpStatus }
					}
				}),
			)

			if (selectedOrganization && selectedOrganization.info) {
				dispatch(
					organizationApi.util.updateQueryData(
						'getOrganizationEvents',
						{ orgId: selectedOrganization.info.id, filter: 'future' },
						(draft) => {
							if (draft) {
								draft.results.forEach((event) => {
									if (event.id === id) {
										event.responseStatus = rsvpStatus
										// @ts-ignore
										event.response = { ...event.response, response: rsvpStatus }
									}
								})
							}
						},
					),
				)
			}

			await http.events.postEventUserResponse({ id, userId, response: status, reportMessage, schoolId })

			dispatch(eventsApi.util.invalidateTags([EVENT_RESPONSE_MAP.going, EVENT_RESPONSE_MAP.interested]))
			dispatch(loadUserEventsRequest())

			const event = await loadFullEventData(id, userId, schoolId)
			dispatch(setIndividualEvent(event))
		} else {
			const key = uuidv4()
			dispatch(
				enqueueSnackbar({
					key,
					notification: {
						message: {
							type: SnackbarType.error,
							message: 'You must cancel your shifts before changing your response.',
							callback: {
								label: 'See Event',
								onClick: () => {
									dispatch(closeSnackbar({ key }))
									dispatch(push(PATHS.APP.EVENTS_SINGLE(id)))
								},
							},
						},
					},
				}),
			)
		}
		if (status === UserResponseStatuses.notInterested) dispatch(openNotInterestedEventPopup(true))
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: notificationId }))
	}
})

export const updateEventVolunteerShifts = createAsyncThunk<
	any,
	{ eventId: string; submittedIds: string[] },
	{
		state: RootState
	}
>(`${MODULE_NAME}/UPDATE_EVENT_SHIFTS_REQUEST`, async ({ eventId, submittedIds }, { dispatch, getState, rejectWithValue }) => {
	const notificationId = uuidv4()

	try {
		// dispatch(
		//   enqueueSnackbar({
		//     key: notificationId,
		//     notification: {
		//       message: {
		//         type: SnackbarType.uploading,
		//         message: 'Saving response...',
		//       },
		//     },
		//   })
		// );
		const rootState = getState()

		const { schoolId, userId } = selectAuth(rootState)

		const shifts = getState().EVENTS.selectedEvent?.volunteerShifts ?? []

		const idsToDelete = shifts
			.filter((shift: any) => isUserSubmittedToShift(shift, userId))
			.map(({ id }: any) => id)
			.filter((id: string) => !submittedIds?.includes(id)) as string[]

		const idsToSubmit = shifts
			.filter((shift: any) => !isUserSubmittedToShift(shift, userId))
			.map(({ id }: any) => id)
			.filter((id: string) => submittedIds?.includes(id)) as string[]

		await Promise.all(idsToSubmit.map((shiftId) => http.events.postVolunteerResponse(shiftId, userId, schoolId)))

		if (idsToDelete && idsToDelete.length) {
			await Promise.all(idsToDelete.map((shiftId) => http.events.deleteVolunteerSubscription(shiftId, userId, schoolId)))
			if (!submittedIds.length) {
				dispatch(
					enqueueSnackbar({
						key: uuidv4(),
						notification: {
							message: {
								type: SnackbarType.info,
								message: 'You are no longer scheduled for volunteer shifts.',
							},
						},
					}),
				)
			}
		}

		const event = await loadFullEventData(eventId, userId, schoolId)

		dispatch(setIndividualEvent(event))
		dispatch(loadUserVolunteerShiftsRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: notificationId }))
	}
})

const applyEventPostToFeed = async (postToFeed: boolean, eventId: string) => {
	if (postToFeed) {
		globalStates.createFeedsModal.setValue((data) => {
			data.content.sharedEntity = { sharedEntityId: eventId, sharedEntityType: EntityTypes.event }
		})
	}
}

export const createEventRequest = createAsyncThunk<
	any,
	any,
	{
		state: RootState
	}
>(`${MODULE_NAME}/CREATE_EVENT_REQUEST`, async (data: any, { dispatch, getState, rejectWithValue }) => {
	const eventId = uuidv4()

	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		dispatch(
			enqueueSnackbar({
				key: eventId,
				notification: {
					message: {
						type: SnackbarType.uploading,
						message: 'Uploading Event',
					},
				},
			}),
		)

		const { eventInfo, newImageName } = prepareEventInfo(userId, data, eventId)
		dispatch(push(PATHS.APP.EVENTS))

		const response = await http.events.postNewEvent(eventInfo, schoolId)

		if (response.status === 200 && newImageName) {
			await uploadPhoto({
				schoolId,
				fileUrl: data[FORM.photo],
				imageName: newImageName,
				directory: PhotoUploadDirectories.EVENT,
				options: {
					compressImage: true,
				},
			})
		}

		const successId = uuidv4()

		const newEventId = response.data.id
		dispatch(
			enqueueSnackbar({
				key: successId,
				notification: {
					message: {
						message: 'Your event has successfully been submitted!',
						type: SnackbarType.success,
						callback: {
							label: 'See Event',
							onClick: () => {
								dispatch(closeSnackbar({ key: successId }))
								dispatch(push(PATHS.APP.EVENTS_SINGLE(newEventId)))
							},
						},
					},
				},
			}),
		)
		applyEventPostToFeed(data.postToFeed, response.data.id)
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: eventId }))
	}
})

export const editEventRequest = createAsyncThunk<
	any,
	any,
	{
		state: RootState
	}
>(`${MODULE_NAME}/EDIT_EVENT_REQUEST`, async (data: any, { dispatch, getState, rejectWithValue }) => {
	const rootState = getState()
	const { schoolId, userId } = selectAuth(rootState)

	const { id: eventId, photoUrl } = rootState.EVENTS.selectedEvent!

	try {
		dispatch(
			enqueueSnackbar({
				key: eventId,
				notification: {
					message: {
						type: SnackbarType.uploading,
						message: 'Updating Event',
					},
				},
			}),
		)

		const { eventInfo, newImageName } = prepareEventInfo(userId, data, eventId, photoUrl)

		const response = await http.events.patchEvent(userId, eventInfo, schoolId)

		if (response.status === 200 && newImageName) {
			await uploadPhoto({
				schoolId,
				fileUrl: data[FORM.photo],
				imageName: newImageName,
				directory: PhotoUploadDirectories.EVENT,
				options: {
					compressImage: true,
					onError: {
						uploadErrors: UploadErrors.EVENT,
						uploadErrorMessage: UploadErrorMessage.EVENT,
					},
				},
			})
		}

		applyEventPostToFeed(data.postToFeed, response.data.id)
	} catch (e: any) {
		return rejectWithValue(e)
	} finally {
		dispatch(closeSnackbar({ key: eventId }))
		dispatch(push(PATHS.APP.EVENTS_SINGLE(eventId)))
	}
})

export const deleteEventRequest = createAsyncThunk<
	any,
	string,
	{
		state: RootState
	}
>(`${MODULE_NAME}/DELETE_EVENT_REQUEST`, async (eventId, { dispatch, getState, rejectWithValue }) => {
	try {
		const rootState = getState()
		const { schoolId, userId } = selectAuth(rootState)

		await http.events.deleteEvent(userId, eventId, schoolId)

		dispatch(deleteEvent(eventId))

		await dispatch(loadUserEventsRequest())
		await dispatch(loadUserVolunteerShiftsRequest())
	} catch (e: any) {
		return rejectWithValue(e)
	}
})

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback } from 'react'

import { CheerIcon, ColorButton, Modal } from '@/components'
import PopupModalCard from '@/components/UserProfile/EditUserModal/PopupModalCard'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	itemPopupButton: {
		width: '100%',
		textAlign: 'center',
		borderTop: `solid 1px ${theme.palette.divider}`,
		borderRadius: 0,
		color: theme.palette.text.primary,
		padding: '20px 0',
	},
	cardHeader: {
		borderBottom: 'none',
	},
	checkIcon: {
		width: 70,
		height: 70,
	},
	titleText: {
		margin: '5px 0',
		textAlign: 'center',
		fontWeight: 700,
	},
	text: {
		width: 300,
		margin: '15px 0',
		textAlign: 'center',
	},
	button: {
		marginTop: 20,
		padding: '10px 30px',
		fontWeight: 600,
	},
	header: {
		margin: '10px 0',
		display: 'flex',
		alignItems: 'center',
	},
	popup: {
		width: '40%',
	},
}))

interface SuccessPopupWithButtonProps {
	isOpenModal: boolean
	handleCloseModal: () => void
	header: string
	title: string[]
	text: string
	buttonLabel: string
}

const SuccessPopupWithButton = ({
	isOpenModal = false,
	handleCloseModal = () => {},
	header,
	title,
	text,
	buttonLabel,
}: SuccessPopupWithButtonProps) => {
	const classes = useStyles()

	const onClose = useCallback(() => {
		handleCloseModal()
	}, [handleCloseModal])

	return (
		<Modal isOpen={isOpenModal} onClose={onClose}>
			<PopupModalCard className={classes.popup}>
				<div className={classes.header}>
					<Typography className={classes.titleText}>{header}</Typography>
					<CheerIcon />
				</div>
				{title.map((txt, idx) => (
					<Typography key={idx} className={classes.titleText}>
						{txt}
					</Typography>
				))}
				<Typography className={classes.text}>{text}</Typography>
				<ColorButton className={classes.button} onClick={onClose}>
					{buttonLabel}
				</ColorButton>
			</PopupModalCard>
		</Modal>
	)
}

export default SuccessPopupWithButton

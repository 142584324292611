import IconButton from '@/components/Buttons/IconButton'
import Filter from '@/components/Filter'
import Router from '@/components/Router'
import ConnectionRequests from '@/components/UserNetwork/ConnectionRequestsBar'
import NetworkPageHeader from '@/components/UserNetwork/NetworkPageHeader'
import { PATHS, ROLE, STYLES } from '@/constants'
import { allCampusesOption } from '@/constants/filter'
import { getActiveCampuses, selectShowCampuses } from '@/features/campus/slice'
import { Filters, SearchConfig } from '@/interfaces/common'
import ROUTES from '@/routes'
import { useAppDispatch } from '@/store'
import {
	clearFilters,
	getConnectionRequests,
	getPendingRequests,
	getSearchConfig,
	getTotalConnections,
	loadNetworkListWithFilterRequest,
	loadUserConnectionsRequest,
} from '@/store/network'
import { getUserType } from '@/utils/authHandlers'
import { makeStyles } from '@material-ui/core/styles'
import { PersonAdd } from '@material-ui/icons'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	searchUsersButton: {
		width: '100%',
		margin: '3vh 0',
		fontWeight: 600,
		padding: '1vh 1vw',
		color: theme.palette.primary.main,
		border: `solid 1px ${theme.palette.primary.main}`,
	},
	container: {
		height: '100%',
		width: STYLES.FILL_AVAILABLE_WIDTH,
	},
}))

const ROUTES_WITHOUT_FILTER = [PATHS.APP.NETWORK_USER()]

const NetworkPage = () => {
	const classes = useStyles()
	const { pathname } = useLocation()
	const { push } = useHistory()
	const dispatch = useAppDispatch()

	const [showFilter, setShowFilter] = useState(true)
	const [activeTab, setActiveTab] = useState(0)

	const searchConfig = useSelector(getSearchConfig)
	const campuses = useSelector(getActiveCampuses)
	const connectionRequests = useSelector(getConnectionRequests)
	const pendingRequests = useSelector(getPendingRequests)
	const totalConnections = useSelector(getTotalConnections)

	const pageTitle = pathname === PATHS.APP.NETWORK_MY ? 'My Network' : 'Discover'
	const searchPlaceholder = pathname === PATHS.APP.NETWORK_MY ? 'Search my network' : 'Search'
	const showCampuses = useSelector(selectShowCampuses)
	const withAllCampusesOptionList = useMemo(() => [allCampusesOption, ...campuses], [campuses])

	const handleClearFilters = () => {
		dispatch(clearFilters())
		setActiveTab(0)
	}

	const fetchSearchAndFilter = useCallback(
		(config: SearchConfig) => {
			dispatch(loadNetworkListWithFilterRequest(config))
			window.scrollTo({ top: 0 })
		},
		[dispatch],
	)

	const handleChangeTab = useCallback(
		(tabIndex: number) => {
			const userType = {
				0: undefined,
				1: [getUserType(ROLE.STUDENT)],
				2: [getUserType(ROLE.FACULTY), getUserType(ROLE.STAFF)],
			}

			const userTypeId = userType[tabIndex as 0 | 1 | 2]

			const config = {
				...searchConfig,
				params: {
					...searchConfig.params,
					userTypeId,
				},
				fetchMore: false,
			}

			setActiveTab(tabIndex)
			fetchSearchAndFilter(config)
		},
		[fetchSearchAndFilter, searchConfig],
	)

	const handleClickDiscoverConnections = useCallback(() => {
		push(PATHS.APP.NETWORK_SEARCH)
	}, [push])

	const defaultFilter = useMemo(
		() =>
			pathname === PATHS.APP.NETWORK_MY
				? {
						key: pathname,
						defaultFilterOptions: {
							connectivityStatus: [0],
						},
				  }
				: {
						key: pathname,
				  },
		[pathname],
	)

	useLayoutEffect(() => {
		dispatch(loadUserConnectionsRequest())
		setShowFilter(!ROUTES_WITHOUT_FILTER.includes(pathname) && !pathname.includes('user'))
	}, [dispatch, pathname])
	const filters = showCampuses ? [Filters.campuses] : []
	return (
		<div className={classes.root}>
			{showFilter && (
				<Filter
					{...defaultFilter}
					title={pageTitle}
					searchPlaceholder={searchPlaceholder}
					value={searchConfig}
					filters={filters}
					filterOptions={{
						campuses: withAllCampusesOptionList,
					}}
					canUseFilter={true}
					onFilterChange={fetchSearchAndFilter}
					clearFilters={handleClearFilters}
					additionalElementsRenderer={() =>
						pathname === PATHS.APP.NETWORK_MY && totalConnections ? (
							<IconButton className={classes.searchUsersButton} onClick={handleClickDiscoverConnections} startIcon={<PersonAdd />}>
								Discover Connections
							</IconButton>
						) : null
					}
				/>
			)}
			<div className={classes.container}>
				{showFilter && (
					<NetworkPageHeader
						title="My Network"
						tabs={['All', 'Students', 'Faculty/Staff']}
						activeTab={activeTab}
						handleChangeTab={handleChangeTab}
						pageInfo={totalConnections}
					/>
				)}
				<Router
					routes={ROUTES.network}
					redirectPaths={[
						{
							from: PATHS.APP.NETWORK,
							to: PATHS.APP.NETWORK_MY,
						},
					]}
				/>
			</div>
			{showFilter && <ConnectionRequests pendingRequests={pendingRequests} connectionRequests={connectionRequests} />}
		</div>
	)
}

export default NetworkPage

import SignUpCard from '@/components/SignUpCard'
import { SearchBar } from '@/components/filters/SearchBar'
import { GridLayout } from '@/features/onboarding/components/onboardingEntities/layouts/GridLayout'
import OnboardingEntityList from '@/features/onboarding/components/onboardingEntities/list'
import { ButtonItem } from '@/features/onboarding/components/onboardingEntities/templates/ButtonItem'
import { selectIsLoading, selectUserChoicesByType } from '@/features/onboarding/slice'
import { TOnboardingEntitiesParams } from '@/features/onboarding/types/queryParams/GetOnboardingEntitiesParams'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import useDebouncedHandler from '@/hooks/useDebounce'
import { useAppSelector } from '@/store'
import React from 'react'
import { useOnboardingStepConfig } from '../hooks'
import IStepProps from './types/IStepProps'

const MajorsStep: React.FC<IStepProps> = ({ onClickNext }) => {
	const majorsConfig = useOnboardingStepConfig('majors')

	const [search, setSearch] = useDebouncedHandler<TOnboardingEntitiesParams['search']>('')
	const userSelections = useAppSelector((state) => selectUserChoicesByType(state, { entityType: EntityTypes.major }))
	const isLoading = useAppSelector(selectIsLoading)

	const disableContinueButton = userSelections.length === 0

	const onClickContinue = () => {
		onClickNext()
	}

	return (
		<SignUpCard
			title={majorsConfig?.title}
			subTitle={majorsConfig?.subTitle}
			continueButtonDisabled={disableContinueButton}
			showSkipButtonWhenContinueDisabled={true}
			onClickContinue={onClickContinue}
			skipText="Skip"
		>
			<GridLayout>
				<SearchBar placeholder="Search Majors" isLoading={isLoading && !!search} initialValue={search} onChange={setSearch} />
				<OnboardingEntityList
					entityType={EntityTypes.major}
					search={search}
					renderItem={({ item, onClick }) => <ButtonItem {...item} onClick={onClick} />}
				/>
			</GridLayout>
		</SignUpCard>
	)
}

export { MajorsStep }

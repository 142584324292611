import { Typography, makeStyles } from '@material-ui/core'
import React from 'react'

import { CommonModalCard, Modal } from '@/components'
import useSchoolId from '@/features/school/hooks/useSchoolId'
import { Message, TMessageReactions, TReactions } from '@/store/messages/types'
import { rootStyles } from '@/styles'
import { MessageContents } from '../Group/MessageContents'
import { MessageProfileImage } from '../MessageProfileImage'
import { ReactionsList } from './ReactionsList'

const useStyles = makeStyles((theme) => ({
	...rootStyles,
	modalLikersLabel: {
		color: theme.palette.text.secondary,
		fontSize: 16,
	},
	modalContent: {
		padding: 20,
		width: '100%',
		boxSizing: 'border-box',
	},
	modalLiker: {
		color: theme.colors.grey[600],
		fontSize: 18,
	},
}))

export type IReactionModalData = {
	message: Message
	type: TReactions
}

export type IMessageReactionsModalProps = IReactionModalData & {
	handleClose: () => void
}

const mapReactionTypeToHeader: {
	[key in TMessageReactions]?: string
} = {
	[TMessageReactions.like]: 'Likers',
}

const MessageReactionsModal: React.FC<IMessageReactionsModalProps> = ({ message, type, handleClose }) => {
	const schoolId = useSchoolId()
	const classes = useStyles()
	return (
		<Modal isOpen={true} onClose={handleClose}>
			<CommonModalCard title="Message Likes" onClose={handleClose}>
				<div className={classes.modalContent}>
					<div className={classes.flexCenteredItemsY}>
						<MessageProfileImage
							externalId={message.sourceUser.externalId}
							photoUrl={message.sourceUser.photoUrl}
							userId={message.sourceUser.id}
						/>
						<MessageContents {...{ message, isHovered: false, isInModal: true }} />
					</div>
					<div className={classes.marginTop10}>
						{mapReactionTypeToHeader[type] && <Typography className={classes.modalLikersLabel}>{mapReactionTypeToHeader[type]}</Typography>}
						<ReactionsList schoolId={schoolId} type={type} messageId={message.id} />
					</div>
				</div>
			</CommonModalCard>
		</Modal>
	)
}

export { MessageReactionsModal }

import http from '@/api/http/requestHttp'
import { UserInfo } from '@/interfaces/user'
import { orgTypes } from '@/utils/organizationRoles'

interface PostOrganizationMembershipRequestParams {
	orgId: string
	userId: UserInfo['id']
	membershipTypeId: orgTypes
	schoolId: number
}

const postOrganizationMembershipRequest = async ({
	orgId,
	userId,
	membershipTypeId,
	schoolId,
}: PostOrganizationMembershipRequestParams) => {
	const url = `${schoolId}/organizations/org/${orgId}/membershipRequest`
	const body = { userId, membershipTypeId }

	return http.post(url, body)
}

export default postOrganizationMembershipRequest
